const TabelFilterViews = (self: any) => ({
	viewEPUsers() {
		let epList: any[] = []
		self.orgDashboardList.map((project: any) => {
			const fetchedEps = project.epUsers
			project.epUsers &&
				fetchedEps.map((ep: any) => {
					if (
						epList.findIndex((item: any) => item === ep.name) === -1
					) {
						epList.push(ep.name)
					}
				})
		})

		return epList.sort()
	},
	viewQCUsers() {
		let qcList: any[] = []
		self.orgDashboardList.map((project: any) => {
			const fetchedQcs = project.qcUsers
			project.qcUsers &&
				fetchedQcs.map((qc: any) => {
					if (
						qcList.findIndex((item: any) => item === qc.name) === -1
					) {
						qcList.push(qc.name)
					}
				})
		})
		return qcList.sort()
	},
	viewClients() {
		let clients: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				clients.findIndex(
					(item: any) => item === project.clientName
				) === -1
			) {
				clients.push(project.clientName)
			}
		})
		return clients.sort()
	},
	viewEngTypes() {
		let engTypes: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				engTypes.findIndex(
					(item: any) => item === project.engTypeName
				) === -1
			) {
				engTypes.push(project.engTypeName)
			}
		})
		return engTypes.sort()
	},
	viewYearList() {
		let years: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				years.findIndex(
					(item: any) => item === project.financialYear
				) === -1
			) {
				years.push(project.financialYear)
			}
		})
		return years.sort()
	},
	viewPeriodTypes() {
		let periodTypes: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				periodTypes.findIndex(
					(item: any) => item === project.periodName
				) === -1
			) {
				periodTypes.push(project.periodName)
			}
		})
		return periodTypes.sort()
	},
	viewGroupNames() {
		let groupNames: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				groupNames.findIndex(
					(item: any) => item === project.groupName
				) === -1
			) {
				groupNames.push(project.groupName)
			}
		})
		return groupNames.sort()
	},
	viewRaNames() {
		let raNames: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				raNames.findIndex((item: any) => item === project.raName) === -1
			) {
				raNames.push(project.raName)
			}
		})
		return raNames.sort()
	},

	viewArchPolicyPeriodList() {
		let archPolicyPeriodList: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				archPolicyPeriodList.findIndex(
					(item: any) => item === project.archPolicyPeriod
				) === -1
			) {
				archPolicyPeriodList.push(project.archPolicyPeriod)
			}
		})
		return archPolicyPeriodList.sort()
	},
	viewProjStatus() {
		let projStatus: any[] = []

		self.orgDashboardList.map((project: any) => {
			if (
				projStatus.findIndex(
					(item: any) => item === project.statusName
				) === -1
			) {
				projStatus.push(project.statusName)
			}
		})
		return projStatus.sort()
	},
})

export default TabelFilterViews
