import { types } from "mobx-state-tree"
import {
	DLObjectFormat,
	DLSrcFormat,
} from "../../../../common-models/enumerations/common-enums"
import { OrgProjTemplateItemType } from "../../../organization-side/org-library/org-proj-templates/store/data-models/org-proj-templates.data-model"

// WARNING: Isn't it better to use integrated hierarchy... ??? (TBD)
export enum LibHierarchy {
	group = "GROUP",
	folder = "FOLDER", // @Noah: TODO: update
	file = "FILE",
}

export type LibFileProps = {
	parentId: string | null
	id: string
	title: string
	hierarchy: LibHierarchy
	objectFormat: DLObjectFormat
	srcFormat: DLSrcFormat
	expanded: boolean
	//
	aliasId?: string
}

export const LibFile = types.model({
	parentId: types.union(types.string, types.null), // template folder ID or null(=template folder which has no parent)
	id: types.string, // template folder ID or template(=file) ID
	title: types.string, // template folder name or template(=file) name
	hierarchy: types.enumeration<LibHierarchy>(Object.values(LibHierarchy)),
	objectFormat: types.enumeration<DLObjectFormat>(
		Object.values(DLObjectFormat)
	),
	srcFormat: types.enumeration<DLSrcFormat>(Object.values(DLSrcFormat)),
	expanded: types.union(types.boolean, types.undefined),
	// for dnd to WP or PF
	// TODO: update is required
	aliasId: "x",
})

/**
 * * File library structure
 * - Library Group
 * ---- Library
 * -------- Folder
 * ------------ File
 */
// lib group : library group which has apg and normal library together
export const libGroupPrefix = "lib_group_"
export const apgLibPrefix = "apg_lib_"
export const normalLibPrefix = "normal_lib_"
// NOTE: apg lib file does not exist
// export const apgLibFilePrefix = ""
export const normalLibFilePrefix = "lib_file_normal_"

export const ProjTemplates = types.model({
	id: types.string,
	parentId: types.union(types.null, types.string),
	title: types.string,
	type: types.enumeration<OrgProjTemplateItemType>(
		Object.values(OrgProjTemplateItemType)
	),
})
