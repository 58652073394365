import { flow } from "mobx-state-tree"

const UpdateProjTrash = (self: any, restore: any) => ({
	updateProjTrashItem: flow(function* (payload) {
		const actionName = "updateProjTrashItem"
		try {
			const response = yield restore(payload)
			// console.log(actionName, "__ response", response)

			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default UpdateProjTrash
