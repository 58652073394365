import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import DLSpinner from "../../../../components/basic-elements/spinner/DLSpinner"
import Adjustments from "../adjustments/Adjustments"

export default observer(function PassedAdjustmentsController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.fs_paje
	const actionName = "getTrialBalanceDetails"

	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	const needRefresh = projStore.passedAdjustments.needRefresh

	useEffect(() => {
		const projId = projStore.checkin.projId
		menuAccess &&
			needRefresh &&
			projStore.passedAdjustments.getTrialBalanceDetails(projId)
	}, [menuAccess, needRefresh])

	const actionStatus = projStore.passedAdjustments.getActionStatus(actionName)
	const contentsHeight = store.ui.contentsAreaHeight
	return (
		<>
			{menuAccess ? (
				<>
					{actionStatus === ActionStatus.loading && (
						<div
							className="FR AC JC"
							style={{
								width: "100%",
								height: contentsHeight,
							}}
						>
							<DLSpinner />
						</div>
					)}
					{actionStatus === ActionStatus.success && (
						<Adjustments
							partialStore={projStore.passedAdjustments}
							contentsHeight={contentsHeight}
							fetchingStatus={projStore.passedAdjustments.getActionStatus(
								"getPassedAdjustments"
							)}
							permission={permission}
							menuId={menuId}
						/>
					)}
				</>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
