import React from "react"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import {
	mdiHelpCircleOutline,
	mdiSettings,
	mdiMagnify,
	mdiUnfoldLessHorizontal,
	mdiArrowExpandVertical,
} from "@mdi/js"
import {
	DLIconButton,
	DLInputField,
	DLBasicToggleButton,
} from "../../../../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"

export default observer(function RoleDetailControl({
	query,
	handleQuery,
	on,
	handleOn,
}: {
	query: string | undefined
	handleQuery: any
	on: boolean
	handleOn: any
}) {
	const orgStore = useOrgStore()

	return (
		<StyledRoleDetailControl className="FR AC JSB">
			<div className="left-side FR AC">
				<DLInputField
					eleType="search"
					elePlaceholder="Search the menu"
					eleTestId="search-menu-for-permission"
					eleHandleChange={handleQuery}
					eleValue={query}
					eleStartAdornment={<Icon path={mdiMagnify} size={0.8} />}
					eleClassName="search-menu-input"
				/>
				<div className="column-header-container FR AC">
					<span className="column-header">access</span>
					<span className="column-header">create</span>
					<span className="column-header">update</span>
					<span className="column-header">delete</span>
					<span className="column-header">export</span>
				</div>
			</div>
			<div className="right-side FR AC">
				<div className="FR AC">
					<DLTooltip title="Collapse sub menus" elePlacement="top">
						<div>
							<DLIconButton
								eleTestId="collapse-all-btn"
								clickHandler={() =>
									orgStore.orgRoles.collapseAll()
								}
								disabled={orgStore.orgRoles.allMenusAreCollapsed()}
							>
								<Icon
									path={mdiUnfoldLessHorizontal}
									size={0.8}
								/>
							</DLIconButton>
						</div>
					</DLTooltip>
					<DLTooltip title="Expand sub menus" elePlacement="top">
						<div>
							<DLIconButton
								eleTestId="expand-all-btn"
								clickHandler={() =>
									orgStore.orgRoles.expandAll()
								}
								disabled={orgStore.orgRoles.allMenusAreExpanded()}
							>
								<Icon
									path={mdiArrowExpandVertical}
									size={0.8}
								/>
							</DLIconButton>
						</div>
					</DLTooltip>
					<DLIconButton eleTestId="screen-setting-btn">
						<Icon path={mdiSettings} size={0.8} />
					</DLIconButton>
					Show available only{" "}
					<DLBasicToggleButton on={on} setOn={handleOn} />
				</div>
				<DLIconButton eleTestId="open-help-dialog-btn">
					<Icon path={mdiHelpCircleOutline} size={0.8} />
				</DLIconButton>
			</div>
		</StyledRoleDetailControl>
	)
})

// const ExpandSubMenusButton = () => {
// 	const projStore = useProjStore()
// 		return (
// 			<>
// 				{projStore.permissions.expandSubMenus ? (
// 					<DLTooltip title="Collapse sub menus" elePlacement="top">
// 						<div>
// 							<DLIconButton
// 								eleTestId="collapse-all-btn"
// 								clickHandler={() =>
// 									projStore.permissions.setExpandSubMenus(
// 										false
// 									)
// 								}
// 							>
// 								<Icon
// 									path={mdiUnfoldLessHorizontal}
// 									size={0.8}
// 								/>
// 							</DLIconButton>
// 						</div>
// 					</DLTooltip>
// 				) : (
// 					<DLTooltip title="Expand sub menus" elePlacement="top">
// 						<div>
// 							<DLIconButton
// 								eleTestId="expand-all-btn"
// 								clickHandler={() =>
// 									projStore.permissions.setExpandSubMenus(
// 										true
// 									)
// 								}
// 							>
// 								<Icon
// 									path={mdiArrowExpandVertical}
// 									size={0.8}
// 								/>
// 							</DLIconButton>
// 						</div>
// 					</DLTooltip>
// 				)}
// 			</>
// 		)
// }

const StyledRoleDetailControl = styled.div`
	height: ${(props) => props.theme.shared.baseUnit};
	border-bottom: 1px solid gray;
	.left-side {
		.search-menu-input {
			/* border: 1px solid pink; */
			width: 14rem;
			padding-right: 1rem;
		}
		.column-header-container {
			/* border: 1px solid pink; */
			.column-header {
				width: 5rem;
				text-align: center;
			}
		}
	}
	.right-side {
	}
`
