import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"

export type EditFileViewModelActionProps = {
	projId: string
	fileId: string
	aliasId: string
	title: string
}

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const EditFile = (self: any) => ({
	editFile({ projId, fileId, aliasId, title }: EditFileViewModelActionProps) {
		// 0.
		const actionName = "editFile"
		// 1.
		// 2.
		if (title === "" && title === undefined) {
			alert("Cannot use empty title. Please try again")
			return
		}
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.updateFile({ projId, fileId, aliasId, title })
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					self.setFileTreeDialogOpen(FileScreenDialog.fileEdit, false)
					self.reflectFileInfoUpdate({ fileId, aliasId, title })
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditFile
