import { flow } from "mobx-state-tree"

const UpdateEliminatingEntry = (self: any, update: any) => ({
	updateEliminatingEntry: flow(function*(payload: {
		AccountDescription: string
		AccountNumber: string
		Credit: string
		Debit: string
		EntityID: number
		Year: string
		EliminatingEntryID: number
	}) {
		const actionName = "updateEliminatingEntry"
		try {
			const response = yield update(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default UpdateEliminatingEntry
