const allMenuOptions = [
	{
		text: "Create Folder",
		id: "Create Folder",
	},
	{
		text: "Create Workpaper",
		id: "Create Workpaper",
	},
	{
		text: "Folder Lock",
		id: "Folder Lock",
	},
	{
		text: "Show Checkbox",
		id: "Show Checkbox",
	},
	{
		text: "QC View",
		id: "QC View",
	},
	{
		text: "Prepare",
		id: "Prepare",
	},
	{
		text: "Un-Prepare",
		id: "Un-Prepare",
	},
	{
		text: "Review",
		id: "Review",
	},
	{
		text: "Un-Review",
		id: "Un-Review",
	},
	{
		text: "Expand All",
		id: "Expand All",
	},
	{
		text: "Toggle Column",
		id: "Toggle Column",
	},
	{
		text: "Show Files Only",
		id: "Show Files Only",
	},
	{
		text: "Open Comment Drawer",
		id: "Open Comment Drawer",
	},
	{
		text: "Open Diagnosis Drawer",
		id: "Open Diagnosis Drawer",
	},
	{
		text: "Open Opened File List",
		id: "Open Opened File List",
	},
	{
		text: "Open Library Drawer",
		id: "Open Library Drawer",
	},
	{
		text: "Search View",
		id: "Search View",
	},
	{
		text: "Export",
		id: "Export",
	},
]

export default allMenuOptions
