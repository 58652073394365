import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"


export default observer(function CommentListCard({ partialStore } : { partialStore:any }) {
	return (
		<StyledCommentListCard
			className="simple-card FC AC"
			data-testid="comment-list-card"
		>
		    <h2>Comments List</h2>
		    <div className="comment-list-table" >
		    	<div className="FR header">
		    		<div className="comment-type">
		    			Comment Type
		    		</div>
		    		<div className="completed">
		    			Completed
		    		</div>
		    		<div className="open">
		    			Open
		    		</div>
		    		<div className="total">
		    			Total
		    		</div>
		    	</div>
		    	<hr style={{ height: "3px", backgroundColor: "gray" }} />
		    	{partialStore.commentList.map((comment:any) => {
		    		return(
			    		<>
			    			<div className="FR" key={comment.type} >
			    				<div className="comment-type">
					    			{comment.type}
					    		</div>
					    		<div className="completed">
					    			{!!comment.completed ? comment.completed : "-"}
					    		</div>
					    		<div className="open">
					    			{!!comment.open ? comment.open : "-"}
					    		</div>
					    		<div className="total">
					    			{!!comment.total ? comment.total : "-"}
					    		</div>
			    			</div>
			    			<hr />
			    		</>
		    		)
		    	})}
		    </div>
		</StyledCommentListCard>
	)
})

const StyledCommentListCard = styled.div`
	width: 33rem;
	&.simple-card {
		padding: 1rem;
		height: 12rem;
		box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
		border: 1px solid ${(props) => props.theme.shade10};
		border-radius: 4px;
		margin-bottom: 1rem;
		overflow: hidden;
		transition: 0.4s;
		:hover {
			/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
			box-shadow: 0px 2px 8px 1px ${(props) => props.theme.shade40};
			border: 1px solid ${(props) => props.theme.primaryLightDeep};
		}
	}
	.comment-list-table{
		width:100%;
		margin-top: 10px;
		.header{
			font-weight: 700
		}
		.comment-type{
			flex-basis: 25rem;
		}
		.completed{
			flex-basis: 10rem;
		}
		.open{
			flex-basis: 10rem;
		}
		.total{
			flex-basis: 10rem;
		}
	}
`