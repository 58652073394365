import { types } from "mobx-state-tree"
// model & view-model
import ResetPasswordModel from "./reset-password.model"
import ResetPasswordViewModel from "./reset-password.view-model"
import * as api from "./reset-password.apis"

export const ResetPasswordStore = (apiChangePassword: any) =>
	types
		.compose(ResetPasswordModel(apiChangePassword), ResetPasswordViewModel)
		.named("ResetPasswordStore")

const ResetPasswordStoreInstance = ResetPasswordStore(api.resetPassword).create(
	{
		// shared parts
		responses: {},
		responseSnackbar: {
			message: "",
		},
	}
)

export default ResetPasswordStoreInstance
