import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import GetNotificationSettings from "./view-model-actions/get-notification-settings"
import EditNotificationSettings from "./view-model-actions/edit-notification-settings"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import {
	NotificationSettings,
	ProjInfoAndModifiedDetails,
} from "./data-models/notification-settings.data-models"
import { initialStore } from "./notification-settings.provider"
import { getTzDate } from "../../../../library/converters/date-utc-converter"

const NotificationSettingsViewModel = types
	.model({
		commentNoti: types.array(NotificationSettings),
		expReportDateNoti: NotificationSettings,
		expArchDeadlineDateNoti: NotificationSettings,
		finalArchDeadlineDateNoti: NotificationSettings,
		projInfoAndModifiedDetails: ProjInfoAndModifiedDetails,
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setCommentNoti(item: any) {
			self.commentNoti = item
		},
		setExpReportDateNoti(item: any) {
			self.expReportDateNoti = item
		},
		setExpArchDeadlineDateNoti(item: any) {
			self.expArchDeadlineDateNoti = item
		},
		setFinalArchDeadlineDateNoti(item: any) {
			self.finalArchDeadlineDateNoti = item
		},
		pushItemToProjInfo(item: any) {
			self.projInfoAndModifiedDetails = item
		},
	}))
	.views((self) => ({
		viewProjInfoDates(timeZone: string, dateFormat: string) {
			const projInfo = self.projInfoAndModifiedDetails
			const formattedFinalReportDate = projInfo.finalReportDate
				? getTzDate({
						date: projInfo.finalReportDate,
						timeZone,
						dateFormat,
				  })
				: ""
			const formattedExpectedArchiveDeadlineDate =
				projInfo.expectedArchiveDeadlineDate
					? getTzDate({
							date: projInfo.expectedArchiveDeadlineDate,
							timeZone,
							dateFormat,
					  })
					: ""
			const formattedExpectedReportDate = projInfo.expectedReportDate
				? getTzDate({
						date: projInfo.expectedReportDate,
						timeZone,
						dateFormat,
				  })
				: ""
			const formattedFinalArchiveDeadlineDate =
				projInfo.finalArchiveDeadlineDate
					? getTzDate({
							date: projInfo.finalArchiveDeadlineDate,
							timeZone,
							dateFormat,
					  })
					: ""
			return {
				archPolicyPeriod: projInfo.archPolicyPeriod,
				finalReportDate: formattedFinalReportDate,
				expectedArchiveDeadlineDate:
					formattedExpectedArchiveDeadlineDate,
				expectedReportDate: formattedExpectedReportDate,
				finalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
			}
		},
	}))
	//CRUD
	.actions(GetNotificationSettings)
	.actions(EditNotificationSettings)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getNotificationSettings()
	// 	},
	// }))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default NotificationSettingsViewModel
