import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	InputWithLabel,
	DLSingleSelect,
	DLDatepicker,
	DLTextArea,
	DLInputField,
} from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"

export interface AddStepInputProps {
	legend: string
	auditSteps: string
	assertions: string
	notes: string
	ref: string
	preparer: string
	preparerDueDate: string
	reviewer: string
	reviewerDueDate: string
	prepared: string
	reviewed: string
}

export default observer(function AddNewStepDialog({
	open,
	setOpen,
	data,
	setData,
	selectedData,
	fromDialog,
}: {
	open: boolean
	setOpen: any
	data: any
	setData: any
	fromDialog: boolean
	selectedData?: any
}) {
	const store = useRootStore()

	const isEditMode = selectedData && Object.keys(selectedData).length

	const [inputs, setInputs] = useState({
		legend: "",
		auditSteps: "",
		assertions: "",
		notes: "",
		ref: "",
		preparer: "",
		preparerDueDate: "",
		reviewer: "",
		reviewerDueDate: "",
		prepared: "",
		reviewed: "",
	})

	useEffect(() => {
		isEditMode && setInputs(selectedData)
	}, [selectedData])

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value

		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	console.log(inputs)

	const handleDateInput = (name: string, date: string) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: date,
		}))
	}

	const handleAdd = () => {
		const fileId = data.reduce((acc: any, val: any) => {
			const id = idToNumber(val.id, IdType.file)
			acc = acc === undefined || id > acc ? id : acc
			return acc
		}, 0)
		const newData = [...data]
		newData.push({
			id: `fileId${fileId + 1}`,
			...inputs,
		})
		setData(newData)
		setOpen(false)
	}

	const handleEdit = () => {
		const newData = [...data]
		const index = data.findIndex((i: any) => i.id === selectedData?.id)
		newData[index] = inputs
		setData(newData)
		setOpen(false)
	}

	return (
		<DLDialog
			eleTestId={`${isEditMode ? "edit" : "add-new"}-step-dialog`}
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={isEditMode ? handleEdit : handleAdd}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={`${isEditMode ? "Edit" : "Add New"} Step`}
			dialogContents={
				<AddProjUserForm
					inputs={inputs}
					handleInputs={handleInputs}
					handleDateInput={handleDateInput}
					dateFormat={store.global.dateFormat.value}
					fromDialog={fromDialog}
				/>
			}
			cancelBtnText="Cancel"
			actionReady={inputs.auditSteps !== ""}
			actionBtn={isEditMode ? "Edit" : "Add"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})

function AddProjUserForm({
	inputs,
	handleInputs,
	handleDateInput,
	dateFormat,
	fromDialog,
}: {
	inputs: AddStepInputProps
	handleInputs: any
	handleDateInput: any
	dateFormat: string
	fromDialog: boolean
}) {
	return (
		<StyledAddNewStep>
			<div className="input-section FR">
				<InputWithLabel label="Legend">
					<DLInputField
						autoFocus
						eleTestId="legend_input"
						eleFullWidth
						eleName="legend"
						eleValue={inputs.legend}
						eleHandleChange={handleInputs}
						elePlaceholder="Please input legend"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Audit Steps">
					<DLTextArea
						eleTestId="input-comment-text"
						elePlaceholder="Please input audit steps"
						eleValue={inputs.auditSteps}
						eleHandleChange={handleInputs}
						eleName="auditSteps"
						eleClassName="auditSteps-text-input-area"
						eleFieldHeight={3}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="Assertions">
					<DLTextArea
						eleTestId="input-comment-text"
						elePlaceholder="Please input assertions"
						eleValue={inputs.assertions}
						eleHandleChange={handleInputs}
						eleName="assertions"
						eleClassName="assertions-text-input-area"
						eleFieldHeight={3}
					/>
				</InputWithLabel>
			</div>
			{!fromDialog && (
				<div className="input-section FR">
					<InputWithLabel label="Notes">
						<DLTextArea
							eleTestId="input-comment-text"
							elePlaceholder="Please input notes"
							eleValue={inputs.notes}
							eleHandleChange={handleInputs}
							eleName="notes"
							eleClassName="notes-text-input-area"
							eleFieldHeight={3}
						/>
					</InputWithLabel>
				</div>
			)}
			<div className="input-section FR">
				<InputWithLabel label="Ref">
					<DLSingleSelect
						eleTestId="select-ref"
						eleValue={inputs.ref}
						eleOnChange={handleInputs}
						eleName="ref"
						eleFullWidth
						withLabel={false}
						// eleRequired
						placeholder="Select Ref"
						optionList={[
							{
								name: "Ref-1",
								value: "Ref-1",
							},
							{
								name: "Ref-2",
								value: "Ref-2",
							},
						]}
					/>
				</InputWithLabel>
			</div>
			{!fromDialog && (
				<>
					<div className="input-section FR">
						<InputWithLabel label="Preparer">
							<DLSingleSelect
								eleTestId="select-preparer"
								eleValue={inputs.preparer}
								eleOnChange={handleInputs}
								eleName="preparer"
								eleFullWidth
								withLabel={false}
								placeholder="Select Preparer"
								optionList={[
									{
										name: "User 1",
										value: "user-1",
									},
									{
										name: "User 2",
										value: "user-2",
									},
								]}
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Preparer Due Date">
							<DLDatepicker
								eleTestId="preparerDueDate-date"
								eleName="preparerDueDate"
								selected={
									inputs.preparerDueDate === ""
										? null
										: inputs.preparerDueDate
								}
								onChange={(date: any) =>
									handleDateInput("preparerDueDate", date)
								}
								format={dateFormat}
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Reviewer">
							<DLSingleSelect
								eleTestId="select-project-role"
								eleValue={inputs.reviewer}
								eleOnChange={handleInputs}
								eleName="reviewer"
								eleFullWidth
								withLabel={false}
								placeholder="Select Reviewer"
								optionList={[
									{
										name: "User 1",
										value: "user-1",
									},
									{
										name: "User 2",
										value: "user-2",
									},
								]}
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Reviewer Due Date">
							<DLDatepicker
								eleTestId="reviewerDueDate-date"
								eleName="reviewerDueDate"
								selected={
									inputs.reviewerDueDate === ""
										? null
										: inputs.reviewerDueDate
								}
								onChange={(date: any) =>
									handleDateInput("reviewerDueDate", date)
								}
								format={dateFormat}
							/>
						</InputWithLabel>
					</div>
				</>
			)}
		</StyledAddNewStep>
	)
}

const StyledAddNewStep = styled.div`
	padding-left: 0.5rem;
	height: 500px;
	min-height: 50vh;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
