import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddEngType = (self: any) => ({
	addEngType(engTypeName: string) {
		// 0.
		const actionName = "addEngType"
		// 1.
		const checkedProps = hasEmptyProps({ engTypeName })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.createEngType(checkedProps.props.engTypeName)
			.then((response: any) => {
				if (response.data.Status === 1) {
					const reOrganizedEngType = {
						createdAt: response.data.CreatedDate, // [CreatedDate], ex) "10/14/19 6:32:53 PM"
						createdBy: response.data.CreatedUser, // [CreatedUser], ex) "cskim 10/14/2019"
						id: idToString(
							response.data.EngagementID,
							IdType.engType
						), // [EngagementID], ex) 159
						name: response.data.EngagementName,
					}
					self.pushItemToList(reOrganizedEngType)
					// close the dialog
					self.setOpenAdd(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to add a new engagement type",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddEngType
