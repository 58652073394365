import { types } from "mobx-state-tree"
import { ConsoleLog } from "../../components/basic-elements"

const OrgMainMenuUiInfo = types.model({
	id: types.string,
	title: types.string,
})

const OrgStoreUiHelper = types
	.model({
		currentOrgMainMenu: OrgMainMenuUiInfo,
	})
	.actions((self) => ({
		setCurrentOrgMainMenu(menuId: string, menuTitle: string) {
			ConsoleLog("org store ui-helper set current menu")
			self.currentOrgMainMenu = { id: menuId, title: menuTitle }
		},
	}))

export default OrgStoreUiHelper
