import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	putRequest,
	postRequest,
	deleteRequest,
} from "../../../../../library/api-requests"

export function create(params: { RiskAssessmentName: string }) {
	const response = postRequest({
		url: "/CreateRiskAssessment",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function read(payload: { PageIndex?: number; PageSize: number }) {
	const response = postRequest({
		url: "/GetRiskAssessments",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function update(params: {
	RiskAssessmentName: string
	RiskAssessmentID: number
}) {
	const response = putRequest({
		url: "/EditRiskAssessment",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(params: { id: number }) {
	const response = deleteRequest({
		url: "/DeleteRiskAssessment",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgRiskAssessmentApiProps = {
	apiCreate: (params: {
		RiskAssessmentName: string
	}) => Promise<AxiosResponse<any>>
	apiRead: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>

	apiUpdate: (params: {
		RiskAssessmentName: string
		RiskAssessmentID: number
	}) => Promise<AxiosResponse<any>>
	apiDelete: (params: { id: number }) => Promise<AxiosResponse<any>>
}
