import React, { useCallback } from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { Line } from "react-chartjs-2"
import styled from "styled-components"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"

const LineGraphView = observer(
	({
		partialStore,
		downloadAreaId,
	}: {
		partialStore: any
		downloadAreaId: string
	}) => {
		const store = useRootStore()

		const onChangeDate = useCallback((date: any, name: string) => {
			if (name === "fromDate") {
				partialStore.setFromDate(date)
			} else {
				partialStore.setToDate(date)
			}
		}, [])

		const filteredData = partialStore.getGraphFilteredData()

		const dateFormat = store.global.dateFormat.value
		const dntFormat = store.global.getDntFormat
		const options = {
			onClick: (e: any, element: any) => {
				console.log(e, element, "element")
				if (element.length) {
					const index = element[0]._index
					const datasetIndex = element[0]._datasetIndex
					const projectData =
						element[0]._chart.data.datasets[datasetIndex].projects
					const labels = element[0]._chart.data.labels
					const deepCopy = JSON.parse(
						JSON.stringify(projectData[index])
					)
					partialStore.setHBarDialogTitle(
						`Archived Projects at ${labels[index]}`
					)
					partialStore.setHBarTableList(deepCopy)
					partialStore.setHBarTableDetailsDialogOpen(true)
				}
			},
			scales: {
				yAxes: [
					{
						ticks: {
							beginAtZero: true,
							stepSize: 1,
						},
					},
				],
			},
			legend: {
				display: true,
				position: "bottom",
			},
		}

		return (
			<StyledLineGraphView>
				<div className="FR JC">
					<DLDateRangepicker
						selectedStartDate={partialStore.fromDate}
						selectedEndDate={partialStore.toDate}
						eleStartName="fromDate"
						eleEndName="toDate"
						onChange={onChangeDate}
						format={dateFormat}
						showSelectedDate={true}
					/>
				</div>
				<div className="graph-container" id={downloadAreaId}>
					<Line
						data={partialStore.getGraphData(
							filteredData,
							dntFormat
						)}
						options={options}
						height={100}
						getElementAtEvent={(e: any) => console.log(e)}
					/>
				</div>
			</StyledLineGraphView>
		)
	}
)

const StyledLineGraphView = styled.div``

export default LineGraphView
