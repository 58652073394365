import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import CryptoJS from "crypto-js"

const version = process.env.REACT_APP_API_VERSION

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail by pw, fail by email
 */
const Login = (self: any) => ({
	login(userInfo: { password: string; username: string }) {
		// 0.
		const actionName = "login"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const default_key = process.env.REACT_APP_encryption_key
		const default_iv = process.env.REACT_APP_encryption_iv

		const key = default_key && CryptoJS.enc.Utf8.parse(default_key)
		const iv = default_iv && CryptoJS.enc.Utf8.parse(default_iv)

		const encryptedPw = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf8.parse(userInfo.password.trim()),
			key,
			{
				keySize: 128 / 8,
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			}
		)

		const id = userInfo.username
		const pw = encryptedPw.toString()

		// WARNING: Do not clear the localStorage here.
		// WARNING: It should be in the LoginScreenController
		// clearLocalStorageForLobby()

		self.requestLogin(id, version === "v2" ? userInfo.password : pw)
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)

				let responseStatus = 0
				let loginToken = ""
				let cognitoToken = ""
				let serverMessage = ""

				// * ----- API version control
				const basicStatus = response.status
				const prevStatus = response.data?.status?.code
				//
				if (version === "v2") {
					responseStatus = basicStatus
					loginToken = response.data.Data.access_token
					// v2 API has no cognito token
					serverMessage =
						response.data.ValidationResults[0].ErrorMessage
				} else {
					responseStatus = prevStatus
					loginToken = response.data.data.access_token
					cognitoToken = response.data.data.CognitoAccesstoken
					serverMessage = response.data.status.message
				}

				// * ----- View Model Tasks
				if (responseStatus === 200) {
					localStorage.setItem("lobbyUserId", userInfo.username)
					localStorage.setItem(
						"loginToken",
						JSON.stringify(loginToken)
					)
					localStorage.setItem(
						"awsToken",
						JSON.stringify(cognitoToken)
					)
					// TODO: 'setDefaultEmail' below is duplicated with lobby checkin.
					// Maybe this one will be updated as ID in the future...
					self.setDefaultEmail(userInfo.username)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
					})
				} else if (responseStatus === 400) {
					// When the email address is incorrect
					// const serverMessage = response.data.status.message
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 400,
						customMessage: serverMessage,
						// msgCode:
						// 	ActionStatus.fail + "_" + actionName + "_byEmail",
						color: MessageColorType.orange,
						open: true,
					})
				} else if (responseStatus === 0) {
					// When the password is incorrect
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage: serverMessage,
						// msgCode: ActionStatus.fail + "_" + actionName + "_byPw",
						color: MessageColorType.orange,
						open: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage: serverMessage
							? serverMessage
							: "Something went wrong during sign in... Please contact to administrator.",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					openSnackbar: true,
				})
			})
	},
})

export default Login
