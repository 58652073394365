import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import {
	MultipleSignOffProps,
	SingleSignOffProps,
} from "../../data-models/sign-off.data-model"

const UnSignOff = (self: any) => ({
	unSignOff({ userId, signOffType, fileId, roleId }: SingleSignOffProps) {
		// 0.
		const actionName = "unSignOff"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		//3.
		const fileInfo = self.fileShortInfo(fileId, "UnSignOff")
		const fileAliasId = fileInfo.aliasId
		const fileInfoDisplay = `(${fileAliasId}) ${fileInfo.title}`
		let proceed = window.confirm(
			`Do you want to unsign off on this file ${fileInfoDisplay}?`
		)
		if (!proceed) {
			return
		}

		const fileNumberId = idToNumber(fileId, IdType.file)
		if (typeof fileNumberId === "number") {
			self.requestUnSignOff(
				fileNumberId,
				signOffType,
				self.storeName,
				roleId
			)
				.then((response: any) => {
					// console.log(actionName+ "__response " + response)
					if (response.status === 200 && response.data.Status === 1) {
						/**
						 * REFLECT to screen
						 */
						// const result = response.data
						// console.log(actionName, "result", result)
						// NOTE: : ( .... Need to request little more detailed response
						// NOTE: Reflect is not yet done...
						// WARNING: getChildrenOfFolder cannot reflect the update on the screen
						self.reflectUnSignOff({
							fileId,
							type: signOffType,
							userId,
							roleId,
						})

						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage: `Success to unsign off (${fileAliasId})`,
							open: true,
							// autoHide: true,
						})
					} else {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message:
								response.data.Message ||
								response.data.message ||
								`Something wrong to unsign off (${fileAliasId})`,
							// customMessage: "Something wrong...",
							open: true,
							autoHide: true,
						})
					}
				})
				.catch((error: Error) => {
					ConsoleLog([actionName, "__error ", error])
					self.handleViewModelError({
						error,
						actionName,
						open: true,
					})
				})
		} else {
			alert(
				`${actionName} cannot convert the id to number. ${fileNumberId} `
			)
		}
	},
	sequentialMultipleUnSignOffs(props: MultipleSignOffProps) {
		const { userId, roleId, signOffType, fileIds, index } = props
		// 0.
		const actionName = "sequentialMultipleUnSignOffs"
		// 1.
		// 2.
		if (index === 0) {
			// Do not comment under things. It is used to restrict multiple click sign off button
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.loading,
			})
		}

		//3.
		const targetId = fileIds[index]
		const fileNumId = idToNumber(targetId, IdType.file)
		const fileActionName = "unSignOff_" + targetId
		const carryOn = index < fileIds.length - 1
		const lastItem = index === fileIds.length - 1

		/**
		 * * casess
		 * 1. success
		 * 2. fail-A by logical issue (200 but fail)
		 * 3. fail-B by model or view model issue
		 * 4. fail-C because fail to convert file Id as number
		 */

		if (typeof fileNumId === "number") {
			self.responses.setResponse(fileActionName, {
				actionName: fileActionName,
				status: ActionStatus.loading,
			})
			self.requestUnSignOff(
				fileNumId,
				signOffType,
				self.storeName,
				roleId
			)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// ConsoleLog(fileActionName, "__response", response)
						// 1. when sucess
						ConsoleLog(["success", targetId, fileActionName])
						self.reflectUnSignOff({
							fileId: targetId,
							type: signOffType,
							userId,
							roleId,
						})
						self.responses.setResponse(fileActionName, {
							actionName: fileActionName,
							status: ActionStatus.success,
						})
						self.spliceSelectedItem(targetId)

						// NOTE: call next item or finish this action
						if (carryOn) {
							self.sequentialMultipleUnSignOffs({
								...props,
								index: index + 1,
							})
						}
						if (lastItem) {
							self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.standby,
							})
						}
					} else {
						// 2. when fail
						ConsoleLog(["fail-A", targetId, fileActionName])
						self.handleResponse({
							actionName: fileActionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message:
								response.data.Message ||
								response.data.message ||
								"Something's wrong...",
							open: false,
						})
						// NOTE: call next item or finish this action
						if (carryOn) {
							self.sequentialMultipleUnSignOffs({
								...props,
								index: index + 1,
							})
						}
						if (lastItem) {
							self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.standby,
							})
						}
					}
				})
				.catch((error: Error) => {
					// when fail by other issue
					ConsoleLog(["fail-B", targetId, fileActionName])

					self.handleResponse({
						actionName: fileActionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: "Something's wrong in viewModel or Model...",
						open: false,
					})
					// NOTE: call next item or finish this action
					if (carryOn) {
						self.sequentialMultipleUnSignOffs({
							...props,
							index: index + 1,
						})
					}
					if (lastItem) {
						self.responses.setResponse(actionName, {
							actionName,
							status: ActionStatus.standby,
						})
					}
				})
		} else {
			// When the fileString ID converting be fail
			ConsoleLog(["fail-C", fileActionName, fileNumId])
			self.handleResponse({
				actionName: fileActionName,
				status: ActionStatus.fail,
				code: 999,
				color: MessageColorType.orange,
				message: "Faild to convert the file Id to number",
				open: false,
			})
			// NOTE: call next item or finish this action
			if (carryOn) {
				self.sequentialMultipleUnSignOffs({
					...props,
					index: index + 1,
				})
			}
			if (lastItem) {
				self.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.standby,
				})
			}
		}
	},
})

export default UnSignOff
