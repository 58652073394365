import { ConsoleLog } from "../../../../../components/basic-elements"

export const GroupsCurrentPathActions = (self: any) => ({
	resetCurrentPath() {
		self.currentPath.length = 0
	},
	pushItemToCurrentPath(id: string) {
		// NOTE: Do not use this function on the tree nav.
		// This function cannot distingush the depth
		const pathLength = self.currentPath.length
		// if (item.depth === pathLength) {
		// } else if (item.depth > pathLength) {
		// } else if (item.depth < pathLength) {
		// }

		const itemIndex = self.currentPath.findIndex(
			(prevId: string) => prevId === id
		)
		if (itemIndex === -1) {
			// if the item does not exist on the path
			self.currentPath.push(id)
		} else {
			// if the itme exist on the path
			self.currentPath.splice(itemIndex + 1, pathLength)
		}
	},
	setCurrentPath(path: any) {
		self.currentPath.length = 0
		self.currentPath = path
	},
	spliceCurrentPathByIndex(index: number) {
		const pathLength = self.currentPath.length
		self.currentPath.splice(index + 1, pathLength)
	},
	spliceCurrentPathById(id: string) {
		const targetIndex = self.currentPath.findIndex(
			(item: any) => item.id === id
		)

		if (targetIndex !== -1) {
			self.currentPath.slice(0, targetIndex)
			ConsoleLog(["spliceCurrentPathById", id, self.currentPath])
		} else {
			ConsoleLog(
				"spliceCurrentPathById - matched item not exist on the path"
			)
		}
	},
	splicePathForSelection(id: string) {
		const targetIndex = self.pathForSelection.findIndex(
			(item: any) => item.id === id
		)
		ConsoleLog(["splicePathForSelection", id, self.pathForSelection])
		if (targetIndex !== -1) {
			self.pathForSelection.slice(0, targetIndex)
		} else {
			ConsoleLog(
				"splicePathForSelection - matched item not exist on the path"
			)
		}
	},
})

export const GroupsCurrentPathViews = (self: any) => ({
	get currentPathWithName() {
		const path = self.currentPath
		let newPath: { id: string; name: string }[] = []
		path.map((id: string) => {
			const item = {
				id,
				name: self.getNameById(id),
			}
			newPath.push(item)
		})
		return newPath
	},
	get viewCurrentPath() {
		let curPath: string[] = []
		self.currentPath.map((item: string) => {
			curPath.push(item)
		})
		return curPath
	},
})
