import { flow } from "mobx-state-tree"
import axios, { Method } from "axios"

export type ReadSignedUrlProps = {
	id: string
	fileType: string
	extension: string
}

const ReadSignedUrl = (self: any) => ({
	readSignedUrl: flow(function* ({
		id,
		fileType,
		extension,
	}: ReadSignedUrlProps) {
		let method: Method = "get"

		const postApiEndpoint =
			process.env.REACT_APP_CDK_RESTAPI_ENDPOINT + "post"
		const cdkRestApiKey = process.env.REACT_APP_CDK_RESTAPI_KEY

		const endpoint = postApiEndpoint + "/getSignedUrl" // apiInfo.DlBackendStack.PostApiEndpoint + "getSignedUrl"
		// NOTE: WARNING: DO NOT USE FILE NAME to KEY! UTF-8 characters seems not good for key...
		// const partialName = name.length > 10 ? name.slice(0, 10) : name
		// const key = `${partialName}-${id}.${extension}`
		// const key = `${id}.${extension}`
		const key = `${id}` // NOTE: Do not add extension. It makes hard to update

		var config = {
			method,
			url:
				endpoint +
				`?key=${key}&fileType=${fileType ? fileType : "others"}`, // &extension=${extension}`,

			// NOTE: File name is dangerous to use on s3 file name. Do not use it to make S3 key
			headers: {
				"x-api-key": cdkRestApiKey, // apiInfo.DlBackendStack.cdkRestApiKey,
			},
		}

		try {
			const response = yield axios(config)
			return response
		} catch (err) {
			console.log("err:", err)
			return false
		}
	}),
})

export default ReadSignedUrl
