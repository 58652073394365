import React from "react"
import { Link, useLocation } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import Drawer from "@material-ui/core/Drawer"
import ProjSidebarMenuList from "./ProjSidebarMenuList"
import Icon from "@mdi/react"
import { mdiChevronLeft, mdiChevronRight } from "@mdi/js"
import { DLIconButton } from "../../../components/basic-elements/buttons"
import StyledSidebar from "./sidebar.style"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { DLTooltip } from "@datalobby/components"
import SidebarDateDisplay from "./SidebarDateDisplay"
import keyboardJS from "keyboardjs"

export default observer(function Sidebar() {
	const store = useRootStore()
	const projStore = useProjStore()
	let location = useLocation()

	let orgTitle = localStorage.getItem("orgName")

	return (
		<StyledSidebar
			drawerWidth={store.ui.drawerWidth}
			collapsedDrawerWidth={store.ui.collapsedDrawerWidth}
			data-testid="proj-left-sidebar"
		>
			<Drawer
				variant="permanent"
				className={
					store.ui.isSidebarOpen
						? "drawer drawer-open proj-drawer indent"
						: "drawer drawer-close proj-drawer"
				}
				classes={{
					paper: store.ui.isSidebarOpen
						? "drawer drawer-open proj-drawer indent"
						: "drawer drawer-close proj-drawer",
				}}
				open={store.ui.isSidebarOpen}
			>
				<div
					className={
						store.ui.isSidebarOpen
							? `sidebar-header FR AC JSB`
							: `sidebar-header FR AC JC`
					}
				>
					<h1
						className={
							store.ui.isSidebarOpen
								? "company-title bold"
								: "company-title off"
						}
						data-testid="orgTitle-on-sidebar"
					>
						<DLTooltip
							title={
								orgTitle ? orgTitle : "Cannot read org name..."
							}
						>
							<Link
								to={{
									pathname: "/project/checkout",
									state: { from: location.pathname },
								}}
								onClick={() => keyboardJS.pause()}
							>
								{orgTitle
									? orgTitle.length > 16
										? orgTitle.slice(0, 16) + "..."
										: orgTitle
									: "Pending..."}
							</Link>
						</DLTooltip>
					</h1>

					<DLIconButton
						eleClassName="sidebar-on-off-button"
						variant="iconOnly"
						size="large"
						clickHandler={() =>
							store.ui.setSidebarOpen(!store.ui.isSidebarOpen)
						}
						eleTestId="left-sidebar on-off btn"
					>
						{store.ui.isSidebarOpen ? (
							<Icon path={mdiChevronLeft} size={1} />
						) : (
							<Icon path={mdiChevronRight} size={1} />
						)}
					</DLIconButton>
				</div>

				<SidebarDateDisplay
					tz={store.global.timeZone}
					tf={store.global.timeFormat.value}
					df={store.global.dateFormat.value}
					on={store.ui.isSidebarOpen}
				/>

				<ProjSidebarMenuList />
			</Drawer>
		</StyledSidebar>
	)
})
