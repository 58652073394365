import { flow } from "mobx-state-tree"
import { callGraphQL } from "@datalobby/common"
import { updatePost } from "../../../graphql/Post/mutations"
import { PostType } from "@datalobby/types/lib"

const UpdatePost = (self: any) => ({
	updatePost: flow(function* (post: any) {
		const actionName = "updatePost"
		try {
			const result = yield callGraphQL<any>(updatePost, { post })
			console.log(actionName, "result", result)
			return result
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default UpdatePost
