import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../components/basic-elements"
import { OrgGroupApiCallProps } from "./org-group-shared-apis.calling-props"

const DeleteGroupItem = (
	self: any,
	apiDelete: OrgGroupApiCallProps["apiDeleteGroupItem"]
) => ({
	deleteGroupItem: flow(function* (groupItemId: number) {
		const actionName = "deleteGroupItem"

		try {
			let params = {
				CabinetID: groupItemId, // NOTE: CabinetId also for the group-cabinet
				Type: "Group", // NOTE: same both group and group-cabinet
				// UserID: 16
			}
			ConsoleLog(["params", params])
			const response = yield apiDelete(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
	}),
})

export default DeleteGroupItem
