import { flow } from "mobx-state-tree"

const ReadFilters = (self: any, readFilterData: any) => ({
	readFilters: flow(function*(payload) {
		const actionName = "readFilters"
		try {
			// const endpoint = "/GetSearchAccessMngmntUsers"
			const response = yield readFilterData(payload)

			return response
		} catch (error) {
			// ConsoleLog("readFilteredUsers error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadFilters
