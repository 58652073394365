import { flow } from "mobx-state-tree"

const UpdateOrgTrash = (self: any, restore: any) => ({
	updateOrgTrashItem: flow(function*(payload) {
		const actionName = "updateOrgTrash"
		// ConsoleLog(payload)
		try {
			const response = yield restore(payload)
			// ConsoleLog("updateOrgTrashItem response", response)

			return response
		} catch (error) {
			// ConsoleLog("updateOrgTrashItem error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateOrgTrash
