import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import { hasEmptyProps } from "@datalobby/common"
import { ConsoleLog } from "../../../../components/basic-elements"

const AddClient = (self: any) => ({
	addClient(clientInfo: any) {
		// 0.
		const actionName = "addClient"
		// 1.
		const props = {
			aliasId: clientInfo.aliasId.value,
			name: clientInfo.name.value,
		}
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.createClient(checkedProps.props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// ConsoleLog(response.data)
					const reOrganizedClient = {
						clientId: idToString(response.data.ID, IdType.client), // [ID], ex) 10
						clientName: response.data.Name, // [Name], ex) "A"
						// userId: response.data.CreatedBy.toString(), // [UserID], ex) 10
						createdAt: response.data.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:35:40.140698-05:00"
						createdBy: response.data.CreatedUser, // [CreatedUser], ex) "AN"
						clientAliasId: response.data.ClientId,
					}
					self.pushItemToList(reOrganizedClient)
					self.setAddClientDialogOpen(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to add a new client",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						customMessage:
							response.data.Message ||
							response.data.message ||
							"Something's wrong...",
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	addMultipleClients(
		clients: { clientId: string; aliasId: string; name: string }[],
		index: number
	) {
		const actionName = "createMultipleClients"
		if (!self.pauseMultipleClients) {
			const target = clients[index]
			self.updateBulkClientStatus(
				"inprogress...",
				ActionStatus.loading,
				target.clientId
			)
			const clientInfo = {
				name: target.name.trim(),
				aliasId: target.aliasId.trim(),
			}
			self.createClient(clientInfo)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						ConsoleLog([
							index,
							"Success",
							target.aliasId,
							target.name,
						])
						self.updateBulkClientStatus(
							response.data.Message,
							ActionStatus.success,
							target.clientId,
							idToString(response.data.ID, IdType.client)
						)
						if (index !== clients.length - 1) {
							self.addMultipleClients(clients, index + 1)
						}
					} else {
						ConsoleLog([
							index,
							"Fail",
							target.aliasId,
							target.name,
							response.data.Message || response.data.message,
						])
						self.updateBulkClientStatus(
							response.data.Message,
							ActionStatus.fail,
							target.clientId
						)
						if (index !== clients.length - 1) {
							self.addMultipleClients(clients, index + 1)
						}
					}
					if (index === clients.length - 1) {
						const successClients = self.bulkClientList.filter(
							(client: any) =>
								client.status === ActionStatus.success
						)
						let newClients: any[] = JSON.parse(
							JSON.stringify(self.clientList)
						)
						successClients.map((item: any) => {
							newClients.push({
								clientId: item.clientId,
								clientName: item.name,
								createdAt: response.data.CreatedDate,
								createdBy: response.data.CreatedUser,
								clientAliasId: item.aliasId,
							})
						})
						self.setClientList(newClients)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							message: response.data.Message,
							customMessage:
								"Successfully created multiple clients",
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					ConsoleLog([
						index,
						"Fail_2",
						target.aliasId,
						target.name,
						error,
					])
					if (index !== clients.length - 1) {
						self.addMultipleClients(clients, index + 1)
					}
				})
			// WARNING: Do not place the next calling in the outside of the response status condition to take sequentiall process
		}
	},
})

export default AddClient
