import React from "react"
import { observer } from "mobx-react-lite"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	RestoreIcon,
} from "../../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../common-models/types/common-props"

export default observer(function OrgTrashCtxMenus({
	clickPoint,
	handleClose,
	permission,
	divider,
	handleOpenDialog,
	i18n,
}: {
	clickPoint: ClickPointProps
	handleClose: () => void
	permission: PermissionAsObjectProps
	divider: number[]
	handleOpenDialog: (type: "Restore" | "Delete") => void
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const options = (itemCount: number) => [
		{
			label:
				(i18n.twRestore || "Restore") +
				" " +
				(itemCount > 1 ? "(" + itemCount + ")" : ""),
			// label: i18n.twRestore | "Restore" + `${
			// 	itemCount > 1 ? "(" + itemCount + ")" : ""}`
			// ,
			value: "restore",
			available: permission.update,
			disabled: false, // TODO: check is there some condition to restrict restore
			clickEvent: () => handleOpenDialog("Restore"),
			icon: <RestoreIcon />,
		},
		{
			label:
				(i18n.twDelete || "Delete") +
				" " +
				(itemCount > 1 ? "(" + itemCount + ")" : ""),
			// label: `${i18n.twDelete | "Delete"} ${
			// 	itemCount > 1 ? "(" + itemCount + ")" : ""
			// }`,
			value: "permanentDelete",
			available: permission.delete,
			disabled: false, // TODO: check is there some condition to restrict restore
			clickEvent: () => handleOpenDialog("Delete"),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	const itemCount = orgStore.trash.selectedItems.length
	return (
		<DLContextMenu
			eleId="org-trash-ctx-menus"
			clickPoint={{
				mouseX: clickPoint.mouseX,
				mouseY: clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={options(itemCount)}
			hasDivider={divider}
		/>
	)
})
