import React from "react"
import styled from "styled-components"

export const StyledHeader = styled.div`
	.grow {
		flex-grow: 1;
	}
	.app-bar {
		box-shadow: none;
	}
	.toolbar {
		min-height: var(--standard-unit);
		height: var(--standard-unit);
		background-color: ${(props) => props.theme.background};
		color: ${(props) => props.theme.textHighEmphasis};
		padding-left: var(--spacing-md);
		padding-right: var(--spacing-md);
		.header-btn {
			color: ${(props) => props.theme.textMediumEmphasis};
			cursor: pointer
      		:hover {
				color: ${(props) => props.theme.textHighEmphasis};
			}
			&.help-info-btn {
				margin-left: -3px;
				/* back to org list button is wider than others.. so just for visual align... */
			}
		}
		.profile-btn {
			.role-avatar {
				width: 26px;
				height: 26px;
				right: -6px;
				font-size: 12px;
				background-color: transparent;
			}
			.user-avatar {
				width: 26px;
				height: 26px;
				background-color: lightgray;
				transition: 0.3s;
				cursor: pointer;
				:hover {
					background-color: gray;
				}
			}
		}
	}
	.section-desktop {
		display: none;
		@media (min-width: 960px) {
			display: flex;
			align-items: center;
		}
	}
	.section-mobile {
		display: flex;
		@media (min-width: 960px) {
			display: none;
		}
	}
`
