import { flow } from "mobx-state-tree"
import { OrgSysSettingApiTypes } from "../../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"
import { OrgSysSettingItemProps } from "../../../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import { EditDntSettingProps } from "../view-model-actions/edit-date-and-time-setting"
import { restoreTF } from "../view-model-actions/get-date-and-time-setting"

const UpdateDateAndTimeSetting = (
	self: any,
	update: OrgSysSettingApiTypes["apiUpdate"]
) => ({
	updateDateAndTimeSetting: flow(function*({
		dateFormat,
		timeFormat,
		timeZone,
	}: EditDntSettingProps) {
		const actionName = "updateDateAndTimeSetting"

		let payload = [
			{
				ParameterName: "Date Format",
				SettingID: parseInt(dateFormat.settingId),
				ParameterDisplay: dateFormat.value,
				ParameterValue: dateFormat.value,
				ParameterValueUI: dateFormat.uiValue,
			},
			{
				ParameterName: "Time Format",
				SettingID: parseInt(timeFormat.settingId),
				ParameterDisplay: restoreTF(timeFormat.uiValue),
				ParameterValue: timeFormat.value,
				ParameterValueUI: restoreTF(timeFormat.uiValue),
			},
			{
				ParameterName: "Time Zone",
				SettingID: parseInt(timeZone.settingId),
				ParameterDisplay: timeZone.label,
				ParameterValue: timeZone.value,
				ParameterValueUI: timeZone.uiValue,
			},
		]
		try {
			const response = yield update({
				payload,
			})

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateDateAndTimeSetting
