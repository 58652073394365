export function checkPropExistence(actionName: string, props: any[]) {
	if (props.includes("")) {
		alert(`(${actionName}) Empty prop exist. ${props}`)
		return false
	} else {
		for (let i = 0; i < props.length; i++) {
			if (props[i] === undefined) {
				alert(`(${actionName}) Undefined prop exist. ${props}`)
				return false
			}
		}
	}
}
