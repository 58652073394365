import { types } from "mobx-state-tree"
import ChecklistModel from "./checklist.model"
import ChecklistViewModel from "./checklist.view-model"
import {
	readChecklists,
	addChecklist,
	ReportsMgmtApiProps,
} from "../../../../organization-side/reports-management(Deprecated)/online-projects/store/reports-management.apis"
import {
	OrgEngTypeApiProps,
	readChecklistTemplates,
} from "../../../../organization-side/org-setup/org-setup-eng-types/store/org-eng-types.apis"

export const ChecklistStore = ({
	apiReadChecklists,
	apiAddChecklist,
	apiReadChecklistTemplates,
}: {
	apiReadChecklists: ReportsMgmtApiProps["apiReadChecklists"]
	apiAddChecklist: ReportsMgmtApiProps["apiAddChecklist"]
	apiReadChecklistTemplates: OrgEngTypeApiProps["apiReadChecklistTemplates"]
}) =>
	types
		.compose(
			ChecklistModel({
				apiReadChecklists,
				apiAddChecklist,
				apiReadChecklistTemplates,
			}),
			ChecklistViewModel
		)
		.named("ChecklistStore")

export const initialStore = {
	checklistDetail: null,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ChecklistStoreInstance = ChecklistStore({
	apiReadChecklists: readChecklists,
	apiReadChecklistTemplates: readChecklistTemplates,
	apiAddChecklist: addChecklist,
}).create(initialStore)

export default ChecklistStoreInstance
