import { flow } from "mobx-state-tree"

const ReadGroupUsers = (self: any, readGroupUsers: any) => ({
	readGroupUsers: flow(function*(params) {
		const actionName = "readGroupUsers"
		try {
			const response = yield readGroupUsers(params)

			// ConsoleLog("readGroupUserList response", response)
			return response
		} catch (error) {
			console.error("readGroupUserList error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadGroupUsers
