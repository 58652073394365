import React, { useEffect, useState } from "react"
import Drawer from "@material-ui/core/Drawer"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLI18nProps } from "../../../common-models/types/common-props"
import { Avatar } from "@material-ui/core"
import Icon from "@mdi/react"
import {
	mdiArrowDown,
	mdiArrowLeft,
	mdiArrowRight,
	mdiArrowUp,
	mdiPalette,
} from "@mdi/js"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { DLProjMenuGroups } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useLocation } from "react-router-dom"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { DLRadioGroup } from "../../basic-elements"
import { DLTooltip } from "@datalobby/components"
import orgMenuIconList from "../../../temporary-data/org-side/default-org-menu-list/org-menu-icon-list"
import projMenuIconList from "../../../temporary-data/project-side/default-proj-menu-list/proj-menu-icon-list"
import {
	changeThemeSetting,
	THEME_SELECTS,
} from "../../../screens/organization-side/my-page/my-information/MyInformation"
import { StyledGlobalRightSideDrawer } from "../global-drawer/StyledGlobalRightSideDrawer"
import { userguideUrl } from "../../../assets/datalobby-info"

export default observer(function HelpInformationDrawer() {
	const store = useRootStore()

	const i18n = store.i18n.globalI18n()
	return (
		<Drawer
			anchor="right"
			open={store.ui.isHelpInfoDrawerOpen}
			onClose={() =>
				store.ui.setHelpInfoDrawerOpen(!store.ui.isHelpInfoDrawerOpen)
			}
		>
			<div style={{ width: 345 }}>
				{store.ui.isHelpInfoDrawerOpen && (
					<HelpInformation i18n={i18n} />
				)}
			</div>
		</Drawer>
	)
})

const HelpInformation = ({ i18n }: { i18n: DLI18nProps }) => {
	const store = useRootStore()
	let location = useLocation()
	const currentSection = location.pathname.split("/")

	const [currentTab, setCurrentTab] = useState<any>(DLProjMenuGroups.info)

	const handleSelectTab = (tab: any) => {
		setCurrentTab(tab)
	}

	const [theme, setTheme] = useState(store.ui.theme)

	const changeThemeHandler = (event: any) => {
		setTheme(event.target.value)
		store.ui.setTheme(event.target.value)
		changeThemeSetting(event.target.value)
	}

	return (
		<StyledGlobalRightSideDrawer>
			<div className="FR">
				<div className="help-menu-tabs FC AC JSB">
					<div>
						<div
							className={`tab FR AC JC`}
							style={{ height: "3rem" }}
						></div>
						<>
							{currentSection[1] === "organization" ? (
								<OrgMenus
									currentTab={currentTab}
									handleSelectTab={handleSelectTab}
								/>
							) : (
								<ProjectMenus
									currentTab={currentTab}
									handleSelectTab={handleSelectTab}
								/>
							)}
						</>
					</div>
					<div
						className={`tab FR AC JC ${
							currentTab === "theme-setting" && "selected"
						}`}
						data-testid={`tab-theme-setting`}
						key={"theme-setting"}
						onClick={() => handleSelectTab("theme-setting")}
					>
						<DLTooltip
							title="Theme Setting"
							elePlacement="right"
							arrow
						>
							<div>
								<Icon path={mdiPalette} size={1} />
							</div>
						</DLTooltip>
					</div>
				</div>
				<div className="content-area">
					<div className="content-header FR JSB AC">
						<h3>{i18n.twHelpInformation}</h3>
						<div className="userguide-link">
							Go to{" "}
							<a href={userguideUrl} target="blank">
								User Guide
							</a>
						</div>
					</div>
					<div className="content-body">
						{currentTab === DLProjMenuGroups.files && (
							<>
								<div className="section">
									<SectionHeader title="Abbreviations" />
									<div className="abbreviation section-body">
										<div className="FR AC">
											<span className="abbr">WP</span> :{" "}
											{i18n.twWP || "Workpaper Files"}
										</div>

										<div className="FR AC">
											<span className="abbr">PF</span> :{" "}
											{i18n.twPF || "Permanent Files"}
										</div>

										<div className="FR AC">
											<span className="abbr">FS</span> :{" "}
											{i18n.twFS ||
												"Financial Statements"}
										</div>

										<div className="FR AC">
											<span className="abbr">AJE</span> :{" "}
											{i18n.twAJE || "Adjustments"}
										</div>

										<div className="FR AC">
											<span className="abbr">PAJE</span> :{" "}
											{i18n.twPAJE ||
												"Passed Adjustments"}
										</div>
										<div className="FR AC">
											<span className="abbr">EP</span> :{" "}
											{i18n.twEngagementPartner ||
												"Engagement Partner"}
										</div>
										<div className="FR AC">
											<span className="abbr">CP</span> :{" "}
											{i18n.twConcurringPartner ||
												"Concurring Partner"}
										</div>
										<div className="FR AC">
											<span className="abbr">QC</span> :{" "}
											{i18n.twQualityControl ||
												"Quality Control"}
										</div>
									</div>
								</div>
								<div className="section shortcuts">
									<SectionHeader title="Shortcuts" />
									<table
										style={{ width: "100%" }}
										className="section-body"
									>
										<tbody>
											{/* <tr>
												<th>Description</th>
												<th>Shortcut</th>
											</tr> */}
											<tr>
												<td>Folder or File select</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														<Icon
															path={mdiArrowDown}
															size={0.6}
														/>
													</Avatar>
													<span className="connector">
														,
													</span>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														<Icon
															path={mdiArrowUp}
															size={0.6}
														/>
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Multiple select</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														Shift
													</Avatar>
													<span className="connector">
														+
													</span>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														<Icon
															path={mdiArrowDown}
															size={0.6}
														/>
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Folder open, collapse</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														<Icon
															path={mdiArrowRight}
															size={0.6}
														/>
													</Avatar>
													<span className="connector">
														,
													</span>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														<Icon
															path={mdiArrowLeft}
															size={0.6}
														/>
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Cancel select all</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														esc
													</Avatar>
												</td>
											</tr>
											<div className="section-divider" />
											<tr>
												<td>Prepare</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														alt
													</Avatar>
													<span className="connector">
														+
													</span>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														1
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Review</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														alt
													</Avatar>
													<span className="connector">
														+
													</span>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														2
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Show context menu</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon non-square"
													>
														alt
													</Avatar>
													<span className="connector">
														+
													</span>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														~
													</Avatar>
												</td>
											</tr>
											<div className="section-divider" />
											<tr>
												<td>Open file</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														O
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>
													Edit folder or file info
												</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														E
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Add files</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														N
													</Avatar>
												</td>
											</tr>
											<tr>
												<td>Delete folder or files</td>
												<td>
													<Avatar
														variant="square"
														className="keyboard-icon"
													>
														D
													</Avatar>
												</td>
											</tr>
										</tbody>
									</table>
								</div>
								<div className="section">
									<SectionHeader title="Sign Off" />
									<div className="signoff-container section-body">
										<p>
											1. Red color change in signoffs
											indicate the other user's signoff on
											the workpaper was updated after the
											signoffs.
										</p>
										<p>
											2. (*) Indicate the workpaper was
											updated after the signoffs.
										</p>
									</div>
								</div>
							</>
						)}
						{currentTab === DLProjMenuGroups.pbc && (
							<div>Blah Blah</div>
						)}
						{currentTab === "theme-setting" && (
							<div className="theme-setting">
								<DLRadioGroup
									// eleTestId="select-theme-radio-group"
									itemDirection="column"
									eleClassName="radio-group"
									color="default"
									// formTitle="Select Theme"
									selectedValue={theme}
									items={THEME_SELECTS}
									eleOnChange={changeThemeHandler}
								/>
							</div>
						)}
					</div>
				</div>
			</div>
		</StyledGlobalRightSideDrawer>
	)
}

const SectionHeader = ({ title }: { title: string }) => {
	return (
		<div className="section-header FR AC JR">
			<div
				className="line-container FC"
				style={{
					width: "100%",
					paddingRight: "0.6rem",
				}}
			>
				<div
					className="line"
					style={{
						height: 1,
						backgroundColor: "lightgray",
						width: "100%",
						marginRight: "0.4rem",
					}}
				></div>
			</div>
			<span style={{ whiteSpace: "nowrap" }}>{title}</span>
		</div>
	)
}

const ProjectMenus = ({
	currentTab,
	handleSelectTab,
}: {
	currentTab: string
	handleSelectTab: any
}) => {
	const projStore = useProjStore()

	const renderIcon = (menuId: string) => {
		return projMenuIconList.find((element) => element?.menuId === menuId)
			?.icon
	}

	useEffect(() => {
		const currentMenu = projStore.checkin.accessibleFlatMenus.find(
			(item: any) => item.id === projStore.menuTabs.currentMainMenu
		)?.menuGroup
		if (currentMenu) {
			handleSelectTab(currentMenu)
		}
	}, [])

	return (
		<>
			{projStore.checkin.accessibleTreeMenus.map((tab: any) => {
				return (
					<div
						className={`tab FR AC JC ${
							currentTab === tab.id && "selected"
						}`}
						data-testid={`tab-${tab.id}`}
						key={tab.id}
						onClick={() => handleSelectTab(tab.id)}
					>
						<DLTooltip title={tab.title} elePlacement="right" arrow>
							<div>{renderIcon(tab.id)}</div>
						</DLTooltip>
					</div>
				)
			})}
		</>
	)
}

const OrgMenus = ({
	currentTab,
	handleSelectTab,
}: {
	currentTab: string
	handleSelectTab: any
}) => {
	const orgStore = useOrgStore()
	let location = useLocation()

	const renderIcon = (menuId: string) => {
		return orgMenuIconList.find((element) => element?.menuId === menuId)
			?.icon
	}

	useEffect(() => {
		const currentMenu = orgStore.checkin.accessibleTreeMenus.find(
			(item: any) => item.url === location.pathname
		)?.id
		if (currentMenu) {
			handleSelectTab(currentMenu)
		}
	}, [])

	return (
		<>
			{orgStore.checkin.accessibleTreeMenus.map((tab: any) => {
				return (
					<div
						className={`tab FR AC JC ${
							currentTab === tab.id && "selected"
						}`}
						data-testid={`tab-${tab.id}`}
						key={tab.id}
						onClick={() => handleSelectTab(tab.id)}
					>
						<DLTooltip title={tab.title} elePlacement="right" arrow>
							<div>{renderIcon(tab.id)}</div>
						</DLTooltip>
					</div>
				)
			})}
		</>
	)
}
