import { types } from "mobx-state-tree"
import NotificationSettingsModel from "./notification-settings.model"
import NotificationSettingsViewModel from "./notification-settings.view-model"
import { ProjNotiInitialInputs } from "./data-models/notification-settings.data-models"
import * as api from "./notification-settings.apis"
import { NotificationSettingsApiProps } from "./notification-settings.apis"

export const NotificationSettingsStore = ({
	apiRead,
	apiUpdate,
}: NotificationSettingsApiProps) =>
	types
		.compose(
			NotificationSettingsModel({ apiRead, apiUpdate }),
			NotificationSettingsViewModel
		)
		.named("NotificationSettingsStore")

export const initialStore = {
	notificationSettingsData: [],
	commentNoti: [],
	expReportDateNoti: ProjNotiInitialInputs,
	expArchDeadlineDateNoti: ProjNotiInitialInputs,
	finalArchDeadlineDateNoti: ProjNotiInitialInputs,
	projInfoAndModifiedDetails: {
		isLocked: false,
		archPolicyPeriod: "",
		archPolicyPeriodValue: "",
		expectedArchiveDeadlineDate: "",
		expectedReportDate: "",
		finalArchiveDeadlineDate: "",
		finalReportDate: "",
		modifiedBy: "",
		modifiedDate: "",
		modifiedUser: "",
		roleName: "",
		userName: "",
	},
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const NotificationSettingsStoreInstance = NotificationSettingsStore({
	apiRead: api.read,
	apiUpdate: api.update,
}).create(initialStore)

export default NotificationSettingsStoreInstance
