import React from "react"
import { observer } from "mobx-react-lite"

import { DLSnackbar } from "../../../../components/basic-elements"
import { useLobbyStore } from "../../../../stores/lobby-store/lobby-store.provider"

const OrgMgmtSnackbar = observer(() => {
	const lobbyStore = useLobbyStore()

	return (
		<DLSnackbar
			type={lobbyStore.orgMgmt.responseSnackbar.type}
			open={lobbyStore.orgMgmt.responseSnackbar.open}
			setOpen={lobbyStore.orgMgmt.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={lobbyStore.orgMgmt.responseSnackbar.message}
			autoHideDuration={
				lobbyStore.orgMgmt.responseSnackbar.autoHideDuration
			}
			autoHide={lobbyStore.orgMgmt.responseSnackbar.autoHide}
		/>
	)
})

export default OrgMgmtSnackbar
