import React from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLSnackbar } from "../../../components/basic-elements"
import { useLobbyStore } from "../../../stores/lobby-store/lobby-store.provider"

export default observer(function OrgListSnackbar() {
	const store = useRootStore()
	const lobbyStore = useLobbyStore()

	return (
		<DLSnackbar
			type={lobbyStore.orgList.responseSnackbar.type}
			open={lobbyStore.orgList.responseSnackbar.open}
			setOpen={lobbyStore.orgList.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={lobbyStore.orgList.responseSnackbar.message}
			autoHideDuration={
				lobbyStore.orgList.responseSnackbar.autoHideDuration
			}
			autoHide={lobbyStore.orgList.responseSnackbar.autoHide}
		/>
	)
})
