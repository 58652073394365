import React from "react"
import Icon from "@mdi/react"
import { mdiArrowDown } from "@mdi/js"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"
import { DLI18nTemp } from "../i18n/DLI18n"

interface IsortingiconheaderProps {
	title?: string
	align?: string
}

const SortingIconHeader = (props: IsortingiconheaderProps) => {
	const { title, align } = props
	const testId = title?.toLowerCase().replace(/ /, "-")
	return (
		<DLTooltip title="use shift key for mulitple sorting" fontSize="small">
			<StyledContainer
				className="FR AC JSB"
				data-testid={`sorting-column-header-${testId}`}
			>
				<DLI18nTemp
					value={title ? title : "?"}
					defaultValue={title ? title : "?"}
					eleClassName={align ? align + " title" : "title"}
				/>
				<Icon path={mdiArrowDown} size={0.6} className="sorting-icon" />
			</StyledContainer>
		</DLTooltip>
	)
}

export default SortingIconHeader

const StyledContainer = styled.div`
	.title {
		width: 100%;
		&.left {
			text-align: left;
		}
		&.center {
			text-align: center;
		}
	}
`
