export const UpdateProjInfoActions = (self: any) => ({
	setProjInfo(projInfo: any) {
		self.projInfo = projInfo
	},
	setPeriodList(list: any) {
		self.periodNames.length = 0
		self.periodNames = list
	},
	setClientList(clients: any) {
		self.clientList.length = 0
		self.clientList = clients
	},
	setEngTypeList(items: any) {
		self.engTypeList.length = 0
		self.engTypeList = items
	},
	setRiskAssessmentList(items: any) {
		self.riskAssessmentList.length = 0
		self.riskAssessmentList = items
	},
	setArchPolicyPeriodList(items: any) {
		self.archPolicyPeriodList.length = 0
		self.archPolicyPeriodList = items
	},
})

export const UpdateProjInfoViews = (self: any) => ({
	viewEngTypeOptions() {
		let options: { id: string | number; title: string }[] = []
		self.engTypeList.map((engType: any) => {
			options.push({
				id: engType.id,
				title: engType.name,
			})
		})
		return options
	},
	viewRiskAssessmentOptions() {
		let options: { id: string | number; title: string }[] = []
		self.riskAssessmentList.map((risk: any) => {
			options.push({
				id: risk.riskAssessmentId,
				title: risk.riskAssessmentName,
			})
		})
		return options
	},
	viewPeriodNames() {
		let options: { id: string; title: string }[] = []
		self.periodNames.map((period: any) => {
			options.push({
				id: period.id,
				title: period.title,
			})
		})
		return options
	},
	viewClientsList() {
		let options: {
			id: string
			title: string
			clientAliasId: string
		}[] = []
		self.clientList.map((client: any) => {
			options.push({
				id: client.clientId,
				title: client.clientName,
				clientAliasId: client.clientAliasId,
			})
		})
		// ConsoleLog("viewClients", options)
		return options
	},
	viewArchPolicyPeriodList() {
		let options: { value: string; name: string }[] = []
		self.archPolicyPeriodList.map((period: any) => {
			options.push({
				value: period.id,
				name: period.title,
			})
		})
		return options
	},
	getArchiveDays(id: string) {
		return self.archPolicyPeriodList.find((period: any) => period.id === id)
			?.days
	},
})
