import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PageContainer } from "../../../../components/app-frame-elements"
import {
	DLButton,
	DLSingleSelect,
	DLSpinner,
	InputWithLabel,
	DLExpansionPanelSingle,
} from "../../../../components/basic-elements"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiChevronUp, mdiChevronDown } from "@mdi/js"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"

export default observer(function FinancialStatement({
	partialStore,
	contentsHeight,
	fetchingStatus,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
}) {
	const [entity, selectEntity] = useState(-1)
	const [year, selectYear] = useState(0)

	const handleSelect = (value: any) => {}

	useEffect(() => {
		partialStore.getFinancialPresentation(entity, year)
	}, [])

	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			{fetchingStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentsHeight }}
				>
					<DLSpinner />
				</div>
			)}
			{fetchingStatus === ActionStatus.success && (
				<StyledFinancialStatement>
					<div className="FR JSA">
						<InputWithLabel label="Select Entity">
							<DLSingleSelect
								eleValue={entity}
								eleOnChange={(e: any) =>
									handleSelect(e.target.value)
								}
								optionList={partialStore.viewTBDetails()}
								eleTestId="search-entity"
								eleClassName="search-entity"
							/>
						</InputWithLabel>
						<InputWithLabel label="Select Year">
							<DLSingleSelect
								eleValue={year}
								eleOnChange={(e: any) =>
									handleSelect(e.target.value)
								}
								optionList={[
									{ name: "Current Year", value: 1 },
									{ name: "Previous Year", value: 2 },
									{ name: "Previous Year 1", value: 3 },
									{ name: "All", value: 0 },
								]}
								eleTestId="search-year"
								eleClassName="search-year"
							/>
						</InputWithLabel>
						<DLButton
							eleTestId="search"
							// clickHandler={handleAction}
							color="primary"
						>
							Submit
						</DLButton>
					</div>
					<DLExpansionPanelSingle
						eleTestId="fs-balance-sheet-expansion"
						eleClassName="list-title"
						title={<h4>Balance Sheet</h4>}
						defaultExpanded
					>
						<div
							data-testid="balance-sheet-table"
							className="table"
						>
							<ReactTableV8
								tableColumns={Columns()}
								data={partialStore.viewFSList()}
								menuId={DLProjSubMenus.fs_fs}
								showPageSetting={false}
								groupBy={["grandParentName", "parentName"]}
							/>
						</div>
					</DLExpansionPanelSingle>
					<div style={{ height: "1rem" }} />
					<DLExpansionPanelSingle
						eleTestId="fs-income-statement-expansion"
						eleClassName="list-title"
						title={<h4>Income Statement</h4>}
						defaultExpanded
					>
						<div data-testid="mandotary-list"></div>
					</DLExpansionPanelSingle>
				</StyledFinancialStatement>
			)}
		</PageContainer>
	)
})

const StyledFinancialStatement = styled.div`
	.MuiInput-formControl {
		margin-top: 0px !important;
	}
	.table {
		width: 100%;
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`

export const Columns = () => [
	{
		header: "",
		accessorKey: "grandParentName",
		cell: ({ row, getValue }: any) => {
			if (!row.getIsGrouped() || row.groupingColumnId === "parentName") {
				return ""
			}
			const expanded = row.getIsExpanded()
			const name = getValue()
			return (
				<div className="FR AC" onClick={row.getToggleExpandedHandler()}>
					{expanded ? (
						<Icon
							path={mdiChevronUp}
							size={0.8}
							data-testid="folder-chevron-up"
						/>
					) : (
						<Icon
							path={mdiChevronDown}
							size={0.8}
							data-testid="folder-chevron-down"
						/>
					)}
					<span>
						<b>{name}</b>
					</span>
				</div>
			)
		},
	},
	{
		header: "",
		accessorKey: "parentName",
		cell: ({ row, getValue }: any) => {
			if (
				!row.getIsGrouped() ||
				row.groupingColumnId === "grandParentName"
			) {
				return ""
			}
			const expanded = row.getIsExpanded()
			const name = getValue()
			return (
				<div className="FR AC" onClick={row.getToggleExpandedHandler()}>
					{expanded ? (
						<Icon
							path={mdiChevronUp}
							size={0.8}
							data-testid="folder-chevron-up"
						/>
					) : (
						<Icon
							path={mdiChevronDown}
							size={0.8}
							data-testid="folder-chevron-down"
						/>
					)}
					<span>
						<b>{name}</b>
					</span>
				</div>
			)
		},
	},
	{
		header: "Name",
		accessorKey: "name",
	},
	{
		header: "Current Year",
		accessorFn: (d: any) => d.consolidated.currentYear,
		cell: ({ getValue }: any) => {
			return (
				<div data-testid={`file-title-${getValue()}`}>{getValue()}</div>
			)
		},
	},
	{
		header: "Previous Year",
		accessorFn: (d: any) => d.consolidated.previousYear,
		cell: ({ getValue }: any) => {
			return (
				<div data-testid={`file-title-${getValue()}`}>{getValue()}</div>
			)
		},
	},
	{
		header: "Previous Year 1",
		accessorFn: (d: any) => d.consolidated.previousYear1,
		cell: ({ getValue }: any) => {
			return (
				<div data-testid={`file-title-${getValue()}`}>{getValue()}</div>
			)
		},
	},
]
