import React from "react"
import { observer } from "mobx-react-lite"
import { DLIconButton } from "../../../../components/basic-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLCheckbox } from "@datalobby/components/lib"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { dateRangeFilter } from "../../../../library/react-table-utils"
import { DLI18nTemp } from "../../../../components/basic-elements/i18n/DLI18n"
import { Icon } from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { Column, Table } from "@tanstack/react-table"
import {
	DLIcon,
	DLIconName,
	RestoreIcon,
} from "../../../../components/basic-elements/icons/common-icons"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import { PageHeader } from "../../../../components/app-frame-elements"

const OrgTrashTable = observer(
	({
		partialStore,
		contentsHeight,
		handleCheckbox,
		handleRightClick,
		handleOpenDialog,
		i18n,
	}: {
		partialStore: any
		contentsHeight: any
		handleCheckbox: (id: string) => void
		handleRightClick: (event: any, targetId: string) => void
		handleOpenDialog: any
		i18n: DLI18nProps
	}) => {
		const store = useRootStore()
		const menuId = DLOrgSubMenus.file_trash
		const isSelected = (id: string) =>
			partialStore.selectedItems.includes(id)
		const timeZone = store.global.timeZone.uiValue
		const dateFormat = store.global.dateFormat.value
		const selectedCount = partialStore.selectedItems.length
		const totalCount = partialStore.orgTrashList.length

		const pageHeader = () => {
			return (
				<PageHeader>
					<div className="FR AC JSB">
						<div className="FR AC counter-container">
							<BasicCounter
								label="Total Trash Item:"
								value={totalCount}
							/>
							{selectedCount > 0 && (
								<div
									data-testid="multiple-actions"
									className="FR AC"
								>
									<span className="partition">|</span>
									<div>selected items: </div>
									<div className="selected-items">
										<span
											className="selected-item"
											data-testid="selected-count"
										>
											{selectedCount}
										</span>
									</div>
									<div className="action-btns FR AC">
										<DLIconButton
											eleTestId="restore-btn"
											tooltipText="Restore"
											clickHandler={() =>
												handleOpenDialog("Restore")
											}
										>
											<RestoreIcon />
										</DLIconButton>
										<DLIconButton
											eleTestId="permanent-delete-btn"
											tooltipText="Permanent Delete"
											clickHandler={() =>
												handleOpenDialog("Delete")
											}
										>
											<DLIcon name={DLIconName.delete} />
										</DLIconButton>
									</div>
								</div>
							)}
						</div>
					</div>
				</PageHeader>
			)
		}

		const handleTypeChange = (type: string) => {
			partialStore.getOrgTrashList(type)
		}
		return (
			<ReactTableV8
				tableColumns={OrgTrashColumns(
					handleCheckbox,
					handleRightClick,
					isSelected,
					i18n
				)}
				data={partialStore.formattedOrgTrashList(dateFormat, timeZone)}
				defaultFilters={[{ id: "type", value: "GROUP" }]}
				pageHeader={pageHeader}
				hasPagination={true}
				customFilters={(props: any) =>
					filterComponent({ ...props, dateFormat, handleTypeChange })
				}
				handleContextMenu={(e: any, rowInfo: any) =>
					handleRightClick(e, rowInfo.id)
				}
				height={store.ui.contentsAreaHeight}
				i18n={i18n}
				menuId={menuId}
			/>
		)
	}
)

export default OrgTrashTable

const filterComponent = ({
	column,
	table,
	dateFormat,
	handleTypeChange,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
	handleTypeChange: any
}) => {
	switch (column.id) {
		case "type":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
						handleTypeChange(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "GROUP") as string[]}
					data-testid="user-type-select"
				>
					<option value="GROUP">Group</option>
					<option value="CABINET">Cabinet</option>
					<option value="PROJECT">Project</option>
					<option value="PROJ_TEMPLATE_GROUP">
						Project Template Group
					</option>
					<option value="PROJ_TEMPLATE">Project Template</option>
					<option value="PROJ_TEMPLATE_FOLDER">
						Project Template Folder
					</option>
					<option value="PROJ_TEMPLATE_FILE">
						Project Template File
					</option>
					<option value="RISK_ASMT">Risk Assessment</option>
				</select>
			)

		case "deletedAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return (
		<DLI18nTemp
			eleTestId={cellId}
			value={props.getValue()}
			defaultValue={props.getValue()}
		/>
	)
}

const TrashTypes: any = {
	GROUP: "Group",
	CABINET: "Cabinet",
	PROJECT: "Project",
	PROJ_TEMPLATE_GROUP: "TemplateParentFolder",
	PROJ_TEMPLATE: "Template",
	PROJ_TEMPLATE_FOLDER: "TemplateFolder",
	PROJ_TEMPLATE_FILE: "TemplateFile",
	// FILE_LIB_GROUP: "WorkpaperTemplateParentFolder",
	// FILE_LIB: "WorkpaperTemplate",
	// FILE_LIB_FILE: "workpapertempfiles",
	RISK_ASMT: "RiskAssessment",
}

const OrgTrashColumns = (
	handleCheckbox: (id: string) => void,
	handleRightClick: (event: any, targetId: string) => void,
	isSelected: (id: string) => boolean,
	// handleToggleAll: () => void,
	i18n: any // TODO: If it is possible, update
) => [
	{
		header: "",
		accessorKey: "id",
		enableColumnFilter: false,
		size: 50,
		cell: (props: any) => {
			const id = props.getValue()
			const handleClick = () => {
				handleCheckbox(id)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={() => handleClick()}
					color="primary"
					isChecked={isSelected(id)}
				/>
			)
		},
	},
	{
		header: i18n.twType,
		accessorKey: "type",
		filterFn: (row: any, columnId: any, value: any) => {
			return row.getValue(columnId) === TrashTypes[value]
		},
	},
	{
		header: i18n.twTitle,
		accessorKey: "name",
		cell: renderCustomCell,
	},
	{
		header: i18n.twDeletedBy,
		accessorKey: "deletedBy",
		cell: renderCustomCell,
	},
	{
		header: i18n.twDeletedAt,
		accessorKey: "deletedAt",
		filterFn: dateRangeFilter,
		// cell: renderCustomCell
	},
	{
		header: "Action",
		// accessorKey: "",
		size: 60,
		cell: (props: any) => {
			const handleClick = (event: any) => {
				handleRightClick(event, props.row.original.id)
			}

			return (
				<div className="FR JC">
					<DLIconButton
						variant="iconOnly"
						eleTestId="trash-list-context-btn"
						clickHandler={handleClick}
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
