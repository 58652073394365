import { types, flow } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import {
	CreateRiskAssessment,
	ReadRiskAssessmentList,
	UpdateRiskAssessment,
	DeleteRiskAssessment,
} from "./model-actions"
import { OrgRiskAssessmentApiProps } from "./org-risk-assessment.apis"

const OrgRiskAssessmentModel = ({
	apiCreate,
	apiRead,
	apiUpdate,
	apiDelete,
}: OrgRiskAssessmentApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => CreateRiskAssessment(self, apiCreate))
		.actions((self) => ReadRiskAssessmentList(self, apiRead))
		.actions((self) => UpdateRiskAssessment(self, apiUpdate))
		.actions((self) => DeleteRiskAssessment(self, apiDelete))
		// common model actions
		.actions(CommonModelActions)

export default OrgRiskAssessmentModel
