import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { OrgSysSettingItemProps } from "../../../../../../../service-modules/sys-setting-module/sys-setting.data-model"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const EditEmailNotificationSetting = (self: any) => ({
	editEmailNotificationSetting({
		atTheEndOfDay,
		everyOtherDay,
		atTheEndOfWeek,
		every3Days,
		daily,
	}: {
		atTheEndOfDay: OrgSysSettingItemProps
		everyOtherDay: OrgSysSettingItemProps
		atTheEndOfWeek: OrgSysSettingItemProps
		every3Days: OrgSysSettingItemProps
		daily: OrgSysSettingItemProps
	}) {
		// 0.
		const actionName = "editEmailNotificationSetting"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const defaultPayload = [
			{
				ParameterName: atTheEndOfDay.label,
				SettingID: atTheEndOfDay.settingId,
				ParameterDisplay: atTheEndOfDay.uiValue,
				ParameterValue: atTheEndOfDay.value,
				ParameterValueUI: atTheEndOfDay.uiValue,
			},
			{
				ParameterName: everyOtherDay.label,
				SettingID: everyOtherDay.settingId,
				ParameterDisplay: everyOtherDay.uiValue,
				ParameterValue: everyOtherDay.value,
				ParameterValueUI: everyOtherDay.uiValue,
			},
			{
				ParameterName: atTheEndOfWeek.label,
				SettingID: atTheEndOfWeek.settingId,
				ParameterDisplay: atTheEndOfWeek.uiValue,
				ParameterValue: atTheEndOfWeek.value,
				ParameterValueUI: atTheEndOfWeek.uiValue,
			},
			{
				ParameterName: every3Days.label,
				SettingID: every3Days.settingId,
				ParameterDisplay: every3Days.uiValue,
				ParameterValue: every3Days.value,
				ParameterValueUI: every3Days.uiValue,
			},
			{
				ParameterName: daily.label,
				SettingID: daily.settingId,
				ParameterDisplay: daily.uiValue,
				ParameterValue: daily.value,
				ParameterValueUI: daily.uiValue,
			},
		]
		// ConsoleLog(payload)
		// return false
		self.updateEmailNotificationSetting(defaultPayload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					/**
					 * REFLECT to STORE
					 */
					self.setAtTheEndOfDay(atTheEndOfDay)
					self.setEveryOtherDay(everyOtherDay)
					self.setAtTheEndOfWeek(atTheEndOfWeek)
					self.setEvery3Days(every3Days)
					self.setDaily(daily)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						autoHideDuration: 1500,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditEmailNotificationSetting
