import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const UpdateRationale = (self: any, update: any) => ({
	updateRationale: flow(function* (
		fileMenu: DLProjSubMenus,
		contents: string,
		rationaleId: string,
		fileId: string
	) {
		const actionName = "updateRationale"
		const RationaleId = rationaleId ? parseInt(rationaleId) : 0
		const params = {
			FileType: fileMenu === DLProjSubMenus.wp ? 1 : 2, // 1: wp, 2: pf
			RationaleDescription: contents,
			RationaleID: RationaleId,
			WorkPaperID: parseInt(fileId),
		}
		try {
			const response = yield update(params)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default UpdateRationale
