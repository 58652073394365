import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import ReactTableV8 from "../../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function TopicDetailTable({
	data,
	showDetail,
	setTargetProjects,
}: {
	data: any
	showDetail: boolean
	setTargetProjects: (projects: any, dialogTitle: string) => void
}) {
	const store = useRootStore()
	const contentHeight = store.ui.contentsAreaHeight

	return (
		<ReactTableV8
			tableColumns={
				showDetail
					? ColumnDetail(setTargetProjects)
					: ColumnSimple(setTargetProjects)
			}
			showPageSetting={false}
			height={contentHeight}
			data={data}
			hasPagination={true}
			menuId="TopicDetailTable"
		/>
	)
})

const ClickableCell = ({
	value,
	testId,
	handleClick,
	style,
}: {
	value: any
	testId: string
	handleClick: () => void
	style?: any
}) => {
	return (
		<div
			className="clickable-cell"
			data-testid={testId}
			onClick={handleClick}
			style={style}
		>
			{value}
		</div>
	)
}

function convert(text: string) {
	return text.toLowerCase().replace(/ /g, "-")
}

const renderCustomCell = (props: any) => {
	const labelForTest = convert(props.row.original.label)
	const cellId = `${labelForTest}-${props.cell.column.id}-cell`
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const ColumnDetail = (
	setTargetProjects: (projects: any, dialogTitle: string) => void
) => [
	{
		header: "Label",
		accessorKey: "label",
		cell: renderCustomCell,
	},
	{
		header: "Total",
		accessorKey: "total",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.totalData
			const title = label + " - Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-total-cell"}
					handleClick={handleClick}
					style={{ fontWeight: "bold" }}
				/>
			)
		},
	},
	// ---- archived
	{
		header: "Archived Total",
		accessorKey: "archived",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.archivedData
			const title = label + " - Archived Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-archived-total-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},
	{
		header: "Archived Timely",
		accessorKey: "archivedTimely",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.archivedData.filter(
				(item: any) => item.isLate === false
			)
			const title = label + " - Archived Timely"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-archived-timely-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},
	{
		header: "Archived Late",
		accessorKey: "archivedLate",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.archivedData.filter(
				(item: any) => item.isLate
			)
			const title = label + " - Archived Late"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-archived-late-cell"}
					handleClick={handleClick}
					style={{ color: "orange" }}
				/>
			)
		},
	},
	{
		header: "Archived Cannot Calc",
		accessorKey: "archivedCannotCalc",
		style: { color: "purple" },
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.archivedData.filter(
				(item: any) => item.isLate === undefined
			)
			const title = label + " - Archived Cannot Calculate"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-archived-cannotCalc-cell"}
					handleClick={handleClick}
					style={{ color: "purple" }}
				/>
			)
		},
	},
	// ---- normal
	{
		header: "Normal Total",
		accessorKey: "normal",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.normalData
			const title = label + " - Normal Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-normal-total-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},
	{
		header: "Normal Timely",
		accessorKey: "normalTimely",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.normalData.filter(
				(item: any) => item.isLate === false
			)
			const title = label + " - Normal Timely"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-normal-timely-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},
	{
		header: "Normal Late",
		accessorKey: "normalLate",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.normalData.filter(
				(item: any) => item.isLate
			)
			const title = label + " - Normal Late"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-normal-late-cell"}
					handleClick={handleClick}
					style={{ color: "orange" }}
				/>
			)
		},
	},
	{
		header: "Normal Cannot Calc",
		accessorKey: "normalCannotCalc",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.normalData.filter(
				(item: any) => item.isLate === undefined
			)
			const title = label + " - Normal Cannot Calculate"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-normal-cannotCalc-cell"}
					handleClick={handleClick}
					style={{ color: "purple" }}
				/>
			)
		},
	},
	// ---- unarchived
	{
		header: "Unarchived Total",
		accessorKey: "unarchived",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.unarchivedData
			const title = label + " - Unarchived Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-unarchived-total-cell"}
					handleClick={handleClick}
					style={{ color: "red" }}
				/>
			)
		},
	},
	{
		header: "Unarchived Timely",
		accessorKey: "unarchivedTimely",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.unarchivedData.filter(
				(item: any) => item.isLate === false
			)
			const title = label + " - Unarchived Timely"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-unarchived-timely-cell"}
					handleClick={handleClick}
					style={{ color: "red" }}
				/>
			)
		},
	},
	{
		header: "Unarchived Late",
		accessorKey: "unarchivedLate",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.unarchivedData.filter(
				(item: any) => item.isLate
			)
			const title = label + " - Unarchived Late"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-unarchived-late-cell"}
					handleClick={handleClick}
					style={{ color: "red" }}
				/>
			)
		},
	},
	{
		header: "Unarchived Cannot Calc",
		accessorKey: "unarchivedCannotCalc",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.unarchivedData.filter(
				(item: any) => item.isLate === undefined
			)
			const title = label + " - Unarchived Cannot Calculate"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-unarchived-cannotCalc-cell"}
					handleClick={handleClick}
					style={{ color: "red" }}
				/>
			)
		},
	},
]

const ColumnSimple = (setTargetProjects: any) => [
	{
		header: "Label",
		accessorKey: "label",
		cell: (props: any) => {
			const labelForTest = convert(props.row.original.label)
			return (
				<div data-testid={labelForTest + "-label-cell"}>
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: "Total",
		accessorKey: "total",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.totalData
			const title = label + " - Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-total-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},
	// ---- archived
	{
		header: "Archived Total",
		accessorKey: "archived",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.archivedData
			const title = label + " - Archived Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-archived-total-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},
	// ---- normal
	{
		header: "Normal Total",
		accessorKey: "normal",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.normalData
			const title = label + " - Normal Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-normal-total-cell"}
					handleClick={handleClick}
				/>
			)
		},
	},

	// ---- unarchived
	{
		header: "Unarchived Total",
		accessorKey: "unarchived",
		cell: (props: any) => {
			const label = props.row.original.label
			const projects = props.row.original.unarchivedData
			const title = label + " - Unarchived Total"
			const handleClick = () => {
				setTargetProjects(projects, title)
			}
			return (
				<ClickableCell
					value={props.getValue()}
					testId={convert(label) + "-unarchived-total-cell"}
					handleClick={handleClick}
					style={{ color: "red" }}
				/>
			)
		},
	},
]
