import React, { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { DLExpansionPanelSingle } from "../../../components/basic-elements"
import {
	EngagementInfo,
	ClientDefaultInfo,
	AuditInfo,
	PrevFinancialInfo,
	AuditorsInfo,
	AuditHoursCalculation,
} from "./sub-components"

export default observer(function OrgEngDetail() {
	const orgStore = useOrgStore()
	let { id } = useParams<{ id: string }>()

	return (
		<StyledOrgEngDetail
			style={{
				height: 800,
				overflowY: "scroll",
				paddingBottom: "2rem",
			}}
		>
			<div>Detail of {id}</div>
			<div style={{ height: 8 }} />
			{/* 
			-
			-
			-
			-
			 */}
			<h3>계약 정보</h3>
			<div style={{ height: 6 }} />
			<DLExpansionPanelSingle
				eleTestId=""
				title="Engagement Information"
				defaultExpanded
			>
				<EngagementInfo />
			</DLExpansionPanelSingle>
			<div style={{ height: 32 }} />
			{/* 
			-
			-
			-
			-
			 */}
			<h3>감사대상법인 정보</h3>
			<div style={{ height: 6 }} />
			<DLExpansionPanelSingle
				eleTestId=""
				title="기본정보"
				defaultExpanded
			>
				<ClientDefaultInfo />
			</DLExpansionPanelSingle>
			<div style={{ height: 16 }} />
			<DLExpansionPanelSingle
				eleTestId=""
				title="기본재무제표 감사 및 내부회계관리제도 감사 정보정보"
				defaultExpanded
			>
				<AuditInfo />
			</DLExpansionPanelSingle>
			<div style={{ height: 16 }} />
			<DLExpansionPanelSingle
				eleTestId=""
				title="기본직전 사업연도 재무 정보정보"
				defaultExpanded
			>
				<PrevFinancialInfo />
			</DLExpansionPanelSingle>
			<div style={{ height: 32 }} />
			{/* 
			-
			-
			-
			-
			 */}
			<h3>감사인 정보</h3>
			<div style={{ height: 6 }} />
			<DLExpansionPanelSingle
				eleTestId=""
				title="감사인 정보"
				defaultExpanded
			>
				<AuditorsInfo />
			</DLExpansionPanelSingle>
			<div style={{ height: 32 }} />
			{/* 
			-
			-
			-
			-
			 */}
			<h3>표준감사시간 산출</h3>
			<div style={{ height: 6 }} />
			<DLExpansionPanelSingle
				eleTestId=""
				title="표준감사시간 산출"
				defaultExpanded
			>
				<AuditHoursCalculation />
			</DLExpansionPanelSingle>
		</StyledOrgEngDetail>
	)
})

const StyledOrgEngDetail = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
