import React from "react"
import { Route } from "react-router-dom"
import RecentProjectsController from "./recent-projects/RecentProjectsController"
import MyInformation from "./my-information/MyInformation"
import MyHistory from "./my-history/MyHistory"
import MyLibrary from "./my-library/MyLibrary"
import RemindArchivingController from "./remind-archiving/RemindArchivingController"
import RemindReportController from "./remind-report/RemindReportController"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default function MyPage() {
    return (
        <>
            <Route
                exact
                path="/organization/my-page"
                component={RecentProjectsController}
            />
            <Route
                exact
                path="/organization/my-page/recent"
                component={RecentProjectsController}
            />
            <Route
                exact
                path="/organization/my-page/my-information"
                component={MyInformation}
            />
            <Route
                exact
                path="/organization/my-page/my-history"
                component={MyHistory}
            />
            <Route
                exact
                path="/organization/my-page/my-library"
                component={MyLibrary}
            />
            <Route
                exact
                path="/organization/my-page/remind-archiving"
                render={(props) => (
                    <RemindArchivingController
                        {...props}
                        menuId={DLOrgSubMenus.remind_archiving}
                    />
                )}
            />
            <Route
                exact
                path="/organization/my-page/remind-report"
                render={(props) => (
                    <RemindReportController
                        {...props}
                        menuId={DLOrgSubMenus.remind_report}
                    />
                )}
            />
        </>
    )
}
