import { types } from "mobx-state-tree"
import { DLProjectModel } from "../../../../service-modules/project-module/data-model/project-module-data-models"
import { SimpleUserModel } from "../../../../service-modules/user-module/user-module-data-models"

// TODO: @Noah integrate below all
// create project related things
export type SimpleClientProps = {
	id: string
	aliasId: string
	name: string
}

const SimpleClient = types.model({
	id: types.string,
	aliasId: types.string,
	name: types.string,
})

export type SimpleUserProps = {
	id: string
	accessType: string
	email: string
	isActive: boolean
	name: string
	title: string | null
	aliasId: string
}
const SimpleUser = types.model({
	id: types.string,
	accessType: types.string,
	email: types.string,
	isActive: types.boolean,
	name: types.string,
	title: types.union(types.string, types.null),
	aliasId: types.string,
})

export type ClientsByGroupProps = {
	groupId: string
	clients: SimpleClientProps[]
}

export const ClientsByGroup = types.model({
	groupId: types.identifier,
	clients: types.array(SimpleClient),
})

export const UsersByGroup = types.model({
	groupId: types.identifier,
	users: types.array(SimpleUser),
})

export type RfSourceProjectProps = {
	id: string
	title: string
	available: boolean
	combinedInfo: string
	year: number
}

export const RfSourceProject = types.model({
	id: types.string,
	title: types.string, // NOTE: use 'linkName' instead of the 'ClientName'
	available: types.boolean,
	combinedInfo: types.string,
	year: types.number,
})

// NOTE: to export project user list
// NOTE: OrgProject model + Proj Users Model
export const UserForExport = types.model({
	// projInfo: types.model({
	groupName: types.string, // [GroupName], ex) "Prepare and Review"
	clientName: types.string, // [ClientName], ex) "ABCDEFGH"
	clientAliasId: types.string,
	aliasId: types.string, // [ProjectBusinessId], ex) "" --> is null possible..?
	title: types.string, // [ProjectName], ex) "57yth"
	engTypeName: types.string, // [EngagementType], ex) "CUULWRRKSC"
	raName: types.string,
	statusName: types.string,
	periodName: types.string, // [PeriodName], ex) "Full year"
	version: types.number, // [Version], ex) 0
	// isArchived: types.boolean, // [Archive], ex) false
	// isLocked: types.boolean, // [IsLocked], ex) false
	financialYear: types.integer, // [Year], ex) 2019
	periodEndDate: types.string, // [FinancialYear], ex) "10/23/2019"
	finalReportDate: types.union(types.null, types.string), // [ReportIssuranceDate], ex) "" // is this final report date?
	// createdBy: types.union(types.null, SimpleUserModel),
	createdAt: types.union(types.null, types.string),
	// lastAccessBy: types.union(types.null, types.undefined, types.string), // [LastAccessedBy], ex) "cskim 2019-08-07 09:51:19.972355"
	// lastAccessAt: types.union(types.null, types.undefined, types.string),
	// archOrUnArchBy: types.union(types.null, types.undefined, types.string), // [ArchivedBy], ex) 0
	// archOrUnArchAt: types.union(types.null, types.undefined, types.string), // [ProjectArchivedDate], ex) ""
	epUsers: types.string, // [EpUsers], ex) ""
	qcUsers: types.string, // [QCUsers], ex) ""
	// }),
	// projectId: "",
	// projTitle: "",
	// users: types.array(
	// 	types.model({
	name: types.string,
	aliasId_shortName: types.string, //@Noah: need to update
	email: types.string,
	orgTitle: types.union(types.string, types.null), // TODO: Take discussion about 'null' type case
	type: types.enumeration(["internal", "external"]),
	// project side info
	status: types.enumeration(["active", "inactive"]),
	// ----- permission info
	roleName: types.string,
	accessTypeName: types.string,
	canRollForward: types.boolean,
	// ----- date related info
	// modifiedByName: types.union(
	// 	types.string,
	// 	types.null,
	// 	types.undefined
	// ), // TODO: Take discussion about 'null' type case
	// modifiedAt: types.union(types.string, types.null, types.undefined), //TODO: Update is required for 'null' and 'undefined'
	// lastActivity: types.union(
	// 	types.string,
	// 	types.null,
	// 	types.undefined
	// ),
	// 	})
	// ),
})

export const ProjectDetailWithUsersAndFilesCount = types.model({
	succeedToGet: false,
	usersCount: 0,
	users: types.array(
		types.model({
			projRoleName: "",
			projRoleId: "",
			email: "",
			expDate: "",
			name: "",
			id: "",
			active: false,
		})
	),
	wpCount: 0,
	pfCount: 0,
	// ----------
	// projectInfo: DLProjectModel,
	// projectInfo: types.model({
	// 	title: "",
	// 	aliasId: "",
	// 	id: ""
	// })
	financialYear: 0,
	projectTitle: "",
	projectAliasId: "",
	projectId: "",
	clientName: "",
	clientId: "",
	clientAliasId: "",
	engTypeName: "",
	periodName: "",
})
