import React from "react"
import styled from "styled-components"
// Please use inidividual component if you want to use different shape header.
// Dialog header can get react component
// this header only for simple one
export default function DLDialogHeader({
	icon,
	dialogName,
	targetName,
}: {
	icon?: any
	dialogName: string
	targetName: string
}) {
	return (
		<StyledDLDialogHeader className="FR AC">
			{icon}
			<div className="dialog-header-dialog-name">{dialogName}</div>
			{targetName !== "" && (
				<div style={{ marginRight: "0.4rem", marginLeft: "0.4rem" }}>
					-
				</div>
			)}
			{targetName !== "" && (
				<div
					className="dialog-header-group-name"
					style={{ fontWeight: 700 }}
				>
					{targetName}
				</div>
			)}
		</StyledDLDialogHeader>
	)
}

const StyledDLDialogHeader = styled.div`
	background-color: ${(props) => props.theme.background};
	.dialog-header-dialog-name {
	}
	.dialog-header-group-name {
	}
`
