import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	deleteRequest,
} from "../../../../library/api-requests"

export async function read(params: {
	TrashType: string
	CustomerID?: number
	Lang?: String
}) {
	const response = await getRequest({
		url: "/GetHomeTrashDetails",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function restore(params?: { TrashID: number }) {
	const response = await putRequest({
		url: "/HomeRestore",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function remove(params?: { TrashID: number }) {
	const response = await deleteRequest({
		url: "/PermanentDelete",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgTrashApiProps = {
	apiRead: (params: {
		TrashType: string
		CustomerID?: number
		Lang?: String
	}) => Promise<AxiosResponse<any>>
	apiRemove: (params?: { TrashID: number }) => Promise<AxiosResponse<any>>
	apiRestore: (params?: { TrashID: number }) => Promise<AxiosResponse<any>>
}
