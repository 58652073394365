import { flow } from "mobx-state-tree"
import { AmArchivedApiProps } from "../am-archived-projects.apis"

const ReadReadOnlyUsers = (
	self: any,
	readAccessMgmtUser: AmArchivedApiProps["apiReadAccessMgmtUser"]
) => ({
	readReadOnlyUsers: flow(function*(payload) {
		const actionName = "readReadOnlyUsers"
		try {
			const response = yield readAccessMgmtUser(payload)

			return response
		} catch (error) {
			// ConsoleLog("readAccessMgmtUsers error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadReadOnlyUsers
