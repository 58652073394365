// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../../library/converters/id-converter"
import { reOrganizeItem } from "./get-archive-policies"

const CopyArchivePolicy = (self: any) => ({
	copyArchivePolicy(policyId: string) {
		// 0.
		const actionName = "copyArchivePolicy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const policyNumberId = idToNumber(policyId, IdType.archivePolicy)
		self.requestCopyArchivePolicy(policyNumberId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					let reOrganizedConditions: {
						mandatoryList: any[]
						optionalList: any[]
					} = {
						mandatoryList: [],
						optionalList: [],
					}
					let reOrganizedFlatConditions: any[] = []
					const policyId = idToString(
						response.data.Id,
						IdType.archivePolicy
					)
					const fetchedConditions = response.data.PreConditionsList
					const flatPolicy = {
						id: policyId,
						parentId: null,
						title: response.data.Title,
						description: response.data.Description,
						expanded: false,
						type: "policy",
					}

					// 2. mandatory conditions
					fetchedConditions.MandatoryList.map((item: any) => {
						reOrganizedConditions.mandatoryList.push(
							reOrganizeItem(item)
						)
						reOrganizedFlatConditions.push(reOrganizeItem(item))
					})
					// 3. optional conditions
					fetchedConditions.NonMandatoryList.map((item: any) => {
						reOrganizedConditions.optionalList.push(
							reOrganizeItem(item)
						)
						reOrganizedFlatConditions.push(reOrganizeItem(item))
					})
					const policyDetail = {
						id: policyId,
						conditions: reOrganizedFlatConditions,
					}
					self.pushItemToFlatList(flatPolicy)
					self.pushItemToList(policyId, policyDetail)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default CopyArchivePolicy
