import {
	Sector,
	Priority,
	WhoCanRead,
	PostAttachmentType,
} from "@datalobby/types"

// export type VisitPostResponseType = {
// 	visitPost: {
// 		id: string
// 		visitor: string
// 	} | null
// }

// export const visitPost = /* GraphQL */ `
// 	mutation VisitPost($postId: ID, $userId: String) {
// 		visitPost(postId: $postId, userId: $userId) {
// 			id
// 			visitor
// 		}
// 	}
// `

export type CreatePostResponseType = {
	createPost: {
		__typename: "Post"
		menu: string
		id: string
		title: string
		content: string
		//
		sector: Sector
		parent: string
		priority?: Priority
		fixToTop?: boolean
		attachments?: PostAttachmentType[]
		//
		// whoCanRead?: WhoCanRead
		// allowedTargets?: string[]
		// visitors?: string[]
		// visitorCount?: number
		//
		createdBy?: string
		createdAt?: string
		modifiedBy?: string
		modifiedAt?: string
	} | null
}
export const createPost = /* GraphQL */ `
	mutation CreatePost($post: PostInput!) {
		createPost(post: $post) {
			menu
			id
			title
			content
			sector
			parent
			priority
			fixToTop
			attachments {
				fileName
				extension
				size
				formattedSize
				s3ObjKey
			}
			# visitorCount
			# whoCanRead
			# allowedTargets
			createdBy
			createdAt
			modifiedBy
			modifiedAt
			deleted
		}
	}
`

export type UpdatePostResponseType = {
	updatePost: {
		__typename: "Post"
		menu: string
		id: string
		title: string
		content: string
		//
		priority?: Priority
		fixToTop?: boolean
		attachments?: PostAttachmentType[]
		//
		// whoCanRead?: WhoCanRead
		// allowedTargets?: string[]
		//
		modifiedBy?: string
		modifiedAt?: string
	} | null
}
export const updatePost = /* GraphQL */ `
	mutation UpdatePost($post: UpdatePostInput!) {
		updatePost(post: $post) {
			menu
			id
			title
			content
			priority
			fixToTop
			attachments {
				fileName
				extension
				size
				formattedSize
				s3ObjKey
			}
			# whoCanRead
			# allowedTargets
			# createdBy
			# createdAt
			modifiedBy
			modifiedAt
			# deleted
		}
	}
`

export type DeletePostResponseType = {
	deletePost: string
}
export const deleteOrRestorePost = /* GraphQL */ `
	mutation DeleteOrRestorePost($postId: String!, $menuId: String!, $toDelete: Boolean!, $by: String!) {
		deleteOrRestorePost(postId: $postId, menuId: $menuId, toDelete:$toDelete, by:$by)
	}
`

export const permanentDeletePost = /* GraphQL */ `
	mutation PermanentDeletePost($postId: String!, $menuId: String!) {
		permanentDeletePost(postId: $postId, menuId: $menuId)
	}
`
