const sharedRegEx = {
	email: /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{1,}))$/,
	verificationCode: /^[0-9]{6}$/,
	aliasId: /^(?=.*[a-z])[a-z|A-Z|0-9]{2,}$/, // How can I allow multilingual?
	name: /^(?=.*[a-z])[a-z|A-Z|0-9]{2,}$/,
	groupName: /^[|a-z|0-9|A-Z| -!@#$&*()\\-`.+,/\'"]*$/,
	password: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*])(?=.{8,})$/, // WARNING:  TODO: update is requireD!!!
	dlPassword: /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[!@#$%^&*()]).{8,}$/,
	nameWithSpace: /^(?=.*[a-z|A-Z])[a-z|A-Z|0-9 ]{2,}$/,
	// noSpecialCharacters: /^[^*|\":<>[\]{}`\\()';@&$]+$/,
	noSpecialCharacters: /^[^*|\":<>[\]{}`\\';@&$!]+$/,
	noSpecialChar2: /^[^*|\/":<>{}`?\\]+$/, // for file name:  / * ? " > < : |
	noSpecialChar3: /^[^*|\/":<>{}`?\\_]+$/, // for file aliasId :  / * ? " > < : | _
}

export default sharedRegEx

/** example
	^                         Start anchor
	(?=.*[A-Z].*[A-Z])        Ensure string has two uppercase letters.
	(?=.*[!@#$&*])            Ensure string has one special case letter.
	(?=.*[0-9].*[0-9])        Ensure string has two digits.
	(?=.*[a-z].*[a-z].*[a-z]) Ensure string has three lowercase letters.
	.{8}                      Ensure string is of length 8.
	$                         End anchor.
 */
