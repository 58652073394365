import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLDialogHeader,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function UnAssignUsersFromGroupDialog({
	unassignGroups,
	i18n,
}: {
	unassignGroups: any
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- UnAssignUsersToGroupDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const groupId = orgStore.setupGroups.selectedGroup
	const groupName = orgStore.setupGroups.getGroupInfoById(groupId)?.groupName
	
	useEffect(() => {
		return () => {
			orgStore.setupGroups.resetAssignedProjectList()
        };
	},[])

	return (
		<DLDialog
			eleTestId="unassign-users-from-group-dialog"
			isOpen={orgStore.setupGroups.unAssignUsersToGroupDialogOpen}
			setIsOpen={
				orgStore.setupGroups.setUnAssignUsersToGroupDialogOpen
			}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle={
				<DLDialogHeader
					dialogName="Unassign user from group"
					targetName={groupName ? groupName : "(Unknown group)"}
				/>
			}
			dialogContents={<ProjectList />}
			actionReady={true}
			handleAction={unassignGroups}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twUnassign || "Unassign"}
			maxWidth="sm"
		/>
	)
})

const ProjectList = observer(() => {
	const orgStore = useOrgStore()
	return (
		<div>
			<p>
				The user is currently assigned to the projects below. Still,
				you want to Suspend the user?
			</p>
			{orgStore.setupGroups.assignedProjectList.map((item) => {
				return (
					<div key={item.name}>
						<b>
							{item.name} - Assigned Project List(
							{item.projectList.length})
						</b>
						<ol>
							{item.projectList.map((proj) => {
								return (
									<li
										key={`${proj.clientName}-${proj.projectName}`}
									>
										{proj.clientName} {proj.periodName}{" "}
										{proj.clientName}:{" "}
										{proj.projectName} (
										{proj.clientAliasId}) (
										{proj.projectStatus})
									</li>
								)
							})}
						</ol>
					</div>
				)
			})}
		</div>
	)
})
