import React from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import {
	DLDialog,
	DLButton,
	InputWithLabel,
	DLInputField,
} from "../../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiShieldLockOutline } from "@mdi/js"
import { observer } from "mobx-react-lite"
import useForm from "../../../../../../library/use-form"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import styled from "styled-components"
import {
	DialogOnOffProps,
	DialogFormProps,
} from "../../../../../../common-models/types/dialog.props"

export default observer(function ArchiveDialog({
	open,
	setOpen,
}: DialogOnOffProps) {
	const projStore = useProjStore()
	const actionName = "archiveProject"

	const inputsSchema = {
		email: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email,
				error: "Invalid Email format",
			},
		},
	}
	const handleArchive = () => {
		projStore.archive.archive()
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, handleArchive, open)

	if (projStore.archive.getActionStatus(actionName) === "SUCCESS") {
		setOpen(false)
	}
	return (
		<div>
			<DLDialog
				eleTestId="archive-dialog"
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiShieldLockOutline}
							size={1.2}
							style={{ marginRight: 8 }}
						/>{" "}
						Project Archive
					</div>
				}
				dialogContents={
					<ArchiveDialogContents
						inputs={inputs}
						handleOnChange={handleOnChange}
					/>
				}
				// handleAction={handleOnSubmit}
				actionBtn={
					<DLButton
						startIcon={
							<Icon path={mdiShieldLockOutline} size={0.8} />
						}
						eleTestId="archive-btn"
						disabled={!isReady}
						clickHandler={handleOnSubmit}
						color="primary"
					>
						Archive
					</DLButton>
				}
				actionReady={isReady}
				openBtn={
					<DLButton
						eleTestId="archive-dialog-open-btn"
						startIcon={
							<Icon path={mdiShieldLockOutline} size={0.8} />
						}
					>
						Project Archive
					</DLButton>
				}
				// TODO: UPDATE below parts
				// dialogError={
				// 	projStore.archive.actionStatus !== "SUCCESS" &&
				// 	projStore.archive.response.statusMessage
				// 		? projStore.archive.response.statusMessage
				// 		: undefined
				// }
				// showSpinner={
				// 	projStore.archive.actionStatus === "LOADING"
				// 		? true
				// 		: false
				// }
			/>
		</div>
	)
})

const ArchiveDialogContents = ({ inputs, handleOnChange }: DialogFormProps) => {
	return (
		<StyledArchiveDialogContents>
			<p>Do you really want to archive this project?</p>
			<div className="archive-help-text">
				Archive Notes will come here
			</div>
			<div className="confirm-by-email">
				<p>To Archive, input your email address</p>
				<InputWithLabel required label="Email Address">
					<DLInputField
						eleTestId="email-adress-input"
						eleFullWidth
						eleName="email"
						eleValue={inputs.email.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={inputs.email.error}
						requestInput={inputs.email.requestInput}
					/>
				</InputWithLabel>
			</div>
		</StyledArchiveDialogContents>
	)
}

const StyledArchiveDialogContents = styled.div`
	.archive-help-text {
	}
	.confirm-by-email {
		padding: 1.4rem 0;
		.input-area {
			min-width: 20rem;
		}
	}
`
