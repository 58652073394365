import { format } from "date-fns"
import { flow } from "mobx-state-tree"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../library/converters/id-converter"
import { findProjAccessTypeId } from "../../../../temporary-data/project-side/find-proj-accessType"
import { ConsoleLog } from "../../../../components/basic-elements"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getByToken } from "../../../../library/api-requests/request-get-others"
import { getRequest } from "../../../../library/api-requests"

async function getToken(projId: string, self: any, userId: string) {
	const tokenResponse = await self.getProjToken(projId, userId)
	return tokenResponse
}

export async function getUserList(projId: string, token: any, self: any) {
	const response = await self.getProjectUsersToExport(projId, token)
	return response
}

async function getUsers(
	projId: string,
	self: any,
	userId: string,
	projInfo: any,
	index: number,
	projects: any,
	dateFormat: string,
	proj: any
) {
	const tokenResponse = await getToken(projId, self, userId)
	const usersResponse = await getUserList(
		projId,
		tokenResponse.data.data.access_token,
		self
	)

	let combinedList: any[] = []
	if (usersResponse.Status === 1) {
		self.pushItemToUserExportStatusList({
			projId,
			success: true,
		})
		//

		const originUsers = usersResponse.UsersList
		originUsers.map((user: any) => {
			const reOrganizedProjUser = {
				...projInfo,
				// organization side info
				name: user.Name,
				aliasId: user.UserName,
				email: user.Email,
				orgTitle: user.OrganizationTitle,
				type: user.UserType === 1 ? "internal" : "external",
				// project side info
				status: user.isGrayed ? "inactive" : "active",
				// ----- permission info
				roleName: user.Role,
				accessTypeName: user.AccessRight,
				canRollForward: user.isRollForward === 1 ? true : false,
				// ----- date related info
				// modifiedById: idToString(
				// 	user.ModifiedBy,
				// 	IdType.user
				// ),
				// modifiedByName: user.ModifiedByName,
				// modifiedAt: user.ModifiedDate,
				// lastActivity: user.LastActivity
				// 	? user.LastActivity
				// 	: "",
				// expDate: user.ExpDate,
				// inactiveDueTo: user.InActiveDueTo,
				// strikedOut: user.IsStrikeOut,
			}
			combinedList.push(reOrganizedProjUser)
		})

		const concattedList = self.usersForExport.concat(combinedList)
		// NOTE: This line need update. Create new function to concat
		self.concatItemsToUsersForExport(concattedList)
		//
		if (index < projects.length - 1) {
			self.exportUsers(dateFormat, projects, userId, index + 1)
		}
		ConsoleLog([index, "success", proj.title])
	} else {
		self.pushItemToUserExportStatusList({
			projId,
			success: fail,
		})
		if (index < projects.length - 1) {
			self.exportUsers(dateFormat, projects, userId, index + 1)
		}
		ConsoleLog([index, "fail", proj.title])
	}
}

const ExportUsersOfProject = (self: any) => ({
	exportUsers(
		dateFormat: string,
		projects: any,
		userId: string,
		index: number
	) {
		const proj = projects[index]
		const projInfo = {
			groupName: proj.groupName,
			clientName: proj.clientName,
			clientAliasId: proj.clientAliasId,
			aliasId: proj.aliasId,
			title: proj.title,
			engTypeName: proj.engTypeName,
			raName: proj.raName,
			statusName: proj.statusName,
			periodName: proj.periodName,
			version: proj.version,
			// isArchived: proj.// isArchived,
			// isLocked: proj.// isLocked,
			financialYear: proj.financialYear,
			periodEndDate: proj.periodEndDate,
			finalReportDate: proj.finalReportDate,
			// createdBy: proj.createdBy,
			createdAt: proj.createdAt,
			epUsers: proj.epUsers,
			qcUsers: proj.qcUsers,
		}
		const projId = proj.id
		getUsers(
			projId,
			self,
			userId,
			projInfo,
			index,
			projects,
			dateFormat,
			proj
		)
	},
	getProjToken: flow(function* (projId: string, userId: string) {
		const projNumId = idToNumber(projId, IdType.project)
		// ConsoleLog("projId in getProjToken", projId, projNumId)
		try {
			const response = yield getRequest({
				url: "/ClientToken",
				params: {
					UserId: idToNumber(userId, IdType.user),
					ClientId: projNumId,
				},
				tokenType: TOKEN_TYPE.loginToken,
			})
			return response
		} catch (error) {
			ConsoleLog([projId, "error", error])
			return false
		}
	}),
	getProjectUsersToExport: flow(function* (id: string, token: any) {
		// ConsoleLog(["getProjectUsersToExport", token])
		try {
			const endpoint = "/GetUsersByClient" // This API is not working with orgToken for normal project...
			const response = yield getByToken({
				url: endpoint,
				params: { clientId: idToNumber(id, IdType.project) },
				token,
				// tokenType: TOKEN_TYPE.orgToken,
			})
			// ConsoleLog([id, "done", response.data.length])
			return response.data
		} catch (error) {
			ConsoleLog([id, "error", error])
			return false
		}
	}),
})

export default ExportUsersOfProject
