import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import {
	CreateEngType,
	ReadEngTypeList,
	UpdateEngType,
	DeleteEngType,
} from "./model-actions"
import ReadChecklistConnections from "./model-actions/read-checklist-connections"
import ReadChecklistTemplates from "./model-actions/read-checklist-templates"
import { OrgEngTypeApiProps } from "./org-eng-types.apis"

const OrgEngTypeList = ({
	apiCreate,
	apiRead,
	apiUpdate,
	apiDelete,
	apiReadChecklistTemplates,
	apiReadChecklistConnections,
}: OrgEngTypeApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => CreateEngType(self, apiCreate))
		.actions((self) => ReadEngTypeList(self, apiRead))
		.actions((self) =>
			ReadChecklistTemplates(self, apiReadChecklistTemplates)
		)
		.actions((self) =>
			ReadChecklistConnections(self, apiReadChecklistConnections)
		)
		.actions((self) => UpdateEngType(self, apiUpdate))
		.actions((self) => DeleteEngType(self, apiDelete))
		// common model actions
		.actions(CommonModelActions)

export default OrgEngTypeList
