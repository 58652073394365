import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import {
	DLButton,
	DLTextArea,
	ConsoleLog,
} from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiArrowLeft, mdiContentSave } from "@mdi/js"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { PageContainer } from "../../../../components/app-frame-elements"
import styled from "styled-components"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function RationaleEdit() {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	let { parentId } = useParams<{ parentId?: string }>()

	const i18nSheet = ProjI18n.rationale
	const i18n = store.i18n.combineI18n(i18nSheet)

	const [contents, setContents] = useState<any>()

	// TODO: It's danger to use below script. because the api can return not "SUCCESS"...
	if (projStore.rationales.getActionStatus("getRationales") !== "SUCCESS") {
		projStore.rationales.getRationales()
	}

	const dnt = store.global.getDntFormat
	const { dateFormat, timeZone } = dnt

	useEffect(() => {
		parentId &&
			setContents(
				projStore.rationales.viewRationale(
					parentId,
					dateFormat,
					timeZone
				)
			)
	}, [parentId, projStore.rationales.getActionStatus("getRationales")])

	// const modules = {
	// 	toolbar: [
	// 		[{ header: "1" }, { header: "2" }, { font: [] }],
	// 		[{ size: [] }],
	// 		["bold", "italic", "underline", "strike", "blockquote"],
	// 		[
	// 			{ list: "ordered" },
	// 			{ list: "bullet" },
	// 			{ indent: "-1" },
	// 			{ indent: "+1" },
	// 		],
	// 		["link", "image", "video"],
	// 		["clean"],
	// 		[{ color: [] }, { background: [] }],
	// 	],
	// 	clipboard: {
	// 		// toggle to add extra line breaks when pasting HTML:
	// 		matchVisual: false,
	// 	},
	// }

	// const formats = [
	// 	"header",
	// 	"font",
	// 	"size",
	// 	"bold",
	// 	"italic",
	// 	"underline",
	// 	"strike",
	// 	"blockquote",
	// 	"list",
	// 	"bullet",
	// 	"indent",
	// 	"link",
	// 	"image",
	// 	"video",
	// 	"color",
	// 	"background",
	// ]

	const changeHandler = (value: string) => {
		setContents((content: any) => ({
			...content,
			rationaleDescription: value,
		}))
	}

	const saveRationaleInfo = () => {
		const fileMenu =
			contents.type === "WP" ? DLProjSubMenus.wp : DLProjSubMenus.pf
		projStore.rationales.editRationale(
			fileMenu,
			contents.rationaleDescription,
			contents.rationaleId,
			contents.podId
		)
	}

	return (
		<StyledRationaleEdit
			contentsHeight={store.ui.pageContentsAreaHeight - 300}
		>
			<PageContainer hasToolbar={false}>
				{parentId && (
					<>
						<div className="FR AC JSB">
							<Link to={`${defaultUrl}/archive/rationales`}>
								<DLButton
									variant="text"
									eleTestId="back-to-list"
									startIcon={
										<Icon path={mdiArrowLeft} size={0.8} />
									}
									disabled={
										projStore.rationales.viewRationale(
											parentId,
											dateFormat,
											timeZone
										)?.rationaleDescription ===
										contents?.rationaleDescription
											? false
											: true
									}
								>
									Back to the list
								</DLButton>{" "}
							</Link>
							<DLButton
								eleTestId="rationale-save-btn"
								startIcon={
									<Icon path={mdiContentSave} size={0.8} />
								}
								color="primary"
								clickHandler={saveRationaleInfo}
								disabled={
									projStore.rationales.viewRationale(
										parentId,
										dateFormat,
										timeZone
									)?.rationaleDescription ===
									contents?.rationaleDescription
								}
							>
								Save
							</DLButton>
						</div>
						<RationaleParentFile
							fileInfo={projStore.rationales.viewRationale(
								parentId,
								dateFormat,
								timeZone
							)}
							finalReportDate={
								// TODO: variable name need update
								projStore.rationales.finalReportDate
									? projStore.rationales.finalReportDate
									: "Please set the final Report date"
							}
							// diff={projStore.rationales.diff}
						/>
					</>
				)}

				<div className="rationale-editor">
					<DLTextArea
						eleTestId="rationale-description"
						elePlaceholder="Start Typing..."
						eleValue={contents?.rationaleDescription}
						eleHandleChange={(e: any) =>
							changeHandler(e.target.value)
						}
						eleName="rationale-description"
						eleClassName="rationale-description-text-input-area"
					/>
				</div>
			</PageContainer>
			<CommonSnackbarComponent
				i18n={i18n} // this component seems cannot take i18n , or let's try to get the i18n on the router...
				partialStore={projStore.rationales}
			/>
		</StyledRationaleEdit>
	)
})

const RationaleParentFile = ({
	fileInfo,
	finalReportDate, // TODO: variable name need update
}: // diff,
{
	fileInfo: any
	finalReportDate: string
	// diff: number | string
}) => {
	ConsoleLog(["fileInfo", fileInfo])

	return (
		<StyledRationaleParentFile className="about-parent">
			<div className="info-row FR AC">
				<span className="bold">Ref. num: </span>
				<div data-testid="file-aliasId">{fileInfo?.aliasId}</div>
			</div>
			<div className="info-row FR AC">
				<span className="bold">File name: </span>
				<div data-testid="file-title">{fileInfo?.description}</div>
			</div>
			<div className="info-row FR AC">
				<span className="bold">Modified by: </span>
				<div data-testid="file-modifiedBy">{fileInfo?.modifiedBy}</div>
			</div>
			<div className="info-row FR AC">
				<span className="bold">Modified at: </span>
				<div data-testid="file-modifiedAt">
					{fileInfo?.modifiedAt}
				</div>{" "}
				{/* <div className="diff">
					{diff} days excess from the Final Report date (
					{finalReportDate})
				</div> */}
			</div>
		</StyledRationaleParentFile>
	)
}

const StyledRationaleParentFile = styled.div`
	border-top: 1px solid ${(props) => props.theme.shade20};
	border-bottom: 1px solid ${(props) => props.theme.shade20};
	padding: 1rem 0;
	margin: 1rem 0;
	.info-row {
		margin-bottom: 0.5rem;
		.bold {
			font-weight: 600;
			margin-right: 0.5rem;
		}
	}
`

const StyledRationaleEdit = styled.div<{ contentsHeight: number }>`
	.quill {
		height: ${(props) => props.contentsHeight}px;
	}
`
