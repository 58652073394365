import React from "react"
import { observer } from "mobx-react-lite"
import { DLButton, DLDialog, DLDialogHeader } from "../../basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import CommentCardSimple from "./CommentCardSimple"
import { SimpleCommentProps } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { PermissionAsObjectProps } from "../../../common-models/permission"

export default observer(function CommentListDialog({
	open,
	setOpen,
	openAddCommentDialog,
	openEditCommentDialog,
	openCloseCommentDialog,
	handleDeleteComment,
	openCommentReadonlyDialog,
	comments,
	objectInfo,
	permission,
}: {
	open: boolean
	setOpen: (request?: boolean) => void
	openAddCommentDialog: () => void
	openEditCommentDialog: (commentId: string) => void
	openCloseCommentDialog: (commentId: string) => void
	handleDeleteComment: (commentId: string) => void
	openCommentReadonlyDialog: (commentId: string) => void
	comments: SimpleCommentProps[]
	objectInfo: {
		title: string
		aliasId: string
		id: string
	}
	permission: PermissionAsObjectProps
}) {
	return (
		<DLDialog
			eleTestId="comment-list-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			openBtn={<div />}
			dialogTitle={
				<DLDialogHeader
					dialogName="Comments on"
					targetName={`(${objectInfo.aliasId}) ${objectInfo.title}`}
				/>
			}
			dialogContents={
				<CommentsByObject
					comments={comments}
					handleDeleteComment={handleDeleteComment}
					openAddCommentDialog={openAddCommentDialog}
					openEditCommentDialog={openEditCommentDialog}
					openCloseCommentDialog={openCloseCommentDialog}
					openCommentReadonlyDialog={openCommentReadonlyDialog}
					permission={permission}
				/>
			}
			actionBtn={<div />}
			showCloseBtn
			cancelBtnText="Close"
		/>
	)
})

const CommentsByObject = observer(
	({
		comments,
		handleDeleteComment,
		openAddCommentDialog,
		openEditCommentDialog,
		openCloseCommentDialog,
		openCommentReadonlyDialog,
		permission,
	}: {
		comments: SimpleCommentProps[]
		handleDeleteComment: (commentId: string) => void
		openAddCommentDialog: () => void
		openEditCommentDialog: (commentId: string) => void
		openCloseCommentDialog: (commentId: string) => void
		openCommentReadonlyDialog: (commentId: string) => void
		permission: PermissionAsObjectProps
	}) => {
		return (
			<div>
				{comments.length <= 0 ? (
					<div>No Comments</div>
				) : (
					<div>
						{comments.map((comment) => {
							return (
								<div key={comment.id}>
									<CommentCardSimple
										comment={comment}
										handleDelete={handleDeleteComment}
										handleOpenEdit={openEditCommentDialog}
										handleCloseComment={
											openCloseCommentDialog
										}
										handleOpenReadonly={
											openCommentReadonlyDialog
										}
										permission={permission}
									/>
								</div>
							)
						})}
					</div>
				)}
				<DLButton
					eleTestId="add-comment-btn"
					startIcon={<Icon path={mdiPlus} size={0.8} />}
					variant="text"
					color="primary"
					clickHandler={openAddCommentDialog}
				>
					Add Comment
				</DLButton>
			</div>
		)
	}
)
