import React, { useState } from "react"

import { DLButton, ConsoleLog } from "../../../../components/basic-elements"
import {
	AddClientDialog,
	BulkAddClientsDialog,
	EditClientDialog,
	RemoveClientDialog,
	AssignToGroupDialog,
	DeleteMultipleClients,
} from "./dialogs"
import { PageContainer } from "../../../../components/app-frame-elements"
//
import OrgSetupClientsTable from "./sub-components/OrgSetupClientsTable"
import OrgSetupClientsTableMenus from "./sub-components/OrgSetupClientsTableMenus"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function OrgSetupClients({
	partialStore,
	fetchingStatus,
	permission,
	i18n,
	menuId,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
	menuId: DLOrgSubMenus
}) {
	ConsoleLog(" __________ OrgSetupClients __________ ")
	const [filteredData, setFilteredData] = useState<any>([])

	const [delMulClientsDialogOpen, setDelMulClientsDialogOpen] =
		useState(false)

	// const deleteAllClients = () => {
	// 	const allClients = orgStore.setupClients.getAllClientsId()
	// 	orgStore.setupClients.removeClients(allClients, 0)
	// }

	const isAppAdminUser = JSON.parse(
		localStorage.getItem("isAdminAppUser") || "false"
	)

	return (
		<PageContainer
			hasToolbar={isAppAdminUser}
			pageTools={
				<div className="FR AC" style={{ overflowX: "auto" }}>
					<DLButton
						eleTestId="delete"
						color="warning"
						clickHandler={() => setDelMulClientsDialogOpen(true)}
						tooltipText="Delete clients"
					>
						Delete Filtered Clients
					</DLButton>
				</div>
			}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === ActionStatus.loading}
		>
			{fetchingStatus === ActionStatus.success && (
				<StyledSetupClients>
					<OrgSetupClientsTable
						i18n={i18n}
						setFilteredData={setFilteredData}
						permission={permission}
						menuId={menuId}
					/>
				</StyledSetupClients>
			)}
			{/* context menu */}
			<OrgSetupClientsTableMenus i18n={i18n} permission={permission} />
			{/* dialogs */}
			{partialStore.addClientDialogOpen && (
				<AddClientDialog i18n={i18n} />
			)}
			{partialStore.bulkAddClientsDialogOpen && (
				<BulkAddClientsDialog partialStore={partialStore} />
			)}
			{partialStore.editClientDialogOpen && (
				<EditClientDialog i18n={i18n} />
			)}
			{partialStore.removeClientDialogOpen && (
				<RemoveClientDialog i18n={i18n} />
			)}
			{partialStore.assignClientsToGroupDialogOpen && (
				<AssignToGroupDialog i18n={i18n} />
			)}
			{delMulClientsDialogOpen && (
				<DeleteMultipleClients
					open={delMulClientsDialogOpen}
					setOpen={setDelMulClientsDialogOpen}
					clientData={filteredData}
					partialStore={partialStore}
				/>
			)}
			{/* response snackbar -- can be removed or commented in the future */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledSetupClients = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.counter-container {
		.total-count {
			margin-left: 0.4rem;
		}
		.partition {
			margin: 0 1rem;
		}
		.selected-items {
			margin: 0 0.6rem;
		}
	}
`
