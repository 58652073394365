import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLCommentType } from "../../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../../../library/api-requests/"

/**
 *
 * NOTE: MOVE TO COMMENT AREA.
 * NOTE: And if the module be saparted in the future, copy that remote actions
 * NOTE: For consistency !
 */

export type CreateCommentProps = {
	projId: string
	comment: string
	userId: string
	priority: "High" | "Medium" | "Low"
	type: DLCommentType // normal: "ReviewComment" | required: "QcComment"
	assignedUsers: string[] // "["userId111, userId222"],
	objAliasId: string
	fileId: string
	menuId: DLProjSubMenus
}

const CreateComment = (self: any) => ({
	createComment: flow(function* (props: CreateCommentProps) {
		const actionName = "createComment"
		const {
			projId,
			comment,
			userId,
			priority,
			type,
			assignedUsers,
			objAliasId,
			fileId,
			menuId,
		} = props
		// 1.
		//
		//
		// 2.
		const projNumId = idToNumber(projId, IdType.project)
		const userNumId = idToNumber(userId, IdType.user)
		const fileNumId = idToNumber(fileId, IdType.file)
		let assignedNumUsers: number[] = []
		assignedUsers.map((user) => {
			const numId = idToNumber(user, IdType.user)
			if (typeof numId === "number") {
				assignedNumUsers.push(numId)
			} else {
				alert(`Assigned user ID is invalid. ${numId}`)
			}
		})
		//
		//
		// 3.
		try {
			const payload = {
				ClientID: projNumId,
				Comment: comment,
				CreatedBy: userNumId,
				Priority: priority,
				Type:
					type === DLCommentType.normal
						? "ReviewComment"
						: "QCComment",
				UserId: assignedNumUsers.join(), // "111,222",
				WPREF: objAliasId,
				WPRID: fileNumId,
			}
			// ConsoleLog("payload of add comment:", payload)
			//
			let endpoint = ""
			if (menuId === DLProjSubMenus.wp) {
				endpoint = "/CreateReviewComments"
			} else if (menuId === DLProjSubMenus.pf) {
				endpoint = "/CreatePermanentFileReviewComments"
			}
			const response = yield postRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.projToken,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default CreateComment
