import { types, flow } from "mobx-state-tree"
import { OrgSetupClientsApiProps } from "../org-clients.apis"

const DeleteClient = (
	self: any,
	del: OrgSetupClientsApiProps["apiDelete"]
) => ({
	deleteClient: flow(function*(payload) {
		const actionName = "deleteClient"
		// ConsoleLog(payload)
		try {
			const response = yield del(payload)

			// ConsoleLog("deleteClient response", response)
			return response
		} catch (error) {
			// console.error("deleteClient error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteClient
