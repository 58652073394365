import React from "react"
import { DLChip, DLIconButton } from "../../../../components/basic-elements"
import { DLCheckbox, DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiDotsVertical } from "@mdi/js"
import {
	MessageColorType,
	PriorityType,
	DLCommentType,
	DialogActionType,
} from "../../../../common-models/enumerations/common-enums"
import ToggleSelectAllCheckbox from "../../../../common-models/shared-store-code/selected-items-related/ToggleSelectAllCheckbox"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { CommentStatus } from "./store/data-models/comments.data-model"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import {
	dateRangeFilter,
	singleSelectFilter,
} from "../../../../library/react-table-utils"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"

export const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "status":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="status-select"
				>
					<option value="all">All</option>
					<option value={CommentStatus.todo}>Todo</option>
					<option value={CommentStatus.done}>Done</option>
				</select>
			)

		case "priority":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="priority-select"
				>
					<option value="all">All</option>
					<option value={PriorityType.high}>High</option>
					<option value={PriorityType.medium}>Medium</option>
					<option value={PriorityType.low}>Low</option>
				</select>
			)

		case "type":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="type-select"
				>
					<option value="all">All</option>
					<option value={DLCommentType.normal}>Normal</option>
					<option value={DLCommentType.qc}>QC Comment</option>
				</select>
			)

		case "menuId":
			return (
				<select
					onChange={(e: any) => {
						column.setFilterValue(e.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string[]}
					data-testid="menuId-select"
				>
					<option value="all">All</option>
					<option value={DLProjSubMenus.wp}>WP</option>
					<option value={DLProjSubMenus.pf}>PF</option>
				</select>
			)

		case "createdAt":
		case "closedAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const CommentsColumns = ({
	selectedItemLength,
	handleToggleSelectAllFiles,
	//
	setSelectedItem,
	handleDetailDialogOpen,
	handleRightClick,
	handleCheckbox,
	isSelected,
	handleOpenComment,
	i18n,
}: {
	selectedItemLength: number
	handleToggleSelectAllFiles: () => void
	//
	setSelectedItem: ({ id, type }: { id: string; type: string }) => void
	handleDetailDialogOpen: (mode: DialogActionType) => void
	handleRightClick: (e: any, rowInfo: any) => void
	handleCheckbox: (e: any, id: string, type: string) => void
	isSelected: (id: string) => boolean
	handleOpenComment: (id: string) => void
	i18n: DLI18nProps
}) => [
	{
		header: (
			<ToggleSelectAllCheckbox
				selectedItemExist={selectedItemLength > 0}
				toggleAction={handleToggleSelectAllFiles}
			/>
		),
		accessorKey: "id",
		size: 30,
		enableColumnFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			const id = props.row.original.id
			const type = props.row.original.type
			const handleClick = (e: any) => {
				handleCheckbox(e, id, type)
			}

			return (
				<DLCheckbox
					eleTestId={`checkbox-${id}`}
					clickHandler={(e: any) => handleClick(e)}
					color="primary"
					isChecked={isSelected(id)}
				/>
			)
		},
	},
	{
		header: i18n.twStatus || "Status",
		accessorKey: "status",
		width: 60,
		filterFn: singleSelectFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const status = props.getValue()
			return <div data-testid={cellId}>{status.toLowerCase()}</div>
		},
	},
	{
		header: i18n.twMenu || "Menu",
		accessorKey: "menuId",
		size: 50,
		filterFn: (row: any, columnId: any, value: any) => {
			if (value === "all") return true
			else return row.getValue(columnId) === value
		},
		cell: (props: any) => {
			const menu = props.getValue()
			const cellId = props.cell.column.id + "-cell"
			const menuType =
				menu === DLProjSubMenus.wp
					? "WP"
					: menu === DLProjSubMenus.pf
					? "PF"
					: "?"
			return (
				<DLChip
					eleTestId={cellId}
					size="small"
					label={menuType}
					color={menu === DLProjSubMenus.wp ? "primary" : "default"}
				/>
			)
		},
	},
	{
		header: i18n.twPriority || "Priority",
		accessorKey: "priority",
		filterFn: singleSelectFilter,
		size: 80,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			const value = props.getValue()
			const colorCode =
				value === "High" ? "red" : value === "Medium" ? "orange" : ""
			return (
				<DLChip
					eleTestId={cellId}
					size="small"
					label={value}
					colorCode={colorCode}
					variant="outlined"
				/>
			)
		},
	},
	{
		header: i18n.twRefNum || "Ref.",
		accessorKey: "objAliasId",
		size: 60,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId} className="comment-info">
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: i18n.twFileName || "File Name",
		accessorKey: "objectName",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"

			// const fileInfo = {
			// 	menuId: ,

			// }
			// const userInfo = {
			// 	id: ,
			// 	isReadonly: true,
			// 	isExternal:
			// }

			// const handleOpenFile = () => {
			// const fileProps = checkFileProps({
			// 	projId,
			// 	fileInfo,
			// 	userInfo,
			// })
			// if (fileProps && fileProps.useEditor) {
			// 	store.editor.openEditor(fileProps.props)
			// } else if (fileProps && fileProps.useEditor === false) {
			// 	openFileWithoutEditor(fileProps?.props)
			// } else {
			// 	console.log("What should I do on here..?")
			// }

			// }
			return (
				// <Link to="/plese-set-editor" data-testid={cellId}>
				// <DLTooltip title="File Open as Readonly">
				<div className="comment-info" data-testid={cellId}>
					{props.getValue()}
				</div>
				// </DLTooltip>
				// </Link>
			)
		},
	},
	{
		header: i18n.twType || "Type",
		accessorKey: "type",
		filterFn: singleSelectFilter,
		size: 50,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			if (props.getValue() === DLCommentType.normal) {
				return (
					<div className="comment-info" data-testid={cellId}>
						Normal
					</div>
				)
			} else if (props.getValue() === DLCommentType.qc) {
				return (
					<DLChip
						eleTestId={cellId}
						label="QC Comment"
						color={MessageColorType.orange}
					/>
				)
			} else {
				return <div>(unknown)</div>
			}
		},
	},

	{
		header: i18n.twAssignee || "Assignee",
		accessorKey: "assigneeNames",
		cell: (props: any) => {
			const names = props.getValue()
			const cellId = props.cell.column.id + "-cell"
			return (
				<div className="FR AC comment-info" data-testid={cellId}>
					{names.map((name: string, index: number) => {
						return (
							<span style={{ marginRight: 4 }} key={index}>
								{name}
								{index < names.length - 1 && ","}
							</span>
						)
					})}
				</div>
			)
		},
	},
	{
		header: i18n.twComment || "Comment",
		accessorKey: "contents",
		cell: (props: any) => {
			let short = props.getValue()
			const cellId = props.cell.column.id + "-cell"

			return (
				<DLTooltip title="Open Comment Detail">
					<div
						className="comment-contents"
						data-testid={cellId}
						onClick={() => handleOpenComment(props.row.original.id)}
					>
						{short}
					</div>
				</DLTooltip>
			)
		},
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			// NOTE: original creatdBy is object. this createdBy is converted to userName on the views
			return (
				<div data-testid={cellId} className="comment-info">
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return (
				<div data-testid={cellId} className="comment-info">
					{props.getValue()}
				</div>
			)
		},
	},
	// {
	// 	header: "At",
	// 	accessorKey: "createdAt"
	// },
	// {
	// 	header: "Sign Off",
	// 	accessorKey: "signOff"
	// },
	{
		header: i18n.twResponse || "Response",
		accessorKey: "response",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: i18n.twClosedBy || "Closed By",
		accessorKey: "closedBy",
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: i18n.twClosedAt || "Closed At",
		accessorKey: "closedAt",
		filterFn: dateRangeFilter,
		cell: (props: any) => {
			const cellId = props.cell.column.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: "Action",
		accessorKey: "xxx",
		size: 50,
		enableColumnFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			const comment = props.row.original
			const id = comment.id
			const type = comment.type

			const handleContextMenu = (e: any) => {
				setSelectedItem({ id, type })
				handleRightClick(e, comment)
			}
			return (
				<div>
					<DLIconButton
						variant="iconOnly"
						aria-label="more"
						clickHandler={(e: any) => handleContextMenu(e)}
						eleTestId="ctx-btn-on-row"
					>
						<Icon path={mdiDotsVertical} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]

export default CommentsColumns
