import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddFinancialSubGroup = (self: any) => ({
	addFinancialSubGroup(
		financialSubGroup: string,
		projectId: string,
		fsGroupId: number
	) {
		// 0
		const actionName = "addFinancialSubGroup"
		// 1
		const checkedProps = hasEmptyProps({ financialSubGroup })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Creating financial sub group is in progress",
			open: true,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			financialSubGroup: checkedProps.props.financialSubGroup,
			financialGroupId: fsGroupId,
			clientId,
		}

		// 3) request API call to Model
		self.createFinancialSubGroup(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						const reOrganizedGroup = {
							fsSubGroupId: fetchedData.id,
							fsSubGroupName: fetchedData.financialSubGroup,
							fsSubGroupMapId: 0, // Not getting
						}
						self.pushItemToAvailableSubGroups(reOrganizedGroup)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully added sub group",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddFinancialSubGroup
