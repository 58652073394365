import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"

const ReadProjTemplateGroups = (
	self: any,
	readProjTemplateGroups: OrgProjTemplatesApiProps["apiReadProjTemplateGroups"]
) => ({
	readProjTemplateGroups: flow(function*() {
		const actionName = "readProjTemplateGroups"
		try {
			const response = yield readProjTemplateGroups()
			// NOTE: Do not save the original data on the Model (TBD) --> Move to the ViewModel
			// ConsoleLog(actinName, "__response", response)
			return response
		} catch (error) {
			// ConsoleLog(actinName, "__error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadProjTemplateGroups
