import React, { useState, useEffect } from "react"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import styled from "styled-components"
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers"
import { CalenderIcon, CalenderSelectedIcon } from "../icons/common-icons"

interface DLDatepickerWithoutInputProps {
	eleTestId: string
	selected?: any
	disabled?: boolean
	eleName?: string
	onChange(value: any, name: string): any
	format?: string
	noMargin?: boolean
	autoOk?: boolean
}

export default function DLDatepickerWithoutInput({
	eleTestId = "dl-date-picker-without-input",
	selected = null,
	disabled = false,
	eleName = "",
	onChange,
	format = "MM/dd/yyyy",
	noMargin,
	autoOk = true,
}: DLDatepickerWithoutInputProps) {
	// const [selectedDate, setSelectedDate] = React.useState<
	//     Date | null | undefined
	// >(selected)
	// const handleDateChange = (date: Date | null) => {
	//     setSelectedDate(date)
	// }

	const [selectedDate, setSelectedDate] = React.useState<Date | null>(null)

	useEffect(() => {
		setSelectedDate(selected)
	}, [selected])

	const handleDateChange = (
		date: Date | null,
		value: string | null | undefined
	) => {
		setSelectedDate(date)
		onChange(value, eleName)
	}

	return (
		<DLStyledDatePicker className="dl-date-picker-without-input-container FR AC">
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					// disableToolbar
					clearable
					className={`custom-style date-picker-input ${
						noMargin && "no-margin"
					}`}
					// variant="inline"
					format={format}
					margin="normal"
					id={eleTestId}
					name={eleName}
					value={selectedDate}
					onChange={handleDateChange}
					InputProps={{
						disableUnderline: true,
					}}
					KeyboardButtonProps={{
						"aria-label": "change date",
					}}
					autoOk={autoOk}
					disabled={disabled}
					keyboardIcon={
						<div data-testid={`${eleTestId}-calendar-open`}>
							{selectedDate ? (
								<CalenderSelectedIcon noMargin />
							) : (
								<CalenderIcon noMargin />
							)}
						</div>
					}
				/>
			</MuiPickersUtilsProvider>
		</DLStyledDatePicker>
	)
}

const DLStyledDatePicker = styled.div`
	.custom-style {
		&.no-margin {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.date-picker-input {
		margin: 0;
		input {
			display: none;
		}
		.MuiInputAdornment-positionEnd {
			margin-left: 0;
		}
		button {
			padding: 0;
		}
		:hover {
			svg {
				color: ${(props) => props.theme.primaryMainDeep};
			}
		}
	}
`
