// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - confirm (translation has been postponed)
 * - success, fail, loading
 */
const RemoveProjTemplateGroup = (self: any) => ({
	removeProjTemplateGroup(templateGroupId: string) {
		// 0.
		const actionName = "removeProjTemplateGroup"
		// 1.
		const childrenCount = self.getTemplateCountOfGroup(templateGroupId)
		let proceed = window.confirm(
			`This template gruop has ${childrenCount} template. Do you really want to delete <${self.getTemplateNameById(
				templateGroupId
			)}> template group? `
		)

		if (!proceed) {
			return
		}
		// 2.

		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		const numId = idToNumber(templateGroupId, IdType.projTemplateGroup)
		// ConsoleLog(["numId", numId, templateGroupId])

		if (typeof numId === "number") {
			self.deleteProjTemplateGroup(numId)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// console.log(actionName+ "__response " + response)

						/**
						 * REFLECT RESPONSE ON THE STORE
						 */
						self.emptyTheFlatList() // remove stored data
						self.resetSelectedTemplate()
						self.setSelectedTemplateGroup("")
						self.reflectRemoveProjTemplate(templateGroupId)
						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 1600,
						})
					} else {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		} else {
			ConsoleLog([actionName, numId])
		}
	},
})

export default RemoveProjTemplateGroup
