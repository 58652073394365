import { flow } from "mobx-state-tree"
import { OrgEngTypeApiProps } from "../org-eng-types.apis"

const ReadChecklistTemplates = (
	self: any,
	apiReadChecklistTemplates: OrgEngTypeApiProps["apiReadChecklistTemplates"]
) => ({
	readChecklistTemplates: flow(function* (orgId: string) {
		const actionName = "readChecklistTemplates"
		try {
			const response = yield apiReadChecklistTemplates(orgId)
			console.log(actionName, "response:", response)
			return response
		} catch (error) {
			console.error(actionName, "error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadChecklistTemplates
