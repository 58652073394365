import { SignoffHistoryDetailProps } from "../data-models/signoff-history.data-model"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DnTFormatProps } from "../../../../../../common-models/types/common-props"
import { getTzDateAndTime2 } from "../../../../../../library/converters/date-utc-converter"

export const ActionsForSignoffDetails = (self: any) => ({
	setDetail(detail: SignoffHistoryDetailProps, menuId: DLProjSubMenus) {
		if (menuId === DLProjSubMenus.wp) {
			self.wpHistoryDetail.set(detail.fileId, detail)
		} else if (menuId === DLProjSubMenus.pf) {
			self.pfHistoryDetail.set(detail.fileId, detail)
		}
	},
})

export const ViewsForSignoffDetails = (self: any) => ({
	viewDetailHistory(
		fileId: string,
		menuId: DLProjSubMenus,
		dntFormat: DnTFormatProps
	) {
		let target: any = undefined
		if (menuId === DLProjSubMenus.wp) {
			target = self.wpHistoryDetail.get(fileId)
		} else if (menuId === DLProjSubMenus.pf) {
			target = self.pfHistoryDetail.get(fileId)
		}
		//
		if (target !== undefined) {
			const history = target.history
			let formattedHistory: any[] = []
			//
			if (history !== null) {
				history.map((item: any) => {
					if (item.at !== "") {
						formattedHistory.push({
							...item,
							at: getTzDateAndTime2({
								date: item.at,
								dntFormat,
							}),
						})
					} else {
						formattedHistory.push(item)
					}
				})
			}

			return formattedHistory
		} else {
			return []
		}
	},
	exportDetailHistory({
		menuId,
		dntFormat,
	}: {
		menuId: DLProjSubMenus
		dntFormat: DnTFormatProps
	}) {
		let target: { fileId: string; history: any } = {
			fileId: "",
			history: "",
		}
		if (menuId === DLProjSubMenus.wp) {
			target = JSON.parse(JSON.stringify(self.wpHistoryDetail))
		} else if (menuId === DLProjSubMenus.pf) {
			target = JSON.parse(JSON.stringify(self.pfHistoryDetail))
		}

		const negativeMark = (isNegative: boolean) => {
			return isNegative ? "-- " : ""
		}

		if (target !== undefined) {
			let history: any[] = []
			for (const [key, value] of Object.entries(target)) {
				if (key.includes("fileId")) {
					const fileDetails = self.viewLastSignoffById(menuId, key)
					if (!value) {
						return
					}
					value.history.map((item: any) => {
						let prepare: string = "",
							review: string = "",
							epReview: string = "",
							cpReview: string = "",
							qcReview: string = "",
							at: string = ""

						if (item.prepare) {
							const { isNegative, role, by } = item.prepare
							prepare = `${negativeMark(
								isNegative
							)}(${role}) ${by}`
						}
						if (item.review) {
							const { isNegative, role, by } = item.review
							review = `${negativeMark(
								isNegative
							)}(${role}) ${by}`
						}
						if (item.epReview) {
							const { isNegative, role, by } = item.epReview
							epReview = `${negativeMark(
								isNegative
							)}(${role}) ${by}`
						}
						if (item.cpReview) {
							const { isNegative, role, by } = item.cpReview
							cpReview = `${negativeMark(
								isNegative
							)}(${role}) ${by}`
						}
						if (item.qcReview) {
							const { isNegative, role, by } = item.qcReview
							qcReview = `${negativeMark(
								isNegative
							)}(${role}) ${by}`
						}
						if (item.at) {
							at = getTzDateAndTime2({ date: item.at, dntFormat })
						}
						history.push({
							folderName: fileDetails.parentName,
							referenceNumber: fileDetails.aliasId,
							fileName: fileDetails.title,
							at,
							prepare,
							review,
							epReview,
							cpReview,
							qcReview,
						})
					})
				}
			}
			return history
		}
	},
})
