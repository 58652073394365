import { types } from "mobx-state-tree"
import { DLI18nProps } from "./common-props"

export const ClickPoint = types.model({
	mouseX: types.union(types.null, types.number),
	mouseY: types.union(types.null, types.number),
})

export type ClickPointProps = {
	mouseX: null | number
	mouseY: null | number
}

export const initialClickPoint = {
	mouseX: null,
	mouseY: null,
}

//
//
//
//
//

export interface DialogOnOffProps {
	open: boolean
	setOpen: any
	showOpenBtn?: boolean
	[x: string]: any
}

export interface DialogFormProps {
	inputs: any
	handleOnChange: any
	actionName?: string
}

export interface DialogFormProps2 {
	inputs: any
	handleOnChange: any
	actionName: string
	[x: string]: any
}
export interface I18nDialogFormProps {
	inputs: any
	handleOnChange: any
	i18n: DLI18nProps
	actionName?: string
}
