import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import OrgSearch from "./OrgSearch"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function OrgSearchController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getOrgSearch"
	const menuId = DLOrgSubMenus.search
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	return (
		<>
			{menuAccess ? (
				<OrgSearch
					partialStore={orgStore.search}
					actionStatus={orgStore.search.getActionStatus(actionName)}
					contentsHeight={store.ui.contentsAreaHeight}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
