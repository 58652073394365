// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import { SimpleUserProps } from "../data-models/org-project.data-model"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */

const GetUsersByGroup = (self: any) => ({
	getUsersByGroup(groupId: string) {
		// 0.
		const actionName = "getUsersByGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readUsersByGroup(groupId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const fetchedUsers = response.data.UsersList
					let reOrganizedUsers: SimpleUserProps[] = []
					fetchedUsers.map(
						(user: {
							AccessTypeId: number // 1 -->
							Email: string //"test+am@datalobby.co.kr"
							IsGrayed: boolean //false
							Name: string // "AmUserName"
							Title: null | string // null
							UserID: number //680
							UserName: string // "AmUserId"
						}) => {
							reOrganizedUsers.push({
								id: idToString(user.UserID, IdType.user),
								accessType: user.AccessTypeId.toString(), // TODO: access type converter is required...
								email: user.Email,
								isActive: !user.IsGrayed,
								name: user.Name,
								title: user.Title,
								aliasId: user.UserName,
							})
						}
					)
					self.setUsersByGroupMap(reOrganizedUsers, groupId)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetUsersByGroup
