import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { SimpleFileTreeProps } from "../../../../service-modules/file-module/data-models/file.data-props"
import {
	ConsoleLog,
	DLDialog,
	DLTreeListByTreeData,
} from "../../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import { Icon } from "@mdi/react"
import { mdiFolder } from "@mdi/js"
import styled from "styled-components"
import { FileTreeForSelectionDialogContents } from "./FileTreeForSelectionDialog"

export default observer(function MoveFileOrFolderDialog({
	partialStore,
	i18n,
}: {
	partialStore: any
	i18n: DLI18nProps
}) {

	const open = partialStore.fileTreeDialogOpenStatus[FileScreenDialog.moveFileOrFolder]
	const setOpen = () => {
		partialStore.setFileTreeDialogOpen([FileScreenDialog.moveFileOrFolder])
	}

	const simpleFileTree = partialStore.viewSimpleFileTree("folder-only")
	ConsoleLog(["simpleFileTree ", simpleFileTree])

	const selectedItem = partialStore.selectedItems[0]?.id
	const selectedItemDetails = partialStore.getItemById(selectedItem)
	const selectedParentId = selectedItemDetails.parentId
	const [targetFolder, setTargetFolder] = useState<string | undefined>(
		undefined
	)
	console.log(selectedItemDetails,selectedParentId,targetFolder)

	const handleTargetItem = () => {
		if(selectedItemDetails && targetFolder) {
			const itemList = JSON.parse(JSON.stringify(selectedItemDetails))
			partialStore.reorderChildren(targetFolder, [itemList])
		}
		setOpen()
	}

	return (
		<DLDialog
			eleTestId="move-file-or-folder-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleTargetItem}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={"Move Item"}
			dialogContents={
				<FileTreeForSelectionDialogContents
					simpleFileTree={simpleFileTree}
					selectedItem={targetFolder}
					setSelectedItem={setTargetFolder}
				/>
			}
			cancelBtnText="Cancel"
			actionReady={(selectedParentId !== targetFolder && selectedItem !== targetFolder) && !!targetFolder}
			actionBtn="Move"
			draggable
		/>
	)
})

