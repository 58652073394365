import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

// NOTE: Do not use 'any' for the separated api
// NOTE: Please refer 'delete-group.ts'
const RequestRepairReplica = (self: any, repair: any) => ({
	requestRepairReplica: flow(function* (
		projId,
		replicaId,
		orgNumId,
		userNumId
	) {
		const actionName = "requestRepairReplica"
		// 1. check the props existence
		// if (params === undefined) {
		// 	alert(`(${actionName}) Undefined prop exist. params: ${params}`)
		// 	return
		// }
		//
		//
		// 2. organize the params
		// const xxx = "xxx"
		//
		//
		// 3. validate format
		// if (typeof xxx === "number") {

		try {
			const response = yield repair({
				clientId: projId,
				custId: orgNumId,
				userId: userNumId,
				replicaId,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
		// } else {
		// 	alert(`(${actionName}) Invalid props. xxx: ${xxx}`)
		// 	return false
		// }
	}),
})

export default RequestRepairReplica
