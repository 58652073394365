import React from "react"
import styled from "styled-components"

export default function SimpleCardList({
	list,
	handleClick,
}: {
	list: { id: string; title: string; [x: string]: any }[]
	handleClick: (id: string) => void
}) {
	return (
		<StyledSimpleCardList>
			<div className="card-container">
				{list.map(
					(item: { id: string; title: string; [x: string]: any }) => {
						const { id, title } = item
						return (
							<div
								className="simple-card"
								onClick={() => handleClick(id)}
							>
								{title}
							</div>
						)
					}
				)}
			</div>
		</StyledSimpleCardList>
	)
}

const StyledSimpleCardList = styled.div`
	.total-counter {
		height: ${(props) => props.theme.shared.baseUnit};
	}
	.card-container {
		display: flex;
		flex-wrap: wrap;
		.simple-card {
			padding: 1rem;
			width: 160px;
			height: 160px;
			box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
			border: 1px solid ${(props) => props.theme.shade10};
			border-radius: 4px;
			margin-right: 1rem;
			margin-bottom: 1rem;
			overflow: hidden;
			cursor: pointer;
			transition: 0.4s;
			:hover {
				/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
				box-shadow: 0px 2px 8px 1px
					${(props) => props.theme.primaryLight};
				border: 1px solid ${(props) => props.theme.primaryLightDeep};
			}
		}
	}
`
