import React, { useEffect } from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLButton, DLMenus } from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import styled from "styled-components"
import {
	ForgotPwDefault,
	ForgotPwLoading,
	ForgotPwSuccess,
	ForgotPwFail,
	ForgotPwSnackbar,
} from "./sub-components"
import {
	ActionStatus,
	SupportedLanguage,
} from "../../../common-models/enumerations/common-enums"

export default observer(function ForgotPassword() {
	const store = useRootStore()
	const moduleName = "LobbyForgotPassword"
	const actionName = "passwordReset"

	/**
	 * navigator.languages returns ["en-US", "en"]
	 */
	const browserLanguage = navigator.language

	useEffect(() => {
		if (browserLanguage !== "en-US" && browserLanguage !== "ko-KR") {
			store.i18n.setLanguage(SupportedLanguage.us)
		} else if (browserLanguage === "en-US") {
			store.i18n.setLanguage(SupportedLanguage.us)
		} else if (browserLanguage === "ko-KR") {
			store.i18n.setLanguage(SupportedLanguage.kr)
		}
		// to reset the error message
		store.forgotPw.handleResponse({
			// for responses
			actionName,
			status: ActionStatus.standby,
			message: "",
			open: false,
		})
	}, [])

	const handleSelectLanguage = (language: SupportedLanguage) => {
		store.i18n.setLanguage(language)
	}

	const language = store.i18n.language
	const moduleDictionary = store.i18n.byModule(
		moduleName,
		language,
		"forgotPw"
	)

	let i18n: { [x: string]: string } = {
		tsBackToLogin: "Back to Login screen without request",
		twCancel: "Cancel",
	}

	if (moduleDictionary) {
		i18n = moduleDictionary
	}
	const { tsBackToLogin } = i18n

	return (
		<StyledForgotPassword className="FC AC JC forgot-pw-screen">
			{store.forgotPw.responses.getActionStatus(actionName) ===
				"STANDBY" && <ForgotPwDefault i18n={i18n} />}
			{store.forgotPw.responses.getActionStatus(actionName) ===
				"LOADING" && <ForgotPwLoading i18n={i18n} />}
			{store.forgotPw.responses.getActionStatus(actionName) ===
				"SUCCESS" && <ForgotPwSuccess i18n={i18n} />}
			{store.forgotPw.responses.getActionStatus(actionName) ===
				"FAIL" && <ForgotPwFail i18n={i18n} />}
			<div className="common-back-button">
				<DLTooltip arrow title={tsBackToLogin}>
					<div className="back-to-login">
						<Link to="/login">
							<DLButton
								variant="text"
								eleTestId="back-to-login"
								startIcon={
									<Icon path={mdiArrowLeft} size={1} />
								}
							>
								{tsBackToLogin ||
									"Back to Login screen without request"}
							</DLButton>
						</Link>
					</div>
				</DLTooltip>
			</div>
			<ForgotPwSnackbar />
			<div className="select-language">
				<DLMenus
					eleTestId="lang-select-menu"
					btn={
						<DLButton eleTestId="lang-change-btn" variant="text">
							{language.toUpperCase()}
						</DLButton>
					}
					options={[
						{
							value: "en-US",
							label: "English",
							clickEvent: () =>
								handleSelectLanguage(SupportedLanguage.us),
						},
						{
							value: "ko-KR",
							label: "Korean",
							clickEvent: () =>
								handleSelectLanguage(SupportedLanguage.kr),
						},
					]}
				/>
			</div>
		</StyledForgotPassword>
	)
})

const StyledForgotPassword = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.primaryLight};
	.card-body {
		height: 500px;
		align-items: center;
		justify-content: center;
		text-align: center;
		.heading1 {
			margin: 3rem 0;
		}
		.description {
			margin-bottom: 3rem;
		}
	}
	.common-back-button {
		margin-top: 5rem;
	}
	.select-language {
		position: absolute;
		left: 1rem;
		bottom: 1rem;
	}
`
