import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { HandleResponseProps } from "../../../common-models/common-view-model-actions"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const RemoveProject = (self: any) => ({
	removeProject({
		projectId,
		cabinetId,
		handleResponse,
		postWorkForSuccess,
	}: {
		projectId: string
		cabinetId: string
		handleResponse: (props: HandleResponseProps) => void
		// TODO: Consider to integrate all status project to one list...
		postWorkForSuccess: () => void
	}) {
		// 0.
		const actionName = "removeProject"
		// 1.
		// 2.
		// let proceed = window.confirm(
		// 	`Do you really want to delete this project?`
		// )
		// if (!proceed) {
		// 	return
		// }
		handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		/**
		 * REORGANIZE THE INPUTS
		 */
		// ClientId: 22629
		// CabinetId: 14262
		// UserId: 11133
		// Type: Client

		self.deleteProject(projectId, cabinetId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */

					postWorkForSuccess && postWorkForSuccess()
					// TODO: reflect the result with the fetched ID
					// self.popItemFromProjectList(projectId, projectStatus)
					// TODO: delete reflection on the Groups and Projects... How to?
					// TODO: unified view-model? or separated one?
					// TODO: --> separated one seems better
					// self.setOpenDeleteProjDialog(false)
					// set success case response
					handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveProject
