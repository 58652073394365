import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../common-models/enumerations/common-enums"
import {
	LocalFileProps,
	UploadProjFileProps,
} from "../../data-models/file.data-props"
import { initialDLFile } from "../../data-models/initial-file"
import {
	convertExtension,
	convertExtensionToFormat,
	convertSourceFormat,
} from "../../../../library/converters/source-format-converter"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import {
	convertAttention,
	convertSignOffs,
} from "../../proj-files-shared-functions"
import { compareValues } from "../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"
import { formatFileSize } from "@datalobby/common/lib/format-file-size"

/**
 *
 * CreationMode: 1
 * WPEF: refNumName
 * MediaType: Excel
 * WPDesc: FileTitle
 * FinancialYear: 18-Mar-20
 * ParentMediaID: 286608
 * file: (binary)
 * SignOffType: [1]
 *
 */
const UploadFile = (self: any) => ({
	uploadSingleFile(
		projId: string,
		// userId: string,
		fileInfo: UploadProjFileProps,
		multipleUpload?: boolean
	) {
		// 0.
		const actionName = "uploadSingleFile" + fileInfo.tempId
		ConsoleLog("actionName: " + actionName)

		const menuId = self.storeName
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// 3.
		self.requestFileUpload({
			projId,
			fileInfo,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					const fetchedInfo = response.data

					let objectFormat = DLObjectFormat.normalFile

					const srcFormat = convertSourceFormat(
						fetchedInfo.MediaType.toLowerCase(),
						"",
						"file-upload > uploadSingleFile"
					)

					let newItem: any = {}
					if (menuId !== DLOrgSubMenus.proj_templates) {
						// NOTE: for WP, PF
						newItem = {
							...initialDLFile,
							id: idToString(fetchedInfo.WPRID, IdType.file),
							menuId: self.storeName,
							parentId: fileInfo.parentId,
							aliasId: fileInfo.aliasId, //fetchedInfo.WPEF,
							title: fileInfo.title, // fetchedInfo.WPDesc,
							objectFormat: objectFormat,
							status: convertAttention(fetchedInfo.Final),
							//
							prepareList: fetchedInfo.IsPrepared
								? convertSignOffs([fetchedInfo.PreparerList])
								: [],
							reviewList: fetchedInfo.IsReviewed
								? convertSignOffs([fetchedInfo.ReviewerList])
								: [],
							isPrepared: fetchedInfo.IsPrepared,
							isReviewed: fetchedInfo.IsReviewed,
							//
							srcFormat,
							extension: convertExtension(
								fetchedInfo.Extension,
								srcFormat
							), // fetchedInfo.Extension,
							// size: fileInfo.formattedSize,
							// lastModifiedAt: "", // TODO: update is required
							path: fetchedInfo.FilePath,
							url: fetchedInfo.url,
							//
							srcId: idToString(fetchedInfo.MediaID, IdType.src),
							//
							lastModifiedAt: fetchedInfo.LastModifiedBy.Date,
							lastModifiedBy: {
								userAliasId: "",
								userName: fetchedInfo.LastModifiedBy.userName,
								userId: "",
								//
								roleId: "",
								roleName: fetchedInfo.LastModifiedBy.roleName,
							},
						}
					} else {
						// NOTE: for project template
						newItem = {
							...initialDLFile,
							id: idToString(fetchedInfo.TempFileID, IdType.file),
							menuId: self.storeName,
							parentId: fileInfo.parentId,
							aliasId: fileInfo.aliasId, //fetchedInfo.WPEF,
							title: fileInfo.title, // fetchedInfo.WPDesc,
							objectFormat: objectFormat,
							//
							srcFormat,
							extension: convertExtension(
								fetchedInfo.Extension,
								srcFormat
							), // fetchedInfo.Extension,
							size: fetchedInfo.FileSize,
							//
							path: fetchedInfo.FilePath,
							url: fetchedInfo.url,
						}
					}
					//
					self.pushFlatItem(newItem)

					// close the dialog, open the parent folder
					// self.setFileTreeDialogOpen(
					// 	FileScreenDialog.createFile,
					// 	false
					// )
					self.toggleExpanded(fileInfo.parentId, true)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to upload",
						open: false,
					})
				} else {
					// set fail case response
					const serverMsg = response.data.message
						? response.data.message
						: response.data.Message
						? response.data.Message
						: "Something wrong... "
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: serverMsg,
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	uploadMultipleFiles({
		files,
		parentId,
		projId,
	}: {
		files: LocalFileProps[]
		parentId: string
		projId: string
	}) {
		const actionName = "uploadMultipleFiles"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		files.sort(compareValues("aliasId"))

		self.sequentialFileUpload(projId, parentId, files, 0)
	},
	sequentialFileUpload(
		projId: string,
		parentId: string,
		files: any[],
		index: number
	) {
		// 0.
		const file = files[index]
		const fileInfo = {
			tempId: file.tempId,
			title: file.name,
			aliasId: file.aliasId,
			parentId,
			//
			sourceType: convertExtensionToFormat(file.extension),
			extension: file.extension,
			//
			prepared: file.prepared,
			reviewed: file.reviewed,
			//
			file: file.file,
		}
		const actionName = "sequentialFileUpload" + fileInfo.tempId
		const menuId = self.storeName
		const carryOn = index < files.length - 1
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		
		console.log(fileInfo.file.size, "filesize")

		// 3.
		self.requestFileUpload({
			projId,
			fileInfo,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					const fetchedInfo = response.data

					let objectFormat = DLObjectFormat.normalFile

					const srcFormat = convertSourceFormat(
						fetchedInfo.MediaType.toLowerCase(),
						"",
						"file-upload > uploadMultipleFiles"
					)

					let newItem: any = {}
					if (menuId !== DLOrgSubMenus.proj_templates) {
						// NOTE: for WP, PF
						newItem = {
							...initialDLFile,
							id: idToString(fetchedInfo.WPRID, IdType.file),
							menuId: self.storeName,
							parentId: fileInfo.parentId,
							aliasId: fileInfo.aliasId, //fetchedInfo.WPEF,
							title: fileInfo.title, // fetchedInfo.WPDesc,
							objectFormat: objectFormat,
							status: convertAttention(fetchedInfo.Final),
							//
							prepareList: fetchedInfo.IsPrepared
								? convertSignOffs([fetchedInfo.PreparerList])
								: [],
							reviewList: fetchedInfo.IsReviewed
								? convertSignOffs([fetchedInfo.ReviewerList])
								: [],
							isPrepared: fetchedInfo.IsPrepared,
							isReviewed: fetchedInfo.IsReviewed,
							//
							srcFormat,
							extension: convertExtension(
								fetchedInfo.Extension,
								srcFormat
							), // fetchedInfo.Extension,
							size: formatFileSize(fileInfo.file.size),
							// lastModifiedAt: "", // TODO: update is required
							path: fetchedInfo.FilePath,
							url: fetchedInfo.url,
							//
							srcId: idToString(fetchedInfo.MediaID, IdType.src),
							//
							lastModifiedAt: fetchedInfo.LastModifiedBy.Date,
							lastModifiedBy: {
								userAliasId: "",
								userName: fetchedInfo.LastModifiedBy.userName,
								userId: "",
								//
								roleId: "",
								roleName: fetchedInfo.LastModifiedBy.roleName,
							},
						}
					} else {
						// NOTE: for project template
						newItem = {
							...initialDLFile,
							id: idToString(fetchedInfo.TempFileID, IdType.file),
							menuId: self.storeName,
							parentId: fileInfo.parentId,
							aliasId: fileInfo.aliasId, //fetchedInfo.WPEF,
							title: fileInfo.title, // fetchedInfo.WPDesc,
							objectFormat: objectFormat,
							//
							srcFormat,
							extension: convertExtension(
								fetchedInfo.Extension,
								srcFormat
							), // fetchedInfo.Extension,
							size: formatFileSize(fileInfo.file.size),
							//
							path: fetchedInfo.FilePath,
							url: fetchedInfo.url,
						}
					}
					
					console.log(newItem)

					self.pushFlatItem(newItem)

					// self.toggleExpanded(fileInfo.parentId, true)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to upload",
						open: false,
					})
				} else {
					// set fail case response
					const serverMsg = response.data.message
						? response.data.message
						: response.data.Message
						? response.data.Message
						: "Something wrong... "
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: serverMsg,
						open: false,
					})
				}
				if (carryOn) {
					self.sequentialFileUpload(
						projId,
						parentId,
						files,
						index + 1
					)
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
				if (carryOn) {
					self.sequentialFileUpload(
						projId,
						parentId,
						files,
						index + 1
					)
				}
			})
	},
})

export default UploadFile

// NOTE: WP, PF response

// AJE: false
// ActualFilePath: ""
// Archive: false
// ClientID: 4388
// Comment: 1
// CreationMode: 0
// * Extension: ".xls"
// * FilePath: "Documents/17-2_744_4388_11082020010455455.xls"
// FileSize: null
// * Final: "Completed"
// FinancialYear: "0001-01-01T00:00:00"
// ? Followup: 1
// Index: 0
// IsLocked: false
// IsModified: false
// IsOpen: false
// * IsPrepared: true
// ? IsQualityControl: false
// * IsReviewed: false
// LastModified: null
// * LastModifiedBy: {roleName: "EP", userName: "epUserId1", Date: "2020-08-11T01:04:56.8438149+00:00"}
// LockedBy: 0
// * MediaID: 286721
// MediaName: null
// * MediaType: "Excel"
// Message: "Workpaper is created successfully"
// OpenedBy: null
// OpenedByUser: null
// PAJE: false
// * ParentMediaID: 286694
// PdfExists: false
// PdfPath: null
// * PreparerList: {UserID: 744, SignOff: null,…}
// * ReviewProcessID: 0
// * ReviewerList: null
// RolesList: null
// SignOff: null
// Stage: "Notstarted"
// Status: 1
// TemplateID: 0
// UserID: 0
// * WPDesc: "samsung_elec_FS"
// * WPEF: "17-2"
// * WPRID: 168741
// file: null
// signOffType: null
// * url: ""
// wprList: null
// let modifiedBy = {
// 	userAliasId: "",
// 	userName:
// 		fetchedInfo.LastModifiedBy.userName === null
// 			? ""
// 			: fetchedInfo.LastModifiedBy.userName,
// 	userId: "",
// 	//
// 	roleId: "",
// 	roleName:
// 		fetchedInfo.LastModifiedBy.roleName === null
// 			? ""
// 			: fetchedInfo.LastModifiedBy.roleName,
// }
