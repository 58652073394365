import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { putRequest } from "../../../../library/api-requests"

const version = process.env.REACT_APP_API_VERSION

export type resetPwApiProps = {
	EmailID: string
}

export type resetPwApiType = (
	paylod: resetPwApiProps
) => Promise<AxiosResponse<any>>

export function resetPassword(payload: resetPwApiProps) {
	const endpoint = "/ForgotPassword"
	const v2_prefix = "/security/Token"

	const response = putRequest({
		url: version === "v2" ? v2_prefix + endpoint : endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.none,
		version,
	})
	// TODO: this API return user detail. (userID, role, language, accessType... )
	// TODO: It seems not required and not safety..
	return response
}

export default resetPassword
