import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const RequestDownloadFile = (
	self: any,
	downloadFile: FileSystemApiTypes["apiDownloadFile"]
) => ({
	requestDownloadFile: flow(function* (fileId: string) {
		const actionName = "requestDownloadFile"
		const menuId = self.storeName
		// 1.
		if (fileId === undefined || fileId === "") {
			alert(`(${actionName}) undefined props exist. fileId: ${fileId}`)
			return
		}
		//
		//
		// 2.
		const srcId = self.getItemById(fileId).srcId
		const fileNumId = idToNumber(fileId, IdType.file)
		const srcNumId = idToNumber(srcId, IdType.src)
		//
		let type: any = undefined
		if (menuId === DLProjSubMenus.wp) {
			type = 1
		} else if (menuId === DLProjSubMenus.pf) {
			type = 2
		}
		//
		//
		// 3.
		let params: any = {
			FileID: srcNumId,
			Type: type, // 1: wp, 2: pf
		}
		if (menuId === DLOrgSubMenus.proj_templates) {
			params = {
				FileID: fileNumId,
			}
		}

		if (
			(menuId === DLOrgSubMenus.proj_templates &&
				typeof fileNumId === "number") ||
			(menuId !== DLOrgSubMenus.proj_templates &&
				typeof srcNumId === "number")
		) {
			try {
				const response = yield downloadFile({ params, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid prop exist. ${fileNumId} | menu: ${menuId}`
			)
		}
	}),
})

export default RequestDownloadFile
