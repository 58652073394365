import { types } from "mobx-state-tree"
import { Refresh } from "../../../common-models/common-view-model-actions"
import { ConsoleLog } from "../../../components/basic-elements"
import {
    ClickPoint,
    ClickPointProps,
} from "../../../common-models/types/dialog.props"

const OrgSetupGroupsUiHelper = types
    .model({
        selectedGroup: types.string,
        selectedGroupType: types.string,
        clickPoint: ClickPoint,
        // dialogs
        addGroupDialogOpen: types.boolean,
        editGroupDialogOpen: types.boolean,
        assignUsersToGroupDialogOpen: types.boolean,
        assignClientsToGroupDialogOpen: types.boolean,
        removeGroupDialogOpen: types.boolean,
        unAssignUsersToGroupDialogOpen: false,
        showAssignedDetailsDialog: false,
    })
    .actions((self) => ({
        setSelectedGroup(groupId: string) {
            self.selectedGroup = groupId
        },
        setSelectedGroupType(type: "Users" | "Clients") {
            self.selectedGroupType = type
        },
        setClickPoint(clickPoint: ClickPointProps) {
            self.clickPoint = clickPoint
        },
        // dialogs
        setOpenAddGroupDialog(request?: boolean) {
            self.addGroupDialogOpen = request ?? !self.addGroupDialogOpen
        },
        setEditGroupDialogOpen(request?: boolean) {
            self.editGroupDialogOpen = request ?? !self.editGroupDialogOpen
        },
        setAssignUsersToGroupDialogOpen(request?: boolean) {
            self.assignUsersToGroupDialogOpen =
                request ?? !self.assignUsersToGroupDialogOpen
        },
        setAssignClientsToGroupDialogOpen(request?: boolean) {
            self.assignClientsToGroupDialogOpen =
                request ?? !self.assignClientsToGroupDialogOpen
        },
        setRemoveGroupDialogOpen(request?: boolean) {
            self.removeGroupDialogOpen = request ?? !self.removeGroupDialogOpen
        },
        setUnAssignUsersToGroupDialogOpen(request?: boolean) {
            self.unAssignUsersToGroupDialogOpen =
                request ?? !self.unAssignUsersToGroupDialogOpen
        },
        setShowAssignedDetailsDialog(request?: boolean) {
            self.showAssignedDetailsDialog =
                request ?? !self.showAssignedDetailsDialog
        },
    }))

export default OrgSetupGroupsUiHelper
