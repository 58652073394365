import React from "react"
import { Avatar } from "@material-ui/core"
import Icon from "@mdi/react"
import { mdiAccount } from "@mdi/js"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export default observer(function LobbySideProfileButton() {
	const store = useRootStore()

	return (
		<div className="FR AC profile-btn">
			<Avatar className="user-avatar">
				<Icon path={mdiAccount} size={0.8} color="white" />
			</Avatar>
		</div>
	)
})
