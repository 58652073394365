import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"

export function handleDownloadExcelTemplate(columns: { [x: string]: any }[], excelName: string) {
	const fileType =
		"application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"

	console.log("columns:", columns)

	const worksheet = XLSX.utils.json_to_sheet(columns)
	const workbook = {
		Sheets: { Sheet1: worksheet },
		SheetNames: ["Sheet1"],
	}
	const excelBuffer = XLSX.write(workbook, {
		bookType: "xlsx",
		type: "array",
	})
	const data = new Blob([excelBuffer], { type: fileType })
	FileSaver.saveAs(data, `${excelName}.xlsx`)
}
