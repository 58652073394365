import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../../common-models/common-model-actions"
// separated actions
import ReadEmailNotificationSetting from "./model-actions/read-email-notification-setting"
import UpdateEmailNotificationSetting from "./model-actions/update-email-notification-setting"
import { OrgSysSettingApiTypes } from "../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"

const OrgSysSettingItemModel = ({
	apiRead,
	apiUpdate,
}: OrgSysSettingApiTypes) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => ReadEmailNotificationSetting(self, apiRead))
		.actions((self) => UpdateEmailNotificationSetting(self, apiUpdate))
		// common model actions
		.actions(CommonModelActions)

export default OrgSysSettingItemModel
