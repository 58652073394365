// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetFilteredUsers = (self: any) => ({
	getFilteredUsers(type: string) {
		// 0.
		const actionName = "getUsers"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		const payload = {
			type: type === "internal" ? 1 : 2,
			searchText: "",
		}
		self.readFilteredUsers(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					// empty existing stored list
					// ConsoleLog(response.data)
					self.resetSearchAccessMgmtUserList()
					// re-organize response data
					const userList = response.data.userList
					userList.map((user: any) => {
						const reOrganizedUsers = {
							email: user.Email,
							isGrayedOut: user.IsGrayedOut,
							name: user.Name,
							userId: idToString(user.UserId, IdType.user), // TODO: @Prudhvi-0817: Please use idToString
							userType:
								user.UserType === 1 ? "Internal" : "External", // TODO: @Prudhvi-0817: Please use 'internal' | 'external'
							userAliasId: user.UserName,
						}
						self.pushItemToSearchAccessMgmtUserList(
							reOrganizedUsers
						)
					})
				}

				// success case response
				self.handleResponse({
					actionName,
					status: ActionStatus.success,
					code: 200, // temp
					customMessage: "Successfully get ...",
					color: MessageColorType.blue,
					open: false,
				})
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetFilteredUsers
