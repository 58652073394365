import { flow } from "mobx-state-tree"

const VerifyCode = (self: any, verify: any) => ({
	verifyCode: flow(function* ({
		email,
		code,
	}: {
		email: string
		code: number
	}) {
		const actionName = "verifyCode"
		const payload = {
			UserName: email,
			AccessCode: code,
		}
		try {
			const response = yield verify(payload)
			/**
			 * response cases
			 * case 1: (200 but fail) (alert) Your verification code has been expired. Please click on OK to resend code to your email.
			 */

			// console.log("verifyCode response", response)
			return response
		} catch (error) {
			// console.error("ve2rifyCode error", error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default VerifyCode
