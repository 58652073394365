import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import MapUnmapDialogForm from "./MapUnmapDialogForm"
import { DialogType } from "../../store/data-models/fs-setup.data-model"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function MapUnmapGroupDialog({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()
	const actionName = "getAvailableFinGroups"

	const unMapHandler = (fsGroupId: number) => {
		const details = projStore.fsSetup
			.viewFSGroups()
			.find((item: any) => item.id === fsGroupId)
		if (details) {
			projStore.fsSetup.unmapGroupFromFSType(details.mapId)
		}
	}

	const mapHandler = (fsGroupId: number) => {
		const typeId = projStore.fsSetup.selectedTypeId
		projStore.fsSetup.mapGroupToFSType(fsGroupId, typeId)
	}

	const createGroup = (groupName: string) => {
		const projId = projStore.checkin.projId
		projStore.fsSetup.addFinancialGroup(groupName, projId)
	}

	const updateGroup = (fsGroupName: string, fsGroupId: number) => {
		projStore.fsSetup.editFinancialGroup(fsGroupName, fsGroupId)
	}

	const deleteGroup = (fsGroupId: number) => {
		let proceed = window.confirm(`Are you sure you want to delete?`)
		if (proceed) {
			projStore.fsSetup.removeFinancialGroup(fsGroupId)
		} else {
			return
		}
	}

	return (
		<DLDialog
			eleTestId="map-unmap-group-dialog"
			draggable
			isOpen={projStore.fsSetup.mapUnmapGroupDialogOpen}
			setIsOpen={projStore.fsSetup.setMapUnmapGroupDialogOpen}
			showCloseBtn={true}
			dialogTitle="Map/Un-map groups to Financial Statement Type"
			dialogContents={
				<MapUnmapDialogForm
					permission={permission}
					mapHandler={mapHandler}
					unMapHandler={unMapHandler}
					availableItems={projStore.fsSetup.viewAvailableGroups()}
					mappedItems={projStore.fsSetup.viewFSGroups()}
					dialogType={DialogType.group}
					handleCreate={createGroup}
					handleUpdate={updateGroup}
					handleDelete={deleteGroup}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			// dialogError={
			// 	orgStore.setupClients.getActionStatus(actionName) !==
			// 	"SUCCESS"
			// 		? orgStore.setupClients.responses.getResponse(
			// 				actionName
			// 		  )?.message
			// 		: undefined
			// }
			showSpinner={
				projStore.fsSetup.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				projStore.fsSetup.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
