import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const data = {
	// ---------- 3. 직전 사업연도 재무 정보
	prevfinancialYear: 2018,
	prevPeriodEndDate: "2018-12-31T23:59:59.000Z",
	prevIndvRevenue: 0, // 개별 매출액
	prevIndvTotalAssets: 300000000000, // 개별 자산총액
	prevIndvAcntReceivable: 0, // 개별 매출채권(비금융업)
	prevIndvLoans: null, // 개별 대출채권(금융업)
	prevIndvInventories: 0, // 개별 재고자산
	//
	prevCnslRevenue: 300000000000, // 연결 매출액
	prevCnslTotalAssets: 500000000000, // 연결 자산총액
	prevCnslAcntReceivable: 0, // 연결 매출채권
	prevCnslLoans: null, // 연결 대출채권(금융업)
	prevCnslInventories: 0, // 연결 재고자산
	prevAuditHours: 600, // 직전 사업연도 감사시간
	prevAuditSkillLv: 1, // 직전 사업연도 감사인 숙련도
	//
	prevSumAffiliateBookAmount: 0, // 관계회사 장부기재금액합
	prevSumSubsidiaryInternalTransactionAmount: 0, // 종속회사 내부거래금액합
	prevSumSubsidiarySize: 0, // 종속회사 기업규모 합
}

export default observer(function PrevFinancialInfo() {
	const orgStore = useOrgStore()

	return (
		<StyledPrevFinancialInfo>
			Previous Financial Information
		</StyledPrevFinancialInfo>
	)
})

const StyledPrevFinancialInfo = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
