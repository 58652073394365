import React from "react"
import TextField from "@material-ui/core/TextField"
import styled from "styled-components"

interface DLTextFieldProps {
	// required props
	eleTestId: string
	// basic props
	eleDefaultValue?: string | number
	elePlaceholder?: string
	// additionals
	eleValue?: any
	eleLabel?: string
	eleHelperText?: string
	eleReadOnly?: boolean
	eleRequired?: boolean
	// control related
	eleOnChange?: any
	eleId?: string
	// style related
	eleClassName?: string
	eleWidth?: string | number
	eleFullWidth?: boolean
	// pending
	autoFocus?: any
	type?: string
	eleWarningType?: "default" | "warning" | "critical"
	variant?: "standard" | "outlined" | "filled"
}

/**
 * #### Note
 * - Do not set the `defaultValue` to take the state value
 * - `defaultValue` and `placeholder` are different
 * - You can control the input value by `eleValue` but do not use it when you take the user input
 * - <strong>DO NOT</strong> use together `eleValue` and `eleOnChange`
 *
 * TODO:
 * - eleRequired needs update
 */

function DLTextField({
	// required props
	eleTestId = "testId-DLTextField",
	// basic props
	eleDefaultValue = undefined,
	elePlaceholder = undefined,
	// additionals
	eleValue = undefined,
	eleLabel = undefined,
	eleHelperText = undefined,
	eleReadOnly = undefined,
	eleRequired = undefined,
	// control related
	eleOnChange = undefined,
	eleId = undefined,
	// style related
	eleClassName = undefined,
	eleWidth = undefined,
	eleFullWidth = undefined,
	// pending
	autoFocus = undefined,
	type = undefined,
	eleWarningType = undefined,
	variant = "standard",
}: DLTextFieldProps) {
	return (
		<StyledTextbox>
			<TextField
				required={eleRequired}
				id={eleId}
				label={eleLabel}
				defaultValue={eleDefaultValue}
				className={
					eleReadOnly && eleRequired
						? "custom-text-field custom-readonly custom-required"
						: eleReadOnly
						? "custom-text-field custom-readonly"
						: eleRequired
						? "custom-text-field custom-required"
						: eleWarningType
						? `custom-text-field ${eleWarningType}`
						: "custom-text-field"
				}
				value={eleValue && eleValue}
				placeholder={elePlaceholder}
				onChange={eleOnChange}
				disabled={eleReadOnly}
				fullWidth={eleFullWidth}
				data-testid={eleTestId}
				helperText={eleHelperText}
				style={{ width: eleWidth }}
				variant={variant}
			/>
		</StyledTextbox>
	)
}

const StyledTextbox = styled.div`
	.custom-text-field {
		&.custom-readonly {
			input {
				cursor: not-allowed;
			}
		}
		&.custom-required {
			label {
				/* color: ${(props) => props.theme.warning}; */
			}
		}
		&.warning {
			input {
				color: orange;
				border-bottom: 2px solid orange;
				z-index: 999;
			}
		}
		&.critical {
			input {
				color: red;
				border-bottom: 2px solid red;
				z-index: 999;
			}
		}
		span {
			color: ${(props) => props.theme.warning};
			font-weight: 900;
		}
	}
`

export default DLTextField
