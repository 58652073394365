import { types } from "mobx-state-tree"
// common models

// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import ReadOrgFileLibFolders from "./model-actions/read-org-file-lib-folders"
import ReadOrgApTemplateDetails from "./model-actions/read-org-ap-template-details"
import ReadOrgWpTemplateDetails from "./model-actions/read-org-wp-template-details"
import CreateOrgLibraryFolder from "./model-actions/library-folder-actions/create-org-library-folder"
import UpdateLibrayFolder from "./model-actions/library-folder-actions/update-org-library-folder"
import DeleteOrgLibrayFolder from "./model-actions/library-folder-actions/delete-org-library-folder"
import CopyOrgLibraryTemplate from "./model-actions/copy-org-template"
import CreateOrgLibraryTemplate from "./model-actions/library-template-actions/create-org-library-template"
import UpdateOrgLibraryTemplate from "./model-actions/library-template-actions/update-org-library-template"
import DeleteOrgLibraryTemplate from "./model-actions/library-template-actions/delete-org-library-template"
import { OrgFileLibApiProps } from "./org-file-lib-api-props"

const OrgFileLibModel = (props: OrgFileLibApiProps) =>
	types
		.model({})

		// 1. Library Folder
		.actions((self, createLibraryFolder = props.createLibraryFolder) =>
			CreateOrgLibraryFolder(self, createLibraryFolder)
		)
		.actions((self, readLibraryFolders = props.readLibraryFolders) =>
			ReadOrgFileLibFolders(self, readLibraryFolders)
		)
		.actions((self, updateLibraryFolder = props.updateLibraryFolder) =>
			UpdateLibrayFolder(self, updateLibraryFolder)
		)
		.actions((self, deleteLibraryFolder = props.deleteLibraryFolder) =>
			DeleteOrgLibrayFolder(self, deleteLibraryFolder)
		)
		.actions((self, copyLibraryTemplate = props.copyLibraryTemplate) =>
			CopyOrgLibraryTemplate(self, copyLibraryTemplate)
		)
		.actions((self, createLibraryTemplate = props.createLibraryTemplate) =>
			CreateOrgLibraryTemplate(self, createLibraryTemplate)
		)
		.actions((self, updateLibraryTemplate = props.updateLibraryTemplate) =>
			UpdateOrgLibraryTemplate(self, updateLibraryTemplate)
		)
		.actions((self, deleteLibraryTemplate = props.deleteLibraryTemplate) =>
			DeleteOrgLibraryTemplate(self, deleteLibraryTemplate)
		)
		.actions(ReadOrgApTemplateDetails)
		.actions(ReadOrgWpTemplateDetails)
		// Common model actions
		.actions(CommonModelActions)

export default OrgFileLibModel
