import React from "react"
import styled from "styled-components"
import {
	DLChip,
	DLSearchBox,
	DLInputField,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { DLAvatar } from "@datalobby/components"
import { mdiEye, mdiTools, mdiMagnify } from "@mdi/js"
import Icon from "@mdi/react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"

export default function OrgRoleCard({ role }: { role: any }) {
	ConsoleLog(" ====***** OrgRoleCard *****===== ")
	const orgStore = useOrgStore()

	let accessibleMenus: any[] = []
	role.menuPermissions.map((menu: any) => {
		// if (
		// 	menu.actions.find((element: any) => element.actionName === "read")
		// 		.available === true
		// ) {
		// 	accessibleMenus.push(menu)
		// }
	})

	return (
		<StyledOrgRoleCard>
			<div onClick={() => orgStore.orgRoles.setSelectedRole(role.roleId)}>
				<div className="FR AC role-icon">
					<DLAvatar
						text={role.shortName}
						color={role.color}
						eleTestId={`role-icon-${role.roleId}`}
						fontSize={12}
						variant="square"
					/>
					<div
						className={`count ${
							role.users.length > 0 && "have-users"
						}`}
					>
						x {role.users.length}
					</div>
				</div>
				<div className="role-name heading3">{role.name}</div>
				<div className="high-priority-permissions">
					{/* TODO: add views for global Permission chips */}
					{role.globalPermissions.map(
						(item: { actionName: string; available: boolean }) => {
							if (item.available) {
								return (
									<div key={item.actionName}>
										<DLChip
											label={item.actionName}
											eleTestId="readonly-chip"
											icon={
												<Icon
													path={mdiEye}
													size={0.6}
												/>
											}
										/>
									</div>
								)
							}
						}
					)}
				</div>
				<div className="menus">
					Available Menus:
					{/* {accessibleMenus.length} */}
				</div>
			</div>
			<div className="search-user">
				<DLInputField
					eleTestId={`search-${role.shortName}-user`}
					eleType="search"
					elePlaceholder="Search user"
					eleStartAdornment={<Icon path={mdiMagnify} size={0.8} />}
				/>
			</div>
			<div className="users">
				{role.users.map((user: any) => {
					return (
						<div key={user.id}>
							<DLChip
								label={user.name}
								eleTestId={`username-chip-${user.name}`}
								variant="outlined"
							/>
						</div>
					)
				})}
			</div>
		</StyledOrgRoleCard>
	)
}

const StyledOrgRoleCard = styled.div`
	width: 12rem;
	min-height: 10rem;
	border-radius: 3px;
	padding: 1rem;
	box-shadow: 0px 2px 4px 1px ${(props) => props.theme.shadowColorLight};
	margin: 1rem;
	background-color: ${(props) => props.theme.background};
	transition: 0.4s;
	border-top: 3px solid ${(props) => props.theme.emphasis};
	cursor: pointer;
	.role-icon {
		.count {
			margin-left: 0.5rem;
			color: ${(props) => props.theme.shade20};
			&.have-users {
				font-weight: 500;
				color: ${(props) => props.theme.textHighEmphasis};
			}
		}
	}
	.role-name {
		min-height: ${(props) => props.theme.shared.baseUnit};
		margin: 0.5rem 0 1rem;
	}
	.high-priority-permissions {
		min-height: 4rem;
	}
	.menus {
		padding-bottom: 1rem;
	}
	.search-user {
		/* border-top: 1px solid ${(props) => props.theme.shade20}; */
		margin-bottom: 1rem;
	}
	.users {
		padding-top: 1rem;
		height: 16rem;
		overflow: hidden;
		overflow-y: scroll;
	}
	:hover {
		background-color: ${(props) => props.theme.secondary};
	}
`
