import { DLFileControlModel } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
//
import FlatListActions from "../../../../../service-modules/file-module/view-model-actions/flat-list-actions"
import FlatListViews from "../../../../../service-modules/file-module/view-model-actions/flat-list-views"
//
import UiHelperActions from "../../../../../service-modules/file-module/ui-helper-actions/ui-helper.actions"
import UiHelperViews from "../../../../../service-modules/file-module/ui-helper-actions/ui-helper.views"
import SelectedItemsUiActions from "../../../../../service-modules/file-module/ui-helper-actions/file-selected-items-ui.actions"
import SelectedItemsUiViews from "../../../../../service-modules/file-module/ui-helper-actions/file-selected-items-ui.views"

//
import {
	AddFolder,
	EditFolder,
	RemoveFolder,
	ReorderChildren,
	SortChildren,
} from "../../../../../service-modules/file-module/view-model-actions/file-folder"
import {
	AddFile,
	EditFile,
	OpenFileWithoutEditor,
	RemoveFile,
	ReplaceFile,
	UploadFile,
} from "../../../../../service-modules/file-module/view-model-actions/file"
import { DownloadFile, DownloadFolder, DownloadAll } from "../../../../../service-modules/file-module/view-model-actions/download"
import AssignRoleToFile from "../../../../../service-modules/file-module/view-model-actions/assign/assign-role-to-file"

const ProjTemplateFileControl = DLFileControlModel.actions((self) => ({})) // This actions is just placeholder for alignment... :)
	// default action for flatList
	// 2. folder related actions
	.actions(AddFolder)
	.actions(EditFolder)
	.actions(RemoveFolder)
	// 3. file related actions
	.actions(AddFile)
	.actions(EditFile)
	.actions(OpenFileWithoutEditor)
	.actions(RemoveFile)
	.actions(ReplaceFile)
	.actions(UploadFile)
	// 4. file library
	// 5. assign
	.actions(AssignRoleToFile)
	// 6. sign off
	// 7. re-order
	// 8. download
	.actions(DownloadFile)
	.actions(DownloadFolder)
	.actions(DownloadAll)
	//
	.actions(SortChildren)
	.actions(ReorderChildren)
	// * Control
	// for flat list
	.actions(FlatListActions)
	.views(FlatListViews)
	// for selection
	.actions(SelectedItemsUiActions)
	.views(SelectedItemsUiViews)
	// for UI
	.actions(UiHelperActions)
	.views(UiHelperViews)

export default ProjTemplateFileControl

/**
 * 1. get flat list
 * ---- getFirstFolders
 * ---- getChildrenOfFolder
 *
 * 2. folder related actions
 * ---- add folder
 * ---- edit folder
 * ---- duplicate folder
 * ---- remove folder
 *
 * 3. file related actions
 * ---- add file
 * ---- edit file
 * ---- open file
 * ---- duplicate file
 * ---- replace file
 * ---- upload file
 * ---- edit status (=attention)
 * ---- remove file
 *
 * 4. file library
 * ---- add file by library
 *
 * 5. assign
 * ---- assign role to file
 * ---- assign user to file
 *
 * 6. sign off
 * ---- single sign off
 * ---- multiple sign off
 * ---- un sign off
 * ---- + related view
 *
 * 7. re-order
 * ---- sort children
 * ---- re-order
 *
 * 8. download
 * ---- download all as zip
 * ---- download folder
 * ---- download file
 *
 * 9. for flat list
 * ---- flat list actions
 * ---- flat list views
 *
 * 10. for selection
 * ---- select actions
 * ---- select views
 *
 * 11. for UI
 * ---- ui actions
 * ---- ui views
 *
 */
