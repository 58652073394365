import { ConsoleLog } from "../components/basic-elements"
import { idToString, IdType } from "./converters/id-converter"

export function saveLatestProjToken(token: any) {
	// NOTE: Latest Token is only for the relay admin action in the org side
	localStorage.setItem("latestProjToken", JSON.stringify(token))
}

export function setLatestProjToken(projId: string | number) {
	// NOTE: Latest Token is only for the relay admin action in the org side
	const token = findProjToken(projId)
	if (token) {
		console.log(
			"success to find the token and set it as latestProjToken",
			token
		)
		localStorage.setItem("latestProjToken", JSON.stringify(token))
	} else {
		return null
	}
}

export function saveProjTokens(projId: string, token: any) {
	console.log("tokens___ 1. saveProjTokens")
	let tokensMap = localStorage.getItem("projTokens")
	console.log("tokens___ 2. tokensMap:", tokensMap)

	if (tokensMap) {
		const map = new Map(JSON.parse(tokensMap))
		const prevToken = map.get(projId)

		if (prevToken) {
			console.log("tokens___ it has prevToken already")
			// compare the previous one and current one
			if (prevToken === token) {
				//
			} else {
				console.log(
					"tokens___ prevToken is different with current one. Update..."
				)
				map.set(projId, token)
			}
		} else {
			console.log("tokens___ no prevToken")
			map.set(projId, token)
			console.log("tokens___ updated map", map)
			localStorage.setItem(
				"projTokens",
				JSON.stringify(Array.from(map.entries()))
			)
		}
	} else {
		const newTokensMap = new Map()
		newTokensMap.set(projId, token)
		console.log("tokens___ 6. newTokensMap", newTokensMap)
		// const newToken = { projId, token }
		localStorage.setItem(
			"projTokens",
			JSON.stringify(Array.from(newTokensMap.entries()))
		)
	}
}

export function findProjToken(projId: string | number) {
	const tokensFromLocalStorage = localStorage.getItem("projTokens")

	if (tokensFromLocalStorage) {
		const tokensMap = new Map<string, string>(
			JSON.parse(tokensFromLocalStorage)
		)
		let projStringId = ""
		// NOTE: in the tokensMap, project IDs have prefix and the type is string
		if (typeof projId === "number") {
			projStringId = idToString(projId, IdType.project)
		} else {
			projStringId = projId
		}
		const token = tokensMap.get(projStringId)
		if (token) {
			console.log("token exist for", projId)
			return token
		} else {
			console.log("target does not exist for", projId)
			return null
		}
	} else {
		ConsoleLog("No project tokens exist for ", projId)
		return null
	}
}

/**
 *  WARNING: Use below function for
 * login, logout, login-screen
 */
export function clearLocalStorageForLobby() {
	// --- lobby related parts
	localStorage.removeItem("awsToken")
	localStorage.removeItem("loginToken")
	localStorage.removeItem("lobbyUserId")
	//
	localStorage.removeItem("isAdminAppUser")
	// --- org related parts
	localStorage.removeItem("orgToken")
	localStorage.removeItem("orgId")
	localStorage.removeItem("orgRoleId")
	localStorage.removeItem("orgName")
	localStorage.removeItem("orgUserId")
	localStorage.removeItem("orgUserName")
	// below things are for relay actions in the org side
	localStorage.removeItem("latestProjId")
	localStorage.removeItem("latestProjToken")

	/**
	 * Project Tokens must be removed on the
	 * login & logout, checkin & checkout of organization, org list
	 */
	localStorage.removeItem("projTokens")
}

/**
 *  WARNING: Use below function for
 * org-list, org-checkout
 * + Do not use this on the org-list screen. It will display empty screen
 * + Do not use this on the org-checkin because it is also used for same org
 */
export function clearLocalStorageForOrg() {
	// --- org related parts
	localStorage.removeItem("orgToken")
	localStorage.removeItem("orgId")
	localStorage.removeItem("orgRoleId")
	localStorage.removeItem("orgName")
	localStorage.removeItem("orgUserId")
	localStorage.removeItem("orgUserName")
	// below things are for relay actions in the org side
	localStorage.removeItem("latestProjId")
	localStorage.removeItem("latestProjToken")

	/**
	 * Project Tokens must be removed on the
	 * login & logout, checkin & checkout of organization, org list
	 */
	localStorage.removeItem("projTokens")
}
