import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

const RequestDuplicateProjTemplateFile = (self: any, duplicateFile: any) => ({
	requestDuplicateProjTemplateFile: flow(function* (fileId: string) {
		const actionName = "requestDuplicateProjTemplateFile"

		const fileNumberId = idToNumber(fileId, IdType.file)
		if (typeof fileNumberId === "number") {
			try {
				let params = {
					SourceId: fileNumberId,
					Type: 4, // 4 = single file
				}
				const response = yield duplicateFile(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(`fileId has invalid format. ${fileNumberId}`)
		}
	}),
})

export default RequestDuplicateProjTemplateFile
