import { types, applySnapshot } from "mobx-state-tree"
import { Responses, ResponseSnackbar } from "../../../../../common-models"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	ChecklistTemplateDetailModel,
	ChecklistTemplateModel,
} from "./org-setup-checklist.data-model"
import { initialStore } from "./org-setup-checklist.provider"
import GetChecklistTemplates from "./view-model-actions/get-checklist-templates"
import { getTzDate } from "../../../../../library/converters/date-utc-converter"

export const OrgSetupChecklistViewModel = types
	.model({
		checklistTemplates: types.array(ChecklistTemplateModel),
		checklistTemplateDetail: ChecklistTemplateDetailModel,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// actions
	.actions((self) => ({
		// ---------- manage the list
		setChecklistTemplates(templates: any) {
			self.checklistTemplates.length = 0
			self.checklistTemplates = templates
		},
		setChecklistTemplateDetail(data: any) {
			self.checklistTemplateDetail = data
		},
	}))
	.actions(GetChecklistTemplates)
	.views((self) => ({
		formattedChecklist(dateFormat: string, timeZone: string) {
			let formattedList: any[] = []

			self.checklistTemplates.map((item: any) => {
				const formattedCreatedAt = item.createdAt
					? getTzDate({
							date: item.createdAt,
							timeZone,
							dateFormat,
					  })
					: ""
				formattedList.push({
					...item,
					createdAt: formattedCreatedAt,
				})
			})

			return formattedList
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)
