import { flow } from "mobx-state-tree"

const ReadChecklistCollections = (
	self: any,
	readChecklist: any
) => ({
	readChecklistCollections: flow(function* (orgId: string) {
		const actionName = "readChecklists"
		try {
			const response = yield readChecklist(orgId)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadChecklistCollections
