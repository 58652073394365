import { types } from "mobx-state-tree"

// * (next item) period setup
// * usage case
// - project create dialog
// - project information update dialog

export const Period = types.model({
	id: types.string,
	title: types.string,
})
