import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import OpenedFilesOnDrawerSnackbar from "./sub-components/OpenedFilesOnDrawerSnackbar"
import Icon from "@mdi/react"
import { mdiFileExcel, mdiFilePdf, mdiFileWord, mdiClose } from "@mdi/js"
import {
	DLRadioGroup,
	DLIconButton,
	DLButton,
	DLBadge,
	DLSpinner,
	ConsoleLog,
} from "../../../components/basic-elements/index"
import styled from "styled-components"
import { Tabs, Tab } from "@material-ui/core"
import {
	MessageColorType,
	DLSrcFormat,
} from "../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { checkFileProps } from "../../../service-modules/file-module/check-file-props-to-open"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function OpenedFilesOnDrawer() {
	const store = useRootStore()
	const moduleId = "GlobalNotis"
	const partialStore = store.notis

	const [status, setStatus] = useState(0) // 0->opened, 1->requested
	const [feature, setFeature] = useState("workpaper")
	const [loaderActionName, setLoaderActionName] =
		useState("getWPNotifications")
	const handleStatus = (event: any, value: any) => {
		setStatus(value)
	}

	const handleFeature = (event: any) => {
		setStatus(0) // Default make Opened File tab open
		setFeature(event.target.value)
		// if (event.target.value === "permanent") {
		// 	partialStore.getPfFileCheckinrequest()
		// }
		// if (event.target.value === "workpaper") {
		// 	partialStore.getWpFileCheckinRequests()
		// }
	}

	const clearAllNotifications = () => {
		let proceed = window.confirm(
			"Unsaved content can be lost by close. Do you really want to close all ?"
		)
		if (proceed) {
			if (status === 0 && feature === "workpaper") {
				ConsoleLog("workpaper opened")
				partialStore.removeAllWorkPaperOpenedNotification()
			}
			if (status === 0 && feature === "permanent") {
				ConsoleLog("permanent opened")
				partialStore.removeAllPermanentOpenedNotification()
			}
			if (status === 1 && feature === "workpaper") {
				ConsoleLog("workpaper requested")
				partialStore.removeAllWorkPaperRequestedNotification()
			}
			if (status === 1 && feature === "permanent") {
				ConsoleLog("permanent requested")
				partialStore.removeAllPermanentRequestedNotification()
			}
		}
	}

	const clearSingleNotification = (mediaId: any) => {
		let proceed = window.confirm(
			"Unsaved content can be lost by close. Do you really want to close ?"
		)
		if (proceed) {
			if (status === 0 && feature === "workpaper") {
				ConsoleLog("workpaper opened")
				partialStore.removeSingleWorkPaperOpenedNotification(mediaId)
			}
			if (status === 0 && feature === "permanent") {
				ConsoleLog("permanent opened")
				partialStore.removeSinglePermanentOpenedNotification(mediaId)
			}
			if (status === 1 && feature === "workpaper") {
				ConsoleLog("workpaper requested")
				partialStore.removeSingleWorkPaperRequestedNotification(mediaId)
			}
			if (status === 1 && feature === "permanent") {
				ConsoleLog("permanent requested")
				partialStore.removeSinglePermanentRequestedNotification(mediaId)
			}
		}
	}

	useEffect(() => {
		if (status === 0 && feature === "workpaper") {
			setLoaderActionName("getCheckedOutWpFiles")
			partialStore.getCheckedOutWpFiles()
		}
		if (status === 0 && feature === "permanent") {
			setLoaderActionName("getCheckedOutPfFiles")
			partialStore.getCheckedOutPfFiles()
		}
		if (status === 1 && feature === "workpaper") {
			setLoaderActionName("getWpFileCheckinRequests")
			partialStore.getWpFileCheckinRequests()
		}
		if (status === 1 && feature === "permanent") {
			setLoaderActionName("getPfFileCheckinrequest")
			partialStore.getPfFileCheckinrequest()
		}
	}, [status, feature])

	const handleDoubleClickFile = (event: any, fileInfo: any) => {
		event.preventDefault()
		const orgId = localStorage.getItem("orgId") ?? "X"
		const orgUserId = localStorage.getItem("orgUserId") ?? "X"
		console.log(fileInfo, orgId, orgUserId)
		const projId = fileInfo.projectId
		const fileProps = checkFileProps({
			orgId,
			projId,
			fileInfo: {
				menuId:
					feature === "workpaper"
						? DLProjSubMenus.wp
						: DLProjSubMenus.pf,
				...fileInfo,
			},
			userInfo: {
				id: orgUserId,
				isReadonly: false, // @NOAH Please double check
				isExternal: false, // @NOAH Please double check
			},
			asReadonly: false,
		})

		if (fileProps && fileProps.useEditor) {
			store.editor.openEditor(fileProps.props)
		} else if (fileProps && fileProps.useEditor === false) {
			// openFileWithoutEditor(fileProps.props)
		} else {
			console.log("What should I do on here..?")
		}
	}

	const data = partialStore.viewNotifications(status)
	const data0 = partialStore.viewNotifications(0)
	const data1 = partialStore.viewNotifications(1)
	const i18n = store.i18n.combineI18n(moduleId, "OpenedFilesOnDrawer")
	return (
		<StyledOpenedFilesOnDrawer data-testid="notification-sidebar">
			<div
				className="FR AC JC noti-header"
				data-testid="right-drawer-tab-header"
			>
				<h2>{i18n.twOpenedFiles}</h2>
			</div>
			<div className="FR AC menu-select">
				<DLRadioGroup
					items={[
						{
							value: "workpaper",
							label: `${i18n.twWP}`,
						},
						{
							value: "permanent",
							label: `${i18n.twPF}`,
						},
					]}
					selectedValue={feature}
					color="primary"
					size="small"
					itemDirection="row"
					eleOnChange={handleFeature}
					groupName="feature"
				/>
			</div>
			<Tabs
				value={status}
				onChange={handleStatus}
				indicatorColor="primary"
				textColor="primary"
				centered
				className="type-tabs"
			>
				<Tab
					label={i18n.twOpenedFiles + " (" + data0.length + ")"}
					data-testid="opened-files-tab"
					className={"type-tab " + (status === 0 ? "selected" : "")}
				/>
				<Tab
					label={
						data.length > 0 ? (
							<DLBadge
								eleTestId="badge-for-requested-file"
								content={
									partialStore.viewNotifications(1).length
								}
								color={MessageColorType.red}
								eleClassName="margin-bottom-normal"
							>
								{i18n.twRequestedFiles +
									" (" +
									data1.length +
									")"}
							</DLBadge>
						) : (
							i18n.twRequestedFiles + " (" + data1.length + ")"
						)
					}
					data-testid="requested-files-tab"
					className={"type-tab " + (status === 1 ? "selected" : "")}
				/>
			</Tabs>
			{partialStore.responses.getResponse(loaderActionName)?.status !==
			"SUCCESS" ? (
				<DLSpinner />
			) : (
				<>
					{data.length < 1 ? (
						<div className="no-files">
							<p>
								No{" "}
								{status === 0
									? "Opened Files"
									: "Closing Request"}
							</p>
						</div>
					) : (
						<div
							data-testid={`${feature}-notifications-${status}`}
							className="noti-list"
						>
							{data.map((item: any) => {
								return (
									<StyledNotiItem
										key={item.mediaId}
										data-testid={`${feature}-${status}`}
										className="noti-item"
										onDoubleClick={(e: any) => {
											console.log(
												JSON.parse(JSON.stringify(item))
											)
											handleDoubleClickFile(
												e,
												JSON.parse(JSON.stringify(item))
											)
										}}
									>
										<div className="head FR AC JSB">
											<div className="FR AC file-title">
												<Icon
													path={
														item.srcId ===
														DLSrcFormat.excel
															? mdiFileExcel
															: item.mediaType ===
															  DLSrcFormat.pdf
															? mdiFilePdf
															: mdiFileWord
													}
													size={0.8}
													color="green"
												/>
												<span>
													<b>
														{item.aliasId} -
														{item.title}
													</b>
												</span>
											</div>
											<DLIconButton
												clickHandler={() =>
													clearSingleNotification(
														item.srcId
													)
												}
												align="right"
												eleTestId="close-btn"
												tooltipText="Close"
												size="small"
												eleClassName="item-close-btn"
											>
												<Icon
													path={mdiClose}
													size={1}
												/>
											</DLIconButton>
										</div>
										{status === 1 && (
											<div className="FR JSA">
												<span>
													{item.openedUser} -{" "}
													{item.requestedUser}
												</span>
											</div>
										)}
										<div className="content">
											{item.year} {item.clientName} :{" "}
											{item.linkName}(
											{item.projectAliasId}) (
											{item.projectStatusName}
											{item.version === "0"
												? ""
												: " v" + item.version}
											)
										</div>
									</StyledNotiItem>
								)
							})}
						</div>
					)}
				</>
			)}
			<div className="close-all-btn">
				<DLButton
					endIcon={<Icon path={mdiClose} size={1} />}
					variant="contained"
					eleTestId="close-all-btn"
					align="stretched"
					size="regular"
					color="primary"
					clickHandler={clearAllNotifications}
					disabled={
						partialStore.viewNotifications(status).length === 0
					}
				>
					{i18n.twCloseAll}
				</DLButton>
			</div>
			<OpenedFilesOnDrawerSnackbar />
		</StyledOpenedFilesOnDrawer>
	)
})

const StyledOpenedFilesOnDrawer = styled.div`
	.menu-select {
		margin-left: 1rem;
		height: 3rem;
		span {
			font-size: ${(props) => props.theme.shared.textLg};
		}
	}
	.type-tabs {
		min-height: 2rem;
		.type-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
		}
		span {
			/* for the border bottom highlighter */
			background-color: transparent;
		}
	}
	.noti-list {
		overflow-y: auto;
		/* header 2rem, menu-select 3rem, tabs 2rem, close-all-btn 3rem: total: 10rem */
		height: calc(100vh - 10rem);
		padding-top: 0.6rem;
	}
	.close-all-btn {
		height: 3rem;
		position: absolute;
		bottom: 0;
		z-index: 1;
	}
	.spinner {
		margin-left: 158px;
		margin-top: 38px;
	}
	.no-files {
		margin-top: 6em;
		p {
			text-align: center;
		}
	}
`

const StyledNotiItem = styled.div`
	padding: 1rem 0.6rem;
	border: 1px solid transparent;
	border-bottom: 1px solid ${(props) => props.theme.shade05};
	transition: 0.3s;
	:hover {
		background-color: ${(props) => props.theme.shade05};
		border-color: ${(props) => props.theme.shade10};
		border-radius: 3px;
	}
	.content {
		padding: 10px;
	}
	.head {
		.file-title {
			span {
				margin-left: 0.4rem;
			}
		}
		.item-close-btn {
			opacity: 0.5;
			:hover {
				opacity: 1;
			}
		}
	}
`
