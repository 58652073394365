import { flow } from "mobx-state-tree"
import { AmArchivedApiProps } from "../am-archived-projects.apis"

const ReadAmArchivedProjectList = (
	self: any,
	read: AmArchivedApiProps["apiRead"]
) => ({
	readAmArchivedProjectList: flow(function* (
		year: string,
		pageSize?: number,
		pageIndex?: number,
		searchText?: string,
		usage?: number
	) {
		// 0. set actionName
		const actionName = "readAmArchivedProjectList"
		const numberYear = year === "All Years" ? 0 : parseInt(year)
		try {
			const payload = {
				// UserId: 16,
				// CustomerID: 1,
				// EngagementTypeID: 0,
				// GroupID: 0,
				// EpUserID: 0,
				// QCUserID: 0,
				// ClientID: 0,
				Year: numberYear,
				SearchText: searchText === undefined ? "" : searchText,
				PageIndex: pageIndex || 0,
				PageSize: pageSize || 5000, // Because '/GetArchivedManagementProjects' API in the productions are not updated yet and unable to cover 10,000 as pageSize
				usage: usage,
				// periodID: 0
			}
			const response = yield read(payload)

			// ConsoleLog(
			// 	"readAmArchivedProjectList response",
			// 	response
			// )
			return response
		} catch (error) {
			// ConsoleLog("readAmArchivedProjectList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadAmArchivedProjectList
