import React, { useEffect } from "react"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
//
import OrgRoleCard from "./sub-components/OrgRoleCard"
import OrgSetupOrgRoleDetails from "./OrgSetupOrgRoleDetails"
import { ConsoleLog } from "../../../../components/basic-elements"
import { DLSector } from "../../../../common-models/enumerations/common-enums"

export default observer(function OrgSetupOrgRoles() {
	ConsoleLog(" ====***** OrgSetupOrgRoles *****===== ")
	const orgStore = useOrgStore()

	useEffect(() => {
		/**
		 * In this step, get three types of data and organized to display
		 * (Can be updated with backend service in the future)
		 */
		orgStore.orgRoles.getRoleSet(DLSector.org) // same with the org checkin
		orgStore.orgRoles.getAllOrgRoleDetails()
		orgStore.orgRoles.getAssignedUsers()
	}, [])
	// TODO: WARNING: Below method re-render the card. UPDATE is required
	return (
		<>
			{orgStore.orgRoles.responses.getActionStatus(
				"getAllOrgRoleDetails"
			) === "SUCCESS" &&
				(orgStore.orgRoles.selectedRole ? (
					<OrgSetupOrgRoleDetails />
				) : (
					<StyledOrgSetupOrgRoles>
						{orgStore.orgRoles.viewRoleSet().map((role: any) => {
							return (
								<div key={role.roleId}>
									<OrgRoleCard role={role} />
								</div>
							)
						})}
					</StyledOrgSetupOrgRoles>
				))}
		</>
	)
})

const StyledOrgSetupOrgRoles = styled.div`
	padding: 1rem 2rem;
	display: flex;
	flex-wrap: wrap;
`
