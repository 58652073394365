import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const RequestDonwloadFileFolder = (
	self: any,
	downloadFolder: FileSystemApiTypes["apiDownloadFolder"]
) => ({
	requestDonwloadFileFolder: flow(function* ({
		projId,
		folderId,
	}: {
		projId?: string
		folderId: string
	}) {
		const actionName = "requestDonwloadFileFolder"
		const menuId = self.storeName
		// 1.
		if (
			(menuId !== DLOrgSubMenus.proj_templates &&
				(projId === undefined || projId === "")) ||
			folderId === undefined ||
			folderId === ""
		) {
			alert(
				`(${actionName}) Undefined props exist. projId: ${projId} | folderId: ${folderId}`
			)
			return
		}
		//
		//
		// 2.
		const folderNumberId = idToNumber(folderId, IdType.folder)
		let endpoint = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/PartialZipfiles"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/PermanentFilePartialZipfiles"
		} else if (menuId === DLOrgSubMenus.proj_templates) {
			endpoint = "/ExportZipTemplateFiles"
		} else {
			alert("(api - downloadFolder) invalid menuType")
			return
		}
		//
		//
		// 3.
		let payload: any
		if (menuId !== DLOrgSubMenus.proj_templates && projId) {
			const projNumberId = idToNumber(projId, IdType.project)
			payload = {
				ClientID: projNumberId,
				Id: folderNumberId, // 1: wp, 2: pf
			}
		}
		if (menuId === DLOrgSubMenus.proj_templates) {
			payload = {
				ParentFldID: folderNumberId,
			}
		}
		if (typeof folderNumberId === "number") {
			try {
				// const payload = {
				// 	ClientID: projNumberId,
				// 	Id: folderNumberId,
				// 	// UserID: 16
				// }
				const response = yield downloadFolder(payload, endpoint, menuId)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid format of props. | ${folderNumberId}`
			)
			return false
		}
	}),
})

export default RequestDonwloadFileFolder
