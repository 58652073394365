import React from "react"
import { DLButton } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { handleDownloadExcelTemplate } from "../../../../library/bulk-import-utils/handle-download-template"
import Icon from "@mdi/react"
import { mdiArrowUp } from "@mdi/js"

export default observer(function PBCActionBtns({
	partialStore,
	setOpen,
	handleSelectFilesFromLocal,
}: {
	partialStore: any
	setOpen: any
	handleSelectFilesFromLocal: (e: any) => void
}) {
	return (
		<div className="pbc-action-area FR JSB">
			<DLButton
				eleTestId="choose-files-btn"
				startIcon={<Icon path={mdiArrowUp} size={0.8} />}
				color="primary"
			>
				<label className="file-upload-btn-wrapper">
					Import Data
					<input
						type="file"
						name="file"
						onChange={handleSelectFilesFromLocal}
						data-testid="file-input"
						accept=".xlsx,.xls"
					/>
				</label>
			</DLButton>
			<div className="FR AC">
				<DLButton
					eleTestId="clear-filters-btn"
					color="primary"
					// clickHandler={() =>}
				>
					Clear Filters
				</DLButton>
				<DLButton
					eleTestId="template-btn"
					color="primary"
					eleClassName="ml-4"
					clickHandler={() =>
						handleDownloadExcelTemplate(
							[
								{
									Area: "",
									ControlRefNo: "",
									Description: "",
									DueDate: "",
									Before: "",
									After: "",
									Recur: "",
								},
							],
							"PBC_List"
						)
					}
				>
					Template
				</DLButton>
				<DLButton
					eleTestId="expand-all-btn"
					color="primary"
					eleClassName="ml-4"
					// clickHandler={() =>}
				>
					Expand All
				</DLButton>
				<DLButton
					eleTestId="send-btn"
					color="primary"
					eleClassName="ml-4"
					// clickHandler={() =>}
				>
					Send
				</DLButton>
			</div>
			<div className="FR AC">
				<DLButton
					eleTestId="add-btn"
					color="primary"
					clickHandler={() => setOpen(true)}
				>
					Add
				</DLButton>
				<DLButton
					eleTestId="delete-all-btn"
					color="primary"
					eleClassName="ml-4"
					// clickHandler={() =>}
				>
					Delete All
				</DLButton>
			</div>
			<DLButton
				eleTestId="export-btn"
				color="primary"
				// clickHandler={() =>}
			>
				Export
			</DLButton>
		</div>
	)
})
