import { ConsoleLog } from "../components/basic-elements"
import {
	ActionStatus,
	MessageColorType,
	DLSector,
} from "./enumerations/common-enums"

export const CommonModelActions = (self: any) => ({
	// ----- for API
	handleModelError({
		actionName,
		error,
		open,
	}: {
		actionName: string
		error: any
		open?: boolean
	}) {
		ConsoleLog(`handleModelError of ${actionName}`, [
			actionName,
			error.toString(),
			error.response,
			error,
		])
		//
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.fail,
			color: MessageColorType.red,
			open,
		})
		//
		if (error.response?.status) {
			if (error.response.status === 403) {
				alert("You don't have permission on this page")
			}
			// NOTE: How to distingush the requirements of force logout...
			if (error.response.status === 401) {
				ConsoleLog(`handleModelError 401 of ${actionName}`, [error])

				alert(
					"Sorry, you lost your access permission. Please login again"
				)

				window.location.replace("/lobby-checkout") // TODO: lobby checkout? org checkout? proj checkout?
			}
		}
		// 403 error from CloudFront new configs & Private S3 Bucket
		// Below process make the user
		if (
			error.toString() === "Error: Network Error" &&
			!error.toString().includes("INTERNET_DISCONNECTED")
		) {
			// self.responseSnackbar.setMessage("Network Error")
			// self.responseSnackbar.setOpen(true)
			alert("Network Error")
			if (window.location.pathname.includes("project")) {
				window.location.replace("/project/checkout")
			} else if (window.location.pathname.includes("organization")) {
				window.location.replace("/organization/checkout")
			} else {
				window.location.replace("/lobby-checkout")
			}
		}
		if (
			// error.toString() === "Error: Network Error"
			error.toString().includes("INTERNET_DISCONNECTED")
		) {
			self.responseSnackbar.setMessage("Network Error")
			self.responseSnackbar.setOpen(true)
			alert("Network Error")
		}
	},
	handleTokenError({
		actionName,
		error,
		open,
	}: {
		actionName: string
		error: any
		open?: boolean
	}) {
		console.log(
			"Token error from:",
			actionName,
			"|",
			error.toString(),
			"|",
			error.response
		)

		//
		/**
		 * error.response has
		 * - data: string
		 * - status: number
		 * - statusText: string
		 * - headers: ...
		 * - config: ...
		 * - etc...
		 */
		const responseStatusCode = error.response?.status
		const currentPath = window.location.pathname
		ConsoleLog(`handleTokenError in the path ${currentPath}`)

		let currentSector = ""
		if (currentPath.includes("/organization/")) {
			currentSector = DLSector.org
		} else if (currentPath.includes("/project/")) {
			currentSector = DLSector.proj
		} else {
			currentSector = DLSector.lobby
		}
		// alert
		if (responseStatusCode && responseStatusCode === 401) {
			alert(`Token is invalid. Please login again`)
			ConsoleLog(
				`401 Token error in ${currentSector} ${responseStatusCode} by ${actionName}`
			)
			// localStorage.removeItem()
			// window.location.replace("/lobby-checkout")
		} else if (
			actionName === "requestLogin" &&
			error.toString() === "Error: Network Error"
		) {
			alert(
				`The server is not responding. Please contact to administrator.`
			)
			ConsoleLog(
				`503 responseStatusCode in ${currentSector} ${responseStatusCode} by ${actionName}`
			)
		} else {
			alert(`Token returns an error by ${actionName}`)
			ConsoleLog(`Token error in ${currentSector} ${responseStatusCode}`)
		}
		// redirect
		// if (currentSector === DLSector.lobby) {
		// 	window.location.replace("/lobby-checkout")
		// } else if (currentSector === DLSector.org) {
		// 	window.location.replace("/organization/checkout")
		// } else if (currentSector === DLSector.proj) {
		// 	window.location.replace("/project/checkout")
		// }
		// window.location.replace("/lobby-checkout")
		localStorage.removeItem("loginToken")
		localStorage.removeItem("awsToken")
		localStorage.removeItem("lobbyUserId")
		window.location.replace("/")
	},
})
