const sharedTheme = {
	/* ---------- font-size ---------- */
	// If you can, use App.scss for font-size
	heading1: "2rem", // 32px
	heading2: "1.25rem", // 20px
	heading3: "1rem", // 16px
	heading4: "0.875rem", // 14px
	body1: "1rem",
	body2: "0.875rem",
	/* based on standard 16px */
	/* 20px / 18px / 16px / 14px / 12px / 11px / 9px / 8px */
	textXxxl: "1.25rem",
	textXxl: "1.125rem",
	textXl: "1rem",
	textLg: "0.875rem",
	textMd: "0.75rem",
	textRg: "0.7rem",
	textSm: "0.6rem",
	textXs: "0.5rem",

	/* ---------- font-weight ---------- */
	fontLight: 300,
	fontRegular: 400,
	fontMedium: 500,
	fontBold: 600,
	fontBlack: 900,

	/* ---------- component standard ---------- */
	baseUnit: "48px",
	mediumUnit: "32px",
	smallUnit: "24px",

	/* ---------- spacing ---------- */
	spacingWide: "4rem",
	spacingNormal: "2rem",
	spacingThin: "1rem",
	//
	spacing5: "4rem",
	spacing4: "3rem",
	spacing3: "2rem",
	spacing2: "1.5rem",
	spacing1: "1rem",
	//
	spacingS: "0.5rem",
	spacingSS: "0.25rem",

	// ---------- message colors
	messageGreenAutoHide: "rgba(163,236,224,0.5)",
	messageBlueAutoHide: "rgba(171,191,255,0.5)",
	messageOrangeAutoHide: "rgba(245,190,64,0.6)",
	messageCoralAutoHide: "rgba(218,93,77,0.5)",
	messageRedAutoHide: "rgba(219,0,38,0.5)", // NOTE: DO NOT USE autohide for red type message!
	//
	messageGreen: "#A3ECE0",
	messageBlue: "#ABBFFF",
	messageOrange: "#F5BE40",
	messageCoral: "#DA5D4D",
	messageRed: "#DB0026",
}

export default sharedTheme
