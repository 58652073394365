import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { CreateProjectPathProps } from "../../../../../components/combined-elements/create-project/create-project.data-props"

export const GroupsPathForSelectionActions = (self: any) => ({
	setPathForSelection(newPath: string[]) {
		ConsoleLog(["setPathForSelection", newPath])
		self.pathForSelection = newPath
	},
})

export const GroupsPathForSelectionViews = (self: any) => ({
	get selectionPathWithName() {
		const path = self.pathForSelection
		let newPath: { id: string; name: string }[] = []
		path.map((id: string) => {
			const item = {
				id,
				name: self.getNameById(id),
			}
			newPath.push(item)
		})
		return newPath
	},
	get selectionPathWithNameAndFormat() {
		// ConsoleLog("-----selectionPathWithNameAndFormat")
		// ConsoleLog("--self.orgGroupFlatList", self.orgGroupFlatList)
		const path = self.pathForSelection
		let newPath: CreateProjectPathProps = []
		path.map((id: string) => {
			const target = self.getTargetDetail(id)
			// ConsoleLog("target", id, target)
			const item = {
				id,
				name: target ? target.title : "(Unknown title)",
				objFormat: target ? target.type : "(Unknown)",
			}
			newPath.push(item)
		})
		// ConsoleLog("-----newPath", newPath)
		return newPath
	},
	get selectionPathWithNameAndFormatToCreateProject() {
		const path = self.pathForSelection
		let newPath: CreateProjectPathProps = []
		path.map((id: string) => {
			const target = self.getTargetDetail(id)
			// ConsoleLog("target", id, target)
			const item = {
				id,
				name: target ? target.title : "(Unknown title)",
				objFormat: target ? target.type : "(Unknown)",
			}
			newPath.push(item)
		})
		const lastItem = newPath[newPath.length - 1]
		// ConsoleLog("lastItem", lastItem)
		if (lastItem && lastItem.objFormat === DLObjectFormat.project) {
			newPath.pop()
		}
		// ConsoleLog("-----newPath", newPath)
		return newPath
	},
})
