import { types } from "mobx-state-tree"

import SearchUiActions from "../../../../../service-modules/file-module/ui-helper-actions/search-ui.actions"
import CommentUiActions from "../../../communications/proj-comments/store/ui-helper-views/comment-dialog-actions.ui-actions"

const WpUiHelper = types
	.model({
		// TODO: Remove the line below (control it on the comment store)
		selectedComment: "",
	})
	.actions(SearchUiActions)
	.actions(CommentUiActions)

export default WpUiHelper
