import React from "react"
import { Link } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiAccountBadge, mdiSync, mdiClose } from "@mdi/js"
// styles
import StyledDLTab from "./StyledDLTab"

// WARNING:
import Tab from "@material-ui/core/Tab"
import Badge from "@material-ui/core/Badge"

export interface ProjMenuTabProps {
    id: string
    title: string
    hasUpdate?: boolean
    url?: string
    cannotClose?: boolean
    handleSelectMenu: (menuId: string) => void
    handleCloseMenu: (menuId: string) => void
    handleRefresh: (menuId: string) => void
    prevUrl?: string
    isCurrentMenu?: boolean
}

export default function DLTabForProj({
    id,
    title,
    hasUpdate,
    url,
    cannotClose,
    handleSelectMenu,
    handleCloseMenu,
    handleRefresh,
    prevUrl,
    isCurrentMenu,
}: ProjMenuTabProps) {
    return (
        <StyledDLTab className="dl-tab-for-proj FR" style={{ width: "100%" }}>
            <Link
                to={url ? url : "/empty-url"}
                className="FR JSB AC"
                style={{ width: "100%", height: 40 }}
                onClick={() => handleSelectMenu(id)}
            >
                <div className="tab-label FR AC JC">
                    <Badge
                        variant="dot"
                        badgeContent={hasUpdate ? 1 : 0}
                        color="secondary"
                        overlap="rectangular"
                        anchorOrigin={{ horizontal: "left", vertical: "top" }}
                    >
                        <div className="text-label">{title}</div>
                        {/* <Icon className="update-icon" path={mdiSync} /> */}
                    </Badge>
                </div>
                <div
                    className="refresh-button FC AC"
                    onClick={() => handleRefresh(id)}
                    data-testid={`refresh-btn-on-tab-${id}`}
                >
                    <Icon className="update-icon" path={mdiSync} size={0.8} />
                </div>
            </Link>
            {!cannotClose &&
                (isCurrentMenu ? (
                    <Link to={prevUrl ? prevUrl : "/empty-url"}>
                        <div
                            className="tab-control FR JR"
                            onClick={() => handleCloseMenu(id)}
                        >
                            <Icon className="close-icon" path={mdiClose} />
                        </div>
                    </Link>
                ) : (
                    <div
                        className="tab-control FR JR"
                        onClick={() => handleCloseMenu(id)}
                    >
                        <Icon className="close-icon" path={mdiClose} />
                    </div>
                ))}
        </StyledDLTab>
    )
}
