import React from "react"
import { Link } from "react-router-dom"
import { mdiArrowLeft, mdiBullhorn } from "@mdi/js"
import Icon from "@mdi/react"
import styled from "styled-components"
import { DLIconButton } from "../../../components/basic-elements"
import {
	DLIcon,
	DLIconName,
} from "../../../components/basic-elements/icons/common-icons"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import PostContents from "./PostContents"
import AttachmentsForView from "./AttachmentsForView"

export default function PostDetail({
	permission,
	postId,
	post,
	parentId,
	handleDelete,
	contentsAreaWidth,
	contentsAreaHeight,
	loading,
	existingFiles,
	downloadHandler,
	mainUrl,
}: {
	permission: PermissionAsObjectProps
	postId: string
	post: any // update is required
	parentId: string
	handleDelete: any
	contentsAreaWidth: number
	contentsAreaHeight: number
	loading: boolean
	existingFiles: any // update is required
	downloadHandler: (s3ObjKey: string, fileName: string) => void // update is required
	mainUrl: string
}) {
	return (
		<StyledPostDetail className="FR">
			<div className="button-area">
				<Link to={mainUrl}>
					<DLIconButton eleTestId="back-to-list" variant="iconOnly">
						<Icon path={mdiArrowLeft} size={0.8} />
					</DLIconButton>
				</Link>
				{permission.update && (
					<Link to={`${mainUrl}/edit/${postId}`}>
						<DLIconButton
							eleTestId="post-edit-btn"
							variant="iconOnly"
						>
							<DLIcon name={DLIconName.edit} noMargin />
						</DLIconButton>
					</Link>
				)}
				{permission.delete && (
					<DLIconButton
						eleTestId="post-delete-btn"
						variant="iconOnly"
						clickHandler={() => handleDelete()}
					>
						<DLIcon name={DLIconName.delete} noMargin />
					</DLIconButton>
				)}
			</div>
			<div
				className="contents-container FC"
				style={{
					width: contentsAreaWidth - 80,
					height: contentsAreaHeight - 16,
				}}
			>
				<div className="contents">
					{post ? (
						post.parent === parentId ? (
							<PostContents
								post={post}
								contentHeight={contentsAreaHeight - 240}
							/>
						) : (
							<div>Not Authorized</div>
						)
					) : loading ? (
						<div data-testid="loading-post">Loading...</div>
					) : (
						<div data-testid="cannot-find-post">
							Cannot find the post anymore...
						</div>
					)}
				</div>
				<div className="bottom-area">
					{post?.attachments.length === 0 ? (
						<div className="no-attachments FR AC">
							No Attachments
						</div>
					) : (
						post?.attachments && (
							<AttachmentsForView
								files={existingFiles}
								downloadHandler={downloadHandler}
								eleClassName="attachments-table"
							/>
						)
					)}
				</div>
			</div>
		</StyledPostDetail>
	)
}

const StyledPostDetail = styled.div`
	.button-area {
		height: 3rem;
		margin-right: 1rem;
	}
	.contents-container {
		.bottom-area {
			border-top: 1px solid ${(props) => props.theme.shade20};
			padding-top: 0.6rem;
			overflow-y: auto;
			/* height: 3rem; */
			.attachments-table {
				width: 100%;
			}
		}
	}
`
