import { flow } from "mobx-state-tree"

const DeleteEliminatingEntry = (self: any, deleteEE: any) => ({
	deleteEliminatingEntry: flow(function*(payload: {
		EliminatingEntryID: number
	}) {
		const actionName = "deleteEliminatingEntry"
		try {
			const response = yield deleteEE(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default DeleteEliminatingEntry
