import React, { useEffect, useState } from "react"
import { useParams, Link } from "react-router-dom"
import { DLButton, ConsoleLog } from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiArrowLeft, mdiPencil } from "@mdi/js"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	PageHeader,
	PageContainer,
} from "../../../../components/app-frame-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export default observer(function Rationale() {
	// default setting
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	let { parentId } = useParams<{ parentId?: string }>()

	const [contents, setContents] = useState<any>()

	if (projStore.rationales.getActionStatus("getRationales") !== "SUCCESS") {
		projStore.rationales.getRationales()
	}
	const dnt = store.global.getDntFormat
	const { dateFormat, timeZone } = dnt

	useEffect(() => {
		ConsoleLog("useEffect on a rationale")
		parentId &&
			setContents(
				projStore.rationales.viewRationale(
					parentId,
					dateFormat,
					timeZone
				)
			)
	}, [parentId, projStore.rationales.getActionStatus("getRationales")])

	return (
		<PageContainer hasToolbar={false}>
			<PageHeader>
				<Link to={`${defaultUrl}/archive/rationales`}>
					<DLButton
						variant="text"
						eleTestId="asdfs"
						startIcon={<Icon path={mdiArrowLeft} size={0.8} />}
					>
						Back to the list
					</DLButton>{" "}
				</Link>
				<Link to={`${defaultUrl}/archive/rationales/edit/${parentId}`}>
					<DLButton
						variant="text"
						eleTestId="rationale-edit-btn"
						startIcon={<Icon path={mdiPencil} size={0.8} />}
					>
						Edit
					</DLButton>
				</Link>
			</PageHeader>

			<h2>Rationale</h2>
			<p>Rationale Parent: {parentId}</p>
			{projStore.rationales.getActionStatus("getRationales") ===
				"SUCCESS" &&
				contents && (
					<div>
						<p>{contents.rationaleId}</p>
						<p>{contents.rationaleDescription}</p>
					</div>
				)}
		</PageContainer>
	)
})
