import { format } from "date-fns"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const ExportUsers = (self: any) => ({
	exportUsers(
		dateFormat: string,
		projects: any,
		userId: string,
		index: number
	) {
		const proj = projects[index]
		const projInfo = {
			groupName: proj.groupName,
			clientName: proj.clientName,
			clientAliasId: proj.clientAliasId,
			// aliasId: proj. ,
			title: proj.title,
			engTypeName: proj.engTypeName,
			// raName: proj. ,
			statusName: "Archived",
			periodName: proj.periodName,
			version: proj.version,
			// isArchived: proj. ,
			// isLocked: proj. ,
			financialYear: proj.financialYear,
			periodEndDate: proj.periodEndDate,
			// finalReportDate: proj. ,
			// createdBy: proj. ,
			// createdAt: proj. ,
			// lastAccessBy: proj. ,
			// lastAccessAt: proj. ,
			// archOrUnArchBy: proj. ,
			// archOrUnArchAt: proj. ,
			epUsers: proj.epUsers,
			qcUsers: proj.qcUsers,
		}
		const projId = proj.id

		// get users
		const payload = {
			ClientId: idToNumber(projId, IdType.project),
			Type: 4,
		}
		self.readProjUsersAndFileCount(payload).then((response: any) => {
			if (response.status === 200 && response.data.Status === 1) {
				self.pushItemToUserExportStatusList({
					projId,
					success: true,
				})
				//

				let combinedList: any[] = [] // combine user info and project info

				const internalUsers = response.data.InternalUsersList
				const externalUsers = response.data.ExternalUsersList

				if (internalUsers.length > 0) {
					internalUsers.map((user: any) => {
						const newUser = {
							userName: user.Name,
							userAliasId: user.UserName,
							email: user.Email,
							userRole: user.Role,
							userAccessRight: user.AccessRight,
							userType: "internal",
							isGrayedOut: user.isGrayed,
							isStrikedOut: user.IsStrikeOut,
						}
						combinedList.push({
							...projInfo,
							...newUser,
						})
					})
				}

				if (externalUsers.length > 0) {
					externalUsers.map((user: any) => {
						const newUser = {
							userName: user.Name,
							userAliasId: user.UserName,
							email: user.Email,
							userRole: user.Role,
							userAccessRight: user.AccessRight,
							userType: "external",
							isGrayedOut: user.isGrayed,
							isStrikedOut: user.IsStrikeOut,
						}
						combinedList.push({
							...projInfo,
							...newUser,
						})
					})
				}
				const concattedList = self.usersForExport.concat(combinedList)
				self.concatUsersForExport(concattedList)
				if (index < projects.length - 1) {
					self.exportUsers(dateFormat, projects, userId, index + 1)
				}
				// ConsoleLog([index, "success", proj.title])
			} else {
				self.pushItemToUserExportStatusList({ projId, success: false })
				//
				if (index < projects.length - 1) {
					self.exportUsers(dateFormat, projects, userId, index + 1)
				}
				// ConsoleLog([index, "fail", proj.title])
			}
		})
	},
})

export default ExportUsers
