// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const ArchiveProject = (self: any) => ({
	archiveProject(
		projId: string,
		userId: string,
		reason: string,
		projectSize: string | null,
		filesData?: any
	) {
		// 0.
		const actionName = "archiveProject"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const projNumberId = idToNumber(projId, IdType.project)
		const userNumberId = idToNumber(userId, IdType.user)

		const formData = new FormData()
		const ArchiveDataObj = {
			ClientID: projNumberId,
			IsArchivedProcess: true, // For Normal and Unarchived
			ArchivedBy: userNumberId,
			ReplicaClientID: 0, // ? ---- Why 0 ?
			reason: reason,
			...(projectSize && { ProjectSize: projectSize })
		}
		if (filesData) {
			filesData.map((file: any) => {
				formData.append("files", file.file)
			})
		}
		formData.append("param", JSON.stringify(ArchiveDataObj))
		self.mArchiveProject(formData)
			.then((response: any) => {
				// if success
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					const fetchedData = response.data.data
					let archiveDetails = {
						epName: fetchedData.EPUser,
						archivedDate: fetchedData.ArchivedDate,
						archivedBy: fetchedData.ArchivedByUser,
					}
					self.setArchiveDetails(archiveDetails)

					// if success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ArchiveProject
