import React from "react"
import styled from "styled-components"
import { DLCommentType } from "../../../common-models/enumerations/common-enums"
import {
	CommentModelProps,
	SimpleCommentProps,
} from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { ConsoleLog } from "../../basic-elements"

export default function CommentCardThin({
	comment,
	handleDelete,
	handleOpenEdit,
	handleCloseComment,
}: {
	comment: SimpleCommentProps
	handleDelete: (commentId: string) => void
	handleOpenEdit: (commentId: string) => void
	handleCloseComment: (commentId: string) => void
}) {
	const projStore = useProjStore()
	ConsoleLog("CommentCardThin", comment)

	const { id, type, assignees, contents, response, createdAt, createdBy } =
		comment

	const isReviewComment = type === DLCommentType.normal
	const isQcComment = type === DLCommentType.qc

	return (
		<StyledCommentCard className="FC">
			<div className={`comment-type ${isQcComment && "qc-comment"}`}>
				{isReviewComment
					? "Review Comment"
					: isQcComment
					? "QC Comment"
					: "(unknown type)"}
			</div>
			<div
				className="comment-body"
				onClick={() => handleOpenEdit(id)}
				data-testid="comment-thin-card-contents"
			>
				{contents}
			</div>
		</StyledCommentCard>
	)
}

const StyledCommentCard = styled.div`
	padding: 0.6rem 1rem;
	border: 1px solid gray;
	background-color: ${(props) => props.theme.background};
	border-radius: 0.5rem;
	margin-bottom: 0.4rem;
	.comment-type {
		font-size: ${(props) => props.theme.shared.textSm};
		&.qc-comment {
			color: orange;
		}
	}
	.comment-body {
		cursor: pointer;
		:hover {
			font-weight: 700;
		}
	}
`
