import { types } from "mobx-state-tree"

const LibInFolderUiHelper = types
	.model({
		query: "",
		firstCalled: true,
	})
	.actions((self) => ({
		setQuery(queryText: string) {
			self.query = queryText
		},
		setFirstCalled(request: boolean) {
			self.firstCalled = request
		},
	}))
	.views((self) => ({
		get viewQuery() {
			return self.query
		},
	}))

export default LibInFolderUiHelper
