import { observer, useObserver } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import {
	DLIconButton,
	DLSingleSelect,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import sharedRegEx from "../../../../../library/sharedRegEx"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import DLReactEditableTable from "../../../../../components/basic-elements/tables/DLReactEditableTable"

const BulkAddUsersDialogTable = ({ partialStore }: { partialStore: any }) => {
	function renderEditableFileName(cellInfo: any) {
		const users = JSON.parse(JSON.stringify(partialStore.bulkUserList))
		const targetFile = users.find(
			(user: any) => user.userId === cellInfo.row.original.userId
		)
		if (targetFile) {
			const columnId = cellInfo.column.id
			console.log(columnId)
			let error = []
			let className = ""

			if (columnId === "aliasId") {
				let duplicateAliasId =
					users.filter((x: any) => x.aliasId === targetFile[columnId])
						.length > 1
				if (partialStore.isDuplicatedAliasId(targetFile[columnId])) {
					className = "error"
					error.push("Alias id already exist")
				}
				if (duplicateAliasId) {
					className = "error"
					error.push("Duplicate alias id")
				}
			}

			if (columnId === "email") {
				let duplicateEmail =
					users.filter((x: any) => x.email === targetFile[columnId])
						.length > 1
				if (partialStore.isDuplicatedEmail(targetFile[columnId])) {
					className = "error"
					error.push("Email already exist")
				}
				if (duplicateEmail) {
					className = "error"
					error.push("Duplicate email")
				}
				if (
					!sharedRegEx.email.test(targetFile.email) &&
					targetFile.email !== ""
				) {
					error.push("Invalid Format")
				}
			}

			if (targetFile[columnId] === "" && columnId !== "title") {
				className = "error"
			}
			if (
				targetFile["type"] === "external" &&
				targetFile["title"] === ""
			) {
				className = "error"
			}
			// const className =
			// 	(cellInfo.original.duplicatedAliasId &&
			// 		columnId === "aliasId") ||
			// 	((cellInfo.original.duplicatedEmail ||
			// 		!sharedRegEx.email.test(targetFile.email)) &&
			// 		columnId === "email") ||
			// 	targetFile[columnId] === ""
			// 		? "error"
			// 		: ""

			/**
			 NOTE: Take same test id with single user add dialog
				input name (data-testid)
				1. User type (user-type-selection)
				2. Email (email-input-field)
				3. Name (username-input-field)
				4. Alias ID (alias-id-input-field)
				5. Access Right (org-role-selection)
				6. Title (title-input-field)
			*/
			let testId = columnId + "-cell"
			// if (columnId === "name") {
			// 	// testId = "username-input-field"
			// }
			// if (columnId === "email") {
			// 	// testId = "email-input-field"
			// }
			// if (columnId === "aliasId") {
			// 	// testId = "alias-id-input-field"
			// }
			// if (columnId === "title") {
			// 	// testId = "title-input-field"
			// }
			return (
				<div className="FC" style={{ width: "100%" }}>
					<div
						data-testid={testId}
						className={className}
						style={{ backgroundColor: "#fafafa", width: "100%" }}
						contentEditable
						suppressContentEditableWarning
						onBlur={(e: any) => {
							const userId = cellInfo.row.original.userId
							ConsoleLog(userId)
							const updated = users.map((user: any) =>
								user.userId === userId
									? {
											...user,
											[columnId]: e.target.innerHTML
												.replace(/&nbsp;/g, " ")
												.trim(),
									  }
									: user
							)
							ConsoleLog(updated)
							partialStore.setBulkUserList(updated)
						}}
						dangerouslySetInnerHTML={{
							__html:
								targetFile && targetFile[columnId]
									? targetFile[columnId]
									: "",
						}}
					/>
					{error.length > 0 && (
						<div
							style={{ color: "red" }}
							data-testid={`${columnId}-error`}
						>
							{error.map((item: any) => {
								return (
									<>
										<span>{item}</span>
										<br />
									</>
								)
							})}
						</div>
					)}
				</div>
			)
		}
		return null
	}

	const handleRemove = (userId: string) => {
		partialStore.spliceBulkUser(userId)
	}

	const handleSelect = (name: string, type: string, userId: string) => {
		const users = JSON.parse(JSON.stringify(partialStore.bulkUserList))
		const updated = users.map((user: any) =>
			user.userId === userId
				? {
						...user,
						[name]: type,
				  }
				: user
		)
		ConsoleLog(updated)
		partialStore.setBulkUserList(updated)
	}

	return useObserver(() => {
		return (
			<StyledTable>
				<DLReactEditableTable
					tableColumns={TableColumns1(
						renderEditableFileName,
						handleRemove,
						handleSelect
					)}
					data={JSON.parse(
						JSON.stringify(partialStore.viewBulkUserList)
					)}
					// hasPagination={false}
				/>
			</StyledTable>
		)
	})
}

/**
 NOTE: Take same test id with single user add dialog
 	input name (data-testid)
	1. User type (user-type-selection)
	2. Email (email-input-field)
	3. Name (username-input-field)
	4. Alias ID (alias-id-input-field)
	5. Access Right (org-role-selection)
	6. Title (title-input-field)
*/

const TableColumns = (
	renderEditableFileName: any,
	handleRemove: any,
	handleSelect: any
) => [
	{
		Header: "User Type",
		accessor: "type",
		Cell: (row: any) => {
			const id = row.original.userId
			const type = row.original.type
			const cellId = row.column.id + "-cell"

			return (
				<DLSingleSelect
					eleValue={type}
					eleOnChange={(e) =>
						handleSelect("type", e.target.value, id)
					}
					optionList={[
						{ name: "Internal", value: "internal" },
						{ name: "External", value: "external" },
					]}
					eleTestId={cellId} // "user-type-selection"
					eleClassName="user-type"
				/>
			)
		},
	},
	{
		Header: "User name",
		accessor: "name",
		Cell: renderEditableFileName,
	},
	{
		Header: "Email",
		accessor: "email",
		Cell: renderEditableFileName,
	},
	{
		Header: "Alias Id",
		accessor: "aliasId",
		Cell: renderEditableFileName,
	},
	{
		Header: "Organization Role",
		accessor: "accessRight",
		style: { justifyContent: "center" },
		Cell: (row: any) => {
			const id = row.original.userId
			const accessRight = row.original.accessRight
			const type = row.original.type
			const cellId = row.column.id + "-cell"
			return (
				<>
					{type === "internal" ? (
						<DLSingleSelect
							eleValue={accessRight}
							eleOnChange={(e) =>
								handleSelect("accessRight", e.target.value, id)
							}
							optionList={[
								{
									name: "Archive Admin",
									value: "Archive Admin",
								},
								{ name: "Super Admin", value: "Super Admin" },
								{ name: "Group Admin", value: "Group Admin" },
								{ name: "User Access", value: "User Access" },
							]}
							eleTestId={cellId} //"org-role-selection"
							eleClassName="access-right"
						/>
					) : (
						<span> - </span>
					)}
				</>
			)
		},
	},
	{
		Header: "Title",
		accessor: "title",
		Cell: renderEditableFileName,
	},
	{
		Header: "Status",
		accessor: "status",
		Cell: (row: any) => {
			const status = row.original.status
			return (
				<div data-testid="submit-status">
					{status === ActionStatus.standby && <span>Stand By</span>}
					{status === ActionStatus.success && (
						<span style={{ color: "green" }}>Successful</span>
					)}
					{status === ActionStatus.fail && (
						<span style={{ color: "red" }}>Failed</span>
					)}
					{status === ActionStatus.loading && (
						<span style={{ color: "orange" }}>In Progress</span>
					)}
				</div>
			)
		},
	},
	{
		Header: "Remove",
		accessor: "xxx",
		Cell: (row: any) => {
			const id = row.original.userId
			const handleClickRemove = () => {
				handleRemove(id)
			}
			return (
				<DLIconButton
					// eleTestId={`cancel-select-${row.original.aliasId}`}
					eleTestId="remove-row"
					clickHandler={handleClickRemove}
					tooltipText="Cancel select"
				>
					<Icon path={mdiClose} size={0.6} />
				</DLIconButton>
			)
		},
	},
]

const TableColumns1 = (
	renderEditableFileName: any,
	handleRemove: any,
	handleSelect: any
) => [
	{
		header: "User Type",
		accessorKey: "type",
		cell: (props: any) => {
			const id = props.row.original.userId
			const type = props.getValue()
			const cellId = props.column.id + "-cell"
			return (
				<DLSingleSelect
					eleValue={type}
					eleOnChange={(e) =>
						handleSelect("type", e.target.value, id)
					}
					optionList={[
						{ name: "Internal", value: "internal" },
						{ name: "External", value: "external" },
					]}
					eleTestId={cellId} // "user-type-selection"
					eleClassName="user-type"
				/>
			)
		},
	},
	{
		header: "User name",
		accessorKey: "name",
		cell: renderEditableFileName,
	},
	{
		header: "Email",
		accessorKey: "email",
		cell: renderEditableFileName,
	},
	{
		header: "Alias Id",
		accessorKey: "aliasId",
		cell: renderEditableFileName,
	},
	{
		header: "Organization Role",
		accessorKey: "accessRight",
		cell: (props: any) => {
			const { userId: id, type } = props.row.original
			const accessRight = props.getValue()
			const cellId = props.column.id + "-cell"
			return (
				<>
					{type === "internal" ? (
						<DLSingleSelect
							eleValue={accessRight}
							eleOnChange={(e) =>
								handleSelect("accessRight", e.target.value, id)
							}
							optionList={[
								{
									name: "Archive Admin",
									value: "Archive Admin",
								},
								{ name: "Super Admin", value: "Super Admin" },
								{ name: "Group Admin", value: "Group Admin" },
								{ name: "User Access", value: "User Access" },
							]}
							eleTestId={cellId} //"org-role-selection"
							eleClassName="access-right"
						/>
					) : (
						<span> - </span>
					)}
				</>
			)
		},
	},
	{
		header: "Title",
		accessorKey: "title",
		cell: renderEditableFileName,
	},
	{
		header: "Status",
		accessorKey: "status",
		cell: (props: any) => {
			const status = props.getValue()
			return (
				<div data-testid="submit-status">
					{status === ActionStatus.standby && <span>Stand By</span>}
					{status === ActionStatus.success && (
						<span style={{ color: "green" }}>Successful</span>
					)}
					{status === ActionStatus.fail && (
						<span style={{ color: "red" }}>Failed</span>
					)}
					{status === ActionStatus.loading && (
						<span style={{ color: "orange" }}>In Progress</span>
					)}
				</div>
			)
		},
	},
	{
		header: "Remove",
		accessorKey: "xxx",
		cell: (props: any) => {
			const { userId: id } = props.row.original
			return (
				<DLIconButton
					eleTestId="remove-row"
					clickHandler={() => handleRemove(id)}
					tooltipText="Cancel select"
				>
					<Icon path={mdiClose} size={0.6} />
				</DLIconButton>
			)
		},
	},
]

const StyledTable = styled.div`
	.warning {
		background-color: ${(props) => props.theme.shared.messageOrange};
	}
	.error {
		border: 1px solid ${(props) => props.theme.shared.messageRed};
	}
	.MuiInput-formControl {
		margin-top: 0px;
	}
	.disabled {
		opacity: 0.3;
	}
`

export default BulkAddUsersDialogTable
