import { types } from "mobx-state-tree"
import LibInProjModel from "./lib-in-proj.model"
import LibInProjViewModel from "./lib-in-proj.view-model"
import LibInFolderUiHelper from "./lib-in-proj.ui-helper"
import { OrgProjTemplatesApiProps } from "../../../organization-side/org-library/org-proj-templates/store/org-proj-templates.api-props"
import { readProjTemplateDetails } from "../../../organization-side/org-library/org-proj-templates/store/org-proj-templates.apis"
// Model, ViewModel, UiHelper
// common
// apis

export const LibInProjStore = ({
	apiReadProjTemplateDetails,
}: {
	apiReadProjTemplateDetails: OrgProjTemplatesApiProps["apiReadProjTemplateDetails"]
}) =>
	types
		.compose(
			LibInProjModel({ apiReadProjTemplateDetails }),
			LibInProjViewModel,
			LibInFolderUiHelper
		)
		.named("LibInProjStore")

export const initialStore = {
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const LibInProjStoreInstance = LibInProjStore({
	apiReadProjTemplateDetails: readProjTemplateDetails,
}).create(initialStore)

export default LibInProjStoreInstance
