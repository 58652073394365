import { types } from "mobx-state-tree"
import MyAccountViewModel from "./my-account.view-model"
import MyAccountModel from "./my-account.model"
import MyAccountUiHelper from "./my-account.ui-helper"
import * as api from "./my-account.apis"

const MyAccountStore = ({ apiChangePassword }: api.MyAccountProps) =>
	types
		.compose(
			MyAccountUiHelper,
			MyAccountViewModel,
			MyAccountModel({ apiChangePassword })
		)
		.named("MyAccountStore")

const MyAccountStoreInstance = MyAccountStore({
	apiChangePassword: api.changePassword,
}).create({
	// view model common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
})

export default MyAccountStoreInstance
