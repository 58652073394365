import React from "react"
import { Link } from "react-router-dom"
import { DLIconButton } from "../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiDomain } from "@mdi/js"

export default function CheckoutToLobby({
	tooltipText,
}: {
	tooltipText: string
}) {
	return (
		<Link to="/organization/checkout">
			<DLIconButton
				variant="iconOnly"
				aria-label="move to organization list page"
				color="default"
				eleTestId="back-to-org-list-btn"
				tooltipText={tooltipText}
			>
				<Icon path={mdiDomain} size={0.8} />
			</DLIconButton>
		</Link>
	)
}
