import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

const RequestValidatePrevUserRole = (self: any, validatePrevRole: any) => ({
	requestValidatePrevUserRole: flow(function* (userId: string) {
		const actionName = "requestValidatePrevUserRole"
		try {
			const userNumId = idToNumber(userId, IdType.user)
			if (typeof userNumId === "number") {
				const response = yield validatePrevRole({ userId: userNumId })
				return response
			} else {
				console.log("user ID is invalid", userNumId)
			}
		} catch (error) {
			console.log(actionName, "error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestValidatePrevUserRole
