import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import GetProjRoleDetails from "./view-model-actions/get-proj-role-details"
import GetAssignedUsers from "./view-model-actions/get-assigned-users"
import {
	CombinedRole,
	AssignedUser,
	CombinedRoleProps,
	CombinedMenuProps,
	RoleSet2,
} from "../../../../service-modules/role-module/roles.data-model"
import { initialStore } from "./proj-roles.provider"
import { ConsoleLog } from "../../../../components/basic-elements"
import GetRoleSet from "../../../organization-side/org-access-control/org-checkin/store/view-model-actions/get-role-set"

const UiHelperActions = (self: any) => ({
	toggle(menuId: string) {
		const targetMenu = self.roleDetails
			.find(
				(role: CombinedRoleProps) => role.roleId === self.selectedRole
			)
			?.menuPermissions.find(
				(menu: CombinedMenuProps) => menu.menuId === menuId
			)
		// ConsoleLog("toggle----targetMenu", targetMenu)
		if (targetMenu) {
			targetMenu.collapsed = !targetMenu.collapsed
		}
	},
	expandAll() {
		const targetRole = self.roleDetails.find(
			(role: CombinedRoleProps) => role.roleId === self.selectedRole
		)

		targetRole?.menuPermissions.map((menu: CombinedMenuProps) => {
			menu.collapsed = false
		})
	},
	collapseAll() {
		const targetRole = self.roleDetails.find(
			(role: CombinedRoleProps) => role.roleId === self.selectedRole
		)

		targetRole?.menuPermissions.map((menu: CombinedMenuProps) => {
			menu.collapsed = true
		})
	},
})

const ProjRolesViewModel = types
	.model({
		roleSet: types.optional(RoleSet2, {}),
		selectedRole: types.optional(types.string, ""),
		roleDetails: types.array(CombinedRole),
		assignedUsers: types.array(AssignedUser),
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setRoleSet(roleSet: any) {
			self.roleSet = roleSet
		},
		resetRoleSet() {
			applySnapshot(self.roleSet, {})
		},
		setSelectedRole(roleId: string) {
			self.selectedRole = roleId
		},
		setRoleDetails(roleDetails: any) {
			self.roleDetails.length = 0
			self.roleDetails = roleDetails
		},
		setAssignedUsers(users: any) {
			self.assignedUsers.length = 0
			self.assignedUsers = users
		},
	}))
	.actions(GetRoleSet)
	// AGER
	.actions(GetProjRoleDetails)
	.actions(GetAssignedUsers)
	// views
	.views((self) => ({
		viewRoleSet2() {
			return self.roleSet
		},
		viewRoleSetWithUsers() {
			let list: any[] = []
			ConsoleLog(["viewRoleSetWithUsers default", self.roleSet.roles])
			self.roleSet.roles?.map((role: any) => {
				const matchedRole = self.roleDetails.find(
					(roleDetail) => roleDetail.roleId === role.id
				)
				let users: any[] = []
				ConsoleLog(["users", self.assignedUsers])
				self.assignedUsers.map((user) => {
					if (user.roleId === role.id) {
						users.push(user)
					}
				})
				let updatedRole: any
				if (matchedRole) {
					updatedRole = {
						...role,
						usePeriod: matchedRole.usePeriod,
						globalPermissions: matchedRole.globalPermissions,
						menuPermissions: matchedRole.menuPermissions,
						//
						users: users,
					}
				} else {
					ConsoleLog([
						"(viewRoleSetWithUsers in project) No matched role exist",
						role.roleId,
					])
				}

				list.push(updatedRole)
			})
			ConsoleLog(["viewRoleSetWithUsers", list])
			return list
		},
		viewRoleDetail(roleId: string) {
			const role = self.roleSet.roles?.find((role) => role.id === roleId)
			const matchedRole = self.roleDetails.find(
				(roleDetail) => roleDetail.roleId === roleId
			)
			let users: any[] = []
			self.assignedUsers.map((user) => {
				if (user.roleId === roleId) {
					users.push(user)
				}
			})
			const viewRoleDetail = {
				roleId: role?.id,
				//
				name: role?.name,
				shortName: role?.shortName,
				color: role?.color,
				//
				usePeriod: matchedRole?.usePeriod,
				globalPermissions: matchedRole?.globalPermissions,
				menuPermissions: matchedRole?.menuPermissions,
				//
				users: users,
			}
			// ConsoleLog("updated role", viewRoleDetail)
			return viewRoleDetail
		},
		allMenusAreCollapsed() {
			const targetRole = self.roleDetails.find(
				(roleDetail) => roleDetail.roleId === self.selectedRole
			)
			if (
				targetRole?.menuPermissions.find(
					(element) => element.collapsed === false
				)
			) {
				return false
			} else {
				return true
			}
		},
		allMenusAreExpanded() {
			const targetRole = self.roleDetails.find(
				(roleDetail) => roleDetail.roleId === self.selectedRole
			)

			if (
				targetRole?.menuPermissions.find(
					(element) => element.collapsed === true
				)
			) {
				return false
			} else {
				return true
			}
		},
		getRoleShortName(roleId: string) {
			return self.roleSet.roles?.find((role) => role.id === roleId)
				?.shortName
		},
		getRoleColor(roleId: string) {
			return self.roleSet.roles?.find((role) => role.id === roleId)?.color
		},
	}))
	.actions(UiHelperActions)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ProjRolesViewModel
