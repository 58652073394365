import { flow } from "mobx-state-tree"

const ReadAvailableFinGroups = (self: any, readAvailableGroups: any) => ({
	readAvailableFinGroups: flow(function*(payload: {
		fsTypeId: number
		clientId: number
	}) {
		const actionName = "readAvailableFinGroups"
		try {
			const response = yield readAvailableGroups(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadAvailableFinGroups
