import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { CreateProjectMethod } from "../../../components/combined-elements/create-project/create-project.data-props"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../library/converters/id-converter"
import { convertProjStatusAsEnum } from "../../../library/get-project-status"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetProjectDetails = (self: {
	setProjectDetails: (details: any) => void
	[x: string]: any
}) => ({
	getProjectDetails(projId: string) {
		// 0.
		const actionName = "getProjectDetails"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const projectNumberId = idToNumber(projId, IdType.project)
		const payload = {
			clientId: projectNumberId,
		}
		self.readProjectDetails(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// ConsoleLog(response)
					const projectDetails = response.data.Result

					const reOrganizedProjectDetails = {
						groupName: projectDetails.GroupName,
						//
						clientName: projectDetails.EngagementUser,
						clientAliasId: projectDetails.clientShortId,
						//
						id: idToString(projectDetails.clientId, IdType.project), // NOTE: projectDetails.clientId means project ID
						aliasId: projectDetails.ClientShortName,
						title: projectDetails.LinkName,
						replicaId: idToString(
							// NOTE: production has no replica ID yet
							// NOTE: if there is no replica, server returns 0
							projectDetails.ReplicaId,
							// ? projectDetails.ReplicaId
							// : "notExistYet",
							IdType.project
						),
						//
						engTypeName: projectDetails.EngagementType,
						raName: projectDetails.RiskAssessmentName,
						//
						projectStatus: projectDetails.ProjectType,
						periodName: projectDetails.Period,
						//
						version:
							projectDetails.Version === "-"
								? 0
								: parseInt(
										projectDetails.Version.replace("v", "")
								  ),
						isLocked:
							projectDetails.ProjectStatus === "Locked"
								? true
								: false,
						//
						periodEndDate: projectDetails.FiscalEnd,
						//
						expectedReportDate: projectDetails.expectedDate,
						finalReportDate: projectDetails.finalDate,
						expectedArchiveDeadlineDate:
							projectDetails.ExpectedArchiveDeadlineDate,
						//
						archPolicyPeriodId: projectDetails.ArchiveDatePolicy,
						//
						createdBy: projectDetails.CreatedUser,
						//
						archivedFinalDate: projectDetails.archivedFinalDate,
						//
						epUsers: projectDetails.EngagementUser,
						//
						address: projectDetails.Address,
						fee: projectDetails.Fee,
					}

					const projInfo = {
						groupName: projectDetails.GroupName,
						groupId: "",
						cabinetId: "",
						cabinetName: "",
						//
						engPartner: projectDetails.EngagementUser,
						//
						clientId: "",
						clientName: projectDetails.ClientName,
						clientAliasId: projectDetails.clientShortId,

						clientAddress: projectDetails.Address,
						//
						id: idToString(projectDetails.clientId, IdType.project),
						projTitle: projectDetails.LinkName,
						projAliasId: projectDetails.ClientShortName,

						statusId: "",
						statusName: convertProjStatusAsEnum(
							projectDetails.ProjectType
						),

						version:
							projectDetails.Version === "-"
								? 0
								: parseInt(
										projectDetails.Version.replace("v", "")
								  ),

						isArchived: false,
						isLocked:
							projectDetails.ProjectStatus === "Locked"
								? true
								: false,
						//
						engTypeName: projectDetails.EngagementType,
						engTypeId: "",
						//
						raName:
							projectDetails.RiskAssessmentName === null
								? ""
								: projectDetails.RiskAssessmentName,
						raId: "",
						//
						createdBy: projectDetails.CreatedUser,
						createdAt: "",
						//
						periodName: projectDetails.Period,
						periodId: "",
						//
						financialYear: 0,
						periodEndDate: "",
						//
						fee:
							projectDetails.Fee === undefined ||
							projectDetails.Fee === null ||
							projectDetails.Fee === ""
								? 0
								: typeof projectDetails.Fee === "number"
								? projectDetails.Fee
								: parseInt(projectDetails.Fee),
						archPolicyPeriodName: projectDetails.ArchiveDatePolicy,
						archPolicyPeriodId: idToString(
							projectDetails.ArchiveDatePolicy,
							IdType.archPolicyPeriod
						),
						//
						expectedReportDate:
							projectDetails.expectedDate === undefined ||
							projectDetails.expectedDate === null
								? ""
								: projectDetails.expectedDate,

						expectedArchDeadlineDate:
							projectDetails.ExpectedArchiveDeadLineDate ===
								undefined ||
							projectDetails.ExpectedArchiveDeadLineDate === null
								? ""
								: projectDetails.ExpectedArchiveDeadLineDate,

						finalReportDate:
							projectDetails.finalDate === undefined ||
							projectDetails.finalDate === null
								? ""
								: projectDetails.finalDate,
						// NOTE: because the GetClientInfo return null for archivedDeadlineDate, we need to use archivedFinalDate
						// NOTE: sometimes it return the value and sometimes not... but the archivedFinalDate returns value always...
						// NOTE: anyways, archivedDeadlineDate is not the correct wording :(  Final wording should be FinalArchiveDeadlineDate.
						finalArchDeadlineDate:
							projectDetails.archivedFinalDate === undefined ||
							projectDetails.archivedFinalDate === null
								? ""
								: projectDetails.archivedFinalDate,
						createMethod: CreateProjectMethod.byBlank,
						//
						archiveChecklistItem1: "",
						archiveChecklistItem2: "",
						archiveChecklistLastUpdatedBy: "",
						archiveChecklistLastUpdatedByUserAlias: "",
						archiveChecklistLastUpdatedByUserName: "",
						archiveChecklistLastUpdatedDate: "",
					}

					self.setProjInfo(projInfo)
					console.log(
						reOrganizedProjectDetails,
						"reOrganizedProjectDetails"
					)
					self.setProjectDetails(reOrganizedProjectDetails)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	getProjectReplicaId(projId: number) {
		const payload = {
			clientId: projId,
		}
		console.log("getProjectReplicaId", projId)
		self.readProjectDetails(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const projectDetails = response.data.Result

					const replicaId = projectDetails.ReplicaId
					console.log("replicaId", replicaId)
					return replicaId
				} else {
					console.log("failed to get project replicaId for", projId)
					return 0
				}
			})
			.catch((error: Error) => {
				console.log("err to get project replicaId for", projId)
			})
	},
	relayGetRepId(projList: any, index: number, updateList: any) {
		if (projList.length >= index + 1) {
			const project = projList[index]
			const projId = project.id
			const payload = {
				clientId: projId,
			}
			self.readProjectDetails(payload).then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const projectDetails = response.data.Result

					const replicaId = projectDetails.ReplicaId
					console.log("replicaId", replicaId)
					updateList({
						...project,
						replicaId,
					})
					if (projList.length > index + 1) {
						self.relayGetRepId(projList, index + 1, updateList)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				} else {
					console.log("failed to get project replicaId for", projId)
					updateList({
						...project,
						replicaId: 0,
					})
					if (projList.length > index + 1) {
						self.relayGetRepId(projList, index + 1, updateList)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				}
			})
			// .catch((err: Error) => {
			// 	console.log("err to get project replicaId for", projId, err)
			// 	if (projList.length > index + 1) {
			// 		self.relayGetRepId(projList, index + 1)
			// 	}
			// 	if (projList.length === index + 1) {
			// 		console.log("THE END")
			// 	}
			// })
		}
	},
	relayGetProjInfo(projList: any, index: number, updateList: any) {
		if (projList.length >= index + 1) {
			const project = projList[index]

			const projId = idToNumber(project.id, IdType.project)

			const archivedDate = project.archivedAt.slice(0, 10)
			const archivedTime = project.archivedAt.slice(11)
			// const archivedBy = ""
			const wpCount = 0
			const pfCount = 0
			const userCount = 0

			const payload = {
				clientId: projId,
			}

			self.readProjectDetails(payload).then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const projectDetails = response.data.Result

					const replicaId = projectDetails.ReplicaId

					const xxx = {
						...project,
						replicaId,
						//
						archivedDate,
						archivedTime,
						wpCount,
						pfCount,
						userCount,
					}

					updateList(xxx)
					if (projList.length > index + 1) {
						self.relayGetProjInfo(projList, index + 1, updateList)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				} else {
					console.log(
						index,
						"failed to get project detail for",
						projId
					)
					const xxx = {
						...project,
						replicaId: 0,
						//
						archivedDate,
						archivedTime,
						wpCount,
						pfCount,
						userCount,
					}

					updateList(xxx)
					if (projList.length > index + 1) {
						self.relayGetProjInfo(projList, index + 1, updateList)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				}
			})
			// .catch((err: Error) => {
			// 	console.log("err to get project replicaId for", projId, err)
			// 	if (projList.length > index + 1) {
			// 		self.relayGetRepId(projList, index + 1)
			// 	}
			// 	if (projList.length === index + 1) {
			// 		console.log("THE END")
			// 	}
			// })
		}
	},
})

export default GetProjectDetails
