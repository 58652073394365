import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"
import { ReadChecklistConnectionsProps } from "../org-eng-types.apis"

const GetChecklistConnections = (self: any) => ({
	getChecklistConnections(params: ReadChecklistConnectionsProps) {
		// 0.
		const actionName = "getChecklistConnections"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readChecklistConnections(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					if (params.projectTypeId) {
						// individual connection info
						self.setChecklistConnectionByProjectType(
							params.projectTypeId,
							response.data
						)
					} else {
						// all the connections
						self.setChecklistConnections(response.data)
					}
				} else {
					//
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetChecklistConnections
