import { flow } from "mobx-state-tree"

const ProjectLock = (self: any, lock: any) => ({
	projectLock: flow(function*() {
		const actionName = "projectLock"
		try {
			const response = yield lock()
			// ConsoleLog("project Lock response", response)
			return response
		} catch (error) {
			// ConsoleLog("project Lock error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ProjectLock
