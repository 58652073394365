// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const AddUsersToGroup = (self: any) => ({
	addUsersToGroup(groupId: string, userList: any) {
		// 0.
		const actionName = "addUsersToGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const Userlst = userList.map((items: any) => {
			return { UserID: idToNumber(items.UserId, IdType.user) }
		})
		const groupNumberId = idToNumber(groupId, IdType.group)
		let payload = {
			GroupID: groupNumberId,
			Userlst,
		}
		self.assignUsersToGroup(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// if success
					self.updateUnassignedUserOfGroup(userList)
					//
					self.updateAssignedUsers(
						response.data.AssignedUsers,
						response.data.UsersCount,
						groupId
					)
					// at the end of the action
					ConsoleLog(response)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddUsersToGroup
