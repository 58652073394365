import { types } from "mobx-state-tree"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"

export type OrgClientModel = {
	clientId: string // [ClientMasterID], ex) 10
	clientName: string // [ClientMasterName], ex) "A"
	clientAliasId: string // [ClientId], ex) "AS"
	// userId: string, Not Using// [UserID], ex) 10 // TODO: @Prudhvi-0817: Could you check what is the meaning fo this 'userId'? It seems have same value with clientId...
	// TODO: Prudhvi0817: If this userId means end-user, please update the createdBy as object { id: string, name: string } and delete above line
	createdAt: string // [CreatedDate], ex) "2018-11-26T21:35:40.140698-05:00"
	createdBy: string // [CreatedBy], ex) "AN"
}

export const OrgClient = types.model({
	clientId: types.string, // [ClientMasterID], ex) 10
	clientName: types.string, // [ClientMasterName], ex) "A"
	clientAliasId: types.string, // [ClientId], ex) "AS"
	// userId: types.string, Not Using// [UserID], ex) 10 // TODO: @Prudhvi-0817: Could you check what is the meaning fo this 'userId'? It seems have same value with clientId...
	// TODO: Prudhvi0817: If this userId means end-user, please update the createdBy as object { id: string, name: string } and delete above line
	createdAt: types.string, // [CreatedDate], ex) "2018-11-26T21:35:40.140698-05:00"
	createdBy: types.string, // [CreatedBy], ex) "AN"
})

export const GroupList = types.model({
	id: types.string, // [GroupName], ex) 74
	name: types.string, // [GroupName], ex) "Testing"
	selected: types.boolean,
})

export type BulkClientImportItemType = {
	clientId: string
	name: string
	aliasId: string
	duplicatedAliasId: boolean
	duplicatedName: boolean
	status: ActionStatus
	statusMessage: string
}

export const BulkClientsImportProps = types.model({
	clientId: types.string,
	name: types.string,
	aliasId: types.string,
	duplicatedAliasId: types.boolean,
	duplicatedName: types.boolean,
	status: types.enumeration<ActionStatus>(Object.values(ActionStatus)),
	statusMessage: types.string,
})

export enum AssignStatus {
	assigned = "ASSIGNED",
	unAssigned = "UNASSIGNED", // this part should be removed
}
