import {
	RoleSetProps,
	RolePermissionProps,
	CombinedRoleProps,
	RoleInfoProps,
	CombinedMenuProps,
} from "../service-modules/role-module/roles.data-model"
import { MenuType } from "../common-models/enumerations/common-enums"

function combineRoleAndPermission(
	roleSet: RoleSetProps,
	rolePermissions: RolePermissionProps[],
	defaultMenuList: {
		id: string
		title: string
		menuGroup: null | string
		url: string
	}[]
) {
	let roleDetails: CombinedRoleProps[] = []
	/**
	 * To do: combined data for roleDetails
	 * - for each roles
	 * (1) get rolePermissions from API
	 * (2) get roleInfos from self.roleSet
	 * (3) 1 + 2
	 *
	 * - for each menus in a role
	 * (a) get menuInfos from defaultMenuList
	 * (b) add menuPermissions of (1) to the (a)
	 * (c) convert (b) to tree type list
	 * (d) replace menuPermissions of (3) with (c)
	 *
	 * (8) push (d) to roleDetails
	 * (9) return roleDetails
	 */

	rolePermissions.map((role) => {
		const matchedRoleInfo = roleSet.roles.find(
			(role: RoleInfoProps) => role.roleId === role.roleId
		)
		// update menuPermissions in the rolePermission
		const menuPermissions = role.menuPermissions
		let combinedMenuFlatList: CombinedMenuProps[] = []

		menuPermissions.map((menu) => {
			const matchedMenuInfo = defaultMenuList.find(
				(menuInfo) => menuInfo.id === menu.menuId
			)
			if (matchedMenuInfo) {
				const combinedMenu = {
					menuId: menu.menuId,
					accessible: menu.accessible,
					actions: menu.actions,
					//
					title: matchedMenuInfo.title,
					url: matchedMenuInfo.url,
					menuGroup: matchedMenuInfo.menuGroup,
					menuType:
						matchedMenuInfo.menuGroup === null
							? MenuType.group
							: MenuType.sub,
					//
					collapsed: true,
					subMenus: [],
				}
				combinedMenuFlatList.push(combinedMenu)
			} else {
				console.error(
					"Matched menu not exist in the default menu list",
					menu.menuId
				)
			}
		})
		// convert flat list to tree list
		let treeList: CombinedMenuProps[] = []
		let mappedFlatData: any = {}
		combinedMenuFlatList.map((flatItem) => {
			mappedFlatData[flatItem.menuId] = {
				...flatItem,
			}
		})
		for (var menuId in mappedFlatData) {
			if (mappedFlatData.hasOwnProperty(menuId)) {
				const item = mappedFlatData[menuId]
				if (item.menuGroup === null) {
					treeList.push(item)
				} else {
					mappedFlatData[item.menuGroup]["subMenus"].push(item)
				}
			}
		}
		if (matchedRoleInfo) {
			const combinedRole = {
				...role,
				name: matchedRoleInfo.name,
				shortName: matchedRoleInfo.shortName,
				color: matchedRoleInfo.color,
				//
				menuPermissions: treeList,
			}
			roleDetails.push(combinedRole)
		} else {
			console.error("MatchedRole not exist", role.roleId)
		}
	})

	return roleDetails
}

export default combineRoleAndPermission
