import React, { useState } from "react"
import Snackbar from "@material-ui/core/Snackbar"
import { DLIconButton } from "../buttons"
import { Icon } from "@mdi/react"
import {
	mdiClose,
	mdiCheckboxMarkedCircleOutline,
	mdiAlertCircleOutline,
	mdiAlert,
	mdiAlertDecagram,
	mdiInformationVariant,
} from "@mdi/js"
import styled from "styled-components"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import DLSpinner from "../spinner/DLSpinner"
import ConsoleLog from "../ConsoleLog"

interface DLSnackbarProps {
	type?: MessageColorType
	anchorLocation?: "RIGHT_TOP" | "RIGHT_BOTTOM" | "LEFT_BOTTOM"
	open: boolean
	setOpen?: React.EventHandler<any>
	autoHideDuration?: number | null
	autoHide?: boolean
	message?: string
	additionalAction?: any
}

/**
 * Default AutoHideDuration is 6000. Update it if you want short duration.
 */

export default function DLSnackbar({
	type = MessageColorType.default,
	anchorLocation = "RIGHT_TOP",
	open = false,
	setOpen = undefined,
	autoHideDuration = 4000,
	autoHide = true,
	message = undefined,
	additionalAction = undefined,
}: DLSnackbarProps) {
	// @Noah: Check this for performance
	// ConsoleLog("@Performance check, DLSnackbar", [open, message])
	const handleClose = (
		event: React.SyntheticEvent | React.MouseEvent,
		reason?: string
	) => {
		if (reason === "clickaway") {
			return
		}
		if (setOpen) {
			setOpen(false)
		}
		if (additionalAction) {
			additionalAction()
		}
	}

	return (
		<>
			<Snackbar
				data-testid="dl-snackbar"
				data-status={`dl-snackbar-${type}`}
				anchorOrigin={
					anchorLocation === "RIGHT_TOP"
						? { vertical: "top", horizontal: "right" }
						: anchorLocation === "RIGHT_BOTTOM"
						? { vertical: "bottom", horizontal: "right" }
						: anchorLocation === "LEFT_BOTTOM"
						? { vertical: "bottom", horizontal: "left" }
						: // exception case: use RIGHT_TOP as default
						  { vertical: "top", horizontal: "right" }
				}
				open={open}
				autoHideDuration={autoHide ? autoHideDuration : null}
				transitionDuration={500}
				onClose={handleClose}
				message={message ? message : type}
				action={
					<DLIconButton
						eleTestId="snackbar-close-btn"
						clickHandler={handleClose}
					>
						<Icon path={mdiClose} size={1} />
					</DLIconButton>
				}
			>
				<StyledSnackbarContents
					className={`FR AC JSB ${type} ${
						autoHide ? "auto-hide" : ""
					}`}
				>
					<div className="contents-area FR AC">
						{type === "DEFAULT" ? (
							<Icon path={mdiInformationVariant} size={1} />
						) : type === "GREEN" ? (
							<div className="FR AC" style={{ width: 20 }}>
								<DLSpinner size={16} />
							</div>
						) : type === "BLUE" ? (
							<Icon
								path={mdiCheckboxMarkedCircleOutline}
								size={1}
							/>
						) : type === "ORANGE" ? (
							<Icon path={mdiAlertCircleOutline} size={1} />
						) : type === "CORAL" ? (
							<Icon path={mdiAlert} size={1} />
						) : type === "RED" ? (
							<Icon path={mdiAlertDecagram} size={1} />
						) : (
							""
						)}
						<div className="contents-text">
							{message
								? message.length > 200
									? message.slice(0, 200) + "..."
									: message
								: "Something went wrong (on DLScnackbar)"}
						</div>
					</div>
					<div className="action-area">
						<DLIconButton
							eleTestId="snackbar-close-btn"
							clickHandler={handleClose}
							eleClassName="close-btn"
						>
							<Icon path={mdiClose} size={0.8} />
						</DLIconButton>
					</div>
				</StyledSnackbarContents>
			</Snackbar>
		</>
	)
}

const StyledSnackbarContents = styled.div`
	width: 100%;
	height: 100%;
	min-height: 3rem;
	padding: 0.2rem 0.5rem 0.2rem 1rem;
	/* transition: 5s; */
	border-radius: 0.5rem;
	/* common settings  */
	.contents-area {
		.contents-text {
			margin-left: 0.5rem;
			margin-right: 1rem;
		}
	}
	.action-area {
		.close-btn {
			opacity: 0.4;
		}
	}
	/* setting for individual types */
	&.DEFAULT {
		background-color: ${(props) => props.theme.background};
		box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade20};
		&.auto-hide {
			box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade10};
		}
	}
	&.GREEN {
		background-color: ${(props) => props.theme.shared.messageGreen};
		box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade20};
		&.auto-hide {
			background-color: ${(props) =>
				props.theme.shared.messageGreenAutoHide};
			box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade10};
		}
		.contents-area {
			svg {
				fill: ${(props) => props.theme.textHighEmphasis};
			}
		}
	}
	&.BLUE {
		background-color: ${(props) => props.theme.shared.messageBlue};
		box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade20};
		&.auto-hide {
			background-color: ${(props) =>
				props.theme.shared.messageBlueAutoHide};
			box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade10};
		}
		.contents-area {
			svg {
				fill: ${(props) => props.theme.textHighEmphasis};
			}
		}
	}
	&.ORANGE {
		background-color: ${(props) => props.theme.shared.messageOrange};
		box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade20};
		&.auto-hide {
			background-color: ${(props) =>
				props.theme.shared.messageOrangeAutoHide};
			box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade10};
		}
	}
	&.CORAL {
		background-color: ${(props) => props.theme.shared.messageCoral};
		box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade20};
		&.auto-hide {
			background-color: ${(props) =>
				props.theme.shared.messageCoralAutoHide};
			box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade10};
		}
	}
	&.RED {
		background-color: ${(props) => props.theme.shared.messageRed};
		box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade20};
		&.auto-hide {
			background-color: ${(props) =>
				props.theme.shared.messageRedAutoHide};
			box-shadow: 2px 0px 10px 1px ${(props) => props.theme.shade10};
		}
	}
`
