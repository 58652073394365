import { flow } from "mobx-state-tree"

import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

const CreateFileFolder = (
	self: any,
	createFolder: FileSystemApiTypes["apiCreateFolder"]
) => ({
	createFileFolder: flow(function* (props: {
		projId: string
		parentId: string
		title: string
		menuId: DLOrgSubMenus | DLProjSubMenus
	}) {
		const actionName = "createFileFolder"
		const { projId, parentId, title, menuId } = props
		// 2.
		let parentNumId: any = ""
		let projNumId: any = ""

		ConsoleLog(["parentId", parentId, "| projId", projId])

		if (menuId === DLOrgSubMenus.proj_templates) {
			projNumId = idToNumber(projId, IdType.projTemplate)
			if (parentId === projId) {
				parentNumId = idToNumber(parentId, IdType.projTemplate)
			} else {
				parentNumId = idToNumber(parentId, IdType.folder)
			}
		} else {
			// projNumId = idToNumber(projId, IdType.project)
			projNumId = 0 // WP, PF do not require the project ID
			parentNumId = idToNumber(parentId, IdType.folder)
		}

		//
		//
		// 3.
		if (typeof parentNumId === "number" && typeof projNumId === "number") {
			try {
				let data: any = undefined
				if (menuId === DLOrgSubMenus.proj_templates) {
					// NOTE: Project Template
					data = new FormData()
					data.append("ParentTempFoldID", parentNumId.toString())
					data.append("TempName", title)
					data.append("MediaType", "Folder")
					data.append("WPREF", "")
					data.append("TemplateId", projNumId.toString())
				} else {
					// NOTE: WP, PF
					data = {
						ParentMediaID: parentNumId, // 0 will makes root folder (depth 0 folder)
						WPDesc: title,
					}
				}
				//
				//
				//
				const response = yield createFolder({ data, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(
				`(${actionName}) invalid props: ${parentNumId} | ${projNumId}`
			)
		}
	}),
})

export default CreateFileFolder
