import { types } from "mobx-state-tree"
import NotificationsModel from "./notifications.model"
import NotificationsViewModel from "./notifications.view-model"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"

const NotificationsStore = types
	.compose(NotificationsModel, NotificationsViewModel)
	.named("NotificationsStore")

export const initialStore = {
	notiList: [],
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	//
	actionStatus: ActionStatus.standby,
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const NotificationsStoreInstance = NotificationsStore.create(initialStore)

export default NotificationsStoreInstance
