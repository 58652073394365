import React from "react"
import CommentsColumns, { filterComponent } from "./CommentsColumns"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import {
	DialogActionType,
	DLCommentType,
} from "../../../../common-models/enumerations/common-enums"
import { CommentStatus } from "./store/data-models/comments.data-model"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommentsMainToolbar from "./sub-components/CommentsMainToolbar"
import { Table } from "@tanstack/react-table"

export default observer(function CommentsTable({
	comments,
	permission,
	// handleOpenAddDialog,
	handleToggleSelectAllFiles,
	handleSelectItem,
	handleCheckbox,
	handleRightClick,
	contentsHeight,
	handleDetailDialogOpen,
	dateFormat,
	i18n,
}: {
	comments: any[]
	permission: PermissionAsObjectProps
	// handleOpenAddDialog: () => void
	handleToggleSelectAllFiles: () => void
	handleSelectItem: (e: any, id: string, type: string) => void
	handleCheckbox: (e: any, id: string, type: string) => void
	handleRightClick: (e: any) => void
	contentsHeight: number
	handleDetailDialogOpen: (mode: DialogActionType) => void
	dateFormat: any
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const cmtStore = projStore.comments

	const isSelected = (id: string) => {
		const idList = cmtStore.selectedItemIdList
		return idList.includes(id)
	}
	// const handleToggleSelectAllFiles = () => {
	// 	cmtStore.toggleSelectAll(cmtStore.comments)
	// }

	const setSelectedItem = cmtStore.setSelectedItem

	const handleOpenReadonly = (id: string) => {
		setSelectedItem({ id, type: "" })
		handleDetailDialogOpen(DialogActionType.read)
	}
	const handleOpenEdit = (id: string) => {
		setSelectedItem({ id, type: "" })
		handleDetailDialogOpen(DialogActionType.edit)
	}

	const dntFormat = store.global.getDntFormat

	const handleOpenComment = (commentId: string) => {
		const commentInfo = cmtStore.viewCommentById(commentId, dntFormat)
		if (commentInfo) {
			// NOTE: Must update together: CommentCardSimple.tsx
			const { status, type } = commentInfo
			const isCompleted = status === CommentStatus.done
			const isNormalComment = type === DLCommentType.normal
			const projRole = projStore.checkin.user.roleShortName

			if (isCompleted) {
				handleOpenReadonly(commentId)
			} else {
				if (isNormalComment) {
					// review comment
					if (permission.update) {
						handleOpenEdit(commentId)
					} else {
						handleOpenReadonly(commentId)
					}
				} else {
					// QC comment
					if (projRole === "QC") {
						if (permission.update) {
							handleOpenEdit(commentId)
						} else {
							handleOpenReadonly(commentId)
						}
					} else {
						handleOpenReadonly(commentId)
					}
				}
			}
		}
	}

	const selectedItemLength = projStore.comments.selectedItemLength

	const totalCount = comments.length
	const closedCount = cmtStore.getClosedCount("all")
	const wpClosedCount = cmtStore.getClosedCount("wp")
	const pfClosedCount = cmtStore.getClosedCount("pf")
	const wpCommentsCount = cmtStore.wpCommentsCount
	const pfCommentsCount = cmtStore.pfCommentsCount
	const selectedCount = cmtStore.viewSelectedItems.length

	const pageHeader = (instance: Table<any>) => {
		const currentMenu =
			instance
				.getState()
				.columnFilters.find((item: any) => item.id === "menuId")
				?.value || "all"

		const handleCurrentMenu = (value: string) => {
			instance.setColumnFilters((props: any) => {
				const filteredProps = props.filter(
					(item: any) => item.id !== "menuId"
				)
				return [...filteredProps, { id: "menuId", value }]
			})
		}

		return (
			<CommentsMainToolbar
				currentMenu={currentMenu}
				handleCurrentMenu={handleCurrentMenu}
				totalCount={totalCount}
				closedCount={closedCount}
				wpClosedCount={wpClosedCount}
				pfClosedCount={pfClosedCount}
				wpCommentsCount={wpCommentsCount}
				pfCommentsCount={pfCommentsCount}
				selectedCount={selectedCount}
				handleDeleteMultipleComments={() =>
					cmtStore.setDeleteAllTrashDialogOpen(true)
				}
				i18n={i18n}
			/>
		)
	}

	return (
		<StyledCommentsTable>
			<ReactTableV8
				tableColumns={CommentsColumns({
					selectedItemLength,
					handleToggleSelectAllFiles,
					//
					setSelectedItem,
					handleDetailDialogOpen,
					handleRightClick,
					handleCheckbox,
					isSelected,
					handleOpenComment,
					i18n,
				})}
				pageHeader={pageHeader}
				data={comments}
				menuId={DLProjSubMenus.comments}
				height={contentsHeight}
				handleContextMenu={(e: any, rowInfo: any) => {
					const { id, type } = rowInfo
					handleSelectItem(e, id, type)
					handleRightClick(e)
				}}
				customFilters={(props: any) =>
					filterComponent({ ...props, dateFormat })
				}
				getRowClass={(rowInfo: any) => {
					const id = rowInfo?.original.id
					let className =
						rowInfo && isSelected(id)
							? "selected"
							: rowInfo?.original.status === CommentStatus.done
							? "closed-comment"
							: ""
					return className
				}}
				permission={permission}
			/>
		</StyledCommentsTable>
	)
})

const StyledCommentsTable = styled.div`
	.comments-table {
		padding: 0 1rem;
		.closed-comment {
			opacity: 0.5;
		}
		.comment-contents {
			white-space: nowrap;
			/* padding: 0 1rem; */
			font-weight: 700;
			cursor: pointer;
			opacity: 1;
			:hover {
				font-weight: 700;
			}
		}
		.comment-info {
			opacity: 0.8;
		}
	}
`
