import React from "react"
// components
import DLOrgTab from "./DLOrgTab"
// styles
import styled from "styled-components"
// WARNING:
import AppBar from "@material-ui/core/AppBar"
import Tabs from "@material-ui/core/Tabs"
import Tab from "@material-ui/core/Tab"
//
import { SubMenuInfoProps } from "./DLFixedTabsController"
import { ConsoleLog } from "../../basic-elements"

/**
 * This Tabs Component seems not matched with our case perfectly.
 * Create new one will be better but postponed.
 * Because there are many other works...
 */
export default function DLFixedTabs(props: any) {
	const {
		menuGroupUrlEdge,
		drawerWidth,
		collapsedDrawerWidth,
		sidebarOpen,
		selectedTab,
		handleChange,
		menuTabs,
		a11yProps,
		i18n,
	} = props

	ConsoleLog("DLFixedTabs", "")

	return (
		<StyledTabs
			drawerWidth={drawerWidth}
			collapsedDrawerWidth={collapsedDrawerWidth}
			data-testid="dl-fixed-tabs"
		>
			<AppBar
				className={sidebarOpen ? "app-bar" : "app-bar wide"}
				position="static"
				color="default"
			>
				<Tabs
					value={selectedTab}
					onChange={handleChange}
					variant="scrollable"
					scrollButtons="auto"
					aria-label="datalobby fixed tabs"
					className="tabs-container"
				>
					{menuTabs.map((menu: SubMenuInfoProps) => {
						return (
							<Tab
								key={menu.id}
								{...a11yProps(menu.id)}
								className="tab"
								label={
									<DLOrgTab
										id={menu.id}
										// title={i18n[menu.id] || menu.title}
										title={i18n[menu.id] || menu.title}
										hasUpdate={false}
										url={
											"/organization/" +
											menuGroupUrlEdge +
											"/" +
											menu.url
										}
									/>
								}
							/>
						)
					})}
				</Tabs>
			</AppBar>
		</StyledTabs>
	)
}

interface StyledTabsProps {
	drawerWidth: number
	collapsedDrawerWidth: number
}

const StyledTabs = styled.div<StyledTabsProps>`
	.app-bar {
		background-color: transparent;
		box-shadow: none;
		width: calc(100vw - ${(props) => props.drawerWidth}px);
		&.wide {
			width: calc(100vw - ${(props) => props.collapsedDrawerWidth}px);
		}
		.tabs-container {
			border-bottom: 1px solid ${(props) => props.theme.emphasisLight};
			color: ${(props) => props.theme.tabColor};
			height: var(--standard-unit);
			.active-nav {
				color: ${(props) => props.theme.emphasis};
				font-weight: 500;
			}
			.MuiTabs-indicator {
				height: 0px;
				background-color: ${(props) => props.theme.emphasis};
			}
			.MuiTab-root {
				padding: 2px 12px !important;
			}
			button {
				font-weight: var(--font-light);
			}
			button[aria-selected="true"] {
				background-color: #d2cfcf;
				color: #065299;
				font-weight: var(--font-bold);
				svg {
					fill: ${(props) => props.theme.orgSidebarEmphasisTxt};
				}
				border-radius: 4px;
			}
			& div:first-child[aria-disabled] {
				border-right: 1px solid ${(props) => props.theme.shade05};
				box-shadow: 3px 0px 20px 2px ${(props) => props.theme.shade05};
				transition: 0.4s;
				&:hover {
					box-shadow: 3px 0px 20px 2px
						${(props) => props.theme.shade10};
				}
			}
			& div:last-child[aria-disabled] {
				border-left: 1px solid ${(props) => props.theme.shade05};
				box-shadow: -3px 0px 20px 2px ${(props) => props.theme.shade05};
				transition: 0.4s;
				&:hover {
					box-shadow: -3px 0px 20px 2px
						${(props) => props.theme.shade10};
				}
			}
		}
	}
`
