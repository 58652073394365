import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const RemoveOrgTrashItem = (self: any) => ({
	removeOrgTrashItem(itemId: string) {
		// 0.
		const actionName = "removeOrgTrash"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		let params = {
			TrashID: parseInt(itemId),
		}
		self.deleteOrgTrashItem(params)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.deleteItem(itemId)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
					self.spliceSelectedItem(itemId)
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	removeMultipleItems(items: string[]) {
		// 0.
		const actionName = "removeMultipleItems"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		items.map((item) => {
			self.removeOrgTrashItem(item)
		})
	},
})

export default RemoveOrgTrashItem
