import { ProgressStatus } from "../../common-models/enumerations/common-enums"
import { findProjRoleInfo } from "../../temporary-data/project-side/find-proj-role"

export function sortFoldersNFiles(data: any[]) {
	const sortedFolders = sortItems(data, "folder")
	const sortedFiles = sortItems(data, "file")
	const mergedArray = sortedFolders.concat(sortedFiles)
	return mergedArray
}

function sortItems(items: any[], type: "folder" | "file") {
	const itemStartsWith = type === "folder" ? "folderId" : "fileId"
	const target = type === "folder" ? "title" : "aliasId"
	const sortedItems = items
		.filter((item: any) => item.id.startsWith(itemStartsWith))
		.sort((a: any, b: any) => {
			const aTitle =
				typeof a[target] === "string" ? a[target].toUpperCase() : a
			const bTitle =
				typeof b[target] === "string" ? b[target].toUpperCase() : b
			if (aTitle < bTitle) {
				return -1
			}
			if (aTitle > bTitle) {
				return 1
			}
			return 0
		})
	return sortedItems
}

export function getCommentStatus(commentInfo: any) {
	const { completed, pending } = commentInfo
	const total = completed + pending
	const completedStatus = `Completed (${completed})`
	const pendingStatus = `Pending (${pending})`
	return `${total} - ${completedStatus}, ${pendingStatus}`
}

export function getAttentionStatus(status: ProgressStatus) {
	switch (status) {
		case ProgressStatus.todo:
			return "Not Started"
		case ProgressStatus.inProgress:
			return "In Progress"
		case ProgressStatus.done:
			return "Completed"
		default:
			return "-"
	}
}

export function getAssignedRolesForAFile(rolesList: number[]) {
	if (rolesList.length > 0) {
		return rolesList
			.map((roleID) => findProjRoleInfo(roleID).shortName)
			.join(", ")
	} else {
		return "-"
	}
}
