// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const RemovePreConditionFiles = (self: any) => ({
	removePreConditionFiles(projId: string, userId: string) {
		// 0.
		const actionName = "removePreConditionFiles"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.deletePreConditionFiles(projId, userId)
			.then((response: any) => {
				// if success
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					self.resetPreConditionData()
					self.setCancelPreProcessDialogOpen(false)
					// self.getPreConditionStatus()
					// if success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemovePreConditionFiles
