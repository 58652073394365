import React, { useEffect, useCallback } from "react"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { ConsoleLog, DLButton } from "../../../../../components/basic-elements"
import styled from "styled-components"
import { DefaultSignOffPolicy } from "../../../../../temporary-data/project-side/signoff-policy"
import { sortBy } from "@datalobby/common"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import {
	FileMenuViewType,
	FileScreenColumn,
	FileScreenDialog,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import LinearProgress from "@material-ui/core/LinearProgress"
import FileOnlyTableColumns from "./FileOnlyTableColumns"
import FileOnlyTableCounter from "./FileOnlyTableCounter"
import handleExportFileList from "./handle-export-file-list"
import { periodOptions } from "../../../../../common-models/enumerations/org-related-enums"
import { SearchResultsCount } from "../file-menu-search-view/FileMenuSearchResults"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"

export default observer(function FileOnlyTableView({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = partialStore.storeName
	const handleFileStatus = partialStore.editFileStatus
	// select related
	const toggleSelectAllFiles = partialStore.toggleSelectAllFiles
	const setCheckbox = partialStore.pushOrSpliceSelectedItem
	const isSelected = partialStore.isSelected
	// for sign-off area
	const singleSignOff = partialStore.singleSignOff
	const getColumnSignOffs = partialStore.getSignOffListByColumnId
	const permission = projStore.checkin.getActionsAsObject(menuId)
	const handleOpenCommentListOfFile = (objId: string) => {
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentList, true)
	}
	//
	const handleCheckbox = useCallback((fileId: string) => {
		setCheckbox(fileId, false)
	}, [])

	// NOTE: temporary commented
	// useEffect(() => {
	// 	if (
	// 		partialStore.flatList.filter((item: any) => item.isParent).length >=
	// 			1 &&
	// 		partialStore.flatList.find((item: any) => !item.isParent) ===
	// 			undefined
	// 	) {
	// 		partialStore.getFirstFolders(projStore.checkin.projId)
	// 	}
	// }, [])

	const handleRequestMore = () => {
		partialStore.getAllFoldersAndFiles(projStore.checkin.projId, false) // refreshAll: false
		partialStore.resetSelectedItems()
	}

	const handleGetAllFiles = () => {
		partialStore.getAllFoldersAndFiles(projStore.checkin.projId, true) // refreshAll: true
		partialStore.resetSelectedItems()
	}

	const handleRightClick = (event: any, id: string) => {
		event.preventDefault()
		ConsoleLog(["right click on the table view", event, id])
		// if there are some selected items already, use push
		// if there are no selected items yet, use ... push
		// if there are folder item in the list, use set // (TBD) this line

		partialStore.setFileClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleCtxMenuButtonClick = useCallback(
		(event: any, fileId: string) => {
			partialStore.setSelectedItem(fileId, false)
			partialStore.setFileClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
		},
		[]
	)

	const setSelectedFile = (fileId: string) => {
		partialStore.setSelectedItem(fileId, false)
	}
	const handleClickFile = (e: any, fileId: string) => {
		e.preventDefault()
		partialStore.setSelectedItemsWithKeyboard({
			itemId: fileId,
			isParent: false,
			shiftKey: e.shiftKey,
			altKey: e.altKey,
		})
	}

	const formattedFileOnly = partialStore.formattedFileOnly

	const { periodName, financialYear, clientName } =
		projStore.projInfo.projInfo

	const periodAbbrName = periodOptions.find(
		(item: any) =>
			item.label.replace(/ /g, "").toLowerCase() ===
			periodName.replace(/ /g, "").toLowerCase()
	)?.abbr
	const timeZoneAbbr = store.global.timeZone.value
		.match(/\b([A-Z])/g)
		?.join("")

	const fileCount = partialStore.fileCount
	console.log("fileCount:", fileCount)
	const noFilesYet = fileCount === 0

	useEffect(() => {
		if (noFilesYet) {
			console.log("no files. start the handleReuqestMore")
			handleRequestMore()
		}
	}, [noFilesYet])

	const totalFileCount = partialStore.totalFileCount
	const countSelectedFiles = partialStore.countSelectedFiles
	const signOffColumnsDisplay = partialStore.showSignOffColumns

	const cellType = partialStore.signOffCellType
	const dntFormat = store.global.getDntFormat

	// WARNING: formattedFileOnly returns file list or search results by current xviewType
	const data = formattedFileOnly(dntFormat)

	const signOffPolicy = sortBy(DefaultSignOffPolicy, "order")
	let treeHeight = store.ui.contentsAreaHeight - 36 - 48
	const signOffAreaWidth =
		partialStore.columnWidth[FileScreenColumn.signOffArea]

	let tableLoad = true
	let statusList: any = []
	const folderList = partialStore.folderList
	folderList.map((folder: any) => {
		const actionName = "getChildrenOfFolder" + folder.id
		const status = partialStore.getActionStatus(actionName)

		statusList.push(status)
	})

	if (
		partialStore.getActionStatus("relayGetChildrenOfFolder") ===
		ActionStatus.loading
	) {
		tableLoad = false
	}
	if (
		partialStore.getActionStatus("searchFileOrFolder") ===
		ActionStatus.loading
	) {
		tableLoad = false
	}
	if (
		partialStore.getActionStatus("relayGetChildrenOfFolder") ===
		ActionStatus.success
	) {
		tableLoad = true
	}

	const actionStatus = partialStore.getActionStatus(
		"relayGetChildrenOfFolder"
	)

	return (
		<StyledFileOnlyTableView
			className="table-view-container"
			style={{ height: treeHeight }}
		>
			<div className="FR AC JSB counting-header">
				{partialStore.viewType === FileMenuViewType.fileTable && (
					<FileOnlyTableCounter
						handleRequestMore={handleRequestMore}
						handleGetAllFiles={handleGetAllFiles}
						dataLength={data.length}
						totalFileCount={totalFileCount}
						countSelectedFiles={countSelectedFiles}
						actionStatus={actionStatus}
					/>
				)}
				{partialStore.viewType === FileMenuViewType.search && (
					<SearchResultsCount count={data.length} />
				)}
				{permission.export && (
					<DLButton
						eleTestId="export-file-list"
						clickHandler={() =>
							handleExportFileList({
								formattedFileOnly,
								dntFormat,
								periodName,
								financialYear,
								clientName,
								menuId,
								timeZoneAbbr,
								periodAbbrName,
								viewType: partialStore.viewType,
							})
						}
					>
						Export
					</DLButton>
				)}
			</div>
			{partialStore.viewType !== FileMenuViewType.search &&
				actionStatus === "LOADING" && (
					<LinearProgress
						value={(data.length / totalFileCount) * 100}
						variant="determinate"
					/>
				)}
			{tableLoad && (
				<ReactTableV8
					tableColumns={FileOnlyTableColumns({
						handleOpenCommentListOfFile,
						handleFileStatus,
						toggleSelectAllFiles,
						handleCheckbox,
						setSelectedFile,
						isSelected,
						countSelectedFiles,
						// for sign off area
						signOffPolicy,
						getColumnSignOffs,
						singleSignOff,
						cellType,
						dateFormat: dntFormat.dateFormat,
						signOffAreaWidth,
						signOffColumnsDisplay,
						handleCtxMenuButtonClick,
						permission,
						handleClickFile,
					})}
					customFilters={filterComponent}
					handleContextMenu={(e: any, row: any) => {
						const id = row.id
						if (partialStore.selectedItemsHasNoFolder) {
							if (partialStore.getSelectedItemsCount > 1) {
								partialStore.pushSelectedItem(id, false)
							} else {
								partialStore.setSelectedItem(id, false)
							}
						} else {
							partialStore.setSelectedItem(id, false)
						}
						handleRightClick(e, id)
					}}
					getRowClass={(row: any) => {
						let className = ""
						if (isSelected(row.original.id)) {
							className = className + " selected"
						}
						return className
					}}
					data={data}
					menuId={menuId}
					showPageSetting={false}
					hasPagination={false}
					height={store.ui.currentPageContentsAreaHeight}
				/>
			)}
		</StyledFileOnlyTableView>
	)
})

const filterComponent = ({
	column,
	table,
}: {
	column: Column<any>
	table: Table<any>
}) => (
	<InputFieldForTableV8
		columnId={column.id}
		onChange={column.setFilterValue}
		value={column.getFilterValue()}
	/>
)

const StyledFileOnlyTableView = styled.div`
	/* width: 500px; */
	/* height: 500px; */

	.counting-header {
		height: 48px;
		padding-left: 1rem;
		/* height: ${(props) => props.theme.shared.baseUnit}; */
	}
	.file-table-view {
		/* border: 1px solid pink; */
		padding: 0 1rem;
	}
	.partition {
		margin: 0 1rem;
		color: lightgray;
	}
	.MuiLinearProgress-root {
		height: 10px;
		border-radius: 5px;
		margin-left: 1rem;
		margin-right: 1rem;
	}
`
