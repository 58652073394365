import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	RestoreIcon,
	IconPlaceholder,
} from "../../../../../components/basic-elements/icons/common-icons"
import { ConsoleLog } from "../../../../../components/basic-elements"

export default observer(function OrgSetupUsersCtxMenus({
	permission,
	i18n,
}: {
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=-$ OrgSetupUsersCtxMenus $-=-=-=-=- ")

	const orgStore = useOrgStore()

	const handleClose = () => {
		orgStore.setupUsers.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	const userInfo = orgStore.setupUsers.viewSelectedUserInfo()

	const menuOptions = [
		{
			label: `${i18n.twUserDetail}`,
			value: "ctx-detail",
			available: permission.read,
			isLink: true,
			linkTo: "/organization/organization-setup/organization-user-list/detail",
			clickEvent: () => {},
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twEdit}`,
			value: "ctx-edit",
			available: permission.update && userInfo?.status !== "suspended",
			clickEvent: () => orgStore.setupUsers.setEditDialogOpen(true),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: `Assigned Projects`,
			value: "ctx-assigned-projects",
			clickEvent: () =>
				orgStore.setupUsers.setAssignedProjectsDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twSuspend}`,
			value: "ctx-suspend",
			available: permission.update,
			disabled: userInfo?.status === "normal" ? false : true,
			clickEvent: () => orgStore.setupUsers.setSuspendDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twRestore}`,
			value: "ctx-restore",
			available: permission.update,
			disabled: userInfo?.status === "suspended" ? false : true,
			clickEvent: () => {
				orgStore.setupUsers.setRestoreFrom("contextMenu")
				orgStore.setupUsers.setRestoreDialogOpen(true)
			},
			icon: <RestoreIcon />,
		},
		{
			label: `${i18n.twRemove}`,
			value: "ctx-remove",
			available: permission.delete,
			disabled: userInfo?.status === "suspended" ? false : true,
			clickEvent: () => orgStore.setupUsers.setRemoveDialogOpen(true),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	return (
		<DLContextMenu
			eleId="org-setup-users-ctx-menus"
			clickPoint={{
				mouseX: orgStore.setupUsers.clickPoint.mouseX,
				mouseY: orgStore.setupUsers.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1, 3]}
		/>
	)
})
