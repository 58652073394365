import React, { useEffect, useState, useCallback } from "react"
// import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLDialog, ConsoleLog } from "../../../../components/basic-elements"
import ProjInfoUpdateForm from "./ProjInfoUpdateForms"
import { observer } from "mobx-react-lite"
import { addTzToDate } from "../../../../library/converters/date-utc-converter"
import { ProjInfoUpdateInputProps } from "../store/data-models/proj-info-update-input-props"
import { addDays } from "date-fns"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { Tabs, Tab } from "@material-ui/core"
import styled from "styled-components"
import OtherUsersComponent from "../../../../components/combined-elements/create-project/OtherUsersComponent"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"

export default observer(function ProjInfoUpdateDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	// 	{
	// 	open,
	// 	setOpen,
	// }: {
	// 	open: boolean
	// 	setOpen: () => void
	// }
	const store = useRootStore()
	// const projStore = useProjStore()
	// const projInfoStore = projStore.projInfo
	//
	const open = partialStore.updateDialogOpenStatus
	const setOpen = partialStore.setUpdateDialogOpenStatus

	const [selectedTab, setSelectedTab] = useState<"info" | "users">("info")

	//
	const groupId = partialStore.projInfo.groupId

	const dntFormat = store.global.getDntFormat
	const timeZone = store.global.timeZone
	const tzValue = timeZone.uiValue
	//
	const {
		clientId,
		clientName,
		clientAliasId,
		//
		projTitle,
		projAliasId,
		//
		engTypeId,
		engTypeName,
		//
		statusName,
		periodId,
		periodName,
		//
		archPolicyPeriodId,
		//
		financialYear,
		periodEndDate,
		expectedReportDate,
		expectedArchDeadlineDate,
		finalReportDate,
		finalArchDeadlineDate,
		//
		raId,
		raName,
		//
		clientAddress,
		fee,
		otherUsers,
		createMethod,
	} = partialStore.projInfo
	// setInputs()
	const [inputs, setInputs] = useState<ProjInfoUpdateInputProps>(
		{
			clientId,
			clientName,
			clientAliasId,
			//
			projTitle,
			projAliasId,
			//
			engTypeId,
			engTypeName,
			periodId,
			periodName,
			archPolicyPeriodId,
			statusName,
			//
			financialYear,
			periodEndDate,
			expectedReportDate,
			expectedArchDeadlineDate,
			finalReportDate,
			finalArchDeadlineDate,
			//
			raId,
			raName,
			//
			clientAddress,
			fee,
			otherUsers,
		}
		// JSON.parse(
		// 	JSON.stringify({
		// 		...projStore.projInfo.projInfo,
		// 		...projStore.projInfo.projInfo,
		// 	})
		// )
	)

	const isThereAnyChanges = () => {
		if (
			clientId === inputs.clientId &&
			clientName === inputs.clientName &&
			clientAliasId === inputs.clientAliasId &&
			//
			projTitle === inputs.projTitle &&
			projAliasId === inputs.projAliasId &&
			//
			engTypeId === inputs.engTypeId &&
			engTypeName === inputs.engTypeName &&
			//
			periodId === inputs.periodId &&
			periodName === inputs.periodName &&
			//
			archPolicyPeriodId === inputs.archPolicyPeriodId &&
			//
			financialYear === inputs.financialYear &&
			periodEndDate === inputs.periodEndDate &&
			expectedReportDate === inputs.expectedReportDate &&
			expectedArchDeadlineDate === inputs.expectedArchDeadlineDate &&
			finalReportDate === inputs.finalReportDate &&
			finalArchDeadlineDate === inputs.finalArchDeadlineDate &&
			//
			raId === inputs.raId &&
			raName === inputs.raName &&
			//
			clientAddress === inputs.clientAddress &&
			fee === inputs.fee
		) {
			return false
		} else {
			return true
		}
	}

	const inputValidation = () => {
		if (
			// are there any changes?
			isThereAnyChanges() &&
			// do mandatory data exist?
			inputs.clientName !== "" &&
			inputs.clientAliasId !== "" &&
			inputs.projTitle !== "" &&
			inputs.projAliasId !== "" &&
			inputs.periodId !== "" &&
			inputs.periodEndDate !== ""
		) {
			return true
		} else {
			return false
		}
	}

	useEffect(() => {
		// ConsoleLog("groupId", groupId)
		partialStore.getClientsByGroup(groupId)
		partialStore.getEngTypeList()
		partialStore.getRiskAssessmentList()
		partialStore.getProjPeriodTypes()

		partialStore.getArchPolicyPeriodList()
	}, [])

	const handleUpdate = () => {
		partialStore.editProjInfo(inputs)
	}

	const handleChange = (event: any) => {
		const target = event.target.id
		const value = event.target.value
		if (target === "fee") {
			const re = /^[0-9\b]+$/
			if (value === "" || re.test(value)) {
				setInputs((inputs: any) => ({
					...inputs,
					[target]: value,
				}))
			}
		} else {
			setInputs((inputs: any) => ({
				...inputs,
				[target]: value,
			}))
		}
		// ConsoleLog("handleChange", inputs)
	}

	/**
	 * period end date & expected archive deadline date do not get 23:59:59 from the server
	 */
	const handleDateInput = (name: any, date: any) => {
		const newDate = addTzToDate(date, tzValue)

		ConsoleLog(["newDate", name, newDate])
		setInputs((inputs: any) => ({
			...inputs,
			[name]: newDate,
		}))
		if (name === "periodEndDate") {
			const year = new Date(newDate).getFullYear()
			if (year !== inputs.financialYear) {
				setInputs((inputs: any) => ({
					...inputs,
					financialYear: year,
				}))
			}
		}
		if (name === "expectedReportDate") {
			const days = partialStore.getArchiveDays(inputs.archPolicyPeriodId)
			if (days) {
				changeExpectedArchiveDeadLineDate(days, date) // TODO: ??
			}
		}
		if (name === "finalReportDate") {
			const days = partialStore.getArchiveDays(inputs.archPolicyPeriodId)
			if (days) {
				changeFinalArchiveDeadLineDate(days, date)
			}
		}
	}

	const changeExpectedArchiveDeadLineDate = (days: number, date: string) => {
		const newDate = new Date(date)
		const expectedDate = addDays(newDate, days)
		ConsoleLog(["expectedDate1 ---", expectedDate])

		setInputs((projInfo: any) => ({
			...projInfo,
			expectedArchDeadlineDate: addTzToDate(expectedDate, tzValue),
		}))
	}

	const changeFinalArchiveDeadLineDate = (days: number, date: any) => {
		if (date !== "" && date !== null) {
			if (days) {
				const newDate = new Date(date)
				const expectedDate = addDays(newDate, days)
				ConsoleLog(["expectedDate1 ---", expectedDate])

				setInputs((projInfo: any) => ({
					...projInfo,
					finalArchDeadlineDate: addTzToDate(expectedDate, tzValue),
				}))
			}
		}
	}

	const handleSelectArchPolicyPeriod = useCallback((e: any) => {
		const newArchPolicyPeriodId = e.target.value
		setInputs((projInfo: any) => ({
			...projInfo,
			archPolicyPeriodId: newArchPolicyPeriodId,
		}))
		const days = partialStore.getArchiveDays(newArchPolicyPeriodId)
		if (days) {
			changeExpectedArchiveDeadLineDate(days, inputs.expectedReportDate) // TODO: ??
			changeFinalArchiveDeadLineDate(days, inputs.finalReportDate) // TODO: ??
		}
	}, [])

	const handleComboBoxInput = useCallback((name: any, value: any) => {
		if (value !== null) {
			setInputs((inputs: any) => ({
				...inputs,
				[name]: value.id,
			}))
			if (name === "clientId") {
				setInputs((projInfo: any) => ({
					...projInfo,
					clientAliasId: value.clientAliasId,
				}))
			}
		} else {
			setInputs((inputs: any) => ({
				...inputs,
				[name]: "",
			}))
		}
	}, [])

	ConsoleLog(["inputs in dialog", inputs])
	//
	const clientList = partialStore.viewClientsList()
	const engTypeList = partialStore.viewEngTypeOptions()
	const periodList = partialStore.viewPeriodNames()
	const archPolicyPeriods = partialStore.viewArchPolicyPeriodList()
	const raList = partialStore.viewRiskAssessmentOptions()
	//
	ConsoleLog(["clientList in dialog", clientList])
	return (
		<DLDialog
			eleTestId="proj-info-edit-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showOpenBtn={false}
			showCloseBtn
			draggable
			dialogContents={
				<StyledProjectUpdateForm>
					{createMethod === CreateProjectMethod.byHardCopyOnly && (
						<Tabs
							value={selectedTab}
							onChange={(e: any, value: any) =>
								setSelectedTab(
									selectedTab === "info" ? "users" : "info"
								)
							}
							className="proj-info-tabs"
							indicatorColor="primary"
							textColor="primary"
						>
							<Tab
								label="Information"
								data-testid="proj-info-tab"
								className={
									"proj-info-tab " +
									(selectedTab === "info" ? "selected" : "")
								}
							/>
							<Tab
								label="Other Users"
								data-testid="proj-users-tab"
								className={
									"proj-info-tab " +
									(selectedTab === "users" ? "selected" : "")
								}
							/>
						</Tabs>
					)}
					{selectedTab === "info" && (
						<ProjInfoUpdateForm
							inputs={inputs}
							// setInputs={setInputs}
							handleChange={handleChange}
							handleSelectArchPolicyPeriod={
								handleSelectArchPolicyPeriod
							}
							handleDateInput={handleDateInput}
							handleComboBoxInput={handleComboBoxInput}
							//
							dntFormat={dntFormat}
							//
							clientList={clientList}
							engTypeList={engTypeList}
							periodList={periodList}
							archPolicyPeriods={archPolicyPeriods}
							raList={raList}
						/>
					)}
					{selectedTab === "users" && (
						<OtherUsersComponent
							otherUsers={inputs.otherUsers}
							setInputs={setInputs}
						/>
					)}
				</StyledProjectUpdateForm>
			}
			dialogTitle={i18n.twEditProjInfo || "Edit Project Information"}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twSave || "Save"}
			handleAction={handleUpdate}
			// additionalCloseAction={resetProjInfo}
			maxWidth="md"
			fullWidth={false}
			actionReady={inputValidation()}
		/>
	)
})

const StyledProjectUpdateForm = styled.div`
	width: 700px;
	.proj-info-tabs {
		min-height: 2rem;
		.proj-info-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
			span {
				/* for the border bottom highlighter */
				background-color: transparent;
			}
		}
	}
`
