import { types } from "mobx-state-tree"
import RequestResetPassword from "./model-actions/request-reset-user-pw"

const ResetPasswordModel = (apiResetPassword: any) =>
	types
		.model({})
		.actions((self, resetPassword = apiResetPassword) =>
			RequestResetPassword(self, resetPassword)
		)

export default ResetPasswordModel
