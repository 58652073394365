import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiCommentOutline, mdiDotsHorizontal, mdiAlertOutline } from "@mdi/js"
import { DLContextMenu, DLIconButton } from "../../basic-elements"
import { DLTooltip } from "@datalobby/components"
import Partition, { WidthControl } from "./file-row-sub-components/Partition"
import SignOffPartWidthControl from "./SignOffPartWidthControl"
import { FileColumn } from "../../../service-modules/file-module/data-models/dl-file-control-model"
import { SignOffPolicyItemProps } from "../../../service-modules/file-module/data-models/sign-off.data-model"
import { ResizableFileInfoArea } from "../../../service-modules/file-module/ui-helper-actions/ui-helper.actions"

export default observer(function FileTreeColumnHeader({
	setColumnHighlight,
	needCollapseAll,
	// left side
	indentWidth,
	assignedRolesWidth,
	aliasIdWidth,
	formatWidth,
	titleWidth,
	sizeWidth,
	signOffLockWidth,
	// right side
	contentsAreaWidth,
	updateFileInfoAreaWidth,
	updateSignOffAreaWidth,
	signOffAreaWidth,
	historyWidth,
	//
	simpleMode,
	columnDisplayStatus,
	signOffColumnsDisplay,
	signOffPolicy,
}: {
	// partialStore: any
	setColumnHighlight: (columnName: string) => void
	needCollapseAll: boolean
	//
	indentWidth: number
	assignedRolesWidth: number
	aliasIdWidth: number
	formatWidth: number
	titleWidth: number
	sizeWidth: number
	signOffLockWidth: number
	//
	contentsAreaWidth: number
	updateFileInfoAreaWidth: (
		part: ResizableFileInfoArea,
		value: number
	) => void
	updateSignOffAreaWidth: (value: number) => void
	signOffAreaWidth: number
	historyWidth: number
	//
	simpleMode?: boolean
	columnDisplayStatus: { [x: string]: boolean }
	signOffColumnsDisplay: { [x: string]: boolean }
	signOffPolicy: SignOffPolicyItemProps[]
}) {
	// const store = useRootStore()

	// const setHighlight = partialStore.setColumnHighlight
	// const updateSignOffAreaWidth = partialStore.updateSignOffAreaWidth

	// ----- Column header highlight
	const handleMouseEnter = (e: any) => {
		setColumnHighlight(e.target.id)
	}
	const handleMouseLeave = (e: any) => {
		setColumnHighlight("")
	}

	// ----- Column header resizing
	const [resizingTarget, setResizingTarget] = useState<
		ResizableFileInfoArea | "signOffArea"
	>("signOffArea")
	const [resizingStart, setResizingStart] = useState(false)
	const [movedX, setMovedX] = useState(0)

	const handleMouseDown = (target: ResizableFileInfoArea | "signOffArea") => {
		setResizingTarget(target)
		setResizingStart(true)
	}
	const handleMouseUp = (e: any) => {
		setResizingStart(false)
	}

	const handleMouseMove = (e: any) => {
		resizingStart && setMovedX(e.movementX)
	}

	useEffect(() => {
		if (resizingStart) {
			if (resizingTarget === "signOffArea") {
				updateSignOffAreaWidth(movedX)
			} else {
				updateFileInfoAreaWidth(resizingTarget, movedX)
			}
		}
	}, [resizingStart, movedX])

	const [rightClickPoint, setRightClickPoint] = useState<{
		mouseX: null | number
		mouseY: null | number
	}>({ mouseX: null, mouseY: null })

	function signOffPolicyHasThisColumn(columnId: string) {
		const result = signOffPolicy.findIndex((item) => item.id === columnId)
		if (result !== -1) {
			return true
		} else {
			return false
		}
	}

	// NOTE: update as sane as FileRowSignOffArea
	let divide = signOffPolicy.length
	for (const item in signOffColumnsDisplay) {
		if (!signOffColumnsDisplay[item]) {
			divide = divide - 1
		}
	}
	const cellWidth = signOffAreaWidth / divide // 5 // TODO: update with sign off policy length
	let fixedArea = signOffAreaWidth + 24 + 24 + 160 + historyWidth // 24 means comment icon width
	//
	return (
		<StyledFileTreeColumnHeader
			className={`file-tree-column-header FR AC ${
				needCollapseAll && "display"
			}`}
			onMouseUp={handleMouseUp}
			onMouseMove={handleMouseMove}
			onMouseLeave={handleMouseUp}
			// fixedArea
		>
			<div
				className="left-side-column-header FR AC"
				// style={{ width: contentsAreaWidth -  }}
			>
				{/* <div style={{ width: indentWidth + 16 }}>&nbsp;</div> */}
				<div style={{ width: indentWidth + 24 }}>&nbsp;</div>
				{/* place holder for 1 indent */}
				{columnDisplayStatus[FileColumn.assignedRoles] && (
					<DLTooltip title="Assigned Roles" elePlacement="top">
						<div
							className="FR AC"
							style={{
								width: assignedRolesWidth,
								overflow: "hidden",
							}}
						>
							Roles
						</div>
					</DLTooltip>
				)}
				{/* <WidthControl
					handleMouseDown={handleMouseDown}
					target={ResizableFileInfoArea.roles}
				/> */}
				{columnDisplayStatus[FileColumn.fileAliasId] && (
					<div
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						id="file_refNo"
						className="FR AC"
						style={{ width: aliasIdWidth, overflow: "hidden" }}
					>
						Ref. num
					</div>
				)}
				<WidthControl
					handleMouseDown={handleMouseDown}
					target={ResizableFileInfoArea.aliasId}
				/>
				{columnDisplayStatus[FileColumn.format] && (
					<div
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						id="file_format"
						className="FR AC"
						style={{ width: formatWidth, overflow: "hidden" }}
					>
						Format
					</div>
				)}
				<WidthControl
					handleMouseDown={handleMouseDown}
					target={ResizableFileInfoArea.format}
				/>
				{columnDisplayStatus[FileColumn.size] && (
					<div
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						id="file_size"
						className="FR AC"
						style={{ width: sizeWidth }}
					>
						Size
					</div>
				)}
				<WidthControl
					handleMouseDown={handleMouseDown}
					target={ResizableFileInfoArea.size}
				/>
				{columnDisplayStatus[FileColumn.title] && (
					<div
						className="FR AC"
						onMouseEnter={handleMouseEnter}
						onMouseLeave={handleMouseLeave}
						id="file_title"
						style={{ width: titleWidth }}
					>
						Title
					</div>
				)}
				<WidthControl
					handleMouseDown={handleMouseDown}
					target={ResizableFileInfoArea.title}
				/>
			</div>
			{!simpleMode && (
				<div className="FR AC right-side-column-header">
					{/* <SignOffPartWidthControl
						handleMouseDown={handleMouseDown}
					/> */}
					<div
						className="fixed-column-header FR AC JSB"
						// style={{ width: fixedArea }}
					>
						<div className="FR AC JSB">
							{columnDisplayStatus[FileColumn.signOffs] && (
								<div
									className="sign-offs FR AC"
									// style={{ width: signOffAreaWidth }}
								>
									{signOffColumnsDisplay.id_prepare &&
										signOffPolicyHasThisColumn(
											"id_prepare"
										) && (
											<div
												className="sign-off-cell-column"
												style={{ width: cellWidth }}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id="id_prepare"
												// onContextMenu={(e: any) =>
												// 	handleSignOffColumnRightClick(
												// 		e,
												// 		"prepare"
												// 	)
												// }
											>
												Prepare
											</div>
										)}

									{/* <Partition /> */}
									{signOffColumnsDisplay.id_review &&
										signOffPolicyHasThisColumn(
											"id_review"
										) && (
											<div
												className="sign-off-cell-column"
												style={{ width: cellWidth }}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id="id_review"
												// onContextMenu={(e: any) =>
												// 	handleSignOffColumnRightClick(
												// 		e,
												// 		"review"
												// 	)
												// }
											>
												Review
											</div>
										)}
									{/* <Partition /> */}
									{signOffColumnsDisplay.id_epReview &&
										signOffPolicyHasThisColumn(
											"id_epReview"
										) && (
											<div
												className="sign-off-cell-column"
												style={{ width: cellWidth }}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id="id_epReview"
												// onContextMenu={(e: any) =>
												// 	handleSignOffColumnRightClick(
												// 		e,
												// 		"epReview"
												// 	)
												// }
											>
												EP Review
											</div>
										)}
									{/* <Partition /> */}
									{signOffColumnsDisplay.id_cpReview &&
										signOffPolicyHasThisColumn(
											"id_cpReview"
										) && (
											<div
												className="sign-off-cell-column"
												style={{ width: cellWidth }}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id="id_cpReview"
												// onContextMenu={(e: any) =>
												// 	handleSignOffColumnRightClick(
												// 		e,
												// 		"cpReview"
												// 	)
												// }
											>
												CP Review
											</div>
										)}
									{/* <Partition /> */}
									{signOffColumnsDisplay.id_qcReview &&
										signOffPolicyHasThisColumn(
											"id_qcReview"
										) && (
											<div
												className="sign-off-cell-column"
												style={{ width: cellWidth }}
												onMouseEnter={handleMouseEnter}
												onMouseLeave={handleMouseLeave}
												id="id_qcReview"
												// onContextMenu={(e: any) =>
												// 	handleSignOffColumnRightClick(
												// 		e,
												// 		"qcReview"
												// 	)
												// }
											>
												QC Review
											</div>
										)}
								</div>
							)}
							{columnDisplayStatus[FileColumn.signOffLock] && (
								<div
									className="FR AC JC"
									style={{ width: signOffLockWidth }}
									onMouseEnter={handleMouseEnter}
									onMouseLeave={handleMouseLeave}
									id="file_signOffLock"
								>
									Sign Off Lock
								</div>
							)}
							<WidthControl
								handleMouseDown={handleMouseDown}
								target={ResizableFileInfoArea.signOffLock}
							/>
							<div className="FR comment-and-history">
								<Partition />

								<div className="comment-cell">
									<DLIconButton
										eleTestId="file-comment-cell"
										variant="iconOnly"
										size="small"
									>
										<Icon
											path={mdiCommentOutline}
											size={0.6}
										/>
									</DLIconButton>
								</div>
								<div className="status-cell">
									<DLIconButton
										eleTestId="file-status-cell"
										variant="iconOnly"
										size="small"
									>
										<Icon
											path={mdiAlertOutline}
											size={0.6}
										/>
									</DLIconButton>
								</div>
								<Partition />
								<div
									className="last-modified"
									style={{
										width: historyWidth,
									}}
								></div>
							</div>
						</div>
					</div>
				</div>
			)}
		</StyledFileTreeColumnHeader>
	)
})

const StyledFileTreeColumnHeader = styled.div`
	font-size: ${(props) => props.theme.shared.textRg};
	overflow: hidden;
	height: 0;
	transition: 0.4s;
	border-bottom: 1px solid ${(props) => props.theme.shade05};
	// &.display {
	height: 36px; /** TODO: Is this right value..? */
	// }
	.left-side-column-header {
	}
	.right-side-column-header {
		.fixed-column-header {
			/* border: 1px solid pink; */
			.sign-offs {
				/* border: 1px solid purple; */
				.sign-off-cell-column {
					/* margin: 0 0.3rem; */
					/* match with wign-off-cell-container on the file-row  */
					text-align: center;
					/* border: 1px solid red; */
				}
			}
			.comment-cell {
				/* border: 1px solid orange; */
				opacity: 0;
			}
			.status-cell {
				opacity: 0;
			}
			.last-modified {
			}
			.ctx-btn-cell {
			}
		}
	}
`
