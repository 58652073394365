import React from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
// WARNING: dependencies
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import OrgHeaderTitle from "./sub-components/OrgHeaderTitle"
import {
	LanguageMenus,
	ProfileMenus,
	HeaderMobileMenus,
	OrgSearchBar,
} from "./sub-components"
import { DLSnackbar, DLIconButton } from "../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiHelp } from "@mdi/js"
import CheckoutToLobby from "./sub-components/CheckoutToLobby"
import NotificationBtn from "./sub-components/NotificationBtn"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import { StyledHeader } from "./StyledHeader"

export default observer(function Header() {
	const store = useRootStore()

	const i18n = store.i18n.combineI18n(OrgI18n.menus, "OrgHeader")
	return (
		<StyledHeader className="grow" data-testid="org-header-container">
			<AppBar position="static" className="app-bar">
				<Toolbar className="toolbar">
					{/* ===== Title =====  */}
					<OrgHeaderTitle i18n={i18n} />

					<div className="grow" />

					{/* ===== Desktop Menus =====  */}
					<div className="section-desktop">
						{/* Hided to restrict some inappropriate behavior
						<OrgSearchBar i18n={i18n} /> */}

						{/* ----- Checkout to Lobby ----- */}
						<div className="header-btn">
							<CheckoutToLobby
								tooltipText={
									i18n.twBackToOrgList ||
									"Back to the organization list"
								}
							/>
						</div>

						{/* ----- Notification Drawer Open ----- */}
						<div className="header-btn">
							<NotificationBtn
								tooltipText={
									i18n.twNotifications || "Notifications"
								}
							/>
						</div>

						{/* ----- Help info drawer ----- */}
						<DLIconButton
							eleClassName="header-btn help-info-btn"
							eleTestId="help-info-btn"
							clickHandler={() =>
								store.ui.setHelpInfoDrawerOpen(
									!store.ui.isHelpInfoDrawerOpen
								)
							}
							tooltipText="help"
						>
							<Icon path={mdiHelp} size={0.7} />
						</DLIconButton>

						{/* ----- User related actions ----- */}
						<ProfileMenus />
						{/* ----- Language select button ----- */}
						<LanguageMenus />
					</div>

					{/* ===== Mobile Menus ===== */}
					<div className="section-mobile">
						<HeaderMobileMenus
							setRightDrawerOpen={() =>
								store.ui.setRightDrawerOpen(
									!store.ui.isRightDrawerOpen
								)
							}
						/>
					</div>
				</Toolbar>
			</AppBar>
			<OrgHeaderSnackbar />
		</StyledHeader>
	)
})

const OrgHeaderSnackbar = observer(() => {
	const store = useRootStore()

	const { type, open, setOpen, message, autoHideDuration } =
		store.global.responseSnackbar
	return (
		<DLSnackbar
			type={type}
			open={open}
			setOpen={setOpen}
			anchorLocation="RIGHT_TOP"
			message={message}
			autoHideDuration={autoHideDuration}
			autoHide={false}
		/>
	)
})
