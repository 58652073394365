// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { OrgProjTemplateItemType } from "../../data-models/org-proj-templates.data-model"
import {
	idToString,
	IdType,
} from "../../../../../../../library/converters/id-converter"
// ---------- reponse cases

const GetOrgProjTemplateGroups = (self: any) => ({
	getOrgProjTemplateGroups() {
		// 0.
		const actionName = "getOrgProjTemplateGroups"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readProjTemplateGroups()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					let list: any[] = []
					const fetchedFolders = response.data.TemplateListRes
					//
					fetchedFolders.map((folder: any) => {
						const folderId = idToString(
							folder.TempFoldID,
							IdType.projTemplateGroup
						)
						if (folder.TemplateList) {
							folder.TemplateList.map((template: any) => {
								const reOrganizedTemplate = {
									id: idToString(
										template.TempId,
										IdType.projTemplate
									),

									parentId: folderId,
									title: template.TempName,
									type: OrgProjTemplateItemType.template,
									expanded: false,
								}

								list.push(reOrganizedTemplate)
							})
						}
						const reOrganizedFolder = {
							id: folderId,
							parentId: null,
							title: folder.TempFoldName,
							type: OrgProjTemplateItemType.templateGroup,
							expanded: false,
						}

						list.push(reOrganizedFolder)
					})
					self.setProjTemplates(list)
					self.setNeedRefresh(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get the template folders",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgProjTemplateGroups
