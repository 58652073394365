import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLButton } from "../../../../components/basic-elements"
import { getProjectIcon } from "../../../../components/basic-elements/icons/common-project-icons"
import { Link } from "react-router-dom"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function ProjectTable({
	partialStore,
	contentsHeight,
}: {
	partialStore: any
	contentsHeight: any
}) {
	return (
		<StyledProjectTable>
			<ReactTableV8
				tableColumns={Columns()}
				data={partialStore.projectList}
				hasPagination={true}
				showPageSetting={false}
				height={contentsHeight - 40}
				menuId={DLOrgSubMenus.search}
			/>
		</StyledProjectTable>
	)
})

const Columns = () => [
	{
		header: "Status",
		accessorKey: "projectStatus",
		cell: (props: any) => {
			const projStatus = props.getValue()
			const cellId = props.cell.column.id + "-cell"

			return (
				<div data-testid={cellId}>{getProjectIcon(projStatus, 1)}</div>
			)
		},
	},
	{
		header: "Title",
		accessorKey: "title",
		cell: (props: any) => {
			const details = props.row.original
			const title = props.getValue()
			const testId =
				"title-cell-" + title.toLowerCase().replace(/ /g, "-")
			return (
				<span data-testid={testId} className="title-text">
					{details.period} {details.financialYear}{" "}
					{details.client.name} : {details.title}
				</span>
			)
		},
	},
	{
		header: "Created By",
		accessorKey: "createdBy",
		accessorFn: (props: any) => props.createdBy.name,
	},
	{
		header: "Open",
		cell: (props: any) => {
			const projId = props.row.original.id
			const projNumId = idToNumber(projId, IdType.project)
			return (
				<Link to={`/project/checkin/${projNumId}`}>
					<DLButton
						eleTestId="open-project-btn"
						size="thin"
						eleClassName="open-btn"
						disabled={!props.row.original.accessible}
					>
						Open
					</DLButton>
				</Link>
			)
		},
	},
]

const StyledProjectTable = styled.div`
	.cannot-access {
		opacity: 0.3;
		cursor: not-allowed;
	}
	table > thead > tr > th {
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`
