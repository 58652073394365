import { types } from "mobx-state-tree"
import { DLObjectFormat } from "../../../common-models/enumerations/common-enums"
import { getYear } from "date-fns"
// NOTE: This props is different with the template selection in the dialog.
// NOTE: this props mean the starting point of project creation

export enum CreateProjectMethod {
	byUndefined = "BY_UNDEFINED",
	//
	byBlank = "BY_BLANK",
	byRollForward = "BY_ROLL_FORWARD",
	byProjectTemplate = "BY_PROJECT_TEMPLATE",
	byHardCopyOnly = "BY_HARD_ONLY",
}

export type CreateProjectPathProps = {
	id: string
	name: string
	objFormat: DLObjectFormat
}[]

export const UserPropsForAssign = types.model({
	id: types.string,
	roleId: types.string,
})

export const CreateProjectInputsModel = types.model({
	//
	//
	// * 1. location info: group, cabinet
	groupId: types.string,
	groupName: "",
	cabinetId: types.string,
	cabinetName: "",
	//
	//
	// * 2. client info
	clientId: types.string,
	clientName: "",
	clientAliasId: "",
	//
	//
	// * 3. project basic info
	// id: ...,
	aliasId: types.string,
	title: types.string,
	//
	engTypeId: types.string,
	engTypeName: "",
	riskAssessment: types.string, // raId,
	// raName: ...,
	//
	//
	// * 4. project status info
	// projStatusId: ...,
	// projStatusName: ...,
	periodId: types.string,
	periodName: "",
	//
	// version: ...,
	// isArchived: ...,
	// isLocked: ...,
	//
	//
	// * 5. access info
	// roleId: ...,
	// roleName: ...,
	// accessible: ...,
	//
	//
	// * 6. schedule info
	year: types.number,
	periodEndDate: types.string,
	expectedReportDate: types.string,
	finalReportDate: types.string,
	archPolicyPeriod: types.string,
	// archPolicyPeriodName: ...,
	expectedArchiveDate: types.string, // Expected Archive Deadline Date
	finalArchDeadlineDate: types.string,
	//
	//
	// * 7. history info
	// createdBy: ..., (id, name, aliasId)
	// createdAt: ...,
	// recentVisitBy: ...,
	// recentVisitAt: ...,
	// archivedBy: ..., (id, name, aliasId)
	// archivedAt: ...,
	// unarchivedBy: ...,
	// unarchivedAt: ...,
	//
	//
	// * 8. assigned users
	epUserId: types.string,
	creatorRole: types.string,
	users: types.union(
		types.array(types.model({ id: types.string, roleId: types.string })),
		types.undefined
	),
	// epUsers: ...,
	// qcUsers: ...,
	//
	//
	// * 9. extras
	otherUsers: types.union(
		types.array(
			types.model({
				id: types.string,
				roleId: types.string,
				accessRight: types.string,
			})
		),
		types.undefined
	),
	//
	//
	// * + speciall part
	createMethod: types.enumeration<CreateProjectMethod>(
		Object.values(CreateProjectMethod)
	),
	templateId: types.string,
	copyFrom: types.union(types.null, types.string),
	copyContents: types.boolean,
	copyUsers: types.boolean,
	moveFsDataAsPy: types.boolean,
})

export type CreateProjectInputProps = {
	groupId: string
	cabinetId: string
	clientId: string
	engTypeId: string
	// for rollforwrad
	groupName?: string
	cabinetName?: string
	clientName?: string
	clientAliasId?: string
	engTypeName?: string
	periodName?: string
	//
	year: number
	periodId: string
	periodEndDate: string
	//
	expectedReportDate: string
	archPolicyPeriod: string
	expectedArchiveDate: string // NOTE:
	riskAssessment: string
	finalReportDate: string
	finalArchDeadlineDate: string
	//
	title: string
	aliasId: string
	epUserId: string
	creatorRole: string
	users?: any // TODO: Need to update more proper way
	//
	createMethod: CreateProjectMethod
	templateId: string
	copyFrom: null | string
	//
	copyContents: boolean
	copyUsers: boolean
	moveFsDataAsPy: boolean
	//
	otherUsers?: any //  TODO: Need to update more proper way // { id: string; roleId: string; accessRight: string }[] // for hard copy
}

export const initialCreateProjectInputs = {
	groupId: "",
	cabinetId: "",
	clientId: "",
	engTypeId: "",
	//
	groupName: "",
	cabinetName: "",
	clientName: "",
	clientAliasId: "",
	engTypeName: "",
	periodName: "",
	//
	year: getYear(new Date()),
	periodId: "",
	periodEndDate: "",
	//
	expectedReportDate: "",
	archPolicyPeriod: "",
	expectedArchiveDate: "",
	riskAssessment: "",
	finalReportDate: "",
	finalArchDeadlineDate: "",
	//
	title: "",
	aliasId: "",
	epUserId: "",
	creatorRole: "",
	users: [{ id: "", roleId: "" }],
	//
	createMethod: CreateProjectMethod.byBlank,
	templateId: "",
	copyFrom: null,
	//
	copyContents: true,
	copyUsers: false,
	moveFsDataAsPy: false,
	// epUser: "", // NOTE: --> Let's set creator as EP !
	// role: "", // NOTE:
	otherUsers: [{ id: "", roleId: "", accessRight: "" }],
}

export type CreateProjectByRollForwardProps = {
	group: string
	cabinet: string
	client: string
	engType: string
	//
	// year: number
	periodId: string
	periodEnd: string
	//
	// expectedReportDate: string
	archPolicyPeriod: string
	// expectedArchiveDate: string // NOTE:
	riskAssessment: string
	//
	title: string
	aliasId: string
	//
	createMethod: string
	// templateId: string
	copyFrom: string
	//
	copyContents: boolean
	copyUsers: boolean
	moveFsDataAsPy: boolean
	//
	// epUser: string
	// role: string
}
