// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"

const GetSetupClientList = (self: any) => ({
	getSetupClientList() {
		const actionName = "getSetupClientList"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		//
		const payload = {
			PageSize: 10000,
		}
		self.readClientList(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					self.resetList()
					const existingClientList = response.data.Clientlist
					let clients: any[] = []
					existingClientList.map((client: any) => {
						const reOrganizedClient = {
							clientId: idToString(
								client.ClientMasterID,
								IdType.client
							), // [ClientMasterID], ex) 10
							clientName: client.ClientMasterName, // [ClientMasterName], ex) "A"
							// userId: idToString(client.UserID, IdType.user), // [UserID], ex) 10
							createdAt: client.CreatedDate, // [CreatedDate], ex) "2018-11-26T21:35:40.140698-05:00"
							createdBy: client.CreatedBy
								? client.CreatedBy
								: "(Unknown)", // [CreatedBy], ex) "AN"
							clientAliasId: client.ClientId,
						}
						clients.push(reOrganizedClient)
						// self.pushItemToList(reOrganizedClient)
					})
					self.setClientList(clients)
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Successfully fetched clients",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage:
							response.data.Message ||
							response.data.message ||
							"Something's Wrong... ",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetSetupClientList
