import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLButton,
	DLIconButton,
	DLInputField,
} from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import {
	mdiArrowLeftBold,
	mdiArrowRightBold,
	mdiPencil,
	mdiTrashCan,
} from "@mdi/js"
import { DialogType } from "../../store/data-models/fs-setup.data-model"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

const MapUnmapDialogForm = observer(
	({
		permission,
		mapHandler,
		unMapHandler,
		availableItems,
		mappedItems,
		dialogType,
		handleCreate,
		handleUpdate,
		handleDelete,
	}: {
		permission: PermissionAsObjectProps
		mapHandler: any
		unMapHandler: any
		availableItems: any[]
		mappedItems: any[]
		dialogType: DialogType
		handleCreate?: any
		handleUpdate?: any
		handleDelete?: any
	}) => {
		const [actionType, setActionType] = useState<"create" | "update">(
			"create"
		)
		const [optionName, setOptionName] = useState("")
		const [selectedItem, setSelectedItem] = useState(0)

		const cancelHandler = () => {
			setActionType("create")
			setOptionName("")
		}

		const handleOption = (e: any) => {
			setOptionName(e.target.value)
		}

		const editHandler = (id: number) => {
			const itemDetails = availableItems.find(
				(item: any) => item.id === id
			)
			if (itemDetails) {
				setActionType("update")
				setOptionName(itemDetails.name)
			}
		}

		const handleSelect = (id: number) => {
			setSelectedItem(id)
		}

		return (
			<StyledDialogForm>
				{(dialogType === DialogType.group ||
					dialogType === DialogType.subGroup) && (
					<div className="create-update-section FR">
						{actionType === "create" ? (
							<>
								{permission.create && (
									<>
										<DLInputField
											eleTestId={`create-${dialogType}-input`}
											eleValue={optionName}
											eleHandleChange={(e: any) =>
												handleOption(e)
											}
										/>
										<DLButton
											eleTestId={`create-${dialogType}-btn`}
											size="thin"
											color="primary"
											clickHandler={() =>
												handleCreate(optionName)
											}
											disabled={optionName.trim() === ""}
										>
											Create
										</DLButton>
									</>
								)}
							</>
						) : (
							<>
								{permission.update && (
									<>
										<DLInputField
											eleTestId={`edit-${dialogType}-input`}
											eleValue={optionName}
											eleHandleChange={(e: any) =>
												handleOption(e)
											}
										/>
										<DLButton
											eleTestId={`update-${dialogType}-btn`}
											size="thin"
											color="primary"
											clickHandler={() =>
												handleUpdate(
													optionName,
													selectedItem
												)
											}
											disabled={optionName === ""}
										>
											Update
										</DLButton>
										<DLButton
											eleTestId={`cancel-${dialogType}-btn`}
											size="thin"
											clickHandler={cancelHandler}
										>
											Cancel
										</DLButton>
									</>
								)}
							</>
						)}
					</div>
				)}
				<hr />
				<div className="map-unmap-section FR JSA">
					<div className="card">
						<div className="card-header">
							Available {dialogType}
						</div>
						<div>
							{availableItems.map((item: any) => {
								return (
									<div
										className={`FR JSB card-list ${
											selectedItem === item.id &&
											"selected"
										}`}
										onClick={() => handleSelect(item.id)}
										key={item.name}
									>
										<span>{item.name}</span>
										{(dialogType === DialogType.group ||
											dialogType ===
												DialogType.subGroup) && (
											<div className="FR">
												{permission.update && (
													<DLIconButton
														variant="iconOnly"
														eleTestId={`edit-${dialogType}-btn`}
														tooltipText="edit"
														clickHandler={() =>
															editHandler(item.id)
														}
													>
														<Icon
															path={mdiPencil}
															size={0.6}
														/>
													</DLIconButton>
												)}
												{permission.delete && (
													<DLIconButton
														variant="iconOnly"
														eleTestId={`delete-${dialogType}-btn`}
														tooltipText="delete"
														clickHandler={() =>
															handleDelete(
																item.id
															)
														}
													>
														<Icon
															path={mdiTrashCan}
															size={0.6}
														/>
													</DLIconButton>
												)}
											</div>
										)}
									</div>
								)
							})}
						</div>
					</div>
					<div className="buttons-container">
						<DLIconButton
							clickHandler={() => mapHandler(selectedItem)}
							disabled={
								!availableItems.some(
									(i: any) => i.id === selectedItem
								)
							}
							eleTestId="map-btn"
						>
							<Icon path={mdiArrowRightBold} size={2} />
						</DLIconButton>

						<DLIconButton
							clickHandler={() => unMapHandler(selectedItem)}
							disabled={
								!mappedItems.some(
									(i: any) => i.id === selectedItem
								)
							}
							eleTestId="unmap-btn"
						>
							<Icon path={mdiArrowLeftBold} size={2} />
						</DLIconButton>
					</div>
					<div className="card">
						<div className="card-header">Mapped {dialogType}</div>
						<div>
							{mappedItems.map((item: any) => {
								return (
									<div
										className={`FR JSB card-list ${
											selectedItem === item.id &&
											"selected"
										}`}
										onClick={() => handleSelect(item.id)}
										key={item.name}
									>
										<span>{item.name}</span>
									</div>
								)
							})}
						</div>
					</div>
				</div>
			</StyledDialogForm>
		)
	}
)

export default MapUnmapDialogForm

const StyledDialogForm = styled.div`
	.card {
		height: 300px;
		border: 1px solid gray;
		background-color: white;
		width: 15rem;
		.card-header {
			background-color: #f5f7f9;
			padding: 1rem;
			font-size: 1rem;
		}
	}
	.buttons-container {
		height: 8rem;
	}
	.card-list {
		padding: 8px;
		&:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.button-base.medium {
			width: 1.2rem;
			height: 1.2rem;
		}
	}
	.selected {
		background-color: ${(props) => props.theme.emphasis};
		&:hover {
			background-color: ${(props) => props.theme.emphasis};
		}
	}
`
