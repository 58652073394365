import { postRequest } from "../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"

// NOTE: seems async/await are not required because there are already flow/yeild on the MST
export function verify(payload: { UserName: string; AccessCode: number }) {
	const response = postRequest({
		url: "/ConfirmUser",
		params: payload,
		tokenType: TOKEN_TYPE.none,
	})

	return response
}

export async function resend(userEmail: string) {
	const response = await postRequest({
		url: `/ResendConfirmationcode/${userEmail}`,
		params: {},
		tokenType: TOKEN_TYPE.none,
	})

	return response
}

export default verify
