import { flow } from "mobx-state-tree"
import { OrgSetupArchPolicyApiProps } from "../org-archive-policies.apis"

const CreateArchivePolicy = (
	self: any,
	create: OrgSetupArchPolicyApiProps["apiCreate"]
) => ({
	createArchivePolicy: flow(function* (title: string, description: string) {
		const actionName = "createArchivePolicy"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const payload = {
			Title: title,
			Description: description,
		}
		try {
			const response = yield create(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateArchivePolicy
