import { flow } from "mobx-state-tree"

const MArchiveProject = (self: any, archiveProject: any) => ({
	mArchiveProject: flow(function* (formData) {
		const actionName = "mArchiveProject"
		try {
			const response = yield archiveProject(formData)

			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName, '__error', error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default MArchiveProject
