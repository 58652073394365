import { flow } from "mobx-state-tree"

const ReadProjRoleDetails = (self: any) => ({
	readProjRoleDetails: flow(function* (payload?) {
		const permissionList: any[] = []
		try {
			// const endpoint = '/'
			// const response = yield getRequest({
			//     url: endpoint,
			//     params: {},
			//     tokenType: TOKEN_TYPE.projToken
			// })
			let response: any
			if (payload === undefined) {
				response = permissionList
			} else {
				response = permissionList.find(
					(element) => element.roleId === payload.role
				)
			}
			// ConsoleLog("readProjRoleDetails response", response)
			return response
		} catch (error) {
			// ConsoleLog("readProjRoleDetails error", error)
			return false
		}
	}),
})

export default ReadProjRoleDetails
