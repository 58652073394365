import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"

const RemoveAllWorkPaperOpenedNotification = (self: any) => ({
	/**
	 * * i18n records
	 * - success, fail
	 */
	removeAllWorkPaperOpenedNotification() {
		// 0.
		const actionName = "removeAllWorkPaperOpenedNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const url = "/CloseAllFileNotifications"
		self.mDiscardCheckoutAllFiles(url)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						// ConsoleLog(res)
						self.resetNotificationList()
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

const RemoveAllPermanentOpenedNotification = (self: any) => ({
	/**
	 * * i18n records
	 *  - success, fail (but it seems there are no fail case handle response?)
	 * TODO: Check the above comment.
	 */
	removeAllPermanentOpenedNotification() {
		// 0.
		const actionName = "removeAllPermOpenNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const url = "/CloseAllPfFileNotifications"
		self.mDiscardCheckoutAllFiles(url)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						self.resetNotificationList()
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage:
							// 	"Successfully closed all permanent file opened notifications",
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

const RemoveAllPermanentRequestedNotification = (self: any) => ({
	/**
	 * * i18n records
	 *  - success, fail
	 */
	removeAllPermanentRequestedNotification() {
		// 0.
		const actionName = "removeAllPermReqNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const url = "/CloseAllPfRequestFileNotifications"
		self.mDiscardCheckoutAllFiles(url)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						// ConsoleLog(res)
						self.resetReqNotificationList()
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage:
							// 	"Successfully closed all permanent file requested notifications",
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

const RemoveAllWorkPaperRequestedNotification = (self: any) => ({
	/**
	 * * i18n records
	 *  - success, fail
	 */
	removeAllWorkPaperRequestedNotification() {
		// 0.
		const actionName = "removeAllWPReqNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const url = "/CloseAllRequestFileNotifications"
		self.mDiscardCheckoutAllFiles(url)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						self.resetReqNotificationList()
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage:
							// 	"Successfully closed all workpaper file requested notifications",
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export {
	RemoveAllWorkPaperOpenedNotification,
	RemoveAllPermanentOpenedNotification,
	RemoveAllPermanentRequestedNotification,
	RemoveAllWorkPaperRequestedNotification,
}
