import React from "react"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiCursorDefaultClickOutline } from "@mdi/js"

export default function NoSelectedItem() {
	return (
		<StyledNoSelectedItem className="FC AC JC no-selected-item">
			<Icon path={mdiCursorDefaultClickOutline} size={3} />
			<div className="heading2">Please select a Item</div>
			Please select a item on the left side
		</StyledNoSelectedItem>
	)
}

const StyledNoSelectedItem = styled.div`
	height: 300px;
	opacity: 0.4;
	.heading2 {
		margin-top: 2rem;
	}
`
