import React, { useCallback } from "react"
import {
	DLButton,
	DLRadioGroup,
	DLDatepicker,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { convertDateAsTzEOD } from "../../../../../library/converters/date-utc-converter"

const AccessMgmtDialogTableColumns = (
	setAssignedUsers: any,
	unassignUser: any,
	updateUser: any,
	dateFormat: any,
	timeZone: any,
	i18n: DLI18nProps
) => [
	{
		header: i18n.twType,
		accessorKey: "userType",
		size: 50,
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "userType-cell-" + userAliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: `${i18n.twUserName}`,
		accessorKey: "name",
		size: 50,
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "userName-cell-" + userAliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: "Id",
		accessorKey: "aliasId",
		size: 50,
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "userAliasId-cell-" + userAliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: `${i18n.twEmail}`,
		accessorKey: "email",
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "userEmail-cell-" + userAliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: `${i18n.twUserTitle}`,
		accessorKey: "title",
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "userTitle-cell-" + userAliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: `${i18n.twExportPermission}`,
		accessorKey: "isExportPerm",
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "export-permission-cell-" + userAliasId

			const updateExportPermission = () =>
				setAssignedUsers((prev: any) =>
					prev.map((user: any) =>
						user.id === props.row.original.id
							? { ...user, isExportPerm: !user.isExportPerm }
							: user
					)
				)

			return (
				<StyledRadio
					className="contents-area FR AC JC"
					data-testid={cellId}
				>
					<DLRadioGroup
						selectedValue={
							props.row.original.isExportPerm ? "true" : "false"
						}
						groupName={props.row.original.id}
						items={[
							{ value: "true", label: "Yes" },
							{ value: "false", label: "No" },
						]}
						eleClassName="export-permision-radio"
						eleOnChange={updateExportPermission}
						color="primary"
						size="small"
						itemDirection="row"
						disabled={
							new Date(props.row.original.expiredDate).getTime() <
							new Date(new Date().toDateString()).getTime()
						}
					/>
				</StyledRadio>
			)
		},
	},
	{
		header: `${i18n.twExpiredDate}`,
		accessorKey: "expiredDate",
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "expired-date-cell-" + userAliasId
			const updateExpiredDate = (date: any) => {
				const endOfTheDate = convertDateAsTzEOD(date, timeZone)

				setAssignedUsers((prev: any) =>
					prev.map((user: any) =>
						user.id === props.row.original.id
							? { ...user, expiredDate: endOfTheDate }
							: user
					)
				)
			}

			return (
				<div className="FR AC JC">
					<DLDatepicker
						eleTestId={cellId} // "exp-date-field"
						eleName="expiredDate"
						selected={new Date(props.row.original.expiredDate)}
						// disabled={e.original.suspended}
						onChange={(date: any) => updateExpiredDate(date)}
						format={dateFormat}
					/>
				</div>
			)
		},
	},
	{
		header: "Update",
		size: 40,
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "update-btn-" + userAliasId

			const handleUpdate = () => updateUser(props.row)
			return (
				<DLButton
					eleTestId={cellId} // {`dialog-action-btn-update`}
					clickHandler={handleUpdate}
					size="thin"
					color="secondary"
				>
					Update
				</DLButton>
			)
		},
	},
	{
		header: "Unassign",
		size: 40,
		cell: (props: any) => {
			const userAliasId = props.row.original.aliasId
			const cellId = "unassign-btn-" + userAliasId

			const handleUnassign = () => {
				let proceed = window.confirm(
					"Do you really want to unassign the user?"
				)
				if (!proceed) {
					return
				}
				unassignUser(props.row.original.id)
			}

			return (
				<DLButton
					eleTestId={cellId} //{`dialog-action-btn-unassign`}
					clickHandler={handleUnassign}
					disabled={props.row.original.suspended} // TODO: does suspended users cannot be unassigned? Need to check bussiness logic
					color="secondary"
					size="thin"
				>
					UnAssign
				</DLButton>
			)
		},
	},
]

// const AccessMgmtDialogTableColumns = (
// 	setAssignedUsers: any,
// 	unassignUser: any,
// 	updateUser: any,
// 	dateFormat: any,
// 	timeZone: any,
// 	i18n: DLI18nProps
// ) => [
// 	{
// 		Header: `${i18n.twType}`,
// 		width: 60,
// 		accessor: "userType",
// 		Cell: (row: any) => {
// 			const userAliasId = row.original.aliasId
// 			const cellId = "userType-cell-" + userAliasId
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 	},
// 	{
// 		Header: `${i18n.twUserName}`,
// 		accessor: "name",
// 		width: 80,
// 		Cell: (row: any) => {
// 			const userAliasId = row.original.aliasId
// 			const cellId = "userName-cell-" + userAliasId
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 	},
// 	{
// 		Header: "Id",
// 		accessor: "aliasId",
// 		width: 60,
// 		Cell: (row: any) => {
// 			const userAliasId = row.original.aliasId
// 			const cellId = "userAliasId-cell-" + userAliasId
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 	},
// 	{
// 		Header: `${i18n.twEmail}`,
// 		accessor: "email",
// 		Cell: (row: any) => {
// 			const userAliasId = row.original.aliasId
// 			const cellId = "userEmail-cell-" + userAliasId
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 	},
// 	{
// 		Header: `${i18n.twUserTitle}`,
// 		accessor: "title",
// 		width: 80,
// 		Cell: (row: any) => {
// 			const userAliasId = row.original.aliasId
// 			const cellId = "userTitle-cell-" + userAliasId
// 			return <div data-testid={cellId}>{row.value}</div>
// 		},
// 	},
// 	{
// 		Header: `${i18n.twExportPermission}`,
// 		accessor: "isExportPerm",
// 		width: 170,
// 		Cell: (event: any) => {
// 			const userAliasId = event.original.aliasId
// 			const cellId = "export-permission-cell-" + userAliasId

// 			const updateExportPermission = () =>
// 				setAssignedUsers((prev: any) =>
// 					prev.map((user: any) =>
// 						user.id === event.original.id
// 							? { ...user, isExportPerm: !user.isExportPerm }
// 							: user
// 					)
// 				)

// 			return (
// 				<StyledRadio
// 					className="contents-area FR AC JC"
// 					data-testid={cellId}
// 				>
// 					<DLRadioGroup
// 						selectedValue={
// 							event.original.isExportPerm ? "true" : "false"
// 						}
// 						groupName={event.original.id}
// 						items={[
// 							{ value: "true", label: "Yes" },
// 							{ value: "false", label: "No" },
// 						]}
// 						eleClassName="export-permision-radio"
// 						eleOnChange={updateExportPermission}
// 						color="primary"
// 						size="small"
// 						itemDirection="row"
// 						disabled={
// 							new Date(event.original.expiredDate).getTime() <
// 							new Date(new Date().toDateString()).getTime()
// 						}
// 					/>
// 				</StyledRadio>
// 			)
// 		},
// 	},
// 	{
// 		Header: `${i18n.twExpiredDate}`,
// 		accessor: "expiredDate",
// 		width: 170,
// 		Cell: (e: any) => {
// 			// const updateExpiredDate = useCallback((date) => {
// 			// 	const endOfTheDate = convertDateAsTzEOD(date, timeZone)
// 			// 	let temp = JSON.parse(JSON.stringify(assignedUsers))
// 			// 	const index = temp.findIndex(
// 			// 		(item: any) => item.id === e.original.id
// 			// 	)
// 			// 	temp[index].expiredDate = endOfTheDate
// 			// 	setAssignedUsers(temp)
// 			// }, [])
// 			const userAliasId = e.original.aliasId
// 			const cellId = "expired-date-cell-" + userAliasId
// 			const updateExpiredDate = (date: any) => {
// 				const endOfTheDate = convertDateAsTzEOD(date, timeZone)

// 				setAssignedUsers((prev: any) =>
// 					prev.map((user: any) =>
// 						user.id === e.original.id
// 							? { ...user, expiredDate: endOfTheDate }
// 							: user
// 					)
// 				)
// 			}

// 			return (
// 				<div className="FR AC JC">
// 					<DLDatepicker
// 						eleTestId={cellId} // "exp-date-field"
// 						eleName="expiredDate"
// 						selected={new Date(e.original.expiredDate)}
// 						// disabled={e.original.suspended}
// 						onChange={(date: any) => updateExpiredDate(date)}
// 						format={dateFormat}
// 					/>
// 				</div>
// 			)
// 		},
// 	},

// 	{
// 		Header: "Update",
// 		width: 90,
// 		Cell: (e: any) => {
// 			const handleUpdate = () => updateUser(e)

// 			const userAliasId = e.original.aliasId
// 			const cellId = "update-btn-" + userAliasId

// 			return (
// 				<DLButton
// 					eleTestId={cellId} // {`dialog-action-btn-update`}
// 					clickHandler={handleUpdate}
// 					size="thin"
// 					color="secondary"
// 				>
// 					Update
// 				</DLButton>
// 			)
// 		},
// 	},
// 	{
// 		Header: "Unassign",
// 		width: 90,
// 		Cell: (e: any) => {
// 			const handleUnassign = () => {
// 				let proceed = window.confirm(
// 					"Do you really want to unassign the user?"
// 				)
// 				if (!proceed) {
// 					return
// 				}
// 				unassignUser(e.original.id)
// 			}
// 			const userAliasId = e.original.aliasId
// 			const cellId = "unassign-btn-" + userAliasId

// 			return (
// 				<DLButton
// 					eleTestId={cellId} //{`dialog-action-btn-unassign`}
// 					clickHandler={handleUnassign}
// 					disabled={e.original.suspended} // TODO: does suspended users cannot be unassigned? Need to check bussiness logic
// 					color="secondary"
// 					size="thin"
// 				>
// 					UnAssign
// 				</DLButton>
// 			)
// 		},
// 	},
// ]

export default AccessMgmtDialogTableColumns

const StyledRadio = styled.div`
	.export-permision-radio {
		margin-left: 14px;
	}
`
