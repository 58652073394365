// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetAdjustments = (self: any) => ({
	getAdjustments(
		projectId: string,

		// periodEndDate: string,
		tbId: number
	) {
		// 0. set actionName
		const actionName = "getAdjustments"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const projectNumberId = idToNumber(projectId, IdType.project)

		const payload = {
			ClientId: projectNumberId,
			// CabinetId: cabinetId,
			// FinancialYear: periodEndDate,
			// Lang: "en",
			TBID: tbId,
		}

		// 3) request API call to Model
		self.readAdjustments(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						let ajes: any[] = []
						fetchedData.Adjustments.map((item: any) => {
							ajes.push({
								ajeDesc: item.AdjDescription,
								ajeType: item.AdjType,
								ajeId: item.AdjustmentId.toString(),
								extension: item.Extension,
								isOpen: item.IsOpen,
								mediaId: item.MediaID.toString(),
								mediaType: item.MediaType,
								openedBy: item.OpenedBy,
								ajeNumber: item.RowNo,
								wpDesc: item.WPDesc,
								wpRef: item.WorkPaperReference,
							})
						})
						self.setAdjustments(ajes)
						self.setNeedRefresh(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully fetched adjustments",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAdjustments
