import { types } from "mobx-state-tree"

//model actions
import ReadCheckedOutPfFiles from "./model-actions/read-checked-out-pf-list"
import ReadCheckedOutWpFiles from "./model-actions/read-checked-out-wp-list"
import ReadWpFileCheckinrequest from "./model-actions/read-wp-file-checkin-requests"
import ReadPfFileCheckinrequest from "./model-actions/read-pf-file-checkin-requests"
import MdiscardCheckoutFile from "./model-actions/m-discard-checkout-file"
import MdiscardCheckoutAllFiles from "./model-actions/m-discard-checkout-all-files"
import { GlobalNotisApiProps } from "./global-notis.apis"

import { CommonModelActions } from "../../../../common-models/common-model-actions"
import DeleteOrRestorePost from "../../../../service-modules/post/store-model-actions/delete-or-restore-post"
import ReadOrgNotiList from "../../../../service-modules/post/store-model-actions/read-org-noti-list"

const GlobalNotisModel = ({
	apiReadWP,
	apiReadPF,
	apiReadWPRequest,
	apiReadPFRequest,
	apiDelAllNotification,
	apiDelSingleNotification,
}: GlobalNotisApiProps) =>
	types
		.model({})
		.actions((self) => ({
			// ------ Read
			// ------ Update
		}))
		.actions((self, readWP = apiReadWP) =>
			ReadCheckedOutWpFiles(self, readWP)
		)
		.actions((self, readPF = apiReadPF) =>
			ReadCheckedOutPfFiles(self, readPF)
		)
		.actions((self, readWPRequest = apiReadWPRequest) =>
			ReadWpFileCheckinrequest(self, readWPRequest)
		)
		.actions((self, readPFRequest = apiReadPFRequest) =>
			ReadPfFileCheckinrequest(self, readPFRequest)
		)
		.actions((self, delSingleNotification = apiDelSingleNotification) =>
			MdiscardCheckoutFile(self, delSingleNotification)
		)
		.actions((self, delAllNotification = apiDelAllNotification) =>
			MdiscardCheckoutAllFiles(self, delAllNotification)
		)
		.actions(ReadOrgNotiList)
		.actions(DeleteOrRestorePost)
		// Common model actions
		.actions(CommonModelActions)

export { GlobalNotisModel }
