import { types } from "mobx-state-tree"
import {
	ChecklistRecordType,
	ChecklistRequirementType,
	ChecklistStatus,
} from "@datalobby/types"

// NOTE: Standard: ChecklistListProps
export const ChecklistModel = types.model({
	// common parts
	id: types.string,
	parentId: types.string,
	orgId: types.string,
	name: types.string,
	description: "",
	type: types.enumeration<ChecklistRecordType>(
		Object.values(ChecklistRecordType)
	), // ==> always ChecklistRecordType.list
	lastModifiedBy: "",
	lastModifiedAt: "",
	createdAt: types.string,
	createdBy: types.string,
	assignedRoles: types.union(
		types.array(
			types.model({
				id: types.string,
				shortName: types.string,
				name: types.string,
			})
		),
		types.null,
		types.undefined
	),
	assignedUsers: types.union(
		types.array(
			types.model({
				id: types.string,
				aliasId: types.string,
				name: types.string,
			})
		),
		types.null,
		types.undefined
	),
	mustInOrder: types.union(types.boolean, types.null, types.undefined),
	// specific for list
	status: types.enumeration<ChecklistStatus>(Object.values(ChecklistStatus)),
	templateId: types.string,
	projectId: types.string,
	workspaceId: types.string,
	isEditingBy: types.union(
		types.model({
			id: types.string,
			name: types.string,
			aliasId: types.string,
			startedAt: types.string,
		}),
		types.null
	),
})

export const ChecklistItemModel2 = types.model({
	assignedRoles: types.union(
		types.array(
			types.model({
				id: types.string,
				name: types.string,
				shortName: types.string,
			})
		),
		types.null
	),
	assignedUsers: types.union(
		types.array(
			types.model({
				id: types.string,
				name: types.string,
				aliasId: types.string,
			})
		),
		types.null
	),
	//
	id: types.string,
	name: types.string,
	order: types.number,
	//
	requirements: types.array(
		types.model({
			id: types.string,
			name: types.string,
			isRequired: types.boolean,
			order: types.number,
			type: types.enumeration<ChecklistRequirementType>(
				Object.values(ChecklistRequirementType)
			),
			// TODO: value has multiple types
			value: types.union(types.null, types.undefined, types.string),
		})
	),
	status: types.enumeration<ChecklistStatus>(Object.values(ChecklistStatus)),
})
