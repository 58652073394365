import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"

const AdjustmentsCard = observer(({ partialStore }: { partialStore: any }) => {
	return (
		<StyledAdjustmentsCard
			className="simple-card FC AC"
			data-testid="comment-list-card"
		>
			<h2>Adjustments</h2>
			<div className="adjustments-table">
				<div className="FR JSB header">
					<div className="adjustment-type">Adjustment Type</div>
					<div className="current-year">Current Year</div>
				</div>
				<hr style={{ height: "3px", backgroundColor: "gray" }} />
				<div className="FR JSB">
					<div className="adjustment-type">Client</div>
					<div className="current-year">
						{partialStore.adjustments.client}
					</div>
				</div>
				<hr />
				<div className="FR JSB">
					<div className="adjustment-type">Audit</div>
					<div className="current-year">
						{partialStore.adjustments.audit}
					</div>
				</div>
				<hr />
				<div className="FR JSB">
					<div className="adjustment-type">Total</div>
					<div className="current-year">
						{partialStore.adjustments.total}
					</div>
				</div>
				<hr />
			</div>
		</StyledAdjustmentsCard>
	)
})

const StyledAdjustmentsCard = styled.div`
	width: 33rem;
	&.simple-card {
		padding: 1rem;
		height: 12rem;
		box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
		border: 1px solid ${(props) => props.theme.shade10};
		border-radius: 4px;
		margin-bottom: 1rem;
		overflow: hidden;
		transition: 0.4s;
		:hover {
			/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
			box-shadow: 0px 2px 8px 1px ${(props) => props.theme.shade40};
			border: 1px solid ${(props) => props.theme.primaryLightDeep};
		}
	}
	.adjustments-table {
		width: 100%;
		margin-top: 10px;
		.header {
			font-weight: 700;
		}
	}
`

export default AdjustmentsCard
