import { ConsoleLog } from "../../../components/basic-elements"

const OpenedFilesActions = (self: any) => ({
	resetOpenedFiles() {
		self.openedBySameUser.length = 0
		self.openedByOther.length = 0
	},
	pushOpenedBySameUser(item: any) {
		self.openedBySameUser.push(item)
	},
	pushOpenedByOther(item: any) {
		self.openedByOther.push(item)
	},
	selectOpenedFile(id: string, type: number) {
		if (type === 0) {
			const target = self.openedBySameUser.find(
				(item: any) => item.id === id
			)
			if (target) {
				target.selected = !target.selected
			}
			ConsoleLog(target.selected)
		} else {
			const target = self.openedByOther.find(
				(item: any) => item.id === id
			)
			if (target) {
				target.selected = !target.selected
			}
		}
	},
	selectAllOpenedFile(type: number, selected: boolean) {
		if (type === 0) {
			self.openedBySameUser.map((item: any) => (item.selected = selected))
		} else {
			self.openedByOther.map((item: any) => (item.selected = selected))
		}
	},
	removeFromOpenedList(type: number, id: any) {
		if (type === 0) {
			const itemIndex = self.openedBySameUser.findIndex(
				(item: any) => item.id === id
			)
			self.openedBySameUser.splice(itemIndex, 1)
		} else {
			const itemIndex = self.openedByOther.findIndex(
				(item: any) => item.id === id
			)
			self.openedByOther.splice(itemIndex, 1)
		}
	},
})

export default OpenedFilesActions
