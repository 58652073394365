import { types } from "mobx-state-tree"

// model common action
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// separated actions (CRUD)
import ReadOrgList from "./model-actions/read-org-list"

const OrgListModel = (apiRead: any) =>
	types
		.model({})
		// CRUD
		.actions((self, read = apiRead) => ReadOrgList(self, read))
		// common parts
		.actions(CommonModelActions)

export default OrgListModel
