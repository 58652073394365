import { flow } from "mobx-state-tree"

const RequestActivateProjUser = (self: any, activate: any) => ({
	requestActivateProjUser: flow(function* (params: {
		UserId: number
		Type?: number
		ExpiryDate?: string
	}) {
		const actionName = "requestActivateProjUser"
		if (params.UserId === null || params.UserId === undefined) {
			alert(`(${actionName}) User ID is empty`)
			return
		}
		try {
			const response = yield activate(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })
		}
	}),
})

export default RequestActivateProjUser
