import { FlatItemType } from "../../../../../../common-models/enumerations/common-enums"
import { getTreeFromFlatData } from "../../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { SignoffHistoryFlatItemProps } from "../data-models/signoff-history.data-model"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	getTzDate,
	getTzDateAndTime2,
} from "../../../../../../library/converters/date-utc-converter"
import { DnTFormatProps } from "../../../../../../common-models/types/common-props"

export const ActionsForLastSignoffs = (self: any) => ({
	setLastSignoffs(list: any, target: DLProjSubMenus) {
		if (target === DLProjSubMenus.wp) {
			self.wpLastSignoffs = list
		} else if (target === DLProjSubMenus.pf) {
			self.pfLastSignoffs = list
		}
	},
	pushItemToList(item: any, target: DLProjSubMenus) {
		if (target === DLProjSubMenus.wp) {
			self.wpLastSignoffs.push(item)
			// TODO: check duplicated
		} else if (target === DLProjSubMenus.pf) {
			self.pfLastSignoffs.push(item)
			// TODO: check duplicated
		}
	},
	toggleExpandedFolder(
		folderId: string,
		menuId: DLProjSubMenus,
		expanded?: boolean
	) {
		let targetFolder: any
		if (menuId === DLProjSubMenus.wp) {
			targetFolder = self.wpLastSignoffs
				.filter(
					(item: SignoffHistoryFlatItemProps) =>
						item.type === FlatItemType.folder
				)
				.find(
					(item: SignoffHistoryFlatItemProps) => item.id === folderId
				)
		} else if (menuId === DLProjSubMenus.pf) {
			targetFolder = self.pfLastSignoffs
				.filter(
					(item: SignoffHistoryFlatItemProps) =>
						item.type === FlatItemType.folder
				)
				.find(
					(item: SignoffHistoryFlatItemProps) => item.id === folderId
				)
		}
		if (targetFolder) {
			targetFolder.expanded =
				expanded === undefined ? !targetFolder.expanded : expanded
		}
	},
})

export const ViewsForLastSignoffs = (self: any) => ({
	viewFlatListAsTree(menuId: DLProjSubMenus) {
		if (menuId === DLProjSubMenus.wp) {
			const tree = getTreeFromFlatData(self.wpLastSignoffs)
			return tree
		} else if (menuId === DLProjSubMenus.pf) {
			const tree = getTreeFromFlatData(self.pfLastSignoffs)
			return tree
		}
	},
	viewFlatListAsTreeWithQuery(menuId: DLProjSubMenus) {
		let list: any = []
		let query = self.treeNavQuery

		if (menuId === DLProjSubMenus.wp) {
			list = self.wpLastSignoffs.filter((item: any) =>
				item.title.toLowerCase().includes(query.toLowerCase())
			)
			list.map((item: any) => {
				if (
					list.findIndex(
						(listItem: any) => listItem.id === item.parentId
					) === -1
				) {
					const parent = self.wpLastSignoffs.find(
						(originItem: any) => originItem.id === item.parentId
					)
					if (parent) {
						self.toggleExpandedFolder(parent.id, menuId, true)
						list.push(parent)
					} else {
						// ConsoleLog([
						// 	"No parent in the original list",
						// 	item.id,
						// 	item.parentId,
						// ])
					}
				}
				// WARNING: this process cannot control deep tree.
				// TODO: Need to consider better way
			})

			// const tree = getTreeFromFlatData(self.wpLastSignoffs)
			const tree = getTreeFromFlatData(list, "title")
			return tree
		} else if (menuId === DLProjSubMenus.pf) {
			const tree = getTreeFromFlatData(self.pfLastSignoffs)
			return tree
		}
	},

	viewLastSignoffs(menuId: DLProjSubMenus, dntFormat: DnTFormatProps) {
		let query = self.treeNavQuery
		const lastSignOffs = menuId === DLProjSubMenus.wp ? self.wpLastSignoffs : self.pfLastSignoffs
		let	fileList = lastSignOffs
				.filter(
					(item: SignoffHistoryFlatItemProps) =>
						item.type === FlatItemType.file
				)
				.filter((item: SignoffHistoryFlatItemProps) =>
					item.title.toLowerCase().includes(query.toLowerCase())
				)

		// Apply format
		let formattedList: any[] = []
		fileList.map((file: SignoffHistoryFlatItemProps) => {
			let prepare = file.lastSignoffs?.prepare
			let review = file.lastSignoffs?.review
			let epReview = file.lastSignoffs?.epReview
			let cpReview = file.lastSignoffs?.cpReview
			let qcReview = file.lastSignoffs?.qcReview
			//
			let prepareAt: string | undefined = undefined,
				reviewAt: string | undefined = undefined,
				epReviewAt: string | undefined = undefined,
				cpReviewAt: string | undefined = undefined,
				qcReviewAt: string | undefined = undefined

			if (prepare && prepare.at) {
				prepareAt = getTzDateAndTime2({
					date: prepare.at,
					dntFormat,
				})
			}
			if (review && review.at) {
				reviewAt = getTzDateAndTime2({
					date: review.at,
					dntFormat,
				})
			}
			if (epReview && epReview.at) {
				epReviewAt = getTzDateAndTime2({
					date: epReview.at,
					dntFormat,
				})
			}
			if (cpReview && cpReview.at) {
				cpReviewAt = getTzDateAndTime2({
					date: cpReview.at,
					dntFormat,
				})
			}
			if (qcReview && qcReview.at) {
				qcReviewAt = getTzDateAndTime2({
					date: qcReview.at,
					dntFormat,
				})
			}

			let formattedSignoffs = {
				prepare: { ...prepare, at: prepareAt },
				review: { ...review, at: reviewAt },
				epReview: { ...epReview, at: epReviewAt },
				cpReview: { ...cpReview, at: cpReviewAt },
				qcReview: { ...qcReview, at: qcReviewAt },
			}
			formattedList.push({
				...file,
				lastSignoffs: formattedSignoffs,
			})
		})
		return formattedList
	},
	foldersCount(menuId: DLProjSubMenus) {
		const lastSignOffs = menuId === DLProjSubMenus.wp ? self.wpLastSignoffs : self.pfLastSignoffs
		return lastSignOffs.length
	},
	viewLastSignoffById(menuId: DLProjSubMenus, fileId: string) {
		const lastSignOffs = menuId === DLProjSubMenus.wp ? self.wpLastSignoffs : self.pfLastSignoffs
		return lastSignOffs.find(
			(item: SignoffHistoryFlatItemProps) => item.id === fileId
		)
	},
	exportLastSignOff(menuId: DLProjSubMenus, dntFormat: DnTFormatProps) {
		const { timeZone, dateFormat } = dntFormat
		const lastSignOffs = menuId === DLProjSubMenus.wp ? self.wpLastSignoffs : self.pfLastSignoffs
		let	fileList = lastSignOffs.filter(
				(item: SignoffHistoryFlatItemProps) =>
					item.type === FlatItemType.file
			)
		let formattedList: any[] = []
		fileList.map((file: SignoffHistoryFlatItemProps) => {
			let prepare = file.lastSignoffs?.prepare
			let review = file.lastSignoffs?.review
			let epReview = file.lastSignoffs?.epReview
			let cpReview = file.lastSignoffs?.cpReview
			let qcReview = file.lastSignoffs?.qcReview
			//
			let prepareAt: string | undefined = undefined,
				reviewAt: string | undefined = undefined,
				epReviewAt: string | undefined = undefined,
				cpReviewAt: string | undefined = undefined,
				qcReviewAt: string | undefined = undefined

			if (prepare && prepare.at) {
				const date = getTzDate({
					date: prepare.at,
					timeZone,
					dateFormat,
				})

				prepareAt = `(${prepare.role}) ${prepare.by} ${date}`
			}
			if (review && review.at) {
				const date = getTzDateAndTime2({ date: review.at, dntFormat })
				reviewAt = `(${review.role}) ${review.by} ${date}`
			}
			if (epReview && epReview.at) {
				const date = getTzDateAndTime2({ date: epReview.at, dntFormat })
				epReviewAt = `(${epReview.role}) ${epReview.by} ${date}`
			}
			if (cpReview && cpReview.at) {
				const date = getTzDateAndTime2({ date: cpReview.at, dntFormat })
				cpReviewAt = `(${cpReview.role}) ${cpReview.by} ${date}`
			}
			if (qcReview && qcReview.at) {
				const date = getTzDateAndTime2({ date: qcReview.at, dntFormat })
				qcReviewAt = `(${qcReview.role}) ${qcReview.by} ${date}`
			}

			const isAppAdminUser = JSON.parse(
				localStorage.getItem("isAdminAppUser") || "false"
			)

			let list: any = {
				folderName: file.parentName,
				fileName: file.title,
				referenceNumber: file.aliasId,
				format: file.format,
				prepare: prepareAt,
				review: reviewAt,
				epReview: epReviewAt,
				cpReview: cpReviewAt,
				qcReview: qcReviewAt,
			}

			if (isAppAdminUser) {
				list = {
					...list,
					id: file.id,
					parentId: file.parentId,
				}
			}

			formattedList.push(list)
		})

		return formattedList
	},
})
