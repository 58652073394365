import axios from "axios"
import { idToString, IdType } from "../converters/id-converter"
import { APIProps, setTokenForAxiosAuth } from "./shared-for-api-requests"

/**
 * from axiox documentation
 * -  `params` are the URL parameters to be sent with the request
 *     Must be a plain object or a URLSearchParams object
 * -  `data` is the data to be sent as the request body
 * 		Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
 * 		When no `transformRequest` is set, must be of one of the following types:
 * 		- string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
 * 		- Browser only: FormData, File, Blob
 * 		- Node only: Stream, Buffer
 *
 */
const CancelToken = axios.CancelToken
const source = CancelToken.source()

export function getProjIdFromUrl() {
	const path = window.location.pathname

	if (path.includes("/project/checkin/")) {
		const projNumId = parseInt(path.split("/")[3])
		const projStringId = idToString(projNumId, IdType.project)
		console.log(
			"project ID from URL (in checkin):",
			projNumId,
			projStringId
		)
		return projStringId
	} else if (path.includes("/project/")) {
		const projNumId = parseInt(path.split("/")[2])
		const projStringId = idToString(projNumId, IdType.project)
		console.log("project ID from URL:", projNumId, projStringId)
		return projStringId
	} else {
		console.log("Current location is not project")
		return null
	}
}

export const getByToken = ({
	url,
	params = {},
	token,
	version,
}: {
	url: string
	params?: any
	token: any
	version?: string
}) => {
	axios.defaults.headers.authorization = "Bearer " + token
	params.cancelToken = source.token
	// for 'Access-Control-Allow-Origin'
	if (params.headers) {
		params.headers.withCredentials = true
	} else {
		params.headers = { withCredentials: true }
	}

	if (version === "v2") {
		return axios.get(process.env.REACT_APP_API_ENDPOINT_V2 + url, {
			params,
		})
	} else {
		return axios.get(process.env.REACT_APP_API_ENDPOINT + url, { params })
	}
}

export function getToDownload({ url, params = {}, tokenType }: APIProps) {
	setTokenForAxiosAuth(tokenType)
	params.cancelToken = source.token

	// for 'Access-Control-Allow-Origin' // TODO: seems not required... remove?
	if (params.headers) {
		params.headers.withCredentials = true
		// params.responseType = "blob"
	} else {
		params.headers = { withCredentials: true }
	}

	return axios.get(process.env.REACT_APP_API_ENDPOINT + url, {
		responseType: "blob",
	})
}
