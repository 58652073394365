// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetEliminatingEntryDetails = (self: any) => ({
	getEliminatingEntryDetails(projectId: string) {
		// 0. set actionName
		const actionName = "getEliminatingEntryDetails"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			clientId,
		}

		// 3) request API call to Model
		self.readEliminatingEntryDetails(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (
						response.status === 200 &&
						response.data[0].Status === 1
					) {
						//
						const fetchedData = response.data[0]
						let eeDetails: any[] = []
						fetchedData.EliminatingEntryDetails.map((item: any) => {
							eeDetails.push({
								accountNumber: item.AccountNumber,
								accountDesc: item.AccountDescription,
								createdBy: idToString(
									item.CreatedBy,
									IdType.user
								),
								credit: item.Credit,
								debit: item.Debit,
								entityName: item.Entity,
								entityId: item.EntityID,
								eeId: item.ID.toString(),
								year: item.Year,
							})
						})
						self.setEEDetails(eeDetails)
						self.setNeedRefresh(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched eliminating entry details",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetEliminatingEntryDetails
