import React, { useEffect, useState, useCallback } from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	DLIconButton,
	DLSpinnerCenterAligned,
	DLTreeListByTreeData,
	DLInputField,
	ConsoleLog,
	DLButton,
} from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiChevronRight, mdiAutorenew, mdiMagnify, mdiLibrary } from "@mdi/js"
// WARNING:
import Drawer from "@material-ui/core/Drawer"
import styled from "styled-components"
import {
	ActionStatus,
	DLObjectFormat,
} from "../../../common-models/enumerations/common-enums"
import { InputAdornment } from "@material-ui/core"
import { TempFolderRow, TempFileRow } from "./sub-components"

export default observer(function TemplateDrawerInProject() {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "getProjTemplateFirstFolders"
	ConsoleLog(" ----- TemplateDrawer rendered ----- ")

	const orgId = localStorage.getItem("orgId")
	const open = projStore.lib.drawerOpen
	const setOpen = projStore.lib.setDrawerOpen

	const handleSelectFolder = (event: any) => {
		projStore.lib.setSelectedTempFolder(event.target.value)
		projStore.lib.resetTemplateFlatList()
	}
	const handleSelectFile = (event: any) => {
		projStore.lib.resetTemplateFlatList()
		projStore.lib.setSelectedTempFile(event.target.value)
		projStore.lib.getProjTemplateFirstFolders(event.target.value)
	}

	useEffect(() => {
		if (orgId !== null && projStore.lib.flatList.length === 0) {
			// NOTE: Do not call if there are existing stored data because lib is not updated many time
			// NOTE: If the user want to get the latest list, should click the refresh button
			ConsoleLog("calling lib data from userEffect in lib drawer")
			open && projStore.lib.getTempFilesInProj(orgId)
		}
	}, [open])

	const drawerPadding = store.ui.isPageToolbarOpen ? "9rem" : "6rem"
	const openStatus = projStore.lib.drawerOpenStatus

	const treeData = projStore.lib.flatListAsTree()

	// WARNING: NEED TO CUSTOMIZE THIS DRAWER !!!!

	const handleRefresh = () => {
		if (orgId) {
			projStore.lib.setSelectedTempFolder("")
			projStore.lib.setSelectedTempFile("")
			projStore.lib.refreshInDrawer(orgId)
		}
	}
	return (
		<StyledTemplateDrawer
			paddingTop={drawerPadding}
			data-testid="temp-drawer-in-project"
		>
			<Drawer
				className="drawer-root"
				variant="persistent"
				anchor="right"
				open={openStatus}
			>
				<div className="drawer-container FC JSB">
					<div>
						<div className="drawer-header FR AC JSB">
							<div className="FR AC">
								<DLIconButton
									variant="iconOnly"
									size="large"
									align="left"
									clickHandler={() => setOpen(false)}
									eleTestId="Template drawer close button"
								>
									<DLTooltip title="Close the drawer">
										<Icon path={mdiChevronRight} size={1} />
									</DLTooltip>
								</DLIconButton>
								Template Library
								<DLIconButton
									eleTestId="file-temp-refresh-btn"
									tooltipText="Refresh"
									clickHandler={handleRefresh}
								>
									<Icon path={mdiAutorenew} size={0.7} />
								</DLIconButton>
							</div>
							<Link to="/organization/templates-and-library/templates">
								<DLIconButton eleTestId="move-to-templates-main-page">
									<DLTooltip title="Move to Organization Templates">
										<Icon path={mdiLibrary} size={0.8} />
									</DLTooltip>
								</DLIconButton>
							</Link>
						</div>
						<div className="drawer-body">
							<div className="temp-folder-select">
								<DLSingleSelect
									eleTestId="temp-folder-selection"
									placeholder="Select Template Group"
									eleOnChange={handleSelectFolder}
									eleValue={projStore.lib.selectedTempFolder}
									eleFullWidth
									optionList={projStore.lib.viewTemplateFolder()}
								/>
							</div>
							<div className="temp-file-type-select">
								<DLSingleSelect
									eleTestId="temp-file-selection"
									placeholder="Select Template"
									eleOnChange={handleSelectFile}
									eleValue={projStore.lib.selectedTempFile}
									eleFullWidth
									optionList={projStore.lib.viewTemplateFiles(
										projStore.lib.selectedTempFolder
									)}
								/>
							</div>
							<div className="lib-contents">
								{projStore.lib.getActionStatus(actionName) ===
									ActionStatus.loading && (
									<DLSpinnerCenterAligned />
								)}
								{projStore.lib.getActionStatus(actionName) ===
									ActionStatus.success && (
									<>
										{treeData.length ? (
											<DLTreeListByTreeData
												treeData={treeData}
												eleRenderNode={
													LibDrawerInProjRowRenderer
												}
												canDrag={false}
												eleWidth={620}
												eleHeight={450}
												indentWidth={20}
												rowHeight="relative"
												sortBy="title"
												externalNodeType="dlFileNode"
											/>
										) : (
											<div className="no-folder">
												No folder exist
											</div>
										)}
									</>
								)}
							</div>
						</div>
					</div>
					<div className="drawer-footer FR">
						<DLButton
							eleTestId="close-temp-drawer"
							color="primary"
							clickHandler={() => setOpen(false)}
							eleHeight="auto"
						>
							Close Template Library
						</DLButton>
					</div>
				</div>
			</Drawer>
		</StyledTemplateDrawer>
	)
})

const LibDrawerInProjRowRenderer = ({ node }: { node: any }) => {
	return (
		<>
			{node.objectFormat === DLObjectFormat.folder ? (
				<TempFolderRow node={node} />
			) : (
				<TempFileRow node={node} />
			)}
		</>
	)
}

interface StyledTemplateDrawerProps {
	paddingTop: any
}

const StyledTemplateDrawer = styled.div<StyledTemplateDrawerProps>`
	transition: 0.2s;
	.drawer-root > div {
		box-shadow: -2px 10px 10px 1px ${(props) => props.theme.shadowColorDeep};
		// background-color: ${(props) => props.theme.secondaryDeep};
		border-left: none;
		margin-top: ${(props) => props.paddingTop};
		border-top-left-radius: 4px;
		height: 82%;
		.drawer-container {
			width: 43rem;
			height: 100%;
			.drawer-header {
				padding-right: 1rem;
			}
			.drawer-body {
				padding: 0 2rem;
				.lib-select {
				}
				.lib-file-type-select {
					margin-bottom: 0.4rem;
				}
				.lib-searchbar {
					margin-bottom: 0.4rem;
				}
				.lib-count {
					margin-bottom: 0.4rem;
				}
				.lib-contents {
				}
			}
			.drawer-footer {
				margin-left: 1rem;
				margin-bottom: 1rem;
			}
		}
	}
	.no-folder {
		height: 150px;
		display: grid;
		text-align: center;
		align-items: center;
	}
	.rst__node {
		min-height: 32px !important;
		height: auto !important;
	}
`
