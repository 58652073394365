import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PageContainer } from "../../../../components/app-frame-elements"
import { DLSpinner } from "../../../../components/basic-elements"
import Iframe from "react-iframe"

export default observer(function TrialBalance({
	partialStore,
	contentsHeight,
	fetchingStatus,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
}) {
	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			{fetchingStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentsHeight }}
				>
					<DLSpinner />
				</div>
			)}
			{fetchingStatus === ActionStatus.success && (
				<div className="trial-balance-details">
					<Iframe
						data-testid="iframe-trial-balance"
						url={partialStore.url}
						width="100%"
						// frameborder="0"
						// allowfullscreen
						height={`${contentsHeight + 88}px`}
					/>
				</div>
			)}
		</PageContainer>
	)
})
