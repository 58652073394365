/**
 * Use this for dev admin side checking.
 * With this, console will not print the value when it is production
 */
const ConsoleLog = (title: string | any[], data?: any) => {
	const adminMode = localStorage.getItem("isAdminAppUser") === "true"

	if (process.env.REACT_APP_STAGE === "PRODUCTION" && !adminMode) {
		return
	} else {
		console.log(title, "___", data)
	}
}

export default ConsoleLog
