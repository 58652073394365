import { types } from "mobx-state-tree"
import {
	ReadOrgGroups,
	ReadOrgGroupCabinets,
	ReadClientsByGroup,
} from "../../../organization-side/groups/store/model-actions"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import { OrgGroupApiCallProps } from "../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import { OrgProjectApiProps } from "../../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"
import { CreateProject } from "../../../../service-modules/project-module/model-actions"

const ProjRollForwardModel = ({
	apiReadGroups,
	apiReadCabinets,
	apiReadClients,
	apiCreateProject,
}: {
	apiReadGroups: OrgGroupApiCallProps["apiReadGroups"]
	apiReadCabinets: OrgGroupApiCallProps["apiReadCabinets"]
	apiReadClients: OrgProjectApiProps["apiReadClients"]
	apiCreateProject: OrgProjectApiProps["apiCreateProject"]
}) =>
	types
		.model({})
		// * Group basic
		.actions((self) => ReadOrgGroups(self, apiReadGroups))
		.actions((self) => ReadOrgGroupCabinets(self, apiReadCabinets))
		.actions((self) => ReadClientsByGroup(self, apiReadClients))
		.actions((self, createProject = apiCreateProject) =>
			CreateProject(self, createProject)
		)
		// model common actions
		.actions(CommonModelActions)

export default ProjRollForwardModel
