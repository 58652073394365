import React, { useState } from "react"
import { observer } from "mobx-react-lite"

// dialogs
import EditOrgUserDialog from "./dialogs/EditOrgUserDialog"
import SuspendOrgUserDialog from "./dialogs/SuspendOrgUserDialog"
import RestoreOrgUserDialog from "./dialogs/RestoreOrgUserDialog"
import RemoveOrgUserDialog from "./dialogs/RemoveOrgUserDialog"
// components
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { AddUserButton, AddUserDialog } from "./dialogs/AddUserDialog"
import {
	DLButton,
	DLSpinner,
	ConsoleLog,
} from "../../../../components/basic-elements"
// dependencies

import OrgSetupUserListTable from "./sub-components/OrgSetupUserListTable"
import OrgSetupUsersCtxMenus from "./sub-components/OrgSetupUsersCtxMenus"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"

import { PermissionAsObjectProps } from "../../../../common-models/permission"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"

import DefaultPageCtxMenus from "../../../../components/combined-elements/projects-table/DefaultPageCtxMenus"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import BulkAddUsersDialog from "./dialogs/BulkAddUsersDialog"
import AssignedProjectsDialog from "./dialogs/AssignedProjectsDialog"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export default observer(function OrgSetupUsers({
	partialStore,
	actionStatus,
	clickPointExist,
	permission,
	i18n,
}: {
	partialStore: any
	actionStatus: ActionStatus
	clickPointExist: boolean
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const store = useRootStore()

	ConsoleLog(" __________ OrgSetupUsers __________ ")

	const timeZone = store.global.timeZone.uiValue
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={actionStatus === "LOADING"}
		>
			{/* ----- Contents ----- */}
			{actionStatus === "SUCCESS" && (
				<StyledSetupClients>
					<OrgSetupUserListTable
						i18n={i18n}
						permission={permission}
					/>
				</StyledSetupClients>
			)}

			{/* ---------- dialogs  ---------- */}
			{partialStore.addDialogOpen && <AddUserDialog i18n={i18n} />}
			{partialStore.editDialogOpen && <EditOrgUserDialog i18n={i18n} />}
			{partialStore.suspendDialogOpen && (
				<SuspendOrgUserDialog i18n={i18n} />
			)}
			{partialStore.restoreDialogOpen && (
				<RestoreOrgUserDialog i18n={i18n} />
			)}
			{partialStore.removeDialogOpen && (
				<RemoveOrgUserDialog i18n={i18n} />
			)}
			{partialStore.bulkAddUsersDialogOpen && (
				<BulkAddUsersDialog partialStore={partialStore} i18n={i18n} />
			)}
			{partialStore.assignedProjectsDialogOpen && (
				<AssignedProjectsDialog i18n={i18n} />
			)}

			{/* ---------- context menus ---------- */}
			{clickPointExist && (
				<OrgSetupUsersCtxMenus permission={permission} i18n={i18n} />
			)}

			{/* ---------- snackbar  ---------- */}
			<CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
		</PageContainer>
	)
})

const StyledSetupClients = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.partition {
		margin: 0 0.5rem;
	}
`
