import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const RemoveFile = (self: any) => ({
	removeFile(fileId: string, withoutConfirm?: boolean) {
		// 0.
		const actionName = "removeFile"

		let proceed = true
		if (!withoutConfirm) {
			proceed = window.confirm(
				`Do you really want to delete this <(${
					self.getItemById(fileId).aliasId
				}) ${self.getItemById(fileId).title}> file?`
			)
		}
		if (!proceed) {
			return
		} else {
			// 1.
			// 2.
			self.handleResponse({
				actionName,
				status: ActionStatus.loading,
				code: 0,
				color: MessageColorType.green,
				open: true,
			})

			// 3.
			self.deleteFile(fileId)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						self.resetSelectedItems()
						self.reflectRemoveItem(fileId) // delete a item on the flat list
						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else {
						// set fail case response (200 but fail)
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					// console.log(actionName+ "__error "+ error)
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		}
	},
	removeFiles({
		fileIds,
		index,
	}: {
		fileIds: { id: string; isParent: boolean }[]
		index: number
	}) {
		const actionName = "removeFiles"

		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Removing selected files ...",
			open: true,
		})

		self.deleteFile(fileIds[index].id)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// postWorkForSuccess && postWorkForSuccess()

					self.reflectRemoveItem(fileIds[index].id) // ---> Getting Error

					// set success case response
					if (index !== fileIds.length - 1) {
						self.removeFiles({
							fileIds,
							index: index + 1,
						})
					} else {
						// After all completion reset selected items
						self.resetSelectedItems()
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage: "Success to remove selected files",
							open: true,
							autoHide: true,
						})
					}
				} else {
					self.removeFiles({
						fileIds,
						index: index + 1,
					})
				}
			})
			.catch((error: Error) => {
				if (index !== fileIds.length - 1) {
					self.removeFiles({
						fileIds,
						index: index + 1,
					})
				}
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveFile
