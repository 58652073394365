import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../../components/basic-elements"
import BasicCounter from "../../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { handleExport } from "../../../../../../components/basic-elements/export/ExportXLSX"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import OrgDashboardTable from "../../../table-view/sub-components/OrgDashboardTable"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"

const HBarTableDetailsDialog = observer(
	({ partialStore, i18n }: { partialStore: any; i18n: DLI18nProps }) => {
		return (
			<DLDialog
				eleTestId="bar-detail-table-dialog"
				draggable
				isOpen={partialStore.hBarTableDetailsDialogOpen}
				setIsOpen={partialStore.setHBarTableDetailsDialogOpen}
				showCloseBtn={true}
				dialogTitle={
					partialStore.hBarDialogTitle !== ""
						? partialStore.hBarDialogTitle
						: "Projects"
				}
				dialogContents={
					<HBarTableDetailsDialogContainer
						partialStore={partialStore}
						i18n={i18n}
					/>
				}
				maxWidth="xl"
				fullWidth={true}
			/>
		)
	}
)

const HBarTableDetailsDialogContainer = observer(
	({ partialStore, i18n }: { partialStore: any; i18n: DLI18nProps }) => {
		const store = useRootStore()

		const dateFormat = store.global.dateFormat.value
		const dntFormat = store.global.getDntFormat
		const data = partialStore.viewHBarTableList(dntFormat)
		return (
			<div className="projects-table">
				<OrgDashboardTable
					partialStore={partialStore}
					contentsHeight="500px"
					dateFormat={dateFormat}
					data={data}
					i18n={i18n}
					menuId="HBarTableDetailsDialog"
				/>
			</div>
		)
	}
)

export default HBarTableDetailsDialog
