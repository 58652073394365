import { ConsoleLog } from "../../components/basic-elements"

/**
 * NOTE: Do not remove this file.
 * NOTE: This data should be replaced by API but it seems not ready and unrequired
 * NOTE: because customers are not using their customized role set yet.
 */

// WARNING: This Role LIST Should be removed
export const displayedProjRoles = [
	{
		oldNumId: 8,
		roleId: "id_qc",
		shortName: "QC",
		name: "Quality Control",
		color: "#4441EB",
	},
	{
		oldNumId: 6,
		roleId: "id_cp",
		shortName: "CP",
		name: "Concurring Partner",
		color: "#F2623E",
	},
	{
		oldNumId: 9,
		roleId: "id_ep",
		shortName: "EP",
		name: "Engagement Partner",
		color: "#A9A4FE",
	},
	{
		oldNumId: 5,
		roleId: "id_p",
		shortName: "P",
		name: "Partner",
		color: "#F7AD1B",
	},
	{
		oldNumId: 4,
		roleId: "id_d",
		shortName: "D",
		name: "Director",
		color: "#DADB7F",
	},
	{
		oldNumId: 3,
		roleId: "id_m",
		shortName: "M",
		name: "Manager",
		color: "#D4D3EF",
	},
	{
		oldNumId: 2,
		roleId: "id_ss",
		shortName: "SS",
		name: "Senior Staff",
		color: "#D8D8D8",
	},
	{
		oldNumId: 1,
		roleId: "id_js",
		shortName: "JS",
		name: "Junior Staff",
		color: "#D8D8D8",
	},
	{
		oldNumId: 0,
		roleId: "id_oa",
		name: "Organization Admin",
		shortName: "OA",
		color: "red",
	},
	// NOTE: WARNING: @Noah: because numberID are same as 10, cannot distinguish the readonly and pbc uploader here
	{
		oldNumId: 10,
		roleId: "pu_ex",
		name: "External Project User",
		shortName: "EX",
		color: "red",
	},
	// NOTE: WARNING: BELOW PART IS TEMPORARY FOR ISSUE FIX!!! COMMENT AND CHECK THE ROOT CAUSE WITH BACKEND
	{
		oldNumId: 11,
		roleId: "pu_ex",
		name: "TEMPORARY External Project User",
		shortName: "TEMP_EX",
		color: "red",
	},
	// {
	// 	oldNumId: 10,
	// 	roleId: "pu_exp", // "id_external_pbc",
	// 	name: "External PBC User",
	// 	shortName: "XP",
	// 	color: "red",
	// },
	// {
	// 	oldNumId: 10,
	// 	roleId: "pu_exr", // "id_external_readonly",
	// 	name: "External Readonly User",
	// 	shortName: "XR",
	// 	color: "pink",
	// },
]

// NOTE: Do not set the role name here. It is better to use role set information.
// WARNING: NOTE: Cannot use above list on the real production... let's find other way to get the shortName from the roleSet

export function findProjRoleId(roleId: number | string) {
	if (typeof roleId === "number") {
		return displayedProjRoles.find((element) => element.oldNumId === roleId)
			?.roleId
	} else if (typeof roleId === "string") {
		return displayedProjRoles.find((element) => element.roleId === roleId)
			?.oldNumId
	}
}

export type RolePropTypes = {
	oldNumId: number
	roleId: string
	name: string
	shortName: string
	color: string
}

export function findProjRoleInfo(roleId: number | string, from?: string) {
	console.log("roleId:", roleId, "/from:", from)

	let placeholder: RolePropTypes = {
		oldNumId: 999,
		roleId: "x",
		name: "(Unknown2)",
		shortName: "(?2)",
		color: "black",
	}
	// console.log("roleId:", roleId)
	if (typeof roleId === "number") {
		const target = displayedProjRoles.find(
			(element) => element.oldNumId === roleId
		)
		if (target !== undefined) {
			return target
		} else {
			ConsoleLog("Cannot find matched role " + roleId)

			return placeholder
		}
	} else if (typeof roleId === "string") {
		const target = displayedProjRoles.find(
			(element) => element.roleId === roleId
		)
		if (target !== undefined) {
			return target
		} else {
			ConsoleLog("Cannot find matched role " + roleId)

			return placeholder
		}
	} else {
		ConsoleLog("roleId type is weird...")
		return placeholder
	}
}

export function findProjRoleNumberId(roleId: string) {
	return displayedProjRoles.find((element) => element.roleId === roleId)
		?.oldNumId
}

export function findPrevRight(
	prevRightName: string,
	isInternal: boolean,
	prevRoleId: number
) {
	let prevRight = ""
	const right = prevRightName.toLowerCase().replace(/ /g, "")

	if (isInternal) {
		if (right.includes("archive") && right.includes("admin")) {
			prevRight = "existing_proj_arch_admin"
		} else if (!right.includes("archive") && right.includes("admin")) {
			prevRight = "existing_proj_admin"
		} else if (right.includes("project") && right.includes("user")) {
			prevRight = "existing_proj_user"
		} else if (right === "readonly" && prevRoleId !== 0) {
			prevRight = "existing_proj_readonly"
		} else if (right === "readonly" && prevRoleId === 0) {
			prevRight = "existing_proj_org_admin"
		}
	} else {
		// external user
		if (right.includes("pbc")) {
			prevRight = "existing_external_pbc_uploader"
		} else if (right.includes("readonly")) {
			prevRight = "existing_external_readonly"
		}
	}

	return prevRight
}

export function findProjRoleNewId(numId: number) {
	const roleId = displayedProjRoles.find(
		(element) => element.oldNumId === numId
	)?.roleId

	// if (roleId) {
	return roleId
	// } else {
	// 	return "external" // TODO: update is required
	// }
}
