import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams, useHistory } from "react-router-dom"
import { GraphQLResult } from "@aws-amplify/api"
import { getPostById, GetPostQuery } from "../../../../graphql/Post/queries"
// import {
// 	visitPost,
// 	VisitPostResponseType,
// } from "../../../../graphql/Post/mutations"
import {
	callGraphQL,
	subscribeGraphQL,
	subscribeGraphQL2,
} from "@datalobby/common"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import {
	onDeletePost,
	OnDeletePostSubscription,
	onUpdatePost,
	OnUpdatePostSubscription,
	setTypeOnDeletePostSubs,
	onUpdatePostHandler,
} from "../../../../graphql/Post/subscriptions"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import axios, { Method } from "axios"
import * as FileSaver from "file-saver"
import PostDetail from "../../../../service-modules/post/components/PostDetail"
import { PostAttachmentType } from "@datalobby/types"
import { downloadPostAttachment } from "../../../../service-modules/post/utils/download-post-attachment"

export default observer(function NotificationDetail({
	permission,
	menuId,
}: {
	permission: PermissionAsObjectProps
	menuId: string
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const userId = orgStore.checkin.orgUserId
	let { postId } = useParams<{ postId: string }>()
	let history = useHistory()

	useEffect(() => {
		// NOTE: Required initialization
		orgStore.noti.resetS3FilesStatus()
	}, [])

	const [loading, setLoading] = useState(false)

	const orgId = orgStore.checkin.orgInfo.orgId

	function getPost(postByIdQuery: GraphQLResult<GetPostQuery>) {
		const result = postByIdQuery.data?.getPostById
		return result
	}

	// NOTE: onUpdate subscription --------------------------
	useEffect(() => {
		const subscription = subscribeGraphQL2<OnUpdatePostSubscription>(
			onUpdatePost,
			onUpdatePostHandler,
			store.notis.updateOrgNotiItem
		)
		return () => subscription.unsubscribe()
	})

	// NOTE: onDelete subscription --------------------------
	const onDeletePostHandler = (deleteSubs: OnDeletePostSubscription) => {
		const postId = setTypeOnDeletePostSubs(deleteSubs)
		store.notis.sliceOrgNotiItem(postId)
		alert("This post has been deleted.")
		history.replace("/organization/notifications")
	}
	useEffect(() => {
		const subscription = subscribeGraphQL<OnDeletePostSubscription>(
			onDeletePost,
			onDeletePostHandler
		)

		return () => subscription.unsubscribe()
	})

	// NOTE: other things --------------------------
	useEffect(() => {
		async function getData() {
			setLoading(true)
			try {
				const options = {
					menuId,
					postId,
				}
				const postData = await callGraphQL<GetPostQuery>(
					getPostById,
					options
				)
				const post = getPost(postData)
				if (post === null) {
					// when the post ID doesn't exist anymore (when someone deleted it)
					history.replace("/organization/notifications")
				}
				store.notis.pushOrgNotiItem(post)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.error("Error fetching post", error)
			}
		}
		const postInStore = store.notis.getOrgNotiById(postId)
		if (postInStore) {
		} else {
			getData()
		}
	}, [])

	// async function runVisitPost(options: { postId: string; userId?: string }) {
	// 	await callGraphQL<VisitPostResponseType>(visitPost, options)
	// }

	const post_static = store.notis.getOrgNotiById(postId)
	// useEffect(() => {
	// 	if (post_static) {
	// 		if (!post_static.visitors.includes(userId)) {
	// 			const options = {
	// 				postId: postId,
	// 				userId: userId,
	// 			}
	// 			// Do not update store visit data. It will be updated with subscription
	// 			runVisitPost(options)
	// 		} else {
	// 			const options = {
	// 				postId: postId,
	// 			}
	// 			// Do not update store visit data. It will be updated with subscription
	// 			runVisitPost(options)
	// 		}
	// 	}
	// }, [])

	useEffect(() => {
		const userNeedRefresh = orgStore.setupUsers.needRefresh
		const userListAccess = orgStore.checkin.checkAccess(
			DLOrgSubMenus.setup_users
		)
		if (userNeedRefresh && userListAccess) {
			orgStore.setupUsers.getUserList()
		}
	}, [])

	const handleDelete = () => {
		let proceed = window.confirm("Do you really want to delete this post?")
		const postAction = () => {
			// remove s3 objects
			history.replace("/organization/notifications")
		}
		if (!proceed) {
			return
		} else {
			store.notis.removeOrRestorePost({
				postId,
				menuId,
				toDelete: true,
				by: userId,
				postAction,
			})
		}
	}

	const contentsAreaWidth = store.ui.contentsAreaWidth
	const contentsAreaHeight = store.ui.contentsAreaHeight
	const dntFormat = store.global.getDntFormat
	const orgUserList = orgStore.setupUsers.userListForRender
	const post = store.notis.viewOrgNotiItemById(postId, dntFormat, orgUserList)
	const existingFiles = post?.attachments.map((item: PostAttachmentType) => {
		// const fileInfo = JSON.parse(item)
		console.log("fileInfo:", item)
		return item
	})

	return (
		<PostDetail
			permission={permission}
			postId={postId}
			post={post}
			parentId={orgId}
			handleDelete={handleDelete}
			contentsAreaWidth={contentsAreaWidth}
			contentsAreaHeight={contentsAreaHeight}
			loading={loading}
			existingFiles={existingFiles}
			downloadHandler={downloadPostAttachment}
			mainUrl="/organization/notifications"
		/>
	)
})
