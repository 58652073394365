import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"

import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const DeleteFile = (
	self: any,
	deleteFile: FileSystemApiTypes["apiDeleteFile"]
) => ({
	deleteFile: flow(function* (fileId: string) {
		const actionName = "deleteFile"
		const menuId = self.storeName
		// 1.
		if (fileId === undefined || fileId === "" || menuId === undefined) {
			alert(
				`(${actionName}) undefined prop exist. fileId: ${fileId} | menuId: ${menuId}`
			)
			console.error(`(${actionName}) undefined prop exist.`)
			return
		}
		//
		//
		// 2. prepare params
		const fileNumId = idToNumber(fileId, IdType.file)

		//
		//
		// 3.
		if (typeof fileNumId === "number") {
			let params: any = {
				WPRID: fileNumId,
				Type: "WorkpaperFile", // wp & pf use same type "WorkpaperFile"
			}
			if (menuId === DLOrgSubMenus.proj_templates) {
				params = {
					TemplateId: fileNumId,
				}
			}
			try {
				const response = yield deleteFile({ params, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleError({ error: error, openSnackbar: true })

				return
			}
		} else {
			alert(`(${actionName}) invalid format props: ${fileNumId}`)
		}
	}),
})

export default DeleteFile
