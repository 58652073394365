import { flow } from "mobx-state-tree"

const CreateOtherProject = (
	self: any,
	createOtherProject: any
) => ({
	createOtherProject: flow(function* (inputs: any) {
	    const actionName = "createOtherProject"
	    
    	try {
			let params = {
				...inputs
			}
			const response = yield createOtherProject(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
	})
})

export default CreateOtherProject