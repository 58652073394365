import {
	ActionStatus,
	DLSector,
} from "../../../../../../common-models/enumerations/common-enums"
// Organization side permissions
import orgArchiveAdminPermission from "../../../../../../admin-static-data/app-access-control-table/org-side/aul-org-2021-01-aa.json"
import orgSudperAdminPermission from "../../../../../../admin-static-data/app-access-control-table/org-side/aul-org-2021-01-sa.json"
import orgGroupAdminPermission from "../../../../../../admin-static-data/app-access-control-table/org-side/aul-org-2021-01-ga.json"
import orgUserAccessPermission from "../../../../../../admin-static-data/app-access-control-table/org-side/aul-org-2021-01-ua.json"
import orgExternalUserPermission from "../../../../../../admin-static-data/app-access-control-table/org-side/aul-org-2021-01-ex.json"
// Project side permissions
import projProjectArchiveAdminPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-paa.json"
import projProjectAdminPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-pa.json"
import projProjectUserPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-pu.json"
import projProjectReadonlyPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-pr.json"
import projOrgAdminPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-oa.json"
import projExternalReadonlyPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-pxr.json"
import projExternalPBCUserPermission from "../../../../../../admin-static-data/app-access-control-table/project-side/aul-proj-2021-01-pxp.json"

/**
 * * Organization Side Default Role Set (aul-default-org-role-set-2021-01.json)
 * - aul-org-2021-01-aa
 * - aul-org-2021-01-sa
 * - aul-org-2021-01-ga
 * - aul-org-2021-01-ua
 * - aul-org-2021-01-ex
 *
 * * Project Side Default Role Set (aul-default-proj-role-set-2021-01.json)
 * - aul-proj-2021-01-paa
 * - aul-proj-2021-01-pa
 * - aul-proj-2021-01-pu
 * - aul-proj-2021-01-pr
 * - aul-proj-2021-01-oa
 * - aul-proj-2021-01-pxr
 * - aul-proj-2021-01-pxp
 */
const GetPermissionSet = (self: any) => ({
	getPermissionSet(sector: DLSector, roleId: string) {
		const actionName = "getPermissionSet"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		let permissionData: any = []
		if (sector === DLSector.org) {
			// NOTE: Select Organization Side Permission Data
			if (roleId === "aul-org-2021-01-aa") {
				permissionData = orgArchiveAdminPermission
			} else if (roleId === "aul-org-2021-01-sa") {
				permissionData = orgSudperAdminPermission
			} else if (roleId === "aul-org-2021-01-ga") {
				permissionData = orgGroupAdminPermission
			} else if (roleId === "aul-org-2021-01-ua") {
				permissionData = orgUserAccessPermission
			} else if (roleId === "aul-org-2021-01-ex") {
				permissionData = orgExternalUserPermission
			}
		} else if (sector === DLSector.proj) {
			// NOTE: Select Project Side Permission Data
			if (roleId === "aul-proj-2021-01-paa") {
				permissionData = projProjectArchiveAdminPermission
			} else if (roleId === "aul-proj-2021-01-pa") {
				permissionData = projProjectAdminPermission
			} else if (roleId === "aul-proj-2021-01-pu") {
				permissionData = projProjectUserPermission
			} else if (roleId === "aul-proj-2021-01-pr") {
				permissionData = projProjectReadonlyPermission
			} else if (roleId === "aul-proj-2021-01-oa") {
				permissionData = projOrgAdminPermission
			} else if (roleId === "aul-proj-2021-01-pxr") {
				permissionData = projExternalReadonlyPermission
			} else if (roleId === "aul-proj-2021-01-pxp") {
				permissionData = projExternalPBCUserPermission
			}
		}

		const baseRoles = self.roleSet.roles
		console.log(actionName, "self.roleSet:", self.roleSet)
		console.log(actionName, "baseRoles:", baseRoles)

		const roleInfo = baseRoles.find(
			(info: {
				color: string
				id: string
				index: number
				name: string
				shortName: string
			}) => info.id === permissionData.id
		)
		const assignedRole = {
			roleId: permissionData.id,
			name: roleInfo?.name || "please find my name",
			shortName: roleInfo?.shortName || "please find my short name",
			color: roleInfo?.color || "please find my color",
			usePeriod: permissionData.usePeriod ? permissionData.usePeriod : {},
			globalPermissions: permissionData.generalPermissions,
			menuPermissions: permissionData.menuPermissions,
		}
		console.log("--- assignedRole", assignedRole)
		self.setAssignedRole(assignedRole)

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.success,
		})
	},
})

export default GetPermissionSet
