const DialogRelatedViews = (self: any) => ({
	isDuplicatedTemplateGroupName(name: string) {
		const templateGroups = self.projTemplates.filter(
			(item: any) => item.parentId === null
		)
		const target = templateGroups.find((item: any) => item.title.toLowerCase() === name.toLowerCase())
		if (target) {
			return true
		} else {
			return false
		}
	},
	isDuplicatedTemplateName(name: string) {
		const templates = self.projTemplates.filter(
			(item: any) => item.parentId !== null
		)
		const target = templates.find((item: any) => item.title.toLowerCase() === name.toLowerCase())
		if (target) {
			return true
		} else {
			return false
		}
	},
})

export default DialogRelatedViews
