import React, { ReactElement } from "react"
import Icon from "@mdi/react"
import {
	mdiTrashCan,
	mdiDownload,
	mdiInformationVariant,
	mdiPencil,
	mdiCommentOutline,
	mdiCommentPlusOutline,
	mdiCommentEditOutline,
	mdiSwapHorizontal,
	mdiTableOfContents,
	mdiCheck,
	mdiCheckAll,
	mdiFilePlusOutline,
	mdiFolderPlusOutline,
	mdiCursorMove,
	mdiPlus,
	mdiContentCopy,
	mdiPin,
	mdiRestore,
	mdiClipboardAccount,
	mdiCloseBox,
	mdiWindowClose,
	mdiMessageProcessingOutline,
	mdiStar,
	mdiStarOutline,
	mdiFileOutline,
	mdiFolderOpen,
	mdiFolder,
	mdiFileExcelOutline,
	mdiFileExcel,
	mdiFilePdfOutline,
	mdiFileWordOutline,
	mdiDrag,
	mdiFolderZipOutline,
	mdiCalendar,
	mdiSettingsOutline,
	mdiSync,
	mdiAccountGroup,
	mdiDotsHorizontal,
	mdiClipboardArrowDown,
	mdiClipboardArrowUpOutline,
	mdiFormatListCheckbox,
	mdiAlert,
	mdiAlertCircleOutline,
	mdiClose,
} from "@mdi/js"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"

const StyledCommonIcon = styled.div`
	display: flex;
	align-items: center;
	justify-content: center;
	width: 1.2rem;
	/* width: 5rem; */
	overflow: hidden;
	margin-right: 6px;
	color: ${(props) => props.theme.shade60};
	fill: ${(props) => props.theme.shade60};
	&.primary {
		color: ${(props) => props.theme.emphasis};
		fill: ${(props) => props.theme.emphasis};
	}
	&.noMargin {
		margin-right: 0;
	}
	.warning {
		fill: ${(props) => props.theme.warning};
		color: ${(props) => props.theme.warning};
		svg {
			fill: ${(props) => props.theme.warning};
			color: ${(props) => props.theme.warning};
		}
	}
	&.warning {
		fill: ${(props) => props.theme.warning};
		color: ${(props) => props.theme.warning};
		svg {
			fill: ${(props) => props.theme.warning};
			color: ${(props) => props.theme.warning};
		}
	}
	svg {
		/* NOTE: when set svg width as 100%, it inherit the width of parent */
		/* width: 1.2rem; */
		width: 100%;
	}
`

export enum CommonIconSize {
	small = "1rem",
	normal = "1.2rem",
	ctxMenuIcon = "1.2rem",
	dialogHeaderIcon = "1.6rem",
}

// export const EditIcon = ({ noMargin }: CommonIconProps) => {
// 	return (
// 		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
// 			<Icon path={mdiPencil} />
// 		</StyledCommonIcon>
// 	)
// }

export enum DLIconName {
	// CRUD related
	add = "add",
	delete = "delete",
	// comment related
	comment = "comment",
	addComment = "addComment",
	editComment = "editComment",
	//
	setting = "setting",
	//
	forceClose = "forceClose",
	close = "close",
	refresh = "refresh",
	//
	duplicate = "duplicate",
	//
	group = "group",
	folder = "folder",
	replace = "replace",
	download = "download",
	edit = "edit",
	//
	excel = "excel",
	//
	ctx = "ctx",
	warningLow = "warningLow",
	warning = "warning",
}

const dlIcons = {
	// CRUD related
	add: mdiPlus,
	delete: mdiTrashCan,
	// comment related
	comment: mdiCommentOutline,
	addComment: mdiCommentPlusOutline,
	editComment: mdiCommentEditOutline,
	//
	setting: mdiSettingsOutline,
	//
	forceClose: mdiCloseBox,
	close: mdiClose,
	refresh: mdiSync,
	//
	duplicate: mdiContentCopy,
	//
	group: mdiAccountGroup,
	folder: mdiFolderOpen,
	replace: mdiSwapHorizontal,
	download: mdiDownload,
	edit: mdiPencil,
	//
	excel: mdiFileExcel,
	//
	ctx: mdiDotsHorizontal,
	warningLow: mdiAlertCircleOutline,
	warning: mdiAlert,
}

export type DLIconProps = {
	name: DLIconName
	noMargin?: boolean
	size?: CommonIconSize | string | number
	color?: "primary" | "warning" | string
	eleClassName?: string
	tooltipText?: string | ReactElement
}

export type CommonIconProps = {
	noMargin?: boolean
	size?: CommonIconSize | string | number
	color?: "primary" | string
}

export const IconPlaceholder = ({
	size = "1.2rem",
}: {
	size?: CommonIconSize | string | number
}) => {
	return <div style={{ width: size, marginRight: 6 }} />
}

export const DLIcon = ({
	name,
	noMargin,
	size,
	color,
	eleClassName,
	tooltipText,
}: DLIconProps) => {
	let className = eleClassName
	let iconColor = ""
	if (noMargin) {
		className = className + " noMargin" // NOTE: empty space is required. DO NOT REMOVE
	}
	if (color !== undefined && color === "primary") {
		className = className + " primary" // NOTE: empty space is required. DO NOT REMOVE
		iconColor = color
	}
	if (color !== undefined && color !== "primary") {
		iconColor = color
	}
	if (color !== undefined && color === "warning") {
		className = className + " warning"
	}
	return (
		<StyledCommonIcon className={className} style={{ width: size }}>
			{tooltipText ? (
				<DLTooltip title={tooltipText} arrow>
					<Icon path={dlIcons[name]} color={iconColor} />
				</DLTooltip>
			) : (
				<Icon path={dlIcons[name]} color={iconColor} />
			)}
		</StyledCommonIcon>
	)
}

/**
 *
 *
 *
 *
 *
 */

export const RequestIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiMessageProcessingOutline} />
		</StyledCommonIcon>
	)
}

export const CloseIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiWindowClose} />
		</StyledCommonIcon>
	)
}

export const RestoreIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiRestore} />
		</StyledCommonIcon>
	)
}

export const InfoIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiInformationVariant} />
		</StyledCommonIcon>
	)
}

export const CommentIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiCommentOutline} />
		</StyledCommonIcon>
	)
}

export const HistoryIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiTableOfContents} />
		</StyledCommonIcon>
	)
}

export const PrepareIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiCheck} />
		</StyledCommonIcon>
	)
}

export const ReviewIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiCheckAll} />
		</StyledCommonIcon>
	)
}

export const FileIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiFileOutline} />
		</StyledCommonIcon>
	)
}

export const FileAddIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiFilePlusOutline} />
		</StyledCommonIcon>
	)
}

export const DragIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiDrag} />
		</StyledCommonIcon>
	)
}

export const ExcelIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<DLTooltip title="Excel">
				<Icon path={mdiFileExcelOutline} size={0.8} color="green" />
			</DLTooltip>
		</StyledCommonIcon>
	)
}

export const PdfIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<DLTooltip title="PDF">
				<Icon path={mdiFilePdfOutline} size={0.8} color="red" />
			</DLTooltip>
		</StyledCommonIcon>
	)
}

export const WordIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<DLTooltip title="Word">
				<Icon path={mdiFileWordOutline} size={0.8} color="blue" />
			</DLTooltip>
		</StyledCommonIcon>
	)
}

export const ZipIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<DLTooltip title="Zip">
				<Icon path={mdiFolderZipOutline} size={0.8} />
			</DLTooltip>
		</StyledCommonIcon>
	)
}

export const FolderIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiFolder} />
		</StyledCommonIcon>
	)
}

export const ApgIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiFormatListCheckbox} />
		</StyledCommonIcon>
	)
}

export const OpenedFolderIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiFolderOpen} />
		</StyledCommonIcon>
	)
}

export const FolderAddIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiFolderPlusOutline} />
		</StyledCommonIcon>
	)
}

export const MoveIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiCursorMove} />
		</StyledCommonIcon>
	)
}

export const PlusIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiPlus} />
		</StyledCommonIcon>
	)
}

export const PinIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiPin} />
		</StyledCommonIcon>
	)
}

export const FileCheckoutIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiClipboardAccount} />
		</StyledCommonIcon>
	)
}

export const FileCheckoutToLocalIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiClipboardArrowDown} />
		</StyledCommonIcon>
	)
}

export const FileCheckinFromLocalIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiClipboardArrowUpOutline} />
		</StyledCommonIcon>
	)
}

export const StarIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin primary" : "primary"}>
			<Icon path={mdiStar} size={0.8} />
		</StyledCommonIcon>
	)
}

export const StarOutlineIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiStarOutline} size={0.8} />
		</StyledCommonIcon>
	)
}

export const CalenderIcon = ({ noMargin }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin" : ""}>
			<Icon path={mdiCalendar} size={1} />
		</StyledCommonIcon>
	)
}

export const CalenderSelectedIcon = ({ noMargin, color }: CommonIconProps) => {
	return (
		<StyledCommonIcon className={noMargin ? "noMargin primary" : "primary"}>
			<Icon path={mdiCalendar} size={1} />
		</StyledCommonIcon>
	)
}
