import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import FileFolderRow from "../../../../components/combined-elements/tree-nav/DLTreeNavParentRow2"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import styled from "styled-components"

export default observer(function TempDrawerFolderRow({ node }: { node: any }) {
	const projStore = useProjStore()
	const libStore = projStore.lib

	const title = node.title

	const handleExpandFolder = ({
		targetId,
		status,
	}: {
		targetId: string
		status: boolean
	}) => {
		libStore.toggleTemplateExpand(targetId, !status)
		libStore.getChildrenOfFolder(targetId)
	}

	const actionName = "getChildrenOfFolder"
	const actionNameForUi = actionName + "_" + node.id

	let query = libStore.viewQuery
	let matched =
		query !== "" && node.title.toLowerCase().includes(query.toLowerCase())

	const isLoading =
		libStore.getActionStatus(actionNameForUi) === ActionStatus.loading
	return (
		<StyledContainer className={matched && "matched"}>
			{/* TODO: This container should be removed and query related part should be moved to the shared folder row below */}
			{/* NOTE: hide or show does not be controled on here becuase this part is a 'title' on the row (to use sortable tree library) */}
			<FileFolderRow
				expanded={node.expanded}
				handleExpand={() =>
					handleExpandFolder({
						// node,
						targetId: node.id,
						status: node.expanded,
					})
				}
				canDrag={false}
				title={title}
				selected={false} // TODO: update is required
				noChildren={node.children.length <= 0}
				noChevron
				isLoading={isLoading}
			/>
		</StyledContainer>
	)
})

const StyledContainer = styled.div`
	&.matched {
		color: ${(props) => props.theme.emphasis};
	}
`
