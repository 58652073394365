import React from "react"
import {
	AddDialog,
	EditDialog,
	DeleteDialog,
	EditChecklistDialog,
} from "./dialogs"
import { PageContainer } from "../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import OrgSetupEngTypesTable from "./sub-components/OrgSetupEngTypesTable"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import styled from "styled-components"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../../common-models/permission"

export default function OrgSetupEngTypes({
	partialStore,
	fetchingStatus,
	permission,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={fetchingStatus === ActionStatus.loading}
		>
			<StyledSetupEngType>
				<OrgSetupEngTypesTable
					i18n={i18n}
					permission={permission}
				/>

				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>

				<AddDialog i18n={i18n} />
				<EditDialog i18n={i18n} />
				<EditChecklistDialog i18n={i18n} />
				<DeleteDialog i18n={i18n} />
			</StyledSetupEngType>
		</PageContainer>
	)
}

const StyledSetupEngType = styled.div`
	padding: 0 1rem;
`
