import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */

const GetProjUsersAndFileCount = (self: any) => ({
	getProjUsersAndFileCount(projId: string) {
		// 0.
		const actionName = "getProjUsersAndFileCount"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const clientId = idToNumber(projId, IdType.project)
		const payload = {
			ClientId: clientId,
			Type: 4,
			// FinancialYear: "",
			// Lang: "",
			// UserType: 0,
			// RoleID: "",
			// AccessRightID: "",
			// SearchText: "",
			// pageSize: 0,
			// pageNo: 0,
			// UserStatusType: 0
		}
		self.readProjUsersAndFileCount(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					const projectUsers = response.data
					let reOrganizedExtUsers: any[] = []
					projectUsers.ExternalUsersList.map((user: any) => {
						const userDetails = {
							userId: idToString(user.UserID, IdType.user), // TODO: @Prudhvi-0817: Please use idToString
							name: user.Name,
							aliasId: user.UserName,
							email: user.Email,
							role: user.Role,
							accessRight: user.AccessRight,
							isGrayed: user.isGrayed,
							isStrikedOut: user.IsStrikeOut,
						}
						reOrganizedExtUsers.push(userDetails)
					})
					let reOrganizedIntUsers: any[] = []
					projectUsers.InternalUsersList.map((user: any) => {
						const intUserDetails = {
							userId: idToString(user.UserID, IdType.user), // TODO: @Prudhvi-0817: Please use idToString
							name: user.Name,
							aliasId: user.UserName,
							email: user.Email,
							role: user.Role,
							accessRight: user.AccessRight,
							isGrayed: user.isGrayed,
							isStrikedOut: user.IsStrikeOut,
						}
						reOrganizedIntUsers.push(intUserDetails)
					})

					const reOrganizedUserDetails = {
						// TODO: @Prudhvi-0817: Do we need to use string for count...??
						extUsersCount: projectUsers.ExtUsersCount,
						activeExtUsersCount: projectUsers.ActiveExtUsersCount,
						inActiveExtUsersCount:
							projectUsers.InActiveExtUsersCount,
						unassignExtUsersCount:
							projectUsers.UnassignExtUsersCount,
						internalUsersCount: projectUsers.InternalUsersCount,
						activeIntUsersCount: projectUsers.ActiveIntUsersCount,
						inActiveIntUsersCount:
							projectUsers.InActiveIntUsersCount,
						unassignIntUsersCount:
							projectUsers.UnassignIntUsersCount,
						wpCount: projectUsers.WorkPaperCount,
						pfCount: projectUsers.PermanentFileCount,
						internalUsers: reOrganizedIntUsers,
						externalUsers: reOrganizedExtUsers,
					}
					// ConsoleLog(reOrganizedUserDetails)
					self.pushItemToProjectUsers(reOrganizedUserDetails)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	// relayGetProjUsers
	// WARNING: below is working only for the archived project
	// WARNING: and its replica validation. Not for normal purpose
	relayGetProjUsersAndFileCount(
		projList: any,
		index: number,
		updateList: any
	) {
		if (projList.length >= index + 1) {
			const project = projList[index]

			const projId = idToNumber(project.id, IdType.project)

			const archivedDate = project.archivedAt.slice(0, 10)
			const archivedTime = project.archivedAt.slice(11)

			let wpCount = 0
			let pfCount = 0
			let userCount = 0

			const payload = {
				ClientId: projId,
				Type: 4,
			}

			self.readProjUsersAndFileCount(payload).then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const projUsers = response.data

					userCount =
						projUsers.InternalUsersCount + projUsers.ExtUsersCount
					wpCount = projUsers.WorkPaperCount
					pfCount = projUsers.PermanentFileCount
					// const replicaId = projectDetails.ReplicaId

					const xxx = {
						...project,
						// replicaId: "x",
						//
						archivedDate,
						archivedTime,
						wpCount,
						pfCount,
						userCount,
					}
					updateList(xxx)

					if (projList.length > index + 1) {
						self.relayGetProjUsersAndFileCount(
							projList,
							index + 1,
							updateList
						)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				} else {
					console.log(
						index,
						"failed to get project detail for",
						projId
					)
					const xxx = {
						...project,
						// replicaId: 0,
						//
						archivedDate,
						archivedTime,
						wpCount,
						pfCount,
						userCount,
					}
					updateList(xxx)

					if (projList.length > index + 1) {
						self.relayGetProjUsersAndFileCount(
							projList,
							index + 1,
							updateList
						)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				}
			})
			// .catch((err: Error) => {
			// 	console.log("err to get project replicaId for", projId, err)
			// 	if (projList.length > index + 1) {
			// 		self.relayGetRepId(projList, index + 1)
			// 	}
			// 	if (projList.length === index + 1) {
			// 		console.log("THE END")
			// 	}
			// })
		}
	},
})

export default GetProjUsersAndFileCount
