import styled from "styled-components"

const StyledDLMenuTabs = styled.div`
	&.page-header {
		height: var(--standard-unit);
		flex-shrink: 0;
		font-weight: var(--font-bold);
		color: ${(props) => props.theme.textHighEmphasis};
		border-bottom: 1px solid ${(props) => props.theme.secondary};
		padding: 0;
		.org-page-header {
			padding-left: var(--spacing-lg);
			.page-description {
				color: ${(props) => props.theme.textMediumEmphasis};
				font-weight: 100;
				margin-left: 1rem;
				margin-top: 2px;
			}
		}
	}
`
export default StyledDLMenuTabs
