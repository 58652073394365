import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"

const EditFinancialSubGroup = (self: any) => ({
	editFinancialSubGroup(financialSubGroup: string, fsSubGroupId: number) {
		// 0
		const actionName = "editFinancialSubGroup"
		// 1
		const checkedProps = hasEmptyProps({ financialSubGroup })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Updating financial sub group is in progress",
			open: true,
		})

		const payload = {
			financialSubGroup: checkedProps.props.financialSubGroup,
			id: fsSubGroupId,
		}

		// 3) request API call to Model
		self.createFinancialSubGroup(payload) // Same API for create and update with diff payload
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						self.editSubGroupName(
							fetchedData.financialSubGroup,
							fetchedData.id
						)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully updated sub group",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditFinancialSubGroup
