import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

const ReadFirstFolders = (
	self: any,
	apiReadFirstFolder: FileSystemApiTypes["apiReadFirstFolder"]
) => ({
	readFirstFolders: flow(function* ({
		projId,
		menuId,
	}: {
		projId: string
		menuId: DLProjSubMenus
	}) {
		const actionName = "readFirstFolders"
		//
		//
		// 1.
		if (projId === undefined || projId === "" || menuId === undefined) {
			alert(`(${actionName}) One of the params is undefined.`)
			ConsoleLog([actionName, "projId: ", projId, "menuId: ", menuId])
			return
		}
		//
		//
		// organize the props
		const numberId = idToNumber(projId, IdType.project)
		let endpoint: string = ""
		//
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/CheckWPRFldFiles"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/GetParentPermanentFileFolders"
		}
		//
		//
		// 2.
		if (typeof numberId === "number" && endpoint !== "") {
			try {
				const params = {
					ClientId: numberId,
				}
				const response = yield apiReadFirstFolder(endpoint, params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId} endpoint: ${endpoint}`)
		}
	}),
})

export default ReadFirstFolders
