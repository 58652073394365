import React from "react"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import {
	mdiFolder,
	mdiDrag,
	mdiPlus,
	mdiPencil,
	mdiTrashCan,
	mdiContentCopy,
	mdiSortAlphabeticalAscending,
	mdiFolderOpen,
	mdiChevronUp,
	mdiChevronDown,
} from "@mdi/js"
import { DLIconButton } from "../../basic-elements"
import { ActionProps } from "../../../common-models/types/folder-row-rendering"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import { convertTestId } from "../../../library/converters/id-converter"

type TreeNavParentRowCommonActionProps = {
	add: ActionProps
	edit: ActionProps
	remove: ActionProps
	duplicate: ActionProps
	arrange: ActionProps
}

type TreeNavParentRowProps = {
	expanded: boolean
	canDrag: boolean
	title: string
	selected: boolean
	showButtons: boolean
	actions?: TreeNavParentRowCommonActionProps
	icon?: React.ReactElement
	permission: PermissionAsObjectProps
	hasChildren?: boolean
}

export default function DLTreeNavParentRow({
	title,
	expanded = false,
	canDrag = false,
	selected = false,
	showButtons = true,
	actions,
	permission,
	icon,
	hasChildren = false,
}: TreeNavParentRowProps) {
	const getTestId = (source: string) => {
		return source.split(" ").join("-")
	}

	return (
		<StyledDLTreeNavParentRow
			className={`FR AC JSB folder-row ${selected && "selected"}`}
		>
			<div className="left-side">
				{canDrag && (
					<Icon path={mdiDrag} size={0.8} className="drag-icon" />
				)}
				<div
					className="FR AC"
					data-testid={`treeNav-folder-icon-${convertTestId(title)}`}
				>
					{expanded ? (
						<>
							<Icon
								path={mdiChevronUp}
								size={0.7}
								className="folder-icon expanded"
							/>
							{icon ? (
								icon
							) : (
								<Icon path={mdiFolderOpen} size={0.8} />
							)}
							<div className="title-text">{title}</div>
							{!hasChildren && (
								<span className="no-children">(No items)</span>
							)}
						</>
					) : (
						<>
							<Icon
								path={mdiChevronDown}
								size={0.7}
								className="folder-icon"
							/>
							{icon ? icon : <Icon path={mdiFolder} size={0.8} />}
							<div className="title-text">{title}</div>
						</>
					)}
				</div>
			</div>
			<div className="right-side">
				{showButtons && actions && (
					<div className="buttons-container FR AC">
						{permission.create && (
							<DLIconButton
								eleTestId={getTestId(actions.add.tooltipText)}
								tooltipText={actions.add.tooltipText}
								clickHandler={actions.add.action}
							>
								<Icon path={mdiPlus} size={0.7} />
							</DLIconButton>
						)}
						{permission.update && (
							<DLIconButton
								eleTestId={getTestId(actions.edit.tooltipText)}
								tooltipText={actions.edit.tooltipText}
								clickHandler={actions.edit.action}
							>
								<Icon path={mdiPencil} size={0.7} />
							</DLIconButton>
						)}
						{permission.delete && (
							<DLIconButton
								eleTestId={getTestId(
									actions.remove.tooltipText
								)}
								tooltipText={actions.remove.tooltipText}
								clickHandler={actions.remove.action}
							>
								<Icon path={mdiTrashCan} size={0.7} />
							</DLIconButton>
						)}
						{permission.create && (
							<DLIconButton
								eleTestId={getTestId(
									actions.duplicate.tooltipText
								)}
								tooltipText={actions.duplicate.tooltipText}
								clickHandler={actions.duplicate.action}
							>
								<Icon path={mdiContentCopy} size={0.7} />
							</DLIconButton>
						)}
						<DLIconButton
							eleTestId={getTestId(actions.arrange.tooltipText)}
							tooltipText={actions.arrange.tooltipText}
							clickHandler={actions.arrange.action}
						>
							<Icon
								path={mdiSortAlphabeticalAscending}
								size={0.7}
							/>
						</DLIconButton>
					</div>
				)}
			</div>
		</StyledDLTreeNavParentRow>
	)
}

const StyledDLTreeNavParentRow = styled.div`
	width: 100%;
	.left-side {
		.drag-icon {
		}
		.folder-icon {
			cursor: pointer;
			transition: 0.4s;
			:hover {
				color: ${(props) => props.theme.emphasis};
			}
			&.expanded {
			}
		}
		.title-text {
			margin-left: 0.6rem;
		}
	}
	.right-side {
		.buttons-container {
			opacity: 0;
			transition: 0.4s;
		}
	}
	:hover {
		.buttons-container {
			opacity: 1;
		}
	}
	&.selected {
		font-weight: 700;
		color: ${(props) => props.theme.emphasis};
	}
	cursor: pointer;
`
