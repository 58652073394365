import { types } from "mobx-state-tree"

export const openedFilesModel = types.model({
	id: types.string,
	accessRequestedBy: types.union(types.string, types.null),
	openedAt: types.string,
	openedBy: types.string,
	//openedFilesRequestList: types.string,
	openedUserRole: types.string,
	description: types.string,
	referenceNumber: types.string,
	selected: types.boolean,
})
