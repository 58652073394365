import { ActionStatus, CreatePostInputType } from "@datalobby/types/lib"

const AddPost = (self: any) => ({
	addPost(post: CreatePostInputType, postAction?: (postId: string) => void) {
		const actionName = "addPost"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.createPost(post)
			.then((response: any) => {
				console.log(actionName, "/ response", response)
				const post = response.data.createPost
				//
				postAction && postAction(post.id)
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddPost
