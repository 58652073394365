import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { OrgGroupApiCallProps } from "../../../../../../service-modules/group-module/org-group-shared-apis.calling-props"

const ReadOrgGroupCabinetList = (
	self: any,
	readCabinets: OrgGroupApiCallProps["apiReadCabinets"]
) => ({
	readOrgGroupCabinetList: flow(function* (groupId: string) {
		const actionName = "readOrgGroupCabinetList"
		// 1.
		if (groupId === undefined) {
			alert(`(${actionName}) param is undefined: groupId: ${groupId}`)
			return
		}
		// 2.
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			try {
				const params = {
					GroupID: numberId,
					Type: 1,
				}
				const response = yield readCabinets(params)
				// console.log(actionName"__ response", response)
				return response
			} catch (error) {
				// console.log(actionName"__ error", error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}: ${groupId}`)
		}
	}),
})

export default ReadOrgGroupCabinetList
