// ---------- reponse cases
import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../../../common-models/enumerations/common-enums"

import { findProjRoleInfo } from "../../../../../../../temporary-data/project-side/find-proj-role"
import {
	idToString,
	IdType,
} from "../../../../../../../library/converters/id-converter"
import {
	initialDLFileAsFolder,
	initialDLFile,
} from "../../../../../../../service-modules/file-module/data-models/initial-file"
import { DLOrgSubMenus } from "../../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { RoleModelProps } from "../../../../../../../service-modules/file-module/data-models/file.data-model"
import {
	convertExtension,
	convertSourceFormat,
	isCustomFile,
} from "../../../../../../../library/converters/source-format-converter"
import { formatFileSize } from "@datalobby/common/lib/format-file-size"
import { sortFoldersNFiles } from "../../../../../../../library/file-utils/file-utils"

const GetProjTemplateChildrenOfFolder = (self: any) => ({
	getChildrenOfFolder(parentId: string) {
		// 0.
		// NOTE: Both template and its internal folders are using same API
		// NOTE: but set individual viewModel action because it makes some confusions...
		// (= get template details also uses same API )
		const actionName = "getChildrenOfFolder"
		const actionNameForUiDetail = "getChildrenOfFolder" + parentId
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.responses.setResponse(actionNameForUiDetail, {
			actionName: actionNameForUiDetail,
			status: ActionStatus.loading,
		})
		// 3
		self.readProjTemplateFileFolderDetails(parentId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)

					const fetchedFolders = response.data.ChildFolds
					const fetchedFiles = response.data.TempFiles

					let children: any[] = []

					if (fetchedFolders.length > 0) {
						fetchedFolders.map((subFolder: any, index: number) => {
							const subFolderId = idToString(
								subFolder.FoldId,
								IdType.folder
							)
							let reOrganizedFolder = {
								...initialDLFileAsFolder,
								id: subFolderId,
								parentId,
								isParent: true,
								//
								title: subFolder.FoldName,
								menuId: DLOrgSubMenus.proj_templates,
								objectFormat: DLObjectFormat.folder,
								expanded: false,
								index: subFolder.Index,
							}
							children.push(reOrganizedFolder)
						})
					}

					const formatSize = (FileSize: string) => {
						const kb = FileSize.split("K")[0]
						const bytes = +kb * 1000

						return formatFileSize(bytes) === "0 bytes"
							? "0KB"
							: formatFileSize(bytes)
					}

					// b) Flatten TempFiles
					// (below) fetched file example
					// --------------------
					// Extension: ".xlsx"
					// FileDesc: "1100A 계약전 위험평가표 수임"
					// FileId: 1704
					// FilePath: "Templates/Temp_excel_1100a 계약전 위험평가표 수임_1681100a_150120190425332533.xlsx"
					// FileSize: "230KB"
					// FileType: "Excel"
					// IsQualityControl: false
					// ParentMediaID: 0
					// RolesList: []
					// S3UploadStatus: 2
					// S3Url: null --> S3Url seems null always
					// WPREF: "1100A"
					// url: "" --> pptx, hwp and similar formats have url
					// --------------------
					// NOTE: Project template has no source Id...

					if (fetchedFiles.length > 0) {
						fetchedFiles.map((file: any, index: number) => {
							const fileId = idToString(file.FileId, IdType.file)
							const srcId = idToString(file.FileId, IdType.src) // NOTE: WARNING: Project template use same ID as file ID and source ID

							// modifiedBy does not exist in project template

							let assignedRoles: RoleModelProps[] = []
							file.RolesList.length >= 1 &&
								file.RolesList[0].Roles.map((role: any) => {
									const roleInfo = findProjRoleInfo(role)
									if (roleInfo) {
										assignedRoles.push({
											id: roleInfo.roleId,
											name: roleInfo.name,
											shortName: roleInfo.shortName,
											color: roleInfo.color,
										})
									} else {
										assignedRoles.push({
											id: "roleId" + role,
											name: "(...)",
											shortName: "(...)",
											color: "lightgray",
										})
									}
								})

							const srcFormat = convertSourceFormat(
								file.FileType.toLowerCase(),
								file.Extension.toLowerCase(),
								"get-pt-children-of-folder"
							)
							const objectFormat = isCustomFile(srcFormat)
								? DLObjectFormat.customFile
								: DLObjectFormat.normalFile

							const reOrganizedFile = {
								...initialDLFile,
								id: fileId,
								menuId: DLOrgSubMenus.proj_templates,
								parentId: parentId,
								aliasId: file.WPREF,
								title: file.FileDesc,
								objectFormat,
								//
								srcId, // NOTE: WARNING: Project template use same ID as file ID and source ID
								srcFormat,
								extension: convertExtension(
									file.Extension,
									srcFormat
								),
								size: file.FileSize
									? formatSize(file.FileSize)
									: "-",
								path: file.FilePath, // NOTE: What is different of FilePath and Actual File Path...
								url: file.url,
								uploadStatus:
									file.S3UploadStatus === 3
										? "inProgress"
										: "",
								assignedRoles: assignedRoles,
								index,
							}
							children.push(reOrganizedFile)
						})
					}
					children = sortFoldersNFiles(children)
					self.updateFlatList(children)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get project template file folder details",
						open: false,
						autoHide: true,
					})
					self.responses.setResponse(actionNameForUiDetail, {
						actionName: actionNameForUiDetail,
						status: ActionStatus.success,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetProjTemplateChildrenOfFolder
