import styled from "styled-components"

const StyledUploadFilesForm = styled.div`
	height: 100%;
	.before-start-action {
		height: 100%;
	}
	.file-list {
		height: 100%;
		overflow-y: scroll;
	}
	.signoff-all-toggle-checkbox {
		/* this checkboxes are in table header. purpose: give priority than resizer (without this, resizer override the checkbox)  */
		z-index: 10;
	}
	.separator-area {
		width: 100%;
		background-color: ${(props) => props.theme.secondary};
		padding: 1rem;
		margin-top: 1rem;
		margin-bottom: 1rem;
		&.disabled {
			opacity: 0.6;
		}
		.separator-input {
			width: 100px;
			margin: 0 0.6rem;
			/* padding: 0 0.6rem; */
		}
		.example {
			opacity: 0.4;
			margin-top: 0.4rem;
		}
	}
	.action-buttons {
		.file-upload-btn-wrapper {
			input[type="file"] {
				display: none;
			}
		}
		.total-files-and-size {
			margin-right: 0.6rem;
			.file-count {
				color: ${(props) => props.theme.emphasis};
			}
			.total-size {
				color: ${(props) => props.theme.emphasis};
			}
		}
	}
	.help-text {
		width: 100%;
		color: ${(props) => props.theme.warning};
	}
	.warning {
		background-color: ${(props) => props.theme.shared.messageOrange};
	}
	.error {
		.alias-id-input {
			border: 1px solid ${(props) => props.theme.shared.messageRed};
		}
		.file-name-input {
			border: 1px solid ${(props) => props.theme.shared.messageRed};
		}
	}
	.input-container {
		.alias-id-input,
		.file-name-input {
			border-radius: 3px;
			padding: 4px 8px;
			width: 100%;
			&.empty {
				background-color: ${(props) =>
					props.theme.shared.messageRedAutoHide};
			}
			&.duplicated {
				border: 1px solid ${(props) => props.theme.shared.messageRed};
			}
			&.invalid {
				border: 1px solid purple;
				background-color: ${(props) =>
					props.theme.shared.messageOrangeAutoHide};
			}
			&.exceed {
				border: 1px solid orange;
			}
		}
		.warning-msg {
			font-size: ${(props) => props.theme.shared.textRg};
			color: ${(props) => props.theme.shared.messageRed};
			padding-left: 8px;
		}
	}

	/* .alias-id-input {
		border-radius: 3px;
		padding: 4px 8px;
		&.empty {
			background-color: ${(props) => props.theme.shared.messageRedAutoHide};
		}
		&.duplicated {
			border: 1px solid ${(props) => props.theme.shared.messageRed};
		}
		&.invalid {
			border: 1px solid purple;
			background-color: ${(props) => props.theme.shared.messageOrangeAutoHide};
		}
		&.exceed {
			border: 1px solid orange;
		}
	}
	.file-name-input {
		border-radius: 3px;
		padding: 4px 8px;
		&.empty {
			background-color: ${(props) => props.theme.shared.messageRedAutoHide};
		}
		&.duplicated {
			border: 1px solid ${(props) => props.theme.shared.messageRed};
		}
		&.invalid {
			border: 1px solid purple;
		}
		&.exceed {
			border: 1px solid orange;
		}
	} */
	.oversize {
		background-color: ${(props) => props.theme.shared.messageCoralAutoHide};
	}
	.result-container {
		.result-item {
			padding: 0.6rem;
			.file-info {
			}
			.response-info {
				width: 50%;
				.response-message {
					padding-left: 0.6rem;
				}
				.success-icon {
					color: ${(props) => props.theme.emphasis};
					fill: ${(props) => props.theme.emphasis};
				}
				.failure-icon {
					color: ${(props) => props.theme.themeRed};
					fill: ${(props) => props.theme.emphasis};
				}
			}
		}
		.second-action-btn-area {
			margin-top: 1rem;
			.retry-btn {
			}
		}
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
		}
	}
`

export default StyledUploadFilesForm
