import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { DLButton } from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import LoginBox from "./sub-components/LoginBox"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { mdiAccountCheckOutline, mdiHelp, mdiLockQuestion } from "@mdi/js"
import Icon from "@mdi/react"
import styled from "styled-components"
import { userguideUrl } from "../../../assets/datalobby-info"
import { clearLocalStorageForLobby } from "../../../library/token-utils"

// NOTE: Check the 'src/pages/lobby-side/lobby-access-control` to find login store
export default function LoginScreen({
	actionStatus,
	handleLogin,
}: {
	actionStatus: ActionStatus
	handleLogin: any
}) {
	const frontendDeployInfo = process.env.REACT_APP_FRONT_END_DEPLOY_INFO

	// login button hover
	const [ready, setReady] = useState(false)
	const [isAppAdminLogin, setIsAppAdminLogin] = useState(false)

	useEffect(() => {
		clearLocalStorageForLobby()
	}, [])

	return (
		<StyledLoginScreen className="login-screen-2 FR AC JSB">
			<LoginBox
				ready={ready}
				setReady={setReady}
				handleLogin={handleLogin}
				eleClassName="login-section"
				actionStatus={actionStatus}
				isAppAdminLogin={isAppAdminLogin}
			/>
			{actionStatus !== ActionStatus.loading && (
				<div className="FC icons-container">
					<div className="FR">
						<DLTooltip title="Confirm Sign Up" arrow>
							<Link
								data-testid="confirm-signup-link"
								to="/confirm-signup"
							>
								<div className="icon confirm-signup-icon">
									<Icon
										path={mdiAccountCheckOutline}
										size={1.6}
									/>
								</div>
							</Link>
						</DLTooltip>
						<DLTooltip title="Did you forget your Password?" arrow>
							<Link
								data-testid="forgot-pw-link"
								to="/forgot-password"
							>
								<div
									className="icon forgot-password-icon"
									style={{ paddingTop: 2 }}
								>
									<Icon path={mdiLockQuestion} size={1.4} />
								</div>
							</Link>
						</DLTooltip>
						<DLTooltip title="User Guide" arrow>
							<a
								data-testid="userguide-link"
								href={userguideUrl}
								target="blank"
							>
								<div
									className="icon userguide-icon"
									style={{ paddingTop: 3 }}
								>
									<Icon path={mdiHelp} size={1.3} />
								</div>
							</a>
						</DLTooltip>
					</div>
				</div>
			)}

			<div className="FR JSB footer">
				Served by Data Lobby &nbsp;&nbsp; | &nbsp;&nbsp; Frontend last
				build: {frontendDeployInfo}
				<DLButton
					variant="text"
					eleTestId="app-admin-login-btn"
					color="primary"
					eleClassName="admin-mode-login-btn"
					clickHandler={() => setIsAppAdminLogin(!isAppAdminLogin)}
				>
					Admin Mode Login
				</DLButton>
			</div>
		</StyledLoginScreen>
	)
}

const StyledLoginScreen = styled.div`
	height: 100vh;
	padding: 0;
	padding-left: 6rem;
	padding-right: 6rem;
	.login-section {
		width: 30vw;
		height: 46vh;
		justify-content: space-between;
	}
	.icons-container {
		align-items: flex-end;
		justify-content: flex-end;
		height: 46vh;
		.icon {
			/* opacity: 0.5; */
			margin-left: 1rem;
			transition: 0.4s;
			color: ${(props) => props.theme.emphasisDeep};
		}
	}
	.footer {
		width: 100vw;
		height: 2rem;
		position: absolute;
		bottom: 0;
		display: flex;
		color: ${(props) => props.theme.shade30};
		transition: 0.4s;
		&.ready-txt {
			opacity: 0.1;
		}
		.admin-mode-login-btn {
			margin-right: 6rem;
			color: #9cacff54;
		}
	}
`
