import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import OrgSetupArchivePolicies from "./OrgSetupArchivePolicies"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function OrgSetupArchivePoliciesController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getArchivePolicies"
	const menuId = DLOrgSubMenus.setup_arch_policy
	const i18nSheet = OrgI18n.archPolicy

	// menu access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.archPolicy.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && orgStore.archPolicy.getArchivePolicies()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<OrgSetupArchivePolicies
					partialStore={orgStore.archPolicy}
					fetchingStatus={orgStore.archPolicy.getActionStatus(
						actionName
					)}
					permission={permission}
					contentHeight={store.ui.contentsAreaHeight}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
