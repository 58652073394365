import { types } from "mobx-state-tree"

import OpenedFilesViewModel from "./opened-files.view-model"
import OpenedFilesModel from "./opened-files.model"

import * as openedFileApi from "../../../../service-modules/opened-files-module/opened-files.apis"
import OpenedFilesUiHelper from "./opened-files.ui-helper"

export const initialStore = {
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

export const OpenedFilesStore = ({
	apiReadOpenedFiles,
	apiDeleteOpenedFiles,
	apiCreateRequestNotification,
}: openedFileApi.OpenedFilesApiProps) =>
	types
		.compose(
			OpenedFilesModel({
				apiReadOpenedFiles,
				apiDeleteOpenedFiles,
				apiCreateRequestNotification,
			}),
			OpenedFilesViewModel,
			OpenedFilesUiHelper
		)
		.named("OpenedFilesStore")

const OpenedFilesStoreInstance = OpenedFilesStore({
	apiReadOpenedFiles: openedFileApi.apiReadOpenedFiles,
	apiDeleteOpenedFiles: openedFileApi.apiDeleteOpenedFiles,
	apiCreateRequestNotification: openedFileApi.apiCreateRequestNotification,
}).create(initialStore)

export default OpenedFilesStoreInstance
