import React, { useEffect, useState } from "react"
import styled from "styled-components"
import {
	DLComboBox,
	DLSpinner,
	DLTreeListByTreeData,
	ConsoleLog,
} from "../../../basic-elements"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"

export default observer(function WithTemplate({
	selectedTemplate,
	handleSelectTemplate,
	highlight,
}: {
	selectedTemplate: any
	handleSelectTemplate: any
	highlight: boolean
}) {
	const orgStore = useOrgStore()
	const actionName1 = "getOrgProjTemplateGroups"
	// const actionName2 = "getOrgTemplateDetail"

	useEffect(() => {
		orgStore.orgProjTemplates.getOrgProjTemplateGroups()
	}, [])
	const options = orgStore.orgProjTemplates.templateListToCreateProject

	// const [selectedTemplateId, setSelectedTemplateId] = useState("")

	const handleSelect = (value: any) => {
		// orgStore.orgProjTemplates.getOrgTemplateDetail(value.parentId, value.id)
		// setSelectedTemplateId(value.id)
		ConsoleLog("template value " + value)
		if (value !== null) {
			handleSelectTemplate(value)
		} else {
			handleSelectTemplate(null)
		}
	}

	const getTemplateListStatus =
		orgStore.orgProjTemplates.getActionStatus(actionName1)
	// const getTemplateDetail = orgStore.orgProjTemplates.getActionStatus(
	// 	actionName2
	// )
	return (
		<StyledWithTemplate>
			{getTemplateListStatus === "LOADING" && (
				<div
					className="FR AC JC"
					style={{
						width: "100%",
						minHeight: 200,
						height: "100%",
					}}
				>
					<DLSpinner />
				</div>
			)}
			{getTemplateListStatus === "SUCCESS" && (
				<DLComboBox
					eleName="templateId"
					onChange={handleSelect}
					options={options.sort(
						(a: any, b: any) =>
							-b.parentName.localeCompare(a.parentName)
					)}
					placeholder="Select a template"
					groupBy={(option: any) => option.parentName}
					getOptionLabel={(option: any) => option.title}
					eleTestId="select-proj-template"
				/>
			)}
			{/* {getTemplateDetail === "LOADING" && <DLSpinner />}
				{getTemplateDetail === "SUCCESS" && (
					<div style={{ width: "100%", border: "1px solid red" }}>
						<DLTreeListByTreeData
							treeData={orgStore.orgProjTemplates.templateDetailsAsTree(
								selectedTemplateId
							)}
							eleRenderNode={OrgTemplateDetailItemRenderer}
							eleWidth={"100%"}
						/>
						{orgStore.orgProjTemplates.templateDetailsAsTree(
							selectedTemplateId
						).length === 0 && "No files in the template"}
					</div>
				)} */}
		</StyledWithTemplate>
	)
})

const StyledWithTemplate = styled.div`
	width: 100%;
	height: 100%;
	padding: 1rem;
`
