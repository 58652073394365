import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

const selectedToken = (tokenType: TOKEN_TYPE) => {
	const tokenFromLocalStorage = localStorage.getItem(tokenType)
	if (tokenFromLocalStorage) {
		return JSON.parse(tokenFromLocalStorage)
	}
	return `Failed to find the ${tokenType}`
}

const ReadOrgApTemplateDetails = (self: any) => ({
	readOrgApTemplateDetails: flow(function* (payload) {
		const actionName = "readOrgApTemplateDetails"
		try {
			ConsoleLog(payload)
			const endpoint =
				process.env.REACT_APP_DEV_EXPRESS_ENDPOINT +
				"/AuditProgram_Template.aspx?userid=" +
				payload.userId +
				"&templateid=" +
				payload.templateId +
				"&Lang=" +
				payload.Lang +
				"&token=" +
				selectedToken(TOKEN_TYPE.orgToken)
			const response = {
				status: 200,
				data: {
					Status: 1,
					URL: endpoint,
				},
			}
			// NOTE: Need to update when backend is ready
			// ConsoleLog(actinName, "__response", response)
			return response
		} catch (error) {
			// ConsoleLog(actinName, "__error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadOrgApTemplateDetails
