import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../components/basic-elements"

const MdiscardCheckoutFile = (self: any, delAllNotification: any) => ({
	mDiscardCheckoutFile: flow(function* (url: string, payload: any) {
		const actionName = "mDiscardCheckoutFile"
		ConsoleLog([url, payload])
		try {
			const response = yield delAllNotification(url, payload)
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error, error.response)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default MdiscardCheckoutFile
