import React from "react"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiDrag, mdiChevronUp, mdiChevronDown } from "@mdi/js"
import { DLIconButton, DLSpinnerByIcon } from "../../basic-elements"
import {
	OpenedFolderIcon,
	FolderIcon,
} from "../../basic-elements/icons/common-icons"

type DLTreeNavParentRow2Props = {
	expanded: boolean
	handleExpand: (props: any) => void
	canDrag: boolean
	title: string
	selected: boolean
	noChildren?: boolean
	noChevron?: boolean
	isLoading?: boolean
}

export default function DLTreeNavParentRow2({
	title,
	expanded = false,
	canDrag = false,
	selected = false,
	noChildren,
	handleExpand,
	noChevron,
	isLoading,
}: DLTreeNavParentRow2Props) {
	return (
		<StyledDLTreeNavParentRow2
			className={`FR AC file-folder-row-comp ${selected &&
				"selected-file-folder"} `}
			data-testid="tree-nav-parent-row"
		>
			<div className="left-side FR AC">
				{canDrag && (
					<DLIconButton eleTestId="drag-icon">
						<Icon path={mdiDrag} size={0.8} className="drag-icon" />
					</DLIconButton>
				)}
				<div className="info-area" onClick={handleExpand}>
					{expanded ? (
						<div className="FR AC expand-icons">
							{!noChevron && (
								<DLIconButton
									variant="iconOnly"
									eleTestId="collapse-row"
								>
									<Icon path={mdiChevronUp} size={0.8} />
								</DLIconButton>
							)}
							{isLoading ? (
								<DLSpinnerByIcon />
							) : (
								<OpenedFolderIcon />
							)}
						</div>
					) : (
						<div className="FR AC">
							{!noChevron && (
								<DLIconButton
									variant="iconOnly"
									eleTestId="expand-row"
								>
									<Icon path={mdiChevronDown} size={0.8} />
								</DLIconButton>
							)}
							<FolderIcon />
						</div>
					)}

					<div className="FR AC title-area">
						<div className="title-text">
							{title}
							{expanded && noChildren && !isLoading ? (
								<span className="no-item-text">(No items)</span>
							) : expanded && isLoading ? (
								<span className="folder-loading-text">
									(Loading...)
								</span>
							) : (
								""
							)}
						</div>
					</div>
				</div>
			</div>
		</StyledDLTreeNavParentRow2>
	)
}

const StyledDLTreeNavParentRow2 = styled.div`
	width: 100%;
	.left-side {
		width: calc(100% - 200px);
		.drag-icon {
		}
		.info-area {
			cursor: pointer;
			.title-area {
				width: 100%;
				.title-text {
					/* margin-left: 0.6rem; */
					margin-right: 2rem;
					span.no-item-text,
					span.folder-loading-text {
						opacity: 0.6;
						margin-left: 0.4rem;
					}
				}
				&.edit-mode {
					margin-left: 0.6rem;
				}
			}
		}
	}
	&.selected-file-folder {
		background-color: pink;
	}
`
