import React from "react"
import { observer } from "mobx-react-lite"
import SimpleMDE from "react-simplemde-editor"
import { DLInputField } from "../../../components/basic-elements"
import { DLCheckbox } from "@datalobby/components"
import "easymde/dist/easymde.min.css"
import styled from "styled-components"

export default observer(function PostForm({
	post,
	setPost,
}: {
	post: any
	setPost: any
}) {
	return (
		<StyledPostForm>
			<DLInputField
				eleTestId="post-title-input"
				eleName="title"
				eleLabel="Title"
				eleValue={post.title}
				elePlaceholder="Please input title"
				eleHandleChange={(e: any) =>
					setPost({ ...post, title: e.target.value })
				}
			/>
			<div className="other-settings FR AC">
				<div className="other-setting">
					<DLCheckbox
						eleTestId="post-fixToTop-checkbox"
						isChecked={post.fixToTop}
						labelText="Fix to Top"
						clickHandler={() =>
							setPost({ ...post, fixToTop: !post.fixToTop })
						}
					/>
				</div>
				{/* <div className="other-setting priority FR AC">
					<span className="label">Priroity: </span>
					<DLSingleSelect
						eleTestId="post-priority-select"
						eleOnChange={(e: any) =>
							setPost({ ...post, priority: e.target.value })
						}
						eleName="priority"
						eleValue={post.priority}
						minHeight="auto"
						optionList={[
							{ value: Priority.high, label: "High" },
							{ value: Priority.medium, label: "Medium" },
							{ value: Priority.low, label: "Low" },
						]}
						withLabel={false}
					/>
				</div> */}
				{/* <div className="other-setting who-can-read FR AC">
					<span className="label">Who can read: </span>
					<DLSingleSelect
						eleTestId="post-who-can-read"
						eleOnChange={(e: any) =>
							setPost({ ...post, whoCanRead: e.target.value })
						}
						eleName="whoCanRead"
						eleValue={post.whoCanRead}
						withLabel={false}
						minHeight="auto"
						optionList={[
							{
								value: WhoCanRead.all,
								label: "Who can access this menu",
							},
							{
								value: WhoCanRead.internal,
								label: "Internal Users Only",
							},
							{
								value: WhoCanRead.allowedRoles,
								label: "Allowed Roles Only",
							},
							{
								value: WhoCanRead.allowedUsers,
								label: "Allowed Users Only",
							},
						]}
					/>
				</div> */}
			</div>

			<SimpleMDE
				className="md-editor"
				value={post.content}
				onChange={(value) => setPost({ ...post, content: value })}
				data-testid="post-md-editor"
			/>
		</StyledPostForm>
	)
})

const StyledPostForm = styled.div`
	.other-settings {
		height: 3rem;
		/* margin-bottom: 0.4rem; */
		.other-setting {
			margin-right: 2rem;
			span.label {
				margin-right: 0.4rem;
			}
		}
	}
	.md-editor {
		.CodeMirror {
			height: 300px;
			/* overflow: auto; */
		}
	}
`
