import React from "react"
import styled from "styled-components"
import { DLIconButton } from "../../basic-elements"
import { Icon } from "@mdi/react"
import {
	mdiBookMultiple,
	mdiDrag,
	mdiPencil,
	mdiTrashCan,
	mdiContentCopy,
	mdiDotsHorizontal,
	mdiFolderMove,
} from "@mdi/js"
import { ActionProps } from "../../../common-models/types/folder-row-rendering"
import { PermissionAsObjectProps } from "../../../common-models/permission"

// TODO: action part needs update
type RowActionProps = {
	edit?: ActionProps
	remove?: ActionProps
	duplicate?: ActionProps
	move?: ActionProps
}

type DLTreeNavChildRowProps = {
	canDrag: boolean
	title: string
	selected: boolean
	showButtons: boolean
	actions?: RowActionProps
	permission: PermissionAsObjectProps
	icon?: React.ReactElement
}

export default function DLTreeNavChildRow({
	canDrag = false,
	title,
	selected = false,
	showButtons = true,
	permission,
	actions,
	icon,
}: DLTreeNavChildRowProps) {
	const getTestId = (source: string) => {
		return source.split(" ").join("-")
	}
	return (
		<StyledChildRow
			className={`FR AC JSB file-row ${selected && "selected"}`}
		>
			<div className="left-side">
				{canDrag && (
					<Icon path={mdiDrag} size={0.8} className="drag-icon" />
				)}
				{icon ? (
					icon
				) : (
					<div className="row-icon">
						<Icon path={mdiBookMultiple} size={0.7} />
					</div>
				)}

				<div className="title-text">{title}</div>
			</div>
			<div className="right-side">
				{showButtons && actions && (
					<div className="buttons-container FR AC">
						{permission.update && actions.edit && (
							<DLIconButton
								eleTestId={getTestId(actions.edit.tooltipText)}
								tooltipText={actions.edit.tooltipText}
								clickHandler={actions.edit.action}
							>
								<Icon path={mdiPencil} size={0.7} />
							</DLIconButton>
						)}
						{permission.create && actions.duplicate && (
							<DLIconButton
								eleTestId={getTestId(
									actions.duplicate.tooltipText
								)}
								tooltipText={actions.duplicate.tooltipText}
								clickHandler={actions.duplicate.action}
							>
								<Icon path={mdiContentCopy} size={0.7} />
							</DLIconButton>
						)}
						{permission.delete && actions.remove && (
							<DLIconButton
								eleTestId={getTestId(
									actions.remove.tooltipText
								)}
								tooltipText={actions.remove.tooltipText}
								clickHandler={actions.remove.action}
							>
								<Icon path={mdiTrashCan} size={0.7} />
							</DLIconButton>
						)}
						{permission.move && actions.move && (
							<DLIconButton
								eleTestId={getTestId(actions.move.tooltipText)}
								tooltipText={actions.move.tooltipText}
								clickHandler={actions.move.action}
							>
								<Icon path={mdiFolderMove} size={0.7} />
							</DLIconButton>
						)}
					</div>
				)}
			</div>
		</StyledChildRow>
	)
}

const StyledChildRow = styled.div`
	width: 100%;
	.left-side {
		.drag-icon {
		}
		.title-text {
			margin-left: 0.6rem;
		}
	}
	.right-side {
		.buttons-container {
			opacity: 0;
			transition: 0.4s;
		}
	}
	:hover {
		.buttons-container {
			opacity: 1;
		}
	}
	&.selected {
		background-color: pink;
	}
`
