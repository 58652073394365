import React, { useState } from "react"
import { useHistory } from "react-router-dom"
import Icon from "@mdi/react"
import {
	mdiDotsVertical,
	mdiBellOutline,
	mdiMagnify,
	mdiDomain,
	mdiHelpCircleOutline,
} from "@mdi/js"
// sub components
import DLMenusWithMultipleAction from "../../../../components/basic-elements/menus/DLMenusWithMultipleAction"
import { IconPlaceholder } from "../../../basic-elements/icons/common-icons"
import { ConsoleLog } from "../../../basic-elements"
import LangaugeSelectionDialog from "./LanguageSelectionDialog"
import { userguideUrl } from "../../../../assets/datalobby-info"

type HeaderMobileMenusProps = {
	setRightDrawerOpen?: any
}

export default function HeaderMobileMenus({
	setRightDrawerOpen,
}: HeaderMobileMenusProps) {
	const history = useHistory()

	const [open, setOpen] = useState(false)

	const helpHandler = () => {
		window.open(userguideUrl)
	}

	const handleLogout = () => {
		// store.checkin.logout()
		history.replace("/lobby-checkout")
	}

	const menuOptions = [
		{
			label: "Search",
			value: "search",
			available: true,
			isLink: true,
			linkTo: "/organization/search",
			clickEvent: () => ConsoleLog("Mobile, Search", ""),
			icon: <Icon path={mdiMagnify} size={0.8} />,
		},
		{
			label: "Back to Lobby",
			value: "",
			available: true,
			isLink: true,
			linkTo: "/organization/checkout",
			clickEvent: () => ConsoleLog("Mobile, Back to Lobby", ""),
			icon: <Icon path={mdiDomain} size={0.8} />,
		},
		{
			label: "Languages",
			value: "",
			available: true,
			clickEvent: () => setOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Notifications",
			value: "",
			available: true,
			clickEvent: setRightDrawerOpen,
			icon: <Icon path={mdiBellOutline} size={0.8} />,
		},
		{
			label: "Help",
			value: "",
			available: true,
			clickEvent: helpHandler,
			icon: <Icon path={mdiHelpCircleOutline} size={0.8} />,
		},
		{
			label: "My Account",
			value: "myAccount",
			available: true,
			isLink: true,
			linkTo: "/organization/my-page/my-information",
			clickEvent: () => ConsoleLog("Mobile, My Account", ""),
			icon: <IconPlaceholder />,
		},
		{
			label: "Logout",
			value: "logout",
			available: true,
			disabled: false,
			clickEvent: handleLogout,
			icon: <IconPlaceholder />,
		},
		{
			label: "Profile Menues",
			value: "",
			clickEvent: () => ConsoleLog("Mobile, Profile Menu", ""),
			icon: <div></div>,
		},
	]
	return (
		<>
			<DLMenusWithMultipleAction
				remoteButton={<Icon path={mdiDotsVertical} size={1} />}
				menuOptions={menuOptions}
				eleTestId="header-mobile-menus"
			/>
			{open && <LangaugeSelectionDialog open={open} setOpen={setOpen} />}
		</>
	)
}
