import { ActionStatus } from "@datalobby/types/lib"

const EditPost = (self: any) => ({
	editPost(post: any, postAction?: any) {
		const actionName = "editPost"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.updatePost(post)
			.then((response: any) => {
				console.log(actionName, "/ response", response)
				if (response.data?.updatePost !== null) {
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
					postAction && postAction()
				} else {
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.fail,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditPost
