import React from "react"
import styled from "styled-components"

interface StyledReactTableProps {
	readonly tableBodyHeight?: number
	readonly rowHeight?: number
}

const StyledReactTable = styled.div<StyledReactTableProps>`
	/* Hierarchy: ReactTable ---> rt-table ---> rt-.rt-thead & rt-tbody */
	.ReactTable {
		border: none;
		height: 610px;

		.dl-disabled {
			opacity: 0.3;
			cursor: not-allowed;
		}
		.dl-disabled-am-project {
			opacity: 0.7;
			// cursor: not-allowed;
		}
		.dl-disabled-project {
			opacity: 0.3;
			// cursor: not-allowed;
		}
		.rt-tr {
			/* for multiple lines contents.. ex. comment */
			height: ${(props) =>
				props.rowHeight ? props.rowHeight + "px" : "auto"};
			display: flex;
			flex-direction: row;
			align-items: center;
			.rt-th {
				text-align: left;
				font-size: 0.8rem;
				border-right: none;
				.sorting-icon {
					fill: transparent;
					transition: 0.2s;
				}
				:hover {
					.sorting-icon {
						fill: ${(props) => props.theme.shade40};
					}
				}
				&.-sort-asc {
					box-shadow: none;
					color: ${(props) => props.theme.emphasis};
					.sorting-icon {
						fill: ${(props) => props.theme.emphasis};
						transform: rotate(180deg);
					}
				}
				&.-sort-desc {
					box-shadow: none;
					color: ${(props) => props.theme.emphasis};
					.sorting-icon {
						fill: ${(props) => props.theme.emphasis};
					}
				}
			}
		}
	}
	.ReactTable .rt-thead.-header {
		box-shadow: none;
		border-bottom: 1px solid ${(props) => props.theme.shade40};
		color: ${(props) => props.theme.shade40};
		padding: 0;
		.rt-th {
			.table-partition {
				width: calc(100% + 20px);
				margin-left: -8px;
				background-color: ${(props) => props.theme.background};
				height: 152vh;
			}
		}
	}
	.ReactTable .rt-thead.-filters {
		input {
			border: none;
			font-size: 14px;
			color: ${(props) => props.theme.shade60};
			fill: ${(props) => props.theme.shade60};
			background-color: ${(props) => props.theme.background};
		}
		select {
			background-color: transparent;
			color: ${(props) => props.theme.shade60};
		}
	}
	.ReactTable .rt-table .rt-tbody div[role="rowgroup"].selected {
		background-color: ${(props) => props.theme.shade20};
	}
	.ReactTable .rt-tbody {
		height: ${(props) => props.tableBodyHeight}px;
		padding-bottom: 1rem;
		overflow-y: auto;
		overflow-x: hidden;
		.rt-tr-group {
			border-bottom: 1px solid ${(props) => props.theme.shade05};
			.row-disabled {
				color: ${(props) => props.theme.shade05};
			}
			transition: 0.4s;
			margin: 0;
			&:hover {
				background-color: ${(props) => props.theme.shade05};
			}
		}
		.rt-td {
			display: flex;
			align-items: center;
			border-right: none;
			/* for multiple lines contents.. ex. comment */
			height: auto;
			transition: 0.4s;
			& a {
				font-weight: 500;
				color: ${(props) => props.theme.emphasis};
				&:hover {
					color: ${(props) => props.theme.emphasisDeep};
				}
			}
			& .btn-cell {
				font-weight: 500;
				cursor: pointer;
				&:hover {
					color: ${(props) => props.theme.emphasisDeep};
				}
			}
			.selected {
				background-color: ${(props) => props.theme.emphasis};
			}
		}
	}
	.ReactTable .-pagination {
		box-shadow: none;
		border-top: 1px solid ${(props) => props.theme.shade20};
		padding: 0.5rem 0rem;
		color: ${(props) => props.theme.shade40};
		.-previous {
			.-btn {
				background-color: transparent;
				color: ${(props) => props.theme.shade20};
				:hover {
					color: ${(props) => props.theme.emphasis};
					font-weight: var(--font-bold);
					border: 1px solid ${(props) => props.theme.emphasis};
					background-color: transparent;
				}
			}
		}
		.-next {
			.-btn {
				background-color: transparent;
				color: ${(props) => props.theme.shade20};
				:hover {
					color: ${(props) => props.theme.emphasis};
					font-weight: var(--font-bold);
					border: 1px solid ${(props) => props.theme.emphasis};
					background-color: transparent;
				}
			}
		}
		.-pageInfo {
		}
		input {
			background-color: transparent;
			color: ${(props) => props.theme.textHighEmphasis};
		}
		select {
			background-color: transparent;
			color: ${(props) => props.theme.shade60};
		}
	}
`

export default StyledReactTable
