import React from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLButton, DLDialog } from "../../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiLockOpenVariantOutline } from "@mdi/js"
import { DialogOnOffProps } from "../../../../../../common-models/types/dialog.props"

export default observer(function ProjectUnLockDialog({
    open,
    setOpen,
}: DialogOnOffProps) {
    // default settings
    const projStore = useProjStore()
    const actionName = "setProjectUnLock"

    const handleProjectUnLock = () => {
        projStore.archive.setProjectUnLock(() => {
            projStore.projInfo.updateProjectLock(false)
            setOpen(false)
        })
    }

    return (
        <div>
            <DLDialog
                eleTestId="project-unlock-dialog"
                dialogTitle="Project Un-lock"
                dialogContents={<ProjectUnLockDialogContents />}
                actionBtn={
                    <DLButton
                        startIcon={
                            <Icon path={mdiLockOpenVariantOutline} size={0.8} />
                        }
                        eleTestId="project-un-lock-btn"
                        clickHandler={handleProjectUnLock}
                    >
                        Project Un-lock
                    </DLButton>
                }
                openBtn={
                    <DLButton
                        eleTestId="project-un-lock-btn"
                        startIcon={
                            <Icon path={mdiLockOpenVariantOutline} size={0.8} />
                        }
                    >
                        Project Un-lock
                    </DLButton>
                }
                showSpinner={
                    projStore.archive.getActionStatus(actionName) === "LOADING"
                        ? true
                        : false
                }
                showCloseBtn={true}
                cancelBtnText="cancel"
            />
        </div>
    )
})

const ProjectUnLockDialogContents = () => {
    return <div>Do you really want to un-lock this project?</div>
}
