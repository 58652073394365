import React, { useState } from "react"
import Icon from "@mdi/react"
import { mdiEye, mdiEyeOffOutline, mdiLoading, mdiPowerStandby } from "@mdi/js"
import useForm from "../../../../library/use-form"
import sharedRegEx from "../../../../library/sharedRegEx"
import styled from "styled-components"
import { DLSystemMsg } from "../../../../components/basic-elements"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

const customerName = process.env.REACT_APP_CUSTOMER_NAME

export default function LoginBox({
	ready,
	setReady,
	handleLogin,
	eleClassName,
	actionStatus,
	isAppAdminLogin
}: {
	ready: boolean
	setReady: any
	handleLogin: any
	eleClassName?: string
	actionStatus: ActionStatus
	isAppAdminLogin: boolean
}) {
	const inputsSchema = {
		email: { value: "", error: "", requestInput: false },
		password: { value: "", error: "", requestInput: false },
		adminPassword: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email,
				error: "Invalid format",
			},
		},
		password: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.dlPassword,
				error: "Invalid format",
			},
		},
		adminPassword: {
			isRequired: isAppAdminLogin,
			// validator: {
			// 	regEx: sharedRegEx.dlPassword,
			// 	error: "Invalid format",
			// },
		},
	}

	const handleSignin = () => {
		handleLogin({
			username: inputs.email.value,
			password: inputs.password.value,
			adminPassword: inputs.adminPassword.value,
			isAppAdminLogin
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		handleSignin
	)

	const handleOnKeyDown = (event: any) => {
		if (event.keyCode === 13) {
			// 13 is enter key
			handleSignin()
		}
	}

	const [hidePw, setHidePw] = useState(true)
	const [hideAdminPw, setHideAdminPw] = useState(true)

	const emailWarning = inputs.email.error
	const pwWarning = inputs.password.error
	const adminPwWarning = inputs.adminPassword.error

	return (
		<StyledLoginBox
			onKeyDown={handleOnKeyDown}
			className={`FC ${ready ? "ready-box" : ""} ${eleClassName}`}
		>
			<div className="title-container">
				<div className={`customer-title ${ready ? "ready-title" : ""}`}>
					{customerName ? customerName : "Audit Lobby"}
				</div>
				<div className="product-title">Audit Lobby</div>
			</div>

			<div className="inputs-container">
				<div className="email-container">
					<input
						data-testid="email-input-field"
						onChange={handleOnChange}
						value={inputs.email.value}
						name="email"
						className="input-field email-field"
						placeholder="Email"
						id="email-field-id"
						autoFocus
					/>
					{emailWarning && (
						<DLSystemMsg
							msg={emailWarning}
							type={MessageColorType.orange}
							eleTestId="msg-login-email-field"
						/>
					)}
					{inputs.email.value === "" && ready && (
						<DLSystemMsg
							msg={"This field is required"}
							type={MessageColorType.red}
							eleTestId="msg-login-email-field"
						/>
					)}
				</div>
				<div>
					<div className="pw-container FR AC JSB">
						<input
							data-testid="password-input-field"
							onChange={handleOnChange}
							value={inputs.password.value}
							name="password"
							className="input-field pw-field"
							placeholder="Password"
							type={hidePw ? "password" : ""}
						/>
						<div
							onClick={() => setHidePw(!hidePw)}
							className="on-off-pw"
						>
							{hidePw ? (
								<Icon path={mdiEyeOffOutline} size={0.7} />
							) : (
								<Icon path={mdiEye} size={0.7} />
							)}
						</div>
					</div>
					{pwWarning && (
						<DLSystemMsg
							msg={
								"More than 8 characters with capital, small letter, number, special characters"
							}
							type={MessageColorType.orange}
							eleTestId="msg-login-pw-field"
						/>
					)}
					{inputs.password.value === "" && ready && (
						<DLSystemMsg
							msg={"This field is required"}
							type={MessageColorType.red}
							eleTestId="msg-login-pw-field"
						/>
					)}
				</div>
				{isAppAdminLogin && (
					<div>
						<div className="pw-container FR AC JSB">
							<input
								data-testid="admin-password-input-field"
								onChange={handleOnChange}
								value={inputs.adminPassword.value}
								name="adminPassword"
								className="input-field admin-pw-field"
								placeholder="Admin Password"
								type={hideAdminPw ? "password" : ""}
							/>
							<div
								onClick={() => setHideAdminPw(!hideAdminPw)}
								className="on-off-pw"
							>
								{hideAdminPw ? (
									<Icon path={mdiEyeOffOutline} size={0.7} />
								) : (
									<Icon path={mdiEye} size={0.7} />
								)}
							</div>
						</div>
						{adminPwWarning && (
							<DLSystemMsg
								msg={
									"More than 8 characters with capital, small letter, number, special characters"
								}
								type={MessageColorType.orange}
								eleTestId="msg-login-pw-field"
							/>
						)}
						{inputs.adminPassword.value === "" && ready && (
							<DLSystemMsg
								msg={"This field is required"}
								type={MessageColorType.red}
								eleTestId="msg-login-pw-field"
							/>
						)}
					</div>
				)}
			</div>
			<div
				className={
					"login-button-container FR " + (isReady ? "ready" : "")
				}
				data-testid="login-button"
				onClick={
					isReady && actionStatus !== ActionStatus.loading
						? handleOnSubmit
						: undefined
				}
				onMouseEnter={() => setReady(true)}
				onMouseLeave={() => setReady(false)}
			>
				{actionStatus === ActionStatus.loading ? (
					<Icon
						className="loading-power-icon"
						path={mdiLoading}
						size={2.5}
					/>
				) : (
					<Icon
						className="power-icon"
						path={mdiPowerStandby}
						size={2.5}
					/>
				)}
			</div>
		</StyledLoginBox>
	)
}

const StyledLoginBox = styled.div`
	.title-container {
		.customer-title {
			font-size: 2rem;
			transition: 0.4s;
			color: ${(props) => props.theme.emphasisDeep};
			&.ready-title {
			}
		}
		.product-title {
			font-weight: 100;
			font-size: 0.9rem;
			opacity: 0.5;
		}
	}
	.inputs-container {
		width: 90%;
		.input-field {
			width: 24vw;
			min-width: 160px;
			border: none;
			transition: 0.4s;
			border-bottom: 1px solid ${(props) => props.theme.secondaryDeep};
			padding: 0.6rem 0;
			outline: none;
			:-webkit-autofill {
				-webkit-box-shadow: 0 0 0px 1000px white inset;
			}
			:focus {
				border: none;
				border-bottom: 1px solid ${(props) => props.theme.emphasisDeep};
			}
		}

		.email-container {
			.input-field.email-field {
			}
		}
		.pw-container {
			.input-field.pw-field {
			}
			margin-top: 1rem;
			.on-off-pw {
				padding-right: 2rem;
				transition: 0.4s;
				opacity: 0.4;
				cursor: pointer;
				:hover {
					opacity: 1;
				}
			}
		}
	}
	.login-button-container {
		transition: 0.4s;
		width: 4rem;
		margin-left: -9px;
		color: ${(props) => props.theme.secondaryDeep};
		&.ready {
			color: ${(props) => props.theme.emphasisDeep};
		}
		&.ready:hover {
			cursor: pointer;
		}
		.loading-power-icon {
			-webkit-animation: spin 1.2s linear infinite;
			-moz-animation: spin 1.2s linear infinite;
			animation: spin 1.2s linear infinite;
		}
		@-moz-keyframes spin {
			100% {
				-moz-transform: rotate(360deg);
			}
		}
		@-webkit-keyframes spin {
			100% {
				-webkit-transform: rotate(360deg);
			}
		}
		@keyframes spin {
			100% {
				-webkit-transform: rotate(360deg);
				transform: rotate(360deg);
			}
		}
	}
`
