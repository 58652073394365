import React, { useEffect } from "react"
import { Link, Redirect } from "react-router-dom"
import {
	DLSpinner,
	ConsoleLog,
	DLButton,
} from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"

const LobbyCheckout = observer(() => {
	const store = useRootStore()

	const logoutStatus = store.checkin.getActionStatus("logout")
	const loginStatus = store.checkin.getActionStatus("login")
	console.log("logoutStatus 1:", logoutStatus)
	console.log("~~~loginStatus 1:", loginStatus)

	useEffect(() => {
		ConsoleLog("========== LOBBY CHECKOUT ==========")
		// NOTE: initializeStore is late to update this login status.
		// WARNING: So.. this setReponse for login is required in here
		store.checkin.responses.setResponse("login", {
			actionName: "login",
			status: ActionStatus.fail,
		})

		store.checkin.logout()
	}, [])

	return (
		<div>
			{logoutStatus === "SUCCESS" ? (
				<Redirect to="/" />
			) : logoutStatus === "LOADING" ? (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			) : logoutStatus === undefined && loginStatus === undefined ? (
				<Redirect to="/" />
			) : (
				<div className="FC AC JC" style={{ padding: 16 }}>
					<div>
						Something wrong. If this screen is remained, please
						report to the administrator. @Venu, please let me know
						if you meet this screen.
					</div>
					<div style={{ marginTop: "2rem" }}>
						<DLButton eleTestId="back-to-login-btn">
							<Link to="/"> Back to Login</Link>
						</DLButton>
					</div>
				</div>
			)}
		</div>
	)
})

export default LobbyCheckout
