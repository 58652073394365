import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import OrgSetupEngTypes from "./OrgSetupEngTypes"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function OrgSetupEngTypesController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_eng_types
	const actionName = "getEngTypeList"
	const i18nSheet = OrgI18n.engType
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.setupEngTypes.needRefresh

	const orgIdForCdkBackend = "org" + orgStore.checkin.orgInfo.orgId.slice(5)
	useEffect(() => {
		if (menuAccess && needRefresh) {
			orgStore.setupEngTypes.getEngTypeList()
			// orgStore.setupEngTypes.getChecklistTemplates(orgIdForCdkBackend)
			// orgStore.setupEngTypes.getChecklistConnections({
			// 	orgId: orgIdForCdkBackend,
			// })
		}
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<OrgSetupEngTypes
					partialStore={orgStore.setupEngTypes}
					fetchingStatus={orgStore.setupEngTypes.getActionStatus(
						actionName
					)}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
