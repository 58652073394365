import React, { ReactElement } from "react"
import styled from "styled-components"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import {
	mdiCheckboxMarkedCircleOutline,
	mdiAlertCircleOutline,
	mdiAlert,
	mdiAlertDecagram,
	mdiInformationVariant,
} from "@mdi/js"

interface DLSystemMsgProps {
	msg: string | ReactElement
	/** DEFAULT | BLUE | ORANGE | CORAL | RED */
	type?: MessageColorType
	/** alert */
	eleRole?: string
	eleTestId?: string
}
const DLSystemMsg = ({
	msg = "",
	type = MessageColorType.default,
	eleRole = undefined,
	eleTestId = "testId-DLSystemMsg",
}: DLSystemMsgProps) => {
	return (
		<StyledDLSystemMsg
			className={`FR AC ${type}`}
			role={eleRole}
			data-testid={eleTestId}
		>
			{type === MessageColorType.default ? (
				<Icon
					path={mdiInformationVariant}
					size={0.7}
					className="icon"
				/>
			) : type === MessageColorType.blue ? (
				<Icon
					path={mdiCheckboxMarkedCircleOutline}
					size={0.7}
					className="icon"
				/>
			) : type === MessageColorType.orange ? (
				<Icon
					path={mdiAlertCircleOutline}
					size={0.7}
					className="icon"
				/>
			) : type === MessageColorType.coral ? (
				<Icon path={mdiAlert} size={0.7} className="icon" />
			) : type === MessageColorType.red ? (
				<Icon path={mdiAlertDecagram} size={0.7} className="icon" />
			) : (
				""
			)}
			{msg}
		</StyledDLSystemMsg>
	)
}

export default DLSystemMsg

export const NoSpecialChar2Msg = () => (
	<div>
		{`* |`} &#92; {`/ "`} &#96; {`: < > { } ? _ are not allowed.`}
	</div>
)

const StyledDLSystemMsg = styled.div`
	&.BLUE {
		color: ${(props) => props.theme.shared.messageBlue};
		svg {
			fill: ${(props) => props.theme.shared.messageBlue};
		}
	}
	&.ORANGE {
		color: ${(props) => props.theme.shared.messageOrange};
		svg {
			fill: ${(props) => props.theme.shared.messageOrange};
		}
	}
	&.CORAL {
		color: ${(props) => props.theme.shared.messageCoral};
		svg {
			fill: ${(props) => props.theme.shared.messageCoral};
		}
	}
	&.RED {
		color: ${(props) => props.theme.shared.messageRed};
		svg {
			fill: ${(props) => props.theme.shared.messageRed};
		}
	}
	.icon {
		margin-right: 0.3rem;
		flex-shrink: 0;
	}
`
