// Transparency hexadecimal code for hexcode format color
export const transparency = {
	p05: "0d",
	p10: "33",
	p20: "33",
	p30: "4d",
	p35: "59",
	p40: "66",
	p50: "80",
	p60: "99",
	p70: "b3",
	p80: "cc",
	p85: "d9",
	p90: "e6",
}

export const shadowPosition = {
	regular: "0px 2px 10px 1px ",
	left: "-2px 0px 10px 1px ",
	right: "2px 0px 10px 1px ",
}
