import React, { useEffect, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { initializeProjStores } from "../../../stores/initialize-stores"
import { DLSpinner, ConsoleLog } from "../../../components/basic-elements"
import styled from "styled-components"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import keyboardJS from "keyboardjs"

export default observer(function ProjCheckout() {
	const projStore = useProjStore()

	const [checkout, setCheckout] = useState(false)

	let location: { state: { from: any } } = useLocation()
	let from: any
	if (location?.state) {
		from = location.state.from
	}

	let openedFiles: any

	useEffect(() => {
		ConsoleLog(
			"========== PROJECT CHECKOUT && REMOVE RELATED ITEMS =========="
		) // TODO: close opened workpapers? or...
		projStore.checkin.setProjCheckin(ActionStatus.loading)
		localStorage.removeItem("projTokens")
		if (openedFiles) {
			alert(
				"Some files are opened. Do you really want to checkout from this project?"
			)
		}
		keyboardJS.reset()
		keyboardJS.pause()

		// * remove related itmes
		// localStorage.removeItem("latestProjId")
		// localStorage.removeItem("latestProjToken")
		// NOTE: Do not remove the projTokens...
		//
		// * initialize project store
		// <Project store list>
		// ! - checkin (! important)
		//
		// - default
		// - ui
		// - menuList
		// - projInfo
		// - notes
		// - comments
		// - wp
		// - pf
		// - library
		// - archive
		// - archiveHistory
		// - rationales
		// - signOff
		// - projUsers
		// - roles
		// - trash
		// ------------

		// ------------
		initializeProjStores(projStore)
		// update the action status
		// NOTE: DO NOT USE ActionStatus.fail here...
		// projStore.checkin.setProjCheckin(ActionStatus.fail)
		// NOTE: Initialize checkin store is mandatory
		// WARNING: DO THIS AT THE END!
		projStore.checkin.initializeStore()
		setCheckout(true)
	}, [])

	return (
		<div>
			{checkout ? (
				<Redirect to="/organization/my-page/recent" />
			) : (
				<StyledProjAuthScreen>
					<DLSpinner />
				</StyledProjAuthScreen>
			)}
		</div>
	)
})

const StyledProjAuthScreen = styled.div`
	z-index: 999; /* for this z-index, sidebar style has z-index: 998 */
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	/* background-color: {props => props.theme.background} */
	/*  TODO: progress bar component needs to update */
`
