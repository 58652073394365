import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../../components/basic-elements"

const orgRoleDetails: any[] = []

const ReadOrgRoleDetails = (self: any) => ({
	readOrgRoleDetails: flow(function* (roleId?) {
		try {
			let response: any
			if (roleId) {
				response = orgRoleDetails.find(
					(element) => element.roleId === roleId
				)
			} else {
				response = orgRoleDetails
			}
			return response
		} catch (error) {
			ConsoleLog(["readOrgRoleDetails error", error])
			return false
		}
	}),
})

export default ReadOrgRoleDetails
