// ---------- common models
import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import { getProjectStatus } from "../../../../../library/get-project-status"
import { ConsoleLog } from "../../../../../components/basic-elements"

const OrgSearch = (self: any) => ({
	orgSearch({
		type,
		query,
	}: {
		type: "group" | "cabinet" | "client"
		query: string
	}) {
		// 0.
		const actionName = "getOrgSearch"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.setSearchType(type)
		let params = {
			// UserID: localStorage.getItem("orgUserId"),
			Type: type,
			EnteredText: query,
			// Lang: en
		}
		self.requestOrgSearch(params)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT
					 */
					// set success case response
					if (type === "group") {
						const fetchedGroups = response.data.Grplst
						let newGroupList: any[] = []
						fetchedGroups.map((group: any) => {
							const reOrganizedGroup = {
								id: idToString(group.GroupID, IdType.group),
								title: group.GroupName,
								parentId: null,
								createdBy: group.CreatedUser, // NOTE:
								createdAt: group.CreatedDate,
								expanded: false,
								type: DLObjectFormat.group,
								detail: null,
							}
							newGroupList.push(reOrganizedGroup)
							// self.pushItemToList(reOrganizedGroup)
						})
						// self.setGroupList(newGroupList)
					}
					if (type === "client") {
						const projects = response.data.ClientDetails
						self.resetList()
						projects.map((item: any) => {
							const reOrganizedProject = {
								id: idToString(item.ClientID, IdType.project),
								title: item.LinkName,
								projectStatus: getProjectStatus(
									item.ProjectType,
									item.IsLocked
								), // ex) "Normal" (projectType: 1)
								isLocked: item.IsLocked,
								aliasId: item.ProjectBusinessId,
								accessRight: item.AccessRights,
								archived: item.Archive,
								archivedBy: {
									id: idToString(
										item.ArchivedBy,
										IdType.user
									),
									name: item.ArchivedByUser,
								},
								archivedAt: item.ProjectArchivedDate,
								createdBy: {
									id: idToString(
										item.ClientMasterID,
										IdType.client
									),
									name: item.CreatedUser,
								},
								client: {
									id: idToString(
										item.ClientID,
										IdType.project
									),
									name: item.ClientMasterName,
								},
								expirationDate: item.ExpirationDate,
								financialYear: item.Year,
								periodEndDate: item.FinancialYear,
								period: item.PeriodName,
								assignedRole: item.RoleName,
								version: item.Version,
								accessible: !item.isGrayout,
							}
							self.pushItemToProjects(reOrganizedProject)
						})
					}
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get the search result",
						open: false,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage: "(TODO) Something Wrong.. ",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default OrgSearch
