// ---------- common models
import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { LibHierarchy, normalLibFilePrefix } from "../lib-in-proj.data-model"
import { convertSourceFormat } from "../../../../../library/converters/source-format-converter"
import { ConsoleLog } from "../../../../../components/basic-elements"

const GetLibFilesInProj = (self: any) => ({
	getLibFilesInProj(folderId: string) {
		// 0.
		const actionName = "getLibFilesInProj"
		const actionNameForUi = "getLibFilesInProj_" + folderId
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		self.responses.setResponse(actionNameForUi, {
			actionName: actionNameForUi,
			status: ActionStatus.loading,
		})
		// 3.
		ConsoleLog([actionName, "folderId", folderId])
		self.readLibFilesInProj(folderId)
			.then((response: any) => {
				// WARNING:  NOTE:  TODO: this API does not return the status value...
				if (response.status === 200 && response.data.length !== 0) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const fetchedFiles = response.data
					fetchedFiles.map((file: any) => {
						// MediaType: "Word"
						// Message: null
						// Status: 0
						// WPRFileID: 363
						// WPRFileName: "mtech"
						const newFile = {
							parentId: folderId,
							id:
								normalLibFilePrefix +
								idToString(file.WPRFileID, IdType.file),
							title: file.WPRFileName,
							hierarchy: LibHierarchy.file,
							objectFormat: DLObjectFormat.normalFile,
							srcFormat: convertSourceFormat(
								file.MediaType.toLowerCase(),
								"",
								"get-lib-files-in-proj"
							),
							expanded: false,
							//
							aliasId: "x",
						}
						self.pushChildItem(newFile)
					})
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						// autoHide: true,
					})
					self.handleResponse({
						actionName: actionNameForUi,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: false,
					})
				} else if (
					response.status === 200 &&
					response.data.length === 0
				) {
					ConsoleLog(`(${actionName}) No item in this folder`)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						// autoHide: true,
					})
					self.handleResponse({
						actionName: actionNameForUi,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: false,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetLibFilesInProj
