import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { SignoffDetailRowProps } from "../data-models/signoff-history.data-model"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

function formatSignoff(info: string) {
	if (info === "") {
		return null
	} else if (info.includes("--")) {
		// ConsoleLog("Negative sign off!")
		// when the sign off has minus value (canceld sign off)
		const role = info.split(" ")[1].slice(1, -1)
		const name = info.split(" ")[2]
		const result = {
			isNegative: true,
			by: name,
			role,
		}
		return result
	} else {
		const role = info.split(" ")[0].slice(1, -1)
		const name = info.split(" ")[1]
		const result = {
			by: name,
			role,
		}
		return result
	}
}

const GetSignoffHistoryDetail = (self: any) => ({
	getSignoffHistoryDetail(fileId: string, menuId: DLProjSubMenus) {
		// 0.
		const actionName = "GetSignoffHistoryDetail" + menuId + fileId
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readSignoffHistoryDetail(fileId, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT
					 */
					const fetchedHistory = response.data.Data
					let reOrganizedHistory: SignoffDetailRowProps[] = []
					fetchedHistory.map((item: any) => {
						// ConsoleLog(["getSignoffHistoryDetail-item", item])
						reOrganizedHistory.push({
							at: item.SignOffDate,
							prepare: formatSignoff(item.Prepare),
							review: formatSignoff(item.Review),
							epReview: formatSignoff(item.EP),
							cpReview: formatSignoff(item.CP),
							qcReview: formatSignoff(item.QC),
						})
					})
					const result = {
						fileId: fileId,
						history: reOrganizedHistory,
					}
					// ConsoleLog(["result", result])
					self.setDetail(result, menuId)
					// when success - update response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the sign off details of the file",
						open: false,
					})
				} else {
					// when fail (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.red,
						message: response.data.Message,
						// customMessage:
						// 	"TODO: Fail by some reason. Please Check",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	relayGetSignoffHistoryDetail(
		fileIdList: string[],
		index: number,
		menuId: DLProjSubMenus,
		postSuccess: () => void,
		handleCompletedFiles: (targetId: string) => void
	) {
		const actionName = "relayGetSignoffHistoryDetail"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		if (fileIdList.length >= index + 1) {
			const targetId = fileIdList[index]
			self.readSignoffHistoryDetail(targetId, menuId).then(
				(response: any) => {
					handleCompletedFiles(targetId)
					if (response.status === 200 && response.data.Status === 1) {
						// default re-organizing
						const data = response.data.Data
						let detail: SignoffDetailRowProps[] = []
						data.map((item: any) => {
							detail.push({
								at: item.SignOffDate,
								prepare: formatSignoff(item.Prepare),
								review: formatSignoff(item.Review),
								epReview: formatSignoff(item.EP),
								cpReview: formatSignoff(item.CP),
								qcReview: formatSignoff(item.QC),
							})
						})

						/**
						 *
						 *
						 *
						 *
						 */
						// 1. set success case response
						console.log(
							index,
							"Succeed to get sign off detail of",
							targetId
						)
						// NOTE: update function here = same function with the basic one
						// this function saves the data to the 'wpHistoryDetail' or 'pfHistoryDetail'
						const result = {
							fileId: targetId,
							history: detail,
						}
						// ConsoleLog(["result", result])
						self.setDetail(result, menuId)
						// 2. next step
						// 2-1. if there are more files
						if (fileIdList.length > index + 1) {
							self.relayGetSignoffHistoryDetail(
								fileIdList,
								index + 1,
								menuId,
								postSuccess,
								handleCompletedFiles
							)
						}
						// 2-2. if the target is the last one
						if (fileIdList.length === index + 1) {
							console.log("----- The End -----")
							self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.success,
							})
							postSuccess && postSuccess()
						}
					} else {
						// 1. set fail case response
						console.log(
							index,
							"Failed to get sign off detail of",
							targetId
						)
						// NOTE: update function here
						// 2. next step
						// 2-1. if there are more files
						if (fileIdList.length > index + 1) {
							self.relayGetSignoffHistoryDetail(
								fileIdList,
								index + 1,
								menuId,
								postSuccess,
								handleCompletedFiles
							)
						}
						// 2-2. if the target is the last one
						if (fileIdList.length === index + 1) {
							console.log("----- The End -----")
							self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.success,
							})
							postSuccess && postSuccess()
						}
					}
				}
			)
		}
	},
})

export default GetSignoffHistoryDetail
