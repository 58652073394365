import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail (1), fail (2)
 */
const RemoveGroup = (self: any) => ({
	removeGroup(groupId: string) {
		// 0.
		const actionName = "removeGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		if (groupId === undefined) {
			alert(`(${actionName}) Group ID is undefined`)
			return
		}
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			self.deleteGroupItem(numberId)
				.then((response: any) => {
					// console.log(actionName, "resonse__", response)
					const statusCode = response.data.Status
					const serverMsg =
						response.data.message || response.data.Message
					// if success
					if (response.status === 200 && statusCode === 1) {
						self.deleteGroupFromStore(groupId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else if (
						statusCode === 0 &&
						serverMsg ===
							"You can't delete this Group as it has project files in it"
					) {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 200, // temp
							message: serverMsg,
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
							msgCode: "error-" + "byProjects", // "byProjects" should be updated as error code
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 200, // temp
							customMessage: serverMsg,
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: true,
					})
				})
		} else {
			// if the numberId type is not number
			alert(`(${actionName}) ${numberId}`)
		}
	},
})

export default RemoveGroup
