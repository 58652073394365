import React, { useState } from "react"
import {
	Column,
	ColumnDef,
	flexRender,
	useReactTable,
	getCoreRowModel,
	PaginationState,
	getPaginationRowModel,
} from "@tanstack/react-table"
import styled from "styled-components"
import MaUTable from "@material-ui/core/Table"
import TableBody from "@material-ui/core/TableBody"
import TableCell from "@material-ui/core/TableCell"
import TableContainer from "@material-ui/core/TableContainer"
import TableHead from "@material-ui/core/TableHead"
import TablePagination from "@material-ui/core/TablePagination"
import TableRow from "@material-ui/core/TableRow"
import { observer } from "mobx-react-lite"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

const defaultColumn: Partial<ColumnDef<any>> = {
	cell: ({ getValue, row: { index }, column: { id }, table }: any) => {
		const initialValue = getValue()
		// We need to keep and update the state of the cell normally
		// const [value, setValue] = React.useState(initialValue)
		const value = ""
		// When the input is blurred, we'll call our table meta's updateData function
		const onBlur = (e: any) => {
			table.options.meta?.updateData(index, id, e.target.value)
		}

		// If the initialValue is changed external, sync it up with our state
		// React.useEffect(() => {
		//   setValue(initialValue)
		// }, [initialValue])

		return (
			<input
				value={initialValue as string}
				onChange={onBlur}
				onBlur={onBlur}
			/>
		)
	},
}

export default observer(function DLReactEditableTable({
	tableColumns,
	data,
	defaultPageSize = 15,
	hasPagination = true,
	height,
	noDataText = "No Data",
}: {
	tableColumns: any
	data: any
	defaultPageSize?: any
	hasPagination?: boolean
	height?: number | string
	noDataText?: string
}) {
	const [editableData, setEditableData] = useState<any>([])

	const [{ pageIndex, pageSize }, setPagination] =
		React.useState<PaginationState>({
			pageIndex: 0,
			pageSize: defaultPageSize,
			// pageCount: data?.length ?? -1,
		})

	const pagination = React.useMemo(
		() => ({
			pageIndex,
			pageSize,
			pageCount: data?.length ?? -1,
		}),
		[pageIndex, pageSize, data?.length]
	)

	const handleChangePage = (event: any, newPage: any) =>
		table.setPageIndex(newPage)

	const handleChangeRowsPerPage = (event: any) =>
		table.setPageSize(Number(event.target.value))

	React.useEffect(() => {
		setEditableData(data)
	}, [data])

	const table = useReactTable({
		data: editableData,
		columns: tableColumns,
		state: {
			pagination,
		},
		defaultColumn,
		onPaginationChange: setPagination,
		getCoreRowModel: getCoreRowModel(),
		getPaginationRowModel: getPaginationRowModel(),
		meta: {
			updateData: (rowIndex: any, columnId: any, value: any) => {
				// Skip age index reset until after next rerender
				// skipAutoResetPageIndex()
				console.log(rowIndex, columnId, value)
				setEditableData((old: any) =>
					old.map((row: any, index: number) => {
						if (index === rowIndex) {
							return {
								...old[rowIndex]!,
								[columnId]: value,
							}
						}
						return row
					})
				)
			},
		},
		// debugTable: true,
	})

	return (
		<StyledTable height={height}>
			<TableContainer className="container">
				<MaUTable stickyHeader aria-label="sticky table">
					<TableHead>
						{table.getHeaderGroups().map((headerGroup) => (
							<TableRow key={headerGroup.id}>
								{headerGroup.headers.map((header) => {
									return (
										<TableCell
											key={header.id}
											colSpan={header.colSpan}
										>
											{header.isPlaceholder ? null : (
												<div className="header">
													<div
														onClick={header.column.getToggleSortingHandler()}
														style={{
															whiteSpace:
																"nowrap",
														}}
													>
														{flexRender(
															header.column
																.columnDef
																.header,
															header.getContext()
														)}
													</div>
												</div>
											)}
										</TableCell>
									)
								})}
							</TableRow>
						))}
					</TableHead>
					<TableBody>
						{!!table.getRowModel().rows.length ? (
							<>
								{table.getRowModel().rows.map((row) => (
									<TableRow key={row.id} hover>
										{row.getVisibleCells().map((cell) => (
											<TableCell
												key={`${cell.id}_${cell.column.columnDef.header}`}
											>
												<div
													data-testid={`${cell.column.id}-cell`}
												>
													{flexRender(
														cell.column.columnDef
															.cell,
														cell.getContext()
													)}
												</div>
											</TableCell>
										))}
									</TableRow>
								))}
							</>
						) : (
							<TableRow>
								<TableCell
									colSpan={table.getAllFlatColumns().length}
								>
									<div className="FR JC">{noDataText}</div>
								</TableCell>
							</TableRow>
						)}
					</TableBody>
				</MaUTable>
			</TableContainer>
			{hasPagination && (
				<TablePagination
					rowsPerPageOptions={[5, 10, 15, 20, 50, 100]}
					component="div"
					count={table.getFilteredRowModel().flatRows.length}
					rowsPerPage={pageSize}
					page={pageIndex}
					onPageChange={handleChangePage}
					onRowsPerPageChange={handleChangeRowsPerPage}
				/>
			)}
		</StyledTable>
	)
})

interface props {
	readonly height?: number | string
}

const StyledTable = styled.div<props>`
	.container {
		height: ${(props: any) =>
			props.height ? props.height - 120 - 12 + "px" : "500px"};
	}
	.pageHeader {
		padding: 1rem;
	}
	a {
		color: blue;
	}
`
