import { flow } from "mobx"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const reOrganization = (item: any) => {
	const intId = parseInt(item.id)
	// a-1) prepare the item count
	const reOrganizedCounts = {
		totalCount: 0, // [totalCount] ex) 0,
		completedCount: 0, // [completedCount] ex) 0,
		dateTime: null, // [DateTime] ex) null,
		data: "", // [data] ex) "",
		isPending: false, // [is_pending] ex) false
	}
	if (item.counts) {
		reOrganizedCounts.totalCount = item.counts.totalCount // [totalCount] ex) 0,
		reOrganizedCounts.completedCount = item.counts.completedCount // [completedCount] ex) 0,
		reOrganizedCounts.dateTime = item.counts.DateTime // [DateTime] ex) null,
		reOrganizedCounts.data = item.counts.data // [data] ex) "",
		reOrganizedCounts.isPending = item.counts.is_pending // [is_pending] ex) false
	}
	// a-2) prepare the additional data (wp, pf count)
	const reOrganizedAdditionalData = {
		wpCount: 0,
		pfCount: 0,
	}
	if (item.additional_data) {
		reOrganizedAdditionalData.wpCount = item.additional_data.wp_count
		reOrganizedAdditionalData.pfCount = item.additional_data.pf_count
	}
	// a-3) convert mandatory value to boolean
	let isMandatory
	if (item.is_mandatory === 1) {
		isMandatory = true
	} else if (item.is_mandatory === 0) {
		isMandatory = false
	}
	// ----- item id list -----
	// (type) id: title --> completion condition
	//
	// (A) 1: prepare signoff  -->  0/0 or 100/100
	// (A) 2: review signoff -->  0/0 or 100/100
	// (A) 3: ep review signoff -->   0/0 or 100/100
	// (A) 4: cp review signoff -->   0/0 or 100/100
	// (A) 5: qc review signoff -->   0/0 or 100/100
	// (A) 6: attention status -->   0/0 or 100/100
	// (A) 7: timeline -->   0/0 or 100/100
	// (A) 8: pbc list -->   0/0 or 100/100
	// (A) 9: pbc attachments (= pbc files, = pbc items) -->   0/0 or 100/100
	//
	// <B> 10: comments -->  0
	// <B> 11: trash -->  0
	// <B> 12: opened file -->  0
	//
	// (A) 13: required signoff again type 2 (re-signoff 2) -->  0
	// (A) 14: required signoff again type 1 (re-signoff 1) -->  0
	//
	// [C] 15: final Report date exists -->  1/0 --> This condition uses totalCount
	// [C] 16: project lock -->  1/0
	//
	// (A) 17: rationale -->  0/0 or 100/100
	// (A) 18: role assigned files -->  0/0 or 100/100
	//
	// a-4) set status & condition counting method
	let status
	let countingMethod
	// completion check type
	// type 1: 0/0 or 100/100 (completed count / total count)
	// type 2: 0
	// type 3: 1/0 (completed count / total count)
	const conditionTypeA = [1, 2, 3, 4, 5, 6, 7, 8, 9, 13, 14, 17, 18]
	const conditionTypeB = [10, 11, 12]
	const conditionTypeC = [15, 16] // use is_pending for id 15, 16

	if (conditionTypeA.findIndex((item: any) => item === intId) !== -1) {
		// ---- Type A
		countingMethod = "TYPE_A"
		if (item.counts.totalCount === item.counts.completedCount) {
			status = "DONE"
		} else if (
			item.counts.totalCount > item.counts.completedCount &&
			item.counts.completedCount !== 0
		) {
			status = "INPROGRESS"
		} else if (item.counts.completedCount === 0) {
			status = "TODO"
		} else {
			status = "?"
		}
	} else if (conditionTypeB.findIndex((item: any) => item === intId) !== -1) {
		// ----- Type B
		countingMethod = "TYPE_B"
		if (item.counts.totalCount === 0) {
			status = "DONE"
		} else {
			status = "INPROGRESS"
		}
	} else if (conditionTypeC.findIndex((item: any) => item === intId) !== -1) {
		// ----- Type C
		countingMethod = "TYPE_C"
		if (item.counts.is_pending === false) {
			status = "DONE"
		} else {
			status = "TODO"
		}
	} else {
		ConsoleLog([intId, item.name, "is faied to find matched type"])
	}
	//
	// a-5) set hasDetail
	// 15-final report date & 16-project lock are not have detail information on the screen
	let hasDetail
	if (intId === 15 || intId === 16) {
		hasDetail = false
	} else {
		hasDetail = true
	}
	// a-6) re-organize mandatory list
	const reOrganizedMandatoryItem = {
		id: idToString(item.id, IdType.archiveCondition), // item.id.toString(), // [id] ex) 12
		title: item.name, // [name] ex) "열려있는 파일"
		order: item.order, // [order] ex) 1
		isMandatory: isMandatory, // [is_mandatory] ex) 1
		counts: reOrganizedCounts, // [counts]
		additionalData: reOrganizedAdditionalData, // [additional_data]
		hasDetail: hasDetail,
		status: status,
		countingMethod,
	}
	return reOrganizedMandatoryItem
}

const GetArchiveDiagnosis = (self: any) => ({
	/**
	 *
	 * @param self
	 * @returns
	 *
	 * * i18n records
	 * - success, fail
	 *
	 */
	getArchiveDiagnosis() {
		// 0.
		const actionName = "getArchiveDiagnosis"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readArchiveDiagnosis()
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					self.setTitle(response.data.ArchivePolicyName)
					self.setTotalRationaleFiles(response.data.TotalFilesCount)
					self.setFilesWithoutRationaleCount(
						response.data.FilesWithoutRationaleCount
					)
					self.setProjectSize(response.data.ProjectSize)
					// initialize existing stored list
					self.resetList()
					// flatten the archive condition list
					const existingMandatoryList = response.data.MandatoryList
					const existingNonMandatoryList =
						response.data.NonMandatoryList
					// A) re-organize function
					// ----------- moved to above to reuse
					// B) re-organize mandatory list
					existingMandatoryList.map((item: any) => {
						self.pushItemToList(reOrganization(item))
					})
					// C) re-organize non-mandatory list
					existingNonMandatoryList.map((item: any) => {
						self.pushItemToList(reOrganization(item))
					})
					// set success response
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	relayValidateReplicas: flow(function* (
		targetReplicas: number[],
		index: number,
		getProjectToken: any,
		userId: number,
		postAction: any
	) {
		const delay = 7000 // 1000 = 1s
		const totalCount = targetReplicas.length
		const count = `${index}/${totalCount}`
		if (index === 0) {
			console.log("Target replica list length: ", totalCount)
		}
		if (totalCount >= index + 1) {
			const projId = targetReplicas[index]
			const tokenAvailable = yield getProjectToken(projId, userId)
			if (tokenAvailable) {
				self.readArchiveDiagnosis().then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						console.log(
							count,
							"Success to get the archive dialogsis of",
							projId
						)
						/**
						 *
						 * POST ACTION HERE
						 *
						 */
						const data = response.data
						const projectSize = data.ProjectSize
						const mandatories = data.MandatoryList
						// const optionals = data.NonMandatoryList
						// NOTE: validate mandatory condition only
						let mandatoryStatus: {
							id: number
							title: string
							status: string | undefined
						}[] = []
						// let optionalStatus: any[] = []
						mandatories.map((item: any) => {
							const id = item.id
							const reOrganizedItem = reOrganization(item)
							const status = reOrganizedItem.status
							const title = reOrganizedItem.title
							if (status !== "DONE") {
								mandatoryStatus.push({
									id,
									title,
									status,
								})
							}
						})
						// optionals.map((item: any) => {
						// 	optionalStatus.push(reOrganization(item).status)
						// })
						console.log(
							projectSize,
							mandatoryStatus
							// optionalStatus
						)
						if (mandatoryStatus.length !== 0) {
							const info = {
								replicaId: projId,
								projectSize,
								validationResult: mandatoryStatus,
							}
							postAction(info)
						}

						/**
						 *
						 * FOR ITERATION
						 *
						 */
						if (totalCount > index + 1) {
							setTimeout(() => {
								self.relayValidateReplicas(
									targetReplicas,
									index + 1,
									getProjectToken,
									userId,
									postAction
								)
							}, delay)
						}
						if (totalCount === index + 1) {
							console.log("THE END")
						}
					} else {
						console.log(
							count,
							"Failed to get the archive dialogsis of",
							projId
						)
						if (totalCount > index + 1) {
							setTimeout(() => {
								self.relayValidateReplicas(
									targetReplicas,
									index + 1,
									getProjectToken,
									userId,
									postAction
								)
							}, delay)
						}
						if (totalCount === index + 1) {
							console.log("THE END")
						}
					}
				})
			} else {
				console.log(count, "getting token is failed for", projId)
				if (totalCount > index + 1) {
					setTimeout(() => {
						self.relayValidateReplicas(
							targetReplicas,
							index + 1,
							getProjectToken,
							userId,
							postAction
						)
					}, delay)
				}
				if (totalCount === index + 1) {
					console.log("THE END")
				}
			}
		}
	}),
})

export default GetArchiveDiagnosis
