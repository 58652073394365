import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DLSector } from "../../../../../common-models/enumerations/common-enums"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { putRequest } from "../../../../../library/api-requests"

// this props are shared with view-model action
export type CheckoutFileProps = {
	srcId: string
	userId: string
	sector: DLSector
	sectorId: string
	menuId: string
	postActionForCancel?: any
	postActionForSuccess?: any
}

// WARNING: WARNING: WARNING: WARNING: this CHECK-OUT means !!! OPEN !!!
const MCheckoutFile = (self: any) => ({
	mCheckoutFile: flow(function* ({
		srcId,
		userId,
		sector,
		sectorId,
		menuId,
	}: CheckoutFileProps) {
		const actionName = "mCheckoutFile"
		// 1.
		if (
			srcId === "" ||
			srcId === undefined ||
			userId === "" ||
			userId === undefined
		) {
			alert(
				`(${actionName}) Undefined props exist. srcId: ${srcId} | userId: ${userId}`
			)
			return
		}
		//
		//
		// 2.
		const srcNumId = idToNumber(srcId, IdType.src)
		const userNumberId = idToNumber(userId, IdType.user)
		let token: TOKEN_TYPE | undefined = undefined
		if (sector === DLSector.org) {
			token = TOKEN_TYPE.orgToken
		} else if (sector === DLSector.proj) {
			token = TOKEN_TYPE.projToken
		} else if (sector === DLSector.lobby) {
			// pending: Lobby side file management does not exist
			return
		} else {
			alert(
				`(${actionName}) This sector does not be supported. sector: ${sector}`
			)
		}
		//
		//
		// 3.
		if (
			typeof srcNumId === "number" &&
			typeof userNumberId === "number" &&
			token !== undefined
		) {
			try {
				const endpoint = "/FileOpenClose"
				const params = {
					// NOTE: WARNING: Why this api does not require the project ID...
					MediaID: srcNumId,
					UserID: userNumberId,
					Type: 1, // NOTE: 0 = close, 1 = open
				}
				const response = yield putRequest({
					url: endpoint,
					params,
					tokenType: token,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		}
	}),
})

export default MCheckoutFile
