import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"

const EditFinancialGroup = (self: any) => ({
	editFinancialGroup(financialGroup: string, fsGroupId: number) {
		// 0
		const actionName = "editFinancialGroup"
		// 1
		const checkedProps = hasEmptyProps({ financialGroup })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Updating financial group is in progress",
			open: true,
		})

		const payload = {
			financialGroup: checkedProps.props.financialGroup,
			id: fsGroupId,
		}

		// 3) request API call to Model
		self.createFinancialGroup(payload) // Same API for create and update with diff payload
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						self.editGroupName(
							fetchedData.financialGroup,
							fetchedData.id
						)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully updated group",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditFinancialGroup
