import React from "react"
import Icon from "@mdi/react"
import {
	mdiSortAlphabeticalAscending,
	mdiSortAlphabeticalDescending,
	mdiCheckboxMarkedOutline,
	mdiArrowExpandVertical,
	mdiUnfoldLessHorizontal,
	mdiDrag,
	mdiLock,
	mdiClockCheck,
} from "@mdi/js"
import { DLIconButton } from "."

export const SortingIconButton = ({
	isAsc = true,
	handleSort,
	by,
}: {
	isAsc?: boolean
	handleSort?: () => void
	by?: string
}) => {
	const handleClick = () => {
		handleSort && handleSort()
	}
	let tooltipText = isAsc ? "Ascending" : "Descending"
	if (by) {
		tooltipText = tooltipText + " " + by
	}
	return (
		<DLIconButton
			eleTestId="sorting-icon-button"
			clickHandler={handleClick}
			tooltipText={tooltipText}
		>
			{isAsc ? (
				<Icon path={mdiSortAlphabeticalAscending} size={0.8} />
			) : (
				<Icon path={mdiSortAlphabeticalDescending} size={0.8} />
			)}
		</DLIconButton>
	)
}

export const DragOnOffIconButton = ({
	handleOnOff,
	structureLock,
	iconSize = 0.8,
}: // featureName = "Drag",
{
	handleOnOff: () => void
	structureLock: boolean
	iconSize?: number
	// featureName?: string
}) => {
	return (
		<DLIconButton
			variant="iconOnly"
			eleTestId="drag-onoff"
			clickHandler={handleOnOff}
			color={structureLock ? "orange" : "default"}
			tooltipText={structureLock ? "Unlock Structure" : "Lock Structure"}
		>
			{structureLock ? (
				<Icon path={mdiLock} size={iconSize} />
			) : (
				<Icon path={mdiLock} size={iconSize} />
			)}

			{/* TODO: when the icon size is not 0.8, IconButton fail to align. Fix this issue  */}
		</DLIconButton>
	)
}

export const CheckboxOnOffIconButton = ({
	handleOnOff,
	showCheckbox,
}: {
	handleOnOff: () => void
	showCheckbox: boolean
}) => {
	return (
		<DLIconButton
			variant="iconOnly"
			eleTestId="checkbox-onoff"
			clickHandler={handleOnOff}
			color={showCheckbox ? "orange" : "default"}
			tooltipText={showCheckbox ? "Hide Checkbox" : "Show Checkbox"}
		>
			{showCheckbox ? (
				<Icon path={mdiCheckboxMarkedOutline} size={0.8} />
			) : (
				<Icon path={mdiCheckboxMarkedOutline} size={0.8} />
			)}
		</DLIconButton>
	)
}

export const ExpandAllIconButton = ({
	handleExpandAll,
	disabled,
}: {
	handleExpandAll: () => void
	disabled: boolean
}) => {
	return (
		<DLIconButton
			variant="iconOnly"
			eleTestId="expand-all-btn"
			clickHandler={handleExpandAll}
			tooltipText="Expand All"
			disabled={disabled}
		>
			<Icon path={mdiArrowExpandVertical} size={0.8} />
		</DLIconButton>
	)
}

export const CollapseAllIconButton = ({
	handleCollapseAll,
	disabled,
}: {
	handleCollapseAll: () => void
	disabled: boolean
}) => {
	return (
		<DLIconButton
			variant="iconOnly"
			eleTestId="collapse-all-btn"
			clickHandler={handleCollapseAll}
			tooltipText="Collapse All"
			disabled={disabled}
		>
			<Icon path={mdiUnfoldLessHorizontal} size={0.8} />
		</DLIconButton>
	)
}
