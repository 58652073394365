import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../components/basic-elements"
import { getRequest } from "../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { OrgSetupUserApiProps } from "../org-setup-users.apis"

const DeleteOrgUser = (
	self: any,
	restore: OrgSetupUserApiProps["apiRestore"],
	deleteUser: OrgSetupUserApiProps["apiDelete"]
) => ({
	// 4-1-1. Precheck Suspend
	precheckSuspendOrgUser: flow(function* (params) {
		const actionName = "precheckSuspendOrgUser"
		try {
			const endpoint = "/DeleteUserManagementUsers_Verify"
			const response = yield getRequest({
				url: endpoint,
				params,
				tokenType: TOKEN_TYPE.orgToken,
			})
			ConsoleLog(["suspendOrgUser response", response])
			return response
		} catch (error) {
			console.error("suspendOrgUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
	//
	// 4-1-2. Suspend
	suspendOrgUser: flow(function* (payload) {
		const actionName = "suspendOrgUser"
		try {
			const response = yield deleteUser(payload)
			ConsoleLog(["suspendOrgUser response", response])
			return response
		} catch (error) {
			console.error("suspendOrgUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
	//
	// 4-2. Restore
	restoreOrgUser: flow(function* (payload) {
		const actionName = "restoreOrgUser"
		try {
			const response = yield restore(payload)
			// ConsoleLog("restoreOrgUser response", response)
			return response
		} catch (error) {
			// console.error("restoreOrgUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
	//
	// 4-3. Delete
	deleteOrgUser: flow(function* (payload) {
		const actionName = "deleteOrgUser"
		try {
			const response = yield deleteUser(payload)
			ConsoleLog(["deleteOrgUser response", response])
			return response
		} catch (error) {
			console.error("deleteOrgUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteOrgUser
