import { types } from "mobx-state-tree"

export const ProjectDetailDataModel = types.model({
	//
	//
	// * 1. location info: group, cabinet
	// groupId: ...,
	groupName: types.string,
	// cabinetId: ...,
	// cabinetName: ...,
	//
	//
	// * 2. client info
	// clientId: types.string,
	clientName: types.string,
	clientAliasId: types.string,
	//
	//
	// * 3. project basic info
	id: types.string,
	aliasId: types.string,
	title: types.string,
	replicaId: types.union(types.null, types.string),
	//
	// engTypeId: ...,
	engTypeName: types.string, // NOTE: Not exist on the group item detail
	// raId: ...,
	raName: types.union(types.null, types.string), // NOTE: Not exist on the group item detail
	//
	//
	// * 4. project status info
	// projStatusId: ...,
	projectStatus: types.string,
	// periodId: ...,
	periodName: types.string,
	//
	version: types.number,
	// NOTE: --> API response return "-" for 0 in here
	// NOTE: --> API response return "v1" for 1 in here
	// NOTE: for consistency, convert them as number
	// isArchived: ...,
	isLocked: types.boolean,
	//
	//
	// * 5. access info
	// roleId: ...,
	// roleName: ...,
	// accessible: ...,
	// ableToRollForward: ...,
	//
	//
	// * 6. schedule info
	// financialYear: ...,
	periodEndDate: types.string,
	//
	expectedReportDate: types.union(types.null, types.string), // NOTE: Not exist on the group item detail
	finalReportDate: types.union(types.null, types.string), // NOTE: Not exist on the group item detail
	//
	archPolicyPeriodId: types.union(types.null, types.string), // NOTE: Not exist on the group item detail
	// archPolicyPeriodName: ...,
	// archPolicyPeriodValue: ...,
	//
	expectedArchiveDeadlineDate: types.union(types.null, types.string),
	// expectedArchiveDate: ...,
	// finalArchDeadlineDate: ...,
	//
	//
	// * 7. history info
	createdBy: types.string,
	// createdAt: ...,
	//
	// lastAccessBy: ...,
	// lastAccessAt: ...,
	//
	// archivedBy: ...,
	archivedFinalDate: types.union(types.null, types.string),
	// TODO: @Noah-0914: please update as "archivedAt" and related parts
	// TODO: @Noah-0914: please check this "archivedFinalDate" means "Final archive deadline date" or "Archiving date"
	//
	// unarchivedBy: ...,
	// unarchivedAt: ...,
	//
	//
	// * 8. assigned users
	epUsers: types.string, // NOTE: Not exist on the group item detail
	// qc users: ...,
	//
	//
	// * 9. extras
	//
	//
	// * + additional parts
	address: types.string, // NOTE: Not exist on the group item detail
	fee: types.string, // NOTE: Not exist on the group item detail
	//
	//
})

export const initialProjectDetail = {
	//
	//
	// * 1. location info: group, cabinet
	// groupId: ...,
	groupName: "",
	// cabinetId: ...,
	// cabinetName: ...,
	//
	//
	// * 2. client info
	// clientId: "",
	clientName: "",
	clientAliasId: "",
	//
	//
	// * 3. project basic info
	id: "",
	aliasId: "",
	title: "",
	replicaId: null,
	//
	// engTypeId: ...,
	engTypeName: "",
	// raId: ...,
	raName: "",
	//
	//
	// * 4. project status info
	// projStatusId: ...,
	projectStatus: "",
	// periodId: ...,
	periodName: "",
	//
	version: 0,
	// isArchived: ...,
	isLocked: true,
	//
	//
	// * 5. access info
	// roleId: ...,
	// roleName: ...,
	// accessible: ...,
	//
	//
	// * 6. schedule info
	// financialYear: ...,
	periodEndDate: "",
	//
	expectedReportDate: "",
	finalReportDate: "",
	//
	archPolicyPeriodId: "",
	// archPolicyPeriodName: ...,
	// archPolicyPeriodValue: ...,
	//
	expectedArchiveDeadlineDate: "",
	// expectedArchiveDate: ...,
	// finalArchDeadlineDate: ...,
	//
	//
	// * 7. history info
	createdBy: "",
	// createdAt: ...,
	//
	// lastAccessBy: ...,
	// lastAccessAt: ...,
	//
	// archivedBy: ...,
	archivedFinalDate: "", // WARNING:
	//
	// unarchivedBy: ...,
	// unarchivedAt: ...,
	//
	//
	// * 8. assigned users
	epUsers: "", // TODO: @Prudhvi-0817: please update as "epUsers" and related parts
	// qc users: ...,
	//
	//
	// * 9. extras
	//
	//
	// * + additional parts
	address: "",
	fee: "",
	//
	//
}
