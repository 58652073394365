import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddFinancialGroup = (self: any) => ({
	addFinancialGroup(financialGroup: string, projectId: string) {
		// 0
		const actionName = "addFinancialGroup"
		// 1
		const checkedProps = hasEmptyProps({ financialGroup })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Creating financial group is in progress",
			open: true,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			financialGroup: checkedProps.props.financialGroup,
			clientId,
		}

		// 3) request API call to Model
		self.createFinancialGroup(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						const reOrganizedGroup = {
							fsGroupId: fetchedData.id,
							fsGroupName: fetchedData.financialGroup,
							fsGroupMapId: 0, // Not getting
							// message: item.Message,
							// status: item.Status.toString(),
						}
						self.pushItemToAvailableGroups(reOrganizedGroup)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully added group",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddFinancialGroup
