import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

const ReadProjTemplateDetails = (
	self: any,
	apiReadProjTemplateDetails: OrgProjTemplatesApiProps["apiReadProjTemplateDetails"]
) => ({
	readProjTemplateDetails: flow(function* (
		templateId: string
		// parentType: OrgProjTemplateItemType
	) {
		const actionName = "readProjTemplateDetails"
		// 1.
		if (templateId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. templateId: ${templateId}`
			)
			return
		}
		// 2.
		// let numberId: any = undefined
		// if (parentType === OrgProjTemplateItemType.template) {
		// 	numberId = idToNumber(folderId, IdType.projTemplate)
		// } else if (parentType === OrgProjTemplateItemType.fileFolder) {
		// 	numberId = idToNumber(folderId, IdType.folder)
		// }

		const numberId = idToNumber(templateId, IdType.projTemplate)
		if (typeof numberId === "number") {
			try {
				const params = {
					ParentFldID: numberId,
				}
				const response = yield apiReadProjTemplateDetails(params)
				// NOTE: Do not save the original data on the Model (TBD) --> Move to the ViewModel
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default ReadProjTemplateDetails
