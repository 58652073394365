import { applySnapshot, types } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
// ---------- view model actions
import GetCheckedOutWpFiles from "./view-model-actions/get-checked-out-wp-list"
import GetCheckedOutPfFiles from "./view-model-actions/get-checked-out-pf-list"
import GetPfFileCheckinrequest from "./view-model-actions/get-pf-file-checkin-requests"
import GetWfFileCheckinrequest from "./view-model-actions/get-wp-file-checkin-requests"
import {
	RemoveAllWorkPaperOpenedNotification,
	RemoveAllPermanentOpenedNotification,
	RemoveAllPermanentRequestedNotification,
	RemoveAllWorkPaperRequestedNotification,
} from "./view-model-actions/discard-checkout-all-files"
import {
	RemoveSingleWorkPaperOpenedNotification,
	RemoveSinglePermanentOpenedNotification,
	RemoveSinglePermanentRequestedNotification,
	RemoveSingleWorkPaperRequestedNotification,
} from "./view-model-actions/discard-checkout-file"
import {
	MST_Post,
	NotificationData,
	RequestedData,
	// SysNoti,
	// OrgNoti,
	// SysNotiType,
} from "./data-models/notifications.data-model"

import { sortBy } from "@datalobby/common"

import { PostType } from "@datalobby/types"
import { DnTFormatProps } from "../../../../common-models/types/common-props"
import { getTzDateAndTime2 } from "../../../../library/converters/date-utc-converter"
import { initialStore } from "./global-notis.provider"
import RemoveOrRestorePost from "../../../../service-modules/post/store-view-model-actions/remove-or-restore-post"
import GetOrgNotiList from "../../../../service-modules/post/store-view-model-actions/get-org-noti-list"

const GlobalNotisViewModel = types
	.model({
		openedNotification: types.array(NotificationData),
		requestedNotification: types.array(RequestedData),
		//
		// sysNotiList: types.array(SysNoti),
		// orgNotiList: types.array(OrgNoti),
		sysNotiList: types.array(MST_Post),
		orgNotiList: types.array(MST_Post),
		//
		viewNotificationDialogOpen: false,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions(GetCheckedOutWpFiles)
	.actions(GetCheckedOutPfFiles)
	.actions(GetPfFileCheckinrequest)
	.actions(GetWfFileCheckinrequest)
	// remove all
	.actions(RemoveAllWorkPaperOpenedNotification)
	.actions(RemoveAllPermanentOpenedNotification)
	.actions(RemoveAllPermanentRequestedNotification)
	.actions(RemoveAllWorkPaperRequestedNotification)
	//remove single
	.actions(RemoveSingleWorkPaperOpenedNotification)
	.actions(RemoveSinglePermanentOpenedNotification)
	.actions(RemoveSinglePermanentRequestedNotification)
	.actions(RemoveSingleWorkPaperRequestedNotification)
	.actions(GetOrgNotiList)
	.actions(RemoveOrRestorePost)
	.actions((self) => ({
		setSysNotiList(notiList: any) {
			self.sysNotiList.length = 0
			self.sysNotiList = notiList
		},
		pushSysNotiItem(sysNoti: any) {
			self.sysNotiList.push(sysNoti)
		},
		setOrgNotiList(list: any) {
			self.orgNotiList = list
		},
		pushOrgNotiItem(orgNoti: any) {
			if (
				self.orgNotiList.findIndex((item) => item.id === orgNoti.id) ===
				-1
			) {
				self.orgNotiList.push(orgNoti)
			}
		},
		setViewNotificationDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.viewNotificationDialogOpen = request
			} else {
				self.viewNotificationDialogOpen =
					!self.viewNotificationDialogOpen
			}
		},
		sliceOrgNotiItem(postId: string) {
			const targetIndex = self.orgNotiList.findIndex(
				(item) => item.id === postId
			)
			self.orgNotiList.splice(targetIndex, 1)
		},
		updateOrgNotiItem(post: PostType) {
			const target = self.orgNotiList.find((item) => item.id === post.id)
			if (target) {
				/**
				 * NOTE:
				 * - Must use splice: if update the target directly, table cannot catch the update
				 * - Must use hard copy: and if do not use hardCopy, shallowCopy occurs an error of cache
				 */
				const targetCopy = JSON.parse(JSON.stringify(target))
				const updatedPost = {
					...targetCopy,
					title: post.title,
					content: post.content,
					priority: post.priority,
					fixToTop: post.fixToTop,
					attachments: post.attachments ? post.attachments : [],
					// attachments: post.attachments.slice(),
					// whoCanRead: post.whoCanRead,
					// allowedTargets: post.allowedTargets.slice(),
					modifiedBy: post.modifiedBy,
					modifiedAt: post.modifiedAt,
				}

				const targetIndex = self.orgNotiList.findIndex(
					(item) => item.id === post.id
				)
				self.orgNotiList.splice(targetIndex, 1)
				self.orgNotiList.push(updatedPost)
			} else {
				console.log("Cannot find the target post to update")
			}
		},
		orgNotiById(notiId: string) {
			const target = self.orgNotiList.find((item) => item.id === notiId)
			return target
		},
		// updateOrgNotiVisitorInfo({
		// 	notiId,
		// 	visitor,
		// }: {
		// 	notiId: string
		// 	visitor?: string
		// }) {
		// 	const target = self.orgNotiList.find((item) => item.id === notiId)
		// 	if (target) {
		// 		target.visitorCount = target.visitorCount + 1
		// 		if (visitor) {
		// 			target.visitors.push(visitor)
		// 		}
		// 	} else {
		// 		console.log(
		// 			"Cannot find the target post to update visitor info"
		// 		)
		// 	}
		// },
	}))
	.views((self) => ({
		viewOrgNotiList(dntFormat: DnTFormatProps, userList?: any) {
			const copy = self.orgNotiList.slice().map((item) => {
				function findUserInfo(id: string) {
					const user = userList.find((item: any) => item.id === id)
					return user
				}
				let createdBy = item.createdBy
				let modifiedBy = item.modifiedBy
				if (userList?.length > 0) {
					createdBy = item.createdBy
						? findUserInfo(item.createdBy)?.name +
						  "[" +
						  findUserInfo(item.createdBy)?.aliasId +
						  "]"
						: "?"
					modifiedBy = item.modifiedBy
						? findUserInfo(item.modifiedBy)?.name +
						  "[" +
						  findUserInfo(item.modifiedBy)?.aliasId +
						  "]"
						: "-"
				}

				const createdAt = item.createdAt
					? getTzDateAndTime2({
							date: item.createdAt,
							dntFormat,
					  })
					: "?"
				const modifiedAt = item.modifiedAt
					? getTzDateAndTime2({
							date: item.modifiedAt,
							dntFormat,
					  })
					: "-"

				return {
					...item,
					createdBy: createdBy,
					createdAt: createdAt,
					modifiedBy: modifiedBy,
					modifiedAt: modifiedAt,
				}
			})
			return sortBy(copy, "fixToTop", false)
		},
		get viewNewOrgNotis() {
			let newOrgNotiCount = 0
			const userId = localStorage.getItem("orgUserId")

			// if (userId) {
			// 	const filterNewOrgNoti = self.orgNotiList.filter(
			// 		(item) => !item.visitors.includes(userId)
			// 	)

			// 	newOrgNotiCount = filterNewOrgNoti.length
			// }

			return newOrgNotiCount
		},
		get viewFixToTopCount() {
			const fixToTop = self.orgNotiList.filter((item) => item.fixToTop)
			return fixToTop.length
		},
		viewOrgNotiItemById(
			notiId: string,
			dntFormat: DnTFormatProps,
			userList?: any
		) {
			let target: any = self.orgNotiList.find(
				(item) => item.id === notiId
			)
			function findUserInfo(id: string) {
				const user = userList.find((item: any) => item.id === id)
				return user
			}
			let createdBy = target?.createdBy || ""
			let modifiedBy = target?.modifiedBy || ""
			if (userList?.length > 0) {
				if (createdBy !== "") {
					createdBy = target?.createdBy
						? findUserInfo(target?.createdBy)?.name +
						  "[" +
						  findUserInfo(target?.createdBy)?.aliasId +
						  "]"
						: "?"
				}
				if (modifiedBy !== "") {
					modifiedBy = target?.modifiedBy
						? findUserInfo(target?.modifiedBy)?.name +
						  "[" +
						  findUserInfo(target?.modifiedBy)?.aliasId +
						  "]"
						: "?"
				}
			}

			const createdAt = target?.createdAt
				? getTzDateAndTime2({
						date: target?.createdAt,
						dntFormat,
				  })
				: "?"
			const modifiedAt = target?.modifiedAt
				? getTzDateAndTime2({
						date: target?.modifiedAt,
						dntFormat,
				  })
				: "-"

			if (target && userList && dntFormat) {
				target = {
					...target,
					createdBy,
					modifiedBy,
					createdAt,
					modifiedAt,
				}
			} else {
				// target = null
			}

			return target
		},
	}))
	.actions((self) => ({
		resetNotificationList() {
			self.openedNotification.length = 0
		},
		pushItemToList(item: any) {
			self.openedNotification.push(item)
		},
		removeNotification(id: string) {
			self.openedNotification.splice(
				self.openedNotification.findIndex(
					(item: any) => item.srcId === id
				),
				1
			)
		},
		resetReqNotificationList() {
			self.requestedNotification.length = 0
		},
		pushItemToReqList(item: any) {
			self.requestedNotification.push(item)
		},
		removeReqNotification(id: string) {
			self.requestedNotification.splice(
				self.requestedNotification.findIndex(
					(item: any) => item.srcId === id
				),
				1
			)
		},
		getOrgNotiById(notiId: string) {
			const target = self.orgNotiList.find((item) => item.id === notiId)
			return target
		},
	}))
	.views((self) => ({
		viewNotifications(type: number) {
			if (type === 0) {
				return self.openedNotification
			} else {
				return self.requestedNotification
			}
		},
	}))
	// common
	.actions(Refresh)
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default GlobalNotisViewModel
