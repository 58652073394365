import { flow } from "mobx-state-tree"
import axios from "axios"
import { ProjPermission } from "../../../project-setup-users/store/data-models/proj-setup-users.data-models"
import { ConsoleLog } from "../../../../../components/basic-elements"

const ReadProjAssignedRole = (self: any) => ({
	// NOTE: Do not remove this commented area.
	// NOTE: This part should be turned to real API in the future
	readProjAssignedRole: flow(function* (userRoleId: string) {
		// 0) set actionName
		const actionName = "readProjAssignedRole"
		// ConsoleLog("get PROJ permission data from remote")

		const existingPermissions: any = []

		// NOTE: get data from DL Permissios sheet
		const prefix = "https://sheets.googleapis.com/v4/spreadsheets/"
		const book = "1zO9KfFx1IqfucpR8lQYzOVoGfyEqqIrfwIEFMgsreDk"
		const sheet = "Proj"
		const apiKey = "AIzaSyBNwUwxBeN9nr_b029ta43nReKeoM2jGn4"

		const bookUrl =
			prefix + book + "/values/" + sheet + "!B:J?key=" + apiKey
		//
		try {
			// TODO: NOTE: Need to update
			// * get static data from the code
			const response = existingPermissions.find(
				(element: any) => element.roleId === userRoleId
			)

			// * get remote data from google sheet
			const response2 = yield axios.get(bookUrl, {
				headers: { authorization: "" }, // To remove Datalobby Token
			})

			// console.log("ReadProjAssignedRole - response2", response2)

			const result = response2.data.values
			const header = result[1] // header is second line on this sheet

			result.shift() // remove regend
			result.shift() // remove header
			// console.log("ReadProjAssignedRole - after 2 shift", result)

			let roles: any[] = []

			for (let k = 2; k < header.length; k++) {
				let rolePermissions: any[] = []
				result.map((item: any) => {
					if (
						!rolePermissions.some(
							(menu: any) => menu.menuId === item[0]
						)
					) {
						rolePermissions.push({
							menuId: item[0],
							actions: [],
						})
					}
				})

				result.map((item: any, i: number) => {
					rolePermissions.map((item1: any, j: number) => {
						if (item[0] === item1.menuId) {
							rolePermissions[j].actions.push({
								actionName: item[1],
								available: item[k] === "TRUE" ? true : false,
							})
						}
					})
				})
				roles.push({
					role: header[k],
					permissions: rolePermissions,
				})
			}
			ConsoleLog("ReadProjAssignedRole", roles)

			const roleMatching = [
				{
					id1: "existing_proj_arch_admin",
					id2: ProjPermission.paa,
				},
				{
					id1: "existing_proj_admin",
					id2: ProjPermission.pa,
				},
				{
					id1: "existing_proj_user",
					id2: ProjPermission.pu,
				},
				{
					id1: "existing_proj_org_admin",
					id2: ProjPermission.oa,
				},
				{
					id1: "existing_proj_readonly",
					id2: ProjPermission.pr,
				},
				{
					id1: "existing_external_readonly",
					id2: ProjPermission.exr,
				},
				{
					id1: "existing_external_pbc_uploader",
					id2: ProjPermission.exp,
				},
			]

			const targetRole = roleMatching.find(
				(item: any) => item.id1 === userRoleId
			)?.id2
			const targetPermission = roles.find(
				(item: any) => item.role === targetRole
			).permissions
			// NOTE: re-organize (need to update)
			// TODO: is current role structure correct?
			// TODO: need to check the global permission part
			targetPermission.map((item: any) => {
				item["accessible"] = item.actions[0].available
			})

			const updatedResult = {
				...response,
				menuPermissions: targetPermission,
			}

			return targetPermission ? updatedResult : response
		} catch (error) {
			self.handleModelError({
				actionName,
				error,
				open: true,
			})
			return false
		}
	}),
})

export default ReadProjAssignedRole
