import React from "react"
import {
	StyledDLBreadCrumbs,
	BreadCrumbsArrow,
} from "./dl-bread-crumbs.sub-parts"

export default function DLBreadCrumbs1({
	rootName,
	handleBackToRoot,
	path,
	handleClickCrumb,
}: {
	rootName: string
	path: { id: string; name: string }[]
	handleBackToRoot: () => void
	handleClickCrumb: (id: string, index: number) => void
}) {
	let pathLength = path.length
	return (
		<StyledDLBreadCrumbs className="dl-bread-crumbs-container FR AC">
			<div className="FR AC crumb-container">
				<div
					className="group-list crumb click-able FR AC"
					onClick={handleBackToRoot}
				>
					{rootName}
				</div>
				{pathLength >= 1 && <BreadCrumbsArrow />}
			</div>
			{path.map((item: { id: string; name: string }, i: number) => {
				const { id, name } = item
				return (
					<div className="FR AC crumb-container" key={id + i}>
						<div
							className={`crumb FR AC click-able ${
								i + 1 === pathLength && "last-crumb"
							}`}
							onClick={() => handleClickCrumb(id, i)}
						>
							{name}
						</div>
						{i + 1 < pathLength && <BreadCrumbsArrow />}
					</div>
				)
			})}
		</StyledDLBreadCrumbs>
	)
}
