import React from "react"
import { observer } from "mobx-react-lite"
import { DLSnackbar } from "../../../components/basic-elements"
import { DLI18nProps } from "../../../common-models/types/common-props"

export default observer(function CommonSnackbarComponent({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
	const {
		type,
		open,
		setOpen,
		msgCode,
		message,
		autoHideDuration,
		autoHide,
	} = partialStore.responseSnackbar
	return (
		<DLSnackbar
			type={type}
			open={open}
			setOpen={setOpen}
			anchorLocation="RIGHT_TOP"
			message={message || i18n[msgCode]}
			autoHideDuration={autoHideDuration}
			autoHide={autoHide}
		/>
	)
})
