import React from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

const SourceProjectInfo = ({ partialStore }: { partialStore: any }) => {
	const selectedProjInfo = partialStore.rfSourceProjInfo
	const store = useRootStore()
	const orgStore = useOrgStore()

	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue

	const projInfo = orgStore.projects.formattedProjectDetails(
		dateFormat,
		timeZone
	)

	return (
		<StyledSourceProjectInfo>
			<h3 className="info-title">1. Source Project Information</h3>

			<div className="proj-info">
				<InfoRow label="Group Name" value={projInfo.groupName} />
				<InfoRow
					label="Cabinet Name"
					value={selectedProjInfo.cabinetName}
				/>
				<InfoRow label="Client Name" value={projInfo.clientName} />
				<InfoRow
					label="Client Alias ID"
					value={projInfo.clientAliasId}
				/>
				<InfoRow label="Project Title" value={projInfo.projTitle} />
				<InfoRow label="Project ID" value={projInfo.projAliasId} />
				<InfoRow label="Project Stage" value={projInfo.statusName} />
				<InfoRow
					label="Project Version"
					value={"v." + projInfo.version}
				/>
				<InfoRow label="Eng. Type" value={projInfo.engTypeName} />
				<InfoRow label="Risk Assessment" value={projInfo.raName} />
				<InfoRow label="Period Type" value={projInfo.periodName} />
				<InfoRow
					label="Period End Date"
					value={projInfo.periodEndDate}
				/>
				<InfoRow
					label="Archive Date Policy"
					value={projInfo.archPolicyPeriodName}
				/>
				<InfoRow
					label="Expected Report Date"
					value={projInfo.expectedReportDate}
				/>
				<InfoRow
					label="Final Report Date"
					value={projInfo.expectedReportDate}
				/>
				<InfoRow label="Created At" value={projInfo.createdAt} />
				<InfoRow label="Eng. Partner" value={projInfo.engPartner} />
			</div>
		</StyledSourceProjectInfo>
	)
}

export default SourceProjectInfo

const InfoRow = ({ label, value }: { label: string; value: any }) => {
	return (
		<div className="info-row">
			<div className="label-area">{label}</div>
			<div className="value-area">{value}</div>
		</div>
	)
}

const StyledSourceProjectInfo = styled.div`
	.proj-info {
		padding: 0 16px;
	}
	.info-title {
		margin-bottom: 8px;
	}
	.info-row {
		font-size: 12px;
		display: flex;
		padding: 0.3rem 0 0.3rem 0;
		.label-area {
			min-width: 9rem;
		}
	}
`
