import { types } from "mobx-state-tree"
// common models

// separated model actions
import UpdateForgotPassword from "./model-actions/update-forgot-pw"
// model common action
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import { resetPwApiType } from "./forgot-pw.apis"

const ForgotPasswordModel = (apiResetPassword: resetPwApiType) =>
	types
		.model({})
		// CRUD actions (main actions)
		.actions((self) => UpdateForgotPassword(self, apiResetPassword))
		// model common actions
		.actions(CommonModelActions)

export default ForgotPasswordModel
