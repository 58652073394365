import { flow } from "mobx-state-tree"
import { GraphQLResult } from "@aws-amplify/api"
import {
	postsByParent,
	PostsByParentQuery,
} from "../../../graphql/Post/queries"
import { callGraphQL } from "@datalobby/common"

const ReadProjBoardList = (self: any) => ({
	readProjBoardList: flow(function* (projId: string) {
		const actionName = "readProjBoardList"

		function setPostType(result: GraphQLResult<PostsByParentQuery>) {
			return result.data?.postsByParent
		}

		try {
			const postData = yield callGraphQL<PostsByParentQuery>(
				postsByParent,
				{
					parentId: projId,
					deleted: false,
				}
			)
			const posts = setPostType(postData)

			return posts
		} catch (error) {
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadProjBoardList
