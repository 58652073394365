// NOTE: Reference for sorting
// https://www.sitepoint.com/sort-an-array-of-objects-in-javascript/

import { ConsoleLog } from ".."

export function compareValues(key: string, order = "asc") {
	return function innerSort(a: any, b: any) {
		if (!a.hasOwnProperty(key) || !b.hasOwnProperty(key)) {
			// property doesn't exist on either object

			return 0
		}

		const varA = typeof a[key] === "string" ? a[key].toUpperCase() : a[key]
		const varB = typeof b[key] === "string" ? b[key].toUpperCase() : b[key]

		let comparison = 0
		if (varA > varB) {
			comparison = 1
		} else if (varA < varB) {
			comparison = -1
		}

		return order === "desc" ? comparison * -1 : comparison
	}
}

export function getTreeFromFlatData(
	flatData: {
		id: string
		parentId: null | string
		title?: string
		detail?: any
		index?: number
		[x: string]: any
	}[],
	sortBy?: string,
	order?: "asc" | "desc"
) {
	let tree: any = []
	let mappedFlatData: any = {}

	// if (sortBy !== undefined) {
	//     flatData
	//         .slice()
	//         // .sort(compareValues(sortBy, order))
	//         .map((flatItem) => {
	//             mappedFlatData[flatItem.id] = {
	//                 ...flatItem,
	//                 children: [],
	//             }
	//         })
	// } else {
	flatData.map((flatItem) => {
		mappedFlatData[flatItem.id] = {
			...flatItem,
			children: [],
		}
	})
	// }

	for (var id in mappedFlatData) {
		if (mappedFlatData.hasOwnProperty(id)) {
			const item = mappedFlatData[id]

			if (item.parentId === null || item.parentId === "folderId0") {
				// if the item is root level (depth 0)
				tree.push({ ...item, isParent: true })
			} else {
				// if the item has parent and the parent exist in the list
				if (mappedFlatData[item.parentId] !== undefined) {
					mappedFlatData[item.parentId]["children"].push({
						...item,
					})
					// to align folders forward
					mappedFlatData[item.parentId]["children"].sort(
						compareValues("isParent", "desc")
					)
				} else {
					// NOTE: This is for the search by store...
					// WARNING: Do not try search by this function. It makes many problems
					// ex: How to add parent? If add parent by child, how can we sort it?
					// recommand: hide not matched result on the component
					ConsoleLog(
						"getTreeFromFlatData",
						`: ( this item's parent not exist on this list. itemId: ${item.id} | parentId: ${item.parentId}`
					)
				}
			}
		}
	}
	if (sortBy !== undefined) {
		tree.sort(compareValues(sortBy, order))
	}

	return tree
}

export function getTreeFromFlatData2(
	flatData: {
		id: string
		parentId: null | string
		title?: string
		expanded?: boolean
	}[],
	sortBy?: string
) {
	let tree: any = []
	let mappedFlatData: any = {}

	flatData.map((flatItem) => {
		mappedFlatData[flatItem.id] = {
			...flatItem,
			children: [],
		}
	})

	for (var id in mappedFlatData) {
		if (mappedFlatData.hasOwnProperty(id)) {
			const item = mappedFlatData[id]

			if (item.parentId === null) {
				// if the item is root level (depth 0)
				tree.push(item)
			} else {
				// if the item has parent
				mappedFlatData[item.parentId]["children"].push(item)
			}
		}
	}

	if (sortBy) {
		// sort parent
		let sortedTree = tree.sort(compareValues(sortBy))
		// sort children
		sortedTree.map((parent: any) => {
			let sortedChildren = parent.children.sort(compareValues(sortBy))
			parent.children = sortedChildren
		})
		return sortedTree
	}

	return tree
}
