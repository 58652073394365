import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { ProjCheckinApiProps } from "../proj-checkin.apis"

const RequestProjToken = (
	self: any,
	requestToken: ProjCheckinApiProps["apiRequestToken"]
) => ({
	requestProjToken: flow(function* (projId: string, userId: string) {
		const actionName = "requestProjToken"
		// 1.
		if (
			projId === undefined ||
			projId === "" ||
			userId === undefined ||
			userId === ""
		) {
			alert(
				`(${actionName}) undefined props exist: ${projId} | ${userId}`
			)
			console.error(
				`(${actionName}) undefined props exist: ${projId} | ${userId}`
			)
			return
		}
		//
		//
		// organize params
		const projNumberId = idToNumber(projId, IdType.project)
		const userNumberId = idToNumber(userId, IdType.user)
		//
		//
		// 2.
		if (
			typeof projNumberId === "number" &&
			typeof userNumberId === "number"
		) {
			try {
				const params = {
					UserId: userNumberId,
					ClientId: projNumberId,
				}
				const response = yield requestToken(params)

				return response
			} catch (error) {
				self.handleTokenError({
					actionName,
					error,
					open: true,
				})
				return
			}
		} else {
			alert(
				`(${actionName}) invalid prop types: ${projNumberId} | ${userNumberId}`
			)
			console.error(
				`(${actionName}) invalid prop types: ${projNumberId} | ${userNumberId}`
			)
		}
	}),
})

export default RequestProjToken
