import React from "react"
import Icon from "@mdi/react"
import { mdiAlertOutline } from "@mdi/js"
//
import styled from "styled-components"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export default function ForgotPwFail({
	i18n,
}: {
	i18n: { [x: string]: string }
}) {
	const store = useRootStore()
	const { twFailRes1, twFailRes2, twFailRes3 } = i18n
	return (
		<StyledForgotPwFail className="card-body">
			<Icon path={mdiAlertOutline} size={5} color="#ffffff" />
			<div className="title heading1">{twFailRes1}</div>
			<div className="description">{twFailRes2}</div>
			<div className="registered-email">
				{twFailRes3}: {store.forgotPw.email}
			</div>
		</StyledForgotPwFail>
	)
}

const StyledForgotPwFail = styled.div``
