import { flow } from "mobx-state-tree"
import { putRequest } from "../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"

const UpdateUserLanguage = (self: any) => ({
	updateUserLanguage: flow(function* (
		country: string,
		language: "kr" | "en",
		userId: number
	) {
		const actionName = "updateUserLanguage"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const payload = {
			CountryCode: country,
			LanguageCode: language,
			UserID: userId,
		}
		try {
			const endpoint = "/UpdateUserLanguage"
			const response = yield putRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.loginToken,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateUserLanguage
