import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLDialog,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import { ProjectInfoContainer } from "./ProjectInfoContainer"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { initialProjectDetail } from "../../../../../service-modules/project-module/data-model/project-detail.data-model"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { saveLatestProjToken } from "../../../../../library/token-utils"

export default observer(function RepairReplicaDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const partialStore = orgStore.archiveMgmt

	const dnt = store.global.getDntFormat
	const projectId = orgStore.archiveMgmt.selectedProj
	const projectInfo = partialStore.getClientInfoById(
		projectId,
		dnt.dateFormat,
		dnt.timeZone,
		dnt.timeFormat
	)

	const userId = orgStore.checkin.orgUserId
	const orgId = orgStore.checkin.orgInfo.orgId

	useEffect(() => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const projNumId = idToNumber(projectId, IdType.project)

		// to hide the pdf file list
		partialStore.responses.setResponse("getPreConditionStatus", {
			actionName: "getPreConditionStatus",
			status: ActionStatus.standby,
		})

		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
					} else {
						ConsoleLog(
							"Cannot request the pre-process condition status. Failed to request project token"
						)
					}
					// NOTE: After API update, remain only the below one line
					partialStore.getPreConditionStatus()
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the pre-process condition status. Project ID or User ID is incorrect."
			)
		}
	}, [])

	useEffect(() => {
		// to get the replica ID
		ConsoleLog("getProjectDetails for replica ID")
		orgStore.projects.setProjectDetails(initialProjectDetail) //Reset to initial
		orgStore.projects.getProjectDetails(projectId)
	}, [projectId])

	const handleRepairReplica = (replicaId: string | null) => {
		if (replicaId) {
			partialStore.repairReplica(projectId, replicaId, orgId, userId)
		} else {
			alert("Cannot find the previous replica ID. Plesae try again")
		}
	}

	const handleRegenPreProcessPdfs = () => {
		const userId = orgStore.checkin.orgUserId
		const projId = orgStore.archiveMgmt.selectedProj
		partialStore.regenPreProcessPdfs(projId, userId)
	}

	const actionStatus = partialStore.getActionStatus

	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue

	// NOTE: This one to get the replica ID
	const projectDetail = orgStore.projects.formattedProjectDetails(
		dateFormat,
		timeZone
	)
	const replicaId = projectDetail.replicaId
	const preProcessStatus = partialStore.preConditionData.isPreProcessed

	return (
		<DLDialog
			eleTestId="repair-replica-dialog"
			isOpen={partialStore.repairReplicaDialog}
			setIsOpen={partialStore.setRepairReplicaDialog}
			showCloseBtn={true}
			dialogTitle="Repair Broken Replica"
			dialogContents={
				<RepairReplicaDialogContainer
					projInfo={projectInfo}
					projDetail={projectDetail}
					partialStore={partialStore}
					handleRegenPreProcessPdfs={handleRegenPreProcessPdfs}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			// main action
			actionBtn="Repair"
			handleAction={() => handleRepairReplica(replicaId)}
			showSpinner={
				actionStatus("regenPreProcessPdfs") === ActionStatus.loading ||
				actionStatus("getPreConditionStatus") ===
					ActionStatus.loading ||
				actionStatus("repairReplica") === ActionStatus.loading
			}
			cannotUpdate={
				actionStatus("regenPreProcessPdfs") === ActionStatus.loading ||
				actionStatus("getPreConditionStatus") ===
					ActionStatus.loading ||
				actionStatus("repairReplica") === ActionStatus.loading
			}
			actionReady={
				preProcessStatus &&
				(actionStatus("repairReplica") !== ActionStatus.loading ||
					actionStatus("repairReplica") !== ActionStatus.success) &&
				replicaId !== null
			}
		/>
	)
})

const RepairReplicaDialogContainer = observer(
	({
		projInfo,
		projDetail,
		partialStore,
		handleRegenPreProcessPdfs,
	}: {
		projInfo: any
		projDetail: any
		partialStore: any
		handleRegenPreProcessPdfs: any
	}) => {
		const isPreProcessPdfGeneated =
			partialStore.preConditionData.isPreProcessed

		return (
			<StyledRepairReplicaContainer>
				<ProjectInfoContainer projInfo={projInfo} />
				<div style={{ height: "3rem" }} />
				{isPreProcessPdfGeneated ? (
					<div>
						<p data-testid="msg-ask-confirmation">
							This restoration will create a new replica based on
							this archived project and delete the existing
							replica. Are you sure you want to proceed?
						</p>
						<br />
						<span data-testid="msg-note">
							*This restoration does not affect archived project
							<br />
							*Please check the exiting replica status first
						</span>
					</div>
				) : (
					<div>
						<span
							style={{ color: "red" }}
							data-testid="msg-pdf-not-exist"
						>
							!Pre-process PDF files does not exist
						</span>
						<br />
						<span data-testid="msg-request">
							PDF files are required to repair replica.
							<br />
							Please click the button to generate the PDF files.
						</span>
						<br />
						<DLButton
							eleTestId="generate-pdf-btn"
							size="thin"
							color="primary"
							clickHandler={handleRegenPreProcessPdfs}
						>
							Regenerate PDF
						</DLButton>
					</div>
				)}
			</StyledRepairReplicaContainer>
		)
	}
)

const StyledRepairReplicaContainer = styled.div``
