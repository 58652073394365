import { types } from "mobx-state-tree"
// Archive PreProcess
import ArchivePreProcessViewModel from "./archive-pre-process.view-model"
import ArchivePreProcessModel from "./archive-pre-process.model"
import ArchivePreProcessUiHelper from "./archive-pre-process.ui-helper"
import * as api from "./archive-pre-process.apis"
import { DefaultPreConditionData } from "./data-models/archive-pre-process.data-model"

export const initialStore = {
	preConditionData: DefaultPreConditionData,
	archiveDetails: {
		epName: "",
		archivedDate: "",
		archivedBy: "",
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

export const ArchivePreProcessStore = ({
	apiCheckPreConditionStatus,
	apiRemovePreConditionFiles,
	apiStartPreProcess,
	apiArchiveProject,
}: api.ArchPreProcessApiProps) =>
	types
		.compose(
			ArchivePreProcessModel({
				apiCheckPreConditionStatus,
				apiRemovePreConditionFiles,
				apiStartPreProcess,
				apiArchiveProject,
			}),
			ArchivePreProcessViewModel,
			ArchivePreProcessUiHelper
		)
		.named("ArchivePreProcessStore")

const ArchivePreProcessStoreInstance = ArchivePreProcessStore({
	apiCheckPreConditionStatus: api.preConditionStatus,
	apiRemovePreConditionFiles: api.removePreConditionFiles,
	apiStartPreProcess: api.startPreProcess,
	apiArchiveProject: api.archiveProject,
}).create(initialStore)

export default ArchivePreProcessStoreInstance
