import React, { useEffect } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { useParams, Link } from "react-router-dom"
import { DLButton, DLSpinner } from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiArrowLeft } from "@mdi/js"
import { PageContainer } from "../../../../../components/app-frame-elements"
import styled from "styled-components"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import Iframe from "react-iframe"

export default observer(function PassedAdjustmentView() {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	let { parentId } = useParams<{ parentId?: string }>()
	const actionName = "getPassedAdjustmentUrl"

	useEffect(() => {
		const role = projStore.checkin.assignedRole.roleId
		const isArchived = projStore.projInfo.projInfo.isArchived
		const finYear = projStore.projInfo.projInfo.financialYear // ?Is this the right one
		let accessRight
		if (role === "existing_proj_arch_admin" || isArchived) {
			accessRight = 1
		} else {
			accessRight = 0
		}
		if (parentId) {
			projStore.passedAdjustments.getPassedAdjustmentUrl(
				parentId,
				accessRight,
				finYear
			)
		}
	}, [])

	const contentsHeight = store.ui.pageContentsAreaHeight
	const fetchingStatus =
		projStore.passedAdjustments.getActionStatus(actionName)
	return (
		<StyledPassedAdjustmentView contentsHeight={contentsHeight - 300}>
			<PageContainer hasToolbar={false}>
				{fetchingStatus === ActionStatus.loading && (
					<div
						className="FR AC JC"
						style={{
							width: "100%",
							height: contentsHeight - 22,
						}}
					>
						<DLSpinner />
					</div>
				)}
				{fetchingStatus === ActionStatus.success && (
					<>
						{parentId && (
							<>
								<div className="FR AC JSB">
									<Link
										to={`${defaultUrl}/financial-statements/passed-adjustments`}
									>
										<DLButton
											variant="text"
											eleTestId="back-to-list"
											startIcon={
												<Icon
													path={mdiArrowLeft}
													size={0.8}
												/>
											}
										>
											Back to the list
										</DLButton>
									</Link>
								</div>

								<StyledPassedAdjustmentViewInfo
									fileInfo={projStore.passedAdjustments.viewPassedAdjustmentDetails(
										parentId
									)}
								/>
							</>
						)}
						<div className="adjustment-details">
							<Iframe
								data-testid="iframe-passed-adjustment"
								url={projStore.passedAdjustments.url}
								width="100%"
								height={`${contentsHeight - 110}px`}
							/>
						</div>
					</>
				)}
			</PageContainer>
			<CommonSnackbarComponent
				i18n={{ dummy: "dummy" }} // this component seems cannot take i18n , or let's try to get the i18n on the router...
				partialStore={projStore.rationales}
			/>
		</StyledPassedAdjustmentView>
	)
})

const StyledPassedAdjustmentViewInfo = ({ fileInfo }: { fileInfo: any }) => {
	return (
		<StyledStyledPassedAdjustmentViewInfo className="about-parent">
			<div className="FR AC">
				<div className="info-row FR AC first">
					<span className="bold">Passed Adjustment Number: </span>
					<div>{fileInfo?.ajeNumber}</div>
				</div>
				<div className="info-row FR AC">
					<span className="bold">
						Passed Adjustment Description:{" "}
					</span>
					<div>{fileInfo?.ajeDesc}</div>
				</div>
			</div>
			<div className="FR AC">
				<div className="info-row FR AC first">
					<span className="bold">Passed Adjustment Type: </span>
					<div>{fileInfo?.ajeType}</div>
				</div>
				<div className="info-row FR AC">
					<span className="bold">Workpaper Reference: </span>
					<div>{fileInfo?.wpRef}</div>
				</div>
			</div>
		</StyledStyledPassedAdjustmentViewInfo>
	)
}

const StyledStyledPassedAdjustmentViewInfo = styled.div`
	border-top: 1px solid ${(props) => props.theme.shade20};
	border-bottom: 1px solid ${(props) => props.theme.shade20};
	padding: 1rem 0;
	margin: 1rem 0;
	.info-row {
		margin-bottom: 0.5rem;
		.bold {
			font-weight: 600;
			margin-right: 0.5rem;
		}
	}
	.first {
		flex-basis: 22rem;
	}
`

const StyledPassedAdjustmentView = styled.div<{ contentsHeight: number }>`
	.quill {
		height: ${(props) => props.contentsHeight}px;
	}
`
