import { types } from "mobx-state-tree"
import { ActionStatus } from "./enumerations/common-enums"

const Response = types.model({
	actionName: types.identifier, // key
	status: types.enumeration<ActionStatus>(Object.values(ActionStatus)),
	// optional things
	code: types.optional(types.number, 0),
	message: "",
	customMessage: "",
})

const Responses = types
	.model({
		responses: types.map(Response),
	})
	.actions((self) => ({
		// NOTE: putResponse and setResponse do same thing. Just a count of props is different.
		putResponse(response: any) {
			self.responses.put(response)
			// 'put' is MST feature. It will infer the key by identifier on the model
		},
		setResponse(
			actionName: string,
			response: {
				actionName: string
				status: ActionStatus
				code?: number
				message?: string
				customMessage?: string
			}
		) {
			self.responses.set(actionName, response)
			// 'set' is javascrtip feature for Map. It requires key and its object. In this case, `actionName` is key
		},
		removeResponse(actionName: string) {
			self.responses.delete(actionName)
		},
	}))
	.views((self) => ({
		getResponse(actionName: string) {
			return self.responses.get(actionName)
		},
		getCustomMessage(actionName: string) {
			const currentResponse = self.responses.get(actionName)
			const currentStatusCode = currentResponse?.code
			const customMessage = currentResponse?.customMessage

			if (currentResponse?.customMessage) {
				return currentStatusCode + " " + customMessage
			} else {
				return (
					currentStatusCode +
					" No Custom Message | " +
					currentResponse?.message
				)
			}
		},
		getActionStatus(actionName: string) {
			return self.responses.get(actionName)?.status
		},
	}))

export default Responses
