import React, { ReactNode } from "react"
import FormControl from "@material-ui/core/FormControl"
import FormHelperText from "@material-ui/core/FormHelperText"
import Input from "@material-ui/core/Input"
import InputLabel from "@material-ui/core/InputLabel"
import styled from "styled-components"
import DLSystemMsg from "../system-msg/DLSystemMsg"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"

interface DLInputFieldProps {
	eleId?: string
	/** text | password | number | search | date | week | month */
	eleType?: string
	eleName?: string
	eleClassName?: string
	/** required */
	eleTestId: string
	eleLabel?: string
	eleValue?: string
	eleHelperText?: string
	eleHandleChange?: any
	eleError?: boolean
	warningMsg?: string
	/** default | blue | orange | coral | red */
	warningType?: "default" | "blue" | "orange" | "coral" | "red"
	eleReadOnly?: boolean
	elePlaceholder?: string
	eleRequired?: boolean
	requestInput?: boolean
	eleFullWidth?: boolean
	eleEndAdornment?: ReactNode
	eleStartAdornment?: ReactNode
	eleFieldHeight?: string | number
	variant?: "standard" | "outlined" | "filled"
	// eleRowsMax?: string | number
}
/**
 * #### What is different with `DLTextField` ?
 *
 * `DLTextField` is using preset material UI TextField (combined component)
 * <br/>And this `DLInputField` is using partial material UI component.
 * <br/>To provide `data-testid` by `eleTestId`, this component is using `inputProps` property.
 *
 * #### Note for test
 * - You can find input element by `eleTestId` (`data-testid`)
 */
export default function DLTextArea({
	eleId = undefined,
	eleType = "text",
	eleName = undefined,
	eleClassName = undefined,
	eleTestId = "testId-DLInputField",
	eleLabel = undefined,
	eleValue = undefined,
	eleHelperText = undefined,
	eleHandleChange = undefined,
	eleError = undefined,
	warningMsg = undefined,
	warningType = undefined,
	eleReadOnly = undefined,
	elePlaceholder = undefined,
	eleRequired = undefined,
	requestInput = undefined,
	eleFullWidth = true,
	eleEndAdornment = undefined,
	eleStartAdornment = undefined,
	eleFieldHeight = 8,
	variant = "standard",
}: // eleRowsMax = undefined
DLInputFieldProps) {
	return (
		<StyledDLTextField className={eleClassName}>
			<FormControl
				error={eleError || warningType === "red"}
				disabled={eleReadOnly}
				fullWidth={eleFullWidth}
			>
				{eleLabel && (
					<InputLabel htmlFor={eleId} required={eleRequired}>
						{eleLabel}
					</InputLabel>
				)}
				<Input
					multiline
					// rowsMax={eleRowsMax}
					rows={eleFieldHeight}
					id={eleId}
					type={eleType}
					name={eleName}
					value={eleValue}
					onChange={eleHandleChange}
					placeholder={elePlaceholder}
					aria-describedby="component-helper-text"
					required={eleRequired}
					endAdornment={eleEndAdornment}
					startAdornment={eleStartAdornment}
					inputProps={{ "data-testid": eleTestId, variant: variant }}
					inputComponent="textarea"
				/>
				{eleHelperText && (
					<FormHelperText id="component-helper-text">
						{eleHelperText}
					</FormHelperText>
				)}
				{requestInput && (
					<div className="request-input" role="alert">
						This field is required
					</div>
				)}
				{warningMsg && !warningType && (
					<div className="error-message" role="alert">
						{warningMsg}
					</div>
				)}
				{warningMsg && warningType === "default" && (
					<DLSystemMsg
						eleTestId={eleTestId + "_alert"}
						type={MessageColorType.default}
						msg={warningMsg}
						eleRole="alert"
					/>
				)}
				{warningMsg && warningType === "blue" && (
					<DLSystemMsg
						eleTestId={eleTestId + "_alert"}
						type={MessageColorType.blue}
						msg={warningMsg}
						eleRole="alert"
					/>
				)}
				{warningMsg && warningType === "orange" && (
					<DLSystemMsg
						eleTestId={eleTestId + "_alert"}
						type={MessageColorType.orange}
						msg={warningMsg}
						eleRole="alert"
					/>
				)}
				{warningMsg && warningType === "coral" && (
					<DLSystemMsg
						eleTestId={eleTestId + "_alert"}
						type={MessageColorType.coral}
						msg={warningMsg}
						eleRole="alert"
					/>
				)}
				{warningMsg && warningType === "red" && (
					<DLSystemMsg
						eleTestId={eleTestId + "_alert"}
						type={MessageColorType.red}
						msg={warningMsg}
						eleRole="alert"
					/>
				)}
			</FormControl>
		</StyledDLTextField>
	)
}

export const StyledDLTextField = styled.div`
	color: inherit;
	&.custom-readonly {
		input {
			cursor: not-allowed;
		}
	}
	&.custom-required {
		label {
			/* color: ${(props) => props.theme.warning}; */
		}
	}
	&.warning {
		input {
			color: orange;
			border-bottom: 2px solid orange;
			z-index: 999;
		}
	}
	&.critical {
		input {
			color: red;
			border-bottom: 2px solid red;
			z-index: 999;
		}
	}
	span {
		color: ${(props) => props.theme.warning};
		font-weight: 900;
	}
	.error-message {
		margin-top: 0.2rem;
		color: ${(props) => props.theme.shared.messageRed};
	}
	.request-input {
		margin-top: 0.2rem;

		color: ${(props) => props.theme.shared.messageOrange};
	}
`
