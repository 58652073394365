import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const ReadFinalSignoffHistory = (self: any) => ({
	readFinalSignOffHistory: flow(function* (menuId: DLProjSubMenus) {
		const actionName = "readFinalSignOffHistory-" + menuId

		const wpEndpoint = "/GetSignoffHistoryforWorkpaper"
		const pfEndpoint = "/GetSignoffHistoryforPermanentFile"

		try {
			const endpoint =
				menuId === DLProjSubMenus.wp ? wpEndpoint : pfEndpoint
			const response = yield getRequest({
				url: endpoint,
				params: {},
				tokenType: TOKEN_TYPE.projToken,
			})
			// ConsoleLog("actionName, "__response", response)
			return response
		} catch (error) {
			// ConsoleLog("actionName, "__error", error)
			self.handleModelError({
				actionName,
				error,
				open: true,
			})

			return false
		}
	}),
})

export default ReadFinalSignoffHistory
