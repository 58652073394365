import React from "react"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLIconButton } from "../../../../components/basic-elements"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"
import { dateRangeFilter } from "../../../../library/react-table-utils"
import { Icon } from "@mdi/react"
import { mdiChevronUp, mdiChevronDown, mdiFile } from "@mdi/js"
import {
	DLIcon,
	DLIconName,
} from "../../../../components/basic-elements/icons/common-icons"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { useHistory } from "react-router-dom"

export const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "requestedDate":
		case "dueDate":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const PBCTableColumns = (
	handleRemove: (rowId: string) => void,
	handleEdit: (rowId: string) => void,
	partialStore: any,
	i18n: DLI18nProps
) => {
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	let history = useHistory()

	const handleLink = () => {
		history.push(defaultUrl + "/prepared-by-client/pbc-team-files")
	}

	const handleClientLink = () => {
		history.push(defaultUrl + "/prepared-by-client/pbc-client-files")
	}

	return [
		{
			header: "",
			accessorKey: "area",
			width: 32,
			cell: (props: any) => {
				const expanded = props.row.getIsExpanded()
				console.log(props.row.getIsGrouped(), "rowrow1")
				return (
					<>
						{props.row.getIsGrouped() ? (
							<div
								className="FR AC"
								onClick={props.row.getToggleExpandedHandler()}
							>
								{expanded ? (
									<>
										<Icon
											path={mdiChevronUp}
											size={0.8}
											data-testid="folder-chevron-up"
										/>
									</>
								) : (
									<>
										<Icon
											path={mdiChevronDown}
											size={0.8}
											data-testid="folder-chevron-down"
										/>
									</>
								)}
								<span
									style={{ marginLeft: "0.6rem" }}
									data-testid="folder-name"
								>
									{props.getValue()}
								</span>
							</div>
						) : (
							<div>{props.getValue()}</div>
						)}
					</>
				)
			},
		},
		// {
		// 	header: i18n.twSelect || "Select",
		// 	accessorKey: "select",
		// 	size: 30,
		// 	cell: (props: any) => {
		// 		return <div data-testid="select-cell">{props.getValue()}</div>
		// 	},
		// },
		{
			header: "Control Ref Num",
			accessorKey: "controlRefNo",
			cell: (props: any) => {
				return <div data-testid="area-cell">{props.getValue()}</div>
			},
		},
		{
			header: i18n.twDescription || "Description",
			accessorKey: "description",
			cell: (props: any) => {
				return (
					<div data-testid="description-cell">{props.getValue()}</div>
				)
			},
		},
		{
			header: i18n.twRequestedDate || "Requested Date",
			accessorKey: "requestedDate",
			filterFn: dateRangeFilter,
			cell: (props: any) => {
				const cellId = props.cell.column.id + "-cell"
				if (props.row.getIsGrouped()) {
					return ""
				}
				return (
					<div data-testid={cellId}>
						{props.getValue() ? props.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: i18n.twDueDate || "Due Date",
			accessorKey: "dueDate",
			filterFn: dateRangeFilter,
			cell: (props: any) => {
				const cellId = props.cell.column.id + "-cell"
				if (props.row.getIsGrouped()) {
					return ""
				}
				return (
					<div data-testid={cellId}>
						{props.getValue() ? props.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: i18n.twReminderDays || "Reminder (days)",
			columns: [
				{
					header: "B",
					accessorKey: "before",
					cell: (props: any) => {
						const cellId = props.cell.column.id + "-cell"
						if (props.row.getIsGrouped()) {
							return ""
						}
						return (
							<div data-testid={cellId}>{props.getValue()}</div>
						)
					},
				},
				{
					header: "A",
					accessorKey: "after",
					cell: (props: any) => {
						const cellId = props.cell.column.id + "-cell"
						if (props.row.getIsGrouped()) {
							return ""
						}
						return (
							<div data-testid={cellId}>{props.getValue()}</div>
						)
					},
				},
				{
					header: "R",
					accessorKey: "recur",
					cell: (props: any) => {
						const cellId = props.cell.column.id + "-cell"
						if (props.row.getIsGrouped()) {
							return ""
						}
						return (
							<div data-testid={cellId}>{props.getValue()}</div>
						)
					},
				},
			],
		},
		{
			header: i18n.twRequestedBy || "Requested By",
			columns: [
				{
					header: "Name",
					accessorKey: "requestedBy",
					cell: (props: any) => {
						const cellId = props.cell.column.id + "-cell"
						if (props.row.getIsGrouped()) {
							return ""
						}
						return (
							<div data-testid={cellId}>{props.getValue()}</div>
						)
					},
				},
			],
		},
		{
			header: i18n.twAudUploadDoc || "PBC Team Files",
			accessorKey: "uploadDoc",
			cell: (props: any) => {
				if (props.row.getIsGrouped()) {
					return ""
				}
				return (
					<div data-testid="uploadDoc-cell">
						<DLIconButton
							eleTestId="team-files"
							tooltipText="PBC Team Files"
							clickHandler={handleLink}
						>
							<Icon path={mdiFile} size={0.8} color="blue" />
						</DLIconButton>
					</div>
				)
			},
		},
		{
			header: i18n.twRequestedTo || "Requested To",
			columns: [
				{
					header: "Name",
					accessorKey: "requestedTo",
					cell: (props: any) => {
						const cellId = props.cell.column.id + "-cell"
						if (props.row.getIsGrouped()) {
							return ""
						}
						return (
							<div data-testid={cellId}>{props.getValue()}</div>
						)
					},
				},
			],
		},
		{
			header: i18n.twClientUploadDoc || "PBC Client Files",
			accessorKey: "clientDoc",
			cell: (props: any) => {
				if (props.row.getIsGrouped()) {
					return ""
				}
				return (
					<div data-testid="clientDoc-cell">
						<div data-testid="uploadDoc-cell">
							<DLIconButton
								eleTestId="client-files"
								tooltipText="PBC Client Files"
								clickHandler={handleClientLink}
							>
								<Icon path={mdiFile} size={0.8} color="red" />
							</DLIconButton>
						</div>
					</div>
				)
			},
		},
		{
			header: i18n.twNotes || "Notes",
			accessorKey: "notes",
			cell: (props: any) => {
				if (props.row.getIsGrouped()) {
					return ""
				}
				return <div data-testid="notes-cell">{props.getValue()}</div>
			},
		},
		{
			header: i18n.twStatus || "Status",
			accessorKey: "status",
			cell: (props: any) => {
				if (props.row.getIsGrouped()) {
					return ""
				}
				return <div data-testid="status-cell">{props.getValue()}</div>
			},
		},
		{
			header: i18n.twActions || "Actions",
			cell: (props: any) => {
				if (props.row.getIsGrouped()) {
					return ""
				}
				const rowId = props.row.original.id
				return (
					<div data-testid="actions-cell" className="FR">
						<DLIconButton
							eleTestId="edit-btn"
							tooltipText="Edit"
							clickHandler={() => handleEdit(rowId)}
						>
							<DLIcon name={DLIconName.edit} />
						</DLIconButton>
						<DLIconButton
							eleTestId="delete-btn"
							tooltipText="Delete"
							clickHandler={() => handleRemove(rowId)}
						>
							<DLIcon name={DLIconName.delete} />
						</DLIconButton>
					</div>
				)
			},
		},
	]
}

export default PBCTableColumns
