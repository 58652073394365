import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import styled from "styled-components"
import {
	DLSpinner,
	DLButton,
	DLSingleSelect,
	InputWithLabel,
} from "../../../../../components/basic-elements"
import { OrgSysSettingItemProps } from "../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function EmailNotification({
	partialStore,
	actionStatus,
	permission,
	dateFormat,
	i18n,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
	dateFormat: string
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const [selectedEOD, setSelectedEOD] = useState<OrgSysSettingItemProps>(
		partialStore.atTheEndOfDay
	)
	const [selectedEveryOtherDay, setSelectedEveryOtherDay] = useState<
		OrgSysSettingItemProps
	>(partialStore.everyOtherDay)
	const [selectedEndOfWeek, setSelectedEndOfWeek] = useState<
		OrgSysSettingItemProps
	>(partialStore.atTheEndOfWeek)
	const [selectedEvery3Days, setSelectedEvery3Days] = useState<
		OrgSysSettingItemProps
	>(partialStore.every3Days)
	const [selectedDaily, setSelectedDaily] = useState<OrgSysSettingItemProps>(
		partialStore.daily
	)

	const handleEOD = (event: any) => {
		let tempData = { ...selectedEOD }
		tempData.uiValue = event.target.value
		tempData.value = event.target.value
		// ConsoleLog(tempData)
		setSelectedEOD(tempData)
	}
	const handleEveryOtherDay = (event: any) => {
		let tempData = { ...selectedEveryOtherDay }
		tempData.uiValue = event.target.value
		tempData.value = event.target.value
		setSelectedEveryOtherDay(tempData)
	}
	const handleEndOfWeek = (event: any) => {
		let tempData = { ...selectedEndOfWeek }
		tempData.uiValue = event.target.value
		tempData.value = event.target.value
		setSelectedEndOfWeek(tempData)
	}
	const handleEvery3Days = (event: any) => {
		let tempData = { ...selectedEvery3Days }
		tempData.uiValue = event.target.value
		tempData.value = event.target.value
		setSelectedEvery3Days(tempData)
	}
	const handleDaily = (event: any) => {
		let tempData = { ...selectedDaily }
		tempData.uiValue = event.target.value
		tempData.value = event.target.value
		setSelectedDaily(tempData)
	}

	const handleSubmit = () => {
		partialStore.editEmailNotificationSetting({
			atTheEndOfDay: selectedEOD,
			everyOtherDay: selectedEveryOtherDay,
			atTheEndOfWeek: selectedEndOfWeek,
			every3Days: selectedEvery3Days,
			daily: selectedDaily,
		})
	}

	const deactivateSaveButton = () => {
		if (
			partialStore.atTheEndOfDay.value === selectedEOD?.value &&
			partialStore.everyOtherDay.value === selectedEveryOtherDay?.value &&
			partialStore.atTheEndOfWeek.value === selectedEndOfWeek?.value &&
			partialStore.every3Days.value === selectedEvery3Days?.value &&
			partialStore.daily.value === selectedDaily?.value
		) {
			return true
		}
		return false
	}

	const handleCancel = () => {
		setSelectedEOD(partialStore.atTheEndOfDay)
		setSelectedEveryOtherDay(partialStore.everyOtherDay)
		setSelectedEndOfWeek(partialStore.atTheEndOfWeek)
		setSelectedEvery3Days(partialStore.every3Days)
		setSelectedDaily(partialStore.daily)
	}

	const {
		twEmailNotification,
		twSave,
		twCancel,
		twAtTheEndOfTheDay,
		twEveryOtherDay,
		twAtTheEndOfTheWeek,
		twEvery3Days,
		twDaily,
	} = i18n
	const timeZone = store.global.timeZone.uiValue

	return (
		<StyledDateAndTimeFormat>
			<div className="panel-title">{twEmailNotification}</div>
			{actionStatus === "LOADING" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{actionStatus === "SUCCESS" && (
				<div className="FR panel-contents-container">
					<div className="FC settings-container">
						<div className="setting-section FR AC">
							<InputWithLabel
								label={twAtTheEndOfTheDay}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="end-of-day-selection"
									eleValue={selectedEOD?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableOptions
									}
									eleOnChange={handleEOD}
								/>
							</InputWithLabel>
						</div>
						<div className="setting-section FR AC">
							<InputWithLabel
								label={twEveryOtherDay}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="every-other-day-selection"
									eleValue={selectedEveryOtherDay?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableOptions
									}
									eleOnChange={handleEveryOtherDay}
								/>
							</InputWithLabel>
						</div>
						<div className="setting-section FR AC">
							<InputWithLabel
								label={twAtTheEndOfTheWeek}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="end-of-week-selection"
									eleValue={selectedEndOfWeek?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableOptions
									}
									eleOnChange={handleEndOfWeek}
								/>
							</InputWithLabel>
						</div>
						<div className="setting-section FR AC">
							<InputWithLabel
								label={twEvery3Days}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="every-three-days-selection"
									eleValue={selectedEvery3Days?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableOptions
									}
									eleOnChange={handleEvery3Days}
								/>
							</InputWithLabel>
						</div>
						<div className="setting-section FR AC">
							<InputWithLabel
								label={twDaily}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="daily-selection"
									eleValue={selectedDaily?.value}
									eleFullWidth
									withLabel={false}
									optionList={
										partialStore.availableOptions
									}
									eleOnChange={handleDaily}
								/>
							</InputWithLabel>
						</div>
						<div className="modified-details">
							<span>
								*This configuration is last modified by{" "}
								{
									partialStore.viewModifiedDetails(
										dateFormat,
										timeZone
									).userName
								}{" "}
								[
								{
									partialStore.viewModifiedDetails(
										dateFormat,
										timeZone
									).userId
								}
								] on{" "}
								{
									partialStore.viewModifiedDetails(
										dateFormat,
										timeZone
									).modifiedDate
								}
							</span>
						</div>
						{/* --------- Save button --------- */}
						{permission.update && (
							<div className="FR JSA JC">
								<DLButton
									color="primary"
									eleTestId="noti-setting-cancel-button"
									clickHandler={() => handleCancel()}
									disabled={deactivateSaveButton()}
								>
									{twCancel}
								</DLButton>{" "}
								<DLButton
									color="primary"
									eleTestId="noti-setting-save-button"
									clickHandler={() => handleSubmit()}
									disabled={deactivateSaveButton()}
								>
									{twSave}
								</DLButton>
							</div>
						)}
					</div>
				</div>
			)}
			{/* Snackbar */}
			<CommonSnackbarComponent
				i18n={i18n}
				partialStore={partialStore}
			/>
		</StyledDateAndTimeFormat>
	)
})

const StyledDateAndTimeFormat = styled.div`
	width: 100%;
	.panel-title {
		height: ${(props) => props.theme.shared.baseUnit};
		font-weight: 900;
	}
	.settings-container {
		margin-right: 4rem;
		.setting-section {
			margin-bottom: 1rem;
			.label-and-input-container {
				min-width: 20rem;
				.label {
					width: 20rem;
				}
				.input-area {
					width: calc(100% - 8rem);
				}
			}

			.example {
				margin-left: 2rem;
				color: ${(props) => props.theme.shade40};
				padding-bottom: 1rem;
			}
		}
	}
	.modified-details {
		font-size: 0.8rem;
		margin-bottom: 1rem;
	}
`
