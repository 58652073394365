import { types } from "mobx-state-tree"

export const AccessMgmtUsers = types.model({
	// clientName: types.string,
	email: types.string,
	expiredDate: types.string,
	// fiscalYear: types.union(types.string, types.undefined), // TODO: @Prudhvi-0817: Do we need this financial year for readonly user...??
	isExportPerm: types.boolean,
	// isgrayed: types.boolean,
	// lastModifiedBy: types.string,
	// modifiedDate: types.string,
	// projectName: types.string,
	suspended: types.boolean,
	title: types.string,
	id: types.string,
	userId: types.string, // TODO: @Prudhvi-0817: What is different with this userId and the id at the line above?
	aliasId: types.string, // TODO: @Prudhvi-0817: Please update as aliasId and also the related parts
	name: types.string,
	userType: types.string,
	// version: types.string,
})

export const User = types.model({
	email: types.string,
	isGrayedOut: types.boolean,
	name: types.string,
	userId: types.string,
	userType: types.string,
	userAliasId: types.string,
})

export const UnarchiveResponse = types.model({
	unarchivedDateTime: types.union(types.string, types.null),
	engagementPartner: types.union(types.string, types.null),
	unarchivedBy: types.union(types.string, types.null),
})
