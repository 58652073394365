import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { DLSnackbar } from "../../../components/basic-elements"

export default observer(function EditorSnackbar() {
	const store = useRootStore()

	return (
		<DLSnackbar
			type={store.editor.responseSnackbar.type}
			open={store.editor.responseSnackbar.open}
			setOpen={store.editor.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={store.editor.responseSnackbar.message}
			autoHideDuration={store.editor.responseSnackbar.autoHideDuration}
			autoHide={store.editor.responseSnackbar.autoHide}
		/>
	)
})
