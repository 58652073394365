import { flow } from "mobx-state-tree"
import { OrgSetupClientsApiProps } from "../org-clients.apis"

const ReadClientGroups = (
	self: any,
	readGroups: OrgSetupClientsApiProps["apiReadGroups"]
) => ({
	readGroupsForClient: flow(function*(payload) {
		const actionName = "readGroupsForClient"
		// ConsoleLog(payload)
		try {
			const response = yield readGroups(payload)

			// ConsoleLog("readGroupsForClient response", response)
			return response
		} catch (error) {
			// console.error("readGroupsForClient error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadClientGroups
