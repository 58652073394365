import React, { useState } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { DLButton } from "../../../../components/basic-elements"
import AuditProgramTableColumns from "./AuditProgramTableColumns"
import AuditProgramActionBtns from "./AuditProgramActionBtns"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import DLDateRangepicker from "../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import * as XLSX from "xlsx"
import AddNewStepDialog from "./AddNewStepDialog"

export default observer(function AuditProgramEditor({
	partialStore,
	fromDialog,
}: {
	partialStore: any
	fromDialog: boolean
}) {
	const store = useRootStore()
	console.log(store, "store")

	const dntFormat = store.global.getDntFormat
	const dateFormat = dntFormat.dateFormat
	const contentsHeight = store.ui.contentsAreaHeight

	const [data, setData] = useState<any>([])
	const [selectedFile, setSelectedFile] = useState<string | null>(null)
	const [open, setOpen] = useState(false)
	const [selectedFileData, setSelectedFileData] = useState<any>(null)

	function handleFile(e: any) {
		var file = e.target.files[0]
		var reader = new FileReader()

		reader.onload = function (e) {
			if (e.target) {
				if (
					e.target.result !== null &&
					typeof e.target.result !== "string"
				) {
					var data = new Uint8Array(e.target.result)
					var workbook = XLSX.read(data, { type: "array" })

					const jsonData = XLSX.utils.sheet_to_json(
						workbook.Sheets.Sheet1,
						{ raw: false }
					)

					console.log(jsonData, "jsonData")
					let organizedList: any = []
					const hasEmptyFields = jsonData.every((item: any) =>
						Object.keys(item).includes("auditSteps")
					)

					if (!hasEmptyFields) {
						alert(
							"Excel file contains empty fields. Please input correct data."
						)
						return false
					}
					jsonData.map((item: any, i: any) => {
						organizedList.push({
							clientId: `fileId${i + 1}`,
							legend: item.legend,
							auditSteps: item.auditSteps,
							assertions: item.assertions,
							notes: item.notes,
							ref: item.ref,
							preparer: item.preparer,
							preparerDueDate: item.preparerDueDate,
							reviewer: item.reviewer,
							reviewerDueDate: item.reviewerDueDate,
						})
					})

					setData(organizedList)
				}
			} else {
				console.log("event target is null")
			}
		}
		reader.readAsArrayBuffer(file)
	}

	const handleSelectFilesFromLocal = (e: any) => {
		handleFile(e)
		// partialStore.setBulkClientFileReadStatus(ActionStatus.loading)
	}

	const handleRemove = (rowId: string) => {
		const filtered = data.filter((i: any) => i.id !== rowId)
		setData(filtered)
	}

	const handleEdit = (rowId: string) => {
		const filtered = data.find((i: any) => i.id === rowId)
		setSelectedFileData(filtered)
		setOpen(true)
	}

	const handleAdd = () => {
		setSelectedFileData(null)
		setOpen(true)
	}

	const handleCancel = () => {
		setData([])
	}

	console.log(selectedFile, "selectedFile")

	return (
		<StyledAuditProgramEditor>
			<AuditProgramActionBtns
				partialStore={partialStore}
				handleAdd={handleAdd}
				selectedFile={selectedFile}
				handleSelectFilesFromLocal={handleSelectFilesFromLocal}
				fromDialog={fromDialog}
			/>
			<div className="table-container">
				<ReactTableV8
					tableColumns={AuditProgramTableColumns({
						handleRemove,
						handleEdit,
						dateFormat,
						fromDialog,
					})}
					customFilters={(props: any) =>
						filterComponent({ ...props, dateFormat })
					}
					data={data}
					hasPagination={false}
					showPageSetting={false}
					height={contentsHeight}
					menuId="BulkAddClients"
					onRowClick={(e: any, row: any) => setSelectedFile(row.id)}
					getRowClass={(rowInfo: any) => {
						const id = rowInfo.original.id
						let className = ""
						if (selectedFile === id) {
							className = className + " selected"
						}

						return className
					}}
				/>
			</div>
			<div className="FR JR">
				{/* <DLButton
					eleTestId="preview-btn"
					// clickHandler={() =>}
					variant="outlined"
					color="primary"
					eleClassName="MR"
				>
					Preview Changes
				</DLButton> */}
				<DLButton
					eleTestId="save-btn"
					variant="outlined"
					color="primary"
					eleClassName="MR"
					disabled={data.length === 0}
					// clickHandler={() =>}
				>
					Save Changes
				</DLButton>
				<DLButton
					eleTestId="cancel-btn"
					variant="outlined"
					color="warning"
					disabled={data.length === 0}
					clickHandler={handleCancel}
				>
					Cancel Changes
				</DLButton>
			</div>
			{open && (
				<AddNewStepDialog
					open={open}
					setOpen={setOpen}
					data={data}
					setData={setData}
					selectedData={selectedFileData}
					fromDialog={fromDialog}
				/>
			)}
		</StyledAuditProgramEditor>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "preparerDueDate":
		case "reviewerDueDate":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const StyledAuditProgramEditor = styled.div`
	padding: 0 1rem;
	.table-container {
		margin-top: 1rem;
	}
	input[type="file"] {
		display: none;
	}
	.textarea {
		-moz-appearance: textfield-multiline;
		-webkit-appearance: textarea;
		// border: 1px solid gray;
		font: medium -moz-fixed;
		font: -webkit-small-control;
		height: 28px;
		overflow: auto;
		padding: 2px;
		resize: both;
		width: 400px;
	}
	.MR {
		margin-right: 5px;
	}
	.audit-program-action-area {
		margin-top: 5px;
	}
	.selected {
		background-color: #758fff;
		:hover {
			background-color: #758fff !important;
		}
	}
`
