import React, { useEffect, useCallback } from "react"
import { observer } from "mobx-react-lite"
import DLComboBoxWithCheckbox from "../../../../../components/basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { DLButton } from "../../../../../components/basic-elements"

const DashboardFilterDialogContents = observer(
	({
		partialStore,
		setOpen,
		i18n,
	}: {
		partialStore: any
		setOpen: any
		i18n: DLI18nProps
	}) => {
		const store = useRootStore()
		const menuId = DLOrgSubMenus.dash_graph
		const pageSettingName = `pageSetting_${menuId}`
		const needRefresh = partialStore.needRefresh

		useEffect(() => {
			const userId = localStorage.getItem("orgUserId")
			const pageSetting = localStorage.getItem("pageSetting")
			const pageSettingData = pageSetting ? JSON.parse(pageSetting) : []
			if (pageSettingData.some((item: any) => item.userId === userId)) {
				const data = pageSettingData.find(
					(item: any) => item.userId === userId
				)[pageSettingName]
				if (data) {
					partialStore.setYearGraphFilter(
						data.financialYear ? data.financialYear : []
					)
					partialStore.setPeriodTypeGraphFilter(
						data.periodTypes ? data.periodTypes : []
					)
					partialStore.setEngTypeGraphFilter(
						data.engTypes ? data.engTypes : []
					)
					partialStore.setGroupGraphFilter(
						data.groups ? data.groups : []
					)
					partialStore.setEPUserGraphFilter(
						data.epUsers ? data.epUsers : []
					)
					partialStore.setQCUserGraphFilter(
						data.qcUsers ? data.qcUsers : []
					)
				}
			}
		}, [needRefresh])

		const savePageSetting = (name: string, value: any) => {
			const userId = localStorage.getItem("orgUserId")
			const pageSetting = localStorage.getItem("pageSetting")
			const pageSettingData = pageSetting ? JSON.parse(pageSetting) : []
			if (pageSettingData.some((item: any) => item.userId === userId)) {
				let prevData = pageSettingData.find(
					(item: any) => item.userId === userId
				)
				const index = pageSettingData.findIndex(
					(item: any) => item.userId === userId
				)
				prevData = {
					...prevData,
					[pageSettingName]: {
						...prevData[pageSettingName],
						[name]: value,
					},
				}
				pageSettingData[index] = prevData
			} else {
				pageSettingData.push({
					userId,
					[pageSettingName]: {
						[name]: value,
					},
				})
			}
			localStorage.setItem("pageSetting", JSON.stringify(pageSettingData))
		}

		const clearFilters = () => {
			partialStore.setYearGraphFilter([])
			partialStore.setPeriodTypeGraphFilter([])
			partialStore.setEngTypeGraphFilter([])
			partialStore.setGroupGraphFilter([])
			partialStore.setEPUserGraphFilter([])
			partialStore.setQCUserGraphFilter([])
			savePageSetting("financialYear", [])
			savePageSetting("periodTypes", [])
			savePageSetting("engTypes", [])
			savePageSetting("groups", [])
			savePageSetting("epUsers", [])
			savePageSetting("qcUsers", [])
			setOpen(false)
		}

		const dateFormat = store.global.dateFormat.value
		return (
			<StyledDashboardFilterDialog className="FC">
				<DLComboBoxWithCheckbox
					options={partialStore.viewYearList()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("financialYear", value)
						partialStore.setYearGraphFilter(value)
					}}
					defaultValue={
						partialStore.yearGraphFilter
							? partialStore.yearGraphFilter
							: []
					}
					eleTestId="financial-year-select"
					elePlaceHolder={i18n.twFinancialYear || "Financial Year"}
					eleClassName="graph-view-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewPeriodTypes()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("periodTypes", value)
						partialStore.setPeriodTypeGraphFilter(value)
					}}
					defaultValue={
						partialStore.periodTypeGraphFilter
							? partialStore.periodTypeGraphFilter
							: []
					}
					eleTestId="periodName-select"
					elePlaceHolder={i18n.twPeriodType || "Period Types"}
					eleClassName="graph-view-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewEngTypes()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("engTypes", value)
						partialStore.setEngTypeGraphFilter(value)
					}}
					defaultValue={
						partialStore.engTypeGraphFilter
							? partialStore.engTypeGraphFilter
							: []
					}
					eleTestId="engType-select"
					elePlaceHolder={i18n.twEngType || "Engagement Types"}
					eleClassName="graph-view-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewGroupNames()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("groups", value)
						partialStore.setGroupGraphFilter(value)
					}}
					defaultValue={
						partialStore.groupGraphFilter
							? partialStore.groupGraphFilter
							: []
					}
					eleTestId="groupName-select"
					elePlaceHolder={i18n.twGroup || "Groups"}
					eleClassName="graph-view-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewEPUsers()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("epUsers", value)
						partialStore.setEPUserGraphFilter(value)
					}}
					defaultValue={
						partialStore.epUserGraphFilter
							? partialStore.epUserGraphFilter
							: []
					}
					eleTestId="epList-select"
					elePlaceHolder={i18n.twEP || "EP Users"}
					eleClassName="graph-view-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewQCUsers()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("qcUsers", value)
						partialStore.setQCUserGraphFilter(value)
					}}
					defaultValue={
						partialStore.qcUserGraphFilter
							? partialStore.qcUserGraphFilter
							: []
					}
					eleTestId="qcList-select"
					elePlaceHolder={i18n.twQC || "QC Users"}
					eleClassName="graph-view-filter"
				/>
				<DLButton
					eleTestId="clear-filters"
					color="primary"
					clickHandler={clearFilters}
					disabled={
						partialStore.orgDashboardList.length ===
						partialStore.getGraphFilteredData().length
					}
				>
					Clear Filters
				</DLButton>
			</StyledDashboardFilterDialog>
		)
	}
)

export default DashboardFilterDialogContents

const StyledDashboardFilterDialog = styled.div`
	width: 100%;
	.MuiAutocomplete-input {
		width: 0;
		min-width: 90px !important;
	}
	.graph-view-filter {
		margin-bottom: 1rem;
	}
`
