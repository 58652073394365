import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { putRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const RequestCloseComment = (self: any) => ({
	requestCloseComment: flow(function* (
		payload: {
			Response: string
			ReviewCommentsId: number
			ReviewStatus: string
		},
		// NOTE: NEED TO CHECK THAT COMMENT TYPE (review, qc) is not required for this
		menuId: DLProjSubMenus
	) {
		const actionName = "requestCloseComment"
		let endpoint =
			menuId === DLProjSubMenus.wp
				? "/SignoffReviewComments"
				: menuId === DLProjSubMenus.pf
				? "/SignoffPermanentFileReviewComments"
				: "/unknown"

		try {
			const response = yield putRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.projToken,
			})
			ConsoleLog([actionName, "__response ", response])
			return response
		} catch (error) {
			ConsoleLog([actionName, "__error ", error])
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default RequestCloseComment
