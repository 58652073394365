import { types, flow } from "mobx-state-tree"
import { OrgSetupClientsApiProps } from "../org-clients.apis"

const ReadClientList = (
	self: any,
	read: OrgSetupClientsApiProps["apiRead"]
) => ({
	readClientList: flow(function*(payload: {
		PageIndex?: number
		PageSize: number
		GroupID?: number
		ClientName?: string
		ClientId?: string
	}) {
		const actionName = "readClientList"
		try {
			const response = yield read(payload)

			// ConsoleLog("requestClientList response", response)
			return response
		} catch (error) {
			// console.error("requestClientList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadClientList
