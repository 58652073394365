import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton, ConsoleLog } from "../../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import styled from "styled-components"
import { v4 as uuidv4 } from "uuid"
import { formatFileSize } from "@datalobby/common"
import { Icon } from "@mdi/react"
import { mdiFilePlusOutline } from "@mdi/js"
import { LocalFileProps } from "../../../../service-modules/file-module/data-models/file.data-props"
import {
	DLIconName,
	DLIcon,
	CommonIconSize,
} from "../../../basic-elements/icons/common-icons"
import {
	allowedFormats,
	FileScreenDialog,
} from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { convertExtensionToFormat } from "../../../../library/converters/source-format-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../temporary-data/project-side/signoff-policy"

export default observer(function ReplaceFileDialog({
	partialStore,
	userRole,
	i18n,
}: {
	partialStore: any
	userRole: string
	i18n: DLI18nProps
}) {
	// TODO: let's update if this replace also on the organization templates
	const dialogName = FileScreenDialog.replaceFile
	const fileId = partialStore.selectedFile
	const menuId = partialStore.storeName

	const open = partialStore.fileTreeDialogOpenStatus[dialogName]
	const setOpen = () => {
		partialStore.setFileTreeDialogOpen(dialogName)
	}

	const fileInfo = partialStore.getItemInfo(fileId)

	const initialInputs = {
		aliasId: "",
		format: "",
		size: "",
		title: "",
		srcId: "",
		prepared: false,
		reviewed: false,
		//
		newSize: "",
		newFormat: "",
		newFileName: "",
		lastModified: "",
		newPrepare: false,
		// menuId === DLOrgSubMenus.proj_templates
		// 	? false
		// 	: userRole === "id_qc"
		// 	? false
		// 	: true,
		newReview: false,
	}
	const [inputs, setInputs] = useState<{ [x: string]: any }>(initialInputs)
	const [localFile, setLocalFile] = useState<LocalFileProps>()
	useEffect(() => {
		setInputs({
			...inputs,
			aliasId: fileInfo.aliasId,
			format: fileInfo.extension,
			size: fileInfo.size,
			title: fileInfo.title,
			srcId: fileInfo.srcId,
			prepared: fileInfo.prepareList.length > 0,
			reviewed: fileInfo.reviewList.length > 0,
		})
	}, [fileInfo])
	//
	const handleSignoffs = (event: any) => {
		const target = event.target.value
		setInputs({ ...inputs, [target]: !inputs[target] })
	}
	//
	const handleSelectLocalFile = (event: any) => {
		const localFile = event.target.files[0]
		ConsoleLog(["localFile", localFile])

		const { name, type, size, lastModifiedDate } = localFile
		const fileName = name.slice(0, name.lastIndexOf("."))
		const fileExtension = name.slice(name.lastIndexOf("."))
		const fileFormat = type.slice(type.lastIndexOf("/"))
		const formattedSize = formatFileSize(size, 2)
		const lastModified = lastModifiedDate.toLocaleString()
		setLocalFile({
			tempId: uuidv4(),
			aliasId: "",
			selected: false,
			name: fileName,
			extension: fileExtension,
			size: size,
			formattedSize: formattedSize,
			type: type,
			lastModified: lastModified,
			file: localFile,
			prepared: false,
			reviewed: false,
		})
		setInputs({
			...inputs,
			newFileName: name,
			newSize: formattedSize,
			newFormat: fileExtension,
			lastModified: lastModified,
		})
	}
	//
	const handleReplace = () => {
		const parentId = fileInfo.parentId
		if (localFile !== undefined && parentId !== undefined) {
			partialStore.replaceFile({
				parentId,
				fileId,
				srcId: inputs.srcId,
				srcFormat: convertExtensionToFormat(localFile.extension),
				extension: localFile.extension,
				file: localFile.file,
				size: localFile.formattedSize,
				prepared: inputs.newPrepare,
				reviewed: inputs.newReview,
			})
		} else {
			alert(`(ReplaceFileDialog) local file is undefined`)
		}
	}

	const hasFileSizeError = () => {
		if (localFile) {
			return localFile.size > 100000000
		} else {
			return false
		}
	}

	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: null
	let showPrepareCheckbox = false
	let showReviewCheckbox = false
	if (signOffPolicy !== null) {
		showPrepareCheckbox = signOffPolicy[0].availableRoles.includes(userRole)
		showReviewCheckbox = signOffPolicy[1].availableRoles.includes(userRole)
	}

	return (
		<DLDialog
			eleTestId="file-replace-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showOpenBtn={false}
			dialogTitle={
				<div className="FR AC">
					<DLIcon
						name={DLIconName.replace}
						size={CommonIconSize.dialogHeaderIcon}
					/>
					{i18n.twReplaceFile || "Replace File"}
				</div>
			}
			dialogContents={
				<ReplaceFileDialogContents
					inputs={inputs}
					handleSignoffs={handleSignoffs}
					handleSelectLocalFile={handleSelectLocalFile}
					hasFileSizeError={hasFileSizeError()}
					menuId={menuId}
					i18n={i18n}
					showPrepareCheckbox={showPrepareCheckbox}
					showReviewCheckbox={showReviewCheckbox}
					isSignOffLocked={fileInfo.signOffLock}
				/>
			}
			actionBtn="Replace"
			handleAction={handleReplace}
			actionReady={localFile !== undefined && !hasFileSizeError()}
			cancelBtnText={i18n.twCancel || "Cancel"}
		/>
	)
})

const ReplaceFileDialogContents = ({
	inputs,
	handleSignoffs,
	handleSelectLocalFile,
	hasFileSizeError,
	menuId,
	i18n,
	showPrepareCheckbox,
	showReviewCheckbox,
	isSignOffLocked,
}: {
	inputs: any
	handleSignoffs: (event: any) => void
	handleSelectLocalFile: (event: any) => void
	hasFileSizeError: boolean
	menuId: DLOrgSubMenus | DLProjSubMenus
	i18n: DLI18nProps
	showPrepareCheckbox: boolean
	showReviewCheckbox: boolean
	isSignOffLocked: boolean
}) => {
	return (
		<StyledReplaceFileDialogContents>
			<div className="input-section current-file-area">
				<div className="section-title heading3">
					{i18n.twCurrentFile || "Current File"}
				</div>
				<div className="file-info-area FR AC">
					<FileInfo
						info={{
							aliasId: inputs.aliasId,
							format: inputs.format,
							size: inputs.size,
							title: inputs.title,
						}}
					/>
					{menuId !== DLOrgSubMenus.proj_templates && (
						<div className="sign-offs disabled">
							<DLCheckbox
								labelText="Prepare"
								eleTestId="current-file-prepare"
								isChecked={inputs.prepared}
								eleDisabled
							/>

							<DLCheckbox
								labelText="Review"
								eleTestId="current-file-review"
								isChecked={inputs.reviewed}
								eleDisabled
							/>
						</div>
					)}
				</div>
			</div>
			<div className="input-section new-file-area">
				<div className="section-title heading3 FR AC JSB">
					{i18n.twNewFile || "New File"}
					<DLButton
						size="thin"
						eleClassName="file-select-btn"
						eleTestId="choose-files-btn"
						startIcon={
							<Icon path={mdiFilePlusOutline} size={0.8} />
						}
					>
						<label className="file-upload-btn-wrapper">
							{i18n.twSelectAFile || "Select a file"}
							<input
								type="file"
								name="file"
								onChange={handleSelectLocalFile}
								accept={allowedFormats}
							/>
						</label>
					</DLButton>
				</div>
				<div className="FR AC new-file-info">
					<div className="FR AC">{inputs.newFileName}</div>
					<div className="FR AC last-modified">
						{inputs.lastModified !== "" &&
							`(last modified: ${inputs.lastModified})`}
					</div>
				</div>
				{hasFileSizeError && (
					<div className="help-text" data-testid="help-msg-1">
						{/*{inputs.newFormat === ".zip" ? (
							<span>* ZIP file size should not exceed 100MB</span>
						) : (*/}
						<span>* file size should not exceed 100MB</span>
						{/*)}*/}
					</div>
				)}
				<div className="file-info-area FR AC">
					<FileInfo
						info={{
							aliasId: inputs.aliasId,
							format: inputs.newFormat,
							size: inputs.newSize,
							title: inputs.title,
						}}
					/>
					{menuId !== DLOrgSubMenus.proj_templates && (
						<div
							className={`sign-offs ${
								isSignOffLocked ? "disabled" : ""
							}`}
						>
							{showPrepareCheckbox && (
								<DLCheckbox
									value="newPrepare"
									labelText="Prepare"
									eleTestId="new-file-prepare"
									isChecked={inputs.newPrepare}
									clickHandler={handleSignoffs}
									color="primary"
									eleDisabled={isSignOffLocked}
								/>
							)}
							{showReviewCheckbox && (
								<DLCheckbox
									value="newReview"
									labelText="Review"
									eleTestId="new-file-review"
									isChecked={inputs.newReview}
									clickHandler={handleSignoffs}
									color="primary"
									eleDisabled={isSignOffLocked}
								/>
							)}
						</div>
					)}
				</div>
			</div>
			<div className="note-area">
				<div>Note:</div>
				<div>
					1) Select the Prepare/Review checkbox to Replace and
					Prepare/Review the file.
				</div>
				<div>
					2) Sign-off is required to Red-colored sign-offs after the
					File Replacement.
				</div>
			</div>
		</StyledReplaceFileDialogContents>
	)
}

type FileInfoProps = {
	aliasId: string
	format: string
	size: string
	title: string
}

const FileInfo = ({ info }: { info: FileInfoProps }) => {
	const { aliasId, format, size, title } = info
	return (
		<StyledFileInfo className="FR AC">
			<div className="">
				<span className="field-name">Ref. Num</span>
				<div className="info-item ref-num">{aliasId}</div>
			</div>
			<div className="">
				<span className="field-name">format</span>
				<div className="info-item format">{format}</div>
			</div>

			<div className="">
				<span className="field-name">size</span>
				<div className="info-item size">{size}</div>
			</div>

			<div className="">
				<span className="field-name">title</span>
				<div className="info-item title">{title}</div>{" "}
			</div>
		</StyledFileInfo>
	)
}

const StyledFileInfo = styled.div`
	.field-name {
		font-size: ${(props) => props.theme.shared.textRg};
		color: ${(props) => props.theme.shade40};
	}
	.info-item {
		display: flex;
		align-contents: center;
		margin-right: 1rem;
		min-width: 100px;
		min-height: 1.4rem;
		background-color: ${(props) => props.theme.secondary};
		padding: 4px 8px;
	}
`

const StyledReplaceFileDialogContents = styled.div`
	.input-section {
		margin-bottom: 1rem;
		.section-title {
			margin-bottom: 0.6rem;
		}
	}
	.sign-offs {
		min-width: 88px;
	}

	.disabled {
		opacity: 0.4;
	}
	.new-file-area {
		margin-top: 3rem;
		margin-bottom: 1rem;
		.file-select-btn {
			.file-upload-btn-wrapper {
				input[type="file"] {
					display: none;
				}
			}
		}
		.new-file-info {
			padding: 1rem;
			border: 1px solid ${(props) => props.theme.shade20};
			border-radius: 3px;
			.last-modified {
				color: ${(props) => props.theme.shade60};
				margin-left: 0.5rem;
			}
		}

		.file-info-area {
			margin-top: 1rem;
		}
	}
	.note-area {
		color: ${(props) => props.theme.shade60};
	}
	.help-text {
		width: 100%;
		color: ${(props) => props.theme.warning};
	}
`
