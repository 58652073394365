import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../../library/api-requests"

export async function read(params?: { CustomerID?: number }) {
	const response = await getRequest({
		url: "/GetArchiveDetails",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
