// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const RemovePassedAdjustments = (self: any) => ({
	removePassedAdjustments(ajeId: string) {
		// 0. set actionName
		const actionName = "removePassedAdjustments"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// const cabinetId = idToNumber(cabinetId, IdType.cabinet)
		const payload = {
			id: parseInt(ajeId),
		}

		// 3) request API call to Model
		self.deletePassedAdjustment(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//

						self.spliceItemFromPassedAdjustment(ajeId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully removed passed adjustments",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemovePassedAdjustments
