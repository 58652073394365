import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

const AdminLogin = (self: any) => ({
	/**
	 *
	 * @param userInfo
	 *
	 * * i18n records
	 *  - No need to translate the messages for developers or application admin
	 */
	adminLogin(userInfo: {
		username: string
		password: string
		adminPassword: string
	}) {
		// 0.
		const actionName = "adminLogin"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API

		const { username, password, adminPassword } = userInfo

		const id = username
		const pw = adminPassword

		self.requestAdminLogin(id, pw)
			.then((response: any) => {
				if (response.status === 200) {
					self.setIsAppAdminUser(true)
					self.login({ username, password })
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 400,
						customMessage:
							"Failed to sign in as Application Admin.",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					openSnackbar: true,
				})
			})
	},
})

export default AdminLogin
