import { types } from "mobx-state-tree"
import BoardModel from "./board.model"
import BoardViewModel from "./board.view-model"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"

const BoardStore = types
	.compose(BoardModel, BoardViewModel)
	.named("NotificationsStore")

export const initialStore = {
	notiList: [],
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	//
	actionStatus: ActionStatus.standby,
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const BoardStoreInstance = BoardStore.create(initialStore)

export default BoardStoreInstance
