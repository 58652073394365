import React from "react"
import { Avatar } from "@material-ui/core"
import Icon from "@mdi/react"
import { mdiAccount } from "@mdi/js"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLTooltip } from "@datalobby/components"

export default observer(function OrgSideProfileButton() {
	const orgStore = useOrgStore()

	const role = orgStore.checkin.assignedRole
	return (
		<div className="FR AC profile-btn">
			<DLTooltip title={role.name} arrow>
				<Avatar
					variant="square"
					className="role-avatar"
					style={{
						color: role.color,
						border: `1px solid ${role.color}`,
						borderRadius: 4,
					}}
				>
					{role.shortName}
				</Avatar>
			</DLTooltip>

			<Avatar className="user-avatar">
				<Icon path={mdiAccount} size={0.8} color="white" />
			</Avatar>
		</div>
	)
})
