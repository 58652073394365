import React, { useEffect, useState } from "react"
import styled from "styled-components"

import { observer } from "mobx-react-lite"
// import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { Icon } from "@mdi/react"
import { mdiArrowLeft, mdiPlus } from "@mdi/js"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
// sub components
import RoleDetailTabs from "./sub-components/RoleDetailTabs"
import RoleDetailPanel from "./sub-components/RoleDetailPanel"

export default observer(function OrgSetupRoleDetails() {
	const orgStore = useOrgStore()

	/**
	 * Project Permission Detail Screen Layout
	 * - 1. PageHeader
	 * - 2. TabsAndPanel
	 * ---- 2-1. Tabs
	 * ---- 2-2. Panel
	 * -------- 2-2-1. PanelHeader
	 * -------- 2-2-2. PanelContents
	 * ------------- 2-2-1-1. ContentsControl
	 * ------------- 2-2-1-2. Contents
	 */
	return (
		<StyledOrgRoleDetail>
			{/* ----- 1. PageHeader (omitted) ----- */}

			{/* ----- 2. TabsAndPanel ----- */}
			<div className="vertical-tabs-container FR">
				{/* ----- 2-1. Tabs ----- */}
				<RoleDetailTabs data={orgStore.orgRoles.roleSet.roles} />
				{/* ----- 2-2. Panel ----- */}
				<RoleDetailPanel />
			</div>
		</StyledOrgRoleDetail>
	)
})

const StyledOrgRoleDetail = styled.div`
	/* border: 1px solid pink; */

	.control-area {
		height: ${(props) => props.theme.shared.baseUnit};
		border-bottom: 1px solid ${(props) => props.theme.secondary};
	}
`
