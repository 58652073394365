import React from "react"
import styled from "styled-components"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"

import GroupDetailAreaHeader from "./detail-area/GroupDetailAreaHeader"
import GroupDetailAreaItemRenderer from "./detail-area/GroupDetailAreaItemRenderer"
import {
	DLSpinner,
	DLSpinnerCenterAligned,
} from "../../../../components/basic-elements"
import ProjectInfo from "./detail-area/ProjectInfo"
import CabinetInfo from "./detail-area/CabinetInfo"
import { DLObjectFormat } from "../../../../common-models/enumerations/common-enums"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { useLocation } from "react-router-dom"
import GroupRow from "./detail-area/GroupRow"

export default observer(function OrgGroupDetail() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const sheetId = OrgI18n.groups
	const location = useLocation()
	const actionName = location.state
		? "getOrgCabinetDetail"
		: "getOrgGroupList"

	//
	const menuId = DLOrgSubMenus.groups
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	//
	const handleSelectGroup = (groupId: string) => {
		orgStore.groups.toggleExpanded(groupId)
		orgStore.groups.getOrgGroupCabinetList(groupId)
		orgStore.groups.setCurrentPath([groupId])
	}

	const i18n = store.i18n.byModule(
		sheetId,
		store.i18n.language,
		"orgGroupDetail"
	)
	let selectedItem = orgStore.groups.getLastItemOfPath
	let itemInfo = orgStore.groups.getTargetDetail(selectedItem)
	let contentsHeight = store.ui.contentsAreaHeight
	// let areaWidth = store.ui.contentsAreaWidth - orgStore.groups.navWidth
	let areaWidth = store.ui.contentsAreaWidth
	const dntFormat = store.global.getDntFormat
	//

	let groupCabinetListLoadingStatus = "getOrgGroupCabinetList_" + itemInfo?.id
	let fetchingStatus = orgStore.groups.getActionStatus(actionName)
	return (
		<StyledOrgGroupDetail
			height={contentsHeight}
			style={{ width: areaWidth }}
			data-testid="groups-detail-area"
		>
			{fetchingStatus === "LOADING" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentsHeight }}
				>
					<DLSpinner />
				</div>
			)}
			{fetchingStatus === "SUCCESS" && (
				<>
					<GroupDetailAreaHeader
						i18n={i18n}
						permission={permission}
					/>

					{selectedItem ? (
						<>
							<div
								className="group-detail-contents-container"
								style={{ height: contentsHeight - 140 }}
							>
								{itemInfo?.type === DLObjectFormat.group && (
									<>
										{orgStore.groups.getActionStatus(
											groupCabinetListLoadingStatus
										) === "LOADING" && (
											<DLSpinnerCenterAligned
												height={contentsHeight}
											/>
										)}
										{orgStore.groups.getActionStatus(
											groupCabinetListLoadingStatus
										) === "SUCCESS" && (
											<>
												{orgStore.groups.getChildrenInTheFlatList(
													orgStore.groups
														.getLastItemOfPath
												).length > 0 ? (
													<div className="items">
														{orgStore.groups
															.getChildrenInTheFlatList(
																orgStore.groups
																	.getLastItemOfPath
															)
															.map(
																(item: any) => {
																	return (
																		<div
																			key={
																				item.id
																			}
																		>
																			<GroupDetailAreaItemRenderer
																				item={
																					item
																				}
																			/>
																		</div>
																	)
																}
															)}
													</div>
												) : (
													<div>
														No items in the group
													</div>
												)}
											</>
										)}
									</>
								)}
								{itemInfo?.type === DLObjectFormat.cabinet && (
									<CabinetInfo />
								)}
								{itemInfo?.type === DLObjectFormat.project && (
									<ProjectInfo
										projectId={selectedItem}
										projectInfo={itemInfo}
										height={contentsHeight - 200}
									/>
								)}
							</div>
						</>
					) : orgStore.groups.getGroupsOnly(dntFormat).length > 0 ? (
						// <SimpleCardList
						// 	list={orgStore.groups.getGroupsOnly}
						// 	handleClick={handleSelectGroup}
						// />
						<GroupRow
							groupDetails={orgStore.groups.getGroupsOnly(
								dntFormat
							)}
							handleSelectGroup={handleSelectGroup}
						/>
					) : (
						<div className="no-groups-help-text FC AC JC">
							<p>No Groups</p>
							<p>Please create new groups in the Setup Menu</p>
						</div>
					)}
					{/* <div
							className="empty-area"
							onClick={() => {
								orgStore.groups.setRightClickTarget("")
							}}
							style={{ height: "100%" }}
						/> */}
				</>
			)}
		</StyledOrgGroupDetail>
	)
})

const StyledOrgGroupDetail = styled.div<{ height: number | string }>`
	height: ${(props) => props.height}px;
	overflow: auto;
	// overflow: hidden;
	// overflow-y: scroll;
	/* width: calc(100% - 20vw); */
	// height: 100%;
	background-color: ${(props) => props.theme.background};
	// padding: 0 1rem;
	/* border: 1px solid orange; */
	.no-groups-help-text {
		width: 100%;
		height: 100%;
	}
	.group-detail-contents-container {
		overflow: scroll;
	}
`
