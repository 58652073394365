import { observer } from "mobx-react-lite"
import React from "react"
import Icon from "@mdi/react"
import { mdiDownload, mdiEye } from "@mdi/js"
import styled from "styled-components"
import {
    DLButton,
    DLIconButton,
    DLSpinner,
} from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"

const PreProcessPDF = observer(({ partialStore }: { partialStore: any }) => {
    const orgStore = useOrgStore()
    const previewPdf = (url: string) => {
        if (url.length > 0) {
            window.open(url)
        }
    }

    const downloadPdf = (url: string) => {
        if (url.length > 0) {
            const downloadWPFile = document.createElement("a")
            downloadWPFile.href = url

            downloadWPFile.setAttribute("download", "")
            document.body.appendChild(downloadWPFile)
            downloadWPFile.click()
            downloadWPFile.remove()
        }
    }

    const handleRegenPreProcessPdfs = () => {
        const userId = orgStore.checkin.orgUserId
        const projId = partialStore.selectedProj
        partialStore.regenPreProcessPdfs(projId, userId)
    }

    const preProcessStatus = partialStore.preConditionData.isPreProcessed
    const regenPdfRequestStatus = partialStore.getActionStatus(
        "regenPreProcessPdfs"
    )
    const preProcessStatusCheck = partialStore.getActionStatus(
        "getPreConditionStatus"
    )
    if (preProcessStatusCheck === ActionStatus.success) {
        return (
            <StyledPreProcessPDF
                className="file-details"
                data-testid="preprocess-info-container"
            >
                <h4 data-tetsid="area-title">PDF Files</h4>
                {partialStore.preConditionData.preProcessFiles.map(
                    (file: any) => {
                        return (
                            <div
                                className="FR AC JSB content-area"
                                data-testid={`pdf-${file.fileName}`}
                                key={file.fileName}
                            >
                                <div
                                    className="file-title"
                                    data-testid="fileName"
                                >
                                    {file.fileName}
                                </div>
                                <div className="right-side FR AC">
                                    <div data-testid="file-status">
                                        {file.isPreProcessed ? (
                                            <div className="FR JSB file-status">
                                                <span
                                                    style={{
                                                        color: "blue",
                                                    }}
                                                >
                                                    Exist
                                                </span>
                                            </div>
                                        ) : (
                                            <div className="FR JSB file-status">
                                                <span style={{ color: "red" }}>
                                                    Not Exist
                                                </span>
                                            </div>
                                        )}
                                    </div>
                                    <div className="btn-area FR AC">
                                        <DLIconButton
                                            eleTestId="view-pdf"
                                            tooltipText="Preview PDF"
                                            clickHandler={() =>
                                                previewPdf(file.viewUrl)
                                            }
                                            disabled={!file.isPreProcessed}
                                            eleClassName="preview-pdf"
                                        >
                                            <Icon path={mdiEye} size={0.8} />
                                        </DLIconButton>

                                        <DLIconButton
                                            eleTestId="download-pdf"
                                            tooltipText="Download PDF"
                                            clickHandler={() =>
                                                downloadPdf(file.downloadUrl)
                                            }
                                            disabled={!file.isPreProcessed}
                                            eleClassName="download-pdf"
                                        >
                                            <Icon
                                                path={mdiDownload}
                                                size={0.8}
                                            />
                                        </DLIconButton>
                                    </div>
                                </div>
                            </div>
                        )
                    }
                )}
                {!preProcessStatus &&
                    regenPdfRequestStatus !== ActionStatus.loading &&
                    regenPdfRequestStatus !== ActionStatus.success && (
                        <DLButton
                            eleTestId="generate-preprocess-pdf-btn"
                            clickHandler={() => handleRegenPreProcessPdfs()}
                            align="left"
                            color="primary"
                        >
                            Generate Pre-process PDFs
                        </DLButton>
                    )}
            </StyledPreProcessPDF>
        )
    } else {
        return <DLSpinner />
    }
})

export default PreProcessPDF

const StyledPreProcessPDF = styled.div`
    h4 {
        margin-bottom: 1rem;
    }
    .content-area {
        height: 1rem;
        margin-bottom: 0.5rem;
        overflow: hidden;
        .file-title {
            min-width: 19rem;
        }
        .right-side {
            .btn-area {
                width: 4rem;
                margin-left: 1.6rem;
            }
        }
    }
`
