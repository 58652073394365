import React from "react"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"
import DLDatepickerWithoutInput from "./DLDatepickerWithoutInput"

interface DateRangePickerProps {
	selectedStartDate?: any
	selectedEndDate?: any
	disabled?: boolean
	eleStartName?: string
	eleEndName?: string
	onChange(value: any, name: string): any
	format?: string
	noMargin?: boolean
	showSelectedDate?: boolean
}

export default function DLDateRangepicker({
	selectedStartDate = null,
	selectedEndDate = null,
	disabled = false,
	eleStartName = "",
	eleEndName = "",
	onChange,
	format = "MM/dd/yyyy",
	noMargin,
	showSelectedDate = false,
}: DateRangePickerProps) {
	return (
		<DLStyledDatePicker className="FR">
			<DLTooltip
				title={`Start Date: ${
					selectedStartDate ? selectedStartDate : "Not Selected"
				}`}
				elePlacement="top"
				eleTestId={`start-date-tooltip-${eleStartName}`}
			>
				<div className="FR JSA">
					<DLDatepickerWithoutInput
						eleTestId={"start-date-picker-" + eleStartName}
						selected={selectedStartDate}
						disabled={disabled}
						eleName={eleStartName}
						onChange={onChange}
						format={format}
					/>
					{showSelectedDate && selectedStartDate && (
						<span className="selected-date">
							{selectedStartDate}
						</span>
					)}
				</div>
			</DLTooltip>
			<span className="divider">~</span>
			<DLTooltip
				title={`End Date: ${
					selectedEndDate ? selectedEndDate : "Not Selected"
				}`}
				elePlacement="top"
				eleTestId={`end-date-tooltip-${eleStartName}`}
			>
				<div className="FR JSA">
					<DLDatepickerWithoutInput
						eleTestId={"end-date-picker-" + eleEndName}
						selected={selectedEndDate}
						disabled={disabled}
						eleName={eleEndName}
						onChange={onChange}
						format={format}
					/>
					{showSelectedDate && selectedEndDate && (
						<span className="selected-date">{selectedEndDate}</span>
					)}
				</div>
			</DLTooltip>
		</DLStyledDatePicker>
	)
}

const DLStyledDatePicker = styled.div`
	.custom-style {
		&.no-margin {
			margin-top: 0;
			margin-bottom: 0;
		}
	}
	.MuiIconButton-root {
		padding: 0;
	}
	.divider {
		padding: 6px;
		font-size: 16px;
	}
	.selected-date {
		padding: 7px;
	}
`
