import { flow } from "mobx-state-tree"
import { OrgRiskAssessmentApiProps } from "../org-risk-assessment.apis"

const DeleteRiskAssessment = (
	self: any,
	apiDelete: OrgRiskAssessmentApiProps["apiDelete"]
) => ({
	deleteRiskAssessment: flow(function*(payload: { id: number }) {
		const actionName = "deletRiskAssessment"
		if (typeof payload.id === "number") {
			try {
				const response = yield apiDelete(payload)

				// ConsoleLog("delete response", response)
				return response
			} catch (error) {
				// console.error("deleet RiskAssessment error", error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(`(${actionName}) invalid prop exist. ${payload.id}`)
		}
	}),
})

export default DeleteRiskAssessment
