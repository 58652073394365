import { types } from "mobx-state-tree"

export type ProjUserProps = {
	// organization sied info
	id: string
	name: string
	aliasId: string
	email: string
	orgTitle: string
	type: "internal" | "external"
	// project side info
	status: "Active" | "Inactive"
	// ----- permission info
	permissionRole: string
	roleId: string
	roleNumId: number
	roleName: string
	//
	accessTypeId: string
	accessTypeName: string
	canRollForward: boolean
	// ----- date related info
	modifiedById: string
	modifiedByName: string
	modifiedAt: string
	lastActivity: string
	expDate: string
	inactiveDueTo: string
	strikedOut: boolean
}

export const ProjUser = types.model({
	// organization sied info
	id: types.string,
	name: types.string,
	aliasId: types.string,
	email: types.string,
	orgTitle: types.union(types.string, types.null), // TODO: Take discussion about 'null' type case
	type: types.enumeration(["internal", "external"]),
	// project side info
	status: types.enumeration(["Active", "Inactive"]),
	// ----- permission info
	roleId: types.string,
	roleNumId: types.number,
	roleName: types.string,
	//
	accessTypeId: types.string,
	accessTypeName: types.string,
	//
	canRollForward: types.boolean,
	// ----- date related info
	modifiedById: types.optional(types.string, ""), // TODO: Sometimes it returns '0' and sometimes returns 'null'. Update is required
	modifiedByName: types.union(types.string, types.null, types.undefined), // TODO: Take discussion about 'null' type case
	modifiedAt: types.union(types.string, types.null, types.undefined), //TODO: Update is required for 'null' and 'undefined'
	lastActivity: types.union(types.string, types.null, types.undefined),
	expDate: types.union(types.string, types.null, types.undefined), //TODO: Update is required for 'null' and 'undefined'
	inactiveDueTo: types.union(types.string, types.null, types.undefined), //TODO: Update is required for 'null' and 'undefined'
	strikedOut: types.optional(types.boolean, false), //TODO: Update is required for 'null' and 'undefined'
})

export const GroupUser = types.model({
	id: types.string,
	aliasId: types.string,
	name: types.string,
	email: types.string,
	orgRole: types.string,
	orgTitle: types.string,
	status: types.string,
	// inactiveDueTo: types.string,
})

/**
 * for new permission sheet
 */
export enum ProjPermission {
	paa = "PAA", // Project Archive Admin
	pa = "PA", // Project Admin
	pu = "PU", // Project User
	pr = "PR", // Project Readonly
	oa = "OA", // Organization Admin (not assigned but admin in organization)
	exr = "EXR", // External Readonly
	exp = "EXP", // External PBC Uploader
}

export enum ProjRoles {
	qc = "id_qc",
	cp = "id_cp",
	ep = "id_ep",
	p = "id_p",
	d = "id_d",
	m = "id_m",
	ss = "id_ss",
	js = "id_js",
}

export enum ProjAccess {
	archAdmin = "ProjectArchiveAdmin",
	projAdmin = "ProjectAdmin",
	user = "ProjectUser",
	readonly = "ReadOnly",
	pbcUpload = "PBCUpload",
}
export interface AssignProjUserInputProps {
	type: "internal" | "external"
	id: string
	roleId: ProjRoles
	accessTypeId: ProjAccess
	// readonly: false
	canRollForward: boolean
	extUserRight: string
	expDate?: string
}

export interface UpdateProjUserProps {
	type: "internal" | "external"
	roleId: string
	userName: string
	userAliasId: string // TODO: WARNING: Temporary value for old API (backend is not deployed yet to productions)
	// readonly: boolean
	accessTypeId: string // TODO: (TBD) is ProjAccess better or..??
	expDate?: null | string
	canRollForward: boolean
}
