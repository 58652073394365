import { types, flow } from "mobx-state-tree"
// model actions
import CreateNotes from "./model-actions/create-notes"
import ReadNotes from "./model-actions/read-notes"

const NotesModel = (apiCreate: any, apiRead: any) =>
	types
		.model({})
		.actions((self) => ({
			// ------ Read
			// ------ Update
		}))
		.actions((self) => CreateNotes(self, apiCreate))
		.actions((self) => ReadNotes(self, apiRead))

export { NotesModel }
