import React from "react"
import styled from "styled-components"
import {
	StackedHorizontalBarChart as Bar,
	StackedHorizontalBarChartProps,
} from "./StackedHorizontalBarChart"

export default function StackedBarChartWithRatio({
	label,
	totalBarWidth,
	unit,
	height,
	data,
	targetCount,
	totalCount,
	testId, // Do not add ratio postfix
	subValue,
	eleClassName,
}: {
	label: string
	totalBarWidth: number
	unit: number
	height: number
	data: StackedHorizontalBarChartProps[]
	targetCount: number
	totalCount: number
	testId: string
	subValue?: boolean
	eleClassName?: string
}) {
	const newTestId = testId.toLowerCase().replace(/ /g, "-")
	return (
		<StyledBarChartWithRatio
			className={"barchart-with-ratio FR AC " + eleClassName}
		>
			<div className={subValue ? "sub-label" : "main-label"}>{label}</div>
			<div
				className="bar-chart-row FR JSB"
				style={{ height: height + 1 }}
			>
				<Bar
					totalWidth={totalBarWidth}
					unit={unit}
					height={height}
					showTooltip
					showCountInBar
					data={data}
				/>
				<div className="ratio main" data-testid={newTestId + "-ratio"}>
					{targetCount === 0
						? 0
						: ((targetCount / totalCount) * 100).toFixed(2) + "%"}
				</div>
			</div>
		</StyledBarChartWithRatio>
	)
}

const StyledBarChartWithRatio = styled.div`
	.bar-chart-row {
		width: calc(100% - 100px);
		border-bottom: 1px solid rgba(0, 0, 0, 0.06);
		transition: 0.4s;
		:hover {
			border-bottom: 1px solid rgba(0, 0, 0, 0.5);
		}
	}
	.main-label {
		width: 100px;
		font-weight: bold;
	}
	.sub-label {
		width: 100px;
		text-align: right;
		padding-right: 0.8rem;
		opacity: 0.6;
	}
`
