import React from "react"
import styled from "styled-components"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import GroupDetailAreaItemRenderer from "./GroupDetailAreaItemRenderer"
import { DLSpinnerCenterAligned } from "../../../../../components/basic-elements"

export default observer(function CabinetInfo() {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const items = orgStore.groups.getChildrenInTheFlatList(
		orgStore.groups.getLastItemOfPath,
		orgStore.groups.search
	)
	let contentsHeight = store.ui.contentsAreaHeight
	return (
		<StyledCabinetInfo
			className="org-group-cabinet-body"
			data-testid="cabinet-info"
			// style={{ height: contentsHeight - 180 }}
		>
			{orgStore.groups.getActionStatus("getOrgCabinetDetail") ===
				"LOADING" && <DLSpinnerCenterAligned />}
			{orgStore.groups.getActionStatus("getOrgCabinetDetail") ===
				"SUCCESS" && (
				<>
					{items.length > 0 ? (
						<div className="items">
							{items.map((item: any, i: number) => {
								return (
									<div key={item.id + i}>
										<GroupDetailAreaItemRenderer
											item={item}
										/>
									</div>
								)
							})}
						</div>
					) : (
						<div className="empty-cabinet FR AC">
							<div>No Items in the cabinet</div>
						</div>
					)}
				</>
			)}
		</StyledCabinetInfo>
	)
})

const StyledCabinetInfo = styled.div`
	height: 100%;
	/* border: 1px solid pink; */
	.empty-cabinet {
		width: 100%;
		padding: 2rem;
	}
`
