import {
	ActionStatus,
	MessageColorType,
	FlatItemType,
} from "../../../../../../common-models/enumerations/common-enums"
import { SignoffHistoryFlatItemProps } from "../data-models/signoff-history.data-model"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

export function reOrganizeSignoff(fetchedInfo: {
	Date: string
	SignOffBy: string
}) {
	if (fetchedInfo === null) {
		return null
	} else {
		return {
			at: fetchedInfo.Date,
			by: fetchedInfo.SignOffBy.split(" ")[1],
			role: fetchedInfo.SignOffBy.split(" ")[0].slice(1, -1),
		}
	}
}

function reOrganizeFile(fileInfo: any, folderId: string, folderName: string) {
	const { Prepare, Review, EPReview, CPReview, QCReview } = fileInfo
	//
	const lastPrepare = reOrganizeSignoff(Prepare)
	const lastReview = reOrganizeSignoff(Review)
	const lastEpReivew = reOrganizeSignoff(EPReview)
	const lastCpReview = reOrganizeSignoff(CPReview)
	const lastQcReview = reOrganizeSignoff(QCReview)
	//
	const reOrganizedFile = {
		id: "fileId" + fileInfo.WPRId.toString(),
		parentId: folderId, // parent = depth 0 folder
		parentName: folderName,
		title: fileInfo.Description,
		aliasId: fileInfo.Reference,
		format: fileInfo.Type,
		type: FlatItemType.file,
		expanded: false, // file has no expanded status
		lastSignoffs: {
			prepare: lastPrepare,
			review: lastReview,
			epReview: lastEpReivew,
			cpReview: lastCpReview,
			qcReview: lastQcReview,
		},
		signoffs: null, // this detailed sign off history will be set next step
	}

	return reOrganizedFile
}

function reOrganizeFolder(
	folderInfo: any,
	parentId: string,
	parentName: string
) {
	let folderAndFiles: SignoffHistoryFlatItemProps[] = []
	//
	const currentFolderId = "folderId" + folderInfo.FolderId.toString()
	const currentFolderName = folderInfo.FolderName
	const subFolders = folderInfo.Folders
	const subFiles = folderInfo.Files
	//
	const reOrganizedFolder = {
		id: currentFolderId,
		parentId: parentId, // parent = depth 0 folder
		parentName: parentName,
		title: currentFolderName,
		aliasId: undefined,
		format: undefined,
		type: FlatItemType.folder,
		expanded: false,
		lastSignoffs: undefined, // folder has no sign off
		signoffs: null, // folder has no sign off
	}
	folderAndFiles.push(reOrganizedFolder)
	// NOTE: 2-2. get sub folders or files in the folder
	if (subFolders?.length > 0) {
		subFolders.map((folder: any) => {
			const updatedSubFolders = reOrganizeFolder(
				folder,
				currentFolderId,
				currentFolderName
			)

			folderAndFiles.concat(updatedSubFolders)
		})
	}
	if (subFiles?.length > 0) {
		subFiles.map((file: any) => {
			const updatedSubFiles = reOrganizeFile(
				file,
				currentFolderId,
				currentFolderName
			)
			folderAndFiles.push(updatedSubFiles)
		})
	}
	return folderAndFiles
}

const GetLastSignoffHistory = (self: any) => ({
	/**
	 *
	 * @param self
	 * @returns
	 *
	 * * i18n records
	 * - success, fail
	 *
	 */
	getLastSignoffHistory(menuId: DLProjSubMenus) {
		// 0. set actionName
		const actionName = "getLastSignoffHistory"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readFinalSignOffHistory(menuId)
			.then((response: any) => {
				// console.log(actionName, "response__", response)
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedRootFolders = response.data.Data // this means root folders of the project (depth 0 with no parent)
					// item count (required..?)
					const totalWp = response.data.TotalWP
					const totalPf = response.data.TotalPF
					self.setTotalWp(totalWp)
					self.setTotalPf(totalPf)
					//
					// control the data
					/**
					 * NOTE: Current API working like below
					 * 1. get the last signoffs of all files
					 * 2. when the user select a file, get whole sign off history of the file
					 */
					// fetched tree type data start with folders
					// NOTE: 1. add DEPTH 0 FOLDERS to the flat list
					let result: SignoffHistoryFlatItemProps[] = []
					fetchedRootFolders.map((rootFolder: any) => {
						const rootFolderId = idToString(
							rootFolder.FolderId,
							IdType.folder
						)
						const fetchedFolders = rootFolder.Folders // depth 1 folders
						const fetchedFiles = rootFolder.Files // files in the root folders (0 depth folders)

						// refer to FlatItem data-model
						const reOrganizedRootFolder = {
							id: rootFolderId,
							parentId: null, // depth 0 folder
							parentName: null, // depth 0 folder
							title: rootFolder.FolderName,
							aliasId: undefined,
							format: undefined,
							type: FlatItemType.folder,
							expanded: false,
							lastSignoffs: undefined, // folder has no sign off
							signoffs: null, // folder has no sign off
						}
						result.push(reOrganizedRootFolder)
						// NOTE: 2. add DEPTH 1 FOLDERS to the flat list
						fetchedFolders.map((folder: any) => {
							let reOrganizedItems = reOrganizeFolder(
								folder,
								rootFolderId,
								rootFolder.FolderName
							)
							reOrganizedItems.map(
								(item: SignoffHistoryFlatItemProps) => {
									result.push(item)
								}
							)
						})
						// NOTE: 3. add FILES in the root folders to the flat list
						fetchedFiles.map((file: any) => {
							let reOrganizedItems = reOrganizeFile(
								file,
								rootFolderId,
								rootFolder.FolderName
							)
							result.push(reOrganizedItems)
						})
					})
					console.log("result:", result)

					self.setLastSignoffs(result, menuId)
					// when success - update response
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else if (
					response.status === 200 &&
					response.data.Status === 0 &&
					response.data.Message.toLowerCase() === "no data found"
				) {
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999,
						color: MessageColorType.default,
						message: "No Sign off history",
						// customMessage:
						// 	"TODO: Fail by some reason. Please Check",
						open: true,
					})
				} else {
					// when fail (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.red,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetLastSignoffHistory
