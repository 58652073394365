import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"

export type RemoveFileFolderProps = {
	folderId: string
	postAction?: any
}

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const RemoveFileFolder = (self: any) => ({
	removeFileFolder({ folderId, postAction }: RemoveFileFolderProps) {
		// 0.
		const actionName = "removeFileFolder"

		const title = self.getItemById(folderId).title
		let proceed = window.confirm(
			`Do you really want to delete this folder <${title}>? Sign offs cannot be restored after deletion.`
		)
		if (!proceed) {
			return
		} else {
			// 1.

			// 2.
			self.handleResponse({
				actionName,
				status: ActionStatus.loading,
				code: 0,
				color: MessageColorType.green,
				open: true,
			})
			// 3.
			self.deleteFileFolder(folderId)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// console.log(actionName+ "__response " + response)
						/**
						 * PROCESS
						 */
						// remove the folder on the stored data
						self.resetSelectedItems()
						self.reflectRemoveItem(folderId)
						postAction && postAction()
						// initialize selected folder

						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else if (response === false) {
						//
					} else {
						// set fail case response (200 but fail)
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					ConsoleLog([actionName, "__error ", error])
					self.handleViewModelError({
						error,
						actionName,
						open: true,
					})
				})
		}
	},
})

export default RemoveFileFolder
