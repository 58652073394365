import { flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../../../library/api-requests"

const ReadVerifyDigitalSignatureHashValue = (self: any) => ({
	readVerifyDigitalSignatureHashValue: flow(function* (payload: {
		clientId: number
		InputHash: string
	}) {
		const actionName = "readVerifyDigitalSignatureHashValue"
		try {
			const endpoint = "/VerifyHash"
			const response = yield postRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.orgToken,
			})

			return response
		} catch (error) {
			// ConsoleLog("readVerifyDigitalSignatureHashValue error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadVerifyDigitalSignatureHashValue
