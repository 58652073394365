import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { idToNumber, IdType } from "../../../library/converters/id-converter"
import { getProjIdFromUrl } from "../../../library/api-requests/request-get-others"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const RequestNotification = (self: any) => ({
	addRequestNotification(
		menuId: DLProjSubMenus,
		fileDetails: any,
		notes: string
	) {
		// 0.
		const actionName = "addRequestNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let payload: any = []
		const userId = localStorage.getItem("orgUserId")
		const projId = getProjIdFromUrl()
		const projNumberId = projId ? idToNumber(projId, IdType.project) : 0
		const userNumberId = userId ? idToNumber(userId, IdType.user) : 0

		fileDetails.map((item: any) => {
			if (menuId === DLProjSubMenus.wp) {
				payload.push({
					ClientID: projNumberId,
					CreatedDate: new Date(), // need to update with nazir approach
					MediaID: item.id,
					Notes: notes,
					UserID: userNumberId,
				})
			}
			if (menuId === DLProjSubMenus.pf) {
				// for pf
				payload.push({
					ClientId: projNumberId,
					CreatedDate: new Date(), // need to update with nazir approach
					MediaId: item.id,
					Notes: notes,
					UserId: userNumberId,
				})
			}
		})
		self.createRequestNotification(payload, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.setOpenedFilesRequestAccessDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RequestNotification
