import { types } from "mobx-state-tree"
import OtherProjectsModel from "./other-projects.model"
import OtherProjectsViewModel from "./other-projects.view-model"
// import { ReportManagementInitialPageSetting } from "./data-models/initial-page-settings"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import {
	readOthers,
	OtherProjectsApiProps
} from "./other-projects.apis"
import { ReportManagementInitialPageSetting } from "../../online-projects/store/data-models/initial-page-settings"

const OtherProjectsStore = ({
	apiReadOthers,
}: OtherProjectsApiProps) =>
	types
		.compose(
			OtherProjectsModel({
				apiReadOthers,
			}),
			OtherProjectsViewModel
		)
		.named("OtherProjectsStore")

export const initialStore = {
// 	checklistDetail: null,
	//
	tableColumnsPageSetting: ReportManagementInitialPageSetting,
	clickPoint: initialClickPoint,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OtherProjectsStoreInstance = OtherProjectsStore({
	apiReadOthers: readOthers,
}).create(initialStore)

export default OtherProjectsStoreInstance