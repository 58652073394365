import { ConsoleLog } from "../components/basic-elements"

export const getDefaultYear = (menuId: string) => {
	const currentYear = new Date().getFullYear()

	const pageSetting = localStorage.getItem("pageSetting")
	const userId = localStorage.getItem("orgUserId")

	const pageSettingName = `pageSetting_${menuId}`

	// NOTE: below method occurs error when the app does not have the settings
	// const defaultYear = pageSetting
	// 	? JSON.parse(pageSetting)
	// 			.find((item: any) => item.userId === userId)
	// 			?.[pageSettingName]?.columnFilters.find(
	// 				(item: any) => item.id === "year"
	// 			).value
	// 	: undefined

	// console.log("pageSetting:", pageSetting)

	let defaultYear: any = undefined
	if (pageSetting) {
		const parsedPageSetting = JSON.parse(pageSetting)
		const userSetting = parsedPageSetting.find(
			(item: any) => item.userId === userId
		)
		console.log("userSetting:", userSetting)
		if (userSetting) {
			const pSetting = userSetting[pageSettingName]
			console.log("pSetting:", pSetting)
			if (pSetting) {
				defaultYear = pSetting.defaultYear
			}
		}
	}

	if (defaultYear) {
		return defaultYear
	} else {
		return currentYear
	}
}

export const getDefaultFinancialYear = (menuId: string) => {
	const currentYear = new Date().getFullYear()

	const pageSetting = localStorage.getItem("pageSetting")
	const userId = localStorage.getItem("orgUserId")

	const pageSettingName = `pageSetting_${menuId}`

	let defaultYear: any = undefined
	if (pageSetting) {
		const parsedPageSetting = JSON.parse(pageSetting)
		const userSetting = parsedPageSetting.find(
			(item: any) => item.userId === userId
		)
		console.log("userSetting:", userSetting)
		if (userSetting) {
			const pSetting = userSetting[pageSettingName]
			console.log("pSetting:", pSetting)
			if (pSetting?.columnFilters) {
				defaultYear = pSetting.columnFilters.find((item: any) => item.id === "financialYear")?.value
			}
		}
	}

	if (defaultYear) {
		return defaultYear
	} else {
		return currentYear
	}
}

export const getPageSettings = (menuId: string) => {
	const settings = localStorage.getItem("pageSetting")
	const userId = localStorage.getItem("orgUserId")

	const pageSettingName = `pageSetting_${menuId}`
	const pageSettingData = settings ? JSON.parse(settings) : []

	const pageSetting = pageSettingData
		? pageSettingData.find((item: any) => item.userId === userId)?.[
				pageSettingName
		  ]
		: undefined
	ConsoleLog(["getPageSettings > pageSetting", pageSettingName, pageSetting])
	return pageSetting
}

export const savePageSetting = (
	name: string,
	value: any,
	pageSettingName: string
) => {
	const userId = localStorage.getItem("orgUserId")
	const pageSetting = localStorage.getItem("pageSetting")
	const pageSettingData = pageSetting ? JSON.parse(pageSetting) : []
	if (pageSettingData.some((item: any) => item.userId === userId)) {
		let prevData = pageSettingData.find(
			(item: any) => item.userId === userId
		)
		const index = pageSettingData.findIndex(
			(item: any) => item.userId === userId
		)
		prevData = {
			...prevData,
			[pageSettingName]: {
				...prevData[pageSettingName],
				[name]: value,
			},
		}
		pageSettingData[index] = prevData
	} else {
		pageSettingData.push({
			userId,
			[pageSettingName]: {
				[name]: value,
			},
		})
	}
	ConsoleLog(["savePageSetting > pageSettingData", pageSettingData])
	localStorage.setItem("pageSetting", JSON.stringify(pageSettingData))
}
