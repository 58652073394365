import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const RemoveClient = (self: any) => ({
	removeClient(id: string) {
		// 0.
		const actionName = "removeClient"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const clientId = idToNumber(id, IdType.client)
		const payload = {
			ID: clientId,
		}
		self.deleteClient(payload)
			.then((response: any) => {
				ConsoleLog([actionName, "__response:", response])
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					self.deleteClientFromList(id)
					// update success case response
					self.setRemoveClientDialogOpen(false)
					self.resetSelectedItems()
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						message: response.data.Message || response.data.message,
						customMessage: "Success to remove the client",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response
					// self.resetSelectedItems()
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						customMessage: response.data.Message,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	removeClients(clients: string[], index: number) {
		//
		const actionName = "removeClients"
		//
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			customMessage: "Removing multiple clients in progress...",
			open: true,
		})
		//
		const currentTarget = clients[index]
		const props = {
			ID: idToNumber(currentTarget, IdType.client),
		}
		self.deleteClient(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([index, "Success", currentTarget])
					self.deleteClientFromList(currentTarget)
					self.pushOrSpliceItem(currentTarget)
					if (
						index !== clients.length - 1 &&
						index < clients.length
					) {
						self.removeClients(clients, index + 1)
					}
				} else {
					ConsoleLog([index, "Fail", currentTarget])
					if (
						index !== clients.length - 1 &&
						index < clients.length
					) {
						self.removeClients(clients, index + 1)
					}
				}
				if (index === clients.length - 1) {
					// self.resetSelectedItems()
					// self.setRemoveClientDialogOpen(false)
					const selectedItems = self.selectedItems
					const selectedItemsLength = selectedItems.length
					const customMessage =
						selectedItemsLength > 0
							? "You cant delete this Clients as there were being used by fewer projects"
							: "Delete multiple clients complete"

					// Close dialog if all are successfully deleted
					if (selectedItemsLength === 0)
						self.setRemoveClientDialogOpen(false)
					//
					self.handleResponse({
						actionName,
						status:
							selectedItemsLength > 0
								? ActionStatus.fail
								: ActionStatus.success,
						code: 111,
						color:
							selectedItemsLength > 0
								? MessageColorType.orange
								: MessageColorType.blue,
						customMessage: customMessage, // This is not related with individual status
						open: true,
						autoHide: selectedItemsLength === 0,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog([index, "Fail_2", currentTarget])
				if (index !== clients.length - 1 && index < clients.length) {
					self.removeClients(clients, index + 1)
				} else if (index === clients.length - 1) {
					self.resetSelectedItems()
					self.setRemoveClientDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 111,
						color: MessageColorType.blue,
						customMessage: "Delete multiple clients complete", // This is not related with individual status
						open: true,
						autoHide: true,
					})
				}
			})
	},
})

export default RemoveClient
