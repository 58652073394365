import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	ConsoleLog,
	DLBasicDialog,
	DLIconButton,
} from "../../../../../components/basic-elements"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import {
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import FilesPageSettingDialogContents from "./FilesPageSettingDialogContents"
import {
	FileColumn,
	SignOffDisplayType,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function FilePageSettingDialog({
	partialStore,
}: {
	partialStore: any
}) {
	const userId = localStorage.getItem("orgUserId")
	const orgId = localStorage.getItem("orgId")
	const pageSettingName = `pageSetting_${partialStore.storeName}` // TODO: Isn't it better to use 'menuId' instead of the 'storeName'  in the store?
	const menuId = partialStore.storeName

	useEffect(() => {
		const prevSetting = localStorage.getItem("projPageSetting")
		const props = prevSetting ? JSON.parse(prevSetting) : []
		if (
			props.some(
				(item: any) => item.userId === userId && item.orgId === orgId
			)
		) {
			let prevData = props.find(
				(item: any) => item.userId === userId && item.orgId === orgId
			)
			if (prevData[pageSettingName]?.columns) {
				partialStore.setShowColumns(prevData[pageSettingName].columns)
			}
			if (prevData[pageSettingName]?.showSignOffColumns) {
				partialStore.setShowSignOffColumns(
					prevData[pageSettingName].showSignOffColumns
				)
			}
			if (prevData[pageSettingName]?.signOffCellType) {
				partialStore.setSignOffCellType(
					prevData[pageSettingName].signOffCellType
				)
			}
		}
	})

	const updatePageSetting = () => {
		const prevSetting = localStorage.getItem("projPageSetting")
		const props = prevSetting ? JSON.parse(prevSetting) : []
		if (
			props.some(
				(item: any) => item.userId === userId && item.orgId === orgId
			)
		) {
			let prevData = props.find(
				(item: any) => item.userId === userId && item.orgId === orgId
			)
			const index = props.findIndex(
				(item: any) => item.userId === userId && item.orgId === orgId
			)
			prevData = {
				...prevData,
				[pageSettingName]: {
					columns: partialStore.showColumns,
					showSignOffColumns: partialStore.showSignOffColumns,
					signOffCellType: partialStore.signOffCellType,
				},
			}
			props[index] = prevData
		} else {
			props.push({
				userId,
				orgId,
				[pageSettingName]: {
					columns: partialStore.showColumns,
					showSignOffColumns: partialStore.showSignOffColumns,
					signOffCellType: partialStore.signOffCellType,
				},
			})
		}
		ConsoleLog(["projPageSetting props", props])
		localStorage.setItem("projPageSetting", JSON.stringify(props))
	}

	const columns = [
		{
			value: FileColumn.assignedRoles,
			label: "Assigned Roles",
		},
		{
			value: FileColumn.assignedUsers,
			label: "Assigned Users",
			disabled: true,
		},
		{
			value: FileColumn.fileAliasId,
			label: "Reference Number",
		},
		{
			value: FileColumn.format,
			label: "Format",
		},
		{
			value: FileColumn.title,
			label: "Title",
		},
		{
			value: FileColumn.size,
			label: "Size",
		},
		{
			value: FileColumn.signOffs,
			label: "Sign Offs",
		},
		{
			value: FileColumn.comment,
			label: "Comment",
		},
		{
			value: FileColumn.attention,
			label: "Attention",
		},
		{
			value: FileColumn.history,
			label: "History",
		},
		{
			value: FileColumn.size,
			label: "Size",
		},
		{
			value: FileColumn.signOffLock,
			label: "Sign Off Lock",
		},
	]

	const signOffDisplayTypeOptions = [
		{
			value: SignOffDisplayType.min,
			label: "Icon",
			disabled: false,
			optionTestId: "signoff-display-min",
		},
		{
			value: SignOffDisplayType.nameOnly,
			label: "User name only",
			disabled: false,
			optionTestId: "signoff-display-nameOnly",
		},
		{
			value: SignOffDisplayType.dateOnly,
			label: "Date only",
			disabled: true,
			optionTestId: "signoff-display-dateOnly",
		},
		{
			value: SignOffDisplayType.dateAndTime,
			label: "Date and time",
			disabled: true,
			optionTestId: "signoff-display-dateAndTime",
		},
		{
			value: SignOffDisplayType.nameAndDate,
			label: "User name and date",
			disabled: false,
			optionTestId: "signoff-display-nameAndDate",
		},
		{
			value: SignOffDisplayType.max,
			label: "User name, date and time",
			disabled: true,
			optionTestId: "signoff-display-max",
		},
	]
	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []

	let signOffColumns: any = []
	signOffPolicy.map((item: any) => {
		signOffColumns.push({
			value: item.id,
			label: item.label,
		})
	})
	// const signOffColumns = [
	// 	{
	// 		value: "id_prepare",
	// 		label: "Prepare",
	// 	},
	// 	{
	// 		value: "id_review",
	// 		label: "Review",
	// 	},
	// 	{
	// 		value: "id_epReview",
	// 		label: "EP Review",
	// 	},
	// 	{
	// 		value: "id_cpReview",
	// 		label: "CP Review",
	// 	},
	// 	{
	// 		value: "id_qcReview",
	// 		label: "QC Review",
	// 	},
	// ]

	return (
		<DLBasicDialog
			openBtn={
				<DLIconButton
					eleTestId="page-setting-onoff"
					tooltipText="Page setting"
					eleClassName="page-setting-btn"
				>
					<DLIcon name={DLIconName.setting} noMargin />
				</DLIconButton>
			}
			showCloseBtn
			closeBtnText="Close"
			dialogId="fileMenu-page-setting"
			dialogContents={
				<FilesPageSettingDialogContents
					partialStore={partialStore}
					columns={columns}
					signOffDisplayTypeOptions={signOffDisplayTypeOptions}
					signOffColumns={signOffColumns}
				/>
			}
			dialogHeaderText={`Page Setting`}
			actionBtnText="Save"
			actionHandler={updatePageSetting}
			allowAction
			// TODO: Temporary, selection will works to update the value directly
			// TODO: Need to update
			// TODO: + Do we need basic dialog...?
			maxWidth="md"
			fullWidth
		/>
	)
})
