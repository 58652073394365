import { types, flow } from "mobx-state-tree"
import ReadOrgMgmtList from "./model-actions/read-org-mgmt-list"
import CreateOrgCustomer from "./model-actions/create-org-customer"
import UpdateOrgCustomer from "./model-actions/update-org-customer"

const OrgMgmtModel = (apiRead: any, apiCreate: any, apiUpdate: any) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadOrgMgmtList(self, read))
		.actions((self, create = apiCreate) => CreateOrgCustomer(self, create))
		.actions((self, update = apiUpdate) => UpdateOrgCustomer(self, update))

export default OrgMgmtModel
