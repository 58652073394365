import React from "react"
import { types } from "mobx-state-tree"
import ReadSignedUrl from "../../../../service-modules/post/store-model-actions/read-signed-url"
import CreatePost from "../../../../service-modules/post/store-model-actions/create-post"
import UpdatePost from "../../../../service-modules/post/store-model-actions/update-post"
import DeleteOrRestorePost from "../../../../service-modules/post/store-model-actions/delete-or-restore-post"
import { CommonModelActions } from "../../../../common-models/common-model-actions"

const NotificationsModel = types
	.model({})
	.actions(CreatePost)
	.actions(DeleteOrRestorePost)
	.actions(UpdatePost)
	.actions(ReadSignedUrl)
	.actions(CommonModelActions)

export default NotificationsModel
