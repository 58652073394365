import React from "react"
import { observer } from "mobx-react-lite"
import { Link } from "react-router-dom"
import {
	DLButton,
	DLChip,
	DLIconButton,
	DLSingleSelect,
} from "../../../../components/basic-elements/index"
import styled from "styled-components"
import { StyledNotiCard } from "../GlobalNotisOnDrawer"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import {
	mdiDownload,
	mdiFolderDownload,
	mdiFolderDownloadOutline,
} from "@mdi/js"
import Icon from "@mdi/react"
import {
	AsyncItemType,
	AsyncItemStatus,
	DownloadItem,
} from "../store/data-models/notifications.data-model"
import {
	DLIcon,
	DLIconName,
} from "../../../../components/basic-elements/icons/common-icons"

import { DLTooltip } from "@datalobby/components/lib"
import {
	ReplicaProjectIcon,
	UnarchivedProjectIcon,
} from "../../../../components/basic-elements/icons/common-project-icons"
import { getTzDateAndTime2 } from "../../../../library/converters/date-utc-converter"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

const asyncItemTypeOptions = [
	{ name: "All", value: "all" },
	{ name: "Pre-Process PDFs", value: AsyncItemType.preprocess },
	{ name: "Archive Zip", value: AsyncItemType.archZip },
	{
		name: "Export All WP or PF in a project",
		value: AsyncItemType.exportAll,
	},
	{ name: "Partial File Folder", value: AsyncItemType.fileFolder },
	{ name: "Replica", value: AsyncItemType.replica },
	{ name: "Unarchive", value: AsyncItemType.unarchived },
	{ name: "Roll Forward", value: AsyncItemType.rollForward },
	{
		name: "New Project by Template",
		value: AsyncItemType.newProjByTemplate,
	},
	{
		name: "Template Duplication",
		value: AsyncItemType.duplicateTemplate,
	},
]

const asyncItemStatusOptions = [
	{ name: "All", value: "all" },
	{ name: "Pending", value: AsyncItemStatus.pending },
	{ name: "Ready", value: AsyncItemStatus.ready },
	{ name: "Failed", value: AsyncItemStatus.fail },
	{ name: "In Progress", value: AsyncItemStatus.inprogress },
]

export default observer(function DownloadItemList({
	items,
	asyncItemType,
	handleAsyncItemType,
	asyncItemStatus,
	handleAsyncItemStatus,
	downloadAndMoveToPrevItems,
}: {
	items: DownloadItem[]
	asyncItemType: AsyncItemType | "all"
	handleAsyncItemType: (e: any) => void
	asyncItemStatus: AsyncItemStatus | "all"
	handleAsyncItemStatus: (e: any) => void
	downloadAndMoveToPrevItems: (target: DownloadItem) => void
}) {
	let filteredItems = items
	const store = useRootStore()
	const dntFormat = store.global.getDntFormat

	if (asyncItemType === "all" && asyncItemStatus !== "all") {
		filteredItems = items.filter(
			(item: DownloadItem) => item.status === asyncItemStatus
		)
	} else if (asyncItemType !== "all" && asyncItemStatus === "all") {
		filteredItems = items.filter(
			(item: DownloadItem) => item.type === asyncItemType
		)
	} else if (asyncItemType !== "all" && asyncItemStatus !== "all") {
		filteredItems = items.filter(
			(item: DownloadItem) =>
				item.type === asyncItemType && item.status === asyncItemStatus
		)
	} else if (asyncItemType === "all" && asyncItemStatus === "all") {
		//
	}

	return (
		<StyledDownloadItems>
			<div className="FR AC async-itmes-filter">
				<div className="filter-label">Type</div>
				<DLSingleSelect
					eleValue={asyncItemType}
					eleClassName="filter-select"
					eleFullWidth
					eleOnChange={handleAsyncItemType}
					optionList={asyncItemTypeOptions}
					eleTestId="async-item-type"
					withLabel={false}
				/>
			</div>
			<div className="FR AC async-itmes-filter">
				<div className="filter-label">Status</div>
				<DLSingleSelect
					eleValue={asyncItemStatus}
					eleClassName="filter-select"
					eleFullWidth
					eleOnChange={handleAsyncItemStatus}
					optionList={asyncItemStatusOptions}
					eleTestId="async-item-status"
					withLabel={false}
				/>
			</div>
			<hr style={{ opacity: 0.4 }} />
			{filteredItems.length} / {items.length} (filtered / total)
			<div className="async-item-list">
				{filteredItems.map((item) => {
					return (
						<StyledNotiCard className={item.status}>
							<div className="FR AC JSB status-and-type">
								{item.type === AsyncItemType.fileFolder ? (
									<div className="FR AC">
										<Icon
											path={mdiFolderDownload}
											size={0.9}
										/>
										<div
											style={{
												fontSize: "0.9rem",
												marginLeft: 5,
											}}
										>
											File Folder
										</div>
									</div>
								) : item.type === AsyncItemType.replica ? (
									<div className="FR AC">
										<ReplicaProjectIcon size={0.9} />
										<div
											style={{
												fontSize: "0.9rem",
												marginLeft: 5,
											}}
										>
											Replica
										</div>
									</div>
								) : item.type === AsyncItemType.unarchived ? (
									<div className="FR AC">
										<UnarchivedProjectIcon size={0.9} />
										<div
											style={{
												fontSize: "0.9rem",
												marginLeft: 5,
											}}
										>
											Unarchiving
										</div>
									</div>
								) : item.type === AsyncItemType.exportAll ? (
									<div className="FR AC">
										<Icon
											path={mdiFolderDownloadOutline}
											size={0.9}
										/>
										<div
											style={{
												fontSize: "0.9rem",
												marginLeft: 5,
											}}
										>
											Export All
										</div>
									</div>
								) : (
									<DLChip
										size="small"
										label={item.type}
										eleTestId="item-type"
										variant="outlined"
									/>
								)}
								{item.status === AsyncItemStatus.ready &&
								(item.type === AsyncItemType.archZip ||
									item.type === AsyncItemType.fileFolder ||
									item.type === AsyncItemType.exportAll) ? (
									<DLIconButton
										eleTestId="download-btn"
										eleClassName="download-ready-icon"
										tooltipText={
											item.expired
												? "Expired"
												: "Download"
										}
										disabled={item.expired}
										clickHandler={() =>
											downloadAndMoveToPrevItems(item)
										}
									>
										<Icon path={mdiDownload} size={0.8} />
									</DLIconButton>
								) : item.status ===
								  AsyncItemStatus.downloaded ? (
									<DLIconButton
										eleTestId="download-again-btn"
										disabled={item.expired}
										// eleClassName="download-ready-icon"
										tooltipText={
											item.expired
												? "Expired"
												: "Download"
										}
									>
										<Icon path={mdiDownload} size={0.8} />
									</DLIconButton>
								) : (
									<DLChip
										size="small"
										label={item.status}
										eleTestId="item-status"
										color={
											item.status === AsyncItemStatus.fail
												? MessageColorType.red
												: item.status ===
												  AsyncItemStatus.inprogress
												? "primary"
												: item.status ===
												  AsyncItemStatus.ready
												? MessageColorType.blue
												: "default"
										}
									/>
								)}
							</div>
							{/* 
                            
                            Card title  
                            
                            */}
							<div className="card-title">
								{item.type === AsyncItemType.preprocess ? (
									<DLTooltip title="Open this project">
										<Link
											to="/xxxx"
											className="project-link"
										>
											{item.title}
										</Link>
									</DLTooltip>
								) : (item.type === AsyncItemType.replica ||
										item.type ===
											AsyncItemType.unarchived ||
										item.type ===
											AsyncItemType.rollForward ||
										item.type ===
											AsyncItemType.duplicateTemplate ||
										item.type ===
											AsyncItemType.newProjByTemplate) &&
								  item.status === AsyncItemStatus.ready ? (
									<DLTooltip title="Open this project">
										<Link
											to="/xxxx"
											className="project-link"
										>
											{item.title}
										</Link>
									</DLTooltip>
								) : (
									<div>{item.title}</div>
								)}
							</div>
							{/* 
                            
                            When the item has parent project link
                            
                            */}
							{(item.type === AsyncItemType.fileFolder ||
								item.type === AsyncItemType.exportAll) && (
								<DLTooltip
									title="Open"
									arrow
									elePlacement="top"
								>
									<Link to="/xxxx" className="project-link">
										Project: {item.parentName}
									</Link>
								</DLTooltip>
							)}
							{/* 
                            
                            When the item has expiration data
                            
                            */}
							{item.expiration && (
								<div>
									Expiration:{" "}
									{getTzDateAndTime2({
										date: item.expiration,
										dntFormat,
									})}
								</div>
							)}
							{/**
							 *
							 * Downloaded ??
							 *
							 */}
							{/* {item.status === AsyncItemStatus.downloaded && (
								<div className="download-button FR AC JSB">
									<span>Download again</span>
									<DLIconButton eleTestId="download-btn">
										<Icon path={mdiDownload} size={0.8} />
									</DLIconButton>
								</div>
							)} */}
							{/**
							 *
							 * Failed ??
							 *
							 */}
							{item.status === AsyncItemStatus.fail && (
								<div className="download-button FR AC JSB">
									<DLButton
										eleTestId="retry-btn"
										// color="warning"
										size="thin"
									>
										Retry
									</DLButton>
								</div>
							)}
						</StyledNotiCard>
					)
				})}
			</div>
		</StyledDownloadItems>
	)
})

const StyledDownloadItems = styled.div`
	.async-itmes-filter {
		width: 100%;
		.filter-label {
			width: 94px;
			max-width: 94px;
		}
		.filter-select {
			padding-top: 8px;
			width: calc(100% - 94px);
		}
	}
	.status-and-type {
		margin-bottom: 0.4rem;
		font-size: 0.7rem;
	}
	.async-item-list {
		height: calc(100vh - 15rem);
		overflow-y: auto;
	}
	.READY {
		/* border-color: ${(props) => props.theme.emphasis}; */
		/* border-radius: 3px; */
		/* background-color: ${(props) => props.theme.emphasisLight}; */
		.download-ready-icon {
			color: ${(props) => props.theme.emphasis};
			fill: ${(props) => props.theme.emphasis};
		}
	}
	.project-link {
		:hover {
			color: ${(props) => props.theme.emphasis};
		}
	}
`
