import { types } from "mobx-state-tree"

export const ItemCount = types.model({
	totalCount: types.number, // [totalCount] ex) 0,
	completedCount: types.number, // [completedCount] ex) 0,
	dateTime: types.union(types.null, types.string), // [DateTime] ex) null,
	data: types.optional(types.string, ""), // [data] ex) "",
	isPending: types.boolean, // [is_pending] ex) false
})

export const WpPfDetail = types.model({
	wpCount: types.union(types.undefined, types.number),
	pfCount: types.union(types.undefined, types.number),
})

export const ArchiveCondition = types.model({
	// NOTE: org setup arch policy has similar one
	id: types.string, // [id] ex) 12

	title: types.string, // [name] ex) "열려있는 파일"
	order: types.number, // [order] ex) 1
	isMandatory: types.boolean, // [is_mandatory] ex) 1
	counts: ItemCount, // [counts] ex)
	additionalData: types.union(WpPfDetail, types.null), // [additional_data] ex)
	hasDetail: types.boolean,
	status: types.enumeration("Condition Status", [
		"TODO",
		"INPROGRESS",
		"DONE",
	]),
	countingMethod: types.enumeration("Counting Method", [
		"TYPE_A",
		"TYPE_B",
		"TYPE_C",
	]),
})

export const RationaleFileItem = types.model({
	description: types.string, // ex) "wpwp first file"
	isModifiedAfterRationale: types.boolean, // ex) false
	modifiedBy: types.string, // ex) "epUserId1"
	modifiedDate: types.union(types.string, types.null), // ex) "2020-05-10T18:36:45.628539+00:00"
	rationaleBy: types.string, // ex) "cskim"
	rationaleDate: types.union(types.string, types.null), // ex) "2020-05-15T10:41:53.937453+00:00"
	rationaleDesc: types.string, // ex) "cxc"
	rationaleId: types.string, // ex) 154
	reference: types.string, // ex) "wpwp"
	workpaperId: types.string, // ex)  5296
	type: types.enumeration("Source From", ["WP", "PF"]),
})

export const ConditionDetailItem = types.model({
	id: types.string, // [wprId] ex) 153834
	type: types.enumeration("Source From", ["WP", "PF"]),
	aliasId: types.string, // [wref] ex) "eee"
	description: types.string, // [wpdesc] ex) "png"
	mediaType: types.string, // [mediaType] ex) ".png"
	mediaId: types.string, // [mediaId] ex) 267772
	index: types.number, // [index] ex) 1
	// TODO: assigned role type should be checked.
	// TODO: below 'Roles' seems not working on the API...
	// TODO: assignedRoles: types.union(types.null, types.string ), // [Roles] ex) null
})
