// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetReadOnlyUsers = (self: any) => ({
	getReadOnlyUsers(projId: string) {
		// 0.
		const actionName = "getReadOnlyUsers"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		const clientId = idToNumber(projId, IdType.project)
		const payload = {
			clientId: clientId,
		}
		self.readReadOnlyUsers(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// re-organize response data
					const userList = response.data.Result
					let readOnlyUsers: any = []
					userList.map((user: any) => {
						const reOrganizedUsers = {
							// clientName: user.ClientName,
							email: user.Email,
							expiredDate: user.ExpiredDate,
							// fiscalYear: user.FiscalYear.toString(),
							id: idToString(user.Id, IdType.user), // readonly assign ID
							isExportPerm: user.IsExportPerm,
							// isgrayed: user.Isgrayed,
							// lastModifiedBy: user.LastModifiedBy,
							// modifiedDate: user.ModifiedDate,
							name: user.Name,
							// projectName: user.ProjectName,
							suspended: user.Suspended,
							title: user.Title,
							userId: idToString(user.UserId, IdType.user), // original user ID
							aliasId: user.UserName,
							userType:
								user.UserType === 1 ? "Internal" : "External",
							// version: user.Version,
						}
						readOnlyUsers.push(reOrganizedUsers)
					})
					self.setAccessMgmtUserList(readOnlyUsers)
					// success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	relayGetReadonlyIdOnly(
		projList: any,
		index: number,
		userId: number,
		updateFunction: any
	) {
		if (projList.length >= index + 1) {
			const project = projList[index]
			const projId = project.id
			const projNumId = idToNumber(projId, IdType.project)
			const payload = {
				clientId: projNumId,
			}
			self.readReadOnlyUsers(payload)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// when success
						console.log(
							index,
							"Success to get the assigned readonly user list",
							projNumId
						)
						const userList = response.data.Result
						userList.map((user: any) => {
							if (user.UserId === userId) {
								console.log(
									userId,
									"is assigned in",
									projNumId,
									"as",
									user.Id
								)
								updateFunction({
									projId: projNumId,
									readonlyId: user.Id,
								})
							}
						})
						// next step
						if (projList.length > index + 1) {
							self.relayGetReadonlyIdOnly(
								projList,
								index + 1,
								userId,
								updateFunction
							)
						}
						if (projList.length === index + 1) {
							console.log(" ----- THE END ----- ")
						}
					} else {
						// when fail
						console.log(
							index,
							"Failed to get the assigned readonly user list",
							projNumId
						)
						// next step
						if (projList.length > index + 1) {
							self.relayGetReadonlyIdOnly(
								projList,
								index + 1,
								userId,
								updateFunction
							)
						}
						if (projList.length === index + 1) {
							console.log(" ----- THE END ----- ")
						}
					}
				})
				.catch((err: Error) => {
					console.log(index, "Err", err)
					// next step
					if (projList.length > index + 1) {
						self.relayGetReadonlyIdOnly(
							projList,
							index + 1,
							userId,
							updateFunction
						)
					}
					if (projList.length === index + 1) {
						console.log(" ----- THE END ----- ")
					}
				})
		}
	},
})

export default GetReadOnlyUsers
