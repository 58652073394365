import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import MapUnmapDialogForm from "./MapUnmapDialogForm"
import { DialogType } from "../../store/data-models/fs-setup.data-model"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function MapUnmapSubGroupDialog({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()
	const actionName = "getAvailableFinSubGroups"

	const unMapHandler = (fsSubGroupId: number) => {
		const details = projStore.fsSetup
			.viewFSSubGroups()
			.find((item: any) => item.id === fsSubGroupId)
		if (details) {
			projStore.fsSetup.unmapSubGroupFromGroup(details.mapId)
		}
	}

	const mapHandler = (fsSubGroupId: number) => {
		const groupId = projStore.fsSetup.selectedGroupId
		projStore.fsSetup.mapSubGroupToGroup(groupId, fsSubGroupId)
	}

	const createSubGroup = (subGroupName: string) => {
		const projId = projStore.checkin.projId
		const groupId = projStore.fsSetup.selectedGroupId
		projStore.fsSetup.addFinancialSubGroup(subGroupName, projId, groupId)
	}

	const updateSubGroup = (fsSubGroupName: string, fsSubGroupId: number) => {
		projStore.fsSetup.editFinancialSubGroup(fsSubGroupName, fsSubGroupId)
	}

	const deleteSubGroup = (fsSubGroupId: number) => {
		let proceed = window.confirm(`Are you sure you want to delete?`)
		if (proceed) {
			projStore.fsSetup.removeFinancialSubGroup(fsSubGroupId)
		} else {
			return
		}
	}

	return (
		<DLDialog
			eleTestId="map-unmap-sub-group-dialog"
			draggable
			isOpen={projStore.fsSetup.mapUnmapSubGroupDialogOpen}
			setIsOpen={projStore.fsSetup.setMapUnmapSubGroupDialogOpen}
			showCloseBtn={true}
			dialogTitle="Map/Un-map Sub-groups to Financial Group"
			dialogContents={
				<MapUnmapDialogForm
					permission={permission}
					mapHandler={mapHandler}
					unMapHandler={unMapHandler}
					availableItems={projStore.fsSetup.viewAvailableSubGroups()}
					mappedItems={projStore.fsSetup.viewFSSubGroups()}
					dialogType={DialogType.subGroup}
					handleCreate={createSubGroup}
					handleUpdate={updateSubGroup}
					handleDelete={deleteSubGroup}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				projStore.fsSetup.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				projStore.fsSetup.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
