import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"

// separated model actions
import ReadProjTrash from "./model-actions/read-proj-trash"
import UpdateProjTrash from "./model-actions/request-restore-proj-trash"
import DeleteProjTrash from "./model-actions/delete-proj-trash"
import { ProjSetupTrashApiProps } from "./project-trash.apis"
import DeleteAmArchivedProject from "../../../organization-side/archive-management/am-archived-projects/store/model-actions/delete-am-archive-project"

const ProjectTrashModel = ({
	apiRead,
	apiRemove,
	apiRestore,
	apiRemoveAll,
	apiRemoveProj,
}: ProjSetupTrashApiProps) =>
	types
		.model({})
		.actions((self) => ReadProjTrash(self, apiRead))
		.actions((self) => UpdateProjTrash(self, apiRestore))
		.actions((self) => DeleteProjTrash(self, apiRemove, apiRemoveAll))
		// @Reusing from arch mgmt for proj delete
		.actions((self) => DeleteAmArchivedProject(self, apiRemoveProj))
		// model common actions
		.actions(CommonModelActions)

export default ProjectTrashModel
