import { flow } from "mobx-state-tree"

const CreateNotes = (self: any, apiCreate: any) => ({
	createNotes: flow(function*(payload: any) {
		const actionName = "createNote"
		try {
			const response = yield apiCreate(payload)
			// ConsoleLog("createNotes response", response)
			return response
		} catch (error) {
			// console.error("createNotes error", error, error.response)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default CreateNotes
