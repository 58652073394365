import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import OrgSetupRiskAssessment from "./OrgSetupRiskAssessment"
import { CannotAccess } from "../../../../components/basic-elements"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function OrgSetupRiskAssessmentController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_risk
	const actionName = "getRiskAssessmentList"
	const i18nSheet = OrgI18n.ra
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.setupRiskAssessment.needRefresh

	useEffect(() => {
		menuAccess &&
			needRefresh &&
			orgStore.setupRiskAssessment.getRiskAssessmentList()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<OrgSetupRiskAssessment
					partialStore={orgStore.setupRiskAssessment}
					fetchingStatus={orgStore.setupRiskAssessment.getActionStatus(
						actionName
					)}
					permission={permission}
					i18n={i18n}
					contentHeight={store.ui.contentsAreaHeight}
				/>
			) : (
				<CannotAccess />
			)}
		</>
	)
})
