import { types } from "mobx-state-tree"
// ViewModel, Model, UiHelper
import OrgFileLibModel from "./org-file-lib.model"
import OrgFileLibViewModel from "./org-file-lib.view-model"
// import OrgFileLibUiHelper from "../../org-templates/store/org-templates.ui-helper"
import OrgFileLibUiHelper from "./org-file-lib.ui-helper"
// apis
import * as api from "./org-file-lib.apis"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { OrgLibraryType } from "./data-models/org-library.data-model"
import { OrgFileLibApiProps } from "./org-file-lib-api-props"

export const OrgFileLibStore = ({
	readLibraryFolders,
	createLibraryFolder,
	updateLibraryFolder,
	deleteLibraryFolder,
	copyLibraryTemplate,
	createLibraryTemplate,
	updateLibraryTemplate,
	deleteLibraryTemplate,
}: OrgFileLibApiProps) =>
	types
		.compose(
			OrgFileLibModel({
				readLibraryFolders,
				createLibraryFolder,
				updateLibraryFolder,
				deleteLibraryFolder,
				copyLibraryTemplate,
				createLibraryTemplate,
				updateLibraryTemplate,
				deleteLibraryTemplate,
			}),
			OrgFileLibViewModel,
			OrgFileLibUiHelper
		)
		.named("OrgFileLibStore")

export const initialStore = {
	selectedTemplate: {
		id: "",
		parentId: "",
		title: "",
		type: OrgLibraryType.wpTemplate || OrgLibraryType.apTemplate,
		expanded: undefined,
	},
	selectedTemplateURL: "",
	// ui-helper

	fileClickPoint: initialClickPoint,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgFileLibStoreInstance = OrgFileLibStore({
	// library folder related apis
	readLibraryFolders: api.readLibraryFolders,
	createLibraryFolder: api.createLibraryFolder,
	updateLibraryFolder: api.updateLibraryFolder,
	deleteLibraryFolder: api.deleteLibraryFolder,
	// library templaterelated apis
	copyLibraryTemplate: api.copyLibraryTemplate,
	createLibraryTemplate: api.createLibraryTemplate,
	updateLibraryTemplate: api.updateLibraryTemplate,
	deleteLibraryTemplate: api.deleteLibraryTemplate,
}).create(initialStore)

export default OrgFileLibStoreInstance
