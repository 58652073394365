import { types } from "mobx-state-tree"
import {
	SimpleUserModel,
	SimpleUserModelType,
} from "../../../../../../service-modules/user-module/user-module-data-models"

export const OrgDashboardItem = types.model({
	//
	//
	// * 1. location info: group, cabinet
	// groupId: types.string, // system ID
	groupName: types.string,
	cabinetId: types.string, // system ID
	cabinetName: types.string, // Not using
	//
	//
	// * 2. client info\
	// clientId: types.string, // system ID
	clientName: types.string, // ex) "데모식품"
	// clientAliasId: types.string,
	//
	//
	// * 3. project basic info
	id: types.string,
	aliasId: types.string,
	title: types.string,
	//
	engTypeName: types.string, // ex) "860995"
	raName: types.union(types.null, types.string), // ex) ""
	//
	//
	// * 4. project status info
	statusId: types.string, // ex) 3
	statusName: types.string, // ex) "Archived"
	// periodId
	periodName: types.string, // ex) "Full year"
	//
	//
	isLocked: types.boolean, // ex) true
	isArchived: types.boolean, // ex) true +
	version: types.string, // ex) 1
	versionName: types.string, // ex) "Archived v1"
	//
	//
	// * 5. access info
	// roleId: types.string, // system ID
	// roleName: types.string, // Not using
	accessible: types.boolean, // ex) true
	//
	//
	// * 6. schedule info
	financialYear: types.string, // ex) 2019 // TODO: Can we use integer instead of the string?
	//
	expectedReportDate: types.union(types.null, types.string),
	finalReportDate: types.union(types.null, types.string), // ex) "2019-03-05T19:00:00+00:00"
	expectedArchiveDeadlineDate: types.union(types.null, types.string),
	finalArchiveDeadlineDate: types.union(types.null, types.string),
	//
	//
	// * 7. history info
	createdBy: types.union(types.null, SimpleUserModel),
	createdAt: types.union(types.null, types.string),
	//
	// * 8. assigned users
	epUsers: types.array(SimpleUserModel), // ex) ""
	qcUsers: types.array(SimpleUserModel), // ex) ""
	//
	//
	// * date information
	archPolicyPeriod: types.union(types.null, types.string), // ex) "60 Days" +
	archivedAt: types.union(types.null, types.string), // ex) "2019-06-20T08:29:07.043423+00:00"
	unarchivedAt: types.string,
	startDate: types.union(types.null, types.string), // ex) null
	isLate: types.union(types.undefined, types.boolean), // NOTE: undefined: cannot calculate (final report date does not exist)
	//
	//
	// * 9. extras
	archiveProjectToolTipId: types.string, // ex) 1   NOTE: what is the purpose of this value?
	//
	cpCompletionCount: types.string, // ex) 0
	cpStatus: types.string, // ex) "0"
	//
	partnerCompletedCount: types.string, // ex) 6
	partnerStatus: types.string, // ex) "100"
	//
	preparedCompletedCount: types.string, // ex) 0
	preparedStatus: types.string, // ex) "0"
	//
	reviewedCompletedCount: types.string, // ex) 6
	reviewedStatus: types.string, // ex) "100"
	//
	summaryReportId: types.string, // ex) 2647
	totalCpCount: types.string, // ex) 6
	totalPartnerCount: types.string, // ex) 6
	totalPreparedCount: types.string, // ex) 6
	totalReviewedCount: types.string, // ex) 6
})

export type OrgDashboardItemType = {
	//
	//
	// * 1. location info: group, cabinet
	// groupId: string, // system ID
	groupName: string
	cabinetId: string // system ID
	cabinetName: string // Not using
	//
	//
	// * 2. client info\
	// clientId: string, // system ID
	clientName: string // ex) "데모식품"
	// clientAliasId: string,
	//
	//
	// * 3. project basic info
	id: string
	aliasId: string
	title: string
	//
	engTypeName: string // ex) "860995"
	raName: null | string // ex) ""
	//
	//
	// * 4. project status info
	statusId: string // ex) 3
	statusName: string // ex) "Archived"
	// periodId
	periodName: string // ex) "Full year"
	//
	//
	isLocked: boolean // ex) true
	isArchived: boolean // ex) true +
	version: string // ex) 1
	versionName: string // ex) "Archived v1"
	//
	//
	// * 5. access info
	// roleId: types.string, // system ID
	// roleName: types.string, // Not using
	accessible: boolean // ex) true
	//
	//
	// * 6. schedule info
	financialYear: string // ex) 2019 // TODO: Can we use integer instead of the string?
	//
	expectedReportDate: null | string
	finalReportDate: null | string // ex) "2019-03-05T19:00:00+00:00"
	expectedArchiveDeadlineDate: null | string
	finalArchiveDeadlineDate: null | string

	//
	// * 7. history info
	createdBy: null | string
	createdAt: string
	//
	//
	// * 8. assigned users
	epUsers: SimpleUserModelType[] // ex) ""
	qcUsers: SimpleUserModelType[] // ex) ""
	//
	//
	// * date information
	archPolicyPeriod: null | string // ex) "60 Days" +
	archivedAt: null | string // ex) "2019-06-20T08:29:07.043423+00:00"
	unarchivedAt: string
	startDate: null | string // ex) null
	isLate: undefined | boolean // NOTE: undefined: cannot calculate (final report date does not exist)
	//
	//
	// * 9. extras
	archiveProjectToolTipId: string // ex) 1   NOTE: what is the purpose of this value?
	//
	cpCompletionCount: string // ex) 0
	cpStatus: string // ex) "0"
	//
	partnerCompletedCount: string // ex) 6
	partnerStatus: string // ex) "100"
	//
	preparedCompletedCount: string // ex) 0
	preparedStatus: string // ex) "0"
	//
	reviewedCompletedCount: string // ex) 6
	reviewedStatus: string // ex) "100"
	//
	summaryReportId: string // ex) 2647
	totalCpCount: string // ex) 6
	totalPartnerCount: string // ex) 6
	totalPreparedCount: string // ex) 6
	totalReviewedCount: string // ex) 6
}

export enum CardType {
	progressRate = "PROGRESS_RATE",
	engType = "ENGAGEMENT_TYPE",
	group = "GROUP",
	inTime = "IN_TIME",
	epUser = "EP_USER",
	qcUser = "QC_USER",
	periodType = "PERIOD_TYPE",
}
