import { types } from "mobx-state-tree"
import OrgListModel from "./org-list.model"
import OrgListViewModel from "./org-list.view-model"
import * as api from "./org-list.apis"

export const OrgListStore = (apiRead: any) =>
	types.compose(OrgListModel(apiRead), OrgListViewModel).named("OrgListStore")

const OrgListStoreInstance = OrgListStore(api.read).create({
	// viewModel common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
})

export default OrgListStoreInstance
