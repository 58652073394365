// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetFinalAnalysis = (self: any) => ({
	getFinalAnalysis(
		fsTypeId: number,
		projectId: string,
		tbId: number,
		wpId: string
	) {
		// 0. set actionName
		const actionName = "getFinalAnalysis"
		// 1. set response cases
		// 2. set response
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Fetching final analytics details is in progress",
			open: false,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const workpaperId = idToNumber(wpId, IdType.file)
		const payload = {
			clientId,
			fsTypeId: fsTypeId,
			tbNameId: tbId,
			wprId: workpaperId,
		}

		// 3) request API call to Model
		self.readFinalAnalysis(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data

						const finalAnalysis = {
							purpose: fetchedData.Purpose,
							scope: fetchedData.Scope,
							conclusion: fetchedData.Conclusion,
						}
						self.setFinalAnalysis(finalAnalysis)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched final analytic details lead sheets",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetFinalAnalysis
