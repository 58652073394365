import { flow } from "mobx-state-tree"

const DeleteReadOnlyUser = (self: any, delReadOnlyUser: any) => ({
	deleteReadOnlyUser: flow(function*(payload) {
		const actionName = "deleteReadOnlyUser"
		try {
			const response = yield delReadOnlyUser(payload)

			return response
		} catch (error) {
			// ConsoleLog("deleteReadOnlyUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteReadOnlyUser
