import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { putRequest } from "../../../../library/api-requests"

const version = process.env.REACT_APP_API_VERSION

export type ChangePasswordProps = {
	EmailID: string
	// UserID: string
	Password: string
	NewPwd: string
	AWSAccessToken: any
}

export async function changePassword(payload: ChangePasswordProps) {
	const endpoint = "/ChangePassword"
	const v2_prefix = "/security/Token"
	const response = await putRequest({
		url: version === "v2" ? v2_prefix + endpoint : endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.loginToken,
		version,
	})

	return response
}

export type MyAccountProps = {
	apiChangePassword: (
		payload: ChangePasswordProps
	) => Promise<AxiosResponse<any>>
}
