import { types } from "mobx-state-tree"

import {
	ReadArchiveDiagnosis,
	ProjectLock,
	ProjectUnlock,
	RequestArchive,
	ReadRationaleFiles,
	DownloadArchiveDiagnosisPdf,
} from "./model-actions"

import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import ReadConditionDetail from "./model-actions/read-condition-detail"
import { ArchDiagnosisApiProps } from "./archive-diagnosis.apis"
import ReadExportArchivedZipFile from "../../../../organization-side/archive-management/am-archived-projects/store/model-actions/read-export-archived-zip-file"

const ArchiveDiagnosisModel = ({
	apiRead,
	apiUnlock,
	apiLock,
	apiReadDetail,
	apiReadRationales,
	apiExportPdf,
}: ArchDiagnosisApiProps) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadArchiveDiagnosis(self, read))
		.actions((self, unlock = apiUnlock) => ProjectUnlock(self, unlock))
		.actions((self, lock = apiLock) => ProjectLock(self, lock))
		// TODO: WARNING:
		.actions(RequestArchive)
		.actions((self, readDetail = apiReadDetail) =>
			ReadConditionDetail(self, readDetail)
		)
		.actions((self, readRationales = apiReadRationales) =>
			ReadRationaleFiles(self, readRationales)
		)
		.actions((self, exportPdf = apiExportPdf) =>
			DownloadArchiveDiagnosisPdf(self, exportPdf)
		)
		//
		.actions(ReadExportArchivedZipFile) // TODO: this model uses org Token. Need to consider more
		// common parts
		.actions(CommonModelActions)

export default ArchiveDiagnosisModel
