import React from "react"
import Autocomplete, {
	createFilterOptions,
} from "@material-ui/lab/Autocomplete"
import TextField from "@material-ui/core/TextField"
import styled from "styled-components"
import { DLCheckbox } from "@datalobby/components"

export default function DLComboBoxWithCheckbox({
	options,
	getOptionLabel,
	defaultValue = [],
	renderTags,
	fullWidth = true,
	onChange,
	eleTestId = "dl-checkbox-combo-box",
	eleValue,
	elePlaceHolder = "select",
	eleClassName,
}: {
	options: any
	getOptionLabel: (option: any) => any
	defaultValue?: any
	renderTags?: any
	fullWidth?: boolean
	onChange?: any
	eleTestId?: string
	eleValue?: any
	elePlaceHolder?: string
	eleClassName?: string
}) {
	// const icon = <CheckBoxOutlineBlankIcon fontSize="small" />;
	// const checkedIcon = <CheckBoxIcon fontSize="small" />;
	const filterOptions = createFilterOptions({
		matchFrom: "any",
		limit: 30,
	})
	return (
		<SteyldDLComboBoxWithCheckbox className={eleClassName}>
			<Autocomplete
				multiple
				// limitTags={2}
				id=""
				data-testid={eleTestId}
				className="autocomplete-body"
				options={options}
				getOptionLabel={getOptionLabel}
				onChange={(event: object, value: any) => onChange(value)}
				defaultValue={defaultValue}
				disableCloseOnSelect // Seems not working with multiple property
				size="small"
				renderTags={renderTags}
				filterOptions={filterOptions}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="standard"
						placeholder={
							defaultValue.length === 0 ? elePlaceHolder : ""
						}
						fullWidth={fullWidth}
					/>
				)}
				renderOption={(option, { selected }) => {
					let optionAsString: string = ""
					if (typeof option !== "string") {
						optionAsString = "OptionIsNotString"
					} else {
						optionAsString = option
					}

					const testId =
						"option-checkbox-" +
						optionAsString.toLowerCase().replace(/ /g, "-")

					return (
						<StyledOption className="FR AC">
							<DLCheckbox
								eleTestId={testId}
								isChecked={selected}
								color="primary"
							/>
							<span className="option-label">
								{optionAsString}
							</span>
						</StyledOption>
					)
				}}
				value={eleValue}
			/>
		</SteyldDLComboBoxWithCheckbox>
	)
}

const SteyldDLComboBoxWithCheckbox = styled.div``
const StyledOption = styled.div`
	font-size: ${(props) => props.theme.shared.body2};
	.option-label {
		margin-left: 4px;
		white-space: nowrap;
	}
	overflow: hidden;
	height: 1.4rem;
`
