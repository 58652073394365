// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const UnAssignReadOnlyUser = (self: any) => ({
	unAssignReadOnlyUser(id: string, projectId: string) {
		// 0.
		const actionName = "unAssignReadOnlyUser"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		if (projectId === undefined || id === undefined) {
			alert(`${actionName} one of the params is undefined`)
			return
		}
		const clientId = idToNumber(projectId, IdType.project)
		const userId = idToNumber(id, IdType.user)
		const payload = {
			clientId: clientId,
			id: userId,
		}

		self.deleteReadOnlyUser(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					// empty existing stored list
					const user = self.accessMgmtUserList.find(
						(item: any) => item.id === id
					)
					self.removeAccessMgmtUserFromStore(id)
					// to update the project list

					const userId = localStorage.getItem("orgUserId")
					if (userId === user.userId) {
						self.updateAccessible(projectId, false, "false")
					}
					// success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	relayUnassignReadonlyUser(projList: any, index: number) {
		if (projList.length >= index + 1) {
			const project = projList[index]
			const payload = {
				clientId: project.id,
				id: project.readonlyId,
			}
			self.deleteReadOnlyUser(payload).then((response: any) => {
				if (response.data.Status === 1) {
					console.log("success to unassign from", project.id)
					if (projList.length > index + 1) {
						self.relayUnassignReadonlyUser(projList, index + 1)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				} else {
					console.log("failed to unassign from", project.id)
					if (projList.length > index + 1) {
						self.relayUnassignReadonlyUser(projList, index + 1)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				}
			})
		}
	},
})

export default UnAssignReadOnlyUser
