import React, { useState, useCallback, useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus, mdiAccountPlusOutline } from "@mdi/js"
import {
	AssignProjUserInputProps,
	ProjRoles,
	ProjAccess,
} from "../store/data-models/proj-setup-users.data-models"
import AddProjUserForm from "./AddProjUserForm"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export const AddProjUserButton = ({ i18n }: { i18n: DLI18nProps }) => {
	const projStore = useProjStore()
	return (
		<DLButton
			variant="contained"
			eleTestId="assign-user-dialog-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			clickHandler={() => projStore.projUsers.setOpenAdd(true)}
			color="primary"
		>
			{i18n.twAssignUser || "Assign User"}
		</DLButton>
	)
}

export default observer(function AddProjUser({ i18n }: { i18n: DLI18nProps }) {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName = "assignProjUser"
	const projId = projStore.checkin.projId // localStorage.getItem("latestProjId")
	const actBy = projStore.checkin.userId // localStorage.getItem("orgUserId")

	const [inputs, setInputs] = useState<AssignProjUserInputProps>({
		type: "internal",
		id: "",
		roleId: ProjRoles.js,
		accessTypeId: ProjAccess.user,
		// readonly: false,
		canRollForward: false,
		extUserRight: ProjAccess.readonly,
		expDate: "",
	})

	useEffect(() => {
		projStore.projUsers.resetPrevUserRole()
	}, [])

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value

		if (value === "true") {
			value = true
		} else if (value === "false") {
			value = false
		}

		if (name === "roleId" && value === ProjRoles.ep) {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
				accessTypeId: ProjAccess.archAdmin,
				canRollForward: true,
			}))
		}
		if (name === "roleId" && value === ProjRoles.qc) {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
				accessTypeId: ProjAccess.projAdmin,
			}))
		}
		if (name === "accessTypeId" && value === ProjAccess.readonly) {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
				canRollForward: false,
			}))
		} else {
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
			}))
		}
	}, [])

	const handleUserSelect = (user: any) => {
		const userId = user ? user.id : ""
		projStore.projUsers.setSelectedUserForAssign(userId)
		if (userId !== "") {
			validatePreviousUserRole(userId)
		}
		if (userId === "") {
			projStore.projUsers.resetPrevUserRole()
		}

		setInputs({
			...inputs,
			id: userId,
		})
	}

	const validatePreviousUserRole = (userId: string) => {
		console.log("validatePreviousUserRole", userId)
		projStore.projUsers.validatePrevUserRole(userId)
	}

	const handleAssign = () => {
		if (projId !== null && actBy !== null) {
			projStore.projUsers.assignProjUser(inputs, projId, actBy)
		} else {
			alert("(AddProjUserDialog) Project ID or User ID does not exist")
		}
	}

	const handleDateInput = (name: string, date: string) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: date,
		}))
	}

	return (
		<DLDialog
			eleTestId="add-proj-user-dialog"
			isOpen={projStore.projUsers.openAdd}
			setIsOpen={projStore.projUsers.setOpenAdd}
			handleAction={handleAssign}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon path={mdiAccountPlusOutline} size={1} />
					<div style={{ marginLeft: 8 }}>
						{i18n.twAssignUser || "Assign User"}
					</div>
				</div>
			}
			dialogContents={
				<AddProjUserForm
					inputs={inputs}
					handleInputs={handleInputs}
					handleUserSelect={handleUserSelect}
					handleDateInput={handleDateInput}
					dateFormat={store.global.dateFormat.value}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				inputs.type === "internal"
					? inputs.id !== ""
					: inputs.id !== "" && inputs.expDate !== ""
			}
			actionBtn={i18n.twAssign || "Assign"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				projStore.projUsers.getActionStatus(actionName) === "FAIL"
					? projStore.projUsers.getMessage(actionName)
					: ""
			}
			showSpinner={
				projStore.projUsers.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				projStore.projUsers.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
