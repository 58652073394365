import { ProgressStatus } from "../../common-models/enumerations/common-enums"
import { IdType, idToString } from "../../library/converters/id-converter"
import { CommentStatus } from "../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { findProjRoleInfo } from "../../temporary-data/project-side/find-proj-role"
import { OldSignOffType } from "./data-models/sign-off.data-model"

export function convertAttention(status: string) {
	let newStatus: string = ""
	if (status === "Notstarted") {
		newStatus = ProgressStatus.todo
	} else if (status === "Inprogress") {
		newStatus = ProgressStatus.inProgress
	} else if (status === "Completed") {
		newStatus = ProgressStatus.done
	} else {
		alert(`(convertAttention) Not matched case: ${status}`)
	}
	return newStatus
}

export function revertAttention(status: ProgressStatus) {
	let attentionForApi: string = ""
	if (status === ProgressStatus.todo) {
		attentionForApi = "Notstarted"
	} else if (status === ProgressStatus.inProgress) {
		attentionForApi = "Inprogress"
	} else if (status === ProgressStatus.done) {
		attentionForApi = "Completed"
	} else {
		alert(`(revertAttention) Not matched case: ${status}`)
	}
	return attentionForApi
}

export function convertCommentStatus(commentStatus: number) {
	if (commentStatus === 1) {
		return CommentStatus.notExist
	} else if (commentStatus === 2) {
		return CommentStatus.todo
	} else if (commentStatus === 3) {
		return CommentStatus.done
	} else {
		return CommentStatus.unknown // for exception case
	}
}

// FileModified: false
// SignOff: null
// SignOffDate: null
// SignOffInfo: {
//	roleName: "EP",
//	userName: "userId_am",
//  Date: "2020-08-16T17:48:57.857867+00:00"
// }
// SignOffModified: false
// SignUp: "2020-08-16T17:48:57.857867+00:00"
// UserID: 861
// roleId: 9
// signOffType: 1
export function convertSignOffs(signOffs: any[] | null) {
	let signOffList: any[] = []
	if (signOffs !== null && signOffs.length !== 0) {
		signOffs.map((item: any) => {
			const newRole = findProjRoleInfo(item.roleId)

			signOffList.push({
				userId: idToString(item.UserID, IdType.user),
				userName: item.SignOffInfo.userName, // also need converter
				//
				roleShortName: newRole.shortName, // also need converter
				//
				signOffType:
					item.signOffType === 1
						? OldSignOffType.prepare
						: OldSignOffType.review,
				// TODO: Check if the exception case exist... ex) 3
				//
				signedAt: item.SignOffInfo.Date,
				//
				signOffModified: item.SignOffModified,
				srcModified: item.FileModified,
			})
		})
	}

	return signOffList
}
