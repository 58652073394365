import React from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLButton, DLDialog } from "../../../../../../components/basic-elements"
import Icon from "@mdi/react"
import {
    mdiLock,
    mdiInformationVariant,
    mdiCheckboxMarkedCircleOutline,
} from "@mdi/js"
import { DialogOnOffProps } from "../../../../../../common-models/types/dialog.props"

export default observer(function ProjectLockDialog({
    open,
    setOpen,
    isAdminMode,
}: DialogOnOffProps) {
    const projStore = useProjStore()
    const actionName = "setProjectLock"

    const handleProjectLock = () => {
        projStore.archive.setProjectLock(() => {
            projStore.projInfo.updateProjectLock(true)
            setOpen(false)
        })
    }

    return (
        <div>
            <DLDialog
                eleTestId="project-lock-dialog"
                dialogTitle="Project Lock"
                dialogContents={<ProjectLockDialogContents />}
                actionBtn={
                    <DLButton
                        startIcon={<Icon path={mdiLock} size={0.8} />}
                        eleTestId="project-lock-btn"
                        clickHandler={handleProjectLock}
                    >
                        Project Lock
                    </DLButton>
                }
                openBtn={
                    <DLButton
                        eleTestId="project-lock-btn"
                        startIcon={<Icon path={mdiLock} size={0.8} />}
                        tooltipText="This Lock allow over 1GB. Must check the zipping setting first"
                        color={isAdminMode ? "warning" : "primary"}
                    >
                        Project Lock
                    </DLButton>
                }
                showSpinner={
                    projStore.archive.getActionStatus(actionName) === "LOADING"
                        ? true
                        : false
                }
                showCloseBtn={true}
                cancelBtnText="cancel"
            />
        </div>
    )
})

const ProjectLockDialogContents = observer(() => {
    const projStore = useProjStore()

    return (
        <>
            {projStore.archive.totalRationaleFiles > 0 ? (
                <div className="FR JSB">
                    <Icon
                        path={mdiInformationVariant}
                        size={1}
                        color="orange"
                    />
                    <span style={{ marginLeft: 2 }}>
                        {projStore.archive.totalRationaleFiles} files were
                        modified after the Final Report Date.{" "}
                        {projStore.archive.filesWithoutRationaleCount} files
                        have not Rationale. Do you really want to lock this
                        project?
                    </span>
                </div>
            ) : (
                <div className="FR JSB">
                    <Icon
                        path={mdiCheckboxMarkedCircleOutline}
                        size={1}
                        color="blue"
                    />
                    <span style={{ marginLeft: 5 }}>
                        No files were modified after the Final Report Date, Do
                        you really want to lock this project?
                    </span>
                </div>
            )}
        </>
    )
})
