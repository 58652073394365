import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetConditionDetail = (self: any) => ({
	getConditionDetail(conditionId: string) {
		// 0.
		const actionName = "getConditionDetail"
		// 1.
		// 2.
		// 3.
		const conditionNumId = idToNumber(conditionId, IdType.archiveCondition)
		if (typeof conditionNumId !== "number") {
			ConsoleLog([actionName, conditionNumId])
			alert(`(${actionName}) invalid props`)
			return
		}
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readConditionDetail(conditionNumId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const existingWpList = response.data.WorkpaperList
					const existingPfList = response.data.PermanentFileList
					// 2) Re-organize workpaper list
					existingWpList.map((wp: any) => {
						const reOrganizedWpInfo = {
							id: wp.wprId.toString(), // [wprId] ex) 153834
							type: "WP",
							aliasId: wp.wref, // [wref] ex) "eee"
							description: wp.wpdesc, // [wpdesc] ex) "png"
							mediaType: wp.mediaType, // [mediaType] ex) ".png"
							mediaId: wp.mediaId.toString(), // [mediaId] ex) 267772
							index: wp.index, // [index] ex) 1
						}
						self.pushDetailItem(reOrganizedWpInfo)
					})
					// 3) Re-organize permanent list
					existingPfList.map((pf: any) => {
						const reOrganizedPfInfo = {
							id: pf.wprId.toString(), // [wprId] ex) 153834
							type: "PF",
							aliasId: pf.wref, // [wref] ex) "eee"
							description: pf.wpdesc, // [wpdesc] ex) "png"
							mediaType: pf.mediaType, // [mediaType] ex) ".png"
							mediaId: pf.mediaId.toString(), // [mediaId] ex) 267772
							index: pf.index, // [index] ex) 1
						}
						self.pushDetailItem(reOrganizedPfInfo)
					})
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success",
						open: false,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message:
							response.data.Message ||
							response.data.message ||
							"Something went wrong...",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "__error ", error])
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetConditionDetail
