// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const RemoveProjTemplate = (self: any) => ({
	removeProjTemplate(templateId: string) {
		// 0.
		const actionName = "removeProjTemplate"
		// 1.
		let proceed = window.confirm(
			`Do you really want to delete this Project Template<${self.getTemplateNameById(
				templateId
			)}>?`
		)
		if (!proceed) {
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.

		self.deleteProjTemplate(templateId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					self.emptyTheFlatList() // remove stored data
					self.resetSelectedTemplate()
					self.reflectRemoveProjTemplate(templateId)

					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveProjTemplate
