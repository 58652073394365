import { types, IAnyModelType } from "mobx-state-tree"

const Menu = types.model({
	id: types.string,
	title: types.string,
	location: types.string,
	subMenus: types.optional(
		types.maybe(types.late((): IAnyModelType => Menu)),
		[]
	),
})

const UiModel = types
	.model({
		menuList: types.array(Menu),
	})
	.actions((self) => ({
		// getMenuList: flow(function*(appType) {
		// 	try {
		// 		const endpoint = `${baseURL}/datalobby/menuStructure`
		// 		const response = yield axios.get(endpoint)
		// 		const result = response.data.menuStructureList.find(
		// 			element => element.id === appType
		// 		)
		// 		self.menuList = result
		// 	} catch (error) {
		// 		console.error("getMenuList error", error)
		// 	}
		// })
	}))

export default UiModel
