import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const VerifyDigitalSignatureHashValue = (self: any) => ({
	verifyDigitalSignatureHashValue(projectId: string, hashValue: string) {
		// 0.
		const actionName = "verifyDigitalSignatureHashValue"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			clientId: clientId,
			InputHash: hashValue,
		}
		self.readVerifyDigitalSignatureHashValue(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					self.setDigitalSignatureDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default VerifyDigitalSignatureHashValue
