import { types } from "mobx-state-tree"

import ArchiveDiagnosisModel from "./archive-diagnosis.model"
import ArchiveDiagnosisViewModel from "./archive-diagnosis.view-model"
import ArchiveDiagnosisUiHelper from "./archive-diagnosis.ui-helper"
//
import * as api from "./archive-diagnosis.apis"
import * as remoteApi from "../../rationales/store/rationales.apis"
import { ArchDiagnosisApiProps } from "./archive-diagnosis.apis"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export const initialStore = {
	// Archive Diagnosis
	title: "",
	// Archive condition detail
	selectedConditionId: "",
	//opened files
	storeName: DLProjSubMenus.wp, // TODO: Is this right ...?
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

export const ArchiveDiagnosisStore = ({
	apiRead,
	apiUnlock,
	apiLock,
	apiReadDetail,
	apiReadRationales,
	apiExportPdf,
}: ArchDiagnosisApiProps) =>
	types
		.compose(
			ArchiveDiagnosisModel({
				apiRead,
				apiUnlock,
				apiLock,
				apiReadDetail,
				apiReadRationales,
				apiExportPdf,
			}),
			ArchiveDiagnosisViewModel,
			ArchiveDiagnosisUiHelper
		)
		.named("ArchiveDiagnosisStore")

const ArchiveDiagnosisStoreInstance = ArchiveDiagnosisStore({
	apiRead: api.read,
	apiUnlock: api.unlock,
	apiLock: api.lock,
	apiReadDetail: api.readDetail,
	apiReadRationales: remoteApi.read,
	apiExportPdf: api.apiExportPdf,
}).create(initialStore)

export default ArchiveDiagnosisStoreInstance
