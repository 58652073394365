import { flow } from "mobx-state-tree"

const ReadOrgTrashList = (self: any, read: any) => ({
	readOrgTrashList: flow(function*(type: string) {
		const actionName = "readOrgTrashList"
		try {
			const response = yield read({ TrashType: type })
			// ConsoleLog("readOrgTrashList response", response)
			return response
		} catch (error) {
			// ConsoleLog("readOrgTrashList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadOrgTrashList
