import { types } from "mobx-state-tree"
// common models

// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import { ReadTemplateGroups } from "./model-actions"

/**
 * * separated actions hierarchy
 * - Template Folder > Template > File Folder > File
 */
// 1. template folder (group of templates)
import {
	CreateProjTemplateGroup,
	UpdateProjTemplateGroup,
	DeleteProjTemplateGroup,
	RequestDuplicateProjTemplateGroup,
} from "./model-actions/template-list"
// 2. template
import {
	CreateProjTemplate,
	ReadProjTemplateDetails,
	UpdateProjTemplate,
	DeleteProjTemplate,
} from "./model-actions/template"
// 3. template file folder
import { ReadProjTemplateFileFolderDetails } from "./model-actions/file-folder"

import { OrgProjTemplatesApiProps } from "./org-proj-templates.api-props"
import RequestDuplicateProjTemplate from "./model-actions/template/request-duplicate-proj-template"
import RequestDuplicateFolder from "./model-actions/file-folder/request-duplicate-file-folder"
import RequestDuplicateProjTemplateFile from "./model-actions/file/request-duplicate-proj-template-file"

import {
	CreateFile,
	UpdateFile,
	DeleteFile,
	RequestReplaceFile,
	RequestFileUpload,
} from "../../../../../service-modules/file-module/model-actions/file"

import {
	CreateFolder,
	UpdateFolder,
	DeleteFolder,
	RequestReorder,
} from "../../../../../service-modules/file-module/model-actions/file-folder"

import { RequestDownloadFile, RequestDownloadFolder, RequestDownloadAll } from "../../../../../service-modules/file-module/model-actions/download"
import RequestAssignRoleToFile from "../../../../../service-modules/file-module/model-actions/assign/request-assign-role-to-file"

const OrgTemplatesModel = ({
	apiReadProjTemplateGroups,
	apiReadProjTemplateDetails,
	// 1. templare group
	apiCreateProjTemplateGroup,
	apiDeleteTemplateGroup,
	apiUpdateTemplateGroup,
	apiRequestDuplicateProjTemplateGroup,
	// 2. template
	apiCreateTemplate,
	apiUpdateTemplate,
	apiDeleteTemplate,
	apiDuplicateTemplate,
	// 3. template file folder
	apiCreateFileFolder,
	apiReadFileFolderDetalis,
	apiUpdateFileFolder,
	apiDeleteFileFolder,
	apiReorderChildren,
	// 4. file
	apiCreateFile,
	apiUpdateFile,
	apiDeleteFile,
	apiReplaceFile,
	apiDuplicateFile,
	apiDownloadFile,
	apiDownloadFolder,
	apiDownloadAll,
	//
	apiAssignRole,
}: OrgProjTemplatesApiProps) =>
	types
		.model({})
		// default
		.actions((self) => ReadTemplateGroups(self, apiReadProjTemplateGroups))

		/**
		 *  * separated actions hierarchy
		 * - Template Folder > Template > File Folder > File
		 * - Template Folder
		 * - Template
		 * - File Folder (in Template)
		 * - File (in Template)
		 */
		//
		//
		//
		//
		// 1. Template Group
		.actions((self) =>
			CreateProjTemplateGroup(self, apiCreateProjTemplateGroup)
		)
		.actions((self, deleteFolder = apiDeleteTemplateGroup) =>
			DeleteProjTemplateGroup(self, deleteFolder)
		)
		.actions((self) =>
			UpdateProjTemplateGroup(self, apiUpdateTemplateGroup)
		)
		.actions((self) =>
			RequestDuplicateProjTemplateGroup(
				self,
				apiRequestDuplicateProjTemplateGroup
			)
		)
		//
		//
		//
		//
		// 2. Template
		.actions((self) => CreateProjTemplate(self, apiCreateTemplate))
		.actions((self) =>
			ReadProjTemplateDetails(self, apiReadProjTemplateDetails)
		)
		.actions((self) => UpdateProjTemplate(self, apiUpdateTemplate))
		.actions((self) => DeleteProjTemplate(self, apiDeleteTemplate))
		.actions((self) =>
			RequestDuplicateProjTemplate(self, apiDuplicateTemplate)
		)
		//
		//
		//
		//
		// 3. File Folder (in Template)
		.actions((self) => CreateFolder(self, apiCreateFileFolder))
		.actions((self) =>
			ReadProjTemplateFileFolderDetails(self, apiReadFileFolderDetalis)
		)
		.actions((self) => UpdateFolder(self, apiUpdateFileFolder))
		.actions((self) => DeleteFolder(self, apiDeleteFileFolder))
		.actions((self) => RequestDuplicateFolder(self, apiDuplicateFile))
		.actions((self) =>
			RequestDuplicateProjTemplateFile(self, apiDuplicateFile)
		)
		.actions((self) => RequestReorder(self, apiReorderChildren))
		//
		//
		//
		//
		// 4. File (in Template)
		.actions((self) => CreateFile(self, apiCreateFile))
		.actions((self) => UpdateFile(self, apiUpdateFile))
		.actions((self) => DeleteFile(self, apiDeleteFile))
		.actions((self) => RequestReplaceFile(self, apiReplaceFile))
		.actions((self) => RequestFileUpload(self, apiCreateFile))
		//
		.actions((self) => RequestAssignRoleToFile(self, apiAssignRole))

		//
		//
		//
		// download
		.actions((self) => RequestDownloadFile(self, apiDownloadFile))
		.actions((self) => RequestDownloadFolder(self, apiDownloadFolder))
		.actions((self) => RequestDownloadAll(self, apiDownloadAll))
		//
		// Common model actions
		.actions(CommonModelActions)

export default OrgTemplatesModel
