import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLDialog } from "../../../../../components/basic-elements"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import styled from "styled-components"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

const FileHistoryDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const store = useRootStore()
		const projStore = useProjStore()
		const objectId = partialStore.selectedFile
		const objInfo = partialStore.getItemInfo(objectId)
		const projectId = projStore.checkin.projId
		const menuId = partialStore.storeName
		const dntFormat = store.global.getDntFormat

		useEffect(() => {
			partialStore.getFileHistory({
				activityType: "All",
				projId: projectId,
				target: "File",
				fileId: objInfo.id,
				menuId,
			})
		}, [])

		console.log(objInfo, "objInfo")
		const dialogName = FileScreenDialog.FileHistoryDialog
		const setOpen = () => {
			partialStore.setFileTreeDialogOpen(dialogName)
		}

		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		return (
			<DLDialog
				eleTestId="file-history-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle="File History"
				dialogContents={
					<>
						<div style={{ marginBottom: 20 }}>
							<b>Target File:</b> ({objInfo.aliasId}){" "}
							{objInfo.title}
						</div>
						<FileHistoryTabel
							data={partialStore.getSignOffLockHistories(
								dntFormat
							)}
						/>
					</>
				}
				openBtn={<div />}
				// cancelBtnText="Cancel"
				actionReady={false}
				// handleAction={downloadPDF}
				maxWidth="md"
				showSpinner={
					partialStore.getActionStatus("getFileHistory") === "LOADING"
				}
			/>
		)
	}
)

const FileHistoryTabel = ({ data }: { data: any }) => {
	return (
		<StyledSignOff>
			<ReactTableV8
				tableColumns={columns}
				data={data}
				hasPagination={false}
				showPageSetting={false}
				// height={contentHeight + 20}
				menuId="FileHistory"
			/>
		</StyledSignOff>
	)
}

const StyledSignOff = styled.div`
	table {
		thead > tr > th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				justify-content: center;
			}
		}
		tbody {
			.MuiTableCell-root {
				padding: 8px;
			}
		}
	}
`

const columns = [
	{
		header: "Activity",
		accessorKey: "activityName",
	},
	{
		header: "Ref.Num",
		accessorKey: "refName",
	},
	{
		header: "File Name",
		accessorKey: "fileName",
	},
	{
		header: "Attention Status",
		accessorKey: "attentionStatus",
	},
	{
		header: "Date",
		accessorKey: "createdDate",
	},
	{
		header: "By",
		accessorKey: "createdBy",
	},
]

export default FileHistoryDialog
