import React from "react"
import { observer} from "mobx-react-lite"
import { DLSingleSelect, DLIconButton, DLButton } from "../../basic-elements"
import { displayedProjRoles } from "../../../temporary-data/project-side/find-proj-role"
import { ProjAccess } from "../../../screens/project-side/project-setup-users/store/data-models/proj-setup-users.data-models"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import styled from "styled-components"

export default observer(function OtherUsersComponent({
	otherUsers,
	setInputs
}: {
	otherUsers:{ id: string; roleId: string; accessRight: string }[]
	setInputs: any
}) {
	const handleAddUser = () => {
		const users = [...otherUsers]
		users.push({id: "", roleId: "", accessRight: ""})
		setInputs((inputs:any) => ({
			...inputs,
			otherUsers: users
		}))
	}
	
	const handleSelect = (target:string, index:number, value:string) => {
		const users:any = [...otherUsers]
		users[index][target] = value
		setInputs((inputs:any) => ({
			...inputs,
			otherUsers: users
		}))
	}
	
	const handleRemoveRow = (index:number) => {
		const users:any = [...otherUsers]
		users.splice(index,1)
		setInputs((inputs:any) => ({
			...inputs,
			otherUsers: users
		}))
	}
    return (
    	<>
	        <OtherUsersRow otherUsers={otherUsers} handleSelect={handleSelect} handleRemoveRow={handleRemoveRow}/>
			<div>
				<DLButton eleTestId="add-users-row" clickHandler={handleAddUser}>
					Add Row
				</DLButton>
			</div>
		</>
    )
})


const OtherUsersRow = observer(({
	otherUsers,
	handleSelect,
	handleRemoveRow
} : {
	otherUsers: { id: string; roleId: string; accessRight: string }[],
	handleSelect: any
	handleRemoveRow: any
}) => {
	const orgStore = useOrgStore()
	const groupUserList = orgStore.setupGroups.viewEpAvailableUsers
	const projRoleList = displayedProjRoles.filter(
		(role: any) => role.roleId !== "id_oa" && role.roleId !== "pu_ex"
	).map((item:any) => ({ name:item.name, value: item.roleId}))
	const userList = groupUserList.map((item:any) => ({ name:item.name, value: item.id}))
	return (
		<StyledOtherUsersComponent>
			{otherUsers.map((item:any,i:number) => {
				return (
					<div key={i} className="FR JSB row">
						<DLSingleSelect
							eleValue={item.id}
							eleOnChange={(e) =>
								handleSelect("id", i, e.target.value)
							}
							optionList={userList}
							eleTestId={`user-list-${i}`}
							eleClassName="user-type"
							placeholder="Select User"
							
						/>
						<DLSingleSelect
							eleValue={item.roleId}
							eleOnChange={(e) =>
								handleSelect("roleId", i, e.target.value)
							}
							optionList={projRoleList}
							eleTestId={`proj-role-${i}`}
							eleClassName="proj-role"
							placeholder="Select Role"
						/>
						<DLSingleSelect
							eleValue={item.accessRight}
							eleOnChange={(e) =>
								handleSelect("accessRight", i, e.target.value)
							}
							optionList={[
								{
									value: ProjAccess.archAdmin,
									name: "Project Archive Admin",
								},
								{
									value: ProjAccess.projAdmin,
									name: "Project Admin",
								},
								{
									value: ProjAccess.user,
									name: "Project User",
								},
								{
									value: ProjAccess.readonly,
									name: "Readonly",
								},
							]}
							eleTestId={`access-type-${i}`}
							eleClassName="access-type"
							placeholder="Select Access Type"
						/>
						<DLIconButton
							eleTestId={`remove-row-${i}`}
							clickHandler={() => handleRemoveRow(i)}
							tooltipText="Remove row"
							disabled={i===0}
						>
							<Icon path={mdiClose} size={0.6} />
						</DLIconButton>
					</div>
				)
			})}
		</StyledOtherUsersComponent>
	)
	
})


const StyledOtherUsersComponent = styled.div`
	.row{
		margin-bottom: 8px;
		.MuiInput-formControl {
		    margin-top: 0px;
		    min-width: 11rem;
		}
	}
`