import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { DLIconButton } from "../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function FSCard({
	headerName,
	list,
	clickHandler,
	handleDialogOpen,
	permission,
}: {
	headerName: string
	list: any
	clickHandler: any
	handleDialogOpen: any
	permission: PermissionAsObjectProps
}) {
	const [selectedItem, setSelectedItem] = useState(0)

	const handleClick = (id: number) => {
		setSelectedItem(id)
		clickHandler(id)
	}

	return (
		<StyledFSCard>
			<div className="card" data-testid={`card-${headerName}`}>
				<div className="card-header" data-testid="card-header">
					{headerName}
				</div>
				<div className="card-body">
					{list.map((item: any) => {
						return (
							<div
								className={`FR JSB card-list ${
									selectedItem === item.id &&
									headerName !== "Lead Sheet" &&
									"selected"
								}`}
								onClick={() => handleClick(item.id)}
								key={item.name}
								data-testid={item.name}
							>
								<span>{item.name}</span>
								{permission.update &&
									headerName !== "Lead Sheet" && (
										<DLIconButton
											variant="iconOnly"
											eleTestId="map-unmap-items"
											tooltipText="Map/Unmap"
											clickHandler={() =>
												handleDialogOpen(item.id)
											}
										>
											<Icon path={mdiPlus} size={0.6} />
										</DLIconButton>
									)}
							</div>
						)
					})}
				</div>
			</div>
		</StyledFSCard>
	)
})

const StyledFSCard = styled.div`
	padding: 1rem;
	.card {
		height: 400px;
		border: 1px solid gray;
		background-color: white;
		width: 14rem;
	}
	.card-body {
		padding: 0.6rem;
	}
	.card-header {
		background-color: #f5f7f9;
		padding: 1rem;
		font-size: 1rem;
	}
	.card-list {
		padding: 8px;
		&:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.button-base.medium {
			width: 1.2rem;
			height: 1.2rem;
		}
	}
	.selected {
		background-color: ${(props) => props.theme.emphasis};
		&:hover {
			background-color: ${(props) => props.theme.emphasis};
		}
	}
`
