import { flow } from "mobx-state-tree"

const DeleteRationale = (self: any, remove: any) => ({
	deleteRationale: flow(function* (params: any) {
		const actionName = "deleteRationale"
		try {
			const response = yield remove(params)

			// console.log(actionName+ "__response " + response)

			return response
		} catch (error) {
			// console.log(actionName, "__error:", error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default DeleteRationale
