import React, { useState, useEffect } from "react"
import SortableTree, {
	changeNodeAtPath,
	toggleExpandedForAll,
} from "react-sortable-tree"
import styled from "styled-components"
import { DLCheckbox } from "@datalobby/components"
import { mdiDrag } from "@mdi/js"
import Icon from "@mdi/react"
import StyledTree from "./StyledTree"
import { observer } from "mobx-react-lite"
import { TreeProps } from "../../../common-models/types/tree.props"
import { getTreeFromFlatData } from "./getTreeFromFlatData"

interface Node {
	id: string
	title: string
	parentId: string
	description?: string
	detail?: any
	type?: string
	aliasId?: string | null
}

interface DLTreeListProps {
	// source data for tree
	flatData: Array<any> // Should be updated
	// functions for control
	getDetail?: (targetId: string, isRecursive: boolean) => void // to get sub items
	syncExpandedStatus?: (itemId: string, expanded: boolean) => void // to sync expanded status to store
	expandAll?: boolean
	//
	showCheckbox?: boolean
	rowHeight?: "thin" | "medium" | "large" | "relative" //  for custom styling. This is not a default props of the package
	rowDirection?: "ltr" | "rtl"
	canDrag?: boolean
	eleRenderNode?: (props: any) => JSX.Element
	eleHeight?: number
	eleWidth?: number
	indentWidth?: number
	isVirtualized?: boolean
	// search related
	searchString?: string
	searchFocusIndex?: number
	searchFoundCount?: null | number
	setSearchString?: React.EventHandler<any>
	setSearchFoundCount?: React.EventHandler<any>
	setSearchFocusIndex?: React.EventHandler<any>
	sortBy?: string
}

/**
 * This component will fetch children when the user click individual item
 */
// NOTE: This DLTreeList is not used now
export default observer(function DLTreeList({
	//
	flatData,
	//
	getDetail,
	syncExpandedStatus,
	expandAll,
	//
	showCheckbox = false,
	rowHeight = "relative",
	rowDirection,
	canDrag = true,
	eleRenderNode,
	eleHeight = 900,
	eleWidth = 1200,
	indentWidth = 40,
	isVirtualized = true,
	searchString,
	searchFocusIndex,
	searchFoundCount,
	setSearchString,
	setSearchFoundCount,
	setSearchFocusIndex,
	sortBy,
}: DLTreeListProps) {
	const [tree, setTree] = useState<TreeProps>({ treeData: [] })

	const toggleExpandAll = (expanded: boolean) => {
		setTree((prevState) => ({
			treeData: toggleExpandedForAll({
				treeData: prevState.treeData,
				expanded,
			}),
		}))
	}

	useEffect(() => {
		setTree({
			treeData: getTreeFromFlatData(flatData, sortBy),
		})

		// NOTE: 'get flat data from tree' methods are on the rootStore > uiStore
	}, [flatData, flatData.length])

	useEffect(() => {
		if (expandAll) {
			toggleExpandAll(expandAll)
		}
	}, [expandAll])

	// default row renderer when there is no custom eleRowRenderer
	const renderNode = (node: any) => {
		const isDepth0 = node.parentId === null

		return (
			<StyledRow
				className={
					isDepth0
						? "depth0"
						: "" + `${rowHeight} rendered-title FR AC`
				}
				style={{
					color: isDepth0 ? "red" : "black",
					backgroundColor: isDepth0 ? "transparent" : "#e2e2e2",
				}}
				onClick={() => {
					if (node.type !== "TEMPLATE_FOLDER") {
						// because Template folder already has its children. TODO: Need some update...
						if (getDetail) {
							getDetail(node.id, false)
						} // 'false' means repeat (recursive)
					}
				}}
			>
				{canDrag && (
					<Icon path={mdiDrag} size={0.8} className="drag-icon" />
				)}
				{showCheckbox && (
					<DLCheckbox
						eleTestId="checkbox on the tree list"
						color="primary"
					/>
				)}
				<div className="node-title FR">
					{/* TODO: Need more tuning */}
					{node.parentId === null ? (
						<h4>{node.title}</h4>
					) : (
						node.title
					)}{" "}
					__ ({node.type}){" "}
					{node.children && `(${node.children.length})`}{" "}
					{!node.children && node.expanded ? "(No Children)" : ""}{" "}
				</div>
			</StyledRow>
		)
	}

	// for eleRowRenderer
	const rowHeightForStyle =
		rowHeight === "thin" ? 24 : rowHeight === "medium" ? 32 : 48

	const getNodeKey = ({ node }: any) => node.id

	return (
		<div className="FC">
			<StyledTree indentWidth={indentWidth}>
				<SortableTree
					treeData={tree.treeData}
					rowHeight={
						rowHeight === "thin"
							? 24
							: rowHeight === "medium"
							? 32
							: rowHeight === "relative"
							? undefined
							: 48
					}
					rowDirection={rowDirection}
					canDrag={canDrag}
					getNodeKey={getNodeKey}
					// onMoveNode={({ node, treeIndex, path }) =>
					// 	global.console.debug(
					// 		"node:",
					// 		node,
					// 		"treeIndex:",
					// 		treeIndex,
					// 		"path:",
					// 		path
					// 	)
					// }
					//   search related parts
					searchQuery={searchString}
					searchFocusOffset={searchFocusIndex}
					// searchFinishCallback={matches => {
					// 	setSearchFoundCount(matches.length)
					// 	setSearchFocusIndex(
					// 		matches.length > 0 ? searchFocusIndex % matches.length : 0
					// 	)
					// }}
					generateNodeProps={({
						node,
						path,
					}: {
						node: any
						path: any
					}) => {
						const depth = path.length
						return {
							title: eleRenderNode
								? eleRenderNode({
										node,
										depth,
										canDrag,
										showCheckbox,
										eleWidth,
										indentWidth,
										rowHeightForStyle,
										// functional parts
										getDetail,
										syncExpandedStatus,
								  })
								: renderNode(node),
							style: {
								boxShadow:
									depth === 1 && node.expanded
										? "0px 6px 10px -10px rgba(0,0,0,0.4)"
										: "none",
								borderTop:
									depth === 1
										? "1px solid rgba(0,0,0,0.1)"
										: "none",
								borderBottom:
									depth === 1
										? "1px solid rgba(0,0,0,0.25)"
										: node.expanded
										? "1px solid rgba(0,0,0,0.25)"
										: "1px solid rgba(0,0,0,0.1)",
							},
							onClick: () => {
								syncExpandedStatus
									? syncExpandedStatus(
											node.id,
											node.expanded
												? !node.expanded
												: true
									  ) // for re-roading
									: setTree((tree) => ({
											treeData: changeNodeAtPath({
												treeData: tree.treeData,
												path,
												getNodeKey,
												newNode: {
													...node,
													expanded: !node.expanded,
												},
											}),
									  }))
							},
						}
					}}
					isVirtualized={isVirtualized}
					onChange={(treeData) => {
						setTree({ treeData: treeData })
					}}
					// style related
					style={{ width: eleWidth, height: eleHeight }}
				/>
			</StyledTree>
		</div>
	)
})

const StyledRow = styled.div`
	.rendered-title {
		&.thin {
			height: 24px;
			padding: 0;
		}
		&.medium {
			height: 32px;
			padding: 4px;
		}
		&.large {
			height: 48px;
			padding: 8px;
		}
		.checkbox {
			padding: 0;
		}
	}

	.node-title {
		margin-left: 0.5rem;
	}
`
