import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"

const version = process.env.REACT_APP_API_VERSION

const RequestPasswordReset = (self: any) => ({
	/**
	 *
	 * @param email
	 * @returns
	 *
	 * * i18n records
	 * - success, fail
	 */
	requestPasswordReset(email: string) {
		// 0) set actionName
		const actionName = "passwordReset"
		self.setEmail(email) // for UI & UX
		// 1)
		const checkedProps = hasEmptyProps({ email })
		if (checkedProps.hasEmptyProps) {
			alert("Cannot use empty email address")
			return
		}
		// 2) set actionStatus to loading
		self.responses.putResponse({ actionName, status: ActionStatus.loading })
		// 3) request API call

		self.updateForgotPassword(checkedProps.props.email)
			/**
			 *
			 */
			.then((response: any) => {
				if (response) {
					// console.log(actionName+ "__response " + response)
					let responseStatus = 0
					let serverMessage = ""
					let fetchedData: any = {}
					//
					// * ----- API version control
					const basicStatus = response.status
					//
					if (version === "v2") {
						responseStatus = response.data.Data.Status
						serverMessage = response.data.Data.Message
						fetchedData = response.data.Data
					} else {
						responseStatus = response.data.Status
						serverMessage = response.data.Message
						fetchedData = response.data
					}
					if (basicStatus === 200 && responseStatus === 1) {
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 1,
							// message: serverMessage,
							color: MessageColorType.blue,
							open: true,
						})
					} else if (basicStatus === 200 && responseStatus === 0) {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 0,
							// message: serverMessage,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RequestPasswordReset
