import React from "react"
import { useHistory } from "react-router-dom"
import {
	BreadCrumbsArrow,
	BreadCrumbsPathProps,
	StyledDLBreadCrumbs,
} from "../../basic-elements/breadcrumbs/dl-bread-crumbs.sub-parts"
import { observer } from "mobx-react-lite"
import { IStateTreeNode, getSnapshot, types } from "mobx-state-tree"

const BreadCrumbsPathModel = types.model({
	id: types.string,
	name: types.string,
	type: types.string,
})

const ProjectLocationBreadCrumb = observer(
	({ path, setIsOpen }: { path: BreadCrumbsPathProps; setIsOpen: any }) => {
		const pathLength = path.length
		const history = useHistory()

		const handleClickCrumb = () => {
			// history.push({
			// 	pathname: "/organization/groups",
			// 	state: path,
			// })
			const pathAsNode = path as IStateTreeNode<
				typeof BreadCrumbsPathModel
			>
			const pathSnapshot = getSnapshot(pathAsNode)
			localStorage.setItem("pathSnapshot", JSON.stringify(pathSnapshot))
			history.push("/organization/groups", pathSnapshot)
			setIsOpen(false)
		}
		return (
			<StyledDLBreadCrumbs className="dl-bread-crumbs-2-container FR AC">
				{path.map((item: { id: string; name: string }, i: number) => {
					const { id, name } = item
					return (
						<div className="FR AC crumb-container" key={id + i}>
							<div
								className={`crumb FR AC ${
									i + 1 === pathLength && "last-crumb"
								}`}
							>
								<span
									className="click-able crumb-name"
									onClick={() => handleClickCrumb()}
								>
									{name}
								</span>
							</div>
							{i + 1 < pathLength && <BreadCrumbsArrow />}
						</div>
					)
				})}
			</StyledDLBreadCrumbs>
		)
	}
)

export default ProjectLocationBreadCrumb
