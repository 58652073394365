import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { OrgGroupApiCallProps } from "../../../../../../service-modules/group-module/org-group-shared-apis.calling-props"

const UpdateGroupName = (
	self: any,
	updateGroupName: OrgGroupApiCallProps["apiUpdateGroupName"]
) => ({
	updateGroupName: flow(function* (groupId: string, title: string) {
		const actionName = "updateGroupName"
		// 1.
		if (groupId === undefined || title === undefined) {
			alert(
				`(${actionName}) params are undefined. groupId: ${groupId} / title: ${title}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			try {
				let payload = {
					CabinetID: numberId,
					CabinetName: title,
					// UserID: 16
				}
				const response = yield updateGroupName(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}: ${groupId}`)
		}
	}),
})

export default UpdateGroupName
