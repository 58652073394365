import { flow } from "mobx-state-tree"
import { callGraphQL } from "@datalobby/common"
import {
	deleteOrRestorePost,
	DeletePostResponseType,
} from "../../../graphql/Post/mutations"

const DeleteOrRestorePost = (self: any) => ({
	deleteOrRestorePost: flow(function* ({
		postId,
		menuId,
		toDelete,
		by
	}: {
		postId: string
		menuId: string
		toDelete: boolean
		by: string
	}) {
		const actionName = "deleteOrRestorePost"

		console.log(actionName, postId, menuId)
		try {
			const response = yield callGraphQL<DeletePostResponseType>(
				deleteOrRestorePost,
				{ postId, menuId, toDelete, by }
			)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteOrRestorePost
