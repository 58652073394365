import { getSnapshot } from "mobx-state-tree"

type SignOffInfoType = {
	userId: string
	userName: string
	roleShortName: string
	signOffType: string
	signedAt: string
	signOffModified: boolean
	srcModified: boolean
}

/**
 * Change all next level sign-offs (Review, EP, CP & QC Reviews) to red after Prepare or UnPrepare
 *
 * @param targetFile
 */
export function changeAllNextSignOffsToRed(targetFile: any) {
	targetFile.isReviewed = false
	changeSignOffsToRed("REVIEW", targetFile)
	changeSignOffsToRed("EP", targetFile)
	changeSignOffsToRed("CP", targetFile)
	changeSignOffsToRed("QC", targetFile)
}

/**
 * Change all next level sign-offs (EP Review, CP Review & QC Review) to red next to current level Review
 *
 * @param targetColumn
 * @param targetFile
 */
export function changeSignOffsToRed(
	targetColumn: "REVIEW" | "EP" | "CP" | "QC",
	targetFile: any
) {
	let arrList = getTargetArrList(targetColumn)
	if (targetFile[arrList].length > 0) {
		targetFile[arrList] = targetFile[arrList].map((signOffInfo: any) => ({
			...signOffInfo,
			signOffModified: true,
		}))
	}
}

/**
 * Change all current level sign-offs to black after the respective sign-off level review
 *
 * @param targetColumn
 * @param targetFile
 */
export function changeCurrLevelSignOffsToBlack(
	targetColumn: "PREPARE" | "REVIEW" | "EP" | "CP" | "QC",
	targetFile: any
) {
	let arrList = getTargetArrList(targetColumn)
	if (targetFile[arrList].length > 0) {
		targetFile[arrList] = targetFile[arrList].map((signOffInfo: any) =>
			changeSingleSignOffToBlack(signOffInfo)
		)
	}
}

/**
 * Change single sign-off to black after the respective sign-off level review
 * @param signOffInfo
 * @returns
 */
export function changeSingleSignOffToBlack(signOffInfo: SignOffInfoType) {
	return {
		...signOffInfo,
		signOffModified: false,
		srcModified: false,
	}
}

function getTargetArrList(
	targetColumn: "PREPARE" | "REVIEW" | "EP" | "CP" | "QC"
) {
	let arrList = ""
	switch (targetColumn) {
		case "PREPARE":
			arrList = "prepareList"
			return arrList
		case "REVIEW":
			arrList = "reviewList"
			return arrList
		case "EP":
			arrList = "epReviewList"
			return arrList
		case "CP":
			arrList = "cpReviewList"
			return arrList
		case "QC":
			arrList = "qcReviewList"
			return arrList
		default:
			return arrList
	}
}

export function pushSignOffIfNotExisted(
	targetColumn: "PREPARE" | "REVIEW" | "EP" | "CP" | "QC",
	targetArrList: any,
	newSignOff: any
) {
	// push sign-off if not already existed
	const isExisted = targetArrList.find(
		(signOffInfo: any) => signOffInfo.userId === newSignOff.userId
	)

	if (!isExisted) {
		targetArrList.push(newSignOff)
	} else {
		const index = targetArrList.findIndex(
			(signOffInfo: any) => signOffInfo.userId === newSignOff.userId
		)
		targetArrList[index] = newSignOff
	}
}

export function removeSignOffFromList(
	targetColumn: "PREPARE" | "REVIEW" | "EP" | "CP" | "QC",
	targetArrList: any,
	userId: string
) {
	const targetIndex = targetArrList.findIndex(
		(item: any) => item.userId === userId
	)
	targetArrList.splice(targetIndex, 1)
}
