import { flow } from "mobx-state-tree"

const ReadProjectDetails = (self: any, readProjectDetails: any) => ({
	readProjectDetails: flow(function*(payload) {
		const actionName = "readProjectDetails"
		try {
			const response = yield readProjectDetails(payload)
			// ConsoleLog(
			// 	"readAmArchivedProjectList",
			// 	response
			// )
			return response
		} catch (error) {
			// ConsoleLog("readAmArchivedProjectList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadProjectDetails
