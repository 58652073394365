// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../../components/basic-elements"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const DuplicateOrgLibraryFolder = (self: any) => ({
	duplicateOrgLibraryFolder(id: string) {
		// 0.
		const actionName = "duplicateOrgLibraryFolder"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let folderId = id.split("libraryFolderId")[1]
		const payload = {
			SourceID: parseInt(folderId),
			Type: 1, // for folder
		}
		self.copyOrgLibraryTemplate(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// close the dialog
					self.setOpenDeleteAndDuplicateDialog(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DuplicateOrgLibraryFolder
