import { flow } from "mobx-state-tree"
import {
	OrgEngTypeApiProps,
	ReadChecklistConnectionsProps,
} from "../org-eng-types.apis"

const ReadChecklistConnections = (
	self: any,
	apiReadChecklistConnections: OrgEngTypeApiProps["apiReadChecklistConnections"]
) => ({
	readChecklistConnections: flow(function* (
		params: ReadChecklistConnectionsProps
	) {
		const actionName = "readChecklistConnections"
		try {
			const response = yield apiReadChecklistConnections(params)
			console.log(actionName, "response:", response)
			return response
		} catch (error) {
			console.error(actionName, "error:", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadChecklistConnections
