import { flow } from "mobx-state-tree"

const ReadFilteredUsers = (self: any, readFilterUsers: any) => ({
	readFilteredUsers: flow(function*(payload) {
		const actionName = "readFilteredUsers"
		try {
			// const endpoint = "/GetSearchAccessMngmntUsers"
			const response = yield readFilterUsers(payload)

			return response
		} catch (error) {
			// ConsoleLog("readFilteredUsers error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadFilteredUsers
