import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLCommentType } from "../../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../../../library/api-requests"

const ReadComments = (self: any) => ({
	readComments: flow(function* ({
		projId,
		type,
		menuId,
	}: {
		projId: string
		type: DLCommentType | "all"
		menuId: DLProjSubMenus
	}) {
		//
		const actionName = "readComments"
		//
		let commentType
		if (type === "all") {
			commentType = 3 // TODO: ???? ????? Check
		}
		if (type === DLCommentType.normal) {
			commentType = 1 // review comment
		}
		if (type === DLCommentType.qc) {
			commentType = 2 // qc comment
		}
		//
		const numberId = idToNumber(projId, IdType.project)
		let endpoint = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/GetReviewComments"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/GetPermanentFileReviewComments"
		}

		if (typeof numberId === "number" && endpoint !== "") {
			try {
				const params = {
					ClientID: numberId,
					// Lang: string
					TypeofComment: commentType,
				}
				const response = yield getRequest({
					url: endpoint,
					params,
					tokenType: TOKEN_TYPE.projToken,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.error(actionName, "__error", error, error.response)
				self.handleModelError({ actionName, error, open: false })

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid props exist. endpoint: ${endpoint} | ${numberId}`
			)
		}

		//

		//
	}),
})

export default ReadComments
