import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { SearchFileApiProps } from "../../apis/file-system-file-apis"

const RequestFileOrFolderSearch = (
	self: any,
	searchFileOrFolder: FileSystemApiTypes["apiSearchFileOrFolder"]
) => ({
	requestFileOrFolderSearch: flow(function* (payload: SearchFileApiProps) {
		const actionName = "requestFileOrFolderSearch"
		try {
			const response = yield searchFileOrFolder(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestFileOrFolderSearch
