import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiArrowRight, mdiAccountCardDetailsOutline, mdiSend } from "@mdi/js"
import sharedRegEx from "../../../library/sharedRegEx"
import {
	DLButton,
	DLSpinner,
	DLSnackbar,
	DLInputField,
	DLMenus,
	ConsoleLog,
} from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import styled from "styled-components"
import {
	ActionStatus,
	SupportedLanguage,
} from "../../../common-models/enumerations/common-enums"

export default observer(function ConfirmSignup() {
	const store = useRootStore()
	const actionName = "confirmSignup"
	const i18nSheet = "LobbyConfirmSignup"
	const initialValues = {
		value: "",
		isValid: true,
	}
	const [email, setEmail] = useState(initialValues)
	const [emailWarning, setEmailWarning] = useState<string>("")

	const [verificationCode, setVerificationCode] = useState(initialValues)
	const [verificationCodeWarning, setVerificationCodeWarning] =
		useState<string>("")
	const browserLanguage = navigator.language
	useEffect(() => {
		if (browserLanguage !== "en-US" && browserLanguage !== "ko-KR") {
			store.i18n.setLanguage(SupportedLanguage.us)
		} else if (browserLanguage === "en-US") {
			store.i18n.setLanguage(SupportedLanguage.us)
		} else if (browserLanguage === "ko-KR") {
			store.i18n.setLanguage(SupportedLanguage.kr)
		}
		store.forgotPw.responses.putResponse({
			actionName,
			status: ActionStatus.standby,
		})
	}, [])

	const handleEmailInput = (event: any) => {
		if (!sharedRegEx.email.test(event.target.value)) {
			setEmail({ value: event.target.value, isValid: false })
		} else {
			setEmail({ value: event.target.value, isValid: true })
		}
	}

	const handleVerificationCodeInput = (event: any) => {
		ConsoleLog("verification code " + event.target.value)
		if (!sharedRegEx.verificationCode.test(event.target.value)) {
			setVerificationCode({ value: event.target.value, isValid: false })
		} else {
			setVerificationCode({ value: event.target.value, isValid: true })
		}
	}

	const handleConfirmSignup = () => {
		let requestedInfo = {
			email: email.value,
			code: verificationCode.value, // parseInt(), --> NOTE: STRING!. Number cannot catch like "012345"
		}
		// TODO: Are below validataions required..?
		// if (!email) {
		// 	setEmailWarning("Email Address is required")
		// }
		// if (!verificationCode) {
		// 	setVerificationCodeWarning("Verification Code is requried")
		// }
		// if (email.isValid && verificationCode.isValid) {
		store.signup.confirmSignup(requestedInfo)
		// }
	}

	const handleSelectLanguage = (language: SupportedLanguage) => {
		store.i18n.setLanguage(language)
	}

	const i18n = store.i18n.combineI18n(i18nSheet, "ConfirmSignup")
	const language = store.i18n.language

	const {
		tsBackToLogin,
		twCancel,
		twConfirmSignup,
		tsHelpS1,
		tsEmailInvalid,
		twEmailAddress,
		twVerificationCode,
		tsHelpS3,
		tsVerificationInvalid,
		twVerify,
	} = i18n
	return (
		<StyledContainer className="FC AC JC confirm-sign-up-screen">
			{store.signup.responses.getActionStatus(actionName) ===
				"LOADING" && <DLSpinner />}
			<div className="page-title FC AC JC">
				<Icon
					path={mdiAccountCardDetailsOutline}
					size={5}
					color="#ffffff"
				/>
				<h1>{twConfirmSignup}</h1>
			</div>
			{/* <div className="description">
					Some descriptions will come here.
				</div> */}
			<div className="email-input FC">
				<DLInputField
					eleRequired
					eleValue={email.value}
					eleTestId="email_input_testId"
					elePlaceholder={tsHelpS1}
					eleLabel={twEmailAddress}
					eleType="email"
					eleFullWidth
					eleHandleChange={handleEmailInput}
					warningMsg={
						!email.isValid ? `${tsEmailInvalid}` : undefined
					}
					warningType="orange"
					// variant="filled"
				/>
			</div>
			<div className="verification">
				<DLInputField
					eleRequired
					eleValue={verificationCode.value}
					eleTestId="veriCode_input_testId"
					elePlaceholder={tsHelpS3}
					eleLabel={twVerificationCode}
					eleFullWidth
					eleHandleChange={handleVerificationCodeInput}
					eleClassName="for-white-text"
					warningMsg={
						verificationCode.value.length > 0 &&
						!verificationCode.isValid
							? `${tsVerificationInvalid}`
							: undefined
					}
					warningType="orange"
					// variant="filled"
				/>
			</div>
			<div className="submit-button">
				<DLButton
					variant="contained"
					eleTestId="submit-button"
					clickHandler={handleConfirmSignup}
					color="primary"
					disabled={
						!(
							email.isValid &&
							verificationCode.isValid &&
							email.value.length > 0 &&
							verificationCode.value.length > 0
						)
					}
					endIcon={<Icon path={mdiSend} size={0.8} />}
				>
					{twVerify}
				</DLButton>
			</div>
			{/* NOTE:  TODO: When the user already verified, through the aliert, redirect the user to login screen   */}
			<div className="common-back-button">
				<DLTooltip arrow title={tsBackToLogin}>
					<div className="back-to-login">
						<Link to="/login">
							<DLButton
								variant="text"
								eleTestId="back-to-login"
								endIcon={<Icon path={mdiArrowRight} size={1} />}
							>
								{twCancel}
							</DLButton>
						</Link>
					</div>
				</DLTooltip>
			</div>
			{/* response snackbar  */}
			{/* WARNING:  TODO: COnfirmSignup response snackbar haven't yet confirmed operation  */}
			<ConfirmSignupSnackbar />
			<div className="select-language">
				<DLMenus
					eleTestId="lang-select-menu"
					btn={
						<DLButton eleTestId="lang-change-btn" variant="text">
							{language.toUpperCase()}
						</DLButton>
					}
					options={[
						{
							value: "en-US",
							label: "English",
							clickEvent: () =>
								handleSelectLanguage(SupportedLanguage.us),
						},
						{
							value: "ko-KR",
							label: "Korean",
							clickEvent: () =>
								handleSelectLanguage(SupportedLanguage.kr),
						},
					]}
				/>
			</div>
		</StyledContainer>
	)
})

const ConfirmSignupSnackbar = observer(() => {
	const store = useRootStore()

	return (
		<DLSnackbar
			type={store.signup.responseSnackbar.type}
			open={store.signup.responseSnackbar.open}
			setOpen={store.signup.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={store.signup.responseSnackbar.message}
			autoHideDuration={store.signup.responseSnackbar.autoHideDuration}
			autoHide={store.signup.responseSnackbar.autoHide}
			// additionalAction={() => {
			// 	store.signup.setActionStatus(ActionStatus.standby)
			// }}
		/>
	)
})

const StyledContainer = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.emphasisDeep};
	color: ${(props) => props.theme.background};
	.page-title {
		font-size: 1.8rem;
		font-weight: 600;
		margin-bottom: 2rem;
	}
	.description {
		opacity: 0.6;
		margin-bottom: ${(props) => props.theme.shared.spacingNormal};
	}
	.email-input {
		width: 30vw;
		min-width: 300px;
		margin-bottom: ${(props) => props.theme.shared.spacingNormal};
	}
	.verification {
		width: 30vw;
		min-width: 300px;
		margin-bottom: ${(props) => props.theme.shared.spacingNormal};
	}
	.submit-button {
		margin-top: 1rem;

		}

	}
	.back-to-login {
		margin-top: 6rem;
		opacity: 0.6;
		transition: 0.2s;
		:hover {
			opacity: 1;
		}
	}
	.select-language {
		position: absolute;
		left: 1rem;
		bottom: 1rem;
	}
`
