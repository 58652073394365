import { flow } from "mobx-state-tree"
import { GraphQLResult } from "@aws-amplify/api"
import { callGraphQL } from "@datalobby/common"
import {
	postsByParent,
	PostsByParentQuery,
} from "../../../graphql/Post/queries"

const ReadOrgNotiList = (self: any) => ({
	readOrgNotiList: flow(function* (orgId: string, deleted?:boolean) {
		const actionName = "readOrgNotiList"

		function setPostType(result: GraphQLResult<PostsByParentQuery>) {
			return result.data?.postsByParent
		}

		try {
			const postData = yield callGraphQL<PostsByParentQuery>(
				postsByParent,
				{
					parentId: orgId,
					deleted: deleted || false,
				}
			)
			// console.log("postData:", postData)
			const posts = setPostType(postData)

			return posts
		} catch (error) {
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadOrgNotiList
