import { types } from "mobx-state-tree"

const OrgMgmtUiHelper = types
	.model({
		selectedId: types.string,
		openAdd: types.boolean,
		openEdit: types.boolean,
	})
	.actions((self) => ({
		setSelectedId(id: string) {
			self.selectedId = id
		},
		setOpenAdd(request?: boolean) {
			if (request !== undefined) {
				self.openAdd = request
			} else {
				self.openAdd = !self.openAdd
			}
		},
		setOpenEdit(request?: boolean) {
			if (request !== undefined) {
				self.openEdit = request
			} else {
				self.openEdit = !self.openEdit
			}
		},
	}))
	.views((self) => ({}))

export default OrgMgmtUiHelper
