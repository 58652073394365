import { types } from "mobx-state-tree"
// viewModel, Model, UiHelper
import OrgSetupGroupsViewModel from "./org-setup-groups.view-model"
import OrgSetupGroupsModel from "./org-setup-groups.model"
import OrgSetupGroupsUiHelper from "./org-setup-groups.ui-helper"
// apis
import * as api from "./org-setup-groups.apis"
import * as orgGroupApis from "../../../service-modules/group-module/org-group-shared-apis"

export const OrgSetupGroupsStore = ({
    apiRead,
    apiUpdate,
    apiDelete,
    apiCreate,
    apiReadUnassignedUsersOfGroup,
    apiReadAssignedUsersOfGroup,
    apiAssignUsersToGroup,
    apiUnAssignUsersToGroup,
    apiReadClientsByGroup,
    apiAssignClientsToGroup,
}: api.OrgSetupGroupsApiProps) =>
    types
        .compose(
            OrgSetupGroupsViewModel,
            OrgSetupGroupsModel({
                apiRead,
                apiUpdate,
                apiDelete,
                apiCreate,
                apiReadUnassignedUsersOfGroup,
                apiReadAssignedUsersOfGroup,
                apiAssignUsersToGroup,
                apiUnAssignUsersToGroup,
                apiReadClientsByGroup,
                apiAssignClientsToGroup,
            }),
            OrgSetupGroupsUiHelper
        )
        .named("OrgSetupGroupsStore")

export const initialStore = {
    groupList: [],
    userList: [],
    assignedProjectList: [],
    // ui-helpers
    selectedGroup: "",
    selectedGroupType: "",
    clickPoint: {
        mouseX: null,
        mouseY: null,
    },
    // dialogs
    addGroupDialogOpen: false,
    editGroupDialogOpen: false,
    assignClientsToGroupDialogOpen: false,
    assignUsersToGroupDialogOpen: false,
    removeGroupDialogOpen: false,
    // addGroupStatus: ActionStatus.standby,
    // refresh: false, // WARNING:
    // viewModel related parts
    responses: {},
    responseSnackbar: {
        message: "",
    },
}

const OrgSetupGroupsStoreInstance = OrgSetupGroupsStore({
    apiRead: api.read,
    apiUpdate: orgGroupApis.updateGroupItem,
    apiDelete: orgGroupApis.deleteGroupItem,
    apiCreate: orgGroupApis.createGroupItem,
    apiReadUnassignedUsersOfGroup: api.readUnassignedUsersOfGroup,
    apiReadAssignedUsersOfGroup: orgGroupApis.readGroupUsers,
    apiAssignUsersToGroup: api.assignUsersToGroup,
    apiUnAssignUsersToGroup: api.unAssignUsersToGroup,
    apiReadClientsByGroup: orgGroupApis.readClientsByGroup,
    apiAssignClientsToGroup: api.assignClientsToGroup,
}).create(initialStore)

export default OrgSetupGroupsStoreInstance
