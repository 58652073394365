import { types } from "mobx-state-tree"
// import * as ttt from "@datalobby/types"
// import { Priority, WhoCanRead } from "@datalobby/types"
import { Sector, Priority, WhoCanRead } from "@datalobby/types"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export enum AsyncItemType {
	// Download
	archZip = "ARCHIVE_ZIP",
	fileFolder = "FILE_FOLDER",
	exportAll = "EXPORT_ALL",
	/**
	 *
	 *
	 *
	 */
	// Move to the project
	preprocess = "PRE_PROCESS",
	// (after completion)
	replica = "REPLICA",
	unarchived = "UNARCHIVED",
	rollForward = "ROLL_FORWARD",
	newProjByTemplate = "NEW_PROJ_BY_TEMPLATE",
	/**
	 *
	 *
	 *
	 */
	// Move to the template
	duplicateTemplate = "DUPLICATE_TEMPLATE",
}

export enum AsyncItemStatus {
	pending = "PENDING",
	inprogress = "INPROGRESS",
	fail = "FAIL",
	ready = "READY",
	downloaded = "DOWNLOADED",
}

export type DownloadItem = {
	id: string
	targetId: null | string
	parentName?: string
	type: AsyncItemType
	status: AsyncItemStatus
	url: string
	title: string
	expiration?: string
	expired?: boolean
}

export const NotificationData = types.model({
	srcId: types.string,
	projectId: types.string,
	srcFormat: types.string,
	title: types.string,
	aliasId: types.string,
	clientName: types.string,
	linkName: types.string,
	year: types.string,
	actualFilePath: types.union(types.string, types.null),
	path: types.union(types.string, types.null),
	archive: types.boolean,
	isLocked: types.boolean,
	version: types.string,
	projectStatus: types.string,
	projectStatusName: types.string,
	projectAliasId: types.string,
})

export const RequestedData = types.model({
	srcId: types.string,
	projectId: types.string,
	srcFormat: types.string,
	title: types.string,
	aliasId: types.string,
	clientName: types.string,
	linkName: types.string,
	year: types.string,
	actualFilePath: types.union(types.string, types.null),
	path: types.union(types.string, types.null),
	archive: types.boolean,
	isLocked: types.boolean,
	version: types.string,
	projectStatus: types.string,
	projectStatusName: types.string,
	projectAliasId: types.string,
	notes: types.string,
	openedUser: types.string,
	requestedUser: types.string,
})

// export const SysNoti = types.model({
// 	content: "",
// 	title: "",
// 	id: "",
// 	createdAt: "",
// 	modifiedAt: "",
// })

// export type SysNotiType = {
// 	content: string
// 	title: string
// 	id: string
// 	createdAt: string
// 	modifiedAt: string
// }

// export const OrgNoti = types.model({
// 	menu: types.enumeration<DLOrgSubMenus>(Object.values(DLOrgSubMenus)),
// 	id: types.string,
// 	title: types.string,
// 	content: types.string,
// 	//
// 	sector: types.enumeration<Sector>(Object.values(Sector)),
// 	parent: types.string,
// 	priority: types.enumeration<Priority>(Object.values(Priority)),
// 	fixToTop: types.boolean,
// 	attachments: types.array(types.string),
// 	//
// 	// whoCanRead: types.enumeration<WhoCanRead>(Object.values(WhoCanRead)),
// 	// allowedTargets: types.array(types.string),
// 	// visitorCount: types.number,
// 	// visitors: types.array(types.string),
// 	//
// 	createdBy: types.string,
// 	createdAt: types.string,
// 	modifiedBy: types.string,
// 	modifiedAt: types.string,
// })

export const MST_PostAttachment = types.model({
	fileName: types.string,
	extension: types.string,
	size: types.number,
	formattedSize: "",
	s3ObjKey: types.string,
})

export const MST_Post = types.model({
	menu: types.string,
	id: types.string,
	title: types.string,
	content: types.string,
	sector: types.enumeration<Sector>(Object.values(Sector)),
	parent: types.string,
	priority: types.enumeration<Priority>(Object.values(Priority)),
	fixToTop: types.boolean,
	attachments: types.array(MST_PostAttachment),
	// whoCanRead: WhoCanRead
	// allowedTargets: types.string[],
	// visitors: types.string[],
	// visitorCount: number
	createdBy: types.string,
	createdAt: types.string,
	modifiedBy: types.string,
	modifiedAt: types.string,
	//
	deleted: types.boolean,
})
