import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { Link } from "react-router-dom"
import styled from "styled-components"
import {
	PageHeader,
	PageContainer,
} from "../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { getProjectIcon } from "../../../../components/basic-elements/icons/common-project-icons"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import { Column, Table } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function ArchiveHistory({
	partialStore,
	contentsHeight,
	fetchingStatus,
	i18n,
	permission,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const dnt = store.global.getDntFormat
	const { dateFormat, timeZone, timeFormat } = dnt

	const pageHeader = (instance: Table<any>) => {
		const totalRA = instance.getPreFilteredRowModel().flatRows.length
		return (
			<PageHeader>
				<div className="FR AC counter-container">
					<BasicCounter
						label={i18n.twTotal || "Total: "}
						value={totalRA}
					/>
				</div>
			</PageHeader>
		)
	}

	return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledArchiveHistory>
				<ReactTableV8
					tableColumns={Columns(i18n)}
					data={partialStore.formattedArchiveHistoryList(
						dateFormat,
						timeZone,
						timeFormat
					)}
					customFilters={filterComponent}
					hasPagination={partialStore.archiveHistoryList.length > 10}
					pageHeader={pageHeader}
					i18n={i18n}
					permission={permission}
					menuId={DLProjSubMenus.arch_history}
					height={contentsHeight}
				/>
			</StyledArchiveHistory>
			<CommonSnackbarComponent
				i18n={i18n}
				partialStore={projStore.archiveHistory}
			/>
		</PageContainer>
	)
})

const filterComponent = ({ column }: { column: Column<any> }) => {
	return (
		<InputFieldForTableV8
			columnId={column.id}
			onChange={column.setFilterValue}
			value={column.getFilterValue()}
		/>
	)
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const Columns = (i18n: DLI18nProps) => [
	{
		header: i18n.twType,
		accessorKey: "type",
		cell: renderCustomCell,
	},
	{
		header: i18n.twArchivedUnarchivedBy,
		accessorKey: "archivedUnArchivedBy",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedAt,
		accessorKey: "archivedUnArchivedAt",
		cell: renderCustomCell,
	},
	{
		header: i18n.twSourceProject,
		accessorKey: "sourceProjectName",
		cell: (props: any) => {
			const projId = props.row.original.sourceProjectId
			const projNumId = idToNumber(projId, IdType.project)
			const projStatus = props.row.original.sourceProjectType

			const cellId = props.cell.column.id + "-cell"
			return props.row.original.isDisabled ? (
				<span style={{ color: "red" }} data-testid={cellId}>
					{props.getValue()}
				</span>
			) : (
				<Link to={`/project/checkin/${projNumId}`} data-testid={cellId}>
					<div className="FR AC">
						{getProjectIcon(projStatus)}
						<span style={{ marginLeft: "0.6rem" }}>
							{props.getValue()}
						</span>
					</div>
				</Link>
			)
		},
	},
	{
		header: i18n.twArchivedNote,
		accessorKey: "reason",
		cell: renderCustomCell,
	},
	{
		header: i18n.twArchivedFiles,
		accessorKey: "x",
		cell: (props: any) => {
			const files = props.row.original.fileList
			const cellId = "archived-files-cell"
			return (
				<ol data-testid={cellId}>
					{files.map((file: any) => {
						return (
							<li>
								{/* {permission.export ? ( */}
								<a
									href={file.preSignedUrl}
									download={file.name}
								>
									{file.name}
								</a>
								{/* ) : (
                                    <span>{file.name}</span>
                                )} */}
							</li>
						)
					})}
				</ol>
			)
		},
	},
]

const StyledArchiveHistory = styled.div`
	.page-contents-area {
		padding-left: 0px;
	}
`
