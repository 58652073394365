import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import { OrgDashboardApiProps } from "./org-dashboard.apis"
import ReadOrgDashboardList from "./model-actions/read-org-dashboard-list"

const OrgDashboardModel = ({ apiRead }: OrgDashboardApiProps) =>
	types
		.model({})
		// CRUD
		.actions((self, read = apiRead) => ReadOrgDashboardList(self, read))
		// Common model actions
		.actions(CommonModelActions)

export default OrgDashboardModel
