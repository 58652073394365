import React from "react"
import { TreeNodeProps } from "../../../../../../common-models/types/tree.props"
import styled from "styled-components"
import { OrgLibraryType } from "../../store/data-models/org-library.data-model"
import FileLibFolderRowController from "./FileLibFolderRowController"
import FileLibRowController from "./FileLibRowController"

export default function OrgFileLibraryNavItemRenderer({
	node,
}: {
	node: TreeNodeProps
}) {
	return (
		<StyledOrgFileLibraryNavItem>
			{/* TODO: WARNING: Need to check which one is better: node or node.id only */}
			{node.type === OrgLibraryType.libraryFolder && (
				<FileLibFolderRowController node={node} />
			)}
			{(node.type === OrgLibraryType.wpTemplate ||
				node.type === OrgLibraryType.apTemplate) && (
				<FileLibRowController node={node} />
			)}
		</StyledOrgFileLibraryNavItem>
	)
}

const StyledOrgFileLibraryNavItem = styled.div``
