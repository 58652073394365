import React from "react"
import {
	SortingIconHeader,
	DLIconButton,
} from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { DLI18nProps } from "../../../../common-models/types/common-props"

const OrgMgmtTableColumns = (handleEdit: any, i18n: DLI18nProps) => [
	{
		Header: <SortingIconHeader title={i18n.twOrganizationName} />,
		accessorKey: "organizationName",
		minWidth: 90,
		style: {
			fontWeight: 600,
		},
	},
	{
		Header: <SortingIconHeader title={i18n.twStatus} />,
		accessorKey: "custStatus",
		minWidth: 30,
	},
	{
		Header: <SortingIconHeader title={i18n.twUserName} />,
		accessorKey: "custName",
		minWidth: 60,
	},
	{
		Header: <SortingIconHeader title={i18n.twUserId} />,
		accessorKey: "custId",
		minWidth: 40,
	},
	{
		Header: <SortingIconHeader title={i18n.twUserEmail} />,
		accessorKey: "emailId",
		minWidth: 60,
	},

	{
		Header: <SortingIconHeader title={i18n.twCreated} />,
		accessorKey: "createdBy",
		minWidth: 30,
	},
	{
		Header: "Action",
		accessorKey: "",
		width: 50,
		filterable: false,
		sortable: false,
		Cell: (row: any) => {
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					{/* {permission.update && ( */}
					<DLIconButton
						eleTestId="edit-customer"
						tooltipText="Edit"
						clickHandler={() => handleEdit(row.original.id)}
					>
						<Icon path={mdiPencil} size={0.8} />
					</DLIconButton>
					{/* )} */}
				</div>
			)
		},
	},
]

export const OrgMgmtTableColumns1 = (handleEdit: any, i18n: DLI18nProps) => [
	{
		header: i18n.twOrganizationName ?? "Org Name",
		accessorKey: "organizationName",
	},
	{
		header: i18n.twStatus ?? "Status",
		accessorKey: "custStatus",
	},
	{
		header: i18n.twUserName ?? "User Name",
		accessorKey: "custName",
	},
	{
		header: i18n.twUserId ?? "ID",
		accessorKey: "custId",
	},
	{
		header: i18n.twUserEmail ?? "User Email",
		accessorKey: "emailId",
	},
	{
		header: i18n.twCreated ?? "Created By",
		accessorKey: "createdBy",
	},
	{
		header: "Action",
		accessorKey: "actions",
		enableColumnFilters: false,
		enableSorting: false,
		cell: (props: any) => {
			return (
				<div style={{ width: "100%", textAlign: "center" }}>
					{/* {permission.update && ( */}
					<DLIconButton
						eleTestId="edit-customer"
						tooltipText="Edit"
						clickHandler={() => handleEdit(props.row.original.id)}
					>
						<Icon path={mdiPencil} size={0.8} />
					</DLIconButton>
					{/* )} */}
				</div>
			)
		},
	},
]

export default OrgMgmtTableColumns
