import { flow } from "mobx-state-tree"

const ReadPfFileCheckinrequest = (self: any, readPFRequest: any) => ({
	readPfFileCheckinrequest: flow(function*() {
		const actionName = "readPfFileCheckinrequest"
		try {
			const response = yield readPFRequest()
			// ConsoleLog("ReadPfFileCheckinrequest response", response)
			return response
		} catch (error) {
			// console.error("ReadPfFileCheckinrequest error", error, error.response)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadPfFileCheckinrequest
