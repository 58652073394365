import { types } from "mobx-state-tree"
import { RecentProjModel } from "./recent-proj-list.model"
import { RecentProjViewModel } from "./recent-proj-list.view-model"
import * as api from "./recent-proj-list.apis"

export const RecentProjStore = ({ apiRead }: api.OrgRecentProjectApiProps) =>
	types
		.compose(
			RecentProjModel({
				apiRead,
			}),
			RecentProjViewModel
		)
		.named("RecentProjStore")

export const initialStore = {
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const RecentProjInstance = RecentProjStore({
	apiRead: api.read,
}).create(initialStore)

export default RecentProjInstance
