import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../components/basic-elements"
import styled from "styled-components"
import { Link } from "react-router-dom"
import { callGraphQL } from "@datalobby/common"
// import {
// 	visitPost,
// 	VisitPostResponseType,
// } from "../../../../graphql/Post/mutations"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import PostContents from "../../../../service-modules/post/components/PostContents"
import { PostType } from "@datalobby/types"

export default observer(function ViewNotificationDialog({
	open,
	setOpen,
	userId,
	post,
}: {
	open: boolean
	setOpen: any
	userId: string | null
	post: PostType
}) {
	const store = useRootStore()
	const actionName = "addClient"

	// async function runVisitPost(options: { postId: string; userId?: string }) {
	// 	await callGraphQL<VisitPostResponseType>(visitPost, options)
	// }

	const post_static = store.notis.getOrgNotiById(post.id)
	// useEffect(() => {
	// 	if (post_static) {
	// 		if (userId) {
	// 			// if (userId && !post_static.visitors.includes(userId)) {
	// 			const options = {
	// 				postId: post.id,
	// 				userId: userId,
	// 			}
	// 			// Do not update store visit data. It will be updated with subscription
	// 			runVisitPost(options)
	// 		} else {
	// 			const options = {
	// 				postId: post.id,
	// 			}
	// 			// Do not update store visit data. It will be updated with subscription
	// 			runVisitPost(options)
	// 		}
	// 	}
	// }, [])

	return (
		<DLDialog
			eleTestId="view-noti-dialog"
			draggable
			isOpen={open}
			setIsOpen={setOpen}
			showCloseBtn={true}
			dialogTitle={
				post.parent === "sys" ? (
					"System Notification"
				) : (
					<Link
						to="/organization/notifications"
						onClick={() => {
							setOpen(false)
							store.ui.setRightDrawerOpen(false)
						}}
						data-testid="move-to-noti-list"
					>
						Organization Notification
					</Link>
				)
			}
			dialogContents={
				<StyledDialogContainer>
					<PostContents post={post} contentHeight={400} />
				</StyledDialogContainer>
			}
			cancelBtnText="Close"
			maxWidth="lg"
			fullWidth={true}
		/>
	)
})

const StyledDialogContainer = styled.div`
	max-height: 70vh;
	min-height: 300px;
`
