import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { periodOptions } from "../../../../../common-models/enumerations/org-related-enums"
import { LinearProgress } from "@material-ui/core"

const ExportDetailHistoryDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const store = useRootStore()
		const projStore = useProjStore()
		const dntFormat = store.global.getDntFormat

		const [fetchedFiles, setFetchedFiles] = useState<String[]>([])

		const postSuccess = () => {
			const { periodName, financialYear, clientName } =
				projStore.projInfo.projInfo
			const fileType =
				partialStore.selectedTab === DLProjSubMenus.wp ? "WP" : "PF"
			const periodAbbrName = periodOptions.find(
				(item: any) =>
					item.label.replace(/ /g, "").toLowerCase() ===
					periodName.replace(/ /g, "").toLowerCase()
			)?.abbr
			const fileName = `${periodAbbrName} ${financialYear} ${clientName} - ${fileType} Sign Off Detail`

			const data = partialStore.exportDetailHistory(
				{
					menuId: partialStore.selectedTab,
					dntFormat,
				}

				// store.global.dateFormat.value,
				// store.global.timeZone.uiValue
			)
			handleExport(data, fileName)
			partialStore.setDetailHistoryDialogOpen(false)
		}

		const handleCompletedFiles = (targetId: string) =>
			setFetchedFiles((prev: any) => [...prev, targetId])

		const fileIds = partialStore.getAllFileIds(partialStore.selectedTab)
		const handleAction = () => {
			console.log("file count:", fileIds.length, "/ file Ids:", fileIds)
			if (fileIds.length) {
				setFetchedFiles([])
				partialStore.relayGetSignoffHistoryDetail(
					fileIds,
					0,
					partialStore.selectedTab,
					postSuccess,
					handleCompletedFiles
				)
			}
		}

		const actionStatus = partialStore.getActionStatus(
			"relayGetSignoffHistoryDetail"
		)

		return (
			<DLDialog
				eleTestId="export-detail-history-dialog"
				isOpen={partialStore.detailHistoryDialogOpen}
				setIsOpen={partialStore.setDetailHistoryDialogOpen}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle="Export"
				dialogContents={
					<div>
						{actionStatus === "LOADING" ? (
							<ProgressBar
								fetchedFilesCount={fetchedFiles.length}
								totalFileCount={fileIds.length}
							/>
						) : (
							"Do you want to export the detail sign off history?"
						)}
					</div>
				}
				actionReady={true}
				handleAction={handleAction}
				actionBtn="Export"
				cancelBtnText="Cancel"
				// showSpinner={actionStatus === "LOADING"}
				cannotUpdate={actionStatus === "LOADING"}
			/>
		)
	}
)

const ProgressBar = ({
	fetchedFilesCount,
	totalFileCount,
}: {
	fetchedFilesCount: number
	totalFileCount: number
}) => {
	return (
		<LinearProgress
			value={(fetchedFilesCount / totalFileCount) * 100}
			variant="determinate"
		/>
	)
}

export default ExportDetailHistoryDialog
