import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddRiskAssessment = (self: any) => ({
	addRiskAssessment(props: { raName: string; userAliasId: string }) {
		// 0.
		const actionName = "addRiskAssessment"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const { raName, userAliasId } = checkedProps.props

		self.createRiskAssessment(raName)
			.then((response: any) => {
				if (response.data.Status === 1) {
					const reOrganizedRiskAssessment = {
						createdAt: new Date().toISOString(), // [CreatedDate], ex) "10/14/19 6:32:53 PM"
						createdBy: userAliasId, // [CreatedUser], ex) "cskim 10/14/2019"
						riskAssessmentId: idToString(
							response.data.RiskAssessmentID,
							IdType.ra
						), // [RiskAssessmentID], ex) 159
						riskAssessmentName: response.data.RiskAssessmentName,
					}
					// ConsoleLog(reOrganizedRiskAssessment)
					self.pushItemToList(reOrganizedRiskAssessment)
					// close the dialog
					self.setAddRiskAssessmentDialogOpen(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to add a new Risk Assessment",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.Message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddRiskAssessment
