import React from "react"
import { DLContextMenu } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import {
	IconPlaceholder,
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../components/basic-elements/icons/common-icons"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"

export default observer(function ProjUsersContextMenus({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	// ConsoleLog("ProjUsersContextMenus")
	const projStore = useProjStore()
	const userId = projStore.projUsers.selectedUser
	const actBy = localStorage.getItem("orgUserId")
	const projId = getProjIdFromUrl()

	/**
	 * < action list >
	 * - read user detail (not exist yet)
	 * - edit user
	 * - activate user
	 * - deactivate user
	 * - unassign user
	 */
	const menuOptions = (userInfo?: {
		status: "Active" | "Inactive"
		[x: string]: any
	}) => [
		// {
		// 	label: "User Detail",
		// 	value: "detail",
		// 	available: permission.read,
		// 	// clickEvent: () =>
		// 	// 	projStore.projUsers.xxx(true)
		// 	icon: <IconPlaceholder />,
		// },
		{
			label: "Edit",
			value: "edit",
			available: permission.update,
			clickEvent: () => projStore.projUsers.setOpenEdit(true),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Activate",
			value: "activate",
			disabled: userInfo && userInfo.status === "Active",
			available: permission.update,
			clickEvent: () => {
				let proceed = window.confirm(
					"Do you really want to activate this user?"
				)
				if (proceed === false) {
					return
				}
				projStore.projUsers.activateProjUser(userId)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: "Deactivate",
			value: "deactivate",
			disabled: userInfo && userInfo.status === "Inactive",
			available: permission.update,
			clickEvent: () => {
				let proceed = window.confirm(
					"Do you really want to deactivate this user?"
				)
				if (proceed === false) {
					return
				}
				projStore.projUsers.deactivateProjUser(userId)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: "Unassign",
			value: "unassign",
			available: permission.delete,
			clickEvent: () => {
				let proceed = window.confirm(
					"Do you really want to unassign this user from the project?"
				)
				if (proceed === false) {
					return
				}
				if (userId !== null && actBy !== null && projId !== null) {
					projStore.projUsers.unAssignProjUser(userId, actBy, projId)
				} else {
					alert("(Project User Ctx Menu) Props are invalid")
				}
			},
			icon: <IconPlaceholder />,
		},
	]

	const handleClose = () => {
		projStore.projUsers.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="proj-users-ctx-menus"
			clickPoint={{
				mouseX: projStore.projUsers.clickPoint.mouseX,
				mouseY: projStore.projUsers.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions(
				projStore.projUsers.viewSelectedUserInfo()
			)}
			// hasDivider={[1]}
		/>
	)
})
