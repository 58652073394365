import { types } from "mobx-state-tree"
// ViewModel, Model, UiHelper
import OrgCheckinModel from "./org-checkin.model"
import OrgCheckinViewModel from "./org-checkin.view-model"
// common models
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
// apis
import * as api from "./org-checkin.apis"
import { OrgCheckinApiProps } from "./org-checkin.apis"
import { initialRoleSet2 } from "../../../../../service-modules/role-module/roles.data-model"

export const OrgCheckinStore = ({
	apiRequestToken,
	apiReadOrgInfo,
	apiReadSysInfo,
}: OrgCheckinApiProps) =>
	types
		.compose(
			OrgCheckinModel({
				apiRequestToken,
				apiReadOrgInfo,
				apiReadSysInfo,
			}),
			OrgCheckinViewModel
		)
		.named("OrgCheckinStore")

/**
 *
 * organization checkin process
 * - 1. get organization token
 * - 2. get organization info
 * - 3. get organization system(date & time) setting
 * - 4. get user's permission in the organization (by user's role)
 * - 5. set accessible menu list of user
 * - 6. checkin to organization
 *
 */

const initialRoleInfo = {
	roleId: "",
	usePeriod: {},
	globalPermissions: [],
	menuPermissions: [],
	//
	name: "",
	shortName: "",
	color: "",
	//
	readonly: false,
	canSetup: false,
}

export const initialStore = {
	// 1. (omitted)
	// 2.
	orgInfo: {},
	// 3. --> DnT has been moved to global store
	// 4. user permission
	roleSet: initialRoleSet2,
	assignedRole: initialRoleInfo,
	// 5. accessible menu list
	// 6.
	orgCheckin: ActionStatus.standby,

	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgCheckinStoreInstance = OrgCheckinStore({
	apiRequestToken: api.requestToken,
	apiReadOrgInfo: api.readOrgInfo,
	apiReadSysInfo: api.readSysInfo,
}).create(initialStore)

export default OrgCheckinStoreInstance
