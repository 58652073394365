import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

const ReadLibrariesInProj = (self: any) => ({
	readLibrariesInProj: flow(function* (orgId: string) {
		const actionName = "readLibrariesInProj"
		// 1.
		//
		//
		// 2.
		const numberId = idToNumber(orgId, IdType.org)
		//
		//
		// 3.
		if (typeof numberId === "number") {
			try {
				const endpoint = "/GetWorkPaperTemplateInfo"
				const params = {
					CustomerID: numberId, // org ID
					// Lang: en
				}
				const response = yield getRequest({
					url: endpoint,
					params,
					tokenType: TOKEN_TYPE.projToken, // proj TOKEN...?
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) Invalid props. ${numberId}`)
		}
	}),
})

export default ReadLibrariesInProj
