import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"

const UpdateStructureLock = (
	self: any,
	updateStructureLock: FileSystemApiTypes["apiUpdateStructureLock"]
) => ({
	updateStructureLock: flow(function* (
		projId: string,
		request: "lock" | "unlock"
	) {
		const actionName = "updateStructureLock"
		const menuId = self.storeName

		const projNumId = idToNumber(projId, IdType.project)
		//
		//
		// 3.
		if (typeof projNumId === "number") {
			try {
				const response = yield updateStructureLock({
					menuId,
					request,
					projNumId,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })
				return false
			}
		} else {
			alert(`(${actionName}) Invalid prop format. ${projNumId}`)
			return false
		}
	}),
})

export default UpdateStructureLock
