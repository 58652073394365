import React from "react"
import { observer } from "mobx-react-lite"
import { DLSnackbar } from "../../../../../components/basic-elements"

export default observer(function FileMenuSnackbar({
	partialStore,
}: {
	partialStore: any
}) {
	const type = partialStore.responseSnackbar.type
	const open = partialStore.responseSnackbar.open
	const setOpen = partialStore.responseSnackbar.setOpen
	const message = partialStore.responseSnackbar.message
	const duration = partialStore.responseSnackbar.autoHideDuration
	const autoHide = partialStore.responseSnackbar.autoHide

	return (
		<DLSnackbar
			type={type}
			open={open}
			setOpen={setOpen}
			anchorLocation="RIGHT_TOP"
			message={message}
			autoHideDuration={duration}
			autoHide={autoHide}
		/>
	)
})
