import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../library/api-requests"

export async function read(params: {
	ClientId: number
}) {
	const response = await getRequest({
		url: "/GetClientInfo",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ProjInfoApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
}
