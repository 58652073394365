import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../components/basic-elements"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const CreateRequestNotification = (
	self: any,
	apiCreateRequestNotification: any
) => ({
	createRequestNotification: flow(function* (
		payload: any,
		menuId: DLProjSubMenus
	) {
		const actionName = "createRequestNotification"
		try {
			// let endpoint = ""
			// if (menuType === DLProjSubMenus.wp) {
			// 	endpoint = "/WorkPaperRequestNotifications"
			// } else if (menuType === DLProjSubMenus.pf) {
			// 	endpoint = "/SendPfRequestNotifications"
			// }
			const response = yield apiCreateRequestNotification(payload, menuId)
			ConsoleLog([actionName, "__response ", response])
			return response
		} catch (error) {
			ConsoleLog([actionName, "__error ", error])
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default CreateRequestNotification
