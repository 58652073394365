import React from "react"
import { observer } from "mobx-react-lite"
import { DLButton } from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import {
	mdiCheckCircle,
	mdiDownload,
	mdiCloseCircleOutline,
	mdiEye,
} from "@mdi/js"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function PreProcessFileRow({
	file,
	permission,
	previewPdf,
	downloadPdf,
	i18n,
}: {
	file: {
		fileName: string
		isPreProcessed: boolean
		viewUrl: any
		downloadUrl: any
	}
	permission: PermissionAsObjectProps
	previewPdf: (url: string) => void
	downloadPdf: (url: string) => void
	i18n: DLI18nProps
}) {
	return (
		<div
			className="FR AC JSB content-area"
			data-testid={`pdf-item-${file.fileName}`}
			key={file.fileName}
		>
			<div className="file-status FR AC JC">
				{file.isPreProcessed ? (
					<Icon path={mdiCheckCircle} size={0.8} color="blue" />
				) : (
					<Icon path={mdiCloseCircleOutline} size={0.8} color="red" />
				)}
			</div>
			<div className="file-title" data-testid="file-title">
				{file.fileName}
			</div>
			<div className="status-name" data-testid="file-status">
				{file.isPreProcessed ? "Completed" : "Pending"}
			</div>
			<div className="view-btn">
				{permission.read && (
					<DLButton
						eleTestId="view-pdf"
						startIcon={<Icon path={mdiEye} size={0.8} />}
						clickHandler={() => previewPdf(file.viewUrl)}
						disabled={!file.isPreProcessed}
					>
						{i18n.twViewPdf}
					</DLButton>
				)}
			</div>
			<div className="download-btn">
				{permission.export && (
					<DLButton
						eleTestId="download-pdf"
						startIcon={<Icon path={mdiDownload} size={0.8} />}
						disabled={!file.isPreProcessed}
						clickHandler={() => downloadPdf(file.downloadUrl)}
					>
						{i18n.twDownloadPdf}
					</DLButton>
				)}
			</div>
		</div>
	)
})
