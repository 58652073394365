import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"

export default observer(function ResizingTrigger({
	handleMouseDown,
}: {
	handleMouseDown: any
}) {
	return (
		<StyledResizingTrigger className="FR JC" onMouseDown={handleMouseDown}>
			<div className="notify-area"></div>
		</StyledResizingTrigger>
	)
})

const StyledResizingTrigger = styled.div`
	/* NOTE: to calculate, to not use rem for width */
	width: 16px;
	height: 100%;
	padding: 1rem 0;
	.notify-area {
		width: 3px;
		minwidth: 2px;
		padding: 1rem 0;
		transition: 0.4s;
		border-radius: 1.5px;
		height: 100%;
	}
	:hover {
		cursor: col-resize;
		.notify-area {
			background-color: ${(props) => props.theme.primaryLight};
		}
	}
`
