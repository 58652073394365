// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const DiscardCheckout = (self: any) => ({
	prepareDiscardCheckout({
		menuId,
		userId,
		srcId,
		postAction,
	}: {
		menuId: string | null | undefined // DLProjSubMenus NOTE: Because this value come from localStorage, cannot use the enum... Need to update
		userId: string | null | undefined
		srcId: string
		postAction?: () => void
	}) {
		if (menuId === undefined || userId === undefined) {
			alert(`Undefined prop exist. menuId: ${menuId} | userId: ${userId}`)
			return
		}

		if (menuId !== undefined && userId !== null) {
			// @Noah is this alert shared with normal checkin and discard checkout?
			let proceed = window.confirm(
				"Unsaved content may be lost. Do you really want to checkin this file?"
			)
			if (proceed) {
				self.discardCheckout(srcId, userId, menuId, postAction)
			} else {
				return
			}
		} else {
			alert(
				`Invalid props exist. menuId: ${menuId} | userId: ${userId} | srcId: ${srcId}`
			)
		}
	},
	/**
	 *
	 * @param srcId
	 * @param userId
	 * @param menuId
	 * @param postAction
	 *
	 * * i18n records
	 *  - success, fail
	 */
	discardCheckout(
		srcId: string,
		userId: string,
		menuId: DLProjSubMenus,
		postAction?: () => void
	) {
		// 0.
		const actionName = "discardCheckout"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.mDiscardCheckout(srcId, userId, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 1,
						color: MessageColorType.blue,
						open: true,
					})
					window.close()
					postAction && postAction() // for the ctx menu on the wp screen. to reflect the result on the screen.
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message || response.data.message,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DiscardCheckout
