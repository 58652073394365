import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	NormalProjectIcon,
	NormalLockedProjectIcon,
	ReplicaProjectIcon,
	ArchivedProjectIcon,
	UnarchivedProjectIcon,
	ForceArchivedProjectIcon,
} from "../../../../components/basic-elements/icons/common-project-icons"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"

export default observer(function ProjTitle() {
	const projStore = useProjStore()

	const project = projStore.projInfo.projInfo
	const {
		statusName,
		isLocked,
		clientName,
		financialYear,
		version,
		projAliasId,
	} = project

	return (
		<StyledProjectTitle className="FR AC">
			<DLTooltip title="Period Type">
				<h2 className="dl-ml">
					{project.periodName
						? project.periodName
						: "Period Type be failed to fetch. Please reload"}
				</h2>
			</DLTooltip>
			<DLTooltip title="Fiscal Year">
				<h2 className="dl-ml">{financialYear ? financialYear : "-"}</h2>
			</DLTooltip>
			<DLTooltip title="Client">
				<h2 className="dl-ml">{clientName ? clientName : "-"}</h2>
			</DLTooltip>
			<h2 className="dl-ml">:</h2>
			<DLTooltip title="Project Title">
				<h2 className="dl-ml">
					{project.projTitle
						? project.projTitle
						: "Project title be failed to fetch. Please reload"}
				</h2>
			</DLTooltip>
			<DLTooltip title="Project ID">
				<h2>({projAliasId})</h2>
			</DLTooltip>
			<DLTooltip title="Project Status">
				<h2>
					(
					{`${statusName ? statusName : "-"} ${
						version ? "v." + version : ""
					}`}
					)
				</h2>
			</DLTooltip>
			{/* <DLTooltip title="Engagement Type">
				<div className="project-status proj-info-item">
					{engTypeName ? engTypeName : "-"}
				</div>
			</DLTooltip> */}
			<DLTooltip title="Version">
				<div className="project-status proj-info-item">{}</div>
			</DLTooltip>
			{statusName === ProjectStatus.normal && !isLocked ? (
				<NormalProjectIcon size={1.2} />
			) : statusName === ProjectStatus.normal && isLocked ? (
				<NormalLockedProjectIcon size={1.2} />
			) : statusName === ProjectStatus.replica ? (
				<ReplicaProjectIcon size={1.2} />
			) : statusName === ProjectStatus.archived ? (
				<ArchivedProjectIcon size={1.2} />
			) : statusName === ProjectStatus.unarchived ? (
				<UnarchivedProjectIcon size={1.2} />
			) : statusName === ProjectStatus.forceArchived ? (
				<ForceArchivedProjectIcon size={1.2} />
			) : (
				statusName
			)}
		</StyledProjectTitle>
	)
})

const StyledProjectTitle = styled.div`
	.dl-ml {
		margin-left: 0.6rem;
	}
`
