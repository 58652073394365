import { flow } from "mobx-state-tree"

const ReadFinSubGroupsByFinGroup = (self: any, readFSSubGroups: any) => ({
	readFinSubGroupsByFinGroup: flow(function*(payload: {
		financialGroupId: number
		clientId: number
	}) {
		const actionName = "readFinSubGroupsByFinGroup"
		try {
			const response = yield readFSSubGroups(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadFinSubGroupsByFinGroup
