import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest,
} from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

// readLibraryFolders below is shared with proj > file > file lib drawer
export async function readLibraryFolders(params: {
	CustomerID: number
	// Lang: string
}) {
	const response = await getRequest({
		url: "/GetWorkPaperTemplateInfo",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function createLibraryFolder(params: {
	TempFoldName: string
	// UserID: number
}) {
	const response = await postRequest({
		url: "/CreateWorkpaperTmpFolder",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function updateLibraryFolder(payload: {
	TempFoldID: number
	TempFoldName: string
	// UserID: number
}) {
	const response = await putRequest({
		url: "/EditWorkpaperTempFolder",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function deleteLibraryFolder(params: {
	WorkTempFoldID: number
	// UserID: number
}) {
	const response = await deleteRequest({
		url: "/DeleteWorkpaperTmpFolder",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function copyLibraryTemplate(payload: {
	SourceID: number
	Type: number
	// UserID: number
}) {
	const response = await postRequest({
		url: "/CopyWorkPaperTemplate",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

// library template
export async function createLibraryTemplate(params: {
	MediaType: string
	ParentTempFoldID: number
	TempName: string
	// UserID: number
}) {
	const response = await postRequest({
		url: "/CreateWorkpaperTemp",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function updateLibraryTemplate(payload: {
	TempName: string
	TemplateId: number
	// UserID: number
}) {
	const response = await putRequest({
		url: "/EditWorkpaperTemplate",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function deleteLibraryTemplate(payload: {
	WorkTemplateId: number
	// UserID: number
}) {
	const response = await deleteRequest({
		url: "/DeleteWorkpaperTemplate",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
