import { flow } from "mobx-state-tree"
import {
	OtherProjectsApiProps,
	ReadOtherProjectsProps,
} from "../other-projects.apis"

const ReadOtherProjects = (
	self: any,
	readOthers: OtherProjectsApiProps["apiReadOthers"]
) => ({
	readOtherProjects: flow(function* (params: ReadOtherProjectsProps) {
		const actionName = "readOtherProjects"

		try {
			const response = yield readOthers(params)

			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadOtherProjects
