import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import BoardList from "./sub-components/BoardList"
import { observer } from "mobx-react-lite"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import PostAddOrEdit from "../../../../service-modules/post/components/PostAddOrEdit"
import { Sector } from "@datalobby/types"
import BoardDetail from "./sub-components/BoardDetail"

export default observer(function Board() {
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const defaultMenuUrl =
		projStore.checkin.defaultUrl + "/note-and-comments/board"
	const menuId = DLProjSubMenus.board
	const boardStore = projStore.board

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(menuId)

	const needRefresh = boardStore.needRefresh
	const actionName = "getProjBoardList"

	useEffect(() => {
		const projId = projStore.projInfo.projInfo.projectId
		if (menuAccess && needRefresh) {
			if (
				boardStore.getActionStatus(actionName) !== ActionStatus.success
			) {
				boardStore.getProjBoardList(projId)
			}
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<StyledBoardContainer>
					<PageContainer
						fullWidth
						fixedHeight
						hasToolbar={false}
						pageTools={<div />}
						eleClassName="board-container"
					>
						<Switch>
							<Route exact path={`${defaultMenuUrl}`}>
								<BoardList
									permission={permission}
									dataStatus={boardStore.getActionStatus(
										actionName
									)}
								/>
							</Route>
							<Route exact path={`${defaultMenuUrl}/add`}>
								<PostAddOrEdit
									permission={permission}
									sector={Sector.proj}
									partialStore={projStore.board}
									projDefaultUrl={defaultUrl}
								/>
							</Route>
							<Route
								exact
								path={`${defaultMenuUrl}/edit/:postId`}
							>
								<PostAddOrEdit
									permission={permission}
									sector={Sector.proj}
									postById={projStore.board.getProjBoardById}
									partialStore={projStore.board}
									projDefaultUrl={defaultUrl}
								/>
							</Route>
							<Route
								exact
								path={`${defaultMenuUrl}/detail/:postId`}
							>
								<BoardDetail
									permission={permission}
									menuId={menuId}
								/>
							</Route>
						</Switch>
					</PageContainer>
				</StyledBoardContainer>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledBoardContainer = styled.div`
	.board-container {
		padding: 0 1rem;
		.page-contents-area {
			padding-bottom: 0;
		}
	}
`
