import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import ReadAllFiles from "../../../../service-modules/file-module/model-actions/file/read-all-files"
import { ProjDashboardApiProps } from "./project-dashboard.apis"
import ReadDashboardDetails from "./model-actions/read-dashboard-details"

const ProjectDashboardModel = ({
	apiRead,
}: ProjDashboardApiProps) =>
	types
		.model({})
		.actions((self) => ReadDashboardDetails(self, apiRead))
		// .actions((self) => ReadAllFiles(self, apiReadAllFiles))
		// model common actions
		.actions(CommonModelActions)

export default ProjectDashboardModel
