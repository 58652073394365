import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import OrgTrash from "./OrgTrash"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import styled from "styled-components"
import { TrashType } from "./store/data-models/org-trash.data-models"

export default observer(function OrgTrashController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.file_trash

	const i18nSheet = OrgI18n.trash

	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const notificationMenuAccess = orgStore.checkin.checkAccess(DLOrgSubMenus.org_noti)
	const permission = orgStore.checkin.getActionsAsObject(menuId)


	const partialStore = orgStore.trash
	const contentsHeight = store.ui.contentsAreaHeight - 48
	const dateFormat = store.global.dateFormat.value

	//
	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<OrgTrash
					partialStore={partialStore}
					contentsHeight={contentsHeight}
					dateFormat={dateFormat}
					permission={permission}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledSimpleCardList = styled.div`
	.card-container {
		padding: 1rem;
		display: flex;
		flex-wrap: wrap;
		.simple-card {
			padding: 1rem;
			width: 240px;
			height: 160px;
			box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
			border: 1px solid ${(props) => props.theme.shade10};
			border-radius: 4px;
			margin-right: 1rem;
			margin-bottom: 1rem;
			overflow: hidden;
			cursor: pointer;
			transition: 0.4s;
			:hover {
				/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
				box-shadow: 0px 2px 8px 1px
					${(props) => props.theme.primaryLight};
				border: 1px solid ${(props) => props.theme.primaryLightDeep};
			}
		}
	}
`
