import { flow } from "mobx-state-tree"

const ReadAssignedUsers = (self: any, apiReadProjectUsers: any) => ({
	readAssignedUsers: flow(function*() {
		const actionName = "readAssignedUsers"
		try {
			const response = yield apiReadProjectUsers()

			// ConsoleLog("readProjUsersModel response", response)
			return response
		} catch (error) {
			// ConsoleLog("readProjUsersModel error", error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default ReadAssignedUsers
