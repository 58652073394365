import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import OrgSetupGroups from "./OrgSetupGroups"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { useLocation } from "react-router-dom"

export default observer(function OrgSetupGroupsController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const location = useLocation()
	
	const currentMenu = location.pathname.split("/")[2]
	const menuId = currentMenu === "org-normal-setup" ? DLOrgSubMenus.readonly_groups : DLOrgSubMenus.setup_groups
	const actionName = "getSetupGroupList"
	const i18nSheet = OrgI18n.groups
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.setupGroups.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && orgStore.setupGroups.getSetupGroupList()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<OrgSetupGroups
					partialStore={orgStore.setupGroups}
					fetchingStatus={orgStore.setupGroups.getActionStatus(
						actionName
					)}
					permission={permission}
					contentHeight={store.ui.contentsAreaHeight}
					i18n={i18n}
					menuId={menuId}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
