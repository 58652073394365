import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
//
import getOrgFileLibFolders from "./view-model-actions/get-org-file-lib-folders"
import GetOrgFileLibShelfDetails from "./view-model-actions/get-org-file-lib-shelf-details"
import AddOrgLibraryFolder from "./view-model-actions/library-folder-actions/add-org-file-lib-folder"
import EditOrgLibraryFolder from "./view-model-actions/library-folder-actions/edit-org-file-lib-folder"
import RemoveOrgLibraryFolder from "./view-model-actions/library-folder-actions/remove-org-file-lib-folder"
import DuplicateOrgLibraryFolder from "./view-model-actions/library-folder-actions/duplicate-org-file-lib-folder"
//templates
import AddOrgLibraryTemplate from "./view-model-actions/library-template-actions/add-org-file-lib-template"
import EditOrgLibraryTemplate from "./view-model-actions/library-template-actions/edit-org-file-lib-template"
import RemoveOrgLibraryTemplate from "./view-model-actions/library-template-actions/remove-org-file-lib-template"
import DuplicateOrgLibraryTemplate from "./view-model-actions/library-template-actions/duplicate-org-file-lib-template"
import {
	compareValues,
	getTreeFromFlatData,
} from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import {
	OrgLibraryFlatItem,
	OrgLibraryType,
	OrgLibraryFlatItemProps,
} from "./data-models/org-library.data-model"
import { initialStore } from "./org-file-lib.provider"

const OrgFileLibViewModel = types
	.model({
		libraryFolders: types.array(OrgLibraryFlatItem),
		//
		selectedTemplate: OrgLibraryFlatItem,
		selectedTemplateURL: types.string,
		selectedLibraryFolder: "",
		// ----- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// AGER(Add, Get, Edit, Remove) Actions
	.actions(getOrgFileLibFolders)
	.actions(AddOrgLibraryFolder)
	.actions(EditOrgLibraryFolder)
	.actions(RemoveOrgLibraryFolder)
	.actions(DuplicateOrgLibraryFolder)
	//templates
	.actions(AddOrgLibraryTemplate)
	.actions(GetOrgFileLibShelfDetails)
	.actions(EditOrgLibraryTemplate)
	.actions(RemoveOrgLibraryTemplate)
	.actions(DuplicateOrgLibraryTemplate)
	.actions((self) => ({
		setLibraryFolders(folders: any) {
			self.libraryFolders.length = 0
			self.libraryFolders = folders
		},
		pushLibraryFolderItem(folder: any) {
			self.libraryFolders.push(folder)
		},
		setSelectedTemplate(templateInfo: OrgLibraryFlatItemProps) {
			self.selectedTemplate = templateInfo
		},
		setSelectedLibraryFolder(id: string) {
			self.selectedLibraryFolder = id
		},
		setSelectedTemplateURL(url: string) {
			self.selectedTemplateURL = url
		},
		toggleExpandedTemplateFolder(itemId: string, expanded?: boolean) {
			const target = self.libraryFolders.find(
				(item: any) => item.id === itemId
			)
			if (target) {
				target.expanded = expanded ? expanded : !target.expanded
			}
		},
		updateStoreFolderName(id: string, name: string) {
			const index = self.libraryFolders.findIndex(
				(item: any) => item.id === id
			)
			self.libraryFolders[index].title = name
		},
		removeLibraryFolderFromStore(id: string) {
			self.libraryFolders.splice(
				self.libraryFolders.findIndex((item: any) => item.id === id),
				1
			)
		},
		toggleExpandedLibraryFolder(itemId: string, expanded?: boolean) {
			const target = self.libraryFolders.find(
				(item: any) => item.id === itemId
			)
			if (target) {
				target.expanded = expanded ? expanded : !target.expanded
			}
		},
	}))
	.views((self) => ({
		get libraryFoldersAsTree() {
			let sortedList = self.libraryFolders.sort(compareValues("title"))
			let tree = getTreeFromFlatData(sortedList, "title")
			return tree
		},
		projLibraryFoldersAsTreeWithQuery(query: string) {
			let list: any = []
			list = self.libraryFolders.filter((item: any) =>
				item.title.toLowerCase().includes(query.toLowerCase())
			)
			list.map((item: any) => {
				if (
					list.findIndex(
						(listItem: any) => listItem.id === item.parentId
					) === -1
				) {
					const parent = self.libraryFolders.find(
						(originItem: any) => originItem.id === item.parentId
					)
					if (parent) {
						self.toggleExpandedLibraryFolder(parent.id, true)
						list.push(parent)
					}
					// else {
					// 	ConsoleLog(
					// 		"There is no parent even in the original list"
					// 	)
					// }
				}
				// TODO: need to consider better way
			})
			// TODO: Need to check if there are other required process
			const tree = getTreeFromFlatData(list, "title")
			// ConsoleLog("tree for templates", tree)
			return tree
		},
		get templateCount() {
			const templates = self.libraryFolders.filter(
				(item) => item.type === OrgLibraryType.libraryFolder
			)
			return templates.length
		},
		isDuplicatedLibraryFolderName(name: string) {
			const target = self.libraryFolders.find(
				(item: any) => item.title === name
			)
			if (target) {
				return true
			} else {
				return false
			}
		},
		isDuplicatedLibraryTemplateName(name: string, parentId: string | null) {
			const target = self.libraryFolders
				.filter((folder: any) => folder.parentId === parentId)
				.find((item: any) => item.title === name)
			if (target) {
				return true
			} else {
				return false
			}
		},
		selectedFolderDetails(id: string) {
			return self.libraryFolders.find((folder: any) => folder.id === id)
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgFileLibViewModel
