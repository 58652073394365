import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { SetGlobalDnTType } from "../../../../../stores/root-store/global-store/global-store.view-model"
import { reOrganizeDntSettings } from "../../../../organization-side/org-access-control/org-checkin/store/view-model-actions/get-sys-info"

// NOTE: This function uses same API with 'getSysInfo' and 'readSysInfo'
// NOTE: In the future, each project will get individual date & time setting but it not be provided, that's why we call this API again
const GetProjSettings = (self: any) => ({
	getProjSettings(setGlobalDnT: (props: SetGlobalDnTType) => void) {
		// 0)
		const actionName = "getProjSettings"
		// 1)
		// 2)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		self.readProjSettings()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedSettings = response.data.SettingList
					const result = reOrganizeDntSettings(fetchedSettings)
					/**
					 * REFLECT
					 */
					const { dateFormat, timeFormat, timeZone } = result
					setGlobalDnT({
						dateFormat,
						timeFormat,
						timeZone,
					})
					//
					self.handleResponse({
						actionName,
						code: 200,
						status: ActionStatus.success,
						customMessage: "Success to get project settings",
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					self.setProjCheckin(ActionStatus.fail)
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						message: response.data.Message,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.setProjCheckin(ActionStatus.fail)
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetProjSettings
