import { flow } from "mobx-state-tree"
import { OrgSetupArchPolicyApiProps } from "../org-archive-policies.apis"

const UpdateArchivePolicy = (
	self: any,
	update: OrgSetupArchPolicyApiProps["apiUpdate"]
) => ({
	updateArchivePolicy: flow(function* (payload) {
		const actionName = "updateArchivePolicy"

		try {
			const response = yield update(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateArchivePolicy
