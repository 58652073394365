import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const EditStructureLock = (self: any) => ({
	editStructureLock({
		projId,
		request,
	}: {
		projId: string
		request: "lock" | "unlock"
	}) {
		// 0.
		const actionName = "editStructureLock"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.updateStructureLock(projId, request)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					self.setStructureLock(request === "lock" ? true : false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditStructureLock
