import { types } from "mobx-state-tree"
import UpdateProjInfo from "./model-actions/update-proj-info"
import ReadProjInfo from "./model-actions/read-proj-info"
import UpdateArchiveChecklist from "./model-actions/update-archive-checklist"
import ReadProjPeriodTypes from "../../../organization-side/org-setup/org-setup-period-types/read-proj-period-types"
import ReadEngTypeList from "../../../organization-side/org-setup/org-setup-eng-types/store/model-actions/read-engType"
import { ReadRiskAssessmentList } from "../../../organization-side/org-setup/org-setup-risk-assessment/store/model-actions"
import { ProjInfoApiProps } from "./proj-info.apis"
import ReadClientsByGroup from "./model-actions/read-clients-by-group"
import ReadArchPolicyPeriodList from "../../../organization-side/org-setup/org-setup-arch-policy-periods/read-arch-policy-period-list"
import ReadCabinetLocation from "./model-actions/read-cabinet-location"

const ProjInfoModel = ({
	apiRead,
	apiUpdate,
	apiReadEngType,
	apiReadRiskAssessment,
	apiReadPeriodNames,
	apiReadClients,
	apiReadArchPolicyPeriodList,
	apiUpdateArchiveChecklist,
	apiReadCabinetLocation,
}: ProjInfoApiProps) =>
	types
		.model({})
		.actions((self) => ReadProjInfo(self, apiRead))
		.actions((self) => UpdateProjInfo(self, apiUpdate))
		.actions((self) => ReadEngTypeList(self, apiReadEngType))
		.actions((self) => ReadEngTypeList(self, apiReadEngType))
		.actions((self) => ReadRiskAssessmentList(self, apiReadRiskAssessment))
		.actions((self) => ReadProjPeriodTypes(self, apiReadPeriodNames))
		.actions((self) => ReadClientsByGroup(self, apiReadClients))
		.actions((self) =>
			UpdateArchiveChecklist(self, apiUpdateArchiveChecklist)
		)
		.actions((self) =>
			ReadArchPolicyPeriodList(self, apiReadArchPolicyPeriodList)
		)
		.actions((self) => ReadCabinetLocation(self, apiReadCabinetLocation))

export default ProjInfoModel
