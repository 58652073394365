import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import { DLWorkspaceModel } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import {
	CommonViewModelActions,
	ViewResponseHelper,
	Refresh,
} from "../../../../../common-models/common-view-model-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { initialStore } from "./other-projects.provider"
import GetOtherProjects from "./view-model-actions/get-other-projects"
import { SharedProjTablePageSettingModel } from "../../../../../components/combined-elements/projects-table/proj-page-setting.data-model"
import ProjectTableFilterViews from "../../../../../service-modules/project-module/view-model-views/table-filter-views"

const OtherProjectsViewModel = types
	.model({
		otherProjects: types.array(DLWorkspaceModel),
		//
		clickPoint: ClickPoint,
		selectedProjId: "",
		tableColumnsPageSetting: types.array(SharedProjTablePageSettingModel),
		// ----- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
	    setOtherProjects(projects: any) {
			self.otherProjects.length = 0
			self.otherProjects = projects
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedProjId(projId: string) {
			self.selectedProjId = projId
		},
		setTableColumnsPageSetting(list: any) {
			self.tableColumnsPageSetting = list
		},
	}))
	.actions(GetOtherProjects)
	.views(ProjectTableFilterViews)
	.views((self) => ({
		formattedOtherProjects(dntFormat: DnTFormatProps) {
			return []
		},
		getTablePageSettingColumn(column: string) {
			return self.tableColumnsPageSetting.find(
				(item) => item.aliasName === column
			)?.show
		},
		viewSelectedProj() {
			const info = self.otherProjects.find(
				(project: any) => project.id === self.selectedProjId
			)

			return info
		},
	}))
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupSearch")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OtherProjectsViewModel