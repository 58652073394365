import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
// components
import ResetPasswordSnackbar from "./sub-components/ResetPasswordSnackbar"
import Icon from "@mdi/react"
import {
	DLButton,
	DLInputField,
	DLPasswordField,
} from "../../../components/basic-elements"
import styled from "styled-components"
import sharedRegEx from "../../../library/sharedRegEx"
import useForm from "../../../library/use-form"
import { Link } from "react-router-dom"
import { mdiArrowRight, mdiSend, mdiLockReset } from "@mdi/js"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"

export default observer(function ResetPasswordController({
	partialStore,
	actionStatus,
}: {
	partialStore: any
	actionStatus: ActionStatus
}) {
	const [confirmPassword, setConfirmPassword] = useState({
		value: "",
		isValid: false,
	})

	const inputsSchema = {
		password: { value: "", error: "", requestInput: false },
		verificationCode: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		password: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.dlPassword,
				error: "Invalid format",
			},
		},
		verificationCode: {
			isRequired: true,
		},
	}
	const handleResetPassword = () => {
		partialStore.resetUserPassword(inputs)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, handleResetPassword)

	const handleConfirmPassword = (event: any) => {
		if (inputs.password.value !== event.target.value) {
			setConfirmPassword({ value: event.target.value, isValid: false })
		} else {
			setConfirmPassword({ value: event.target.value, isValid: true })
		}
	}

	return (
		<StyledResetPassword className="FC AC JC reset-password-screen">
			<div className="page-title FC AC JC">
				<Icon path={mdiLockReset} size={5} color="#ffffff" />
				<h1>Reset Password</h1>
			</div>
			<div className="description">
				New Password must consist of at least 1 lowercase character, 1
				uppercase character, 1 special character, 1 numeric digit and
				should be at least 8 characters long.
			</div>
			<div className="password-input FC">
				<DLPasswordField
					eleValue={inputs.password.value}
					eleTestId="password_input_testId"
					elePlaceholder="Please input new password"
					eleLabel="New Password"
					eleName="password"
					eleFullWidth
					eleOnChange={handleOnChange}
					eleWarningMsg={inputs.password.error}
					eleWarningType="orange"
				/>
			</div>
			<div className="confirm-password-input FC">
				<DLInputField
					eleRequired
					eleValue={confirmPassword.value}
					eleTestId="confirm_password_input_testId"
					elePlaceholder="Please input new password"
					eleLabel="Confirm Password"
					eleType="password"
					eleName="confirmPassword"
					eleFullWidth
					eleHandleChange={handleConfirmPassword}
					warningMsg={
						confirmPassword.value !== "" && !confirmPassword.isValid
							? "Password don't match"
							: undefined
					}
					warningType="orange"
				/>
			</div>
			<div className="verification">
				<DLInputField
					eleRequired
					eleValue={inputs.verificationCode.value}
					eleTestId="veriCode_input_testId"
					elePlaceholder="Please input 6 digits"
					eleLabel="Verification Code"
					eleName="verificationCode"
					eleFullWidth
					eleHandleChange={handleOnChange}
					eleClassName="for-white-text"
					warningMsg={inputs.verificationCode.error}
					warningType="orange"
				/>
			</div>
			<div className="submit-button">
				<DLButton
					variant="contained"
					eleTestId="submit-button"
					clickHandler={handleOnSubmit}
					color="primary"
					disabled={!(confirmPassword.isValid && isReady)}
					endIcon={<Icon path={mdiSend} size={0.8} />}
				>
					Verify
				</DLButton>
			</div>
			{/* NOTE:  TODO: When the user already verified, through the aliert, redirect the user to login screen   */}
			<div className="back-to-login">
				<Link to="/login">
					<DLButton
						variant="text"
						eleTestId="back-to-login"
						endIcon={<Icon path={mdiArrowRight} size={1} />}
					>
						Cancel & Back to Login
					</DLButton>
				</Link>
			</div>
			{/* snackbar */}
			<ResetPasswordSnackbar />
		</StyledResetPassword>
	)
})

const StyledResetPassword = styled.div`
	height: 100vh;
	display: flex;
	align-items: center;
	justify-content: center;
	background-color: ${(props) => props.theme.emphasisDeep};
	color: ${(props) => props.theme.background};
	.page-title {
		font-size: 1.8rem;
		font-weight: 600;
		margin-bottom: 2rem;
	}
	.description {
		opacity: 0.6;
		margin-bottom: ${(props) => props.theme.shared.spacingNormal};
	}
	.password-input,
	.confirm-password-input {
		width: 30vw;
		min-width: 300px;
		margin-bottom: ${(props) => props.theme.shared.spacingNormal};
	}
	.verification {
		width: 30vw;
		min-width: 300px;
		margin-bottom: ${(props) => props.theme.shared.spacingNormal};
	}
	.submit-button {
		margin-top: 1rem;
	}
	.back-to-login {
		margin-top: 4rem;
		opacity: 0.6;
		transition: 0.2s;
		:hover {
			opacity: 1;
		}
	}
`
