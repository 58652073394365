import React, { useEffect } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import FinalAnalysis from "./FinalAnalysis"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLSpinner } from "../../../../../components/basic-elements"

export default observer(function FinalAnalysisController() {
	const store = useRootStore()
	const projStore = useProjStore()

	useEffect(() => {
		const projId = projStore.checkin.projId
		projStore.wp.getTrialBalanceDetails(projId)
		projStore.wp.getFinancialStatementType()
	}, [])

	const contentsHeight = store.ui.contentsAreaHeight
	const actionStatus = projStore.wp.getActionStatus(
		"getTrialBalanceDetails"
	)
	return (
		<>
			{actionStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{
						width: "100%",
						height: contentsHeight,
					}}
				>
					<DLSpinner />
				</div>
			)}
			{actionStatus === ActionStatus.success && (
				<FinalAnalysis
					partialStore={projStore.wp}
					contentsHeight={contentsHeight}
					fetchingStatus={actionStatus}
				/>
			)}
		</>
	)
})
