import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"

const GetChecklistTemplates = (self: any) => ({
	getChecklistTemplates(orgId: string) {
		// 0.
		const actionName = "getChecklistTemplates"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readChecklistTemplates(orgId)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					self.setChecklistTemplates(response.data)
				} else {
					//
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetChecklistTemplates
