import React from "react"
import RadioGroup from "@material-ui/core/RadioGroup"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControlLabel from "@material-ui/core/FormControlLabel"
import FormControl from "@material-ui/core/FormControl"
import FormLabel from "@material-ui/core/FormLabel"
import DLRadio from "./DLRadio"
import styled from "styled-components"

interface DLRadioGroupProps {
	formTitle?: string
	items: {
		value: string | boolean
		label: string
		disabled?: boolean
		optionTestId?: string
	}[] // value and label
	groupName?: string
	selectedValue: any
	eleOnChange?: React.EventHandler<any>
	// changeHandler?: (event: React.ChangeEvent<HTMLInputElement>) => void
	formHelpText?: string
	/** "row" | "column" */
	itemDirection?: "row" | "column"
	/** "start" | "end" */
	labelPlacement?: "start" | "end"
	color?: "default" | "secondary" | "primary"
	eleClassName?: string
	size?: "small" | "medium" | "large"
	useInternalLabel?: boolean
	disabled?: boolean
	eleTestId?: string
	disableOtherOptions?: boolean
}

/**
 * row-direction needs more update
 */

export default function DLRadioGroup({
	formTitle = undefined,
	items = [
		{
			value: "",
			label: "",
			optionTestId: undefined,
		},
	],
	groupName = undefined,
	selectedValue = undefined,
	eleOnChange = undefined,
	formHelpText = undefined,
	itemDirection = "column",
	labelPlacement = "end",
	color = "primary",
	eleClassName = undefined,
	size = "medium",
	useInternalLabel = false,
	disabled,
	eleTestId,
	disableOtherOptions = false, // Use disabled instead if you want all option disabled, This prop will disable other option otherthan selected.
}: DLRadioGroupProps) {
	return (
		<StyledDLRadioGroup
			className={eleClassName}
			data-testid={`radio-group-${eleTestId}`}
		>
			<FormControl disabled={disabled}>
				<FormLabel>{formTitle}</FormLabel>
				<RadioGroup
					aria-label="radio-group"
					// name={groupName}
					value={selectedValue}
					onChange={eleOnChange}
					style={{ flexDirection: itemDirection }}
				>
					{items.map((item: any) => {
						const { value, label, optionTestId } = item
						return (
							<FormControlLabel
								key={value}
								value={value}
								disabled={disabled || item.disabled} // NOTE: if use this line, cannot control individiual item disabled status
								control={
									<DLRadio
										disabled={
											disableOtherOptions &&
											value !== selectedValue
										}
										color={color}
										eleTestId={
											optionTestId
												? optionTestId
												: "radio-" + label + "-" + value
										}
										size={size}
										label={useInternalLabel && label}
										name={groupName}
										labelPlacement={labelPlacement}
									/>
								}
								label={useInternalLabel ? "" : label}
								className={`${size} ${itemDirection === "row" &&
									"row-direction"}`}
								labelPlacement={labelPlacement}
							/>
						)
					})}
				</RadioGroup>
				<FormHelperText>{formHelpText}</FormHelperText>
			</FormControl>
		</StyledDLRadioGroup>
	)
}

const StyledDLRadioGroup = styled.div`
	div[aria-label="radio-group"] {
		> label {
			display: flex;
			align-items: center;

			&.small {
				margin-bottom: ${(props) => props.theme.shared.spacingSS};
				&.row-direction {
					margin-right: ${(props) => props.theme.shared.spacing3};
				}
			}
			&.medium {
				margin-bottom: ${(props) => props.theme.shared.spacingSS};
				&.row-direction {
					margin-right: ${(props) => props.theme.shared.spacing3};
				}
			}
			&.large {
				margin-bottom: ${(props) => props.theme.shared.spacingS};
				&.row-direction {
					margin-right: ${(props) => props.theme.shared.spacing3};
				}
			}
			.dl-radio-style {
				.radio-area {
					span[style-label="dl-radio"].radio {
						color: ${(props) => props.theme.shade60};
					}
				}
			}
		}
	}
`
