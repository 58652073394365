import { ConsoleLog } from "../../../../components/basic-elements"
import { compareValues } from "../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { sortBy } from "@datalobby/common"

const SortChildren = (self: any) => ({
	sortChildren({
		folderId,
		isAsc,
	}: {
		folderId: string | null
		isAsc: boolean
	}) {
		// NOTE: This is not a main action.
		// NOTE: Main action is 'reorderChildren' and 'requestReorder'
		const siblings = self.flatList.filter(
			(item: any) => item.parentId === folderId
		)
		let list: { id: string; aliasId: string }[] = []
		siblings.map((item: any) => {
			list.push({
				id: item.id,
				aliasId: item.isParent ? item.title : item.aliasId,
				// folder has no alias Id and be sorted by title
			})
		})

		const sortedList = list.sort(
			compareValues("aliasId", isAsc ? "asc" : "desc")
		)

		let listForApi: { id: string; parentId: string | null }[] = []
		sortedList.map((item: any) =>
			listForApi.push({
				id: item.id,
				parentId: folderId,
			})
		)

		self.reorderChildren(folderId, listForApi)
	},
	sortRootFoldersWithNewItem(targetIndex: number, node: any) {
		// when move the child folder as root folder
		const rootFolders = self.flatList.filter(
			(item: any) => item.parentId === null
		)
		sortBy(rootFolders, "index")

		let idList: string[] = []
		rootFolders.map((item: any) => {
			idList.push(item.id)
		})

		idList.splice(targetIndex, 0, node.id)

		return idList
	},
	getNewOrderedRootFolders(targetIndex: number, node: any) {
		// when reorder existing root folders
		const rootFolders = self.flatList.filter(
			(item: any) => item.parentId === null
		)
		sortBy(rootFolders, "index")

		let idList: string[] = []
		rootFolders.map((item: any) => {
			idList.push(item.id)
		})
		const defaultIndex = idList.indexOf(node.id)
		if (defaultIndex !== -1) {
			idList.splice(defaultIndex, 1)
			idList.splice(targetIndex, 0, node.id)
			return idList
		} else {
			ConsoleLog("something is wrong... cannot find the existing index")
			return
		}
	},
})

export default SortChildren
