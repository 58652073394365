import React, { useState } from "react"
import styled from "styled-components"
import {
	DLButton,
	DLIconButton,
	DLBasicToggleButton,
	ConsoleLog,
} from "../../../../components/basic-elements"
import { DLAvatar, DLTooltip } from "@datalobby/components"
import { Icon } from "@mdi/react"
import { mdiPencil, mdiAccountMultiple } from "@mdi/js"

export default function RoleDetailPannelHeader({ info }: { info: any }) {
	ConsoleLog(["info: ", info])

	const [readonly, setReadonly] = useState(false)
	const [rollForward, setRollForward] = useState(true)

	const handleReadonly = () => {
		setReadonly(!readonly)
	}
	const handleRollForward = () => {
		setRollForward(!rollForward)
	}

	return (
		<StyledRoleDetailPannelHeader className="FC">
			<div className="FR AC JSB">
				<div className="FR AC">
					<DLAvatar
						text={info.shortName}
						color={info.color}
						eleTestId={`role-icon-${info.id}`}
						fontSize={12}
					/>
					<h3 className="heading3">{info.name}</h3>
					<DLTooltip title="Edit Project Role Name">
						<div>
							<DLIconButton eleTestId="edit-role-name">
								<Icon path={mdiPencil} size={0.8} />{" "}
							</DLIconButton>
						</div>
					</DLTooltip>
					{/* NOTE: Readonly & Roll Forward on personal attribute have higher priority */}
					<div className="high-level-item FR">
						Readonly:{" "}
						<DLBasicToggleButton
							on={readonly}
							colorWithOn
							setOn={handleReadonly}
						/>
					</div>
					<div className="high-level-item FR">
						Roll-Forward:{" "}
						<DLBasicToggleButton
							on={rollForward}
							colorWithOn
							setOn={handleRollForward}
						/>
					</div>
					<DLTooltip title="Who are in this role?">
						<div className="FR AC">
							<DLIconButton eleTestId="user-list-btn">
								<Icon path={mdiAccountMultiple} size={0.8} />
							</DLIconButton>
							<span>6</span>
						</div>
					</DLTooltip>
				</div>
				<DLButton eleTestId="edit1">Edit 2</DLButton>
			</div>
		</StyledRoleDetailPannelHeader>
	)
}

const StyledRoleDetailPannelHeader = styled.div`
	padding-bottom: 0.5rem;
	h3.heading3 {
		margin: 0 1rem;
	}
	.high-level-item {
		margin-right: 1rem;
	}
`
