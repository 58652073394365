import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import FSCard from "./FSCard"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function FinancialGroup({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()

	const handleClick = (groupId: number) => {
		const projId = projStore.checkin.projId
		projStore.fsSetup.setSelectedGroupId(groupId)
		projStore.fsSetup.getFinSubGroupByFinGroup(groupId, projId)
	}

	const handleDialogOpen = (groupId: number) => {
		const projId = projStore.checkin.projId
		projStore.fsSetup.getAvailableFinSubGroups(groupId, projId)
		projStore.fsSetup.setMapUnmapSubGroupDialogOpen(true)
	}

	return (
		<FSCard
			headerName="Financial Group"
			list={projStore.fsSetup.viewFSGroups()}
			clickHandler={handleClick}
			handleDialogOpen={handleDialogOpen}
			permission={permission}
		/>
	)
})
