// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetEmailNotificationSetting = (self: any) => ({
	getEmailNotificationSetting() {
		// 0.
		const actionName = "getEmailNotificationSettings"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API call
		self.readEmailNotificationSetting()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const modifiedDetails = response.data.modifiedDetails

					const fetchedSettings = response.data.configurationlist.filter(
						(element: any) =>
							element.Ordinal !== 1 &&
							element.Ordinal !== 2 &&
							element.Ordinal !== 3
					)
					const fetchedEOD = fetchedSettings.find(
						(element: any) =>
							element.ParameterName === "At the end of the day"
					)
					let eod = {
						settingId: fetchedEOD.SettingID.toString(),
						label: "At the end of the day",
						value: fetchedEOD.ParameterValue,
						uiValue: fetchedEOD.ParameterValueUI,
					}
					self.setAtTheEndOfDay(eod)

					const fetchedEveryOtherDay = fetchedSettings.find(
						(element: any) =>
							element.ParameterName === "Every other day"
					)
					let everyOtherDay = {
						settingId: fetchedEveryOtherDay.SettingID.toString(),
						label: "Every other day",
						value: fetchedEveryOtherDay.ParameterValue,
						uiValue: fetchedEveryOtherDay.ParameterValueUI,
					}
					self.setEveryOtherDay(everyOtherDay)

					const fetchedEndOfWeek = fetchedSettings.find(
						(element: any) =>
							element.ParameterName === "At the end of the week"
					)
					let endOfWeek = {
						settingId: fetchedEndOfWeek.SettingID.toString(),
						label: "At the end of the week",
						value: fetchedEndOfWeek.ParameterValue,
						uiValue: fetchedEndOfWeek.ParameterValueUI,
					}
					self.setAtTheEndOfWeek(endOfWeek)

					const fetchedEvery3Days = fetchedSettings.find(
						(element: any) =>
							element.ParameterName === "Every 3 days"
					)
					let every3Days = {
						settingId: fetchedEvery3Days.SettingID.toString(),
						label: "Every 3 days",
						value: fetchedEvery3Days.ParameterValue,
						uiValue: fetchedEvery3Days.ParameterValueUI,
					}
					self.setEvery3Days(every3Days)

					const fetchedDaily = fetchedSettings.find(
						(element: any) => element.ParameterName === "Daily"
					)
					let daily = {
						settingId: fetchedDaily.SettingID.toString(),
						label: "Daily",
						value: fetchedDaily.ParameterValue,
						uiValue: fetchedDaily.ParameterValueUI,
					}
					self.setDaily(daily)

					const existingAvailableOptions = fetchedSettings.find(
						(element: any) =>
							element.ParameterName === "At the end of the day"
					).AvailableSettings

					self.resetAvailableOptions()
					existingAvailableOptions.map((format: any) => {
						let formattedLabel = (
							"0" + format.ParameterDisplay
						).slice(-2)
						formattedLabel = formattedLabel.concat(":00")
						const reOrganizedItem = {
							label: formattedLabel,
							value: format.ParameterValue,
							uiValue: format.ParameterValueUI,
						}
						self.pushItemToAvailableOptions(reOrganizedItem)
					})

					//
					const fetchedPBC = fetchedSettings.find(
						(element: any) => element.Ordinal === 9
					)
					let pbc = {
						settingId: fetchedPBC.SettingID.toString(),
						label: fetchedPBC.ParameterName,
						value: fetchedPBC.ParameterValue,
						uiValue: fetchedPBC.ParameterValueUI,
					}
					self.setPBCUpload(pbc)

					const details = {
						userName: modifiedDetails.ModifiedUser,
						userId: modifiedDetails.UserName,
						modifiedDate: modifiedDetails.ModifiedDate,
					}

					self.setModifiedDetails(details)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						autoHideDuration: 1500,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetEmailNotificationSetting
