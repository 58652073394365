import React from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../components/basic-elements"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

const DeleteEngTypeDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "removeEngType"
	const { tsDeleteEngType, twDeleteEngType, twDelete, twCancel } = i18n

	//
	const deleteEngType = () => {
		const userId = orgStore.checkin.orgUserId
		const selectedEngTypeId = orgStore.setupEngTypes.engagementId
		orgStore.setupEngTypes.removeEngType(selectedEngTypeId, userId)
	}

	const engTypeInfo = orgStore.setupEngTypes.getEngTypeById(
		orgStore.setupEngTypes.engagementId
	)

	return (
		<DLDialog
			eleTestId="delete-engtype-dialog"
			isOpen={orgStore.setupEngTypes.openDelete}
			setIsOpen={orgStore.setupEngTypes.setOpenDelete}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiTrashCan}
						size={1}
						// color={MessageColorType.orange}
						style={{ marginRight: 8 }}
					/>
					{twDeleteEngType} - {engTypeInfo?.name}
				</div>
			}
			dialogContents={<span>{tsDeleteEngType}</span>}
			actionReady={true}
			// handleAction={deleteEngType}
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="delete-engType-btn-on-dialog"
					startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={deleteEngType}
					color="warning"
				>
					{twDelete}
				</DLButton>
			}
			cancelBtnText={twCancel}
			showSpinner={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

export default DeleteEngTypeDialog
