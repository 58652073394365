import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLMenuId } from "../../../../components/combined-elements/file-tree/dl-file-tree-props"

const UpdateSignOffLock = (
	self: any,
	apiUpdateSignOffLock: FileSystemApiTypes["apiUpdateSignOffLock"]
) => ({
	updateSignOffLock: flow(function* (
		FileId: number,
		SignOffLock: boolean,
		menuId: DLMenuId
	) {
		const actionName = "updateSignOffLock"

		try {
			const params = {
				FileId,
				SignOffLock,
				menuId,
			}
			const response = yield apiUpdateSignOffLock(params)

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default UpdateSignOffLock
