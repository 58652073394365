import { types } from "mobx-state-tree"

export const FSType = types.model({
	fsTypeId: types.number,
	fsTypeName: types.string,
})

export const FSGroup = types.model({
	fsGroupId: types.number,
	fsGroupName: types.string,
	fsGroupMapId: types.number,
	// message: types.union(types.string, types.null),
	// status: types.string,
})

export const FSSubGroup = types.model({
	fsSubGroupId: types.number,
	fsSubGroupName: types.string,
	fsSubGroupMapId: types.number,
})

export const FSLeadSheets = types.model({
	leadSheetName: types.string,
	leadSheetMapId: types.number,
})

export enum DialogType {
	group = "Group",
	subGroup = "Sub Group",
	leadSheet = "Lead Sheet",
}
