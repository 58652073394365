import sharedTheme from "./sharedTheme"
import {
	transparency as transp,
	shadowPosition as sp,
} from "./shared-variables"

// NOTE: NO GREEN (Its hard to distinguish with red)
const mainColor = "#181c2a"
const subColor = "#211b34"
const emphasis = "#758FFF"

const themeBlack = "#020927"
const themeShadow = "#C0CCDD"

const themeRed = "#D6301B" // unarchived project icon color
const themeYellow = "#F5BE40" // locked project icon color
const themeOrange = "#F58B40"
const themePurple = "#5200B7" // archived project icon color

console.log(themeBlack + transp.p90, "themeBlack")

const defaultTheme: { [x: string]: any } = {
	mainColor,
	subColor,
	// ---------- basic variables (sholud be matched with dark theme)
	shared: sharedTheme,
	background: "#ffffff",

	orgSidebar: mainColor,
	projSidebar: subColor,

	orgSidebarEmphasisTxt: "#ffffff",
	projSidebarEmphasisTxt: "#5282FF",

	emphasis,
	emphasisDeep: "#4F6BE2",
	emphasisLight: emphasis + transp.p60,
	//
	txtOnEmphasisColor: "#ffffff",
	//
	activeMenuTxt: "#e38914",
	//
	tabColor: "#002547",

	// ---------- color standard primary
	primaryMainDeep: "#5d72cc",
	primaryLight: "#abbfff",
	primaryLightDeep: "#3c344e",

	// ---------- color standard secondary
	secondary: "#fff",
	secondaryDeep: "#eceaea",

	// ---------- project type color standard
	normalProject: themeBlack + transp.p60,
	normalLockedProject: themeYellow,
	replicaProject: themeBlack + transp.p20,
	archivedProject: themePurple,
	unarchivedProject: themeRed,

	// ---------- warning and dangerous
	warning: themeRed,
	warningDeep: "#B51804",

	// ---------- text standard
	textHighEmphasis: themeBlack + transp.p90,
	textMediumEmphasis: themeBlack + transp.p80,
	textDisabled: themeBlack + transp.p35,

	// ---------- shade
	shade05: themeBlack + transp.p05,
	shade10: themeBlack + transp.p10,
	shade20: themeBlack + transp.p20,
	shade30: themeBlack + transp.p30,
	shade40: themeBlack + transp.p40,
	shade60: themeBlack + transp.p60,
	shade90: themeBlack + transp.p90,

	// ---------- shadow
	shadowColorLight: themeShadow + transp.p60,
	shadowColorDeep: themeShadow + transp.p80,
	// stand
	shadowRegularLight: sp.regular + themeShadow + transp.p60,
	shadowRegularDeep: sp.regular + themeShadow + transp.p80,
	// left
	shadowRegularLeftLight: sp.left + themeShadow + transp.p60,
	shadowRegularLeftDeep: sp.left + themeShadow + transp.p80,
	// right
	shadowRegularRightLight: sp.right + themeShadow + transp.p60,
	shadowRegularRightDeep: sp.right + themeShadow + transp.p80,
	//
	themeRed,
	themeYellow,
	themeOrange,
	themePurple,
}

export default defaultTheme
