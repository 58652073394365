import { types } from "mobx-state-tree"
import RationalesModel from "./rationales.model"
import RationalesViewModel from "./rationales.view-model"
import * as api from "./rationales.apis"
import { RationaleApiProps } from "./rationales.apis"

export const RationalesStore = ({
	apiRead,
	apiUpdate,
	apiRemove,
	apiDownload,
}: RationaleApiProps) =>
	types
		.compose(
			RationalesModel({ apiRead, apiUpdate, apiRemove, apiDownload }),
			RationalesViewModel
		)
		.named("RationalesStore")

export const initialStore = {
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const RationalesStoreInstance = RationalesStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiRemove: api.remove,
	apiDownload: api.download,
}).create(initialStore)

export default RationalesStoreInstance
