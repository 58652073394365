import React from "react"

import { observer } from "mobx-react-lite"
import {
	DLSpinner,
	DLIconButton,
} from "../../../../../components/basic-elements"

import {
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import { ActionStatus } from "@datalobby/types"

const FileOnlyTableCounter = observer(
	({
		handleRequestMore,
		handleGetAllFiles,
		dataLength,
		totalFileCount,
		countSelectedFiles,
		actionStatus,
	}: {
		handleRequestMore: () => void
		handleGetAllFiles: () => void
		dataLength: number
		totalFileCount: number
		countSelectedFiles: number
		actionStatus: ActionStatus
	}) => {
		return (
			<div className="FR AC">
				<DLIconButton
					eleTestId="request-more-files"
					clickHandler={handleRequestMore}
					tooltipText="Request to open the rest of the folders"
				>
					<DLIcon name={DLIconName.refresh} noMargin />
				</DLIconButton>
				<DLIconButton
					eleTestId="request-all-again"
					clickHandler={handleGetAllFiles}
					tooltipText="Request all the files again (This request can take more time)"
				>
					<DLIcon color="red" name={DLIconName.refresh} noMargin />
				</DLIconButton>
				<span>
					Total current files count: {dataLength} / Total Count :{" "}
					{totalFileCount}
				</span>
				<span className="partition">|</span>
				<span>selected files: {countSelectedFiles}</span>
				{actionStatus === "LOADING" && <DLSpinner />}
			</div>
		)
	}
)

export default FileOnlyTableCounter
