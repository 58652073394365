import { flow } from "mobx-state-tree"
import { OrgCheckinApiProps } from "../org-checkin.apis"

const ReadOrgInfo = (
	self: any,
	readOrgInfo: OrgCheckinApiProps["apiReadOrgInfo"]
) => ({
	readOrgInfo: flow(function*() {
		const actionName = "readOrgInfo"
		//
		//
		// 1.
		//
		//
		// organize params
		//
		//
		// 2.
		try {
			const response = yield readOrgInfo()

			// ConsoleLog("readOrgInfo response", response)
			return response
		} catch (error) {
			// console.error("readOrgInfo error", error)
			self.handleTokenError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadOrgInfo
