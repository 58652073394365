import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest,
} from "../../../../../library/api-requests"
import { ChecklistRecordType } from "@datalobby/types"

type readProps = {
	orgId: string
	id?: string
	// type: ChecklistRecordType
}
export async function read({ orgId, id }: readProps) {
	let url = "/checklists"
	let params: any = {
		orgId,
		type: ChecklistRecordType.template,
	}
	if (id) {
		params = {
			...params,
			id,
		}
	}
	const response = await getRequest({
		tokenType: TOKEN_TYPE.cdkBackend,
		url,
		params,
	})

	return response
}

export async function update(payload: any) {
	const response = await putRequest({
		tokenType: TOKEN_TYPE.cdkBackend,
		url: "/checklist/templates",
		payload,
	})

	return response
}

export async function create(payload: any) {
	const response = await postRequest({
		tokenType: TOKEN_TYPE.cdkBackend,
		url: "checklist/templates",
		payload,
	})

	return response
}

export async function del(payload: any) {
	const response = await deleteRequest({
		tokenType: TOKEN_TYPE.cdkBackend,
		url: "checklist/templates",
		payload,
	})

	return response
}

export type OrgSetupChecklistApiProps = {
	apiRead: (props: readProps) => Promise<AxiosResponse<any>>
	apiUpdate: (payload: any) => Promise<AxiosResponse<any>>
	apiCreate: (payload: any) => Promise<AxiosResponse<any>>
	apiDelete: (payload: any) => Promise<AxiosResponse<any>>
}
