import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { FileOnlyTableView } from ".."
import { ActionStatus } from "@datalobby/types"
import { DLSpinner } from "../../../../../components/basic-elements"
import { FileMenuViewType } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function FileMenuSearchResults({
	partialStore,
}: {
	partialStore: {
		searchResults: any[]
		setViewType: (type: FileMenuViewType) => void
		getActionStatus: (actionName: string) => ActionStatus
		setSelectedItem: (itemId: string, isParent: boolean) => void
		[x: string]: any
	}
}) {
	console.log("searchResults:", partialStore.searchResults)
	const actionName = "searchFileOrFolder"
	const actionStatus = partialStore.getActionStatus(actionName)

	const setSelectedFolder = (folderId: string) => {
		partialStore.setSelectedItem(folderId, true)
	}
	const setViewTypeAsTree = () => {
		partialStore.setViewType(FileMenuViewType.tree)
	}

	const searchQuery = partialStore.searchQuery
	const resultsLength = partialStore.searchResults.length

	if (partialStore.searchQuery === "") {
		return (
			<StyledNoSearchResults className="FC AC JC">
				<div
					className="heading2 request-input"
					data-testid="request-search-input"
				>
					Please input search text
				</div>
			</StyledNoSearchResults>
		)
	} else {
		return (
			<StyledFileMenuSearchResults data-testid="search-results-area">
				{actionStatus === ActionStatus.success &&
					partialStore.searchType === "file" &&
					(resultsLength === 0 ? (
						<NoSearchResults
							searchType={"file"}
							searchQuery={searchQuery}
						/>
					) : (
						<FileOnlyTableView partialStore={partialStore} />
					))}
				{actionStatus === ActionStatus.success &&
					partialStore.searchType === "folder" && (
						<FolderSearchResults
							searchQuery={searchQuery}
							folders={partialStore.searchResults}
							setSelectedFolder={setSelectedFolder}
							setViewTypeAsTree={setViewTypeAsTree}
						/>
					)}

				{actionStatus === ActionStatus.loading && <DLSpinner />}
			</StyledFileMenuSearchResults>
		)
	}
})

const FolderSearchResults = ({
	searchQuery,
	folders,
	setSelectedFolder,
	setViewTypeAsTree,
}: {
	searchQuery: string
	folders: any
	setSelectedFolder: (folderId: string) => void
	setViewTypeAsTree: () => void
}) => {
	const handleClick = (folderId: string) => {
		// console.log("folder Id:", folderId)
		setSelectedFolder(folderId)
		setViewTypeAsTree()
	}
	return (
		<StyledFolderSearchResults data-testid="folder-search-results">
			{folders.length === 0 ? (
				<NoSearchResults
					searchType={"folder"}
					searchQuery={searchQuery}
				/>
			) : (
				<div>
					<SearchResultsCount count={folders.length} />
					{folders.map((folder: any) => {
						return (
							<div
								key={folder.id}
								className="folder-item"
								onClick={() => handleClick(folder.id)}
							>
								{folder.title}
							</div>
						)
					})}
				</div>
			)}
		</StyledFolderSearchResults>
	)
}

export const SearchResultsCount = ({ count }: { count: number }) => {
	return (
		<div data-testid="search-result-count">
			Search results count: {count}
		</div>
	)
}

const NoSearchResults = ({
	searchType,
	searchQuery,
}: {
	searchType: "file" | "folder"
	searchQuery: string
}) => {
	return (
		<StyledNoSearchResults className="FC AC JC">
			<div className="heading2" data-testid="no-results-found">
				No results found with "{searchQuery}" in {searchType}s
			</div>
		</StyledNoSearchResults>
	)
}

const StyledNoSearchResults = styled.div`
	width: 100%;
	height: 100%;
	.heading2 {
		color: ${(props) => props.theme.shade40};
		margin-bottom: 1rem;
		&.request-input {
			padding-top: 3rem;
		}
	}
	.center {
		height: 5px;
	}
`

const StyledFileMenuSearchResults = styled.div`
	padding: 0.6rem 1rem;
`

const StyledFolderSearchResults = styled.div`
	.folder-item {
		padding: 1rem;
		border: 1px solid gray;
		border-radius: 4px;
		transition: 0.4s;
		cursor: pointer;
		margin-bottom: 0.4rem;
		:hover {
			background-color: lightgray;
		}
	}
`
