import { AxiosResponse } from "axios"
import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { resetPwApiType } from "../forgot-pw.apis"

const UpdateForgotPassword = (self: any, apiResetPassword: resetPwApiType) => ({
	updateForgotPassword: flow(function*(email: string) {
		const actionName = "updateForgotPassword"
		const payload = {
			EmailID: email,
		}
		try {
			/**
			 * required payload: EmailID
			 *
			 * This API has below status code
			 *
			 * - 200
			 * - 200 but fail: "You are not confirmed in cognito...""
			 * - 500
			 */
			const response = yield apiResetPassword(payload)

			ConsoleLog(["updateForgotPassword response", response])
			return response
		} catch (error) {
			ConsoleLog(["updateForgotPassword error", error])
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default UpdateForgotPassword
