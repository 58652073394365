// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { SetGlobalDnTType } from "../../../../../../stores/root-store/global-store/global-store.view-model"
// ---------- common actions
// ---------- response cases

export function reOrganizeDntSettings(fetchedSettings: any) {
	const fetchedDateFormat = fetchedSettings.find(
		(element: any) => element.ParameterName === "Date Format"
	)
	const fetchedTimeFormat = fetchedSettings.find(
		(element: any) => element.ParameterName === "Time Format"
	)
	const fetchedTimeZone = fetchedSettings.find(
		(element: any) => element.ParameterName === "Time Zone"
	)

	let dateFormat = {
		label: "Date Format",
		settingId: fetchedDateFormat.SettingID.toString(),
		value: fetchedDateFormat.ParameterValue,
		uiValue: fetchedDateFormat.ParameterValueUI,
	}

	// NOTE: tt is not working... need to use aa
	let formatValue = "hh:mm aa"
	let timeFormatSettingId = "dummy_id"

	if (fetchedTimeFormat) {
		// formatValue = fetchedTimeFormat.ParameterValue
		if (fetchedTimeFormat.ParameterValue === "hh:mm tt") {
			formatValue = "hh:mm aa"
		}
		if (fetchedTimeFormat.ParameterValue === "h:mm tt") {
			formatValue = "h:mm aa"
		}
		timeFormatSettingId = fetchedTimeFormat.SettingID.toString()
	}

	let timeFormat = {
		label: "Time Format",
		settingId: timeFormatSettingId,
		value: formatValue,
		uiValue: formatValue,
	}

	let timeZone = {
		label: "Time Zone",
		settingId: fetchedTimeZone.SettingID.toString(),
		value: fetchedTimeZone.ParameterValue,
		uiValue: fetchedTimeZone.ParameterValueUI,
	}
	// ConsoleLog("reOrganizeDntSettings", dateFormat, timeFormat, timeZone)
	return { dateFormat, timeFormat, timeZone }
}

/**
 * NOTE:
 * copied from getDateAndTimeSetting (setup > system settings > date and time ...)
 * in org setup, "GetSystemSeeting" gets full available list.
 * So, above 'reOrganizeDntSeetings' is shared with proj checkin only
 *
 */

const GetSysInfo = (self: any) => ({
	getSysInfo(setGlobalDnT: (props: SetGlobalDnTType) => void) {
		// 0. set action name
		const actionName = "getSysInfo"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readSysInfo()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedSettings = response.data.SettingList
					const result = reOrganizeDntSettings(fetchedSettings)
					const { dateFormat, timeFormat, timeZone } = result
					//
					setGlobalDnT({
						dateFormat,
						timeFormat,
						timeZone,
					})
					//
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
				} else {
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.fail,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetSysInfo
