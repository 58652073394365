import { ConsoleLog } from "../../../../components/basic-elements"

const OrgProjectsUiViews = (self: any) => ({
	getProjLengthOfYear(yearFilter: any, type: any) {
		ConsoleLog([
			"year filter in viewModel",
			yearFilter,
			type,
			parseInt(yearFilter),
		])
		if (type === "normal") {
			if (yearFilter === "All Years") {
				return self.normalProjList.length
			} else {
				const year = parseInt(yearFilter)
				return self.normalProjList.filter(
					(proj: any) => proj.financialYear === year
				).length
			}
		}
		if (type === "replica") {
			if (yearFilter === "All Years") {
				return self.replicaProjList.length
			} else {
				const year = parseInt(yearFilter)
				return self.replicaProjList.filter(
					(proj: any) => proj.financialYear === year
				).length
			}
		}
		if (type === "archived") {
			if (yearFilter === "All Years") {
				return self.archivedProjList.length
			} else {
				const year = parseInt(yearFilter)
				return self.archivedProjList.filter(
					(proj: any) => proj.financialYear === year
				).length
			}
		}
		if (type === "unarchived") {
			if (yearFilter === "All Years") {
				return self.unarchivedProjList.length
			} else {
				const year = parseInt(yearFilter)
				return self.unarchivedProjList.filter(
					(proj: any) => proj.financialYear === year
				).length
			}
		}
	},
	get normalProjListLength() {
		return self.normalProjList.length
	},
	get viewReplicaProjList() {
		return self.replicaProjList
	},
	get viewArchivedProjList() {
		return self.archivedProjList
	},
	get viewUnarchivedProjList() {
		return self.unarchivedProjList
	},
})

export default OrgProjectsUiViews
