import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import ReadChecklists from "../../online-projects/store/model-actions/read-checklists"

const ChecklistsModel = ({
	apiReadChecklists,
}: any) =>
	types
		.model({})
		.actions((self) => ReadChecklists(self, apiReadChecklists))
		// Common model actions
		.actions(CommonModelActions)

export default ChecklistsModel
