import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ArchivePreProcess from "./ArchivePreProcess"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function ArchivePreProcessController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.arch_pre_process
	const actionName = "getPreConditionStatus"
	const i18nSheet = ProjI18n.archPreProcess
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	
	console.log(projStore.projInfo, "projInfo")
	//
	useEffect(() => {
		const projId = projStore.checkin.projId
		if (menuAccess) {
			projStore.archivePreProcess.getPreConditionStatus()
			projStore.projInfo.getProjInfo(projId)
			projStore.archive.getArchiveDiagnosis()
		}
	}, [])
	//

	const completedMandotaryList =
		projStore.archive.getCompletedCount("mandatory")
	const totalMandotoryList = projStore.archive.getMandatoryList.length
	//
	const i18n = store.i18n.combineI18n(i18nSheet)
	return (
		<>
			{menuAccess ? (
				<ArchivePreProcess
					partialStore={projStore.archivePreProcess}
					fetchingStatus={projStore.archivePreProcess.getActionStatus(
						actionName
					)}
					archiveDiagnosisFetchingStatus={projStore.archive.getActionStatus(
						"getArchiveDiagnosis"
					)}
					isArchiveConditionsCompleted={completedMandotaryList === totalMandotoryList}
					permission={permission}
					projectStatus={
						projStore.projInfo.projInfo.statusName
					}
					i18n={i18n}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
