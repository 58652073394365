import { flow } from "mobx-state-tree"
import { OrgRiskAssessmentApiProps } from "../org-risk-assessment.apis"

const ReadRiskAssessmentList = (
	self: any,
	apiRead: OrgRiskAssessmentApiProps["apiRead"]
) => ({
	readRiskAssessmentList: flow(function*(payload: {
		PageIndex?: number
		PageSize: number
	}) {
		const actionName = "createRiskAssessment"
		if (typeof payload.PageSize === "number") {
			try {
				const response = yield apiRead(payload)
				// ConsoleLog("requestRiskAssessmentList response", response)
				return response
			} catch (error) {
				// console.error("requestRiskAssessmentList error", error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(`(${actionName}) invalid prop exist. ${payload.PageSize}`)
		}
	}),
})

export default ReadRiskAssessmentList
