const OpenedFilesViews = (self: any) => ({
	viewOpenedFilesByUser() {
		return self.openedBySameUser
	},
	viewOpenedFilesByOther() {
		return self.openedByOther
	},
	get selectedFilesByUserLength() {
		return self.openedBySameUser.filter((item: any) => item.selected).length
	},
	get selectedFilesByOtherLength() {
		return self.openedByOther.filter((item: any) => item.selected).length
	},
	get openedFilesByUserLength() {
		return self.openedBySameUser.length
	},
})

export default OpenedFilesViews
