import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"

import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ReplaceFileProps } from "../../view-model-actions/file/replace-file"

// Required form data example

// fileType: 1 --> 1 = wp, 2 = pf
// MediaID: 6688
// file: (binary)
// mediaType: Excel
// SignOffType: [1] --> 1: prepare, 2: review
// WPRList: [{"WPRID":5296}]

const RequestReplaceFile = (
	self: any,
	replaceFile: FileSystemApiTypes["apiReplaceFile"]
) => ({
	requestReplaceFile: flow(function* ({
		fileId,
		srcId,
		srcFormat,
		file,
		prepared,
		reviewed,
	}: ReplaceFileProps) {
		const actionName = "requestReplaceFile"
		const menuId = self.storeName
		// 1.
		//
		//
		// 2.
		let menuNumber = ""
		if (menuId === DLProjSubMenus.wp) {
			menuNumber = "1"
		} else if (menuId === DLProjSubMenus.pf) {
			menuNumber = "2"
		} else if (menuId === DLOrgSubMenus.proj_templates) {
			menuNumber = "3"
		}

		const fileNumberId = idToNumber(fileId, IdType.file)
		let srcNumId: number | string = ""
		if (menuId !== DLOrgSubMenus.proj_templates) {
			srcNumId = idToNumber(srcId, IdType.src)
		}

		const fileIdList = [{ WPRID: fileNumberId }]
		let signoffs: number[] = []
		if (prepared) {
			signoffs.push(1)
		}
		if (reviewed) {
			signoffs.push(2)
		}
		//
		//
		// 3.
		const formData = new FormData()
		if (menuId === DLOrgSubMenus.proj_templates) {
			formData.append("fileType", menuNumber)
			formData.append("WPRList", JSON.stringify(fileIdList))
			formData.append("MediaID", fileNumberId.toString())
			formData.append("mediaType", srcFormat)
			formData.append("file", file)
		} else {
			formData.append("fileType", menuNumber)
			formData.append("WPRList", JSON.stringify(fileIdList))
			formData.append("MediaID", srcNumId.toString())
			formData.append("mediaType", srcFormat)
			formData.append("file", file)
			formData.append("SignOffType", JSON.stringify(signoffs))
		}

		try {
			const response = yield replaceFile({ formData, menuId })
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestReplaceFile
