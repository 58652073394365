import {
	ActionStatus,
	DLSector,
	DLSrcFormat,
} from "../../../../../common-models/enumerations/common-enums"

import {
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"
import { findProjToken } from "../../../../../library/token-utils"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	makeAPGForProjTemplateUrl,
	generateNormalFileUrl,
	makeLSUrl,
	makeFileGroupUrl,
	makeAPGUrl,
	makeFluxAnalysisUrl,
	makeFinalAnalysisUrl,
} from "./make-editor-url"

const SetEditorUrl = (self: any) => ({
	setEditorUrl(postAction?: () => void) {
		const userId = self.orgInfo.userId // localStorage.getItem("orgUserId")
		const parentCabinetOfProject = self.projInfo.cabinetId
		const { sector, menuId, projId, fileId, srcFormat, asReadonly } =
			self.urlInfo

		const fileStatus = self.fileStatus
		const token = findProjToken(projId)

		// 0.
		const actionName = "setEditorUrl"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		// NOTE: Cannot use this because backend side check the right by token or separated API call

		const userNumId = idToNumber(userId, IdType.user)
		const objNumId = idToNumber(fileId, IdType.file)
		const projNumId = idToNumber(projId, IdType.project)
		const projParentCabinetNumId = idToNumber(
			parentCabinetOfProject,
			IdType.cabinet
		)

		// NOTE: DO not use fileStatus ID. It doesn't work for PF
		const srcId =
			menuId === DLProjSubMenus.wp || menuId === DLProjSubMenus.pf
				? idToNumber(fileStatus.id, IdType.src)
				: idToNumber(fileId, IdType.file)

		// TODO: WARNING: readonly setting need more update
		let iframeUrl = ""
		if (
			userId !== null &&
			token &&
			typeof srcId === "number" &&
			typeof objNumId === "number" &&
			typeof userNumId === "number"
		) {
			/**
			 * WARNING:
			 * - excel, word, pdf are using same format URL to open editor
			 * - others have different URL depend on the sector (Org or Proj)
			 * - leadsheet, fileGroup, apg, flux, final are in the project side only
			 */
			if (
				srcFormat === DLSrcFormat.excel ||
				srcFormat === DLSrcFormat.word ||
				srcFormat === DLSrcFormat.pdf
			) {
				iframeUrl = generateNormalFileUrl({
					srcFormat,
					srcId,
					menuId,
					userNumId,
					token,
				})
			} else {
				// WARNING: SEPARATE THE ORG AND PROJ
				// WARNING: ---------- ORGANIZATION ----------
				if (sector === DLSector.org) {
					if (srcFormat === DLSrcFormat.auditProgram) {
						iframeUrl = makeAPGForProjTemplateUrl({
							userNumId,
							srcId,
							lang: "en",
							token,
						})
					} else {
						alert("Unknown src format")
					}
				} else if (sector === DLSector.proj && projId !== null) {
					// WARNING: ---------- PROJECT SIDE ----------
					if (
						typeof projNumId === "number" &&
						typeof projParentCabinetNumId === "number"
					) {
						if (srcFormat === DLSrcFormat.leadSheet) {
							// NOTE: pending to check the cabinet Id
							iframeUrl = makeLSUrl({
								userNumId,
								projNumId,
								projParentCabinetNumId,
								// year: 1234, // there are no process for this on devexpress code
								// lang: "en", // there are no process for this on devexpress code
								token,
							})
						} else if (srcFormat === DLSrcFormat.fileGroup) {
							// fileGroup means previous 'Upload File'
							iframeUrl = makeFileGroupUrl({
								userNumId,
								objNumId,
								projNumId,
								lang: "en",
								token,
							})
						} else if (srcFormat === DLSrcFormat.auditProgram) {
							iframeUrl = makeAPGUrl({
								userNumId,
								objNumId,
								projNumId,
								lang: "en",
								token,
							})
						} else if (srcFormat === DLSrcFormat.fluxAnalysis) {
							iframeUrl = makeFluxAnalysisUrl({
								userNumId,
								projNumId,
								projParentCabinetNumId,
								// year, // there are no process for this on devexpress code
								objNumId,
								// lang, // there are no process for this on devexpress code
								token,
							})
						} else if (srcFormat === DLSrcFormat.finalAnalysis) {
							// NOTE: Final Analytics is not devexpress editor
						} else {
							alert("Unknown src format")
						}
					}
				}
			}

			// console.log("url for iframe", iframeUrl)
			self.setIframeUrl(iframeUrl)
			postAction && postAction()
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.success,
			})
		} else {
			alert(
				`invalid props: userId: ${userId}, srcId: ${srcId}, objNumId: ${objNumId}, userNumId: ${userNumId},`
			)
		}
	},
})

export default SetEditorUrl
