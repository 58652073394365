import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import {
	getTzDate,
	getTzDate2,
} from "../../../../../../library/converters/date-utc-converter"
import { eachDayOfInterval, format } from "date-fns"
import { DnTFormatProps } from "../../../../../../common-models/types/common-props"

const DashboardGraphViews = (self: any) => ({
	getGraphFilteredData() {
		let filteredData = JSON.parse(JSON.stringify(self.orgDashboardList))
		if (self.yearGraphFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.yearGraphFilter.includes(item.financialYear)
			)
		}
		if (self.periodTypeGraphFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.periodTypeGraphFilter.includes(item.periodName)
			)
		}
		if (self.engTypeGraphFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.engTypeGraphFilter.includes(item.engTypeName)
			)
		}
		if (self.groupGraphFilter.length > 0) {
			filteredData = filteredData.filter((item: any) =>
				self.groupGraphFilter.includes(item.groupName)
			)
		}
		if (self.epUserGraphFilter.length > 0) {
			let arr: any[] = []
			filteredData.map((item: any) => {
				item.epUsers.map((i: any) => {
					if (self.epUserGraphFilter.includes(i.name)) {
						arr.push(item)
					}
				})
			})
			filteredData = arr
		}
		if (self.qcUserGraphFilter.length > 0) {
			let arr: any[] = []
			filteredData.map((item: any) => {
				item.qcUsers.map((i: any) => {
					if (self.qcUserGraphFilter.includes(i.name)) {
						arr.push(item)
					}
				})
			})
			filteredData = arr
		}
		return filteredData
	},
	getGraphData(filterdData: any, dntFormat: DnTFormatProps) {
		const intervalDates: any[] = []
		if (self.fromDate && self.toDate) {
			const betweenDates = eachDayOfInterval({
				start: new Date(self.fromDate),
				end: new Date(self.toDate),
			}).map((d) => intervalDates.push(format(d, dntFormat.dateFormat)))
		}

		const statusFilter = (status: ProjectStatus) => {
			return filterdData.filter(
				(item: any) => item.statusName === status && item.archivedAt
			)
		}
		let projects = filterdData.map((item: any) => {
			return {
				...item,
				archivedAt: item.archivedAt
					? getTzDate2({ date: item.archivedAt, dntFormat })
					: "",
				finalReportDate: item.finalReportDate
					? getTzDate2({ date: item.finalReportDate, dntFormat })
					: "",
				expectedReportDate: item.expectedReportDate
					? getTzDate2({ date: item.expectedReportDate, dntFormat })
					: "",
				expectedArchiveDeadlineDate: item.expectedArchiveDeadlineDate
					? getTzDate2({
							date: item.expectedArchiveDeadlineDate,
							dntFormat,
					  })
					: "",
				finalArchiveDeadlineDate: item.finalArchiveDeadlineDate
					? getTzDate2({
							date: item.finalArchiveDeadlineDate,
							dntFormat,
					  })
					: "",
			}
		})

		const groupBy = (key: any) =>
			projects.reduce(
				(objectsByKeyValue: any, obj: any) => ({
					...objectsByKeyValue,
					[obj[key]]: (objectsByKeyValue[obj[key]] || []).concat(obj),
				}),
				{}
			)
		const groupByArchivedDate = groupBy("archivedAt")
		const groupByFinalReportDate = groupBy("finalReportDate")
		const groupByExpectedReportDate = groupBy("expectedReportDate")
		const groupByExpectedArchiveDeadlineDate = groupBy(
			"expectedArchiveDeadlineDate"
		)
		const groupByFinalArchiveDeadlineDate = groupBy(
			"finalArchiveDeadlineDate"
		)

		function sortObj(obj: any) {
			return Object.keys(obj)
				.sort(
					(a: any, b: any) =>
						new Date(a).valueOf() - new Date(b).valueOf()
				)
				.reduce(function (result: any, key: any) {
					if (key) {
						result[key] = obj[key]
					}
					return result
				}, {})
		}

		let labels: any[]
		let archivedData: any[]
		let finalReportDateData: any[]
		let expectedReportDateData: any[]
		let expectedArchiveDeadlineDateData: any[]
		let finalArchiveDeadlineDateData: any[]
		let archivedProjectData: any = []
		let finalReportProjectData: any = []
		let expectedReportProjectData: any = []
		let expectedArchiveDeadlineProjectData: any = []
		let finalArchiveDeadlineProjectData: any = []

		if (self.fromDate && self.toDate) {
			labels = intervalDates
			archivedData = intervalDates.map((date: any) => {
				archivedProjectData.push([
					...projects.filter((item: any) => item.archivedAt === date),
				])
				return projects.filter((item: any) => item.archivedAt === date)
					.length
			})
			finalReportDateData = intervalDates.map((date: any) => {
				finalReportProjectData.push([
					...projects.filter(
						(item: any) => item.finalReportDate === date
					),
				])
				return projects.filter(
					(item: any) => item.finalReportDate === date
				).length
			})
			expectedReportDateData = intervalDates.map((date: any) => {
				expectedReportProjectData.push([
					...projects.filter(
						(item: any) => item.expectedReportDate === date
					),
				])
				return projects.filter(
					(item: any) => item.expectedReportDate === date
				).length
			})
			expectedArchiveDeadlineDateData = intervalDates.map((date: any) => {
				expectedArchiveDeadlineProjectData.push([
					...projects.filter(
						(item: any) => item.expectedArchiveDeadlineDate === date
					),
				])
				return projects.filter(
					(item: any) => item.expectedArchiveDeadlineDate === date
				).length
			})
			finalArchiveDeadlineDateData = intervalDates.map((date: any) => {
				finalArchiveDeadlineProjectData.push([
					...projects.filter(
						(item: any) => item.finalArchiveDeadlineDate === date
					),
				])
				return projects.filter(
					(item: any) => item.finalArchiveDeadlineDate === date
				).length
			})
		} else {
			labels = Object.keys(
				sortObj({
					...groupByArchivedDate,
					...groupByFinalReportDate,
					...groupByExpectedReportDate,
					...groupByExpectedArchiveDeadlineDate,
					...groupByFinalArchiveDeadlineDate,
				})
			)
			archivedData = labels.reduce((acc: any, val: any) => {
				if (groupByArchivedDate[val]) {
					archivedProjectData.push([...groupByArchivedDate[val]])
					acc.push(groupByArchivedDate[val].length)
				} else {
					archivedProjectData.push([])
					acc.push(0)
				}
				return acc
			}, [])
			finalReportDateData = labels.reduce((acc: any, val: any) => {
				if (groupByFinalReportDate[val]) {
					finalReportProjectData.push([
						...groupByFinalReportDate[val],
					])
					acc.push(groupByFinalReportDate[val].length)
				} else {
					finalReportProjectData.push([])
					acc.push(0)
				}
				return acc
			}, [])
			expectedReportDateData = labels.reduce((acc: any, val: any) => {
				if (groupByExpectedReportDate[val]) {
					expectedReportProjectData.push([
						...groupByExpectedReportDate[val],
					])
					acc.push(groupByExpectedReportDate[val].length)
				} else {
					expectedReportProjectData.push([])
					acc.push(0)
				}
				return acc
			}, [])
			expectedArchiveDeadlineDateData = labels.reduce(
				(acc: any, val: any) => {
					if (groupByExpectedArchiveDeadlineDate[val]) {
						expectedArchiveDeadlineProjectData.push([
							...groupByExpectedArchiveDeadlineDate[val],
						])
						acc.push(groupByExpectedArchiveDeadlineDate[val].length)
					} else {
						expectedArchiveDeadlineProjectData.push([])
						acc.push(0)
					}
					return acc
				},
				[]
			)
			finalArchiveDeadlineDateData = labels.reduce(
				(acc: any, val: any) => {
					if (groupByFinalArchiveDeadlineDate[val]) {
						finalArchiveDeadlineProjectData.push([
							...groupByFinalArchiveDeadlineDate[val],
						])
						acc.push(groupByFinalArchiveDeadlineDate[val].length)
					} else {
						finalArchiveDeadlineProjectData.push([])
						acc.push(0)
					}
					return acc
				},
				[]
			)
		}

		console.log(labels, archivedData, finalReportDateData)

		return {
			labels,
			datasets: [
				{
					label: "Archived Projects",
					data: archivedData,
					fill: false,
					backgroundColor: "rgb(255, 99, 132)",
					borderColor: "rgba(255, 99, 132, 0.2)",
					projects: archivedProjectData,
				},
				{
					label: "Final Report Date Projects",
					data: finalReportDateData,
					fill: false,
					backgroundColor: "rgba(75,192,192,0.2)",
					borderColor: "rgba(75,192,192,1)",
					projects: finalReportProjectData,
				},
				{
					label: "Expected Report Date Projects",
					data: expectedReportDateData,
					fill: false,
					backgroundColor: "rgb(0,0,128)",
					borderColor: "rgb(0,0,128)",
					projects: expectedReportProjectData,
				},
				{
					label: "Expected Archive Deadline Date Projects",
					data: expectedArchiveDeadlineDateData,
					fill: false,
					backgroundColor: "rgb(128,0,128)",
					borderColor: "rgb(128,0,128)",
					projects: expectedArchiveDeadlineProjectData,
				},
				{
					label: "Final Archive Deadline Date Projects",
					data: finalArchiveDeadlineDateData,
					fill: false,
					backgroundColor: "rgb(255,255,0)",
					borderColor: "rgb(255,255,0)",
					projects: finalArchiveDeadlineProjectData,
				},
			],
		}
	},
})

export default DashboardGraphViews
