import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
//
import {
	CreateFile,
	UpdateFile,
	DeleteFile,
	RequestReplaceFile,
	RequestFileUpload,
	UpdateFileStatus,
	CreateFileFromLib,
	ReadAllFiles,
	RequestFileOrFolderSearch,
} from "../../../../../service-modules/file-module/model-actions/file"
import {
	CreateFolder,
	ReadFirstFolders,
	ReadChildrenOfFolder,
	UpdateFolder,
	DeleteFolder,
	RequestReorder,
	UpdateStructureLock,
} from "../../../../../service-modules/file-module/model-actions/file-folder"
import {
	RequestDownloadFile,
	RequestDownloadFolder,
	RequestDownloadAll,
} from "../../../../../service-modules/file-module/model-actions/download"

//
// NOTE: sign-off part will be shared from the signoff module
import {
	RequestSignOff,
	RequestUnSignOff,
} from "../../../../../service-modules/file-module/model-actions/sign-offs"
//
import RequestAssignRoleToFile from "../../../../../service-modules/file-module/model-actions/assign/request-assign-role-to-file"
//
import { ProjectFilesApiProps } from "../../proj-files-components/project-files-api-types"
import ReadFileInfo from "../../../../../service-modules/file-module/model-actions/file/read-file-info"
import ReadFileHistory from "../../../../../service-modules/file-module/model-actions/file/read-file-history"
import UpdateSignOffLock from "../../../../../service-modules/file-module/model-actions/file/update-sign-off-lock"

const PfModel = ({
	// default get
	apiReadFirstFolder,
	apiReadChildrenOfFolder,
	// file
	apiCreateFile,
	apiDeleteFile,
	apiUpdateFile,
	apiReplaceFile,
	apiReadAllFiles,
	//
	apiSearchFileOrFolder,
	// folder
	apiCreateFolder,
	apiUpdateFolder,
	apiDeleteFolder,
	apiReorderChildren,
	apiUpdateStructureLock,
	// download
	apiDownloadFile,
	apiDownloadFolder,
	apiDownloadAll,
	//
	apiAssignRole,
	//
	apiCreateFileFromLib,
	apiReadTrialBalance,
	apiReadFS,
	apiReadFA,
	apiSaveFA,
	apiReadFileInfo,
	apiReadFileHistory,
	apiUpdateSignOffLock,
}: ProjectFilesApiProps) =>
	types
		.model({})
		// CRUD
		// CRUD
		.actions((self) => ReadFirstFolders(self, apiReadFirstFolder))
		.actions((self) => ReadChildrenOfFolder(self, apiReadChildrenOfFolder))
		//
		//
		//
		//
		// file related parts
		.actions((self) => CreateFile(self, apiCreateFile))
		.actions((self) => UpdateFile(self, apiUpdateFile))
		.actions((self) => DeleteFile(self, apiDeleteFile))
		.actions((self) => RequestReplaceFile(self, apiReplaceFile))
		.actions((self) => ReadAllFiles(self, apiReadAllFiles))
		.actions((self) => ReadFileInfo(self, apiReadFileInfo))
		.actions((self) => ReadFileHistory(self, apiReadFileHistory))
		.actions((self) => UpdateSignOffLock(self, apiUpdateSignOffLock))
		//
		.actions((self) =>
			RequestFileOrFolderSearch(self, apiSearchFileOrFolder)
		)
		//
		.actions(UpdateFileStatus)
		.actions((self) => RequestFileUpload(self, apiCreateFile))
		//
		//
		//
		//
		// folder related parts
		.actions((self) => CreateFolder(self, apiCreateFolder))
		.actions((self) => UpdateFolder(self, apiUpdateFolder))
		.actions((self) => DeleteFolder(self, apiDeleteFolder))
		//
		//
		// download
		.actions((self) => RequestDownloadFile(self, apiDownloadFile))
		.actions((self) => RequestDownloadFolder(self, apiDownloadFolder))
		.actions((self) => RequestDownloadAll(self, apiDownloadAll))
		//
		//
		// comment related
		// .actions(WpCommentCreate)
		// .actions(WpCommentUpdate)
		// sign offs
		.actions(RequestSignOff)
		.actions(RequestUnSignOff)
		//
		//
		// assign role
		.actions((self) => RequestAssignRoleToFile(self, apiAssignRole))
		//
		// library
		.actions((self, cretaeFileByLib = apiCreateFileFromLib) =>
			CreateFileFromLib(self, cretaeFileByLib)
		)
		// re-order items (by ref number or dnd)
		.actions((self) => RequestReorder(self, apiReorderChildren))
		.actions((self) => UpdateStructureLock(self, apiUpdateStructureLock))
		// download all

		//
		// model common actions
		.actions(CommonModelActions)

export default PfModel
