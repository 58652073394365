import { flow } from "mobx-state-tree"
import { OrgSetupUserApiProps } from "../org-setup-users.apis"

const ReadOrgUserList = (self: any, read: OrgSetupUserApiProps["apiRead"]) => ({
	readOrgUserList: flow(function*() {
		const actionName = "readOrgUserList"
		// 3-1) set payload
		const pageIndex = 1
		// TODO: temporary payload. update is required
		/**
		 * CustomerID: 3
		 * Type: 0
		 * PageIndex: 1
		 * PageSize: 50
		 * ArchiveManager: -1
		 * IsActive: 0
		 */
		// WARNING: TODO: THIS PAYLOAD SHOULD BE UPDATED!!!! WARNING: TODO:
		let payload = {
			// ArchiveManager: 0,
			ArchiveManager: -1,
			// CustomerID: orgId
			IsActive: 0, // read all status (suspended, normal, ...)
			PageIndex: pageIndex,
			// PageSize: self.totalUser ? self.totalUser + 10 : 1000 // request 1000 working very fast. totalUser + 10 is required?
			PageSize: 1000, // request 1000 working very fast. totalUser + 10 is required?
			// Type: 0
		}
		//3-2) call the API in the Model
		try {
			const response = yield read(payload)

			// ConsoleLog("readOrgUserList response", response)
			return response
		} catch (error) {
			// console.error("readOrgUserList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadOrgUserList
