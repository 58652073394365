import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"

// FormData ex

// UserID: 16
// ParentTempFoldID: 333
// TempName: TemplateName
// MediaType: Template
// WPREF: TemplateName

const CreateProjTemplate = (self: any, createTemplate: any) => ({
	createProjTemplate: flow(function* ({
		userId,
		templateGroupId,
		templateTitle,
	}: {
		userId: number
		templateGroupId: number
		templateTitle: string
	}) {
		const actionName = "createProjTemplate"

		try {
			const formData = new FormData()

			formData.append("UserID", userId.toString())
			formData.append("ParentTempFoldID", templateGroupId.toString())
			formData.append("TempName", templateTitle)
			formData.append("MediaType", "Template")
			formData.append("WPREF", templateTitle)

			const response = yield createTemplate(formData)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateProjTemplate
