import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetUnarchivedProjects = (self: any) => ({
	getUnarchivedProjects({
		year,
		searchText,
	}: {
		year: "All Years" | string
		searchText?: string
	}) {
		// 0.
		const actionName = "getUnarchivedProjects"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readAmUnarchivedProjectList(year, searchText)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// 1) initialize existing stored list
					self.resetStoredList()
					// 2) Re-organize data from the server
					const existingAmArchivedProjectList =
						response.data.ArchivedClientList

					if (existingAmArchivedProjectList === null) {
					} else {
						existingAmArchivedProjectList.map(
							(unarchivedProject: any) => {
								let epUsers: any = []
								if (unarchivedProject.EpUsers !== "") {
									unarchivedProject.EpUsers.split(",").map(
										(user: string, i: number) => {
											epUsers.push({
												id:
													unarchivedProject.EPUserIDs &&
													unarchivedProject.EPUserIDs[
														i
													]
														? idToString(
																unarchivedProject
																	.EPUserIDs[
																	i
																],
																IdType.user
														  )
														: "xxx",
												aliasId: "xxx",
												name: user,
											})
										}
									)
								}
								// 2-2) convert qcUserIdList by string
								let qcUsers: any = []
								if (unarchivedProject.QCUsers !== "") {
									unarchivedProject.QCUsers.split(",").map(
										(user: string, i: number) => {
											qcUsers.push({
												id:
													unarchivedProject.QCUserIDs &&
													unarchivedProject.QCUserIDs[
														i
													]
														? idToString(
																unarchivedProject
																	.QCUserIDs[
																	i
																],
																IdType.user
														  )
														: "xxx",
												aliasId: "xxx",
												name: user,
											})
										}
									)
								}
								let archivedBy = null
								if (
									unarchivedProject.ArchivedBy !== 0 &&
									unarchivedProject.ArchivedBy !== null
								) {
									archivedBy = {
										id: idToString(
											unarchivedProject.ArchivedBy,
											IdType.user
										),
										aliasId: "xxx",
										name: unarchivedProject.ArchivedByUser,
									}
								}
								let unarchivedBy = null
								if (unarchivedProject.UnArchivedBy !== 0) {
									unarchivedBy = {
										id: idToString(
											unarchivedProject.UnArchivedBy,
											IdType.user
										),
										aliasId: "xxx",
										name: unarchivedProject.UnArchivedByUser,
									}
								}
								let lastAccessBy = null
								if (unarchivedProject.LastAccessedBy !== null) {
									lastAccessBy = {
										id: "xxx", // Not getting from backend
										aliasId: "xxx",
										name: unarchivedProject.LastAccessBy,
									}
								}
								// 2-3) re-organize the project information
								const reOrganizedUnarchivedProject = {
									//
									// * 1. location info: group, cabinet
									groupId: "xxx",
									groupName: unarchivedProject.GroupName, // [GroupName] ex) "2019 TEST"
									cabinetId: idToString(
										unarchivedProject.CabinetId,
										IdType.cabinet
									), // [CabinetId] ex) 400
									cabinetName: "",
									//
									// * 2. client info
									clientId: "xxx",
									clientName: unarchivedProject.ClientName, // [ClientName] ex) "CJ"
									clientAliasId:
										unarchivedProject.ClientAliasId, // [ClientAliasId]
									//
									// * 3. project basic info
									id: idToString(
										unarchivedProject.ProjectId,
										IdType.project
									), // [ProjectId] ex) 1238
									aliasId: unarchivedProject.clientShortName,
									title: unarchivedProject.ProjectName, // [ProjectName] ex) "01-07 11:07:53"
									//
									engTypeId: "xxx",
									engTypeName: unarchivedProject.EngType, // [EngType] ex) "Engagement A"
									//
									//
									raId: "xxx",
									raName:
										unarchivedProject.RiskAssessmentName ===
										null
											? "null"
											: unarchivedProject.RiskAssessmentName,
									//
									//
									// * 4. project status info
									statusId: "4",
									statusName: "Unarchived",
									periodId: "xxx",
									periodName: unarchivedProject.Period, // [Period] ex) "Full year"
									archiveDatePolicy: "xxx",
									//
									//
									replicaStatus:
										unarchivedProject.ReplicaStatus ===
										"Yes"
											? true
											: false,
									archiveZipStatus:
										unarchivedProject.ArchiveZipStatus ===
										"Yes"
											? true
											: false,
									unarchiveStatus:
										unarchivedProject.UnArchiveStatus ===
										"Yes"
											? true
											: false,
									//
									//
									version: parseInt(
										unarchivedProject.Version.split(" v")[1]
									), // [Version] ex) "Archived v1"
									isArchived: false, // Not getting from backend
									isLocked: true, // Not getting from backend
									//
									//
									// * 5. access info
									roleId: "xxx",
									roleName: "xxx",
									accessible: unarchivedProject.canVisit, // [IsOpenaccessright] ex) false
									// NOTE: Watchout. Because this is for unarchived projects, this "IsOpenaccessright" not be used
									//
									//
									// * 6. schedule info
									financialYear: unarchivedProject.Year, // [Year] ex) 2020
									periodEndDate:
										unarchivedProject.FinancialYear, // [FinancialYear] ex) "2020-01-05T19:00:00+00:00"
									//
									expectedReportDate:
										unarchivedProject.ExpectedReportDate ||
										"",
									finalReportDate:
										unarchivedProject.FinalReportDate || "",
									//
									//
									expectedArchiveDate:
										unarchivedProject.ExpectedArchiveDeadlineDate ||
										"",
									finalArchiveDeadlineDate:
										unarchivedProject.FinalArchiveDeadlineDate ||
										"",
									//
									//
									// * 7. history info
									// archOrUnArchBy:
									// 	unarchivedProject.ArchOrUnAchivedBy, // [ArchOrUnAchivedBy] ex) "cskim"
									// archOrUnArchAt:
									// 	unarchivedProject.ArchOrUnArchivedOn, // [ArchOrUnArchivedOn] ex) "2020-01-10T17:58:02.994666+00:00"
									createdBy: {
										id: idToString(
											unarchivedProject.CreatedBy,
											IdType.user
										),
										aliasId: "",
										name: unarchivedProject.CreatedByUser,
									},
									createdAt: unarchivedProject.CreatedDate,
									//
									archivedBy,
									archivedAt: unarchivedProject.ArchivedDate
										? unarchivedProject.ArchivedDate
										: "", // [ArchivedDate], ex) null
									//
									unarchivedBy,
									unarchivedAt:
										unarchivedProject.UnarchivedDate
											? unarchivedProject.UnarchivedDate
											: "", // [UnarchivedDate], ex) null
									//
									lastAccessBy, // [LastAccessBy] ex) "cskim"
									lastAccessAt:
										unarchivedProject.LastAccessDate, // [LastAccessDate] ex) "2020-01-10T17:57:25.614208+00:00"
									//
									// * 8. assigned users
									epUsers, // [EpUsers] ex) "김철수" // TODO: check the case with multiple ep users
									qcUsers, // [QCUsers] ex) "" // TODO: check the case with multiple qc users
									//
									// * 9. extras

									//
									// * + speciall part
									hasExportPermission:
										unarchivedProject.IsExportPermission, // [IsExportPermission] ex) false
									// NOTE: Watchout. Because this is for unarchived projects, this "IsExportPermission" not be used
									//
									// * ???
									// epUserIdList: epUserIdList, // [EPUserIDs] ex) [16]
									// qcUserIdList: qcUserIdList, // [QCUserIDs] ex) []
									// archOrUnArchDate:
									// 	unarchivedProject.ArchUnarchDate, // [ArchUnarchDate] ex) "2020-01-10T17:58:02.994666+00:00"
									// ---- related with signed-in user's permission
									// canVisit: unarchivedProject.canVisit, // [canVisit] ex) true
								}
								self.pushItemToList(
									reOrganizedUnarchivedProject
								)
							}
						)
					}

					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
				// At the end of the actions
			})
			.catch((error: Error) => {
				console.log(error, "error")
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetUnarchivedProjects
