import { flow } from "mobx-state-tree"

const DeleteAdjustment = (self: any, del: any) => ({
	deleteAdjustment: flow(function*(payload: { AdjustmentId: string }) {
		const actionName = "deleteAdjustment"
		try {
			const response = yield del(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default DeleteAdjustment
