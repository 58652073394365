import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { convertProjectStatus } from "../../../../../../library/get-project-status"

const GetOnlineProjects = (self: any) => ({
	getOnlineProjects(year: string) {
		// 0.
		const actionName = "getOnlineProjects"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readOnlineProjects({ year })
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					let onlineProjects: any[] = []
					response.data.AssignedProjectsList.map((project: any) => {
						let epUsers: any = []
						if (project.EpUsers !== "") {
							project.EpUsers.split(",").map(
								(user: string, i: number) => {
									epUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						let qcUsers: any = []
						if (project.QCUsers !== "") {
							project.QCUsers.split(",").map(
								(user: string, i: number) => {
									qcUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						//
						//
						let archivedBy = null
						if (project.ArchivedBy !== 0) {
							archivedBy = {
								id: idToString(project.ArchivedBy, IdType.user),
								aliasId: "xxx",
								name: "xxx",
							}
						}
						let unarchivedBy = null
						if (project.UnArchivedBy !== 0) {
							unarchivedBy = {
								id: idToString(
									project.UnArchivedBy,
									IdType.user
								),
								aliasId: "xxx",
								name: "xxx",
							}
						}

						const reOrganizedProjectInfo = {
							// * new
							checklistId: null, // will get from cdk api next step
							reportId: null, // will get from cdk api next step
							// * 1. location info: group, cabinet
							groupId: idToString(project.GroupID, IdType.group), // [GroupID], ex) 444
							groupName: project.GroupName, // [GroupName], ex) "Prepare and Review"
							cabinetId: idToString(
								project.CabinetID,
								IdType.cabinet
							),
							cabinetName: "xxx",
							//
							//
							// * 2. client info
							clientId: idToString(
								project.ClientMasterId,
								IdType.client
							), // [ClientMasterId], ex) 104
							clientName: project.ClientName, // [ClientName], ex) "ABCDEFGH"
							clientAliasId: project.ClientAliasId,
							//
							//
							// * 3. project basic info
							id: idToString(project.ClientID, IdType.project), // [ClientID], ex) 2507
							aliasId: project.LinkName, // [ProjectBusinessId], ex) "" --> is null possible..?
							title: project.ProjectName, // [ProjectName], ex) "57yth"
							//
							engTypeId: idToString(
								project.EngagementId,
								IdType.engType
							), // [EngagementId], ex) 115
							engTypeName: project.EngagementType, // [EngagementType], ex) "CUULWRRKSC"
							raId: "xxx",
							raName:
								project.RiskAssessmentName === null
									? "null"
									: project.RiskAssessmentName,
							//
							//
							// * 4. project status info
							statusId: convertProjectStatus(project.ProjectType)
								.id, // [ProjectType], ex) 1 --> normal, replica, archived, unarchived...
							statusName: convertProjectStatus(
								project.ProjectType
							).name,
							periodId: idToString(
								project.PeriodId,
								IdType.period
							), // [PeriodId], ex) 1
							periodName: project.PeriodName, // [PeriodName], ex) "Full year"
							//
							replicaStatus:
								project.ReplicaStatus === "Yes" ? true : false,
							archiveZipStatus:
								project.ArchiveZipStatus === "Yes"
									? true
									: false,
							unarchiveStatus:
								project.UnArchiveStatus === "Yes"
									? true
									: false,
							//
							version: project.Version, // [Version], ex) 0
							isArchived: project.Archive, // [Archive], ex) false
							isLocked: project.IsLocked, // [IsLocked], ex) false
							//
							//
							// * 5. access info
							roleId: project.RoleID.toString(), // Getting 0 for all records
							roleName:
								project.RoleName !== null
									? project.RoleName
									: "",
							accessible: !project.isGrayout,
							//
							createdBy: {
								id: idToString(
									project.ClientCreatedBy,
									IdType.user
								),
								aliasId: "()",
								name: project.CreatedUser,
							}, // [ClientCreatedBy], ex) 262
							createdAt: project.CreatedDate || "",
							//
							//
							// * 6. schedule info
							financialYear: project.Year, // [Year], ex) 2019
							periodEndDate: project.FinancialYear, // [FinancialYear], ex) "10/23/2019"
							//
							expectedReportDate:
								project.ExpectedReportDate || "",
							finalReportDate: project.FinalReportDate || "", // [ReportIssuranceDate], ex) ""

							//
							expectedArchiveDate:
								project.ExpectedArchiveDeadlineDate || "",
							finalArchiveDeadlineDate:
								project.FinalArchiveDeadlineDate || "", // watch out. This is different with ArchivedAt
							//
							//
							// * 7. history info
							// createdBy: types.union(types.null, SimpleUserModel),
							// createdAt: types.string,

							//
							//
							archivedBy: archivedBy,
							archivedAt: project.ProjectArchivedDate
								? project.ProjectArchivedDate
								: "",
							//
							unarchivedBy: unarchivedBy,
							unarchivedAt: project.UnarchivedDate
								? project.UnarchivedDate
								: "",

							// * 8. assigned users
							epUsers, // [EpUsers], ex) "" // TODO: @Noah: update for multiple EP Users
							qcUsers, // [QCUsers], ex) "" // TODO: @Noah: update for multiple QC Users
						}
						onlineProjects.push(reOrganizedProjectInfo)
					})
					self.setNeedRefresh(false)
					self.setOnlineProjects(onlineProjects)

					if (response.data.TotalCount === 0) {
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Success to fetch but project not exist",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					}
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
				// At the end of the actions
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOnlineProjects
