import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	DLButton,
	ConsoleLog,
} from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiFolder } from "@mdi/js"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function MoveEngTypeDialog({ i18n }: { i18n: DLI18nProps }) {
	const orgStore = useOrgStore()
	const actionName = "moveEngType"
	//
	//
	const handleMove = () => {
		const from = orgStore.archPolicy.selectedPolicy
		const to = orgStore.archPolicy.selectedPolicyForEngType
		const engTypeId = orgStore.archPolicy.selectedEngType
		ConsoleLog([engTypeId, from, to])
		orgStore.archPolicy.moveEngType(engTypeId, from, to)
	}

	const selectFolderHandler = (id: string) => {
		orgStore.archPolicy.setSelectedPolicyForEngType(id)
	}

	return (
		<DLDialog
			eleTestId="move-eng-type-dialog"
			isOpen={orgStore.archPolicy.openMoveEngTypeDialog}
			setIsOpen={orgStore.archPolicy.setOpenMoveEngTypeDialog}
			handleAction={handleMove}
			showCloseBtn={true}
			dialogTitle={i18n.twMoveEngType || "Move Engagement Type"}
			dialogContents={
				<MoveEngTypeDialogContent
					selectedFolder={
						orgStore.archPolicy.selectedPolicyForEngType
					}
					selectFolderHandler={selectFolderHandler}
					folders={orgStore.archPolicy.viewArchivePolicies()}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				orgStore.archPolicy.selectedPolicyForEngType !== ""
			}
			actionBtn={i18n.twMove || "Move"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.archPolicy.responses.getResponse(actionName)
					?.message
			}
			showSpinner={
				orgStore.archPolicy.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.archPolicy.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})

const MoveEngTypeDialogContent = observer(({
	selectedFolder,
	selectFolderHandler,
	folders,
}: {
	selectedFolder: string
	selectFolderHandler: any
	folders: any
}) => {
	return (
		<StyledDialogContent>
			{folders.map((item: any) => {
				return (
					<div
						className={`folder-details FR AC ${selectedFolder ===
							item.id && "selected"}`}
						onClick={() => selectFolderHandler(item.id)}
					>
						<Icon path={mdiFolder} size={1} />
						<span className="title-text">{item.title}</span>
					</div>
				)
			})}
		</StyledDialogContent>
	)
})

const StyledDialogContent = styled.div`
	.folder-details {
		padding: 3px;
		transition: backgrond-color 0.4s;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		margin: 0.2rem 0 0.2rem 0;
		cursor: pointer;
	}
	.title-text {
		margin-left: 0.6rem;
	}
	.selected {
		background-color: lightblue;
		:hover {
			background-color: lightblue;
		}
	}
`
