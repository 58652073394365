import React from "react"
// store related
import { observer } from "mobx-react-lite"
// components
import { DLContextMenu } from "../../../../../components/basic-elements"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import FileFolderContextMenuOptions from "./file-folder-ctx-menu-options"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function FileFolderContextMenu({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) {
	const handleClose = () => {
		partialStore.setFolderClickPoint(initialClickPoint)
	}
	const clickPoint = partialStore.folderClickPoint

	return (
		<DLContextMenu
			eleId="proj-files-folder-ctx-menu"
			autoFocus
			clickPoint={{
				mouseX: clickPoint.mouseX,
				mouseY: clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={FileFolderContextMenuOptions(partialStore, permission)}
			hasDivider={[1]}
		/>
	)
})
