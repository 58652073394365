import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const data = {
	id: "eng_1",
	aliasId: "21-0705-0021",
	type: "외감",
	year: 2020,
	description: "",
	custom_fields: [
		{
			key: "내역",
			value: "일반외감법감사보수",
		},
		{
			key: "상세내역",
			value: "일반법인",
		},
	],
	owner: "조개마",
	acceptee: "김도균",
	clientAliasId: "121-86-30542",
	clientName: "케이비세라믹",
	createdBy: "김도균",
	createdAt: "2020-07-16T09:57:18.343Z",
	lastModifiedBy: "조개마",
	lastModifiedDate: "2020-07-10T08:50:10.343Z",
	timeBudget: null,
	status1: "REQUESTED",
	status2: "CONFIRMED",
	confirmedBy: "김기수",
	confirmedAt: "2020-07-14T12:30:10.343Z",
	fee: "",
	project: "케이비세라믹 2020 기말감사",
	projectStatus: "ARCHIVED",
	comments: [],
	assignees: [],
}

const RowComp = ({
	label,
	value,
}: {
	label: string
	value: string | number | null
}) => {
	return (
		<div className="FR AC row-component">
			<div className="row-label">{label}</div>
			<div className="row-value">{value}</div>
		</div>
	)
}

export default observer(function EngInfo() {
	const orgStore = useOrgStore()

	return (
		<StyledEngInfo>
			<div className="section">
				<RowComp label="Engagement Code" value={data.aliasId} />
				<RowComp label="Type" value={data.type} />
				<RowComp label="Financial Year" value={data.year} />
				<RowComp label="Description" value={data.description} />
				<RowComp label="Custom fields" value={"pending..."} />
			</div>
			<div className="vertical-divider" />
			{/* 
			-
			-
			 */}
			<div className="section">
				<RowComp label="Client Code" value={data.clientAliasId} />
				<RowComp label="Client Name" value={data.clientName} />
				<div className="horizon-divider" />
				<RowComp label="Owner" value={data.owner} />
				<RowComp label="Acceptee" value={data.acceptee} />
				<div className="horizon-divider" />
				<RowComp label="Created By" value={data.createdBy} />
				<RowComp label="Created At" value={data.createdAt} />
				<div className="horizon-divider" />
				<RowComp label="Modified By" value={data.lastModifiedBy} />
				<RowComp label="Modified At" value={data.lastModifiedDate} />
			</div>
			<div className="vertical-divider" />
			{/* 
			-
			-
			 */}
			<div className="section">
				<RowComp
					label="Time Budget"
					value={data.timeBudget ? data.timeBudget : "-"}
				/>
				<RowComp label="Fee" value={data.fee ? data.fee : "-"} />
				<div className="horizon-divider" />

				<RowComp label="Status 1" value={data.status1} />
				<RowComp label="Status 2" value={data.status2} />
				<div className="horizon-divider" />

				<RowComp label="Confirmed By" value={data.confirmedBy} />
				<RowComp label="Confirmed At" value={data.confirmedAt} />
				<div className="horizon-divider" />

				<RowComp label="Project" value={data.project} />
			</div>
		</StyledEngInfo>
	)
})

const StyledEngInfo = styled.div`
	width: 100%;
	padding-left: 1rem;
	padding-right: 1rem;
	display: flex;
	flex-wrap: wrap;
	.section {
		flex-grow: 1;
		.row-component {
			.row-label {
				min-width: 40%;
				font-weight: 600;
			}
		}
	}
	.vertical-divider {
		width: 1px;
		height: 100%;
		background-color: gray;
		margin: 0 0.6rem;
	}
	.horizon-divider {
		margin: 1rem 0;
	}
`
