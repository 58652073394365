import React from "react"
// import ReactDOM from "react-dom"
import { createRoot } from "react-dom/client"
import { BrowserRouter } from "react-router-dom"
import App from "./App"
import * as serviceWorker from "./serviceWorker"
import { RootStoreProvider } from "./stores/root-store/root-store.provider"

const container = document.getElementById("root") as HTMLElement
const root = createRoot(container)

root.render(
	<BrowserRouter>
		<RootStoreProvider>
			<App />
		</RootStoreProvider>
	</BrowserRouter>
)

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister()
