import React from "react"
import { observer } from "mobx-react-lite"
import { CreateProjectMethod } from "./create-project.data-props"
import { InputWithLabel, DLRadioGroup } from "../../basic-elements"
import {
	WithoutTemplate,
	WithTemplate,
	WithRollForward,
} from "./center-section-sub-parts"
import { CreateProjectSectionCenterProps } from "./create-project.comp-props"

export default observer(function CreateProjectSectionCenter({
	groupId,
	path,
	//
	inputs,
	handleInputs,
	handleCheckbox,
	handleSelectTemplate,
	handleSelectRfSource,
	//
	createMethod,
	//
	highlight,
	//
	i18n,
}: CreateProjectSectionCenterProps) {
	const isRollForward = createMethod === CreateProjectMethod.byRollForward

	console.log(isRollForward, "isRollForward")
	return (
		<div className={`center-area select-template section-on FC`}>
			{path.length <= 1 ? (
				<div className="request-required-field note">
					Please set location first
				</div>
			) : (
				<>
					{/* When start by roll-forward  */}
					{isRollForward ? (
						<div />
					) : (
						<div className={`input-section FR template-type`}>
							<InputWithLabel
								label="1. Select Template"
								// required
								eleClassName=" with-label"
								direction="vertical"
							>
								<DLRadioGroup
									groupName="createMethod"
									disableOtherOptions={
										createMethod ===
										CreateProjectMethod.byHardCopyOnly
									}
									items={[
										{
											value: CreateProjectMethod.byBlank,
											label: i18n.twStartWithEmpty,
										},
										{
											value: CreateProjectMethod.byProjectTemplate,
											label: i18n.twStartWithTemplate,
										},
										// {
										// 	value: CreateProjectMethod.byRollForward,
										// 	label: i18n.twStartWithRf,
										// },
										// {
										// 	value: CreateProjectMethod.byHardCopyOnly,
										// 	label: i18n.twHardCopyOnly,
										// },
									]}
									selectedValue={inputs.createMethod}
									eleOnChange={handleInputs}
								/>
							</InputWithLabel>
						</div>
					)}

					{inputs.createMethod === CreateProjectMethod.byBlank && (
						<WithoutTemplate />
					)}
					{inputs.createMethod ===
						CreateProjectMethod.byProjectTemplate && (
						<WithTemplate
							selectedTemplate={inputs.templateId}
							handleSelectTemplate={handleSelectTemplate}
							highlight={highlight}
						/>
					)}
					{isRollForward && (
						<WithRollForward
							createMethod={createMethod}
							groupId={groupId}
							handleSelectRfSource={handleSelectRfSource}
							handleCheckbox={handleCheckbox}
							inputs={inputs}
							i18n={i18n}
							highlight={highlight}
						/>
					)}
				</>
			)}
		</div>
	)
})
