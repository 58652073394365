import React from "react"
import { observer } from "mobx-react-lite"
import {
	InputWithLabel,
	DLInputField,
	DLTextArea,
	DLButton,
	DLIconButton,
	DLSystemMsg,
} from "../../../../../../components/basic-elements"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiFilePlusOutline, mdiClose } from "@mdi/js"
import { MessageColorType } from "../../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { allowedFormats } from "../../../../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function ArchiveProjectForm({
	inputs,
	handleOnChange,
	filesData,
	handleChangeFile,
	removeFilefromList,
	hasUpload,
	i18n,
}: {
	inputs: any
	handleOnChange: any
	filesData: any
	handleChangeFile: any
	removeFilefromList: any
	hasUpload: boolean
	i18n: DLI18nProps
}) {
	const userEmail = localStorage.getItem("lobbyUserId")
	return (
		<StyledArchivedForm>
			<p>{i18n.tsArchiveAlert}</p>
			<div className="input-section FR">
				<InputWithLabel required label={i18n.twEmail}>
					<DLInputField
						autoFocus
						eleTestId="archive_email_input"
						eleFullWidth
						eleName="email"
						eleValue={inputs.email.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={inputs.email.error}
						requestInput={inputs.email.requestInput}
					/>
					{inputs.email.value !== "" &&
						userEmail !== inputs.email.value && (
							<DLSystemMsg
								type={MessageColorType.red}
								msg={i18n.tsEmailValidation}
							/>
						)}
				</InputWithLabel>
			</div>
			{hasUpload && (
				<>
					<div className="input-section FR">
						<InputWithLabel required label={i18n.twReason}>
							<DLTextArea
								eleTestId="reason_input_area"
								eleFullWidth
								eleName="reason"
								eleValue={inputs.reason.value}
								eleHandleChange={handleOnChange}
								eleRequired
								warningMsg={inputs.reason.error}
								requestInput={inputs.reason.requestInput}
								eleFieldHeight="5"
							/>
						</InputWithLabel>
					</div>
					<div>
						<div className="FR JSB">
							<div className="fileType">{i18n.twFileType}</div>
							<div className="fileSize">{i18n.twFileSize}</div>
							<div className="fileName">{i18n.twFileName}</div>
							<div className="fileActions">
								<DLButton
									eleTestId="choose-files-btn"
									startIcon={
										<Icon
											path={mdiFilePlusOutline}
											size={0.8}
										/>
									}
								>
									<label className="file-upload-btn-wrapper">
										{i18n.twChooseFiles}
										<input
											type="file"
											name="file"
											onChange={handleChangeFile}
											multiple
											accept={allowedFormats}
										/>
									</label>
								</DLButton>
							</div>
						</div>
						<hr />
						{filesData.map((item: any) => {
							return (
								<div
									key={item.tempId}
									className={`${
										item.hasSizeErr && "file-error"
									}`}
								>
									<div className="FR JSB">
										<div className="fileType">
											{item.MediaType}
										</div>
										<div className="fileSize">
											{item.formattedSize}
										</div>
										<div className="fileName">
											{item.WPDesc}
										</div>
										<div className="fileActions">
											<DLIconButton
												clickHandler={() =>
													removeFilefromList(
														item.tempId
													)
												}
												color="warning"
												align="center"
												eleTestId="close-btn"
												tooltipText="Remove file"
												size="small"
											>
												<Icon
													path={mdiClose}
													size={1}
												/>
											</DLIconButton>
										</div>
									</div>
									{item.hasSizeErr && (
										<>
											{item.MediaType === ".zip" ? (
												<p>
													ZIP file size should not
													exceed 100MB
												</p>
											) : (
												<p>
													File size should not exceed
													30MB
												</p>
											)}
										</>
									)}
								</div>
							)
						})}
						{filesData.length < 1 && (
							<div className="no-files">
								<span>Please upload atleast one file</span>
							</div>
						)}
					</div>
					<hr />
					<div className="notes-reason-docs">
						<p> Note: </p>
						<p>1). {i18n.tsFileNote1}</p>
						<p>2). ZIP file size should not exceed 100MB</p>
						<p>3). {i18n.tsFileNote2}</p>
					</div>
				</>
			)}
		</StyledArchivedForm>
	)
})

const StyledArchivedForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
	.no-files {
		height: 100px;
		text-align: center;
	}
	.file-upload-btn-wrapper {
		input[type="file"] {
			display: none;
		}
	}
	.file-error {
		color: red;
	}
	.fileType {
		flex-basis: 10rem;
	}
	.fileSize {
		flex-basis: 10rem;
	}
	.fileName {
		flex-basis: 20rem;
	}
	.fileActions {
		flex-basis: 10rem;
	}
`
