import { flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../../../library/api-requests"
import { getProjIdFromUrl } from "../../../../../../library/api-requests/request-get-others"

const RequestArchive = (self: any) => ({
	requestArchive: flow(function* () {
		const actionName = "requestArchive"
		try {
			// const endpoint = "/archive"
			const endpoint = "/Archive"

			let form = new FormData()

			const archivedBy = localStorage.getItem("orgUserId")
			const projectId = getProjIdFromUrl()

			let archiveInfo

			if (archivedBy && projectId) {
				archiveInfo = {
					Step: 1,
					ArchivedBy: parseInt(archivedBy),
					ClientID: parseInt(projectId),
					ReplicaClientID: 0, // response will provide replica ID
				}
			}

			// form.append("Step", "1")
			// form.append("ArchivedBy", localStorage.getItem("orgUserId"))
			// form.append("ClientID", localStorage.getItem(""))
			// form.append("ReplicaClientID", "0")

			// form.set("ArchiveDataObj", {})

			const response = yield postRequest({
				url: endpoint,
				params: form,
				tokenType: TOKEN_TYPE.projToken,
			})
			// ConsoleLog("archiveProject response", response)
			return response
		} catch (error) {
			// ConsoleLog("archiveProject error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestArchive
