import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	ConsoleLog,
	DLDialog,
	DLSpinnerCenterAligned,
} from "../../basic-elements"
import {
	PriorityType,
	DialogActionType,
} from "../../../common-models/enumerations/common-enums"
import {
	DLCommentDetailDialogProps,
	DLCommentDetailFormInputProps,
} from "../../../common-models/types/comment-props"
import { DLCommentType } from "../../../common-models/enumerations/common-enums"
import CommentDetailForm from "./CommentDetailForm"
import {
	DLIconName,
	DLIcon,
	CommonIconSize,
} from "../../basic-elements/icons/common-icons"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import keyboardJS from "keyboardjs"

// NOTE: DO NOT USE ANY STORE HERE
// Store related datas should came from controller
// NOTE: WARNING: TODO:
// It is hard to get whole file list or file tree on the comment screen
// So the comment add button can be omitted on the comments screen (TBD)
// It also requires update the 'actions' part.
// It should connected with updated method not only for the wp, pf
export default observer(function CommentDetailDialog(
	props: DLCommentDetailDialogProps
) {
	const {
		open,
		setOpen,
		//
		mode,
		from,
		//
		commentId,
		commentInfo,
		objectId,
		objectInfo,
		assignableUserList,
		commentType,
		//
		// getUserListForSelection,
		addComment,
		editComment,
		//
		projRole,
		i18n,
	} = props

	const initialInputs = {
		priority: PriorityType.medium,
		type: DLCommentType.normal,
		assigneeIds: [],
		contents: "",
	}

	const handleOpen = (request: boolean) => {
		setOpen(request)
	}

	const [inputs, setInputs] =
		useState<DLCommentDetailFormInputProps>(initialInputs)
	const [isInProgress, setIsInProgress] = useState(false)

	const addMode = mode === DialogActionType.add
	const editMode = mode === DialogActionType.edit
	const readMode = mode === DialogActionType.read

	useEffect(() => {
		if (!addMode) {
			if (commentInfo) {
				setInputs({
					priority: commentInfo.priority,
					type: commentInfo.type,
					assigneeIds: commentInfo.assigneeIds,
					contents: commentInfo.contents,
				})
			} else {
				ConsoleLog("(CommentDetailDialog) Cannot find matched comment")
			}
		} else {
			if (commentType) {
				setInputs((prev) => ({
					...prev,
					type: commentType,
				}))
			}
		}
	}, [mode])
	//
	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value

		if (value === "true") {
			value = true
		} else if (value === "false") {
			value = false
		}

		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	const handleAssignees = (
		event: object,
		value: { id: string; name: string; roleId: string; aliasId: string }[]
	) => {
		let users: string[] = []
		value.map((user) => users.push(user.id))

		setInputs((inputs: any) => ({
			...inputs,
			assigneeIds: users,
		}))
	}

	const handleAction = () => {
		if (readMode) {
		} else if (editMode) {
			editComment(inputs)
			setIsInProgress(true)
		} else if (addMode) {
			addComment(inputs)
			setIsInProgress(true)
		}
	}

	let actionBtnText = ""
	if (addMode) {
		actionBtnText = "Add"
	} else if (editMode) {
		actionBtnText = "Edit"
	} else if (readMode) {
		actionBtnText = "Mark Closed"
	}

	const isCompleted = commentInfo?.status === CommentStatus.done

	const commentDialogTitle = () => {
		// NOTE: read and edit use same title for UX
		return (
			<div className="FR AC">
				{readMode && (
					<>
						<DLIcon
							name={DLIconName.comment}
							size={CommonIconSize.dialogHeaderIcon}
						/>
						<div>{isCompleted ? "Closed Comment" : "Comment"}</div>
					</>
				)}
				{addMode && (
					<>
						<DLIcon
							name={DLIconName.addComment}
							size={CommonIconSize.dialogHeaderIcon}
						/>
						<div>
							{`Add ${
								commentType === DLCommentType.normal
									? "Review"
									: "QC"
							} Comment`}
						</div>
					</>
				)}
				{editMode && (
					<>
						<DLIcon
							name={DLIconName.editComment}
							size={CommonIconSize.dialogHeaderIcon}
						/>
						<div>Edit Comment</div>
					</>
				)}
			</div>
		)
	}

	let actionReady = false
	if (addMode) {
		if (
			inputs.priority !== undefined &&
			inputs.contents !== undefined &&
			inputs.contents !== "" &&
			inputs.type !== undefined &&
			inputs.assigneeIds !== undefined &&
			inputs.assigneeIds.length > 0
		) {
			actionReady = true
		}
	} else if (editMode) {
		if (
			inputs.priority !== undefined &&
			inputs.contents !== undefined &&
			inputs.contents !== "" &&
			inputs.type !== undefined &&
			inputs.assigneeIds !== undefined &&
			inputs.assigneeIds.length > 0 &&
			JSON.stringify({
				priority: commentInfo.priority,
				type: commentInfo.type,
				assigneeIds: commentInfo.assigneeIds,
				contents: commentInfo.contents,
			}) !== JSON.stringify(inputs)
		) {
			actionReady = true
		}
	} else if (readMode) {
	}

	return (
		<DLDialog
			eleTestId="comment-detail-dialog"
			isOpen={open}
			setIsOpen={handleOpen}
			handleAction={handleAction}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={commentDialogTitle()}
			dialogContents={
				<>
					{isInProgress && (
						<DLSpinnerCenterAligned
							absolute
							backgroundOpacity={0.7}
						/>
					)}
					<CommentDetailForm
						mode={mode}
						from={from}
						//
						objectInfo={objectInfo}
						assignableUserList={assignableUserList}
						//
						commentInfo={commentInfo}
						inputs={inputs}
						handleInputs={handleInputs}
						handleAssignees={handleAssignees}
						//
						projRole={projRole}
					/>
				</>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={actionReady && !isInProgress && !readMode}
			actionBtn={actionBtnText}
			maxWidth="md"
			fullWidth={false}
		/>
	)
})
