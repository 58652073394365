import React, { useEffect } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { ClickPointProps } from "../../../common-models/types/dialog.props"
import { capitalizeFirstLetter } from "@datalobby/common"
import { Link } from "react-router-dom"
import { DLTooltip } from "@datalobby/components"
import keyboardJS from "keyboardjs"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

export type DLCtxMenuOptionType = {
	label: string
	value: string
	clickEvent?: () => void
	icon?: React.ReactNode
	disabled?: boolean
	available?: boolean
	isLink?: boolean
	linkTo?: string
	tooltip?: string
}
interface DLContextMenuProps {
	clickPoint: ClickPointProps
	menuOptions: DLCtxMenuOptionType[]
	hasDivider?: Array<number>
	handleClose?: any
	eleId?: string
	autoFocus?: boolean
}

export default function DLContextMenu({
	clickPoint,
	menuOptions,
	hasDivider,
	handleClose,
	eleId,
	autoFocus = true,
}: DLContextMenuProps) {
	const store = useRootStore()
	const bgColor = store.ui.getThemeValue("background")
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	const { mouseX, mouseY } = clickPoint
	const open = mouseY !== null && mouseX !== null

	const finalOptions = menuOptions.filter(
		(option) => option.available === undefined || option.available === true
	)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	return (
		<Menu
			autoFocus={autoFocus}
			MenuListProps={{
				id: eleId,
				className: `dl-ctx-menu-testid ${open ? "opened" : "closed"}`,
				style: { backgroundColor: bgColor, color: txtColor },
			}}
			// @Noah: below line seems canbe changed
			// WARNING: DO NOTE REMOVE OR UPDATE this className 'dl-ctx-menu-testid' and 'opened'
			// It is used on test instead of the testid because this component does not support that property
			// and 'opened' is also required to distinguish the opened ctx menu

			keepMounted
			open={open}
			onClose={() => {
				keyboardJS.resume()
				handleClose()
			}}
			anchorReference="anchorPosition"
			anchorPosition={
				mouseY !== null && mouseX !== null
					? { top: mouseY, left: mouseX }
					: undefined
			}
		>
			{finalOptions.map((option, index) => {
				const {
					label,
					value,
					clickEvent,
					icon,
					disabled,
					isLink,
					linkTo,
					tooltip,
				} = option
				return (
					// WARNING: DO NOT WRAP MENUITEM WITH OTHER ELEMENT
					// WARNING: IT WILL REMOVE THE KEYBOARD CONNECTION
					<MenuItem
						id={value}
						key={index}
						data-testid={value}
						disabled={disabled}
						className="menu-item"
						onClick={
							isLink
								? () => {}
								: () => {
										clickEvent && clickEvent()
										handleClose()
								  }
							// NOTE: Do not use linkTo here instead of the isLink
						}
						style={{
							display: "flex",
							flexDirection: "row",
							justifyContent: "space-between",
							padding: 0,
						}}
					>
						<DLTooltip title={tooltip} arrow>
							{/* this div element is requried for tooltip */}
							<StyledContextMenu style={{ width: "100%" }}>
								{isLink && linkTo ? (
									<Link
										to={linkTo}
										onClick={() => {
											clickEvent && clickEvent()
											handleClose()
										}}
									>
										<MenuItemLabel
											hasDivider={hasDivider}
											index={index}
											icon={icon}
											label={label}
										/>
									</Link>
								) : (
									<MenuItemLabel
										hasDivider={hasDivider}
										index={index}
										icon={icon}
										label={label}
									/>
								)}
							</StyledContextMenu>
						</DLTooltip>
					</MenuItem>
				)
			})}
		</Menu>
	)
}

export const MenuItemLabel = ({
	hasDivider,
	index,
	icon,
	label,
}: {
	hasDivider: any
	index: any
	icon: any
	label: any
}) => {
	return (
		<div
			className="FR AC icon-and-label"
			style={{
				borderBottom: `1px solid ${
					hasDivider?.includes(index)
						? "rgba(0,0,0,0.1)"
						: "transparent"
				}`,
				width: "100%",
				padding: "0.4rem 1rem",
			}}
		>
			<div className="FR AC">{icon}</div>
			<div>{capitalizeFirstLetter(label, true)}</div>
		</div>
	)
}

const StyledContextMenu = styled.div``
