export enum IdType {
	org = "orgId",
	group = "groupId",
	project = "projId",
	user = "userId", // NOTE: Does this need to separate lobby user id and org user id?
	client = "clientId",
	folder = "folderId",
	cabinet = "cabinetId",
	//
	file = "fileId", // wp, pf
	src = "srcId", // ex. xlsx, pdf, ... etc uploaded source of file (wp, pf)
	//
	archPolicyPeriod = "archPolicyPeriodId", // 15 days, 30 days... etc
	archivePolicy = "archivePolicyId", // group of conditions
	archiveCondition = "archiveConditionId",
	//
	engType = "engTypeId",
	period = "periodId",
	ra = "raId", // risk assessment (R.A.)
	//
	projTemplateGroup = "projTemplateGroupId", // group of project template
	projTemplate = "projectTemplateId", // template for project creation
	//
	wpComment = "wpCommentId",
	pfComment = "pfCommentId",
	//
	hcId = "hcId"
}

export function convertId(id: string | number, type: IdType) {
	if (typeof id === "string") {
		if (id.includes(type)) {
			return parseInt(id.split(type)[1])
		} else {
			console.error("Invalid ID or ID type. Please check", id, type)
		}
	} else if (typeof id === "number") {
		return type + id.toString()
	}
}

export function idToString(id: number, type: IdType) {
	return type + id.toString()
}

export function idToNumber(id: string, type: IdType) {
	if (id.includes(type)) {
		return parseInt(id.split(type)[1])
	} else {
		console.error(
			"Invalid ID or ID type. Please check: (id)",
			id,
			" (type)",
			type
		)
		return `Failed to convert stringId (${id}, type: ${type}) to numberId`
	}
}

// Org level access right types
// "UA" = 3
// "GA" = 2
// "SA" = 1

// NOTE: refer to this
export function orgRoleToString() {
	return
}

export function orgRoleToNumber() {
	return
}

export function convertTestId(name: string) {
	return name.toLowerCase().replace(/ /g, "-")
}
