import { flow } from "mobx-state-tree"

const ReadAvailableLeadSheets = (self: any, readAvaLeadSheets: any) => ({
	readAvailableLeadSheets: flow(function*(payload: {
		clientId: number
		financialSubGroupId: number
	}) {
		const actionName = "readAvailableLeadSheets"
		try {
			const response = yield readAvaLeadSheets(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadAvailableLeadSheets
