import React, { useState, useEffect } from "react"
import { DLInputField } from ".."
import { DLIconButton } from "../buttons"
import Icon from "@mdi/react"
import { mdiMagnify, mdiClose } from "@mdi/js"
import styled from "styled-components"

export default function InputFieldForTable({ data }: { data: any }) {
	const [query, setQuery] = useState("")

	let columnId = data.column.id
	let filterQuery = ""
	const dataFilter = data.filter
	if (dataFilter) {
		filterQuery = dataFilter.value
	}

	useEffect(() => {
		if (dataFilter) {
			setQuery(dataFilter.value)
		}
	}, [dataFilter])

	const handleOnKeyDown = (event: any) => {
		if (event.keyCode === 13) {
			// 13 is enter key
			data.onChange(query)
		}
		if (event.keyCode === 27) {
			// 27 is escape key
			data.onChange("")
			setQuery("")
		}
	}

	return (
		<StyledInputFieldForTable
			className="column-search-container FR AC"
			onKeyDown={(e: any) => handleOnKeyDown(e)}
		>
			<DLInputField
				eleTestId={`column-search-${columnId}`}
				eleType="search"
				variant="outlined"
				eleValue={
					query === "" && filterQuery !== "" ? filterQuery : query
				}
				eleHandleChange={(e: any) => {
					setQuery(e.target.value)
					if (e.target.value === "" && filterQuery !== "") {
						data.onChange("")
					}
				}}
			/>
			{query !== "" && (
				<DLIconButton
					eleClassName="run-search"
					eleTestId={`${
						data.filter
							? `run-search-${columnId}`
							: "run-search-unknown"
					}`}
					clickHandler={() => {
						data.onChange(query)
					}}
				>
					<Icon path={mdiMagnify} size={0.7} />
				</DLIconButton>
			)}
			{query === "" && filterQuery !== "" && (
				<DLIconButton
					eleClassName="reset-query"
					eleTestId={"reset-query-" + columnId}
					clickHandler={() => {
						data.onChange("")
					}}
				>
					<Icon path={mdiClose} size={0.7} />
				</DLIconButton>
			)}
		</StyledInputFieldForTable>
	)
}


export function InputFieldForTableV8({ columnId, onChange, value }: { columnId: any, onChange: any, value: any }) {
	const [query, setQuery] = useState("")

	useEffect(() => {
		if (value) {
			setQuery(value)
		}
	}, [value])

	const handleOnKeyDown = (event: any) => {
		if (event.keyCode === 13) {
			// 13 is enter key
			onChange(query)
		}
		if (event.keyCode === 27) {
			// 27 is escape key
			onChange("")
			setQuery("")
		}
	}

	return (
		<StyledInputFieldForTable
			className="column-search-container FR AC"
			onKeyDown={(e: any) => handleOnKeyDown(e)}
		>
			<DLInputField
				eleTestId={`column-search-${columnId}`}
				eleType="search"
				variant="outlined"
				eleValue={query}
				eleHandleChange={(e: any) => {
					setQuery(e.target.value)
					if (e.target.value === "") {
						onChange("")
					}
				}}
			/>
			{query !== "" && (
				<DLIconButton
					eleClassName="run-search"
					eleTestId={`${
						value
							? `run-search-${columnId}`
							: "run-search-unknown"
					}`}
					clickHandler={() => onChange(query)}
				>
					<Icon path={mdiMagnify} size={0.7} />
				</DLIconButton>
			)}
		</StyledInputFieldForTable>
	)
}

const StyledInputFieldForTable = styled.div`
	&.column-search-container {
		/* NOTE: This place is not working. Please update the table style  */
		/* input {
			padding: none;
			font-size: 14px;
			border: none;
		} */
	}
`
