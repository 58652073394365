import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const data = {
	// ---------- section 2. 재무제표 감사 및 내부회계관리제도 감사 정보
	financialYear: 2019,
	periodEndDate: "2019-12-31T23:59:59.000Z",
	isFirstAudit: false, // 초도감사 여부
	isQuarterReviewed: false, // 분기검토 여부
	isHalfReviewed: false, // 반기검토 여부
	isKAMApplied: false, // 핵심감사제(KAM) 적용대상 여부
	isKIFRSApplied: false, // 한국채택 국제회계기준 적용 여부
	//
	isPureHolding: false, //순수지주사 여부
	expectAuditOpinion: "적정", // 예상 감사의견
	hasExpectIndvNetLoss: false, // 예상 당기순손실 여부
	isSOXTarget: false, // 내부회계관리제도 감사 대상 여부
	SOXYearCnt: null, // 내부회계관리제도 감사 적용 연차
}

export default observer(function AuditInfo() {
	const orgStore = useOrgStore()

	return <StyledAuditInfo>Audit Information</StyledAuditInfo>
})

const StyledAuditInfo = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
