import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
	deleteRequest,
} from "../../../../../library/api-requests"
import { ChecklistRecordType } from "@datalobby/types/lib"

export function create(params: { EngagementName: string }) {
	const response = postRequest({
		url: "/CreateEngagementType",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function read(payload: { PageIndex?: number; PageSize: number }) {
	const response = postRequest({
		url: "/GetEngagementType",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type ReadChecklistConnectionsProps = {
	orgId: string
	projectTypeId?: string
}
export function readChecklistConnections({
	orgId,
	projectTypeId,
}: ReadChecklistConnectionsProps) {
	let url = `/checklists`

	const response = getRequest({
		url,
		params: {
			orgId,
			type: ChecklistRecordType.connection,
			...(projectTypeId && { projectTypeId }),
		},
		tokenType: TOKEN_TYPE.cdkBackend,
	})
	return response
}
export function readChecklistTemplates(orgId: string) {
	let url = "/checklists"

	const response = getRequest({
		url,
		params: {
			orgId,
			type: ChecklistRecordType.template,
		},
		tokenType: TOKEN_TYPE.cdkBackend,
	})
	return response
}
export function update(params: {
	EngagementName: string
	EngagementID: string
	ModifiedBy: string
}) {
	const response = putRequest({
		url: "/EditEngagementType",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(params: { ID: string; UserID: string }) {
	const response = deleteRequest({
		url: "/DeleteEngagementType",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgEngTypeApiProps = {
	apiCreate: (params: {
		EngagementName: string
	}) => Promise<AxiosResponse<any>>
	apiRead: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>
	apiReadChecklistConnections: (
		params: ReadChecklistConnectionsProps
	) => Promise<AxiosResponse<any>>
	apiReadChecklistTemplates: (orgId: string) => Promise<AxiosResponse<any>>
	//
	apiUpdate: (params: {
		EngagementName: string
		EngagementID: string
		ModifiedBy: string
	}) => Promise<AxiosResponse<any>>
	apiDelete: (params: {
		ID: string
		UserID: string
	}) => Promise<AxiosResponse<any>>
}
