import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
// import OrgMenuFlatList from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menu-flat-list"
import { RolePermissionProps } from "../../../../../../service-modules/role-module/roles.data-model"
import combineRoleAndPermission from "../../../../../../library/combine-role-and-permissions"
import { ConsoleLog } from "../../../../../../components/basic-elements"

const GetOrgRoleDetails = (self: any) => ({
	getAllOrgRoleDetails() {
		// 0. set actionName
		const actionName = "getAllOrgRoleDetails"
		// 1.
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readOrgRoleDetails()
			.then((response: RolePermissionProps[]) => {
				if (response) {
					//
					// WARNING: below line will occur error but postpone the fixnig becuase this screen is not served yet
					const orgMenuFlatList = self.appMenus
					// WARNING: call the all menus again or check the lobby store
					const roleDetails = combineRoleAndPermission(
						self.roleSet,
						response,
						// OrgMenuFlatList
						orgMenuFlatList
					)
					ConsoleLog(["roleDetails of org", roleDetails])
					self.setRoleDetails(roleDetails)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999, // temp
						customMessage:
							"Successfully get the organization role details",
						color: MessageColorType.blue,
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgRoleDetails
