import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../../../library/api-requests"

const RequestRegenPreProcessPdfs = (self: any) => ({
	requestRegenPreProcessPdfs: flow(function* (
		projId: string,
		userId: string
		// isProjectLocked: boolean
	) {
		const actionName = "requestRegenPreProcessPdfs"
		const projNumberId = idToNumber(projId, IdType.project)
		const userNumberId = idToNumber(userId, IdType.user)

		if (
			typeof projNumberId === "number" &&
			typeof userNumberId === "number"
		) {
			try {
				// const payload = {
				// 	projectId: projNumberId,
				// 	userId: userNumberId,
				// }
				const response = yield postRequest({
					url: `/GenerateOldPreProcPDFs?projectId=${projNumberId}&userId=${userNumberId}`,
					params: {}, // payload,
					tokenType: TOKEN_TYPE.orgToken,
				})

				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName, '__error', error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			// alert(
			// 	`(${actionName}) invalid prop exist. ${projNumberId} | ${userNumberId}`
			// )
		}
	}),
})

export default RequestRegenPreProcessPdfs
