import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"
import { ReadOtherProjectsProps } from "../other-projects.apis"

const GetOtherProjects = (self: any) => ({
	getOtherProjects(params: ReadOtherProjectsProps) {
		// 0.
		const actionName = "getOtherProjects"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readOtherProjects(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				self.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.success,
				})
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOtherProjects
