import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import {
	BulkClientImportItemType,
	OrgClientModel,
} from "../data-models/org-clients.data-models"

// NOTE: actions
export const BulkClientImportRelated = (self: any) => ({
	resetBulkClientList() {
		self.bulkClientList.length = 0
	},
	pushItemToBulkList(item: any) {
		self.bulkClientList.push(item)
	},
	setBulkClientList(list: any) {
		self.bulkClientList = list
	},
	updateBulkClientStatus(
		statusMessage: string,
		status: ActionStatus,
		clientId: string,
		fetchedClientId?: string
	) {
		const index = self.bulkClientList.findIndex(
			(client: any) => client.clientId === clientId
		)
		self.bulkClientList[index].status = status
		self.bulkClientList[index].statusMessage = statusMessage
		if (fetchedClientId) {
			self.bulkClientList[index].clientId = fetchedClientId
		}
	},
	spliceBulkClient(clientId: string) {
		const targetIndex = self.bulkClientList.findIndex(
			(client: any) => client.clientId === clientId
		)
		self.bulkClientList.splice(targetIndex, 1)
	},
	setBulkClientFileReadStatus(status: ActionStatus) {
		self.bulkClientFileReadStatus = status
	},
	removeDuplicatedBulkClients(duplicatedList: any) {
		const filteredList = self.bulkClientList.filter((item: any) => {
			return !duplicatedList.some(
				(item1: any) =>
					item1.reqAliasId === item.aliasId ||
					item1.reqName === item.name
			)
		})
		self.bulkClientList = filteredList
	},
})

export type DuplicatedClientItemType = {
	id: string
	name: string
	duplicatedName: boolean
	aliasId: string
	duplicatedAliasId: boolean
	reqId?: string
	reqName?: string
	reqAliasId?: string
}

// NOTE: views
export const BulkClientImportRelatedViews = (self: any) => ({
	get viewBulkClientList() {
		return self.bulkClientList
	},
	get viewDuplicatedInput() {
		let allNamesInSheet: string[] = []
		let allAliasIdsInSheet: string[] = []

		self.bulkClientList.map((item: any) => {
			allNamesInSheet.push(item.name)
			allAliasIdsInSheet.push(item.aliasId)
		})

		let duplicatedItems: DuplicatedClientItemType[] = []
		let duplicatedItemsInSheet: DuplicatedClientItemType[] = []

		self.clientList.map((item: OrgClientModel) => {
			let duplicatedItem: DuplicatedClientItemType = {
				id: "",
				name: "",
				duplicatedName: false,
				aliasId: "",
				duplicatedAliasId: false,
				reqId: "",
				reqName: "",
				reqAliasId: "",
			}
			if (allNamesInSheet.includes(item.clientName)) {
				const reqClientDetails = self.bulkClientList.find(
					(item1: any) => item1.name === item.clientName
				)
				duplicatedItem.id = item.clientId
				duplicatedItem.name = item.clientName
				duplicatedItem.aliasId = item.clientAliasId
				duplicatedItem.reqId = reqClientDetails?.clientId
				duplicatedItem.reqName = reqClientDetails?.name
				duplicatedItem.reqAliasId = reqClientDetails?.aliasId
				duplicatedItem.duplicatedName = true
			}
			if (allAliasIdsInSheet.includes(item.clientAliasId)) {
				const reqClientDetails = self.bulkClientList.find(
					(item1: any) => item1.aliasId === item.clientAliasId
				)
				duplicatedItem.id = item.clientId
				duplicatedItem.name = item.clientName
				duplicatedItem.aliasId = item.clientAliasId
				duplicatedItem.reqId = reqClientDetails?.clientId
				duplicatedItem.reqName = reqClientDetails?.name
				duplicatedItem.reqAliasId = reqClientDetails?.aliasId
				duplicatedItem.duplicatedAliasId = true
			}
			if (
				duplicatedItem.duplicatedName ||
				duplicatedItem.duplicatedAliasId
			) {
				duplicatedItems.push(duplicatedItem)
			}
		})

		self.bulkClientList.map((item: BulkClientImportItemType) => {
			let duplicatedItem: DuplicatedClientItemType = {
				id: "",
				name: "",
				duplicatedName: false,
				aliasId: "",
				duplicatedAliasId: false,
			}
			if (allNamesInSheet.filter((x) => x === item.name).length > 1) {
				duplicatedItem.id = item.clientId
				duplicatedItem.name = item.name
				duplicatedItem.aliasId = item.aliasId
				duplicatedItem.duplicatedName = true
			}
			if (
				allAliasIdsInSheet.filter((x) => x === item.aliasId).length > 1
			) {
				duplicatedItem.id = item.clientId
				duplicatedItem.name = item.name
				duplicatedItem.aliasId = item.aliasId
				duplicatedItem.duplicatedAliasId = true
			}
			if (
				duplicatedItem.duplicatedName ||
				duplicatedItem.duplicatedAliasId
			) {
				duplicatedItemsInSheet.push(duplicatedItem)
			}
		})

		return { duplicatedItems, duplicatedItemsInSheet }
	},
	get viewDuplicatedAliasIdCount() {
		const duplicatedAliasIds = self.bulkClientList.filter((item: any) =>
			self.isDuplicatedAliasId(item.aliasId)
		)
		return duplicatedAliasIds.length
	},
	viewSubmitStatusCount(status: ActionStatus) {
		return self.bulkClientList.filter((item: any) => item.status === status)
			.length
	},
	viewBulkClientListById(clientId: string) {
		return self.bulkClientList.find(
			(item: any) => item.clientId === clientId
		)
	},
})
