import React from "react"
import { observer } from "mobx-react-lite"
import { ConsoleLog } from "../../../../components/basic-elements"
import { PageContainer } from "../../../../components/app-frame-elements"
//
import OrgSetupGroupsTableColumns from "./sub-components/OrgSetupGroupsTableColumns"
import OrgSetupGroupsTableMenus from "./sub-components/OrgSetupGroupsTableMenus"
import {
    AddGroupDialog,
    EditGroupDialog,
    AssignClientsToGroupDialog,
    AssignUsersToGroupDialog,
    RemoveGroupDialog,
    AssignedDetailsDialog,
} from "./dialogs"
import { AddGroupBtn } from "./dialogs/AddGroupDialog"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import styled from "styled-components"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { Table, Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function OrgSetupGroups({
    partialStore,
    fetchingStatus,
    permission,
    contentHeight,
    i18n,
    menuId,
}: {
    partialStore: any
    fetchingStatus: ActionStatus
    permission: PermissionAsObjectProps
    contentHeight: any
    i18n: DLI18nProps
    menuId: DLOrgSubMenus
}) {
    ConsoleLog(" __________ OrgSetupGroups __________ ")
    const { twTotal } = i18n

    const handleContextMenu = (event: any, groupId: string) => {
        event.preventDefault()
        partialStore.setSelectedGroup(groupId)
        partialStore.setClickPoint({
            mouseX: event.clientX - 2,
            mouseY: event.clientY - 4,
        })
    }

    const handleClick = (groupId: string, type: string) => {
        partialStore.setSelectedGroup(groupId)
        partialStore.setSelectedGroupType(type)
        partialStore.setShowAssignedDetailsDialog()
    }

    const pageHeader = (instance: Table<any>) => {
        const totalGroups = instance.getPreFilteredRowModel().flatRows.length
        const filteredGroups = instance.getFilteredRowModel().flatRows.length

        return (
            <div className="FR JSB" style={{ width: "100%" }}>
                <div className="FR AC">
                    <BasicCounter label={twTotal} value={totalGroups} />

                    {totalGroups > filteredGroups && (
                        <div className="FR AC">
                            <span className="partition">|</span>
                            <div className="filtered-count">
                                Filtered Groups : {filteredGroups}
                            </div>
                        </div>
                    )}
                </div>
                <div className="FR">
                    {permission.create && <AddGroupBtn i18n={i18n} />}
                </div>
            </div>
        )
    }

    return (
        <PageContainer
            hasToolbar={false}
            pageTools={<div />}
            fullWidth
            fixedHeight
            isLoading={fetchingStatus === "LOADING"}
        >
            <StyledGroups>
                <ReactTableV8
                    tableColumns={OrgSetupGroupsTableColumns(
                        handleContextMenu,
                        i18n,
                        handleClick
                    )}
                    data={partialStore.viewGroupTable()}
                    hasPagination={true}
                    customFilters={filterComponent}
                    handleContextMenu={(e: any, row: any) =>
                        handleContextMenu(e, row.groupId)
                    }
                    height={contentHeight}
                    permission={permission}
                    i18n={i18n}
                    pageHeader={pageHeader}
                    menuId={menuId}
                />
            </StyledGroups>
            {/* context menus */}
            <OrgSetupGroupsTableMenus i18n={i18n} permission={permission} />
            {/* dialogs  */}
            <AddGroupDialog i18n={i18n} />
            {partialStore.editGroupDialogOpen && (
                <EditGroupDialog i18n={i18n} />
            )}
            {partialStore.assignUsersToGroupDialogOpen && (
                <AssignUsersToGroupDialog i18n={i18n} />
            )}
            {partialStore.assignClientsToGroupDialogOpen && (
                <AssignClientsToGroupDialog i18n={i18n} />
            )}
            {partialStore.removeGroupDialogOpen && (
                <RemoveGroupDialog i18n={i18n} />
            )}
            {partialStore.showAssignedDetailsDialog && (
                <AssignedDetailsDialog partialStore={partialStore} />
            )}
            {/* response snackbar -- can be removed or commented in the future */}
            <CommonSnackbarComponent i18n={i18n} partialStore={partialStore} />
        </PageContainer>
    )
})

const filterComponent = ({
    column,
    table,
}: {
    column: Column<any>
    table: Table<any>
}) => (
    <InputFieldForTableV8
        columnId={column.id}
        onChange={column.setFilterValue}
        value={column.getFilterValue()}
    />
)

const StyledGroups = styled.div`
    padding-left: 1rem;
    padding-right: 1rem;
    .partition {
        margin: 0 1rem;
    }
`
