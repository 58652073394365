import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { getMatchedFileIcon } from "../../../../library/converters/icon-converter"
import styled from "styled-components"
import { DLIconButton, ConsoleLog } from "../../../../components/basic-elements"
import { ApgIcon } from "../../../../components/basic-elements/icons/common-icons"

import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	FileScreenDialog,
	FileUploadFromType,
} from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { apgLibPrefix } from "../store/lib-in-proj.data-model"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { AssignedRoles } from "../../../../components/combined-elements/file-tree/file-row-sub-components"
import { DLTooltip } from "@datalobby/components"
import { DLSrcFormat } from "../../../../common-models/enumerations/common-enums"

export default observer(function TempDrawerFileRow({ node }: { node: any }) {
	const projStore = useProjStore()
	const libStore = projStore.lib
	const currentMenu = libStore.openedOn

	const { id, title, srcFormat, size, aliasId, assignedRoles } = node
	ConsoleLog([" ------ temp node:", JSON.parse(JSON.stringify(node))])

	const dialogName = FileScreenDialog.createFile

	const handleAdd = () => {
		libStore.setSelectedItemId(id)
		if (currentMenu === DLProjSubMenus.wp) {
			projStore.wp.setFileTreeDialogOpen(dialogName)
			projStore.wp.setFileUploadFrom(FileUploadFromType.temp)
		} else if (currentMenu === DLProjSubMenus.pf) {
			projStore.pf.setFileTreeDialogOpen(dialogName)
			projStore.pf.setFileUploadFrom(FileUploadFromType.temp)
		}
	}

	const isApg = id.includes(apgLibPrefix)

	return (
		<StyledFileRow className="temp-drawer-file-row FR AC">
			<DLIconButton
				eleTestId="import-temp-file-to-project"
				eleClassName="import-to-project-btn"
				tooltipText="Import"
				clickHandler={handleAdd}
				color="primary"
			>
				<Icon path={mdiPlus} size={0.8} />
			</DLIconButton>
			<AssignedRoles
				roleList={assignedRoles}
				width={36}
				aliasId={aliasId}
			/>
			<div className="format-icon">
				{isApg ? (
					<ApgIcon />
				) : srcFormat === DLSrcFormat.auditProgram ? (
					srcFormat.split("_").join(" ")
				) : (
					getMatchedFileIcon(srcFormat)
				)}
			</div>
			<span className="alias-id">
				<DLTooltip title={"Ref.Num: " + aliasId}>
					<div
						className="alias-id-prop"
						data-testid={"fileRow-aliasId-of-" + aliasId}
					>
						{aliasId}
					</div>
				</DLTooltip>
			</span>
			<span className="file-name">
				<DLTooltip title={title}>
					<div className="file-name-prop">{title}</div>
				</DLTooltip>
			</span>
			<span className="size">{size}</span>
			{/* TODO: NOAH {node.fileType === DLObjectFormat.apg && "(APG)"} {node.title} */}
		</StyledFileRow>
	)
})

const StyledFileRow = styled.div`
	&.temp-drawer-file-row {
		font-size: 0.7rem;
		.import-to-project-btn {
			opacity: 1;
			width: ${(props) => props.theme.shared.mediumUnit};
			margin-right: 0.6rem;
		}
		.alias-id {
			width: 80px;
			overflow: hidden;
			margin-left: 5px;
			text-wrap: wrap;
		}
		.file-name {
			width: 280px;
			overflow: hidden;
			margin-left: 5px;
			text-wrap: wrap;
		}
		.format-icon {
			text-wrap: wrap;
			width: 60px;
		}
	}
`
