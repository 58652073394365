// NOTE: Project Type = audit, sox, ... etc
// NOTE: Project status = todo, work in progress, done(archived),  replica, unarchived... etc
// WARNING: This part will be updated again at next Monday.. maybe as .. Phase?
export enum ProjectStatus {
	normal = "Normal",
	// TODO: Normal Locked should be removed
	normalLocked = "NormalLocked", // this part should be removed
	replica = "Replica",
	archived = "Archived",
	unarchived = "Unarchived",
	roamArchived = "ROAMArchived",
	forceArchived = "ForceArchived",
	unknown = "Unknown",
}

export enum ProjectStatusColor {
	unarchived = "#B20000",
	late = "#ff5815",
	urgent = "#ffa500",
	timely = "#6592f0", // timely or archived
	replica = "#32475d",
	normal = "#e3e3e3",
	undefined = "gray",
}
