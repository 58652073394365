import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"

import { UploadProjFileProps } from "../../data-models/file.data-props"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

/**
 *
 * CreationMode: 1
 * WPEF: refNumName
 * MediaType: Excel
 * WPDesc: FileTitle
 * FinancialYear: 18-Mar-20
 * ParentMediaID: 286608
 * file: (binary)
 * SignOffType: [1]
 *
 */

const RequestFileUpload = (
	self: any,
	createFile: FileSystemApiTypes["apiCreateFile"]
) => ({
	requestFileUpload: flow(function* ({
		projId,
		// userId,
		fileInfo,
	}: {
		projId: string
		// userId: string
		fileInfo: UploadProjFileProps
	}) {
		const actionName = "requestFileUpload"
		const menuId = self.storeName
		const {
			parentId,
			aliasId,
			title,
			sourceType,
			// extension,
			prepared,
			reviewed,
			file,
		} = fileInfo

		//
		// 1.
		if (
			projId === undefined ||
			projId === "" ||
			// userId === undefined ||
			// userId === "" ||
			fileInfo === undefined
		) {
			alert(`(${actionName}) One of the props is undefined.`)
			ConsoleLog([
				">> undefined props exist:",
				actionName,
				projId,
				// userId,
				fileInfo,
				menuId,
			])
			return
		}
		if (
			parentId === undefined ||
			parentId === "" ||
			aliasId === undefined ||
			aliasId === "" ||
			title === undefined ||
			title === "" ||
			sourceType === undefined
		) {
			alert(`(${actionName}) One of the fileInfo is undefined.`)
			ConsoleLog([
				actionName,
				">> undefined file info exist:",
				parentId,
				aliasId,
				title,
				sourceType,
			])
			return
		}
		//
		//
		// organize parameter
		let projNumberId: any = ""
		if (menuId === DLOrgSubMenus.proj_templates) {
			projNumberId = idToNumber(projId, IdType.projTemplate)
		} else {
			projNumberId = idToNumber(projId, IdType.project)
		}
		const parentNumberId = idToNumber(parentId, IdType.folder)
		// const userNumberId = idToNumber(userId, IdType.user)

		let signOffs: number[] = []
		prepared && signOffs.push(1)
		reviewed && signOffs.push(2)
		//
		// 2.
		if (
			typeof projNumberId === "number" &&
			typeof parentNumberId === "number"
			// typeof userNumberId === "number"
		) {
			//
			const formData = new FormData()
			if (menuId !== DLOrgSubMenus.proj_templates) {
				formData.append("CreationMode", "1") // NOTE: 1: normal upload, 2: DnD upload, 3: create file
				formData.append("ClientID", projNumberId.toString())
				formData.append("ParentMediaID", parentNumberId.toString())
				//
				formData.append("WPEF", aliasId)
				formData.append("WPDesc", title)
				formData.append("MediaType", sourceType)
				formData.append("SignOffType", JSON.stringify(signOffs))
				formData.append("file", file)
				// formData.append("UserID", userNumberId.toString())
			} else {
				formData.append("CreationMode", "1") // NOTE: 1: normal upload, 2: DnD upload, 3: create file
				formData.append("TemplateId", projNumberId.toString())
				formData.append("ParentTempFoldID", parentNumberId.toString())
				//
				formData.append("WPREF", aliasId)
				formData.append("TempName", title)
				formData.append("MediaType", sourceType)
				formData.append("file", file)
			}

			//
			try {
				const response = yield createFile({ formData, menuId }) // NOTE: create and upload use same API
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })
				return false
			}
		} else {
			alert(`(${actionName}) ${projNumberId} | ${parentNumberId}`)
			ConsoleLog(
				[
					actionName,
					"Fail to id converting",
					projNumberId,
					parentNumberId,
				]
				// userId
			)
		}
	}),
})

export default RequestFileUpload
