import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
	Refresh,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./reports-management.provider"
import { DLWorkspaceModel } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import GetOnlineProjects from "./view-model-actions/get-online-projects"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { getTzDate } from "../../../../../library/converters/date-utc-converter"
import ProjectTableFilterViews from "../../../../../service-modules/project-module/view-model-views/table-filter-views"
import { SharedProjTablePageSettingModel } from "../../../../../components/combined-elements/projects-table/proj-page-setting.data-model"
import {
	CommonSelectedItem,
	SelectedItemsSharedActions,
	SelectedItemsSharedViews,
} from "../../../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
import { GetAllFiles } from "../../../../../service-modules/file-module/view-model-actions/file"

import GetChecklists from "./view-model-actions/get-checklists"
import {
	ChecklistItemModel2,
	ChecklistModel,
} from "./data-models/checklist-data-models"
import AddChecklist from "./view-model-actions/addChecklist"
import GetChecklistCollections from "./view-model-actions/get-checklist-collections"

export const ChecklistDetailModel = types.model({
	checklistInfo: ChecklistModel,
	items: types.array(ChecklistItemModel2),
})

const ChecklistCollectionModel = types.model({
	projTypeId: types.string,
	templates: types.array(
		types.model({
			id: types.string,
			name: types.string,
		})
	)
})

const ReportsManagementViewModel = types
	.model({
		onlineProjects: types.array(DLWorkspaceModel), // previous: finalReportsProjectList + DLProjectModel
		//
		checklists: types.array(ChecklistModel),
		checklistDetail: types.union(ChecklistDetailModel, types.null), // for the checklist dialog
		checklistCollections: types.array(ChecklistCollectionModel),

		// dialogs
		openChecklistDialog: false,
		openFileSelectionDialog: false,
		openAddChecklistDialog: false,
		tablePageSettingDialog: false,
		//
		clickPoint: ClickPoint,
		// selectedItems: types.array(CommonSelectedItem),
		selectedProjId: "",
		//
		tableColumnsPageSetting: types.array(SharedProjTablePageSettingModel),
		// ----- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setOnlineProjects(projects: any) {
			self.onlineProjects.length = 0
			self.onlineProjects = projects
		},
		setChecklists(checklists: any) {
			self.checklists.length = 0
			self.checklists = checklists
		},
		pushItemToChecklist(checklist: any){
			self.checklists.push(checklist)
		},
		setChecklistDetail(detail: any) {
			self.checklistDetail = detail
		},
		setChecklistCollections(collection: any) {
			self.checklistCollections = collection
		},
		setTableColumnsPageSetting(list: any) {
			self.tableColumnsPageSetting = list
		},
		setOpenChecklistDialog(request?: boolean) {
			self.openChecklistDialog = request || !self.openChecklistDialog
		},
		setOpenAddChecklistDialog(request?: boolean) {
			self.openAddChecklistDialog =
				request || !self.openAddChecklistDialog
		},
		setOpenFileSelectionDialog(request?: boolean) {
			self.openFileSelectionDialog =
				request || !self.openFileSelectionDialog
		},
		setTablePageSettingDialog(request?: boolean) {
			self.tablePageSettingDialog =
				request || !self.tablePageSettingDialog
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},

		setSelectedProjId(projId: string) {
			self.selectedProjId = projId
		},
		// updateOnlineProjects(checklists: any) {
		// 	console.log("updateOnlineProjects - checklists", checklists)
		// 	checklists.map((c: any) => {
		// 		const wsId = "projId" + c.workspaceId.slice(2) // workspaceId ex) ws7813
		// 		const project = self.onlineProjects.find(
		// 			(proj) => proj.id === wsId
		// 		)
		// 		const projIndex = self.onlineProjects.findIndex(
		// 			(proj) => proj.id === wsId
		// 		)
		// 		if (project && projIndex !== -1) {
		// 			self.onlineProjects.splice(projIndex, 1)
		// 			self.onlineProjects.push({
		// 				...project,
		// 				checklistId: c.id,
		// 			})
		// 		}
		// 	})
		// },
	}))
	.actions(GetOnlineProjects)
	.actions(GetChecklists)
	.actions(GetChecklistCollections)
	.actions(GetAllFiles)
	.actions(AddChecklist)
	.views(ProjectTableFilterViews)
	.views((self) => ({
		getProjLengthOfYear(yearFilter: any, type: any) {
			if (yearFilter === "All Years") {
				return self.onlineProjects.length
			} else {
				const year = parseInt(yearFilter)
				return self.onlineProjects.filter(
					(proj: any) => proj.financialYear === year
				).length
			}
		},
		getSelectedChecklistId() {
			const projId = self.selectedProjId
			const wsId = "ws" + projId.slice(6)
			const checklist = self.checklists.find(
				(c) => c.workspaceId === wsId
			)
			return checklist ? checklist.id : null
		},
		// for online projects
		formattedProjList(dntFormat: DnTFormatProps) {
			const { dateFormat, timeFormat, timeZone } = dntFormat
			let selectedProj = self.onlineProjects

			let dateFormattedList: any[] = []

			function formatDate(date: string | null) {
				if (date) {
					return getTzDate({
						date,
						timeZone,
						dateFormat,
					})
				}
				return ""
			}
			selectedProj.map((project: any) => {
				const formattedPeriodEndDate = formatDate(project.periodEndDate)
				const formattedLastAccessAt = formatDate(project.lastAccessAt)
				const formattedCreatedAt = formatDate(project.createdAt)
				//
				const formattedArchivedAt = formatDate(project.archivedAt)
				const formattedFinalReportDate = formatDate(
					project.finalReportDate
				)
				const formattedExpectedReportDate = formatDate(
					project.expectedReportDate
				)
				const formattedExpectedArchiveDate = formatDate(
					project.expectedArchiveDate
				)
				const formattedFinalArchiveDeadlineDate = formatDate(
					project.finalArchiveDeadlineDate
				)

				const wsIdForCdk = "ws" + project.id.slice(6)
				// console.log("wsIdForCdk:", wsIdForCdk)
				const checklist = self.checklists.find(
					(c) => c.workspaceId === wsIdForCdk
				)
				// checklist && console.log("find checklist:", checklist)

				dateFormattedList.push({
					...project,
					//
					checklistId: checklist ? checklist.id : null,
					//
					archivedAt: formattedArchivedAt,
					periodEndDate: formattedPeriodEndDate,
					lastAccessAt: formattedLastAccessAt,
					finalReportDate: formattedFinalReportDate, // TODO: variable name need update
					expectedReportDate: formattedExpectedReportDate,
					finalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
					expectedArchiveDate: formattedExpectedArchiveDate,
					createdAt: formattedCreatedAt,
				})
			})

			return dateFormattedList
		},
		getTablePageSettingColumn(column: string) {
			return self.tableColumnsPageSetting.find(
				(item) => item.aliasName === column
			)?.show
		},
		viewSelectedProj() {
			const info = self.onlineProjects.find(
				(project: any) => project.id === self.selectedProjId
			)

			return info
		},
		forExport(dntFormat: DnTFormatProps, filteredData?: any) {
			const { dateFormat, timeFormat, timeZone } = dntFormat

			let defaultList: any = self.onlineProjects
			let formattedList: any[] = []

			const isAppAdminUser = JSON.parse(
				localStorage.getItem("isAdminAppUser") || "false"
			)

			if (filteredData) {
				defaultList = filteredData
			}

			function formatDate(date: string | null) {
				if (!date) return "-"
				return getTzDate({
					date,
					timeZone,
					dateFormat,
				})
			}

			defaultList.map((project: any) => {
				/**
				 * Required date information
				 * 1. Period end date
				 * 2. Expected report date
				 * 3. Expected archive deadline date
				 * 4. Final reprot date
				 * 5. Final archive deadline date
				 */
				const formattedPeriodEndDate = formatDate(project.periodEndDate)
				const formattedExpectedReportDate = formatDate(
					project.expectedReportDate
				)
				const formattedExpectedArchiveDeadlineDate = formatDate(
					project.expectedArchiveDate
				)
				const formattedFinalReportDate = formatDate(
					project.finalReportDate
				)
				const formattedFinalArchiveDeadlineDate = formatDate(
					project.finalArchiveDeadlineDate
				)
				// --------------------------------------
				const formattedLastAccessAt = formatDate(project.lastAccessAt)

				// --------------------------------------
				const qcUsers = project.qcUsers
					? project.qcUsers
							.map((user: any) => {
								return user.name
							})
							.join(",")
					: "-"
				const epUsers = project.epUsers
					? project.epUsers
							.map((user: any) => {
								return user.name
							})
							.join(",")
					: "-"
				// --------------------------------------
				let formattedProject: any = {
					EngagementType: project.engTypeName,
					Group: project.groupName,
					ProjectTitle: project.title,
					ProjectId: project.aliasId,
					Version: project.version,
					// createdBy: project.createdBy,
					Client: project.clientName,
					EngagementPartner: epUsers,
					QualityControl: qcUsers,
					FinancialYear: project.financialYear,
					PeriodName: project.periodName,
					// ----- common date informations
					PeriodEndDate: formattedPeriodEndDate,
					ExpectedReportDate: formattedExpectedReportDate,
					ExpectedArchiveDeadlineDate:
						formattedExpectedArchiveDeadlineDate,
					FinalReportDate: formattedFinalReportDate,
					FinalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
					// -------------------------
					IsLocked: project.isLocked,
					IsArchived: project.isArchived,
					LastAccessAt: formattedLastAccessAt,
					LastAccessBy: project.lastAccessBy?.name,
				}

				if (isAppAdminUser) {
					formattedProject = {
						...formattedProject,
						projectId: project.id,
					}
				}
				formattedList.push(formattedProject)
			})

			return formattedList
		},
		getConnectedChecklistByProjId(projTypeId: string){
			const checklist = self.checklistCollections.find((item:any) => item.projTypeId === projTypeId)
			if(checklist){
				return checklist.templates.map((item:any) => ({...item, selected: false}))
			}
		}
	}))
	// .actions(SelectedItemsSharedActions)
	// .views(SelectedItemsSharedViews)
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupSearch")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ReportsManagementViewModel
