import {
    ActionStatus,
    MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
    IdType,
    idToString,
} from "../../../../../library/converters/id-converter"

const EditArchiveChecklist = (self: any) => ({
    editArchiveChecklist(
        archiveChecklistItem1: string,
        archiveChecklistItem2: string,
        handleResponse: any
    ) {
        // TODO: any is temporary setting. Must update
        // 0.
        const actionName = "editArchiveChecklist"
        // 1.
        // 2.
        self.responses.setResponse(actionName, {
            actionName,
            status: ActionStatus.loading,
        })
        // 3.
        const payload = {
            ArchiveChecklistItem1: archiveChecklistItem1,
            ArchiveChecklistItem2: archiveChecklistItem2,
        }
        self.updateArchiveChecklist(payload)
            .then((response: any) => {
                if (response.status === 200 && response.data.Status === 1) {
                    // set success case response

                    const updatedData = {
                        archiveChecklistItem1:
                            response.data.ArchiveChecklistItem1,
                        archiveChecklistItem2:
                            response.data.ArchiveChecklistItem2,
                        archiveChecklistLastUpdatedDate:
                            response.data.LastUpdatedDate,
                        archiveChecklistLastUpdatedByUserAlias:
                            response.data.UserAlias,
                        archiveChecklistLastUpdatedBy: idToString(
                            response.data.UserId,
                            IdType.user
                        ),
                        archiveChecklistLastUpdatedByUserName:
                            response.data.UserName,
                    }

                    self.updateChecklistInfo(updatedData)
                    handleResponse({
                        actionName,
                        status: ActionStatus.success,
                        code: 200,
                        color: MessageColorType.blue,
                        customMessage: response.data.Message,
                        open: true,
                        // autoHide: true,
                    })
                } else {
                    // set fail case response
                    handleResponse({
                        actionName,
                        status: ActionStatus.fail,
                        code: 999,
                        color: MessageColorType.orange,
                        message: response.data.Message,
                        // customMessage:
                        // 	"Something Wrong... (UPDATE IS REQUIRED)",
                        open: true,
                        autoHide: false,
                    })
                }
            })
            .catch((error: Error) => {
                self.handleViewModelError({
                    error,
                    actionName,
                    open: false,
                })
            })
    },
})

export default EditArchiveChecklist
