import { types } from "mobx-state-tree"

export const UserForExport2 = types.model({
	groupName: types.string, // [GroupName], ex) "Prepare and Review"
	clientName: types.string, // [ClientName], ex) "ABCDEFGH"
	clientAliasId: types.string,
	// aliasId: types.string, // [ProjectBusinessId], ex) "" --> is null possible..?
	title: types.string, // [ProjectName], ex) "57yth"
	engTypeName: types.string, // [EngagementType], ex) "CUULWRRKSC"
	// raName: types.string,
	statusName: types.string,
	periodName: types.string, // [PeriodName], ex) "Full year"
	version: types.string, // [Version], ex) 0
	// isArchived: types.boolean, // [Archive], ex) false
	// isLocked: types.boolean, // [IsLocked], ex) false
	financialYear: types.integer, // [Year], ex) 2019
	periodEndDate: types.string, // [FinancialYear], ex) "10/23/2019"
	// finalReportDate: types.union(types.null, types.string), // [ReportIssuranceDate], ex) "" // is this final report date?
	// createdBy: types.union(types.null, SimpleUserModel),
	// createdAt: types.string,
	// lastAccessBy: types.union(types.null, types.undefined, types.string), // [LastAccessedBy], ex) "cskim 2019-08-07 09:51:19.972355"
	// lastAccessAt: types.union(types.null, types.undefined, types.string),
	// archOrUnArchBy: types.union(types.null, types.undefined, types.string), // [ArchivedBy], ex) 0
	// archOrUnArchAt: types.union(types.null, types.undefined, types.string), // [ProjectArchivedDate], ex) ""
	epUsers: types.string, // [EpUsers], ex) ""
	qcUsers: types.string, // [QCUsers], ex) ""
	//
	userName: types.string,
	userAliasId: types.string,
	email: types.string,
	userRole: types.string,
	userAccessRight: types.string,
	userType: types.string,
	isGrayedOut: types.boolean,
	isStrikedOut: types.boolean,
})
