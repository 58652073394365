import { flow } from "mobx-state-tree"
import { LobbyCheckinProps } from "../lobby-access-control.apis"

const RequestLogout = (self: any, logout: LobbyCheckinProps["apiLogout"]) => ({
	requestLogout: flow(function* (userEmail: string) {
		const actionName = "requestLogout"
		try {
			const response = yield logout(userEmail)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestLogout
