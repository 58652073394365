import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import EliminatingEntry from "./EliminatingEntry"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function EliminatingEntryController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.fs_ee
	const actionName = "getEliminatingEntryDetails"

	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	const needRefresh = projStore.eliminatingEntry.needRefresh

	useEffect(() => {
		const projId = projStore.checkin.projId
		menuAccess &&
			needRefresh &&
			projStore.eliminatingEntry.getEliminatingEntryDetails(projId)
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<EliminatingEntry
					partialStore={projStore.eliminatingEntry}
					contentsHeight={store.ui.contentsAreaHeight}
					fetchingStatus={projStore.eliminatingEntry.getActionStatus(
						actionName
					)}
					permission={permission}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
