import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ChecklistRecordType } from "@datalobby/types"
import { ReadChecklistsProps } from "../reports-management.apis"

// NOTE: org-setup-checklist has similar case to get the checklist templates
const GetChecklists = (self: any) => ({
	getChecklists(params: ReadChecklistsProps) {
		const actionName = "getChecklists"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readChecklists(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					const data = response.data

					if (params.id) {
						// *  NOTE: ----- individual tmeplate detail -----
						let detail: any = {
							checklistInfo: {},
							items: [],
						}
						data.map((d: any) => {
							if (d.type === ChecklistRecordType.list) {
								detail.checklistInfo = d
								// detail.checklistInfo = {
								// 	// common parts
								// 	id: d.id,
								// 	orgId: d.orgId,
								// 	description: d.description,
								// 	parentId: d.parentId,
								// 	name: d.name,
								// 	type: d.type,
								// 	lastModifiedAt: d.lastModifiedAt || "",
								// 	lastModifiedBy: d.lastModifiedBy || "",
								// 	assignedRoles: d.assignedRoles
								// 		? d.assignedRoles
								// 		: null,
								// 	assignedUsers: d.assignedUsers
								// 		? d.assignedUsers
								// 		: null,
								// 	createdAt: d.createdAt,
								// 	createdBy: d.createdBy,
								// 	mustInOrder: d.mustInOrder,
								// 	// specific for list
								// 	status: d.status,
								// 	templateId: d.templateId,
								// 	projectId: d.projectId,
								// 	workspaceId: d.workspaceId,
								// 	isEditingBy: d.isEditingBy,
								// }
							} else if (d.type === ChecklistRecordType.item) {
								detail.items.push(d)
								// detail.items.push({
								// 	assignedRoles: d.assignedRoles
								// 		? d.assignedRoles
								// 		: null,
								// 	id: d.id,
								// 	name: d.name,
								// 	order: d.order,
								// 	requirements: d.requirements,
								// })
							} else {
								console.log(
									"TEMPLATE cannot exist on this response. Please check the database.",
									d
								)
							}
						})
						console.log("detail:", detail)
						self.setChecklistDetail(detail)
					} else {
						// * NOTE: ----- When get multiple checklists -----
						self.setChecklists(data)

						// self.updateOnlineProjects(data)
					}
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Successfully fetched the checklists",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage: "Something's Wrong... ",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetChecklists
