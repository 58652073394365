export const ReportManagementInitialPageSetting = [
	{
		displayName: "Status",
		aliasName: "statusName",
		show: true,
	},
	{
		displayName: "Period Name",
		aliasName: "periodName",
		show: true,
	},
	{
		displayName: "Financial Year",
		aliasName: "financialYear",
		show: true,
	},
	{
		displayName: "Group Name",
		aliasName: "groupName",
		show: true,
	},
	{
		displayName: "Client Name",
		aliasName: "clientName",
		show: true,
	},
	{
		displayName: "Report",
		aliasName: "report",
		show: true,
	},
	{
		displayName: "Checklist",
		aliasName: "checklist",
		show: true,
	},
	{
		displayName: "Project Name",
		aliasName: "title",
		show: true,
	},
	{
		displayName: "Alias Id",
		aliasName: "aliasId",
		show: true,
	},
	{
		displayName: "Version",
		aliasName: "version",
		show: true,
	},
	{
		displayName: "EP Users",
		aliasName: "epUsers",
		show: true,
	},
	{
		displayName: "QC Users",
		aliasName: "qcUsers",
		show: true,
	},
	{
		displayName: "Eng Type",
		aliasName: "engTypeName",
		show: true,
	},
	{
		displayName: "Period End Date",
		aliasName: "periodEndDate",
		show: true,
	},
	{
		displayName: "Final Report Date",
		aliasName: "finalReportDate",
		show: true,
	},
	{
		displayName: "Final Archive Deadline Date",
		aliasName: "finalArchiveDeadlineDate",
		show: true,
	},
	{
		displayName: "Expected Report Date",
		aliasName: "expectedReportDate",
		show: true,
	},
	{
		displayName: "Expected Archive Date", // Expected Archive Deadline date
		aliasName: "expectedArchiveDate",
		show: true,
	},
	{
		displayName: "Last Accessed At",
		aliasName: "lastAccessAt",
		show: true,
	},
	{
		displayName: "Last Accessed By",
		aliasName: "lastAccessBy",
		show: true,
	},
	{
		displayName: "Created At",
		aliasName: "createdAt",
		show: true,
	},
	{
		displayName: "Created By",
		aliasName: "createdBy",
		show: true,
	},
]
