import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { convertProjStatusAsEnum } from "../../../../../../library/get-project-status"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetArchiveHistoryList = (self: any) => ({
	getArchiveHistoryList() {
		// 2) set actionStatus to Loading
		const actionName = "getArchiveHistory"
		self.responses.putResponse({ actionName, status: ActionStatus.loading })
		// 3) request API call to Model
		self.readArchiveHistoryList()
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// 1) initialize stored list
					self.resetList()
					// 2) re-organize returned data from the server
					const existingArchiveHistoryList =
						response.data.ArchiveUnArchive
					existingArchiveHistoryList.map((historyItem: any) => {
						// 2-1) prepare the file list
						let reOrganizedfileList: any[] = []
						historyItem.filesLists.map((file: any) => {
							const reOrganizedFile = {
								file: file.File, // [File] ex) null
								type: file.FileType, // [FileType] ex) null
								name: file.FileName, // [FileName] ex) "DevExpress Tickets - Summary"
								order: file.Order, // [Order] ex) 1
								preSignedUrl: file.PreSignedURL, // [PreSignedURL] ex) ""
								archiveDataObj: file.ArchiveDataObj, // [ArchiveDataObj] ex) null
								filePath: file.filepath, // [filepath] ex) null
							}
							reOrganizedfileList.push(reOrganizedFile)
						})
						// 2-2) re-organize the data
						const reOrganizedHistoryItem = {
							type: historyItem.Type, // [Type] ex) "UnArchive v4 "
							archivedUnArchivedBy:
								historyItem.ArchivedUnArchivedBy, // [ArchivedUnArchivedBy] ex) "vanitha"
							archiveId: idToString(
								historyItem.ArchiveId,
								IdType.project
							), // [ArchiveId] ex) 1043
							archivedUnArchivedAt:
								historyItem.ArchivedUnArchivedDate, // [ArchivedUnArchivedDate] ex) "2020-01-10T10:05:55.978712+00:00"
							sourceProjectId: historyItem.SourceProjectID
								? idToString(
										historyItem.SourceProjectID,
										IdType.project
								  )
								: "", // [SourceProjectID] ex) 3464
							sourceProjectType: historyItem.Type, // [SourceProjectType] ex) "Archived"
							sourceProjectName: historyItem.SourceProjectName, // [SourceProjectName] ex) "Full year 2020 rationale : Archive - (test) (Archive v4)"
							isDisabled: historyItem.IsGrayOut, // [IsGrayOut] ex)  true
							reason: historyItem.Reason, // [Reason] ex)  "8281B833678782537936A8EF1AFE12FC5AC4C7E347E99BD57F884428A582F76C8281B833678782537936A8EF1AFE12FC5AC4C7E347E99BD57F884428A582F76C8281B833678782537936A8EF1AFE12FC5AC4C7E347E99BD57F884428A582F76C"
							fileList: reOrganizedfileList, // [filesLists] ex)  [,…]
							clientId: historyItem.ClientID.toString(), // [ClientID] ex) 1043
						}
						// 2-3) push the item to the list
						self.pushItemToList(reOrganizedHistoryItem)
					})
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
				//
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetArchiveHistoryList
