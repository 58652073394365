import React, { useEffect } from "react"
import Checklist from "./Checklist"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import mockData from "./store/mockData.json"
import { observer } from "mobx-react-lite"

export default observer(function ChecklistController() {
	// const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.users
	//
	const needRefresh = projStore.checklist.needRefresh
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(menuId)
	
	const orgIdForCdk = "org" + localStorage.getItem('orgId')?.slice(5)
	useEffect(() => {
		// projStore.checklist.getChecklists({ orgId: orgIdForCdk })
		projStore.checklist.getChecklistTemplates(orgIdForCdk)
	},[])
	
	return (
		<>
			{menuAccess ? (
				<Checklist 
					partialStore={projStore.checklist} 
					actionStatus={projStore.checklist.getActionStatus("getChecklists")}	
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
