import React from "react"
// flag images
import US_flag from "../../../../assets/images/nation_flags/nation_flag_US_400w.png"
import UK_flag from "../../../../assets/images/nation_flags/nation_flag_UK_400w.png"
import IN_flag from "../../../../assets/images/nation_flags/nation_flag_IN_400w.png"
import KR_flag from "../../../../assets/images/nation_flags/nation_flag_KR_400w.png"
// styles
import styled from "styled-components"

export const US = () => (
	<StyledLanguageIcon>
		<img src={US_flag} alt="language icon for US" />
	</StyledLanguageIcon>
)

export const UK = () => (
	<StyledLanguageIcon>
		<img src={UK_flag} alt="language icon for UK" />
	</StyledLanguageIcon>
)

export const IN = () => (
	<StyledLanguageIcon>
		<img src={IN_flag} alt="language icon for IN" />
	</StyledLanguageIcon>
)

export const KR = () => (
	<StyledLanguageIcon>
		<img src={KR_flag} alt="language icon for KR" />
	</StyledLanguageIcon>
)

const StyledLanguageIcon = styled.div`
	display: flex;
	align-items: center;
	margin-left: 0.4rem;
	img {
		width: 1.6rem;
	}
`
