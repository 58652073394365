import { flow } from "mobx-state-tree"

const ReadArchiveHistoryList = (self: any, read: any) => ({
	readArchiveHistoryList: flow(function*() {
		const actionName = "readArchiveHistory"
		try {
			const response = yield read()

			// ConsoleLog("readArchiveHistoryList response", response)
			return response
		} catch (error) {
			// ConsoleLog("readArchiveHistoryList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return error
		}
	}),
})

export default ReadArchiveHistoryList
