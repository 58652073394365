import { flow } from "mobx-state-tree"

const CreateAdjustment = (self: any, create: any) => ({
	createAdjustment: flow(function*(payload: {
		AdjDescription: string
		AdjType: string
		// CabinetId: number
		ClientId: number
		// FinancialYear: string
		TBID: number
		// UserID: number
		WorkPaperReference: string
	}) {
		const actionName = "createAdjustment"
		try {
			const response = yield create(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default CreateAdjustment
