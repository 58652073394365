import React, { ReactNode, useEffect } from "react"
import Dialog from "@material-ui/core/Dialog"
import DialogActions from "@material-ui/core/DialogActions"
import DialogContent from "@material-ui/core/DialogContent"
import DialogTitle from "@material-ui/core/DialogTitle"
import DLButton from "../buttons/DLButton"

import styled from "styled-components"
import Paper, { PaperProps } from "@material-ui/core/Paper"
import Draggable from "react-draggable"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import { DLIconButton } from "../buttons"
import keyboardJS from "keyboardjs"

interface DialogProps {
	openBtn: ReactNode
	closeBtnText?: string // cancel button text
	dialogHeaderText: string // dialog title
	dialogContents: ReactNode
	actionBtnText?: string // action Btn
	actionHandler?: any // handle action
	maxWidth?: WidthTypes
	fullWidth?: boolean
	showCloseBtn?: boolean // showCloseBtn
	allowAction?: boolean // actionReady
	// belows: different with dialog
	dialogId?: string
	additionalCloseAction?: React.EventHandler<any>
}

type WidthTypes = "xs" | "sm" | "md" | "lg" | "xl"

function PaperComponent(props: PaperProps) {
	return (
		<Draggable cancel={'[class*="MuiDialogContent-root"]'}>
			<Paper {...props} />
		</Draggable>
	)
}

/**
 * Will be deprecated or turn to 'draggable' dialog
 * <br> or update 'DLDialog' to get draggable property
 */

export default function DLBasicDialog({
	openBtn,
	closeBtnText,
	dialogId,
	dialogHeaderText,
	dialogContents,
	actionBtnText,
	actionHandler,
	additionalCloseAction,
	maxWidth,
	fullWidth,
	showCloseBtn,
	allowAction,
}: DialogProps) {
	const [open, setOpen] = React.useState(false)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	const handleClickOpen = () => {
		// seems not be used
		keyboardJS.pause()
		setOpen(true)
	}

	const handleClose = (event: any) => {
		keyboardJS.resume()
		additionalCloseAction && additionalCloseAction(event)
		setOpen(false)
	}

	const handleAction = (event: any) => {
		if (event) {
			actionHandler(event)
			setOpen(false)
			keyboardJS.resume()
		}
		// setOpen(false)
	}

	return (
		<>
			<div className="open-btn" onClick={handleClickOpen}>
				{openBtn}
			</div>
			<Dialog
				id={dialogId}
				open={open}
				// open={isCompleted ? false : open}
				onClose={(e: any) => {
					keyboardJS.resume()
					handleClose(e)
				}}
				PaperComponent={PaperComponent}
				aria-labelledby="draggable-dialog-title"
				className="dialog-container"
				maxWidth={maxWidth}
				fullWidth={fullWidth}
			>
				<StyledForBgColor>
					<DialogTitle style={{ cursor: "move" }}>
						<div className="FR AC JSB">
							{dialogHeaderText}{" "}
							{showCloseBtn && (
								<DLIconButton
									clickHandler={handleClose}
									eleTestId="dialog-close-btn"
									// style={{ cursor: "pointer", zIndex: 999 }}
								>
									<Icon path={mdiClose} size={1} />
								</DLIconButton>
							)}
						</div>
					</DialogTitle>
					<DialogContent className="dialog-content">
						{dialogContents}
					</DialogContent>

					<DialogActions
						style={{
							justifyContent: "space-between",
							paddingBottom: "1rem",
						}}
					>
						{closeBtnText && (
							<DLButton
								variant="text"
								eleTestId="adcv"
								clickHandler={handleClose}
							>
								{closeBtnText}
							</DLButton>
						)}

						{actionBtnText && (
							<DLButton
								eleTestId="adcv"
								clickHandler={handleAction}
								color="primary"
								disabled={!allowAction}
							>
								{actionBtnText}
							</DLButton>
						)}
					</DialogActions>
				</StyledForBgColor>
			</Dialog>
		</>
	)
}

const StyledForBgColor = styled.div`
	background-color: ${(props) => props.theme.background};
	color: ${(props) => props.theme.textMediumEmphasis};
`
