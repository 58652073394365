import {
	ProgressStatus,
	DLObjectFormat,
	DLSrcFormat,
} from "../../../common-models/enumerations/common-enums"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export const initialDLFileAsFolder = {
	// WARNING: Do not add file related values
	id: "",
	parentId: null,
	isParent: true,
	//
	//
	//
	aliasId: "",
	title: "",
	menuId: DLProjSubMenus.wp,
	url: null,
	objectFormat: DLObjectFormat.folder,
	//
	status: ProgressStatus.done,
	qcCommentStatus: CommentStatus.notExist, // [Followup] ex) 1 --> 1 means no comments
	reviewCommentStatus: CommentStatus.notExist, // [Comments] ex) 1 --> 2 means this pod has comments
	//
	cpReviewList: [], // [ConcurringPartner] ex) []
	qcReviewList: [], // [QualityControl] ex) []
	epReviewList: [], // [EngagementPartner] ex) []
	prepareList: [], // [PreparerList] ex) []
	reviewList: [], // [ReviewerList] ex) []

	isPrepared: false,
	isReviewed: false,

	lastModifiedBy: {
		userAliasId: "",
		userName: "",
		userId: "",
		//
		roleId: "",
		roleName: "",
	}, // [LastModifiedBy] ex) {roleName: null, userName: null, Date: null}
	lastModifiedAt: "",

	srcId: "", // [MediaID] ex) 18757
	srcFormat: DLSrcFormat.unknown, // [MediaType] ex) "Excel"
	size: "",
	extension: "",
	path: "",
	uploadStatus: "",

	//
	isOpen: false, // file related part
	openedByName: null, // file related part
	//
	assignedRoles: [], // file related part
	//
	expanded: false, // file related part
	index: 0, // file related part
	//
	//
	//
	// extension:

	//
	signOffLock: false,
	signOffLockModifiedDate: "",
	signOffLockModifiedBy: {
		userAliasId: "",
		userName: "",
		userId: "",
		//
		roleId: "",
		roleName: "",
	},
	//
	//
	//
	//

	//
	//
	// lastModifiedBy:
	// lastModifiedAt:
}

export const initialDLFile = {
	id: "",
	parentId: "",
	isParent: false,
	//
	//
	//
	aliasId: "",
	title: "",
	menuId: DLProjSubMenus.wp,
	objectFormat: DLObjectFormat.normalFile,
	//
	srcId: "", // [MediaID] ex) 18757
	srcFormat: DLSrcFormat.unknown, // [MediaType] ex) "Excel"
	size: "",
	extension: "",
	url: null,
	path: "",
	uploadStatus: "",
	//
	isOpen: false, // [IsOpen] ex) false
	openedByName: null, // [OpenedBy] ex) null
	//
	assignedRoles: [], // [RolesList] ex) []
	//
	expanded: false,
	index: 0,
	//
	//
	signOffLock: false,
	signOffLockModifiedDate: "",
	signOffLockModifiedBy: {
		userAliasId: "",
		userName: "",
		userId: "",
		//
		roleId: "",
		roleName: "",
	},
	//
	//
	status: ProgressStatus.done,

	// 1: no comments
	// 2: has comments
	// 3: completed
	qcCommentStatus: CommentStatus.notExist, // [Followup] ex) 1 --> 1 means no comments
	reviewCommentStatus: CommentStatus.notExist, // [Comments] ex) 1 --> 2 means this pod has comments
	//
	//
	//
	cpReviewList: [], // [ConcurringPartner] ex) []
	qcReviewList: [], // [QualityControl] ex) []
	epReviewList: [], // [EngagementPartner] ex) []
	prepareList: [], // [PreparerList] ex) []
	reviewList: [], // [ReviewerList] ex) []
	//
	isPrepared: false,
	isReviewed: false,
	//
	lastModifiedBy: {
		userAliasId: "",
		userName: "",
		userId: "",
		//
		roleId: "",
		roleName: "",
	}, // [LastModifiedBy] ex) {roleName: null, userName: null, Date: null}
	lastModifiedAt: "",
}

export const initialFileInfo = {
	id: "",
	alias: "",
	attentionStatus: ProgressStatus.todo,
	checkedOutBy: {
		id: "",
		name: "",
		aliasId: "",
	},
	createdBy: {
		id: "",
		name: "",
		aliasId: "",
	},
	createdDate: "",
	extension: "",
	srcFormat: "",
	assignedRoles: [],
	hasRationale: false,
	isCheckedOut: false,
	isLocked: false,
	lastModifiedBy: {
		id: "",
		name: "",
		aliasId: "",
	},
	lastModifiedDate: "",
	srcId: "",
	title: "",
	parentId: "",
	parentName: "",
	// ProjectId: 77,
	qcComments: {
		completed: 0,
		pending: 0,
	},
	reviewComments: {
		completed: 0,
		pending: 0,
	},
	size: "",
}
