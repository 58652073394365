import { types } from "mobx-state-tree"
import SignoffHistoryModel from "./sign-off-history.model"
import SignoffHistoryViewModel from "./sign-off-history.view-model"
import SignOffHistoryUiHelper from "./sign-off-history.ui-helper"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const SignoffHistoryStore = types
	.compose(
		SignoffHistoryModel,
		SignoffHistoryViewModel,
		SignOffHistoryUiHelper
	)
	.named("SignoffHistoryStore")

export const initialSelectedColumns = [
	{ label: "Ref. num", id: "aliasId", display: true },
	{ label: "Title", id: "title", display: true },
	{ label: "Prepare", id: "prepare", display: true },
	{ label: "Review", id: "review", display: true },
	{ label: "EP Review", id: "epReview", display: true },
	{ label: "CP Review", id: "cpReview", display: true },
	{ label: "QC Review", id: "qcReview", display: true },
]

export const initialStore = {
	totalWp: 0,
	totalPf: 0,
	// ui-helper
	selectedTab: DLProjSubMenus.wp,
	columns: initialSelectedColumns,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const SignoffHistoryStoreInstance = SignoffHistoryStore.create(initialStore)

export default SignoffHistoryStoreInstance
