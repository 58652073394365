import { types } from "mobx-state-tree"

const ArchivePreProcessUiHelper = types
	.model({
		// dialogs
		cancelPreProcessDialogOpen: false,
		startPreProcessDialogOpen: false,
		archiveProjectDialogOpen: false,
	})
	.actions((self) => ({
		// dialogs

		setCancelPreProcessDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.cancelPreProcessDialogOpen = request
			} else {
				self.cancelPreProcessDialogOpen = !self.cancelPreProcessDialogOpen
			}
		},
		setStartPreProcessDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.startPreProcessDialogOpen = request
			} else {
				self.startPreProcessDialogOpen = !self.startPreProcessDialogOpen
			}
		},
		setArchiveProjectDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.archiveProjectDialogOpen = request
			} else {
				self.archiveProjectDialogOpen = !self.archiveProjectDialogOpen
			}
		},
	}))

export default ArchivePreProcessUiHelper
