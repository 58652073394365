import React from "react"
import { observer } from "mobx-react-lite"
import {
	Partition,
	AssignedRoles,
	FileInfo,
	StatusArea,
	FileRowComments,
	FileRowHistory,
	FileRowSignOffArea,
} from "./file-row-sub-components"
//
import { DLButton, DLIconButton } from "../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiDotsHorizontal, mdiDrag } from "@mdi/js"
import styled from "styled-components"
import { FileTreeFileRowProps } from "./dl-file-tree-props"
import { FileColumn } from "../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function FileTreeFileRow({
	data,
	permission,
	menuId,
	//
	showCheckbox,
	canDrag,
	indentWidth,
	// select
	isSelected,
	isCurrentParent,
	isLastSelectedItem,
	//
	setPrevTreeItem,
	setNextTreeItem,
	//
	handleRightClick,
	handleCtxMenuButtonClick,
	//
	handleClick,
	handleCheckbox,
	handleDoubleClick,
	setSelectedFile,
	// for file
	handleEditFileInfo,
	handleOpenCommentListOfFile,
	handleFileStatus,

	// sign off
	signOffPolicy,
	getColumnSignOffs,
	hoveredColumn,
	cellType,
	dateFormat,
	singleSignOff,

	// for column widths
	contentsAreaWidth,
	//
	assignedRolesWidth,
	aliasIdWidth,
	titleWidth,
	formatWidth,
	sizeWidth,
	signOffLockWidth,
	//
	fixedAreaWidth,
	signOffAreaWidth,
	historyWidth,
	//
	simpleMode,
	columnDisplayStatus,
	//
	checkDuplicatedAliasId,
	checkDuplicatedName,
	signOffColumnsDisplay,
	handleOpenSignOffLockDialog,
}: FileTreeFileRowProps) {
	const path = data.path
	const fileId = data.node.id
	const file = data.node

	/**
	 * Workpaper individual file row has below structure
	 * Icon area / File information / Sign off area / status (attention) / comments / history
	 *
	 * 1. Icon area: special icons (AJE or PAJE: purpose of use), assigned role icons
	 * 2. File information: ref num, file format (.png, .xslx...), file name
	 * 3. Sign off information: prepare / review / ep review / cp review / qc review (and they are can be added)
	 * 4. Comments:
	 * 5. Status:
	 * 6. History:
	 * 7. ... Btn
	 *
	 * separation: [1,2] [3 | 4,5,6]
	 */
	let forIndent: number[] = []

	for (let i = 0; i < path.length; i++) {
		forIndent.push(i)
	}

	let inSameFolderIndex = 0
	let inSameFolder = false

	path.map((nodeId: string, i: number) => {
		if (isCurrentParent(nodeId)) {
			inSameFolderIndex = i + 1
			inSameFolder = true
		}
	})

	console.log(path, "path1")

	const testId = "file-" + file.aliasId.toLowerCase().replace(/ /g, "-")

	const depth = path.length
	const rowWidth =
		contentsAreaWidth - indentWidth * depth - (canDrag ? 32 : 0) // 32 = drag icon width
	const selected = isSelected(fileId)
	const currentFolder = isCurrentParent(file.parentId)
	//
	const isLastSelected = isLastSelectedItem(fileId)
	const simpleNodeForKeyboardShortcut = {
		node: data.node,
		parentNode: data.parentNode,
	}
	isLastSelected && setPrevTreeItem(simpleNodeForKeyboardShortcut)
	isLastSelected && setNextTreeItem(simpleNodeForKeyboardShortcut)

	return (
		<StyledFileTreeFileRow
			data-testid={testId}
			className={`FR  ${selected && "selected"}`}
		>
			{/**
			 *
			 * ---------- Indntation ----------
			 *
			 */}
			<div className="padding-placeholder" style={{ width: "0.4rem" }} />
			{canDrag ? (
				<DLIconButton eleTestId="drag-icon">
					<Icon path={mdiDrag} size={0.8} className="drag-icon" />
				</DLIconButton>
			) : (
				<div style={{ width: 32 }}></div>
			)}
			{showCheckbox && (
				<DLCheckbox
					eleTestId={`checkbox-${file.aliasId}`}
					color="primary"
					isChecked={selected}
					clickHandler={(e: any) => handleCheckbox(e, fileId, false)}
				/>
			)}
			{forIndent.map((i) => {
				return (
					<div
						className={`indent-placeholder index-${i} ${
							currentFolder &&
							i === forIndent.length - 1 &&
							"in-same-folder"
						} ${
							i === inSameFolderIndex &&
							inSameFolder &&
							"in-same-folder"
						} ${isSelected(path[i - 1]) && "parentSelected"} ${
							inSameFolder && "highlight-root"
						} ${path[i]}`}
						style={{
							width: indentWidth,
							height: 32,
						}}
						key={i}
					>
						{i === 0 &&
							columnDisplayStatus[FileColumn.assignedRoles] && (
								<AssignedRoles
									roleList={file.assignedRoles}
									width={assignedRolesWidth}
									aliasId={file.aliasId}
								/>
							)}
					</div>
				)
			})}
			{/**
			 *
			 * ---------- Partition ----------
			 *
			 */}
			<div className="file-row FR JSB">
				{/**
				 *
				 *
				 */}
				<div
					className="new-left-side FR JSB"
					// style={{ width: rowWidth - fixedAreaWidth }}
					onClick={(e: any) => handleClick(e, file.id)}
					onContextMenu={(e: any) => handleRightClick(e, file.id)}
					onDoubleClick={(e: any) => handleDoubleClick(e, file)}
				>
					<div className="section-left FR AC">
						<Partition />
						<FileInfo
							fileInfo={file}
							//
							aliasIdWidth={aliasIdWidth}
							titleWidth={titleWidth}
							formatWidth={formatWidth}
							sizeWidth={sizeWidth}
							signOffLockWidth={signOffLockWidth}
							//
							columnDisplayStatus={columnDisplayStatus}
							handleOpenSignOffLockDialog={
								handleOpenSignOffLockDialog
							}
							hoveredColumn={hoveredColumn}
						/>
						<Partition />
						{/* WARNING:  NOTE: DO NOT USE PROPS EXCEPT NODE ID! OTHER PROPS MAKE MANY DELAYS!! */}
					</div>
				</div>
				{/**
				 *
				 *
				 */}
				{!simpleMode && (
					<div
						className="new-right-side FR JSB"
						style={{
							// width: fixedAreaWidth,
							paddingRight: "0.6rem",
						}}
					>
						<div
							className="section-right file-row-properties FR AC JSB"
							style={{
								// width: fixedAreaWidth - 64,
								overflow: "hidden",
							}}
						>
							{columnDisplayStatus[FileColumn.signOffs] && (
								<FileRowSignOffArea
									hoveredColumn={hoveredColumn}
									width={signOffAreaWidth}
									fileId={fileId}
									fileAliasId={file.aliasId}
									signOff={singleSignOff}
									getColumnSignOffs={getColumnSignOffs}
									cellType={cellType}
									dateFormat={dateFormat}
									signOffPolicy={signOffPolicy}
									signOffColumnsDisplay={
										signOffColumnsDisplay
									}
									permission={permission}
									isSignOffLocked={file?.signOffLock}
								/>
							)}

							<div className="FR comment-and-history">
								<Partition />
								{columnDisplayStatus[
									FileColumn.signOffLock
								] && (
									<div
										className={`file-signOffLock info-section-prop ${
											hoveredColumn ===
												"file_signOffLock" &&
											"highlight"
										}`}
										data-testid={
											"fileRow-signOffLock-of-" +
											file?.aliasId
										}
										style={{
											width: signOffLockWidth,
											overflow: "hidden",
										}}
									>
										<DLButton
											eleClassName="sign-off-btn"
											eleTestId={`sign-off-btn-on-row-${file?.aliasId}`}
											size="thin"
											variant="outlined"
											clickHandler={() =>
												handleOpenSignOffLockDialog(
													file?.id
												)
											}
											color={
												file?.signOffLock
													? "warning"
													: "primary"
											}
											eleStyle={{ width: "100%" }}
										>
											{file?.signOffLock
												? "Locked"
												: "Unlocked"}
										</DLButton>
									</div>
								)}
								<Partition />
								<div className="column-section FR AC JC">
									{columnDisplayStatus[
										FileColumn.attention
									] && (
										<StatusArea
											fileId={file.id}
											fileAliasId={file.aliasId}
											status={file.status}
											handleStatus={handleFileStatus}
											permission={permission}
										/>
									)}
									{columnDisplayStatus[
										FileColumn.comment
									] && (
										<FileRowComments
											fileId={fileId}
											fileAliasId={file.aliasId}
											menuId={menuId}
											reviewCommentStatus={
												file.reviewCommentStatus
											}
											qcCommentStatus={
												file.qcCommentStatus
											}
											setSelectedFile={setSelectedFile}
											handleOpenCommentListOfFile={
												handleOpenCommentListOfFile
											}
										/>
									)}
								</div>
								<Partition />
								{columnDisplayStatus[FileColumn.history] && (
									<FileRowHistory
										lastModifiedAt={file.lastModifiedAt}
										lastModifiedBy={file.lastModifiedBy}
										width={historyWidth}
									/>
								)}
							</div>
						</div>

						{/* fixed area right side (ctx btn) */}
						<DLIconButton
							eleTestId={`file-ctx-menu-btn-${file.aliasId}`}
							eleClassName="file-ctx-menu-btn"
							clickHandler={(e: any) =>
								handleCtxMenuButtonClick(e, fileId)
							}
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					</div>
				)}
			</div>
		</StyledFileTreeFileRow>
	)
})

const StyledFileTreeFileRow = styled.div`
	overflow-y: hidden;
	font-weight: 600;
	.highlight {
		background-color: ${(props) => props.theme.shade10};
	}
	&.depth-1 {
		.indent-placeholder {
			border-right: none;
		}
	}
	&.depth-2 {
		background-color: ${(props) => props.theme.shade05};
	}
	&.depth-3 {
		background-color: ${(props) => props.theme.shade10};
	}
	&.depth-4 {
		background-color: ${(props) => props.theme.shade20};
	}
	&.depth-5 {
		background-color: ${(props) => props.theme.shade30};
	}
	&.depth-too-deep {
		background-color: ${(props) => props.theme.shade40};
	}
	overflow: hidden;
	.indent-placeholder {
		border-right: 1px solid ${(props) => props.theme.shade10};
		&.index-0 {
			border-right: none;
		}
		&.in-same-folder {
			border-right: 2px solid #ff9900;
		}
	}
	.highlight-root {
		&.index-1 {
			// border-right: none;
			border-right: 2px solid blue !important;
		}
	}
	&.selected {
		background-color: ${(props) => props.theme.emphasisLight};
		/* color: ${(props) => props.theme.emphasis}; */
		font-weight: 700;
	}
	.parentSelected {
		border-right: 2px solid #ff9900;
	}
	.file-row {
		.new-left-side {
			overflow: hidden;
			.file-row-properties {
				/** TODO: Class names are too complicated now and not working properly... Need to update  */
				/* overflow: hidden; */
				.column-section {
				}
			}
		}
		.new-right-side {
		}
		.file-ctx-menu-btn {
			/* position: absolute;
			right: 0; */
			/* background-color: orange; */
		}
	}
	.sign-off-btn {
		border-color: ${(props) => props.theme.projSidebar};
	}
`
