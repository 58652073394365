import { flow } from "mobx-state-tree"
import { OrgSetupChecklistApiProps } from "../org-setup-checklist.apis"

const ReadChecklistTemplates = (
	self: any,
	read: OrgSetupChecklistApiProps["apiRead"]
) => ({
	readChecklistTemplates: flow(function* (params: {
		orgId: string
		id?: string
	}) {
		const actionName = "readChecklistTemplates"
		try {
			const response = yield read(params)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadChecklistTemplates
