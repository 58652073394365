import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

const DeleteFileFolder = (
	self: any,
	deleteFolder: FileSystemApiTypes["apiDeleteFolder"]
) => ({
	deleteFileFolder: flow(function* (folderId: string) {
		const actionName = "deleteFileFolder"
		const menuId = self.storeName
		// 1.
		if (folderId === "" || folderId === undefined) {
			alert(`${actionName} Undefined props exist. folderId: ${folderId}`)
			ConsoleLog(
				`${actionName} Undefined props exist. folderId: ${folderId}`
			)
		}
		//
		//
		// 2.
		const folderNumId = idToNumber(folderId, IdType.folder)
		const userId = localStorage.getItem("orgUserId")
		let userNumId: string | number = ""
		if (userId !== null) {
			userNumId = idToNumber(userId, IdType.user)
		}
		//
		//
		// 3.
		if (typeof folderNumId === "number") {
			try {
				let params: any = {
					WPFolderID: folderNumId,
					// UserID: localStorage.getItem("orgUserId"),
					Type: "WorkpaperFolder", // TODO: pf also uses "WorkpaperFolder". // NOTE: Do not omit this line... it will occur error on WP (PF is okay but WP get error)
				}
				if (menuId === DLOrgSubMenus.proj_templates) {
					if (userId !== null && typeof userNumId === "number") {
						params = {
							TemplateId: folderNumId,
							UserID: userNumId,
						}
					} else {
						alert(
							`(${actionName}) User ID is invalid: ${userNumId}`
						)
					}
				}

				const response = yield deleteFolder({ params, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(`${actionName} prop format is invalid: ${folderNumId}`)
			return false
		}
	}),
})

export default DeleteFileFolder
