import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

const ReadExportArchivedZipFile = (self: any) => ({
	readExportArchivedZipFile: flow(function* (payload: { clientId: number }) {
		const actionName = "readExportArchivedZipFile"
		try {
			const endpoint = "/GetArchivedZipUrl"
			const response = yield getRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.orgToken,
			})

			return response
		} catch (error) {
			// ConsoleLog("readExportArchivedZipFile error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadExportArchivedZipFile
