import { flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../library/api-requests"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { OldSignOffType } from "../../data-models/sign-off.data-model"

const RequestSignOff = (self: any) => ({
	requestSignOff: flow(function* ({
		fileList,
		roleId,
		userNumId,
		type,
		menuId,
	}: {
		fileList: { WPRID: number }[]
		roleId: string
		userNumId: number
		type: OldSignOffType
		menuId: DLProjSubMenus
	}) {
		// signOffType 1 means prepare, 2 means review
		const actionName = "requestSignOff"
		if (!fileList || !type || !menuId) {
			alert(
				`(${actionName}) properties are incorrect: ${fileList} ${type} ${menuId}`
			)
		}

		//
		let endpoint = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/CreateReviewProcess"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/CreatePermanentFileReviewProcess"
		}
		//
		const payload = {
			UserID: userNumId,
			WPRList: fileList,
			signOffType:
				type === OldSignOffType.prepare
					? 1
					: type === OldSignOffType.review && roleId !== "id_qc"
					? 2
					: type === OldSignOffType.review && roleId === "id_qc"
					? 3
					: 999, // error
		}
		if (payload.signOffType === 999) {
			alert(`(${actionName}) sign off type is incorrect: ${type}`)
		}
		try {
			const response = yield postRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.projToken,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default RequestSignOff
