// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetTrialBalanceDetails = (self: any) => ({
	getTrialBalanceDetails(projectId: string) {
		// 0. set actionName
		const actionName = "getTrialBalanceDetails"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			clientId,
		}

		// 3) request API call to Model
		self.readTrialBalanceDetails(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						let trialBalance: any[] = []
						fetchedData.TrialBalanceDetails.map((item: any) => {
							trialBalance.push({
								tbId: item.ID,
								tbName: item.Name,
							})
						})
						self.setTBDetails(trialBalance)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched trial balance details",
							color: MessageColorType.blue,
							open: false,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetTrialBalanceDetails
