import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
} from "../../../../library/api-requests"

export async function read(params: { ClientId: number }) {
	const response = await getRequest({
		url: "/GetClientInfo",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type UpdateProj = {
	Address: string
	ArchiveDatePolicyId: number
	ClientId: number
	ClientMasterID: number
	ClientShortName: string
	EngagementID: number
	ExpectedArchiveDeadLineDate: string
	Fee: string
	FiscalYearEnd: string
	LinkName: string
	RiskAssessmentId: number
	archivedDeadlineDate: string
	expectedIssuanceDate: string
	finalIssuanceDate: string
	periodID: number
	restoreRationale: boolean
}

export async function update(payload?: UpdateProj) {
	const response = await putRequest({
		url: "/EditClient",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readCabinetLocation(cabinetId: number) {
	const response = await getRequest({
		url: `/OrganizationCabinetsGetCabinetLocation`,
		params: {
			cabinetId,
		},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readPeriodNames() {
	const response = await getRequest({
		url: "/GetPeriodNames",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export function readEngType(payload: { PageIndex?: number; PageSize: number }) {
	const response = postRequest({
		url: "/GetEngagementType",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readRiskAssessment(payload: {
	PageIndex?: number
	PageSize: number
}) {
	const response = postRequest({
		url: "/GetRiskAssessments",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function updateArchChecklist(payload: {
	ArchiveChecklistItem1: string
	ArchiveChecklistItem2: string
}) {
	const response = putRequest({
		url: "/UpdateArchiveChecklist",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ProjInfoApiProps = {
	apiRead: (params: { ClientId: number }) => Promise<AxiosResponse<any>>
	apiUpdate: (payload?: UpdateProj) => Promise<AxiosResponse<any>>
	apiReadEngType: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>
	apiReadRiskAssessment: (payload: {
		PageIndex?: number
		PageSize: number
	}) => Promise<AxiosResponse<any>>
	apiReadPeriodNames: () => Promise<AxiosResponse<any>>
	apiReadClients: (params: {
		// CustomerID: number
		GroupID: number
		Type: number
	}) => Promise<AxiosResponse<any>>
	apiReadArchPolicyPeriodList: () => Promise<AxiosResponse<any>>
	apiUpdateArchiveChecklist: (payload: {
		ArchiveChecklistItem1: string
		ArchiveChecklistItem2: string
	}) => Promise<AxiosResponse<any>>
	apiReadCabinetLocation: (cabinetId: number) => Promise<AxiosResponse<any>>
}
