import React from "react"
import styled from "styled-components"

const StyledTreeForFiles = styled.div`
	outline: none;
	color: ${(props) => props.theme.textHighEmphasis};

	/* @Noah: What the hell...  */
	/* 이게 Archive Policy 에서는 되고 여기서는 안된다는 건 뒤쪽에 얘를 덮어버리는? 무언가가 있는 거 같은데 */
	/* Archive policy style이랑 비교해서 답을 찾아낼 것  */
	.rst__rowLandingPad,
	.rst__rowCancelPad {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
	.rst__rowLandingPad > *,
	.rst__rowCancelPad > * {
		opacity: 0 !important;
	}
	.rst__rowLandingPad::before,
	.rst__rowCancelPad::before {
		background-color: purple !important;
		/* border: 3px dashed white; */
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}

	.rst__tree {
		outline: none;
		.rst__node {
			display: flex;
			min-height: 32px !important;
			height: auto !important;
			border-bottom: 1px solid ${(props) => props.theme.shade10};
			:hover {
				background-color: ${(props) => props.theme.secondaryDeep};
				.depth0 {
					background-color: ${(props) => props.theme.secondaryDeep};
				}
			}
			.rst__lineBlock {
				&.rst__lineFullVertical {
				}
				&.rst__lineHalfHorizontalRight {
				}
				/* opacity: 0; */
				border: 1px solid red;
				display: none;
				flex-shrink: 0;
			}
			.rst__nodeContent {
				/* border-bottom: 1px solid purple; */
				/* padding-left: 0.4rem; */
				display: flex;
				width: 100%;
				overflow: hidden;
				div:first-child {
					display: flex;
					align-items: center;
					flex-shrink: 0;
					/* border-bottom: 1px solid purple; */
					/* background-color: purple; */
				}
				/* button[type="button"] {
					cursor: pointer;
					width: 12px;
					height: 12px;
					margin-left: -12px;
					border: solid black;
					border-width: 0 3px 3px 0;
					display: inline-block;
					padding: 3px;
					outline: none;
				} */
				button.rst__expandButton {
					display: none;
				}
				button.rst__collapseButton {
					display: none;
				}
				.rst__lineChildren {
					width: 0 !important;
					/* width: calc(44px - 24px) !important; */
				}

				.rst__rowWrapper {
					/* border-bottom: 1px solid gray; */
					/* To highlight search matched node  */
					.rst__row.rst__rowSearchMatch {
						border: 5px solid green;
						&.rst__rowSearchFocus {
							border: 5px solid red;
						}
					}
					.rst__row {
						display: flex;
						flex-direction: row;
						align-items: center;
						white-space: nowrap;
						/* border-bottom: 1px solid ${(props) =>
							props.theme.shade10}; */
						/* border-bottom: 1px solid orange; */
						.rst__moveHandle,
						.rst__loadingHandle {
							width: 32px;
							height: 32px;
							/* background: pink url("./test.png") no-repeat center; */
							cursor: grab;
							position: absolute;
							z-index: 1;
						}
						/* .rst__moveHandle,
						.rst__loadingHandle {
							 height: 100%; 
							width: 44px;
							 background: #d9d9d9
								url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHdpZHRoPSI0MiIgaGVpZ2h0PSI0MiI+PGcgc3Ryb2tlPSIjRkZGIiBzdHJva2Utd2lkdGg9IjIuOSIgPjxwYXRoIGQ9Ik0xNCAxNS43aDE0LjQiLz48cGF0aCBkPSJNMTQgMjEuNGgxNC40Ii8+PHBhdGggZD0iTTE0IDI3LjFoMTQuNCIvPjwvZz4KPC9zdmc+")
								no-repeat center; 
							box-shadow: 0 2px 2px -2px;
							cursor: move;
							border-radius: 1px;
							z-index: 1;
						}  */
						.rst__loadingHandle {
							cursor: default;
							background: #d9d9d9;
						}
						.rst__rowContents {
							.rst__rowLabel {
								.rst__rowTitle {
									.drag-icon {
										opacity: 0.2;
										transition: 0.2s;
										:hover {
											opacity: 1;
										}
									}
								}
								.rst__rowToolbar {
								}
							}
						}
					}
				}
			}
		}
	}
	.rst__virtualScrollOverride {
		overflow: auto !important;
	}
	.rst__virtualScrollOverride * {
		box-sizing: border-box;
	}

	.ReactVirtualized__Grid__innerScrollContainer {
		overflow: visible !important;
	}

	.rst__rtl .ReactVirtualized__Grid__innerScrollContainer {
		direction: rtl;
	}

	.ReactVirtualized__Grid {
		outline: none;
	}
	/* from node renderer default css */
	/**
 * The outline of where the element will go if dropped, displayed while dragging
 */
	.rst__rowLandingPad,
	.rst__rowCancelPad {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
	.rst__rowLandingPad > *,
	.rst__rowCancelPad > * {
		opacity: 0 !important;
	}
	.rst__rowLandingPad::before,
	.rst__rowCancelPad::before {
		/* 이거 드랍 때문에 한 거 같은데 안 먹고..  */
		background-color: ${(props) => props.theme.secondaryDeep};
		transition: 0.2s;
		border: 3px dashed red;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	@keyframes pointFade {
		0%,
		19.999%,
		100% {
			opacity: 0;
		}
		20% {
			opacity: 1;
		}
	}

	.rst__loadingCircle {
		width: 80%;
		height: 80%;
		margin: 10%;
		position: relative;
	}

	.rst__loadingCirclePoint {
		width: 100%;
		height: 100%;
		position: absolute;
		left: 0;
		top: 0;
	}
	.rst__rtl.rst__loadingCirclePoint {
		right: 0;
		left: initial;
	}

	.rst__loadingCirclePoint::before {
		content: "";
		display: block;
		margin: 0 auto;
		width: 11%;
		height: 30%;
		background-color: red;
		/* @Noah */
		/* background-color: #fff; */
		border-radius: 30%;
		animation: pointFade 800ms infinite ease-in-out both;
	}
	.rst__loadingCirclePoint:nth-of-type(1) {
		transform: rotate(0deg);
	}
	.rst__loadingCirclePoint:nth-of-type(7) {
		transform: rotate(180deg);
	}
	.rst__loadingCirclePoint:nth-of-type(1)::before,
	.rst__loadingCirclePoint:nth-of-type(7)::before {
		animation-delay: -800ms;
	}
	.rst__loadingCirclePoint:nth-of-type(2) {
		transform: rotate(30deg);
	}
	.rst__loadingCirclePoint:nth-of-type(8) {
		transform: rotate(210deg);
	}
	.rst__loadingCirclePoint:nth-of-type(2)::before,
	.rst__loadingCirclePoint:nth-of-type(8)::before {
		animation-delay: -666ms;
	}
	.rst__loadingCirclePoint:nth-of-type(3) {
		transform: rotate(60deg);
	}
	.rst__loadingCirclePoint:nth-of-type(9) {
		transform: rotate(240deg);
	}
	.rst__loadingCirclePoint:nth-of-type(3)::before,
	.rst__loadingCirclePoint:nth-of-type(9)::before {
		animation-delay: -533ms;
	}
	.rst__loadingCirclePoint:nth-of-type(4) {
		transform: rotate(90deg);
	}
	.rst__loadingCirclePoint:nth-of-type(10) {
		transform: rotate(270deg);
	}
	.rst__loadingCirclePoint:nth-of-type(4)::before,
	.rst__loadingCirclePoint:nth-of-type(10)::before {
		animation-delay: -400ms;
	}
	.rst__loadingCirclePoint:nth-of-type(5) {
		transform: rotate(120deg);
	}
	.rst__loadingCirclePoint:nth-of-type(11) {
		transform: rotate(300deg);
	}
	.rst__loadingCirclePoint:nth-of-type(5)::before,
	.rst__loadingCirclePoint:nth-of-type(11)::before {
		animation-delay: -266ms;
	}
	.rst__loadingCirclePoint:nth-of-type(6) {
		transform: rotate(150deg);
	}
	.rst__loadingCirclePoint:nth-of-type(12) {
		transform: rotate(330deg);
	}
	.rst__loadingCirclePoint:nth-of-type(6)::before,
	.rst__loadingCirclePoint:nth-of-type(12)::before {
		animation-delay: -133ms;
	}
	.rst__loadingCirclePoint:nth-of-type(7) {
		transform: rotate(180deg);
	}
	.rst__loadingCirclePoint:nth-of-type(13) {
		transform: rotate(360deg);
	}
	.rst__loadingCirclePoint:nth-of-type(7)::before,
	.rst__loadingCirclePoint:nth-of-type(13)::before {
		animation-delay: 0ms;
	}
	/* ------------ test ------------ */
	/**
	* The outline of where the element will go if dropped, displayed while dragging
	*/
	.rst__rowLandingPad,
	.rst__rowCancelPad {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
	.rst__rowLandingPad > *,
	.rst__rowCancelPad > * {
		opacity: 0 !important;
	}
	.rst__rowLandingPad::before,
	.rst__rowCancelPad::before {
		/* 이것도 안먹고...  */
		background-color: lightblue;
		border: 3px dashed white;
		content: "";
		position: absolute;
		top: 0;
		right: 0;
		bottom: 0;
		left: 0;
		z-index: -1;
	}
	/**
	* The outline of where the element will go if dropped, displayed while dragging
	*/
	.rst__placeholderLandingPad,
	.rst__placeholderCancelPad {
		border: none !important;
		box-shadow: none !important;
		outline: none !important;
	}
	.rst__placeholderLandingPad *,
	.rst__placeholderCancelPad * {
		opacity: 0 !important;
	}
	.rst__placeholderLandingPad::before,
	.rst__placeholderCancelPad::before {
		background-color: lightblue;
		border-color: white;
	}
	/**
	* Alternate appearance of the landing pad when the dragged location is invalid
	*/
	.rst__placeholderCancelPad::before {
		background-color: #e6a8ad;
	}
	::-webkit-scrollbar {
		// display: none;
		transition: 0.4s;
		width: 10px;
		height: 10px;
		opacity: 0;

		:hover {
			opacity: 1;
		}
	}

	::-webkit-scrollbar-track {
		// border-left: 1px solid #e5e5e5;
		border-radius: 5px;
		transition: 0.2s;
	}
	::-webkit-scrollbar-track:hover {
		background-color: rgba(150, 150, 150, 0.3);
		border-radius: 5px;
	}
`

export default StyledTreeForFiles
