import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const SaveNotes = (self: any) => ({
	saveNotes(notes: string, noteId: number) {
		// 0.
		const actionName = "saveProjNotes"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let payload
		if (noteId === 0) {
			payload = {
				// to create
				notes: notes,
			}
		} else {
			payload = {
				// to update
				id: noteId,
				notes: notes,
			}
		}
		self.createNotes(payload)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					self.setNoteId(response.data.id)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default SaveNotes
