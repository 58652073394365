import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const ExportArchivedZipFile = (self: any) => ({
	exportArchivedZipFile(projectId: string) {
		// 0.
		const actionName = "exportArchivedZipFile"
		// 1.
		let proceed = window.confirm(
			"Do you really want to export this archive zip file?"
		)
		if (!proceed) {
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			clientId: clientId,
		}
		self.readExportArchivedZipFile(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					let newlink
					if (response.data.Url !== null) {
						newlink = document.createElement("a")
						newlink.setAttribute("href", response.data.Url)
						newlink.setAttribute("download", "fileName" + ".zip")
						newlink.click()
					}
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	relayGetArchivedZipUrl(
		projList: any,
		index: number,
		updateFunction: ({ id, zipUrl }: { id: string; zipUrl: string }) => void
	) {
		if (projList.length >= index + 1) {
			const project = projList[index]
			const projId = project.id
			//
			const clientId = idToNumber(projId, IdType.project)
			const payload = {
				clientId: clientId,
			}
			self.readExportArchivedZipFile(payload).then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const zipUrl = response.data.Url
					if (zipUrl !== null) {
						updateFunction({ id: projId, zipUrl })
						console.log(index, "Success to get the Zip URL", projId)
					} else {
						updateFunction({ id: projId, zipUrl: "No Zip URL" })
						console.log(index, "No Zip URL", projId)
					}
					// next step
					if (projList.length > index + 1) {
						self.relayGetArchivedZipUrl(
							projList,
							index + 1,
							updateFunction
						)
					}
					if (projList.length === index + 1) {
						console.log(" ----- THE END ----- ")
					}
				} else {
					// set fail case response
					console.log(index, "failed to get the zipUrl", projId)
					updateFunction({
						id: projId,
						zipUrl: "Failed to get Zip URL",
					})
					// next step
					if (projList.length > index + 1) {
						self.relayGetArchivedZipUrl(
							projList,
							index + 1,
							updateFunction
						)
					}
					if (projList.length === index + 1) {
						console.log(" ----- THE END ----- ")
					}
				}
			})
			// .catch((error: Error) => {
			// 	self.handleViewModelError({
			// 		error,
			// 		actionName,
			// 		open: false,
			// 	})
			// })
		}
	},
})

export default ExportArchivedZipFile
