import React from "react"
import { Route } from "react-router-dom"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import RemindArchivingController from "../my-page/remind-archiving/RemindArchivingController"
import RemindReportController from "../my-page/remind-report/RemindReportController"

export default function AdminRemind() {
    return (
        <>
            <Route
                exact
                path="/organization/admin-remind/report"
                render={(props) => (
                    <RemindReportController
                        {...props}
                        menuId={DLOrgSubMenus.admin_remind_report}
                    />
                )}
            />
            <Route
                exact
                path="/organization/admin-remind/archiving"
                render={(props) => (
                    <RemindArchivingController
                        {...props}
                        menuId={DLOrgSubMenus.admin_remind_archiving}
                    />
                )}
            />
        </>
    )
}
