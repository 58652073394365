import React from "react"
import styled from "styled-components"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import {
	DLSpinnerCenterAligned,
	DLButton,
} from "../../../../components/basic-elements"
import { DLAvatar } from "@datalobby/components"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { Icon } from "@mdi/react"
import { mdiArrowRight } from "@mdi/js"
import {
	DLProjMenuGroups,
	DLProjSubMenus,
} from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { findProjRoleInfo } from "../../../../temporary-data/project-side/find-proj-role"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"

export default observer(function ProjInfoProjUsers({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const actionName = "getProjUsers"
	const dntFormat = store.global.getDntFormat

	const users = projStore.projUsers.formattedUsers(
		dntFormat.timeZone,
		dntFormat.dateFormat
	)
	const setupUsersAccess = projStore.checkin.checkAccess(DLProjSubMenus.users)

	const handleAddMenuTab = () => {
		const item = {
			id: DLProjSubMenus.users,
			menuGroup: DLProjMenuGroups.setup,
			subMenus: [],
			title: "Users",
			url: `${defaultUrl}/project-setup/project-users`,
		}
		projStore.menuTabs.setCurrentMainMenu(item.id)
		projStore.menuTabs.addOpenedMenu(item)
	}

	return (
		<StyledProjInfoProjUsers>
			<div
				className="FR AC proj-info-section-header"
				style={{ marginBottom: "1rem" }}
			>
				<h4>Project Users</h4>
				<span style={{ marginLeft: "0.4rem" }}>({users.length})</span>
				{setupUsersAccess && (
					<DLButton
						variant="text"
						eleTestId="open-proj-users-btn"
						endIcon={<Icon path={mdiArrowRight} size={0.8} />}
						clickHandler={() => handleAddMenuTab()}
					>
						<Link to={`${defaultUrl}/project-setup/project-users`}>
							Open
						</Link>
					</DLButton>
				)}
			</div>

			{projStore.projUsers.getActionStatus(actionName) === "SUCCESS" && (
				<ReactTableV8
					tableColumns={Columns}
					data={users}
					menuId="ProjInfoProjUsers"
					showPageSetting={false}
					// height={users.length * 48}
					hasPagination={false}
					// permission={permission}
				/>
			)}
			{projStore.projUsers.getActionStatus(actionName) ===
				ActionStatus.loading && <DLSpinnerCenterAligned />}
		</StyledProjInfoProjUsers>
	)
})

const Columns = [
	{
		header: "Type",
		accessorKey: "type",
	},
	{
		header: "Role",
		accessorKey: "roleId",
		size: 30,
		cell: (props: any) => {
			const roleId = props.getValue()
			// const roleInfo = roles.find((role) => role.id === roleId)
			// NOTE: in here, use displayed role
			const roleInfo = findProjRoleInfo(roleId)
			return (
				<div className="FR AC">
					<DLAvatar
						size={18}
						fontSize={12}
						text={roleInfo?.shortName}
						eleTestId={`${roleInfo?.shortName}-avatar`}
						color={roleInfo?.color}
					/>
					{/* <span style={{ marginLeft: 4 }}>{roleInfo?.name}</span> */}
				</div>
			)
		},
	},
	{
		header: "User Name",
		accessorKey: "name",
	},
	{
		header: "User Alias ID",
		accessorKey: "aliasId",
	},
	{
		header: "Project Access Right",
		accessorKey: "accessTypeName",
	},
]

const StyledProjInfoProjUsers = styled.div`
	margin-left: 2rem;
	margin-right: 1rem;
	table > thead > tr > th {
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`
