import Crypto from "crypto-js"
import { EditorUrlProps } from "../screens/lobby-side/file-editor/store/data-models/editor-related.data-model"

const key = process.env.REACT_APP_URL_KEY ?? "NonSmoking Place"

export function encryptUrl(urlInfo: EditorUrlProps) {
	return Crypto.AES.encrypt(JSON.stringify(urlInfo), key).toString()
}

export function decryptUrl(url: string) {
	const bytes = Crypto.AES.decrypt(url, key)
	const original = bytes.toString(Crypto.enc.Utf8)
	const value = JSON.parse(original)

	return value
}
