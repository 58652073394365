import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiChevronRight, mdiCommentTextMultiple } from "@mdi/js"
import {
	DLIconButton,
	DLButton,
	DLSingleSelect,
	ConsoleLog,
} from "../../../../components/basic-elements"
// WARNING:
import Drawer from "@material-ui/core/Drawer"
import styled from "styled-components"
import CommentCardThin from "../../../../components/combined-elements/comment/CommentCardThin"
import {
	DLProjMenuGroups,
	DLProjSubMenus,
} from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DialogActionType } from "../../../../common-models/enumerations/common-enums"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"

export default observer(function CommentDrawer() {
	const store = useRootStore()
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const currentMenu = projStore.menuTabs.currentMainMenu
	//
	const open = projStore.comments.openDrawer
	const setOpen = projStore.comments.setOpenDrawer

	const [commentType, setCommentType] = useState("all")

	const handleCommentType = (event: any) => {
		setCommentType(event.target.value)
	}

	useEffect(() => {
		ConsoleLog(["DRAWER for COMMENT", currentMenu])
		if (open === true) {
			if (
				projStore.checkin.projId !== undefined &&
				projStore.checkin.projId !== ""
			) {
				projStore.comments.getComments(
					"all",
					projStore.checkin.projId,
					currentMenu === DLProjSubMenus.wp
						? DLProjSubMenus.wp
						: DLProjSubMenus.pf
				)
			}
		}
	}, [open])

	const handleDeleteComment = (commentId: string) => {
		const userId = localStorage.getItem("orgUserId")
		ConsoleLog(["user id from store", projStore.checkin.userId])
		// const handleResponse = actions[menuType].handleResponse
		const handleResponse = (props: any) => {
			ConsoleLog("Does drawer need individual snackbar in itself..?")
		}
		if (userId !== null) {
			ConsoleLog(["currentMenu for comment drawer", currentMenu])

			projStore.comments.removeComment({
				props: { commentId, deleteBy: userId },
				handleResponse,
			})
		} else {
		}
	}

	const handleEditComment = (commentId: string) => {
		const partialStore =
			currentMenu === DLProjSubMenus.wp ? projStore.wp : projStore.pf
		partialStore.setCommentDetailDialogMode(DialogActionType.edit)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
		partialStore.setSelectedComment(commentId)
	}

	const handleCloseComment = (commentId: string) => {
		const partialStore =
			currentMenu === DLProjSubMenus.wp ? projStore.wp : projStore.pf
		partialStore.setCommentClosingDialogOpenStatus(true)
		partialStore.setSelectedComment(commentId)
	}

	const redirectTab = () => {
		const item = {
			id: DLProjSubMenus.comments,
			menuGroup: DLProjMenuGroups.communication,
			subMenus: [],
			title: "Comments",
			url: `${defaultUrl}/note-and-comments/comments`,
		}
		projStore.menuTabs.setCurrentMainMenu(item.id)
		projStore.menuTabs.addOpenedMenu(item)
		setOpen(false)
	}

	const drawerPadding = store.ui.isPageToolbarOpen ? "9rem" : "6rem"
	const openStatus = projStore.comments.drawerOpenStatus
	const comments = projStore.comments.getCommentByTypeAndMenu(
		commentType,
		currentMenu === DLProjSubMenus.wp
			? DLProjSubMenus.wp
			: DLProjSubMenus.pf
	)
	ConsoleLog([comments, "comments"])

	return (
		<StyledCommentDrawer paddingTop={drawerPadding}>
			<Drawer
				className="drawer-root"
				variant="persistent"
				anchor="right"
				open={openStatus}
			>
				<div className="drawer-container">
					<div className="drawer-header FR AC JSB">
						<div className="FR AC JL">
							<DLIconButton
								variant="iconOnly"
								size="large"
								align="left"
								clickHandler={() => setOpen(false)}
								eleTestId="library drawer close button"
							>
								<Icon path={mdiChevronRight} size={1} />
							</DLIconButton>
							Comment List
						</div>
						<Link
							to={`${defaultUrl}/note-and-comments/comments`}
							className="FR AC JR"
						>
							<DLButton
								variant="text"
								size="regular"
								align="right"
								eleTestId="library drawer close button"
								clickHandler={redirectTab}
								endIcon={
									<Icon
										path={mdiCommentTextMultiple}
										size={1}
									/>
								}
							>
								View More
							</DLButton>
						</Link>
					</div>
					<div className="drawer-body">
						<div className="comment-type-select">
							<DLSingleSelect
								eleTestId="comment-type-selection"
								placeholder="Select Comment Type"
								eleValue={commentType}
								eleOnChange={handleCommentType}
								optionList={[
									{
										name: "All",
										value: "all",
									},
									{
										name: "Review Comments",
										value: "reviewComments",
									},
									{
										name: "QC Comments",
										value: "qcComments",
									},
								]}
							/>
						</div>
						<br />
						<br />
						{comments.length > 0 ? (
							<div className="comment-contents">
								{comments.map((comment: any) => {
									return (
										<div key={comment.id}>
											<CommentCardThin
												comment={{
													...comment,
													assignees:
														comment.assigneeNames,
												}}
												handleDelete={
													handleDeleteComment
												}
												handleOpenEdit={
													handleEditComment
												}
												handleCloseComment={
													handleCloseComment
												}
											/>
										</div>
									)
								})}
							</div>
						) : (
							<div>
								No Comments on{" "}
								{currentMenu === DLProjSubMenus.wp
									? "Workpaper Files"
									: "Permanent Files"}
							</div>
						)}
					</div>
				</div>
			</Drawer>
		</StyledCommentDrawer>
	)
})

interface StyledCommentDrawerProps {
	paddingTop: any
}

const StyledCommentDrawer = styled.div<StyledCommentDrawerProps>`
	transition: 0.2s;

	.drawer-root > div {
		box-shadow: -2px 10px 10px 1px ${(props) => props.theme.shadowColorDeep};
		background-color: ${(props) => props.theme.secondaryDeep};
		border-left: none;
		margin-top: ${(props) => props.paddingTop};
		border-top-left-radius: 4px;
		.drawer-container {
			width: 22rem;
			height: 100%;
			.drawer-header {
			}
			.drawer-body {
				padding: 0 2rem;
				.lib-select {
				}
				.lib-contents {
				}
			}
		}
	}
`
