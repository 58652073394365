import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { CannotAccess } from "../../../components/basic-elements"
import { PageContainer } from "../../../components/app-frame-elements"
import OrgMgmt from "./OrgMgmt"
import { useLobbyStore } from "../../../stores/lobby-store/lobby-store.provider"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function OrgMgmtController() {
	const store = useRootStore()
	const lobbyStore = useLobbyStore()
	const orgStore = useOrgStore()
	const actionName = "getOrgMgmtList"
	const moduleId = "LobbyOrgMgmt"
	const menuId = DLOrgSubMenus.archived_mgmt
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	//
	useEffect(() => {
		orgStore.checkin.orgInfo.hasCustomerViewAccess &&
			lobbyStore.orgMgmt.getOrgMgmtList()
	}, [])

	const i18n = store.i18n.combineI18n(moduleId, "OrgMgmtController")
	return (
		<>
			{orgStore.checkin.orgInfo.hasCustomerViewAccess ? ( // Need to confirm
				<OrgMgmt
					partialStore={lobbyStore.orgMgmt}
					fetchingStatus={lobbyStore.orgMgmt.getActionStatus(
						actionName
					)}
					// permission={lobbyStore.orgMgmt}
					i18n={i18n}
				/>
			) : (
				<PageContainer>
					<CannotAccess />
				</PageContainer>
			)}
		</>
	)
})
