import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

const RequestDuplicateProjTemplate = (self: any, duplicateTemplate: any) => ({
	requestDuplicateProjTemplate: flow(function* (templateId: string) {
		const actionName = "requestDuplicateProjTemplate"
		// 1.
		if (templateId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. templateId: ${templateId}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(templateId, IdType.projTemplate)
		if (typeof numberId === "number") {
			try {
				const params = {
					SourceId: numberId, // NOTE: Why this API doesn't require the parent Id...???
					Type: 2,
				}
				const response = yield duplicateTemplate(params)
				// const endpoint = "/CopyProjectTemplate"
				// const response = yield postRequest({
				// 	url: endpoint,
				// 	params,
				// 	tokenType: TOKEN_TYPE.orgToken,
				// })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default RequestDuplicateProjTemplate
