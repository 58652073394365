import { types } from "mobx-state-tree"
import {
	ReadComments,
	ReadCommentsByObj,
	CreateComment,
	DeleteComment,
	UpdateComment,
	RequestCloseComment,
} from "./model-actions"

const CommentsModel = types
	.model({})
	// main actions (CRUD)
	.actions(CreateComment)
	.actions(ReadComments)
	.actions(ReadCommentsByObj)
	.actions(UpdateComment)
	.actions(RequestCloseComment)
	.actions(DeleteComment)

export default CommentsModel
