import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import ReadLibrariesInProj from "./model-actions/read-libraries-in-proj"
import ReadLibFilesInProj from "./model-actions/read-lib-files-in-proj"
import ReadTempFilesInProj from "./model-actions/read-temp-files-in-proj"
import { OrgProjTemplatesApiProps } from "../../../organization-side/org-library/org-proj-templates/store/org-proj-templates.api-props"
import { ReadProjTemplateDetails } from "../../../organization-side/org-library/org-proj-templates/store/model-actions/template"

// separated model actions

const LibInProjModel = ({
	apiReadProjTemplateDetails,
}: {
	apiReadProjTemplateDetails: OrgProjTemplatesApiProps["apiReadProjTemplateDetails"]
}) =>
	types
		.model({})
		// CRUD
		.actions(ReadLibrariesInProj)
		.actions(ReadLibFilesInProj)
		.actions(ReadTempFilesInProj)
		.actions((self) =>
			ReadProjTemplateDetails(self, apiReadProjTemplateDetails)
		)
		// model common actions
		.actions(CommonModelActions)

export default LibInProjModel
