import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLSpinner } from "../../../../components/basic-elements"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { Link, useParams } from "react-router-dom"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { mdiArrowLeft } from "@mdi/js"
import { Icon } from "@mdi/react"

export default observer(function OrgSetupChecklistDetail({
	partialStore,
	fetchingStatus,
	contentHeight,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	contentHeight: any
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const _store = orgStore.setupChecklist

	let { id } = useParams<{ id: string }>()
	console.log("checklist template id:", id)

	const linkToBack = "/organization/organization-setup/checklist"
	const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)

	useEffect(() => {
		_store.getChecklistTemplates({
			orgId: orgIdForCdk,
			id,
		})
	}, [])

	return (
		<PageContainer fullWidth fixedHeight>
			{/* {fetchingStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentHeight }}
				>
					<DLSpinner />
				</div>
			)} */}
			{/* {fetchingStatus === ActionStatus.success && ( */}
			<StyledSetupChecklistDetail>
				<div className="FR">
					<div onClick={() => store.ui.setShowTabs(true)}>
						<Link to={linkToBack} className="FR back-link">
							<Icon path={mdiArrowLeft} size={0.8} />
							Back
						</Link>
					</div>
				</div>

				<div className="template-info" style={{ marginTop: 8 }}>
					<h3>
						{_store.checklistTemplateDetail.checklistTemplate.name}
					</h3>
				</div>
				<div className="template-items">
					{_store.checklistTemplateDetail.items.map((item) => {
						return <div>{item.name}</div>
					})}
				</div>
			</StyledSetupChecklistDetail>
			{/* )} */}
		</PageContainer>
	)
})

const StyledSetupChecklistDetail = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.back-link {
		cursor: pointer;
		margin-right: 1rem;
	}
`
