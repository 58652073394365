import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"

import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import DLTreeNav from "../../../../../components/combined-elements/tree-nav/DLTreeNav"

import {
	DLButton,
	DLContextMenu,
	DLIconButton,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import ArchPolicyRowController from "./ArchPolicyRowController"
import EngTypeRowController from "./EngTypeRowController"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import { classNames } from "react-select/src/utils"

export default observer(function ArchivePolicyNav({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const policyStore = orgStore.archPolicy
	const actionName = "getArchivePolicies"
	//
	let canDrag = permission.update // policyStore.canDrag

	const [searchQuery, setSearchQuery] = useState("")
	const handleQuery = (e: any) => {
		setSearchQuery(e.target.value)
	}
	//
	const handleOpenCreateDialog = () => {
		policyStore.setOpenCreateDialog(true)
	}
	const handleAllowDrag = () => {
		policyStore.setCanDrag()
	}
	const handleNavOpen = () => {
		policyStore.setNavOpen()
	}

	const handleRightClick = (e: any, id: string, on: "policy" | "engType") => {
		e.preventDefault()
		policyStore.setClickPoint({
			mouseX: e.clientX,
			mouseY: e.clientY,
		})
		if (on === "policy") {
			policyStore.toggleExpand(id)
			policyStore.setSelectedPolicy(id)
		} else {
			policyStore.toggleExpand(id)
		}
	}

	const ArchivePolicyRenderRow = ({
		node,
	}: {
		node: { id: string; parentId: null | string; [x: string]: any }
	}) => {
		if (node.type === "policy") {
			return (
				<ArchPolicyRowController
					node={node}
					handleRightClick={handleRightClick}
					canDrag={false} // there is no move feature for archive policy
				/>
			)
		} else if (node.type === "engType") {
			return (
				<EngTypeRowController
					node={node}
					// handleRightClick={handleRightClick}
					canDrag={canDrag}
				/>
			)
		}
	}

	const handleDuplicate = () => {
		let proceed = window.confirm(
			"Are you sure you want to duplicate this archive condition policy?"
		)
		if (proceed) {
			policyStore.copyArchivePolicy(policyStore.selectedPolicy)
		} else {
			return
		}
	}

	const handleDelete = () => {
		let proceed = window.confirm(
			"Upon deletion, the engagement types under this archive condition will be moved to the Default Archive Condition policy. Do you want to continue?"
		)
		if (proceed) {
			policyStore.removeArchivePolicy(policyStore.selectedPolicy)
		} else {
			return
		}
	}

	const handleMoveEngType = (data: any) => {
		if (data.node.type === "policy") {
			alert("Cannot move archive policy")
		} else if (data.nextParentNode === null) {
			return
		} else if (
			data.node.type === "engType" &&
			data.nextParentNode.type === "policy" &&
			data.node.parentId !== data.nextParentNode.id
		) {
			const nextParentName = data.nextParentNode.title
			const nextParentId = data.nextParentNode.id
			const engTypeId = data.node.id
			const currentParentId = data.node.parentId
			let proceed = window.confirm(
				`Do you want to update this Eng. Type to use this '${nextParentName}' Archive policy?`
			)
			if (!proceed) {
				return
			}
			policyStore.moveEngType(engTypeId, currentParentId, nextParentId)
		}
	}

	let contentsHeight = store.ui.contentsAreaHeight
	let navWidth = policyStore.viewNavWidth
	let navHeaderHeight = 64
	let navOpen = policyStore.navOpen
	//

	let treeData = policyStore.simpleTreeForNav
	let treeHeight = contentsHeight - navHeaderHeight - 80
	let treeIndent = 24

	let loadingStatus = policyStore.getActionStatus(actionName)
	return (
		<>
			<DLTreeNav
				navWidth={navWidth}
				navHeaderHeight={navHeaderHeight}
				contentsHeight={contentsHeight}
				navOpen={navOpen}
				canDrag={canDrag}
				treeData={treeData}
				treeHeight={treeHeight}
				treeIndent={treeIndent}
				loadingStatus={loadingStatus}
				//
				showSearchbar={false}
				showSortBtn={false}
				actionButtons={
					permission.create ? (
						<DLButton
							startIcon={<Icon path={mdiPlus} size={0.8} />}
							eleTestId="add-arch-policy-on-nav"
							variant="text"
							tooltipText="Add Archive Policy"
							clickHandler={handleOpenCreateDialog}
						>
							Add Policy
						</DLButton>
					) : undefined
				}
				//
				treeRowRenderer={ArchivePolicyRenderRow}
				handleAllowDrag={handleAllowDrag}
				handleNavOpen={handleNavOpen}
				//
				handleQueryText={handleQuery}
				onMoveNode={handleMoveEngType}
			/>
			{policyStore.clickPoint.mouseX && (
				<ArchPolicyCtxMenu
					clickPoint={policyStore.clickPoint}
					handleClose={() =>
						policyStore.setClickPoint({
							mouseX: null,
							mouseY: null,
						})
					}
					handleOpenEdit={() => policyStore.setOpenEditDialog(true)}
					handleDuplicate={handleDuplicate}
					handleDelete={handleDelete}
				/>
			)}
		</>
	)
})

const ArchPolicyCtxMenu = observer(
	({
		clickPoint,
		handleClose,
		handleOpenEdit,
		handleDuplicate,
		handleDelete,
	}: {
		clickPoint: any
		handleClose: any
		handleOpenEdit: any
		handleDuplicate: any
		handleDelete: any
	}) => {
		const menuOptions = [
			{
				label: "Edit",
				value: "ctx-edit-arch-policy",
				icon: (
					<DLIcon
						name={DLIconName.edit}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
				clickEvent: handleOpenEdit,
			},
			{
				label: "Duplicate",
				value: "ctx-duplicate-arch-policy",
				clickEvent: handleDuplicate,
				icon: (
					<DLIcon
						name={DLIconName.duplicate}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
			},
			{
				label: "Delete",
				value: "ctx-delete-arch-policy",
				clickEvent: handleDelete,
				icon: (
					<DLIcon
						name={DLIconName.delete}
						size={CommonIconSize.ctxMenuIcon}
					/>
				),
			},
		]
		return (
			<DLContextMenu
				eleId="arch-policy-ctx-menu"
				clickPoint={clickPoint}
				handleClose={handleClose}
				menuOptions={menuOptions}
			/>
		)
	}
)
