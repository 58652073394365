import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import ReadNotificationSettings from "./model-actions/read-notifications-settings"
import UpdateNotificationSettings from "./model-actions/update-notification-settings"
import { NotificationSettingsApiProps } from "./notification-settings.apis"

const NotificationSettingsModel = ({
	apiRead,
	apiUpdate,
}: NotificationSettingsApiProps) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadNotificationSettings(self, read))
		.actions((self, update = apiUpdate) =>
			UpdateNotificationSettings(self, update)
		)
		// model common actions
		.actions(CommonModelActions)

export default NotificationSettingsModel
