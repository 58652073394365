import React, { useEffect } from "react"
import { PageContainer } from "../../../../components/app-frame-elements"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { TreeNavFileList, SignOffDetail, Toolbar } from "./sub-components"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import styled from "styled-components"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import SignOffHistoryDetailMore from "./sub-components/SignOffHistoryDetailMore"
import { ConsoleLog } from "../../../../components/basic-elements"

export default observer(function SignOffHistory() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.signoff_history
	const partialStore = projStore.signOff
	const actionName = "getLastSignoffHistory"
	const i18nSheet = ProjI18n.signOffHistory
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	const needRefresh = partialStore.needRefresh
	ConsoleLog("needRefresh 1:" + needRefresh)

	useEffect(() => {
		// NOTE: Must update needRefresh when the selected tab be updated
		ConsoleLog("needRefresh 2:" + needRefresh)
		menuAccess &&
			needRefresh &&
			partialStore.getLastSignoffHistory(partialStore.selectedTab)
	}, [menuAccess, needRefresh, partialStore.selectedTab])

	const handleSelectTab = (tab: DLProjSubMenus) => {
		partialStore.setSelectedTab(tab)
		partialStore.setNeedRefresh(true)
	}

	const handleCollapseList = () => {
		partialStore.setListCollapsed(!partialStore.listCollapsed)
	}

	let actionStatus = partialStore.getActionStatus(actionName)

	const listWidth = partialStore.viewListWidth
	const listCollapsed = partialStore.listCollapsed
	const currentTab = partialStore.selectedTab

	const i18n = store.i18n.combineI18n(i18nSheet)
	const fileId = partialStore.selectedFile
	return (
		<>
			{menuAccess ? (
				<>
					<PageContainer
						fullWidth
						fixedHeight
						pageTools={<Toolbar />}
					>
						<StyledSignOffHistory className="sign-off-history-container FR">
							<TreeNavFileList
								permission={permission}
								handleSelectTab={handleSelectTab}
								handleCollapseList={handleCollapseList}
								actionStatus={actionStatus}
								listWidth={listWidth}
								listCollapsed={listCollapsed}
								currentTab={currentTab}
								partialStore={partialStore}
							/>
							{partialStore.selectedFile ? (
								<SignOffHistoryDetailMore
									fileId={fileId}
									fromTable={false}
								/>
							) : (
								<SignOffDetail permission={permission} />
							)}
						</StyledSignOffHistory>
					</PageContainer>
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={partialStore}
					/>
				</>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledSignOffHistory = styled.div`
	padding-top: 0.4rem;
	padding-right: 1rem;
`
