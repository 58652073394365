import { flow } from "mobx-state-tree"

const ReadDashboardDetails = (self: any, read: any) => ({
	readDashboardDetails: flow(function*(params) {
		// 0. set actionName
		const actionName = "readDashboardDetails"
		try {
			const response = yield read(params)
			// ConsoleLog("readProjInfo response", response)
			return response
		} catch (error) {
			// console.error("readProjInfo error", error, error.response)
			self.handleModelError({ actionName, error, open: false })

			return false
		}
	}),
})

export default ReadDashboardDetails
