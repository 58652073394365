import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const AddEliminatingEntry = (self: any) => ({
	addEliminatingEntry(
		inputs: {
			year: string
			entity: number
			accountNumber: string
			accountDesc: string
			debit: string
			credit: string
		},
		projectId: string
	) {
		// 0.
		const actionName = "addEliminatingEntry"
		// 1.
		const checkedProps = hasEmptyProps(inputs)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			AccountDescription: checkedProps.props.accountDesc,
			AccountNumber: checkedProps.props.accountNumber,
			ClientID: clientId,
			Credit: checkedProps.props.credit,
			Debit: checkedProps.props.debit,
			EntityID: checkedProps.props.entity,
			Year: checkedProps.props.year,
		}

		// 3) request API call to Model
		self.createEliminatingEntry(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const fetchedData = response.data
						let eeDetails = {
							accountNumber: fetchedData.AccountNumber,
							accountDesc: fetchedData.AccountDescription,
							createdBy: idToString(
								fetchedData.CreatedBy,
								IdType.user
							),
							credit: fetchedData.Credit,
							debit: fetchedData.Debit,
							entityName: fetchedData.Entity,
							entityId: fetchedData.EntityID,
							eeId: fetchedData.EliminatingEntryID.toString(),
							year: fetchedData.Year,
						}
						self.pushItemToEEDetails(eeDetails)
						self.setAddEliminatingEntryDialogOpen(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully added eliminating entry",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddEliminatingEntry
