import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import { initialStore } from "./file-editor.provider"
import {
	GetFileStatus,
	CheckinFile,
	CheckoutFile,
	DiscardCheckout,
	OpenEditor,
	SetEditorUrl,
	RequestCheckinFile,
} from "./view-model-actions"
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { EditorUrlProps } from "./data-models/editor-related.data-model"
import {
	ActionStatus,
	DLSector,
} from "../../../../common-models/enumerations/common-enums"
import { editor_checkin_steps } from "../EditorCheckin"
import GetOrgInfo from "../../../organization-side/org-access-control/org-checkin/store/view-model-actions/get-org-info"
import { OrgListItem } from "../../organization-list/store/data-models/org-list.data-model"
import GetProjInfo from "../../../project-side/proj-access-control/store/view-model-actions/get-proj-info"
import { ProjInfo } from "../../../project-side/project-information/store/data-models/proj-info.data-model"
import {
	CombinedRoleDefault,
	RoleSet,
} from "../../../../service-modules/role-module/roles.data-model"
import GetProjAssignedRole from "../../../project-side/proj-access-control/store/view-model-actions/get-proj-assigned-role"
import CheckingPermission from "../../../../common-models/permission"
import GetProjToken from "../../../project-side/proj-access-control/store/view-model-actions/get-proj-token"

const FileEditorViewModel = types
	.model({
		editorCheckin: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		orgInfo: OrgListItem,
		projInfo: ProjInfo,
		fileStatus: types.model({
			id: "", // source ID
			userRole: "", // "ProjectAdmin" includes "Project & Archive admin"
			path: "",
			s3Path: "",
			extension: "", // .xlsx
			isOpened: false,
			isRequested: false,
			onSyncLobby: false,
			isTrashed: false,
			//
			checkedOutByName: types.union(types.string, types.null),
			checkedOutById: types.union(types.string, types.null),
			//
			hasPdf: false,
			size: "",
		}),
		// user, client, roleSet are same with the project access control store
		user: types.model({
			userId: "",
			name: "",
			isReadonly: true,
			roleId: "",
			roleColor: "",
			roleShortName: "",
			orgRoleId: "",
			userType: "",
			prevRight: "", // TODO: this part will be deprecated. Project & Archive Admin, Project Admin, Project User...
		}),
		client: types.model({
			id: "",
			name: "",
			aliasId: types.union(types.string, types.undefined),
		}),
		roleSet: RoleSet,
		assignedRole: CombinedRoleDefault,
		//
		iframeUrl: "", // devexpress iframe url
		// ------ from URL ------
		urlInfo: types.model({
			menuId: "",
			sector: types.enumeration<DLSector>(Object.values(DLSector)),
			//
			orgId: "",
			projId: "",
			fileId: "",
			srcId: "",
			srcFormat: "",
			//
			fileTitle: "",
			fileAliasId: "",
			//
			asReadonly: true,
		}),
		//---------------
		year: 0, // let's save this from the project information

		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		// setProjInfo is same with the function in the proj-info store
		setProjInfo(projInfo: any) {
			self.projInfo = projInfo
		},
		// setUser, setClient, setRoleSet, setAssignedRole are same with the proj-access-control store
		setUser(userInfo: any) {
			self.user = userInfo
		},
		setClient(clientInfo: any) {
			self.client = clientInfo
		},
		setRoleSet(roleSet: any) {
			self.roleSet = roleSet
		},
		setAssignedRole(roleInfo: any) {
			self.assignedRole = roleInfo
		},
		//
		setCheckinStatus(status: ActionStatus) {
			self.editorCheckin = status
		},
		setUrlInfo(urlInfo: EditorUrlProps) {
			self.urlInfo = urlInfo
			const actionName = editor_checkin_steps.step1
			self.editorCheckin = ActionStatus.loading
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.success,
			})
		},
		setFileStatus(file: any) {
			self.fileStatus = file
		},
		setIframeUrl(url: string) {
			self.iframeUrl = url
		},
		setOrgInfo(orgInfo: any) {
			self.orgInfo = orgInfo
		},
	}))
	.actions(OpenEditor)
	.actions(GetOrgInfo)
	// WARNING: in the view-model, uses GetProjInfo of proj-access-control to save the user info
	// WARNING: but in the model, uses ReadProjInfo of proj-info
	.actions(GetProjInfo)
	.actions((self: any) => ({
		setProjCheckin(status: ActionStatus) {
			self.projCheckin = status
		},
	}))
	.actions(GetProjAssignedRole)
	.actions(GetProjToken)
	//
	.views(CheckingPermission)
	//
	.actions(GetFileStatus)
	.actions(CheckinFile)
	.actions(CheckoutFile)
	.actions(DiscardCheckout)
	.actions(SetEditorUrl)
	.actions(RequestCheckinFile)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FileEditorViewModel
