import { flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { getToDownload } from "../../../../../../library/api-requests/request-get-others"

const DownloadSignOffHistoryZip = (self: any) => ({
	downloadSignOffHistoryZip: flow(function* () {
		const actionName = "downloadSignOffHistoryZip"

		const endpoint = "/Export_SignOffHistory_Zip"

		try {
			const response = yield getToDownload({
				url: endpoint,
				params: {},
				tokenType: TOKEN_TYPE.projToken,
			})
			// ConsoleLog("actionName, "__response", response)
			return response
		} catch (error) {
			// ConsoleLog("actionName, "__error", error)
			self.handleModelError({
				actionName,
				error,
				open: true,
			})

			return false
		}
	}),
})

export default DownloadSignOffHistoryZip
