import React, { useEffect } from "react"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import OrgEngagements from "./OrgEngagements"

export default observer(function OrgEngagementsController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.engagements
	const menuAccess = orgStore.checkin.checkAccess(menuId)

	const contentsHeight = store.ui.contentsAreaHeight

	return (
		<>
			{menuAccess ? (
				<>
					<OrgEngagements contentsHeight={contentsHeight} />
				</>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
