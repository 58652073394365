import React from "react"
import styled from "styled-components"
import { InputWithLabel } from "../../../../basic-elements"
import { DLCheckbox } from "@datalobby/components"

export default function AddFileSignOffForm({
	prepare,
	setPrepare,
	review,
	setReview,
}: {
	prepare: boolean
	setPrepare: any
	review: boolean
	setReview: any
}) {
	return (
		<StyledAddFileSignOffForm>
			<div className="input-section FR">
				<InputWithLabel label="Sign Off">
					<div className="FR signoff-checkboxes">
						<DLCheckbox
							eleTestId="checkbox-prepare"
							labelText="Prepare"
							color="primary"
							isChecked={prepare}
							clickHandler={() => setPrepare(!prepare)}
							eleClassName="checkbox-prepare"
						/>
						<DLCheckbox
							eleTestId="checkbox-review"
							labelText="Review"
							color="primary"
							isChecked={review}
							clickHandler={() => setReview(!review)}
							eleClassName="checkbox-review"
						/>
					</div>
				</InputWithLabel>
			</div>
		</StyledAddFileSignOffForm>
	)
}

const StyledAddFileSignOffForm = styled.div`
	.signoff-checkboxes {
		padding-top: 6px;
	}
	.checkbox-review {
		margin-left: 2rem;
	}
`
