import { types } from "mobx-state-tree"
// import ReadProjRoleSet from "./model-actions/read-proj-role-set"
import ReadProjRolePermissions from "./model-actions/read-proj-role-details"
import ReadAssignedUsers from "./model-actions/read-assigned-users"
import ReadRoleSet from "../../../organization-side/org-access-control/org-checkin/store/model-actions/read-role-set"

const ProjRolesModel = ({
	apiReadProjectUsers,
}: {
	apiReadProjectUsers: any
}) =>
	types
		.model({})
		// .actions(ReadProjRoleSet)
		.actions(ReadProjRolePermissions)
		.actions(ReadRoleSet)
		.actions((self) => ReadAssignedUsers(self, apiReadProjectUsers))

export default ProjRolesModel
