import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"

export default observer(function WPStatusTable({
	partialStore,
}: {
	partialStore: any
}) {
	const data = partialStore.viewWPStatusDetails()
	return (
		<StyledWPStatusTable>
			<h2>Workpaper Status</h2>
			<ReactTableV8
				tableColumns={columns()}
				showPageSetting={false}
				data={data}
				hasPagination={false}
				menuId="WPStatusTable"
				height={400}
			/>
		</StyledWPStatusTable>
	)
})

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns = () => [
	{
		header: "",
		accessorKey: "clientName",
		cell: renderCustomCell,
	},
	{
		header: "Preparation Status",
		columns: [
			{
				header: "Not Started",
				accessorKey: "notStarted",
				cell: renderCustomCell,
			},
			{
				header: "Prepared",
				accessorKey: "prepared",
				cell: renderCustomCell,
			},
			{
				header: "Total WP",
				accessorKey: "total",
				cell: renderCustomCell,
			},
		],
	},
	{
		header: "Review Status",
		columns: [
			{
				header: "Reviewed",
				accessorKey: "reviewed",
				cell: renderCustomCell,
			},
			{
				header: "Engagement Partner",
				accessorKey: "engagementPartner",
				cell: renderCustomCell,
			},
			{
				header: "Concurring Partner",
				accessorKey: "concurringPartner",
				cell: renderCustomCell,
			},
		],
	},
]

const StyledWPStatusTable = styled.div`
	table > thead > tr {
		border: 1px solid black !important;
		th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				border-left: 1px solid black;
				justify-content: center;
				div {
					justify-content: center;
				}
			}
		}
	}
`
