import { types, applySnapshot } from "mobx-state-tree"
// ---------- common parts
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ----------
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { initialStore } from "./pf.provider"

const InitializeStore = (self: any) => ({
	initializeStore() {
		applySnapshot(self, initialStore)
	},
})

const PfViewModel = types
	.model({
		// WARNING: Check the "file-control" in the same folder
		// to check the common part
		storeName: types.enumeration<DLProjSubMenus>(
			Object.values(DLProjSubMenus)
		),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})

	// common parts
	.actions(InitializeStore)
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default PfViewModel
