import { types } from "mobx-state-tree"
import ProjRolesModel from "./proj-roles.model"
import ProjRolesViewModel from "./proj-roles.view-model"
import ProjRolesUiHelper from "./proj-roles.ui-helper"
import * as projUserApis from "../../../../service-modules/user-module/proj-user-shared-apis"
import { initialRoleSet2 } from "../../../../service-modules/role-module/roles.data-model"
// common enums

const ProjPermissionsStore = ({
	apiReadProjectUsers,
}: {
	apiReadProjectUsers: any
}) =>
	types
		.compose(
			ProjRolesModel({
				apiReadProjectUsers,
			}),
			ProjRolesViewModel,
			ProjRolesUiHelper
		)
		.named("ProjPermissionsStore")

export const initialStore = {
	roleSet: initialRoleSet2,
	selectedRole: "",
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjPermissionsStoreInstance = ProjPermissionsStore({
	apiReadProjectUsers: projUserApis.readProjectUsers,
}).create(initialStore)

export default ProjPermissionsStoreInstance
