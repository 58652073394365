import React, { ReactElement } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../basic-elements"

const ApprovalDialog = observer(
	({
		title,
		msg,
		dialogAction,
		dialogBtnText,
		open,
		setOpen,
		actionStatus,
		approvalType,
	}: {
		title: string | ReactElement
		msg: string | ReactElement
		dialogAction: any
		dialogBtnText: string
		open: boolean
		setOpen: any
		actionStatus: any
		approvalType?: "delete" | "restore"
	}) => {
		return (
			// NOTE: It seems fine to use DLDialog directly in the partne screen.
			// But if we need some general update on this, current separated component will be better
			// and also it seems better to use 'approval-dialog' for all the similar dialog to test
			<DLDialog
				eleTestId="approval-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showCloseBtn={true}
				showOpenBtn={false}
				dialogTitle={title}
				dialogContents={<div>{msg}</div>}
				actionReady={true}
				handleAction={dialogAction}
				actionBtn={dialogBtnText}
				actionBtnType={approvalType}
				cancelBtnText="Cancel"
				showSpinner={actionStatus === "LOADING"}
				cannotUpdate={actionStatus === "LOADING"}
			/>
		)
	}
)

export default ApprovalDialog
