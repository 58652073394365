import React, { useState, useEffect } from "react"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import styled from "styled-components"
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
	DatePicker,
} from "@material-ui/pickers"
import { TextField } from "@material-ui/core"
import DLIconButton from "../buttons/DLIconButton"
import Icon from "@mdi/react"
import { mdiMagnify, mdiClose } from "@mdi/js"
import { ConsoleLog } from ".."

interface DatePickerProps {
	eleTestId: string
	selected?: any
	disabled?: boolean
	eleName?: string
	onChange(value: any): any
	noMargin?: boolean
	eleLabel?: string
	fullWidth?: boolean
	showClearIcon?: boolean
}

export default function DLYearpicker({
	eleTestId = "dl-date-picker",
	selected = null,
	disabled = false,
	eleName = "",
	onChange,
	noMargin,
	eleLabel = "",
	fullWidth,
	showClearIcon = false,
}: DatePickerProps) {
	const [selectedDate, setSelectedDate] = React.useState<any>(null)

	useEffect(() => {
		setSelectedDate(
			selected === "All Years" ? null : new Date(selected, 0, 1)
		) // to make 2020-01-01
	}, [selected])

	const handleDateChange = (date: any) => {
		setSelectedDate(date)
		onChange(new Date(date).getFullYear())
	}

	const canceldate = () => {
		setSelectedDate(null)
		onChange("All Years")
	}

	const TextFieldComponent = (props: any) => {
		return (
			<div className="FR JSB AC">
				<TextField
					{...props}
					value={props.value || "All"}
					data-testid="year-select"
				/>
				{props.value !== "" && showClearIcon && (
					<DLIconButton
						eleClassName="reset-query"
						eleTestId="clear-years"
						clickHandler={canceldate}
						size="small"
					>
						<Icon path={mdiClose} size={0.7} />
					</DLIconButton>
				)}
			</div>
		)
	}

	return (
		<DLStyledDatePicker
			className={`dl-date-picker-container ${fullWidth && "fullWidth"}`}
			data-testid={eleTestId}
		>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<DatePicker
					margin="normal"
					// variant="inline" // TODO: Because inline variant doesn't allow to set individual ID
					// NOTE: Use dialog type temporarilly. for test and styling
					value={selectedDate}
					name={eleName}
					format="yyyy"
					onChange={handleDateChange}
					views={["year"]}
					autoOk
					TextFieldComponent={TextFieldComponent}
					DialogProps={{ id: "year-select-dialog" }}
				/>
			</MuiPickersUtilsProvider>
		</DLStyledDatePicker>
	)
}

const DLStyledDatePicker = styled.div`
	&.dl-date-picker-container {
		/* min-width: 132px; */
		/* width: 150px; */
		width: 100%;
		&.fullWidth {
			width: 100%;
			.custom-style {
				width: 100%;
			}
		}
		.custom-style {
			&.no-margin {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
		.MuiFormControl-marginNormal {
			margin: 0;
		}
	}
`
