import React from "react"
import { observer } from "mobx-react-lite"
import {
	DLBasicToggleButton,
	DLRadioGroup,
	DLSingleSelect,
} from "../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import {
	NotificationProps,
	NotificationType,
} from "./store/data-models/notification-settings.data-models"
import { mdiInformationOutline } from "@mdi/js"
import { Icon } from "@mdi/react"

const CommonNotification = observer(({
	projInfoDates,
	notification,
	handleChangeNotification,
	handleRadio,
	handleStartDate,
	isLocked,
	notificationType,
}: {
	projInfoDates: any
	notification: NotificationProps
	handleChangeNotification: any
	handleRadio: any
	handleStartDate: any
	isLocked: boolean
	notificationType: NotificationType
}) => {
	const TooltipInfo = ({ parameterName }: { parameterName: string }) => {
		return (
			<div>
				<span>
					Archive Date Policy:
					{projInfoDates.archPolicyPeriod}
				</span>
				<br />
				{/* TODO: WARNING: paramName..?? */}
				{parameterName === "From Final Issuance Date" ? (
					<>
						<span>
							Final Report Date: {projInfoDates.finalReportDate}
						</span>
						<br />
						<span>
							Final Archive Deadline Date:{" "}
							{projInfoDates.finalArchiveDeadlineDate}
						</span>
					</>
				) : (
					<>
						<span>
							Expected Report Date:{" "}
							{projInfoDates.expectedReportDate}
						</span>
						<br />
						<span>
							Expected Archive Deadline Date:
							{projInfoDates.expectedArchiveDeadlineDate}
						</span>
					</>
				)}
			</div>
		)
	}
	return (
		<>
			<div className="FR" style={{ marginTop: "2.4rem" }}>
				<h4 className="notification-title main-header">
					{notification.displayValue}
				</h4>
				{notification.subSettings.map((setting: any) => {
					return (
						<div
							key={`sub-title-${setting.parameterName}`}
							className="notification-title sub-header"
						>
							{setting.parameterName}
						</div>
					)
				})}
			</div>
			<hr />
			<div className="FR">
				<div className="sub-title"></div>
				<div className="switch-button FC">
					<DLBasicToggleButton
						on={notification.isActive}
						setOn={() =>
							handleChangeNotification(
								notification,
								notificationType
							)
						}
						tooltipText={
							!notification.isActive ? "Turn ON" : "Turn OFF"
						}
						disabled={isLocked}
						eleTestId={`toogle-${notification.parameterValue}`}
					/>
				</div>

				{notification.subSettings.map((setting: any) => {
					return (
						<>
							{setting.parameterName === "Frequency" ? (
								<div key={setting.settingId}>
									<DLRadioGroup
										eleTestId={
											notification.parameterValue
										}
										items={setting.availableSettings}
										selectedValue={
											setting.parameterValue
										}
										color="primary"
										size="small"
										itemDirection="row"
										eleOnChange={(event: any) =>
											handleRadio(
												notification,
												setting.settingId,
												event,
												setting.availableSettings,
												notificationType
											)
										}
										groupName="feature"
										disabled={!notification.isActive}
									/>
								</div>
							) : (
								<div key={`select-${setting.settingId}`}>
									<DLSingleSelect
										eleTestId={
											notification.parameterValue +
											"-start-selection"
										}
										eleValue={setting.parameterValue}
										eleFullWidth
										withLabel={false}
										optionList={
											setting.availableSettings
										}
										eleDisabled={!notification.isActive}
										eleOnChange={(event: any) =>
											handleStartDate(
												notification,
												setting.settingId,
												event,
												setting.availableSettings,
												notificationType
											)
										}
									/>
								</div>
							)}
						</>
					)
				})}
				<div className="FR JSA tooltip">
					{notification.parameterName}
					<DLTooltip
						title={
							<TooltipInfo
								parameterName={notification.parameterName}
							/>
						}
						elePlacement="right"
					>
						<Icon path={mdiInformationOutline} size={1} />
					</DLTooltip>
				</div>
			</div>
		</>
	)
})

export default CommonNotification
