import { types } from "mobx-state-tree"
// ---------- common models
import { MessageColorType } from "../common-models/enumerations/common-enums"

// snackbar 2 (with message in it)
const ResponseSnackbar = types
	.model({
		open: types.optional(types.boolean, false),
		type: types.optional(
			types.enumeration<MessageColorType>(
				Object.values(MessageColorType)
			),
			MessageColorType.default
		),
		message: types.union(types.undefined, types.string),
		autoHideDuration: types.optional(
			types.union(types.number, types.null),
			3000
		),
		autoHide: types.optional(types.boolean, false),
		msgCode: types.optional(types.string, ""),
	})
	.actions((self) => ({
		setMessage(message: string | undefined) {
			self.message = message
		},
		setOpen(request: boolean) {
			self.open = request
		},
		setType(type: MessageColorType) {
			self.type = type
		},
		setAutoHideDuration(duration: number) {
			self.autoHideDuration = duration
		},
		setAutoHide(request: boolean) {
			self.autoHide = request
		},
		setMsgCode(msgCode: string) {
			self.msgCode = msgCode
		},
	}))

export default ResponseSnackbar
