import React, { useEffect } from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../../components/basic-elements"
import styled from "styled-components"
import RationalesController from "../../../rationales/RationalesController"

export default observer(function RationaleDialog() {
	const projStore = useProjStore()
	const actionName = "getRationaleFiles"
	useEffect(() => {
		projStore.archive.getRationaleFiles()
	}, [])

	return (
		<DLDialog
			isOpen={projStore.archive.rationaleDialogOpen}
			setIsOpen={projStore.archive.setRationaleDialogOpen}
			showCloseBtn
			eleTestId="archive-dialog"
			dialogTitle={<div className="FR AC">Rationale</div>}
			dialogContents={
				<>
					{projStore.archive.getActionStatus(actionName) ===
						"SUCCESS" && <RationaleDialogContents />}
					{projStore.archive.getActionStatus(actionName) ===
						"FAIL" && (
						<div>
							Please set the Final Report Date on the Project
							Information to check workpapers and permanent files
							which need to add Rationale.
						</div>
					)}
				</>
			}
			// handleAction={handleOnSubmit}
			maxWidth="xl"
			actionBtn={false}
			openBtn={false}
			showSpinner={
				projStore.archive.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

const RationaleDialogContents = observer(() => {
	const projStore = useProjStore()

	return (
		<StyledRationaleDialog>
			<div className="summary-area FC">
				<div className="FR AC">
					Total Files :{" "}
					{projStore.archive.viewRationaleFiles().length}
				</div>
				<div className="FR AC">
					(Workpapers: {projStore.archive.getRationaleWpLength} /
					Permanent Files: {projStore.archive.getRationalePfLength})
				</div>
			</div>
			<hr />
			{/* <ReactTableV8
				tableColumns={RationaleFilesColumns(handleEdit, handleDelete)}
				customFilters={filterComponent}
				data={projStore.archive.viewRationaleFiles()}
				menuId="RationaleDialog"
				showPageSetting={false}
				hasPagination={false}
			/> */}
			<RationalesController fromDialog={true} />
		</StyledRationaleDialog>
	)
})

const StyledRationaleDialog = styled.div`
	.rt-table {
		padding-left: 0rem !important;
	}
	.page-container {
		width: 100% !important;
		height: 550px !important;
	}
`
