import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLDialog } from "../../../../components/basic-elements"
import { MenuList, MenuItem } from "@material-ui/core"
import * as LanguageIcons from "./language-icons"
import styled from "styled-components"
import { sharedChangeLanguage } from "./LanguageMenus"

export default observer(function LangaugeSelectionDialog({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: any
}) {
	return (
		<StyledDialog>
			<DLDialog
				eleTestId="access-management-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showCloseBtn={true}
				dialogTitle="Language Selection"
				dialogContents={<LangaugeSelectionContent setOpen={setOpen} />}
				maxWidth="sm"
				fullWidth={true}
			/>
		</StyledDialog>
	)
})

const LangaugeSelectionContent = observer(({ setOpen }: { setOpen: any }) => {
	const store = useRootStore()

	const changeLanguage = (value: any) => {
		const postAction = () => setOpen(false)
		sharedChangeLanguage({ value, store, postAction })
	}

	const languageOptions = [
		{
			label: "English",
			value: "en-UK",
			icon: LanguageIcons.UK(),
		},
		{
			label: "English",
			value: "en-US",
			icon: LanguageIcons.US(),
		},
		{
			label: "English",
			value: "en-IN",
			icon: LanguageIcons.IN(),
		},
		{
			label: "Korean",
			value: "ko-KR",
			icon: LanguageIcons.KR(),
		},
	]

	let currentLanguage = store.i18n.language
	return (
		<MenuList>
			{languageOptions.map((option, index) => {
				return (
					<div
						className="menu-item-container FC"
						data-testid={`menu-item-${option.value}`}
						key={option.value + index}
					>
						<MenuItem
							className="menu-item FR AC"
							onClick={() => changeLanguage(option.value)}
							selected={option.value === currentLanguage}
						>
							<div
								className="menu-item-icon FR"
								style={{ marginRight: 4 }}
							>
								{option.icon && option.icon}
							</div>
							<div className="FR AC">{option.label}</div>
						</MenuItem>
					</div>
				)
			})}
		</MenuList>
	)
})

const StyledDialog = styled.div`
	.MuiDialog-container {
		height: 580px;
	}
	.dl-dialog-content {
		height: 440px !important;
	}
`
