import React from "react"
import { observer } from "mobx-react-lite"
import { StyledHeader } from "./StyledHeader"
import { LanguageMenus, ProfileMenus } from "./sub-components"
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
import styled from "styled-components"
import { DLIconButton } from "../../basic-elements"
import Icon from "@mdi/react"
import { mdiHelp } from "@mdi/js"
import { userguideUrl } from "../../../assets/datalobby-info"

export default observer(function LobbyHeader() {
	return (
		<StyledLobbyHeaderContainer>
			<StyledHeader className="grow">
				<AppBar position="static" className="app-bar">
					<Toolbar className="toolbar">
						<div className="aul-logo">
							Organization List
							{/* <AulLogo /> */}
						</div>
						<div className="grow" />
						<LanguageMenus />
						<div style={{ width: 8 }} />
						<ProfileMenus />
						<div style={{ width: 4 }} />
						<DLIconButton
							eleClassName="header-btn help-info-btn"
							eleTestId="help-info-btn"
							tooltipText="User Guide"
							clickHandler={() => window.open(userguideUrl)}
						>
							<Icon path={mdiHelp} size={0.7} />
						</DLIconButton>
					</Toolbar>
					<div className="bottom-line-container FR JC">
						<div className="bottom-line" />
					</div>
				</AppBar>
			</StyledHeader>
		</StyledLobbyHeaderContainer>
	)
})

const StyledLobbyHeaderContainer = styled.div`
	width: 100vw;
	.toolbar {
		padding-right: 4rem;
		.aul-logo {
			margin-top: 4px;
			margin-left: 3rem;
			svg {
				height: 1.3rem;
				margin-left: 1rem;
			}
		}
	}
	.bottom-line-container {
		background-color: ${(props) => props.theme.background};
		.bottom-line {
			background-color: ${(props) => props.theme.secondaryDeep};
			height: 1px;
			width: calc(100% - 8rem);
		}
	}
`
