import styled from "styled-components"

const StyledCheckin = styled.div`
	width: 100vw;
	height: 100vh;
	position: absolute;
	top: 0;
	left: 0;
	z-index: 999;
	background-color: ${(props) => props.theme.background};
	.progress-circles {
		position: absolute;
		width: 100vw;
		height: 100vh;
		display: flex;
		flex-direction: column;
		align-items: center;
		justify-content: center;
		.text-inifo {
		}
		.steps {
			margin-top: 1rem;
			display: flex;
			align-items: center;
			justify-content: center;
			.progress-step {
				margin-right: 1rem;
				width: 0.5rem;
				height: 0.5rem;
				border-radius: 50%;
				background-color: ${(props) => props.theme.shade20};
				&.SUCCESS {
					background-color: ${(props) => props.theme.emphasis};
				}
				&.FAIL {
					background-color: ${(props) => props.theme.warningDeep};
				}
			}
		}
	}
`
export default StyledCheckin
