import { flow } from "mobx-state-tree"
import { OrgGroupApiCallProps } from "../../group-module/org-group-shared-apis.calling-props"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

const RequestMoveProject = (
	self: any,
	moveProject: OrgGroupApiCallProps["apiMoveProject"]
) => ({
	requestMoveProject: flow(function* ({
		projId,
		moveTo,
	}: {
		projId: string
		moveTo: string
	}) {
		const actionName = "requestMoveProject"
		// 1.
		if (projId === undefined || moveTo === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. projId: ${projId} / moveTo: ${moveTo}`
			)
			return
		}
		// 2.
		const projNumberId = idToNumber(projId, IdType.project)
		const destinationCabinetNumberId = idToNumber(moveTo, IdType.cabinet)

		if (
			typeof projNumberId === "number" &&
			typeof destinationCabinetNumberId === "number"
		) {
			try {
				let payload = {
					ClientID: projNumberId,
					CabinetID: destinationCabinetNumberId,
					// UserID: 16
				}
				const response = yield moveProject(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) ${projNumberId} ? ${destinationCabinetNumberId}`
			)
		}
	}),
})

export default RequestMoveProject
