import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 *  - low priority. omitted temporary.
 */
export const AddOrgCustomer = (self: any) => ({
	addOrgCustomer(custInfo: any) {
		// 0.
		const actionName = "addOrgCustomer"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			CustName: custInfo.name.value,
			CustUserName: custInfo.customerId.value,
			EmailID: custInfo.email.value,
			OrganisationName: custInfo.organization.value,
		}
		self.createOrgCustomer(payload)
			// read()
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// set success response
					const newItem = {
						id: response.data.CustID.toString(),
						custName: response.data.CustName,
						custId: response.data.CustUserName,
						emailId: response.data.EmailID,
						organizationName: response.data.OrganisationName,
						createdBy: response.data.CreatedBy,
						custStatus: response.data.CustStatus,
					}
					self.pushItemToList(newItem)
					self.setOpenAdd(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 1,
						customMessage: "Successfully created the customer",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						message:
							response.data.Message ||
							response.data.message ||
							"Something went wrong",
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddOrgCustomer
