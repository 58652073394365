import React from "react"
import IconButton from "@material-ui/core/IconButton"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"

interface IconButtonProps {
	children?: any
	/** "default" | "primary" | "secondary" | "warning" */
	color?: "default" | "primary" | "secondary" | "orange" | "warning"
	/** "small" | "medium" | "large" */
	size?: "small" | "medium" | "large"
	disabled?: boolean
	/** "left" | "right" | "center" | "stretched" */
	align?: "left" | "right" | "center" | "stretched"
	clickHandler?: any
	eleTestId: string
	eleClassName?: string
	/** "contained" | "outlined" | "iconOnly" */
	variant?: "contained" | "outlined" | "iconOnly"
	onMouseDown?: any // TODO:
	tooltipText?: string
	tooltipPlacement?:
		| "bottom-end"
		| "bottom-start"
		| "bottom"
		| "left-end"
		| "left-start"
		| "left"
		| "right-end"
		| "right-start"
		| "right"
		| "top-end"
		| "top-start"
		| "top"
}

/**
 * This is for the storybook documentation
 *
 * ### Note: About 'Size'
 * Because `Icon` requires size property in it, button size do not control the svg icon size directly.
 *
 * But the size of the button can restrict the icon size.
 * If you want to use large size icon, update both the button size and icon size
 * (Recomend to use icon size 1 even the button size is large)
 */

export default function DLIconButton({
	children = undefined,
	color = "default",
	size = "medium",
	disabled = undefined,
	align = "center",
	clickHandler = undefined,
	eleClassName = undefined,
	eleTestId = "testId-DLIconButton",
	variant = undefined,
	onMouseDown = undefined,
	tooltipText = undefined,
	tooltipPlacement = "bottom",
}: IconButtonProps) {
	const isDisabled = disabled ? "disabled" : ""

	return (
		<StyledIconButton className={`${align} ${disabled && "disabled"}`}>
			{tooltipText ? (
				<DLTooltip title={tooltipText} elePlacement={tooltipPlacement}>
					<div>
						<IconButton
							onClick={clickHandler}
							className={`button-base ${variant} ${color} ${size} ${isDisabled} ${eleClassName}`}
							disabled={disabled}
							onMouseDown={onMouseDown && onMouseDown}
							data-testid={eleTestId}
						>
							{children}
						</IconButton>
					</div>
				</DLTooltip>
			) : (
				<IconButton
					onClick={clickHandler}
					className={`button-base ${variant} ${color} ${size} ${isDisabled} ${eleClassName}`}
					disabled={disabled}
					onMouseDown={onMouseDown && onMouseDown}
					data-testid={eleTestId}
				>
					{children}
				</IconButton>
			)}
		</StyledIconButton>
	)
}

const StyledIconButton = styled.div`
	height: 100%;
	display: flex;
	align-items: center;
	justify-content: center;
	flex-shrink: 0;
	&.disabled {
		color: ${(props) => props.theme.shade20};
		fill: ${(props) => props.theme.shade20};
		button.button-base {
			color: ${(props) => props.theme.shade20};
			fill: ${(props) => props.theme.shade20};
		}
	}
	&.right {
		justify-content: flex-end;
	}
	&.left {
		justify-content: flex-start;
	}
	&.stretched {
		justify-content: stretch;
	}
	.button-base {
		display: flex;
		align-items: center;
		justify-content: center;
		border-radius: 100%;
		cursor: pointer;
		box-shadow: none;
		transition: 0.2s;
		background-color: transparent;
		padding: 0;
		color: inherit;
		/* color: ${(props) => props.theme.textHighEmphasis}; */
		&.small {
			width: calc(${(props) => props.theme.shared.baseUnit} / 2);
			height: calc(${(props) => props.theme.shared.baseUnit} / 2);
			padding: 0 0.2rem;
		}
		&.medium {
			width: calc(${(props) => props.theme.shared.baseUnit} / 1.5);
			height: calc(${(props) => props.theme.shared.baseUnit} / 1.5);
			padding: 0 0.2rem;
		}
		&.large {
			width: ${(props) => props.theme.shared.baseUnit};
			height: ${(props) => props.theme.shared.baseUnit};
		}
	}
	.default {
		/* fill: ${(props) => props.theme.shade60}; */
		:hover {
			background-color: ${(props) => props.theme.shade10};
			fill: ${(props) => props.theme.textMediumEmphasis};
		}
		&.contained {
			background-color: ${(props) => props.theme.shade20};
			fill: ${(props) => props.theme.shade60};
			:hover {
				background-color: ${(props) => props.theme.shade40};
				fill: ${(props) => props.theme.textMediumEmphasis};
			}
		}
		&.outlined {
			background-color: transparent;
			fill: ${(props) => props.theme.shade60};
			border: 1px solid ${(props) => props.theme.shade20};
			svg {
				margin-top: -1px;
			}
			:hover {
				background-color: ${(props) => props.theme.shade20};
				fill: ${(props) => props.theme.textMediumEmphasis};
			}
		}
	}
	.primary {
		fill: ${(props) => props.theme.emphasis};
		color: ${(props) => props.theme.emphasis};
		:hover {
			/* background-color: ${(props) => props.theme.emphasisDeep}; */
			fill: ${(props) => props.theme.emphasisDeep};
			color: ${(props) => props.theme.emphasis};
		}
		svg {
			/* color: ${(props) => props.theme.txtOnEmphasisColor}; */
		}
		&.contained {
			background-color: ${(props) => props.theme.emphasis};
			fill: ${(props) => props.theme.txtOnEmphasisColor};
			color: ${(props) => props.theme.txtOnEmphasisColor};
			:hover {
				background-color: ${(props) => props.theme.emphasisDeep};
			}
		}
		&.outlined {
			background-color: transparent;
			fill: ${(props) => props.theme.emphasis};
			border: 1px solid ${(props) => props.theme.emphasis};
			svg {
				margin-top: -1px;
			}
			:hover {
				background-color: ${(props) => props.theme.emphasis};
				fill: ${(props) => props.theme.txtOnEmphasisColor};
				color: ${(props) => props.theme.txtOnEmphasisColor};
			}
		}
	}
	.secondary {
		fill: ${(props) => props.theme.emphasis};
		:hover {
			background-color: ${(props) => props.theme.secondary};
			fill: ${(props) => props.theme.emphasis};
		}
		svg {
			color: ${(props) => props.theme.emphasis};
		}
		&.contained {
			background-color: ${(props) => props.theme.primaryLight};
			fill: ${(props) => props.theme.textMediumEmphasis};
			:hover {
				background-color: ${(props) => props.theme.emphasis};
				fill: ${(props) => props.theme.textHighEmphasis};
			}
		}
		&.outlined {
			background-color: transparent;
			fill: ${(props) => props.theme.emphasis};
			border: 1px solid ${(props) => props.theme.emphasis};
			svg {
				margin-top: -1px;
			}
			:hover {
				background-color: ${(props) => props.theme.emphasis};
				fill: ${(props) => props.theme.background};
			}
		}
	}
	.orange {
		fill: ${(props) => props.theme.themeYellow};
		color: ${(props) => props.theme.themeYellow};
		:hover {
			background-color: ${(props) => props.theme.secondary};
			fill: ${(props) => props.theme.themeOrange};
			color: ${(props) => props.theme.themeOrange};
		}
		svg {
			color: ${(props) => props.theme.themeYellow};
			fill: ${(props) => props.theme.themeYellow};
		}
		&.contained {
			background-color: ${(props) => props.theme.themeYellow};
			fill: ${(props) => props.theme.background};
			:hover {
				background-color: ${(props) => props.theme.themeOrange};
			}
		}
		&.outlined {
			background-color: transparent;
			fill: ${(props) => props.theme.themeYellow};
			border: 1px solid ${(props) => props.theme.themeYellow};
			svg {
				margin-top: -1px;
			}
			:hover {
				background-color: ${(props) => props.theme.themeYellow};
				fill: ${(props) => props.theme.background};
				color: ${(props) => props.theme.background};
			}
		}
	}
	.warning {
		fill: ${(props) => props.theme.warning};
		color: ${(props) => props.theme.warning};
		:hover {
			background-color: ${(props) => props.theme.secondary};
			fill: ${(props) => props.theme.warningDeep};
			color: ${(props) => props.theme.warningDeep};
		}
		svg {
			color: ${(props) => props.theme.warningDeep};
			fill: ${(props) => props.theme.warningDeep};
		}
		&.contained {
			background-color: ${(props) => props.theme.warning};
			fill: ${(props) => props.theme.background};
			:hover {
				background-color: ${(props) => props.theme.warningDeep};
			}
		}
		&.outlined {
			background-color: transparent;
			fill: ${(props) => props.theme.warning};
			border: 1px solid ${(props) => props.theme.warning};
			svg {
				margin-top: -1px;
			}
			:hover {
				background-color: ${(props) => props.theme.warning};
				fill: ${(props) => props.theme.background};
			}
		}
	}
	.disabled {
		fill: ${(props) => props.theme.shade10};
		cursor: not-allowed;
		pointer-events: none;
		:hover {
			background-color: transparent;
			fill: ${(props) => props.theme.shade10};
		}
	}
`
