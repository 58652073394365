import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import OrgSetupChecklist from "./OrgSetupChecklist"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function OrgSetupChecklistController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_checklist
	const actionName = "getChecklistTemplates"
	const i18nSheet = OrgI18n.checklist
	const i18n = store.i18n.combineI18n(i18nSheet)

	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.setupChecklist.needRefresh

	const orgIdForCdk = "org" + orgStore.checkin.orgInfo.orgId.slice(5)

	useEffect(() => {
		menuAccess &&
			needRefresh &&
			orgStore.setupChecklist.getChecklistTemplates({
				orgId: orgIdForCdk,
			})
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<>
					<OrgSetupChecklist
						partialStore={orgStore.setupChecklist}
						fetchingStatus={orgStore.setupChecklist.getActionStatus(
							actionName
						)}
						// permission={permission}
						i18n={i18n}
						// dateFormat={store.global.dateFormat.value}
						contentHeight={store.ui.contentsAreaHeight}
					/>
				</>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
