import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
	deleteRequest,
} from "../../../../../library/api-requests"

export async function read(params: { clientId: number }) {
	const response = await getRequest({
		url: "/GetEliminatingEntryDetails",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function create(payload: {
	AccountDescription: string
	AccountNumber: string
	ClientID: number
	Credit: string
	Debit: string
	EntityID: number
	Year: string
}) {
	const response = await postRequest({
		url: "/CreateEliminatingEntry",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function update(payload: {
	AccountDescription: string
	AccountNumber: string
	Credit: string
	Debit: string
	EntityID: number
	Year: string
	EliminatingEntryID: number
}) {
	const response = await putRequest({
		url: "/EditEliminatingEntry",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function deleteEE(payload: { EliminatingEntryID: number }) {
	const response = await deleteRequest({
		url: "/DeleteEliminatingEntry",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readAccDetails(params: { EntityID: number }) {
	const response = await getRequest({
		url: "/GetAccountDetails",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type EliminatingEntryApiProps = {
	apiRead: (payload: { clientId: number }) => Promise<AxiosResponse<any>>
	apiCreate: (payload: {
		AccountDescription: string
		AccountNumber: string
		ClientID: number
		Credit: string
		Debit: string
		EntityID: number
		Year: string
	}) => Promise<AxiosResponse<any>>
	apiUpdate: (payload: {
		AccountDescription: string
		AccountNumber: string
		Credit: string
		Debit: string
		EntityID: number
		Year: string
		EliminatingEntryID: number
	}) => Promise<AxiosResponse<any>>
	apiReadTrialBalance: (payload: {
		clientId: number
	}) => Promise<AxiosResponse<any>>
	apiReadAccDetails: (payload: {
		EntityID: number
	}) => Promise<AxiosResponse<any>>
	apiDeleteEE: (payload: {
		EliminatingEntryID: number
	}) => Promise<AxiosResponse<any>>
}
