import { types, applySnapshot } from "mobx-state-tree"
import {
	ArchiveHistoryFile,
	ArchiveHistoryItem,
} from "./data-models/archive-history.data-models"

// ---------- common models

import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

// ----------- view model actions
import GetArchiveHistoryList from "./view-model-actions/get-archive-history-list"
import { format } from "date-fns"
import { initialStore } from "./archive-history.provider"
import {
	getTzDate,
	getTzDateAndTime,
} from "../../../../../library/converters/date-utc-converter"

const ArchiveHistoryViewModel = types
	.model({
		archiveHistoryList: types.array(ArchiveHistoryItem),

		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.archiveHistoryList.length = 0
		},
		pushItemToList(item: any) {
			self.archiveHistoryList.push(item)
		},
	}))
	.actions(GetArchiveHistoryList)
	.views((self) => ({
		viewArchiveHistoryList() {
			return self.archiveHistoryList
		},
		formattedArchiveHistoryList(
			dateFormat: string,
			timeZone: string,
			timeFormat: string
		) {
			// ConsoleLog(dateFormat)
			let formattedList: any[] = []
			self.archiveHistoryList.map((item: any) => {
				const formattedArchivedUnArchivedAt = item.archivedUnArchivedAt
					? getTzDateAndTime({
							date: item.archivedUnArchivedAt,
							timeZone,
							dateFormat,
							timeFormat,
					  })
					: ""
				formattedList.push({
					...item,
					archivedUnArchivedAt: formattedArchivedUnArchivedAt,
				})
			})
			// ConsoleLog("formattedArchiveHistoryList", formattedList)
			return formattedList
		},
		// formattedForExport(dateFormat: string, timeZone: string) {
		// 	let result: any[] = []
		// 	self.archiveHistoryList.map((historyItem: any) => {
		// 		const formattedDate = historyItem.archivedUnArchivedAt
		// 			? getTzDate({
		// 					date: historyItem.archivedUnArchivedAt,
		// 					timeZone,
		// 					dateFormat,
		// 			  })
		// 			: ""
		// 		result.push({
		// 			Type: historyItem.type,
		// 			ArchivedUnArchivedBy: historyItem.archivedUnArchivedBy,
		// 			// archiveId: historyItem.archiveId,
		// 			ArchivedUnArchivedDate: formattedDate,
		// 			// sourceProjectId: historyItem.sourceProjectId,
		// 			// SourceProjectType: historyItem.sourceProjectType,
		// 			SourceProjectName: historyItem.sourceProjectName,
		// 			// isDisabled: historyItem.IsGrayOut,
		// 			Reason: historyItem.reason,
		// 			//fileList: reOrganizedfileList, // [filesLists] ex)  [,…]
		// 			// clientId: historyItem.ClientID.toString(),
		// 		})
		// 	})
		// 	return result
		// },
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ArchiveHistoryViewModel
