import { flow } from "mobx-state-tree"

const RequestDuplicateProjTemplateGroup = (
	self: any,
	requestDuplicate: any
) => ({
	requestDuplicateProjTemplateGroup: flow(function* (
		templateGroupId: number
	) {
		const actionName = "requestDuplicateProjTemplateGroup"

		try {
			const payload = {
				SourceId: templateGroupId,
				Type: 1,
			}
			const response = yield requestDuplicate(payload)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
	}),
})

export default RequestDuplicateProjTemplateGroup
