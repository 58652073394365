import { types } from "mobx-state-tree"
import PassedAdjustmentsModel from "./passed-adjustments.model"
import PassedAdjustmentsViewModel from "./passed-adjustments.view-model"
// import PassedAdjustmentsUiHelper from "./adjustments.ui-helper"
import * as api from "./passed-adjustments.apis"
import { readTrialBalance } from "../../financial-statements/store/financial-statements.api"
import PassedAdjustmentsUiHelper from "./passed-adjustments.ui-helper"

export const PassedAdjustmentsStore = ({
	apiRead,
	apiReadTrialBalance,
	apiReadWP,
	apiCreate,
	apiDelete,
}: api.PassedAdjustmentsApiProps) =>
	types
		.compose(
			PassedAdjustmentsModel({
				apiRead,
				apiReadTrialBalance,
				apiReadWP,
				apiCreate,
				apiDelete,
			}),
			PassedAdjustmentsViewModel,
			PassedAdjustmentsUiHelper
		)
		.named("PassedAdjustmentsStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const PassedAdjustmentsStoreInstance = PassedAdjustmentsStore({
	apiRead: api.read,
	apiReadTrialBalance: readTrialBalance,
	apiReadWP: api.readWP,
	apiCreate: api.create,
	apiDelete: api.del,
}).create(initialStore)

export default PassedAdjustmentsStoreInstance
