import React from "react"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { Link } from "react-router-dom"
import {
	multiSelectFilter,
	dateRangeFilter,
	filterMethodForQCandEP,
	yearFilter,
} from "../../../../library/react-table-utils"
import { Icon } from "@mdi/react"
import { mdiDotsHorizontal, mdiLock } from "@mdi/js"
import { DLButton } from "../../../basic-elements"

const RemindProjectsTableColumns = (
	isArchiveAdmin: boolean,
	menuId: DLOrgSubMenus,
	partialStore: any,
	handleContextMenu: any
) => {
	const prefix: any = {
		[DLOrgSubMenus.remind_report]: "Report",
		[DLOrgSubMenus.admin_remind_report]: "Report",
		[DLOrgSubMenus.remind_archiving]: "Archiving",
		[DLOrgSubMenus.admin_remind_archiving]: "Archiving",
	}

	const columns = []

	columns.push(
		{
			header: "Action",
			accessorKey: "actions",
			size: 30,
			cell: (props: any) => {
				const id = props.row.original.id
				const handleClick = (event: any) => {
					partialStore.setSelectedItem({
						id,
					})
					handleContextMenu(event, props.row.original)
				}
				return (
					<div style={{ width: "100%", textAlign: "center" }}>
						<DLButton
							variant="text"
							eleTestId="remind-proj-context-btn"
							clickHandler={(event: any) => handleClick(event)}
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLButton>
					</div>
				)
			},
		},
		{
			header: `Due Days (${prefix[menuId]})`,
			accessorKey: "dueDays",
			sortDescFirst: true,
			cell: (props: any) => {
				return (
					<div className="FR AC JC" data-testid="remind-cell">
						{props.getValue()}
					</div>
				)
			},
		}
	)

	if (
		menuId === DLOrgSubMenus.remind_report ||
		menuId === DLOrgSubMenus.admin_remind_report
	) {
		columns.push(
			{
				header: (
					<div className="column-name">
						Expected Archive
						<br />
						Deadline Date
					</div>
				),
				accessorKey: "expectedArchiveDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div
							data-testid="expected-report-date-cell"
							style={{ marginLeft: 4 }}
						>
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Final
						<br />
						Report Date
					</div>
				),
				accessorKey: "finalReportDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="final-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			}
		)
	} else {
		columns.push(
			{
				header: (
					<div className="column-name">
						Final Archive
						<br />
						Deadline Date
					</div>
				),
				size: 100,
				accessorKey: "finalArchiveDeadlineDate",
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div
							data-testid="archive-at-date-cell"
							style={{ marginLeft: 4 }}
						>
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Final
						<br />
						Report Date
					</div>
				),
				accessorKey: "finalReportDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="final-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Archive
						<br />
						Date Policy
					</div>
				),
				accessorKey: "archiveDatePolicy",
				size: 82,
				cell: (row: any) => {
					return (
						<div data-testid="archiveDatePolicy-cell">
							{row.getValue()}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Expected Archive
						<br />
						Deadline Date
					</div>
				),
				accessorKey: "expectedArchiveDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="expected-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			},
			{
				header: (
					<div className="column-name">
						Expected
						<br />
						Report Date
					</div>
				),
				accessorKey: "expectedReportDate",
				size: 100,
				filterFn: dateRangeFilter,
				cell: (row: any) => {
					return (
						<div data-testid="expected-report-date-cell">
							{row.getValue() ? row.getValue() : "-"}
						</div>
					)
				},
			}
		)
	}

	columns.push(
		{
			header: "Period Type",
			accessorKey: "periodName",
			size: 80,
			filterFn: multiSelectFilter,
			cell: (row: any) => {
				return (
					<div data-testid="period-name-cell">{row.getValue()}</div>
				)
			},
		},
		{
			header: "Year",
			accessorKey: "financialYear",
			size: 82,
			filterFn: yearFilter,
			cell: (row: any) => {
				return (
					<div data-testid="financial-year-cell">
						{row.getValue()}
					</div>
				)
			},
		},
		{
			header: "Group",
			accessorKey: "groupName",
			filterFn: multiSelectFilter,
			size: 150,
			cell: (row: any) => {
				return <div data-testid="group-cell">{row.getValue()}</div>
			},
		},
		{
			header: "Client",
			accessorKey: "clientName",
			size: 150,
			filterFn: multiSelectFilter,
			cell: (row: any) => {
				return <div data-testid="client-cell">{row.getValue()}</div>
			},
		},
		{
			header: "Client Alias ID",
			accessorKey: "clientAliasId",
			size: 120,
			cell: (row: any) => {
				return <div data-testid="client-id-cell">{row.getValue()}</div>
			},
		},
		{
			header: <div className="column-name">Lock Status</div>,
			accessorKey: "lockStatus",
			size: 80,
			cell: (props: any) => {
				const cellId = props.column.id + "-cell"
				return (
					<div data-testid={cellId} className="FR JC">
						{props.row.original.isLocked && (
							<div className="FR AC regular-text">
								<Icon path={mdiLock} size={0.7} />
							</div>
						)}
					</div>
				)
			},
			filterFn: (row: any, columnId: any, value: any) => {
				if (value === "all") return true
				else return row.original.isLocked === (value === "locked")
			},
		},
		{
			header: "Project Name", // NOTE: Keep "project name" for end user
			accessorKey: "title",
			size: 300,
			cell: (props: any) => {
				const projId = props.row.original.id
				const projNumId = idToNumber(projId, IdType.project)
				const projTitle = props.getValue()
				const accessible = props.row.original.accessible
				return (
					<div
						className="project-title-cell"
						data-testid="project-title-cell"
						style={{ fontWeight: 600 }}
					>
						{accessible ? (
							<Link to={`/project/checkin/${projNumId}`}>
								{projTitle}
							</Link>
						) : (
							projTitle
						)}
					</div>
				)
			},
		},
		{
			header: "Project ID", // NOTE: Keep "project id" for end user
			accessorKey: "aliasId",
			size: 200,
			cell: (row: any) => {
				return (
					<div data-testid="project-aliasId-cell">
						{row.getValue()}
					</div>
				)
			},
		},
		// Normal project table doesn't show version
		// (Remind report and Remind archiving only use Normal projects)
		{
			header: "EP",
			accessorKey: "epUsers",
			filterFn: filterMethodForQCandEP,
			cell: (props: any) => {
				return (
					<div data-testid="ep-cell">
						{props.getValue().length > 0 ? (
							<>
								{props
									.getValue()
									.map((item: any, i: number) => {
										const valueLength =
											props.getValue().length
										return (
											<React.Fragment key={item.name}>
												{item.name}
												{valueLength > 1 &&
													i < valueLength - 1 &&
													", "}
											</React.Fragment>
										)
									})}
							</>
						) : (
							<span>-</span>
						)}
					</div>
				)
			},
		},
		{
			header: "QC",
			accessorKey: "qcUsers",
			filterFn: filterMethodForQCandEP,
			cell: (props: any) => {
				return (
					<div data-testid="qc-cell">
						{props.getValue().length > 0 ? (
							<>
								{props
									.getValue()
									.map((item: any, i: number) => {
										const valueLength =
											props.getValue().length
										return (
											<React.Fragment key={item.name}>
												{item.name}
												{valueLength > 1 &&
													i < valueLength - 1 &&
													", "}
											</React.Fragment>
										)
									})}
							</>
						) : (
							<span>-</span>
						)}
					</div>
				)
			},
		},
		{
			header: "Eng Type",
			accessorKey: "engTypeName",
			filterFn: multiSelectFilter,
			cell: (row: any) => {
				return <div data-testid="engType-cell">{row.getValue()}</div>
			},
		},
		{
			header: (
				<div className="column-name">
					Archive
					<br />
					Date Policy
				</div>
			),
			accessorKey: "archiveDatePolicy",
			size: 82,
			cell: (row: any) => {
				return (
					<div data-testid="archiveDatePolicy-cell">
						{row.getValue()}
					</div>
				)
			},
		},
		{
			header: "Period End Date",
			accessorKey: "periodEndDate",
			size: 100,
			filterFn: dateRangeFilter,
			cell: (row: any) => {
				return (
					<div data-testid="period-end-cell">
						{row.getValue() ? row.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: (
				<div className="column-name">
					Risk
					<br />
					Assessment
				</div>
			),
			accessorKey: "raName",
			size: 100,
			cell: (row: any) => {
				return (
					<div data-testid="risk-assessment-cell">
						{row.getValue() ? row.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: (
				<div className="column-name">
					Expected
					<br />
					Report Date
				</div>
			),
			accessorKey: "expectedReportDate",
			size: 100,
			filterFn: dateRangeFilter,
			cell: (row: any) => {
				return (
					<div data-testid="expected-report-date-cell">
						{row.getValue() ? row.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: (
				<div className="column-name">
					Expected Archive
					<br />
					Deadline Date
				</div>
			),
			accessorKey: "expectedArchiveDate",
			size: 100,
			filterFn: dateRangeFilter,
			cell: (row: any) => {
				return (
					<div data-testid="expected-report-date-cell">
						{row.getValue() ? row.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: (
				<div className="column-name">
					Final
					<br />
					Report Date
				</div>
			),
			accessorKey: "finalReportDate",
			size: 100,
			filterFn: dateRangeFilter,
			cell: (row: any) => {
				return (
					<div data-testid="final-report-date-cell">
						{row.getValue() ? row.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: (
				<div className="column-name">
					Final Archive
					<br />
					Deadline Date
				</div>
			),
			size: 100,
			accessorKey: "finalArchiveDeadlineDate",
			filterFn: dateRangeFilter,
			cell: (row: any) => {
				return (
					<div data-testid="archive-at-date-cell">
						{row.getValue() ? row.getValue() : "-"}
					</div>
				)
			},
		},
		{
			header: "Created Date",
			accessorKey: "createdAt",
			size: 100,
			filterFn: dateRangeFilter,
			cell: (row: any) => {
				return <div data-testid="createdAt-cell">{row.getValue()}</div>
			},
		},
		{
			header: "Created By",
			accessorKey: "createdBy",
			size: 80,
			accessorFn: (props: any) => props.createdBy.name,
			cell: (row: any) => {
				return <div data-testid="createdBy-cell">{row.getValue()}</div>
			},
		}
	)

	return columns
}

export default RemindProjectsTableColumns
