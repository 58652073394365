import {
	getRequest,
	putRequest,
	postRequest,
	deleteRequest,
} from "../../library/api-requests"
import { TOKEN_TYPE } from "../../library/api-requests/shared-for-api-requests"

/**
 * * TOC
 * - createGroupItem: group, cabinet in group
 * - deleteGroupItem: group, cabinet in group
 * - updateGroupItem: group, cabinet in group
 *
 * - readGroupUsers
 * - readClientsByGroup
 */

/**
 * ----------------------------------------
 */

export type CreateGroupItemApiProps = {
	CabinetName: string
	FolderType: number // 1: group, 2: cabinet
	ParentID: number // 0: group, !0: cabinet
	// UserID: number
}
export async function createGroupItem(params: CreateGroupItemApiProps) {
	const endpoint = "/CreateCabinet"
	const response = await postRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

// TODO: It seems.. Capital or small-letter not important to param...?
// It seems both CabinetId and CabinetID are working. Need to check
// or one of them are not work for API (blocked)
export type DeleteGroupItemApiProps = {
	CabinetID: number
	Type: string // "Group"
	// UserID: number
}
export async function deleteGroupItem(params: DeleteGroupItemApiProps) {
	const endpoint = "/DeleteCabinet"
	const response = await deleteRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

export type UpdateGroupItemApiProps = {
	CabinetID: number
	CabinetName: string
	// UserID: number
}
export async function updateGroupItem(payload: UpdateGroupItemApiProps) {
	const endpoint = "/EditCabinet"
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

export type ReadGroupUsersApiProps = {
	GroupID: number
	type: number // NOTE: type: 1 (in setup-groups) / type: 2 (in project list)
	// CustomerID: number,
}
export async function readGroupUsers(params: ReadGroupUsersApiProps) {
	const endpoint = "/GetAssignedUsers"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

// WARNING: This API is working without CustomerID
export type ReadClientsByGroupApiProps = {
	CustomerID?: number
	GroupID: number
	Type: number
}
export async function readClientsByGroup(params: ReadClientsByGroupApiProps) {
	const endpoint = "/GetClientMasterByGroup"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
