import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"

import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { Tabs, Tab } from "@material-ui/core"

import { items } from "./mock-datas/mock-items"
import {
	AsyncItemType,
	AsyncItemStatus,
	DownloadItem,
} from "./store/data-models/notifications.data-model"
import DownloadItemList from "./sub-components/DownloadItemList"
import { DLIconButton } from "../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiHelpCircleOutline } from "@mdi/js"

export default observer(function DownloadCenterOnDrawer() {
	const store = useRootStore()
	const [tab, setTab] = useState(0)
	const [currents, setCurrents] = useState<DownloadItem[]>([])
	const [prevItems, setPrevItems] = useState<DownloadItem[]>([])

	useEffect(() => {
		setCurrents(
			items.filter((item) => item.status !== AsyncItemStatus.downloaded)
		)
		setPrevItems(
			items.filter((item) => item.status === AsyncItemStatus.downloaded)
		)
	}, [])

	const [asyncItemType, setAsyncItemType] = useState<AsyncItemType | "all">(
		"all"
	)
	const [asyncItemStatus, setAsyncItemStatus] = useState<
		AsyncItemStatus | "all"
	>("all")
	const handleAsyncItemType = (event: any) => {
		const type = event.target.value
		setAsyncItemType(type)
	}
	const handleAsyncItemStatus = (event: any) => {
		const status = event.target.value
		setAsyncItemStatus(status)
	}

	const downloadAndMoveToPrevItems = (target: DownloadItem) => {
		setCurrents(
			currents.filter((item: DownloadItem) => item.id !== target.id)
		)
		setPrevItems([
			...prevItems,
			{ ...target, status: AsyncItemStatus.downloaded },
		])
	}

	return (
		<StyledDownloadCenterOnDrawer data-testid="">
			<div
				className="FR AC JC noti-header"
				data-testid="right-drawer-tab-header"
			>
				<h2>Download center</h2>
				<DLIconButton eleTestId="download-center-help">
					<Icon
						path={mdiHelpCircleOutline}
						size={0.8}
						style={{ opacity: 0.6 }}
					/>
				</DLIconButton>
			</div>
			{/* <div className="total-pending-items FR AC">
				Total pending items in the organization: 562
			</div> */}

			<Tabs
				value={tab}
				onChange={(e: any, value: any) => {
					console.log("value:", value)
					setTab(value)
				}}
				centered
				className="download-tabs"
			>
				<Tab
					label={`Current (${currents.length})`}
					data-testid="current-download-items-tab"
					className={"download-tab " + (tab === 0 ? "selected" : "")}
				/>
				<Tab
					label={`Previous (${prevItems.length})`}
					data-testid="previous-download-items-tab"
					className={"download-tab " + (tab === 1 ? "selected" : "")}
				/>
			</Tabs>
			<div className="list-container">
				{tab === 0 && (
					<DownloadItemList
						items={currents}
						asyncItemType={asyncItemType}
						handleAsyncItemType={handleAsyncItemType}
						asyncItemStatus={asyncItemStatus}
						handleAsyncItemStatus={handleAsyncItemStatus}
						downloadAndMoveToPrevItems={downloadAndMoveToPrevItems}
					/>
				)}
				{tab === 1 && (
					<DownloadItemList
						items={prevItems}
						asyncItemType={asyncItemType}
						handleAsyncItemType={handleAsyncItemType}
						asyncItemStatus={asyncItemStatus}
						handleAsyncItemStatus={handleAsyncItemStatus}
						downloadAndMoveToPrevItems={downloadAndMoveToPrevItems}
					/>
				)}
			</div>
		</StyledDownloadCenterOnDrawer>
	)
})

const StyledDownloadCenterOnDrawer = styled.div`
	.noti-header {
		margin-bottom: 1rem;
	}
	.total-pending-items {
		height: 3rem;
	}
	.download-tabs {
		min-height: 2rem;
		.download-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
		}
		span {
			/* for the border bottom highlighter */
			background-color: transparent;
		}
	}
	.list-container {
		padding-top: 1rem;
	}
`
