import React, { useState } from "react"
import { render } from "react-dom"
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd"
import { PageContainer } from "../../../../components/app-frame-elements"

const MyLibrary = () => {
	return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			fixedHeight
		>
			<div>My Library</div>
		</PageContainer>
	)
}

export default MyLibrary
