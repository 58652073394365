import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../library/api-requests"

export async function requestToken(params: {
	UserId: number
	ClientId: number
}) {
	const endpoint = "/ClientToken"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.loginToken,
	})

	return response
}

export async function readProjInfo(params: {
	ClientId: number
}) {
	const endpoint = "/GetClientInfo"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readProjSettings() {
	const endpoint = "/GetCustomerSysSettings"
	const response = await getRequest({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.orgToken, // NOTE: it uses org setting, so OrgToken is required
	})

	return response
}

export type ProjCheckinApiProps = {
	apiRequestToken: (params: {
		UserId: number
		ClientId: number
	}) => Promise<AxiosResponse<any>>
	apiReadProjInfo: (params: {
		ClientId: number
	}) => Promise<AxiosResponse<any>>
	apiReadProjSettings: () => Promise<AxiosResponse<any>>
}
