import { types, flow } from "mobx-state-tree"
import { FinancialStatementsApiProps } from "./financial-statements.api"
import ReadFinancialPresentation from "./model-actions/read-financial-presentation"
import ReadTrialBalanceDetails from "./model-actions/read-trial-balance-details"

const FinancialStatementsModel = ({
	apiRead,
	apiReadTrialBalance,
}: FinancialStatementsApiProps) =>
	types
		.model({})
		.actions((self) => ({
			// -p----- Read
			// ------ Update
		}))
		.actions((self, read = apiRead) =>
			ReadFinancialPresentation(self, read)
		)
		.actions((self, readTrialBalance = apiReadTrialBalance) =>
			ReadTrialBalanceDetails(self, readTrialBalance)
		)

export default FinancialStatementsModel
