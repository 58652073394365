import React, { useState } from "react"
import styled from "styled-components"
import { PageContainer } from "../../../../components/app-frame-elements"

export default function MyHistory() {
	return (
		<PageContainer
			fullWidth
			fixedHeight
			hasToolbar={false}
			pageTools={<div />}
		>
			My History
		</PageContainer>
	)
}
