import { types } from "mobx-state-tree"

export enum OrgLibraryType {
	libraryFolder = "LIBRARY_FOLDER",
	wpTemplate = "WP_TEMPLATE",
	apTemplate = "AUDIT_PROGRAM_TEMPLATE",
	fileFolder = "FILE_FOLDER",
	file = "FILE",
}

export type OrgLibraryFlatItemProps = {
	id: string
	parentId: null | string
	title: string
	type: OrgLibraryType
	expanded: boolean | undefined
	fileInfo?: any
}

export const OrgLibraryFlatItem = types.model({
	id: types.string,
	parentId: types.union(types.null, types.string),
	title: types.string,
	type: types.enumeration<OrgLibraryType>(Object.values(OrgLibraryType)),
	expanded: types.union(types.undefined, types.boolean),
})
