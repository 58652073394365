import React from "react"
import * as XLSX from "xlsx"
import * as FileSaver from "file-saver"
import { DLButton } from ".."
import { mdiFileExcel } from "@mdi/js"
import Icon from "@mdi/react"

const fileType =
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8"
const fileExtension = ".xlsx"

export const handleExport = (
    initialData: any,
    fileName: string,
    toUpperCase?: boolean
) => {
    const worksheet = XLSX.utils.json_to_sheet(initialData)
    // This part will convert header from 'createdBy' to "Created By"
    if (toUpperCase) {
        const leng = worksheet["!ref"]
        if (leng) {
            const range = XLSX.utils.decode_range(leng)
            for (var C = range.s.c; C <= range.e.c; ++C) {
                var address = XLSX.utils.encode_col(C) + "1" // <-- first row, column number C
                if (!worksheet[address]) continue
                worksheet[address].v = worksheet[address].v
                    .replace(/([A-Z])/g, " $1")
                    .replace(/^./, function (str: string) {
                        return str.toUpperCase()
                    })
            }
        }
    }
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
        cellDates: true,
        cellStyles: true,
        bookSST: true,
    })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
}

export const ExportXLSX = ({
    initialData,
    fileName,
}: {
    initialData: any
    fileName: string
}) => {
    return (
        <DLButton
            eleTestId="export-xlsx-btn"
            clickHandler={() => handleExport(initialData, fileName)}
            endIcon={<Icon path={mdiFileExcel} size={0.8} color="green" />}
        >
            Export
        </DLButton>
    )
}

export const handleExport2 = (initialData: any, fileName: string) => {
    const worksheet = XLSX.utils.aoa_to_sheet(initialData)
    const workbook = { Sheets: { data: worksheet }, SheetNames: ["data"] }
    const excelBuffer = XLSX.write(workbook, {
        bookType: "xlsx",
        type: "array",
    })
    const data = new Blob([excelBuffer], { type: fileType })
    FileSaver.saveAs(data, fileName + fileExtension)
}
