import { types } from "mobx-state-tree"

export const AvailableSettingsModel = types.model({
	ordinal: types.string, //ex) 1
	label: types.string, //ex) "At Creation"
	value: types.string, //ex) "atcreation"
	parameterValueUI: types.string, //ex) "At Creation"
	parameterValueUIValue: types.string, //ex) 0
})

export const SubSettingsModel = types.model({
	availableSettings: types.array(AvailableSettingsModel),
	displayValue: types.union(types.string, types.null), //ex) null
	isActive: types.boolean, //ex) false
	ordinal: types.string, //ex) 1
	parameterName: types.string, //ex) "At Creation"
	parameterValue: types.string, //ex) "atcreation"
	parameterValueUI: types.string, //ex) "At Creation"
	parentId: types.string, //ex) 55451
	settingId: types.string, //ex) 55456
	subSettings: types.union(types.string, types.null), //ex) null
})

export const NotificationSettings = types.model({
	availableSettings: types.null, // ex) null
	displayValue: types.string, // ex) "Comment Notification"
	isActive: types.boolean, // ex) true
	ordinal: types.string, // ex) 1
	parameterName: types.string, // ex) "QC Comment"
	parameterValue: types.string, // ex) "qccomment"
	parameterValueUI: types.string, // ex) "QC Comment"
	parentId: types.null, // ex) null
	settingId: types.string, // ex) 55451
	subSettings: types.array(SubSettingsModel),
})

export const ProjInfoAndModifiedDetails = types.model({
	isLocked: types.boolean, //ex) false
	archPolicyPeriod: types.string, //ex) "45 Days"
	archPolicyPeriodValue: types.string, //ex) 45
	expectedArchiveDeadlineDate: types.string, //ex) "2020-12-27T00:00:00+00:00"
	expectedReportDate: types.string, //ex) "2020-11-12T00:00:00+00:00"
	finalArchiveDeadlineDate: types.string, //ex) "2020-02-21T00:00:00+00:00"
	finalReportDate: types.string, //ex) "2020-01-07T00:00:00+00:00"
	modifiedBy: types.union(types.string, types.null), //ex) 16
	modifiedDate: types.union(types.string, types.null), //ex) "2020-06-08T03:49:14.500489+00:00"
	modifiedUser: types.union(types.string, types.null), //ex) "김철수"
	roleName: types.union(types.string, types.null), //ex) "QC"
	userName: types.union(types.string, types.null), //ex) "cskim"
})

export interface AvailableSettingsProps {
	ordinal: string
	label: string
	value: string
	parameterValueUI: string
	parameterValueUIValue: string
}

export interface SubSettingsProps {
	availableSettings: Array<AvailableSettingsProps>
	displayValue: string | null
	isActive: boolean
	ordinal: string
	parameterName: string
	parameterValue: string
	parameterValueUI: string
	parentId: string
	settingId: string
	subSettings: string | null
}

export interface NotificationProps {
	availableSettings: null
	displayValue: string
	isActive: boolean
	ordinal: string
	parameterName: string
	parameterValue: string
	parameterValueUI: string
	parentId: null
	settingId: string
	subSettings: Array<SubSettingsProps>
}

export const ProjNotiInitialInputs = {
	availableSettings: null, // ex) null
	displayValue: "", // ex) "Comment Notification"
	isActive: false, // ex) true
	ordinal: "", // ex) 1
	parameterName: "", // ex) "QC Comment"
	parameterValue: "", // ex) "qccomment"
	parameterValueUI: "", // ex) "QC Comment"
	parentId: null, // ex) null
	settingId: "", // ex) 55451
	subSettings: [
		{
			availableSettings: [
				{
					ordinal: "", //ex) 1
					label: "", //ex) "At Creation"
					value: "", //ex) "atcreation"
					parameterValueUI: "", //ex) "At Creation"
					parameterValueUIValue: "",
				},
			],
			displayValue: "", //ex) null
			isActive: false, //ex) false
			ordinal: "", //ex) 1
			parameterName: "", //ex) "At Creation"
			parameterValue: "", //ex) "atcreation"
			parameterValueUI: "", //ex) "At Creation"
			parentId: "", //ex) 55451
			settingId: "", //ex) 55456
			subSettings: "", //ex) null
		},
	],
}

export enum NotificationType {
	comment = "COMMENT",
	expReportDate = "ExpectedReportDate",
	expArchDeadlineDate = "ExpectedArchDeadlineDate",
	finArchDeadLineDate = "FinalArchiveDeadlineDateNotification",
}
