import React from "react"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function RemoveOrgUserDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- RemoveOrgUserDialog -=-=-=-=- ")

	const orgStore = useOrgStore()
	const actionName = "removeUser"

	const handleRemove = () => {
		orgStore.setupUsers.removeUser(orgStore.setupUsers.selectedUser.id)
	}

	const actionStatus = orgStore.setupUsers.getActionStatus(actionName)
	return (
		<DLDialog
			eleTestId="remove-org-user-dialog"
			isOpen={orgStore.setupUsers.removeDialogOpen}
			setIsOpen={orgStore.setupUsers.setRemoveDialogOpen}
			handleAction={handleRemove}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twRemoveDialogTitle}
			dialogContents={<div>{i18n.tsRemoveMsg}</div>}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twRemove}
			actionReady={true}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				actionStatus !== "SUCCESS"
					? orgStore.setupUsers.responses.getResponse(actionName)
							?.message
					: undefined
			}
			showSpinner={actionStatus === "LOADING"}
			cannotUpdate={actionStatus === "LOADING"}
		/>
	)
})
