import React from "react"
import { observer } from "mobx-react-lite"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	RestoreIcon,
} from "../../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"

export default observer(function ProjectTrashCtxMenus({
	permission,
	i18n,
}: {
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()

	const options = () => [
		{
			label: i18n.twRestore || "Restore",
			value: "restore",
			available: permission.update,
			disabled: false, // TODO: check is there some condition to restrict restore
			clickEvent: () => projStore.trash.setRestoreTrashDialogOpen(true),
			icon: <RestoreIcon />,
		},
		{
			label: i18n.twDelete || "Delete",
			value: "permanentDelete",
			available: permission.delete,
			disabled: false, // TODO: check is there some condition to restrict restore
			clickEvent: () => projStore.trash.setDeleteTrashDialogOpen(true),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	const handleClose = () => {
		projStore.trash.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="proj-trash-ctx-menus"
			clickPoint={{
				mouseX: projStore.trash.clickPoint.mouseX,
				mouseY: projStore.trash.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={options()}
			hasDivider={[]}
		/>
	)
})
