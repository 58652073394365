import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import {
	DLSpinnerCenterAligned,
} from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import styled from "styled-components"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { SignoffDetailTable } from "../../../archive/sign-off-history/sub-components/SignOffHistoryDetailMore"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function SignOffDetailDailogContents({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	// const dialogName = FileScreenDialog.signOffDetail
	const menuId = partialStore.storeName
	const dntFormat = store.global.getDntFormat

	// const setOpen = () => {
	// 	partialStore.setFileTreeDialogOpen(dialogName)
	// }
	const file = partialStore.selectedItems[0]
	const fileId = file.id

	if (file.isParent) {
		alert("Cannot check the sign off of the folder. Please select a file")
	}

	const fileInfo = partialStore.getItemInfo(fileId)
	const { title, aliasId } = fileInfo

	useEffect(() => {
		projStore.signOff.getSignoffHistoryDetail(fileId, menuId)
	}, [fileId])

	// const open = partialStore.fileTreeDialogOpenStatus[dialogName]
	const signoffDetail = projStore.signOff.viewDetailHistory(
		fileId,
		menuId,
		dntFormat
	)
	const actionName = "GetSignoffHistoryDetail" + menuId + fileId
	const actionStatus = projStore.signOff.getActionStatus(actionName)

	return (
		<StyledSignOffDetailContents>
			<h3>Sign Off Detail - {`${title} (${aliasId})`}</h3>
			{actionStatus === ActionStatus.fail ? (
				<div>Failed to fetch the data</div>
			) : actionStatus === ActionStatus.loading ? (
				<DLSpinnerCenterAligned />
			) : actionStatus === ActionStatus.success ? (
				signoffDetail.length === 0 ? (
					"No Sign Offs"
				) : (
					<SignoffDetailTable data={signoffDetail} />
				)
			) : (
				<div>Standby...</div>
			)}
		</StyledSignOffDetailContents>
	)
})

const StyledSignOffDetailContents = styled.div`
	margin-top: 1rem;
`
