import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import { idToString, IdType } from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const AddGroup = (self: any) => ({
	addGroup(groupName: string) {
		// 0.
		const actionName = "addGroup"
		// 1.
		const checkedProps = hasEmptyProps({ groupName })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const title = checkedProps.props.groupName
		const parentId = "groupId0" // 0 to create group
		self.createGroupItem({ title, parentId })
			.then((response: any) => {
				const status = response.data.Status
				const serverMsg = response.data.message || response.data.Message

				if (response.status === 200 && status === 1) {
					const userId = localStorage.getItem("orgUserId") || ""
					const reOrganizedGroup = {
						groupId: idToString(
							response.data.CabinetID,
							IdType.group
						), // [CabinetID], ex) GroupID: 805
						groupName: response.data.CabinetName, // [CabinetName], ex) CabinetName: "(0) Release 본부 update"
						userId: userId, // ? user Id for group...?
						createdBy: response.data.CreatedUser, // [CreatedBy], ex) CreatedBy: "cskim 10/14/2019"
						userCount: 1, // [UserCount], ex) UserCount: 4
						assignedUsers: response.data.AssignedUsers, // [AssignedUsers], ex) AssignedUsers: "홍길동,전우치,신사임당"
						clientsCount: 0, // [ClientsCount], ex) ClientsCount: 3
						assignedClients: "",
					}
					self.setOpenAddGroupDialog(false)
					self.pushItemToList(reOrganizedGroup) // NOTE: push item after the dialog closing. if you add this item on the list before close the dialog, dialog displays the duplication error message
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default AddGroup
