import { types, flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { selectedToken } from "../../../../../library/api-requests/shared-for-api-requests"

// model actions

const TrialBalanceModel = () =>
	types
		.model({})
		.actions((self) => ({
			// ------ Read
			// ------ Update
		}))
		.actions((self: any) => ({
			readTrialBalanceUrl: flow(function* (payload: {
				userNumberId: number
				projNumberId: number
				finyear: number
				cabinetid: number
				accessRight: number
			}) {
				const actionName = "readTrialBalanceUrl"
				const {
					userNumberId,
					projNumberId,
					finyear,
					cabinetid,
					accessRight,
				} = payload
				try {
					const endpoint =
						process.env.REACT_APP_DEV_EXPRESS_ENDPOINT +
						"/TrialBalance_new.aspx?userid=" +
						userNumberId +
						"&clientid=" +
						projNumberId +
						"&cabinetid=" +
						cabinetid +
						"&finyear=" +
						finyear +
						"&Lang=" +
						"en" +
						"&AR=" +
						accessRight +
						"&token=" +
						selectedToken(TOKEN_TYPE.projToken)
					const response = {
						status: 200,
						data: {
							Status: 1,
							URL: endpoint,
						},
					}
					// ConsoleLog(actinName, "__response", response)
					return response
				} catch (error) {
					// ConsoleLog(actinName, "__error", error)
					self.handleModelError({
						actionName,
						error,
						openSnackbar: true,
					})

					return false
				}
			}),
		}))

export default TrialBalanceModel
