import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"

const version = process.env.REACT_APP_API_VERSION

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 *  - success, fail
 */
export const GetOrgList = (self: any) => ({
	getOrgList() {
		// 0.
		const actionName = "getOrgList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readOrgList()
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				let responseStatus = 0
				let serverMessage = ""
				let fetchedData: any = {}
				//
				// * ----- API version control
				const basicStatus = response.status
				const prevStatus = response.data?.status?.code
				//
				if (version === "v2") {
					responseStatus = basicStatus
					serverMessage =
						response.data.ValidationResults[0].ErrorMessage
					fetchedData = response.data.Data
				} else {
					if (basicStatus === 200 && prevStatus === 200) {
						responseStatus = 200
					}

					serverMessage = response.data.Message
					fetchedData = response.data.data
				}
				//
				// * ----- View Model Tasks
				if (responseStatus === 200) {
					let newList: any[] = []
					fetchedData.map((fetchedItem: any) => {
						const newItem = {
							orgId: idToString(
								fetchedItem.CustomerID,
								IdType.org
							),
							orgName: fetchedItem.OrganizationName,
							// about the user
							userId: idToString(fetchedItem.UserId, IdType.user),
							userAliasId: fetchedItem.UserName,
							userName: fetchedItem.Name,
							email: fetchedItem.Email,
							isExternalUser: fetchedItem.isExternal,
							// locale
							countryCode: fetchedItem.CountryCode
								? fetchedItem.CountryCode
								: "US", // for login test to production by localhost (03/02/2020) TODO: remove if it is confirmed
							language: fetchedItem.Language,
							// assign info
							assignedBy: fetchedItem.AssignedBy,
							assignedAt: fetchedItem.AssignedDate,
							// access type
							accessType: fetchedItem.AccessType,
							isQcUser: fetchedItem.IsQCUser,
							isSuperAdmin: fetchedItem.IsSuperAdmin,
							isArchiveAdmin: fetchedItem.isArchiveManager,
							hasCustomerViewAccess: fetchedItem.CustomerView,
						}
						newList.push(newItem)
					})
					self.updateWholeList(newList)

					// set success response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						// message: serverMessage || "Something wrong...",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgList
