import React from "react"
import { DLTooltip } from "@datalobby/components"

export default function ValueWithTooltipRatio({
	title,
	value,
	total,
}: {
	title?: string
	value: number
	total: number
}) {
	return (
		<DLTooltip
			title={
				<div className="FC AC">
					{title && <div>{title}</div>}
					<div>
						{total === 0
							? 0
							: ((value / total) * 100).toFixed(2) + "%"}
					</div>
				</div>
			}
			elePlacement="top"
			arrow
		>
			<span>{value}</span>
		</DLTooltip>
	)
}
