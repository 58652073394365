import React from "react"
import { Autocomplete } from "@material-ui/lab"
import TextField from "@material-ui/core/TextField"
import styled from "styled-components"
import { Chip } from "@material-ui/core"

export default function DLComboBoxMultipleValues({
	options,
	getOptionLabel,
	getOptionDisabled,
	defaultValue = [],
	renderTags,
	fullWidth = true,
	onChange,
	eleTestId = "dl-multi-combo-box",
	eleDisabled,
}: {
	options: any
	getOptionLabel: (option: any) => any
	getOptionDisabled?: any
	defaultValue?: any
	renderTags?: any
	fullWidth?: boolean
	onChange?: any
	eleTestId?: string
	eleDisabled?: boolean
}) {
	return (
		<SteyldDLComboBoxMultipleValues>
			<Autocomplete
				disabled={eleDisabled}
				multiple
				id=""
				data-testid={eleTestId}
				className="autocomplete-body"
				options={options}
				getOptionLabel={getOptionLabel}
				getOptionDisabled={getOptionDisabled}
				onChange={(event: object, value: any) => onChange(event, value)}
				defaultValue={defaultValue}
				filterSelectedOptions
				renderTags={
					renderTags
					// (value: any[], getTagProps) =>
					//  value.map((option: any, index: number) => (
					// 		<Chip
					// 			variant="outlined"
					// 			label={option.aliasId}
					// 			{...getTagProps({ index })}
					// 		/>
					//   ))
				}
				renderInput={(params) => (
					<TextField
						{...params}
						variant="outlined"
						label="User Assign"
						placeholder="Select users to assign"
						fullWidth={fullWidth}
					/>
				)}
			/>
		</SteyldDLComboBoxMultipleValues>
	)
}

const SteyldDLComboBoxMultipleValues = styled.div``
