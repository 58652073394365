import { flow } from "mobx-state-tree"

const ReadFinancialStatementType = (self: any, read: any) => ({
	readFinancialStatementType: flow(function*() {
		const actionName = "readFinancialStatementType"
		try {
			const response = yield read()
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadFinancialStatementType
