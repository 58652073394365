import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getToDownload } from "../../../../../library/api-requests/request-get-others"
import {
	getRequest,
	deleteRequest,
	postRequest,
} from "../../../../../library/api-requests"

export async function read() {
	const endpoint = "/GetRationaleFiles"
	const response = await getRequest({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type UpdateProps = {
	FileType: number // 1: wp, 2: pf
	RationaleDescription: string
	RationaleID: number
	WorkPaperID: number // NOTE: even pf uses same keyName
}

export async function update(params: UpdateProps) {
	const endpoint = "/AddorModifyRationale"
	const response = await postRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function remove(params: { rationaleID: number }) {
	const endpoint = "/DeleteRationale"
	const response = await deleteRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function download() {
	const endpoint = "/Export_Rationales_PDF"
	const response = await getToDownload({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type RationaleApiProps = {
	apiRead?: () => Promise<AxiosResponse<any>>
	apiUpdate?: (params: UpdateProps) => Promise<AxiosResponse<any>>
	apiRemove?: (params: { rationaleID: number }) => Promise<AxiosResponse<any>>
	apiDownload?: () => Promise<AxiosResponse<any>>
}
