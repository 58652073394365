import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../components/basic-elements"
import { OrgSetupUserApiProps } from "../org-setup-users.apis"

const CreateOrgUser = (
	self: any,
	create: OrgSetupUserApiProps["apiCreate"]
) => ({
	createOrgUser: flow(function* (userInfo) {
		const actionName = "createOrgUser"
		// ---------- re-organize data
		// ----- 1-1) internal or external
		let type
		if (userInfo.type === "internal") {
			type = 1
		} else {
			type = 2
		}
		// ----- 1-2) SA or GA or UA
		let accessRight
		if (userInfo.accessRight === "User Access" && type === 1) {
			accessRight = 3
		} else if (userInfo.accessRight === "Group Admin" && type === 1) {
			accessRight = 2
		} else if (userInfo.accessRight === "Super Admin" && type === 1) {
			accessRight = 1
		} else {
			accessRight = 0
		}
		// ----- 1-3) is Archive Manager
		let isArchiveAdmin
		if (
			userInfo.accessRight === "Super Admin" &&
			userInfo.isArchiveAdmin === "true" &&
			type === 1 // For internal user
		) {
			isArchiveAdmin = 1
		} else {
			isArchiveAdmin = 0
		}

		// ----- 1-4) call API
		try {
			const payload = {
				UserType: type, // ex) 1 = internal or external
				AccessTypeID: accessRight, // ex) 2, org access right
				ArchiveManager: isArchiveAdmin, // ex) 0, default value: 0 = false
				// CheckUser: true, // this parameter is not mandatory thing
				EmailID: userInfo.email, // ex) xxx@email.com
				Name: userInfo.name, // ex) Alice In Wonderland
				Title: userInfo.title, // ex) The new queen
				UserName: userInfo.aliasId, // ex) alice1234, = userAliasId
			}
			const response = yield create(payload)

			ConsoleLog(["response of the createOrgUser", response])
			return response
		} catch (error) {
			// console.error("error of the createOrgUser", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateOrgUser
