import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import DLComboBoxWithCheckbox from "../../../../../components/basic-elements/autocomplete-fields/DLComboBoxWithCheckbox"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { DLButton } from "../../../../../components/basic-elements"

const CardFilters = observer(
	({ partialStore, setOpen, i18n }: { partialStore: any; setOpen:any; i18n: DLI18nProps }) => {
		const menuId = DLOrgSubMenus.dash_chart
		const pageSettingName = `pageSetting_${menuId}`

		const needRefresh = partialStore.needRefresh

		useEffect(() => {
			const userId = localStorage.getItem("orgUserId")
			const pageSetting = localStorage.getItem("pageSetting")
			const pageSettingData = pageSetting ? JSON.parse(pageSetting) : []
			if (pageSettingData.some((item: any) => item.userId === userId)) {
				const data = pageSettingData.find(
					(item: any) => item.userId === userId
				)[pageSettingName]
				if (data) {
					partialStore.setYearFilter(
						data.financialYear ? data.financialYear : []
					)
					partialStore.setPeriodTypeFilter(
						data.periodTypes ? data.periodTypes : []
					)
					partialStore.setEngTypeFilter(
						data.engTypes ? data.engTypes : []
					)
					partialStore.setGroupFilter(data.groups ? data.groups : [])
					partialStore.setEPUserFilter(
						data.epUsers ? data.epUsers : []
					)
					partialStore.setQCUserFilter(
						data.qcUsers ? data.qcUsers : []
					)
				}
			}
		}, [needRefresh])

		const savePageSetting = (name: string, value: any) => {
			const userId = localStorage.getItem("orgUserId")
			const pageSetting = localStorage.getItem("pageSetting")
			const pageSettingData = pageSetting ? JSON.parse(pageSetting) : []
			if (pageSettingData.some((item: any) => item.userId === userId)) {
				let prevData = pageSettingData.find(
					(item: any) => item.userId === userId
				)
				const index = pageSettingData.findIndex(
					(item: any) => item.userId === userId
				)
				prevData = {
					...prevData,
					[pageSettingName]: {
						...prevData[pageSettingName],
						[name]: value,
					},
				}
				pageSettingData[index] = prevData
			} else {
				pageSettingData.push({
					userId,
					[pageSettingName]: {
						[name]: value,
					},
				})
			}
			localStorage.setItem("pageSetting", JSON.stringify(pageSettingData))
		}
		
		const clearFilters = () => {
			partialStore.setYearFilter([])
			partialStore.setPeriodTypeFilter([])
			partialStore.setEngTypeFilter([])
			partialStore.setGroupFilter([])
			partialStore.setEPUserFilter([])
			partialStore.setQCUserFilter([])
			savePageSetting("financialYear", [])
			savePageSetting("periodTypes", [])
			savePageSetting("engTypes", [])
			savePageSetting("groups", [])
			savePageSetting("epUsers", [])
			savePageSetting("qcUsers", [])
			setOpen(false)
		}

		return (
			<StyledCardFilters className="FC">
				<DLComboBoxWithCheckbox
					options={partialStore.viewYearList()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("financialYear", value)
						partialStore.setYearFilter(value)
					}}
					defaultValue={
						partialStore.yearFilter ? partialStore.yearFilter : []
					}
					eleTestId="financial-year-select"
					elePlaceHolder={i18n.twFinancialYear || "Financial Year"}
					eleClassName="card-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewPeriodTypes()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("periodTypes", value)
						partialStore.setPeriodTypeFilter(value)
					}}
					defaultValue={
						partialStore.periodTypeFilter
							? partialStore.periodTypeFilter
							: []
					}
					eleTestId="periodName-select"
					elePlaceHolder={i18n.twPeriodType || "Period Types"}
					eleClassName="card-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewEngTypes()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("engTypes", value)
						partialStore.setEngTypeFilter(value)
					}}
					defaultValue={
						partialStore.engTypeFilter
							? partialStore.engTypeFilter
							: []
					}
					eleTestId="engType-select"
					elePlaceHolder={i18n.twEngType || "Engagement Types"}
					eleClassName="card-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewGroupNames()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("groups", value)
						partialStore.setGroupFilter(value)
					}}
					defaultValue={
						partialStore.groupFilter ? partialStore.groupFilter : []
					}
					eleTestId="groupName-select"
					elePlaceHolder={i18n.twGroup || "Groups"}
					eleClassName="card-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewEPUsers()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("epUsers", value)
						partialStore.setEPUserFilter(value)
					}}
					defaultValue={
						partialStore.epUserFilter
							? partialStore.epUserFilter
							: []
					}
					eleTestId="epList-select"
					elePlaceHolder={i18n.twEP || "EP Users"}
					eleClassName="card-filter"
				/>
				<DLComboBoxWithCheckbox
					options={partialStore.viewQCUsers()}
					getOptionLabel={(option: any) => option}
					onChange={(value: any) => {
						savePageSetting("qcUsers", value)
						partialStore.setQCUserFilter(value)
					}}
					defaultValue={
						partialStore.qcUserFilter
							? partialStore.qcUserFilter
							: []
					}
					eleTestId="qcList-select"
					elePlaceHolder={i18n.twQC || "QC Users"}
					eleClassName="card-filter"
				/>
				<DLButton
					eleTestId="clear-filters"
					color="primary"
					clickHandler={clearFilters}
				>
					Clear Filters
				</DLButton>
			</StyledCardFilters>
		)
	}
)

export default CardFilters

const StyledCardFilters = styled.div`
	width: 100%;
	.MuiAutocomplete-input {
		width: 0;
		min-width: 90px !important;
	}
	.card-filter {
		margin-bottom: 1rem;
	}
`
