// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetPassedAdjustments = (self: any) => ({
	getPassedAdjustments(
		projectId: string,
		// cabinetId: string,
		// periodEndDate: string,
		tbId: number
	) {
		// 0. set actionName
		const actionName = "getPassedAdjustments"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const clientId = idToNumber(projectId, IdType.project)
		// const cabinetId = idToNumber(cabinetId, IdType.cabinet)
		const payload = {
			clientId: clientId,
			// CabinetId: cabinetId,
			// FinancialYear: periodEndDate,
			// Lang: "en",
			tbnameId: tbId,
		}

		// 3) request API call to Model
		self.readPassedAdjustments(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200) {
						//
						const fetchedData = response.data
						let ajes: any[] = []
						fetchedData.map((item: any, index: number) => {
							ajes.push({
								ajeDesc: item.adjDescription,
								ajeType: item.adjType,
								ajeId: item.id.toString(),
								extension: item.Extension,
								isOpen: item.isOpen,
								mediaId: item.mediaId.toString(),
								mediaType: item.mediaType,
								openedBy: item.openedBy,
								ajeNumber: index + 1,
								wpDesc: item.wpDesc,
								wpRef: item.wref,
							})
						})
						self.setPassedAdjustments(ajes)
						self.setNeedRefresh(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched passed adjustments",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetPassedAdjustments
