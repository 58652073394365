import React from "react"
import { mdiFolder } from "@mdi/js"
import { Icon } from "@mdi/react"
import styled from "styled-components"

const GroupRow = ({
	groupDetails,
	handleSelectGroup,
}: {
	groupDetails: any
	handleSelectGroup: (id: string) => void
}) => {
	return (
		<StyledGroupRow>
			<div className="FR AC header">
				<div className="name">Group Name</div>
				<div className="createdBy">Created By</div>
				<div>Created Date</div>
			</div>
			{groupDetails.map(
				(item: { id: string; title: string; [x: string]: any }) => {
					const { id, title, createdBy, createdAt } = item
					return (
						<div className="FR AC JSB group">
							<div
								className="FR AC group-name"
								onClick={() => handleSelectGroup(id)}
							>
								<div className="FR AC name">
									<Icon path={mdiFolder} size={1} />
									<span className="title-text">{title}</span>
								</div>
								<div className="createdBy">{createdBy}</div>
								<div>
									{createdAt.date + " " + createdAt.time}
								</div>
							</div>
						</div>
					)
				}
			)}
		</StyledGroupRow>
	)
}

const StyledGroupRow = styled.div`
	.group {
		border: 1px solid ${(props) => props.theme.shade20};
		border-radius: 3px;
		margin-bottom: 4px;
		padding: 0.4rem;
		width: 100%;
		cursor: pointer;
		.title-text {
			margin-left: 6px;
		}
		.group-name {
			width: 100%;
			:hover {
				color: ${(props) => props.theme.emphasis};
			}
		}
	}
	.header {
		padding: 5px 0;
		font-weight: bold;
	}
	.name {
		flex-basis: 40%;
	}
	.createdBy {
		flex-basis: 30%;
	}
`

export default GroupRow
