import { flow } from "mobx-state-tree"

const ReadProjUsers = (self: any, read: any) => ({
	readProjUsers: flow(function*() {
		const actionName = "readProjUsers"
		try {
			const response = yield read()

			// ConsoleLog("readProjUsersModel response", response)
			return response
		} catch (error) {
			// ConsoleLog("readProjUsersModel error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadProjUsers
