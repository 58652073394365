import {
	DLObjectFormat,
	DLSrcFormat,
} from "../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../components/basic-elements"

export function convertExtensionToFormat(fileType: string) {
	const formatList = [
		{
			type: ".xlsx",
			name: "Excel",
		},
		{
			type: ".xls",
			name: "Excel",
		},
		{
			type: ".docx",
			name: "Word",
		},
		{
			type: ".doc",
			name: "Word",
		},
		{
			type: ".xbrl",
			name: "XBRL",
		},
		{
			type: ".xml",
			name: "XBRL",
		},
		{
			type: ".ixbrl",
			name: "XBRL",
		},
		{
			type: ".xsd",
			name: "XBRL",
		},
		{
			type: ".hwp",
			name: "HWP",
		},
		{
			type: ".ppt",
			name: "Presentation",
		},
		{
			type: ".pptx",
			name: "Presentation",
		},
		{
			type: ".dsd",
			name: "DART",
		},
		{
			type: ".pdf",
			name: "Pdf", // NOTE: "PDF" (only capitals) occurs an error to upload pdf file
		},
	]
	const target = formatList.find((format) => format.type === fileType)
	if (target) {
		return target.name
	} else {
		return "Others" // "OTHERS" // WARNING: DO NOT USE 'OTHERS'
		// 'OTHERS' occurs error
		// this one for server. Different with Enum for frontend!!
	}
}

export function convertSourceFormat(
	srcFormat: string,
	extension?: string,
	where?: string
) {
	// NOTE: format: Excel, Word / extension: .xlsx, .xls, .docx, .doc
	// NOTE: project template returns 'others' for docx
	console.log("convertSourceFormat", srcFormat, extension)
	const format = srcFormat.toLowerCase()
	if (format === "word") {
		return DLSrcFormat.word
	} else if (format === "pdf") {
		return DLSrcFormat.pdf
	} else if (format === "excel") {
		return DLSrcFormat.excel
	} else if (format === "zip") {
		return DLSrcFormat.zip
	} else if (format === "leadsheet") {
		return DLSrcFormat.leadSheet
	} else if (format === "uploadfile") {
		return DLSrcFormat.fileGroup
	} else if (format === "auditprogram" || format === "auditprgramtemplate") {
		return DLSrcFormat.auditProgram
	} else if (format === "fluxanalytics") {
		return DLSrcFormat.fluxAnalysis
	} else if (format === "finalanalytics") {
		return DLSrcFormat.finalAnalysis
	} else if (extension) {
		if (extension.includes("doc")) {
			return DLSrcFormat.word
		} else if (extension.includes("xls")) {
			return DLSrcFormat.excel
		} else if (extension.includes("pdf")) {
			return DLSrcFormat.pdf
		} else {
			ConsoleLog([
				"convertSourceFormat - UNKNOWN FORMAT (1)",
				where,
				srcFormat,
				extension,
			])
			return DLSrcFormat.unknown
		}
	} else {
		ConsoleLog([
			"convertSourceFormat - UNKNOWN FORMAT (2)",
			where,
			srcFormat,
			extension,
		])
		return DLSrcFormat.unknown
	}
}

const customSrcFormats = [
	DLSrcFormat.leadSheet,
	DLSrcFormat.fileGroup,
	DLSrcFormat.auditProgram,
	DLSrcFormat.fluxAnalysis,
	DLSrcFormat.finalAnalysis,
	DLSrcFormat.aje,
	DLSrcFormat.paje,
]

export function isCustomFile(srcFormat: DLSrcFormat) {
	if (customSrcFormats.includes(srcFormat)) {
		return true
	} else {
		return false
	}
}

export function convertExtension(
	fetchedExtension: string | null,
	srcFormat: DLSrcFormat
) {
	if (fetchedExtension === null) {
		return "(null)"
	} else if (
		fetchedExtension === "" &&
		customSrcFormats.includes(srcFormat)
	) {
		return srcFormat.toLowerCase()
	} else {
		return fetchedExtension
	}
}
