import { mdiAlertCircle, mdiCheckCircle } from "@mdi/js"
import Icon from "@mdi/react"
import { observer } from "mobx-react-lite"
import React from "react"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLSpinnerByIcon } from "../../../../../components/basic-elements"
import { BulkClientImportItemType } from "../../../../../stores/org-sub-stores/org-clients-store/data-models/org-clients.data-models"
import styled from "styled-components"

export default observer(function SubmitStatusList({
	successCount,
	failCount,
	list,
}: {
	successCount: number
	failCount: number
	list: BulkClientImportItemType[]
}) {
	return (
		<StyledSubmitStatusList className="result-container">
			<div className="result-message FR AC">
				<span className="success">
					Total Submitted Count: {successCount}
				</span>
				<span className="divider">|</span>
				<span className={failCount > 0 ? "failed" : ""}>
					Total Failed Count: {failCount}
				</span>
			</div>
			<div className="result-list">
				{list.map((item: any) => {
					return (
						<div className="FR JSB result-row" key={item.clientId}>
							<div style={{ flexBasis: "10rem" }}>
								{item.name}
							</div>
							<div style={{ flexBasis: "10rem" }}>
								{item.aliasId}
							</div>
							<div
								className="response-info FR AC"
								style={{ flexBasis: "1rem" }}
							>
								{item.status === ActionStatus.success ? (
									<Icon
										color="blue"
										path={mdiCheckCircle}
										size={0.8}
									/>
								) : item.status === ActionStatus.fail ? (
									<Icon
										color="red"
										path={mdiAlertCircle}
										size={0.8}
									/>
								) : item.status === ActionStatus.loading ? (
									<DLSpinnerByIcon />
								) : (
									<div>Standby</div>
								)}
							</div>
							<div
								className={item.status}
								style={{ flexBasis: "20rem" }}
							>
								{item.statusMessage}
							</div>
						</div>
					)
				})}
			</div>
		</StyledSubmitStatusList>
	)
})

const StyledSubmitStatusList = styled.div`
	.result-message {
		margin-bottom: 1rem;
		.success {
			color: ${(props) => props.theme.emphasis};
		}
		.divider {
			margin: 0 1rem;
		}
		.failed {
			color: ${(props) => props.theme.themeRed};
		}
	}
	.result-list {
		/* this height is same as table height */
		height: 400px;
		overflow: hidden;
		overflow-y: auto;
		.result-row {
			padding: 0.4rem 1rem;
		}
	}
`
