import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"

const RequestDuplicateFolder = (
	self: any,
	apiDuplicateFile: OrgProjTemplatesApiProps["apiDuplicateFile"]
) => ({
	requestDuplicateFolder: flow(function* (folderId: string) {
		const actionName = "requestDuplicateFolder"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const folderNumberId = idToNumber(folderId, IdType.folder)
		if (typeof folderNumberId === "number") {
			const params = {
				SourceId: folderNumberId,
				Type: 3, // 3 = folder
			}
			try {
				// const endpoint = "/CopyProjectTemplate"
				const response = yield apiDuplicateFile(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(`folderId has invalid format. ${folderNumberId}`)
		}
	}),
})

export default RequestDuplicateFolder
