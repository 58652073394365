// ---------- common models
import {
	ActionStatus,
	DLSector,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

/**
 *  WARNING: WARNING: WARNING: WARNING: this CHECKIN means !!! CLOSE !!!
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const CheckinFile = (self: any) => ({
	checkinFile({
		menuId,
		srcId,
		userId,
		sector,
		projId,
		postAction,
	}: {
		menuId: DLProjSubMenus
		srcId: string
		userId: string
		sector: DLSector
		projId: string
		postAction: any
	}) {
		// 0.
		const actionName = "checkinFile"
		// 1.
		// 2.
		let proceed = window.confirm(
			"Do you really want to close this file? Unsaved content may be lost."
		)
		if (!proceed) {
			return
		}
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.mCheckinFile({
			srcId,
			userId,
			sector,
			projId,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 1,
						color: MessageColorType.blue,
						open: true,
					})
					postAction && postAction()
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message || response.data.message,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default CheckinFile
