import React from "react"
// components
import { DLButton } from "../../../../../components/basic-elements"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
// styles
import styled from "styled-components"

export default function CommentsMainToolbar({
	currentMenu,
	handleCurrentMenu,
	totalCount,
	wpCommentsCount,
	pfCommentsCount,
	selectedCount,
	closedCount,
	wpClosedCount,
	pfClosedCount,
	handleDeleteMultipleComments,
	i18n,
}: {
	currentMenu: any
	handleCurrentMenu: (value: string) => void
	totalCount: number
	wpCommentsCount: number
	pfCommentsCount: number
	selectedCount: number
	closedCount: number
	wpClosedCount: number
	pfClosedCount: number
	handleDeleteMultipleComments: any
	i18n: DLI18nProps
}) {
	return (
		<StyledCommentsMainToolbar
			className="comment-main-toolbar FR AC JSB"
			data-testid="comment-main-toolbar"
		>
			<div className="FR AC">
				{/* <DLIconButton
					eleTestId="add-comment-btn"
					eleClassName="add-btn"
					variant="contained"
					tooltipText="Add Comment"
					clickHandler={handleOpenAddDialog}
				>
					<Icon path={mdiPlus} size={0.8} />
				</DLIconButton> */}
				<div
					className={`counter FR AC ${
						currentMenu === "all" && "current-menu"
					}`}
					onClick={() => handleCurrentMenu("all")}
				>
					{i18n.twTotal || "Total"}:{" "}
					<span className="number" data-testid="totalCount">
						{closedCount}/{totalCount}
					</span>
				</div>
				<div className="vertical-divider">|</div>
				<div
					className={`counter FR AC ${
						currentMenu === DLProjSubMenus.wp && "current-menu"
					}`}
					onClick={() => handleCurrentMenu(DLProjSubMenus.wp)}
				>
					on Workpapers:{" "}
					<span className="number" data-testid="wpCount">
						{wpClosedCount}/{wpCommentsCount}
					</span>
					{/* <DLIconButton
						eleTestId="wp-comment-refresh"
						tooltipText="Refresh"
					>
						<Icon path={mdiRefresh} size={0.8} />
					</DLIconButton> */}
				</div>
				<div className="vertical-divider">|</div>
				<div
					className={`counter FR AC ${
						currentMenu === DLProjSubMenus.pf && "current-menu"
					}`}
					onClick={() => handleCurrentMenu(DLProjSubMenus.pf)}
				>
					on Permanent Files:{" "}
					<span className="number" data-testid="pfCount">
						{pfClosedCount}/{pfCommentsCount}
					</span>
					{/* <DLIconButton
						eleTestId="wp-comment-refresh"
						tooltipText="Refresh"
					>
						<Icon path={mdiRefresh} size={0.8} />
					</DLIconButton> */}
				</div>
				{selectedCount > 0 && (
					<>
						<div className="vertical-divider">|</div>
						<div className={`counter FR AC`}>
							Selected Comments:{" "}
							<span className="number">{selectedCount}</span>
						</div>
					</>
				)}
			</div>
			<div>
				<DLButton
					eleTestId="delete-all-btn"
					size="thin"
					color="primary"
					clickHandler={handleDeleteMultipleComments}
					disabled={selectedCount < 1}
				>
					{i18n.twDelete || "Delete Multiple"}
				</DLButton>
			</div>
		</StyledCommentsMainToolbar>
	)
}

const StyledCommentsMainToolbar = styled.div`
	width: 100%;
	padding: 0 1rem;
	.add-btn {
		margin-right: 1rem;
		background-color: ${(props) => props.theme.primaryLight};
		color: ${(props) => props.theme.background};
		:hover {
			background-color: ${(props) => props.theme.emphasis};
		}
	}
	.vertical-divider {
		margin: 0 1rem;
	}
	.counter {
		cursor: pointer;
		&.current-menu {
			font-weight: 600;
			color: ${(props) => props.theme.emphasis};
		}
		.number {
			margin-left: 0.4rem;
			font-weight: 400;
			color: ${(props) => props.theme.textHighEmphasis};
		}
	}
`
