import React from "react"
import { DLButton } from "../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"
import { handleDownloadExcelTemplate } from "../../../../library/bulk-import-utils/handle-download-template"
import Icon from "@mdi/react"
import { mdiArrowUp } from "@mdi/js"

export default observer(function AuditProgramActionBtns({
	partialStore,
	handleAdd,
	selectedFile,
	handleSelectFilesFromLocal,
	fromDialog,
}: {
	partialStore: any
	handleAdd: any
	selectedFile: string | null
	handleSelectFilesFromLocal: (e: any) => void
	fromDialog: boolean
}) {
	return (
		<div className="audit-program-action-area FR JSB">
			<DLButton
				eleTestId="choose-files-btn"
				startIcon={<Icon path={mdiArrowUp} size={0.8} />}
				color="primary"
			>
				<label className="file-upload-btn-wrapper">
					Choose A file
					<input
						type="file"
						name="file"
						onChange={handleSelectFilesFromLocal}
						data-testid="file-input"
						accept=".xlsx,.xls"
					/>
				</label>
			</DLButton>
			<div className="FR AC">
				<DLButton
					eleTestId="template-btn"
					color="primary"
					eleClassName="MR"
					clickHandler={() =>
						handleDownloadExcelTemplate(
							[
								{
									legend: "",
									auditSteps: "",
									assertions: "",
									notes: "",
									ref: "",
									preparer: "",
									preparerDueDate: "",
									reviewer: "",
									reviewerDueDate: "",
								},
							],
							"AuditProgramTemplate"
						)
					}
				>
					Template
				</DLButton>
				<DLButton
					eleTestId="add-btn"
					color="primary"
					clickHandler={handleAdd}
					eleClassName="MR"
				>
					Add New Step
				</DLButton>
			</div>
			{!fromDialog && (
				<div className="FR AC">
					<DLButton
						eleTestId="prepare-btn"
						color="primary"
						eleClassName="MR"
						disabled={!selectedFile}
						// clickHandler={() =>}
					>
						Prepare
					</DLButton>
					<DLButton
						eleTestId="review-btn"
						color="primary"
						eleClassName="MR"
						disabled={!selectedFile}
						// clickHandler={() =>}
					>
						Review
					</DLButton>
					<DLButton
						eleTestId="assign-users-btn"
						color="primary"
						// clickHandler={() =>}
					>
						Assign Users
					</DLButton>
				</div>
			)}
			<DLButton
				eleTestId="export-btn"
				color="primary"
				// clickHandler={() =>}
			>
				Export
			</DLButton>
		</div>
	)
})
