import { types } from "mobx-state-tree"
// ---------- common models
import { Responses } from "../../../../common-models"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
// separated view model actions
import RequestPasswordReset from "./view-model-actions/request-pw-reset"

const ForgotPasswordViewModel = types
	.model({
		email: types.string,
		// ----- common models
		responseSnackbar: ResponseSnackbar,
		responses: Responses,
	})
	.actions((self) => ({
		setEmail(email: string) {
			self.email = email
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)
	// AGER
	.actions(RequestPasswordReset)

export default ForgotPasswordViewModel
