import React, { useState } from "react"

import Grid from "@material-ui/core/Grid"
import List from "@material-ui/core/List"
import ListItem from "@material-ui/core/ListItem"
import Button from "@material-ui/core/Button"
import ListItemText from "@material-ui/core/ListItemText"
import Styled from "styled-components"
import { Card, CardHeader } from "@material-ui/core"
import Icon from "@mdi/react"

import {
	DLIconButton,
	DLSearchBox,
	DLSpinner,
	DLInputField,
	ConsoleLog,
	DLButton,
} from "../../../components/basic-elements"

import {
	mdiArrowRightBoldCircle,
	mdiArrowLeftBoldCircleOutline,
	mdiSync,
	mdiMagnify,
	mdiCloseCircleOutline,
	mdiArrowRightBold,
	mdiArrowLeftBold,
} from "@mdi/js"

interface IListTranferProps {
	assignedList: any
	unassignedList: any
	selectListHandler: any
	assignedListName: string
	unassignedListName: string
	handleCheckedRight: any
	handleCheckedLeft: any
	handleSearchRight?: any
	handleSearchLeft?: any
	handleSelect50Items?: any
}

export default function DLListTransfer({
	assignedList,
	unassignedList,
	selectListHandler,
	unassignedListName,
	assignedListName,
	handleCheckedLeft,
	handleCheckedRight,
	handleSearchRight,
	handleSearchLeft,
	handleSelect50Items,
}: IListTranferProps) {
	ConsoleLog("DLListTransfer", "")

	// const [assigned, setAssigned] = useState<any>(assignedList)
	// const [unassigned, setUnassigned] = useState<any>(unassignedList)

	const selectHandler = (groupId: string, type: number) => {
		selectListHandler(groupId, type)
	}

	const customList = (items: any[], type: number) => {
		return (
			<List className="list-dialog" dense component="div" role="list">
				{items.map((value: any) => {
					const labelId = `transfer-list-item-${value.id}-label`

					return (
						<ListItem
							key={value.id}
							role="listitem"
							button
							data-testid={`transfer-list-item-${
								type === 1 ? "unassigned" : "assigned"
							}`}
							selected={value.selected}
							disabled={value.isGrayed ? true : false}
							onClick={() => selectHandler(value.id, type)}
						>
							<ListItemText id={labelId} primary={value.name} />
						</ListItem>
					)
				})}
				<ListItem />
			</List>
		)
	}

	return (
		<StyledList>
			<Grid container spacing={2} justify="center" alignItems="center">
				<Grid item>
					<Grid container direction="column" alignItems="flex-start">
						<Card>
							<CardHeader
								className="card-title"
								title={unassignedListName}
								// subheader={group.length}
							/>
							<div className="search-area FR AC">
								<DLInputField
									eleTestId="search-unassigned-list"
									elePlaceholder="Search"
									eleType="search"
									eleHandleChange={handleSearchLeft}
								/>
							</div>
							{customList(unassignedList, 1)}
						</Card>
					</Grid>
				</Grid>
				<Grid item>
					<Grid container direction="column" alignItems="center">
						<DLIconButton
							clickHandler={handleCheckedRight}
							disabled={
								unassignedList.filter(
									(item: any) => item.selected
								).length === 0
							}
							eleTestId="assign-btn"
						>
							<Icon path={mdiArrowRightBold} size={2} />
						</DLIconButton>

						<DLIconButton
							clickHandler={handleCheckedLeft}
							disabled={
								assignedList.filter(
									(item: any) => item.selected
								).length === 0
							}
							eleTestId="unassign-btn"
						>
							<Icon path={mdiArrowLeftBold} size={2} />
						</DLIconButton>
					</Grid>
				</Grid>
				<Grid item>
					<Card>
						<CardHeader
							className="card-title"
							title={assignedListName}
							// subheader={selectedGroup.length}
						/>
						<div className="search-area FR AC">
							<DLInputField
								eleTestId="search-assigned-list"
								elePlaceholder="Search"
								eleType="search"
								eleHandleChange={handleSearchRight}
							/>
						</div>
						{customList(assignedList, 2)}
					</Card>
				</Grid>
			</Grid>
			<div className="FR selectBtn">
				<DLButton
					variant="text"
					eleTestId="add-group-dialog-open-btn"
					color="primary"
					clickHandler={handleSelect50Items}
					disabled={unassignedList.length === 0}
					eleClassName="FR"
				>
					Select 50 Items
				</DLButton>
			</div>
		</StyledList>
	)
}

const StyledList = Styled.div`
    .list-dialog{
        color:black;
        height:200px;
        overflow-y:scroll;
        width:300px;
	}
	.search-area{
		margin-left:1rem;
	}
	.selectBtn{
		padding: 8px 24px;
		margin-left: 84px;
	}
`
