import React from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { CommentDetailDialog } from "../../../../../components/combined-elements/comment"
import { DLSector } from "../../../../../common-models/enumerations/common-enums"
import { DLCommentDetailFormInputProps } from "../../../../../common-models/types/comment-props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function ProjCommentDetailDialogController({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const commentStore = projStore.comments
	const moduleId = DLProjSubMenus.comments
	//
	const commentId = commentStore.selectedFirstItemId

	//
	const roles = projStore.roles.viewRoleSet2().roles
	const assignableUserList = projStore.projUsers.userListWithRoleInfo(roles)
	// TODO: proj-setup-users 에 뭐 이상한 거 잔뜩 만들어놨네.. 다 체크해서 필요없는 거 지우고 통일좀...
	//
	const objFromCommentStore = commentStore.getObjectInfo(commentId)
	const projId = projStore.checkin.projId

	const objectInfo = {
		sector: DLSector.proj,
		sectorId: projId,
		belongsTo:
			objFromCommentStore?.menuId === DLProjSubMenus.wp
				? DLProjSubMenus.wp
				: DLProjSubMenus.pf,
		parentId: "",
		parentTitle: "",
		//
		id: objFromCommentStore?.id,
		title: objFromCommentStore?.title,
		// format: objFromCommentStore?.srcFormat, // TODO:
		format: undefined, // TODO:
		extension: "",
		//
		aliasId: objFromCommentStore?.aliasId,
	}
	// WARNING: Above line will be updated after the api configuration...
	// this component is for the comments screen, not for files
	// NOTE: Can't we merge those two dialogs?
	const handleAddComment = () => {}

	const handleEditComment = (inputs: DLCommentDetailFormInputProps) => {
		projStore.comments.editComment({
			comment: {
				...inputs,
				id: commentId,
			},
			menuId:
				objFromCommentStore?.menuId === DLProjSubMenus.wp
					? DLProjSubMenus.wp
					: DLProjSubMenus.pf,
			postWorkForSuccess: () =>
				projStore.comments.setDetailDialogOpenStatus(false),
			handleResponse: projStore.comments.handleResponse,
		})
	}
	const handleCloseComment = () => {}

	const dntFormat = store.global.getDntFormat

	const commentInfo = commentStore.viewCommentById(commentId, dntFormat)
	const objectId = commentInfo?.objectId
	const mode = commentStore.detailDialogMode
	return (
		<CommentDetailDialog
			open={commentStore.detailDialogOpenStatus}
			setOpen={commentStore.setDetailDialogOpenStatus}
			//
			mode={mode}
			from={moduleId}
			//
			commentId={commentId}
			commentInfo={commentInfo}
			//
			objectId={objectId}
			objectInfo={objectInfo}
			//
			assignableUserList={assignableUserList}
			//
			addComment={handleAddComment}
			editComment={handleEditComment}
			// closeComment={handleCloseComment}
			//
			projRole={projStore.checkin.user.roleShortName}
			i18n={i18n}
		/>
	)
})
