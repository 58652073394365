import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { AddChecklistContent } from "../../../../organization-side/reports-management(Deprecated)/online-projects/sub-components/add-checklist-dialog/AddChecklistDialog"
import { getPeriodAbbrById } from "../../../../../common-models/enumerations/org-related-enums"
import { ChecklistRecordType } from "@datalobby/types/lib"
import styled from "styled-components"
import { DLButton } from "../../../../../components/basic-elements"

const AddChecklist = observer(() => {
	const projStore = useProjStore()
	const [selectedTemplate, setSelectedTemplate] = useState<String | null>(
		null
	)
	const [recommendedTemplate, setRecommendedTemplates] = useState<any>([])

	const { projInfo } = projStore.projInfo
	const projType = projInfo.engTypeId.replace("engTypeId", "projType")
	const orgIdForCdk = "org" + localStorage.getItem("orgId")?.slice(5)

	const handleSelectRecommendedTemplate = (id: string) => {
		const data = [...recommendedTemplate]
		const alreadySelected = data.find((item: any) => item.selected)?.id
		const index = data.findIndex((item: any) => item.id === id)
		if (id === alreadySelected) {
			data[index].selected = !data[index].selected
			setSelectedTemplate(null)
		} else {
			data[index].selected = true
			setSelectedTemplate(id)
		}
		setRecommendedTemplates(data)
	}

	const getProjectId = () => {
		const { periodId, clientId, financialYear, periodEndDate } = projInfo
		const period = getPeriodAbbrById(periodId)
		const client = clientId.replace("clientId", "client")
		const ped = `ped${periodEndDate}`
		return `${financialYear}#${period}#${projType}#${client}#${ped}`
	}

	const handleSave = () => {
		const {
			projectId: id,
			projAliasId,
			projTitle,
			engTypeId,
			engTypeName,
			periodId,
			periodName,
			statusName,
			isLocked,
		} = projInfo
		const projectId = getProjectId()
		const workspaceId = id.replace("projId", "ws")
		const sourceId = selectedTemplate
		const createdBy = localStorage.getItem("orgUserId")

		const wsInfo = {
			stage: statusName,
			isLocked: isLocked,
			archiveDate: null, // Need to fetch
			archivedBy: null, // Need to fetch
		}

		const projectInfo = {
			id,
			aliasId: projAliasId,
			name: projTitle,
			typeId: engTypeId,
			typeName: engTypeName,
			periodType: {
				id: periodId,
				name: periodName,
			},
			owners: null,
			higherQCs: null,
			incharges: null,
			users: null,
		}

		const {
			periodEndDate,
			expectedReportDate,
			finalReportDate,
			expectedArchDeadlineDate,
			finalArchDeadlineDate,
		} = projInfo

		const timelineInfo = {
			periodEndDate,
			archivePolicyPeriod: null,
			expectedReportDate,
			finalReportDate,
			expectedArchiveDate: expectedArchDeadlineDate,
			finalArchiveDeadlineDate: finalArchDeadlineDate,
		}

		const clientInfo = {
			id: projInfo.clientId,
			aliasId: projInfo.clientAliasId,
			name: projInfo.clientName,
		}

		// partialStore.addChecklist({
		// 	projectId,
		// 	workspaceId,
		// 	sourceId,
		// 	destinationType : ChecklistRecordType.list,
		// 	orgId: orgIdForCdk,
		// 	createdBy,
		// 	wsInfo,
		// 	projInfo: projectInfo,
		// 	timelineInfo,
		// 	engInfo: null,
		// 	clientInfo
		// })
	}

	const checklistTemplates = projStore.checklist.checklistTemplates

	const data = {
		projectName: projInfo.projTitle,
		projectType: projInfo.engTypeName,
		checklistTemplates,
		recommendedTemplate,
		setSelectedTemplate,
	}

	return (
		<StyledAddChecklist>
			<AddChecklistContent
				data={data}
				handleSelectRecommendedTemplate={
					handleSelectRecommendedTemplate
				}
			/>
			<DLButton
				eleTestId="save-checklist-btn"
				clickHandler={handleSave}
				disabled={!selectedTemplate}
			>
				Save
			</DLButton>
		</StyledAddChecklist>
	)
})

export default AddChecklist

const StyledAddChecklist = styled.div``
