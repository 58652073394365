import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

const RestoreOrgUser = (self: any) => ({
	restoreUser(userId: string, email: string) {
		const actionName = "restoreUser"
		// 1)
		// 2)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3-1)
		const payload = {
			EmailID: email,
		}
		// 3-2
		self.restoreOrgUser(payload)
			.then((response: any) => {
				// if success
				/// update success case response
				if (response.status === 200 && response.data.Status === 1) {
					if (self.restoreFrom === "createDialog") {
						// NOTE : user id is getting as 0
						// Need to update from backend until then calling get API
						self.getUserList()
						self.setAddDialogOpen(false)
					} else {
						self.updateUserStatus("normal", userId)
					}
					self.setRestoreDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to restore the organization user",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						customMessage: "Failed to restore user",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RestoreOrgUser
