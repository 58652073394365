import React, { useState, useCallback } from "react"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	DLIconButton,
	DLInputField,
} from "../../../../../../components/basic-elements"
import Icon from "@mdi/react"
import {
	mdiFolder,
	mdiPlus,
	mdiPencil,
	mdiTrashCan,
	mdiFolderOpen,
	mdiChevronUp,
	mdiChevronDown,
	mdiCheck,
	mdiClose,
} from "@mdi/js"
import styled from "styled-components"
import { ProjTemplateDialog } from "../../store/org-proj-templates.ui-helper"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"
import sharedRegEx from "../../../../../../library/sharedRegEx"

export default observer(function TemplateGroupRowController({
	node,
	handleClick,
	handleRightClick,
	// handleAdd,
	handleEdit,
	handleDelete,
	permission,
}: {
	node: any
	handleClick: (id: string) => void
	handleRightClick: (e: any, id: string) => void
	// handleAdd: () => void
	handleEdit: (templateGroupId: string, title: string) => void
	handleDelete: (templateGroupId: string) => void
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const moduleId = DLOrgSubMenus.proj_templates
	//
	const [showButtons, setShowButtons] = useState(false)
	const handleMouseEnter = () => {
		setShowButtons(true)
	}
	const handleMouseLeave = () => {
		setShowButtons(false)
	}
	//
	const setEditMode = (request?: boolean) => {
		templateStore.setTemplateGroupEditMode(request)
	}
	const [editModeTitle, setEditModeTitle] = useState(node.title)
	const handleEditMode = useCallback(
		(e: any, request: boolean, title?: string) => {
			e.preventDefault()
			e.target.focus()

			title && setEditModeTitle(title)
			templateStore.setCurrentTargetId(node.id)
			setEditMode(request)
		},
		[]
	)
	//

	const templateGroupId = node.id
	const title = node.title
	let rowWidth = templateStore.viewNavWidth - 60
	let selected = false
	let expanded = node.expanded
	let hasChildren = node.children.length > 0
	let isDuplicatedName =
		templateStore.isDuplicatedTemplateGroupName(editModeTitle)
	let hasSpecialCharacters =
		sharedRegEx.noSpecialCharacters.test(editModeTitle)

	let warningMsg = ""
	if (isDuplicatedName) {
		warningMsg = "Same name already exist"
	} else if (
		editModeTitle !== "" &&
		!sharedRegEx.noSpecialCharacters.test(editModeTitle)
	) {
		warningMsg = "Cannot use special characters"
	} else if (
		editModeTitle === "" ||
		editModeTitle.replace(/ /g, "").length === 0
	) {
		warningMsg = "Please input the name"
	}
	let editMode = templateStore.templateGroupEditMode

	const testId = title.toLowerCase().replace(/ /g, "-")
	const currentTargetId = templateStore.currentTargetId
	return (
		<StyledProjTemplateGroupRow
			className={`FR AC JSB template-group-row ${selected && "selected"}`}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ width: rowWidth }}
			data-testid={`template-group-row-${testId}`}
		>
			{editMode && currentTargetId === templateGroupId ? (
				<div className="FR AC edit-mode">
					<div className="FC">
						<DLInputField
							eleTestId="update-tempate-group-title-input"
							eleValue={editModeTitle}
							eleHandleChange={(e: any) =>
								setEditModeTitle(e.target.value)
							}
						/>
						{editModeTitle !== title && (
							<div className="warning-msg">{warningMsg}</div>
						)}
					</div>
					{permission.update && (
						<DLIconButton
							eleTestId="submit-updated-template-group-name"
							tooltipText="Update"
							tooltipPlacement="top"
							clickHandler={() => {
								handleEdit(templateGroupId, editModeTitle)
								setEditMode(false)
							}}
							variant="contained"
							size="small"
							color="primary"
							disabled={
								isDuplicatedName ||
								editModeTitle === title ||
								editModeTitle.trim() === "" ||
								!hasSpecialCharacters
							}
						>
							<Icon path={mdiCheck} size={0.6} />
						</DLIconButton>
					)}
					<DLIconButton
						eleTestId="cancel-update-template-group-name"
						clickHandler={(e: any) => handleEditMode(e, false)}
						tooltipText="Cancel"
						tooltipPlacement="top"
						size="small"
					>
						<Icon path={mdiClose} size={0.6} />
					</DLIconButton>
				</div>
			) : (
				<div
					className="left-side"
					onClick={() => handleClick(templateGroupId)}
					onContextMenu={(e: any) =>
						handleRightClick(e, templateGroupId)
					}
				>
					{expanded ? (
						<div className="FR AC">
							<Icon
								path={mdiChevronUp}
								size={0.7}
								className="folder-icon expanded"
							/>
							<Icon path={mdiFolderOpen} size={0.8} />
							<div className="title-text">{title}</div>
							{!hasChildren && (
								<span className="no-children"> (No items)</span>
							)}
						</div>
					) : (
						<div className="FR AC">
							<Icon
								path={mdiChevronDown}
								size={0.7}
								className="folder-icon"
							/>

							<Icon path={mdiFolder} size={0.8} />

							<div className="title-text">{title}</div>
						</div>
					)}
				</div>
			)}

			{!editMode && (
				<div className="right-side">
					{showButtons && (
						<div className="buttons-container FR AC">
							{permission.create && (
								<DLIconButton
									eleTestId="add-template-btn-in-tree-nav"
									tooltipText="add template"
									clickHandler={() => {
										templateStore.setSelectedTemplateGroup(
											templateGroupId
										)
										templateStore.setDialogOpen(
											ProjTemplateDialog.createTemplate
										)
									}}
								>
									<Icon path={mdiPlus} size={0.7} />
								</DLIconButton>
							)}
							{permission.update && (
								<DLIconButton
									eleTestId="edit-template-group-btn-in-tree-nav"
									tooltipText="update template group name"
									clickHandler={(e: any) =>
										handleEditMode(e, true, title)
									}
								>
									<Icon path={mdiPencil} size={0.7} />
								</DLIconButton>
							)}
							{permission.delete && (
								<DLIconButton
									eleTestId="delete-template-group-btn-in-tree-nav"
									tooltipText="delete template group"
									clickHandler={() =>
										handleDelete(templateGroupId)
									}
								>
									<Icon path={mdiTrashCan} size={0.7} />
								</DLIconButton>
							)}
							{/* (Noah) cannot understand why this duplication and sort is required....  */}
							{/* {permission.create && (
							<DLIconButton
								eleTestId="duplicate-template-group"
								tooltipText={actions.duplicate.tooltipText}
								clickHandler={actions.duplicate.action}
							>
								<Icon path={mdiContentCopy} size={0.7} />
							</DLIconButton>
						)}
						<DLIconButton
							eleTestId="sort-templates"
							tooltipText={actions.arrange.tooltipText}
							clickHandler={actions.arrange.action}
						>
							<Icon
								path={mdiSortAlphabeticalAscending}
								size={0.7}
							/>
						</DLIconButton> */}
						</div>
					)}
				</div>
			)}
		</StyledProjTemplateGroupRow>
	)
})

const StyledProjTemplateGroupRow = styled.div`
	width: 100%;
	.left-side {
		cursor: pointer;
		.drag-icon {
		}
		.folder-icon {
			&.expanded {
			}
		}
		.title-text {
			margin-left: 0.6rem;
		}
	}
	.right-side {
		.buttons-container {
			opacity: 0;
			transition: 0.4s;
		}
	}
	:hover {
		.buttons-container {
			opacity: 1;
		}
	}
	&.selected {
		font-weight: 700;
		color: ${(props) => props.theme.emphasis};
	}
	.warning-msg {
		color: ${(props) => props.theme.warning};
	}
`
