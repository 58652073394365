import { flow } from "mobx-state-tree"
import { postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

const RequestProjectSearch = (self: any) => ({
	requestProjectSearch: flow(function* (params: any) {
		const actionName = "requestProjectSearch"
		try {
			const endpoint = "/GetProjects"
			const response = yield postRequest({
				url: endpoint,
				params,
				tokenType: TOKEN_TYPE.orgToken,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestProjectSearch
