import React from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiArrowRight, mdiDotsHorizontal } from "@mdi/js"
import {
	DLButton,
	DLIconButton,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { getProjectIcon } from "../../../../../components/basic-elements/icons/common-project-icons"
import { Link } from "react-router-dom"
import {
	CreateProjectMethod,
	initialCreateProjectInputs,
} from "../../../../../components/combined-elements/create-project/create-project.data-props"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

export default observer(function ProjectInfoHeader({ info }: { info: any }) {
	const orgStore = useOrgStore()
	const { accessible } = info.detail

	const projId = info.id
	const projNumId = idToNumber(projId, IdType.project)

	/**
	 * NOTE: When you update the below part, please check similar things together
	 * - ProjectInfoHeader (Project Detail) right click
	 * - TreeNav GroupItemRow
	 * - DetailAreaItemRow -Project
	 */
	const handleRightClick = (e: any) => {
		// ConsoleLog("right click on ProjectInfoHeader")
		e.preventDefault()
		// set mouse point
		orgStore.groups.setClickPoint({
			mouseX: e.clientX - 2,
			mouseY: e.clientY - 4,
		})
		orgStore.groups.setRightClickTarget(projId)
		//
		const currentPath = orgStore.groups.viewCurrentPath
		ConsoleLog("currentPath " + currentPath)
		orgStore.groups.setPathForSelection(currentPath)
		//
		orgStore.groups.setCreateProjectMethod(
			CreateProjectMethod.byRollForward
		)
		//
		// WARNING: When update this project preset for copy, need to update other parts altogether
		// WARNING: 5 parts requires same preset
		// WARNING: Group DetailArea Project Item, Group ProjectInfoHeader, Group treeNav Proj Row, NormalProjectsTablsController, Replica Projects Table Controller
		const projectInfo = {
			...initialCreateProjectInputs,

			cabinetId: info.parentId,
			cabinetName: "loading...",
			title: info.title,
			periodName: info.detail.periodName,
			clientName: info.detail.clientName,
			engTypeName: info.detail.engTypeName,
			createMethod: CreateProjectMethod.byRollForward,
		}
		orgStore.projects.setCreateProjectPresetInputs(projectInfo)
		orgStore.projects.setRfSourceId(projId)
	}

	return (
		<StyledProjectInfoHeader
			className="project-info-header FC"
			data-testid="project-info-header"
		>
			<div className="FC title-area">
				<div className="FR AC title">
					{getProjectIcon(info.detail.projectStatus, 1.2)}
					<span className="title-text heading3">{info.title}</span>
				</div>
				<div className="FR AC JSB sub-area">
					<div>
						<span className="sub-info year">
							{info.detail.financialYear}
						</span>
						<span className="sub-info period-type">
							{info.detail.period}
						</span>
						<span className="sub-info client">
							{info.detail.clientName}
						</span>
					</div>
					<div className="FR AC">
						<DLButton
							eleTestId="project-open-btn"
							eleClassName="open-btn"
							color="primary"
							variant="text"
							disabled={!accessible}
							endIcon={<Icon path={mdiArrowRight} size={0.8} />}
							tooltipText={
								!accessible
									? "Cannot open this project"
									: "Open this project"
							}
						>
							<Link to={`/project/checkin/${projNumId}`}>
								Open
							</Link>
						</DLButton>

						<DLIconButton
							eleTestId="project-ctx-menu-btn"
							clickHandler={handleRightClick}
						>
							<Icon path={mdiDotsHorizontal} size={0.7} />
						</DLIconButton>
					</div>
				</div>
			</div>
		</StyledProjectInfoHeader>
	)
})

const StyledProjectInfoHeader = styled.div`
	width: 100%;
	padding-bottom: 1rem;
	border-top: 1px solid ${(props) => props.theme.shade10};
	border-bottom: 1px solid ${(props) => props.theme.shade10};
	.back-btn {
		opacity: 0.6;
		transition: 0.4s;
		:hover {
			opacity: 1;
		}
	}

	.title-area {
		margin-top: 1rem;
		font-weight: 700;
		.title {
			.title-text {
				margin-left: 0.6rem;
				margin-right: 1rem;
			}
		}
		.sub-area {
			padding-left: 2.4rem;
			.sub-info {
				margin-right: 1rem;
				font-weight: 100;
			}
			.open-btn {
				:hover {
					background-color: ${(props) => props.theme.emphasis};
					color: ${(props) => props.theme.background};
				}
			}
		}
	}
`
