import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"
import { ConsoleLog } from "../../../../../components/basic-elements"

export default observer(function OrgSetupRiskAssessmentTableMenus({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" -=-=-=-=-$ OrgSetupRiskAssessmentTableMenus $-=-=-=-=- ")

	const orgStore = useOrgStore()
	const { twDelete, twEdit } = i18n
	const menuOptions = [
		{
			label: `${twEdit}`,
			value: "ctx-edit-ra",
			available: permission.update,
			clickEvent: () =>
				orgStore.setupRiskAssessment.setEditRiskAssessmentDialogOpen(
					true
				),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: `${twDelete}`,
			value: "ctx-delete-ra",
			available: permission.delete,
			clickEvent: () =>
				orgStore.setupRiskAssessment.setRemoveRiskAssessmentDialogOpen(
					true
				),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	const handleClose = () => {
		orgStore.setupRiskAssessment.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="org-setup-ra-ctx-menus"
			clickPoint={{
				mouseX: orgStore.setupRiskAssessment.clickPoint.mouseX,
				mouseY: orgStore.setupRiskAssessment.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0, 2]}
		/>
	)
})
