import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
//
import { openedFilesModel } from "../../../../service-modules/opened-files-module/data-models/opened-files-models"
import {
	GetOpenedFiles,
	CloseOpenedFiles,
	OpenedFilesActions,
	OpenedFilesViews,
	RequestCloseOpenedFiles,
} from "../../../../service-modules/opened-files-module/view-model-actions"
import { initialStore } from "./opened-files.provider"

const OpenedFilesViewModel = types
	.model({
		openedBySameUser: types.array(openedFilesModel),
		openedByOther: types.array(openedFilesModel),
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	//opened files
	.actions(GetOpenedFiles)
	.actions(CloseOpenedFiles)
	.actions(OpenedFilesActions)
	.actions(RequestCloseOpenedFiles)
	.views(OpenedFilesViews)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OpenedFilesViewModel
