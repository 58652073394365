import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../components/basic-elements"

const AssignUsersToGroup = (self: any, apiAssignUsersToGroup: any) => ({
	assignUsersToGroup: flow(function* (payload) {
		const actionName = "assignUsersToGroup"
		try {
			const response = yield apiAssignUsersToGroup(payload)

			ConsoleLog(["assignUsersToGroup response", response])
			return response
		} catch (error) {
			console.error("assignUsersToGroup error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default AssignUsersToGroup
