import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import Notes from "./Note"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function ProjectTrashController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.note
	const i18nModule = ProjI18n.notes

	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	const needRefresh = projStore.notes.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && projStore.notes.getNotes()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nModule)
	return (
		<>
			{menuAccess ? (
				<>
					<Notes
						partialStore={projStore.notes}
						saveNotesPermission={permission.create}
						//  actionStatus={projStore.notes.responses}
					/>
					<CommonSnackbarComponent
						i18n={i18n}
						partialStore={projStore.notes}
					/>
				</>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
