import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLButton, DLChip } from "../../../../components/basic-elements"
import { DLAvatar } from "@datalobby/components"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { mdiDotsHorizontal } from "@mdi/js"
import Icon from "@mdi/react"
import { findProjRoleInfo } from "../../../../temporary-data/project-side/find-proj-role"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLSector } from "../../../../common-models/enumerations/common-enums"
import { InputFieldForTableV8 } from "../../../../components/basic-elements/tables/InputFieldForTable"
import { Column, Table } from "@tanstack/react-table"
import { singleSelectFilter } from "../../../../library/react-table-utils"
import ReactTableV8, {
	ReactTableColumnProps,
} from "../../../../components/basic-elements/tables/DLReactTable"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { AddProjUserButton } from "../dialogs/AddProjUserDialog"

export default observer(function ProjUsersTable({
	roleSet,
	i18n,
	projUsers,
	permission,
}: {
	roleSet: any
	i18n: DLI18nProps
	projUsers: any
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const projStore = useProjStore()
	// const actionNames = {
	// 	activate: "activateProjUser",
	// 	deactivate: "deactivateProjUser",
	// 	assign: "assignProjUser",
	// 	unassign: "unAssignProjUser",
	// 	edit: "editProjUser",
	// }
	//
	const handleContextMenu = (event: any, userId: string) => {
		event.preventDefault()
		if (ableToUpdate) {
			projStore.projUsers.setClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
			projStore.projUsers.setSelectedUser(userId)
		}
	}
	//
	useEffect(() => {
		if (projStore.roles.viewRoleSet2() === undefined) {
			projStore.roles.getRoleSet(DLSector.proj)
		}
	}, [])

	const [ableToUpdate, setAbleToUpdate] = useState(false)

	useEffect(() => {
		const projStatus = projStore.projInfo.projInfo.statusName
		const isArchived = projStore.projInfo.projInfo.isArchived
		const isPreProcessed =
			projStore.archivePreProcess.preConditionData.isPreProcessed

		if (isArchived || projStatus === ProjectStatus.forceArchived) {
			setAbleToUpdate(false)
		} else if (projStatus === ProjectStatus.unarchived) {
			if (isPreProcessed) {
				setAbleToUpdate(false)
			} else {
				setAbleToUpdate(true)
			}
		} else {
			if (isPreProcessed && !isArchived) {
				setAbleToUpdate(false)
			} else {
				setAbleToUpdate(true)
			}
		}
	}, [])

	//
	// let activating = projStore.projUsers.getActionStatus(actionNames.activate)
	// let deactivating = projStore.projUsers.getActionStatus(
	// 	actionNames.deactivate
	// )
	// let assigning = projStore.projUsers.getActionStatus(actionNames.assign)
	// let unassigning = projStore.projUsers.getActionStatus(actionNames.unassign)
	// let updating = projStore.projUsers.getActionStatus(actionNames.edit)

	const pageHeader = (instance: Table<any>) => {
		const totalUsers = instance.getPreFilteredRowModel().flatRows.length
		const totalInternalUsers = instance
			.getPreFilteredRowModel()
			.flatRows.filter(
				(item: any) => item.original.type === "internal"
			).length
		const totalExternalUsers = instance
			.getPreFilteredRowModel()
			.flatRows.filter(
				(item: any) => item.original.type === "external"
			).length

		const handleUserType = (value: string) => {
			instance.setColumnFilters((props: any) => {
				const filteredProps = props.filter(
					(item: any) => item.id !== "type"
				)
				return [...filteredProps, { id: "type", value }]
			})
		}

		const userType =
			instance
				.getState()
				.columnFilters.find((item: any) => item.id === "type")?.value ||
			"all"

		return (
			<div className="FR JSB" style={{ width: "100%" }}>
				<div className="FR AC counter-container">
					<div
						className={
							userType === "all" ? "counter focused" : "counter"
						}
						onClick={() => handleUserType("all")}
					>
						{i18n.twTotalAssignedUsers || "Total assigned users"}:{" "}
						{totalUsers}
					</div>
					<div style={{ margin: "0 1rem" }}>|</div>
					<span
						className={userType === "internal" ? "focused" : ""}
						onClick={() => handleUserType("internal")}
					>
						{i18n.twInternalUsers || "Internal Users"}:{" "}
						{totalInternalUsers}
					</span>
					<div style={{ margin: "0 1rem" }}>|</div>
					<span
						className={userType === "external" ? "focused" : ""}
						onClick={() => handleUserType("external")}
					>
						{i18n.twExternalUsers || "External Users"}:{" "}
						{totalExternalUsers}
					</span>
					<div style={{ margin: "0 1rem" }}>|</div>
					<DLChip label={roleSet.title} eleTestId="role-set-title" />
				</div>
				<div className="FR">
					{permission.create && ableToUpdate && (
						<AddProjUserButton i18n={i18n} />
					)}
				</div>
			</div>
		)
	}

	return (
		<ReactTableV8
			// TODO: height setting
			tableColumns={ProjUserColumns(
				handleContextMenu,
				ableToUpdate,
				i18n
			)}
			data={projUsers}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.id)
			}
			menuId={DLProjSubMenus.users}
			height={store.ui.currentPageContentsAreaHeight - 58}
			customFilters={filterComponent}
			showPageSetting
			pageHeader={pageHeader}
			getRowClass={(rowInfo: any) => {
				const status = rowInfo.original.status
				let className = ""

				if (status === "Inactive") {
					className = className + " disabled"
				}
				return className
			}}
			// permission={permission}
		/>
	)
})

const filterComponent = ({ column }: { column: Column<any> }) => {
	switch (column.id) {
		case "type":
			return (
				<select
					onChange={(event: any) => {
						column.setFilterValue(event.target.value)
					}}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="type-select"
				>
					<option value="all">All</option>
					<option value="internal">Internal</option>
					<option value="external">External</option>
				</select>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const ProjUserColumns = (
	handleContextMenu: any,
	actionPermission: boolean,
	i18n: DLI18nProps
) => {
	const columns: ReactTableColumnProps = [
		{
			header: i18n.twType || "Type",
			accessorKey: "type",
			cell: renderCustomCell,
			filterFn: singleSelectFilter,
		},
		{
			header: "Status",
			accessorKey: "status",
			cell: (props: any) => {
				const cellId = props.cell.column.id + "-cell"
				return (
					<div data-testid={cellId}>
						{props.getValue()}{" "}
						{props.row.original.inactiveDueTo
							? `(${props.row.original.inactiveDueTo})`
							: ""}
					</div>
				)
			},
		},
		{
			header: i18n.twRole || "Role",
			accessorKey: "roleId",
			cell: (props: any) => {
				// NOTE: Temporary use
				const roleNumId = props.row.original.roleNumId
				const roleInfo = findProjRoleInfo(roleNumId, "ProjUsersTable")
				const cellId = props.cell.column.id + "-cell"
				return (
					<div className="FR AC" data-testid={cellId}>
						{/* TODO: Need to update. but do not update below */}
						<DLAvatar
							size={18}
							fontSize={12}
							text={roleInfo?.shortName}
							eleTestId={`${roleInfo?.shortName}-avatar`}
							color={roleInfo?.color}
						/>
						<span style={{ marginLeft: 4 }}>{roleInfo?.name}</span>
					</div>
				)
			},
		},
		{
			header: i18n.twUserName || "User Name",
			accessorKey: "name",
			cell: renderCustomCell,
		},
		{
			header: i18n.twUserAliasId || "User Alias ID",
			accessorKey: "aliasId",
			cell: renderCustomCell,
		},
		{
			header: i18n.twUserOrgTitle || "Org. Title",
			accessorKey: "orgTitle",
			cell: renderCustomCell,
		},
		{
			header: i18n.twAccessRight || "Access Right",
			accessorKey: "accessTypeName",
			cell: renderCustomCell,
		},
		{
			header: i18n.rfPermission || "Roll Forward Permission",
			accessorKey: "canRollForward",
			cell: (props: any) => {
				const cellId = props.cell.column.id + "-cell"

				return (
					<div
						style={{ width: "100%", textAlign: "center" }}
						data-testid={cellId}
					>
						{props.getValue ? "Yes" : "-"}
					</div>
				)
			},
		},
		{
			header: i18n.twExpDate || "Expiration Date",
			accessorKey: "expDate",
			cell: renderCustomCell,
		},
	]

	if (actionPermission) {
		columns.push({
			header: "Action",
			enableColumnFilter: false,
			accessorKey: "actions",
			cell: (props: any) => {
				const userId = props.row.original.id

				return (
					<div style={{ width: "100%", textAlign: "center" }}>
						<DLButton
							variant="text"
							eleTestId="project-user-list-context-btn"
							clickHandler={(event: any) =>
								handleContextMenu(event, userId)
							}
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLButton>
					</div>
				)
			},
		})
	}

	return columns
}
