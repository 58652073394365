import React from "react"
import DLMenusWithMultipleAction from "../../basic-elements/menus/DLMenusWithMultipleAction"
import { DLIconButton } from "../../basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { handleExport } from "../../basic-elements/export/ExportXLSX"
import { DLI18nProps } from "../../../common-models/types/common-props"
import {
    CommonIconSize,
    DLIcon,
    DLIconName,
} from "../../basic-elements/icons/common-icons"
import { PermissionAsObjectProps } from "../../../common-models/permission"

export default function DefaultPageCtxMenus({
    getTotalData,
    getFilteredData,
    openPageSettingDialog,
    fileName,
    i18n,
    permission,
    toUpperCase = true,
}: {
    getTotalData: any
    getFilteredData: any
    openPageSettingDialog?: any
    fileName: string
    i18n?: DLI18nProps
    permission?: PermissionAsObjectProps
    toUpperCase?: boolean
}) {
    const menuOptions = [
        {
            label: i18n?.twDownloadAll || "Download All (.xlsx)",
            value: "export-xlsx-all",
            available: permission?.export ? true : false,
            icon: (
                <DLIcon
                    name={DLIconName.excel}
                    size={CommonIconSize.ctxMenuIcon}
                    color="green"
                />
            ),
            clickEvent: () => {
                let proceed = window.confirm(
                    `Do you really want to export these ${fileName} as .xlsx?`
                )
                if (proceed) {
                    handleExport(getTotalData(), `All ${fileName}`, toUpperCase)
                } else {
                    return
                }
            },
        },
        {
            label:
                i18n?.twDownloadFiltered || "Download Filtered Table (.xlsx)",
            value: "export-xlsx",
            available: permission?.export ? true : false,
            icon: (
                <DLIcon
                    name={DLIconName.excel}
                    size={CommonIconSize.ctxMenuIcon}
                    color="green"
                />
            ),
            clickEvent: () => {
                let proceed = window.confirm(
                    `Do you really want to export these ${fileName} as .xlsx?`
                )
                if (proceed) {
                    handleExport(
                        getFilteredData(),
                        `Filtered ${fileName}`,
                        toUpperCase
                    )
                } else {
                    return
                }
            },
        },
        {
            label: i18n?.twPageSetting || "Page Setting",
            value: "ctx-page_setting",
            available: openPageSettingDialog !== undefined,
            icon: (
                <DLIcon
                    name={DLIconName.setting}
                    size={CommonIconSize.ctxMenuIcon}
                />
            ),
            clickEvent: openPageSettingDialog,
        },
    ]
    return (
        <DLMenusWithMultipleAction
            remoteButton={
                <DLIconButton eleTestId="page-ctx-btn">
                    <Icon path={mdiDotsHorizontal} size={0.8} />
                </DLIconButton>
            }
            menuOptions={menuOptions}
            eleTestId="page-ctx-menu"
            eleId="page-ctx-menu"
        />
    )
}
