import { flow } from "mobx-state-tree"

const UpdateFinalAnalysis = (self: any, saveFA: any) => ({
	updateFinalAnalysis: flow(function*(payload: {
		Conclusion: string
		Purpose: string
		Scope: string
		lstmodel: any
		fsTypeId: number
		tbNameId: number
		wprId: number
	}) {
		const actionName = "updateFinalAnalysis"
		try {
			const response = yield saveFA(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default UpdateFinalAnalysis
