import React, { useEffect } from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import NotificationSettings from "./NotificationSettings"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function NotificationSettingsController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const actionName1 = "getNotificationSettings"
	const menuId = DLProjSubMenus.notification
	const i18nSheet = ProjI18n.noti
	const partialStore = projStore.notificationSettings
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	const needRefresh = partialStore.needRefresh

	useEffect(() => {
		menuAccess && needRefresh && partialStore.getNotificationSettings()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)
	const dntFormat = store.global.getDntFormat

	return (
		<>
			{menuAccess ? (
				<NotificationSettings
					partialStore={partialStore}
					permission={permission}
					contentsHeight={store.ui.currentPageContentsAreaHeight - 48}
					actionStatus={partialStore.getActionStatus(actionName1)}
					isLocked={isLocked}
					i18n={i18n}
					timeZone={dntFormat.timeZone}
					dateFormat={dntFormat.dateFormat}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
