import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLBreadCrumbs1 } from "../../../../components/basic-elements"

export default observer(function OrgGroupBreadCrumbs() {
	const orgStore = useOrgStore()
	//
	const handleBackToGroupHome = () => {
		orgStore.groups.resetCurrentPath()
	}
	const handleClickCrumb = (id: string, i: number) => {
		orgStore.groups.spliceCurrentPathByIndex(i)
	}

	const path = orgStore.groups.currentPathWithName

	return (
		<DLBreadCrumbs1
			rootName="Groups"
			handleBackToRoot={handleBackToGroupHome}
			path={path}
			handleClickCrumb={handleClickCrumb}
		/>
	)
})
