import { flow } from "mobx-state-tree"
import { OrgEngTypeApiProps } from "../org-eng-types.apis"

const UpdateEngType = (
	self: any,
	apiUpdate: OrgEngTypeApiProps["apiUpdate"]
) => ({
	updateEngType: flow(function*(payload) {
		const actionName = "createEngType"
		try {
			const response = yield apiUpdate(payload)

			// ConsoleLog("requestEngTypeList response", response)
			return response
		} catch (error) {
			// console.error("requestEngTypeList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateEngType
