import React from "react"
import { DLIconButton } from "../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiMinusBox, mdiCheckboxBlankOutline } from "@mdi/js"

export default function ToggleSelectAllCheckbox({
	selectedItemExist,
	toggleAction,
}: {
	selectedItemExist: boolean
	toggleAction: () => void
}) {
	return (
		<>
			{selectedItemExist ? (
				<DLIconButton
					eleTestId="un-select-all-btn"
					tooltipText="Unselect all"
					clickHandler={toggleAction}
				>
					<Icon path={mdiMinusBox} size={0.8} />
				</DLIconButton>
			) : (
				<DLIconButton
					eleTestId="select-all-btn"
					tooltipText="Select all"
					clickHandler={toggleAction}
				>
					<Icon path={mdiCheckboxBlankOutline} size={0.8} />
				</DLIconButton>
			)}
		</>
	)
}
