import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const AddCabinetInGroup = (self: any) => ({
	addCabinetInGroup(props: { title: string; parentId: string }) {
		// 0.
		const actionName = "addCabinetInGroup"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Cannot use empty string here")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const { title, parentId } = checkedProps.props
		self.createGroupItem({ title, parentId }) // NOTE: this model action is in group module
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const data = response.data
					const cabinetId = idToString(data.CabinetID, IdType.cabinet)
					const cabinetName = data.CabinetName
					const createdAt = data.CreatedDate
					const createdBy = data.CreatedUser
					// const parentId = idToString(data.ParentID, IdType)
					const newCabinet = {
						id: cabinetId,
						title: cabinetName,
						parentId: parentId,
						createdBy: createdBy,
						createdByName: data.CreatedUser,
						createdAt: createdAt,
						expanded: false,
						type: DLObjectFormat.cabinet,
						detail: null,
					}

					self.pushItemToList(newCabinet)
					self.setOpenCreateCabinetDialog(false)

					// set success case response
					self.handleResponse({
						actionName,

						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddCabinetInGroup
