import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"

const GetProjPeriodTypes = (self: any) => ({
	getProjPeriodTypes() {
		// 0. set actionName
		const actionName = "getProjPeriodTypes"
		// 1. set response cases
		// self.response.setCases(getProjPeriodTypesReponseCases)
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		self.readProjPeriodTypes()
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						const existingPeriodList = response.data.PeriodList
						let list: any[] = []
						existingPeriodList.map((period: any) => {
							const reOrganizedPeriodList = {
								title: period.PeriodName,
								id: idToString(period.PeriodId, IdType.period),
							}
							list.push(reOrganizedPeriodList)
						})
						self.setPeriodList(list)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Success to get period types",
							color: MessageColorType.blue,
							open: false,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetProjPeriodTypes
