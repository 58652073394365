import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const RegenPreProcessPdfs = (self: any) => ({
	regenPreProcessPdfs(projectId: string, userId: string) {
		// 0.
		const actionName = "regenPreProcessPdfs"
		// 1.
		let proceed = window.confirm(
			"Do you really want to generate pre-process pdfs?"
		)
		if (!proceed) {
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			customMessage: "Pre-Process PDF Request is in progress...",
			open: true,
		})
		// 3.

		self.requestRegenPreProcessPdfs(projectId, userId).then(
			(response: any) => {
				// console.log(actionName + "response" + response)
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					// if success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 111,
						color: MessageColorType.blue,
						customMessage:
							response.data.status.message ||
							response.data.status.Message,
						// "Pre-Process PDF is requested",
						open: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage: "Archive pre-process has been failed.",
						open: true,
					})
				}
			}
		)
	},
	relayRegenPreProcessPdf(projList: any, index: number, tempUserId: number) {
		if (projList.length >= index + 1) {
			const project = projList[index]
			const id = "projId" + project.id
			const userId = "userId" + tempUserId
			self.requestRegenPreProcessPdfs(id, userId)
				.then((response: any) => {
					if (
						response.status === 200 &&
						response.data.status.code === 1
					) {
						console.log(
							"success to request re-generate pdf for",
							project.id,
							project.title
						)
						if (projList.length > index + 1) {
							const func = () => {
								self.relayRegenPreProcessPdf(
									projList,
									index + 1,
									tempUserId
								)
							}
							setTimeout(func, 5000)
						}
						if (projList.length === index + 1) {
							console.log("THE END")
						}
					} else {
						console.log(
							"failed to request re-generate pdf for",
							project.id,
							project.title
						)
						if (projList.length > index + 1) {
							const func = () => {
								self.relayRegenPreProcessPdf(
									projList,
									index + 1,
									tempUserId
								)
							}
							setTimeout(func, 5000)
						}
						if (projList.length === index + 1) {
							console.log("THE END")
						}
					}
				})
				.catch((err: Error) => {
					console.log("error for", project.id, project.title)
					if (projList.length > index + 1) {
						const func = () => {
							self.relayRegenPreProcessPdf(
								projList,
								index + 1,
								tempUserId
							)
						}
						setTimeout(func, 5000)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				})
		}
	},
})

export default RegenPreProcessPdfs
