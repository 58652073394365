import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./fs-setup.provider"
import GetFinancialStatementType from "./view-model-actions/get-financial-statement-type"
import {
	FSType,
	FSGroup,
	FSSubGroup,
	FSLeadSheets,
} from "./data-models/fs-setup.data-model"
import GetFinancialGroupByFSType from "./view-model-actions/get-financial-group-by-fs-type"
import GetFinSubGroupByFinGroup from "./view-model-actions/get-fin-sub-group-by-fin-group"
import GetLeadSheetBySubGroup from "./view-model-actions/get-lead-sheet-by-sub-group"
import GetAvailableFinGroups from "./view-model-actions/get-available-fin-groups"
import AddFinancialGroup from "./view-model-actions/add-financial-group"
import UnmapGroupFromFSType from "./view-model-actions/unmap-group-from-fin-type"
import MapGroupToFSType from "./view-model-actions/map-group-to-fs-type"
import RemoveFinancialGroup from "./view-model-actions/remove-financial-group"
import EditFinancialGroup from "./view-model-actions/edit-financial-group"
import GetAvailableFinSubGroups from "./view-model-actions/get-available-fin-sub-groups"
import AddFinancialSubGroup from "./view-model-actions/add-financial-sub-group"
import EditFinancialSubGroup from "./view-model-actions/edit-financial-sub-group"
import RemoveFinancialSubGroup from "./view-model-actions/remove-financial-sub-group"
import MapSubGroupToGroup from "./view-model-actions/map-sub-group-to-group"
import UnmapSubGroupFromGroup from "./view-model-actions/unmap-sub-group-from-group"
import GetAvailableLeadSheets from "./view-model-actions/get-available-lead-sheets"
import MapLeadSheetToSubGroup from "./view-model-actions/map-lead-sheet-to-sub-group"
import UnmapLeadSheetFromSubGroup from "./view-model-actions/unmap-lead-sheet-from-sub-group"

const FSSetupViewModel = types
	.model({
		fsType: types.array(FSType),
		financialGroups: types.array(FSGroup),
		financialSubGroups: types.array(FSSubGroup),
		leadSheets: types.array(FSLeadSheets), //Need to update
		availableGroups: types.array(FSGroup),
		availableSubGroups: types.array(FSSubGroup),
		availableLeadSheets: types.array(FSLeadSheets), // Need to update

		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setFSType(types: any) {
			self.fsType = types
		},
		pushItemToFinGroups(item: any) {
			self.financialGroups.push(item)
		},
		setFinancialGroups(groups: any) {
			self.financialGroups = groups
		},
		setFinancialSubGroups(subGroups: any) {
			self.financialSubGroups = subGroups
		},
		setLeadSheets(leadSheets: any) {
			self.leadSheets = leadSheets
		},
		setAvailableGroups(groups: any) {
			self.availableGroups = groups
		},
		pushItemToAvailableGroups(item: any) {
			self.availableGroups.push(item)
		},
		pushItemToAvailableSubGroups(item: any) {
			self.availableSubGroups.push(item)
		},
		pushItemToAvailableLeadSheets(item: any) {
			self.availableLeadSheets.push(item)
		},
		editGroupName(fsGroupName: string, fsGroupId: number) {
			const index = self.availableGroups.findIndex(
				(item: any) => item.fsGroupId === fsGroupId
			)
			self.availableGroups[index].fsGroupName = fsGroupName
		},
		editSubGroupName(fsSubGroupName: string, fsSubGroupId: number) {
			const index = self.availableSubGroups.findIndex(
				(item: any) => item.fsSubGroupId === fsSubGroupId
			)
			self.availableSubGroups[index].fsSubGroupName = fsSubGroupName
		},
		spliceAvailableGroupItem(fsGroupId: number) {
			const index = self.availableGroups.findIndex(
				(item: any) => item.fsGroupId === fsGroupId
			)
			self.availableGroups.splice(index, 1)
		},
		spliceAvailableSubGroupItem(fsSubGroupId: number) {
			const index = self.availableSubGroups.findIndex(
				(item: any) => item.fsSubGroupId === fsSubGroupId
			)
			self.availableSubGroups.splice(index, 1)
		},
		setAvailableSubGroups(subGroups: any) {
			self.availableSubGroups = subGroups
		},
		setAvailableLeadSheets(leadSheets: any) {
			self.availableLeadSheets = leadSheets
		},
		unMapGroup(mapId: number) {
			const index = self.financialGroups.findIndex(
				(item: any) => item.fsGroupMapId === mapId
			)
			// ConsoleLog(self.financialGroups[index])
			self.availableGroups.push({
				fsGroupId: self.financialGroups[index].fsGroupId,
				fsGroupName: self.financialGroups[index].fsGroupName,
				fsGroupMapId: self.financialGroups[index].fsGroupMapId,
			})
			self.financialGroups.splice(index, 1)
		},
		mapGroup(fsGroupId: number, mapId: number) {
			const index = self.availableGroups.findIndex(
				(item: any) => item.fsGroupId === fsGroupId
			)
			self.financialGroups.push({
				fsGroupId: self.availableGroups[index].fsGroupId,
				fsGroupName: self.availableGroups[index].fsGroupName,
				fsGroupMapId: mapId,
			})
			self.availableGroups.splice(index, 1)
		},
		mapSubGroup(fsSubGroupId: number, mapId: number) {
			const index = self.availableSubGroups.findIndex(
				(item: any) => item.fsSubGroupId === fsSubGroupId
			)
			self.financialSubGroups.push({
				fsSubGroupId: self.availableSubGroups[index].fsSubGroupId,
				fsSubGroupName: self.availableSubGroups[index].fsSubGroupName,
				fsSubGroupMapId: mapId,
			})
			self.availableSubGroups.splice(index, 1)
		},
		unMapSubGroup(mapId: number) {
			const index = self.financialSubGroups.findIndex(
				(item: any) => item.fsSubGroupMapId === mapId
			)
			self.availableSubGroups.push({
				fsSubGroupId: self.financialSubGroups[index].fsSubGroupId,
				fsSubGroupName: self.financialSubGroups[index].fsSubGroupName,
				fsSubGroupMapId: self.financialSubGroups[index].fsSubGroupMapId,
			})
			self.financialSubGroups.splice(index, 1)
		},
		mapLeadSheet(leadSheetName: string, mapId: number) {
			const index = self.availableLeadSheets.findIndex(
				(item: any) => item.leadSheetName === leadSheetName
			)
			self.leadSheets.push({
				leadSheetName,
				leadSheetMapId: mapId,
			})
			self.availableLeadSheets.splice(index, 1)
		},
		unMapLeadSheet(mapId: number) {
			const index = self.leadSheets.findIndex(
				(item: any) => item.leadSheetMapId === mapId
			)
			self.availableLeadSheets.push({
				leadSheetName: self.leadSheets[index].leadSheetName,
				leadSheetMapId: self.leadSheets[index].leadSheetMapId,
			})
			self.leadSheets.splice(index, 1)
		},
	}))
	//
	.actions(GetFinancialStatementType)
	.actions(GetFinancialGroupByFSType)
	.actions(GetFinSubGroupByFinGroup)
	.actions(GetLeadSheetBySubGroup)
	.actions(GetAvailableFinGroups)
	.actions(AddFinancialGroup)
	.actions(UnmapGroupFromFSType)
	.actions(MapGroupToFSType)
	.actions(RemoveFinancialGroup)
	.actions(EditFinancialGroup)
	.actions(GetAvailableFinSubGroups)
	.actions(AddFinancialSubGroup)
	.actions(EditFinancialSubGroup)
	.actions(RemoveFinancialSubGroup)
	.actions(MapSubGroupToGroup)
	.actions(UnmapSubGroupFromGroup)
	.actions(GetAvailableLeadSheets)
	.actions(MapLeadSheetToSubGroup)
	.actions(UnmapLeadSheetFromSubGroup)
	///
	.views((self) => ({
		viewFSTypes() {
			let types: any[] = []
			self.fsType.map((type) => {
				types.push({
					id: type.fsTypeId,
					name: type.fsTypeName,
				})
			})
			return types
		},
		viewFSGroups() {
			let groups: any[] = []
			self.financialGroups.map((group) => {
				groups.push({
					id: group.fsGroupId,
					name: group.fsGroupName,
					mapId: group.fsGroupMapId,
				})
			})
			return groups
		},
		viewAvailableGroups() {
			let groups: any[] = []
			self.availableGroups.map((group) => {
				groups.push({
					id: group.fsGroupId,
					name: group.fsGroupName,
					mapId: group.fsGroupMapId,
				})
			})
			return groups
		},
		viewAvailableSubGroups() {
			let subGroups: any[] = []
			self.availableSubGroups.map((group) => {
				subGroups.push({
					id: group.fsSubGroupId,
					name: group.fsSubGroupName,
					mapId: group.fsSubGroupMapId,
				})
			})
			return subGroups
		},
		viewFSSubGroups() {
			let subGroups: any[] = []
			self.financialSubGroups.map((group) => {
				subGroups.push({
					id: group.fsSubGroupId,
					name: group.fsSubGroupName,
					mapId: group.fsSubGroupMapId,
				})
			})
			return subGroups
		},
		viewAvailableLeadSheets() {
			let leadSheets: any[] = []
			self.availableLeadSheets.map((leadSheet) => {
				leadSheets.push({
					id: leadSheet.leadSheetName,
					name: leadSheet.leadSheetName,
					mapId: leadSheet.leadSheetMapId,
				})
			})
			return leadSheets
		},
		viewLeadSheets() {
			let leadSheets: any[] = []
			self.leadSheets.map((leadSheet) => {
				leadSheets.push({
					id: leadSheet.leadSheetName,
					name: leadSheet.leadSheetName,
					mapId: leadSheet.leadSheetMapId,
				})
			})
			return leadSheets
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FSSetupViewModel
