import React from "react"
import Tooltip from "@material-ui/core/Tooltip"
import styled from "styled-components"
import { Icon } from "@mdi/react"
import { mdiAlert } from "@mdi/js"
import { DLSignOffModelProps } from "../../../../service-modules/file-module/data-models/sign-off.data-model"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"

interface SignOffCellTooltipProps {
	signOffs: DLSignOffModelProps[]
	columnName: string
	children: any
	fontSize?: "normal" | "small"
	arrow?: boolean
}

/**
 * Note: Wrap the children by HTML element.
 */

const SignOffCellTooltip = ({
	signOffs = [],
	columnName = "Sign Offs",
	children = undefined,
	fontSize = "normal",
	arrow = false,
}: SignOffCellTooltipProps) => {
	return (
		<StyledSignOffCellTooltip className="tooltip-for-sign-off-cell">
			<Tooltip
				className="tooltip-body FR AC"
				title={
					<div
						className="FC tooltip-title"
						style={{
							fontSize: "0.8rem",
							// backgroundColor: "white",
						}}
					>
						<div
							className="column-name"
							style={{ marginBottom: "0.6rem" }}
						>
							{columnName} Sign Offs
						</div>
						{signOffs.map((signOff: any, index: number) => {
							return (
								<div
									className={`FR AC ${signOff.signOffModified &&
										"warning"}`}
									style={{
										color: signOff.signOffModified && "red",
										marginBottom: "0.4rem",
									}}
									key={index}
								>
									{signOff.signOffModified && (
										<Icon
											path={mdiAlert}
											size={0.7}
											color={MessageColorType.red}
										/>
									)}
									{signOff.srcModified && (
										<div
											className="FR AC"
											style={{
												color: MessageColorType.red,
												fontWeight: 700,
											}}
										>
											*
										</div>
									)}
									<span
										className="role"
										style={{
											marginRight: 4,
											width: 32,
											overflow: "hidden",
										}}
									>
										({signOff.roleShortName})
									</span>{" "}
									<span
										className="userId"
										style={{
											marginRight: 4,
											width: 100,
											overflow: "hidden",
										}}
									>
										{signOff.userName}
									</span>{" "}
									<span className="date">
										{signOff.signedAt}
									</span>
								</div>
							)
						})}
					</div>
				}
				arrow={arrow}
				// placement={elePlacement}
			>
				{children}
			</Tooltip>
		</StyledSignOffCellTooltip>
	)
}

const StyledSignOffCellTooltip = styled.div`
	&.tooltip-for-sign-off-cell {
		.tooltip-title {
			.column-name {
			}
			.warning {
				color: red;
				span {
					color: pink;
				}
			}
		}
		/* .tooltip-body {
			.tooltip-title {
				.column-name {
					margin-bottom: 0.6rem;
				}
				.warning {
					color: red;
					span {
						color: pink;
					}
				}
			}
		} */
	}
`

export default SignOffCellTooltip
