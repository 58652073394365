import React from "react"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiLoading } from "@mdi/js"

export default function DLSpinnerByIcon({
	eleClassName,
}: {
	eleClassName?: string
}) {
	return (
		<StyledDLSpinnerByIcon className={`dl-spinner-by-icon ${eleClassName}`}>
			<Icon path={mdiLoading} size={0.8} className="folder-icon" />
		</StyledDLSpinnerByIcon>
	)
}

const StyledDLSpinnerByIcon = styled.div`
	&.dl-spinner-by-icon {
		-webkit-animation: spin 1.2s linear infinite;
		-moz-animation: spin 1.2s linear infinite;
		animation: spin 1.2s linear infinite;
	}
	@-moz-keyframes spin {
		100% {
			-moz-transform: rotate(360deg);
		}
	}
	@-webkit-keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
		}
	}
	@keyframes spin {
		100% {
			-webkit-transform: rotate(360deg);
			transform: rotate(360deg);
		}
	}
`
