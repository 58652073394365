import { types } from "mobx-state-tree"
import ForgotPasswordModel from "./forgot-pw.model"
import ForgotPasswordViewModel from "./forgot-pw.view-model"
import * as api from "./forgot-pw.apis"

export const ForgotPasswordStore = (apiResetPassword: api.resetPwApiType) =>
	types
		.compose(ForgotPasswordModel(apiResetPassword), ForgotPasswordViewModel)
		.named("ForgotPasswordStore")

const ForgotPasswordStoreInstance = ForgotPasswordStore(
	api.resetPassword
).create({
	email: "",
	// shared parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
})

export default ForgotPasswordStoreInstance
