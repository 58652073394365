import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import { DLIconButton } from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

const BulkAddClientsDialogTable = observer(
	({ partialStore, data }: { partialStore: any; data: any }) => {
		function renderEditableFileName(props: any) {
			const clients = JSON.parse(
				JSON.stringify(partialStore.bulkClientList)
			)
			const targetFile = clients.find(
				(client: any) => client.clientId === props.row.original.clientId
			)
			const columnId = props.cell.column.id
			let error = []
			let className = ""
			if (columnId === "aliasId") {
				let duplicateAliasId =
					clients.filter(
						(x: any) => x.aliasId === targetFile[columnId]
					).length > 1
				if (partialStore.isDuplicatedAliasId(targetFile[columnId])) {
					className = "error"
					error.push("Alias id already exist")
				}
				if (duplicateAliasId) {
					className = "error"
					error.push("Duplicated alias id")
				}
			}
			if (columnId === "name") {
				let duplicateName =
					clients.filter((x: any) => x.name === targetFile[columnId])
						.length > 1
				if (partialStore.isDuplicatedName(targetFile[columnId])) {
					className = "error"
					error.push("Client name already exist")
				}
				if (duplicateName) {
					className = "error"
					error.push("Duplicated client name")
				}
			}
			if (targetFile[columnId] === "") {
				className = "error"
			}
			return (
				<div className="FC" style={{ width: "100%" }}>
					<div
						className={className}
						data-testid={`${columnId}-cell`}
						style={{ backgroundColor: "#fafafa", width: "100%" }}
						contentEditable
						suppressContentEditableWarning
						onBlur={(e: any) => {
							const clientId = props.row.original.clientId

							const updated = clients.map((client: any) =>
								client.clientId === clientId
									? {
											...client,
											[columnId]: e.target.innerHTML
												.replace(/&nbsp;/g, " ")
												.trim(),
									  }
									: client
							)

							partialStore.setBulkClientList(updated)
						}}
						dangerouslySetInnerHTML={{
							__html:
								targetFile && targetFile[columnId]
									? targetFile[columnId]
									: "",
						}}
					/>
					{error.length > 0 && (
						<div style={{ color: "red" }}>
							{error.map((item: any) => {
								return (
									<div key={item}>
										<span>{item}</span>
										<br />
									</div>
								)
							})}
						</div>
					)}
				</div>
			)
		}

		const handleRemove = (clientId: string) => {
			partialStore.spliceBulkClient(clientId)
		}

		return (
			<StyledTable>
				<ReactTableV8
					tableColumns={TableColumns(
						renderEditableFileName,
						handleRemove
					)}
					data={data}
					hasPagination={true}
					showPageSetting={false}
					height={!data.length ? 500 : undefined}
					menuId="BulkAddClients"
					getRowClass={(rowInfo: any) => {
						// const clientId = rowInfo.original.clientId
						const aliasId = rowInfo.original.aliasId
						const name = rowInfo.original.name
						const status = rowInfo.original.status
						let className = ""
						if (
							partialStore.isDuplicatedAliasId(aliasId) ||
							partialStore.isDuplicatedName(name)
						) {
							className = className + " warning"
						}
						if (status === ActionStatus.success) {
							className = className + " disabled"
						}
						return className
					}}
				/>
			</StyledTable>
		)
	}
)

const TableColumns = (renderEditableFileName: any, handleRemove: any) => [
	{
		header: "Index",
		accessorKey: "x",
		size: 25,
		cell: (props: any) => {
			return <span>{props.row.index + 1}</span>
		},
	},
	{
		header: "Alias Id",
		accessorKey: "aliasId",
		cell: renderEditableFileName,
	},
	{
		header: "Client Name",
		accessorKey: "name",
		cell: renderEditableFileName,
	},
	{
		header: "Status",
		accessorKey: "status",
		cell: (props: any) => {
			const status = props.row.original.status
			return (
				<div>
					{status === ActionStatus.standby && <span>Stand By</span>}
					{status === ActionStatus.success && (
						<span style={{ color: "green" }}>Successful</span>
					)}
					{status === ActionStatus.fail && (
						<span style={{ color: "red" }}>Failed</span>
					)}
					{status === ActionStatus.loading && (
						<span style={{ color: "orange" }}>In Progress</span>
					)}
				</div>
			)
		},
	},
	{
		header: "Remove",
		accessorKey: "xxx",
		cell: (props: any) => {
			const id = props.row.original.clientId
			const handleClickRemove = () => {
				handleRemove(id)
			}
			return (
				<DLIconButton
					eleTestId={`cancel-select-${props.row.original.aliasId}`}
					clickHandler={handleClickRemove}
					tooltipText="Cancel select"
				>
					<Icon path={mdiClose} size={0.6} />
				</DLIconButton>
			)
		},
	},
]

const StyledTable = styled.div`
	.warning {
		background-color: ${(props) => props.theme.shared.messageOrange};
	}
	.error {
		border: 1px solid ${(props) => props.theme.shared.messageRed};
	}
	.MuiInput-formControl {
		margin-top: 0px;
	}
	.disabled {
		opacity: 0.3;
	}
	table > thead > tr > th {
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
`

export default BulkAddClientsDialogTable
