import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

const UpdateFile = (
	self: any,
	updateFile: FileSystemApiTypes["apiUpdateFile"]
) => ({
	updateFile: flow(function* ({
		projId,
		fileId,
		aliasId,
		title,
	}: {
		projId: string
		fileId: string
		aliasId: string
		title: string
	}) {
		const actionName = "updateFile"
		const menuId = self.storeName
		// 1.
		const prerequisite = [projId, fileId, aliasId, title, menuId]
		for (let i = 0; i < prerequisite.length; i++) {
			const value = prerequisite[i]
			if (value === undefined || value === "") {
				alert(`(${actionName}) props ${i + 1} is undefined`)
				ConsoleLog(`(${actionName}) props ${i + 1} is undefined`)
				return
			}
		}
		//
		//
		// 2.

		let projNumberId: any = ""
		if (menuId === DLOrgSubMenus.proj_templates) {
			projNumberId = idToNumber(projId, IdType.projTemplate)
		} else {
			projNumberId = idToNumber(projId, IdType.project)
		}

		const fileNumberId = idToNumber(fileId, IdType.file)
		//
		//
		// 3.
		if (
			typeof projNumberId === "number" &&
			typeof fileNumberId === "number"
		) {
			let payload: any = {
				ClientID: projNumberId,
				WPRID: fileNumberId,
				WPEF: aliasId,
				WPDESC: title,
				// UserID: 16
			}
			if (menuId === DLOrgSubMenus.proj_templates) {
				payload = {
					TemplateId: fileNumberId,
					WPREF: aliasId,
					TempName: title,
					// UserID: 16
				}
			}
			try {
				const response = yield updateFile({ payload, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${projNumberId} | ${fileNumberId}`)
			ConsoleLog(`(${actionName}) ${projNumberId} | ${fileNumberId}`)
		}
	}),
})

export default UpdateFile
