import { types } from "mobx-state-tree"
// common models
// model common action
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// separated actions (CRUD)
import RequestLogin from "./model-actions/request-login"
import RequestAdminLogin from "./model-actions/request-admin-login"
import RequestLogout from "./model-actions/request-logout"
import ReadLobbyDefault from "./model-actions/read-lobby-default"
import { LobbyCheckinProps } from "./lobby-access-control.apis"

const LobbyCheckinModel = ({
	apiLogin,
	apiLogout,
	apiReadInfo,
}: LobbyCheckinProps) =>
	types
		.model({})
		// separated actions
		.actions((self) => RequestLogin(self, apiLogin))
		.actions((self) => RequestLogout(self, apiLogout))
		.actions((self) => ReadLobbyDefault(self, apiReadInfo))
		.actions((self) => RequestAdminLogin(self, apiLogin))
		// common parts
		.actions(CommonModelActions)

export default LobbyCheckinModel
