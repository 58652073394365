import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
} from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import { MessageColorType } from "../../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function EditFileLibFolderDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "editOrgLibraryFolder"
	//
	const folderId = orgStore.orgFileLib.selectedLibraryFolder
	const folderInfo = orgStore.orgFileLib.selectedFolderDetails(folderId)
	const inputsSchema = {
		name: { value: folderInfo?.title, error: "", requestInput: false },
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters, // TODO: need to update
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}
	const editLibraryFolder = () => {
		orgStore.orgFileLib.editOrgLibraryFolder(inputs.name.value, folderId)
	}
	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		editLibraryFolder,
		orgStore.orgFileLib.openEditLibraryFolder // resetPoint
	)

	const actionStatus = orgStore.orgFileLib.getActionStatus(actionName)
	return (
		<DLDialog
			eleTestId="edit-org-library-folder"
			isOpen={orgStore.orgFileLib.openEditLibraryFolder}
			setIsOpen={orgStore.orgFileLib.setOpenEditLibraryFolder}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twEditLibGroup || "Edit Library Group"}
			dialogContents={
				<EditLibraryFolderForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={isReady}
			actionBtn={i18n.twEdit || "Edit"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.orgFileLib.responses.getResponse(actionName)?.message
			}
			showSpinner={actionStatus === "LOADING"}
			cannotUpdate={actionStatus === "LOADING"}
			useEnterKeyForSubmit
		/>
	)
})

const EditLibraryFolderForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledEditLibraryFolderForm>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twLibGroupName || "Library Group Name"}
					>
						<DLInputField
							eleTestId="library-folder-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.name.error}
							requestInput={inputs.name.requestInput}
						/>
						{orgStore.orgFileLib.isDuplicatedLibraryFolderName(
							inputs.name.value
						) &&
							orgStore.orgFileLib.selectedFolderDetails(
								orgStore.orgFileLib.selectedLibraryFolder
							)?.title !== inputs.name.value && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg={
										i18n.tsSameNameExist ||
										"This name already exist."
									}
								/>
							)}
					</InputWithLabel>
				</div>
			</StyledEditLibraryFolderForm>
		)
	}
)

const StyledEditLibraryFolderForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
