import { FilterFn } from "@tanstack/react-table"

const filterDate = (rowDate: any, startDate: any, endDate: any) => {
	if (!startDate && !endDate) {
		return true
	} else if (startDate && !endDate) {
		return new Date(rowDate).getTime() >= new Date(startDate).getTime()
	} else if (!startDate && endDate) {
		return new Date(rowDate).getTime() <= new Date(endDate).getTime()
	} else {
		return (
			new Date(rowDate).getTime() >= new Date(startDate).getTime() &&
			new Date(rowDate).getTime() <= new Date(endDate).getTime()
		)
	}
}

export const singleSelectFilter: FilterFn<any> = (
	row,
	columnId,
	value,
	addMeta
) => {
	if (value === "all") return true
	else return row.getValue(columnId) === value
}

export const statusFilter: FilterFn<any> = (row, columnId, value, addMeta) => {
	if (value === "all") return true
	else return row.getValue(columnId) === (value === "true" ? true : false)
}

export const dateRangeFilter: FilterFn<any> = (row, columnId, value, addMeta) =>
	filterDate(row.getValue(columnId), value.startDate, value.endDate)

export const yearFilter: FilterFn<any> = (row, columnId, value) => {
	if (value === "All Years") return true
	console.log(row.getValue(columnId), value)
	return row.getValue(columnId).toString() === value.toString()
}

export const multiSelectFilter: FilterFn<any> = (
	row,
	columnId,
	value,
	addMeta
) => {
	if (value !== null && value !== "" && value.length) {
		if (value.includes(row.getValue(columnId))) {
			return true
		} else {
			return false
		}
	} else return true
}

export const filterMethodForQCandEP: FilterFn<any> = (row, columnId, value) => {
	if (value !== "" && value !== null && value.length) {
		for (let i = 0; i < row.getValue(columnId).length; i++) {
			for (let j = 0; j < value.length; j++) {
				if (row.getValue(columnId)[i].name === value[j]) {
					return true
				}
			}
		}
		return false
	} else {
		return true
	}
}
