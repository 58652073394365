import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const RequestDownloadAllAsZip = (
	self: any,
	downloadAll: FileSystemApiTypes["apiDownloadAll"]
) => ({
	requestDownloadAllAsZip: flow(function* ({
		projId,
		templateId,
	}: {
		projId?: string
		templateId?: string
	}) {
		const actionName = "requestDownloadAllAsZip"
		const menuId = self.storeName
		// 1.
		if (
			menuId !== DLOrgSubMenus.proj_templates &&
			(projId === undefined || projId === "")
		) {
			alert(`(${actionName}) Undefined props. project ID: ${projId}`)
			return
		}
		if (
			menuId === DLOrgSubMenus.proj_templates &&
			(templateId === undefined || templateId === "")
		) {
			alert(`(${actionName}) Undefined props. template ID: ${templateId}`)
			return
		}
		//
		//
		// 2.
		const numberId = idToNumber(projId || "", IdType.project)
		const tempId = idToNumber(templateId || "", IdType.projTemplate)
		let params: any
		if (menuId !== DLOrgSubMenus.proj_templates) {
			params = {
				clientID: numberId,
				// UserID: 677,
			}
			if (menuId === DLProjSubMenus.pf) {
				params = {
					client: numberId,
				}
			}
		}
		if (menuId === DLOrgSubMenus.proj_templates) {
			params = {
				ParentFldID: tempId,
			}
		}
		//
		//
		// 3.
		if (
			(menuId !== DLOrgSubMenus.proj_templates &&
				typeof numberId === "number") ||
			(menuId === DLOrgSubMenus.proj_templates &&
				typeof tempId === "number")
		) {
			try {
				const response = yield downloadAll({ params, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) Invalid fortmat prop exist. ${numberId}`)
		}
	}),
})

export default RequestDownloadAllAsZip
