import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../components/basic-elements"

const UnAssignUsersFromGroup = (self: any, apiUnAssignUsersToGroup: any) => ({
	unAssignUsersFromGroup: flow(function* (payload) {
		const actionName = "unAssignUsersFromGroup"
		try {
			const response = yield apiUnAssignUsersToGroup(payload)

			ConsoleLog(["unAssignUsersFromGroup response", response])
			return response
		} catch (error) {
			console.error("unAssignUsersFromGroup error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UnAssignUsersFromGroup
