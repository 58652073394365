import React, { useState } from "react"
import Icon from "@mdi/react"
//
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import sharedRegEx from "../../../../library/sharedRegEx"
import { DLInputField, DLButton } from "../../../../components/basic-elements"
//
import styled from "styled-components"
import { mdiLockQuestion, mdiSend } from "@mdi/js"

export default function ForgotPwDefault({
	i18n,
}: {
	i18n: { [x: string]: string }
}) {
	const store = useRootStore()

	const initialValues = {
		value: "",
		isValid: true,
	}
	const [email, setEmail] = useState(initialValues)

	const handleEmailInput = (event: any) => {
		if (!sharedRegEx.email.test(email.value)) {
			setEmail({ value: event.target.value, isValid: false })
		} else {
			setEmail({ value: event.target.value, isValid: true })
		}
	}

	const handleSubmit = () => {
		if (email.isValid) {
			store.forgotPw.requestPasswordReset(email.value)
		}
	}
	const {
		twForgotPw,
		tpHelpP1,
		tsHelpS2,
		twRequest,
		tsEmailInvalid,
		tsHelpS1,
		twEmailAddress,
	} = i18n
	return (
		<StyledForgotPwDefault className="card-body">
			<Icon path={mdiLockQuestion} size={5} color="#ffffff" />
			<h1 className="page-title title heading1">
				{twForgotPw ? twForgotPw : "Forgot Password"}
			</h1>
			<div className="description">
				{tpHelpP1 ? (
					tpHelpP1
				) : (
					<>
						Please submit your email address of the account. You
						will get 2 emails from us in several minutes. One has
						password update link, another has authentication code.
					</>
				)}
			</div>
			<div className="email-input FC">
				<DLInputField
					eleRequired
					eleValue={email.value}
					eleTestId="email_input_testId"
					elePlaceholder={
						tsHelpS1 ? tsHelpS1 : "Please input your email address"
					}
					eleLabel={twEmailAddress ? twEmailAddress : "Email Address"}
					eleType="email"
					eleFullWidth
					eleHandleChange={handleEmailInput}
					warningMsg={
						!email.isValid
							? tsEmailInvalid
								? tsEmailInvalid
								: "Email format is invalid"
							: undefined
					}
					warningType="coral"
				/>
			</div>
			<div className="submit-button">
				<div
					className={
						email.value.length > 0 && email.isValid
							? "help-text visible"
							: "help-text"
					}
				>
					{tsHelpS2
						? tsHelpS2
						: "Do you want to proceed password reset?"}
				</div>

				<DLButton
					variant="contained"
					eleTestId="forgot_pw_submit_btn"
					clickHandler={handleSubmit}
					color="primary"
					disabled={!(email.isValid && email.value.length > 0)}
					endIcon={<Icon path={mdiSend} size={0.8} />}
				>
					{twRequest ? twRequest : "Request"}
				</DLButton>
			</div>
		</StyledForgotPwDefault>
	)
}

const StyledForgotPwDefault = styled.div`
	.description {
		width: 300px;
	}
	.submit-button {
		margin-top: 3rem;
		.help-text {
			margin-bottom: 1rem;
		}
	}
`
