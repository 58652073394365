import { types } from "mobx-state-tree"
import { ClickPoint } from "../../../../../common-models/types/dialog.props"
import {
    CommonSelectedItem,
    SelectedItemsSharedActions,
    SelectedItemsSharedViews,
} from "../../../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"

const ArchiveMgmtUnarchivedProjectsUiHelper = types
    .model({
        selectedProj: types.string,
        selectedCabinetId: types.string,
        clickPoint: ClickPoint,
        selectedProjType: "",
        //
        selectedItems: types.array(CommonSelectedItem),
        //
        // dialogs
        projectDetailsDialogOpen: types.boolean,
        openDeleteProjDialog: types.boolean,
    })
    .actions(SelectedItemsSharedActions)
    .views(SelectedItemsSharedViews)
    .actions((self) => ({
        setSelectedProj(projectId: string) {
            self.selectedProj = projectId
        },
        setSelectedCabinetId(cabinetId: string) {
            self.selectedCabinetId = cabinetId
        },
        setClickPoint(clickPoint: typeof self.clickPoint) {
            self.clickPoint = clickPoint
        },
        setSelectedProjType(type: string) {
            self.selectedProjType = type
        },
        // dialogs
        setProjectDetailsDialogOpen(request?: boolean) {
            if (request !== undefined) {
                self.projectDetailsDialogOpen = request
            } else {
                self.projectDetailsDialogOpen = !self.projectDetailsDialogOpen
            }
        },
        setOpenDeleteProjDialog(request?: boolean) {
            if (request !== undefined) {
                self.openDeleteProjDialog = request
            } else {
                self.openDeleteProjDialog = !self.openDeleteProjDialog
            }
        },
    }))

export default ArchiveMgmtUnarchivedProjectsUiHelper
