import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
} from "../../../../../../components/basic-elements"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import { MessageColorType } from "../../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function EditFileLibTemplateDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "editOrgLibraryTemplate"
	//
	const inputsSchema = {
		name: {
			value: orgStore.orgFileLib.selectedTemplate.title,
			error: "",
			requestInput: false,
		},
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters, // TODO: need to update
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}
	const editLibraryTemplate = () => {
		orgStore.orgFileLib.editOrgLibraryTemplate(
			inputs.name.value,
			orgStore.orgFileLib.selectedTemplate.id
		)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		editLibraryTemplate,
		orgStore.orgFileLib.openEditLibraryTemplate // resetPoint
	)

	return (
		<DLDialog
			eleTestId="edit-org-library-Template"
			isOpen={orgStore.orgFileLib.openEditLibraryTemplate}
			setIsOpen={orgStore.orgFileLib.setOpenEditLibraryTemplate}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twEditFileLib || "Edit File Library"}
			dialogContents={
				<EditLibraryTemplateForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={isReady}
			actionBtn={i18n.twUpdate || "Update"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.orgFileLib.responses.getResponse(actionName)?.message
			}
			showSpinner={
				orgStore.orgFileLib.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.orgFileLib.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const EditLibraryTemplateForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledEditLibraryTemplateForm>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twFileLibName || "File Library Name"}
					>
						<DLInputField
							eleTestId="library-template-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.name.error}
							requestInput={inputs.name.requestInput}
						/>
						{orgStore.orgFileLib.isDuplicatedLibraryTemplateName(
							inputs.name.value,
							orgStore.orgFileLib.selectedTemplate.parentId
						) &&
							orgStore.orgFileLib.selectedTemplate.title !==
								inputs.name.value && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg={
										i18n.tsSameNameExist ||
										"The same name already exist"
									}
								/>
							)}
					</InputWithLabel>
				</div>
			</StyledEditLibraryTemplateForm>
		)
	}
)

const StyledEditLibraryTemplateForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
