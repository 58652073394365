import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import InputWithLabel from "../../../../components/basic-elements/input-with-label/InputWithLabel"
import sharedRegEx from "../../../../library/sharedRegEx"
import useForm from "../../../../library/use-form"
import styled from "styled-components"
import {
	ConsoleLog,
	DLDialog,
	DLInputField,
	DLRadioGroup,
} from "../../../../components/basic-elements"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useLobbyStore } from "../../../../stores/lobby-store/lobby-store.provider"

const EditCustomerDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const store = useRootStore()
	const lobbyStore = useLobbyStore()
	const actionName = "editOrgCustomer"
	//
	const custInfo = lobbyStore.orgMgmt.selectedCustDetails(
		lobbyStore.orgMgmt.selectedId
	)
	const inputsSchema = {
		name: {
			value: custInfo?.custName || "",
			error: "",
			requestInput: false,
		},
		customerId: {
			value: custInfo?.custId || "",
			error: "",
			requestInput: false,
		},
		email: {
			value: custInfo?.emailId || "",
			error: "",
			requestInput: false,
		},
		organization: {
			value: custInfo?.organizationName || "",
			error: "",
			requestInput: false,
		},
		status: {
			value: custInfo?.custStatus || "Active",
			error: "",
			requestInput: false,
		},
	}

	const validationSchema = {
		name: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.nameWithSpace,
			// 	error: "Invalid format",
			// },
		},
		customerId: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.name,
			// 	error: "Invalid format",
			// },
		},
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email,
				error: "Invalid format",
			},
		},
		organization: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.nameWithSpace,
			// 	error: "Invalid format",
			// },
		},
	}

	const editCustomer = () => {
		lobbyStore.orgMgmt.editOrgCustomer(
			inputs,
			lobbyStore.orgMgmt.selectedId
		)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		editCustomer
		// open // resetPoint
	)

	ConsoleLog("EditCustomerDialog has been rendered")

	return (
		<DLDialog
			eleTestId="edit-org-customer-dialog"
			draggable
			isOpen={lobbyStore.orgMgmt.openEdit}
			setIsOpen={lobbyStore.orgMgmt.setOpenEdit}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twEditOrg}
			dialogContents={
				<EditCustomerDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel}
			actionReady={isReady}
			actionBtn={i18n.twUpdate}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				lobbyStore.orgMgmt.getActionStatus(actionName) === "LOADING"
					? true
					: false
			}
		/>
	)
})

const EditCustomerDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const store = useRootStore()
		const lobbyStore = useLobbyStore()
		const custInfo = lobbyStore.orgMgmt.selectedCustDetails(
			lobbyStore.orgMgmt.selectedId
		)

		return (
			<StyledEditCustomerDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserName}>
						<DLInputField
							eleTestId="customer-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.name.error}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserId}>
						<DLInputField
							eleTestId="customer-id-input"
							eleFullWidth
							eleName="customerId"
							eleValue={inputs.customerId.value}
							eleHandleChange={handleOnChange}
							eleRequired
							eleReadOnly
							requestInput={inputs.customerId.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserEmail}>
						<DLInputField
							eleTestId="customer-email-input"
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.email.error}
							eleReadOnly
							requestInput={inputs.email.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel label={i18n.twOrganizationName}>
						<DLInputField
							eleTestId="organization-input"
							eleFullWidth
							eleName="organization"
							eleValue={inputs.organization.value}
							eleHandleChange={handleOnChange}
							warningMsg={
								custInfo?.organizationName !==
									inputs.organization.value &&
								lobbyStore.orgMgmt.isDuplicatedOrganization(
									inputs.organization.value
								)
									? "Organization with same name already exists"
									: inputs.organization.error
							}
							warningType={
								custInfo?.organizationName !==
									inputs.organization.value &&
								lobbyStore.orgMgmt.isDuplicatedOrganization(
									inputs.organization.value
								)
									? "red"
									: undefined
							}
							eleRequired
							requestInput={inputs.organization.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel label={i18n.twStatus}>
						<DLRadioGroup
							selectedValue={inputs.status.value}
							groupName="status"
							items={[
								{ value: "Active", label: "Active" },
								{ value: "Inactive", label: "Inactive" },
							]}
							eleOnChange={handleOnChange}
							color="primary"
							size="small"
							itemDirection="row"
						/>
					</InputWithLabel>
				</div>
			</StyledEditCustomerDialog>
		)
	}
)

const StyledEditCustomerDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default EditCustomerDialog
