import { types } from "mobx-state-tree"
import UpdatePassword from "./model-actions/update-password"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import { MyAccountProps } from "./my-account.apis"

const MyAccountModel = ({ apiChangePassword }: MyAccountProps) =>
	types
		.model({})
		.actions((self) => UpdatePassword(self, apiChangePassword))
		// common parts
		.actions(CommonModelActions)

export default MyAccountModel
