import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export function commentIdToString(numberId: number, menuId: DLProjSubMenus) {
	let cmtId = ""
	if (menuId === DLProjSubMenus.wp) {
		cmtId = idToString(numberId, IdType.wpComment)
	} else if (menuId === DLProjSubMenus.pf) {
		cmtId = idToString(numberId, IdType.pfComment)
	} else {
		alert(`! cannot find matched menu for comment: ${menuId} / ${numberId}`)
	}
	return cmtId
}

export function commentIdToNumber(stringId: string) {
	let cmtId: number | string = ""
	if (stringId.includes("wp")) {
		cmtId = idToNumber(stringId, IdType.wpComment)
	} else if (stringId.includes("pf")) {
		cmtId = idToNumber(stringId, IdType.pfComment)
	} else {
		alert(`! cannot find matched menu for comment: ${stringId}`)
	}
	return cmtId
}
