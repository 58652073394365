import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import AccessMgmtDialogForm from "./AccessMgmtDialogForm"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"

export default observer(function AccessMgmtDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- AccessMgmtDialog -=-=-=-=- ")
	const orgStore = useOrgStore()

	useEffect(() => {
		orgStore.archiveMgmt.getReadOnlyUsers(orgStore.archiveMgmt.selectedProj)
	}, [])

	const actionStatus = orgStore.archiveMgmt.getActionStatus

	const isLoading =
		actionStatus("assignUser") === "LOADING" ||
		actionStatus("unAssignReadOnlyUser") === "LOADING" ||
		actionStatus("updateReadOnlyUser") === "LOADING" ||
		actionStatus("getReadOnlyUsers") === "LOADING"
	return (
		<StyledDialog>
			<DLDialog
				eleTestId="access-management-dialog"
				isOpen={orgStore.archiveMgmt.accessMgmtDialogOpen}
				setIsOpen={orgStore.archiveMgmt.setAccessMgmtDialogOpen}
				showCloseBtn={true}
				dialogTitle={i18n.tsReadOnlyAccessManagement}
				dialogContents={
					!isLoading && <AccessMgmtDialogForm i18n={i18n} />
				}
				maxWidth="lg"
				fullWidth={true}
				showSpinner={isLoading}
			/>
		</StyledDialog>
	)
})

const StyledDialog = styled.div`
	.MuiDialog-container {
		height: 580px;
	}
	.dl-dialog-content {
		height: 440px !important;
	}
`
