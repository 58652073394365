import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import FSCard from "./FSCard"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function LeadSheet({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()
	const handleClick = () => {}

	const handleDialogOpen = () => {}

	return (
		<FSCard
			headerName="Lead Sheet"
			list={projStore.fsSetup.viewLeadSheets()}
			clickHandler={handleClick}
			handleDialogOpen={handleDialogOpen}
			permission={permission}
		/>
	)
})
