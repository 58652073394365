import React from "react"
import { Route } from "react-router-dom"
import { observer } from "mobx-react-lite"
import NormalProjectsController from "./normal//NormalProjectsController"
import ReplicaProjectsController from "./replica/ReplicaProjectsController"
import ArchivedProjectsController from "./archived/ArchivedProjectsController"
import UnrachivedProjectsController from "./unarchived/UnarchivedProjectsController"
import ROAMArchivedProjectsController from "./roam-archived/ROAMArchivedProjectsController"
import ForceArchivedController from "./force-archived/ForceArchivedController"

export default observer(function AssignedProjects() {
	return (
		<>
			<Route
				exact
				path="/organization/assigned-projects"
				component={NormalProjectsController}
			/>
			<Route
				exact
				path="/organization/assigned-projects/normal"
				component={NormalProjectsController}
			/>
			<Route
				exact
				path="/organization/assigned-projects/replica"
				component={ReplicaProjectsController}
			/>
			<Route
				exact
				path="/organization/assigned-projects/archived"
				component={ArchivedProjectsController}
			/>
			<Route
				exact
				path="/organization/assigned-projects/unarchived"
				component={UnrachivedProjectsController}
			/>
			<Route
				exact
				path="/organization/assigned-projects/archived-readonly"
				component={ROAMArchivedProjectsController}
			/>
			<Route
				exact
				path="/organization/assigned-projects/assigned-force-archived"
				component={ForceArchivedController}
			/>
		</>
	)
})
