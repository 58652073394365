import { types } from "mobx-state-tree"
// model & view-model
import OrgDashboardModel from "./org-dashboard.model"
import OrgDashboardViewModel from "./org-dashboard.view-model"
// apis
import * as api from "./org-dashboard.apis"
import { CardType } from "./data-models/dashboard-table-view.data-model"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"

export const initialStore = {
	createProjectMethod: CreateProjectMethod.byUndefined,
	cardType: CardType.progressRate,
	fromDate: null,
	toDate: null,
	clickPoint: initialClickPoint,
	//common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

export const OrgDashboardStore = ({ apiRead }: api.OrgDashboardApiProps) =>
	types
		.compose(OrgDashboardModel({ apiRead }), OrgDashboardViewModel)
		.named("OrgDashboardStore")

const OrgDashboardStoreInstance = OrgDashboardStore({
	apiRead: api.read,
}).create(initialStore)

export default OrgDashboardStoreInstance
