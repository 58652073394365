import { flow } from "mobx-state-tree"
import { DLSector } from "../../../../../../common-models/enumerations/common-enums"
import { getRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

const ReadPermissionSet = (self: any) => ({
	readPermissionSet: flow(function* (sector: DLSector, roleId: string) {
		const actionName = "readPermissionSet"

		let acCtrlSetId =
			sector === DLSector.org
				? "aul-default-org-acCtrlSet-2021-01"
				: sector === DLSector.proj
				? "aul-default-proj-acCtrlSet-2021-01"
				: "-"

		const id = roleId
		// let id =
		// 	sector === DLSector.org
		// 		? "aul-org-2021-01-aa"
		// 		: sector === DLSector.proj
		// 		? "aul-proj-2021-01-pa"
		// 		: "-"

		const customer = process.env.REACT_APP_CUSTOMER_FOR_ALLOWED_HOST

		// old url: `/appAcCtrl/getPermissionSet?customer=${customer}&acCtrlSetId=${acCtrlSetId}&id=${id}`
		// const url = `/app-access-ctrl/permission-sets`
		const url = `/appAcCtrl/getPermissionSet?customer=${customer}&acCtrlSetId=${acCtrlSetId}&id=${id}`
		// const params = {
		// 	acCtrlSetId,
		// 	id,
		// }

		try {
			const response = yield getRequest({
				url,
				// params,
				tokenType: TOKEN_TYPE.adminApp,
			})
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadPermissionSet
