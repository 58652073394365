import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const data = {
	// ---------- bsection 1. 기본 정보
	name: "오성", // 법인명
	aliasId: "3232-42-343243-4", // 법인번호
	isInStockMarket: true, // 상장여부
	stockMarketType: "KOSPI", // 상장 구분
	isExcludeStdAuditHour: false, // 표준감사시간적용 제외법인 여부
	isBigEnterprizeByKICPA: false, // 한국공인회계사회에서 정한 대규모 기업 여부
	//
	bizTypes: "제조업", // 업종구분
	//
	//
	isHoldingCompany: false, // 지주회사 여부
	holdingCompanyType: null, // 지주회사 구분
	hasParentCompany: false, // 모회사 존재 여부
	isParentCompanyStockMarket: null, // 모회사 상장여부 구분
	isParentCompanyLarge: null, // 모회사 대규모지주회사 여부
	isUSStockMarket: false, // 미국증시 상장 여부
	hasConsolidated: false, // 연결재무제표 여부
	subsidiaryEntityCount: 0, // 자회사 개수
	isSubsidiaryAuditorSame: false, // 종속/관계회사 동일감사인 여부
}

export default observer(function ClientDefaultInfo() {
	const orgStore = useOrgStore()

	return (
		<StyledClientDefaultInfo>
			Client Default Information
		</StyledClientDefaultInfo>
	)
})

const StyledClientDefaultInfo = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
