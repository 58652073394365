// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { OrgLibraryType } from "../data-models/org-library.data-model"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetOrgFileLibFolders = (self: any) => ({
	getOrgFileLibFolders(orgId: string) {
		// 0.
		const actionName = "getOrgFileLibFolders"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readOrgFileLibFolders(orgId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					let list: any[] = []
					const fetchedFolders = response.data.TemplateListRes
					const fetchedAuditProgramFolders =
						response.data.AuditTemplateListRes
					//
					fetchedFolders.map((folder: any) => {
						const folderId =
							"libraryFolderId" + folder.TempFoldID.toString()
						if (folder.TemplateList) {
							folder.TemplateList.map((library: any) => {
								const reOrganizedLibrary = {
									id:
										"wpTemplateId" +
										library.TempId.toString(),
									parentId: folderId,
									title: library.TempName,
									type: OrgLibraryType.wpTemplate,
									expanded: false,
								}

								list.push(reOrganizedLibrary)
							})
						}
						const reOrganizedFolder = {
							id: folderId,
							parentId: null,
							title: folder.TempFoldName,
							type: OrgLibraryType.libraryFolder,
							expanded: false,
						}

						list.push(reOrganizedFolder)
					})
					fetchedAuditProgramFolders.map((folder: any) => {
						const folderId =
							"libraryFolderId" + folder.TempFoldID.toString()
						if (folder.TemplateList) {
							folder.TemplateList.map((library: any) => {
								const reOrganizedLibrary = {
									id:
										"apTemplateId" +
										library.TempId.toString(),
									parentId: folderId,
									title: library.TempName,
									type: OrgLibraryType.apTemplate,
									expanded: false,
								}

								list.push(reOrganizedLibrary)
							})
						}
					})
					// ConsoleLog(list)
					self.setLibraryFolders(list)
					self.setNeedRefresh(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetOrgFileLibFolders
