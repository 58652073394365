import { flow } from "mobx-state-tree"

const DeleteProjTemplateGroup = (self: any, deleteTemplateGroup: any) => ({
	deleteProjTemplateGroup: flow(function* (templateGroupId: number) {
		const actionName = "deleteProjTemplateGroup"
		try {
			let params = {
				TempFoldID: templateGroupId,
				// UserID: 16
			}
			const response = yield deleteTemplateGroup(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteProjTemplateGroup
