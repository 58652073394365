import React from "react"
import { DLIconButton } from "../../../basic-elements"
import Icon from "@mdi/react"
import { mdiAlert, mdiAlertOutline } from "@mdi/js"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { ProgressStatus } from "../../../../common-models/enumerations/common-enums"
import { PermissionAsObjectProps } from "../../../../common-models/permission"

export default observer(function FileRowStatusArea({
	fileId,
	fileAliasId,
	status,
	handleStatus,
	permission,
}: {
	fileId: string
	fileAliasId: string
	status: ProgressStatus
	handleStatus: (fileId: string, currentStatus: ProgressStatus) => void
	permission: PermissionAsObjectProps
}) {
	const handleClick = () => {
		if (permission.update) {
			handleStatus(fileId, status)
		} else {
			alert("Sorry. You don't have the permission")
		}
	}

	return (
		<StyledFileRowStatusArea>
			<div className="file-row-prop file-status">
				<DLIconButton
					eleClassName={`status-icon ${status}`}
					eleTestId={`file-row-status-icon-${fileAliasId}`}
					variant="iconOnly"
					size="small"
					tooltipText="Status"
					clickHandler={handleClick}
				>
					{status === ProgressStatus.todo ||
					status === ProgressStatus.inProgress ? (
						<Icon path={mdiAlert} size={0.6} />
					) : (
						<Icon path={mdiAlertOutline} size={0.6} />
					)}
				</DLIconButton>
			</div>
		</StyledFileRowStatusArea>
	)
})

const StyledFileRowStatusArea = styled.div`
	/* border: 1px solid brown; */
	.file-row-prop.file-status {
		.status-icon {
			&.TODO {
				svg {
					color: ${(props) => props.theme.themeRed};
				}
			}
			&.IN_PROGRESS {
				svg {
					color: ${(props) => props.theme.themeOrange};
				}
			}
			color: ${(props) => props.theme.shade10};
		}
	}
`
