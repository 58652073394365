import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const EditNotificationSettings = (self: any) => ({
	editNotificationSettings(payload: any) {
		// 0. set actionName
		const actionName = "editNotificationSettings"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call
		self.updateNotificationSettings(payload)
			.then((response: any) => {
				if (
					response.status === 200 &&
					response.data.data.Status === 1
				) {
					// if success
					// 1) reset the stored list

					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditNotificationSettings
