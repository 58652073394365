import { types } from "mobx-state-tree"
import FinancialStatementsModel from "./financial-statements.model"
import FinancialStatementsViewModel from "./financial-statements.view-model"
import * as api from "./financial-statements.api"

export const FinancialStatementsStore = ({
	apiRead,
	apiReadTrialBalance,
}: api.FinancialStatementsApiProps) =>
	types
		.compose(
			FinancialStatementsModel({ apiRead, apiReadTrialBalance }),
			FinancialStatementsViewModel
		)
		.named("FinancialStatementsStore")

export const initialStore = {
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const FinancialStatementsStoreInstance = FinancialStatementsStore({
	apiRead: api.read,
	apiReadTrialBalance: api.readTrialBalance,
}).create(initialStore)

export default FinancialStatementsStoreInstance
