import { flow } from "mobx-state-tree"
import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { OrgCheckinApiProps } from "../org-checkin.apis"

const ReadOrgToken = (
	self: any,
	requestToken: OrgCheckinApiProps["apiRequestToken"]
) => ({
	readOrgToken: flow(function* (orgId: string, userId: string) {
		const actionName = "readOrgToken"
		//
		//
		// 1.
		if (
			orgId === undefined ||
			orgId === "" ||
			userId === undefined ||
			userId === ""
		) {
			alert(`(${actionName}) undefined props exist: ${orgId} ${userId}`)
			ConsoleLog(
				`(${actionName}) undefined props exist: ${orgId} ${userId}`
			)
			return
		}
		//
		// organize props
		const orgNumberId = idToNumber(orgId, IdType.org)
		const userNumberId = idToNumber(userId, IdType.user)
		//
		//
		// 2.
		if (
			typeof orgNumberId === "number" &&
			typeof userNumberId === "number"
		) {
			try {
				// WARNING: Do not use params here. It will get 400 error. (Is this fine..?)

				const response = yield requestToken({
					userNumberId,
					orgNumberId,
				})

				// ConsoleLog("response of org token request", response)
				return response
			} catch (error) {
				console.error("request org token error", error)
				self.handleTokenError({ error: error, openSnackbar: true })
				return
			}
		} else {
			alert(
				`(${actionName}) fail to get numberID: ${orgNumberId} | ${userNumberId}`
			)
			ConsoleLog(
				`(${actionName}) fail to get numberID:  ${orgNumberId} | ${userNumberId}`
			)
			self.setOrgCheckin(ActionStatus.fail)
			return
		}
	}),
})

export default ReadOrgToken
