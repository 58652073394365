import React from "react"
import { useOrgStore } from "../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { Route, Redirect } from "react-router-dom"
import { ActionStatus } from "../common-models/enumerations/common-enums"
import { ConsoleLog } from "../components/basic-elements"

/**
 * WARNING: Manadatory Items of OrgRoute
 * // 1. lobbyUserId
 * // 2. lobbyToken
 * (lobbyUserId and lobbyToken existence was checked on the LobbyRoute already)
 *
 * 3. orgUserId
 * 4. orgId
 * 5. orgToken
 *
 * If any of these conditions do not exist,
 * redirect the user to lobby screen
 */
const OrgRoute = observer(
	({ children, path }: { children: any; path: string }) => {
		/**
		 * * <Q1> Is prerequisiete true?
		 * --- if false: redirect to the lobby
		 * --- if true: --> Q2
		 * * <Q2> Is OrganizationCheckin Status success?
		 * ------ if false: redirect to the organization checkin
		 * ------ if true: redirect to original organization screen (children of the route)
		 */
		ConsoleLog(" >>>>> OrgRoute <<<<< " + path)
		const orgStore = useOrgStore()

		// required items
		const orgUserId = localStorage.getItem("orgUserId")
		const orgId = localStorage.getItem("orgId")
		const orgToken = localStorage.getItem("orgToken")
		//
		const prerequisite =
			orgUserId !== null && orgId !== null && orgToken !== null
		//
		const orgCheckinStatus = orgStore.checkin.orgCheckin
		if (orgCheckinStatus !== ActionStatus.success) {
			ConsoleLog("Initialize ORG stores")
			orgStore.checkin.initializeStore()
		}

		return (
			<Route
				path={path}
				render={({ location }) =>
					prerequisite === false ? (
						<Redirect
							to={{
								pathname: "/lobby/organization-list",
							}}
						/>
					) : orgCheckinStatus !== "SUCCESS" ? (
						<Redirect
							to={{
								pathname: "/organization/checkin",
								state: { from: location },
							}}
						/>
					) : (
						children
					)
				}
			/>
		)
	}
)

export default OrgRoute
