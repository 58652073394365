import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
} from "../../../../../../components/basic-elements"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import { ProjTemplateDialog } from "../../store/org-proj-templates.ui-helper"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"

export default observer(function AddProjTemplateDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "addProjTemplate"
	const i18n = store.i18n.globalI18n("AddProjTemplateDialog")

	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}
	const userId = orgStore.checkin.orgUserId

	const addTemplateGroup = () => {
		orgStore.orgProjTemplates.addProjTemplate({
			userId,
			templateGroupId: orgStore.orgProjTemplates.selectedTemplateGroup,
			templateTitle: inputs.name.value,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addTemplateGroup
	)

	return (
		<DLDialog
			eleTestId="add-proj-template-dialog"
			isOpen={
				orgStore.orgProjTemplates.dialogOpenStatus[
					ProjTemplateDialog.createTemplate
				]
			}
			setIsOpen={() =>
				orgStore.orgProjTemplates.setDialogOpen(
					ProjTemplateDialog.createTemplate
				)
			}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twAddProjTemplate || "Add Project Template"}
			dialogContents={
				<AddProjTemplateForm
					inputs={inputs}
					handleOnChange={handleOnChange}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				!orgStore.orgProjTemplates.isDuplicatedTemplateName(
					inputs.name.value
				) && isReady
			}
			// actionStatus={orgStore.orgProjTemplates.getActionStatus(
			// 	actionName
			// )}
			actionBtn={i18n.twAdd || "Add"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.orgProjTemplates.responses.getResponse(actionName)
					?.message
			}
			showSpinner={
				orgStore.orgProjTemplates.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.orgProjTemplates.getActionStatus(actionName) ===
				"LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const AddProjTemplateForm = observer(
	({ inputs, handleOnChange }: { inputs: any; handleOnChange: any }) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddProjTemplateForm>
				<div className="input-section FR">
					<InputWithLabel required label="Template Name">
						<DLInputField
							eleTestId="template-name-input"
							eleFullWidth
							autoFocus
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(inputs.name.value &&
									orgStore.orgProjTemplates.isDuplicatedTemplateName(
										inputs.name.value
									) &&
									"This name already exist.")
							}
							warningType={
								inputs.name.value &&
								orgStore.orgProjTemplates.isDuplicatedTemplateName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddProjTemplateForm>
		)
	}
)

const StyledAddProjTemplateForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
