import { types } from "mobx-state-tree"
import ReadChecklistTemplates from "./model-actions/read-checklist-templates"
import { OrgSetupChecklistApiProps } from "./org-setup-checklist.apis"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"

export const OrgSetupChecklistModel = ({
	apiRead,
}: OrgSetupChecklistApiProps) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadChecklistTemplates(self, read))
		// common model actions
		.actions(CommonModelActions)