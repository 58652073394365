import { types } from "mobx-state-tree"

export const ArchiveHistoryFile = types.model({
	file: types.union(types.null, types.string), // [File] ex) null
	type: types.union(types.null, types.string), // [FileType] ex) null
	name: types.string, // [FileName] ex) "DevExpress Tickets - Summary"
	order: types.integer, // [Order] ex) 1
	preSignedUrl: types.optional(types.string, ""), // [PreSignedURL] ex) ""
	archiveDataObj: types.union(types.null, types.string), // [ArchiveDataObj] ex) null
	filePath: types.union(types.null, types.string) // [filepath] ex) null
})

export const ArchiveHistoryItem = types.model({
	type: types.string, // [Type] ex) "UnArchive v4 "
	archivedUnArchivedBy: types.string, // [ArchivedUnArchivedBy] ex) "vanitha"
	archiveId: types.string, // [ArchiveId] ex) 1043
	archivedUnArchivedAt: types.string, // [ArchivedUnArchivedDate] ex) "2020-01-10T10:05:55.978712+00:00"
	sourceProjectId: types.string, // [SourceProjectID] ex) 3464
	sourceProjectType: types.string, // [SourceProjectType] ex) "Archived"
	sourceProjectName: types.string, // [SourceProjectName] ex) "Full year 2020 rationale : Archive - (test) (Archive v4)"
	isDisabled: types.boolean, // [IsGrayOut] ex)  true
	reason: types.string, // [Reason] ex)  "8281B833678782537936A8EF1AFE12FC5AC4C7E347E99BD57F884428A582F76C8281B833678782537936A8EF1AFE12FC5AC4C7E347E99BD57F884428A582F76C8281B833678782537936A8EF1AFE12FC5AC4C7E347E99BD57F884428A582F76C"
	fileList: types.array(ArchiveHistoryFile), // [filesLists] ex)  [,…]
	clientId: types.string // [ClientID] ex) 1043  // TODO: is this client? or project?
})
