import { flow } from "mobx-state-tree"
import {
	ReadChecklistsProps,
	ReportsMgmtApiProps,
} from "../reports-management.apis"

const ReadChecklists = (
	self: any,
	readChecklists: ReportsMgmtApiProps["apiReadChecklists"]
) => ({
	readChecklists: flow(function* (params: ReadChecklistsProps) {
		const actionName = "readChecklists"
		try {
			const response = yield readChecklists(params)
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadChecklists
