import React from "react"
import { types, flow, Instance, applySnapshot } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
//
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { initialStore } from "./notifications.provider"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"
import GetSignedUrls from "../../../../service-modules/post/store-view-model-actions/get-signed-urls"

import EditPost from "../../../../service-modules/post/store-view-model-actions/edit-post"
import RemoveOrRestorePost from "../../../../service-modules/post/store-view-model-actions/remove-or-restore-post"
import AddPost from "../../../../service-modules/post/store-view-model-actions/add-post"
import RemoveFilesFromS3 from "../../../../service-modules/post/store-view-model-actions/remove-files-from-s3"

export enum S3ObjMethod {
	put = "PUT",
	delete = "DELETE",
}

export type S3FileStatus = {
	key: string
	status: ActionStatus
	fileName: string
	extension: string
	size: number
	formattedSize: string
	methodType: S3ObjMethod
}

const NotificationsViewModel = types
	.model({
		clickPoint: ClickPoint,
		selectedItem: "",
		// to upload attachment files and save the post information by graphql mutation
		getSignedUrlsStatus: ActionStatus.standby,
		savePostDialogOpen: false,
		s3FilesStatus: types.array(
			types.model({
				// NOTE: key has extension at the end. It is different with id
				key: types.string,
				status: types.enumeration<ActionStatus>(
					Object.values(ActionStatus)
				),
				//
				fileName: types.string,
				extension: types.string,
				size: types.number,
				formattedSize: types.string,
				methodType: types.enumeration<S3ObjMethod>(
					Object.values(S3ObjMethod)
				),
			})
		),
		// ---------- common models
		needRefresh: true,
		actionStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions(GetSignedUrls)
	.actions(RemoveFilesFromS3)
	.actions(AddPost)
	.actions(RemoveOrRestorePost)
	.actions(EditPost)
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(id: string) {
			self.selectedItem = id
		},
		setGetSignedUrlsStatus(status: ActionStatus) {
			self.getSignedUrlsStatus = status
		},
		setSavePostDialogOpen(request: boolean) {
			self.savePostDialogOpen = request
		},
		resetS3FilesStatus() {
			self.s3FilesStatus.length = 0
		},
		addItemToS3FilesStatus(item: {
			key: string
			status: ActionStatus
			fileName: string
			extension: string
			size: number
			formattedSize: string
			methodType: S3ObjMethod
		}) {
			self.s3FilesStatus.push(item)
		},
	}))
	.views((self) => ({}))
	// common parts
	.actions(Refresh)
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default NotificationsViewModel
