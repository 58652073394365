import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import { CommonViewModelActions } from "../../../../../common-models/common-view-model-actions"
// separated view model actions
import GetOrgRoleDetails from "./view-model-actions/get-org-role-details"
import GetAssignedUsers from "./view-model-actions/get-assigned-users"
import {
	RoleSet,
	CombinedRole,
	AssignedUser,
	RoleInfoProps,
	CombinedRoleProps,
	CombinedMenuProps,
} from "../../../../../service-modules/role-module/roles.data-model"
import { initialStore } from "./org-roles.provider"
import GetRoleSet from "../../../org-access-control/org-checkin/store/view-model-actions/get-role-set"

const UiHelperActions = (self: any) => ({
	toggle(menuId: string) {
		const targetMenu = self.roleDetails
			.find(
				(role: CombinedRoleProps) => role.roleId === self.selectedRole
			)
			?.menuPermissions.find(
				(menu: CombinedMenuProps) => menu.menuId === menuId
			)
		// ConsoleLog("toggle----targetMenu", targetMenu)
		if (targetMenu) {
			targetMenu.collapsed = !targetMenu.collapsed
		}
	},
	expandAll() {
		const targetRole = self.roleDetails.find(
			(role: CombinedRoleProps) => role.roleId === self.selectedRole
		)

		targetRole?.menuPermissions.map((menu: CombinedMenuProps) => {
			menu.collapsed = false
		})
	},
	collapseAll() {
		const targetRole = self.roleDetails.find(
			(role: CombinedRoleProps) => role.roleId === self.selectedRole
		)

		targetRole?.menuPermissions.map((menu: CombinedMenuProps) => {
			menu.collapsed = true
		})
	},
})

const OrgRolesViewModel = types
	.model({
		roleSet: RoleSet,
		selectedRole: types.optional(types.string, ""),
		roleDetails: types.array(CombinedRole),
		assignedUsers: types.array(AssignedUser),
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setRoleSet(roleSet: any) {
			self.roleSet = roleSet
		},
		setSelectedRole(roleId: string) {
			self.selectedRole = roleId
		},
		setRoleDetails(roleDetails: any) {
			self.roleDetails.length = 0
			self.roleDetails = roleDetails
		},
		setAssignedUsers(users: any) {
			self.assignedUsers.length = 0
			self.assignedUsers = users
		},
	}))
	.views((self) => ({
		viewRoleSet() {
			let viewRoleSet: any[] = []
			self.roleSet.roles?.map((role: RoleInfoProps) => {
				const matchedRole = self.roleDetails.find(
					(roleDetail) => roleDetail.roleId === role.roleId
				)
				let users: any[] = []
				self.assignedUsers.map((user) => {
					if (user.roleId === role.roleId) {
						users.push(user)
					}
				})
				let updatedRole: any
				if (matchedRole) {
					updatedRole = {
						...role,
						usePeriod: matchedRole.usePeriod,
						globalPermissions: matchedRole.globalPermissions,
						menuPermissions: matchedRole.menuPermissions,
						//
						users: users,
					}
				} else {
					console.error(
						"(viewRoleSet in org) No matched role exist",
						role.roleId
					)
				}

				viewRoleSet.push(updatedRole)
			})
			return viewRoleSet
		},
		viewRoleDetail(roleId: string) {
			const role = self.roleSet.roles?.find(
				(role) => role.roleId === roleId
			)
			const matchedRole = self.roleDetails.find(
				(roleDetail) => roleDetail.roleId === roleId
			)
			let users: any[] = []
			self.assignedUsers.map((user) => {
				if (user.roleId === roleId) {
					users.push(user)
				}
			})
			const viewRoleDetail = {
				roleId: role?.roleId,
				//
				name: role?.name,
				shortName: role?.shortName,
				color: role?.color,
				//
				usePeriod: matchedRole?.usePeriod,
				globalPermissions: matchedRole?.globalPermissions,
				menuPermissions: matchedRole?.menuPermissions,
				//
				users: users,
			}
			// ConsoleLog("updated role", viewRoleDetail)
			return viewRoleDetail
		},
		allMenusAreCollapsed() {
			const targetRole = self.roleDetails.find(
				(roleDetail) => roleDetail.roleId === self.selectedRole
			)
			if (
				targetRole?.menuPermissions.find(
					(element) => element.collapsed === false
				)
			) {
				return false
			} else {
				return true
			}
		},
		allMenusAreExpanded() {
			const targetRole = self.roleDetails.find(
				(roleDetail) => roleDetail.roleId === self.selectedRole
			)

			if (
				targetRole?.menuPermissions.find(
					(element) => element.collapsed === true
				)
			) {
				return false
			} else {
				return true
			}
		},
	}))
	// AGER(Add, Get, Edit, Remove) Actions
	.actions(GetRoleSet) // same with org checkin
	.actions(GetOrgRoleDetails)
	.actions(GetAssignedUsers)
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupRoles")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.actions(UiHelperActions)

export default OrgRolesViewModel
