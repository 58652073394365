import { flow } from "mobx-state-tree"

const RequestUnassignProjUser = (self: any, unassign: any) => ({
	requestUnassignProjUser: flow(function* (payload) {
		const actionName = "requestUnassignProjUser"
		try {
			const response = yield unassign(payload)
			// console.log(actionName, "__response: ", response)

			return response
		} catch (error) {
			// console.log(actionName, "__error: ", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestUnassignProjUser
