import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import sharedRegEx from "../../../../library/sharedRegEx"
import useForm from "../../../../library/use-form"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { DLDialog, DLInputField, InputWithLabel } from "../../../basic-elements"
import { NoSpecialChar2Msg } from "../../../basic-elements/system-msg/DLSystemMsg"
import styled from "styled-components"
import keyboardJS from "keyboardjs"

export default observer(function FileFolderDialog({
	partialStore,
	actionHandler,
	i18n,
	type,
}: {
	partialStore: any
	actionHandler: (folderName: string) => void
	i18n: DLI18nProps
	type: "add" | "edit"
}) {
	const actionName = type === "add" ? "addFileFolder" : "editFileFolder"
	const dialogName =
		type === "add"
			? FileScreenDialog.createFolder
			: FileScreenDialog.editFolder

	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const [prevFolderName, setPrevFolderName] = useState("")

	useEffect(() => {
		if (type === "edit") {
			const folderId = partialStore.selectedFolder
			const prevName = partialStore.getItemById(folderId).title
			setPrevFolderName(prevName)
			inputsSchema.name.value = prevName
		}
	}, [type])

	// NOTE: @FileName, FileAliasId, FolderName Validation
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialChar2,
				error: i18n.warningSpecialChar2 || <NoSpecialChar2Msg />,
			},
			maxLength: 50,
		},
	}

	const open = partialStore.fileTreeDialogOpenStatus[dialogName]

	const setOpen = (request: boolean) => {
		partialStore.setFileTreeDialogOpen(dialogName, request)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		() => actionHandler(inputs.name.value),
		open
	)

	const actionStatus = partialStore.getActionStatus(actionName)
	let isDuplicated = false
	if (type === "add") {
		isDuplicated = partialStore.isDuplicatedFolderName(inputs.name.value)
	} else {
		isDuplicated =
			prevFolderName !== inputs.name.value &&
			partialStore.isDuplicatedFolderName(inputs.name.value)
	}

	const isLoading = actionStatus === "LOADING"
	return (
		<DLDialog
			eleTestId={type + "-file-folder-dialog"}
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				type === "add"
					? i18n.twAddFolder || "Add Folder"
					: i18n.twEditFolderName || "Edit Folder Name"
			}
			dialogContents={
				<FileFolderDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					isDuplicated={isDuplicated}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				isReady && !isDuplicated && prevFolderName !== inputs.name.value
			}
			actionBtn={
				type === "add" ? i18n.twAdd || "Add" : i18n.twEdit || "Edit"
			}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={isLoading}
			cannotUpdate={isLoading}
			useEnterKeyForSubmit
		/>
	)
})

const FileFolderDialogForm = observer(
	({
		inputs,
		handleOnChange,
		isDuplicated,
		i18n,
	}: {
		inputs: any
		handleOnChange: (props: any) => void
		isDuplicated: boolean
		i18n: DLI18nProps
	}) => {
		return (
			<StyledFileFolderForm>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twFolderName || "Folder Name"}
					>
						<DLInputField
							eleTestId="folder-name-input"
							autoFocus
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								isDuplicated
									? i18n.tsSameNameExist ||
									  "The same name already exist"
									: inputs.name.error
							}
							warningType="orange" // @Noah: this warning type also has impact on the testID. It seems not good. Have to update
							requestInput={inputs.name.requestInput}
							eleFullWidth
						/>
					</InputWithLabel>
				</div>
			</StyledFileFolderForm>
		)
	}
)

const StyledFileFolderForm = styled.div`
	.input-section {
		.label {
			min-width: 9rem;
			height: 4rem;
			/* height: include warning message */
		}
		.input-area {
			min-width: 20rem;
		}
	}
`
