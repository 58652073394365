import {
	ActionStatus,
	DLSector,
} from "../../../../../../common-models/enumerations/common-enums"
import defaultOrgRoleSet from "../../../../../../admin-static-data/app-access-control-table/aul-default-org-role-set-2021-01.json"
import defaultProjRoleSet from "../../../../../../admin-static-data/app-access-control-table/aul-default-proj-role-set-2021-01.json"

const GetRoleSet = (self: any) => ({
	getRoleSet(sector: DLSector) {
		const actionName = "getRoleSet"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		let roleSetData: any = []

		if (sector === DLSector.org) {
			roleSetData = defaultOrgRoleSet
		} else if (sector === DLSector.proj) {
			roleSetData = defaultProjRoleSet
		}
		self.setRoleSet(roleSetData)

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.success,
		})
	},
})

export default GetRoleSet
