import { flow } from "mobx-state-tree"

const ProjectUnlock = (self: any, unlock: any) => ({
	projectUnLock: flow(function*() {
		const actionName = "projectUnLock"
		try {
			const response = yield unlock()
			// ConsoleLog("project Un-Lock response", response)
			return response
		} catch (error) {
			// ConsoleLog("project Un-Lock error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ProjectUnlock
