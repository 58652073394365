import { flow } from "mobx-state-tree"

const UpdateOrgLibraryFolder = (self: any, updateLibraryFolder: any) => ({
	updateOrgLibraryFolder: flow(function* (payload: any) {
		const actionName = "updateOrgLibraryFolder"
		try {
			const response = yield updateLibraryFolder(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateOrgLibraryFolder
