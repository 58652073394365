import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const EditEngType = (self: any) => ({
	editEngType(props: {
		engTypeName: string
		engagementId: string
		userId: string
	}) {
		// 0.
		const actionName = "editEngType"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const { engTypeName, engagementId, userId } = checkedProps.props
		const payload = {
			EngagementName: engTypeName,
			EngagementID: idToNumber(engagementId.toString(), IdType.engType),
			ModifiedBy: idToNumber(userId.toString(), IdType.user),
		}
		// ConsoleLog(payload)
		self.updateEngType(payload)
			.then((response: any) => {
				// if success
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					self.updateEngStore(engTypeName, engagementId)
					// close the dialog
					self.setOpenEdit(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update the engagement type",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditEngType
