import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import { ArchPreProcessApiProps } from "./archive-pre-process.apis"
// model actions
import ReadPreConditionStatus from "./model-actions/read-pre-condition-status"
import DeletePreConditionFiles from "./model-actions/delete-pre-condition-files"
import CreatePreProcessing from "./model-actions/create-pre-processing"
import MArchiveProject from "./model-actions/m-archive-project"

const ArchivePreProcessModel = ({
	apiCheckPreConditionStatus,
	apiRemovePreConditionFiles,
	apiStartPreProcess,
	apiArchiveProject,
}: ArchPreProcessApiProps) =>
	types
		.model({})
		//
		.actions((self, preConditionStatus = apiCheckPreConditionStatus) =>
			ReadPreConditionStatus(self, preConditionStatus)
		)
		.actions((self, startPreProcess = apiStartPreProcess) =>
			CreatePreProcessing(self, startPreProcess)
		)
		.actions((self, removePreConditionFiles = apiRemovePreConditionFiles) =>
			DeletePreConditionFiles(self, removePreConditionFiles)
		)
		.actions((self, archiveProject = apiArchiveProject) =>
			MArchiveProject(self, archiveProject)
		)
		// common parts
		.actions(CommonModelActions)

export default ArchivePreProcessModel
