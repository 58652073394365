import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton } from "../../../../../components/basic-elements"

const FiltersDialog = observer(({
    open,
    setOpen,
    dialogContent,
}: {
    open: boolean,
    setOpen: any,
    dialogContent: any,
}) => {
		return (
			<DLDialog
				eleTestId="filters-dialog"
				draggable
				isOpen={open}
				setIsOpen={setOpen}
				showCloseBtn={true}
				dialogTitle="Filters"
				dialogContents={dialogContent}
				maxWidth="md"
				fullWidth={true}
			/>
		)
	}
)

export default FiltersDialog
