import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { Doughnut } from "react-chartjs-2"
import styled from "styled-components"
import { CardType } from "../../../table-view/store/data-models/dashboard-table-view.data-model"
import { ProgressRateTable } from "./ProgressRateTable"
import { CommonTableForCard } from "./CommonTableForCard"

const CardComponent = observer(
	({
		cardType,
		cardData,
		cardOptions,
		tableData,
		cardWidth,
		total,
	}: {
		cardType: CardType
		cardData: any
		cardOptions: any
		tableData: any
		cardWidth: number
		total: number
	}) => {
		const orgStore = useOrgStore()
		const clickHandler = () => {
			orgStore.orgDashboard.setCardType(cardType)
			orgStore.orgDashboard.setShowCardDetailView(true)
		}
		return (
			<StyledCardComponent
				cardWidth={cardWidth}
				className="simple-card FC AC"
				data-testid={"summary-card-" + cardType}
			>
				{cardType === CardType.progressRate && (
					<div className="doughnut-filling-container">
						<div className="doughnut-filling-contents">
							<div className="filling-title">Archived</div>
							<div
								className="filling-ratio"
								data-testid="archived-ratio"
							>
								{((tableData[0].total / total) * 100).toFixed(
									2
								) + "%"}
							</div>
						</div>
					</div>
				)}

				<div onClick={clickHandler} className="click-area">
					<Doughnut data={cardData} options={cardOptions} />
				</div>

				<div className="overall-total">Total Projects: {total}</div>
				<div
					data-testid={"summary-card-table-" + cardType}
					className="summary-table"
				>
					{cardType === CardType.progressRate ? (
						<ProgressRateTable
							tableData={tableData}
							overallTotal={total}
						/>
					) : (
						<CommonTableForCard
							tableData={tableData}
							overallTotal={total}
						/>
					)}
				</div>
			</StyledCardComponent>
		)
	}
)

export default CardComponent

type StyledCardProps = {
	cardWidth: number
}

const StyledCardComponent = styled.div<StyledCardProps>`
	width: ${(props) => props.cardWidth}px;
	&.simple-card {
		padding: 1rem;
		height: 22rem;
		box-shadow: 0px 2px 6px 1px rgba(192, 203, 220, 0.3);
		border: 1px solid ${(props) => props.theme.shade10};
		border-radius: 4px;
		margin-bottom: 1rem;
		overflow: hidden;
		transition: 0.4s;
		.click-area {
			cursor: pointer;
			// border: 1px solid orange;
			z-index: 1;
		}
		.overall-total {
			margin-top: 1rem;
			opacity: 0.7;
		}
		.summary-table {
			width: 100%;
		}
		:hover {
			/* box-shadow: 0px 2px 8px 1px rgba(192, 203, 220, 0.6); */
			box-shadow: 0px 2px 8px 1px ${(props) => props.theme.shade40};
			border: 1px solid ${(props) => props.theme.primaryLightDeep};
		}
		.doughnut-filling-container {
			width: ${(props) => props.cardWidth}px;
			// border: 1px solid pink;
			position: relative;
			.doughnut-filling-contents {
				position: absolute;
				width: 100%;
				/* left: ${(props) => props.cardWidth / 2}px;*/
				top: 78px;
				text-align: center;
				.filling-title {
				}
				.filling-ratio {
				}
			}
		}
		.button-container {
			width: ${(props) => props.cardWidth}px;
			border: 1px solid green;
			position: absolute;
			.view-detail-btn {
				transform: rotate(90deg);
				position: absolute;
				right: 2rem;
				top: 78px;
				:hover {
					color: ${(props) => props.theme.emphasis};
				}
			}
		}
	}
`
