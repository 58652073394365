import { flow } from "mobx-state-tree"
import axios from "axios"
import { ConsoleLog } from "../../../../../../components/basic-elements"

const ReadAssignedRoleFromGoogleSheet = (self: any) => ({
	readAssignedRoleFromGoogleSheet: flow(function* (orgRoleId) {
		// 0. set action name
		const actionName = "readAssignedRoleFromGoogleSheet"
		// ConsoleLog("get ORG permission data from remote")
		//
		const permissions: any[] = []

		// NOTE: get data from DL Permissios sheet
		const prefix = "https://sheets.googleapis.com/v4/spreadsheets/"
		const book = "1zO9KfFx1IqfucpR8lQYzOVoGfyEqqIrfwIEFMgsreDk"
		const sheet = "Org"
		const apiKey = "AIzaSyBNwUwxBeN9nr_b029ta43nReKeoM2jGn4"

		const bookUrl =
			prefix + book + "/values/" + sheet + "!B:H?key=" + apiKey

		try {
			// TODO: NOTE: Need to update
			// * get static data from the code
			const response = permissions.find(
				(element) => element.roleId === orgRoleId
			)

			// * get remote data from google sheet
			const response2 = yield axios.get(bookUrl, {
				headers: { authorization: "" }, // To remove Datalobby Token
			})
			// console.log("ReadOrgAssignedRole - response2", response2)

			const result = response2.data.values
			const header = result[0]

			result.shift() // remove header
			// console.log("ReadOrgAssignedRole - after 1 shift", result)

			let roles: any[] = []

			for (let k = 2; k < header.length; k++) {
				let rolePermissions: any[] = []
				result.map((item: any) => {
					if (
						!rolePermissions.some(
							(menu: any) => menu.menuId === item[0]
						)
					) {
						rolePermissions.push({
							menuId: item[0],
							actions: [],
						})
					}
				})
				result.map((item: any, i: number) => {
					rolePermissions.map((item1: any, j: number) => {
						if (item[0] === item1.menuId) {
							rolePermissions[j].actions.push({
								actionName: item[1],
								available: item[k] === "TRUE" ? true : false,
							})
						}
					})
				})
				roles.push({
					role: header[k],
					permissions: rolePermissions,
				})
			}
			ConsoleLog("ReadOrgAssignedRole", roles)

			const roleMatching = [
				{
					id1: "id_aa",
					id2: "AA",
				},
				{
					id1: "id_sa",
					id2: "SA",
				},
				{
					id1: "id_ga",
					id2: "GA",
				},
				{
					id1: "id_ua",
					id2: "UA",
				},
				{
					id1: "id_ex",
					id2: "EX",
				},
			]

			const targetRole = roleMatching.find(
				(item: any) => item.id1 === orgRoleId
			)?.id2
			const targetPermission = roles.find(
				(item: any) => item.role === targetRole
			).permissions
			// NOTE: re-organize (need to update)
			// TODO: is current role structure correct?
			// TODO: need to check the global permission part
			targetPermission.map((item: any) => {
				item["accessible"] = item.actions[0].available
			})

			const updatedResult = {
				...response,
				menuPermissions: targetPermission,
			}

			return targetPermission ? updatedResult : response
		} catch (error) {
			self.handleTokenError({ actionName, error, open: false })
			return false
		}
	}),
})

export default ReadAssignedRoleFromGoogleSheet
