import { types } from "mobx-state-tree"
// Model, ViewModel, UiHelper
import OrgSysSettingItemModel from "./email-notification.model"
import EmailNotificationViewModel from "./email-notification.view-model"
import * as api from "../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"
import { OrgSysSettingApiTypes } from "../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"

export const EmailNotificationStore = ({
	apiRead,
	apiUpdate,
}: OrgSysSettingApiTypes) =>
	types
		.compose(
			OrgSysSettingItemModel({ apiRead, apiUpdate }),
			EmailNotificationViewModel
		)
		.named("EmailNotificationStore")

const initialData = {
	settingId: "",
	label: "",
	value: "",
	uiValue: "",
}

export const initialStore = {
	atTheEndOfDay: initialData,
	everyOtherDay: initialData,
	atTheEndOfWeek: initialData,
	every3Days: initialData,
	daily: initialData,
	hasPBCUpload: initialData,
	modifiedDetails: {
		userName: "",
		userId: "",
		modifiedDate: "",
	},
	// viewModel common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const EmailNotificationStoreInstance = EmailNotificationStore({
	apiRead: api.read,
	apiUpdate: api.update,
}).create(initialStore)

export default EmailNotificationStoreInstance
