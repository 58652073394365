import React, { useEffect, useState } from "react"
// store related
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
// components
import { PageContainer } from "../../../../components/app-frame-elements"
import {
	DLSpinnerCenterAligned,
	ConsoleLog,
} from "../../../../components/basic-elements"
//
import ProjFileTreeController from "../proj-files-components/ProjFileTreeController"
import { Toolbar, NoFolderExist, Snackbar } from "../proj-files-components"
import {
	ActionStatus,
	DLSector,
} from "../../../../common-models/enumerations/common-enums"
import { ProjI18n } from "../../../../common-models/enumerations/translation-sheets"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"
import { FileFolderDialog } from "../../../../components/combined-elements/file-tree/file-tree-dialogs"
import OpenedFileListDialog from "../../opened-files/OpenedFilesDialog"
import CommentDetailDialogController from "../proj-files-components/dialogs/CommentDetailDialogControllerForFiles"
import CommentCloseDialog from "../../../../components/combined-elements/comment/CommentCloseDialog"
import { getPageSettings } from "../../../../library/get-page-settings"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import styled from "styled-components"
import TemplateDrawerInProject from "../../lib-in-proj/TemplateDrawerInProject"

export default observer(function Workpapers() {
	const store = useRootStore()
	const projStore = useProjStore()
	const projId = projStore.checkin.projId
	const wpStore = projStore.wp
	//
	const menuId = DLProjSubMenus.wp
	const actionName = "getFirstFolders"
	const i18nSheet = ProjI18n.files
	//
	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived

	const needRefresh = wpStore.needRefresh

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)

	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- ")
	ConsoleLog(" ----------------------- WP rendering")
	ConsoleLog([
		" --------------- projStore checkin",
		projStore.checkin.projCheckin,
	])
	ConsoleLog([" --------------- menuAccess", menuAccess])

	// get main data
	useEffect(() => {
		if (menuAccess && needRefresh) {
			// if (wpStore.flatListLength <= 0) {
			wpStore.getFirstFolders(projId)

			// NOTE: get project users if the getProjUser status is not success
			if (
				projStore.projUsers.getActionStatus("getProjUsers") !==
				ActionStatus.success
			) {
				projStore.projUsers.getProjUsers()
				projStore.roles.getRoleSet(DLSector.proj)
			}
			// NOTE: get comments if the
			if (
				projStore.comments.getActionStatus("getComments") !==
				ActionStatus.success
			) {
				projStore.comments.getComments(
					"all",
					projStore.checkin.projId,
					menuId
				)
			}
		}
	}, [menuAccess, needRefresh])

	useEffect(() => {
		// get page setting from the local store and apply it on the screen
		// (WIP)
		getPageSettings(menuId)
	}, [])

	const [i18n, setI18n] = useState<DLI18nProps | any>({})

	useEffect(() => {
		const i18n1 = store.i18n.combineI18n(
			[i18nSheet, ProjI18n.comments],
			"Workpapers"
		)

		setI18n(i18n1)
	}, [])

	const handleAddFolder = (folderName: string) => {
		let parentId = wpStore.selectedFolder

		if (parentId === "" || parentId === null) {
			parentId = "folderId0" // Root Folder
		}
		wpStore.addFileFolder({
			projId,
			parentId,
			title: folderName,
		})
	}

	const handleEditFolder = (folderName: string) => {
		wpStore.editFileFolder({
			folderId: wpStore.selectedFolder,
			folderName,
			postAction: wpStore.setFileTreeDialogOpen(
				FileScreenDialog.editFolder,
				false
			),
		})
	}

	ConsoleLog(["i18n in useState --------------- ", i18n])

	const wpActionStatus = wpStore.getActionStatus(actionName)
	return (
		<>
			{menuAccess ? (
				<StyledWP>
					<PageContainer
						pageTools={
							<Toolbar
								partialStore={wpStore}
								permission={permission}
								i18n={i18n}
							/>
						}
						fullWidth
						noPadding
						defaultToolbarStatus="expanded"
					>
						{wpActionStatus === ActionStatus.loading &&
							wpStore.flatList.length === 0 && (
								<DLSpinnerCenterAligned />
							)}
						{wpActionStatus === ActionStatus.success &&
							wpStore.itemNotExist && (
								<>
									{permission.create ? (
										<NoFolderExist
											handlerCreateFolder={() =>
												wpStore.setFileTreeDialogOpen(
													FileScreenDialog.createFolder,
													true
												)
											}
											i18n={i18n}
										/>
									) : (
										<div
											className="FR AC JC"
											style={{
												width: "100%",
												height: "100%",
											}}
										>
											{i18n.tsNoFiles || "No files"}
										</div>
									)}
								</>
							)}
						{wpActionStatus === ActionStatus.success &&
							!wpStore.itemNotExist && (
								<div className="wp-main-container FC">
									<div className="tree-list">
										<ProjFileTreeController
											partialStore={wpStore}
										/>
									</div>
								</div>
							)}

						{/* ----- Folder related ----- */}
						{/* NOTE: Do not move 'create folder dialog' to filetree controller */}
						{wpStore.fileTreeDialogOpenStatus[
							FileScreenDialog.createFolder
						] && (
							<FileFolderDialog
								actionHandler={handleAddFolder}
								type="add"
								partialStore={wpStore}
								i18n={i18n}
							/>
						)}

						{wpStore.fileTreeDialogOpenStatus[
							FileScreenDialog.editFolder
						] && (
							<FileFolderDialog
								actionHandler={handleEditFolder}
								type="edit"
								partialStore={wpStore}
								i18n={i18n}
							/>
						)}

						{/* TODO: @Noah: It seems there are two OpenedFileListDialog. Another one takes menuId and partialStore. Let's check */}
						{/* This is my old memo... but I'm not sure why I writen like this -->  TODO: below 'listDialog' should be separated per screen  */}
						{/* NOTE: Do not move 'opened files dialog' to filetree controller */}
						{projStore.openedFiles.openedFilesDialogOpen && (
							<OpenedFileListDialog menuId={menuId} />
						)}

						{wpStore.fileTreeDialogOpenStatus[
							FileScreenDialog.commentDetail
						] && (
							<CommentDetailDialogController
								partialStore={wpStore}
								i18n={i18n}
							/>
						)}

						{wpStore.commentClosingDialogOpenStatus && (
							<CommentCloseDialog
								partialStore={wpStore}
								commentId={wpStore.selectedComment}
							/>
						)}

						{/* ----- Snackbar ----- */}
						<CommonSnackbarComponent
							i18n={i18n}
							partialStore={wpStore}
						/>
						{!projStore.lib.firstCalled && (
							<TemplateDrawerInProject />
						)}
					</PageContainer>
				</StyledWP>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledWP = styled.div`
	.page-toolbar {
		height: 5rem !important;
	}
`
