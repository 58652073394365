import { flow } from "mobx-state-tree"

const DeleteFinancialGroup = (self: any, delFinGroup: any) => ({
	deleteFinancialGroup: flow(function*(payload: { id: number }) {
		const actionName = "deleteFinancialGroup"
		try {
			const response = yield delFinGroup(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default DeleteFinancialGroup
