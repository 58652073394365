import { HandleResponseProps } from "../../../../../../common-models/common-view-model-actions"
import {
	ActionStatus,
	DLCommentType,
	MessageColorType,
	PriorityType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { commentIdToNumber } from "./utils-for-comment"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const CloseComment = (self: any) => ({
	closeComment({
		inputs,
		menuId,
		postWorkForSuccess,
		handleResponse,
	}: {
		inputs: { id: string; response: string; closedBy: string }
		menuId: DLProjSubMenus
		postWorkForSuccess: () => void
		handleResponse: (props: HandleResponseProps) => void
	}) {
		// 0.
		const actionName = "closeComment"
		// 1.
		// 2.
		handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		const commentId = commentIdToNumber(inputs.id)
		// 3.
		let payload = {
			Response: inputs.response,
			ReviewCommentsId: commentId,
			ReviewStatus: "Completed", // static
			// UserId: 680,
		}

		// ConsoleLog(payload)
		// return false
		self.requestCloseComment(payload, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.reflectCommentClosing(inputs, response.data)
					postWorkForSuccess()
					// set success case response
					handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response (200 but fail)
					handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default CloseComment
