import React from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// for input area
import InputWithLabel from "../../../../../components/basic-elements/input-with-label/InputWithLabel"
import sharedRegEx from "../../../../../library/sharedRegEx"
// validate form
import useForm from "../../../../../library/use-form"
// for UI
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	DLSystemMsg,
	DLInputField,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"

import { I18nDialogFormProps } from "../../../../../common-models/types/dialog.props"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export const AddRiskAssessmentBtn = ({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	return (
		<DLButton
			variant="text"
			eleTestId="add-risk-assessment-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			clickHandler={() =>
				orgStore.setupRiskAssessment.setAddRiskAssessmentDialogOpen(
					true
				)
			}
			color="primary"
		>
			{i18n.twAdd || "Add"}
		</DLButton>
	)
}

const AddRiskAssessmentDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "addRiskAssessment"
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const addRiskAssessment = () => {
		const userAliasId = orgStore.checkin.orgInfo.userAliasId
		const raName = inputs.name.value
		orgStore.setupRiskAssessment.addRiskAssessment({ raName, userAliasId })
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addRiskAssessment
		// open // resetPoint
	)

	return (
		<DLDialog
			eleTestId="add-ra-dialog"
			isOpen={
				orgStore.setupRiskAssessment.addRiskAssessmentDialogOpen
			}
			setIsOpen={
				orgStore.setupRiskAssessment.setAddRiskAssessmentDialogOpen
			}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPlus}
						size={1}
						// color={MessageColorType.blue}
						style={{ marginRight: 8 }}
					/>
					{i18n.twAddRa || "Add Risk Assessment"}
				</div>
			}
			dialogContents={
				<AddRiskAssessmentDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				isReady &&
				!orgStore.setupRiskAssessment.isDuplicatedName(
					inputs.name.value
				)
			}
			actionBtn={i18n.twSubmit || "Submit"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.setupRiskAssessment.getActionStatus(actionName) !==
				"SUCCESS"
					? orgStore.setupRiskAssessment.responses.getResponse(
							actionName
					  )?.message
					: undefined
			}
			showSpinner={
				orgStore.setupRiskAssessment.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.setupRiskAssessment.getActionStatus(actionName) ===
				"LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const AddRiskAssessmentDialogForm = observer(({
	inputs,
	handleOnChange,
	i18n,
}: I18nDialogFormProps) => {
	const orgStore = useOrgStore()
	const actionName = "addRiskAssessment"
	return (
		<StyledAddRiskAssessmentDialog>
			<div className="input-section FR">
				<InputWithLabel
					required
					label={i18n.twRaName || "Risk Assessment Name"}
				>
					<DLInputField
						autoFocus
						eleTestId="risk-assessment-name-input"
						eleFullWidth
						eleName="name"
						eleValue={inputs.name.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={
							inputs.name.error ||
							(orgStore.setupRiskAssessment.isDuplicatedName(
								inputs.name.value
							) &&
								(i18n.tsSameNameExist ||
									"The same name already exist"))
						}
						warningType={
							orgStore.setupRiskAssessment.getActionStatus(
								actionName
							) !== "SUCCESS" &&
							orgStore.setupRiskAssessment.isDuplicatedName(
								inputs.name.value
							)
								? "red"
								: undefined
						}
						requestInput={inputs.name.requestInput}
					/>
				</InputWithLabel>
			</div>
		</StyledAddRiskAssessmentDialog>
	)
})

const StyledAddRiskAssessmentDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default AddRiskAssessmentDialog
