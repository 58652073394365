import { ConsoleLog } from "../components/basic-elements"

export const initializeOrgStores = (orgStore: any) => {
	ConsoleLog("Initialize some organization stores")

	// WARNING: CHECK THE ORG STORE PROVIDER
	// NOTE: DO NOT INITIALIZE CHECKIN STORE HERE

	orgStore.default.initializeStore()
	//
	orgStore.recent.initializeStore()
	//
	orgStore.orgProjTemplates.initializeStore()
	orgStore.orgFileLib.initializeStore()
	//
	orgStore.noti.initializeStore()
	//
	orgStore.orgDashboard.initializeStore()
	//
	orgStore.archiveMgmt.initializeStore()
	orgStore.unarchiveMgmt.initializeStore()
	//
	orgStore.groups.initializeStore()
	orgStore.projects.initializeStore()
	orgStore.trash.initializeStore()
	//
	orgStore.setupUsers.initializeStore()
	orgStore.setupGroups.initializeStore()
	orgStore.setupClients.initializeStore()
	orgStore.setupEngTypes.initializeStore()
	orgStore.setupRiskAssessment.initializeStore()
	orgStore.setupDateAndTimeSetting.initializeStore()
	orgStore.emailNotification.initializeStore()
	orgStore.orgRoles.initializeStore()
	orgStore.archPolicy.initializeStore()
	//
	orgStore.search.initializeStore()

	// update the action status
}

export const initializeHcMgmtStores = (hcMgmtStore: any) => {
	ConsoleLog("Initialize some hard copy management stores")

	// WARNING: MUST CHECK THE STORE PROVIDER
	// NOTE: DO NOT INITIALIZE CHECKIN STORE HERE

	// TODO: How to initialize this store and where??
	hcMgmtStore.hardCopy.initializeStore()
}

export const initializeProjStores = (
	projStore: any,
	forProjCheckin?: boolean
) => {
	ConsoleLog("Initialize some project stores")

	// WARNING: CHECK THE PROJECT STORE PROVIDER
	// NOTE: DO NOT INITIALIZE CHECKIN STORE HERE
	projStore.menuTabs.initializeStore()
	//
	projStore.projDashboard.initializeStore()
	//
	// NOTE: Commented below condition as we are not using anywhere else
	// if (!forProjCheckin) {
	// 	// NOTE: This line seems make error in checkin step 2.
	// 	// Need to find better way than this
	// 	// @Noah: You should check above line!
	// 	projStore.projInfo.initializeStore()
	// }
	projStore.projInfo.initializeStore()
	//
	projStore.board.initializeStore()
	projStore.notes.initializeStore()
	projStore.comments.initializeStore()
	//
	projStore.pbc.initializeStore()
	//
	projStore.leadSheet.initializeStore()
	projStore.trialBalance.initializeStore()
	projStore.fsSetup.initializeStore()
	projStore.financialStatements.initializeStore()
	projStore.eliminatingEntry.initializeStore()
	projStore.adjustments.initializeStore()
	projStore.passedAdjustments.initializeStore()
	//
	projStore.openedFiles.initializeStore()
	//
	projStore.wp.initializeStore()
	projStore.pf.initializeStore()
	projStore.lib.initializeStore()
	//
	projStore.archive.initializeStore()
	projStore.archivePreProcess.initializeStore()
	projStore.archiveHistory.initializeStore()
	projStore.rationales.initializeStore()
	projStore.signOff.initializeStore()
	//
	projStore.projUsers.initializeStore()
	projStore.roles.initializeStore()
	projStore.trash.initializeStore()
	projStore.notificationSettings.initializeStore()
	projStore.projRollForward.initializeStore()
}
