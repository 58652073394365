import { types } from "mobx-state-tree"
import ReadTranslations from "./model-actions/read-translations"
import UpdateUserLanguage from "./model-actions/update-user-language"
import { CommonModelActions } from "../../../common-models/common-model-actions"

const I18nModel = types
	.model({})
	.actions(ReadTranslations)
	.actions(UpdateUserLanguage)
	//
	.actions(CommonModelActions)

export default I18nModel
