import { types } from "mobx-state-tree"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"

// Org level access right types
// "UA" = 3
// "GA" = 2
// "SA" = 1

export const OrgGroup = types.model({
	id: types.string, // [GroupID] ex) 805
	title: types.string, // [GroupName] ex) "(0) Release 본부 update"
	createdBy: types.string, // [CreatedBy] ex) 16
	//creatorName: types.xxx, // [CreatedUser] ex) "김철수 [cskim]"
	createdAt: types.string, // [CreatedDate] ex) "2019-10-14T13:28:57.527176+00:00"
})

export const OrgCabinet = types.model({
	id: types.string, // [CabinetID] ex) 806
	title: types.string, // [CabinetName] ex) "Sprint 4 Release Note"
	createdBy: types.string, // [CreatedBy] ex) 16
	//CreatedUser: types.xxx, // [CreatedUser] ex) "김철수 [cskim]"
	createdAt: types.string, // [CreatedDate] ex) "2019-10-14T13:29:20.039497+00:00"
	//group: types.xxx, // [Group] ex) null
	//groupId: types.xxx, // [GroupID] ex) 0
	//GroupName: types.xxx, // [GroupName] ex) null
	//Type: types.xxx, // [Type] ex) null
})

const SimpleUserInfo = types.model({
	id: types.string,
	name: types.string,
})

export const OrgFlatGroupAdditionalDetail = types.model({
	aliasId: types.string, // input by user (business ID)
	projectStatus: types.string, // Normal, Replica, Arhicved, Unarchived... // TODO: It should be enum
	version: types.number,
	accessible: types.boolean,
	//
	clientId: types.string,
	clientName: types.string,
	clientAliasId: types.string,
	//
	financialYear: types.number,
	periodEndDate: types.string,
	periodName: types.string,
	//
	accessRight: types.string, // TODO: Should be enum
	assignedRole: types.string,
	//
	isLocked: types.boolean,
	//
	archived: types.boolean,
	archivedBy: SimpleUserInfo,
	archivedAt: types.union(types.string, types.null),
	createdBy: SimpleUserInfo,
	//
	expirationDate: types.union(types.string, types.null),
	//
})

export const OrgFlatGroupItem = types.model({
	id: types.string,
	title: types.string,
	parentId: types.union(types.null, types.string),
	createdBy: types.string, // TODO: need update like project detail?
	createdAt: types.string,
	expanded: types.union(types.boolean, types.undefined),
	type: types.enumeration<DLObjectFormat>(Object.values(DLObjectFormat)),
	detail: types.union(OrgFlatGroupAdditionalDetail, types.null),
	createdByName: types.union(types.undefined, types.string),
})
