import { types } from "mobx-state-tree"
// common models
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import ReadArchPolicyList from "./model-actions/read-archive-policies"
import RequestMoveArchiveCondition from "./model-actions/request-move-archive-condition"
import CreateArchivePolicy from "./model-actions/create-archive-policy"
import UpdateArchivePolicy from "./model-actions/update-archive-policy"
import DeleteArchivePolicy from "./model-actions/delete-archive-policy"
import RequestCopyArchivePolicy from "./model-actions/request-copy-archive-policy"
import RequestResetArchivePolicy from "./model-actions/request-reset-archive-policy"
import RequestMoveEngType from "./model-actions/request-move-eng-type"
import { OrgSetupArchPolicyApiProps } from "./org-archive-policies.apis"
// WARNING: TODO: NOTE: Archive Policy List MVVM is not updated yet

const OrgArchivePoliciesModel = ({
	apiRead,
	apiUpdate,
	apiDelete,
	apiCreate,
	apiCopy,
	apiMoveArchiveCondition,
	apiReset,
	apiMoveEngType,
}: OrgSetupArchPolicyApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, read = apiRead) => ReadArchPolicyList(self, read))
		.actions((self, update = apiUpdate) =>
			UpdateArchivePolicy(self, update)
		)
		.actions((self, moveArchiveCondition = apiMoveArchiveCondition) =>
			RequestMoveArchiveCondition(self, moveArchiveCondition)
		)
		.actions((self, create = apiCreate) =>
			CreateArchivePolicy(self, create)
		)
		.actions((self, del = apiDelete) => DeleteArchivePolicy(self, del))
		.actions((self, copy = apiCopy) => RequestCopyArchivePolicy(self, copy))
		.actions((self, reset = apiReset) =>
			RequestResetArchivePolicy(self, reset)
		)
		.actions((self, moveEngType = apiMoveEngType) =>
			RequestMoveEngType(self, moveEngType)
		)
		// common model actions
		.actions(CommonModelActions)

export default OrgArchivePoliciesModel
