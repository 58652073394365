import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { convertProjectStatus } from "../../../../../../library/get-project-status"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */

const GetRecentProjList = (self: any) => ({
	getRecentProjList(userId: string) {
		// 0) set actionName
		const actionName = "getRecentProjList"
		// 1) set the response cases
		// self.response.setCases(recentProjReponseCases)
		// (TBD)
		self.responses.putResponse({
			actionName,
			status: ActionStatus.loading,
		})
		// 2) request recent project list to Model
		const userNumberId = idToNumber(userId, IdType.user)
		self.readRecentProjList(userNumberId)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// Re-organize the data from the server
					const fetchedList = response.data.RecentClientsList
					let newList: any[] = []

					fetchedList.map((recentProject: any) => {
						// prepare
						const createdBy = {
							id: idToString(
								recentProject.ClientCreatedBy,
								IdType.user
							),
							aliasId: recentProject.CreatedUser.split(
								" "
							)[1].slice(1, -1),
							name: recentProject.CreatedUser.split(" ")[0],
						}
						let archivedBy = null
						if (recentProject.ArchivedBy !== 0) {
							archivedBy = {
								id: idToString(
									recentProject.ArchivedBy,
									IdType.user
								),
								aliasId: "xxx",
								name: "xxx",
							}
						}
						// main
						const reOrganizedRecentProject = {
							//
							//
							// * 1. location info: group, cabinet
							groupId: idToString(
								recentProject.GroupID,
								IdType.group
							), // system ID
							groupName: "xxx",
							cabinetId: idToString(
								recentProject.CabinetID,
								IdType.cabinet
							), // system ID
							cabinetName: "xxx",
							//
							//
							// * 2. client info
							clientId: "xxx", // system ID
							clientName: recentProject.ClientName,
							clientAliasId: "xxx",
							//
							//
							// * 3. project basic info
							id: idToString(
								recentProject.ClientID,
								IdType.project
							),
							aliasId: recentProject.ProjectBusinessId,
							title: recentProject.LinkName,
							//
							engTypeId: "xxx",
							engTypeName: "xxx",
							raId: "xxx",
							raName: "xxx",
							//
							//
							// * 4. project status info
							statusId: convertProjectStatus(
								recentProject.ProjectType
							).id, // system ID / project status: normal, replica, archived, unarchived
							statusName: convertProjectStatus(
								recentProject.ProjectType
							).name,
							periodId: idToString(
								recentProject.PeriodId,
								IdType.period
							), // system ID / period: Full year, Half year...
							periodName: recentProject.PeriodName,
							archiveDatePolicy: "xxx",
							//
							//
							replicaStatus: false,
							archiveZipStatus: false,
							unarchiveStatus: false,
							//
							//
							version: recentProject.Version,
							isArchived: recentProject.Archive,
							isLocked: recentProject.IsLocked,
							//
							//
							// * 5. access info
							roleId: "xxx", // system ID
							roleName: recentProject.RoleName,
							accessible: !recentProject.isGrayout,
							//
							//
							// * 6. schedule info
							financialYear: recentProject.Year,
							periodEndDate: recentProject.FinancialYear,
							//
							expectedReportDate: "",
							finalReportDate: "",
							//
							// archPolicyPeriodId: "", // system ID
							// archPolicyPeriodName: "",
							// archPolicyPeriodValue: 0,
							//
							expectedArchiveDate: "",
							finalArchiveDeadlineDate: "", // watch out. This is different with ArchivedAt
							//
							//
							// * 7. history info
							createdBy: createdBy,
							createdAt: recentProject.CreatedDate,
							//
							lastAccessBy: null,
							lastAccessAt: recentProject.RecentCreatedDate,
							//
							archivedBy: archivedBy,
							archivedAt: recentProject.ProjectArchivedDate
								? recentProject.ProjectArchivedDate
								: "",
							//
							unarchivedBy: null,
							unarchivedAt: "",
							//
							//
							//
							// * 8. assigned users
							epUsers: [],
							qcUsers: [],
							//
							//
							// * 9. extras
							//
							//
							// *
							hasExportPermission: false,
							//
							//
							// * ???
						}
						newList.push(reOrganizedRecentProject)
					})
					self.setRecentProjList(newList)
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					open: true,
				})
			})
	},
})

export default GetRecentProjList
