import { types } from "mobx-state-tree"

export enum OrgProjTemplateItemType {
	templateGroup = "TEMPLATE_GROUP",
	template = "TEMPLATE",
	fileFolder = "FILE_FOLDER",
	file = "FILE",
}

export const OrgProjTemplateFlatItem = types.model({
	id: types.string,
	parentId: types.union(types.null, types.string),
	title: types.string,
	roleSetId: "default_proj_role_set_id",
	type: types.enumeration<OrgProjTemplateItemType>(
		Object.values(OrgProjTemplateItemType)
	),
	expanded: types.union(types.undefined, types.boolean),
})
