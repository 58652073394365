import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../../components/basic-elements"
import { hasEmptyProps } from "@datalobby/common"
import { OrgLibraryType } from "../../data-models/org-library.data-model"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */
 
const AddOrgLibraryTemplate = (self: any) => ({
	addOrgLibraryTemplate(props: {
		mediaType: string
		name: string
		folderId: string
	}) {
		// 0.
		const actionName = "addOrgLibraryTemplate"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const { mediaType, name, folderId } = checkedProps.props
		let parentId = folderId.toString().split("libraryFolderId")[1]
		const payload = {
			MediaType: mediaType,
			ParentTempFoldID: parseInt(parentId),
			TempName: name,
		}
		self.createOrgLibraryTemplate(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const fetchedInfo = response.data
					const templateId =
						fetchedInfo.MediaType === "WorkpaperTemplate"
							? "wpTemplateId" + fetchedInfo.TempFileID.toString()
							: "apTemplateId" + fetchedInfo.TempFileID.toString()
					self.pushLibraryFolderItem({
						id: templateId,
						parentId:
							"libraryFolderId" + fetchedInfo.ParentTempFoldID,
						title: fetchedInfo.TempName,
						type:
							fetchedInfo.MediaType === "WorkpaperTemplate"
								? OrgLibraryType.wpTemplate
								: OrgLibraryType.apTemplate,
						expanded: false,
					})
					// close the dialog
					self.setOpenAddLibraryTemplate(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddOrgLibraryTemplate
