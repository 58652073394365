import { flow } from "mobx-state-tree"

const ReadLeadSheetBySubGroup = (self: any, readLeadSheets: any) => ({
	readLeadSheetBySubGroup: flow(function*(payload: {
		clientId: number
		financialSubGroupId: number
	}) {
		const actionName = "readLeadSheetBySubGroup"
		try {
			const response = yield readLeadSheets(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadLeadSheetBySubGroup
