import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

import Icon from "@mdi/react"
import { mdiCheckboxMarkedCircleOutline } from "@mdi/js"
//
import styled from "styled-components"

export default function ForgotPwSuccess({
	i18n,
}: {
	i18n: { [x: string]: string }
}) {
	const store = useRootStore()
	const { twSuccess, twSuccessRes1, twSuccessRes2 } = i18n
	return (
		<StyledForgotPwSuccess className="card-body">
			<Icon
				path={mdiCheckboxMarkedCircleOutline}
				size={5}
				color="#ffffff"
			/>
			<div className="card-title heading1">{twSuccess}</div>
			<div className="description">{twSuccessRes1}</div>
			<div className="registered-email">
				{twSuccessRes2} {store.forgotPw.email}
			</div>
		</StyledForgotPwSuccess>
	)
}

const StyledForgotPwSuccess = styled.div``
