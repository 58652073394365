import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../common-models/types/dialog.props"

const AssignRelatedActions = (self: any) => ({
	setOpenAdd(request?: boolean) {
		// initialize selected user (assign target user)
		self.setSelectedUserForAssign("")
		if (request !== undefined) {
			self.openAdd = request
		} else {
			self.openAdd = !self.openAdd
		}
	},
})

const ProjUsersUiHelper = types
	.model({
		clickPoint: ClickPoint,
		openAdd: false,
		openEdit: false,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},

		setOpenEdit(request?: boolean) {
			if (request !== undefined) {
				self.openEdit = request
			} else {
				self.openEdit = !self.openEdit
			}
		},
	}))
	.actions(AssignRelatedActions)
	.views((self) => ({}))

export default ProjUsersUiHelper
