export enum ProjScreenType {
	normal = "Normal",
	replica = "Replica",
	archived = "Archived",
	unarchived = "Unarchived",
	roamArchived = "ROAMArchived",
	amArchived = "AMArchived",
	amUnarchived = "AMUnarchived",
	recent = "Recent",
	assigned = "Assigned",
	reportAndChecklist = "ReportAndChecklist", // previous: finalReports = "FinalReports"
	otherProjects = "OtherProjects",
	forceArchived = "ForceArchived",
	amForceArchived = "AMForceArchived",
}
