import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { reOrganizeItem } from "./get-archive-policies"

const AddArchivePolicy = (self: any) => ({
	addArchivePolicy(title: string, description: string) {
		// 0.
		const actionName = "addArchivePolicy"
		// 1.
		const checkedProps = hasEmptyProps({ title })
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		self.createArchivePolicy(checkedProps.props.title, description)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					let reOrganizedConditions: {
						mandatoryList: any[]
						optionalList: any[]
					} = {
						mandatoryList: [],
						optionalList: [],
					}
					let reOrganizedFlatConditions: any[] = []
					const policyId = idToString(
						response.data.Id,
						IdType.archivePolicy
					)
					const fetchedConditions = response.data.PreConditionsList
					const flatPolicy = {
						id: policyId,
						parentId: null,
						title: response.data.Title,
						description: response.data.Description,
						expanded: false,
						type: "policy",
					}

					// 2. mandatory conditions
					fetchedConditions.MandatoryList.map((item: any) => {
						reOrganizedConditions.mandatoryList.push(
							reOrganizeItem(item)
						)
						reOrganizedFlatConditions.push(reOrganizeItem(item))
					})
					// 3. optional conditions
					fetchedConditions.NonMandatoryList.map((item: any) => {
						reOrganizedConditions.optionalList.push(
							reOrganizeItem(item)
						)
						reOrganizedFlatConditions.push(reOrganizeItem(item))
					})
					const policyDetail = {
						id: policyId,
						conditions: reOrganizedFlatConditions,
					}
					self.pushItemToFlatList(flatPolicy)
					self.pushItemToList(policyId, policyDetail)
					self.setOpenCreateDialog(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddArchivePolicy

/*

CreatedBy: 16
CreatedDate: "2020-05-11T06:39:52.28282+00:00"
CustomerId: 1
Description: "sfasdfsdfsdfdfdfsaf"
EngagementsTypesList: []
Id: 49
IsActive: 1
IsDefault: 0
IsResetAllowed: 0
LastModifiedBy: "-"
LastModifiedDate: null
Message: "Archive policy is added successfully."
ModifiedBy: 0
ModifiedDate: null
PreConditionsList: {MandatoryList: [{Id: 1010, ArchivePreConditionId: 12, ArchivePreCondition: "Opened Files",…},…],…}
Status: 1
Title: "test policy"

*/
