import React from "react"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { initialClickPoint } from "../../../../common-models/types/dialog.props"
import { DLContextMenu } from "../../../../components/basic-elements"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
	IconPlaceholder,
} from "../../../../components/basic-elements/icons/common-icons"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { Sector } from "@datalobby/types"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

export default observer(function NotificationCtxMenu({
	permission,
	partialStore,
	sector,
	menuId,
}: {
	permission: PermissionAsObjectProps
	partialStore: any
	sector: Sector
	menuId: string
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const userId = orgStore.checkin.orgUserId
	
	const handleClose = () => {
		partialStore.setClickPoint(initialClickPoint)
	}

	const handleDelete = () => {
		let proceed = window.confirm("Do you really want to delete this post?")
		const postAction = () => {
			// remove s3 objects... 이거 lambda 에서 하는 게 나은가??
		}
		if (!proceed) {
			return
		} else {
			if (sector === Sector.org) {
				store.notis.removeOrRestorePost({
					postId: partialStore.selectedItem,
					menuId,
					toDelete: true,
					by: userId,
					postAction,
				})
			} else {
				partialStore.removeOrRestorePost({
					postId: partialStore.selectedItem,
					menuId,
					toDelete: true,
					by: userId,
				})
			}
		}
	}

	const notiId = partialStore.selectedItem
	return (
		<DLContextMenu
			eleId="proj-templates-folder-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={orgNotiCtxMenuOptions({
				notiId,
				handleDelete,
				permission,
				sector,
			})}
		/>
	)
})

const orgNotiCtxMenuOptions = ({
	notiId,
	handleDelete,
	permission,
	sector,
}: {
	notiId: string
	handleDelete: () => void
	permission: PermissionAsObjectProps
	sector: Sector
}) => {
	const mainLink =
		sector === Sector.org
			? "/organization/notifications"
			: "/note-and-comments/board" // `/project/${projNumId}/note-and-comments/board`
	// WARNING: @Noah
	// NOTE: is this context menu used in the project side also?
	return [
		{
			label: "Open",
			value: "ctx-noti-open",
			available: permission.read,
			icon: <IconPlaceholder />,
			isLink: true,
			linkTo: mainLink + "/detail/" + notiId,
		},
		{
			label: "Edit",
			value: "ctx-noti-edit",
			available: permission.update,
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
			isLink: true,
			linkTo: mainLink + "/edit/" + notiId,
		},
		{
			label: "Delete",
			value: "ctx-noti-delete",
			available: permission.delete,
			clickEvent: () => handleDelete(),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]
}
