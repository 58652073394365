import React from "react"
import { types, applySnapshot } from "mobx-state-tree"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
	Refresh,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./board.provider"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
// import { OrgNoti } from "../../../../lobby-side/right-drawer/store/data-models/notifications.data-model"
import GetProjBoardList from "../../../../../service-modules/post/store-view-model-actions/get-proj-board-list"
import { getTzDateAndTime2 } from "../../../../../library/converters/date-utc-converter"
import { sortBy } from "@datalobby/common"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { PostType } from "@datalobby/types"
import RemoveOrRestorePost from "../../../../../service-modules/post/store-view-model-actions/remove-or-restore-post"
import EditPost from "../../../../../service-modules/post/store-view-model-actions/edit-post"
import AddPost from "../../../../../service-modules/post/store-view-model-actions/add-post"
import { MST_Post } from "../../../../lobby-side/right-drawer/store/data-models/notifications.data-model"

const BoardViewModel = types
	.model({
		// projBoardList: types.array(OrgNoti),
		projBoardList: types.array(MST_Post),

		//
		clickPoint: ClickPoint,
		selectedItem: "",
		s3FilesStatus: types.array(types.string),
		// ---------- common models
		needRefresh: true,
		actionStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setSelectedItem(id: string) {
			self.selectedItem = id
		},
		setProjBoardList(list: any) {
			self.projBoardList = list
		},
		pushProjBoardItem(orgNoti: any) {
			if (
				self.projBoardList.findIndex(
					(item) => item.id === orgNoti.id
				) === -1
			) {
				self.projBoardList.push(orgNoti)
			}
		},
		sliceProjBoardItem(postId: string) {
			const targetIndex = self.projBoardList.findIndex(
				(item) => item.id === postId
			)
			self.projBoardList.splice(targetIndex, 1)
		},
		updateProjBoardItem(post: PostType) {
			const target = self.projBoardList.find(
				(item) => item.id === post.id
			)
			if (target) {
				/**
				 * NOTE:
				 * - Must use splice: if update the target directly, table cannot catch the update
				 * - Must use hard copy: and if do not use hardCopy, shallowCopy occurs an error of cache
				 */
				const targetCopy = JSON.parse(JSON.stringify(target))
				const updatedPost = {
					...targetCopy,
					title: post.title,
					content: post.content,
					priority: post.priority,
					fixToTop: post.fixToTop,
					attachments: post.attachments, //post.attachments.slice(),
					// whoCanRead: post.whoCanRead,
					// allowedTargets: post.allowedTargets.slice(),
					modifiedBy: post.modifiedBy,
					modifiedAt: post.modifiedAt,
				}

				const targetIndex = self.projBoardList.findIndex(
					(item) => item.id === post.id
				)
				self.projBoardList.splice(targetIndex, 1)
				self.projBoardList.push(updatedPost)
			} else {
				console.log("Cannot find the target post to update")
			}
		},
		resetS3FilesStatus() {
			self.s3FilesStatus.length = 0
		},
		getProjBoardById(boardId: string) {
			const target = self.projBoardList.find(
				(item) => item.id === boardId
			)
			return target
		},
	}))
	.actions(GetProjBoardList)
	.actions(AddPost)
	.actions(RemoveOrRestorePost)
	.actions(EditPost)
	.views((self) => ({
		viewProjBoardList(dntFormat: DnTFormatProps, userList?: any) {
			const copy = self.projBoardList.slice().map((item) => {
				function findUserInfo(id: string) {
					const user = userList.find((item: any) => item.id === id)
					return user
				}
				let createdBy = item.createdBy
				let modifiedBy = item.modifiedBy
				if (userList?.length > 0) {
					createdBy = item.createdBy
						? findUserInfo(item.createdBy)?.name +
						  "[" +
						  findUserInfo(item.createdBy)?.aliasId +
						  "]"
						: "?"
					modifiedBy = item.modifiedBy
						? findUserInfo(item.modifiedBy)?.name +
						  "[" +
						  findUserInfo(item.modifiedBy)?.aliasId +
						  "]"
						: "-"
				}

				const createdAt = item.createdAt
					? getTzDateAndTime2({
							date: item.createdAt,
							dntFormat,
					  })
					: "?"
				const modifiedAt = item.modifiedAt
					? getTzDateAndTime2({
							date: item.modifiedAt,
							dntFormat,
					  })
					: "-"

				return {
					...item,
					createdBy: createdBy,
					createdAt: createdAt,
					modifiedBy: modifiedBy,
					modifiedAt: modifiedAt,
				}
			})
			return sortBy(copy, "fixToTop", false)
		},
		viewProjBoardItemById(
			boardId: string,
			dntFormat: DnTFormatProps,
			userList?: any
		) {
			let target: any = self.projBoardList.find(
				(item) => item.id === boardId
			)
			function findUserInfo(id: string) {
				const user = userList.find((item: any) => item.id === id)
				return user
			}
			let createdBy = target?.createdBy || ""
			let modifiedBy = target?.modifiedBy || ""
			if (userList?.length > 0) {
				if (createdBy !== "") {
					createdBy = target?.createdBy
						? findUserInfo(target?.createdBy)?.name +
						  "[" +
						  findUserInfo(target?.createdBy)?.aliasId +
						  "]"
						: "?"
				}
				if (modifiedBy !== "") {
					modifiedBy = target?.modifiedBy
						? findUserInfo(target?.modifiedBy)?.name +
						  "[" +
						  findUserInfo(target?.modifiedBy)?.aliasId +
						  "]"
						: "?"
				}
			}

			const createdAt = target?.createdAt
				? getTzDateAndTime2({
						date: target?.createdAt,
						dntFormat,
				  })
				: "?"
			const modifiedAt = target?.modifiedAt
				? getTzDateAndTime2({
						date: target?.modifiedAt,
						dntFormat,
				  })
				: "-"

			if (target && userList && dntFormat) {
				target = {
					...target,
					createdBy,
					modifiedBy,
					createdAt,
					modifiedAt,
				}
			} else {
				// target = null
			}

			return target
		},
	}))
	// common parts
	.actions(Refresh)
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default BoardViewModel
