import React, { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
// stores
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
// components
import DLFixedTabs from "./DLFixedTabs"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import { ConsoleLog } from "../../basic-elements"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export type SubMenuInfoProps = {
	id: string
	title: string
	url: string
}

const projectMenuIds = [
	{ path: "normal", menuId: DLOrgSubMenus.normal_projs },
	{ path: "replica", menuId: DLOrgSubMenus.replicas },
	{ path: "archived", menuId: DLOrgSubMenus.archived },
	{ path: "unarchived", menuId: DLOrgSubMenus.unarchived },
]

const assignedProjectMenuIds = [
	{ path: "normal", menuId: DLOrgSubMenus.assignedNormal },
	{ path: "replica", menuId: DLOrgSubMenus.assignedReplica },
	{ path: "archived", menuId: DLOrgSubMenus.assignedArchived },
	{ path: "unarchived", menuId: DLOrgSubMenus.assignedUnarchived },
]

const archMgmtMenuIds = [
	{ path: "archived", menuId: DLOrgSubMenus.archived_mgmt },
	{ path: "unarchived", menuId: DLOrgSubMenus.unarchived_mgmt },
]

export default observer(function DLFixedTabsController() {
	ConsoleLog("DLFixedTabs Controller", "")

	const store = useRootStore()
	const orgStore = useOrgStore()
	const i18nSheet = OrgI18n.menus

	let pathname = useLocation().pathname
	// console.log("@220902 pathname", pathname)
	const urlSections = pathname.split("/")

	const accessibleFlatMenus = orgStore.checkin.accessibleFlatMenus
	// console.log("@220902 accessibleFlatMenus", accessibleFlatMenus)
	// console.log("@220902 urlSections:", urlSections)

	const lastPath = urlSections[urlSections.length - 1]

	let currentMenu: any = undefined
	if (!["normal", "replica", "archived", "unarchived"].includes(lastPath)) {
		currentMenu = accessibleFlatMenus.find(
			(element) => element.url === lastPath //pathname
		)
	} else {
		// when the last path is 'archived' or 'unarchived'
		const secondLastPath = urlSections[urlSections.length - 2]
		if (secondLastPath === "archive-management") {
			currentMenu = accessibleFlatMenus.find(
				(element) =>
					element.id ===
					archMgmtMenuIds.find((item: any) => item.path === lastPath)
						?.menuId
			)
		}
		if (secondLastPath === "projects") {
			currentMenu = accessibleFlatMenus.find(
				(element) =>
					element.id ===
					projectMenuIds.find((item: any) => item.path === lastPath)
						?.menuId
			)
		}
		if (secondLastPath === "assigned-projects") {
			currentMenu = accessibleFlatMenus.find(
				(element) =>
					element.id ===
					assignedProjectMenuIds.find(
						(item: any) => item.path === lastPath
					)?.menuId
			)
		}
	}

	// console.log("@220902 in the fixed tab-currentMenu:", currentMenu)

	const currentMenuGroup = currentMenu?.menuGroup
		? currentMenu.menuGroup
		: currentMenu?.id

	const menuGroupInfo = accessibleFlatMenus.find(
		(element) => element.id === currentMenuGroup
	)
	// console.log("@220902 menuGroupInfo", menuGroupInfo)

	const [menuTabs, setMenuTabs] = useState<SubMenuInfoProps[]>([])
	const [selectedTab, setSelectedTab] = useState(0)

	// a11y means Accessibility
	function a11yProps(menuId: string) {
		return {
			id: `sub-menu-${menuId}`,
			"data-testid": `sub-menu-${menuId}`,
			"aria-controls": `scrollable-auto-tabpanel-${menuId}`,
		}
	}

	// NOTE: tab indicator control (This handleChagne is not related with panel change)
	// In the app frame for menus, there are no tab-panel
	// Each menu rendered by router
	const handleChange = (event: React.ChangeEvent<{}>, newValue: number) => {
		setSelectedTab(newValue)
	}

	useEffect(() => {
		// 1) check current Menu
		ConsoleLog("currentMenuGroup", currentMenuGroup)

		// 1-1) if the current Menu is menuGroup
		if (currentMenu?.menuGroup === null || currentMenu?.menuGroup === "") {
			let newMenuTabs: SubMenuInfoProps[] = []

			accessibleFlatMenus.map((menu) => {
				if (menu.menuGroup === currentMenu.id) {
					newMenuTabs.push({
						id: menu.id,
						title: menu.title,
						url: menu.url,
					})
				}
			})

			setMenuTabs(newMenuTabs)
			setSelectedTab(0)
		} else {
			// 1-2) if the current Menu is a menu

			let newMenuTabs: SubMenuInfoProps[] = []
			accessibleFlatMenus.map((menu) => {
				if (menu.menuGroup === currentMenuGroup) {
					newMenuTabs.push({
						id: menu.id,
						title: menu.title,
						url: menu.url,
					})
				}
			})
			setMenuTabs(newMenuTabs)
			const currentMenuIndex = newMenuTabs.findIndex(
				(element) => element.id === currentMenu?.id
			)
			setSelectedTab(currentMenuIndex)
		}
	}, [currentMenuGroup, currentMenu])

	const i18n = store.i18n.combineI18n(i18nSheet, "DLFixedTabsController")
	if (currentMenu === undefined) {
		return <div>Cannot render the tab. Please contact to admin.</div>
	} else {
		return (
			<DLFixedTabs
				menuGroupUrlEdge={menuGroupInfo?.url}
				drawerWidth={store.ui.drawerWidth}
				collapsedDrawerWidth={store.ui.collapsedDrawerWidth}
				sidebarOpen={store.ui.isSidebarOpen}
				selectedTab={selectedTab}
				handleChange={handleChange}
				menuTabs={menuTabs}
				a11yProps={a11yProps}
				i18n={i18n}
			/>
		)
	}
})
