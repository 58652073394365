import React from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLDialogHeader,
	DLButton,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { mdiTrashCan } from "@mdi/js"
import Icon from "@mdi/react"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

const RemoveGroupDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	ConsoleLog(" -=-=-=-=- RemoveGroupDialog -=-=-=-=- ")
	const orgStore = useOrgStore()

	const actionName = "removeGroup"

	const deleteGroup = () => {
		orgStore.setupGroups.removeGroup(orgStore.setupGroups.selectedGroup)
		orgStore.setupGroups.setRemoveGroupDialogOpen(false)
	}
	const groupName = orgStore.setupGroups.getGroupInfoById(
		orgStore.setupGroups.selectedGroup
	)?.groupName

	return (
		<DLDialog
			eleTestId="remove-group-dialog"
			isOpen={orgStore.setupGroups.removeGroupDialogOpen}
			setIsOpen={orgStore.setupGroups.setRemoveGroupDialogOpen}
			showOpenBtn={false}
			showCloseBtn
			dialogTitle={
				<DLDialogHeader
					icon={
						<DLIcon
							name={DLIconName.delete}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
					dialogName={i18n.twRemoveGroup}
					targetName={groupName ? groupName : "(Unknown group)"}
				/>
			}
			dialogContents={i18n.twRemoveMsg}
			actionReady={true}
			// handleAction={deleteGroup}
			// actionBtn={i18n.twDelete}
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="delete-group-btn-on-dialog"
					startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={deleteGroup}
					color="warning"
				>
					{i18n.twDelete}
				</DLButton>
			}
			cancelBtnText={i18n.twCancel}
			cannotUpdate={
				orgStore.setupGroups.getActionStatus(actionName) ===
				"LOADING"
			}
		/>
	)
})

export default RemoveGroupDialog
