import React from "react"
import styled from "styled-components"
import { DLIconButton } from "../../../basic-elements"
import { DLTooltip } from "@datalobby/components"
import Icon from "@mdi/react"
import { mdiClipboardAccount } from "@mdi/js"
import { Partition } from "."
import { observer } from "mobx-react-lite"
import { FileColumn } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { Link } from "react-router-dom"
import { DLSrcFormat } from "../../../../common-models/enumerations/common-enums"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"

const FileInfo = observer(
	({
		fileInfo,
		//
		aliasIdWidth,
		formatWidth,
		titleWidth,
		sizeWidth,
		signOffLockWidth,
		//
		columnDisplayStatus,
		handleOpenSignOffLockDialog,
		hoveredColumn,
	}: {
		fileInfo: any
		//
		aliasIdWidth: number
		formatWidth: number
		titleWidth: number
		sizeWidth: number
		signOffLockWidth: number
		//
		columnDisplayStatus: { [x: string]: boolean }
		handleOpenSignOffLockDialog: (fileId: string) => void
		hoveredColumn: string
	}) => {
		const userName = localStorage.getItem("orgUserName")
		const aliasId = fileInfo?.aliasId
		const title = fileInfo?.title
		console.log("FileInfo ----------------", fileInfo)
		const projId = localStorage.getItem("latestProjId")
		const projNumId = idToNumber(projId || "", IdType.project)

		return (
			<StyledFileInfo className="file-row-info FR AC">
				{aliasId && columnDisplayStatus[FileColumn.fileAliasId] && (
					<div
						className={`FR AC ref-num ${
							hoveredColumn === "file_refNo" && "highlight"
						}`}
						style={{
							width: aliasIdWidth,
							overflow: "hidden",
						}}
					>
						<DLTooltip title={"Ref.Num: " + aliasId}>
							<div
								className="info-section-prop alias-prop"
								data-testid={"fileRow-aliasId-of-" + aliasId}
							>
								{aliasId}
							</div>
						</DLTooltip>
					</div>
				)}

				<Partition />

				<div
					className="FR AC normal-title-area"
					data-testid={"fileRow-name-of-" + aliasId}
				>
					{columnDisplayStatus[FileColumn.format] && (
						<div
							className={`file-format info-section-prop ${
								hoveredColumn === "file_format" && "highlight"
							}`}
							data-testid={"fileRow-ext-of-" + aliasId}
							style={{
								width: formatWidth,
								overflow: "hidden",
							}}
						>
							{fileInfo?.extension}
						</div>
					)}
					<Partition />
					{columnDisplayStatus[FileColumn.size] && (
						<div
							className={`file-size info-section-prop ${
								hoveredColumn === "file_size" && "highlight"
							}`}
							data-testid={"fileRow-size-of-" + aliasId}
							style={{
								width: sizeWidth,
								overflow: "hidden",
							}}
						>
							{fileInfo.size}
						</div>
					)}
					{columnDisplayStatus[FileColumn.title] && (
						<>
							{fileInfo.srcFormat ===
							DLSrcFormat.finalAnalysis ? (
								<Link
									to={`/project/${projNumId}/files/workpaper-files/${fileInfo.id}`}
									className="FR JSB"
								>
									<div
										className={`file-title FR AC ${
											hoveredColumn === "file_title" &&
											"highlight"
										}`}
										style={{
											width: titleWidth,
										}}
									>
										{title}
									</div>
								</Link>
							) : (
								<div
									className={`file-title FR AC " +
											${fileInfo?.isOpen && "opened-file"}`}
									style={{
										width: titleWidth,
										overflow: "hidden",
									}}
								>
									{fileInfo?.isOpen && (
										<DLIconButton
											eleTestId={
												"fileRow-openedBy-of-" + aliasId
											}
											eleClassName="opened-by-icon"
											tooltipPlacement="top"
											tooltipText={`Checked out by ${fileInfo.openedByName}`}
											color={
												fileInfo.openedByName ===
												userName
													? "primary"
													: "default"
											}
										>
											<Icon
												path={mdiClipboardAccount}
												size={0.8}
											/>
										</DLIconButton>
									)}
									<DLTooltip title={title}>
										<div
											className={`info-section-prop ${
												hoveredColumn ===
													"file_title" && "highlight"
											}`}
										>
											{title}
										</div>
									</DLTooltip>
								</div>
							)}
						</>
					)}
					<Partition />
				</div>
			</StyledFileInfo>
		)
	}
)

const StyledFileInfo = styled.div`
	.info-section-prop {
		/* padding: 0 8px; --> this line impact on all the cells in the file row */
		overflow: hidden;
		font-size: ${(props) => props.theme.shared.textRg};
		text-wrap: wrap;
	}
	.ref-num-edit-mode {
		transition: 0.4s;
	}
	.highlight {
		background-color: ${(props) => props.theme.shade10};
	}
	.ref-num {
		/* border: 1px solid brown; */
		.alias-prop {
			text-wrap: wrap;
			overflow: hidden;
			width: 100%;
		}
		.ref-num-edit-btn {
			opacity: 0;
			transition: 0.4s;
		}
		&:hover {
			.ref-num-edit-btn {
				opacity: 1;
			}
		}
	}
	.file-format {
		border: 1px solid grren;
	}
	.normal-title-area {
		.opened-by-icon {
			margin-left: -8px;
		}
		.file-title {
			/* border: 1px solid pink; */
			padding: 0 8px;
			overflow: hidden;
			white-space: normal;
			&.opened-file {
				padding: 0;
				display: flex;
				align-items: center;
			}
		}
		.file-size {
			margin-left: 0.6rem;
			opacity: 0.4;
		}
		.file-name-edit-btn {
			opacity: 0;
			transition: 0.4s;
		}
		&:hover {
			.file-name-edit-btn {
				opacity: 1;
			}
		}
	}
`

export default FileInfo
