import React from "react"
import { Route } from "react-router-dom"
import OrgSetupGroupsController from "./org-setup-groups/OrgSetupGroupsController"
import OrgSetupUserDetail from "./org-setup-users/OrgSetupUserDetail"
import OrgSetupClientsController from "./org-setup-clients/OrgSetupClientsController"
import OrgSetupArchivePoliciesController from "./org-archive-policies/OrgSetupArchivePoliciesController"
import OrgSetupOrgRoles from "./org-setup-org-roles/OrgSetupOrgRoles"
import OrgSetupSysSettings from "./org-setup-system-settings/OrgSetupSysSettings"
import OrgSetupRiskAssessmentController from "./org-setup-risk-assessment/OrgSetupRiskAssessmentController"
// import OrgSetupUsers from "./org-setup-users/OrgSetupUsers"
import { OrgSetupEngTypesController, OrgSetupUsersController } from ".."
import OrgSetupChecklistController from "./org-setup-checklist/OrgSetupChecklistController"
import OrgSetupChecklistDetailController from "./org-setup-checklist/OrgSetupChecklistDetailController"
import OrgSetupNotificationsController from "./org-setup-notifications/OrgSetupNotificationsController"

export default function OrganizationSetup() {
	return (
		<>
			<Route
				exact
				path="/organization/organization-setup"
				component={OrgSetupUsersController}
			/>

			<Route
				exact
				path="/organization/organization-setup/organization-user-list"
				component={OrgSetupUsersController}
			/>

			<Route
				exact
				path="/organization/organization-setup/organization-user-list/detail/:id"
				component={OrgSetupUserDetail}
			/>

			<Route
				exact
				path="/organization/organization-setup/setup-groups"
				component={OrgSetupGroupsController}
			/>
			<Route
				exact
				path="/organization/organization-setup/clients"
				component={OrgSetupClientsController}
			/>
			<Route
				exact
				path="/organization/organization-setup/checklist"
				component={OrgSetupChecklistController}
			/>
			<Route
				exact
				path="/organization/organization-setup/checklist/:id"
				component={OrgSetupChecklistDetailController}
			/>
			<Route
				exact
				path="/organization/organization-setup/engagement-type"
				component={OrgSetupEngTypesController}
			/>
			<Route
				exact
				path="/organization/organization-setup/risk-assessment"
				component={OrgSetupRiskAssessmentController}
			/>
			<Route
				exact
				path="/organization/organization-setup/archive-policies"
				component={OrgSetupArchivePoliciesController}
			/>
			<Route
				path="/organization/organization-setup/role-setup"
				component={OrgSetupOrgRoles}
			/>
			<Route
				exact
				path="/organization/organization-setup/system"
				component={OrgSetupSysSettings}
			/>
			<Route
				exact
				path="/organization/organization-setup/notifications"
				component={OrgSetupNotificationsController}
			/>
		</>
	)
}
