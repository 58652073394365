import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../library/api-requests"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export type AssignRoleApiProps = {
	payload: {
		FileID: number
		RoleID: number
		Type: number
	}
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function assignRole(props: AssignRoleApiProps) {
	const { payload, menuId } = props

	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken

	if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/AssignRole_Templates"
		tokenType = TOKEN_TYPE.orgToken
	} else if (menuId === DLProjSubMenus.wp) {
		endpoint = "/AssignRole_WorkPaperFiles"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/AssignRole_PermanentFile"
	}
	// unassign also uses same endpoint
	// 'Type' 1 = assign / 2 = unassign
	const response = await postRequest({
		url: endpoint,
		params: [payload], // this api requires array but unable to take multiple values
		tokenType,
	})

	return response
}
