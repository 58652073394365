import React from "react"
import styled from "styled-components"

export default function WithoutTemplate() {
	return (
		<StyledWithoutTemplate className="FR AC JC">
			{/* <div className="heading3 FR AC">Create a empty project</div> */}
		</StyledWithoutTemplate>
	)
}

const StyledWithoutTemplate = styled.div`
	width: 100%;
	height: 100%;
	padding: 1rem;
	/* border: 1px solid ${(props) => props.theme.shade40}; */
	/* border-radius: 10px; */
	color: ${(props) => props.theme.shade40};
`
