import { ChecklistRecordType } from "@datalobby/types"
import { AxiosResponse } from "axios"
import { getRequest, postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { AddChecklistProps } from "./data-models/checklist-types"

export type ReadAllFilesProps = {
	projectId: number
	menu: "wp" | "pf"
}

export async function readAllFiles(params: ReadAllFilesProps) {
	const response = await getRequest({
		url: "/GetWorkpaperList",
		params,
		tokenType: TOKEN_TYPE.latestProjToken,
	})

	return response
}

export type ReadChecklistsProps = {
	orgId: string
	id?: string
}
export async function readChecklists({ orgId, id }: ReadChecklistsProps) {
	let url = `/checklists`
	const response = await getRequest({
		tokenType: TOKEN_TYPE.cdkBackend,
		url,
		params: {
			orgId,
			type: ChecklistRecordType.list,
			...(id && { id }),
		},
	})

	return response
}

export async function readChecklistCollections(orgId: string) {
	let url = `checklists?orgId=${orgId}&type=${ChecklistRecordType.connection}`

	const response = await getRequest({
		tokenType: TOKEN_TYPE.cdkBackend,
		url,
	})

	return response
}

export async function addChecklist(payload: AddChecklistProps) {
	let url = `checklists/copy`
	const response = await postRequest({
		url,
		tokenType: TOKEN_TYPE.cdkBackend,
		payload,
	})

	return response
}

export type ReportsMgmtApiProps = {
	apiRead: (params: {
		FinancialYear: number
		PageIndex: number
		PageSize: number
		ProjectType: number
		PTMAssignedOnly: boolean
	}) => Promise<AxiosResponse<any>> // this is for online projects
	apiReadAllFiles: (params: ReadAllFilesProps) => Promise<AxiosResponse<any>>
	apiReadChecklists: (
		params: ReadChecklistsProps
	) => Promise<AxiosResponse<any>>
	apiAddChecklist: (params: AddChecklistProps) => Promise<AxiosResponse<any>>
	apiReadChecklistCollections: (orgId: string) => Promise<AxiosResponse<any>>
}
