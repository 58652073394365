import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { SetGlobalDnTType } from "../../../../../../../stores/root-store/global-store/global-store.view-model"

function convertTF(timeFormat: string) {
	if (timeFormat === "h:mm a") {
		return "h:mm aa"
	} else if (timeFormat === "hh:mm a") {
		return "hh:mm aa"
	} else {
		return timeFormat
	}
}

// for API
export function restoreTF(timeFormat: string) {
	if (timeFormat === "h:mm aa") {
		return "h:mm a"
	} else if (timeFormat === "hh:mm aa") {
		return "hh:mm a"
	} else {
		return timeFormat
	}
}

const GetDateAndTimeSetting = (self: any) => ({
	getDntSetting(setGlobalDnT?: (props: SetGlobalDnTType) => void) {
		// 0.
		const actionName = "getDateAndTimeSetting"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API call
		self.readDateAndTimeSetting()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					const fetchedSettings = response.data.configurationlist
					const modifiedDetails = response.data.modifiedDetails
					// --------- get current setting and divide each list
					//
					//
					//
					//
					// date format = DF
					// time format = TF
					// time zone = TZ
					const DFLabel = "Date Format"
					const TFLabel = "Time Format"
					const TZLabel = "Time Zone"
					//
					//
					//
					//
					// A __ Date Format
					const fetchedDF = fetchedSettings.find(
						(element: any) => element.ParameterName === DFLabel
					)
					const dateFormat = {
						settingId: fetchedDF.SettingID.toString(),
						label: DFLabel,
						value: fetchedDF.ParameterValue,
						uiValue: fetchedDF.ParameterValueUI,
					}

					// B __ Time format
					const fetchedTF = fetchedSettings.find(
						(element: any) => element.ParameterName === TFLabel
					)
					const timeFormat = {
						settingId: fetchedTF.SettingID.toString(),
						label: TFLabel,
						value: fetchedTF.ParameterValue,
						uiValue: convertTF(fetchedTF.ParameterValueUI), // NOTE: date-fnt use 'aa' instead ot 'a'
					}

					// C __ Time Zone
					const fetchedTZ = fetchedSettings.find(
						(element: any) => element.ParameterName === TZLabel
					)
					const timeZone = {
						settingId: fetchedTZ.SettingID.toString(),
						label: TZLabel,
						value: fetchedTZ.ParameterValue,
						uiValue: fetchedTZ.ParameterValueUI,
					}

					// update the stores
					// NOTE: update is required: this one should be mandatory
					setGlobalDnT &&
						setGlobalDnT({
							dateFormat,
							timeFormat,
							timeZone,
						})
					//
					//
					//

					// WARNING: TODO: NOTE:
					// --- SET AVAILABLE LIST FOR SELECT ---
					// --------- initialize the previous list
					self.resetDateFormatList()
					self.resetTimeFormatList()
					self.resetTimeZoneList()
					//
					// --------- re-organize the list
					// a2) date format list
					fetchedDF.AvailableSettings.map((format: any) => {
						const reOrganizedDateFormat = {
							settingId: "",
							label: format.ParameterDisplay,
							value: format.ParameterValue,
							uiValue: format.ParameterValueUI,
						}
						self.pushItemToDateFormatList(reOrganizedDateFormat)
					})
					// b2) time format list
					fetchedTF.AvailableSettings.map((format: any) => {
						const reOrganizedTimeFormat = {
							settingId: "",
							label: format.ParameterDisplay,
							value: format.ParameterValue,
							uiValue: convertTF(format.ParameterValueUI), // NOTE: date-fnt use 'aa' instead ot 'a'
						}
						self.pushItemToTimeFormatList(reOrganizedTimeFormat)
					})
					// c2) time zone list
					fetchedTZ.AvailableSettings.map((timeZone: any) => {
						const reOrganizedTimeZone = {
							settingId: "",
							label: timeZone.ParameterDisplay,
							value: timeZone.ParameterValue,
							uiValue: timeZone.ParameterValueUI,
						}
						self.pushItemToTimeZoneList(reOrganizedTimeZone)
					})

					const details = {
						userName: modifiedDetails.ModifiedUser || "",
						userId: modifiedDetails.UserName || "",
						modifiedDate: modifiedDetails.ModifiedDate || "",
					}

					self.setModifiedDetails(details)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get Date & Time setting",
						open: true,
						autoHide: true,
						autoHideDuration: 1500,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage: "Fail to " + actionName,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetDateAndTimeSetting
