import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import {
	DLBasicToggleButton,
	DLRadioGroup,
} from "../../../../components/basic-elements"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLCheckbox } from "@datalobby/components/lib"

export default observer(function OrgSetupNotifications({
	permission,
	contentHeight,
}: {
	permission: PermissionAsObjectProps
	contentHeight: any
}) {
	const orgStore = useOrgStore()
	const [notification, setNotification] = useState({ isActive: false })

	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
			isLoading={false}
		>
			<StyledSetupRiskAssessment>
				<div className="FR header">
					<span>Turn On/Off the Notifications </span>
					<hr />
				</div>
				<div className="section">
					<div className="FR item">
						<DLBasicToggleButton
							on={notification.isActive}
							setOn={() => {}}
							tooltipText={
								!notification.isActive ? "Turn ON" : "Turn OFF"
							}
							// 	disabled={isLocked}
							eleTestId={`toogle-${notification.isActive}`}
						/>
						Users can set the notifications in their individual
						projects by themself
					</div>
					<div className="FR item">
						<DLBasicToggleButton
							on={notification.isActive}
							setOn={() => {}}
							tooltipText={
								!notification.isActive ? "Turn ON" : "Turn OFF"
							}
							// 	disabled={isLocked}
							eleTestId={`toogle-${notification.isActive}`}
						/>
						Use below email summary notification service
					</div>
				</div>
				<div className="FR header">
					<span>Notification Configuration</span>
					<hr />
					<span>Edit</span>
				</div>
				<div className="section">
					<div className="FR item">
						<div className="label">Iterate on</div>
						<DLRadioGroup
							eleTestId="iterate on"
							items={[
								{ value: "dialy", label: "Daily" },
								{ value: "weekly", label: "Weekly" },
								{ value: "monthly", label: "Monthly" },
							]}
							selectedValue="dialy"
							color="primary"
							size="small"
							itemDirection="row"
							eleOnChange={(event: any) => {}}
							groupName="iterate"
							// disabled={!notification.isActive}
						/>
					</div>
					<div className="FR item">
						<div className="label">Send to</div>
						<DLRadioGroup
							eleTestId="iterate on"
							items={[
								{
									value: "dialy",
									label: "All assigned active project users",
								},
								{
									value: "weekly",
									label: "Assigned active EPs",
								},
								{
									value: "monthly",
									label: "Assigned active Project Admins",
								},
							]}
							selectedValue="dialy"
							color="primary"
							size="small"
							itemDirection="row"
							eleOnChange={(event: any) => {}}
							groupName="feature"
							// disabled={!notification.isActive}
						/>
					</div>
					<div className="FR item">
						<div className="label">Engagement Type</div>
						<div className="eng-type-section">
							{orgStore.setupEngTypes.engTypeOptions.map(
								(item: any) => {
									return (
										<div
											className="FR eng-item"
											key={item.id}
										>
											<DLCheckbox
												eleTestId={`checkbox-${item.id}`}
												clickHandler={() => {}}
												color="primary"
												isChecked={false}
											/>
											<span>{item.title}</span>
										</div>
									)
								}
							)}
						</div>
					</div>
				</div>
				<div className="FR header">
					<span>Sent History</span>
					<hr />
				</div>
				<div className="section">
					<span>Next email will be sent on </span>
					<ReactTableV8
						tableColumns={[
							{
								header: "Date and Time",
								accessorKey: "Dnt",
							},
							{
								header: "Sent to",
								accessorKey: "sentTo",
							},
							{
								header: "Destination Count",
								accessorKey: "destinationCount",
							},
							{
								header: "Issue Count",
								accessorKey: "issueCount",
							},
							{
								header: "Download",
								accessorKey: "download",
							},
						]}
						data={[]}
						// defaultFilters={[{ id: "lastAccessAt", value: { lastAccessStartDate: null, lastAccessEndDate: null }}]}
						hasPagination={false}
						showPageSetting={false}
						permission={permission}
						menuId="noti-sent-hitory"
					/>
				</div>
			</StyledSetupRiskAssessment>
		</PageContainer>
	)
})

const StyledSetupRiskAssessment = styled.div`
	padding: 0 1rem;
	.header {
		span {
			font-weight: bold;
			font-size: 1rem;
		}
		hr {
			flex-grow: 1;
			height: 0px;
			margin-left: 5px;
			margin-top: 9px;
		}
	}
	.section {
		padding: 10px 0px 10px 30px;
		.item {
			padding: 5px;
			.label {
				width: 12rem;
			}
		}
	}
	.eng-type-section {
		height: 175px;
		overflow-x: scroll;
		border: 1px solid ${(props) => props.theme.shade20};
		padding: 5px .eng-item {
			padding: 2px;
			:hover {
				background-color: ${(props) => props.theme.shade20};
			}
		}
	}
`
