import { types } from "mobx-state-tree"

const ArchiveDiagnosisUiHelper = types
	.model({
		openDrawer: false,
		firstCalled: true,
		selectedConditionId: "",
		// dialogs
		rationaleDialogOpen: false,
		openArchConditionHelpDialog: false,

		openArchivePolicyDetailDialog: false,
	})
	.actions((self) => ({
		setOpenDrawer(request?: boolean) {
			if (request !== undefined) {
				self.openDrawer = request
			} else {
				self.openDrawer = !self.openDrawer
			}
		},
		setFirstCalled(request: boolean) {
			self.firstCalled = request
		},
		setSelectedConditionId(conditionId: string) {
			self.selectedConditionId = conditionId
		},
		// dialogs
		setRationaleDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.rationaleDialogOpen = request
			} else {
				self.rationaleDialogOpen = !self.rationaleDialogOpen
			}
		},

		setOpenArchConditionHelpDialog(targetId: string, request: boolean) {
			self.openArchConditionHelpDialog = request
			if (request === true) {
				self.selectedConditionId = targetId
			} else {
				self.selectedConditionId = ""
			}
		},
		setOpenArchivePolicyDetailDialog(request?: boolean) {
			if (request !== undefined) {
				self.openArchivePolicyDetailDialog = request
			} else {
				self.openArchivePolicyDetailDialog = !self.openArchivePolicyDetailDialog
			}
		},
	}))
	.views((self) => ({
		get drawerOpenStatus() {
			return self.openDrawer
		},
	}))

export default ArchiveDiagnosisUiHelper
