import { types, flow } from "mobx-state-tree"
import ReadProjInfo from "../../project-information/store/model-actions/read-proj-info"
// model actions

const PBCModel = (apiRead: any) =>
	types
		.model({})
		.actions((self) => ({
			// ------ Read
			// ------ Update
		}))
		.actions((self, read = apiRead) => ReadProjInfo(self, read))

export default PBCModel
