import React from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLButton,
	DLIconButton,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import {
	mdiHumanMale,
	mdiRun,
	mdiCheckCircle,
	mdiLock,
	mdiLockOpenVariantOutline,
	mdiHelpCircleOutline,
} from "@mdi/js"
import {
	DLProjMenuGroups,
	DLProjSubMenus,
} from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { Link } from "react-router-dom"

interface ArchiveConditionCardProps {
	conditionDetail: any
	openDetailDialog: any
}

const ArchiveConditionCard = observer(
	({ conditionDetail, openDetailDialog }: ArchiveConditionCardProps) => {
		const {
			id,
			title,
			counts,
			hasDetail,
			isMandatory,
			status,
			countingMethod,
		} = conditionDetail
		const projStore = useProjStore()
		const message = counts.data

		ConsoleLog([id, "conditionDetail", conditionDetail])

		const handleGetDetail = (itemId: any) => {
			if (itemId === "archiveConditionId17") {
				// TODO
				// Redirect to Rationale tab
				projStore.archive.setRationaleDialogOpen(true)
			} else if (
				itemId === "archiveConditionId8" ||
				itemId === "archiveConditionId9"
			) {
				// TODO
				// Redirect to PBC list tab
			} else if (itemId === "archiveConditionId7") {
				// TODO
				// Redirect to TIMELINE tab
			} else {
				projStore.archive.setSelectedConditionId(id)
				projStore.archive.initializeDetailItems()
				projStore.archive.getConditionDetail(id)
				openDetailDialog(true)
			}
		}

		const handleOpenedFiles = (menuId: DLProjSubMenus) => {
			projStore.archive.setStoreName(menuId)
			projStore.openedFiles.setOpenedFilesDialogOpen(true)
		}

		const handleOpenHelpDialog = () => {
			projStore.archive.setSelectedConditionId(id)
			projStore.archive.setOpenArchConditionHelpDialog(id, true)
		}
		const defaultUrl = projStore.checkin.defaultUrl

		const renderCommentViewDetailButton = () => {
			const menuId = DLProjSubMenus.comments
			const menuInfo = projStore.checkin.getMenuInfoById(menuId)
			const parentMenu =
				menuInfo?.menuGroup &&
				projStore.checkin.getMenuInfoById(menuInfo.menuGroup)

			const parentUrl = parentMenu && parentMenu.url

			if (menuInfo && parentUrl) {
				return (
					<Link
						to={defaultUrl + "/" + parentUrl + "/" + menuInfo.url}
						onClick={() => {
							menuInfo &&
								projStore.menuTabs.addOpenedMenu(menuInfo)
						}}
					>
						<DLButton
							variant="outlined"
							eleTestId="goto-comments-btn"
							size="thin"
						>
							Go To Comments
						</DLButton>
					</Link>
				)
			} else {
				return <div />
			}
		}

		const renderTrashViewDetailButton = () => {
			const menuId = DLProjSubMenus.trash
			const menuInfo = projStore.checkin.getMenuInfoById(menuId)
			const parentMenu =
				menuInfo?.menuGroup &&
				projStore.checkin.getMenuInfoById(menuInfo.menuGroup)

			const parentUrl = parentMenu && parentMenu.url

			if (menuInfo && parentUrl) {
				return (
					<Link
						to={defaultUrl + "/" + parentUrl + "/" + menuInfo.url}
						onClick={() => {
							menuInfo &&
								projStore.menuTabs.addOpenedMenu(menuInfo)
						}}
					>
						<DLButton
							variant="outlined"
							eleTestId="goto-trash-btn"
							size="thin"
						>
							Go To Trash
						</DLButton>
					</Link>
				)
			} else {
				return <div />
			}
		}

		const conditionMessage = (id: string) => {
			const total = counts.totalCount
			const completed = counts.completedCount
			const required = total - completed

			switch (id) {
				case "archiveConditionId1": // prepare sign off
					return `${required} files are not prepared`

				case "archiveConditionId2": // review sign off
					return `${required} files are not reviewed`

				case "archiveConditionId3": // ep review
					return `${required} files are not EP reviewed`

				case "archiveConditionId4": // cp review
					return `${required} files are not CP reviewed`

				case "archiveConditionId5": // qc sign off
					return `${required} QC assigned workpapers are not reviewed`

				case "archiveConditionId6": // attention status
					return `${required} files final status are not completed`

				case "archiveConditionId7": // timeline
					return `${required} timeline items status are not completed`

				case "archiveConditionId8": // pbc list
					return `${required} PBC List status are not completed`

				case "archiveConditionId9": // pbc attachment
					return `${required} PBC uploaded items are not deleted`

				case "archiveConditionId10": // comments
					return `${required} comments on files are not deleted`

				case "archiveConditionId11": // trash
					return `${required} files in the trash are not deleted`

				case "archiveConditionId12": // opened files
					return `${required} files are not closed`

				case "archiveConditionId13": // re sign off 2
					return `${required} files with red * are not re-signed off`

				case "archiveConditionId14": // re sign off 1
					return `${required} files with red colored sign off are not re-signed off`

				case "archiveConditionId15": // final report date
					return `Set the Final Report Date to archive`
				// id 16 = project lock
				case "archiveConditionId17": // rationales
					return `${required} files have no rationale`

				case "archiveConditionId18": // role assigned files
					return `${required} files assigned to roles are not reviewed` // role assigned files are not reviewd by the role`

				default:
					return ``
			}
		}

		return (
			<StyledArchiveConditionCard className="archive-condition-card">
				<div className="status FR AC JC">
					{status === "TODO" ? (
						<Icon
							path={mdiHumanMale}
							size={1}
							color={isMandatory ? "red" : "orange"}
						/>
					) : status === "INPROGRESS" ? (
						<Icon path={mdiRun} size={0.8} color="green" />
					) : status === "DONE" ? (
						<Icon path={mdiCheckCircle} size={0.8} color="blue" />
					) : (
						<div />
					)}
				</div>
				{/* ----- for general conditions */}
				{countingMethod !== "TYPE_C" && (
					<div
						className="condition-count"
						data-testid={`count-${id}`}
					>
						{projStore.archive.getCountRatio(id)}
					</div>
				)}
				{/* ----- for project lock (id: 16) */}
				{countingMethod === "TYPE_C" &&
					id === "archiveConditionId16" && (
						<div
							className="condition-count"
							data-testid={`count-${id}`}
						>
							{status === "DONE" ? (
								<Icon path={mdiLock} size={0.8} />
							) : (
								<Icon
									path={mdiLockOpenVariantOutline}
									size={0.8}
									color={isMandatory ? "red" : ""}
								/>
							)}
						</div>
					)}
				{/* ----- for final report date (id: 15)  */}
				{countingMethod === "TYPE_C" &&
					id === "archiveConditionId15" && (
						<div
							className="condition-count"
							data-testid={`count-${id}`}
						/> // TODO: import final report date from default project information
					)}
				{/* ----  */}
				<div className="condition-title" data-testid={`title-${id}`}>
					{title}
				</div>
				<div className="condition-message" data-testid={`msg-${id}`}>
					{message ? (
						message
					) : status === "DONE" ? (
						<span style={{ color: "blue" }}>Completed</span>
					) : (
						<span style={{ color: "red" }}>
							{conditionMessage(id)}
						</span>
					)}
				</div>
				<div className="view-detail-btn">
					{hasDetail && status !== "DONE" ? (
						<>
							{id === "archiveConditionId12" ? (
								<div className="FR JSB">
									<DLButton
										variant="outlined"
										eleTestId="view-workpaper-btn"
										size="thin"
										clickHandler={() =>
											handleOpenedFiles(DLProjSubMenus.wp)
										}
									>
										Workpaper Files
									</DLButton>
									<DLButton
										variant="outlined"
										eleTestId="view-Permanent-btn"
										size="thin"
										clickHandler={() =>
											handleOpenedFiles(DLProjSubMenus.pf)
										}
									>
										Permanent Files
									</DLButton>
								</div>
							) : id === "archiveConditionId10" ? (
								renderCommentViewDetailButton()
							) : id === "archiveConditionId11" ? (
								renderTrashViewDetailButton()
							) : (
								<DLButton
									variant="outlined"
									eleTestId="view-detail-btn"
									size="thin"
									clickHandler={() => handleGetDetail(id)}
								>
									View Detail
								</DLButton>
							)}
						</>
					) : (
						<div className="placeholder" />
					)}
				</div>
				<div>
					<DLIconButton
						eleTestId={`help-dialog-${title}`}
						eleClassName="help-contents"
						clickHandler={handleOpenHelpDialog}
					>
						<Icon path={mdiHelpCircleOutline} size={0.6} />
					</DLIconButton>
				</div>
			</StyledArchiveConditionCard>
		)
	}
)

export default ArchiveConditionCard

const StyledArchiveConditionCard = styled.div`
	display: flex;
	align-items: center;
	/* margin-bottom: 0.4rem; */
	border-bottom: 1px solid ${(props) => props.theme.secondaryDeep};
	padding: 0.4rem 0 0.4rem 0rem;
	/* height: 2rem; */
	:hover {
		background-color: ${(props) => props.theme.background};
	}
	.status {
		flex-grow: 0;
		flex-basis: 2rem;
		margin-right: 1rem;
	}
	.condition-title {
		flex-grow: 2;
		flex-basis: 4rem;
	}
	.condition-message {
		flex-grow: 4;
		flex-basis: 8rem;
	}
	.condition-count {
		display: flex;
		align-items: center;
		flex-grow: 0;
		flex-basis: 4rem;
		margin-right: 1rem;
	}
	.view-detail-btn {
		flex-grow: 0;
		min-width: 7rem;
	}
`
