import React, { useState } from "react"
import styled from "styled-components"
import StyledReactTable from "../../../components/basic-elements/tables/StyledReactTable"
import DLRadio from "../../../components/basic-elements/radio-button/DLRadio"
import { ConsoleLog } from "../../basic-elements"

export default function PageSettingDiaglog() {
	const [state, setState] = useState({
		id: {
			filter: false,
			column: false,
		},
	})

	const onChange = (targetState: any) => {
		// @Noah: is this required..?
		ConsoleLog("PageSettingDiaglog", targetState)
	}

	return (
		<StyledContainer>
			*This is a draft page setting example (not completed)
			<StyledReactTable>
				ReactTable?
				{/* <ReactTable
					data={ENTITY_INFO_SETTINGS}
					columns={columns(onChange)}
					showPagination={false}
				/> */}
			</StyledReactTable>
		</StyledContainer>
	)
}

const columns = (onChange: Function) => [
	{
		Header: "Data Type",
		accessor: "label",
	},
	{
		Header: "Example",
		accessor: "",
		Cell: () => {
			return <div />
		},
	},
	{
		Header: "Filter On",
		accessor: "filterOn",
		Cell: (row: any) => {
			const targetState = { key: row.original.key, type: "filter" }
			return (
				<DLRadio
					eleTestId={row.value + "-radio"}
					checked={row.value}
					onChange={() => onChange(targetState)}
					value={row.original.key}
					disabled={!row.original.needFilter}
					color="primary"
				/>
			)
		},
	},

	{
		Header: "Table Column On",
		accessor: "columnOn",
		Cell: (row: any) => {
			return (
				<DLRadio
					eleTestId={row.value + "-radio"}
					checked={row.value}
					onChange={() => onChange(row.original.key)}
					value={row.original.key}
					disabled={!row.original.needColumn}
					color="primary"
				/>
			)
		},
	},
]

const ENTITY_INFO_SETTINGS = [
	{
		key: "id",
		label: "Auto generated system ID",
		needFilter: false,
		needColumn: false,
		needSearch: false,
		filterOn: false,
		columnOn: false,
	},
	{
		key: "enteredId",
		label: "Entered ID",
		needFilter: false,
		needColumn: true,
		needSearch: true,
		filterOn: false,
		columnOn: false,
	},
	{
		key: "businessName",
		label: "Business Entity Name",
		needFilter: false,
		needColumn: true,
		needSearch: true,
		filterOn: false,
		columnOn: true,
	},
	{
		key: "type",
		label: "Entity Type",
		needFilter: true,
		needColumn: true,
		needSearch: false,
		filterOn: true,
		columnOn: true,
	},
	{
		key: "address",
		label: "Entity Address",
		needFilter: false,
		needColumn: true,
		needSearch: true,
		filterOn: false,
		columnOn: false,
	},
	{
		key: "mainBusiness",
		label: "Entity's main business",
		needFilter: true,
		needColumn: true,
		needSearch: true,
		filterOn: true,
		columnOn: true,
	},
	{
		key: "isMajorSubsidiary",
		label: "Is Entity major subsidiary?",
		needFilter: true,
		needColumn: true,
		needSearch: false,
		filterOn: false,
		columnOn: true,
	},
	{
		key: "connectedCoA",
		label: "Connected Chart of Accounts",
		needFilter: false,
		needColumn: true,
		needSearch: false,
		filterOn: false,
		columnOn: true,
	},
	{
		key: "relationshipp",
		label: "Why this entity is required on the project?",
		needFilter: false,
		needColumn: true,
		needSearch: false,
		filterOn: false,
		columnOn: true,
	},
]

const StyledContainer = styled.div``
