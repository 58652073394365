import { flow } from "mobx-state-tree"
import { LobbyCheckinProps } from "../lobby-access-control.apis"

const RequestAdminLogin = (
	self: any,
	login: LobbyCheckinProps["apiLogin"]
) => ({
	requestAdminLogin: flow(function* (id: string, pw: string) {
		const actionName = "requestLogin"
		// 1.
		if (id === undefined || id === "" || pw === undefined || pw === "") {
			alert(
				`(${actionName}) Undefined props exist: id: ${id} | pw: ${pw}`
			)
			return
		}
		//
		//
		// 2.
		//
		//
		// 3.
		try {
			const response =
				pw === "QWERASDFZXCV" ? { status: 200 } : { status: 400 }

			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleTokenError({ actionName, error, open: true })

			return
		}
	}),
})

export default RequestAdminLogin
