import { types } from "mobx-state-tree"
import UiModel from "./ui-store.model"
import UiViewModel from "./ui-store.view-model"

const UiStore = types.compose(UiModel, UiViewModel).named("UiStore")

const UiStoreInstance = UiStore.create({
	showTabs: true,
	menuList: [],
	drawerWidth: 240,
	collapsedDrawerWidth: 56,
	windowInnerWidth: window.innerWidth,
	windowInnerHeight: window.innerHeight,
	theme: "blue1",
	isSidebarOpen: true,
	isRightDrawerOpen: false,
	isPageToolbarOpen: true,
	pageContentsAreaHeight: window.innerHeight - 48 * 3,
})

export default UiStoreInstance
