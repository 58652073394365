import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - 200, 409
 * - 400 (just show the server message itself. no translation.)
 */
const ConfirmSignup = (self: any) => ({
	confirmSignup(props: { email: string; code: string }) {
		// NOTE: code is string. number cannot catch like '012345'. (number change it as 12345)
		// 0. set actionName
		const actionName = "confirmSignup"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}

		// 2.
		// self.setActionStatus(ActionStatus.loading)

		// 3. request api call
		const { email, code } = checkedProps.props

		self.verifyCode({ email, code })
			.then((response: any) => {
				/**
				 * case 1 (200 & 400) (already confirmed)
				 * case 2 (200 & 409) (expired code)
				 * case 3 (200 & 400) (code is invalid)
				 * case 4 (200 & 200) (valid)
				 * case 5 (200 & 500) (error)
				 */
				if (
					response.status === 200 &&
					response.data.status.code === 400
				) {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 400,
						// msgCode: actionName + "_400",
						// NOTE: Do not use msgCode here.
						// There are 2 types message for 400
						message: response.data.status.message,
						color: MessageColorType.orange,
						open: true,
					})
				}
				if (
					response.status === 200 &&
					response.data.status.code === 409
				) {
					alert(response.data.status.message)
					self.resendVerificationCode(email)
					// TODO: response not exist after this api...
					// can we check the email status hat whether or not it has arrived?
					self.handleResponse({
						actionName,
						status: ActionStatus.standby,
						code: 409,
						msgCode: actionName + "_409",
						message: response.data.status.message,
						color: MessageColorType.orange,
						open: false,
					})
					window.location.replace("/login")
				}
				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						msgCode: actionName + "_200",
						message: response.data.status.message,
						color: MessageColorType.blue,
						open: true,
					})

					window.location.replace("/login")
				}
				if (
					response.status === 200 &&
					response.data.status.code === 500
				) {
					self.handleResponse({
						actionName,
						status: ActionStatus.standby,
						code: 500,
						// msgCode: actionName + "_500",
						message: response.data.status.message,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ConfirmSignup
