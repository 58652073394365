import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
    CommonViewModelActions,
    Refresh,
    ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// ---------- view model actions
import {
    GetUnarchivedProjects,
    RemoveAMUnarchivedProject,
} from "./view-model-actions"
import { initialStore } from "./am-unarchived-projects.provider"
import ProjectTableFilterViews from "../../../../../service-modules/project-module/view-model-views/table-filter-views"
import { DLProjectModel } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import {
    formattedDateWithTz2,
    getTzDate,
} from "../../../../../library/converters/date-utc-converter"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../../components/combined-elements/projects-table/projects-table-props"

const AmUnarchivedProjectsViewModel = types
    .model({
        storeName: types.enumeration<DLOrgSubMenus>(
            Object.values(DLOrgSubMenus)
        ),
        //
        amUnarchivedProjectList: types.array(DLProjectModel),
        //
        needRefresh: true,
        responses: Responses,
        responseSnackbar: ResponseSnackbar,
    })
    .actions((self) => ({
        pushItemToList(item: any) {
            self.amUnarchivedProjectList.push(item)
        },
        resetStoredList() {
            self.amUnarchivedProjectList.length = 0
        },
        deleteUnarchivedProject(clientId: string) {
            self.amUnarchivedProjectList.splice(
                self.amUnarchivedProjectList.findIndex(
                    (item: any) => item.id === clientId
                ),
                1
            )
        },
    }))
    .views((self) => ({
        viewSelectedProjInfo(selectedProj: string, projStatus: ProjectStatus) {
            const selectedProjInfo = self.amUnarchivedProjectList.find(
                (project: any) => project.id === selectedProj
            )

            return selectedProjInfo
        },
        getProjLengthOfYear(year: string) {
            if (year === "All Years") {
                return self.amUnarchivedProjectList.length
            }
            return self.amUnarchivedProjectList.filter(
                (project: any) => project.financialYear === parseInt(year)
            ).length
        },
        // /**
        //  * org > arch mgmt > unarchived
        //  * @param dateFormat
        //  * @param timeZone
        //  * @param filteredData
        //  * @returns
        //  */
        // forExport(dntFormat: DnTFormatProps, filteredData?: any) {
        //     let defaultList: any
        //     let formattedList: any[] = []

        //     const isAppAdminUser = JSON.parse(
        //         localStorage.getItem("isAdminAppUser") || "false"
        //     )

        //     if (filteredData) {
        //         defaultList = filteredData
        //     } else {
        //         defaultList = self.amUnarchivedProjectList
        //     }

        //     function formatDate(date: string | null) {
        //         if (!date) return "-"
        //         return getTzDate2({
        //             date,
        //             dntFormat,
        //         })
        //     }
        //     console.log(defaultList, "defaultList")
        //     defaultList.map((project: any) => {
        //         /**
        //          * Required date information
        //          * 1. Period end date
        //          * 2. Expected report date
        //          * 3. Expected archive deadline date
        //          * 4. Final reprot date
        //          * 5. Final archive deadline date
        //          */
        //         const formattedPeriodEndDate = filteredData
        //             ? project.periodEndDate
        //             : formatDate(project.periodEndDate)
        //         const formattedExpectedReportDate = filteredData
        //             ? project.expectedReportDate
        //             : formatDate(project.expectedReportDate)
        //         const formattedExpectedArchiveDeadlineDate = filteredData
        //             ? project.expectedArchiveDate
        //             : formatDate(project.expectedArchiveDate)
        //         const formattedFinalReportDate = filteredData
        //             ? project.finalReportDate
        //             : formatDate(project.finalReportDate)
        //         const formattedFinalArchiveDeadlineDate = filteredData
        //             ? project.finalArchiveDeadlineDate
        //             : formatDate(project.finalArchiveDeadlineDate)
        //         // const formattedArchivedDate = formatDate(project.archivedAt)
        //         const formattedUnarchivedDate = filteredData
        //             ? project.unarchivedAt
        //             : formatDate(project.unarchivedAt)
        //         // --------------------------------------
        //         const formattedLastAccessDate = filteredData
        //             ? project.lastAccessAt
        //             : formatDate(project.lastAccessAt)
        //         // --------------------------------------
        //         const qcUsers = project.qcUsers
        //             .map((user: any) => {
        //                 return user.name
        //             })
        //             .join(",")
        //         const epUsers = project.epUsers
        //             .map((user: any) => {
        //                 return user.name
        //             })
        //             .join(",")
        //         // --------------------------------------
        //         let formattedProject: any = {
        //             "System Id": project.id.includes("projId")
        //                 ? project.id.slice(6)
        //                 : project.id,
        //             "Period Name": project.periodName,
        //             Year: project.financialYear,
        //             Client: project.clientName,
        //             "Project Name": project.title,
        //             "Project ID": project.aliasId,
        //             "Project Version": project.version,
        //             EP: epUsers,
        //             QC: qcUsers,
        //             "Eng Type": project.engTypeName,
        //             // ----- common date informations
        //             "Period End Date": formattedPeriodEndDate,
        //             "Expected Report Date": formattedExpectedReportDate,
        //             "Expected Archive Deadline Date":
        //                 formattedExpectedArchiveDeadlineDate,
        //             "Final Report Date": formattedFinalReportDate,
        //             "Final Archive Deadline Date":
        //                 formattedFinalArchiveDeadlineDate,
        //             // -------------------------
        //             // ArchivedAt: formattedArchivedDate,
        //             "Unarchived Date": formattedUnarchivedDate,
        //             "Unarchived By": project.unarchivedBy.name,
        //             "Last Access Date": formattedLastAccessDate,
        //             "Last Access By": project.lastAccessBy.name,
        //         }

        //         // if (isAppAdminUser) {
        //         // 	formattedProject = {
        //         // 		...formattedProject,
        //         // 		projectId: project.id,
        //         // 	}
        //         // }
        //         formattedList.push(formattedProject)
        //     })

        //     return formattedList
        // },
        formattedProjList(
            dntFormat: DnTFormatProps,
            projScreenType: ProjScreenType
        ) {
            const { dateFormat, timeFormat, timeZone } = dntFormat
            // Copy default list from the store
            const formatUpdatedList = [...self.amUnarchivedProjectList]

            function formatDate(date: string | null) {
                if (!date) return "-"
                return getTzDate({
                    date,
                    timeZone,
                    dateFormat,
                })
            }

            function formatTime(date: string | null) {
                if (date) {
                    return formattedDateWithTz2({
                        date,
                        timeZone,
                        timeFormat,
                        dateFormat,
                    })
                }
                return ""
            }

            let formattedList: any[] = []
            formatUpdatedList.map((project: any) => {
                const formattedPeriodEndDate = formatDate(project.periodEndDate)
                const formattedUnarchivedDate = formatDate(project.unarchivedAt)
                const formattedFinalReportDate = formatDate(
                    project.finalReportDate
                )
                const formattedExpectedReportDate = formatDate(
                    project.expectedReportDate
                )
                const formattedExpectedArchiveDate = formatDate(
                    project.expectedArchiveDate
                )
                const formattedFinalArchiveDeadlineDate = formatDate(
                    project.finalArchiveDeadlineDate
                )
                const formattedLastAccess = formatTime(project.lastAccessAt)
                const formattedCreatedAt = formatDate(project.createdAt)

                // Replace date part
                const formattedProject = {
                    ...project,
                    periodEndDate: formattedPeriodEndDate,
                    finalReportDate: formattedFinalReportDate,
                    expectedReportDate: formattedExpectedReportDate,
                    finalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
                    expectedArchiveDate: formattedExpectedArchiveDate,
                    lastAccessDate: formattedLastAccess
                        ? formattedLastAccess.date
                        : "",
                    lastAccessTime: formattedLastAccess
                        ? formattedLastAccess.time
                        : "",
                    unarchivedAt: formattedUnarchivedDate,
                    createdAt: formattedCreatedAt,
                }
                formattedList.push(formattedProject)
            })
            return formattedList
        },
    }))
    .actions(GetUnarchivedProjects)
    .actions(RemoveAMUnarchivedProject)

    // Filter related views
    .views(ProjectTableFilterViews)
    // common parts
    .actions((self) => ({
        initializeStore() {
            applySnapshot(self, initialStore)
        },
    }))
    .actions(Refresh)
    .actions(CommonViewModelActions)
    .views(ViewResponseHelper)

export default AmUnarchivedProjectsViewModel
