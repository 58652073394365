import { types, applySnapshot } from "mobx-state-tree"
import {
	AccessMgmtUsers,
	User,
	UnarchiveResponse,
} from "./data-models/access-mgmt-user-list.data-model"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./am-archived-projects.provider"

import {
	GetArchivedProjects,
	GetReadonlyUsers,
	GetFilteredUsers,
	UnAssignReadOnlyUser,
	AssignReadonlyUser,
	EditReadOnlyUser,
	GetProjUsersAndFileCount,
	RemoveAmArchivedProject,
	Unarchive,
	ExportArchivedZipFile,
	VerifyDigitalSignatureHashValue,
	RegenPreProcessPdfs,
	ReleaseForceArchive,
} from "./view-model-actions"

import ProjectTableFilterViews from "../../../../../service-modules/project-module/view-model-views/table-filter-views"
import ExportUsers from "./view-model-actions/export-users"
import { DLProjectModel } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import {
	getTzDate,
	getTzDateAndTime,
	formattedDateWithTz2,
} from "../../../../../library/converters/date-utc-converter"
import RepairReplica from "./view-model-actions/repair-replica"
import { PreConditionData } from "../../../../project-side/archive/archive-pre-process/store/data-models/archive-pre-process.data-model"
import GetPreConditionStatus from "../../../../project-side/archive/archive-pre-process/store/view-model-actions/get-pre-condition-status"
import GetArchiveDiagnosis from "../../../../project-side/archive/archive-diagnosis/store/view-model-actions/get-archive-diagnosis"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { UserForExport2 } from "./data-models/user-for-export-2.data-model"
import GetFilters from "../../am-archived-projects-2/store/view-model-actions/get-filters"
import FilterActions from "../../am-archived-projects-2/store/filters/filter-actions"
import FilterViews from "../../am-archived-projects-2/store/filters/filter-views"
import GetArchivedProjects2 from "../../am-archived-projects-2/store/view-model-actions/get-archived-projects2"
import ReplicaRestorationRelated from "./replica-restoration-related"
import ZipUrlListRelated from "./zip-url-list-related"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { ProjScreenType } from "../../../../../components/combined-elements/projects-table/projects-table-props"
import {
	IdType,
	idToString,
} from "../../../../../library/converters/id-converter"

export enum AdminMode {
	repairReplica = "REPAIR_REPLICA",
	exportZipUrl = "EXPORT_ZIP_URL",
}

const Filters = types.model({
	id: types.string,
	name: types.string,
})

const ArchiveMgmtArchivedProjectsViewModel = types
	.model({
		storeName: types.enumeration<DLOrgSubMenus>(
			Object.values(DLOrgSubMenus)
		),
		//
		amArchivedProjectList: types.array(DLProjectModel),
		amForceArchivedProjectList: types.array(DLProjectModel),
		accessMgmtUserList: types.array(AccessMgmtUsers),
		//
		preConditionData: PreConditionData,
		//
		userList: types.array(User),
		unarchivedDetails: UnarchiveResponse, // TODO: ???
		//
		usersForExport: types.array(UserForExport2),
		userExportStatusList: types.array(
			types.model({
				projId: types.string,
				success: types.boolean,
			})
		),
		// common parts
		needRefresh: true,
		needRefreshForForceArchive: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
		// NOTE: temporary for replica restoration
		adminMode: types.enumeration<AdminMode>(Object.values(AdminMode)),
		replicaTargetList: types.array(
			types.model({
				id: types.string,
				title: types.string,
				aliasId: types.string,
				version: types.number,
				// (belows) for export json
				archivedBy: types.string,
				archivedAt: types.string,
			})
		),
		pdfStatusList: types.array(
			types.model({
				id: types.number,
				title: types.string,
				aliasId: types.string,
				pdfStatus: types.boolean,
			})
		),
		listWithReplicaId: types.array(
			types.model({
				id: types.number,
				title: types.string,
				aliasId: types.string,
				pdfStatus: types.boolean,
				replicaId: types.number,
			})
		),
		listForJsonExport: types.array(
			types.model({
				id: types.string,
				title: types.string,
				aliasId: types.string,
				archivedDate: types.string,
				archivedTime: types.string,
				archivedUtcDate: types.string,
				archivedBy: types.string,
				replicaId: types.number,
			})
		),
		listForJsonExport2: types.array(
			types.model({
				id: types.string,
				title: types.string,
				aliasId: types.string,
				archivedDate: types.string,
				archivedTime: types.string,
				archivedBy: types.string,
				wpCount: types.number,
				pfCount: types.number,
				userCount: types.number,
				version: types.number,
			})
		),
		zipUrlList: types.array(
			types.model({
				id: types.string,
				title: types.string,
				aliasId: types.string,
				clientName: types.string,
				epUsers: types.string,
				qcUsers: types.string,
				year: types.number,
				periodType: types.string,
				periodEndDate: types.string,
				archivedAt: types.string,
				version: types.number,
				engType: types.string,
				//
				zipUrl: types.string,
				// tempZipUrl: types.string,
			})
		),
		unassignList: types.array(
			types.model({
				projId: types.number,
				readonlyId: types.number,
			})
		),
		//
		clientFilters: types.array(Filters),
		engTypeFilters: types.array(Filters),
		epUsersFilters: types.array(Filters),
		qcUsersFilters: types.array(Filters),
		groupFilters: types.array(Filters),
		periodFilters: types.array(Filters),
		replicaValidationResults: types.array(
			types.model({
				replicaId: types.number,
				projectSize: types.string,
				validationResult: types.array(
					types.model({
						id: types.number,
						title: types.string,
						status: types.union(types.undefined, types.string),
					})
				),
			})
		),
	})
	.views((self) => ({
		get viewUnassignList() {
			const list = self.unassignList.map((item: any) => {
				const { projId, readonlyId } = item
				return {
					projId,
					readonlyId,
				}
			})
			return list
		},
		get viewReplicaValidationResults() {
			const list = self.replicaValidationResults.map((item: any) => {
				const { replicaId, projectSize, validationResult } = item
				let asText: string[] = []
				validationResult.map((item: any) => {
					const readable = `(${item.id}) ${item.title} is ${item.status}`
					asText.push(readable)
				})
				return {
					replicaId,
					projectSize,
					asText,
				}
			})
			return list
		},
		get viewZipUrlList() {
			const list = self.zipUrlList.map((item: any) => {
				const {
					year,
					periodType,
					engType,
					clientName,
					//
					title,
					aliasId,
					epUsers,
					qcUsers,
					periodEndDate,
					archivedAt,
					version,
					//
					zipUrl,
				} = item
				return {
					year,
					periodType,
					engType,
					clientName,
					//
					title,
					aliasId,
					epUsers,
					qcUsers,
					periodEndDate,
					archivedAt,
					version,
					//
					zipUrl,
				}
			})
			return list
		},
		get viewReplicaTargetList() {
			const simpler = self.replicaTargetList.map((item: any) => {
				const { aliasId, archivedAt, archivedBy, id, title, version } =
					item
				const archivedDate = archivedAt.slice(0, 10)
				const archivedTime = archivedAt.slice(11)
				return {
					aliasId,
					archivedDate,
					archivedTime,
					archivedBy,
					version,
					id,
					title,
				}
			})
			return simpler
		},
		get viewPdfStatusList() {
			const simpler = self.pdfStatusList.map((item: any) => {
				return { id: item.id, pdf: item.pdfStatus, title: item.title }
			})
			return simpler
		},
		get viewRepIdList() {
			const simpler = self.listWithReplicaId.map((item: any) => {
				return { id: item.id, repId: item.replicaId, title: item.title }
			})
			return simpler
		},
		get viewListForJsonExport() {
			const simpler = self.listForJsonExport.map((item: any) => {
				const {
					id,
					title,
					aliasId,
					archivedDate,
					archivedTime,
					archivedUtcDate,
					archivedBy,
					replicaId,
				} = item
				return {
					id,
					title,
					aliasId,
					archivedDate,
					archivedTime,
					archivedUtcDate,
					archivedBy,
					replicaId,
				}
			})
			return simpler
		},
		get viewListForJsonExport2() {
			const simpler = self.listForJsonExport2.map((item: any) => {
				const {
					id,
					title,
					aliasId,
					archivedDate,
					archivedTime,
					archivedBy,
					version,
					wpCount,
					pfCount,
					userCount,
				} = item
				return {
					id,
					title,
					aliasId,
					archivedDate,
					archivedTime,
					archivedBy,
					version,
					wpCount,
					pfCount,
					userCount,
				}
			})
			return simpler
		},
	}))
	.actions(ReplicaRestorationRelated)
	.actions(ZipUrlListRelated)
	.actions((self) => ({
		resetReplicaValidationResults() {
			self.replicaValidationResults.length = 0
		},
		pushReplicaValidationResult(item: {
			replicaId: number
			projectSize: string
			validationResult: {
				id: number
				title: string
				status: undefined | string
			}[]
		}) {
			self.replicaValidationResults.push(item)
		},
		// NOTE: admin mode
		updateAdminMode(mode: AdminMode) {
			self.adminMode = mode
		},
		// NOTE: unassign
		resetUnassignList() {
			self.unassignList.length = 0
		},
		pushUnassignList(item: any) {
			self.unassignList.push(item)
		},
		// NOTE: for s3 zip temp url
		resetZipUrlList() {
			self.zipUrlList.length = 0
		},
		pushZipUrlList(item: any) {
			self.zipUrlList.push(item)
		},
		addZipUrl({ id, zipUrl }: { id: string; zipUrl: string }) {
			const target = self.zipUrlList.find((item: any) => item.id === id)
			if (target) {
				target.zipUrl = zipUrl
			} else {
				console.log("(addZipUrl)! Cannot find matched Project ID", id)
			}
		},
		// addS3ZipTempUrl({ id, tempUrl }: { id: string; tempUrl: string }) {
		// 	const target = self.zipUrlList.find((item: any) => item.id === id)
		// 	if (target) {
		// 		target.tempZipUrl = tempUrl
		// 	} else {
		// 		console.log(
		// 			"(addS3ZipTempUrl)! Cannot find matched Project ID",
		// 			id
		// 		)
		// 	}
		// },
		// NOTE: temporary for replica restoration
		setReplicaTargetList(list: any) {
			self.replicaTargetList = list
		},
		pushPdfStatusItem(item: any) {
			self.pdfStatusList.push(item)
		},
		resetPdfStatusList() {
			self.pdfStatusList.length = 0
		},
		pushListWithReplicaId(item: any) {
			self.listWithReplicaId.push(item)
		},
		resetListWithReplicaId() {
			self.listWithReplicaId.length = 0
		},
		pushListForJsonExport(item: any) {
			// console.log("item's archivedAt:", item.archivedAt)
			const utcDate = self.amArchivedProjectList.find(
				(proj: any) => proj.id === item.id
			)?.archivedAt //.slice(0, 10)
			// console.log("utcDate:", utcDate)
			const withUtcDate = {
				...item,
				archivedUtcDate: utcDate,
			}

			self.listForJsonExport.push(withUtcDate)
		},
		resetListForJsonExport() {
			self.listForJsonExport.length = 0
		},
		pushListForJsonExport2(item: any) {
			self.listForJsonExport2.push(item)
		},
		resetListForJsonExport2() {
			self.listForJsonExport2.length = 0
		},
		//
		// ---------------(below) default
		//
		concatUsersForExport(concattedList: any) {
			self.usersForExport = concattedList // NOTE: concat is not working in the action
		},
		resetUsersForExport() {
			self.usersForExport.length = 0
		},
		pushItemToUserExportStatusList(item: any) {
			self.userExportStatusList.push(item)
		},
		resetUserExportStatusList() {
			self.userExportStatusList.length = 0
		},
		pushItemToList(item: any) {
			self.amArchivedProjectList.push(item)
		},
		setArchivedProjectList(projects: any) {
			self.amArchivedProjectList.length = 0
			self.amArchivedProjectList = projects
		},
		setForceArchivedProjectList(projects: any) {
			self.amForceArchivedProjectList.length = 0
			self.amForceArchivedProjectList = projects
		},
		setNeedRefreshForForceArchive(request: boolean) {
			self.needRefreshForForceArchive = request
		},
		pushItemToAccessMgmtUserList(item: any) {
			self.accessMgmtUserList.push(item)
		},
		setAccessMgmtUserList(list: any) {
			self.accessMgmtUserList.length = 0
			self.accessMgmtUserList = list
		},
		pushItemToSearchAccessMgmtUserList(item: any) {
			self.userList.push(item)
		},
		updateAccessMgmtUserList(user: any) {
			const userId = idToString(user.Id, IdType.user)
			const index = self.accessMgmtUserList.findIndex(
				(item: any) => item.id === userId
			)
			self.accessMgmtUserList[index].isExportPerm = user.IsExportPerm
			self.accessMgmtUserList[index].expiredDate = new Date(
				user.ExpiredDate
			).toString()
		},
		setUnarchivedDetails(item: any) {
			self.unarchivedDetails = item
		},
		resetStoredList() {
			self.amArchivedProjectList.length = 0
		},
		resetAccessMgmtUserList() {
			self.accessMgmtUserList.length = 0
		},
		changeExportPermission(id: string) {
			const index = self.accessMgmtUserList.findIndex(
				(item: any) => item.id === id
			)
			self.accessMgmtUserList[index].isExportPerm =
				!self.accessMgmtUserList[index].isExportPerm
		},
		removeAccessMgmtUserFromStore(id: string) {
			self.accessMgmtUserList.splice(
				self.accessMgmtUserList.findIndex(
					(item: any) => item.id === id
				),
				1
			)
		},
		resetSearchAccessMgmtUserList() {
			self.userList.length = 0
		},
		// NOTE: WARNING: Update this method name as project
		getClientInfoById(
			projectId: string,
			dateFormat: string,
			timeZone: string,
			timeFormat: string
		) {
			const projectDetails = self.amArchivedProjectList.find(
				(element: any) => element.id === projectId
			)
			if (!projectDetails) {
				return undefined
			} else {
				// NOTE: WARNING: HAVE TO INTEGRATE WITH orgProjects.view-model.ts formattedProjectDetail
				// WARNING: They have different attribute name for archivedAt / ArchivedFinalDate
				// const PED = projectDetails.periodEndDate
				// const ERD = projectDetails.expectedReportDate
				// const FRD = projectDetails.finalReportDate
				// const AFD = projectDetails.archivedFinalDate
				const AFD = projectDetails.archivedAt

				// const formattedPED = PED
				// 	? getTzDate({ date: PED, timeZone, dateFormat })
				// 	: ""
				// const formattedERD = ERD
				// 	? getTzDate({ date: ERD, timeZone, dateFormat })
				// 	: ""
				// const formattedFRD = FRD
				// 	? getTzDate({ date: FRD, timeZone, dateFormat })
				// 	: ""

				const formattedAFD = AFD
					? getTzDateAndTime({
							date: AFD,
							timeZone,
							dateFormat,
							timeFormat,
					  })
					: ""

				return {
					...projectDetails,
					// periodEndDate: formattedPED,
					// expectedReportDate: formattedERD,
					// finalReportDate: formattedFRD,
					// archivedFinalDate: formattedAFD,
					archivedAt: formattedAFD,
				}
			}
		},
		getUserInfoById(userId: string) {
			return self.userList.find(
				(element: any) => element.userId === userId
			)
		},
		deleteArchivedProject(clientId: string) {
			self.amArchivedProjectList.splice(
				self.amArchivedProjectList.findIndex(
					(item: any) => item.id === clientId
				),
				1
			)
		},
		deleteForceArchivedProject(clientId: string) {
			self.amForceArchivedProjectList.splice(
				self.amForceArchivedProjectList.findIndex(
					(item: any) => item.id === clientId
				),
				1
			)
		},
		updateAccessible(
			clientId: string,
			access: boolean,
			hasExportPermission: string
		) {
			const index = self.amArchivedProjectList.findIndex(
				(item: any) => item.id === clientId
			)
			self.amArchivedProjectList[index].accessible = access
			self.amArchivedProjectList[index].hasExportPermission =
				hasExportPermission === "true"
		},
	}))
	//
	.actions((self) => ({
		setPreConditionData(data: any) {
			self.preConditionData = data
		},
		resetPreConditionData() {
			self.preConditionData.isPreProcessed = false
			self.preConditionData.preProcessFiles.map((files) => {
				files.isPreProcessed = false
				files.downloadUrl = ""
				files.viewUrl = ""
			})
		},
		setPreProcessComplete(isPreProcessed: boolean) {
			self.preConditionData.isPreProcessed = isPreProcessed
		},
	}))
	//
	.actions(FilterActions)
	.views(FilterViews)
	// AGER (Add, Get, Edit, Remove)
	.actions(GetArchivedProjects)
	.actions(GetArchivedProjects2)
	.actions(GetReadonlyUsers)
	.actions(GetFilteredUsers)
	.actions(AssignReadonlyUser)
	.actions(UnAssignReadOnlyUser)
	.actions(EditReadOnlyUser)
	.actions(GetProjUsersAndFileCount)
	.actions(RemoveAmArchivedProject)
	.actions(Unarchive)
	.actions(ExportArchivedZipFile)
	.actions(VerifyDigitalSignatureHashValue)
	.actions(RepairReplica)
	.actions(ReleaseForceArchive)
	// + pre-process pdf try
	.actions(RegenPreProcessPdfs)
	//
	.actions(ExportUsers)
	//
	//PreProcess
	.actions(GetPreConditionStatus)
	.actions(GetArchiveDiagnosis)
	//
	//
	.actions(GetFilters)
	//
	.views((self) => ({
		viewReadOnlyUserList() {
			return self.accessMgmtUserList
		},
		viewSearchAccessMgmtUserList() {
			return self.userList
		},
		getProjLengthOfYear(year: string) {
			if (year === "All Years") {
				return self.amArchivedProjectList.length
			}
			return self.amArchivedProjectList.filter(
				(project: any) => project.financialYear === parseInt(year)
			).length
		},
		formattedProjList(
			dntFormat: DnTFormatProps,
			projScreenType: ProjScreenType
		) {
			const { dateFormat, timeFormat, timeZone } = dntFormat
			// Copy default list from the store
			const defaultList =
				projScreenType === ProjScreenType.amArchived
					? self.amArchivedProjectList
					: self.amForceArchivedProjectList

			function formatDate(date: string | null) {
				if (!date) {
					return ""
				}
				return getTzDate({
					date,
					timeZone,
					dateFormat,
				})
			}

			function formatTime(date: string | null) {
				if (date) {
					return formattedDateWithTz2({
						date,
						timeZone,
						timeFormat,
						dateFormat,
					})
				}
				return ""
			}

			let formattedList: any[] = []

			defaultList.map((project) => {
				const formattedPeriodEndDate = formatDate(project.periodEndDate)
				const formattedArchived = formatTime(project.archivedAt)
				const formattedFinalReportDate = formatDate(
					project.finalReportDate
				)
				const formattedExpectedReportDate = formatDate(
					project.expectedReportDate
				)
				const formattedExpectedArchiveDate = formatDate(
					project.expectedArchiveDate
				)
				const formattedFinalArchiveDeadlineDate = formatDate(
					project.finalArchiveDeadlineDate
				)
				const formattedLastAccess = formatTime(project.lastAccessAt)
				const formattedCreatedAt = formatDate(project.createdAt)

				const formattedProject = {
					...project,
					periodEndDate: formattedPeriodEndDate,
					finalReportDate: formattedFinalReportDate,
					expectedReportDate: formattedExpectedReportDate,
					finalArchiveDeadlineDate: formattedFinalArchiveDeadlineDate,
					expectedArchiveDate: formattedExpectedArchiveDate,
					archivedDate: formattedArchived
						? formattedArchived.date
						: "",
					archivedTime: formattedArchived
						? formattedArchived.time
						: "",
					lastAccessDate: formattedLastAccess
						? formattedLastAccess.date
						: "",
					lastAccessTime: formattedLastAccess
						? formattedLastAccess.time
						: "",
					createdAt: formattedCreatedAt,
				}
				formattedList.push(formattedProject)
			})

			return formattedList
		},
		viewSelectedProjInfo(selectedProj: string) {
			const selectedProjInfo = self.amArchivedProjectList.find(
				(project: any) => project.id === selectedProj
			)

			return selectedProjInfo
		},
		viewFASelectedProjInfo(selectedProj: string) {
			const selectedProjInfo = self.amForceArchivedProjectList.find(
				(project: any) => project.id === selectedProj
			)

			return selectedProjInfo
		},
		// /**
		//  * org > arch mgmt > archived
		//  * @param dateFormat
		//  * @param timeZone
		//  * @param timeFormat
		//  * @param filteredData
		//  * @returns
		//  */
		// forExport(dntFormat: DnTFormatProps, filteredData?: any) {
		//     let defaultList: any
		//     let formattedList: any[] = []

		//     const isAppAdminUser = JSON.parse(
		//         localStorage.getItem("isAdminAppUser") || "false"
		//     )

		//     if (filteredData) {
		//         defaultList = filteredData
		//     } else {
		//         defaultList = self.amArchivedProjectList
		//     }

		//     function formatDate(date: string | null) {
		//         if (!date) return "-"
		//         return getTzDate2({
		//             date,
		//             dntFormat,
		//         })
		//     }

		//     defaultList.map((project: any) => {
		//         /**
		//          * Required date information
		//          * 1. Period end date
		//          * 2. Expected report date
		//          * 3. Expected archive deadline date
		//          * 4. Final reprot date
		//          * 5. Final archive deadline date
		//          */
		//         const formattedPeriodEndDate = formatDate(project.periodEndDate)
		//         const formattedExpectedReportDate = formatDate(
		//             project.expectedReportDate
		//         )
		//         const formattedExpectedArchiveDeadlineDate = formatDate(
		//             project.expectedArchiveDate
		//         )
		//         const formattedFinalReportDate = formatDate(
		//             project.finalReportDate
		//         )
		//         const formattedFinalArchiveDeadlineDate = formatDate(
		//             project.finalArchiveDeadlineDate
		//         )
		//         let formattedArchivedDate
		//         if (filteredData) {
		//             const [date, ...time] = project.archivedAt.split(" ")
		//             formattedArchivedDate = {
		//                 date,
		//                 time: time.join(" "),
		//             }
		//         } else {
		//             formattedArchivedDate = formattedDateWithTz2({
		//                 date: project.archivedAt,
		//                 timeZone: dntFormat.timeZone,
		//                 timeFormat: dntFormat.timeFormat,
		//                 dateFormat: dntFormat.dateFormat,
		//             })
		//         }
		//         // --------------------------------------
		//         const formattedLastAccessAt = formatDate(project.lastAccessAt)
		//         // --------------------------------------
		//         const qcUsers = project.qcUsers
		//             .map((user: any) => {
		//                 return user.name
		//             })
		//             .join(",")
		//         const epUsers = project.epUsers
		//             .map((user: any) => {
		//                 return user.name
		//             })
		//             .join(",")
		//         // --------------------------------------
		//         let formattedProject: any = {
		//             "System Id": project.id.includes("projId")
		//                 ? project.id.slice(6)
		//                 : project.id,
		//             "Period Name": project.periodName,
		//             Year: project.financialYear,
		//             Client: project.clientName,
		//             "Project Name": project.title,
		//             "Project ID": project.aliasId,
		//             "Project Version": project.version,
		//             EP: epUsers,
		//             QC: qcUsers,
		//             "Eng Type": project.engTypeName,
		//             // ----- common date informations
		//             "Period End Date": formattedPeriodEndDate,
		//             "Expected Report Date": formattedExpectedReportDate,
		//             "Expected Archive Deadline Date":
		//                 formattedExpectedArchiveDeadlineDate,
		//             "Final Report Date": formattedFinalReportDate,
		//             "Final Archive Deadline Date":
		//                 formattedFinalArchiveDeadlineDate,
		//             // -------------------------
		//             "Replica Status": project.replicaStatus,
		//             "Archive Zip Status": project.archiveZipStatus,
		//             "Archived Date": formattedArchivedDate
		//                 ? formattedArchivedDate.date
		//                 : "",
		//             "Archived Time": formattedArchivedDate
		//                 ? formattedArchivedDate.time
		//                 : "",
		//             "Archived By": project.archivedBy.name,
		//             "Last Access Date": formattedLastAccessAt,
		//             "Last Access By": project.lastAccessBy.name,
		//         }

		//         // if (isAppAdminUser) {
		//         // 	formattedProject = {
		//         // 		...formattedProject,
		//         // 		projectId: idToNumber(project.id, IdType.project),
		//         // 	}
		//         // }
		//         formattedList.push(formattedProject)
		//     })

		//     return formattedList
		// },
	}))
	// Filter related views
	.views(ProjectTableFilterViews)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ArchiveMgmtArchivedProjectsViewModel
