import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import { mdiBullhorn } from "@mdi/js"
import { DLChip } from "../../../components/basic-elements/index"
import styled from "styled-components"
import { DnTFormatProps } from "../../../common-models/types/common-props"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { PostType, Priority, Sector, WhoCanRead } from "@datalobby/types"
import ViewNotificationDialog from "./sub-components/ViewNotificationDialog"
import { Link } from "react-router-dom"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"

export default observer(function GlobalNotisOnDrawer({
	dntFormat,
	userList,
}: {
	dntFormat: DnTFormatProps
	userList: any[]
}) {
	const store = useRootStore()
	// const orgStore = useOrgStore()
	const notiStore = store.notis
	const userId = localStorage.getItem("orgUserId")
	// const dntFormat = store.global.getDntFormat
	// const userList = orgStore.setupUsers.userListForRender

	const [dialogContent, setDialogContent] = useState<PostType>()

	// useEffect(() => {
	// 	const userNeedRefresh = orgStore.setupUsers.needRefresh
	// 	if (userNeedRefresh) {
	// 		orgStore.setupUsers.getUserList()
	// 	}
	// }, [])

	const clickHandler = (type: Sector, id: string) => {
		let data: any
		if (type === Sector.sys) {
			data = notiStore.sysNotiList.find((item: any) => item.id === id)
			if (data) {
				setDialogContent({
					...data,
					sector: Sector.sys,
					parent: "sys",
					priority: "low",
					// whoCanRead: WhoCanRead.all,
					// allowedTargets: [],
					// visitors: [],
					// visitorCount: 0,
				})
			}
		} else {
			data = notiStore.viewOrgNotiItemById(id, dntFormat, userList)

			if (data) {
				setDialogContent(data)
			}
		}

		notiStore.setViewNotificationDialogOpen(true)
	}

	const sysNotis = notiStore.sysNotiList
	const orgNotis = notiStore.orgNotiList
	return (
		<StyledGlobalNotisOnDrawer data-testid="global-notis-container">
			<div
				className="FR AC JC noti-header"
				data-testid="right-drawer-tab-header"
			>
				<Link to="/organization/notifications">
					<h2>Notifications</h2>
				</Link>
			</div>
			{/* <div className="FR JR">
				NOTE: Added Link to the header
					<Link to="/organization/notifications">
						<DLButton
							variant="text"
							eleTestId="move-to-notifications"
							startIcon={<Icon path={mdiArrowLeft} size={1} />}
						>
							Show All Notifications
						</DLButton>
					</Link>
				</div> */}
			<div className="sys-notis">
				{sysNotis.map((noti: any) => {
					const { title, content, createdAt, modifiedAt } = noti
					return (
						<NotiCard
							type={Sector.sys}
							data={noti}
							clickHandler={clickHandler}
						/>
					)
				})}
			</div>
			<div className="org-notis">
				{orgNotis.map((noti: PostType) => {
					return (
						<OrgNotiCard
							type={Sector.org}
							data={noti}
							clickHandler={clickHandler}
							userId={userId}
						/>
					)
				})}
			</div>
			{notiStore.viewNotificationDialogOpen && dialogContent && (
				<ViewNotificationDialog
					open={notiStore.viewNotificationDialogOpen}
					setOpen={notiStore.setViewNotificationDialogOpen}
					userId={userId}
					post={dialogContent}
				/>
			)}
		</StyledGlobalNotisOnDrawer>
	)
})

const NotiCard = ({
	type,
	data,
	clickHandler,
}: {
	type: Sector
	data: any
	clickHandler: (type: Sector, id: string) => void
}) => {
	const { id, title, content, createdAt, modifiedAt, fixToTop } = data
	return (
		<StyledNotiCard onClick={() => clickHandler(type, id)}>
			<div className="FR AC card-title" data-testid="noti-card-title">
				{type === Sector.sys && (
					<DLChip label="System" eleTestId="sys-noti-chip" />
				)}
				<div className="title">{title}</div>
			</div>
			<div className="card-content" data-testid="noti-card-content">
				{content.length > 150 ? content.slice(0, 150) + "..." : content}
			</div>
		</StyledNotiCard>
	)
}

const OrgNotiCard = ({
	type,
	data,
	clickHandler,
	userId,
}: {
	type: Sector
	data: any
	clickHandler: (type: Sector, id: string) => void
	userId: string | null
}) => {
	const {
		id,
		title,
		content,
		createdAt,
		modifiedAt,
		priority,
		// visitors,
		fixToTop,
	} = data
	return (
		<StyledNotiCard onClick={() => clickHandler(type, id)}>
			<div className="FR AC JSB extra-infos">
				<div className="FR AC">
					{fixToTop && (
						<Icon
							path={mdiBullhorn}
							size={0.7}
							className="noti-fixToTop-icon"
							data-testid="noti-fixToTop-icon"
						/>
					)}
					{/* {priority === Priority.high && (
						<DLChip
							size="thin"
							eleTestId="noti-priority"
							label="High"
							color="primary"
						/>
					)}
					{priority === Priority.medium && (
						<DLChip
							size="thin"
							eleTestId="noti-priority"
							label="Medium"
							color="secondary"
						/>
					)} */}
				</div>
				<div>
					{userId && (
						// {userId && !visitors.includes(userId) && (
						<span className="new-mark" data-testid="new-noti-mark">
							New!
						</span>
					)}
				</div>
			</div>
			<div className="FR AC card-title">
				<div className="title" data-testid="noti-card-title">
					{title}
				</div>
			</div>
			<div className="card-content" data-testid="noti-card-title">
				{content.length > 150 ? content.slice(0, 150) + "..." : content}
			</div>
		</StyledNotiCard>
	)
}

export const StyledNotiCard = styled.div`
	padding: 1rem 0.6rem;
	border: 1px solid transparent;
	border-bottom: 1px solid ${(props) => props.theme.shade05};
	transition: 0.3s;
	:hover {
		background-color: ${(props) => props.theme.shade05};
		border-color: ${(props) => props.theme.shade10};
		border-radius: 3px;
	}
	.extra-infos {
		.noti-fixToTop-icon {
			color: ${(props) => props.theme.themeRed};
		}
		.new-mark {
			color: ${(props) => props.theme.themeRed};
		}
	}
	.card-title {
		font-weight: 600;
		margin-bottom: 0.6rem;
		.title {
			margin-left: 0.4rem;
		}
	}
	.card-content {
	}
`

const StyledGlobalNotisOnDrawer = styled.div``
