import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { postFormData } from "../../../../../library/api-requests/request-post-others"
import { getRequest, postRequest } from "../../../../../library/api-requests"

export async function preConditionStatus() {
	const response = await getRequest({
		url: "/PreConditionCheckStatus",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function removePreConditionFiles(payload: {
	ArchivedBy: number
	ClientID: number
	ReplicaClientID: number
}) {
	const response = await postRequest({
		url: "/DeletePreconditionFiles",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function startPreProcess(payload: {
	ArchivedBy: number
	ClientID: number
	IsLocked: boolean
	ReplicaClientID: number
}) {
	// const { ArchivedBy, ClientID, IsLocked, ReplicaClientID } = payload
	const response = await postRequest({
		url: "/PreConditionCheck",
		// url: `/PreConditionCheck?ArchivedBy=${ArchivedBy}&ClientID=${ClientID}&IsLocked=${IsLocked}&ReplicaClientID=${ReplicaClientID}`, // "/PreConditionCheck",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function archiveProject(formData: {
	params: string
	files: File
}) {
	const response = await postFormData({
		url: "/StartArchive",
		formData: formData,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ArchPreProcessApiProps = {
	apiCheckPreConditionStatus: () => Promise<AxiosResponse<any>>
	apiRemovePreConditionFiles: (payload: {
		ArchivedBy: number
		ClientID: number
		ReplicaClientID: number
	}) => Promise<AxiosResponse<any>>
	apiStartPreProcess: (payload: {
		ArchivedBy: number
		ClientID: number
		IsLocked: boolean
		ReplicaClientID: number
	}) => Promise<AxiosResponse<any>>
	apiArchiveProject: (formData: {
		params: string
		files: File
	}) => Promise<AxiosResponse<any>>
}
