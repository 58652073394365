import React, { useState } from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import LibraryFolderRow from "../../../../../../components/combined-elements/tree-nav/DLTreeNavParentRow"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function FileLibFolderRowController({
	node,
}: {
	node: any
}) {
	const orgStore = useOrgStore()
	const moduleId = DLOrgSubMenus.file_lib
	//
	const [showButtons, setShowButtons] = useState(false)
	const handleMouseEnter = () => {
		setShowButtons(true)
	}
	const handleMouseLeave = () => {
		setShowButtons(false)
	}
	//
	const handleClick = () => {
		orgStore.orgFileLib.toggleExpandedTemplateFolder(node.id)
	}
	const handleRightClick = () => {}
	// actions with button
	const handleAdd = () => {
		orgStore.orgFileLib.setSelectedLibraryFolder(node.id)
		orgStore.orgFileLib.setOpenAddLibraryTemplate(true)
	}
	const handleEdit = () => {
		orgStore.orgFileLib.setSelectedLibraryFolder(node.id)
		orgStore.orgFileLib.setOpenEditLibraryFolder(true)
	}
	const handleRemove = () => {
		orgStore.orgFileLib.setSelectedLibraryFolder(node.id)
		orgStore.orgFileLib.setDialogType("Folder")
		orgStore.orgFileLib.setDialogActionType("delete")
		orgStore.orgFileLib.setOpenDeleteAndDuplicateDialog(true)
	}
	const handleDuplicate = () => {
		orgStore.orgFileLib.setSelectedLibraryFolder(node.id)
		orgStore.orgFileLib.setDialogType("Folder")
		orgStore.orgFileLib.setDialogActionType("duplicate")
		orgStore.orgFileLib.setOpenDeleteAndDuplicateDialog(true)
	}
	const handleArrange = () => {}
	//
	const actions = {
		add: { action: handleAdd, tooltipText: "Add a new template" },
		edit: { action: handleEdit, tooltipText: "Edit template folder" },
		remove: { action: handleRemove, tooltipText: "Remove template folder" },
		duplicate: {
			action: handleDuplicate,
			tooltipText: "Duplicate template folder",
		},
		arrange: { action: handleArrange, tooltipText: "Arrange" },
	}

	let rowWidth = orgStore.orgFileLib.viewListWidth - 60
	let canDrag = orgStore.orgFileLib.canDrag
	const permission = orgStore.checkin.getActionsAsObject(moduleId)
	return (
		<StyledLibraryFolderRow
			onClick={handleClick}
			onContextMenu={handleRightClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ width: rowWidth }}
		>
			<LibraryFolderRow
				expanded={node.expanded}
				canDrag={canDrag}
				title={node.title}
				selected={false} // TODO: update is required
				showButtons={showButtons}
				actions={actions}
				permission={permission}
				hasChildren={node.children.length > 0}
			/>
		</StyledLibraryFolderRow>
	)
})

const StyledLibraryFolderRow = styled.div``
