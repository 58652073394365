import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ChecklistRecordType } from "@datalobby/types"

const GetChecklistTemplates = (self: any) => ({
	getChecklistTemplates(params: { orgId: string; id?: string }) {
		const actionName = "getChecklistTemplates"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readChecklistTemplates(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					const data = response.data

					if (params.id) {
						// individual tmeplate detail
						let detail: any = {
							checklistTemplate: {},
							items: [],
						}
						data.map((d: any) => {
							if (d.type === ChecklistRecordType.template) {
								detail.checklistTemplate = {
									assignedRoles: d.assignedRoles
										? d.assignedRoles
										: null,
									createdAt: d.createdAt,
									createdBy: {
										name: "(pending)",
										aliasId: "(pending)",
										id: d.createdBy,
									},
									description: d.description,
									id: d.id,
									lastModifiedAt: d.lastModifiedAt || "",
									lastModifiedBy: d.lastModifiedBy || "",
									name: d.name,
								}
							} else if (d.type === ChecklistRecordType.item) {
								detail.items.push(d)
							} else {
								console.log(
									"LIST cannot exist on this response. Please check the database.",
									d
								)
							}
						})
						self.setChecklistTemplateDetail(detail)
					} else {
						let templates: any[] = []
						data.map((item: any) => {
							templates.push({
								id: item.id,
								name: item.name,
								description: item.description,
								assignedRoles: item.assignedRoles,
								assignedUsers: item.assignedUsers,
								//
								createdBy: item.createdBy,
								createdAt: item.createdAt,
								lastModifiedBy: item.lastModifiedBy,
								lastModifiedAt: item.lastModifiedAt,
							})
						})

						console.log(templates)

						self.setChecklistTemplates(templates)

						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched checklist templates",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					}
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage: "Something's Wrong... ",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetChecklistTemplates
