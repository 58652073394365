import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { ItemListForSortProps } from "../../view-model-actions/file-folder/reorder-children"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

export type DndProps = {
	parentId: string | null // null for root folders
	itemList: ItemListForSortProps
}

/**
 *
 * requestReorder is used for both 'sorting' and 'reorder by dnd'
 * * - project template
 * ----root folder sorting
 * ----children sorting
 * ----root folder dnd
 * ----children dnd
 *  * - wp & pf
 * ----root folder sorting
 * ----children sorting
 * ----root folder dnd
 * ----children dnd
 */
const RequestReorder = (
	self: any,
	reorderChildren: FileSystemApiTypes["apiReorderChildren"]
) => ({
	requestReorder: flow(function* (props: DndProps) {
		const actionName = "requestReorder"
		const menuId = self.storeName
		const { parentId, itemList } = props
		let forRootFolders = parentId === null ? true : false
		// 1.
		//
		//
		// 2.
		let folderNumberId: any = ""
		if (parentId === null) {
			// when rearrange the root folders
		} else {
			// rearrange children
			folderNumberId = idToNumber(parentId, IdType.folder)
		}

		let payload: any = []

		if (menuId !== DLOrgSubMenus.proj_templates) {
			if (parentId === null) {
				// NOTE: WP, PF root folders
				itemList.map((item: any, i: number) => {
					let itemNumId: any = ""
					if (item.id.includes("fileId")) {
						itemNumId = idToNumber(item.id, IdType.file)
					} else {
						itemNumId = idToNumber(item.id, IdType.folder)
					}
					if (typeof itemNumId === "number") {
						const objForApi = {
							ParentFolderID: itemNumId,
							Index: i,
						}
						payload.push(objForApi)
					} else {
						console.error("Source info is invalid.", item)
					}
				})
			} else {
				// NOTE: WP, PF children
				itemList.map((item: any, i: number) => {
					// ConsoleLog("itemList map:::", item)
					let srcNumId
					if (item.id.includes("fileId")) {
						const srcId = self.getSrcIdOfFile(item.id)
						srcNumId = idToNumber(srcId, IdType.src)
					} else {
						srcNumId = idToNumber(item.id, IdType.folder)
					}

					if (
						typeof srcNumId === "number" &&
						typeof folderNumberId === "number"
					) {
						const objForApi = {
							MediaID: srcNumId,
							ParentMediaID: folderNumberId,
							Index: i,
						}
						payload.push(objForApi)
					} else {
						console.error(
							"Source or Folder info is invalid.",
							folderNumberId,
							item.id
						)
					}
				})
			}
		} else if (menuId === DLOrgSubMenus.proj_templates) {
			if (parentId === null) {
				// NOTE: Project template - root folder
				itemList.map((item: any, i: number) => {
					let itemNumId: any = ""
					if (item.id.includes("fileId")) {
						itemNumId = idToNumber(item.id, IdType.file)
					} else {
						itemNumId = idToNumber(item.id, IdType.folder)
					}
					if (typeof itemNumId === "number") {
						const objForApi = {
							ParentFolderID: itemNumId,
							Index: i,
						}
						payload.push(objForApi)
					} else {
						console.error("Source info is invalid.", item)
					}
				})
			} else {
				// NOTE: Project template - children
				itemList.map((item: any, i: number) => {
					// ConsoleLog("itemList map:::", item)
					let itemNumId: any = ""
					if (item.id.includes("fileId")) {
						itemNumId = idToNumber(item.id, IdType.file)
					} else {
						itemNumId = idToNumber(item.id, IdType.folder)
					}
					if (
						typeof itemNumId === "number" &&
						typeof folderNumberId === "number"
					) {
						const objForApi = {
							FileId: itemNumId,
							TemplateFolderId: folderNumberId,
							Index: i,
						}
						payload.push(objForApi)
					} else {
						console.error(
							"Source or Folder info is invalid.",
							folderNumberId,
							item
						)
					}
				})
			}
		} else {
			ConsoleLog("MenuId is not matched")
		}

		//
		//
		// 3.
		try {
			const response = yield reorderChildren({
				payload,
				menuId,
				forRootFolders,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestReorder
