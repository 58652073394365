import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLContextMenu } from "../../../../../../components/basic-elements"
import ProjTemplateFolderCtxMenuOptions from "./folder-ctx-menu-options"
import { initialClickPoint } from "../../../../../../common-models/types/dialog.props"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function ProjTemplateFolderCtxMenu({
	partialStore,
	permission,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()

	const handleClose = () => {
		orgStore.orgProjTemplates.setFolderClickPoint(initialClickPoint)
	}

	return (
		<DLContextMenu
			eleId="proj-templates-folder-ctx-menus"
			clickPoint={{
				mouseX: orgStore.orgProjTemplates.folderClickPoint.mouseX,
				mouseY: orgStore.orgProjTemplates.folderClickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={ProjTemplateFolderCtxMenuOptions(
				partialStore,
				permission
			)}
			// hasDivder={[2]}
		/>
	)
})
