import React from "react"
import styled from "styled-components"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { DLSpinner } from "../../../../../components/basic-elements"
import { Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"

const ArchiveConditionDetailDialogContents = observer(() => {
	const projStore = useProjStore()
	const actionName = "getConditionDetail"

	return (
		<StyledArchiveConditionDetailDialogContents>
			<div className="summary-area FC">
				<div className="FR AC">
					<span className="condition-title">
						{
							projStore.archive.getConditionInfo(
								projStore.archive.selectedConditionId
							)?.title
						}
					</span>{" "}
					needs{" "}
					<span className="item-count">
						{projStore.archive.conditionDetailList.length}
					</span>{" "}
					actions.
				</div>
				<div className="FR AC">
					(Workpapers: {projStore.archive.getRequiredWpLength} /
					Permanent Files: {projStore.archive.getRequiredPfLength})
				</div>
			</div>

			<div className="condition-detail-list">
				{projStore.archive.getActionStatus(actionName) ===
					"LOADING" && <DLSpinner />}
				{projStore.archive.getActionStatus(actionName) ===
					"SUCCESS" && (
					<ReactTableV8
						tableColumns={Columns}
						data={projStore.archive.conditionDetailList}
						customFilters={filterComponent}
						height={500}
						hasPagination={false}
						showPageSetting={false}
						menuId="ArchiveConditionDetailDialog"
					/>
				)}
			</div>
		</StyledArchiveConditionDetailDialogContents>
	)
})

const filterComponent = ({ column }: { column: Column<any> }) => {
	switch (column.id) {
		case "type":
			return (
				<select
					onChange={(event: any) =>
						column.setFilterValue(event.target.value)
					}
					style={{ width: "100%" }}
					value={(column.getFilterValue() ?? "all") as string}
					data-testid="type-select"
				>
					<option value="all">All</option>
					<option value="WP">Workpaper</option>
					<option value="PF">Permanent File</option>
				</select>
			)

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const Columns = [
	{
		header: "Type",
		accessorKey: "type",
		size: 50,
		cell: (props: any) => {
			const aliasId = props.row.original.aliasId
			const cellId = "menuType-cell-" + aliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
		filterFn: (row: any, columnId: any, value: any) => {
			if (value === "all") return true
			else return row.getValue(columnId) === value
		},
	},
	{
		header: "Description",
		accessorKey: "description",
		cell: (props: any) => {
			const aliasId = props.row.original.aliasId
			const cellId = "desc-cell-" + aliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: "Ref.",
		accessorKey: "aliasId",
		cell: (props: any) => {
			const aliasId = props.row.original.aliasId
			const cellId = "aliasId-cell-" + aliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: "File Type",
		accessorKey: "mediaType",
		cell: (props: any) => {
			const aliasId = props.row.original.aliasId
			const cellId = "fileType-cell-" + aliasId
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
]

export default ArchiveConditionDetailDialogContents

const StyledArchiveConditionDetailDialogContents = styled.div`
	.summary-area {
		margin-bottom: 0.6rem;
		.condition-title {
			font-weight: 600;
			color: ${(props) => props.theme.emphasis};
			margin-right: 0.4rem;
		}
		.item-count {
			font-weight: 600;
			color: ${(props) => props.theme.emphasis};
			margin: 0 0.4rem;
		}
	}
	.condition-detail-list {
	}
	.rt-table {
		padding-left: 0rem !important;
	}
`
