// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { getProjIdFromUrl } from "../../../../../../library/api-requests/request-get-others"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetLeadSheetUrl = (self: any) => ({
	getLeadSheetUrl(cabinetId: string, accessRight: number, finYear: number) {
		// 0. set actionName
		const actionName = "getLeadSheetUrl"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		const userId = localStorage.getItem("orgUserId") || ""
		const projId = getProjIdFromUrl()

		if (!projId) {
			console.log("Cannot find the project ID")
			return
		}
		const payload = {
			userNumberId: idToNumber(userId, IdType.user),
			projNumberId: idToNumber(projId, IdType.project),
			finyear: finYear,
			cabinetid: idToNumber(cabinetId, IdType.cabinet),
			accessRight: accessRight,
		}
		// 3) request API call to Model
		self.readLeadSheetUrl(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						// ----- default
						// let accessRight
						// if (
						// 	projInfo.AccessRights === "ReadOnly" ||
						// 	projInfo.Archive
						// ) {
						// 	accessRight = 1
						// } else if (
						// 	projInfo.AccessRights === "PBC Upload" ||
						// 	projInfo.RoleName == "PBC Upload"
						// ) {
						// 	accessRight = 2
						// } else if (projInfo.AccessRights == "Project User") {
						// 	accessRight = 3
						// } else {
						// 	accessRight = 0
						// }

						self.setUrl(response.data.URL)
						//
						self.setNeedRefresh(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully get the lead sheet",
							color: MessageColorType.blue,
							open: false,
							autoHide: true,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetLeadSheetUrl
