import React from "react"
import OrgHeader from "./header/OrgHeader"
import { OrgSidebar } from "./sidebars"

// WARNING: Have to remove
import { useTheme } from "@material-ui/core/styles"
import { useRootStore } from "../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
// sub components
import { StyledAppFrame, AppFrameStyles } from "./app-frame.style"
import DLFixedTabsController from "./app-frame-tabs/DLFixedTabsController"
import RightDrawer from "./global-drawer/RightDrawer"
import HelpInformationDrawer from "./sub-components/HelpInformationDrawer"
// style
import StyledDLMenuTabs from "./dl-menu-tabs.style"
import { ConsoleLog } from "../basic-elements"
import { useOrgStore } from "../../stores/org-store/org-store.provider"
import { Helmet } from "react-helmet"

/**
 * You can find this component on the OrgRouter and ProjRouter
 */

interface AppFrameProps {
	children: any
}

export default observer(function OrgSideAppFrame({ children }: AppFrameProps) {
	// re-rendering check
	ConsoleLog("** OrgSideAppFrame ******************", "")

	const store = useRootStore()
	const orgStore = useOrgStore()
	const dntFormat = store.global.getDntFormat
	const userList = orgStore.setupUsers.userListForRender
	const theme = useTheme()
	const classes = AppFrameStyles(theme)

	// FIXME: private router should be implemented overall area (current, just a '/' router is wrapped by private router)
	// FIXME: also private router need to be updated

	const showTabs = store.ui.showTabs

	return (
		<div>
			<Helmet>
				<meta charSet="utf-8" />
				<title>Audit Lobby</title>
			</Helmet>
			<StyledAppFrame className={classes.root}>
				<div>
					<OrgSidebar />
					{/* <RightDrawer
							dntFormat={dntFormat}
							userList={userList}
						/> */}
					<HelpInformationDrawer />
				</div>

				<div
					className={
						store.ui.isSidebarOpen
							? classes.contentContainer
							: classes.contentContainerShift
					}
				>
					<OrgHeader />
					{showTabs && (
						<StyledDLMenuTabs className="page-header FR AC JSB">
							<DLFixedTabsController />
							{/* TODO: pageToolbar should be moved to page container */}
						</StyledDLMenuTabs>
					)}
					{/* <hr /> */}
					{children}
				</div>
			</StyledAppFrame>
		</div>
	)
})
