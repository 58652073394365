import { types } from "mobx-state-tree"
import {
	PriorityType,
	DLCommentType,
	DLSrcFormat,
} from "../../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export type CommentModelProps = {
	id: string
	// createdAt: string
	createdBy: {
		id?: string
		aliasId?: string
		name?: string
	}
	// createdUser: string
	// modifiedAt?: string
	// modifiedBy?: string
	objectId?: string // file ID
	objectName: string
	objAliasId: string
	srcFormat: DLSrcFormat
	srcId: string
	//
	type: DLCommentType
	menuId: DLProjSubMenus
	priority: PriorityType
	assigneeIds?: string[]
	assigneeNames?: string[]
	//
	contents?: string
	response?: string | null
	//
	status: CommentStatus
	closedBy: string | null
	closedAt: string | null
	createdAt: string | null
}

export enum CommentStatus {
	notExist = "NOT_EXIST",
	todo = "TODO",
	inprogress = "IN_PROGRESS",
	done = "DONE",
	unknown = "UNKNOWN",
}

export const CommentModel = types.model({
	id: types.string, // [ReviewCommentsId] ex) 506
	// createdAt
	createdBy: types.model({
		id: "",
		aliasId: "",
		name: "",
	}), // [CreatedBy] ex) 16
	// createdUser: "", // [CreatedUser] ex) "김철수" --> Let's try to use the name on the proj user list
	// modifiedAt?: string
	// modifiedBy?: string
	objectId: "", // get API doesn't returns this value but add API returns this one --> request to update to backend side
	objectName: types.string, // [WPDesc] ex) "Test Source A"
	objAliasId: types.string, // [WPREF] ex) "1111-A" --> How about to use wp list information on the store?
	srcFormat: types.enumeration<DLSrcFormat>(Object.values(DLSrcFormat)), // [MediaType] ex) "Excel"
	srcId: types.string, // [MediaID] ex) 275200
	//
	type: types.enumeration<DLCommentType>(Object.values(DLCommentType)), // [Type] ex) "QC Comment"
	menuId: types.enumeration<DLProjSubMenus>(Object.values(DLProjSubMenus)),
	priority: types.enumeration<PriorityType>(Object.values(PriorityType)), // [Priority] ex) "Medium"
	assigneeIds: types.optional(types.array(types.string), []), // [AssignedTo] ex) "16"
	assigneeNames: types.optional(types.array(types.string), []), // [AssignedUser] ex) "김철수"
	//
	contents: "", // [Comment] ex) "blah blah... "
	response: types.union(types.null, types.string), // [Response] ex) ""
	//
	status: types.enumeration<CommentStatus>(Object.values(CommentStatus)),
	closedBy: types.union(types.null, types.string), // [Signoff] ex) "" --> ??? userID? or.. message?
	closedAt: types.union(types.null, types.string), // [SignOffDate] ex) null
	createdAt: types.union(types.null, types.string), // [ReviewDate] ex) "2020-01-08T01:52:59.498527+00:00"
	// ? isOpen: types.xxx, // [IsOpen] ex) false --> comment? source?
	// ? openedBy: types.xxx, // [OpenedBy] ex) "" --> comment? source?
	// Extension: types.xxx // [Extension] ex) ".docx"
})

export const SimpleCommentModel = types.model({
	id: "",
	type: types.enumeration<DLCommentType>(Object.values(DLCommentType)), // [Type] ex) "QC Comment"
	assignees: types.array(types.string),
	createdBy: "", // user name
	createdAt: "",
	contents: "",
	response: "",
})

export type SimpleCommentProps = {
	id: string
	type: DLCommentType
	assignees: string[]
	createdBy: string
	createdAt: string
	contents: string
	response: string
}
