import {
	ActionStatus,
	DLObjectFormat,
} from "../../../../common-models/enumerations/common-enums"
import { IdType, idToString } from "../../../../library/converters/id-converter"
import { initialDLFileAsFolder } from "../../data-models/initial-file"
import { reorganizeFile } from "../file/util-reorganize-file"

function filterArray(array: any, parentId: string) {
	const descendants = array.filter((item: any) => {
		return (
			item.parentId === parentId ||
			array.some(
				(child: any) =>
					child.parentId === parentId && item.parentId === child.id
			)
		)
	})

	let moreDescendants: any = []
	if (descendants.length > 0) {
		descendants.forEach((descendant: any) => {
			const childDescendants = filterArray(array, descendant.id)
			moreDescendants = moreDescendants.concat(childDescendants)
		})
	}

	return descendants.concat(moreDescendants).filter((item: any) => {
		return item.expanded === false && item.isParent === true
	})
}

const RelayGetChildrenOfFolder = (self: any) => ({
	relayGetChildrenOfFolder(index: number = 0, subFolderId?: string) {
		const actionName = "relayGetChildrenOfFolder"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		/**
		 *  WARNING: Do not use object for parameter or update projTemplate together.
		 * This part must take same structure with project template
		 */

		let folders
		if (subFolderId) {
			folders = filterArray(self.flatList, subFolderId)
		} else {
			folders = self.flatList.filter(
				(item: any) =>
					item.isParent && !item.expanded && item.parentId === null
			)
		}
		const delay = 0 // 1000 = 1s
		console.log(
			"-----index:",
			index,
			subFolderId,
			"/folders length:",
			folders.length,
			JSON.parse(JSON.stringify(folders)),
			"/delay:",
			delay
		)

		if (folders.length > 0) {
			const folder = folders[0]
			const folderId = folder.id
			self.toggleExpanded(folderId, true)

			console.log("target folder:", folderId)

			const actionNameForUiDetail = "getChildrenOfFolder" + folderId
			self.responses.setResponse(actionNameForUiDetail, {
				actionName: actionNameForUiDetail,
				status: ActionStatus.loading,
			})

			self.readChildrenOfFolder(folderId, self.storeName)
				.then((response: any) => {
					// NOTE: This API returns an array not an object
					// NOTE: and those individual item has Status
					// NOTE: So, we CANNOT USE the response.data.Status here.
					// NOTE: DO NOT ADD response.data.Status as condition
					if (response.status === 200) {
						response.data.map((item: any) => {
							// 'Child' means folders
							// 'Fileslist' means files
							const fetchedSubFolders = item.Child
							const fetchedFiles = item.fileslist
							//
							let children: any[] = []
							// 1) folders
							if (fetchedSubFolders.length > 0) {
								fetchedSubFolders.map(
									(folder: any, index: number) => {
										const subFolderId = idToString(
											folder.id,
											IdType.folder
										)

										const reOrganizedSubFolder = {
											...initialDLFileAsFolder,
											menuId: self.storeName,
											id: subFolderId,
											title: folder.title,
											objectFormat: DLObjectFormat.folder,
											parentId: folderId,
											expanded: false,
											index,
										}

										children.push(reOrganizedSubFolder)
									}
								)
							}
							// 2) files
							if (fetchedFiles.length > 0) {
								fetchedFiles.map(
									(fetchedFile: any, index: number) => {
										children.push(
											reorganizeFile({
												fetchedFile,
												index,
												menuId: self.storeName,
												expanded: false, // NOTE: Need to check one more time this attr for files
												folderId,
											})
										)
									}
								)
							}

							if (
								fetchedSubFolders.length === 0 &&
								fetchedFiles.length === 0
							) {
								self.resetPartialFlatList(folderId)
							} else {
								self.updateFlatList(children)
							}
						})
						self.responses.setResponse(actionNameForUiDetail, {
							actionName: actionNameForUiDetail,
							status: ActionStatus.success,
						})
						// NOTE: DO NOT Place below part in the map
						// NOTE: There is a weird exception that some folders return empty array as response.data
						// const updatedFolders = self.flatList.filter(
						// 	(item: any) => item.isParent && !item.expanded
						// )
						// console.log("updatedFolders", updatedFolders)
						// if (updatedFolders.length > 0) {
						setTimeout(() => {
							self.relayGetChildrenOfFolder(
								index + 1,
								subFolderId
							)
						}, delay)
						// } else if (updatedFolders.length === 0) {
						// self.responses.setResponse(actionName, {
						// 	actionName,
						// 	status: ActionStatus.success,
						// })
						// }
					} else {
						// set fail case response
						console.log(
							index,
							"failed (type1) to get realy get children of folder for",
							folderId
						)
						self.responses.setResponse(actionNameForUiDetail, {
							actionName: actionNameForUiDetail,
							status: ActionStatus.fail,
						})
						// const updatedFolders = self.flatList.filter(
						// 	(item: any) => item.isParent && !item.expanded
						// )
						// console.log("updatedFolders", updatedFolders)
						// if (updatedFolders.length > 0) {
						setTimeout(() => {
							self.relayGetChildrenOfFolder(
								index + 1,
								subFolderId
							)
						}, delay)
						// } else if (updatedFolders.length === 0) {
						// 	self.responses.setResponse(actionName, {
						// 		actionName,
						// 		status: ActionStatus.success,
						// 	})
						// }
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: true,
					})
					console.log(
						index,
						"failed (type2) to get realy get children of folder for",
						folderId
					)
					self.responses.setResponse(actionNameForUiDetail, {
						actionName: actionNameForUiDetail,
						status: ActionStatus.fail,
					})
					const updatedFolders = self.flatList.filter(
						(item: any) => item.isParent && !item.expanded
					)
					console.log("updatedFolders", updatedFolders)
					if (updatedFolders.length > 0) {
						setTimeout(() => {
							self.relayGetChildrenOfFolder(
								index + 1,
								subFolderId
							)
						}, delay)
					} else if (updatedFolders.length === 0) {
						self.responses.setResponse(actionName, {
							actionName,
							status: ActionStatus.success,
						})
					}
				})
		} else {
			console.log("There is no more closed folder")

			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.success,
			})
		}
	},
})

export default RelayGetChildrenOfFolder
