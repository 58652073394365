import React from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { DLButton } from "../../../../components/basic-elements"
import styled from "styled-components"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import EliminatingEntryTable from "./sub-components/EliminatingEntryTable"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import AddEliminatingEntryDialog from "./sub-components/dialogs/AddEliminatingEntryDialog"
import EliminatingEntryTableMenus from "./sub-components/EliminatingEntryTableContextMenu"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import EditEliminatingEntryDialog from "./sub-components/dialogs/EditEliminatingEntryDialog"

export default observer(function EliminatingEntry({
	partialStore,
	contentsHeight,
	fetchingStatus,
	permission,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
}) {
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			isLoading={fetchingStatus === ActionStatus.loading}
		>
			{fetchingStatus === ActionStatus.success && (
				<StyledEliminatingEntry>
					<PageHeader>
						<BasicCounter
							label="Total :"
							value={partialStore.eeDetails.length}
						/>

						<div className="FR AC">
							{permission.create && (
								<DLButton
									variant="text"
									eleTestId="add-ee-btn"
									startIcon={<Icon path={mdiPlus} size={1} />}
									clickHandler={() =>
										partialStore.setAddEliminatingEntryDialogOpen(
											true
										)
									}
									color="primary"
								>
									Add
								</DLButton>
							)}
						</div>
					</PageHeader>
					<EliminatingEntryTable
						permission={permission}
						contentHeight={contentsHeight}
					/>
				</StyledEliminatingEntry>
			)}
			{/* ----Snackbar---- */}
			<CommonSnackbarComponent
				i18n={{ x: "x" }}
				partialStore={partialStore}
			/>
			{/* ----context menu---- */}
			<EliminatingEntryTableMenus permission={permission} />
			{/* ----Dialog---- */}
			{partialStore.addEliminatingEntryDialogOpen && (
				<AddEliminatingEntryDialog />
			)}
			{partialStore.editEliminatingEntryDialogOpen && (
				<EditEliminatingEntryDialog />
			)}
		</PageContainer>
	)
})

const StyledEliminatingEntry = styled.div``
