import { flow } from "mobx-state-tree"

const ReadPreConditionStatus = (self: any, preConditionStatus: any) => ({
	readPreConditionStatus: flow(function* () {
		const actionName = "readPreConditionStatus"
		try {
			const response = yield preConditionStatus()

			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName, '__error', error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadPreConditionStatus
