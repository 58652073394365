import { types, flow } from "mobx-state-tree"
import { FSSetupApiProps } from "./fs-setup.apis"
import ReadFinancialStatementType from "./model-actions/read-financial-statement-type"
import ReadFinancialGroupByFSType from "./model-actions/read-financial-group-by-fs-type"
import ReadFinSubGroupsByFinGroup from "./model-actions/read-fin-sub-groups-by-fin-group"
import ReadLeadSheetBySubGroup from "./model-actions/read-lead-sheets-by-sub-group"
import ReadAvailableFinGroups from "./model-actions/read-available-fin-groups"
import CreateFinancialGroup from "./model-actions/create-financial-group"
import MUnmapGroupFromFSType from "./model-actions/model-unmap-group-from-fs-type"
import ModelMapGroupToFSType from "./model-actions/model-map-group-to-fs-type"
import DeleteFinancialGroup from "./model-actions/delete-financial-group"
import ReadAvailableFinSubGroups from "./model-actions/read-available-fin-sub-groups"
import CreateFinancialSubGroup from "./model-actions/create-financial-sub-group"
import DeleteFinancialSubGroup from "./model-actions/delete-financial-sub-group"
import MUnmapSubGroupFromGroup from "./model-actions/model-unmap-sub-group-from-group"
import ModelMapSubGroupToGroup from "./model-actions/model-map-sub-group-to-group"
import ReadAvailableLeadSheets from "./model-actions/read-available-lead-sheets"
import MUnmapLeadSheetFromSubGroup from "./model-actions/model-unmap-lead-sheet-from-sub-group"
import ModelMapLeadSheetToSubGroup from "./model-actions/model-map-lead-sheet-to-sub-group"

const FSSetupModel = ({
	apiRead,
	apiReadFSGroups,
	apiReadFSSubGroups,
	apiReadLeadSheets,
	apiReadAvailableGroups,
	apiCreateFinGroup,
	apiUnmapGroup,
	apiMapGroup,
	apiDelFinGroup,
	apiReadAvailableSubGroups,
	apiCreateFinSubGroup,
	apiDelFinSubGroup,
	apiUnmapSubGroup,
	apiMapSubGroup,
	apiReadAvaLeadSheets,
	apiUnmapLeadSheet,
	apiMapLeadSheet,
}: FSSetupApiProps) =>
	types
		.model({})
		.actions((self) => ({
			// ------ Read
			// ------ Update
		}))
		.actions((self, read = apiRead) =>
			ReadFinancialStatementType(self, read)
		)
		.actions((self, readFSGroups = apiReadFSGroups) =>
			ReadFinancialGroupByFSType(self, readFSGroups)
		)
		.actions((self, readFSSubGroups = apiReadFSSubGroups) =>
			ReadFinSubGroupsByFinGroup(self, readFSSubGroups)
		)
		.actions((self, readLeadSheets = apiReadLeadSheets) =>
			ReadLeadSheetBySubGroup(self, readLeadSheets)
		)
		.actions((self, readAvailableGroups = apiReadAvailableGroups) =>
			ReadAvailableFinGroups(self, readAvailableGroups)
		)
		.actions((self, createFinGroup = apiCreateFinGroup) =>
			CreateFinancialGroup(self, createFinGroup)
		)
		.actions((self, unmapGroup = apiUnmapGroup) =>
			MUnmapGroupFromFSType(self, unmapGroup)
		)
		.actions((self, mapGroup = apiMapGroup) =>
			ModelMapGroupToFSType(self, mapGroup)
		)
		.actions((self, delFinGroup = apiDelFinGroup) =>
			DeleteFinancialGroup(self, delFinGroup)
		)
		.actions((self, delFinSubGroup = apiDelFinSubGroup) =>
			DeleteFinancialSubGroup(self, delFinSubGroup)
		)
		.actions((self, readAvailableSubGroups = apiReadAvailableSubGroups) =>
			ReadAvailableFinSubGroups(self, readAvailableSubGroups)
		)
		.actions((self, createFinSubGroup = apiCreateFinSubGroup) =>
			CreateFinancialSubGroup(self, createFinSubGroup)
		)
		.actions((self, unmapSubGroup = apiUnmapSubGroup) =>
			MUnmapSubGroupFromGroup(self, unmapSubGroup)
		)
		.actions((self, mapSubGroup = apiMapSubGroup) =>
			ModelMapSubGroupToGroup(self, mapSubGroup)
		)
		.actions((self, readAvaLeadSheets = apiReadAvaLeadSheets) =>
			ReadAvailableLeadSheets(self, readAvaLeadSheets)
		)
		.actions((self, unmapLeadSheet = apiUnmapLeadSheet) =>
			MUnmapLeadSheetFromSubGroup(self, unmapLeadSheet)
		)
		.actions((self, mapLeadSheet = apiMapLeadSheet) =>
			ModelMapLeadSheetToSubGroup(self, mapLeadSheet)
		)

export default FSSetupModel
