import { flow } from "mobx-state-tree"

const ReadAdjustments = (self: any, read: any) => ({
	readAdjustments: flow(function*(payload: {
		ClientId: string
		// CabinetId: string
		// FinancialYear: string
		// Lang: string
		TBID: number
	}) {
		const actionName = "readAdjustments"
		try {
			const response = yield read(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadAdjustments
