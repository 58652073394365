import {
	ActionStatus,
	MessageColorType,
	DLCommentType,
} from "../../../../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import reOrganizeComment from "./util-reorganize-comment"

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const GetComments = (self: any) => ({
	getComments(
		type: DLCommentType | "all",
		projId: string,
		menuId: DLProjSubMenus
	) {
		// 0.
		const actionName = "getComments" + menuId
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		self.readComments({
			projId,
			type,
			menuId,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					const fetchedComments = response.data.ReviewCommentsList

					// NOTE:
					// Table name seems like "ReviewComments"
					// and it has 'Type' column as string..
					// Discussion is required
					fetchedComments.map((fetchedComment: any) => {
						// ConsoleLog("fetchedComment for get", fetchedComment)
						const newFetchedComment = {
							...fetchedComment,
							// below values are undefined or null in the API response
							WPRID: null,
						}
						const reOrganizedComment = reOrganizeComment({
							fetchedComment: newFetchedComment,
							menuId,
						})
						self.pushItem(reOrganizedComment)
					})
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					// set fail case reponse
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetComments
