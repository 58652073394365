import { flow } from "mobx-state-tree"

const ReadPassedAdjustments = (self: any, read: any) => ({
	readPassedAdjustments: flow(function*(payload: {
		clientId: number
		// CabinetId: string
		// FinancialYear: string
		// Lang: string
		tbnameId: number
	}) {
		const actionName = "readPassedAdjustments"
		try {
			const response = yield read(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadPassedAdjustments
