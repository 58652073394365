import React from "react"
import styled from "styled-components"
import { DLIconButton } from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import {
	mdiClose,
	mdiCheckboxMarkedCircleOutline,
	mdiChevronDown,
	mdiChevronUp,
	mdiUnfoldMoreHorizontal,
	mdiDotsHorizontal,
} from "@mdi/js"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"

export default observer(function RoleDetailRow({
	menu,
	query,
}: {
	menu: any
	query: undefined | string
}) {
	return (
		<>
			{menu.title.toLowerCase().search(query) >= 0 && (
				<RenderRow menu={menu} />
			)}

			{/* sub menus  */}
			{menu.collapsed ? (
				<div />
			) : (
				menu.subMenus.map((subMenu: any) => {
					return (
						<div key={subMenu.menuId}>
							{subMenu.title.toLowerCase().search(query) >=
								0 && <RenderRow menu={subMenu} />}
						</div>
					)
				})
			)}
		</>
	)
})

const RenderRow = observer(({ menu }: { menu: any }) => {
	const orgStore = useOrgStore()
	// ConsoleLog("menu in RenderRow row:", menu)
	return (
		<StyledRenderRow
			className={`menu-row FR AC JSB ${
				menu.menuType === "MENU_GROUP" ? "menu-group" : "sub-menu"
			}`}
		>
			<div className="left-side FR AC">
				<div className="menu-name FR AC">
					{menu.menuType === "MENU_GROUP" &&
					menu.subMenus.length > 0 ? (
						<DLIconButton
							eleTestId="open-sub-menus"
							clickHandler={() =>
								orgStore.orgRoles.toggle(menu.menuId)
							}
						>
							{menu.collapsed ? (
								<Icon path={mdiChevronDown} size={0.6} />
							) : (
								<Icon path={mdiChevronUp} size={0.6} />
							)}
						</DLIconButton>
					) : (
						<DLIconButton eleTestId="placeholder" disabled />
					)}
					{menu.menuType === "SUB_MENU" && "- "}
					{menu.title}
				</div>

				<div className="permissions FR">
					<div className="cell-access">
						<AvailableCheckbox
							available
							// available={menu.actions.find(
							// 	(element: any) =>
							// 		element.actionName === "read"
							// )}
						/>
					</div>

					<AvailableCheckbox
						available
						// available={menu.actions.find(
						// 	(element: any) =>
						// 		element.actionName === "create"
						// )}
					/>

					<AvailableCheckbox
						available
						// available={menu.actions.find(
						// 	(element: any) =>
						// 		element.actionName === "update"
						// )}
					/>

					<AvailableCheckbox
						available
						// available={menu.actions.find(
						// 	(element: any) =>
						// 		element.actionName === "delete"
						// )}
					/>

					<AvailableCheckbox
						available
						// available={menu.actions.find(
						// 	(element: any) =>
						// 		element.actionName === "update"
						// )}
					/>
				</div>
			</div>
			<div className="right-side">
				<DLIconButton eleTestId="context-menu-for-individual-menu">
					<Icon path={mdiDotsHorizontal} size={0.6} />
				</DLIconButton>
			</div>
		</StyledRenderRow>
	)
})

const StyledRenderRow = styled.div`
	&.menu-row {
		height: 3rem;
		&.menu-group {
			border-top: 1px solid ${(props) => props.theme.shade20};
			border-bottom: 1px solid ${(props) => props.theme.shade05};
			.menu-name {
				font-weight: 700;
			}
		}
		&.sub-menu {
			border-bottom: 1px solid ${(props) => props.theme.shade05};
			.menu-name {
				padding-left: 1.6rem;
				color: ${(props) => props.theme.shade60};
			}
		}
		.menu-name {
			width: 14rem;
		}

		.permissions {
			.cell-access {
				background-color: ${(props) => props.theme.secondary};
			}
			.role-checkbox {
				width: 5rem;
				height: calc(${(props) => props.theme.shared.baseUnit} - 2px);
				&.disabled {
					opacity: 0.2;
				}
			}
		}
	}
`

const AvailableCheckbox = ({ available }: { available: any }) => {
	return (
		<div
			className={`role-checkbox FC JC AC ${available.available === null &&
				"disabled"}`}
			key={available.actionName}
		>
			{/* <span>{available.actionName}</span> */}
			{/* <DLCheckbox
                color="primary"
                eleTestId={`checkbox-${available.actionName}`}
            />
             */}
			{available.available === null ? (
				<div>-</div>
			) : available.available === true ? (
				<Icon
					path={mdiCheckboxMarkedCircleOutline}
					size={0.8}
					color="blue"
				/>
			) : available.available === false ? (
				<Icon path={mdiClose} size={0.8} />
			) : (
				<div />
			)}
		</div>
	)
}
