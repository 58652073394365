import { types } from "mobx-state-tree"
import { SupportedLanguage } from "../../../common-models/enumerations/common-enums"
import { ModuleDictionary } from "./data-models/i18n-store.data-model"
import ResponseSnackbar from "../../../common-models/response-snackbar"
import { Responses } from "../../../common-models"
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../common-models/common-view-model-actions"
import EditUesrLanguage from "./view-model-actions/edit-user-language"
import { getStaticDict } from "./view-model-actions/get-static-dict"
import { GlobalSheet } from "../../../common-models/enumerations/translation-sheets"
import { CombineI18n } from "./view-model-actions/i18n-utils"

/**
 * ----- prefix of the key -----
 * tw: translated word
 * ts: translated sentence
 * tp: translated paragraph
 *
 */

export type TranslationsProps = {
	[x: string]: {
		language: string
		values: { key: string; value: string }[]
	}
}

const I18nViewModel = types
	.model({
		country: "UK",
		language: types.enumeration<SupportedLanguage>(
			Object.values(SupportedLanguage)
		),
		//
		//
		integrated: types.map(ModuleDictionary),
		// global: types.map(ModuleDictionary),
		// lobby: types.map(ModuleDictionary),
		// org: types.map(ModuleDictionary),
		// proj: types.map(ModuleDictionary),
		//
		// ----- common models
		responseSnackbar: ResponseSnackbar,
		responses: Responses,
	})
	.actions((self) => ({
		setCountry(country: string) {
			self.country = country
		},
		setLanguage(newLanguage: SupportedLanguage) {
			self.language = newLanguage
		},
		setModuleDictionary(moduleId: string, translations: TranslationsProps) {
			/**
			 * * ----- to save on the store (it requires corret data model)
			 * * ----- You can re-organize this as a view after saving
			 * {
			 *     key: moduleId,
			 *     translations: {
			 *         {
			 * 			    lang: enUS,
			 * 				values: [
			 * 					{ key: twLogin, value: "Login"}
			 *              ]
			 *         }
			 *     }
			 * }
			 *
			 */

			self.integrated.set(moduleId, { moduleId, translations })
		},
	}))
	.actions((self) => ({
		useSetLanguage(language: string) {
			if (language === "en") {
				self.setLanguage(SupportedLanguage.us)
			} else if (language === "kr") {
				self.setLanguage(SupportedLanguage.kr)
			} else {
				self.setLanguage(SupportedLanguage.us)
			}
		},
	}))
	.views(CombineI18n)
	.views((self) => ({
		byModule(moduleId: string, lang: SupportedLanguage, where?: string) {
			/**
			 * * Requirements
			 * - make an object to use on the module with fetched data
			 * - if there are empty value (empty cell), take that part from the static data
			 * - if there are no static data, use the key instead of the value
			 */
			const language = lang.split("-").join("")
			//
			const fetchedValue = self.integrated.get(moduleId)
			const staticValue = getStaticDict(
				moduleId,
				self.language,
				"byModule-" + where
			)

			let result: { [x: string]: string } = {}
			//
			if (fetchedValue) {
				const currentLangDict = fetchedValue.translations.get(language)
				currentLangDict?.values.map((item: any) => {
					const key = item.key
					const value =
						item.value !== ""
							? item.value
							: staticValue[item.key]
							? staticValue[item.key]
							: item.key
					result[key] = value
				})
				// == "" ? item.value : defaultModule?.find(default => default.key === item.key).value
			} else if (fetchedValue === undefined && staticValue) {
				result = staticValue
			} else if (
				fetchedValue === undefined &&
				staticValue === undefined
			) {
				console.error(
					"(i18n byModule) fetched and static i18n data are undefined"
				)
			}
			return result
		},
		/**
		 *
		 * @param where  this where is just to check the request location... Not mandatory!
		 * @returns
		 */
		globalI18n(where?: string) {
			const sheetId = GlobalSheet.common
			// same as byModule but use the fixed values
			const language = self.language.split("-").join("")
			const fetchedValue = self.integrated.get(sheetId)
			const staticValue = getStaticDict(
				sheetId,
				self.language,
				"globalI18n" + where
			)
			let result: { [x: string]: string } = {}
			//
			if (fetchedValue) {
				const currentLangDict = fetchedValue.translations.get(language)
				currentLangDict?.values.map((item: any) => {
					const key = item.key
					const value =
						item.value !== ""
							? item.value
							: staticValue[item.key]
							? staticValue[item.key]
							: item.key
					result[key] = value
				})
				// == "" ? item.value : defaultModule?.find(default => default.key === item.key).value
			} else if (fetchedValue === undefined && staticValue) {
				result = staticValue
			} else if (
				fetchedValue === undefined &&
				staticValue === undefined
			) {
				console.error(
					"(globalI18n) fetched and static i18n data are undefined"
				)
			}
			return result
		},
	}))
	.actions(EditUesrLanguage)
	//
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default I18nViewModel
