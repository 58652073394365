import { flow } from "mobx-state-tree"

const UpdateProjUser = (self: any, update: any) => ({
	updateProjUser: flow(function* (params) {
		const actionName = "updateProjUser"
		try {
			const response = yield update(params)
			// console.log(actionName, "__response: ", response)

			return response
		} catch (error) {
			// console.log(actionName, "__error: ", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default UpdateProjUser
