import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { RolePermissionProps } from "../../../../../service-modules/role-module/roles.data-model"
import combineRoleAndPermission from "../../../../../library/combine-role-and-permissions"

const GetProjRoleDetails = (self: any) => ({
	getAllProjDetails(projMenus: any) {
		// 0) set actionName
		const actionName = "getAllProjDetails"
		// 1)
		// 2) put response with loading type status
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) call API through the Model
		self.readProjRoleDetails()
			.then((response: RolePermissionProps[]) => {
				if (response) {
					// ConsoleLog("getAllProjDetails response", response)
					//
					const roleDetails = combineRoleAndPermission(
						self.roleSet,
						response,
						projMenus
					)
					self.setRoleDetails(roleDetails)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999, // temp
						customMessage:
							"Successfully get the project role detail",
						color: MessageColorType.blue,
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetProjRoleDetails
