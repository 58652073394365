import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import ReactTableV8 from "../../../../../../components/basic-elements/tables/DLReactTable"

export default observer(function ProgressRateDetailTable({
	progressData,
}: {
	progressData: any
}) {
	const store = useRootStore()
	const contentHeight = store.ui.contentsAreaHeight

	return (
		<ReactTableV8
			tableColumns={Columns}
			showPageSetting={false}
			height={contentHeight}
			data={progressData}
			hasPagination={true}
			menuId="ProgressRateDetailTable"
		/>
	)
})

function convert(text: string) {
	return text.toLowerCase().replace(/ /g, "-")
}

const renderCustomCell = (props: any) => {
	const labelForTest = convert(props.row.original.label)
	const cellId = `${labelForTest}-${props.cell.column.id}-cell`
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const Columns = [
	{
		header: "Status",
		accessorKey: "label",
		cell: renderCustomCell,
	},
	{
		header: "Total",
		accessorKey: "total",
		cell: renderCustomCell,
	},
	{
		header: "Timely",
		accessorKey: "timely",
		cell: renderCustomCell,
	},
	{
		header: "Late",
		accessorKey: "late",
		cell: renderCustomCell,
	},
	{
		header: "?",
		accessorKey: "cannotCalculate",
		cell: renderCustomCell,
	},
]
