import { flow } from "mobx-state-tree"

const MUnmapGroupFromFSType = (self: any, unmapGroup: any) => ({
	mUnmapGroupFromFSType: flow(function*(payload: { MapId: number }) {
		const actionName = "MUnmapGroupFromFSType"
		try {
			const response = yield unmapGroup(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default MUnmapGroupFromFSType
