import React, { useState, useCallback } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLButton,
} from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import CreateArchivePolicyForm from "./CreateArchivePolicyForm"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function CreateArchivePolicyDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "addArchivePolicy"
	//
	const [inputs, setInputs] = useState({ title: "", description: "" })
	let inputReady = inputs.title !== "" // && inputs.description !== "" // description is option
	//
	const handleInputs = useCallback((event: any) => {
		let name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
	}, [])
	//
	const handleAdd = () => {
		orgStore.archPolicy.addArchivePolicy(inputs.title, inputs.description)
	}

	return (
		<DLDialog
			eleTestId="add-arch-policy-dialog"
			isOpen={orgStore.archPolicy.openCreateDialog}
			setIsOpen={orgStore.archPolicy.setOpenCreateDialog}
			handleAction={handleAdd}
			showCloseBtn={true}
			dialogTitle={i18n.twAddArchPolicy || "Add Archive Policy"}
			dialogContents={
				<CreateArchivePolicyForm
					inputs={inputs}
					handleInputs={handleInputs}
					i18n={i18n}
					actionName={actionName}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				inputReady &&
				!orgStore.archPolicy.isDuplicatedTitle(inputs.title)
			}
			actionBtn={i18n.twAdd || "Add"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.archPolicy.responses.getResponse(actionName)?.message
			}
			showSpinner={
				orgStore.archPolicy.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.archPolicy.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
