import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const RestoreProjTrash = (self: any) => ({
	restoreProjTrashItem(itemId: string) {
		// 0. set actionName
		const actionName = "restoreProjTrashItem"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const payload = {
			TrashID: parseInt(itemId),
		}
		self.updateProjTrashItem(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.deleteItem(itemId)
					self.setRestoreTrashDialogOpen()
					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	restoreProjTrashItems() {
		//
	},
})

export default RestoreProjTrash
