import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { apgLibPrefix } from "../../../../screens/project-side/lib-in-proj/store/lib-in-proj.data-model"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { CreateFileFromLibProps } from "../../apis/file-system-file-apis"

export type CreateFileFromLibActionProps = {
	projectId: string
	userId: string
	parentId: string
	srcFileId: string
	fileName: string
	fileAliasId: string
	prepared: boolean
	reviewed: boolean
}

const CreateFileFromLib = (
	self: any,
	createFile: FileSystemApiTypes["apiCreateFileFromLib"]
) => ({
	createFileFromLib: flow(function* (props: CreateFileFromLibActionProps) {
		const actionName = "createFileFromLib"
		const {
			projectId,
			userId,
			parentId,
			srcFileId,
			fileName,
			fileAliasId,
			prepared,
			reviewed,
		} = props
		// 1. check the props existence
		if (
			projectId === undefined ||
			userId === undefined ||
			parentId === undefined ||
			srcFileId === undefined ||
			fileName === undefined ||
			fileAliasId === undefined
		) {
			alert(
				`(${actionName}) Undefined prop exist. ${parentId} ${srcFileId} ${fileName} ${fileAliasId} `
			)
			return
		}
		//
		//
		// 2. organize the params
		const isApg = srcFileId.includes(apgLibPrefix) // @Noah: Have to update
		const projNumId = idToNumber(projectId, IdType.project)
		const userNumId = idToNumber(userId, IdType.user)

		const parentNumId = idToNumber(parentId, IdType.folder)
		const srcFileNumId = isApg
			? idToNumber(srcFileId.replace(apgLibPrefix, ""), IdType.file)
			: idToNumber(srcFileId, IdType.file)

		let signOffType: number[] = []
		if (prepared) {
			signOffType.push(1)
		}
		if (reviewed) {
			signOffType.push(2)
		}

		//
		//
		// 3. validate format
		if (
			typeof projNumId === "number" &&
			typeof userNumId === "number" &&
			typeof parentNumId === "number" &&
			typeof srcFileNumId === "number"
		) {
			// omitted: ClientID, FinancialYear
			const payload: CreateFileFromLibProps = isApg
				? {
						ClientID: projNumId,
						UserID: userNumId,
						ParentMediaID: parentNumId,
						TemplateFileID: srcFileNumId,
						WPDesc: fileName,
						WPEF: fileAliasId,
						signOffType,
						prepare: prepared,
						review: reviewed,
						// NOTE: ----- different part
						CreationMode: 1,
				  }
				: {
						// ClientID: projNumId,
						UserID: userNumId,
						ParentMediaID: parentNumId,
						TemplateFileID: srcFileNumId,
						WPDesc: fileName,
						WPEF: fileAliasId,
						signOffType,
						prepare: prepared,
						review: reviewed,
				  }

			try {
				const response = yield createFile(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid props. ${parentNumId} ${srcFileNumId}`
			)
			return false
		}
	}),
})

export default CreateFileFromLib
