import React from "react"
import styled from "styled-components"
import { ResizableFileInfoArea } from "../../../../service-modules/file-module/ui-helper-actions/ui-helper.actions"

const Partition = () => {
	return <StyledPartition>{/* <div>"</div> */}</StyledPartition>
}

export const WidthControl = ({
	handleMouseDown,
	target,
}: {
	handleMouseDown: any
	target: ResizableFileInfoArea
}) => {
	return (
		<StyledPartition className="FR AC">
			<div
				className="width-control"
				onMouseDown={() => handleMouseDown(target)}
			></div>
		</StyledPartition>
	)
}

const StyledPartition = styled.div`
	width: 0.4rem;
	height: 1rem;
	/* background-color: yellow; */
	.width-control {
		width: 0.2rem;
		height: 100%;
		border-radius: 0.1rem;
		cursor: col-resize;
		:hover {
			/* background-color: lightgray; */
		}
	}
`

export default Partition
