import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import InputWithLabel from "../../../../components/basic-elements/input-with-label/InputWithLabel"
import sharedRegEx from "../../../../library/sharedRegEx"
import useForm from "../../../../library/use-form"
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	DLInputField,
	ConsoleLog,
} from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useLobbyStore } from "../../../../stores/lobby-store/lobby-store.provider"

export const CreateCustomerBtn = ({ i18n }: { i18n: DLI18nProps }) => {
	const store = useRootStore()
	const lobbyStore = useLobbyStore()

	return (
		<DLButton
			variant="text"
			eleTestId="add-org-dialog"
			startIcon={<Icon path={mdiPlus} size={1} />}
			clickHandler={() => lobbyStore.orgMgmt.setOpenAdd(true)}
			color="primary"
		>
			{i18n.twCreateOrg}
		</DLButton>
	)
}

const AddOrganizationDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const lobbyStore = useLobbyStore()
	const actionName = "addOrgCustomer"
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
		customerId: { value: "", error: "", requestInput: false },
		email: { value: "", error: "", requestInput: false },
		organization: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.nameWithSpace,
			// 	error: "Invalid format",
			// },
		},
		customerId: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.nameWithSpace,
			// 	error: "Invalid format",
			// },
		},
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email,
				error: "Invalid format",
			},
		},
		organization: {
			isRequired: true,
			// validator: {
			// 	regEx: sharedRegEx.nameWithSpace,
			// 	error: "Invalid format",
			// },
		},
	}

	const addCustomer = () => {
		lobbyStore.orgMgmt.addOrgCustomer(inputs)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addCustomer
		// open // resetPoint
	)

	ConsoleLog("AddOrgCustomerDialog has been rendered")

	return (
		<DLDialog
			eleTestId="add-org-customer-dialog"
			draggable
			isOpen={lobbyStore.orgMgmt.openAdd}
			setIsOpen={lobbyStore.orgMgmt.setOpenAdd}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPlus}
						size={1}
						// color={MessageColorType.blue}
						style={{ marginRight: 8 }}
					/>
					{i18n.twCreateOrg}
				</div>
			}
			dialogContents={
				<AddCustomerDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel}
			actionReady={isReady}
			actionBtn={i18n.twCreate}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				lobbyStore.orgMgmt.getActionStatus(actionName) === "LOADING"
					? true
					: false
			}
			useEnterKeyForSubmit
		/>
	)
})

const AddCustomerDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const lobbyStore = useLobbyStore()

		return (
			<StyledAddCustomerDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserName}>
						<DLInputField
							eleTestId="customer-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								// lobbyStore.orgMgmt.isDuplicatedName(
								// 	inputs.name.value
								// )
								// 	? "Name already exists."
								inputs.name.error
							}
							// warningType={
							// 	lobbyStore.orgMgmt.isDuplicatedName(
							// 		inputs.name.value
							// 	)
							// 		? "red"
							// 		: undefined
							// }
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserId}>
						<DLInputField
							eleTestId="customer-id-input"
							eleFullWidth
							eleName="customerId"
							eleValue={inputs.customerId.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.customerId.error}
							requestInput={inputs.customerId.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserEmail}>
						<DLInputField
							eleTestId="customer-email-input"
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								lobbyStore.orgMgmt.isDuplicatedEmail(
									inputs.email.value
								)
									? "Email already exists with other organization."
									: inputs.email.error
							}
							warningType={
								lobbyStore.orgMgmt.isDuplicatedEmail(
									inputs.email.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.email.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twOrganizationName}>
						<DLInputField
							eleTestId="organization-input"
							eleFullWidth
							eleName="organization"
							eleValue={inputs.organization.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								lobbyStore.orgMgmt.isDuplicatedOrganization(
									inputs.organization.value
								)
									? "Organization with same name already exists"
									: inputs.organization.error
							}
							warningType={
								lobbyStore.orgMgmt.isDuplicatedOrganization(
									inputs.organization.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.organization.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddCustomerDialog>
		)
	}
)

const StyledAddCustomerDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default AddOrganizationDialog
