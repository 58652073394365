import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import GetLeadSheetUrl from "./view-model-actions/get-lead-sheet-url"

const initialStore = {
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const LeadSheetViewModel = types
	.model({
		url: "",
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setUrl(url: string) {
			self.url = url
		},
	}))
	.actions(GetLeadSheetUrl)
	.views((self) => ({}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default LeadSheetViewModel
