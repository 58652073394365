import React from "react"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	IconPlaceholder,
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

export default observer(function OrgSetupClientsTableMenus({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()

	const menuOptions = [
		{
			label: `${i18n.twEditClientName}`,
			value: "ctx-editClient",
			available: permission.update,
			clickEvent: () =>
				orgStore.setupClients.setEditClientDialogOpen(true),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		// {
		// 	label: `${i18n.twAssignGroup}`,
		// 	value: "ctx-assignToGroup",
		// 	available: permission.update,
		// 	clickEvent: () =>
		// 		orgStore.setupClients.setAssignClientToGroupDialogOpen(true),
		// 	icon: <IconPlaceholder />,
		// },
		{
			label: `${i18n.twClientInfo  || "Client Info"}`,
			value: "ctx-clientInfo",
			available: permission.update,
			// clickEvent: () =>
			// 	orgStore.setupClients.setAssignClientToGroupDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twRemoveClient}`,
			value: "ctx-removeClient",
			available: permission.delete,
			clickEvent: () =>
				orgStore.setupClients.setRemoveClientDialogOpen(true),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	const handleClose = () => {
		orgStore.setupClients.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="org-setup-clients-ctx-menus"
			clickPoint={{
				mouseX: orgStore.setupClients.clickPoint.mouseX,
				mouseY: orgStore.setupClients.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1]}
		/>
	)
})
