import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import ReadOtherProjects from "./model-actions/read-other-projects"
import { OtherProjectsApiProps } from "./other-projects.apis"

const OtherProjectsModel = ({
	apiReadOthers,
}: OtherProjectsApiProps) =>
	types
		.model({})
		.actions((self) => ReadOtherProjects(self, apiReadOthers))
		// Common model actions
		.actions(CommonModelActions)

export default OtherProjectsModel
