import { types } from "mobx-state-tree"

export const EEDetails = types.model({
	accountNumber: types.string,
	accountDesc: types.string,
	createdBy: types.string,
	credit: types.number,
	debit: types.number,
	entityName: types.string,
	entityId: types.number,
	eeId: types.string,
	year: types.string,
})

export const AccountDetails = types.model({
	accountNumber: types.string,
	accountDesc: types.string,
})
