import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../../../common-models/types/dialog.props"
// ---------- common models

const ViewInfos = (self: any) => ({
	viewLibraryFolder() {},
})

const navOpenWidth = 400
const navClosedWidth = 48

const OrgFileLibUiHelper = types
	.model({
		listWidth: navOpenWidth,
		navOpen: true,
		canDrag: false,
		fileClickPoint: ClickPoint,
		// dialog
		dialogType: "",
		dialogActionType: "",
		openAddLibraryFolder: false,
		openEditLibraryFolder: false,
		openDeleteAndDuplicateDialog: false,
		//template
		openAddLibraryTemplate: false,
		openEditLibraryTemplate: false,
		openDeleteAndDuplicateTemplateDialog: false,
	})
	.actions((self) => ({
		setListWidth(width: number) {
			self.listWidth = width
		},
		setNavOpen(request: boolean) {
			self.navOpen = request
			if (request === true) {
				self.listWidth = navOpenWidth
			} else {
				self.listWidth = navClosedWidth
			}
		},
		setCanDrag(request?: boolean) {
			if (request !== undefined) {
				self.canDrag = request
			} else {
				self.canDrag = !self.canDrag
			}
		},
		updateListWidth(value: number) {
			self.listWidth = self.listWidth + value
		},
		setFileClickPoint(clickPoint: ClickPointProps) {
			self.fileClickPoint = clickPoint
		},
	}))
	.actions((self) => ({
		// dialog related actions
		setDialogType(type: string) {
			self.dialogType = type
		},
		setDialogActionType(action: string) {
			self.dialogActionType = action
		},
		setOpenAddLibraryFolder(request?: boolean) {
			if (request !== undefined) {
				self.openAddLibraryFolder = request
			} else {
				self.openAddLibraryFolder = !self.openAddLibraryFolder
			}
		},
		setOpenEditLibraryFolder(request?: boolean) {
			if (request !== undefined) {
				self.openEditLibraryFolder = request
			} else {
				self.openEditLibraryFolder = !self.openEditLibraryFolder
			}
		},
		setOpenDeleteAndDuplicateDialog(request?: boolean) {
			if (request !== undefined) {
				self.openDeleteAndDuplicateDialog = request
			} else {
				self.openDeleteAndDuplicateDialog = !self.openDeleteAndDuplicateDialog
			}
		},
		setOpenAddLibraryTemplate(request?: boolean) {
			if (request !== undefined) {
				self.openAddLibraryTemplate = request
			} else {
				self.openAddLibraryTemplate = !self.openAddLibraryTemplate
			}
		},
		setOpenEditLibraryTemplate(request?: boolean) {
			if (request !== undefined) {
				self.openEditLibraryTemplate = request
			} else {
				self.openEditLibraryTemplate = !self.openEditLibraryTemplate
			}
		},
		setOpenDeleteAndDuplicateTemplateDialog(request?: boolean) {
			if (request !== undefined) {
				self.openDeleteAndDuplicateTemplateDialog = request
			} else {
				self.openDeleteAndDuplicateTemplateDialog = !self.openDeleteAndDuplicateTemplateDialog
			}
		},
	}))
	.views((self) => ({
		get viewListWidth() {
			return self.listWidth
		},
	}))
	.views(ViewInfos)

export default OrgFileLibUiHelper
