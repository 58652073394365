// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { CommentDeleteProps } from "../model-actions/delete-comment"
import { HandleResponseProps } from "../../../../../../common-models/common-view-model-actions"

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const RemoveComment = (self: any) => ({
	removeComment({
		props,
		handleResponse,
		postWorkForSuccess,
	}: {
		props: CommentDeleteProps
		handleResponse: (props: HandleResponseProps) => void
		postWorkForSuccess?: () => void
	}) {
		// 0.
		const actionName = "removeComment"
		// 1.
		// 2.
		// self.responses.setResponse(actionName, {
		// 	actionName,
		// 	status: ActionStatus.loading,
		// })
		let proceed = window.confirm(
			`Do you really want to delete this comment?`
		)
		if (!proceed) {
			return
		}
		handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.

		self.deleteComment(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// TODO: When delete the comment on wp or pf screen, reflection is not working
					self.reflectRemoveComment(props.commentId)
					self.resetSelectedItems()
					postWorkForSuccess && postWorkForSuccess()
					// set success case response
					handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	removeMultipleComments({
		selectedCommentIds,
		deleteBy,
		index,
	}: {
		selectedCommentIds: { id: string; type: string }[]
		deleteBy: string
		index: number
	}) {
		// 0.
		const actionName = "removeMultipleComment"
		// 1.
		// 2.
		// self.responses.setResponse(actionName, {
		// 	actionName,
		// 	status: ActionStatus.loading,
		// })
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Comment deletion is in progress ...",
			open: true,
		})
		// 3.

		self.deleteComment({
			commentId: selectedCommentIds[index].id,
			deleteBy,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// TODO: When delete the comment on wp or pf screen, reflection is not working
					// self.resetSelectedItems()
					// postWorkForSuccess && postWorkForSuccess()
					self.reflectRemoveComment(selectedCommentIds[index].id)
					// set success case response
					if (index !== selectedCommentIds.length - 1) {
						self.removeMultipleComments({
							selectedCommentIds,
							deleteBy,
							index: index + 1,
						})
					} else {
						self.setDeleteAllTrashDialogOpen(false)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage:
								"Success to remove selected comments",
							open: true,
							autoHide: true,
						})
					}
				} else {
					self.removeMultipleComments({
						selectedCommentIds,
						deleteBy,
						index: index + 1,
					})
				}
			})
			.catch((error: Error) => {
				if (index !== selectedCommentIds.length - 1) {
					self.removeMultipleComments({
						selectedCommentIds,
						deleteBy,
						index: index + 1,
					})
				}
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RemoveComment
