import { types, applySnapshot } from "mobx-state-tree"
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ----------
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { initialStore } from "./wp.provider"
import GetTrialBalanceDetails from "../../../financial-statements/financial-statements/store/view-model-actions/get-trial-balance-details"
import { TBDetails } from "../../../financial-statements/financial-statements/store/data-models/financial-statement.data-model"
import { FSType } from "../../../financial-statements/fs-setup/store/data-models/fs-setup.data-model"
import GetFinancialStatementType from "../../../financial-statements/fs-setup/store/view-model-actions/get-financial-statement-type"
import GetFinalAnalysis from "./view-model-actions/get-final-analysis"
import { FinalAnalysisModel } from "./data-models/wp.data-model"

const InitializeStore = (self: any) => ({
	initializeStore() {
		applySnapshot(self, initialStore)
	},
})

const WpViewModel = types
	.model({
		// WARNING: Check the "file-control" in the same folder
		// to check the common part
		storeName: types.enumeration<DLProjSubMenus>(
			Object.values(DLProjSubMenus)
		),
		finalAnalysis: FinalAnalysisModel,
		tbDetails: types.array(TBDetails),
		fsType: types.array(FSType),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setTBDetails(details: any) {
			self.tbDetails = details
		},
		setFSType(types: any) {
			self.fsType = types
		},
		setFinalAnalysis(item: any) {
			self.finalAnalysis = item
		},
	}))
	//
	.actions(GetTrialBalanceDetails)
	.actions(GetFinancialStatementType)
	.actions(GetFinalAnalysis)

	.views((self) => ({
		viewTBDetails() {
			let tbDetails: any[] = []
			self.tbDetails.map((item) => {
				tbDetails.push({
					name: item.tbName,
					value: item.tbId,
				})
			})
			return tbDetails
		},
		viewFSTypes() {
			let types: any[] = []
			self.fsType.map((type) => {
				types.push({
					value: type.fsTypeId,
					name: type.fsTypeName,
				})
			})
			return types
		},
	}))

	// common parts
	.actions(InitializeStore)
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default WpViewModel
