import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLButton,
	DLTreeListByTreeData,
	ConsoleLog,
} from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiFolder } from "@mdi/js"
import { DLCheckbox } from "@datalobby/components"
import { SimpleFileTreeProps } from "../../../../../../service-modules/file-module/data-models/file.data-props"
import styled from "styled-components"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { saveLatestProjToken } from "../../../../../../library/token-utils"
import { requestToken } from "../../../../../project-side/proj-access-control/store/proj-checkin.apis"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

export default observer(function FileSelectionDialog({
	partialStore,
	selectedProjId,
	handleFileChange,
	currentChecklistItem,
}: {
	partialStore: any
	selectedProjId: string
	handleFileChange: any
	currentChecklistItem: string | undefined
}) {
	const [selectedItem, setSelectedItem] = useState<{
		id: string
		title: string
		aliasId: string
	} | null>(null)

	const handleTargetItem = () => {
		if(selectedItem){
			handleFileChange(currentChecklistItem, selectedItem.title, selectedItem.aliasId)
			partialStore.setOpenFileSelectionDialog(false)
		}
	}

	const handleSelectedItem = (node: any) => {
		const {	id,	title, aliasId} = node
		setSelectedItem({ id, title, aliasId })
	}


	const getAllWPFiles = () => {
		const userNumId = idToNumber(localStorage.getItem("orgUserId") || "", IdType.user)
		const projNumId = idToNumber(selectedProjId, IdType.project)

		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
						partialStore.getAllFiles(
							selectedProjId,
							DLProjSubMenus.wp
						)
					} else {
						ConsoleLog(
							"Cannot request the project info. Failed to request project token"
						)
					}
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the project info. Project ID or User ID is incorrect."
			)
		}
	}

	useEffect(() => {
		// getAllWPFiles()
	}, [])

	const data = [
		{
			id: "folderId295043",
			isParent: true,
			parentId: null,
			title: "Folder",
			index: 0,
			aliasId: "",
			expanded: true,
			children: [
				{
					id: "folderId338145",
					isParent: true,
					parentId: "folderId295043",
					title: "Inner folder",
					index: 0,
					aliasId: "",
					expanded: false,
					children: [],
				},
				{
					id: "fileId338145",
					isParent: false,
					parentId: "folderId295043",
					title: "File Name 1",
					index: 0,
					aliasId: "Alias ID 1",
					expanded: false,
					children: [],
				},
				{
					id: "fileId338",
					isParent: false,
					parentId: "folderId295043",
					title: "File Name 2",
					index: 0,
					aliasId: "Alias ID 2",
					expanded: false,
					children: [],
				},
			],
		},
		{
			id: "folderId295047",
			isParent: true,
			parentId: null,
			title: "Folder-1",
			index: 1,
			aliasId: "",
			expanded: false,
			children: [],
		},
	]
	return (
		<DLDialog
			eleTestId="file-selection-dialog"
			isOpen={partialStore.openFileSelectionDialog}
			setIsOpen={partialStore.setOpenFileSelectionDialog}
			handleAction={handleTargetItem}
			showCloseBtn={true}
			dialogTitle="Select File"
			dialogContents={
				<FileSelectionDialogContents
					simpleFileTree={data}
					selectedItem={selectedItem}
					handleSelectedItem={handleSelectedItem}
				/>
			}
			actionReady={!!selectedItem}
			maxWidth="sm"
			cancelBtnText="Cancel"
			actionBtn="Save"
		/>
	)
})

export const FileSelectionDialogContents = observer(
	({
		simpleFileTree,
		selectedItem,
		handleSelectedItem,
	}: {
		simpleFileTree: SimpleFileTreeProps[]
		selectedItem: any
		handleSelectedItem: any
	}) => {
		return (
			<StyledFileTreeSelection>
				<DLTreeListByTreeData
					treeData={simpleFileTree}
					eleRenderNode={(props: any) => {
						const { node } = props
						const {
							id,
							isParent,
							parentId,
							title,
							index,
							aliasId,
						} = node
						const isChecked = id === selectedItem?.id

						const clsName = isChecked
							? "item-row selected FR AC "
							: "item-row FR AC "
						if (isParent) {
							return (
								<div className={clsName}>
									<Icon path={mdiFolder} size={0.6} />
									{title}
								</div>
							)
						} else {
							return (
								<div className={clsName}>
									<DLCheckbox
										eleTestId={"file-checkbox-" + title}
										isChecked={isChecked}
										clickHandler={() =>
											handleSelectedItem(node)
										}
										color={
											isChecked ? "primary" : "default"
										}
									/>
									({aliasId}) {title}
								</div>
							)
						}
					}}
					canDrag={false}
					eleWidth="100%"
					eleHeight={500}
					indentWidth={24}
					rowHeight="thin"
				/>
			</StyledFileTreeSelection>
		)
	}
)

const StyledFileTreeSelection = styled.div`
	.item-row {
		height: 1rem;
	}
	.selected {
		color: ${(props) => props.theme.emphasis};
	}
`
