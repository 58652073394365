import React from "react"
import {
	DLObjectFormat,
	DLSrcFormat,
} from "../../common-models/enumerations/common-enums"
import {
	FileIcon,
	ExcelIcon,
	PdfIcon,
	WordIcon,
	ZipIcon,
} from "../../components/basic-elements/icons/common-icons"
import { ProjectStatus } from "../../common-models/enumerations/project-related-enums"
import {
	NormalProjectIcon,
	ReplicaProjectIcon,
	ArchivedProjectIcon,
	UnarchivedProjectIcon,
	ForceArchivedProjectIcon,
} from "../../components/basic-elements/icons/common-project-icons"

export function getMatchedFileIcon(fileType: DLObjectFormat | DLSrcFormat) {
	const fileTypeList = Object.values(DLObjectFormat)
	const srcFormatList = Object.values(DLSrcFormat)

	if (
		fileType === DLObjectFormat.normalFile ||
		fileType === DLObjectFormat.customFile
	) {
		return <FileIcon />
	} else {
		// when the file type is DLSrcFormat
		if (fileType === DLSrcFormat.excel) {
			return <ExcelIcon />
		} else if (fileType === DLSrcFormat.pdf) {
			return <PdfIcon />
		} else if (fileType === DLSrcFormat.word) {
			return <WordIcon />
		} else if (fileType === DLSrcFormat.zip) {
			return <ZipIcon />
		} else if (fileType === DLSrcFormat.unknown) {
			return <FileIcon />
		}
	}
}

export function getMatchedProjectStatusIcon(
	projStatus: ProjectStatus,
	size?: number
) {
	if (projStatus === ProjectStatus.normal) {
		return <NormalProjectIcon size={size ? size : 1} />
	} else if (projStatus === ProjectStatus.replica) {
		return <ReplicaProjectIcon size={size ? size : 1} />
	} else if (projStatus === ProjectStatus.archived) {
		return <ArchivedProjectIcon size={size ? size : 1} />
	} else if (projStatus === ProjectStatus.unarchived) {
		return <UnarchivedProjectIcon size={size ? size : 1} />
	} else if (projStatus === ProjectStatus.forceArchived) {
		return <ForceArchivedProjectIcon size={size ? size : 1} />
	}
}
