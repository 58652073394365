export const DefaultSignOffPolicy = [
	{
		id: "id_prepare",
		label: "Prepare",
		shortLabel: "P",
		order: 1,
		availableRoles: [
			"id_js",
			"id_ss",
			"id_m",
			"id_d",
			"id_p",
			"id_ep",
			"id_cp",
			"id_qc",
		],
		prerequisite: null,
		updateTogether: null,
		required: false,
	},
	{
		id: "id_review",
		label: "Review",
		shortLabel: "R",
		order: 2,
		availableRoles: [
			"id_js",
			"id_ss",
			"id_m",
			"id_d",
			"id_p",
			"id_ep",
			"id_cp",
			"id_qc",
		],
		prerequisite: null,
		updateTogether: null,
		required: true,
	},
	{
		id: "id_epReview",
		label: "EP review",
		shortLabel: "EP",
		order: 3,
		availableRoles: ["id_ep"],
		prerequisite: null,
		updateTogether: ["id_review"],
		required: false,
	},
	{
		id: "id_cpReview",
		label: "CP review",
		shortLabel: "CP",
		order: 4,
		availableRoles: ["id_cp"],
		prerequisite: null,
		updateTogether: ["id_review"],
		required: false,
	},
	{
		id: "id_qcReview",
		label: "QC review",
		shortLabel: "QC",
		order: 5,
		availableRoles: ["id_qc"],
		prerequisite: null,
		updateTogether: null,
		required: false,
	},
]

export const PfSignOffPolicy = [
	{
		id: "id_prepare",
		label: "Prepare",
		shortLabel: "P",
		order: 1,
		availableRoles: [
			"id_js",
			"id_ss",
			"id_m",
			"id_d",
			"id_p",
			"id_ep",
			"id_cp",
			// "id_qc",
		],
		prerequisite: null,
		updateTogether: null,
		required: false,
	},
	{
		id: "id_review",
		label: "Review",
		shortLabel: "R",
		order: 2,
		availableRoles: [
			"id_js",
			"id_ss",
			"id_m",
			"id_d",
			"id_p",
			"id_ep",
			"id_cp",
			// "id_qc"
		],
		prerequisite: null,
		updateTogether: null,
		required: true,
	},
	{
		id: "id_epReview",
		label: "EP review",
		shortLabel: "EP",
		order: 3,
		availableRoles: ["id_ep"],
		prerequisite: null,
		updateTogether: ["id_review"],
		required: false,
	},
	{
		id: "id_cpReview",
		label: "CP review",
		shortLabel: "CP",
		order: 4,
		availableRoles: ["id_cp"],
		prerequisite: null,
		updateTogether: ["id_review"],
		required: false,
	},
	// {
	// 	id: "id_qcReview",
	// 	label: "QC review",
	// 	shortLabel: "QC",
	// 	order: 5,
	// 	availableRoles: ["id_qc"],
	// 	prerequisite: ["id_epReview"],
	// 	updateTogether: null,
	// 	required: false,
	// },
]
