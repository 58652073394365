import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../../library/api-requests"

export async function read(params: {
	// clientId: number
	tbNameId: number
	year: number
}) {
	const response = await getRequest({
		url: "/FetchFinancialPresentation",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readTrialBalance(params: { clientId: number }) {
	const response = await getRequest({
		url: "/GetTrialBalances",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type FinancialStatementsApiProps = {
	apiRead: (payload: {
		clientId: number
		tbNameId: number
		year: number
	}) => Promise<AxiosResponse<any>>
	apiReadTrialBalance: (payload: {
		clientId: number
	}) => Promise<AxiosResponse<any>>
}
