import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../library/converters/id-converter"
import { ConsoleLog } from "../../../components/basic-elements"

const AddOtherProject = (self: any) => ({
	addOtherProject({
		inputs,
		postAction,
		handleResponse,
	}: {
		inputs: any
		postAction: any
		handleResponse: any
	}) {
		// 0.
		const actionName = "addOtherProject"
		// 1.
		// 2.
		handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		/**
		 * REORGANIZE THE INPUTS
		 * NOTE: trim has been added on payload / need to consider better place
		 */
		self.createOtherProject(inputs)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// TODO: reflect the result with the fetched ID
					const fetchedData = response.data

					ConsoleLog("fetched Data for add project" + fetchedData)

					const newProjId = idToString(
						fetchedData.ClientId,
						IdType.project
					)
					// set success case response
					handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						// autoHide: true,
					})
					postAction(newProjId, fetchedData)
				} else {
					// set fail case response
					handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddOtherProject
