import { flow } from "mobx-state-tree"
import { OrgGroupApiCallProps } from "./org-group-shared-apis.calling-props"
import { idToNumber, IdType } from "../../library/converters/id-converter"

const CreateGroupItem = (
	self: any,
	apiCreate: OrgGroupApiCallProps["apiCreateGroupItem"]
) => ({
	createGroupItem: flow(function* ({
		title,
		parentId,
	}: {
		title: string
		parentId: string
	}) {
		const actionName = "createGroupItem"
		// 1.
		if (title === undefined || parentId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. title: ${title} / parentId: ${parentId}`
			)
			return
		}
		// 2.
		const numberId = parentId.includes("group")
			? idToNumber(parentId, IdType.group)
			: parentId.includes("cabinet")
			? idToNumber(parentId, IdType.cabinet)
			: alert("Undefined Type Parent")
		if (typeof numberId === "number") {
			try {
				let payload = {
					CabinetName: title,
					FolderType: numberId === 0 ? 1 : 2, // NOTE:  FolderType: 1 = Group / FolderType: 2 = Cabinet in group
					ParentID: numberId, // 0 for group
					// UserID: 16
				}
				const response = yield apiCreate(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default CreateGroupItem
