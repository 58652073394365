import { flow } from "mobx-state-tree"

const MReleaseForceArchive = (self: any, relForceArch: any) => ({
	mReleaseForceArchive: flow(function* ({ clientId, reason }) {
		const actionName = "mReleaseForceArchive"
		try {
			const response = yield relForceArch({
				ClientId: clientId,
				Reason: reason,
			})
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default MReleaseForceArchive
