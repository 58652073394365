import { observer } from "mobx-react-lite"
import React, { ReactElement, useEffect, useState } from "react"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import styled from "styled-components"
import {
	InputWithLabel,
	DLInputField,
	DLSingleSelect,
	DLButton,
	DLSpinner,
	DLSpinnerCenterAligned,
	ConsoleLog,
} from "../../../../basic-elements"
import {
	apgLibPrefix,
	normalLibPrefix,
} from "../../../../../screens/project-side/lib-in-proj/store/lib-in-proj.data-model"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import AddFileSignOffForm from "./AddFileSignOffForm"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { FolderSelectField } from "."
import sharedRegEx from "../../../../../library/sharedRegEx"
import { isEmptyString } from "@datalobby/common"
import DLSystemMsg, {
	NoSpecialChar2Msg,
} from "../../../../basic-elements/system-msg/DLSystemMsg"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function AddFileFromLibForm({
	menuId,
	projId,
	parentInfo,
	folderId,
	partialStore,
	i18n,
	permission,
}: {
	menuId: DLProjSubMenus | DLOrgSubMenus
	projId: string
	parentInfo: any
	folderId: string | undefined
	partialStore: any
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// NOTE: project template has not import from library
	const projStore = useProjStore()
	const libStore = projStore.lib

	const userRole = projStore.checkin.user.roleId

	const [libGroupId, setLibGroupId] = useState("")
	const [libId, setLibId] = useState("")
	const [fileId, setFileId] = useState("")
	const [aliasId, setAliasId] = useState("")
	const [fileName, setFileName] = useState("")

	const [prepare, setPrepare] = useState(false)
	const [review, setReview] = useState(false)

	const [highlight, setHighlight] = useState(false)

	const fromLibDrawerId = libStore.selectedItemId
	const fromDrawer = fromLibDrawerId !== ""

	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []

	useEffect(() => {
		if (fromLibDrawerId === "") {
			const orgId = projStore.checkin.orgId
			libStore.getLibrariesInProj(orgId)
		}
	}, [])

	useEffect(() => {
		if (fromDrawer) {
			const fileInfo = libStore.viewLibFileInfo(fromLibDrawerId)
			const { title, parentId, id } = fileInfo
			setFileName(title)

			if (fromLibDrawerId.includes(apgLibPrefix)) {
				// when the selected file is apg library
				setLibGroupId(parentId)
				setLibId(fromLibDrawerId)
				setFileId("")
			} else {
				// normal file
				const libGroupId = libStore.viewLibFileInfo(parentId).parentId
				setLibGroupId(libGroupId)
				setLibId(parentId)
				setFileId(id)
			}
		}
	}, [fromLibDrawerId])

	useEffect(() => {
		if (!fromDrawer && libGroupId !== "") {
			// reset libId, fileId, aliasId, fileName
			setLibId("")
			setFileId("")
			setAliasId("")
			setFileName("")
			setPrepare(false)
			setReview(false)
		}
	}, [libGroupId])

	useEffect(() => {
		if (!fromDrawer) {
			if (libId !== "" && !libId.includes(apgLibPrefix)) {
				ConsoleLog("call the files of the lib")
				libStore.getLibFilesInProj(libId)
				setFileId("")
				setAliasId("")
				setFileName("")
				setPrepare(false)
				setReview(false)
			} else if (libId !== "" && libId.includes(apgLibPrefix)) {
				setFileId("")
				setAliasId("")
				setFileName(libStore.viewLibFileInfo(libId).title)
				setPrepare(false)
				setReview(false)
			} else {
				setFileId("")
				setAliasId("")
				setFileName("")
				setPrepare(false)
				setReview(false)
			}
		}
	}, [libId])

	useEffect(() => {
		if (!fromDrawer) {
			if (fileId !== "") {
				ConsoleLog(
					"when the file is selected, get the title as defalut file name"
				)
				setFileName(libStore.viewLibFileInfo(fileId).title)
			}
			setAliasId("")
			setPrepare(false)
			setReview(false)
		}
	}, [fileId])

	const handleAdd = () => {
		if (permission.create) {
			partialStore.addFileFromLib({
				projectId: projId,
				userId: projStore.checkin.userId,
				parentId: folderId,
				srcFileId: libId.includes(apgLibPrefix) ? libId : fileId,
				fileName,
				fileAliasId: aliasId,
				prepared: prepare,
				reviewed: review,
			})
		} else {
			alert("Sorry, you don't have the permission.")
		}
	}

	const checkActionReady = () => {
		let actionReady = false
		if (
			folderId !== "" &&
			folderId !== undefined &&
			libGroupId !== "" &&
			libId !== "" &&
			!isEmptyString(aliasId) &&
			!isEmptyString(fileName)
		) {
			const isApgLib = libId.includes(apgLibPrefix)
			if (isApgLib && fileId === "") {
				actionReady = true
			} else if (isApgLib && fileId !== "") {
				actionReady = false
			} else if (!isApgLib && fileId === "") {
				actionReady = false
			} else if (!isApgLib && fileId !== "") {
				actionReady = true
			}
		}
		return actionReady
	}
	// NOTE: @FileName, FileAliasId, FolderName Validation
	const inputLengthStandard = 50
	let sameNameWarning = i18n.tsSameNameExist || "The same name already exist"
	let specialCharWarning = i18n.warningSpecialChar2 || <NoSpecialChar2Msg />

	let exceedWarning =
		i18n.warningCharExceed || "Exceeded the limited number of characters."

	const libGroupList = libStore.viewLibGroups({ simpleMode: true })
	const libList = libStore.viewLibListByLibGroup({
		libGroupId,
		simpleMode: true,
	})
	const fileList = libStore.viewLibFiles({ libId, simpleMode: true })

	const libFilesFetchingStatus = libStore.getActionStatus("getLibFilesInProj")
	const isLoading =
		partialStore.getActionStatus("addFileFromLib") === ActionStatus.loading
	const libFilesLoading = libFilesFetchingStatus === ActionStatus.loading

	// NOTE: AliasID and Title Check: Must take same logic with global
	let aliasIdWarningMsg: string | ReactElement = ""
	let titleWarningMsg: string | ReactElement = ""

	const duplicatedAliasId = partialStore.isDuplicatedAliasId(aliasId)
	const validAliasId = sharedRegEx.noSpecialChar2.test(aliasId)
	const validAliasIdLength = aliasId.length <= inputLengthStandard

	const duplicatedTitle = partialStore.isDuplicatedName(fileName)
	const validTitle = sharedRegEx.noSpecialChar2.test(fileName)
	const validTitleLength = fileName.length <= inputLengthStandard

	if (duplicatedAliasId) {
		aliasIdWarningMsg = sameNameWarning
	} else if (!validAliasId && aliasId !== "") {
		aliasIdWarningMsg = specialCharWarning
	} else if (aliasId.length > inputLengthStandard) {
		aliasIdWarningMsg = exceedWarning
	}

	if (duplicatedTitle) {
		titleWarningMsg = sameNameWarning
	} else if (!validTitle && fileName !== "") {
		titleWarningMsg = specialCharWarning
	} else if (fileName.length > inputLengthStandard) {
		titleWarningMsg = exceedWarning
	}

	return (
		<>
			{isLoading && (
				<DLSpinnerCenterAligned absolute backgroundOpacity={0.5} />
			)}

			<SteyldImporFileFromLib className="FC JSB">
				<div>
					<FolderSelectField
						i18n={i18n}
						partialStore={partialStore}
						folderTitle={parentInfo?.title}
						highlight={highlight}
					/>
					<div className="input-section FR">
						<InputWithLabel
							label="Library Group"
							required
							highlight={highlight && libGroupId === ""}
						>
							<DLSingleSelect
								eleTestId="lib-group-select"
								eleValue={libGroupId}
								eleOnChange={(e: any) =>
									setLibGroupId(e.target.value)
								}
								eleName="lib-group"
								eleFullWidth
								withLabel={false}
								placeholder="Please select a library group"
								optionList={libGroupList}
								eleDisabled={fromDrawer}
								minHeight="auto"
							/>
						</InputWithLabel>
					</div>

					<div className="input-section FR">
						<InputWithLabel
							label="Library"
							required
							highlight={highlight && libId === ""}
						>
							{libGroupId !== "" && libList.length === 0 ? (
								<DLSystemMsg
									eleTestId="msg-empty-lib-group"
									type={MessageColorType.orange}
									msg="This Library Group is empty."
								/>
							) : (
								<DLSingleSelect
									eleTestId="lib-select"
									eleValue={libId}
									eleOnChange={(e: any) =>
										setLibId(e.target.value)
									}
									eleName="lib"
									eleFullWidth
									withLabel={false}
									placeholder="Please select a library"
									optionList={libList}
									eleDisabled={
										fromDrawer || libGroupId === ""
									}
									minHeight="auto"
								/>
							)}
						</InputWithLabel>
					</div>

					{libId.includes(normalLibPrefix) && (
						<div className="input-section FR">
							<InputWithLabel
								label="Library File"
								required
								highlight={highlight && fileId === ""}
							>
								{fileList.length > 0 ? (
									<DLSingleSelect
										eleTestId="lib-file-select"
										eleValue={fileId}
										eleOnChange={(e: any) =>
											setFileId(e.target.value)
										}
										eleName="lib-file"
										eleFullWidth
										withLabel={false}
										placeholder="Please select a file"
										optionList={fileList}
										eleDisabled={fromDrawer || libId === ""}
										minHeight="auto"
									/>
								) : libFilesLoading ? (
									<DLSpinner size={24} />
								) : (
									<DLSystemMsg
										eleTestId="msg-empty-lib"
										type={MessageColorType.orange}
										msg="This Library is empty."
									/>
								)}
							</InputWithLabel>
						</div>
					)}
					<div className="input-section FR">
						<InputWithLabel
							label={i18n.twRefNum || "Ref. Num"}
							required
							highlight={highlight && isEmptyString(aliasId)}
						>
							{/* WARNING: input validation is required / * ? " > < : | */}
							<DLInputField
								eleTestId="aliasId-input"
								eleName="aliasId"
								elePlaceholder={
									i18n.tsRequestInputRefNum ||
									"Please input reference number"
								}
								eleValue={aliasId}
								eleHandleChange={(e: any) =>
									setAliasId(e.target.value)
								}
								autoFocus
								warningMsg={aliasIdWarningMsg}
								warningType="orange"
								eleRequired
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel
							label={i18n.twFileName || "File Name"}
							required
							highlight={highlight && isEmptyString(fileName)}
						>
							<DLInputField
								eleTestId="fileName-input"
								elePlaceholder="Please input file name"
								eleValue={fileName}
								eleHandleChange={(e: any) =>
									setFileName(e.target.value)
								}
								eleName="fileName"
								warningMsg={titleWarningMsg}
								warningType="orange"
								eleRequired
							/>
						</InputWithLabel>
					</div>
					{signOffPolicy[0].availableRoles.includes(userRole) && (
						<AddFileSignOffForm
							prepare={prepare}
							setPrepare={setPrepare}
							review={review}
							setReview={setReview}
						/>
					)}
				</div>
				<DLButton
					eleTestId="add-file-from-lib-btn"
					color="primary"
					clickHandler={handleAdd}
					eleHeight="auto"
					disabled={
						!checkActionReady() ||
						isLoading ||
						duplicatedAliasId ||
						duplicatedTitle ||
						!validAliasId ||
						!validTitle ||
						!validAliasIdLength ||
						!validTitleLength
					}
					onMouseOver={() => setHighlight(true)}
					onMouseLeave={() => setHighlight(false)}
				>
					Create
				</DLButton>
			</SteyldImporFileFromLib>
		</>
	)
})

const SteyldImporFileFromLib = styled.div`
	height: 100%;
`
