import { types } from "mobx-state-tree"
import OrgRolesModel from "./org-roles.model"
import OrgRolesViewModel from "./org-roles.view-model"

const OrgRolesStore = types
	.compose(OrgRolesModel, OrgRolesViewModel)
	.named("OrgRolesStore")

const initialRoleSet = {
	id: "",
	title: "",
	createdAt: "",
	createdBy: "",
	modifiedAt: null,
	modifiedBy: null,
	roles: [],
}

export const initialStore = {
	roleSet: initialRoleSet,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgRolesStoreInstance = OrgRolesStore.create(initialStore)

export default OrgRolesStoreInstance
