import React from "react"
import styled from "styled-components"
import { DLProjectModelType } from "../../../../../service-modules/project-module/data-model/project-module-data-models"
import { SimpleUserModelType } from "../../../../../service-modules/user-module/user-module-data-models"

export const ProjectInfoContainer = ({
	projInfo,
}: {
	projInfo: DLProjectModelType
}) => {
	return (
		<StyledProjectInfo data-testid="project-info-container">
			<h4 data-tetsid="area-title">Project Information</h4>
			<InfoRow
				testId="info-row-projTitle"
				label="Project Title"
				value={projInfo.title}
			/>
			<InfoRow
				testId="info-row-projAliasId"
				label="Alias ID"
				value={projInfo.aliasId}
			/>
			<InfoRow
				testId="info-row-financialYear"
				label="Year"
				value={projInfo.financialYear}
			/>
			<InfoRow
				testId="info-row-ep" // NOTE: Watch out. This testId is also used to distinguish the item
				label="Engagement Partner"
				value={projInfo.epUsers}
			/>
			<InfoRow
				testId="info-row-clientName"
				label="Client Name"
				value={projInfo.clientName}
			/>
			<InfoRow
				testId="info-row-engType"
				label="Engagement Type"
				value={projInfo.engTypeName}
			/>
			<InfoRow
				testId="info-row-archivedAt"
				label="Archived At"
				value={projInfo.archivedAt}
			/>
			<InfoRow
				testId="info-row-archivedBy"
				label="Archived By"
				value={projInfo.archivedBy?.name}
			/>
			<InfoRow
				testId="info-row-version"
				label="Version"
				value={`v.${projInfo.version}`}
			/>
		</StyledProjectInfo>
	)
}

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	let newValue: string = ""
	if (testId === "info-row-ep") {
		// for ep user list
		// NOTE: Be careful to update the testId
		for (let i = 0; i < value.length; i++) {
			const item = value[i]
			const postFix = i < value.length - 1 ? ", " : ""
			newValue = newValue + item.name + postFix
		}
	}
	return (
		<div className="info-row" data-testid={testId}>
			<div className="label-area">{label}</div>
			<div className="value-area">
				{newValue !== "" ? newValue : value}
			</div>
		</div>
	)
}

const StyledProjectInfo = styled.div`
	h4 {
		margin-bottom: 1rem;
	}
	.info-row {
		display: flex;
		padding: 0rem 0 0.5rem;
		.label-area {
			min-width: 10rem;
			margin-right: 1rem;
			overflow: hidden;
		}
		.value-area {
		}
	}
`
