import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { clearLocalStorageForLobby } from "../../../../../library/token-utils"

const Logout = (self: any) => ({
	logout() {
		// 0.
		const actionName = "logout"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const userEmail = localStorage.getItem("lobbyUserId")

		self.requestLogout(userEmail)
			.then((response: any) => {
				// console.log("logout action in store...")
				// console.log(actionName+ "__response " + response)
				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					self.setLobbyCheckinStatus(ActionStatus.fail)
					// self.setLoginStatus(ActionStatus.fail)
					// NOTE: DO NOT MOVE this localStorage clear part to uppser side. It occurs some error
					// NOTE: Do not clear all things for pageSetting
					clearLocalStorageForLobby()

					// set success response
					// self.handleResponse({
					// 	actionName,
					// 	status: ActionStatus.success,
					// 	code: 200, // temp
					// 	customMessage: "Success to logout, good bye!",
					// 	color: MessageColorType.blue,
					// 	open: true,
					// })

					self.initializeStore()
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage:
							"Something went wrong during logout... Please contact to administrator if this issue be duplicated.",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					openSnackbar: true,
				})
			})
	},
})

export default Logout
