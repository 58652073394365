// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { flow } from "mobx-state-tree"
import { ReadSignedUrlProps } from "../store-model-actions/read-signed-url"
import { S3ObjMethod } from "../../../screens/organization-side/notifications/store/notifications.view-model"

export type GetSignedUrlProps = {
	id: string
	fileName: string
	fileType: string
	extension: string
	size: number
	formattedSize: string
	file: any
}

export type DirectS3UploadProps = {
	url: string
	file: any
}

const GetSignedUrls = (self: any) => ({
	// NOTE: update the file type
	getSignedUrls(files: GetSignedUrlProps[]) {
		// Check the total files size before run this
		const actionName = "getSignedUrls"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		files.map((targetFile: GetSignedUrlProps) => {
			// 1. get signed url for target file
			const targetFileInfo: ReadSignedUrlProps = {
				id: targetFile.id,
				fileType: targetFile.fileType,
				extension: targetFile.extension,
			}
			console.log("targetFileInfo---", targetFileInfo)
			self.readSignedUrl(targetFileInfo).then((response: any) => {
				if (response.status === 200) {
					// 2. upload file to the signed url
					const { uploadURL, key } = response.data
					const uploadInfo: DirectS3UploadProps = {
						url: uploadURL,
						file: targetFile.file,
					}
					self.uploadFileToSignedUrl(uploadInfo)
						.then((response: any) => {
							let itemForGql = {
								key,
								fileName: targetFile.fileName,
								extension: targetFile.extension,
								size: targetFile.size,
								formattedSize: targetFile.formattedSize,
							}
							if (response.status === 200) {
								// 3.
								self.addItemToS3FilesStatus({
									...itemForGql,
									status: ActionStatus.success,
									methodType: S3ObjMethod.put,
								})
							} else {
								self.addItemToS3FilesStatus({
									...itemForGql,
									status: ActionStatus.fail,
									methodType: S3ObjMethod.put,
								})
							}
						})
						.catch((err: Error) => {
							console.log("err:", err)
						})
				}
			})
		})
	},
	uploadFileToSignedUrl: flow(function* (uploadInfo: DirectS3UploadProps) {
		const actionName = "uploadFileToSignedUrl"
		const { url, file } = uploadInfo
		console.log(actionName, "uploadInfo:", uploadInfo)
		const result = yield fetch(url, {
			method: "PUT",
			body: file,
		})
		console.log(actionName, "result:", result)
		return result
	}),
})

export default GetSignedUrls
