import { flow } from "mobx-state-tree"

const RequestMoveEngType = (self: any, moveEngType: any) => ({
	requestMoveEngType: flow(function* (
		engTypeId: number,
		from: number,
		to: number
	) {
		const actionName = "requestMoveEngType"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const params = {
			EngagementId: engTypeId,
			SourceArchivePolicyId: from,
			TargetArchivePolicyId: to,
		}
		try {
			const response = yield moveEngType(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestMoveEngType
