import { types } from "mobx-state-tree"

import ReadArchiveHistoryList from "./model-actions/read-archive-history-list"

const ArchiveHistoryModel = (apiRead: any) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadArchiveHistoryList(self, read))

export default ArchiveHistoryModel
