import { types, flow } from "mobx-state-tree"
import ReadTrialBalanceDetails from "../../financial-statements/store/model-actions/read-trial-balance-details"
import { PassedAdjustmentsApiProps } from "./passed-adjustments.apis"
import ReadPassedAdjustments from "./model-actions/read-passed-adjustments"
import ReadWPReferences from "../../adjustments/store/model-actions/read-wp-references"
import CreatePassedAdjustment from "./model-actions/create-passed-adjustment"
import DeletePassedAdjustment from "./model-actions/delete-passed-adjustment"
import ReadPassedAdjustmentUrl from "./model-actions/read-passed-adjustment-url"

const AdjustmentsModel = ({
	apiRead,
	apiReadTrialBalance,
	apiReadWP,
	apiCreate,
	apiDelete,
}: PassedAdjustmentsApiProps) =>
	types
		.model({})
		.actions((self) => ({
			// -p----- Read
			// ------ Update
		}))
		.actions((self, read = apiRead) => ReadPassedAdjustments(self, read))
		.actions((self, readTrialBalance = apiReadTrialBalance) =>
			ReadTrialBalanceDetails(self, readTrialBalance)
		)
		.actions((self, readWP = apiReadWP) => ReadWPReferences(self, readWP))
		.actions((self, create = apiCreate) =>
			CreatePassedAdjustment(self, create)
		)
		.actions((self, del = apiDelete) => DeletePassedAdjustment(self, del))
		.actions(ReadPassedAdjustmentUrl)

export default AdjustmentsModel
