import { types } from "mobx-state-tree"
// ---------- common models

const MyAccountUiHelper = types
	.model({
		changePwDialogOpen: false,
	})
	.actions((self) => ({
		setChangePwDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.changePwDialogOpen = request
			} else {
				self.changePwDialogOpen = !self.changePwDialogOpen
			}
		},
	}))
	.views((self) => ({}))

export default MyAccountUiHelper
