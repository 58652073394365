import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import {
	OrgArchivePolicy,
	ArchivePolicyFlatItem,
	ArchivePolicyDetail,
} from "./data-models/org-archive-policies.data-models"
// separated actions
import GetArchPolicies from "./view-model-actions/get-archive-policies"
import AddArchivePolicy from "./view-model-actions/add-archive-policy"
import MoveArchiveConditions from "./view-model-actions/move-archive-conditions"
import RemoveArchivePolicy from "./view-model-actions/remove-archive-policy"
import CopyArchivePolicy from "./view-model-actions/copy-archive-policy"
import EditArchivePolicy from "./view-model-actions/edit-archive-policy"
import ResetArchivePolicy from "./view-model-actions/reset-archive-policy"
import MoveEngType from "./view-model-actions/move-eng-type"
//
import {
	// compareValues,
	getTreeFromFlatData,
} from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { initialStore } from "./org-archive-policies.provider"

const OrgArchivePoliciesViewModel = types
	.model({
		// archPolicies: types.array(OrgArchivePolicy),
		flatList: types.array(ArchivePolicyFlatItem),
		policyDetail: types.map(ArchivePolicyDetail),
		// common parts
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		// resetList() {
		// 	self.archPolicies.length = 0
		// },
		// setList(list: any) {
		// 	self.archPolicies.length = 0
		// 	self.archPolicies = list
		// },
		pushItemToList(id: any, item: any) {
			self.policyDetail.set(id, item)
		},
		pushItemToFlatList(item: any) {
			self.flatList.push(item)
		},
		spliceItemFromFlatlist(id: string) {
			self.flatList.splice(
				self.flatList.findIndex((item: any) => item.id === id),
				1
			)
		},
		toggleExpand(id: string, expanded?: boolean) {
			const target = self.flatList.find((item) => item.id === id)
			if (target) {
				target.expanded = expanded ? expanded : !target.expanded
			}
		},
		setPolicyDetail(detail: any) {
			self.policyDetail.put(detail)
		},
		isDuplicatedTitle(name: string) {
			if (self.flatList.find((item: any) => item.title === name)) {
				return true
			} else {
				return false
			}
		},
		editPolicyInfo(title: string, description: string, policyId: string) {
			const index = self.flatList.findIndex(
				(item: any) => item.id === policyId
			)
			self.flatList[index].title = title
			self.flatList[index].description = description
		},
		resetPolicyToDefault(policyId: string) {
			const defaultPolicyId = self.flatList.find(
				(item: any) => item.title === "Default Archive Policy"
			)?.id

			const targetPolicyDetail = self.policyDetail.get(policyId)

			if (defaultPolicyId) {
				const defaultConditions =
					self.policyDetail.get(defaultPolicyId)?.conditions
				if (defaultConditions && targetPolicyDetail) {
					targetPolicyDetail.conditions = JSON.parse(
						JSON.stringify(defaultConditions)
					)
				} else {
					alert("Sorry, default policy has no conditions")
				}
			} else {
				alert("Sorry, cannot find the default policy")
			}
		},
		moveEngTypeInFlatList(engTypeId: string, newPolicyId: string) {
			const index = self.flatList.findIndex(
				(item: any) => item.id === engTypeId
			)
			self.flatList[index].parentId = newPolicyId
		},
		setPolicyConditions(policyId: string, policyConditions: any) {
			const targetPolicyDetail = self.policyDetail.get(policyId)
			if (targetPolicyDetail) {
				targetPolicyDetail.conditions = policyConditions
			}
		},
	}))
	// AGER (Add, Get, Edit, Remove)
	.actions(GetArchPolicies)
	.actions(AddArchivePolicy)
	.actions(EditArchivePolicy)
	.actions(MoveArchiveConditions)
	.actions(RemoveArchivePolicy)
	.actions(CopyArchivePolicy)
	.actions(ResetArchivePolicy)
	.actions(MoveEngType)
	//
	.views((self) => ({
		// get archPoliciesLength() {
		// 	return self.archPolicies.length
		// },
		// get policyTreeForNav() {
		// 	let virtualflatList: any[] = []
		// 	self.archPolicies.map((policy) => {
		// 		const policyForFlat = {
		// 			id: policy.id,
		// 			parentId: null,
		// 			title: policy.title,
		// 			expanded: false,
		// 			type: "a",
		// 		}
		// 		virtualflatList.push(policyForFlat)
		// 		policy.engTypes.map((engType) => {
		// 			const engTypeForFlat = {
		// 				id: engType.id,
		// 				parentId: policy.id,
		// 				title: engType.title,
		// 				expanded: false,
		// 				type: "b",
		// 			}
		// 			virtualflatList.push(engTypeForFlat)
		// 		})
		// 	})
		// 	return getTreeFromFlatData(virtualflatList, "title")
		// },
		get simpleTreeForNav() {
			return getTreeFromFlatData(self.flatList)
		},
	}))
	// common actions
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgArchivePoliciesViewModel
