import { flow } from "mobx-state-tree"

const ReadNotificationSettings = (self: any, read: any) => ({
	readNotificationSettings: flow(function* () {
		const actionName = "readNotificationSettings"
		try {
			const response = yield read()

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default ReadNotificationSettings
