import { useState, useEffect, useCallback } from "react"

// interface useFormProps {
// 	inputsSchema: any
// 	validationSchema: any
// 	requestedAction: any
// 	resetPoint?: any
// }

// function useForm({
// 	inputsSchema,
// 	validationSchema = {},
// 	requestedAction,
// 	resetPoint
// }: useFormProps) {

// validationSchema: {
// 	[x:string] : {
// 		isRequired: boolean,
// 		validator: {
// 			regEx,
// 			error: string | ReactElement
// 		},
// 		maxLength?: number
// 	}
// }

function useForm(
	inputsSchema: any,
	validationSchema: any, // @Noah: updates is required
	requestedAction: any,
	resetPoint?: any
) {
	const [inputs, setInputs] = useState(inputsSchema)
	const [isReady, setIsReady] = useState(false)

	// Deactivate the action button on the first rendering
	useEffect(() => {
		if (resetPoint) {
			setIsReady(false)
			setInputs(inputsSchema)
		}
	}, [resetPoint])

	// For every change of inputs, validate those entire values to activate the action button.
	useEffect(() => {
		setIsReady(!validateInputs()) // validateInputs() returns true if there are any errors
	}, [inputs])

	const initializeInputs = useCallback(() => {
		setInputs(inputsSchema)
	}, [])

	const validateInputs = useCallback(() => {
		// +) Object.keys() returns an array of the given objects' property names
		// +) Array.some() executes the callback to find the first matched value
		const checkErrorInInputs = Object.keys(validationSchema).some((key) => {
			const isThisInputRequired = validationSchema[key].isRequired
			const inputValue = inputs[key].value
			const inputValueLength = inputs[key].value
				.replace(/&nbsp;/g, " ")
				.replace(/ /g, "").length
			const inputError = inputs[key].error

			return (
				(isThisInputRequired && !inputValue) ||
				(isThisInputRequired && inputValueLength === 0) ||
				inputError
			)
		})

		return checkErrorInInputs
	}, [inputs, validationSchema])

	const handleOnChange = useCallback(
		(event: any) => {
			const name = event.target.name
			const value = event.target.value

			let error = ""
			let requestInput = false

			// 1. if it has validation schema
			if (validationSchema[name]) {
				// 2. check its validator
				if (
					validationSchema[name].validator !== null &&
					typeof validationSchema[name].validator === "object"
				) {
					// 3. validate its value
					if (
						value &&
						!validationSchema[name].validator.regEx.test(value)
					) {
						error = validationSchema[name].validator.error
					}
					if (!value && validationSchema[name].isRequired) {
						requestInput = true
					}
					if (
						value.length === 0 &&
						validationSchema[name].isRequired
					) {
						requestInput = true
					}
					if (
						value.replace(/ /g, "").length === 0 &&
						validationSchema[name].isRequired
					) {
						requestInput = true
					}
				}
				if (validationSchema[name].maxLength) {
					if (value.length > validationSchema[name].maxLength) {
						error = "Exceeded the limited number of characters"
					}
				}
			}

			setInputs((prevInputs: any) => ({
				...prevInputs,
				[name]: { value, error, requestInput },
			}))
		},
		[validationSchema]
	)

	const handleOnSubmit = useCallback(
		(event: any) => {
			event.preventDefault()

			// check one more time before calling the action
			if (!validateInputs()) {
				requestedAction(inputs)
			}
		},
		[inputs, requestedAction]
	)

	return { inputs, isReady, handleOnChange, handleOnSubmit, initializeInputs }
}

export default useForm
