import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../../../library/api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetNotificationSettings",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type UpdateNotification = {
	IsActive: boolean
	ParameterName: string
	ParameterValue: string
	ParameterValueUI: string
	ParentId: string | null
	SettingId: string
}

export async function update(params: UpdateNotification[]) {
	const response = await postRequest({
		url: "/UpdateNotificationSettings",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type NotificationSettingsApiProps = {
	apiRead?: () => Promise<AxiosResponse<any>>
	apiUpdate?: (param: UpdateNotification[]) => Promise<AxiosResponse<any>>
}
