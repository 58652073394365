// ---------- common models
import {
    ActionStatus,
    MessageColorType,
    DLObjectFormat,
} from "../../../../../../common-models/enumerations/common-enums"
import {
    IdType,
    idToString,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetOrgGroupList = (self: any) => ({
    getOrgGroupList(userId: string, additionalAction?: any) {
        // 0.
        const actionName = "getOrgGroupList"
        // 1.
        // 2.
        self.responses.setResponse(actionName, {
            actionName,
            status: ActionStatus.loading,
        })
        // 3. api call
        self.readOrgGroupList(userId)
            .then((response: any) => {
                if (response.status === 200 && response.data.Status === 1) {
                    // self.resetFlatList()
                    const fetchedGroups = response.data.Grplst
                    let newGroupList: any[] = []
                    fetchedGroups.map((group: any) => {
                        // 2-1) general group list
                        // 2-2) flatted group (and sub items) list
                        const reOrganizedGroup = {
                            id: idToString(group.GroupID, IdType.group),
                            title: group.GroupName,
                            parentId: null,
                            createdBy: group.CreatedUser, // NOTE:
                            createdAt: group.CreatedDate,
                            expanded: false,
                            type: DLObjectFormat.group,
                            detail: null,
                        }
                        newGroupList.push(reOrganizedGroup)
                        // self.pushItemToList(reOrganizedGroup)
                    })
                    self.setGroupList(newGroupList)
                    self.setNeedRefresh(false)

                    additionalAction && additionalAction()
                    // when success
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.success,
                        code: 200,
                        color: MessageColorType.blue,
                        open: true,
                        autoHide: true,
                    })
                } else {
                    // when fail
                    self.handleResponse({
                        actionName,
                        status: ActionStatus.fail,
                        code: 999,
                        color: MessageColorType.orange,
                        open: true,
                    })
                }
            })
            .catch((error: Error) => {
                self.handleViewModelError({
                    error,
                    actionName,
                    open: true,
                })
            })
    },
})

export default GetOrgGroupList
