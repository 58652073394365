import { types } from "mobx-state-tree"

export const OrgSetupGroup = types.model({
	groupId: types.string, // [GroupID], ex) GroupID: 805
	groupName: types.string, // [GroupName], ex) GroupName: "(0) Release 본부 update"
	userId: types.string, // [UserID], ex) UserID: 16
	createdBy: types.string, // [CreatedBy], ex) CreatedBy: "cskim 10/14/2019"
	userCount: types.number, // [UserCount], ex) UserCount: 4
	assignedUsers: types.string, // [AssignedUsers], ex) AssignedUsers: "홍길동,전우치,신사임당"
	clientsCount: types.number, // [ClientsCount], ex) ClientsCount: 3
	assignedClients: types.string, // [AssignedClients], ex) AssignedClients: "데모식품,데모전자"
})

export const UserList = types.model({
	id: types.string, // [UserID], ex) 11088
	name: types.string, // [Name], ex) "bhanus"
	email: types.string, //[Email], ex) "bhanus@yopmail.com"
	title: types.union(types.string, types.null), //[Title]
	aliasId: types.string, //[UserName] ex) "BS" // TODO: @Prudhvi-0817: please check is this mean the aliasId. and if yes, please update as 'aliasId' and also the related parts
	accessTypeId: types.string, //[AccessTypeId] ex) 3
	isGrayed: types.boolean, // [IsGrayed] ex)false
	selected: types.boolean,
})

export const ProjectList = types.model({
	clientName: types.string,
	clientAliasId: types.string, // TODO: @Prudhvi-0817: is this client alias Id mean the client alias ID? or project alias ID??
	//
	financialYear: types.number,
	periodName: types.string, // TODO: @Prudhvi-0817: Please check that is this mean the period end or period type name and update properly
	//
	projectName: types.string,
	projectStatus: types.string, // TODO: @Prudhvi-0817: Please update as 'projectStatus' and related parts
})

export const AssignedProjList = types.model({
	name: types.string,
	projectList: types.array(ProjectList),
	userName: types.string,
})

export const ClientList = types.model({
	id: types.string, // [ClientMasterID]
	name: types.string, // [ClientName], ex) "Smokeclient"
	clientAliasId: types.string, // [ClientId] ex) 11088 // TODO: @Prudhvi-0817: Please check is this mean the clientAliasId, and if yes, please update as "clientAliasId" or "aliasId"
	selected: types.boolean,
})
