import { types } from "mobx-state-tree"
import RequestProjToken from "./model-actions/request-proj-token"
import ReadProjInfo from "../../project-information/store/model-actions/read-proj-info" // NOTE: Share the proj-info store model-action
import ReadProjSettings from "./model-actions/read-proj-settings"
import ReadProjAssignedRole from "./model-actions/read-proj-assigned-role"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// import ReadProjRoleSet from "../../project-setup-roles/store/model-actions/read-proj-role-set"
import { ProjCheckinApiProps } from "./proj-checkin.apis"
import ReadRoleSet from "../../../organization-side/org-access-control/org-checkin/store/model-actions/read-role-set"
import ReadPermissionSet from "../../../organization-side/org-access-control/org-checkin/store/model-actions/read-permission-set"

const ProjCheckinModel = ({
	apiRequestToken,
	apiReadProjInfo,
	apiReadProjSettings,
}: ProjCheckinApiProps) =>
	types
		.model({})
		.actions((self) => RequestProjToken(self, apiRequestToken))
		.actions((self) => ReadProjInfo(self, apiReadProjInfo))
		.actions((self) => ReadProjSettings(self, apiReadProjSettings))
		// .actions(ReadProjAssignedRole)
		.actions(ReadRoleSet)
		.actions(ReadPermissionSet)

		// from other store..
		// .actions(ReadProjRoleSet)
		// common model actions
		.actions(CommonModelActions)

export default ProjCheckinModel
