import { flow } from "mobx-state-tree"

const ReadConditionDetail = (self: any, readDetail: any) => ({
	readConditionDetail: flow(function* (conditionId) {
		const actionName = "readConditionDetail"
		try {
			const response = yield readDetail(conditionId)

			return response
		} catch (error) {
			// console.log(actionName,"__ error", error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default ReadConditionDetail
