import { ActionStatus, PostType } from "@datalobby/types"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"

const GetOrgNotiList = (self: any) => ({
	getOrgNotiList(orgId: string, deleted?:boolean) {
		const actionName = "getOrgNotiList"

		self.responses.putResponse({
			actionName,
			status: ActionStatus.loading,
		})
		// 2) request recent project list to Model
		self.readOrgNotiList(orgId, deleted)
			.then((response: any) => {
				console.log(actionName, "response: ", response)
				const list = response
				let newList: PostType[] = []

				if (list) {
					list.map((item: any) => {
						newList.push({
							menu: item.menu,
							id: item.id,
							attachments: item.attachments || [],
							createdAt: item.createdAt,
							createdBy: item.createdBy,
							fixToTop:
								item.fixToTop !== null ? item.fixToTop : false,

							modifiedAt: item.modifiedAt || "",
							modifiedBy: item.modifiedBy || "",
							parent: item.parent,
							priority: item.priority || "MEDIUM",
							sector: item.sector,
							title: item.title,
							content: item.content,
							// visitorCount:
							// 	item.visitorCount !== null ? item.visitorCount : 0,
							// visitors: item.visitors || [],
							// whoCanRead: item.whoCanRead || "",
							// allowedTargets: item.allowedTargets || [],
							deleted: item.deleted,
						})
					})
					self.setOrgNotiList(newList)
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						customMessage:
							"Successfully fetched the organization notifications",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						customMessage:
							"There are no organization notifications",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					open: true,
				})
			})
	},
})

export default GetOrgNotiList
