import { AxiosResponse } from "axios"
import {
	ReadClientsByGroupApiProps,
	DeleteGroupItemApiProps,
} from "../../../service-modules/group-module/org-group-shared-apis"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
} from "../../../library/api-requests"

export function read(payload: {
	PageIndex?: number
	PageSize: number
	GroupID?: number
	UserID?: number
	ClientID?: number
}) {
	const response = postRequest({
		url: "/GetGroupsMaster",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type UnassignedUsers = {
	Lang: string
	GroupID: number
}

export function readUnassignedUsersOfGroup(params: UnassignedUsers) {
	const response = getRequest({
		url: "/GetUsers",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type UserData = {
	UserID: number
}

type AssignUsers = {
	GroupID: number
	Userlst: UserData[]
}

export function assignUsersToGroup(payload: AssignUsers) {
	const endpoint = "/AssignUsersToGroup"
	const response = postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

type UnassignUsers = {
	GroupID: number
	IsVerified: boolean
	Userlst: UserData[]
}

export function unAssignUsersToGroup(payload: UnassignUsers) {
	const endpoint = "/UnAssignUsersToGroup"
	const response = putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

type ClientsList = {
	ClientMasterID: string
}

type AssignClients = {
	GroupID: number
	Type: number
	ClientsList: ClientsList[]
}

export function assignClientsToGroup(payload: AssignClients) {
	const endpoint = "/AssignClientsToGroup"
	const response = postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

// TODO: this props will be integrated with org-group-shared-apis
// and org-group-shared-apis.call-props

export type OrgSetupGroupsApiProps = {
	apiRead: (payload: {
		PageIndex?: number
		PageSize: number
		GroupID?: number
		UserID?: number
		ClientID?: number
	}) => Promise<AxiosResponse<any>>
	apiUpdate: (params: {
		CabinetName: string
		CabinetID: number
	}) => Promise<AxiosResponse<any>>
	apiDelete: (params: DeleteGroupItemApiProps) => Promise<AxiosResponse<any>>
	apiCreate: (params: {
		CabinetName: string
		FolderType: number // create group use 'FolderType: 1'
		ParentID: number
	}) => Promise<AxiosResponse<any>>
	apiReadUnassignedUsersOfGroup: (
		payload: UnassignedUsers
	) => Promise<AxiosResponse<any>>
	apiReadAssignedUsersOfGroup: (payload: {
		Lang: string
		GroupID: number
		type: number
		searchText: string
	}) => Promise<AxiosResponse<any>>
	apiAssignUsersToGroup: (payload: AssignUsers) => Promise<AxiosResponse<any>>
	apiUnAssignUsersToGroup: (
		payload: UnassignUsers
	) => Promise<AxiosResponse<any>>
	apiReadClientsByGroup: (
		payload: ReadClientsByGroupApiProps
	) => Promise<AxiosResponse<any>>
	apiAssignClientsToGroup: (
		payload: AssignClients
	) => Promise<AxiosResponse<any>>
}
