import React from "react"
import { useLocalObservable } from "mobx-react-lite"
import { types } from "mobx-state-tree"

const EntityInformationState = types
	.model({
		isToolbarOpen: types.boolean,
		test: types.string,
	})
	.actions((self) => ({
		setToolbarOpen(request: boolean) {
			self.isToolbarOpen = request
		},
	}))

const EntityInformationStore = EntityInformationState.create({
	isToolbarOpen: true,
	test: "Hi....",
})

const createPageStore = () => {
	const store = {
		entityInformationStore: EntityInformationStore,
	}
	return store
}

type TPageStore = ReturnType<typeof createPageStore>

const pageStoreContext = React.createContext<TPageStore | null>(null)

const PageStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const pageStore = useLocalObservable(createPageStore)

	return (
		<pageStoreContext.Provider value={pageStore}>
			{children}
		</pageStoreContext.Provider>
	)
}

const usePageStore = () => {
	const pageStore = React.useContext(pageStoreContext)
	if (!pageStore) {
		throw Error("Page Store shuldn't be null")
	}
	return pageStore
}

export { PageStoreProvider, usePageStore }
