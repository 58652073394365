import { flow } from "mobx-state-tree"

const MUnmapSubGroupFromGroup = (self: any, unmapSubGroup: any) => ({
	mUnmapSubGroupFromGroup: flow(function*(payload: { MapId: number }) {
		const actionName = "MUnmapSubGroupFromGroup"
		try {
			const response = yield unmapSubGroup(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default MUnmapSubGroupFromGroup
