import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./eliminating-entry.provider"
import {
	EEDetails,
	AccountDetails,
} from "./data-models/eliminating-entry.data-model"
import GetEliminatingEntryDetails from "./view-model-actions/get-eliminating-entry-details"
import GetTrialBalanceDetails from "../../financial-statements/store/view-model-actions/get-trial-balance-details"
import { TBDetails } from "../../financial-statements/store/data-models/financial-statement.data-model"
import GetAccountDetails from "./view-model-actions/get-account-details"
import AddEliminatingEntry from "./view-model-actions/add-eliminating-entry"
import RemoveEliminatingEntry from "./view-model-actions/remove-eliminating-entry"
import EditEliminatingEntry from "./view-model-actions/edit-eliminating-entry"

const EliminatingEntryViewModel = types
	.model({
		eeDetails: types.array(EEDetails),
		tbDetails: types.array(TBDetails),
		accountDetails: types.array(AccountDetails),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setEEDetails(details: any) {
			self.eeDetails = details
		},
		pushItemToEEDetails(item: any) {
			self.eeDetails.push(item)
		},
		updateEEDetails(details: any) {
			const index = self.eeDetails.findIndex(
				(ee: any) => ee.eeId === details.eeId
			)
			self.eeDetails[index] = { ...self.eeDetails[index], ...details }
		},
		setTBDetails(details: any) {
			self.tbDetails = details
		},
		setAccountDetails(details: any) {
			self.accountDetails = details
		},
		spliceItemFromEEDetails(eeId: number) {
			const index = self.eeDetails.findIndex(
				(ee: any) => ee.eeId === eeId
			)
			self.eeDetails.splice(index, 1)
		},
	}))
	//
	.actions(GetEliminatingEntryDetails)
	.actions(GetTrialBalanceDetails)
	.actions(GetAccountDetails)
	.actions(AddEliminatingEntry)
	.actions(RemoveEliminatingEntry)
	.actions(EditEliminatingEntry)
	//
	.views((self) => ({
		viewEEDetails() {
			return self.eeDetails
		},
		viewEEDetailsById(eeId: string) {
			return self.eeDetails.find((ee: any) => ee.eeId === eeId)
		},
		viewTBDetails() {
			let tbDetails: any[] = []
			self.tbDetails.map((item) => {
				tbDetails.push({
					name: item.tbName,
					value: item.tbId,
				})
			})
			return tbDetails
		},
		viewAccountDetails() {
			let accountDetails: any[] = []
			self.accountDetails.map((item) => {
				accountDetails.push({
					name: item.accountNumber,
					value: item.accountNumber,
					desc: item.accountDesc,
				})
			})
			return accountDetails
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default EliminatingEntryViewModel
