import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const RemoveRiskAssessment = (self: any) => ({
	removeRiskAssessment(riskAssessmentId: string) {
		// 0.
		const actionName = "removeRiskAssessment"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const raId = idToNumber(riskAssessmentId, IdType.ra)
		const payload = {
			id: raId,
		}
		// ConsoleLog(payload)
		self.deleteRiskAssessment(payload)
			.then((response: any) => {
				ConsoleLog([actionName, "__response ", response])
				if (response.data.Status === 1) {
					self.removeRiskAssessmentStore(riskAssessmentId)
					// close the dialog
					self.setRemoveRiskAssessmentDialogOpen(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to remove the Risk Assessment",
						open: true,
						autoHide: true,
					})
				} else {
					if (response.data.AssignedProjectsInfo) {
						self.resetProjList()
						response.data.AssignedProjectsInfo.map((proj: any) => {
							const reOrganizedProjDetails = {
								periodName: proj.PeriodName,
								year: proj.Year,
								clientName: proj.ClientName,
								projectName: proj.ProjectName,
								projectAliasId: proj.ProjectBusinessId,
								projectStatus: proj.ProjectTypeName,
							}
							self.pushItemToProjList(reOrganizedProjDetails)
						})
						self.setRemoveRiskAssessmentDialogOpen(false)
						self.setCannotRemoveDialogOpen(true)
					}
					// set fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.Message,
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemoveRiskAssessment
