// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const RepairReplica = (self: any) => ({
	repairReplica(
		projId: string,
		replicaId: string,
		orgId: string,
		userId: string
	) {
		// 0.
		const actionName = "repairReplica"
		let proceed = window.confirm(
			"Are you sure you want to repair this replica?"
		)
		if (!proceed) {
			return
		}
		// 1.
		// NOTE: if this function requires 'proceed', must set it before set response status as loading
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// self.handleResponse({
		// 	actionName,
		// 	status: ActionStatus.loading,
		// 	code: 200,
		// 	color: MessageColorType.green,
		// 	customMessage: actionName+ " is in progress...",
		// 	open: true,
		// })
		// 3.
		const projectNumId = idToNumber(projId, IdType.project)
		const replicaNumId = idToNumber(replicaId, IdType.project)
		const orgNumId = idToNumber(orgId, IdType.org)
		const userNumId = idToNumber(userId, IdType.user)

		self.requestRepairReplica(
			projectNumId,
			replicaNumId,
			orgNumId,
			userNumId
		)
			.then((response: any) => {
				// This API has new response structure
				if (
					response.status === 200 &&
					response.data.status.code === 1
				) {
					const serverMsg = response.data.status.message
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
					self.setRepairReplicaDialog(false)
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
	relayRepairReplica(
		projList: any,
		index: number,
		userId: number,
		orgId: number
	) {
		const delay = 60000
		if (projList.length >= index + 1) {
			const project = projList[index]
			// NOTE: WARNING: NOTE: WARNING: NOTE: WARNING:
			const orgNumId = orgId

			// NOTE: WARNING: NOTE: WARNING: NOTE: WARNING:
			self.requestRepairReplica(
				project.id,
				project.replicaId,
				orgNumId,
				userId
			)
				.then((response: any) => {
					if (
						response.status === 200 &&
						response.data.status.code === 1
					) {
						console.log(
							index,
							"success to request repair replica for",
							project.id,
							project.title
						)
						if (projList.length > index + 1) {
							setTimeout(() => {
								self.relayRepairReplica(
									projList,
									index + 1,
									userId,
									orgId
								)
							}, delay)
						}
						if (projList.length === index + 1) {
							console.log("THE END")
						}
					} else {
						console.log(
							"failed to request repair replica for",
							project.id,
							project.title
						)
						if (projList.length > index + 1) {
							setTimeout(() => {
								self.relayRepairReplica(
									projList,
									index + 1,
									userId,
									orgId
								)
							}, delay)
						}
						if (projList.length === index + 1) {
							console.log("THE END")
						}
					}
				})
				.catch((err: Error) => {
					if (projList.length > index + 1) {
						setTimeout(() => {
							self.relayRepairReplica(
								projList,
								index + 1,
								userId,
								orgId
							)
						}, delay)
					}
					if (projList.length === index + 1) {
						console.log("THE END")
					}
				})
		}
	},
})

export default RepairReplica
