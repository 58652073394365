const FilterActions = (self: any) => ({
    setClientFilters(clients:any){
        self.clientFilters = clients
    },
    setEngTypeFilters(engTypes:any){
        self.engTypeFilters = engTypes
    },
    setEPUsersFilters(epUsers:any){
        self.epUsersFilters = epUsers
    },
    setQCUsersFilters(qcUsers:any){
        self.qcUsersFilters = qcUsers
    },
    setGroupFilters(groups:any){
        self.groupFilters = groups
    },
    setPeriodFilters(periods:any){
        self.periodFilters = periods
    },
})

export default FilterActions