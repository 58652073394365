import React from "react"
import styled from "styled-components"
import { DLIconButton } from "../../basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"

type PageHeaderProps = {
	// children: React.ReactChildren
	children?: any // TODO: update is required on the type and component
	hasCtxBtn?: boolean
	ctxMenuOpen?: (e: any) => void
	noPadding?: boolean
}

export default function PageHeader({
	children,
	hasCtxBtn,
	ctxMenuOpen,
	noPadding,
}: PageHeaderProps) {
	return (
		<StyledContainer
			className="FR AC page-header"
			data-testid="dl-page-header"
			style={{ padding: noPadding ? 0 : undefined }}
		>
			{children.length === 2 ? (
				<div className="FR AC JSB with-2-children">
					<div className="FR AC dl-page-header-leftside">
						{children[0]}
					</div>{" "}
					<div className="FR AC JR dl-page-header-rightside">
						{children[1]}
						{hasCtxBtn && ctxMenuOpen && (
							<DLIconButton
								eleTestId="page-header-ctx-btn"
								clickHandler={(e: any) => ctxMenuOpen(e)}
							>
								<Icon path={mdiDotsHorizontal} size={0.8} />
							</DLIconButton>
						)}
					</div>
				</div>
			) : (
				<div className="FR AC JSB with-single-child">
					{children}{" "}
					{hasCtxBtn && ctxMenuOpen && (
						<DLIconButton
							eleTestId="page-ctx-btn"
							clickHandler={(e: any) => ctxMenuOpen(e)}
						>
							<Icon path={mdiDotsHorizontal} size={0.8} />
						</DLIconButton>
					)}
				</div>
			)}
		</StyledContainer>
	)
}

const StyledContainer = styled.div`
	height: calc(var(--base-unit) * 2);
	margin-bottom: 0.5rem;
	// padding: 0 var(--spacing-sm) 0 var(--spacing-xl);
	width: 100%;
	.with-2-children {
		width: 100%;
	}
	.with-single-child {
		width: 100%;
	}
`
