import React from "react"
import styled from "styled-components"
import { DLAvatar } from "@datalobby/components"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLChip, ConsoleLog } from "../../../../components/basic-elements"
import { mdiEye } from "@mdi/js"
import Icon from "@mdi/react"

export default function ProjRoleCard({ role }: { role: any }) {
	ConsoleLog(" ====***** ProjRoleCard *****===== ")
	const projStore = useProjStore()

	let accessibleMenus: any[] = []
	role.menuPermissions.map((menu: any) => {
		menu.accessible && accessibleMenus.push(menu)
	})

	return (
		<StyledProjRoleCard
			onClick={() => projStore.roles.setSelectedRole(role.roleId)}
		>
			<div className="FR AC role-icon">
				<DLAvatar
					text={role.shortName}
					color={role.color}
					eleTestId={`role-icon-${role.roleId}`}
					fontSize={12}
				/>
				<div
					className={`count ${role.users.length > 0 && "have-users"}`}
				>
					x {role.users.length}
				</div>
			</div>
			<div className="role-name heading3">{role.name}</div>
			<div className="high-priority-permissions">
				{role.globalPermissions.map(
					(item: { actionName: string; available: boolean }) => {
						return (
							item.available && (
								<div key={item.actionName}>
									<DLChip
										label={item.actionName}
										eleTestId="readonly-chip"
										icon={<Icon path={mdiEye} size={0.6} />}
									/>
								</div>
							)
						)
					}
				)}
			</div>
			<div className="menus">
				Accessible Menus: {accessibleMenus.length}
			</div>
			<div className="users">
				{role.users.map((user: any) => {
					return (
						<div key={user.id}>
							<DLChip
								label={user.name}
								eleTestId={`username-chip-${user.name}`}
								variant="outlined"
							/>
						</div>
					)
				})}
			</div>
		</StyledProjRoleCard>
	)
}

const StyledProjRoleCard = styled.div`
	width: 12rem;
	min-height: 10rem;
	border-radius: 3px;
	padding: 1rem;
	box-shadow: 0px 2px 4px 1px ${(props) => props.theme.shadowColorLight};
	margin: 1rem;
	background-color: ${(props) => props.theme.background};
	transition: 0.4s;
	cursor: pointer;
	.role-icon {
		.count {
			margin-left: 0.5rem;
			color: ${(props) => props.theme.shade20};
			&.have-users {
				font-weight: 500;
				color: ${(props) => props.theme.textHighEmphasis};
			}
		}
	}
	.role-name {
		min-height: ${(props) => props.theme.shared.baseUnit};
		margin: 0.5rem 0 1rem;
	}
	.high-priority-permissions {
		min-height: 4rem;
	}
	.menus {
		padding-bottom: 1rem;
	}
	.users {
		border-top: 1px solid ${(props) => props.theme.shade20};
		padding-top: 1rem;
	}
	:hover {
		background-color: ${(props) => props.theme.secondary};
	}
`
