import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	deleteRequest,
	postRequest,
} from "../../../../../library/api-requests"

export async function read(params: {
	clientId: number
	// CabinetId: string
	// FinancialYear: string
	// Lang: string
	tbnameId: number
}) {
	const response = await getRequest({
		url: "/GetPaje",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readWP(params: { ClientId: number }) {
	const response = await getRequest({
		url: "/GetWorkReferences",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function create(payload: {
	adjDescription: string
	adjustmentType: string
	// CabinetId: number
	clientId: number
	// FinancialYear: string
	tbnameId: number
	// UserID: number
	wprid: string
}) {
	const response = await postRequest({
		url: "/SavePaje",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function del(payload: { id: number }) {
	const response = await deleteRequest({
		url: "/DeletePaje",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type PassedAdjustmentsApiProps = {
	apiRead: (payload: {
		clientId: number
		// CabinetId: string
		// FinancialYear: string
		// Lang: string
		tbnameId: number
	}) => Promise<AxiosResponse<any>>
	apiReadTrialBalance: (payload: {
		clientId: number
	}) => Promise<AxiosResponse<any>>
	apiReadWP: (payload: { ClientId: number }) => Promise<AxiosResponse<any>>
	apiCreate: (payload: {
		adjDescription: string
		adjustmentType: string
		// CabinetId: number
		clientId: number
		// FinancialYear: string
		tbnameId: number
		// UserID: number
		wprid: string
	}) => Promise<AxiosResponse<any>>
	apiDelete: (payload: { id: number }) => Promise<AxiosResponse<any>>
}
