import React from "react"
import { Avatar } from "@material-ui/core"
import Icon from "@mdi/react"
import { mdiAccount } from "@mdi/js"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLTooltip } from "@datalobby/components"

export default observer(function ProjSideProfileButton() {
	const store = useRootStore()
	const projStore = useProjStore()

	// TODO: for next step (need to remove prevRight)
	// WARNING: Do not remove 2 lines below to remember
	// const color = projStore.checkin.assignedRole.color
	// const shortName = projStore.checkin.assignedRole.shortName

	const color = projStore.checkin.user.roleColor
	const shortName = projStore.checkin.user.roleShortName
	const roleName = projStore.checkin.assignedRole.name
	return (
		<div className="FR AC profile-btn">
			<DLTooltip title={roleName} arrow>
				<Avatar
					className="role-avatar"
					style={{
						color: color,
						border: `1px solid ${color}`,
					}}
				>
					{shortName}
				</Avatar>
			</DLTooltip>

			<Avatar className="user-avatar">
				<Icon path={mdiAccount} size={0.8} color="white" />
			</Avatar>
		</div>
	)
})
