import { flow } from "mobx-state-tree"

const DeleteProjTrash = (self: any, remove: any, removeAll: any) => ({
	// NOTE: because 'delete' is reserved word, using 'remove'
	deleteProjTrashItem: flow(function* (params) {
		const actionName = "deleteProjTrashItem"
		try {
			const response = yield remove(params)
			// console.log(actionName, "__ response", response)

			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
	deleteProjTrashItems: flow(function* (params) {
		const actionName = "deleteAllProjTrashItems"
		try {
			const response = yield removeAll(params)
			// console.log(actionName, "__ response", response)

			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default DeleteProjTrash
