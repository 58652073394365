import { flow } from "mobx-state-tree"

const ReadNotes = (self: any, apiRead: any) => ({
	readNotes: flow(function*() {
		const actionName = "readNote"
		try {
			const response = yield apiRead()
			// ConsoleLog("readNotes response", response)
			return response
		} catch (error) {
			// console.error("readNotes error", error, error.response)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadNotes
