import { flow } from "mobx-state-tree"
import { AddSingleFileProps } from "../../data-models/file.data-props"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { ConsoleLog } from "../../../../components/basic-elements"

/**
 *
 * CreationMode: 1
 * WPEF: refNumName
 * MediaType: Excel
 * WPDesc: FileTitle
 * FinancialYear: 18-Mar-20
 * ParentMediaID: 286608
 * file: (binary)
 * SignOffType: [1]
 *
 */

const CreateFile = (
	self: any,
	createFile: FileSystemApiTypes["apiCreateFile"]
) => ({
	createSingleFile: flow(function* (props: AddSingleFileProps) {
		const actionName = "createSingleFile"

		const { projId, parentId, aliasId, title, localFileFormat } = props
		// ConsoleLog([actionName, "props:", props])
		const menuId = self.storeName
		//
		// 1.
		if (
			projId === undefined ||
			projId === "" ||
			props === undefined ||
			menuId === undefined
		) {
			alert(`(${actionName}) One of the props is undefined.`)
			ConsoleLog([">> undefined props exist:", actionName, props, menuId])
			return
		}
		if (
			parentId === undefined ||
			parentId === "" ||
			aliasId === undefined ||
			aliasId === "" ||
			title === undefined ||
			title === "" ||
			localFileFormat === undefined
		) {
			alert(`(${actionName}) One of the props is undefined.`)
			ConsoleLog([
				actionName,
				">> undefined file info exist:",
				parentId,
				aliasId,
				title,
				localFileFormat,
			])
			return
		}
		//
		// organize parameter
		let rootId: any = ""
		const parentNumberId = idToNumber(parentId, IdType.folder)

		let signOffs: number[] = []
		props.prepared && signOffs.push(1)
		props.reviewed && signOffs.push(2)
		//

		const formData = new FormData()

		if (menuId === DLOrgSubMenus.proj_templates) {
			rootId = idToNumber(projId, IdType.projTemplate)

			formData.append("ParentTempFoldID", parentNumberId.toString())
			formData.append("WPREF", aliasId)
			formData.append("TempName", title)

			formData.append("MediaType", localFileFormat)
			formData.append("TemplateId", rootId.toString())
		} else {
			rootId = idToNumber(projId, IdType.project)

			formData.append("ParentMediaID", parentNumberId.toString())
			formData.append("WPEF", aliasId)
			formData.append("WPDesc", title)

			formData.append("MediaType", localFileFormat)
			formData.append("SignOffType", JSON.stringify(signOffs))
			formData.append("ClientID", rootId.toString())
			formData.append("CreationMode", "3") // NOTE: 1: normal upload, 2: DnD upload, 3: create file
		}

		// 2.
		if (
			typeof rootId === "number" &&
			typeof parentNumberId === "number"
			// typeof userNumberId === "number" &&
			// userId !== null
			// endpoint !== undefined
		) {
			//

			//
			try {
				// const response = yield createFile(formData, endpoint)
				const response = yield createFile({ formData, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })
				return false
			}
		} else {
			alert(`(${actionName}) ${rootId} | ${parentNumberId} `)
			ConsoleLog(
				[actionName, "Fail to id converting", rootId, parentNumberId]
				// userId
				// endpoint
			)
		}
	}),
	// createMultipleFiles() {},
})

export default CreateFile
