import { putRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

export async function moveProject(payload: {
	ClientID: number
	CabinetID: number
}) {
	const endpoint = "/ClientMoving"
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
