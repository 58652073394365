import { SubsCallBackProps } from "@datalobby/common"
import { PostAttachmentType, Priority, WhoCanRead } from "@datalobby/types"

export type OnCreatePostSubscription = {
	onCreatePost: {
		__typename: "Post"
		menu: string
		id: string
		title: string
		content: string | null
		createdAt: string
		createdBy: string
	} | null
}
export const onCreatePost = /* GraphQL */ `
	subscription OnCreatePost {
		onCreatePost {
			menu
			id
			title
			content
			sector
			parent
			priority
			fixToTop
			attachments {
				fileName
				extension
				size
				formattedSize
				s3ObjKey
			}
			# visitorCount
			# whoCanRead
			# allowedTargets
			createdBy
			createdAt
			modifiedBy
			modifiedAt
			deleted
		}
	}
`

export type OnUpdatePostSubscription = {
	onUpdatePost: {
		__typename: "Post"
		menu: string
		id: string
		title: string
		content: string
		priority: Priority
		fixToTop: boolean
		attachments: PostAttachmentType[]
		// whoCanRead: WhoCanRead
		// allowedTargets: string[]
		createdAt: string
		createdBy: string
		modifiedBy: string
		modifiedAt: string
		deleted?: boolean
	}
}
export const onUpdatePost = /* GraphQL */ `
	subscription OnUpdatePost {
		onUpdatePost {
			menu
			id
			title
			content
			priority
			fixToTop
			attachments {
				fileName
				extension
				size
				formattedSize
				s3ObjKey
			}
			# visitors
			# visitorCount
			# whoCanRead
			# allowedTargets
			createdAt
			createdBy
			modifiedBy
			modifiedAt
			deleted
		}
	}
`

export type OnDeletePostSubscription = {
	onDeletePost: string
}
export const onDeletePost = /* GraphQL */ `
	subscription OnDeletePost {
		onDeletePost
	}
`

// export type OnVisitPostSubscription = {
// 	onVisitPost: {
// 		id: string
// 		visitor: string
// 	}
// }
// export const onVisitPost = /* GraphQL */ `
// 	subscription OnVisitPost {
// 		onVisitPost {
// 			id
// 			visitor
// 		}
// 	}
// `

export function setTypeOnDeletePostSubs(
	deletePostSubs: OnDeletePostSubscription
): any {
	const x = deletePostSubs.onDeletePost || ""
	return x
}

export function setTypeOnUpdateSubs(updateSubs: OnUpdatePostSubscription): any {
	const updatedPost = updateSubs.onUpdatePost
	return updatedPost || null
}

export const onUpdatePostHandler = (value: SubsCallBackProps) => {
	console.log("value of updateSubscription", value)
	const { subsData, action } = value
	const post = setTypeOnUpdateSubs(subsData)
	if (post) {
		action(post)
	} else {
		alert("Something went wrong... Failed to fetch the post for update")
	}
}
