import React, { useEffect } from "react"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import { Divider } from "@material-ui/core"
import styled from "styled-components"
import { Link } from "react-router-dom"
import keyboardJS from "keyboardjs"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

const ITEM_HEIGHT = 48

interface DLMenusWithMultipleActionProps {
	remoteButton: React.ReactNode
	menuOptions: {
		label: string
		value: string
		clickEvent?: any
		icon?: React.ReactNode
		disabled?: boolean
		available?: boolean // TODO: update as required (set as optional temporarily to defense conflicts)
		isLink?: boolean
		linkTo?: string
	}[]
	eleTestId: string
	eleId?: string
	useScroll?: boolean
	hasDivider?: Array<number>
}

/**
 * - This Menu Component requires button component
 * - This Menu Component use multiple action
 */

export default function DLMenusWithMultipleAction({
	remoteButton = <div />,
	menuOptions = [
		{
			label: "",
			value: "",
			clickEvent: undefined,
			icon: undefined,
			disabled: false,
			available: true,
			isLink: false,
			linkTo: "",
		},
	],
	eleTestId = "testId-DLMenusWithMultipleAction",
	eleId,
	useScroll = false,
	hasDivider = undefined,
}: DLMenusWithMultipleActionProps) {
	const store = useRootStore()
	const bgColor = store.ui.getThemeValue("background")
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	useEffect(() => {
		keyboardJS.pause()
	}, [])

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleClick = (option: any) => {
		option.clickEvent()
		handleClose()
	}

	return (
		<StyledDLMenusWithMultipleAction>
			<div onClick={handleOpen} data-testid={eleTestId}>
				{remoteButton}
			</div>

			<Menu
				anchorEl={anchorEl}
				MenuListProps={{
					id: eleId,
					className: `dl-ctx-menu-testid ${
						open ? "opened" : "closed"
					}`,
					style: { backgroundColor: bgColor, color: txtColor },
				}}
				// WARNING: DO NOTE REMOVE OR UPDATE this className 'dl-ctx-menu-testid' and 'opened'
				// It is used on test instead of the testid because this component does not support that property
				// and 'opened' is also required to distinguish the opened ctx menu
				keepMounted
				open={open}
				onClose={() => {
					keyboardJS.resume()
					handleClose()
				}}
				// data-testid={eleTestId} // NOTE: This MUI elements does not take test id
				// className="menu-component"  // NOTE: Please use MenuListProps
				PaperProps={{
					style: {
						maxHeight: useScroll ? ITEM_HEIGHT * 4.5 : "none",
						width: "none",
						minWidth: 200,
					},
				}}
			>
				{menuOptions.map((option, index) => {
					const {
						label,
						value,
						clickEvent,
						icon,
						disabled,
						available,
						isLink,
						linkTo,
					} = option
					return (
						<div key={value + index}>
							{available && (
								<div className="FC">
									{!isLink ? (
										<MenuItem
											id={value}
											data-testid={value}
											onClick={() => {
												clickEvent && clickEvent()
												handleClose()
											}}
											disabled={disabled}
											className="menu-item"
											style={{
												display: "flex",
												flexDirection: "row",
												justifyContent: "space-between",
											}}
										>
											<div className="FR AC">
												<div
													className="FR AC"
													style={{ marginRight: 4 }}
												>
													{icon}
												</div>
												<div>{label}</div>
											</div>
										</MenuItem>
									) : linkTo ? (
										<Link
											to={linkTo}
											onClick={() => {
												clickEvent && clickEvent()
												handleClose()
											}}
										>
											<MenuItem
												id={value}
												data-testid={value}
												disabled={disabled}
												className="menu-item"
												style={{
													display: "flex",
													flexDirection: "row",
													justifyContent:
														"space-between",
												}}
											>
												<div className="FR AC JSA">
													<div
														className="FR AC"
														style={{
															marginRight: 4,
														}}
													>
														{icon}
													</div>
													<div>{label}</div>
												</div>
											</MenuItem>
										</Link>
									) : (
										<MenuItem
											onClick={() => handleClick(option)}
											disabled={disabled}
											className="menu-item FR AC"
											data-testid={value}
										>
											<div
												className="FR"
												// style={{ marginRight: 4 }}
											>
												{icon}
											</div>
											<div>{label}</div>
										</MenuItem>
									)}

									{hasDivider &&
									hasDivider.includes(index) ? (
										<Divider style={{ width: "100%" }} />
									) : (
										""
									)}
								</div>
							)}
						</div>
					)
				})}
			</Menu>
		</StyledDLMenusWithMultipleAction>
	)
}

const StyledDLMenusWithMultipleAction = styled.div`
	.menu-component {
		.menu-item {
			font-size: ${(props) => props.theme.shared.textMd};
		}
	}
`
