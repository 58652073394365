const FilterViews = (self: any) => ({
    viewClientFilters(){
        let clients:any[] =[]
        self.clientFilters.map((item:any) => {
            clients.push({
                title: item.name,
                id: item.id
            })
        })
        return clients
    },
    viewEngTypeFilters(){
        let engTypes:any[] =[]
        self.engTypeFilters.map((item:any) => {
            engTypes.push({
                title: item.name,
                id: item.id
            })
        })
        return engTypes
    },
    viewEPUsersFilters(){
        let epUsers:any[] =[]
        self.epUsersFilters.map((item:any) => {
            epUsers.push({
                title: item.name,
                id: item.id
            })
        })
        return epUsers
    },
    viewQCUsersFilters(){
        let qcUsers:any[] =[]
        self.qcUsersFilters.map((item:any) => {
            qcUsers.push({
                title: item.name,
                id: item.id
            })
        })
        return qcUsers
    },
    viewGroupFilters(){
        let groups:any[] = []
        self.groupFilters.map((item:any) => {
            groups.push({
                title: item.name,
                id: item.id
            })
        })
        return groups
    },
    viewPeriodFilters(){
        let periods:any[] =[]
        self.periodFilters.map((item:any) => {
            periods.push({
                title: item.name,
                id: item.id
            })
        })
        return periods
    },
})

export default FilterViews