import { flow } from "mobx-state-tree"
import { ProgressStatus } from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { revertAttention } from "../../proj-files-shared-functions"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { putRequest } from "../../../../library/api-requests"

// NOTE: Update the file attention status
const UpdateFileStatus = (self: any) => ({
	updateFileStatus: flow(function* (
		fileId: string,
		currentStatus: ProgressStatus
	) {
		const actionName = "updateFileStatus"
		// 1.
		if (
			fileId === "" ||
			fileId === undefined ||
			currentStatus === undefined
		) {
			alert(
				`(${actionName}) Undefined prop exist. ${fileId} | ${currentStatus}`
			)
			return
		}
		//
		//
		// 2.
		const numberId = idToNumber(fileId, IdType.file)
		const statusList = [
			ProgressStatus.todo,
			ProgressStatus.inProgress,
			ProgressStatus.done,
		]
		const statusIndex = statusList.findIndex(
			(item) => item === currentStatus
		)
		const nextIndex = statusIndex === 2 ? 0 : statusIndex + 1
		const nextStatus = statusList[nextIndex]
		const statusForApi = revertAttention(nextStatus)
		//
		//
		// 3.
		if (typeof numberId === "number") {
			try {
				const endpoint = "/UpdateWorkPaperStage"
				const payload = {
					// Lang: "kr",
					Status: statusForApi,
					Type: "final", // static
					WPRID: numberId,
				}
				const response = yield putRequest({
					url: endpoint,
					params: payload,
					tokenType: TOKEN_TYPE.projToken,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		}
	}),
})

export default UpdateFileStatus
