import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import FSCard from "./FSCard"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function FinancialStatementType({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()

	const handleClick = (typeId: number) => {
		const projId = projStore.checkin.projId
		projStore.fsSetup.setSelectedTypeId(typeId)
		projStore.fsSetup.getFinancialGroupByFSType(typeId, projId)
	}

	const handleDialogOpen = (typeId: number) => {
		const projId = projStore.checkin.projId
		projStore.fsSetup.getAvailableFinGroups(typeId, projId)
		projStore.fsSetup.setMapUnmapGroupDialogOpen(true)
	}

	return (
		<FSCard
			headerName="Financial Statement Type"
			list={projStore.fsSetup.viewFSTypes()}
			clickHandler={handleClick}
			handleDialogOpen={handleDialogOpen}
			permission={permission}
		/>
	)
})
