import { flow } from "mobx-state-tree"
import { AmArchivedApiProps } from "../am-archived-projects.apis"

const AddReadOnlyUser = (
	self: any,
	addAccessMgmtUser: AmArchivedApiProps["apiAddAccessMgmtUser"]
) => ({
	addReadOnlyUser: flow(function*(payload) {
		const actionName = "addReadOnlyUser"
		try {
			const response = yield addAccessMgmtUser(payload)

			return response
		} catch (error) {
			// ConsoleLog("AddReadOnlyUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default AddReadOnlyUser
