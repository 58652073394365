import { types } from "mobx-state-tree"
// separated actions
import ReadSignoffHistory from "./model-actions/read-signoff-history"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import ReadSignoffHistoryDetail from "./model-actions/read-signoff-history-detail"
import DownloadSignOffHistoryZip from "./model-actions/download-signoff-history-zip"

const SignOffHistoryModel = types
	.model({})
	.actions(ReadSignoffHistory)
	.actions(ReadSignoffHistoryDetail)
	// common model actions
	.actions(CommonModelActions)
	.actions(DownloadSignOffHistoryZip)

export default SignOffHistoryModel
