import { flow } from "mobx-state-tree"
import { OrgGroupApiCallProps } from "../../../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const UpdateCabinetName = (
	self: any,
	updateCabinetName: OrgGroupApiCallProps["apiUpdateCabinetName"]
) => ({
	updateCabinetName: flow(function* ({
		cabinetId,
		title,
	}: {
		cabinetId: string
		title: string
	}) {
		const actionName = "updateCabinetName"
		// 1.
		if (cabinetId === undefined || title === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. cabinetId: ${cabinetId} / title: ${title}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(cabinetId, IdType.cabinet)
		if (typeof numberId === "number") {
			try {
				let payload = {
					CabinetID: numberId,
					CabinetName: title,
					// UserID: 16
				}
				const response = yield updateCabinetName(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default UpdateCabinetName
