import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { findProjRoleNumberId } from "../../../../temporary-data/project-side/find-proj-role"
import { AssignRoleProps } from "../../view-model-actions/assign/assign-role-to-file"

const RequestAssignRoleToFile = (
	self: any,
	assignRole: FileSystemApiTypes["apiAssignRole"]
) => ({
	requestAssignRole: flow(function* (props: AssignRoleProps) {
		const actionName = "requestAssignRole"
		const menuId = self.storeName
		const { fileId, roleId, type } = props
		// 1.
		if (
			fileId === undefined ||
			fileId === "" ||
			roleId === undefined ||
			roleId === ""
		) {
			alert(
				`(${actionName}) Undefined props exist. fileId: ${fileId} | roleId: ${roleId}`
			)
			return
		}
		//
		//
		// 2.
		//
		//
		// 3.
		const fileNumberId = idToNumber(fileId, IdType.file)
		const roleNumberId = findProjRoleNumberId(roleId)
		if (
			typeof fileNumberId === "number" &&
			typeof roleNumberId === "number"
		) {
			try {
				const payload = {
					FileID: fileNumberId,
					RoleID: roleNumberId,
					Type: type === "assign" ? 1 : 2, // 1: assign, 2: unassign
				}
				const response = yield assignRole({ payload, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })
				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid prop format. ${fileId} --> ${fileNumberId} | ${roleId} --> ${roleNumberId}`
			)
			return false
		}
	}),
})

export default RequestAssignRoleToFile
