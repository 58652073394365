import { flow } from "mobx-state-tree"

const UpdateOrgLibraryTemplate = (self: any, updateLibraryTemplate: any) => ({
	updateOrgLibraryTemplate: flow(function* (payload: any) {
		const actionName = "updateOrgLibraryTemplate"
		try {
			const response = yield updateLibraryTemplate(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateOrgLibraryTemplate
