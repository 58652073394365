import React from "react"
import { observer } from "mobx-react-lite"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLDialog, InputWithLabel } from "../../../basic-elements"
import { Icon } from "@mdi/react"
import { mdiChevronDown, mdiFolder } from "@mdi/js"
import styled from "styled-components"
import ReactTableV8 from "../../../basic-elements/tables/DLReactTable"

export default observer(function DragAndDropConfirmDialog({
	partialStore,
	moveData,
}: {
	partialStore: any
	moveData: any
}) {
	const open =
		partialStore.fileTreeDialogOpenStatus[
			FileScreenDialog.dragAndDropConfirmDialog
		]
	const setOpen = () => {
		partialStore.setFileTreeDialogOpen([
			FileScreenDialog.dragAndDropConfirmDialog,
		])
	}

	const fileDetails: any = [moveData.node]

	const sourceFolder = partialStore.getItemById(moveData.node.parentId)?.title

	const handleTargetItem = () => {
		partialStore.reorderChildren(
			moveData.nextParentNode.id,
			moveData.nextParentNode.children,
			setOpen
		)
		partialStore.getChildrenOfFolder(moveData.nextParentNode.id)
		partialStore.toggleExpanded(moveData.nextParentNode.id, true)
	}

	return (
		<DLDialog
			eleTestId="drag-n-drop-confirm-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleTargetItem}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={"Move File"}
			dialogContents={
				<StyledImporFileFromLib className="FC JSB">
					<div>
						<h3>From</h3>
						<FolderSelectField
							label="Source Folder"
							folderTitle={sourceFolder}
							disabled={true}
						/>

						<hr style={{ margin: "2rem 0" }} />

						<h3>To</h3>
						<FolderSelectField
							label="Target Folder"
							folderTitle={moveData.nextParentNode.title}
							disabled={true}
						/>

						{!moveData.node.isParent && (
							<>
								<hr style={{ margin: "2rem 0" }} />
								<h3 style={{ marginBottom: 10 }}>
									File Information
								</h3>
								<ReactTableV8
									tableColumns={[
										{
											header: "Ref. num",
											accessorKey: "aliasId",
											size: 90,
										},
										{
											header: "Type",
											accessorKey: "extension",
											size: 90,
										},
										{
											header: "Size",
											accessorKey: "size",
											size: 90,
										},
										{
											header: "File name",
											accessorKey: "title",
										},
									]}
									data={fileDetails}
									hasPagination={false}
									showPageSetting={false}
									// height={100}
									menuId="AddTemplateForm"
								/>
							</>
						)}
					</div>
				</StyledImporFileFromLib>
			}
			cancelBtnText="Cancel"
			actionReady={true}
			actionBtn="Move"
			draggable
		/>
	)
})

const StyledImporFileFromLib = styled.div`
	height: 100%;
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
	table > tbody > tr > td {
		padding: 8px;
	}
	.folder-selector-container {
		margin-top: 1rem;
		.folder-label {
			margin-right: 2rem;
		}
		.folder-selector {
			border: 1px solid lightgray;
			border-radius: 4px;
			transition: 0.4s;
			padding: 0.4rem 1rem;
			cursor: pointer;
			.folder-name {
				margin-right: 1rem;
			}
			:hover {
				border: 1px solid black;
			}
		}
	}
`

function FolderSelectField({
	label,
	folderTitle,
	disabled = false,
}: {
	label: string
	folderTitle: string | undefined
	disabled?: boolean
}) {
	return (
		<StyledFolderField className="folder-selector-container input-section FR AC">
			<InputWithLabel
				label={label}
				required
				highlight={folderTitle === ""}
			>
				<div
					className={`folder-selector FR AC JSB ${
						disabled ? "disable" : ""
					}`}
					data-testid="add-file-folder-select"
				>
					<div
						className={`folder-name ${
							folderTitle === "" ? "empty" : ""
						}`}
					>
						{folderTitle || "Please select a folder"}
					</div>
					<Icon path={mdiChevronDown} size={0.7} />
				</div>
			</InputWithLabel>
		</StyledFolderField>
	)
}

const StyledFolderField = styled.div`
	.folder-name.empty {
		color: red;
	}
	.disable {
		pointer-events: none;
	}
	.label {
		min-width: 120px;
	}
	.input-area {
		min-widht: 200px;
	}
`
