import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import EmailNotification from "./EmailNotification"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"

export default observer(function EmailNotificationController() {
	// default setting
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getEmailNotificationSettings"
	const menuId = DLOrgSubMenus.setup_sys
	const i18nSheet = OrgI18n.emailNoti

	useEffect(() => {
		orgStore.emailNotification.getEmailNotificationSetting()
	}, [])

	const dateFormat = store.global.dateFormat.value
	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<EmailNotification
			partialStore={orgStore.emailNotification}
			actionStatus={orgStore.emailNotification.getActionStatus(
				actionName
			)}
			permission={orgStore.checkin.getActionsAsObject(menuId)}
			dateFormat={dateFormat}
			i18n={i18n}
		/>
	)
})
