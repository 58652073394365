import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
//
import { LibFile, LibFileProps, ProjTemplates } from "./lib-in-proj.data-model"
import { initialStore } from "./lib-in-proj.provider"
// separated actions
import GetLibrariesInProj from "./view-model-actions/get-libraries-in-proj"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import GetLibFilesInProj from "./view-model-actions/get-lib-files-in-proj"
import ViewsForTreeNav from "./sub-views/views-for-tree-nav"
import { ViewsForCreateFileDialog } from "./sub-views/views-for-create-file-dialog"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import GetTempFilesInProj from "./view-model-actions/get-templates-in-proj"
import {
	OrgProjTemplateFlatItem,
	OrgProjTemplateItemType,
} from "../../../organization-side/org-library/org-proj-templates/store/data-models/org-proj-templates.data-model"
import { getTreeFromFlatData } from "../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { GetProjTemplateFirstFolders } from "../../../organization-side/org-library/org-proj-templates/store/view-model-actions/template"
import { DLFileModel } from "../../../../service-modules/file-module/data-models/file.data-model"
import { sortFoldersNFiles } from "../../../../library/file-utils/file-utils"
import GetProjTemplateChildrenOfFolder from "./view-model-actions/get-proj-template-children-of-folder"

const LibInProjViewModel = types
	.model({
		flatList: types.array(LibFile),
		drawerOpen: false, // TODO: if the actions are expanded, move to ui-helper
		openedOn: types.union(
			types.enumeration<DLProjSubMenus>(Object.values(DLProjSubMenus)),
			types.undefined
		),
		selectedItemId: "",
		selectedTempFolder: "",
		selectedTempFile: "",
		templates: types.array(ProjTemplates),
		templatesFlatList: types.array(DLFileModel),
		// common parts
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// flat list actions
	.actions((self) => ({
		setSelectedItemId(id: string) {
			self.selectedItemId = id
		},
		setSelectedTempFolder(folder: string) {
			self.selectedTempFolder = folder
		},
		setSelectedTempFile(file: string) {
			self.selectedTempFile = file
		},
		setFlatList(list: any) {
			self.flatList = list
		},
		pushChildItem(item: LibFileProps) {
			const targetIndex = self.flatList.findIndex(
				(prev) => prev.id === item.id
			)
			if (targetIndex === -1) {
				self.flatList.push(item)
			} else {
				self.flatList.splice(targetIndex, 1)
				self.flatList.push(item)
			}
		},
		setOpenedOn(menu: DLProjSubMenus) {
			self.openedOn = menu
		},
		setTemplates(templates: any) {
			self.templates.length = 0
			self.templates = templates
		},
		pushFlatItem(item: any) {
			const prevItemIndex = self.templatesFlatList.findIndex(
				(prevItem: any) => prevItem.id === item.id
			)
			if (prevItemIndex !== -1) {
				// NOTE: without splice, views cannot catch the sign off list update
				self.templatesFlatList.splice(prevItemIndex, 1)
				self.templatesFlatList.push(item)
			} else {
				self.templatesFlatList.push(item)
			}
			// self.templatesFlatList = sortFoldersNFiles(self.templatesFlatList)
		},
		updateFlatList(newList: any) {
			newList.map((item: any) => {
				const prevItemIndex = self.templatesFlatList.findIndex(
					(prevItem: any) => prevItem.id === item.id
				)
				if (item.isParent) {
					if (prevItemIndex !== -1) {
						const prevItem = self.templatesFlatList.find(
							(prevItem: any) => prevItem.id === item.id
						)
						self.templatesFlatList.splice(prevItemIndex, 1)
						self.templatesFlatList.push({
							...item,
							expanded: prevItem?.expanded,
						})
					} else {
						self.templatesFlatList.push(item)
					}
				} else {
					if (prevItemIndex !== -1) {
						self.templatesFlatList.splice(prevItemIndex, 1)
						self.templatesFlatList.push(item)
					} else {
						self.templatesFlatList.push(item)
					}
				}
			})
			// self.flatList = sortFoldersNFiles(self.flatList)
		},
		setNeedRefresh(need: boolean) {},
	}))
	// AGER
	.actions(GetLibrariesInProj)
	.actions(GetLibFilesInProj)
	.actions(GetTempFilesInProj)
	.actions(GetProjTemplateFirstFolders)
	.actions(GetProjTemplateChildrenOfFolder)
	// sub actions
	.actions((self) => ({
		setDrawerOpen(request?: boolean) {
			if (request !== undefined) {
				self.drawerOpen = request
			} else {
				self.drawerOpen = !self.drawerOpen
			}
		},
		toggleExpand(id: string, expanded?: boolean) {
			const target = self.flatList.find((item) => item.id === id)
			if (target) {
				if (expanded !== undefined) {
					target.expanded = expanded
				} else {
					target.expanded = !target.expanded
				}
			}
		},
		toggleTemplateExpand(id: string, expanded?: boolean) {
			const target = self.templatesFlatList.find((item) => item.id === id)
			if (target) {
				if (expanded !== undefined) {
					target.expanded = expanded
				} else {
					target.expanded = !target.expanded
				}
			}
		},
		refreshInDrawer(orgId: string) {
			self.templatesFlatList.length = 0
			self.getTempFilesInProj(orgId)
		},
		resetTemplateFlatList() {
			self.templatesFlatList.length = 0
		},
	}))
	.views((self) => ({
		get drawerOpenStatus() {
			return self.drawerOpen
		},
		viewFlatList() {
			return self.flatList
		},
		viewTemplateFolder() {
			return self.templates
				.filter(
					(item: any) =>
						item.type === OrgProjTemplateItemType.templateGroup
				)
				.map((i) => ({ name: i.title, value: i.id }))
		},
		viewTemplateFiles(parentId: string) {
			return parentId
				? self.templates
						.filter((item: any) => item.parentId === parentId)
						.map((i) => ({ name: i.title, value: i.id }))
				: []
		},
		flatListAsTree() {
			const tree = getTreeFromFlatData(self.templatesFlatList, "title")

			return tree
		},
	}))
	.views(ViewsForTreeNav)
	.views(ViewsForCreateFileDialog)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions((self) => ({
		refresh(orgId: string) {
			self.initializeStore()
			self.getLibrariesInProj(orgId)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default LibInProjViewModel
