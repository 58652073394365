// ---------- response cases
import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetOrgGroupCabinetList = (self: any) => ({
	getOrgGroupCabinetList(groupId: string, additionalAction?: any) {
		// 0.
		const actionName = "getOrgGroupCabinetList_" + groupId
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readOrgGroupCabinetList(groupId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					let newCabinetList: any = []
					const fetchedGroupCabinets = response.data.CabinetsList
					fetchedGroupCabinets.map((cabinet: any) => {
						// 2-2-A) save Cabinet
						//
						// 2-2-B) Flatten items and push to flat list
						const reOrganizedGroupCabinet = {
							id: idToString(cabinet.CabinetID, IdType.cabinet),
							title: cabinet.CabinetName,
							parentId: groupId,
							createdBy: idToString(
								cabinet.CreatedBy,
								IdType.user
							),
							createdByName: cabinet.CreatedUser,
							createdAt: cabinet.CreatedDate,
							expanded: false,
							type: DLObjectFormat.cabinet,
							detail: null,
						}
						// check duplication
						const itemIndex = self.orgGroupFlatList.findIndex(
							(prevItem: any) =>
								prevItem.id === reOrganizedGroupCabinet.id
						)
						if (itemIndex === -1) {
							newCabinetList.push(reOrganizedGroupCabinet)
						}
					})
					const concatList =
						self.orgGroupFlatList.concat(newCabinetList)
					self.concatNewList(concatList)

					additionalAction && additionalAction()
					// when success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the cabinet list of the group",
						open: false,
						autoHide: true,
					})
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message:
							response.data.Message ||
							response.data.message ||
							"Something wrong...",
						customMessage: "(TODO) Something Wrong.. ",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgGroupCabinetList
