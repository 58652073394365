import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { Link } from "react-router-dom"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { Column } from "@tanstack/react-table"

export default observer(function OrgSetupChecklistTable({
	i18n,
	partialStore,
}: // dateFilters,
// setDateFilters,
// filters,
// setFilters,
{
	i18n: DLI18nProps
	partialStore: any
	// dateFilters: any
	// setDateFilters: any
	// filters: any
	// setFilters: any
}) {
	const store = useRootStore()

	// const [selectedChecklist, setSelectedChecklist] = useState<any>([])
	// const onChangeDate = useCallback((date, name) => {
	// 	setDateFilters((dateFilters: any) => ({
	// 		...dateFilters,
	// 		[name]: date,
	// 	}))
	// 	setFilters((filters: any) =>
	// 		filters.map((filter: any) =>
	// 			filter.id === "createdAt" ? { ...filter, value: "" } : filter
	// 		)
	// 	) // Need Discussion
	// }, [])
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue

	return (
		<ReactTableV8
			// TODO: height setting
			tableColumns={Columns}
			data={partialStore.formattedChecklist(dateFormat, timeZone)}
			menuId={DLOrgSubMenus.setup_checklist}
			height={store.ui.contentsAreaHeight - 48}
			customFilters={filterComponent}
			showPageSetting={false}
		/>
	)
})

const filterComponent = ({ column }: { column: Column<any> }) => {
	return (
		<InputFieldForTableV8
			columnId={column.id}
			onChange={column.setFilterValue}
			value={column.getFilterValue()}
		/>
	)
}

const Columns = [
	{
		header: "Name",
		accessor: "name",
		cell: (props: any) => {
			const templateId = props.row.original.id
			const linkTo = `/organization/organization-setup/checklist/${templateId}`
			return (
				<div data-testid="checklist-template-name-cell">
					<Link to={linkTo}>{props.getValue()}</Link>
				</div>
			)
		},
	},
	{
		header: "Description",
		accessorKey: "description",
	},
	{
		header: "Assigned Roles",
		accessorKey: "assignedRoles",
	},
	{
		header: "Created At",
		accessorKey: "createdAt",
	},
	{
		header: "Created By",
		accessorKey: "createdBy",
	},
]
