import { observer } from "mobx-react-lite"
import React, { useEffect, useState } from "react"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { SimpleFileTreeProps } from "../../../../service-modules/file-module/data-models/file.data-props"
import {
	ConsoleLog,
	DLDialog,
	DLTreeListByTreeData,
} from "../../../basic-elements"
import { DLCheckbox } from "@datalobby/components"
import { Icon } from "@mdi/react"
import { mdiFolder } from "@mdi/js"
import styled from "styled-components"

export default observer(function FileTreeForSelectionDialog({
	target,
	partialStore,
	i18n,
}: {
	target: "folder-only" | "all"
	partialStore: any
	i18n: DLI18nProps
}) {
	const dialogName =
		target === "folder-only"
			? FileScreenDialog.folderTree
			: FileScreenDialog.fileTree

	const open = partialStore.fileTreeDialogOpenStatus[dialogName]
	const setOpen = () => {
		partialStore.setFileTreeDialogOpen(dialogName)
	}

	const simpleFileTree = partialStore.viewSimpleFileTree("folder-only")
	ConsoleLog(["simpleFileTree ", simpleFileTree])

	const defaultSelectedItem = partialStore.selectedItems[0]?.id
	const [selectedItem, setSelectedItem] = useState<string | undefined>(
		undefined
	)

	useEffect(() => {
		// partialStore.getAllFiles(projId)

		setSelectedItem(defaultSelectedItem)
	}, [])

	const handleTargetItem = () => {
		partialStore.setSelectedItem(selectedItem, true)
		setOpen()
	}

	return (
		<DLDialog
			eleTestId="file-tree-for-selection-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleTargetItem}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={"Folder & File Tree"}
			dialogContents={
				<FileTreeForSelectionDialogContents
					simpleFileTree={simpleFileTree}
					selectedItem={selectedItem}
					setSelectedItem={setSelectedItem}
				/>
			}
			cancelBtnText="Cancel"
			actionReady={defaultSelectedItem !== selectedItem}
			actionBtn="Select"
			draggable
		/>
	)
})

export const FileTreeForSelectionDialogContents = observer(
	({
		simpleFileTree,
		selectedItem,
		setSelectedItem,
	}: {
		simpleFileTree: SimpleFileTreeProps[]
		selectedItem: string | undefined
		setSelectedItem: any
	}) => {
		const updateSelectedItem = (id: string) => {
			setSelectedItem(id)
		}

		return (
			<StyledFileTreeSelection>
				<DLTreeListByTreeData
					treeData={simpleFileTree}
					eleRenderNode={(props: any) => {
						const { node } = props
						const {
							id,
							isParent,
							parentId,
							title,
							index,
							aliasId,
						} = node
						const isChecked = id === selectedItem

						const clsName = isChecked
							? "item-row selected FR AC "
							: "item-row FR AC "
						if (isParent) {
							return (
								<div className={clsName}>
									<DLCheckbox
										eleTestId={"folder-checkbox-" + title}
										isChecked={isChecked}
										clickHandler={() =>
											updateSelectedItem(id)
										}
										color={
											isChecked ? "primary" : "default"
										}
									/>
									<Icon path={mdiFolder} size={0.6} />
									{title}
								</div>
							)
						} else {
							return (
								<div className={clsName}>
									<DLCheckbox
										eleTestId={"file-checkbox-" + title}
										isChecked={isChecked}
										clickHandler={() =>
											updateSelectedItem(id)
										}
										color={
											isChecked ? "primary" : "default"
										}
									/>
									({aliasId}) {title}
								</div>
							)
						}
					}}
					canDrag={false}
					eleWidth="100%"
					eleHeight={500}
					indentWidth={24}
					rowHeight="thin"
				/>
			</StyledFileTreeSelection>
		)
	}
)

const StyledFileTreeSelection = styled.div`
	.item-row {
		height: 1rem;
	}
	.selected {
		color: ${(props) => props.theme.emphasis};
	}
`
