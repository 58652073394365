import React from "react"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiFolderPlusOutline } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default function NoFolderExist({
	handlerCreateFolder,
	i18n,
}: {
	handlerCreateFolder: () => void
	i18n: DLI18nProps
}) {
	return (
		<StyledNoFolderExist className="FC AC JC">
			<div className="heading2">
				{i18n.tsRequestToCreateFolder ||
					"Please create a folder to start"}
			</div>

			<div
				className="icon-button FR AC JC"
				onClick={handlerCreateFolder}
				data-testid="create-first-folder"
			>
				<Icon path={mdiFolderPlusOutline} size={4} />
			</div>
		</StyledNoFolderExist>
	)
}

const StyledNoFolderExist = styled.div`
	width: 100%;
	height: 100%;
	.heading2 {
		color: ${(props) => props.theme.shade40};
		margin-bottom: 1rem;
	}
	.icon-button {
		width: 200px;
		height: 200px;
		transition: 0.4s;
		border-radius: 100px;
		cursor: pointer;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
	}
`
