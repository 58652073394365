// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const MapGroupToFSType = (self: any) => ({
	mapGroupToFSType(fsGroupId: number, fsTypeId: number) {
		// 0
		const actionName = "mapGroupToFSType"
		// 1
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "mapping financial group is in progress",
			open: true,
		})

		const payload = {
			fsTypeId,
			fgroupId: fsGroupId,
		}

		// 3) request API call to Model
		self.modelMapGroupToFSType(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						self.mapGroup(fsGroupId, response.data.mapId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully unmapped financial group ",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default MapGroupToFSType
