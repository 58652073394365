import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"
import { DLOrgMenuGroups } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { MenuPermissionProps } from "../../../../../../service-modules/role-module/roles.data-model"
import { compareValues } from "../../../../../../components/basic-elements/tree-list/getTreeFromFlatData"

const GetAccessibleMenus = (self: any) => ({
	// This one does not use model (because this not use API call)
	getAccessibleMenus(customerMenus: any[]) {
		// 0. set actionName
		const actionName = "getAccessibleMenus"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		let accessibleMenus: any[] = []
		self.assignedRole.menuPermissions.map((menu: MenuPermissionProps) => {
			if (menu.accessible) {
				if (menu.menuId === DLOrgMenuGroups.org_mgmt) {
					if (self.orgInfo.hasCustomerViewAccess) {
						accessibleMenus.push(menu)
					}
				} else {
					accessibleMenus.push(menu)
				}
			}
		})

		let tree: any[] = []
		let flat: any[] = []
		let mappedFlatData: any = {}

		accessibleMenus.map((flatItem: MenuPermissionProps) => {
			const matchedItem = customerMenus.find(
				(menu) => menu.id === flatItem.menuId
			)

			if (matchedItem) {
				mappedFlatData[flatItem.menuId] = {
					id: flatItem.menuId,
					title: matchedItem.name,
					url: matchedItem.urlEdge,
					menuGroup: matchedItem.parentId ?? null,
					index: matchedItem.index,
					subMenus: [],
					isAdminMenu: matchedItem.isAdminMenu ? true : false,
				}
			} else {
				// ConsoleLog(
				// 	actionName,
				// 	"__ No matched menu info",
				// 	flatItem.menuId
				// )
			}
		})

		for (var menuId in mappedFlatData) {
			if (mappedFlatData.hasOwnProperty(menuId)) {
				const item = mappedFlatData[menuId]
				// console.log("item____", item)
				if (item.menuGroup === null || item.menuGroup === "") {
					tree.push(item)
					flat.push(item)
				} else {
					if (mappedFlatData[item.menuGroup]) {
						// if mappedFlatData[item.menuGroup] not exist, it means, parent menuGroup is not allowed to access
						mappedFlatData[item.menuGroup]["subMenus"].push(item)
						flat.push(item)
					}
				}
			}
		}
		console.log("tree org menu list", tree)
		// arrange by index number
		tree.sort(compareValues("index"))
		tree.map((menuGroup: any) => {
			menuGroup.subMenus.sort(compareValues("index"))
		})
		//
		self.setAccessibleTreeMenus(tree)
		// arrange for sub menu tabs
		flat.sort(compareValues("index"))
		// ConsoleLog("flat", flat)
		self.setAccessibleFlatMenus(flat)
		if (flat.length > 0) {
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.success,
			})
		} else {
			console.log("There are no accessible menus")
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.fail,
			})
		}
	},
})

export default GetAccessibleMenus
