const projectRegion = process.env.REACT_APP_ProjectRegion
const gqlApiUrl = process.env.REACT_APP_GraphQLAPIURL
const appSyncApiKey = process.env.REACT_APP_AppSyncAPIKey
const postAssetsBucketName = process.env.REACT_APP_PostAssetsBucketName

const config = {
	aws_project_region: projectRegion,
	aws_region: projectRegion,
	aws_appsync_graphqlEndpoint: gqlApiUrl,
	aws_appsync_apiKey: appSyncApiKey,
	aws_appsync_authenticationType: "API_KEY",
	aws_user_files_s3_bucket_region: projectRegion,
	aws_user_files_s3_bucket: postAssetsBucketName,
	// below information is abhra cognito user pool
	// and the identity pool was created by Noah for testing (It did not exist before)
	aws_cognito_identity_pool_id:
		"ap-northeast-2:08414cb7-4cbc-4626-b904-2787a86d8d31",
	aws_cognito_region: "us-northeast-1",
	aws_user_pools_id: "ap-northeast-2_tXQase6z8",
	aws_user_pools_web_client_id: "613co9fhhvjcebkfv35qu091n6",
}

export default config
