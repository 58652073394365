import { types, applySnapshot } from "mobx-state-tree"
import { OrgFlatGroupItem } from "./data-models/groups.data-models"
// ---------- common models
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { initialStore } from "./org-groups.provider"

import {
	GetOrgGroupList,
	GetOrgGroupCabinetList,
	GetOrgCabinetDetail,
	// Group related
	EditGroupName,
	RemoveGroup,
	// Cabinet related
	EditCabinetName,
	AddCabinetInGroup,
	RemoveCabinetInGroup,
	MoveCabinet,
	// Project related
} from "./view-model-actions"

import {
	getTreeFromFlatData,
	compareValues,
} from "../../../../components/basic-elements/tree-list/getTreeFromFlatData"

/**
 *  NOTE: AddGroup action be shared with org setup > groups --> some parts should use same name for this
 * - pushItemToList
 * - setOpenAddGroupDialog (ui-helper)
 */
import AddGroup from "../../../../stores/org-sub-stores/org-groups-store/view-model-actions/add-group"

import { BreadCrumbsOptionProps } from "../../../../components/combined-elements/postpone-to-classify/BreadCrumbsAndOptions"
import { DLObjectFormat } from "../../../../common-models/enumerations/common-enums"
import {
	MoveProject,
	RemoveProject,
} from "../../../../service-modules/project-module/view-model-actions"
import GetProjectDetails from "../../../../service-modules/project-module/view-model-actions/get-project-details"
import { ProjectDetailDataModel } from "../../../../service-modules/project-module/data-model/project-detail.data-model"
import GetAssignedUsersOfGroup from "../../../../stores/org-sub-stores/org-groups-store/view-model-actions/get-assigned-users-of-group"
import { ConsoleLog } from "../../../../components/basic-elements"
import { RFSourceProjInfoModel } from "../../../../service-modules/project-module/data-model/project-module-data-models"
import { DnTFormatProps } from "../../../../common-models/types/common-props"
import { formattedDateWithTz2 } from "../../../../library/converters/date-utc-converter"

const OrgGroupsViewModel = types
	.model({
		orgGroupFlatList: types.array(OrgFlatGroupItem),
		projectDetails: ProjectDetailDataModel,
		rfSourceProjInfo: RFSourceProjInfoModel,
		//
		search: "",
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	//----------
	// AGER (Add, Get, Edit, Remove)
	.actions(AddGroup)
	.actions(GetOrgGroupList)
	.actions(GetOrgGroupCabinetList)
	.actions(GetOrgCabinetDetail)
	// Group related actions
	.actions(EditGroupName)
	.actions(RemoveGroup)
	// Group cabinet related actions
	.actions(AddCabinetInGroup)
	.actions(RemoveCabinetInGroup)
	.actions(EditCabinetName)
	.actions(MoveCabinet)
	// project related
	.actions(MoveProject)
	.actions(RemoveProject)
	//
	.actions((self) => ({
		setProjectDetails(details: any) {
			self.projectDetails = details
		},
		setSearch(name: string) {
			self.search = name
		},
		setRFSourceProjInfo(info: any) {
			self.rfSourceProjInfo = info
		},
	}))
	//
	.actions(GetAssignedUsersOfGroup)
	// .actions(GetProjectDetails)
	//
	// .actions(GetRfProjByGroup)
	// .actions(GetClientsByGroup) // NOTE: for roll-forward --> org projects has getRfProjectsByGroup & readRfProjects
	// .actions(GetArchPolicyPeriodList)
	//
	.views((self) => ({
		getNameById(id: string) {
			const target = self.orgGroupFlatList.find(
				(item: any) => item.id === id
			)
			return target?.title
		},
		getItemPath(id: string) {
			const list = self.orgGroupFlatList

			let path: string[] = [id]

			const findTarget = (id: string) =>
				list.find((item: any) => item.id === id)
			const target = findTarget(id)

			if (target) {
				let parent: null | string = target.parentId

				for (let i = 0; i < list.length; i++) {
					if (parent !== null) {
						const newTarget = findTarget(parent)

						if (newTarget) {
							path.unshift(parent)
							parent = newTarget.parentId
						} else {
							ConsoleLog(
								"Cannot find this parent item on the list" +
									parent
							)
						}
					} else {
						break
					}
				}
			} else {
				ConsoleLog("(getItemPath) Cannot find the target by ID " + id)
			}
			ConsoleLog("getItemPath " + path)
			return path
		},
	}))
	.actions((self) => ({
		reflectDelete(itemId: string) {
			const targetIndex = self.orgGroupFlatList.findIndex(
				(item) => item.id === itemId
			)
			if (targetIndex !== -1) {
				self.orgGroupFlatList.splice(targetIndex, 1)
			} else {
				ConsoleLog("(reflectDelete) cannot find the target")
			}
		},
		setGroupList(list: any) {
			self.orgGroupFlatList.length = 0
			self.orgGroupFlatList = list
		},
		pushItemToList(item: any) {
			const newId = item.id
			const targetIndex = self.orgGroupFlatList.findIndex(
				(item) => item.id === newId
			)
			if (targetIndex === -1) {
				self.orgGroupFlatList.push(item)
				ConsoleLog("pushItemToList to add project " + item)
			} else {
				ConsoleLog(
					`(org group viewModel, pushFlatItemToList) duplicated item exist`
				)
				const prevItem = self.orgGroupFlatList[targetIndex]
				const newItem = { ...item, expanded: prevItem.expanded }
				self.orgGroupFlatList.splice(targetIndex, 1)
				self.orgGroupFlatList.push(newItem)
			}
		},
		concatNewList(newList: any) {
			// NOTE: if concat old and new list here, it occurs an error... (Not sure the reason...)
			// So we need concat old and new one in the viewModel (at reflect part)
			self.orgGroupFlatList = newList
		},
		resetFlatList() {
			self.orgGroupFlatList.length = 0
		},
		toggleExpanded(itemId: string, expanded?: boolean) {
			const target = self.orgGroupFlatList.find(
				(item: any) => item.id === itemId
			)
			if (target) {
				if (expanded !== undefined) {
					target.expanded = expanded
				} else {
					target.expanded = !target.expanded
				}
			}
		},
		removeFlatItem(itemId: string) {
			const targetIndex = self.orgGroupFlatList.findIndex(
				(item) => item.id === itemId
			)
			self.orgGroupFlatList.splice(targetIndex, 1)
		},
		updateParent(itemId: string, newParent: string) {
			const target = self.orgGroupFlatList.find(
				(item) => item.id === itemId
			)
			if (!target) {
				console.error(
					"(from updateParent request) Cannot find this item",
					itemId
				)
				return
			}
			target.parentId = newParent
		},
		isDuplicatedName(name: string) {
			return self.orgGroupFlatList.some((item) => item.title === name)
		},
		isDuplicatedNameInCabinet(name: string, parentId: string) {
			return self.orgGroupFlatList.some(
				(item) => item.title === name && item.parentId === parentId
			)
		},
		reflectRename(id: string, name: string) {
			const target = self.orgGroupFlatList.find((item) => item.id === id)
			if (!target) {
				ConsoleLog("(reflect rename) Cannot find matched item")
				return
			}
			target.title = name
		},
	}))
	//
	.views((self) => ({
		get getGroupFlatListLength() {
			return self.orgGroupFlatList.length
		},
		getTargetDetail(id: string) {
			return self.orgGroupFlatList.find((item: any) => item.id === id)
		},
		viewFlatListAsTreeWithQuery(query: string) {
			// for tree nav (2)
			//  NOTE: this part only working for groups and cabinets before all data be fetched
			// --> TODO: Set another search action..??

			const defaultList = self.orgGroupFlatList
			let list: any = []
			list = defaultList.filter((item: any) =>
				item.title.toLowerCase().includes(query.toLowerCase())
			)
			// NOTE: if there are no root cabinet, childern cannot be displayed
			ConsoleLog("queried list for group is working") // @Noah : Please check this. This one is working multiple times and .. seems have impact for performance? // list)

			for (let i = 0; i < defaultList.length; i++) {
				list.map((item: any) => {
					if (
						list.findIndex(
							(listItem: any) => listItem.id === item.parentId
						) === -1
					) {
						const parent = defaultList.find(
							(originItem: any) => originItem.id === item.parentId
						)
						if (parent) {
							self.toggleExpanded(parent.id, true)
							list.push(parent)
						}
					}
					// WARNING: this process cannot control deep tree.
					// TODO: Need to consider better way
				})
			}

			// TODO: Need one more step if we need to show other children here
			// TODO: Consider more: show or hide projects on the nav
			const tree = getTreeFromFlatData(list, "title")
			return tree
		},
		getChildrenInTheFlatList(parentId: string, search?: string) {
			let sortedList: any[] = []
			let list = self.orgGroupFlatList.filter(
				(item) => item.parentId === parentId //&& (!search || item.title.includes(search))
			)
			if (search) {
				list = list.filter((item: any) => item.title.includes(search))
			}
			sortedList.push(
				list
					.filter((item: any) => item.type === "CABINET")
					.sort(compareValues("title"))
			)
			sortedList.push(
				list
					.filter((item: any) => item.type === "PROJECT")
					.sort(compareValues("title"))
			)
			// console.log(list, sortedList, "sortedList")
			return list.sort((a: any, b: any) => {
				if (a.type === b.type) {
					return a.title.localeCompare(b.title)
				}
				return a.type === "CABINET" ? -1 : 1
			})
		},
		getChildrenInTheFlatListAsOptions(parentId: string) {
			const list = self.orgGroupFlatList.filter(
				(item) => item.parentId === parentId
			)
			const sortedList = list.sort(compareValues("title"))
			let options: BreadCrumbsOptionProps[] = []
			sortedList.map((item) => {
				options.push({
					id: item.id,
					name: item.title,
					objFormat: item.type,
				})
			})

			return options.sort((a, b) => {
				if (a.objFormat === "CABINET" && b.objFormat !== "CABINET") {
					return -1 // a comes first
				} else if (
					a.objFormat !== "CABINET" &&
					b.objFormat === "CABINET"
				) {
					return 1 // b comes first
				} else {
					return 0 // order remains unchanged
				}
			})
		},

		get groupOptions() {
			const groups = self.orgGroupFlatList.filter(
				(item) => item.type === DLObjectFormat.group
			)
			let options: BreadCrumbsOptionProps[] = []
			groups.map((group) => {
				options.push({
					id: group.id,
					name: group.title,
					objFormat: DLObjectFormat.group,
				})
			})
			return options
		},

		cabinetOptionsToCreateProject(groupId: string) {
			if (groupId) {
				const cabinets = self.orgGroupFlatList
					.filter((item) => item.parentId === groupId)
					.filter((item) => item.type === DLObjectFormat.cabinet)

				let options: { id: string; title: string }[] = []
				cabinets.map((cabinet) => {
					options.push({
						id: cabinet.id,
						title: cabinet.title,
					})
				})
				if (cabinets.length === options.length) {
					ConsoleLog("cabinetList - options " + options)
					return options
				} else {
					return []
				}
			} else {
				ConsoleLog(
					"(cabinetOptionsToCreateProject) groupId is required to get cabinet list"
				)
				return []
			}
		},

		getGroupsOnly(dntFormat: DnTFormatProps) {
			const { dateFormat, timeFormat, timeZone } = dntFormat
			function formatTime(date: string | null) {
				if (date) {
					return formattedDateWithTz2({
						date,
						timeZone,
						timeFormat,
						dateFormat,
					})
				}
				return ""
			}
			const groups = self.orgGroupFlatList
				.filter((item) => item.type === DLObjectFormat.group)
				.map((item: any) => ({
					...item,
					createdAt: formatTime(item.createdAt),
				}))
			return groups
		},
		get groupsCount() {
			const groups = self.orgGroupFlatList.filter(
				(item) => item.type === DLObjectFormat.group
			)
			return groups.length
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgGroupsViewModel
