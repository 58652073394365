import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// separated actions
import ReadOrgTrashList from "./model-actions/read-org-trash-list"
import RestoreOrgTrash from "./model-actions/restore-org-trash"
import DeleteOrgTrash from "./model-actions/delete-org-trash"
import { OrgTrashApiProps } from "./org-trash-apis"
import ReadOrgNotiList from "../../../../service-modules/post/store-model-actions/read-org-noti-list"
import PermanentDeletePost from "../../../../service-modules/post/store-model-actions/permanent-delete-post"
import DeleteOrRestorePost from "../../../../service-modules/post/store-model-actions/delete-or-restore-post"

const OrgTrashModel = ({ apiRead, apiRemove, apiRestore }: OrgTrashApiProps) =>
	types
		.model({})
		// CRUD
		.actions((self, read = apiRead) => ReadOrgTrashList(self, read))
		.actions((self, restore = apiRestore) => RestoreOrgTrash(self, restore))
		.actions((self, remove = apiRemove) => DeleteOrgTrash(self, remove))
		.actions(ReadOrgNotiList)
		.actions(PermanentDeletePost)
		.actions(DeleteOrRestorePost)
		// Common model actions
		.actions(CommonModelActions)

export default OrgTrashModel
