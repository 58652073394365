import React from "react"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
} from "../../../components/basic-elements/icons/common-icons"
import styled from "styled-components"
import { DLIconButton } from "../../../components/basic-elements"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"

export type AttachmentForView = {
	fileName: string
	extension: string
	formattedSize: string
	key: string
}

export const AttachmentsForView = ({
	files,
	downloadHandler,
	eleClassName,
}: {
	files: AttachmentForView[]
	downloadHandler: (s3ObjKey: string, fileName: string) => void
	eleClassName?: string
}) => {
	return (
		<StyledAttachmentsForView className={eleClassName}>
			<div className="FR AC JSB">
				<div className="FR AC">
					Attachments (
					<span data-testid="attachments-count">{files?.length}</span>
					)
				</div>
			</div>
			<ReactTableV8
				tableColumns={columns(downloadHandler)}
				data={files}
				menuId="AttachmentForView"
				showPageSetting={false}
			/>
		</StyledAttachmentsForView>
	)
}

const columns = (
	downloadHandler: (s3ObjKey: string, fileName: string) => void
) => [
	{
		header: "index",
		size: 30,
		cell: (props: any) => {
			return <div>{props.row.index + 1}</div>
		},
	},
	{
		header: "Name",
		accessorKey: "fileName",
		cell: (props: any) => {
			const cellId = props.row.cell.id + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue()}.{props.row.original.extension}
				</div>
			)
		},
	},
	// { header: "Extension", accessorKey: "extension", width: 100 },
	{
		header: "Size",
		accessorKey: "formattedSize",
		width: 100,
		cell: (props: any) => {
			const cellId = props.row.cell.id + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: "Download",
		accessorKey: "x",
		width: 40,
		cell: (props: any) => {
			const s3ObjKey = props.row.original.s3ObjKey
			const fileName = props.row.original.fileName
			return (
				<DLIconButton
					eleTestId="download-file-btn"
					clickHandler={() => downloadHandler(s3ObjKey, fileName)}
				>
					<DLIcon
						name={DLIconName.download}
						size={CommonIconSize.small}
						noMargin
					/>
				</DLIconButton>
			)
		},
	},
]

const StyledAttachmentsForView = styled.div`
	.attachement-list {
		height: 90px;
		overflow-y: auto;
		.attachment-item {
			width: 100%;
			border: 1px solid pink;
		}
		.no-attachments {
		}
	}
`

export default AttachmentsForView
