import { flow } from "mobx-state-tree"

const DeleteFinancialSubGroup = (self: any, delFinSubGroup: any) => ({
	deleteFinancialSubGroup: flow(function*(payload: { id: number }) {
		const actionName = "deleteFinancialSubGroup"
		try {
			const response = yield delFinSubGroup(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default DeleteFinancialSubGroup
