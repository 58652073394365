import { flow } from "mobx-state-tree"

const ReadTrialBalanceDetails = (self: any, readTrialBalance: any) => ({
	readTrialBalanceDetails: flow(function*(payload: {
		clientId: number
		tbNameId: number
		year: number
	}) {
		const actionName = "readTrialBalanceDetails"
		try {
			const response = yield readTrialBalance(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadTrialBalanceDetails
