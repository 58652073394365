const SearchUiActions = (self: any) => ({
	setSearchType(type: "file" | "folder") {
		self.searchType = type
	},
	setSearchQuery(query: string) {
		self.searchQuery = query
	},
	setSearchFocusIndex(index: number) {
		self.searchFocusIndex = index
	},
	selectPrevMatch() {
		const searchResultCount = self.searchResultCount
		if (searchResultCount) {
			self.searchFocusIndex =
				self.searchFocusIndex !== null
					? (searchResultCount + self.searchFocusIndex - 1) %
					  searchResultCount
					: searchResultCount - 1
		}
	},
	selectNextMatch() {
		self.searchFocusIndex =
			self.searchFocusIndex !== null
				? (self.searchFocusIndex + 1) %
				  (self.searchResultCount ? self.searchResultCount : 1)
				: 0
	},
	setSearchResultCount(value: any) {
		self.searchResultCount = value
	},
})

export default SearchUiActions
