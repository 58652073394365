import React, { useEffect } from "react"
import { DLDialog, DLButton } from "../../../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import Icon from "@mdi/react"
import {
	mdiCancel,
	mdiAccountCircleOutline,
	mdiInformationOutline,
	mdiCheck,
	mdiTimerSand,
} from "@mdi/js"
import styled from "styled-components"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

// dialog
export default observer(function SuspendOrgUserDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const userStore = orgStore.setupUsers
	const actionName = "suspendUser"

	const handleSuspend = () => {
		userStore.suspendUser(userStore.selectedUser.id)
	}

	useEffect(() => {
		userStore.precheckSuspend(userStore.selectedUser.id)
	}, [])

	const setOpen = () => {
		// reset assigned project list before open this dialog to initialize the list
		userStore.resetAssignedProjects()
		userStore.setSuspendDialogOpen()
	}

	return (
		<DLDialog
			eleTestId="suspend-org-user-dialog"
			isOpen={userStore.suspendDialogOpen}
			setIsOpen={setOpen}
			handleAction={handleSuspend}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle="Suspend Organization User"
			dialogContents={<SuspendDialogContents />}
			openBtn={
				<DLButton
					variant="text"
					eleTestId="fwf"
					startIcon={<Icon path={mdiCancel} size={1} />}
					color="default"
				>
					Suspend User
				</DLButton>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twSuspend || "Suspend"}
			maxWidth="sm"
			fullWidth={true}
			actionReady={true}
			// TODO: Update below dialog error
			// dialogError={
			// 	userStore.actionStatus !== "SUCCESS"
			// 		? userStore.responses.getResponse(actionName)?.messsage
			// 		: undefined
			// }
			showSpinner={
				userStore.responses.getActionStatus(actionName) === "LOADING" ||
				userStore.responses.getActionStatus("precheckSuspend") ===
					"LOADING"
			}
			cannotUpdate={
				userStore.responses.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})

const SuspendDialogContents = observer(() => {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const userStore = orgStore.setupUsers
	const actionName = "temp_action_name"

	const timeZone = store.global.timeZone.uiValue
	const dateFormat = store.global.dateFormat.value
	const userInfo = userStore.userDetail(
		userStore.selectedUser.id,
		dateFormat,
		timeZone
	)
	return (
		<StyledSuspendDialogContents>
			{userInfo && (
				<div
					className="user-info FR AC"
					data-testid="user-info-section"
				>
					<Icon path={mdiAccountCircleOutline} size={1} />{" "}
					<p>
						{userInfo.name} ({userInfo.aliasId}) ({userInfo.email})
					</p>
				</div>
			)}

			<div className="assigned-info" data-testid="assigned-info-section">
				{userStore.assignedProjList.length > 0 ? (
					<div>
						<div className="FR AC">
							<Icon path={mdiInformationOutline} size={1} />{" "}
							<p>
								This User is assigned to{" "}
								<span>
									{userStore.assignedProjList?.length}
								</span>{" "}
								projects.
							</p>
						</div>
						<div className="assigned-project-list">
							{userStore.assignedProjList.map(
								(item: any, i: number) => {
									return (
										<div
											key={item.title + i}
											className="FR AC item-row"
										>
											<span className="index">
												{i + 1}
											</span>
											<span className="divider">|</span>
											<DLTooltip title="Client">
												<span>{item.clientName}</span>
											</DLTooltip>
											<span className="divider">|</span>
											<DLTooltip title="Title">
												<span>{item.title}</span>
											</DLTooltip>
											<span className="divider">_</span>
											<DLTooltip title="Period">
												<span>{item.periodName}</span>
											</DLTooltip>
											<span className="divider">_</span>
											<DLTooltip title="Status">
												<span>{item.statusName}</span>
											</DLTooltip>
										</div>
									)
								}
							)}
						</div>
					</div>
				) : userStore.getActionStatus(actionName) === "LOADING" ? (
					<div className="FR AC">
						<Icon path={mdiTimerSand} size={1} />
						<p>Please wait. Checking assigned projects.</p>
					</div>
				) : (
					<div className="no-assigned-project FR AC">
						{userStore.getActionStatus(actionName) !==
							"LOADING" && (
							<>
								<Icon path={mdiCheck} size={1} />
								<p>
									There are no projects assigned to this user.
								</p>
							</>
						)}
					</div>
				)}
			</div>
			<div className="confirmation" data-testid="confirmation-section">
				<p>Do you want to suspend this user?</p>

				<div className="note">
					<p>Suspended users cannot login to this organization.</p>
					<p>You can restore the suspended user at any time.</p>
				</div>
			</div>
		</StyledSuspendDialogContents>
	)
})

const StyledSuspendDialogContents = styled.div`
	.user-info {
		margin-bottom: 1rem;
		font-weight: 600;
		p {
			padding-left: 0.2rem;
		}
	}
	.assigned-info {
		margin-bottom: 2rem;
		svg {
			fill: orange;
		}
		p {
			padding-left: 0.2rem;
			color: orange;
			span {
				font-weight: 600;
			}
		}

		.no-assigned-project {
			svg {
				fill: green;
			}
			p {
				color: green;
			}
		}
		.assigned-project-list {
			padding-left: 1.6rem;
			height: 40vh;
			overflow-y: auto;
			.item-row {
				white-space: nowrap;
			}
			.divider {
				margin: 0 0.4rem;
			}
		}
	}
	.confirmation {
		.note {
			padding-left: 1.6rem;
			p {
				color: gray;
				margin: 0.3rem 0;
			}
		}
	}
`
