import { types } from "mobx-state-tree"

export const CommentsList = types.model({
	type: types.string,
	completed: types.number,
	open: types.number,
	total: types.number,
})


export const Adjustments = types.model({
    audit: types.number,
    client: types.number,
    total: types.number
})

export const WPStatusDetails = types.model({
    clientName: types.string,
    concurringPartner: types.number,
    notStarted: types.number,
    engagementPartner: types.number,
    prepared: types.number,
    reviewed: types.number,
    total: types.number,
})

export const AuditProgramStatus = types.model({
    preparedCompletedLately:types.number,
    preparedCompletedTimely: types.number,
    preparedNotAssigned:types.number,
    preparedOpenNotDue: types.number,
    preparedOpenOverDue: types.number,
    reviewedCompletedLately: types.number,
    reviewedCompletedTimely: types.number,
    reviewedNotAssigned: types.number,
    reviewedOpenNotDue: types.number,
    reviewedOpenOverDue: types.number,
    totalSteps: types.number,
    wpDesc: types.string,
    wpId: types.string,
    wpRef: types.string,
})