import { flow } from "mobx-state-tree"
import { OrgSetupUserApiProps } from "../org-setup-users.apis"

const UpdateOrgUser = (
	self: any,
	update: OrgSetupUserApiProps["apiUpdate"]
) => ({
	updateOrgUser: flow(function* (userInfo) {
		const actionName = "updateOrgUser"
		try {
			const response = yield update(userInfo)
			// ConsoleLog("updateOrgUser response", response)
			return response
		} catch (error) {
			// console.error("updateOrgUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateOrgUser
