import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import LoginScreen from "./LoginScreen"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import LoginSnackbar from "./sub-components/LoginSnackbar"
import { Redirect } from "react-router-dom"
import { clearLocalStorageForLobby } from "../../../library/token-utils"

export default observer(function LoginScreenController2() {
	const store = useRootStore()
	const actionName = "login"

	const handleLogin = (userInfo: {
		username: string
		password: string
		adminPassword: string
		isAppAdminLogin: boolean
	}) => {
		const { username, password, adminPassword, isAppAdminLogin } = userInfo

		// NOTE: Do not clear all for page setting
		clearLocalStorageForLobby()

		if (isAppAdminLogin) {
			localStorage.setItem("isAdminAppUser", JSON.stringify(true))
			store.checkin.adminLogin({
				username,
				password,
				adminPassword,
			})
		} else {
			localStorage.setItem("isAdminAppUser", JSON.stringify(false))
			store.checkin.login({
				username,
				password,
			})
		}
	}

	let actionStatus = store.checkin.getActionStatus(actionName)

	return (
		<>
			{actionStatus === "SUCCESS" ? (
				<Redirect to="/lobby-checkin" />
			) : (
				<LoginScreen
					actionStatus={actionStatus}
					handleLogin={handleLogin}
				/>
			)}
			<LoginSnackbar />
		</>
	)
})
