import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
import ReadOnlineProjects from "./model-actions/read-online-projects"
import { ReadAllFiles } from "../../../../../service-modules/file-module/model-actions/file"
import { ReportsMgmtApiProps } from "./reports-management.apis"
import ReadChecklists from "./model-actions/read-checklists"
import CreateChecklist from "./model-actions/create-checklist"
import ReadChecklistCollections from "./model-actions/read-checklist-collections"

const ReportsManagementModel = ({
	apiRead,
	apiReadAllFiles,
	apiReadChecklists,
	apiAddChecklist,
	apiReadChecklistCollections
}: ReportsMgmtApiProps) =>
	types
		.model({})
		.actions((self) => ReadOnlineProjects(self, apiRead))
		.actions((self) => ReadAllFiles(self, apiReadAllFiles))
		.actions((self) => ReadChecklists(self, apiReadChecklists))
		.actions((self) => CreateChecklist(self, apiAddChecklist))
		.actions((self) => ReadChecklistCollections(self, apiReadChecklistCollections))
		// Common model actions
		.actions(CommonModelActions)

export default ReportsManagementModel
