import { SupportedLanguage } from "../../../../common-models/enumerations/common-enums"
import defaultI18n_enUS from "../../../../admin-static-data/i18n-table/220106-i18n-enUS.json"
import defaultI18n_koKR from "../../../../admin-static-data/i18n-table/230817-i18n-koKR-manual-update.json"

export function getStaticDict(
	moduleId: string,
	language: SupportedLanguage,
	from?: string
) {
	const defaultSheet =
		language === SupportedLanguage.us
			? defaultI18n_enUS
			: language === SupportedLanguage.kr
			? defaultI18n_koKR
			: defaultI18n_enUS
	const targetModule = JSON.parse(JSON.stringify(defaultSheet))[moduleId]
	/**
	 *
	 * * Data example
	 * {
	 * 	"TestModule": [
	 * 		"twKey1": {
	 * 		  "key": "twKey1",
	 * 		  "default": "Key1"
	 * 		},
	 * 		"twKey2": {
	 * 		  "key": "twKey2",
	 * 		  "default": "Key2"
	 * 		}
	 * 	  ],
	 *    ...
	 * }
	 */
	let result: { [x: string]: string } = {}
	if (targetModule === undefined) {
		// ConsoleLog(
		// 	`(getStaticDict) moduleId: ${moduleId} | from: ${from} | - targetModule in the default sheet is undefined`
		// )
		return {}
	} else {
		targetModule.map((item: any) => {
			const key = item.key
			result[key] = item.value
		})
	}

	return result
}
