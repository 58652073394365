import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

export type EditFileFolderProps = {
	folderId: string
	folderName: string
	postAction?: any
}

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const EditFileFolder = (self: any) => ({
	editFileFolder({ folderId, folderName, postAction }: EditFileFolderProps) {
		// 0.
		const actionName = "editFileFolder"
		// const menuId = self.storeName
		// 1.
		// 2.
		if (folderName === "" && folderName === undefined) {
			alert("Cannot use empty title. Please try again")
			return
		}
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		self.updateFileFolder({ folderId, folderName })
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)

				if (response.status === 200 && response.data.Status === 1) {
					/**
					 * AFTER CARE
					 */
					self.reflectFolderName(folderId, folderName)
					postAction && postAction()
					// handle response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
					})
				} else if (response === false) {
					return
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditFileFolder
