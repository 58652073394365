import React from "react"
import { ClickPointProps } from "../../../../../common-models/types/dialog.props"
import { observer } from "mobx-react-lite"
import { DLContextMenu } from "../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
	IconPlaceholder,
} from "../../../../../components/basic-elements/icons/common-icons"
import {
	DialogActionType,
	DLCommentType,
} from "../../../../../common-models/enumerations/common-enums"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { CommentStatus } from "../store/data-models/comments.data-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function CommentsCtxMenus({
	clickPoint,
	handleCtxMenuClose,
	handleDetailDialogOpen,
	handleDelete,
	handleCloseComment,
	permission,
	i18n,
}: {
	clickPoint: ClickPointProps
	handleCtxMenuClose: () => void
	handleDetailDialogOpen: (mode: DialogActionType) => void
	handleDelete: () => void
	handleCloseComment: () => void
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()
	const dntFormat = store.global.getDntFormat
	const commentId = projStore.comments.selectedItems[0].id
	const commentInfo = projStore.comments.viewCommentById(commentId, dntFormat)
	// ConsoleLog("commentInfo", commentInfo)

	let createdBy = commentInfo?.createdBy.name
	let status = commentInfo?.status
	let type = commentInfo?.type

	// NOTE: MUST UPDATE TOGETHER WITH 'CommentCardSimple' hasDeleteCommentAccess logic
	const projRole = projStore.checkin.user.roleShortName
	const isNormalComment = type === DLCommentType.normal
	const isCompleted = status === CommentStatus.done
	const currentUserId = projStore.checkin.userId
	const currentUserName = projStore.projUsers.viewUserInfo(currentUserId).name

	const hasDeleteCommentAccess = () => {
		if (isNormalComment) {
			// review comment
			if (createdBy === currentUserName) {
				return permission.deleteOwn
			} else {
				return permission.deleteOthers
			}
		} else {
			// QC comment
			if (projRole === "QC") {
				if (createdBy === currentUserName) {
					return permission.deleteOwn
				} else {
					return permission.deleteOthers
				}
			}
		}
	}

	// NOTE: Must update together: CommentsTable.tsx, CommentCardSimple
	const hasEditCommentAccess = () => {
		if (isCompleted) {
			return false
		} else {
			if (isNormalComment) {
				// review Comment
				return permission.update
			} else {
				// qc comment
				if (projRole === "QC") {
					return permission.update
				} else {
					return false
				}
			}
		}
	}

	// NOTE: WARNING: This method render again and again... isn't it? Need to check
	const options = () => [
		// {
		// 	label: "Open File",
		// 	value: "ctx-open-file-of-comment",
		// 	//TODO: WARNING: how to check the accessibility
		// 	clickEvent: () => {},
		// 	disabled: false,
		// 	icon: <IconPlaceholder />,
		// },
		{
			label: i18n.twViewComment || "View Comment",
			value: "ctx-view-comment",
			available: permission.read,
			clickEvent: () => handleDetailDialogOpen(DialogActionType.read),
			// disabled: isCompleted,
			icon: <IconPlaceholder />,
		},
		{
			label: i18n.twEditComment || "Edit Comment",
			value: "ctx-edit-comment",
			available: permission.update,
			clickEvent: () => handleDetailDialogOpen(DialogActionType.edit),
			disabled: !hasEditCommentAccess(),
			icon: (
				<DLIcon
					name={DLIconName.editComment}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		// {
		// 	label: "Open Comment",
		// 	value: "ctx-open-comment",
		// 	available: permission.update || permisison.read,
		// 	clickEvent: () => handleDetailDialogOpen(DialogActionType.edit),
		// 	disabled: isCompleted,
		// 	icon: (
		// 		<DLIcon
		// 			name={DLIconName.editComment}
		// 			size={CommonIconSize.ctxMenuIcon}
		// 		/>
		// 	),
		// },
		{
			label: i18n.twMarkClosed || "Mark Closed",
			value: "ctx-signOff-comment",
			available: permission.signOff,
			clickEvent: () => handleCloseComment(),
			disabled: isCompleted,
			icon: <IconPlaceholder />,
		},
		{
			label: i18n.twDelete || "Delete",
			value: "ctx-delete-comment",
			// TODO: WARNING: How to check user's own and other's comment?
			available: permission.deleteOwn,
			clickEvent: () => handleDelete(),
			disabled: !hasDeleteCommentAccess(),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	return (
		<DLContextMenu
			eleId="comments-ctx-menus"
			clickPoint={{
				mouseX: clickPoint.mouseX,
				mouseY: clickPoint.mouseY,
			}}
			handleClose={handleCtxMenuClose}
			menuOptions={options()}
			// hasDivider={[0, 2]} // when the open object option is available
		/>
	)
})
