import React from "react"
import { Icon } from "@mdi/react"
import { mdiChevronUp, mdiChevronDown, mdiFolder, mdiFolderOpen } from "@mdi/js"

function formatSignoff(
	info: { by: string; at: string; role: string } | null,
	aliasId?: string,
	columnName?: string
) {
	let signoff = "-"
	if (info !== null && info !== undefined && info.at !== undefined) {
		signoff = "(" + info.role + ") " + info.by + " " + info.at
	}
	const testId = "latest-" + columnName + "-" + aliasId
	return (
		<div className="FR AC" data-testid={testId}>
			{signoff}
		</div>
	)
}

export const SignoffhistoryTableColumns = (
	columns: { label: string; id: string; display: boolean }[]
) => [
	{
		Header: "",
		accessor: "parentId",
		filterable: false,
		width: 32,
		Pivot: (row: any) => {
			const expanded = row.isExpanded
			return (
				<>
					{expanded ? (
						<Icon
							path={mdiChevronUp}
							size={0.8}
							data-testid="folder-chevron-up"
						/>
					) : (
						<Icon
							path={mdiChevronDown}
							size={0.8}
							data-testid="folder-chevron-down"
						/>
					)}
				</>
			)
		},
	},
	{
		Header: "Folder",
		accessor: "parentName",
		width: 140,
		Cell: () => {
			return
		},
		Aggregated: (row: any) => {
			const expanded = row.isExpanded
			return (
				<div
					className="FR AC"
					data-testid="folder"
					style={{
						fontWeight: 700,
					}}
				>
					{expanded ? (
						<Icon
							path={mdiFolderOpen}
							size={0.7}
							data-testid="folder-icon-open"
						/>
					) : (
						<Icon
							path={mdiFolder}
							size={0.7}
							data-testid="folder-icon-closed"
						/>
					)}{" "}
					<span
						style={{ marginLeft: "0.6rem" }}
						data-testid="folder-name"
					>
						{row.subRows[0].parentName}
					</span>
				</div>
			)
		},
	},
	{
		Header: "Ref. num",
		accessor: "aliasId",
		width: 60,
		show: columns.find((item) => item.id === "aliasId")?.display,
		Aggregated: (row: any) => {
			return ""
		},
		Cell: (row: any) => {
			return (
				<div data-testid={`file-aliasId-${row.value}`}>{row.value}</div>
			)
		},
	},
	{
		Header: "File Title",
		accessor: "title",
		show: columns.find((item) => item.id === "title")?.display,
		Aggregated: (row: any) => {
			return ""
		},
		Cell: (row: any) => {
			const aliasId = row.original?.aliasId
			return <div data-testid={`file-title-${aliasId}`}>{row.value}</div>
		},
	},

	{
		id: "prepare",
		Header: "Prepare",
		show: columns.find((item) => item.id === "prepare")?.display,
		accessor: (d: any) => d.lastSignoffs.prepare,
		Cell: (row: any) =>
			formatSignoff(row.value, row.original?.aliasId, "prepare"),
		Aggregated: (row: any) => {
			let signoff = ""
			return <div className="FR AC">{signoff}</div>
		},
	},
	{
		id: "reivew",
		Header: "Review",
		show: columns.find((item) => item.id === "review")?.display,
		accessor: (d: any) => d.lastSignoffs.review,
		Cell: (row: any) =>
			formatSignoff(row.value, row.original?.aliasId, "review"),
		Aggregated: (row: any) => {
			let signoff = ""
			return <div className="FR AC">{signoff}</div>
		},
	},
	{
		id: "epReview",
		Header: "EP Review",
		show: columns.find((item) => item.id === "epReview")?.display,
		accessor: (d: any) => d.lastSignoffs.epReview,
		Cell: (row: any) =>
			formatSignoff(row.value, row.original?.aliasId, "epReview"),
		Aggregated: (row: any) => {
			let signoff = ""
			return <div className="FR AC">{signoff}</div>
		},
	},
	{
		id: "cpReview",
		Header: "CP Review",
		show: columns.find((item) => item.id === "cpReview")?.display,
		accessor: (d: any) => d.lastSignoffs.cpReview,
		Cell: (row: any) =>
			formatSignoff(row.value, row.original?.aliasId, "cpReview"),
		Aggregated: (row: any) => {
			let signoff = ""
			return <div className="FR AC">{signoff}</div>
		},
	},
	{
		id: "qcReview",
		Header: "QC Review",
		show: columns.find((item) => item.id === "qcReview")?.display,
		accessor: (d: any) => d.lastSignoffs.qcReview,
		Cell: (row: any) =>
			formatSignoff(row.value, row.original?.aliasId, "qcReview"),
		Aggregated: (row: any) => {
			let signoff = ""
			return <div className="FR AC">{signoff}</div>
		},
	},
	{
		expander: true,
		Header: "",
		width: 40,
	},
]

export const SignoffhistoryTableColumns1 = (
	columns: { label: string; id: string; display: boolean }[]
) => [
	{
		header: "",
		accessorKey: "parentId",
		width: 32,
		cell: ({ row }: any) => {
			const expanded = row.getIsExpanded()
			console.log(row.getIsGrouped(), "rowrow1")
			return (
				<>
					{row.getIsGrouped() && (
						<div
							className="FR AC"
							onClick={row.getToggleExpandedHandler()}
						>
							{expanded ? (
								<>
									<Icon
										path={mdiChevronUp}
										size={0.8}
										data-testid="folder-chevron-up"
									/>
									<Icon
										path={mdiFolderOpen}
										size={0.7}
										data-testid="folder-icon-open"
									/>
								</>
							) : (
								<>
									<Icon
										path={mdiChevronDown}
										size={0.8}
										data-testid="folder-chevron-down"
									/>
									<Icon
										path={mdiFolder}
										size={0.7}
										data-testid="folder-icon-closed"
									/>
								</>
							)}
							<span
								style={{ marginLeft: "0.6rem" }}
								data-testid="folder-name"
							>
								{row.subRows[0].original.parentName}
							</span>
						</div>
					)}
				</>
			)
		},
	},
	{
		header: "Ref. num",
		accessorKey: "aliasId",
		size: 40,
		cell: (props: any) => {
			return (
				<div data-testid={`file-aliasId-${props.getValue()}`}>
					{props.getValue()}
				</div>
			)
		},
	},
	{
		header: "File Title",
		accessorKey: "title",
		cell: (props: any) => {
			const aliasId = props.row.original?.aliasId
			return (
				<div data-testid={`file-title-${aliasId}`}>
					{props.getValue()}
				</div>
			)
		},
	},

	{
		header: "Prepare",
		size: 100,
		accessorFn: (props: any) => props.lastSignoffs.prepare,
		cell: (props: any) => {
			if (props.row.getIsGrouped()) {
				return ""
			}
			return formatSignoff(
				props.getValue(),
				props.row.original?.aliasId,
				"prepare"
			)
		},
	},
	{
		header: "Review",
		accessorFn: (d: any) => d.lastSignoffs.review,
		size: 100,
		cell: (props: any) => {
			if (props.row.getIsGrouped()) {
				return ""
			}
			return formatSignoff(
				props.getValue(),
				props.row.original?.aliasId,
				"review"
			)
		},
	},
	{
		header: "EP Review",
		accessorFn: (d: any) => d.lastSignoffs.epReview,
		size: 100,
		cell: (props: any) => {
			if (props.row.getIsGrouped()) {
				return ""
			}
			return formatSignoff(
				props.getValue(),
				props.row.original?.aliasId,
				"epReview"
			)
		},
	},
	{
		header: "CP Review",
		accessorFn: (d: any) => d.lastSignoffs.cpReview,
		size: 100,
		cell: (props: any) => {
			if (props.row.getIsGrouped()) {
				return ""
			}
			return formatSignoff(
				props.getValue(),
				props.row.original?.aliasId,
				"cpReview"
			)
		},
	},
	{
		header: "QC Review",
		accessorFn: (d: any) => d.lastSignoffs.qcReview,
		cell: (props: any) => {
			if (props.row.getIsGrouped()) {
				return ""
			}
			return formatSignoff(
				props.getValue(),
				props.row.original?.aliasId,
				"qcReview"
			)
		},
	},
	{
		id: "expander",
		header: "",
		size: 60,
		cell: ({ row, getValue }: any) => {
			if (row.getIsGrouped()) {
				return ""
			}
			const expanded = row.getIsExpanded()
			console.log(row, row.groupingColumnId, getValue(), "rowrow")
			return (
				<div
					className="FR AC"
					data-testid="folder"
					style={{
						fontWeight: 700,
					}}
					onClick={() => row.toggleExpanded()}
				>
					{expanded ? (
						<Icon
							path={mdiChevronUp}
							size={0.8}
							data-testid="folder-chevron-up"
						/>
					) : (
						<Icon
							path={mdiChevronDown}
							size={0.8}
							data-testid="folder-chevron-down"
						/>
					)}
				</div>
			)
		},
	},
]
