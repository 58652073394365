import { types } from "mobx-state-tree"
import { OrgSetupChecklistModel } from "./org-setup-checklist.model"
import { OrgSetupChecklistViewModel } from "./org-setup-checklist.view-model"
// import { OrgSetupChecklistUiHelper } from "./org-setup-checklist.ui-helper"
import * as api from "./org-setup-checklist.apis"

const OrgSetupChecklistStore = ({
	apiRead,
	apiUpdate,
	apiCreate,
	apiDelete,
}: api.OrgSetupChecklistApiProps) =>
	types
		.compose(
			OrgSetupChecklistModel({
				apiRead,
				apiUpdate,
				apiCreate,
				apiDelete,
			}),
			OrgSetupChecklistViewModel
			// OrgSetupChecklistUiHelper
		)
		.named("OrgSetupChecklistStore")

export const initialStore = {
	checklistTemplateDetail: {
		checklistTemplate: {
			createdAt: "",
			createdBy: {
				id: "",
				aliasId: "",
				name: "",
			},
			id: "",
			name: "",
			assignedRoles: null,
		},
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSetupChecklistStoreInstance = OrgSetupChecklistStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiCreate: api.create,
	apiDelete: api.del,
}).create(initialStore)

export default OrgSetupChecklistStoreInstance
