// ---------- reponse cases
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { OrgLibraryType } from "../data-models/org-library.data-model"

const GetOrgFileLibShelfDetails = (self: any) => ({
	getOrgFileLibShelfDetails(templateId: string, parentType: OrgLibraryType) {
		const actionName =
			parentType === OrgLibraryType.wpTemplate
				? "getOrgWpTemplateDetail"
				: "getOrgAuditProgramTemplateDetail"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3
		// convert parentId to original format

		let targetId =
			templateId.indexOf("wpTemplateId") !== -1
				? templateId.split("wpTemplateId")[1]
				: templateId.split("apTemplateId")[1]
		const userId = localStorage.getItem("orgUserId")?.split("userId")[1]
		const payload = {
			templateId: parseInt(targetId),
			userId: userId,
			Lang: "en",
		}
		ConsoleLog([targetId, parentType])
		if (parentType === OrgLibraryType.wpTemplate) {
			self.readOrgWpTemplateDetails(payload)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						ConsoleLog(response.data.URL)
						self.setSelectedTemplateURL(response.data.URL)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage: "Success to get template details",
							open: true,
							autoHide: true,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		} else {
			self.readOrgApTemplateDetails(payload)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						ConsoleLog(response.data.URL)
						self.setSelectedTemplateURL(response.data.URL)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							customMessage: "Success to get template details",
							open: true,
							autoHide: true,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		}
	},
})

export default GetOrgFileLibShelfDetails
