import React, { useEffect, useState } from "react"
import Drawer from "@material-ui/core/Drawer"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import GlobalNotisOnDrawer from "../../../screens/lobby-side/right-drawer/GlobalNotisOnDrawer"
import OpenedFilesOnDrawer from "../../../screens/lobby-side/right-drawer/OpenedFilesOnDrawer"
import { DLTooltip } from "@datalobby/components"
import {
	mdiBullhorn,
	mdiCalendarMultipleCheck,
	mdiDownload,
	mdiPlaylistEdit,
} from "@mdi/js"
import { Icon } from "@mdi/react"
import { StyledGlobalRightSideDrawer } from "./StyledGlobalRightSideDrawer"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"
import DownloadCenterOnDrawer from "../../../screens/lobby-side/right-drawer/DownloadCenterOnDrawer"
import TasksOnDrawer from "../../../screens/lobby-side/right-drawer/TasksOnDrawer"
import {
	onCreatePost,
	OnCreatePostSubscription,
	onDeletePost,
	OnDeletePostSubscription,
	onUpdatePost,
	OnUpdatePostSubscription,
	// onVisitPost,
	// OnVisitPostSubscription,
	setTypeOnDeletePostSubs,
	onUpdatePostHandler,
} from "../../../graphql/Post/subscriptions"
import { PostType } from "@datalobby/types"
import { subscribeGraphQL, subscribeGraphQL2 } from "@datalobby/common"
import { DLBadge } from "../../basic-elements"
import { DnTFormatProps } from "../../../common-models/types/common-props"

export default observer(function RightDrawer({
	dntFormat,
	userList,
}: {
	dntFormat: DnTFormatProps
	userList: any[]
}) {
	const store = useRootStore()
	const notiStore = store.notis
	const orgId = localStorage.getItem("orgId")

	useEffect(() => {
		// if (
		// 	orgId &&
		// 	notiStore.getActionStatus("getOrgNotiList") !== ActionStatus.success
		// ) {
		// 	notiStore.getOrgNotiList(orgId)
		// }
	}, [])

	// NOTE: onCreate subscription --------------------------
	function setTypeOnCreatePostSubs(
		createPostSubscription: OnCreatePostSubscription
	): PostType {
		const post = createPostSubscription.onCreatePost || {}
		return post as PostType
	}

	const onCreatePostHandler = (
		createPostSubscription: OnCreatePostSubscription
	) => {
		const post = setTypeOnCreatePostSubs(createPostSubscription)
		if (post.parent === orgId) {
			notiStore.pushOrgNotiItem(post)
		}
	}

	useEffect(() => {
		const subscription = subscribeGraphQL<OnCreatePostSubscription>(
			onCreatePost,
			onCreatePostHandler
		)

		return () => subscription.unsubscribe()
	})

	// NOTE: onUpdate subscription --------------------------
	useEffect(() => {
		const subscription = subscribeGraphQL2<OnUpdatePostSubscription>(
			onUpdatePost,
			onUpdatePostHandler,
			notiStore.updateOrgNotiItem
		)

		return () => subscription.unsubscribe()
	}, [])

	// NOTE: onVisit subscription --------------------------
	// function setTypeOnVisitSubs(visitSubs: OnVisitPostSubscription): any {
	// 	const visitResult = visitSubs.onVisitPost
	// 	return visitResult || null
	// }
	// const onVisitPostHandler = (visitSubs: OnVisitPostSubscription) => {
	// 	const data = setTypeOnVisitSubs(visitSubs)
	// 	if (data) {
	// 		notiStore.updateOrgNotiVisitorInfo({
	// 			notiId: data.id,
	// 			visitor: data.visitor,
	// 		})
	// 	}
	// }
	// useEffect(() => {
	// 	const subscription = subscribeGraphQL<OnVisitPostSubscription>(
	// 		onVisitPost,
	// 		onVisitPostHandler
	// 	)

	// 	return () => subscription.unsubscribe()
	// }, [])

	// NOTE: onDelete subscription --------------------------
	const onDeletePostHandler = (deleteSubs: OnDeletePostSubscription) => {
		const postId = setTypeOnDeletePostSubs(deleteSubs)
		notiStore.sliceOrgNotiItem(postId)
	}

	useEffect(() => {
		const subscription = subscribeGraphQL<OnDeletePostSubscription>(
			onDeletePost,
			onDeletePostHandler
		)

		return () => subscription.unsubscribe()
	}, [])

	return (
		<Drawer
			anchor="right"
			open={store.ui.isRightDrawerOpen}
			onClose={() =>
				store.ui.setRightDrawerOpen(!store.ui.isRightDrawerOpen)
			}
		>
			<div style={{ width: 390 }}>
				{store.ui.isRightDrawerOpen && (
					<RightDrawerContainer
						dntFormat={dntFormat}
						userList={userList}
					/>
				)}
			</div>
		</Drawer>
	)
})

enum DrawerTabs {
	noti = "NOTIFICATIONS",
	openedFiles = "OPENED_FILES",
	download = "DOWNLOAD",
	tasks = "TASKS",
}

const RightDrawerContainer = observer(
	({
		dntFormat,
		userList,
	}: {
		dntFormat: DnTFormatProps
		userList: any[]
	}) => {
		const store = useRootStore()
		const [currentTab, setCurrentTab] = useState<DrawerTabs>(
			DrawerTabs.openedFiles
		)

		const handleSelectTab = (tab: DrawerTabs) => {
			setCurrentTab(tab)
		}

		return (
			<StyledGlobalRightSideDrawer>
				<div className="FR">
					<div className="help-menu-tabs FC AC">
						<div
							className={`tab FR AC JC`}
							style={{ height: "3rem" }}
						></div>
						<div
							className={`tab FR AC JC ${
								currentTab === DrawerTabs.tasks && "selected"
							}`}
							data-testid={`tab-tasks`}
							onClick={() => handleSelectTab(DrawerTabs.tasks)}
						>
							<DLTooltip title="Tasks" elePlacement="right" arrow>
								<div>
									<Icon
										path={mdiCalendarMultipleCheck}
										size={1}
									/>
								</div>
							</DLTooltip>
						</div>
						<div
							className={`tab FR AC JC ${
								currentTab === DrawerTabs.noti && "selected"
							}`}
							data-testid={`tab-notifications`}
							onClick={() => handleSelectTab(DrawerTabs.noti)}
						>
							<DLTooltip
								title="Notifications"
								elePlacement="right"
								arrow
							>
								<div>
									<DLBadge
										content={store.notis.viewNewOrgNotis}
										eleTestId="noti-new-badge"
										color={MessageColorType.red}
										eleClassName="noti-new-badge"
									>
										<Icon path={mdiBullhorn} size={1} />
									</DLBadge>
								</div>
							</DLTooltip>
						</div>
						<div
							className={`tab FR AC JC ${
								currentTab === DrawerTabs.openedFiles &&
								"selected"
							}`}
							data-testid={`tab-opened-files`}
							onClick={() =>
								handleSelectTab(DrawerTabs.openedFiles)
							}
						>
							<DLTooltip
								title="Opened Files"
								elePlacement="right"
								arrow
							>
								<div>
									<Icon path={mdiPlaylistEdit} size={1} />
								</div>
							</DLTooltip>
						</div>
						<div
							className={`tab FR AC JC ${
								currentTab === DrawerTabs.download && "selected"
							}`}
							data-testid={`tab-download-center`}
							onClick={() => handleSelectTab(DrawerTabs.download)}
						>
							<DLTooltip
								title="Download Center"
								elePlacement="right"
								arrow
							>
								<div>
									<Icon path={mdiDownload} size={1} />
								</div>
							</DLTooltip>
						</div>
					</div>
					<CommonStyleForNotiDrawer className="content-area">
						<div className="content-body">
							{currentTab === DrawerTabs.noti && (
								<GlobalNotisOnDrawer
									dntFormat={dntFormat}
									userList={userList}
								/>
							)}
							{currentTab === DrawerTabs.openedFiles && (
								<OpenedFilesOnDrawer />
							)}
							{currentTab === DrawerTabs.download && (
								<DownloadCenterOnDrawer />
							)}
							{currentTab === DrawerTabs.tasks && (
								<TasksOnDrawer />
							)}
						</div>
					</CommonStyleForNotiDrawer>
				</div>
			</StyledGlobalRightSideDrawer>
		)
	}
)

const CommonStyleForNotiDrawer = styled.div`
	.noti-header {
		height: 2rem;
		padding-top: 0.5rem;
	}
`
