import { types } from "mobx-state-tree"
// common models
// model common action
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// separated actions (CRUD)
import { VerifyCode, ResendVerificationCode } from "./model-actions"

// const ConfirmSignupModel = (apiVerify: any, apiResend: any) =>
const ConfirmSignupModel = (apiVerify: any, apiResend: any) =>
	types
		.model({})
		// CRUD actions
		.actions(self => VerifyCode(self, apiVerify))
		.actions(self => ResendVerificationCode(self, apiResend))
		// model common actions
		.actions(CommonModelActions)

export default ConfirmSignupModel
