import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	IdType,
	idToString,
} from "../../../../../library/converters/id-converter"

const type: any = {
	GROUP: IdType.group,
	CABINET: IdType.cabinet,
}

const GetCabinetLocation = (self: any) => ({
	getCabinetLocation(cabinetId: string) {
		// 0.
		const actionName = "getCabinetLocation"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.readCabinetLocation(cabinetId)
			.then((response: any) => {
				if (response.status === 200) {
					console.log(actionName + "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */

					const details = response.data.map((item: any) => ({
						id: idToString(item.Id, type[item.Type]),
						type: item.Type,
						name: item.Name,
					}))

					console.log(details)
					self.setCabinetLocationDetails(details)
					self.handleResponse({
						// set success case response
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get SOMETHING",
						open: false,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetCabinetLocation
