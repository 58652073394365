import { flow } from "mobx-state-tree"

const ReadClientMasterByGroup = (self: any, apiReadClientsByGroup: any) => ({
	readClientMasterByGroup: flow(function*(payload) {
		const actionName = "readClientMasterByGroup"
		try {
			const response = yield apiReadClientsByGroup(payload)

			// ConsoleLog("readGroupList reponse", response)
			return response
		} catch (error) {
			// console.error("readGroupList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadClientMasterByGroup
