import {
	DLSector,
	DLSrcFormat,
} from "../../common-models/enumerations/common-enums"
import { DLFileProps } from "./data-models/file.data-model"
import { DLOrgSubMenus } from "../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ConsoleLog } from "../../components/basic-elements"

export type OpenFileProps = {
	orgId: string
	sector: DLSector
	menuId: DLOrgSubMenus | DLProjSubMenus
	fileInfo: DLFileProps
	projectId: string // Project or Project Template ID
	userInfo: {
		id: string
		isReadonly: boolean
		isExternal: boolean
	}
	asReadonly: boolean
}

export const checkFileProps = ({
	orgId,
	projId,

	fileInfo,
	userInfo,
	asReadonly,
}: {
	orgId: string
	projId: string

	fileInfo: any // TODO: update
	userInfo: {
		id: string
		isReadonly: boolean
		isExternal: boolean
	}
	asReadonly: boolean
}) => {
	/**
	 * 1. if file.S3UploadStatus === 3 --> restrict to open (file upload is in progress)
	 * --- (this S3UploadStatus is not exist on project files...)
	 * 2. file.url exist
	 * ---- 2.1. xml, png, jpg, xbrl, ixbrl, xsd, dsd --> open new window with URL and fileId
	 * ---- 2.2. others --> download the file
	 * 3. custom format (AuditProgram)
	 * ---- open new window by dev-express iframe
	 * 4. excel, word, pdf
	 * ---- open new window by dev-express iframe
	 */

	ConsoleLog(" -------------- checkFileProps -------------- ")
	ConsoleLog("projId: " + projId)
	ConsoleLog(["fileInfo: ", fileInfo])
	ConsoleLog(["userInfo: ", userInfo])
	ConsoleLog(" -------------- checkFileProps end-------------- ")

	const menuId = fileInfo.menuId
	const sector =
		menuId === DLOrgSubMenus.proj_templates ? DLSector.org : DLSector.proj
	const srcFormat = fileInfo.srcFormat

	let newProjectId = projId
	if (menuId === DLOrgSubMenus.proj_templates) {
		// NOTE: WARNING: Because current project template has no srcId,
		// Need to update the fileInfo
		newProjectId = projId.replace("projectTemplateId", "projId") // NOTE: @Noah: this part has been updated.. but not sure what is the default value.
		const fileId = fileInfo.id
		const srcId = fileId.replace("fileId", "srcId")
		fileInfo = { ...fileInfo, srcId }
	}

	const props = {
		orgId,
		sector,
		menuId,
		fileInfo,
		projectId: newProjectId,

		userInfo,
		asReadonly,
	}

	if (fileInfo.uploadStatus === "inProgress") {
		alert("File upload is in progress. Please try again after some time.")
	} else {
		if (srcFormat === "(unknown)" || srcFormat === DLSrcFormat.unknown) {
			alert("Unknown format. Please check")
		} else if (
			srcFormat === DLSrcFormat.excel ||
			srcFormat === DLSrcFormat.word ||
			srcFormat === DLSrcFormat.pdf ||
			//
			srcFormat === DLSrcFormat.leadSheet ||
			srcFormat === DLSrcFormat.fileGroup ||
			srcFormat === DLSrcFormat.auditProgram ||
			srcFormat === DLSrcFormat.fluxAnalysis ||
			srcFormat === DLSrcFormat.finalAnalysis ||
			srcFormat === DLSrcFormat.aje ||
			srcFormat === DLSrcFormat.paje
		) {
			return { useEditor: true, props }
		} else {
			return { useEditor: false, props }
		}
	}
}
