import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { hasEmptyProps } from "@datalobby/common"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const version = process.env.REACT_APP_API_VERSION

const EditOrgUser = (self: any) => ({
	editOrgUser(userInfo: any) {
		const actionName = "editOrgUser"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		let type
		if (userInfo.type.value === "internal") {
			type = 1
		} else if (userInfo.type.value === "external") {
			type = 2
		}

		let accessRight
		if (userInfo.accessRight.value === "Super Admin") {
			accessRight = 1
		} else if (userInfo.accessRight.value === "Group Admin") {
			accessRight = 2
		} else if (userInfo.accessRight.value === "User Access") {
			accessRight = 3
		} else if (userInfo.accessRight.value === "External User") {
			accessRight = 4
		}

		let isArchiveAdmin
		if (userInfo.isArchiveAdmin.value === "false") {
			isArchiveAdmin = 0
		} else if (userInfo.isArchiveAdmin.value === "true") {
			isArchiveAdmin = 1
		}
		const props = {
			Name: userInfo.name.value,
			UserName: userInfo.aliasId.value,
		}

		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}

		const userId = idToNumber(self.selectedUser.id, IdType.user)

		const reOrganizedUserInfoForApi = {
			...checkedProps.props,
			AccessTypeID: accessRight,
			ArchiveManager: isArchiveAdmin,
			Title: userInfo.title.value,
			UserID: userId,
			UserType: type,
		}
		self.updateOrgUser(reOrganizedUserInfoForApi)
			.then((response: any) => {
				ConsoleLog(response)
				let responseStatus = 0

				// * ----- API version control
				const basicStatus = response.status
				// const prevStatus = response.data?.status?.code // ?? -- No status code is getting
				if (version === "v2") {
					responseStatus = response.data.Data.Status
				} else {
					responseStatus = response.data.Status
				}
				if (basicStatus === 200 && responseStatus === 1) {
					// TODO: Success checking is required
					// at the end of the action
					self.updateUserData(userInfo, self.selectedUser.id)
					self.setEditDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						// message: response.data.Message,
						customMessage: "Success to update the user information",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message,
						customMessage: "Failed",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditOrgUser
