import { types } from "mobx-state-tree"
// model & view-model
import OrgMgmtModel from "./org-mgmt.model"
import OrgMgmtViewModel from "./org-mgmt.view-model"
import OrgMgmtUiHelper from "./org-mgmt.ui-helper"
//apis
import * as api from "./org-mgmt.apis"

export const OrgMgmtStore = (apiRead: any, apiCreate: any, apiUpdate: any) =>
	types
		.compose(
			OrgMgmtModel(apiRead, apiCreate, apiUpdate),
			OrgMgmtViewModel,
			OrgMgmtUiHelper
		)
		.named("OrgMgmtStore")

export const initialStore = {
	//ui-helper
	selectedId: "",
	openAdd: false,
	openEdit: false,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgMgmtStoreInstance = OrgMgmtStore(
	api.read,
	api.create,
	api.update
).create(initialStore)

export default OrgMgmtStoreInstance
