import { flow } from "mobx-state-tree"
import { AmUnarchivedApiProps } from "../am-unarchived-projects.apis"

const DeleteAmUnarchivedProject = (
	self: any,
	del: AmUnarchivedApiProps["apiDelete"]
) => ({
	deleteAmUnarchivedProject: flow(function*(payload) {
		const actionName = "deleteAmUnarchivedProject"
		try {
			const response = yield del(payload)
			// ConsoleLog(
			// 	"deleteAmUnarchivedProject",
			// 	response
			// )
			return response
		} catch (error) {
			// ConsoleLog("deleteAmUnarchivedProject error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteAmUnarchivedProject
