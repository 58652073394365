import React from "react"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function RestoreOrgUserDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	ConsoleLog(" -=-=-=-=- RestoreOrgUserDialog -=-=-=-=- ")

	const orgStore = useOrgStore()
	const actionName = "restoreUser"
	const restoreFrom = orgStore.setupUsers.restoreFrom
	const handleRestore = () => {
		if (restoreFrom === "createDialog") {
			const email = orgStore.setupUsers.restoreEmail
			orgStore.setupUsers.restoreUser("userId", email)
		} else {
			const userInfo = orgStore.setupUsers.viewSelectedUserInfo()
			orgStore.setupUsers.restoreUser(
				orgStore.setupUsers.selectedUser.id,
				userInfo.email
			)
		}
	}

	const actionStatus = orgStore.setupUsers.getActionStatus(actionName)
	return (
		<DLDialog
			eleTestId="restore-org-user-dialog"
			isOpen={orgStore.setupUsers.restoreDialogOpen}
			setIsOpen={orgStore.setupUsers.setRestoreDialogOpen}
			handleAction={handleRestore}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twRestoreDialogTitle}
			dialogContents={
				<div>
					{restoreFrom === "createDialog"
						? i18n.tsRestoreMsg2
						: i18n.tsRestoreMsg}
				</div>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={i18n.twRestore}
			actionReady={true}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				actionStatus !== "SUCCESS"
					? orgStore.setupUsers.responses.getResponse(actionName)
							?.message
					: undefined
			}
			showSpinner={actionStatus === "LOADING"}
			cannotUpdate={actionStatus === "LOADING"}
		/>
	)
})
