import { ConsoleLog } from "../components/basic-elements"
import { ActionStatus, MessageColorType } from "./enumerations/common-enums"

export type HandleResponseProps = {
	actionName: string
	status: ActionStatus
	code?: number // API response code
	// ---- for message
	msgCode?: string
	message?: string
	customMessage?: string
	color?: MessageColorType
	//
	open?: boolean
	autoHide?: boolean
	autoHideDuration?: number
}

export const CommonViewModelActions = (self: any) => ({
	/**
	 * shared response control 2 (03092020~ )
	 * This 'handleResponse' controls 'responses' and 'snackbar'
	 */
	handleResponse({
		actionName,
		status,
		code,
		//
		msgCode,
		message,
		customMessage,
		color,
		//
		open,
		autoHide,
		autoHideDuration,
	}: HandleResponseProps) {
		const newResponse = {
			actionName,
			status,
			code,
			message,
			customMessage,
		}
		self.responses.setResponse(actionName, newResponse)
		// part 2 - Update 'snackbar'
		self.responseSnackbar.setType(color)
		self.responseSnackbar.setMessage(customMessage || message)
		self.responseSnackbar.setAutoHide(autoHide)
		self.responseSnackbar.setAutoHideDuration(autoHideDuration)
		self.responseSnackbar.setOpen(open)
		self.responseSnackbar.setMsgCode(msgCode || status + "_" + actionName)
	},

	// ----- view model error
	handleViewModelError({
		actionName,
		error,
		open,
	}: {
		actionName: string
		error: any
		open?: boolean
	}) {
		ConsoleLog("(handleViewModelError error)", [
			actionName,
			error.toString(),
			error,
		])
		//
		// part 1 - Update 'responses'
		const newResponse = {
			actionName,
			code: 999, // TODO: need to update
			status: ActionStatus.fail,
			message: error.toString(),
		}
		self.responses.setResponse(actionName, newResponse)
		//
		// self.response.statusMessage = error.toString().slice(0, 40)
		self.responseSnackbar.setType(MessageColorType.red)
		//
		self.responseSnackbar.setMessage(
			error.toString().length > 0
				? "(" + actionName + ")" + error.toString()
				: `(${actionName}) No Status Text`
		)
		self.responseSnackbar.setAutoHide(false)

		open === true
			? self.responseSnackbar.setOpen(true)
			: self.responseSnackbar.setOpen(false)
	},
	// setActionStatus(request: ActionStatus) {
	// 	self.actionStatus = request
	// },
})

export const ViewResponseHelper = (self: any) => ({
	getActionStatus(actionName: string) {
		return self.responses.responses.get(actionName)?.status
	},
	getMessage(actionName: string) {
		const response = self.responses.responses.get(actionName)

		let message = "waiting for response..."
		const customMessage = response?.customMessage
		const serverMessage = response?.message

		if (response) {
			if (customMessage) {
				message = customMessage
			} else if (customMessage === undefined || customMessage === "") {
				if (serverMessage) {
					message = serverMessage
				}
			}
			// message = response.customMessage
		}

		return message
	},
})

export const Refresh = (self: any) => ({
	setNeedRefresh(request?: boolean) {
		if (request !== undefined) {
			self.needRefresh = request
		} else {
			self.needRefresh = !self.needRefresh
		}
	},
	refresh() {
		self.initializeStore()
	},
})
