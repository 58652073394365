import { types, applySnapshot } from "mobx-state-tree"
import { EngType } from "./data-models/org-setup-engTypes.data-models"

// ---------- common models
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"

import {
	AddEngType,
	GetEngTypeList,
	EditEngType,
	RemoveEngType,
} from "./view-model-actions"
import { format, isBefore, compareDesc } from "date-fns"
import { initialStore } from "./org-eng-types.provider"
import { getTzDate } from "../../../../../library/converters/date-utc-converter"
import GetChecklistConnections from "./view-model-actions/get-checklist-connections"
import GetChecklistTemplates from "./view-model-actions/get-checklist-templates"
import ConnectChecklistTemplate from "./view-model-actions/connect-checklist-template"

const ChecklistModel = types.model({
	id: types.string,
	name: types.string,
})

const OrgEngTypesViewModel = types
	.model({
		engTypeList: types.array(EngType),
		engagementId: types.string,
		//
		checklistTemplates: types.array(ChecklistModel),
		checklistConnections: types.array(
			types.model({
				id: types.string,
				projectTypeId: types.string,
				checklists: types.array(ChecklistModel),
			})
		),
		//
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setChecklistTemplates(templates: any) {
			self.checklistTemplates.length = 0
			self.checklistTemplates = templates
		},
		setChecklistConnections(connections: any) {
			self.checklistConnections.length = 0
			self.checklistConnections = connections
		},
		setChecklistConnectionByProjectType(
			projTypeId: string,
			connections: any
		) {
			// TODO:
		},
		resetList() {
			self.engTypeList.length = 0
		},
		setSelectedEngId(id: string) {
			self.engagementId = id
		},
		pushItemToList(item: any) {
			self.engTypeList.push(item)
		},
		setEngTypeList(items: any) {
			self.engTypeList.length = 0
			self.engTypeList = items
		},
		popEngType(id: string) {
			self.engTypeList.splice(
				self.engTypeList.findIndex((item) => item.id === id),
				1
			)
		},
		updateEngStore(engTypeName: string, engTypeId: string) {
			const target = self.engTypeList.find(
				(engType) => engType.id === engTypeId
			)
			if (target) {
				target.name = engTypeName
			} else {
				console.error("No matched engType ID")
			}
		},
	}))
	// ----------
	// AGER (Add, Get, Edit, Remove)
	.actions(AddEngType)
	.actions(GetEngTypeList)
	.actions(GetChecklistConnections)
	.actions(GetChecklistTemplates)
	.actions(EditEngType)
	.actions(RemoveEngType)
	.actions(ConnectChecklistTemplate)
	// ----------
	.views((self) => ({
		isDuplicatedName(name: string) {
			return self.engTypeList.some(
				(engType: any) => engType.name === name
			)
		},
	}))
	.views((self) => ({
		getEngTypeById(id: string) {
			return self.engTypeList.find((element) => element.id === id)
		},
		formattedList(dateFormat: string, timeZone: string) {
			let formattedList: any[] = []
			// sort the origin list
			let sortedList = self.engTypeList.slice().sort(function (a, b) {
				const dateA = new Date(a.createdAt)
				const dateB = new Date(b.createdAt)
				return compareDesc(dateA, dateB)
			})
			sortedList.map((engType) => {
				const formattedCreatedAt = engType.createdAt
					? getTzDate({
							date: engType.createdAt,
							timeZone,
							dateFormat,
					  })
					: ""
				formattedList.push({
					...engType,
					createdAt: formattedCreatedAt,
				})
			})

			return formattedList
		},
		get engTypeOptions() {
			let options: { id: string; title: string }[] = []
			self.engTypeList.map((engType) => {
				options.push({
					id: engType.id,
					title: engType.name,
				})
			})
			return options
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupEngtype")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getEngTypeList()
	// 	},
	// }))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgEngTypesViewModel
