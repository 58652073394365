import React, { useEffect } from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import AmArchivedProjects from "./AmArchivedProjects"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { getDefaultFinancialYear } from "../../../../library/get-page-settings"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function AmArchivedProjectsController() {
    const store = useRootStore()
    const orgStore = useOrgStore()
    const menuId = DLOrgSubMenus.archived_mgmt
    const actionName = "getArchivedProjects"
    const i18nSheet = OrgI18n.archMgmt

    const menuAccess = orgStore.checkin.checkAccess(menuId)
    const permission = orgStore.checkin.getActionsAsObject(menuId)

    const needRefresh = orgStore.archiveMgmt.needRefresh

    useEffect(() => {
        const defaultYear = getDefaultFinancialYear(menuId)
        if (menuAccess && needRefresh) {
            orgStore.archiveMgmt.getArchivedProjects({ year: defaultYear })
        }
    }, [menuAccess, needRefresh])

    const i18n = store.i18n.combineI18n(
        i18nSheet,
        "AmArchivedProjectsController"
    )
    return (
        <>
            {menuAccess ? (
                <AmArchivedProjects
                    partialStore={orgStore.archiveMgmt}
                    fetchingStatus={orgStore.archiveMgmt.getActionStatus(
                        actionName
                    )}
                    permission={permission}
                    i18n={i18n}
                />
            ) : (
                <OrgNoPermissionOnMenu menuId={menuId} />
            )}
        </>
    )
})
