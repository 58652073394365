import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import ReactTableV8 from "../../../../components/basic-elements/tables/DLReactTable"

export default observer(function AuditProgramStatusTable({
	partialStore,
}: {
	partialStore: any
}) {
	const data = partialStore.viewAuditProgramStatusDetails()
	return (
		<StyledAuditProgramStatusTable>
			<h2>Audit Program Status</h2>
			<ReactTableV8
				tableColumns={columns(
					partialStore.viewAuditProgramStatusDetails()
				)}
				showPageSetting={false}
				data={data}
				hasPagination={false}
				menuId="AuditProgramStatusTable"
				height={300}
				hasFooter
			/>
		</StyledAuditProgramStatusTable>
	)
})

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns = (data: any) => [
	{
		header: "WP Ref",
		accessorKey: "wpRef",
		cell: renderCustomCell,
	},
	{
		header: "WP Description",
		accessorKey: "wpDesc",
		cell: renderCustomCell,
		footer: (
			<span>
				<b>Total :</b>
			</span>
		),
	},
	{
		header: "Total Steps",
		accessorKey: "totalSteps",
		size: 30,
		cell: renderCustomCell,
		footer: (
			<span>
				{
					// Get the total of the price
					data.reduce(
						(total: any, { totalSteps }: any) =>
							(total += totalSteps),
						0
					)
				}
			</span>
		),
	},
	//     {
	// 		header: "Prepared",
	// 		columns: [
	{
		header: "Prepared Completed",
		columns: [
			{
				header: "Timely",
				accessorKey: "preparedCompletedTimely",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(
									total: any,
									{ preparedCompletedTimely }: any
								) => (total += preparedCompletedTimely),
								0
							)
						}
					</span>
				),
			},
			{
				header: "Late",
				accessorKey: "preparedCompletedLately",
				size: 50,
				cell: renderCustomCell,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(
									total: any,
									{ preparedCompletedLately }: any
								) => (total += preparedCompletedLately),
								0
							)
						}
					</span>
				),
			},
		],
	},
	{
		header: "Prepared Open",
		columns: [
			{
				header: "Not Due",
				accessorKey: "preparedOpenNotDue",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(total: any, { preparedOpenNotDue }: any) =>
									(total += preparedOpenNotDue),
								0
							)
						}
					</span>
				),
			},
			{
				header: "Over Due",
				accessorKey: "preparedOpenOverDue",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(total: any, { preparedOpenOverDue }: any) =>
									(total += preparedOpenOverDue),
								0
							)
						}
					</span>
				),
			},
		],
	},
	{
		header: "Not Assigned",
		accessorKey: "preparedNotAssigned",
		cell: renderCustomCell,
		size: 50,
		footer: (
			<span>
				{
					// Get the total of the price
					data.reduce(
						(total: any, { preparedNotAssigned }: any) =>
							(total += preparedNotAssigned),
						0
					)
				}
			</span>
		),
	},
	// 		],
	// 	},
	{
		header: "Reviewed Completed",
		columns: [
			{
				header: "Timely",
				accessorKey: "reviewedCompletedTimely",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(
									total: any,
									{ reviewedCompletedTimely }: any
								) => (total += reviewedCompletedTimely),
								0
							)
						}
					</span>
				),
			},
			{
				header: "Late",
				accessorKey: "reviewedCompletedLately",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(
									total: any,
									{ reviewedCompletedLately }: any
								) => (total += reviewedCompletedLately),
								0
							)
						}
					</span>
				),
			},
		],
	},
	{
		header: "Reviewed Open",
		columns: [
			{
				header: "Not Due",
				accessorKey: "reviewedOpenNotDue",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(total: any, { reviewedOpenNotDue }: any) =>
									(total += reviewedOpenNotDue),
								0
							)
						}
					</span>
				),
			},
			{
				header: "Over Due",
				accessorKey: "reviewedOpenOverDue",
				cell: renderCustomCell,
				size: 50,
				footer: (
					<span>
						{
							// Get the total of the price
							data.reduce(
								(total: any, { reviewedOpenOverDue }: any) =>
									(total += reviewedOpenOverDue),
								0
							)
						}
					</span>
				),
			},
		],
	},
	{
		header: "Not Assigned",
		accessorKey: "reviewedNotAssigned",
		cell: renderCustomCell,
		size: 50,
		footer: (
			<span>
				{
					// Get the total of the price
					data.reduce(
						(total: any, { reviewedNotAssigned }: any) =>
							(total += reviewedNotAssigned),
						0
					)
				}
			</span>
		),
	},
]

const StyledAuditProgramStatusTable = styled.div`
	table > thead > tr {
		border: 1px solid black !important;
		th {
			padding: 0;
			.header {
				min-height: 2rem;
				padding-left: 8px;
				border-left: 1px solid #d1d1d1;
				justify-content: center;
				div {
					justify-content: center;
				}
			}
		}
	}

	table > tfoot > tr > td {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 16px;
			font-weigth: bold;
		}
	}
`
