import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLRadioGroup,
} from "../../../../../../components/basic-elements"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function AddFileLibTemplateDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "addOrgLibraryTemplate"

	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
		mediaType: {
			value: "WorkpaperTemplate",
			error: "",
			requestInput: false,
		},
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters, // TODO: need to update
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}
	const addLibraryTemplate = () => {
		orgStore.orgFileLib.addOrgLibraryTemplate({
			mediaType: inputs.mediaType.value,
			name: inputs.name.value,
			folderId: orgStore.orgFileLib.selectedLibraryFolder,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addLibraryTemplate,
		orgStore.orgFileLib.openAddLibraryTemplate // resetPoint
	)

	const actionStatus = orgStore.orgFileLib.getActionStatus(actionName)
	return (
		<DLDialog
			eleTestId="add-org-library-Template"
			isOpen={orgStore.orgFileLib.openAddLibraryTemplate}
			setIsOpen={orgStore.orgFileLib.setOpenAddLibraryTemplate}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twCreateFilLib || "Add File Library"}
			dialogContents={
				<AddLibraryTemplateForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				isReady &&
				!orgStore.orgFileLib.isDuplicatedLibraryTemplateName(
					inputs.name.value,
					orgStore.orgFileLib.selectedLibraryFolder
				)
			}
			actionBtn={i18n.twAdd || "Add"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.orgFileLib.responses.getResponse(actionName)?.message
			}
			showSpinner={actionStatus === "LOADING"}
			cannotUpdate={actionStatus === "LOADING"}
		/>
	)
})

const AddLibraryTemplateForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddLibraryTemplateForm>
				<div className="input-section FR">
					{/* <InputWithLabel label="Type"> */}
					<DLRadioGroup
						selectedValue={inputs.mediaType.value}
						groupName="mediaType"
						items={[
							{
								value: "WorkpaperTemplate",
								label: i18n.twWpLibrary || "Workpaper Library",
							},
							{
								value: "AuditProgramTemplate",
								label:
									i18n.twApgLibrary || "Auditprogram Library",
							},
						]}
						eleOnChange={handleOnChange}
						color="primary"
						size="small"
						itemDirection="row"
					/>
					{/* </InputWithLabel> */}
				</div>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twFileLibName || "File Library Name"}
					>
						<DLInputField
							autoFocus
							eleTestId="library-template-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(inputs.name.value &&
									orgStore.orgFileLib.isDuplicatedLibraryTemplateName(
										inputs.name.value,
										orgStore.orgFileLib
											.selectedLibraryFolder
									) &&
									(i18n.tsSameNameExist ||
										"This name already exist."))
							}
							warningType={
								inputs.name.value &&
								orgStore.orgFileLib.isDuplicatedLibraryTemplateName(
									inputs.name.value,
									orgStore.orgFileLib.selectedLibraryFolder
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddLibraryTemplateForm>
		)
	}
)

const StyledAddLibraryTemplateForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
