import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { PageHeader } from "../../app-frame-elements"
import { ProjectsPageHeaderProps } from "./projects-table-props-types"
import { getMatchedProjectStatusIcon } from "../../../library/converters/icon-converter"
import styled from "styled-components"
import BasicCounter from "../../app-frame-elements/sub-components/BasicCounter"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { DLButton } from "../../basic-elements"
import { DLSearchBoxBasic } from "../../basic-elements/search/DLSearchBox"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
    savePageSetting,
    getPageSettings,
} from "../../../library/get-page-settings"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import DLTooltip from "@datalobby/components/lib/tooltip/DLTooltip"
import { Icon } from "@mdi/react"
import { mdiInformation } from "@mdi/js"

export default observer(function SharedProjectsPageHeader({
    year,
    projectStatus,
    projLength,
    filteredProjLength,
    //
    selectedItemsLength,
    additionalButtons,
    shortcuts,
    clearFilters,
    handleSearch,
    menuId,
    canSearch = false,
}: ProjectsPageHeaderProps) {
    const store = useRootStore()
    const orgStore = useOrgStore()
    const pageSetting = getPageSettings(menuId)
    const [searchText, setSearchText] = useState(pageSetting?.searchQuery || "")

    const searchElement = (e: any) => {
        setSearchText(e.target.value)
        savePageSetting("searchQuery", e.target.value, `pageSetting_${menuId}`)
    }

    const handleKeyDown = (e: any) => {
        if (e.key === "Enter") {
            searchQuery()
        }
    }

    const searchQuery = () => {
        if (searchText) handleSearch(searchText)
    }
    const closeHandler = () => {
        savePageSetting("searchQuery", "", `pageSetting_${menuId}`)
        setSearchText("")
        handleSearch("")
    }

    const isRemindProjects =
        menuId === DLOrgSubMenus.remind_report ||
        menuId === DLOrgSubMenus.remind_archiving ||
        menuId === DLOrgSubMenus.admin_remind_report ||
        menuId === DLOrgSubMenus.admin_remind_archiving

    const dntFormat = store.global.getDntFormat

    return (
        <StyledSharedProjectsPageHeader className="FR JSB">
            <PageHeader>
                <div className="FR AC counter-container">
                    {getMatchedProjectStatusIcon(projectStatus)}
                    <BasicCounter
                        // label={`${year} total ${isAssignedOnly ? "Assigned" : ""} ${projectStatus} projects :`}
                        label={`Total Projects in ${year} :`}
                        value={projLength}
                    />
                    <div className="FR AC counter">
                        {projLength > filteredProjLength && (
                            <div className="FR AC">
                                <span className="partition">|</span>
                                <div className="filtered-count">
                                    Filtered Projects : {filteredProjLength}
                                </div>
                                <span className="partition">|</span>
                                <DLButton
                                    eleTestId="clear-filters"
                                    clickHandler={clearFilters}
                                >
                                    Clear Filters
                                </DLButton>
                            </div>
                        )}
                        {shortcuts && (
                            <div className="projects-shortcuts">
                                {shortcuts}
                            </div>
                        )}
                    </div>
                    {/*{selectedItemsLength !== undefined &&
						selectedItemsLength > 0 && (
							<div>selected items: {selectedItemsLength}</div>
						)}*/}
                    {canSearch && (
                        <>
                            <span className="partition">|</span>
                            <DLSearchBoxBasic
                                id="project-search"
                                eleTestId="search_text_box"
                                placeholder="Search Client Name/ Project Name/ E.P. / Q.C."
                                iconPlacement="end"
                                onChange={searchElement}
                                eleValue={searchText}
                                onKeyDown={handleKeyDown}
                                clickHandler={searchQuery}
                                closeHandler={closeHandler}
                            />
                        </>
                    )}
                </div>
                <div
                    className="FR AC right-side-btn-area"
                    data-testid="right-side-btns"
                >
                    {additionalButtons && (
                        <div className="additional-btns">
                            {additionalButtons}
                        </div>
                    )}
                    {/*<DefaultPageCtxMenus
						permission={permission}
						getTotalData={getTotalData}
						getFilteredData={getFilteredData}
						openPageSettingDialog={() =>
							orgStore.projects.setTablePageSettingDialog(true)
						}
						fileName={`${projectStatus} Project List`}
						i18n={i18n}
					/>*/}
                </div>
            </PageHeader>
            {isRemindProjects && (
                <div>
                    <span>Today (based on the org. time zone)</span>
                    <div className="today-date">
                        <DLTooltip
                            title="Due Days for Archiving = Final (or Expected) Archive Deadline Date - Today Date"
                            elePlacement="top"
                            arrow
                        >
                            <div className="FR JSB">
                                <Icon path={mdiInformation} size={0.7} />
                                <span>
                                    {orgStore.projects.getTodayDateWithOrgTZ(
                                        dntFormat
                                    )}
                                </span>
                            </div>
                        </DLTooltip>
                    </div>
                </div>
            )}
        </StyledSharedProjectsPageHeader>
    )
})

export const StyledSharedProjectsPageHeader = styled.div`
    width: 100%;
    .counter-container {
        .proj-icon {
            margin-right: 0.4rem;
        }
        .counter {
            margin-left: 0.4rem;
            span {
                font-weight: 600;
                &.selected-year {
                    color: ${(props) => props.theme.emphasis};
                    margin-left: 0.4rem;
                }
                &.proj-count {
                    margin-left: 0.4rem;
                }
            }
        }
    }
    .partition {
        margin: 0 1rem;
        color: lightgray;
    }
    .right-side-btn-area {
        padding-right: 0.4rem;
    }

    .additional-btns {
        /* margin-right: 1rem; */
    }
    .today-date {
        height: 20px;
        width: 240px;
        background-color: #94b5f3;
        border-radius: 2px;
    }
`
