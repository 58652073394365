import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog, DLButton, InputWithLabel, DLInputField, DLTextArea, DLComboBox } from "../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import styled from "styled-components"

export default observer(function AddHardCopyDialog({
	i18n,
	partialStore,
}: {
	i18n: DLI18nProps
	partialStore: any
}) {
    const orgStore = useOrgStore()
	const projInfo = partialStore.viewSelectedProjInfo()
	
	const [inputs, setInputs] = useState({
	    title:  `Hard_Copy_${projInfo.title}`,
	    description: ""
	})
	
	const handleInputs = (e: any) => {
	    const { name, value } = e.target
	    setInputs((inputs) => ({
    	    ...inputs,
    	    [name]: value
    	}))
	}
	
	useEffect(() => {
		orgStore.archiveMgmt.getFilteredUsers("internal")
	}, [])
	
	console.log(projInfo)

	return (
		<>
			<DLDialog
				eleTestId="checklist-detail-dialog"
				isOpen={partialStore.openAddHardCopyDialog}
				setIsOpen={partialStore.setOpenAddHardCopyDialog}
				showCloseBtn={true}
				dialogTitle="Submit Hard Copy"
				dialogContents={
				    <AddHardCopyDialogContent 
				        projInfo={projInfo}
				        inputs={inputs}
				        handleInputs={handleInputs}
				        userOptions={orgStore.archiveMgmt.viewSearchAccessMgmtUserList()}
				    />
				}
				// actionReady={isReady || userEmail === inputs.email.value}
				maxWidth="md"
				actionBtn="Proceed"
				cancelBtnText={i18n.twCancel || "Cancel"}
				// showSpinner={}
			/>
		</>
	)
})


const AddHardCopyDialogContent = ({ 
    projInfo,
    inputs,
    handleInputs,
    userOptions
}: { 
    projInfo: any
    inputs: any
    handleInputs: any    
    userOptions: any[]
}) => {
    const [showAddHardCopySection, setShowAddHardCopySection] = useState(false)
    const hardCopyList:any[] = []
    return (
        <StyledAddHardCopyDialog>
            <div className="proj-info-section">
                <h3>Project Information</h3>
                <div className="proj-info-details">
                    <RowItem label="Client" value={projInfo.clientName} />
                    <RowItem label="Project Title" value={projInfo.title} />
                    <RowItem label="Project Type" value={projInfo.statusName} />
                    <RowItem label="Version" value={projInfo.version} />
                    <RowItem label="Period Type" value={projInfo.periodName} />
                    <RowItem label="Year" value={projInfo.financialYear} />
                    {/*<RowItem label="Period End Date" value={projInfo.statusName} />*/}
                    <RowItem label="Final Report Date" value={projInfo.finalReportDate} />
                </div>
            </div>
            <div className="hard-copy-list-section">
                <h3>Hard Copy List</h3>
                {!showAddHardCopySection ? (
                    <div>
                        <div className="hard-copy-table">
            				<div className="FC">
            					<div className="FR JSB header">
            						<div className="no">No</div>
            						<div className="title">Hard Copy Title</div>
            						<div className="description">Description</div>
            						<div className="createdAt">Created At</div>
            						<div className="createdBy">Created By</div>
            					</div>
            					<hr />
            					{!!hardCopyList.length ? (
            					    <div className="body">
            					        {hardCopyList.map((item: any) => (
                							<div className="FR JSB">
                								<div className="no">{item.no}</div>
                								<div className="title">{item.title}</div>
                								<div className="description">{item.description}</div>
                								<div className="createdAt">{item.createdAt}</div>
                								<div className="createdBy">{item.createdBy}</div>
                							</div>
                						))}
            					    </div>
            					) : (
            					    <div className="FR JC body">No Hard Copied</div>
            					)}
            				</div>
                        </div>
                        <DLButton
        					eleTestId="add-new-hard-copy-btn"
        					color="primary"
        					clickHandler={() => setShowAddHardCopySection(true)}
        				>
        					Add A New Hard Copy
        				</DLButton>
                    </div>
                ) : (
                    <div className="hard-copy-form">
                        <div className="input-section FR">
        					<InputWithLabel direction="vertical" required label="Hard Copy Title">
        						<DLInputField
        							autoFocus
        							eleTestId="hard-copy-title-input"
        							eleFullWidth
        							eleName="title"
        							eleValue={inputs.title}
        							eleHandleChange={handleInputs}
        							eleRequired
        							variant="outlined"
        						/>
        					</InputWithLabel>
        				</div>
                        <div className="input-section FR">
        					<InputWithLabel direction="vertical" label="Description">
        						<DLTextArea
        							eleTestId="description-input"
        							eleFullWidth
        							eleName="description"
        							eleValue={inputs.description}
        							eleHandleChange={handleInputs}
        							eleFieldHeight="5"
        							variant="outlined"
        						/>
        					</InputWithLabel>
        				</div>
        				<div className="input-section FR">
                            <InputWithLabel direction="vertical" label="Select User">
                                <DLComboBox
                					eleTestId="select-user"
                					eleClassName="select-user"
                					withoutLabel
                					placeholder={
                						"Please select user"
                					}
                					options={userOptions}
                					getOptionLabel={(option: any) =>
                						option.name
                					}
                					onChange={(item: any) => console.log(item.id)}
                					variant="outlined"
                				/>
                            </InputWithLabel>
                        </div>
                    </div>
                )}
            </div>
        </StyledAddHardCopyDialog>
    )
}

const RowItem = ({ label, value }: { label: string, value: string}) => 
    <div className="FR"><div style={{minWidth: "10rem"}}>{label}</div>{value}</div>

const StyledAddHardCopyDialog = styled.div`
    .proj-info-details{
        margin-top: 1rem;
        margin-left: 1rem;
    }
    .hard-copy-list-section{
        margin-top: 1rem;
        .hard-copy-table{
            padding: 5px;
        }
        .body{
           min-height: 200px;
        }
    }
`
