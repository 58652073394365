import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { hasEmptyProps } from "@datalobby/common"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const EditClient = (self: any) => ({
	editClient(clientInfo: any, id: string) {
		// 0.
		const actionName = "editClient"
		// 1.
		const clientId = idToNumber(id, IdType.client)
		let props = {
			ClientId: clientInfo.clientAliasId.value,
			Name: clientInfo.clientName.value,
			ID: clientId,
		}
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.updateClient(checkedProps.props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					// update success case response
					self.updateClientStore(
						response.data.ClientId,
						response.data.Name,
						id
					)
					self.setEditClientDialogOpen(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						customMessage: "Something wrong",
						color: MessageColorType.orange,
						open: false,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditClient
