import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const data = [
	{
		roleName: "Partner",
		name: "고한나",
		isApprenticeship: true,
		careerYears: 10,
		auditHourRatio: 15,
		proficiency: 1.15,
		weight: 0.173,
	},
	{
		roleName: "Partner",
		name: "유성룡",
		isApprenticeship: true,
		careerYears: 5,
		auditHourRatio: 35,
		proficiency: 1,
		weight: 0.35,
	},
	{
		roleName: "Staff",
		name: "한별",
		isApprenticeship: true,
		careerYears: 1,
		auditHourRatio: 15,
		proficiency: 0.8,
		weight: 0.12,
	},
	{
		roleName: "Staff",
		name: "김주승",
		isApprenticeship: true,
		careerYears: 6,
		auditHourRatio: 35,
		proficiency: 1.1,
		weight: 0.385,
	},
]

const columns = [
	{ Headers: "Role", accessor: "roleName" },
	{ Headers: "Name", accessor: "name" },
	{ Headers: "Apprenticeship", accessor: "isApprenticeship" },
	{ Headers: "Career", accessor: "careerYears" },
	{ Headers: "Hours Ratio", accessor: "auditHourRatio" },
	{ Headers: "Proficiency", accessor: "proficiency" },
	{ Headers: "Weight", accessor: "weight" },
]

export default observer(function AuditorsInfo() {
	const orgStore = useOrgStore()

	return (
		<StyledAuditorsInfo>
			Table area for AuditorsInfo - future item
		</StyledAuditorsInfo>
	)
})

const StyledAuditorsInfo = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
