import { flow } from "mobx-state-tree"

const RequestDeactivateProjUser = (self: any, deactivate: any) => ({
	requestDeactivateProjUser: flow(function* (userId: number) {
		const actionName = "requestDeactivateProjUser"
		if (userId === null || userId === undefined) {
			alert(`(${actionName}) User ID is empty`)
			return
		}
		try {
			const response = yield deactivate(userId)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })
		}
	}),
})

export default RequestDeactivateProjUser
