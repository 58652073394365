import React from "react"
import Icon from "@mdi/react"
import { mdiChevronDown } from "@mdi/js"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"

export default observer(function HandlePageToolBarIcon() {
	const store = useRootStore()

	return (
		<StyledIcon
			onClick={() =>
				store.ui.setPageToolbarOpen(!store.ui.isPageToolbarOpen)
			}
			data-testid="page-toolbar-onoff"
		>
			<Icon
				className={
					store.ui.isPageToolbarOpen
						? "filter-open up"
						: "filter-open down"
				}
				path={mdiChevronDown}
				size={1}
			/>
		</StyledIcon>
	)
})

const StyledIcon = styled.div`
	z-index: 999;
	position: absolute;
	right: 1rem;
	margin-top: -0.5rem;
	width: 1rem;
	height: 1rem;
	background-color: ${(props) => props.theme.background};
	border: 1px solid ${(props) => props.theme.primaryLight};
	overflow: hidden;
	border-radius: 50%;
	display: flex;
	flex-direction: row;
	align-items: center;
	justify-content: center;
	fill: ${(props) => props.theme.textMediumEmphasis};
	cursor: pointer;
	&:hover {
		background-color: ${(props) => props.theme.primaryLight};
	}
	& .filter-open {
		transition: 0.4s;
		&.up {
			transform: rotate(180deg);
		}
	}
`
