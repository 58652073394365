import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import AllMenus from "../../../../../admin-static-data/app-menu-table/all-menus.json"
import { ConsoleLog } from "../../../../../components/basic-elements"

const GetAllMenus = (self: any) => ({
	getAllMenus(postAction?: () => void) {
		const actionName = "getAllMenus"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const stage = process.env.REACT_APP_STAGE

		let allMenus: any = []

		if (stage === undefined) {
			console.log("process.env.REACT_APP_STAGE is required")
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.fail,
			})
			return
		}

		if (stage === "PRODUCTION") {
			allMenus = AllMenus.filter((menu) => menu.status === "PRODUCTION")
		} else if (stage === "DEVELOPMENT") {
			allMenus = AllMenus
		} else {
			console.log(
				"Please check the app stage. It is not PRODUCTION or DEVELOPMENT",
				stage
			)
		}

		ConsoleLog("allMenus", allMenus)

		let allowedMenus: any[] = []
		allMenus.map((menu: any) => {
			const allowed = self.allowedMenus.find(
				(m: {
					id: string
					available: boolean
					index: number
					isAdminMenu: boolean
				}) => m.id === menu.id
			)?.available
			if (allowed === true) {
				allowedMenus.push(menu)
			}
		})

		// TODO: Add filter by stage

		self.setCustomerMenus(allowedMenus)
		postAction && postAction()

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.success,
		})
	},
})

export default GetAllMenus
