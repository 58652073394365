import {
	getRequest,
	postRequest,
	putRequest,
	deleteRequest,
} from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { postFormData } from "../../../../../library/api-requests/request-post-others"

// default reading apis

/**
 * - Template Folder
 * - Template
 * - File Folder (in Template)
 * - File (in Template)
 */

/** --------------------------------------------------------------
 * 1. Template Folder related apis
 * - readTemplateGroups
 * - createTemplateGroup
 * - deleteTemplateGroup
 * - updateTemplateGroup
 */
export async function readProjTemplateGroups() {
	const endpoint = "/GetTemplateInfo"
	const response = await getRequest({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function createProjTemplateGroup(params: {
	TempFoldName: string
	// UserID: number
}) {
	const response = await postRequest({
		url: "/CreateTmpFolder",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function deleteTemplateGroup(params: {
	TempFoldID: number
	// UserID: number
}) {
	const response = await deleteRequest({
		url: "/DeleteTmpFolder",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function updateTemplateGroup(payload: {
	TempFoldID: number
	TempFoldName: string
	// UserID: number
}) {
	const response = await putRequest({
		url: "/EditTempFolder",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function requestDuplicateProjTemplateGroup(payload: {
	SourceId: number
	Type: 1
}) {
	const response = await postRequest({
		url: "/CopyProjectTemplate",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/** --------------------------------------------------------------
 * 2. Template related apis
 * - createTemplate
 * - updateTemplate
 * - deleteTemplate
 * - duplicateTemplate
 */

export async function readProjTemplateDetails(params: { ParentFldID: number }) {
	const endpoint = "/GetTemplateStructure"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type CreateTemp = {
	UserID: string
	ParentTempFoldID: string
	TempName: string
	MediaType: string
	WPREF: string
}
export async function createTemplate(formData: CreateTemp) {
	const endpoint = "/CreateTemp"
	const response = await postFormData({
		url: endpoint,
		formData: formData,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type UpdateTemplateProps = {
	TempName: string
	TemplateId: number
	// UserID: number
	// WPREF: ""
}

export async function updateTemplate(payload: UpdateTemplateProps) {
	const endpoint = "/EditTemplate"
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type DeleteTemplateProps = {
	TemplateId: number
	// UserID: number
}

export async function deleteTemplate(params: DeleteTemplateProps) {
	const endpoint = "/DeleteTemplate"
	const response = await deleteRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

// GetTemplateStructure (before confirm) --> CopyProjectTemplate
// 1. "/GetTemplateStructure"
export type DuplicateTemplateProps = {
	SourceId: number
	Type: number
}
export async function duplicateTemplate(params: DuplicateTemplateProps) {
	const endpoint = `/CopyProjectTemplate?SourceId=${params.SourceId}&Type=${params.Type}`
	const response = await postRequest({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/** --------------------------------------------------------------
 * 3. File Folder related apis
 * - createFileFolder
 * - readFileFolderDetails : use same API as readTemplateDetails
 * - updateFileFolder
 * - deleteFileFolder
 */

export async function readFileFolderDetails(params: { ParentFldID: number }) {
	const response = await getRequest({
		url: "/GetTemplateStructure", // same endpoint with template details
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/** --------------------------------------------------------------
 * 4. File related apis
 * - createFile
 * - deleteFile
 * - duplicateFile
 * - replaceFile
 * - updateFile
 *
 */
export async function createFile(formData: any) {
	const response = await postFormData({
		url: "/CreateTemp",
		formData: formData,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function duplicateFile(params: {
	SourceId: number
	Type: number
}) {
	const response = await postRequest({
		url: `/CopyProjectTemplate?SourceId=${params.SourceId}&Type=${params.Type}`,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
