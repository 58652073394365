import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

export default observer(function TasksOnDrawer() {
	const store = useRootStore()

	return (
		<StyledTasksOnDrawer data-testid="">
			<div
				className="FR AC JC noti-header"
				data-testid="right-drawer-tab-header"
			>
				<h2>Tasks</h2>
			</div>
		</StyledTasksOnDrawer>
	)
})

const StyledTasksOnDrawer = styled.div``
