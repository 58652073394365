// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const RemoveCabinetInGroup = (self: any) => ({
	removeCabinetInGroup(cabinetId: string) {
		// 0.
		const actionName = "removeCabinetInGroup"
		// X.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// X.
		if (cabinetId === undefined) {
			alert(
				`(${actionName}) Undefined param exist. cabinetId: ${cabinetId} `
			)
			return
		}
		ConsoleLog(actionName + cabinetId)
		const numberId = idToNumber(cabinetId, IdType.cabinet)
		// X.
		if (typeof numberId === "number") {
			self.deleteGroupItem(numberId)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// console.log(actionName+ "__response " + response)
						/**
						 * REFLECT RESPONSE ON THE STORE
						 */
						self.removeFlatItem(cabinetId)
						//	self.spliceCurrentPathById(cabinetId) // TODO: Should be the parent cabinet instead of the group
						//	self.splicePathForSelection(cabinetId) // ? required?
						const prevPath = self.currentPath
						const targetIndex = prevPath.findIndex(
							(prev: any) => prev === cabinetId
						)
						if (targetIndex !== -1) {
							self.setCurrentPath(prevPath.slice(0, targetIndex))
						}

						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else if (
						response.status === 200 &&
						response.data.Status === 0 &&
						response.data.Message ===
							"You can't delete this Cabinet as it has project files in it"
					) {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message: response.data.Message,
							customMessage:
								"This cabinet has some projects. Cannot delete this cabinet",
							open: true,
						})
					} else {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	},
})

export default RemoveCabinetInGroup
