// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetFilters = (self: any) => ({
	getFilters() {
		// 0.
		const actionName = "getFilters"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.call API
		const payload = {
			usage: 1,
		}
		self.readFilters(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					// empty existing stored list
					const clients = response.data.Client
					const engTypes = response.data.EngType
					const epUsers = response.data.EpUsers
					const groups = response.data.Group
					const periods = response.data.Period
					const qcUsers = response.data.QCUsers

					const clientFilters = clients.map((item: any) => ({
						id: item.Id.toString(),
						name: item.Name,
					}))
					const engTypesFilters = engTypes.map((item: any) => ({
						id: item.Id.toString(),
						name: item.Name,
					}))
					const epUsersFilters = epUsers.map((item: any) => ({
						id: item.Id.toString(),
						name: item.Name,
					}))
					const groupsFilters = groups.map((item: any) => ({
						id: item.Id.toString(),
						name: item.Name,
					}))
					const periodsFilters = periods.map((item: any) => ({
						id: item.Id.toString(),
						name: item.Name,
					}))
					const qcUsersFilters = qcUsers.map((item: any) => ({
						id: item.Id.toString(),
						name: item.Name,
					}))

					self.setClientFilters(clientFilters)
					self.setEngTypeFilters(engTypesFilters)
					self.setEPUsersFilters(epUsersFilters)
					self.setQCUsersFilters(qcUsersFilters)
					self.setGroupFilters(groupsFilters)
					self.setPeriodFilters(periodsFilters)
				}

				// success case response
				self.handleResponse({
					actionName,
					status: ActionStatus.success,
					code: 200, // temp
					customMessage: "Successfully get ...",
					color: MessageColorType.blue,
					open: false,
				})
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetFilters
