import { types } from "mobx-state-tree"
import ReportsManagementModel from "./reports-management.model"
import ReportsManagementViewModel from "./reports-management.view-model"
import { ReportManagementInitialPageSetting } from "./data-models/initial-page-settings"

import { read } from "../../../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import {
	readAllFiles,
	ReportsMgmtApiProps,
	readChecklists,
	addChecklist,
	readChecklistCollections,
} from "./reports-management.apis"

const ReportsManagementStore = ({
	apiRead,
	apiReadAllFiles,
	apiReadChecklists,
	apiAddChecklist,
	apiReadChecklistCollections,
}: ReportsMgmtApiProps) =>
	types
		.compose(
			ReportsManagementModel({
				apiRead,
				apiReadAllFiles,
				apiReadChecklists,
				apiAddChecklist,
				apiReadChecklistCollections,
			}),
			ReportsManagementViewModel
		)
		.named("OrgReportsMgmtStore")

export const initialStore = {
	checklistDetail: null,
	//
	tableColumnsPageSetting: ReportManagementInitialPageSetting,
	clickPoint: initialClickPoint,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ReportsManagementStoreInstance = ReportsManagementStore({
	apiRead: read,
	apiReadAllFiles: readAllFiles,
	apiReadChecklists: readChecklists,
	apiAddChecklist: addChecklist,
	apiReadChecklistCollections: readChecklistCollections,
}).create(initialStore)

export default ReportsManagementStoreInstance
