import React, { useEffect, useState } from "react"
import { Route, Switch } from "react-router-dom"
import styled from "styled-components"
import { PageContainer } from "../../../components/app-frame-elements"

import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import data from "./dummy-engagements.json"
import OrgEngColumns from "./OrgEngColumns"
import OrgEngDetail from "./OrgEngDetail"

export default observer(function OrgEngagements({
	// actionStatus,
	contentsHeight,
}: {
	// actionStatus: ActionStatus
	contentsHeight: any
}) {
	const orgStore = useOrgStore()

	return (
		<>
			<PageContainer
				fullWidth
				fixedHeight
				pageTools={<div />}
				hasToolbar={false}
			>
				<Switch>
					<Route exact path="/organization/engagements">
						<StyledOrgEngagements>
							Table area for OrgEngagements - future item
						</StyledOrgEngagements>
					</Route>
					<Route exact path="/organization/engagements/detail/:id">
						<OrgEngDetail />
					</Route>
				</Switch>
			</PageContainer>
		</>
	)
})

const StyledOrgEngagements = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
