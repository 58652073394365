import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	findProjRoleId,
	findProjRoleInfo,
} from "../../../../../temporary-data/project-side/find-proj-role"

const ValidatePrevUserRole = (self: any) => ({
	validatePrevUserRole(userId: string) {
		// 0.
		const actionName = "validatePrevUserRole"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API
		self.requestValidatePrevUserRole(userId)
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Code === 1) {
					const prevRoleId = response.data.RoleId
					const convertedRoleId = findProjRoleId(prevRoleId)
					self.setPrevUserRole(convertedRoleId)
					const prevRoleName = findProjRoleInfo(prevRoleId).name
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999,
						color: MessageColorType.orange,
						customMessage: `This user was assigned in this project as ${prevRoleName} before`, // tempErrorMsg(response),
						open: true,
					})
					//
				} else if (
					response.status === 200 &&
					response.data.Code === 0
				) {
					// this user was not assigned before
					self.resetPrevUserRole()
				} else {
					// when fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage:
							"Failed to check the user's previous role", // tempErrorMsg(response),
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ValidatePrevUserRole
