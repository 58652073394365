import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { RolePermissionProps } from "../../../../../service-modules/role-module/roles.data-model"
import { findProjRoleInfo } from "../../../../../temporary-data/project-side/find-proj-role"

const GetProjAssignedRole = (self: any) => ({
	getProjAssignedRole(
		tempRight: string,
		roleId: string,
		isReadonly: boolean,
		postAction?: () => void // for editor
	) {
		// NOTE: tempRight: temporary right to use existing role setting (PAA, PA, PU...)
		// NOTE: roleId: QC, M, SS...
		/**
		 * This function only get the permission for the role.
		 * Use overall permissions on the setup-permissions part.
		 */
		// 0. set actionName
		const actionName = "getProjAssignedRole"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readProjAssignedRole(tempRight)
			.then((assignedRole: RolePermissionProps) => {
				// console.log(actionName, "assignedRole", assignedRole)
				const roleInfo = findProjRoleInfo(roleId)

				// ConsoleLog("tempRight, roleId", tempRight, roleId)
				// ConsoleLog("roleInfo", roleInfo)
				console.log("roleInfo in getProjAssignedRole", roleInfo)
				if (roleInfo) {
					self.setAssignedRole({
						...assignedRole,
						roleId: roleInfo.roleId,
						name: roleInfo.name,
						shortName: roleInfo.shortName,
						color: roleInfo.color,
					})
					//
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
					postAction && postAction()
				} else {
					console.error(
						"No matched role exist on the role set",
						tempRight
					)
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.fail,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog(["getProjAssignedRole error", error])
				self.setProjCheckin(ActionStatus.fail)
			})
	},
})

export default GetProjAssignedRole
