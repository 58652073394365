import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	postRequest,
	deleteRequest,
} from "../../../library/api-requests"

export async function read(payload: {
	PageIndex?: number
	PageSize: number
	GroupID?: number
	ClientName?: string
	ClientId?: string
}) {
	const response = await postRequest({
		url: "/GetClientsMaster",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function updateClient(payload: {
	ClientId?: number
	Name: string
	ID?: number
}) {
	const response = await putRequest({
		url: "/EditClientMaster",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function del(payload: { ID?: number }) {
	const response = await deleteRequest({
		url: "/DeleteClientMaster",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function create(payload: { ClientId?: string; Name?: string }) {
	const response = await postRequest({
		url: "/CreateClientMaster",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readGroups(params: {
	Type: number
	Lang: string
	ClientMasterID: number
}) {
	const response = await getRequest({
		url: "/GetGroupsForClients",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function assignToGroup(payload: {
	GroupsList: any[] // TOOD: update is required
	ClientmasterID: number
	Type: number
}) {
	const response = await postRequest({
		url: "/AssignGroupsToClient",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgSetupClientsApiProps = {
	apiRead: (payload: {
		PageIndex?: number
		PageSize: number
		GroupID?: number
		ClientName?: string
		ClientId?: string
	}) => Promise<AxiosResponse<any>>
	apiUpdate: (payload: {
		ClientId?: number
		Name: string
		ID?: number
	}) => Promise<AxiosResponse<any>>
	apiDelete: (payload: { ID?: number }) => Promise<AxiosResponse<any>>
	apiCreate: (payload: {
		ClientId?: string
		Name?: string
	}) => Promise<AxiosResponse<any>>
	apiReadGroups: (payload: {
		Type: number
		Lang: string
		ClientMasterID: number
	}) => Promise<AxiosResponse<any>>
	apiAssignToGroup: (payload: {
		GroupsList: any[] // TOOD: update is required
		ClientmasterID: number
		Type: number
	}) => Promise<AxiosResponse<any>>
}
