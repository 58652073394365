import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { hasEmptyProps } from "@datalobby/common"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const EditRiskAssessment = (self: any) => ({
	editRiskAssessment(props: { raName: string; raId: string }) {
		// 0.
		const actionName = "editRiskAssessment"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const { raName, raId } = checkedProps.props

		const payload = {
			RiskAssessmentName: raName,
			RiskAssessmentID: idToNumber(raId.toString(), IdType.ra),
		}
		// ConsoleLog(payload)
		self.updateRiskAssessment(payload)
			.then((response: any) => {
				// if success
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					self.updateRiskAssessmentStore(raName, raId)
					// close the dialog
					self.setEditRiskAssessmentDialogOpen(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update the Risk Assessment",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditRiskAssessment
