import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

import { postRequest } from "../../../../../../library/api-requests"

const ReadOrgAssignedUsers = (self: any) => ({
	readOrgAssignedUsers: flow(function* (payload) {
		try {
			const endpoint = "/GetUserManagementData"
			const response = yield postRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.orgToken,
			})
			return response
		} catch (error) {
			ConsoleLog(["readAssignedUsers error", error])
			return false
		}
	}),
})

export default ReadOrgAssignedUsers
