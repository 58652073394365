import { ActionStatus } from "@datalobby/types"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { convertSourceFormat } from "../../../../../library/converters/source-format-converter"

const GetWpFileCheckinRequests = (self: any) => ({
	getWpFileCheckinRequests() {
		// 0.
		const actionName = "getWpFileCheckinRequests"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		self.readWpFileCheckinrequest()
			.then((res: any) => {
				if (res) {
					// if success
					self.resetReqNotificationList()
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						const notification = res.data.RequestNotificationDetails
						notification.map((item: any) => {
							const reOrganizedNotification = {
								srcId: idToString(item.MediaID, IdType.src),
								projectId: idToString(
									item.ClientID,
									IdType.project
								),
								srcFormat: convertSourceFormat(
									item.MediaType.toLowerCase()
								),
								title: item.MediaName,
								aliasId: item.WorkReference,
								clientName: item.ClientName,
								linkName: item.LinkName,
								year: item.Year.toString(),
								actualFilePath: item.ActualFilePath,
								path: item.FilePath,
								archive: item.Archive,
								isLocked: item.IsLocked,
								version: item.Version.toString(),
								projectStatus: item.ProjectType.toString(),
								projectStatusName: item.ProjectTypeName,
								projectAliasId: item.ProjectBusinessId,
								notes: item.Notes,
								openedUser: item.OpenedUser,
								requestedUser: item.RequestedUser,
							}
							self.pushItemToReqList(reOrganizedNotification)
						})
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched workpaper file requested notifications",
							color: MessageColorType.blue,
							open: false,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetWpFileCheckinRequests
