import sharedTheme from "./sharedTheme"
import {
	transparency as transp,
	shadowPosition as sp,
} from "./shared-variables"

// NOTE: NO GREEN (Its hard to distinguish with red)
const mainColor = "#4F5E8C"
const subColor = "#7E90C6"
const emphasis = "#2176E1"

const themeBlack = "#010C2D"
const themeShadow = "#C1C0DD"

const themeRed = "#D6301B" // unarchived project icon color
const themeYellow = "#F5BE40" // locked project icon color
const themeOrange = "#F58E40"
const themePurple = "#6500BE" // archived project icon color

const blueTheme2: { [x: string]: any } = {
	mainColor,
	subColor,
	// ---------- basic variables (sholud be matched with dark theme)
	shared: sharedTheme,
	background: "#ffffff",

	orgSidebar: mainColor,
	projSidebar: subColor,

	orgSidebarEmphasisTxt: "#ffffff",
	projSidebarEmphasisTxt: "#ffffff",
	//
	emphasis,
	emphasisDeep: "#0D5ABB",
	emphasisLight: emphasis + transp.p60,
	//
	txtOnEmphasisColor: "#ffffff",

	// ---------- color standard primary
	primaryMainDeep: "#4863CE",
	primaryLight: "#99A7D2",
	primaryLightDeep: "#7888BE",

	// ---------- color standard secondary
	secondary: subColor + transp.p50,
	secondaryDeep: subColor + transp.p60,

	// ---------- project type color standard
	normalProject: themeBlack + transp.p60,
	normalLockedProject: themeYellow,
	replicaProject: themeBlack + transp.p20,
	archivedProject: themePurple,
	unarchivedProject: themeRed,

	// ---------- warning and dangerous
	warning: themeRed,
	warningDeep: "#B11501",

	// ---------- text standard
	textHighEmphasis: themeBlack + transp.p90,
	textMediumEmphasis: themeBlack + transp.p80,
	textDisabled: themeBlack + transp.p35,

	// ---------- shade
	shade05: themeBlack + transp.p05,
	shade10: themeBlack + transp.p10,
	shade20: themeBlack + transp.p20,
	shade30: themeBlack + transp.p30,
	shade40: themeBlack + transp.p40,
	shade60: themeBlack + transp.p60,
	shade90: themeBlack + transp.p90,

	// ---------- shadow
	shadowColorLight: themeShadow + transp.p60,
	shadowColorDeep: themeShadow + transp.p80,
	// stand
	shadowRegularLight: sp.regular + themeShadow + transp.p60,
	shadowRegularDeep: sp.regular + themeShadow + transp.p80,
	// left
	shadowRegularLeftLight: sp.left + themeShadow + transp.p60,
	shadowRegularLeftDeep: sp.left + themeShadow + transp.p80,
	// right
	shadowRegularRightLight: sp.right + themeShadow + transp.p60,
	shadowRegularRightDeep: sp.right + themeShadow + transp.p80,
	//

	themeRed,
	themeYellow,
	themeOrange,

	themePurple,
}

export default blueTheme2
