import { types } from "mobx-state-tree"

export const ProjTrash = types.model({
	id: types.string, // [TrashID] ex) 2474
	type: types.string, // [Type] ex) "WorkpaperFile"
	fileId: types.string, // [FileID] ex) "48008"
	name: types.string, // [Name] ex) "독립성준수확인서"
	deletedBy: types.string, // [DeleteBy] ex) "김철수 [cskim]"
	deletedAt: types.string // [DeletedOn] ex) "2020-01-11T20:33:53.488446+00:00"
})
