import React from "react"
import List from "@material-ui/core/List"
import ListSubheader from "@material-ui/core/ListSubheader"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import SidebarMenuForProj from "./SidebarMenuForProj"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { Link, useLocation } from "react-router-dom"
import { DLIconButton } from "../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiHomeImportOutline } from "@mdi/js"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function SidebarMenuList() {
	const store = useRootStore()
	const projStore = useProjStore()
	let location = useLocation()
	const i18nSheet = ProjI18n.menus

	const i18n = store.i18n.combineI18n(i18nSheet, "ProjSidebarMenuList")
	console.log("i18n in ProjSidebarMenuList", i18n)
	return (
		<List
			subheader={
				<ListSubheader
					component="div"
					id="sidebar-location"
					style={{ padding: 0, height: `var(--standard-unit)` }}
				>
					<div className="view-location FR JSB AC">
						{store.ui.isSidebarOpen
							? i18n.twProjMenu || "Project Menu"
							: "Proj"}{" "}
						{store.ui.isSidebarOpen && (
							<Link
								to={{
									pathname: "/project/checkout",
									state: { from: location.pathname },
								}}
							>
								<DLIconButton
									eleTestId="back-to-org"
									color="primary"
									tooltipText="Back to Organization"
								>
									<Icon
										path={mdiHomeImportOutline}
										size={1}
									/>
								</DLIconButton>
							</Link>
						)}
					</div>
				</ListSubheader>
			}
		>
			{projStore.checkin.accessibleTreeMenus.map(
				(item: any, index: number) => {
					return (
						<div key={index}>
							<SidebarMenuForProj menuInfo={item} i18n={i18n} />
						</div>
					)
				}
			)}
		</List>
	)
})
