import React from "react"
import styled from "styled-components"
import { DLButton } from "../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default function GroupInfoHeader({
	info,
	permission,
}: {
	info: any
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const handleOpenAddDialog = () => {
		orgStore.groups.setOpenCreateCabinetDialog(true)
	}

	const handleDeleteGroup = () => {
		let proceed = window.confirm(
			"Sub cabinets will be deleted together. Do you really want to delete this group?"
		)
		if (proceed === true) orgStore.groups.removeGroup(info.id)
	}

	const i18n = store.i18n.combineI18n(OrgI18n.groups)

	return (
		<StyledGroupInfoHeader
			className="FR AC JSB"
			data-testid="group-info-header"
		>
			<div className="FR AC">
				{permission.create && (
					<DLButton
						variant="text"
						color="primary"
						eleTestId="create-cabinet"
						startIcon={<Icon path={mdiPlus} size={0.8} />}
						clickHandler={handleOpenAddDialog}
					>
						{i18n.twAddCabinet}
					</DLButton>
				)}
			</div>
		</StyledGroupInfoHeader>
	)
}

const StyledGroupInfoHeader = styled.div`
	width: 100%;
	padding: 0.6rem 0 1rem;
	/* border-top: 1px solid ${(props) => props.theme.shade10}; */
	border-bottom: 1px solid ${(props) => props.theme.shade10};
`
