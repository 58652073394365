import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
//View model actions
import GetPreConditionStatus from "./view-model-actions/get-pre-condition-status"
import RemovePreConditionFiles from "./view-model-actions/remove-pre-condition-files"
import StartPreProcessing from "./view-model-actions/start-pre-processing-check"
import ArchiveProject from "./view-model-actions/archive-project"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./archive-pre-process.provider"
import {
	PreConditionData,
	ArchiveDetails,
} from "./data-models/archive-pre-process.data-model"

const ArchivePreProcessViewModel = types
	.model({
		preConditionData: PreConditionData,
		archiveDetails: ArchiveDetails,
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setPreConditionData(data: any) {
			self.preConditionData = data
		},
		resetPreConditionData() {
			self.preConditionData.isPreProcessed = false
			self.preConditionData.preProcessFiles.map((files) => {
				files.isPreProcessed = false
				files.downloadUrl = ""
				files.viewUrl = ""
			})
		},
		setArchiveDetails(data: any) {
			self.archiveDetails = data
		},
		setPreProcessComplete(isPreProcessed: boolean) {
			self.preConditionData.isPreProcessed = isPreProcessed
		},
	}))
	// View model actions
	.actions(GetPreConditionStatus)
	.actions(RemovePreConditionFiles)
	.actions(StartPreProcessing)
	.actions(ArchiveProject)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions((self) => ({
		refresh() {
			self.initializeStore()
			self.getPreConditionStatus()
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ArchivePreProcessViewModel
