import { flow } from "mobx-state-tree"

const ReadGroupList = (self: any, apiRead: any) => ({
	readGroupList: flow(function*(payload: {
		PageIndex?: number
		PageSize: number
		GroupID?: number
		UserID?: number
		ClientID?: number
	}) {
		const actionName = "readGroupList"
		try {
			const response = yield apiRead(payload)

			// ConsoleLog("readGroupList reponse", response)
			return response
		} catch (error) {
			// console.error("readGroupList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadGroupList
