import { flow } from "mobx-state-tree"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

// model actions
const selectedToken = (tokenType: TOKEN_TYPE) => {
	const tokenFromLocalStorage = localStorage.getItem(tokenType)
	if (tokenFromLocalStorage) {
		return JSON.parse(tokenFromLocalStorage)
	}
	return `Failed to find the ${tokenType}`
}

const ReadPassedAdjustmentUrl = (self: any) => ({
	readPassedAdjustmentUrl: flow(function* (payload: {
		userNumberId: number
		projNumberId: number
		finyear: number
		ajeId: number
		accessRight: number
	}) {
		const actionName = "readPassedAdjustmentUrl"
		const { userNumberId, projNumberId, finyear, ajeId, accessRight } =
			payload
		try {
			const endpoint =
				process.env.REACT_APP_DEV_EXPRESS_ENDPOINT +
				"/PassAdjustments.aspx?userid=" +
				userNumberId +
				"&clientid=" +
				projNumberId +
				"&AdjId=" +
				ajeId +
				"&finyear=" +
				finyear +
				"&Lang=" +
				"en" +
				"&AR=" +
				accessRight +
				"&token=" +
				selectedToken(TOKEN_TYPE.projToken)
			const response = {
				status: 200,
				data: {
					Status: 1,
					URL: endpoint,
				},
			}
			// ConsoleLog(actinName, "__response", response)
			return response
		} catch (error) {
			// ConsoleLog(actinName, "__error", error)
			self.handleModelError({
				actionName,
				error,
				openSnackbar: true,
			})

			return false
		}
	}),
})

export default ReadPassedAdjustmentUrl
