import { ActionStatus, PostType } from "@datalobby/types"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"

const PermanentRemovePost = (self: any) => ({
	permanentRemovePost({
		postId,
		menuId,
		postAction
	}: {
		postId: string
		menuId: string
		postAction?: any
	}) {
		const actionName = "permanentRemovePost"

		self.responses.putResponse({
			actionName,
			status: ActionStatus.loading,
		})
		// 2) request recent project list to Model
		self.permanentDeletePost({
    		postId,
    		menuId,
    	}).then((response: any) => {
				console.log(actionName, "response: ", response)
				postAction && postAction()
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					open: true,
				})
			})
	},
})

export default PermanentRemovePost
