import { types } from "mobx-state-tree"

/**
 * - `normal` type signoff can get multiple available roles
 * - `role-signoff` type signoff can get only one available role
 * --> "availableRoles" seems to replace this normal / role-signoff
 */

export type SignOffPolicyItemProps = {
	id: string // NOTE: watchout. this id is used to display the sign off column also.
	label: string
	shortLabel?: string
	// type: SignOffTypeNew
	order: number
	availableRoles: string[]
	prerequisite: null | string[]
	updateTogether: null | string[]
	required: boolean
}

export enum SignOffColumn {
	prepare = "PREPARE_COLUMN",
	review = "REVIEW_COLUMN",
	epReview = "EP_REVIEW_COLUMN",
	cpReview = "CP_REVIEW_COLUMN",
	qcReview = "QC_REVIEW_COLUMN",
}

export enum OldSignOffType {
	prepare = "PREPARE",
	review = "REVIEW",
}

export const SignOffModel = types.model({
	userId: types.string, // [UserID] ex) 16
	userName: types.string,
	// SignOff: types.xxx, // [SignOff] ex) null
	// --> ungrouped // signOffDetail: SignOffDetail, // [SignOffInfo] ex) {roleName: "EP", userName: "cskim", Date: "2020-01-07T15:54:37.195473+00:00"}
	roleShortName: types.string,
	signOffType: types.enumeration<OldSignOffType>(
		Object.values(OldSignOffType)
	),
	//
	signedAt: types.string, // NOTE: Do not use [SignUp]
	//
	signOffModified: types.boolean, // [SignOffModified] ex) false
	srcModified: types.boolean, // [FileModified] ex) false
	// SignOffDate: types.xxx, // [SignOffDate] ex) null
	// signOffType: types.enumeration("SignOff Type Enum", ["PREPARE", "REVIEW"]) // [signOffType] ex) 2 // --> 2 means review // TODO: checking is required
	// roleId: types.xxx, // [roleId] ex) 9
})

export type DLSignOffModelProps = {
	userId: string // [UserID] ex) 16
	userName: string
	// SignOff: xxx, // [SignOff] ex) null
	// --> ungrouped // signOffDetail: SignOffDetail, // [SignOffInfo] ex) {roleName: "EP", userName: "cskim", Date: "2020-01-07T15:54:37.195473+00:00"}
	roleShortName: string
	signOffType: OldSignOffType
	//
	date: string
	signedAt: string // [SignUp] ex) "2020-01-07T15:54:37.195473+00:00"
	//
	signOffModified: boolean // [SignOffModified] ex) false
	srcModified: boolean // [FileModified] ex) false
	// SignOffDate: xxx, // [SignOffDate] ex) null
	// signOffType: enumeration("SignOff Type Enum", ["PREPARE", "REVIEW"]) // [signOffType] ex) 2 // --> 2 means review // TODO: checking is required
	// roleId: xxx, // [roleId] ex) 9
}

export type SingleSignOffProps = {
	userId: string
	roleId: string
	signOffType: OldSignOffType
	forCancel: boolean
	fileId: string
}

export type MultipleSignOffProps = {
	userId: string
	roleId: string
	signOffType: OldSignOffType
	forCancel: boolean
	fileIds: string[]
	index: number // for sequential signoffs
}
