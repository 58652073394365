import { types } from "mobx-state-tree"
import { zonedTimeToUtc, utcToZonedTime, format } from "date-fns-tz"

export function dateByTimeZone({
	timeZone, // ui value of time zone
	date,
}: {
	timeZone: string
	date: Date
}) {
	const zonedDate = utcToZonedTime(date.toJSON(), timeZone)
	const pattern = "yyyy-MM-dd HH:mm:ss (z)"

	return format(zonedDate, pattern, { timeZone })
}

export function dateByFormat({
	date,
	dateFormat,
	timeZone,
	timeFormat,
}: {
	date: Date
	dateFormat: string
	timeZone: string
	timeFormat: string
}) {
	const zonedDate = utcToZonedTime(date.toJSON(), timeZone)
	const pattern = dateFormat + " " + timeFormat

	return format(zonedDate, pattern, { timeZone })
}

const DateAndTimeSettingUiHelper = types
	.model({
		localeDate: types.Date,
		utcDate: types.union(types.Date, types.undefined),
	})
	.actions((self) => ({
		setUtcDate(timeZone: string) {
			const newDate = new Date()
			self.utcDate = zonedTimeToUtc(newDate, timeZone)
		},
	}))
	.views((self) => ({
		getLocaleTimeZone() {
			if (self.localeDate) {
				const loaleDate = self.localeDate.toString()
				const localeTimeZone = loaleDate.slice(
					loaleDate.indexOf("(") + 1,
					loaleDate.length - 1
				)
				return localeTimeZone
			} else {
				return "Locale Date not exist"
			}
		},
	}))

export default DateAndTimeSettingUiHelper
