import React, {
	createContext,
	useContext,
	useMemo,
	useState,
	PropsWithChildren,
} from "react"
import {
	DndContext,
	KeyboardSensor,
	PointerSensor,
	useSensor,
	useSensors,
	DragOverlay,
	defaultDropAnimationSideEffects,
	closestCenter,
} from "@dnd-kit/core"
import {
	Active,
	UniqueIdentifier,
	DraggableSyntheticListeners,
	DropAnimation,
} from "@dnd-kit/core"
import {
	SortableContext,
	arrayMove,
	sortableKeyboardCoordinates,
	useSortable,
	verticalListSortingStrategy,
} from "@dnd-kit/sortable"
import styled from "styled-components"
import { CSS } from "@dnd-kit/utilities"

interface BaseItem {
	id: UniqueIdentifier
}

interface Props<T extends BaseItem> {
	items: T[]
	onChange(items: T[]): void
	renderItem(item: T): React.ReactNode
}

interface SortableProps {
	id: UniqueIdentifier
}
interface Context {
	attributes: Record<string, any>
	listeners: DraggableSyntheticListeners
	ref(node: HTMLElement | null): void
}

const SortableItemContext = createContext<Context>({
	attributes: {},
	listeners: undefined,
	ref() {},
})

const dropAnimationConfig: DropAnimation = {
	sideEffects: defaultDropAnimationSideEffects({
		styles: {
			active: {
				opacity: "0.4",
			},
		},
	}),
}

export function SortableItem({
	children,
	id,
}: PropsWithChildren<SortableProps>) {
	const {
		attributes,
		isDragging,
		listeners,
		setNodeRef,
		setActivatorNodeRef,
		transform,
		transition,
	} = useSortable({ id })
	const context = useMemo(
		() => ({
			attributes,
			listeners,
			ref: setActivatorNodeRef,
		}),
		[attributes, listeners, setActivatorNodeRef]
	)
	const style: any = {
		opacity: isDragging ? 0.4 : undefined,
		transform: CSS.Transform.toString(transform),
		transition,
	}

	return (
		<SortableItemContext.Provider value={context}>
			<li className="SortableItem" ref={setNodeRef} style={style}>
				{children}
			</li>
		</SortableItemContext.Provider>
	)
}

export function SortableList<T extends BaseItem>({
	items,
	onChange,
	renderItem,
}: Props<T>) {
	const [active, setActive] = useState<Active | null>(null)
	const activeItem = useMemo(
		() => items.find((item) => item.id === active?.id),
		[active, items]
	)
	const sensors = useSensors(
		useSensor(PointerSensor),
		useSensor(KeyboardSensor, {
			coordinateGetter: sortableKeyboardCoordinates,
		})
	)

	return (
		<DndContext
			sensors={sensors}
			onDragStart={({ active }) => {
				setActive(active)
			}}
			onDragEnd={({ active, over }) => {
				if (over && active.id !== over?.id) {
					const activeIndex = items.findIndex(
						({ id }) => id === active.id
					)
					const overIndex = items.findIndex(
						({ id }) => id === over.id
					)

					onChange(arrayMove(items, activeIndex, overIndex))
				}
				setActive(null)
			}}
			onDragCancel={() => {
				setActive(null)
			}}
			collisionDetection={closestCenter}
		>
			<SortableContext
				items={items}
				strategy={verticalListSortingStrategy}
			>
				<StyledUl>
					<ul className="SortableList" role="application">
						{items.map((item) => (
							<React.Fragment key={item.id}>
								{renderItem(item)}
							</React.Fragment>
						))}
					</ul>
				</StyledUl>
			</SortableContext>
			<DragOverlay dropAnimation={dropAnimationConfig}>
				{activeItem ? renderItem(activeItem) : null}
			</DragOverlay>
		</DndContext>
	)
}

export function DragHandle() {
	const { attributes, listeners, ref } = useContext(SortableItemContext)

	return (
		<StyledDragHandle>
			<button
				className="DragHandle"
				{...attributes}
				{...listeners}
				ref={ref}
			>
				<svg viewBox="0 0 20 20" width="12">
					<path d="M7 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 2zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 7 14zm6-8a2 2 0 1 0-.001-4.001A2 2 0 0 0 13 6zm0 2a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 8zm0 6a2 2 0 1 0 .001 4.001A2 2 0 0 0 13 14z"></path>
				</svg>
			</button>
		</StyledDragHandle>
	)
}

SortableList.Item = SortableItem
SortableList.DragHandle = DragHandle

const StyledUl = styled.div`
	.SortableList {
		display: flex;
		flex-direction: column;
		gap: 10px;
		padding: 0;
		list-style: none;
	}
`
const StyledDragHandle = styled.div`
	.DragHandle {
		display: flex;
		width: 12px;
		padding: 4px;
		align-items: center;
		justify-content: center;
		flex: 0 0 auto;
		touch-action: none;
		cursor: var(--cursor, pointer);
		border-radius: 5px;
		border: none;
		outline: none;
		appearance: none;
		background-color: transparent;
		-webkit-tap-highlight-color: transparent;
	}

	.DragHandle:hover {
		background-color: rgba(0, 0, 0, 0.05);
	}

	.DragHandle:focus-visible {
		box-shadow: 0 0px 0px 2px #4c9ffe;
	}
	.DragHandle svg {
		flex: 0 0 auto;
		margin: auto;
		height: 100%;
		overflow: visible;
		fill: #919eab;
	}
`
