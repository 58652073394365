import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../../../library/api-requests"

const version = process.env.REACT_APP_API_VERSION

export async function login(params: { userName: string; password: string }) {
	const endpoint = "/token"
	const endpoint_v2 = "/security/Token/Validate"
	//
	const response = await postRequest({
		url: version === "v2" ? endpoint_v2 : endpoint,
		params,
		tokenType: TOKEN_TYPE.none,
		version,
	})

	return response
}

export async function logout(userEmail: string) {
	const response = await postRequest({
		url: "/SignOut/" + userEmail,
		params: {},
		tokenType: TOKEN_TYPE.loginToken,
	})

	return response
}

export async function readInfo() {
	const response = await getRequest({
		/**
		 * 'GetTaggedCustomersList' is for organization list
		 * Because there are no proper apis to get the defulat lobby information,
		 * use this api temporary
		 */
		url: "/GetTaggedCustomersList",
		tokenType: TOKEN_TYPE.loginToken,
	})

	return response
}

export type LobbyCheckinProps = {
	apiLogin: (user: {
		userName: string
		password: string
	}) => Promise<AxiosResponse<any>>
	apiLogout: (userEmail: string) => Promise<AxiosResponse<any>>
	apiReadInfo: () => Promise<AxiosResponse<any>>
}
