import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
// separated viewmodel actions
import GetProjToken from "./view-model-actions/get-proj-token"
import GetProjInfo from "./view-model-actions/get-proj-info"
import GetProjSettings from "./view-model-actions/get-proj-settings"
import GetAssignedRoleFromGoogleSheet from "./view-model-actions/get-proj-assigned-role"
import GetProjAccessibleMenus from "./view-model-actions/get-proj-accessible-menus"
import CheckingPermission from "../../../../common-models/permission"
//
import { OrgSysSettingItemModel } from "../../../../service-modules/sys-setting-module/sys-setting.data-model"

import {
	MenuForUi,
	CombinedRoleDefault,
	RoleSet2,
} from "../../../../service-modules/role-module/roles.data-model"
import { initialStore } from "./proj-checkin.provider"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import GetRoleSet from "../../../organization-side/org-access-control/org-checkin/store/view-model-actions/get-role-set"
import GetPermissionSet from "../../../organization-side/org-access-control/org-checkin/store/view-model-actions/get-permission-set"

/**
 * 1. token
 * 2. project default info
 * 3. settings: time, locale, etc
 * 4. user permission
 * 5. avialable menus
 * 6. checkin
 */

const ProjCheckinViewModel = types
	.model({
		// 1. token
		// 2. project default info
		// get directly through checkin process
		orgId: "",
		projId: "",
		userId: "",
		//
		user: types.model({
			userId: "",
			name: "",
			isReadonly: true,
			roleId: "",
			roleColor: "",
			roleShortName: "",
			orgRoleId: "",
			userType: "",
			prevRight: "", // TODO: this part will be deprecated. Project & Archive Admin, Project Admin, Project User...
			oldAccessRightId: 0,
			oldNumId: 0,
		}),
		client: types.model({
			id: "",
			name: "",
			aliasId: types.union(types.string, types.undefined),
		}),
		roleSet: RoleSet2,
		//
		//
		// 4. NOTE: assigned user's permission by role (this is custom one)
		// WARNING: be careful. this part should have same format on both org and proj
		assignedRole: CombinedRoleDefault,
		// 5. accessible menus
		accessibleTreeMenus: types.array(MenuForUi),
		accessibleFlatMenus: types.array(MenuForUi),
		// 6. checkin
		projCheckin: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		), // for integrated working (do NOT merge with responses)
		//
		//
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.views((self) => ({
		get defaultUrl() {
			const projNumId = idToNumber(self.projId, IdType.project)
			if (typeof projNumId === "number") {
				return `/project/${projNumId}`
			} else {
				ConsoleLog("Error_defaultUrl", projNumId)
				return undefined
			}
		},
		get projNumId() {
			const projNumId = idToNumber(self.projId, IdType.project)
			if (typeof projNumId === "number") {
				return projNumId
			} else {
				ConsoleLog("Error_Incorrect Proj ID:", projNumId)
				return null
			}
		},
	}))
	.actions((self) => ({
		setOrgId(id: string) {
			self.orgId = id
		},
		setProjId(id: string) {
			self.projId = id
		},

		setUserId(id: string) {
			self.userId = id
		},
		setUser(userInfo: any) {
			self.user = userInfo
		},
		setClient(clientInfo: any) {
			self.client = clientInfo
		},
		setRoleSet(roleSet: any) {
			self.roleSet = roleSet
		},
		//
		setAssignedRole(roleInfo: any) {
			self.assignedRole = roleInfo
		},
		setAccessibleTreeMenus(menus: any) {
			self.accessibleTreeMenus.length = 0
			self.accessibleTreeMenus = menus
		},
		setAccessibleFlatMenus(menus: any) {
			self.accessibleFlatMenus.length = 0
			self.accessibleFlatMenus = menus
		},
		setProjCheckin(status: ActionStatus) {
			if (status === ActionStatus.fail) {
				// ConsoleLog("(setProjCheckin) remove the project related items")
				// localStorage.removeItem("latestProjId")
				// localStorage.removeItem("latestProjToken") //("dlProjToken")
				// initialize store
				// applySnapshot(self, initialStore)
			}
			self.projCheckin = status
		},
	}))
	// AGER(Add, Get, Edit, Remove) Actions
	.actions(GetProjToken)
	.actions(GetProjInfo)
	.actions(GetProjSettings)
	// .actions(GetAssignedRoleFromGoogleSheet)
	.actions(GetPermissionSet)
	.actions(GetProjAccessibleMenus)
	.actions(GetRoleSet)

	//
	.views((self) => ({
		getMenuInfoById(menuId: string) {
			const menu = self.accessibleFlatMenus.find(
				(item: any) => item.id === menuId
			)

			if (menu) {
				return menu
			} else {
				ConsoleLog([
					"(getMenuInfoById) This menu ID does not exist on the accessible menu list",
					menuId,
				])
				return null
			}
		},
	}))
	.views(CheckingPermission)
	// common parts
	.actions((self) => ({
		initializeStore() {
			ConsoleLog(
				" #################### project checkin initialize ####################"
			)
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ProjCheckinViewModel
