import { flow } from "mobx-state-tree"

const CreateOrgCustomer = (self: any, create: any) => ({
	createOrgCustomer: flow(function* (payload: any) {
		const actionName = "createOrgCustomer"
		try {
			const response = yield create(payload)
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error)
			self.handleModelError({ actionName, error, open: false })
			return false
		}
	}),
})

export default CreateOrgCustomer
