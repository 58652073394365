import React, { useEffect, useState } from "react"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLSpinner } from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import OrgProjTemplateDetail from "./sub-components/OrgProjTemplateDetail"
import ResizingTrigger from "../../../../components/combined-elements/tree-nav/DLTreeNavResizingTrigger"
import OrgProjTemplateTreeNav from "./sub-components/OrgProjTemplateTreeNavController"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import AddProjTemplateGroupDialog from "./sub-components/dialogs/AddProjTemplateGroupDialog"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjTemplateDialog } from "./store/org-proj-templates.ui-helper"
import AddProjTemplateDialog from "./sub-components/dialogs/AddProjTemplateDialog"
import { FileScreenDialog } from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import ProjTemplateGroupCtxMenu from "./sub-components/context-menus/ProjTemplateGroupCtxMenu"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import {
	OrgI18n,
	ProjI18n,
} from "../../../../common-models/enumerations/translation-sheets"
import FileTreeForSelectionDialog from "../../../../components/combined-elements/file-tree/file-tree-dialogs/FileTreeForSelectionDialog"
import {
	FileFolderDialog,
	FileEditDialog,
	ReplaceFileDialog,
	RoleAssignDialog,
} from "../../../../components/combined-elements/file-tree/file-tree-dialogs"
import { displayedProjRoles } from "../../../../temporary-data/project-side/find-proj-role"
import AuditProgramDialog from "./sub-components/dialogs/AuditProgramDialog"

export default observer(function OrgProjTemplates() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const menuId = DLOrgSubMenus.proj_templates
	const actionName = "getOrgProjTemplateGroups"
	const i18nSheet = OrgI18n.projTemplates
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const userRole = orgStore.checkin.assignedRole.roleId

	const needRefresh = templateStore.needRefresh

	const defaultProjRoleSet: any[] = displayedProjRoles

	const [dragStart, setDragStart] = useState(false)
	const [movedX, setMovedX] = useState(0)

	useEffect(() => {
		menuAccess &&
			needRefresh &&
			!dragStart &&
			templateStore.getOrgProjTemplateGroups()
	}, [menuAccess, needRefresh])

	const handleMouseDown = (e: any) => {
		setDragStart(true)
	}
	const handleMouseUp = (e: any) => {
		setDragStart(false)
	}
	const handleMouseMove = (e: any) => {
		dragStart && setMovedX(e.movementX)
	}

	const handleAddFolder = (folderName: string) => {
		let parentId = templateStore.selectedItems[0].id
		// WARNING: Project Template use template ID as parent ID of root folder
		// it means, in project template, parentId cannot be null for root folder
		templateStore.addFileFolder({
			parentId,
			projId: templateStore.selectedTemplate.id,
			title: folderName,
		})
	}

	const handleEditFolder = (folderName: string) => {
		templateStore.editFileFolder({
			folderId: templateStore.selectedFolder,
			folderName,
			postAction: templateStore.setFileTreeDialogOpen(
				FileScreenDialog.editFolder,
				false
			),
		})
	}

	useEffect(() => {
		dragStart && templateStore.updateNavWidth(movedX)
	}, [dragStart, movedX])

	const contentsHeight = store.ui.contentsAreaHeight
	const i18n = store.i18n.combineI18n([i18nSheet, ProjI18n.files])
	const actionStatus = templateStore.getActionStatus(actionName)

	return (
		<>
			{menuAccess ? (
				<PageContainer
					fullWidth
					fixedHeight
					noPadding
					eleStyle={{ overflowX: "hidden", overflowY: "hidden" }}
					hasToolbar={false}
					isLoading={actionStatus === "LOADING"}
				>
					<StyledOrgProjTemplatesContainer className="FC">
						{/* TODO: Is this counter is required? 
								<PageHeader noPadding>
									<BasicCounter
										label={
											i18n.twTotalTemplateGroups ||
											"Total Template Groups"
										} // TODO: is this total template group? or individual template total?
										value={templateStore.templateCount}
									/>
								</PageHeader>
 							*/}

						{actionStatus === "SUCCESS" && (
							<div
								className="FR templates-main-container"
								onMouseUp={handleMouseUp}
								onMouseMove={handleMouseMove}
								style={{ height: contentsHeight }}
							>
								<OrgProjTemplateTreeNav
									permission={permission}
									i18n={i18n}
								/>
								<ResizingTrigger
									handleMouseDown={handleMouseDown}
								/>
								{/* {templateId === "" ? (
									<OrgProjTemplateCardView />
								) : (
									<OrgProjTemplateDetail
										permission={permission}
										i18n={i18n}
									/>
								)} */}
								<OrgProjTemplateDetail
									permission={permission}
									i18n={i18n}
								/>
							</div>
						)}
						{/* response snackbar */}
						<CommonSnackbarComponent
							i18n={i18n}
							partialStore={templateStore}
						/>
						{/* dialogs */}

						{permission.create &&
							templateStore.dialogOpenStatus[
								ProjTemplateDialog.createTemplate
							] && <AddProjTemplateDialog />}

						{permission.create &&
							templateStore.fileTreeDialogOpenStatus[
								FileScreenDialog.createFolder
							] && (
								<FileFolderDialog
									partialStore={templateStore}
									actionHandler={handleAddFolder}
									type="add"
									i18n={i18n}
								/>
							)}

						{permission.update &&
							templateStore.fileTreeDialogOpenStatus[
								FileScreenDialog.editFolder
							] && (
								<FileFolderDialog
									partialStore={templateStore}
									actionHandler={handleEditFolder}
									type="edit"
									i18n={i18n}
								/>
							)}

						{templateStore.fileTreeDialogOpenStatus[
							FileScreenDialog.folderTree
						] && (
							<FileTreeForSelectionDialog
								target="folder-only"
								partialStore={templateStore}
								i18n={i18n}
							/>
						)}

						{templateStore.fileTreeDialogOpenStatus[
							FileScreenDialog.fileEdit
						] && (
							<FileEditDialog
								partialStore={templateStore}
								projId={templateStore.selectedTemplate.id}
								i18n={i18n}
							/>
						)}

						{templateStore.fileTreeDialogOpenStatus[
							FileScreenDialog.replaceFile
						] && (
							<ReplaceFileDialog
								partialStore={templateStore}
								i18n={i18n}
								userRole={userRole}
							/>
						)}

						{templateStore.fileTreeDialogOpenStatus[
							FileScreenDialog.assign
						] && (
							<RoleAssignDialog
								partialStore={templateStore}
								roleSet={defaultProjRoleSet}
								i18n={i18n}
							/>
						)}

						{templateStore.openAuditProgramDialog && (
							<AuditProgramDialog i18n={i18n} />
						)}

						{/* ctx menus for tree-nav */}
						<ProjTemplateGroupCtxMenu permission={permission} />
					</StyledOrgProjTemplatesContainer>
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
			{permission.create &&
				templateStore.dialogOpenStatus[
					ProjTemplateDialog.createTemplateGroup
				] && <AddProjTemplateGroupDialog />}
		</>
	)
})

const StyledOrgProjTemplatesContainer = styled.div`
	.page-container-org-lib-pt {
	}
	/* padding-left: 1rem; */
	.page-header {
		padding: 0 0 1rem 1rem;
		border-bottom: ${(props) => props.theme.secondary};
	}
`
