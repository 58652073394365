import { GlobalSheet } from "../../../../common-models/enumerations/translation-sheets"
import { getStaticDict } from "./get-static-dict"

export const CombineI18n = (self: any) => ({
	combineI18n(moduleId: string | string[], where?: string) {
		const globalSheet = GlobalSheet.common

		let i18nG = getStaticDict(
			globalSheet,
			self.language,
			"combineI18n-i18nG" + where
		)
		let i18nM

		if (typeof moduleId === "string") {
			i18nM = getStaticDict(
				moduleId,
				self.language,
				"combineI18n-i18nM" + where
			)
		} else {
			i18nM = moduleId.reduce((acc: any, currentModule: any) => {
				const currentI18n = getStaticDict(
					currentModule,
					self.language,
					"combineI18n-i18nM" + where
				)
				return { ...acc, ...currentI18n }
			}, {})
		}

		// console.log(i18nM,"i18nM")

		return { ...i18nG, ...i18nM }
	},
})
