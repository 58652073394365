// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { AssignStatus } from "../data-models/org-clients.data-models"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { ConsoleLog } from "../../../../components/basic-elements"

const AddGroupsToClient = (self: any) => ({
	addGroupsToClient({
		groupList,
		clientId,
		type,
		postAction,
	}: {
		groupList: any
		clientId: string
		type: AssignStatus
		postAction: () => void
	}) {
		// 0.
		const actionName = "addGroupsToClient"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const groupType = type === AssignStatus.assigned ? 2 : 1
		const clientMasterId = idToNumber(clientId, IdType.client)

		let payload = {
			Groupslist: groupList,
			ClientmasterID: clientMasterId,
			Type: groupType,
		}
		self.assignGroupsToClient(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					self.updateGroup(groupList, type)

					ConsoleLog(response)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to add groups to client",
						open: true,
						autoHide: true,
						msgCode:
							ActionStatus.success +
							"_" +
							actionName +
							`${groupType}-${response.data.Status}`,
					})

					postAction()
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						customMessage: "Failed",
						color: MessageColorType.orange,
						open: true,
						autoHide: true,
						msgCode:
							ActionStatus.fail +
							"_" +
							actionName +
							`${groupType}-${response.data.Status}`,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddGroupsToClient
