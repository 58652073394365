import { types } from "mobx-state-tree"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../common-models/types/dialog.props"
import { FormattedViews, UiViews } from "./ui-helper-actions"
import {
	CreateProjectMethod,
	CreateProjectInputsModel,
	CreateProjectInputProps,
} from "../../../components/combined-elements/create-project/create-project.data-props"
import ForExport from "./ui-helper-actions/for-export.actions"
import {
	SelectedItemsSharedActions,
	SelectedItemsSharedViews,
	CommonSelectedItem,
} from "../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"
// ---------- common models

const OrgProjectsUiHelper = types
	.model({
		clickPoint: ClickPoint,
		//
		openCreateProjDialog: false,
		createProjectMethod: types.enumeration<CreateProjectMethod>(
			Object.values(CreateProjectMethod)
		),
		rfSourceId: "",
		createProjectPresetInputs: types.union(
			CreateProjectInputsModel,
			types.undefined
		),
		//
		selectedItems: types.array(CommonSelectedItem),
		//
		openDeleteProjDialog: false,
		projectDetailsDialogOpen: false,
		preProcessDialog: false,
		updateDialogOpenStatus: false,
		openProjLocationDialog: false,
		forceArchiveDialog: false,
	})
	.actions(SelectedItemsSharedActions)
	.views(SelectedItemsSharedViews)
	.actions((self) => ({
		setRfSourceId(srcId: string) {
			self.rfSourceId = srcId
		},
		setClickPoint(clickPoint: ClickPointProps) {
			self.clickPoint = clickPoint
		},
		setCreateProjectPresetInputs(
			inputs: CreateProjectInputProps | undefined
		) {
			self.createProjectPresetInputs = inputs
		},
		setOpenCreateProjDialog(request?: boolean) {
			if (request !== undefined) {
				self.openCreateProjDialog = request
			} else {
				self.openCreateProjDialog = !self.openCreateProjDialog
			}
		},
		setUpdateDialogOpenStatus(openStatus?: boolean) {
			if (openStatus !== undefined) {
				self.updateDialogOpenStatus = openStatus
			} else {
				self.updateDialogOpenStatus = !self.updateDialogOpenStatus
			}
		},
		// TODO: this part is same as groups store.. isn't it better to use same thing?
		setOpenDeleteProjDialog(request?: boolean) {
			if (request !== undefined) {
				self.openDeleteProjDialog = request
			} else {
				self.openDeleteProjDialog = !self.openDeleteProjDialog
			}
		},
		setCreateProjectMethod(from: CreateProjectMethod) {
			self.createProjectMethod = from
		},
		setProjectDetailsDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.projectDetailsDialogOpen = request
			} else {
				self.projectDetailsDialogOpen = !self.projectDetailsDialogOpen
			}
		},
		setPreProcessDialog(request?: boolean) {
			self.preProcessDialog = request ?? !self.preProcessDialog
		},
		setForceArchiveDialog(request?: boolean) {
			self.forceArchiveDialog = request ?? !self.forceArchiveDialog
		},
		setOpenProjLocationDialog(request?: boolean) {
			self.openProjLocationDialog =
				request ?? !self.openProjLocationDialog
		},
	}))
	.views(UiViews)
	.views(FormattedViews)
	.actions(ForExport)

export default OrgProjectsUiHelper
