import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import {
	saveLatestProjToken,
	saveProjTokens,
} from "../../../../../library/token-utils"

const GetProjToken = (self: any) => ({
	getProjToken({
		projId,
		userId,
		whenSuccess,
		whenFail,
	}: {
		projId: string
		userId: string
		whenSuccess: () => void
		whenFail: () => void
	}) {
		// 0) set actionName
		const actionName = "getProjToken"
		console.log("getProjToken for", projId)
		// 1)
		// 2) add this action to responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) API call through the model
		self.requestProjToken(projId, userId)
			.then((response: any) => {
				// console.log(actionName + "__response " + response)

				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					// ConsoleLog("original projId", projId)
					// WARNING:  TODO: DO NOT use localStorage for this!
					const token = response.data.data.access_token
					// for the org side relay action (is this used on there..?)
					// localStorage.setItem("latestProjId", projId)
					saveLatestProjToken(token)
					// for real use
					saveProjTokens(projId, token)
					// self.setProjId(projId)

					whenSuccess()
					// set success
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						customMessage: "Success to get the project token",
						open: false,
					})
				} else {
					whenFail()
					// set fail
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp code. Update is required
						customMessage:
							"Failed to get the Project token. Please retry",
						color: MessageColorType.red,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				whenFail()

				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetProjToken
