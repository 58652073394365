import { flow } from "mobx-state-tree"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { ConsoleLog } from "../../../../components/basic-elements"

const ReadAllFiles = (
	self: any,
	apiReadAllFiles: FileSystemApiTypes["apiReadAllFiles"]
) => ({
	readAllFiles: flow(function* (projNumId: number, menuId: string) {
		const actionName = "readAllFiles"
		// const menuId = self.storeName

		if (menuId !== DLProjSubMenus.wp && menuId !== DLProjSubMenus.pf) {
			ConsoleLog("This menu has no api for get all files")
			return
		}

		let menu: "wp" | "pf" = "wp"
		if (menuId === DLProjSubMenus.pf) {
			menu = "pf"
		}
		try {
			const params = {
				projectId: projNumId,
				menu: menu,
			}
			const response = yield apiReadAllFiles(params)

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default ReadAllFiles
