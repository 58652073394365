import { flow } from "mobx-state-tree"

const ReadRecentProjList = (self: any, read: any) => ({
	readRecentProjList: flow(function*(userId) {
		const actionName = "readRecentProjList"
		try {
			const params = { UserID: userId }
			const response = yield read(params)

			// ConsoleLog("readRecentProjList response", response)
			return response
		} catch (error) {
			// console.error("read recent", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadRecentProjList
