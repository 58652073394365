import { types, getType } from "mobx-state-tree"

export const OrgMgmtListItem = types.model({
	id: types.string,
	custName: types.string,
	custId: types.string,
	emailId: types.string,
	organizationName: types.string,
	createdBy: types.string,
	custStatus: types.string,
})
