import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../common-models/common-model-actions"
// separated actions
import {
	ReadGroupList,
	ReadUnassignedUsersOfGroup,
	ReadAssignedUsersOfGroup,
	AssignUsersToGroup,
	UnAssignUsersToGroup,
	ReadClientMasterByGroup,
	AssignClientsToGroup,
	UpdateGroupName,
} from "./model-actions"
import {
	CreateGroupItem as CreateGroup,
	DeleteGroupItem as DeleteOrgGroup,
} from "../../../service-modules/group-module"
//
import { OrgSetupGroupsApiProps } from "./org-setup-groups.apis"

const OrgSetupGroupsModel = ({
	apiRead,
	apiUpdate,
	apiDelete,
	apiCreate,
	apiReadUnassignedUsersOfGroup,
	apiReadAssignedUsersOfGroup,
	apiAssignUsersToGroup,
	apiUnAssignUsersToGroup,
	apiReadClientsByGroup,
	apiAssignClientsToGroup,
}: OrgSetupGroupsApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => CreateGroup(self, apiCreate))
		.actions((self) => ReadGroupList(self, apiRead))
		.actions((self) => UpdateGroupName(self, apiUpdate))
		.actions((self) => DeleteOrgGroup(self, apiDelete))
		.actions((self) =>
			ReadUnassignedUsersOfGroup(self, apiReadUnassignedUsersOfGroup)
		)
		.actions((self) =>
			ReadAssignedUsersOfGroup(self, apiReadAssignedUsersOfGroup)
		)
		.actions((self) => AssignUsersToGroup(self, apiAssignUsersToGroup))
		.actions((self) => UnAssignUsersToGroup(self, apiUnAssignUsersToGroup))
		.actions((self) => ReadClientMasterByGroup(self, apiReadClientsByGroup))
		.actions((self) => AssignClientsToGroup(self, apiAssignClientsToGroup))
		// common model actions
		.actions(CommonModelActions)

export default OrgSetupGroupsModel
