import { types } from "mobx-state-tree"

export type ArchPolicyPeriodProps = {
	id: string
	title: string
	days: number
}

export const ArchPolicyPeriod = types.model({
	id: types.string,
	title: types.string,
	days: types.number, // days
})
