import { types, applySnapshot } from "mobx-state-tree"
import {
	OrgSysSettingItemModel,
	ModifiedDetails,
} from "../../../../../../service-modules/sys-setting-module/sys-setting.data-model"
// ---------- common models
import Responses from "../../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../../../common-models/common-view-model-actions"
// ---------- actions
import GetDateAndTimeSetting from "./view-model-actions/get-date-and-time-setting"
import EditDateAndTimeSetting from "./view-model-actions/edit-date-and-time-setting"
import { initialStore } from "./date-and-time-setting.provider"
import { getTzDate } from "../../../../../../library/converters/date-utc-converter"

const DateAndTimeSettingViewModel = types
	.model({
		// current settings --> DnT values have been moved to global store
		//
		availableDateFormat: types.array(OrgSysSettingItemModel),
		availableTimeFormat: types.array(OrgSysSettingItemModel),
		availableTimeZone: types.array(OrgSysSettingItemModel),
		modifiedDetails: ModifiedDetails,
		// ---------- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		// set current settings --> DnT related actions have been moved to global store
		setModifiedDetails(details: any) {
			self.modifiedDetails = details
		},
		// reset list
		resetDateFormatList() {
			self.availableDateFormat.length = 0
		},
		resetTimeFormatList() {
			self.availableTimeFormat.length = 0
		},
		resetTimeZoneList() {
			self.availableTimeZone.length = 0
		},
		// push item
		pushItemToDateFormatList(item: any) {
			self.availableDateFormat.push(item)
		},
		pushItemToTimeFormatList(item: any) {
			self.availableTimeFormat.push(item)
		},
		pushItemToTimeZoneList(item: any) {
			self.availableTimeZone.push(item)
		},
	}))
	.views((self) => ({
		findDateFormatItem(dateFormatValue: string) {
			return self.availableDateFormat.find(
				(element: any) => element.value === dateFormatValue
			)
		},
		findTimeZoneItem(timeZoneValue: string) {
			return self.availableTimeZone.find(
				(element: any) => element.value === timeZoneValue
			)
		},
		findTimeFormatItem(timeFormatValue: string) {
			return self.availableTimeFormat.find(
				(element: any) => element.value === timeFormatValue
			)
		},
		viewModifiedDetails(dateFormat: string, timeZone: string) {
			let formattedModified = "-"
			if (self.modifiedDetails.modifiedDate !== "") {
				formattedModified = self.modifiedDetails.modifiedDate
					? getTzDate({
							date: self.modifiedDetails.modifiedDate,
							timeZone,
							dateFormat,
					  })
					: ""
			}

			return {
				...self.modifiedDetails,
				modifiedDate: formattedModified,
			}
		},
	}))
	// ---- AGER (Add, Get, Edit, Remove)
	.actions(GetDateAndTimeSetting)
	.actions(EditDateAndTimeSetting)
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupDnT")
			applySnapshot(self, initialStore)
		},
	}))
	.actions((self) => ({
		refresh() {
			self.initializeStore()
			// NOTE: WARNING: @Noah: What is the reason of below line..?
			// I set the setGlobalDnT as optional props temporarily. Need to update
			self.getDntSetting()
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default DateAndTimeSettingViewModel
