import { flow } from "mobx-state-tree"

const ReadAssignedUsersOfGroup = (
	self: any,
	apiReadAssignedUsersOfGroup: any
) => ({
	readAssignedUsersOfGroup: flow(function*(payload) {
		const actionName = "readAssignedUsersOfGroup"
		try {
			const response = yield apiReadAssignedUsersOfGroup(payload)

			// ConsoleLog("readGroupList reponse", response)
			return response
		} catch (error) {
			// console.error("readGroupList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadAssignedUsersOfGroup
