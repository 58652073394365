import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../components/basic-elements"

const ReadOpenedFiles = (self: any, apiReadOpenedFiles: any) => ({
	readOpenedFiles: flow(function*(payload: { Type: number }) {
		const actionName = "readOpenedFiles"
		try {
			ConsoleLog(self)
			const response = yield apiReadOpenedFiles(payload)
			ConsoleLog([actionName, "__response ", response])
			return response
		} catch (error) {
			ConsoleLog([actionName, "__error ", error])
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default ReadOpenedFiles
