import { getRequest } from "../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"

const version = process.env.REACT_APP_API_VERSION

export function read() {
	const endpoint = "/GetTaggedCustomersList"
	const v2_prefix = "/customermanagement/Customer"
	const response = getRequest({
		url: version === "v2" ? v2_prefix + endpoint : endpoint,
		tokenType: TOKEN_TYPE.loginToken,
		version,
	})

	return response
}
