// ---------- common models
import {
	ActionStatus,
	MessageColorType,
	DLCommentType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { commentIdToString } from "./utils-for-comment"

type FetchedSimpleComment = {
	assignedto: string
	comments: string
	createdBy: string
	createdDate: string
	id: number
	response: string
}

const GetCommentsByObj = (self: any) => ({
	getCommentsByObj({
		objectId,
		menuId,
	}: {
		objectId: string
		menuId: DLProjSubMenus
	}) {
		// 0.
		const actionName = "getCommentsByObj_" + menuId
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Loading comments of the file...",
			open: true,
		})
		// 3.

		self.readCommentsByObj({ objectId, menuId })
			.then((response: any) => {
				const mainResponse = response.data[0]
				if (response.status === 200 && mainResponse.Status === 1) {
					// console.log(actionName, "__mainResponse", mainResponse)
					/**
					 * AFTER CARE
					 */
					// NOTE: Save this on the temporary section
					// NOTE: Is it required to update basic list also?
					// WARNING: This API returns an array... Should be updated...  (Or is this for multiple files?)
					// WARNING: This API returns data even it has Status 0. (Status 0 is false in current App)
					// WARNING: When the status is 0, message is also "You are not authorized" but the response has value... :(
					// WARNING: And I cannot understand this result becuase the account at that time is EP of the project...
					let reOrganizedComments: any = []
					const fetchedNormalComments = mainResponse.comments
					const fetchedRequiredComments = mainResponse.followUp

					//
					if (fetchedNormalComments.length > 0) {
						fetchedNormalComments.map(
							(comment: FetchedSimpleComment) => {
								const normalComment = {
									id: commentIdToString(comment.id, menuId),
									type: DLCommentType.normal,
									assignees: comment.assignedto.split(","),
									createdBy: comment.createdBy,
									createdAt: comment.createdDate,
									contents: comment.comments,
									response: comment.response,
								}
								// ConsoleLog("normalComment", normalComment)
								reOrganizedComments.push(normalComment)
							}
						)
					}
					if (fetchedRequiredComments.length > 0) {
						fetchedRequiredComments.map(
							(comment: FetchedSimpleComment) => {
								const requiredComment = {
									id: commentIdToString(comment.id, menuId),
									type: DLCommentType.qc,
									assignees: comment.assignedto.split(","),
									createdBy: comment.createdBy,
									createdAt: comment.createdDate,
									contents: comment.comments,
									response: comment.response,
								}
								// ConsoleLog("requiredComment", requiredComment)
								reOrganizedComments.push(requiredComment)
							}
						)
					}

					self.setCommentsOfCurrentObject(reOrganizedComments)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Success to get comments of the file",
						color: MessageColorType.blue,
						open: false,
					})
				} else if (
					response.status === 200 &&
					mainResponse.Status === 0
				) {
					// console.log(actionName+ "__response " + response)
					/**
					 * AFTER CARE
					 */
					// NOTE: Save this on the temporary section
					// NOTE: Is it required to update basic list also?
					// WARNING: This API returns an array... Should be updated...  (Or is this for multiple files?)
					// WARNING: This API returns data even it has Status 0. (Status 0 is false in current App)
					// WARNING: When the status is 0, message is also "You are not authorized" but the response has value... :(
					// WARNING: And I cannot understand this result becuase the account at that time is EP of the project...
					let reOrganizedComments: any = []
					const fetchedNormalComments = mainResponse.comments
					const fetchedRequiredComments = mainResponse.followUp

					//
					if (fetchedNormalComments.length > 0) {
						fetchedNormalComments.map(
							(comment: FetchedSimpleComment) => {
								const normalComment = {
									id: commentIdToString(comment.id, menuId),
									type: DLCommentType.normal,
									assignees: comment.assignedto.split(","),
									createdBy: comment.createdBy,
									createdAt: comment.createdDate,
									contents: comment.comments,
									response: comment.response,
								}
								// ConsoleLog("normalComment", normalComment)
								reOrganizedComments.push(normalComment)
							}
						)
					}
					if (fetchedRequiredComments.length > 0) {
						fetchedRequiredComments.map(
							(comment: FetchedSimpleComment) => {
								const requiredComment = {
									id: commentIdToString(comment.id, menuId),
									type: DLCommentType.qc,
									assignees: comment.assignedto.split(","),
									createdBy: comment.createdBy,
									createdAt: comment.createdDate,
									contents: comment.comments,
									response: comment.response,
								}
								// ConsoleLog("requiredComment", requiredComment)
								reOrganizedComments.push(requiredComment)
							}
						)
					}

					self.setCommentsOfCurrentObject(reOrganizedComments)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Success to get comments of the file",
						// mainResponse.Message ||
						// mainResponse.message ||
						// "Something wrong...",
						color: MessageColorType.orange,
						open: false,
						// open: false NOTE: because this message is displayed on the comment screen after some times
						// FYI. this API is called on the WP or PF but stored in the comment stroe
					})
				} else {
					// set fail case reponse
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						message:
							mainResponse.Message ||
							mainResponse.message ||
							"Something wrong...",
						// customMessage: "Something wrong...",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetCommentsByObj
