import { flow } from "mobx-state-tree"
import axios from "axios"
import { DLSector } from "../../../../common-models/enumerations/common-enums"

const staticInfo = {
	sheetApiKey: "AIzaSyDsuVO1L7mQeqZBPfw_a4UpQphaWl9AGWY",
	sheetGlobal: "11YnjR4M99pE9Ka1PFvQ_GD22TTbcJdXm5b5c1tUdtzE",
	sheetLobby: "1MogPTSjuZM0g6wne1ioczVApt0CTXzTe8IObpNxQPpM",
	sheetOrg: "1RdVuCJPDlVnUoVS_tYN5x3SUTAmf_McpZACEKz4boDI",
	sheetProj: "1YlG9BNHTb7YUHI2tt5_sH6n87H8ZWptQkRVED_Re3QI",
	sheetUrlPrefix: "https://sheets.googleapis.com/v4/spreadsheets/",
	sheetRange: "A:D",
}

const sheetGlobal = staticInfo.sheetGlobal
const sheetLobby = staticInfo.sheetLobby
const sheetOrg = staticInfo.sheetOrg
const sheetProj = staticInfo.sheetProj
//
const sheetApiKey = staticInfo.sheetApiKey
const sheetUrlPrefix = staticInfo.sheetUrlPrefix
const i18nRange = staticInfo.sheetRange

const ReadTranslations = (self: any) => ({
	// NOTE: This one is not used now
	readI18nFromGoogleSheet: flow(function* (
		sector: DLSector,
		moduleName: string
	) {
		const actionName = "readI18nFromGoogleSheet"

		let sheet = ""
		if (sector === DLSector.global) {
			sheet = sheetGlobal ? sheetGlobal : staticInfo.sheetGlobal
		} else if (sector === DLSector.lobby) {
			sheet = sheetLobby ? sheetLobby : staticInfo.sheetLobby
		} else if (sector === DLSector.org) {
			sheet = sheetOrg ? sheetOrg : staticInfo.sheetOrg
		} else if (sector === DLSector.proj) {
			sheet = sheetProj ? sheetProj : staticInfo.sheetProj
		}

		const sheetUrl =
			sheetUrlPrefix +
			sheet +
			`/values/${moduleName}!${i18nRange}?key=` +
			sheetApiKey

		try {
			const response = yield axios.get(sheetUrl, {
				headers: { authorization: "" }, // Remove Datalobby Token
			})

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, open: false })

			return
		}
	}),
})

export default ReadTranslations
