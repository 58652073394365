// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { RequestCheckinFileProps } from "../model-actions/m-request-checkin-file"
import { HandleResponseProps } from "../../../../../common-models/common-view-model-actions"

const RequestCheckinFile = (self: any) => ({
	requestCheckinFile(
		props: RequestCheckinFileProps,
		localHandleResponse: (responseProps: HandleResponseProps) => void
	) {
		// 0.
		const actionName = "requestCheckinFile"
		const { projId, srcId, message, userId, menuId } = props
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.mRequestCheckinFile({
			projId,
			srcId,
			message,
			userId,
			menuId,
		})
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// set success case response
					localHandleResponse({
						actionName,
						status: ActionStatus.success,
						code: 1,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					localHandleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default RequestCheckinFile
