import { types } from "mobx-state-tree"

const OrgEngTypesUiHelper = types
	.model({
		openAdd: types.boolean,
		openEdit: types.boolean,
		openDelete: types.boolean,
		openEditConnectedChecklist: types.boolean,
	})
	.actions((self) => ({
		setOpenAdd(request?: boolean) {
			self.openAdd = request || !self.openAdd
		},
		setOpenEdit(request?: boolean) {
			self.openEdit = request || !self.openEdit
		},
		setOpenEditConnectedChecklist(request?: boolean) {
			self.openEditConnectedChecklist =
				request || !self.openEditConnectedChecklist
		},
		setOpenDelete(request?: boolean) {
			self.openDelete = request || !self.openDelete
		},
	}))

export default OrgEngTypesUiHelper
