import { types } from "mobx-state-tree"
import AdjustmentsModel from "./adjustments.model"
import AdjustmentsViewModel from "./adjustments.view-model"
// import AdjustmentsUiHelper from "./adjustments.ui-helper"
import * as api from "./adjustments.apis"
import { readTrialBalance } from "../../financial-statements/store/financial-statements.api"
import AdjustmentsUiHelper from "./adjustments.ui-helper"

export const AdjustmentsStore = ({
	apiRead,
	apiReadTrialBalance,
	apiReadWP,
	apiCreate,
	apiDelete,
}: api.AdjustmentsApiProps) =>
	types
		.compose(
			AdjustmentsModel({
				apiRead,
				apiReadTrialBalance,
				apiReadWP,
				apiCreate,
				apiDelete,
			}),
			AdjustmentsViewModel,
			AdjustmentsUiHelper
		)
		.named("AdjustmentsStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const AdjustmentsStoreInstance = AdjustmentsStore({
	apiRead: api.read,
	apiReadTrialBalance: readTrialBalance,
	apiReadWP: api.readWP,
	apiCreate: api.create,
	apiDelete: api.del,
}).create(initialStore)

export default AdjustmentsStoreInstance
