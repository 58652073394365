import React, { useEffect } from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"

import { useRootStore } from "../../../stores/root-store/root-store.provider"
import ProjectTrash from "./ProjectTrash"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"

export default observer(function ProjectTrashController() {
    const store = useRootStore()
    const projStore = useProjStore()
    const actionName1 = "getProjTrashList"
    const menuId = DLProjSubMenus.trash
    const i18nSheet = ProjI18n.trash
    //
    const isLocked = projStore.projInfo.projInfo.isLocked
    const isArchived = projStore.projInfo.projInfo.isArchived

    const needRefresh = projStore.trash.needRefresh

    const menuAccess = projStore.checkin.checkAccess(menuId)
    const permission = projStore.checkin.getActionsAsObject(
        menuId,
        isLocked,
        isArchived
    )

    useEffect(() => {
        menuAccess && needRefresh && projStore.trash.getProjTrashList()
    }, [menuAccess, needRefresh])

    const i18n = store.i18n.combineI18n(i18nSheet)
    return (
        <>
            {menuAccess ? (
                <ProjectTrash
                    partialStore={projStore.trash}
                    permission={permission}
                    contentsHeight={store.ui.contentsAreaHeight - 100}
                    actionStatus={projStore.trash.getActionStatus(actionName1)}
                    dateFormat={store.global.dateFormat.value}
                    i18n={i18n}
                />
            ) : (
                <ProjNoPermissionOnMenu menuId={menuId} />
            )}
        </>
    )
})
