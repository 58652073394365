import { types } from "mobx-state-tree"

export const RiskAssessment = types.model({
	createdAt: types.string, // [CreatedDate], ex) "2020-05-30T11:18:01.972698+00:00"
	createdBy: types.string, // [CreatedUser], ex) "cskim"
	riskAssessmentId: types.string, // [RiskAssessmentID], ex) 2
	riskAssessmentName: types.string, // [RiskAssessmentName], ex) "Change RA"
})

export const ProjectDetails = types.model({
	periodName: types.string,
	year: types.number,
	clientName: types.string,
	projectName: types.string,
	projectAliasId: types.string,
	projectStatus: types.string, // TODO: @Prudhvi-0817: does this mean the project status? if yes, please update
})
