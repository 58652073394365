import axios, { Method } from "axios"
import { idToNumber, idToString, IdType } from "../converters/id-converter"
import { findProjToken } from "../token-utils"
import { decryptUrl } from "../url-encryption"
// `timeout` specifies the number of milliseconds before the request times out.
// If the request takes longer than `timeout`, the request will be aborted.
export type APIProps = {
	url: string
	params?: any
	tokenType: TOKEN_TYPE
	version?: string
	timeout?: number // milliseconds
	payload?: any
}

export enum TOKEN_TYPE {
	awsToken = "awsToken",
	loginToken = "loginToken",
	orgToken = "orgToken",
	projToken = "projToken",
	latestProjToken = "latestProjToken", // NOTE: Only for the admin relay action in the org side
	resetPasswordToken = "resetPasswordToken",
	//
	cdkBackend = "CDK_BACKEND",
	hardCopy = "HARD_COPY",
	adminApp = "ADMIN_APP",
	none = "none",
}

/**
 *
 * @param tokenType
 * @param projId : only for the editor header API request
 */
export const selectedToken = (tokenType: TOKEN_TYPE) => {
	const path = window.location.pathname

	let projNumId: number | undefined = undefined
	// because normal url has number ID

	if (path.includes("/project/checkin/")) {
		projNumId = parseInt(path.split("/")[3])
	} else if (path.includes("/project/")) {
		projNumId = parseInt(path.split("/")[2])
	} else if (path.includes("/editor/")) {
		const editorPath = window.location.pathname.slice(8)
		const urlInfo = decryptUrl(editorPath)
		const projNumIdFromUrl = idToNumber(urlInfo.projId, IdType.project)
		if (typeof projNumIdFromUrl === "number") {
			projNumId = projNumIdFromUrl
		}
	}

	if (tokenType !== TOKEN_TYPE.projToken) {
		const tokenFromLocalStorage = localStorage.getItem(tokenType)
		if (tokenFromLocalStorage) {
			return JSON.parse(tokenFromLocalStorage)
			// } else if (tokenType !== "none" && !tokenFromLocalStorage) {
			// 	alert(`(${tokenType}) Cannot find the token`)
		}
		// return `Failed to find the ${tokenType}`
	} else if (tokenType === TOKEN_TYPE.projToken && projNumId !== undefined) {
		// when the token type is project token, need to find the token from the projTokens
		const tokenFromLocalStorage = findProjToken(
			idToString(projNumId, IdType.project)
		)

		if (tokenFromLocalStorage) {
			return tokenFromLocalStorage
		} else {
			console.log("No matched project token for", projNumId)
			return null
		}
	} else {
		console.log(
			"Hey... Do not use this case (project ID is undefined or null)"
		)
		return null
	}
}

// TODO: How about warning when the token is not exist?
export const setTokenForAxiosAuth = (tokenType: TOKEN_TYPE) => {
	axios.defaults.headers.authorization = "Bearer " + selectedToken(tokenType)
}
