import { flow } from "mobx-state-tree"

const UpdateOrgCustomer = (self: any, update: any) => ({
	updateOrgCustomer: flow(function* (payload: any) {
		const actionName = "updateOrgCustomer"
		try {
			const response = yield update(payload)
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error)
			self.handleModelError({ actionName, error, open: false })
			return false
		}
	}),
})

export default UpdateOrgCustomer
