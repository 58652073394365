import { types } from "mobx-state-tree"
// model & view-model
import OrgClientListModel from "./org-clients.model"
import OrgClientsViewModel from "./org-clients.view-model"
import OrgClientsUiHelper from "./org-clients.ui-helper"
// common models
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
// apis
import * as api from "./org-clients.apis"

export const OrgSetupClientsStore = ({
	apiRead,
	apiUpdate,
	apiDelete,
	apiCreate,
	apiReadGroups,
	apiAssignToGroup,
}: api.OrgSetupClientsApiProps) =>
	types
		.compose(
			OrgClientListModel({
				apiRead,
				apiUpdate,
				apiDelete,
				apiCreate,
				apiReadGroups,
				apiAssignToGroup,
			}),
			OrgClientsViewModel,
			OrgClientsUiHelper
		)
		.named("OrgSetupClientsStore")

export const initialStore = {
	clientList: [],
	unAssinedGroupList: [],
	assinedGroupList: [],
	bulkClientFileReadStatus: ActionStatus.standby,
	// ui-helpers
	selectedClient: "",
	selectedItems: [],
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	//
	actionStatusUnAssignedGroup: ActionStatus.standby,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSetupClientsStoreInstance = OrgSetupClientsStore({
	apiRead: api.read,
	apiUpdate: api.updateClient,
	apiDelete: api.del,
	apiCreate: api.create,
	apiReadGroups: api.readGroups,
	apiAssignToGroup: api.assignToGroup,
}).create(initialStore)

export default OrgSetupClientsStoreInstance
