import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const ReadSignoffHistoryDetail = (self: any) => ({
	readSignoffHistoryDetail: flow(function* (
		fileId: string,
		menuId: DLProjSubMenus
	) {
		const actionName = "readSignoffHistoryDetail"

		const wpEndpoint = "/GetSignoffHistory_Workpaper_ExpandSignoffs"
		const pfEndpoint = "/GetSignoffHistory_PermanentFile_ExpandSignoffs"

		let numberId = parseInt(fileId.split("fileId")[1])
		let params = { WPRId: numberId }

		try {
			const endpoint =
				menuId === DLProjSubMenus.wp ? wpEndpoint : pfEndpoint
			const response = yield getRequest({
				url: endpoint,
				params,
				tokenType: TOKEN_TYPE.projToken,
			})

			// ConsoleLog("actionName, "__response", response)
			return response
		} catch (error) {
			// ConsoleLog("actionName, "__error", error)
			self.handleModelError({
				actionName,
				error,
				open: true,
			})

			return false
		}
	}),
})

export default ReadSignoffHistoryDetail
