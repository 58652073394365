import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { OrgSysSettingItemProps } from "../../../../../../../service-modules/sys-setting-module/sys-setting.data-model"

const EditOtherSettings = (self: any) => ({
	editOtherSettings({
		hasPBCUpload,
	}: {
		hasPBCUpload: OrgSysSettingItemProps
	}) {
		// 0.
		const actionName = "editOtherSettings"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const defaultPayload = [
			{
				ParameterName: hasPBCUpload.label,
				SettingID: hasPBCUpload.settingId,
				ParameterDisplay: hasPBCUpload.uiValue,
				ParameterValue: hasPBCUpload.value,
				ParameterValueUI: hasPBCUpload.uiValue,
			},
		]
		// ConsoleLog(payload)
		// return false
		self.updateEmailNotificationSetting(defaultPayload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					/**
					 * REFLECT to STORE
					 */
					self.setPBCUpload(hasPBCUpload)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to update the setting",
						open: true,
						autoHide: true,
						autoHideDuration: 1500,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage: "Fail to " + actionName,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default EditOtherSettings
