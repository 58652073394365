import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import {
	DLDialog,
	DLInputField,
	InputWithLabel,
	ConsoleLog,
} from "../../../../../components/basic-elements"

import styled from "styled-components"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function CreateOrgGroupCabinetDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "addCabinetInGroup"

	const i18n = store.i18n.globalI18n("CreateOrgGroupCabinetDialog")
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const handleAddCabinet = () => {
		orgStore.groups.addCabinetInGroup({
			title: inputs.name.value,
			parentId: orgStore.groups.getLastItemOfPath,
		})
	}

	const handleOpen = orgStore.groups.setOpenCreateCabinetDialog
	const open = orgStore.groups.openCreateCabinetDialog

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		handleAddCabinet,
		open
	)

	const isDuplicated = orgStore.groups.isDuplicatedNameInCabinet(
		inputs.name.value,
		orgStore.groups.getLastItemOfPath
	)
	const actionStatus = orgStore.groups.getActionStatus(actionName)
	ConsoleLog(["flatList", orgStore.groups.getLastItemOfPath])
	return (
		<DLDialog
			eleTestId="create-cabinet-in-org-group-dialog"
			isOpen={open}
			setIsOpen={handleOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.twAddCabinet || "Add Cabinet"}
			dialogContents={
				<AddCabinetDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
					isDuplicated={isDuplicated}
				/>
			}
			//
			cancelBtnText={i18n.twCancel || "Cancel"}
			// actionReady={isReady}
			actionStatus={actionStatus}
			actionReady={isReady && !isDuplicated}
			actionBtn={i18n.twCreate || "Create"}
			maxWidth="sm"
			fullWidth={true}
			// showSpinner={
			// 	projStore.wp.actionStatus === "LOADING" ? true : false
			// }
			useEnterKeyForSubmit
		/>
	)
})

const AddCabinetDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
		isDuplicated,
	}: {
		inputs: any
		handleOnChange: (props: any) => void
		i18n: DLI18nProps
		isDuplicated: boolean
	}) => {
		return (
			<StyledAddCabinetForm>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twCabinetName || "Cabinet Name"}
					>
						<DLInputField
							autoFocus
							eleTestId="cabinet-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							// warningMsg={inputs.name.error}
							warningMsg={
								isDuplicated
									? "The same name is already exist."
									: inputs.name.error
							}
							warningType={isDuplicated ? "orange" : undefined}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddCabinetForm>
		)
	}
)

const StyledAddCabinetForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
