import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	idToString,
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const RemoveEngType = (self: any) => ({
	removeEngType(engTypeId: string, userId: string) {
		// 0.
		const actionName = "removeEngType"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const payload = {
			ID: idToNumber(engTypeId, IdType.engType),
			UserID: idToNumber(userId, IdType.user),
		}
		// ConsoleLog(payload)
		self.deleteEngType(payload)
			.then((response: any) => {
				ConsoleLog([actionName, "__response ", response])
				if (response.data.Status === 1) {
					self.popEngType(engTypeId)
					// close the dialog
					self.setOpenDelete(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to remove the engagement type",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response (200 but fail)
					/**
					 * * error case of remove engType
					 * - case 2: ?
					 * - case 2: "You can't delete this engtype as it is being used by fewer projects"
					 */
					if (
						response.data.Message ===
						"You can't delete this Engagement Type as it is being used by fewer projects"
					) {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message:
								response.data.Message ||
								response.data.message ||
								`(${actionName}) Something went wrong... `,
							open: true,
						})
						self.setOpenDelete(false)
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message:
								response.data.Message ||
								response.data.message ||
								`(${actionName}) Something went wrong... `,
							open: true,
							// msgCode: "tsSnackbarError" + response.data.Status,
							// TODO: because this code cannot cover the case 2, comment this line temporarily
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemoveEngType
