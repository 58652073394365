import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import OrgGroupDetail from "./sub-components/OrgGroupDetail"
import { PageContainer } from "../../../components/app-frame-elements"
import OrgGroupTreeNav from "./sub-components/OrgGroupTreeNavController"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import ResizingTrigger from "../../../components/combined-elements/tree-nav/DLTreeNavResizingTrigger"

export default observer(function OrgGroups({
	contentsHeight,
}: {
	contentsHeight: any
}) {
	const orgStore = useOrgStore()

	//
	// const handleCtxMenuPoint = (e: any) => {
	// 	orgStore.groups.setClickPoint({
	// 		mouseX: e.clientX - 2,
	// 		mouseY: e.clientY - 4,
	// 	})
	// }
	//

	const [dragStart, setDragStart] = useState(false)
	const [movedX, setMovedX] = useState(0)
	const handleMouseDown = (e: any) => {
		setDragStart(true)
	}
	const handleMouseUp = (e: any) => {
		setDragStart(false)
	}
	const handleMouseMove = (e: any) => {
		dragStart && setMovedX(e.movementX)
	}
	useEffect(() => {
		dragStart && orgStore.groups.updateNavWidth(movedX)
	}, [dragStart, movedX])

	return (
		<PageContainer
			fullWidth
			fixedHeight
			pageTools={<div />}
			hasToolbar={false}
		>
			<StyledOrgGroups
				className="FR org-groups-root-container"
				onMouseUp={handleMouseUp}
				onMouseMove={handleMouseMove}
				style={{ height: contentsHeight - 48 }}
				// onContextMenu={handleCtxMenuPoint}
			>
				{/* <OrgGroupTreeNav /> */}
				<ResizingTrigger handleMouseDown={handleMouseDown} />
				<OrgGroupDetail />
			</StyledOrgGroups>
		</PageContainer>
	)
})

const StyledOrgGroups = styled.div``
