import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - confirm, loading, success, fail
 */
const DuplicateProjTemplateGroup = (self: any) => ({
	duplicateProjTemplateGroup(templateGroupId: string) {
		// 0.
		const actionName = "duplicateProjTemplateGroup"
		// 1.
		let proceed = window.confirm(
			"Do you really want to duplicate this template group? It will proceed asynchronously and will be notified via email after completion."
		)
		if (!proceed) {
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		const numId = idToNumber(templateGroupId, IdType.projTemplateGroup)
		if (typeof numId === "number") {
			self.requestDuplicateProjTemplateGroup(numId).then(
				(response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// ConsoleLog([actionName, "__response ", response])
						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
						})
					} else {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			)
		} else {
			alert("Cannot proceed because the props is invalid")
			ConsoleLog([actionName, numId])
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.standby,
			})
			return
		}
	},
})

export default DuplicateProjTemplateGroup
