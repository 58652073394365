import React from "react"
import styled from "styled-components"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { DLIconButton, DLButton } from "../../../../components/basic-elements"
import { mdiDotsHorizontal, mdiArrowLeft, mdiPlus, mdiPencil } from "@mdi/js"
import Icon from "@mdi/react"

export default function RoleDetailTabs({ data }: { data: any }) {
	const projStore = useProjStore()
	return (
		<StyledRoleDetailTabs className="vertical-tabs FC">
			<div
				className="back-to-list FR AC"
				onClick={() => projStore.roles.setSelectedRole("")}
			>
				<DLButton
					variant="text"
					eleTestId="back-to-list"
					startIcon={<Icon path={mdiArrowLeft} size={0.8} />}
				>
					Back
				</DLButton>
			</div>
			{data?.map((role: any) => {
				return (
					<div
						key={role.roleId}
						onClick={() =>
							projStore.roles.setSelectedRole(role.roleId)
						}
						className={`tab-item JSB ${projStore.roles
							.selectedRole === role.roleId && "selected-tab"}`}
					>
						{role.name}
						<DLIconButton
							eleClassName="context-menu"
							eleTestId={`${role.roleId}-context-menu-btn`}
						>
							<Icon path={mdiDotsHorizontal} size={0.6} />
						</DLIconButton>
					</div>
				)
			})}
			<div className="button-area FR JC">
				<DLIconButton
					variant="contained"
					eleTestId="add-role"
					tooltipText="Add Project Role"
				>
					<Icon path={mdiPlus} size={0.8} />
				</DLIconButton>
				<DLIconButton
					eleTestId="edit-role-set"
					tooltipText="Edit Project Role Set"
				>
					<Icon path={mdiPencil} size={0.8} />
				</DLIconButton>
			</div>
		</StyledRoleDetailTabs>
	)
}

const StyledRoleDetailTabs = styled.div`
	&.vertical-tabs {
		width: 12rem;
		height: 100vh;
		background-color: ${(props) => props.theme.secondary};
		.back-to-list {
			height: ${(props) => props.theme.shared.baseUnit};
			cursor: pointer;
		}
		.tab-item {
			display: flex;
			align-items: center;
			height: ${(props) => props.theme.shared.baseUnit};
			padding: 0 0.5rem 0 1rem;
			cursor: pointer;
			&.selected-tab {
				font-weight: 900;
				background-color: ${(props) => props.theme.shade10};
				color: ${(props) => props.theme.primaryMainDeep};
				border-right: 3px solid ${(props) => props.theme.primaryMainDeep};
			}
			.context-menu {
				transition: 0.4s
				z-index: 999;
				opacity: 0
			}
			:hover {
				.context-menu {
					opacity: 1
				}
			}
		}
		.button-area {
			margin-top: ${(props) => props.theme.shared.baseUnit};
		}
	}
`
