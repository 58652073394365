import { ConsoleLog } from "../../../components/basic-elements"

const SelectedItemsUiViews = (self: any) => ({
	get selectedFiles() {
		let fileOnlyList: string[] = []
		self.selectedItems.map((item: any) => {
			if (!item.isParent) {
				fileOnlyList.push(item.id)
			}
		})
		return fileOnlyList
	},
	get selectedFile() {
		const selectedItems = self.selectedItems
		// ConsoleLog("selectedItems", selectedItems)
		let fileId = ""
		if (selectedItems.length === 1) {
			const target = selectedItems[0]
			if (!target.isParent) {
				fileId = target.id
			} else if (target.isParent) {
				alert("(store-view: selectedFile) Selected item is not file")
			}
		} else if (selectedItems.length > 1) {
			alert("(store-view: selectedFile) Multiple items are selected")
		} else if (selectedItems.length <= 0) {
			// alert("(store-view: selectedFile) No selected items")
			ConsoleLog("(store-view: selectedFile) No selected items")
		}
		return fileId
	},
	get selectedItemsHasNoFolder() {
		const findFolder = self.selectedItems.findIndex(
			(item: any) => item.isParent
		)

		if (findFolder === -1) {
			return true
		} else {
			return false
		}
	},
	get selectedItemsHasNoFile() {
		const findFile = self.selectedItems.findIndex(
			(item: any) => !item.isParent
		)

		if (findFile === -1) {
			return true
		} else {
			return false
		}
	},
	get selectedFolder() {
		const selectedItems = self.selectedItems
		let folderId = ""
		if (selectedItems.length === 1) {
			const target = selectedItems[0]
			if (target.isParent) {
				// if (target.id.include(IdType.projTemplate)) {
				// 	folderId = target.id
				// }
				folderId = target.id
			} else if (!target.isParent) {
				ConsoleLog("Selected item is not folder")
			}
		} else if (selectedItems.length > 1) {
			const folders = selectedItems.filter((item: any) => item.isParent)
			if (folders.length > 0) {
				ConsoleLog(
					"Multiple items are selected. Return the first folder but please be careful."
				)
				return folders[0].id
			} else {
				ConsoleLog(
					"Multiple items are selected, but there are no folders"
				)
			}
		}
		return folderId
	},
	get countSelectedFolders() {
		return self.selectedItems.filter((item: any) => item.isParent).length
	},
	get countSelectedFiles() {
		return self.selectedItems.filter((item: any) => !item.isParent).length
	},
	get getSelectedItems() {
		return self.selectedItems
	},
	get getSelectedItemsCount() {
		return self.selectedItems.length
	},
	// get upwardItemOfSelection() {
	// 	let upwardItem: any = undefined
	// 	//
	// 	const selectedItems = self.selectedItems
	// 	const lastSelectedItem = selectedItems[selectedItems.length - 1]
	// 	const lastSelectedItemDetail = self.formattedFileById(
	// 		lastSelectedItem.id, dntFormat
	// 	)
	// 	//
	// 	const parent = lastSelectedItemDetail.parentId
	// 	const siblings = self.flatList.filter(
	// 		(item: any) => item.parentId === parent
	// 	)
	// 	const children = self.flatList.filter(
	// 		(item: any) => item.parentId === lastSelectedItem.id
	// 	)
	// 	//

	// 	if (lastSelectedItem.isParent) {
	// 		// if the item is folder
	// 		if (children.length !== 0) {
	// 			// if the folder has children
	// 		} else {
	// 			// if the folder is collpased, select next folder
	// 		}
	// 	} else {
	// 		// if the item is file
	// 	}

	// 	return upwardItem
	// },
	// get downwardItemOfSelection() {
	// 	let downwardItem: any = undefined
	// 	//
	// 	const selectedItems = self.selectedItems
	// 	const lastSelectedItem = selectedItems[selectedItems.length - 1]
	// 	const lastSelectedItemDetail = self.formattedFileById(
	// 		lastSelectedItem.id,
	// 		dntFormat
	// 	)
	// 	//
	// 	const parent = self.formattedFileById(
	// 		lastSelectedItemDetail.parentId,
	// 		dntFormat
	// 	)
	// 	const siblings = self.flatList.filter(
	// 		(item: any) => item.parentId === parent.id
	// 	)
	// 	const siblingsOfParent = self.flatList.filter(
	// 		(item: any) => item.parentId === parent.parentId
	// 	)

	// 	const children = self.flatList.filter(
	// 		(item: any) => item.parentId === lastSelectedItem.id
	// 	)
	// 	//
	// 	// TODO: is sorting required..?
	// 	if (lastSelectedItem.isParent) {
	// 		// if the item is folder
	// 		if (children.length !== 0) {
	// 			// if the folder has children
	// 			downwardItem = children[0]
	// 		} else {
	// 			if (siblings.length > 1) {
	// 				// if the folder has no children and has some siblings
	// 				downwardItem = siblings[0]
	// 			} else if (siblings.length === 1) {
	// 				// if the folder has no children and no siblings
	// 			}
	// 		}
	// 	} else {
	// 		// if the item is file
	// 	}
	// 	return downwardItem
	// },
	getSiblings(parentId: string) {
		const siblings = self.flatList.filter(
			(item: any) => item.parentId === parentId
		)
		return siblings
	},
	getHigherSiblings(parentId: string, itemId: string) {
		const siblings = self.flatList.filter(
			(item: any) => item.parentId === parentId
		)
		const itemIndex = siblings.findIndex((item: any) => item.id === itemId)
		const higherSiblings = siblings.slice(0, itemIndex)
		// ConsoleLog("getHigherSiblings", higherSiblings)
		return higherSiblings
	},
	getLowerSiblings(parentId: string, itemId: string) {
		const siblings = self.flatList.filter(
			(item: any) => item.parentId === parentId
		)
		const itemIndex = siblings.findIndex((item: any) => item.id === itemId)
		const lowerSiblings = siblings.slice(itemIndex + 1, siblings.length)
		// ConsoleLog("getLowerSiblings", lowerSiblings)
		return lowerSiblings
	},
	isLastSelectedItem(itemId: string) {
		const selectedItems = self.selectedItems
		const lastSelectedItem = selectedItems[selectedItems.length - 1]
		if (lastSelectedItem !== undefined) {
			if (lastSelectedItem.id === itemId) {
				return true
			} else {
				return false
			}
		} else {
			return false
		}
	},
})

export default SelectedItemsUiViews
