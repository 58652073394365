import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

const UpdateProjTemplateGroup = (
	self: any,
	updateTemplateGroup: OrgProjTemplatesApiProps["apiUpdateTemplateGroup"]
) => ({
	updateProjTemplateGroup: flow(function* (
		templateGroupId: string,
		title: string
	) {
		const actionName = "updateProjTemplateGroup"
		// 1.
		if (
			templateGroupId === undefined ||
			templateGroupId === "" ||
			title === undefined ||
			title === ""
		) {
			alert(
				`(${actionName}) Undefined props exist. ${templateGroupId} | ${title}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(templateGroupId, IdType.projTemplateGroup)
		// 3.
		if (typeof numberId === "number") {
			try {
				let payload = {
					TempFoldID: numberId, // TODO: need to udpate
					TempFoldName: title,
					// UserID:
				}
				const response = yield updateTemplateGroup(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid format props exist. ${numberId} | ${title}`
			)
		}
	}),
})

export default UpdateProjTemplateGroup
