import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

const ReadLibFilesInProj = (self: any) => ({
	readLibFilesInProj: flow(function* (folderId: string) {
		const actionName = "readLibFilesInProj"
		// 1.
		if (folderId === undefined || folderId === "") {
			alert(`(${actionName}) Undefined prop exist. ${folderId}`)
			return
		}
		//
		//
		// 2.

		const numberId = idToNumber(folderId, IdType.folder)
		//
		//
		// 3.
		if (typeof numberId === "number") {
			try {
				const endpoint = "/GetWPRTemplateFileList"
				const params = {
					ParentTempID: numberId,
				}
				const response = yield getRequest({
					url: endpoint,
					params,
					tokenType: TOKEN_TYPE.projToken,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) Invalid prop type exist. ${numberId}`)
		}
	}),
})

export default ReadLibFilesInProj
