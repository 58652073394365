import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import ProjectDashboard from "./ProjectDashboard"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function ProjectDashboardController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.dashboard_default

	const menuAccess = projStore.checkin.checkAccess(menuId)
	const needRefresh = projStore.projDashboard.needRefresh

	const actionName = "getDashboardDetails"

	useEffect(() => {
		const periodEndDate = projStore.projInfo.projInfo.periodEndDate
		const projectId = projStore.projInfo.projInfo.projectId

		if (menuAccess && needRefresh) {
			projStore.projDashboard.getDashboardDetails(
				projectId,
				periodEndDate
			)
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<ProjectDashboard
					partialStore={projStore.projDashboard}
					contentsHeight={store.ui.contentsAreaHeight - 48}
					fetchingStatus={projStore.projDashboard.getActionStatus(
						actionName
					)}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
