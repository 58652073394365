import { flow } from "mobx-state-tree"

const MdiscardCheckoutAllFiles = (self: any, delSingleNotification: any) => ({
	mDiscardCheckoutAllFiles: flow(function* (url) {
		const actionName = "mDiscardCheckoutAllFiles"
		try {
			const response = yield delSingleNotification(url)
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error, error.response)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default MdiscardCheckoutAllFiles
