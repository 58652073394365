import { types } from "mobx-state-tree"
import {
	MCheckinFile,
	MCheckoutFile,
	MDiscardCheckout,
	ReadFileStatus,
	MRequestCheckinFile,
} from "./model-actions"
import { FileEditorApiProps } from "./file-editor.api-props"
import ReadOrgInfo from "../../../organization-side/org-access-control/org-checkin/store/model-actions/read-org-info"
import ReadProjInfo from "../../../project-side/project-information/store/model-actions/read-proj-info"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// import ReadProjRoleSet from "../../../project-side/project-setup-roles/store/model-actions/read-proj-role-set"
import ReadProjAssignedRole from "../../../project-side/proj-access-control/store/model-actions/read-proj-assigned-role"
import RequestProjToken from "../../../project-side/proj-access-control/store/model-actions/request-proj-token"

const FileEditorModel = ({
	apiDiscardFileCheckout,
	apiRequestCheckinFile,
	apiReadOrgInfo,
	apiReadProjInfo,
	apiRequestToken,
}: FileEditorApiProps) =>
	types
		.model({})
		.actions((self) => ({}))
		.actions((self) => ReadOrgInfo(self, apiReadOrgInfo))
		.actions((self) => ReadProjInfo(self, apiReadProjInfo))
		.actions((self) => RequestProjToken(self, apiRequestToken))
		.actions(ReadProjAssignedRole)
		// .actions(ReadProjRoleSet)
		.actions(ReadFileStatus)
		.actions(MCheckinFile)
		.actions(MCheckoutFile)
		.actions((self) => MDiscardCheckout(self, apiDiscardFileCheckout))
		.actions((self) => MRequestCheckinFile(self, apiRequestCheckinFile))
		.actions(CommonModelActions)

export default FileEditorModel
