import React from "react"
import InputLabel from "@material-ui/core/InputLabel"
import MenuItem from "@material-ui/core/MenuItem"
import FormHelperText from "@material-ui/core/FormHelperText"
import FormControl from "@material-ui/core/FormControl"
import Select from "@material-ui/core/Select"
import styled from "styled-components"

interface SingleSelectProps {
	eleName?: string
	eleValue?: any
	//
	eleRequired?: boolean
	// if false, this component does not show label even if it has label
	withLabel?: boolean
	label?: string
	helperText?: string
	placeholder?: string
	//
	optionList: {
		value: string | number // TODO: Number..??
		label?: string
		name?: string
		disabled?: boolean
		testId?: string
	}[]
	//{ value: string; label: string }[] | { value: string; name: string }[]
	eleFullWidth?: boolean
	eleOnChange?: React.EventHandler<any>
	eleClassName?: string
	//
	thinType?: boolean
	eleWidth?: string
	eleTestId: string
	eleDisabled?: boolean // TODO: Why disabled instead of eleReadonly?
	minHeight?: number | "auto"
}

/**
 * 1. How to set the <strong>default value</strong>
 * 	- The default value of the state will be default value
 * 1. How to use <strong>placeholder</strong>
 * 	- Empty the default value (on the useState part) and set the placeholder property.
 * 1. about Option List format
 * 	- Basically, it has format like below
 * 		- `{ value: string; label: string; }[]`
 * 		- `{ value: string; name: string; }[]`
 * 	- But in some cases, DLSingleSelect component is using stored value directly. That's why this component allows `any[]` type for the optionList. This part will be updated after the clear other parts.
 */

export default function DLSingleSelect({
	eleName = undefined,
	eleValue = undefined,
	//
	eleRequired = undefined,
	withLabel = true,
	label = "",
	helperText = "",
	placeholder = "",
	//
	optionList = [{ value: "", name: "" }],
	eleFullWidth = undefined,
	eleOnChange = undefined,
	eleClassName = undefined,
	//
	thinType = undefined,
	eleWidth = undefined,
	eleTestId = "testId-DLSingleSelect",
	eleDisabled = false,
	minHeight = 48,
}: SingleSelectProps) {
	return (
		<StyledForm
			autoComplete="off"
			className={eleClassName}
			style={{ minHeight }}
		>
			<FormControl
				className={thinType ? "form-control thin-type" : "form-control"}
				fullWidth={eleFullWidth}

				// style={{ width: eleWidth }}
			>
				{withLabel && (
					<InputLabel htmlFor="label" required={eleRequired}>
						{label}
					</InputLabel>
				)}
				<Select
					className="select-element"
					name={eleName}
					value={
						eleValue !== ""
							? eleValue
							: "system-default-placeholder"
					}
					data-testid={eleTestId}
					// displayEmpty
					onChange={eleOnChange}
					disabled={eleDisabled}
					MenuProps={{
						className: "selection-list",
					}}
				>
					<MenuItem value="system-default-placeholder" disabled>
						<div className="placeholder">{placeholder}</div>
					</MenuItem>
					{optionList.map((option: any) => {
						const { value, disabled, label, name, testId } = option
						return (
							<MenuItem
								key={value}
								value={value}
								disabled={disabled}
								data-testid={
									testId
										? eleTestId + "_" + testId
										: eleTestId +
										  "_" +
										  (name ? name : label)
								}
								// WARNING: Watchout to use ()
								// NOTE: @Noah: some use name and some use label... seems to need update
							>
								{name ? name : label}
							</MenuItem>
						)
					})}
				</Select>
				<FormHelperText>{helperText}</FormHelperText>
			</FormControl>
		</StyledForm>
	)
}

const StyledForm = styled.form`
	/* margin-right: var(--spacing-lg); */
	/* for required mark */

	span {
		color: ${(props) => props.theme.warning};
		font-weight: 900;
	}

	& .form-control {
		min-width: 8rem;
		height: 2rem;
		border-bottom-color: ${(props) => props.theme.shade20};
		&.thin-type {
		}

		.select-element {
			background-color: ${(props) => props.theme.background};
			color: ${(props) => props.theme.textMediumEmphasis};
			.placeholder {
				color: ${(props) => props.theme.shade40};
			}
			/* margin-top: 0; */
			&.MuiInput-underline:hover {
				border-bottom: 1px solid
					${(props) => props.theme.textHighEmphasis};
			}
			&.MuiInput-underline:before {
				border-bottom: 1px solid
					${(props) => props.theme.textMediumEmphasis};
			}
			&.MuiInput-underline:after {
				border-bottom: 2px solid ${(props) => props.theme.emphasis};
			}

			& > div {
				font-size: var(--text-lg);
			}
			& svg {
				fill: ${(props) => props.theme.shade40};
			}
		}
	}
`
