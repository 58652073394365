import { types, IAnyModelType, applySnapshot } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// for 2
import { OrgListItem } from "../../../../lobby-side/organization-list/store/data-models/org-list.data-model"
// 3
import { OrgSysSettingItemModel } from "../../../../../service-modules/sys-setting-module/sys-setting.data-model"
// sub actions
import GetOrgToken from "./view-model-actions/get-org-token"
import GetOrgInfo from "./view-model-actions/get-org-info"
import GetSysInfo from "./view-model-actions/get-sys-info"
// import GetAssignedRoleFromGoogleSheet from "./view-model-actions/get-org-assigned-role-from-g-sheet"
import GetAccessibleMenus from "./view-model-actions/get-org-accessible-menus"
// data models
import Responses from "../../../../../common-models/responses"
import {
	MenuForUi,
	CombinedRoleDefault,
	RoleSet2,
} from "../../../../../service-modules/role-module/roles.data-model"

import CheckingPermission from "../../../../../common-models/permission"
import { initialStore } from "./org-checkin.provider"
import GetPermissionSet from "./view-model-actions/get-permission-set"
import GetRoleSet from "./view-model-actions/get-role-set"

/**
 * organization checkin process
 * - 1. get token
 * - 2. get default info
 * - 3. get system (date & time) setting
 * - 4. get user's permission by role
 * - 5. set accessible menus
 * - 6. checkin
 */

const OrgCheckinViewModel = types
	.model({
		orgUserId: "",
		// 1. token be saved on the local storage
		// 2. org info TODO: remove the previous one from the default store
		orgInfo: OrgListItem,
		// 3. org sys - date and time --> moved to global store
		// 4. user permission
		roleSet: RoleSet2,
		assignedRole: CombinedRoleDefault, // WARNING: be careful. this part should be same on org and proj
		// 5. accessible menu list (by tree structure) NOTE: Do not use ui-helper
		// appMenus: types.array(
		// 	types.model({
		// 		id: "",
		// 		menuType: "",
		// 		name: "",
		// 		parentId: types.union(types.null, types.string),
		// 		sector: "",
		// 		index: 0,
		// 		urlEdge: "",
		// 		status: "",
		// 		actions: types.array(
		// 			types.model({
		// 				name: "",
		// 				description: "",
		// 			})
		// 		),
		// 	})
		// ),
		accessibleTreeMenus: types.array(MenuForUi),
		accessibleFlatMenus: types.array(MenuForUi),
		// 6. NOTE: You can find org checkin process state in the ui-helper
		orgCheckin: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		setRoleSet(roleSet: any) {
			self.roleSet = roleSet
		},
		// setAppMenus(appMenus: any) {
		// 	self.appMenus = appMenus
		// },
		setOrgUserId(id: string) {
			self.orgUserId = id
		},
		setOrgInfo(orgInfo: any) {
			self.orgInfo = orgInfo
		},
		setAssignedRole(roleInfo: any) {
			self.assignedRole = roleInfo
		},
		setAccessibleTreeMenus(menus: any) {
			self.accessibleTreeMenus.length = 0
			self.accessibleTreeMenus = menus
		},
		setAccessibleFlatMenus(menus: any) {
			self.accessibleFlatMenus.length = 0
			self.accessibleFlatMenus = menus
		},
		setOrgCheckin(state: ActionStatus) {
			self.orgCheckin = state
		},
	}))
	.views((self) => ({
		get accessibleTreeMenusForAdmin() {
			return self.accessibleTreeMenus.filter((item) => item.isAdminMenu)
		},
		get accessibleTreeMenusForNormal() {
			return self.accessibleTreeMenus.filter((item) => !item.isAdminMenu)
		},
	}))
	// AGER(Add, Get, Edit, Remove) Actions
	.actions(GetOrgToken)
	.actions(GetOrgInfo)
	.actions(GetSysInfo)
	// .actions(GetAssignedRoleFromGoogleSheet)
	.actions(GetAccessibleMenus)
	.actions(GetRoleSet)
	.actions(GetPermissionSet)
	//views
	.views(CheckingPermission)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgCheckinViewModel
