import { flow } from "mobx-state-tree"

const RequestDownloadRationales = (self: any, download: any) => ({
	requestDownloadRationales: flow(function* () {
		const actionName = "requestDownloadRationales"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		try {
			const response = yield download()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestDownloadRationales
