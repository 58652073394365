import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { putRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const UpdateComment = (self: any) => ({
	updateComment: flow(function* (
		payload: {
			Comment: string
			// ModifiedBy: number
			Priority: string
			ReviewCommentsId: number
			UserId: string // assigned user Ids
		},
		menuId: DLProjSubMenus
	) {
		const actionName = "updateComment"
		let endpoint = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/EditReviewComments"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/EditPermanentFileReviewComments"
		}
		try {
			const response = yield putRequest({
				url: endpoint,
				params: payload,
				tokenType: TOKEN_TYPE.projToken,
			})
			ConsoleLog([actionName, "__response ", response])
			return response
		} catch (error) {
			ConsoleLog([actionName, "__error ", error])
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default UpdateComment
