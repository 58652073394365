import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../../../../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"

const ReadOnlineProjects = (
	self: any,
	read: OrgProjectApiProps["apiRead"]
) => ({
	readOnlineProjects: flow(function* ({
		year,
	}: {
		year: "All Years" | string
	}) {
		const actionName = "readOnlineProjects"

		const projTypeId = 1 // 1 = normal project
		console.log(year, "year")
		// + to check the 'Locked', use the 'isLocked'
		const numberYear = year === "All Years" ? 0 : parseInt(year)
		if (typeof projTypeId === "number" && typeof numberYear === "number") {
			try {
				const payload = {
					FinancialYear: numberYear,
					PageIndex: 1,
					PageSize: 10000, // TODO: PageSize need to update (! Check the assigned projects model comment)
					ProjectType: projTypeId,
					PTMAssignedOnly: true,
				}
				const response = yield read(payload)

				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(
				`(${actionName}) There are invalid props: ${projTypeId} / ${year}`
			)
		}
	}),
})

export default ReadOnlineProjects
