import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import ArchPolicyCondition from "./ArchPolicyCondition"
import {
	DLButton,
	DLContextMenu,
	DLIconButton,
} from "../../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { Icon } from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"

export default observer(function ArchivePolicyDetail({
	i18n,
	permission,
	selectedPolicyDetails,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
	selectedPolicyDetails: any
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "getArchivePolicies"

	const [clickPoint, setClickPoint] = useState({
		mouseX: null,
		mouseY: null,
	})

	const handleClickPoint = (e: any) => {
		e.preventDefault()
		setClickPoint({
			mouseX: e.clientX,
			mouseY: e.clientY,
		})
	}

	const [policyConditions, setPolicyConditions] = useState([])

	useEffect(() => {
		setPolicyConditions(JSON.parse(JSON.stringify(selectedPolicyDetails)))
	}, [orgStore.archPolicy.selectedPolicy, selectedPolicyDetails])

	console.log(
		policyConditions.filter((policy: any) => policy.isMandatory),
		"selectedPolicyDetails"
	)

	const setMandatory = (id: string, request: boolean) => {
		const data = JSON.parse(JSON.stringify(policyConditions))
		const targetIndex = data.findIndex((item: any) => item.id === id)
		data[targetIndex].isMandatory = request
		setPolicyConditions(data)
	}

	const cancelChanges = () => {
		setPolicyConditions(JSON.parse(JSON.stringify(selectedPolicyDetails)))
	}

	const mandatories = policyConditions.filter(
		(policy: any) => policy.isMandatory
	)
	const optionals = policyConditions.filter(
		(policy: any) => !policy.isMandatory
	)
	const policyInfo = orgStore.archPolicy.selectedPolicyInfo

	//
	const areaWidth =
		store.ui.contentsAreaWidth - orgStore.archPolicy.viewNavWidth - 48
	//
	const contentsHeight = store.ui.contentsAreaHeight
	const updateArchivePreCondition = () => {
		const policyId = orgStore.archPolicy.selectedPolicy
		orgStore.archPolicy.moveArchiveConditions(policyId, policyConditions)
	}

	const resetPreConditions = () => {
		const policyId = orgStore.archPolicy.selectedPolicy
		orgStore.archPolicy.resetPolicyToDefault(policyId)
		let proceed = window.confirm("Do you want to reset?")
		if (proceed) {
			orgStore.archPolicy.resetArchivePolicy(policyId)
		} else {
			return
		}
	}
	let mandatoriesAreSame = true
	if (
		JSON.stringify(
			orgStore.archPolicy.viewMandatoryConditions.sort(
				(a: any, b: any) => a.id - b.id
			)
		) !== JSON.stringify(mandatories.sort((a: any, b: any) => a.id - b.id))
	) {
		mandatoriesAreSame = false
	}
	return (
		<StyledArchPolicyDetail style={{ width: areaWidth }}>
			{orgStore.archPolicy.selectedPolicy ? (
				<div>
					<div className="policy-title heading3 FR JSB AC">
						{policyInfo.title}{" "}
						{permission.update && (
							<div className="FR JSB">
								<DLButton
									clickHandler={() => cancelChanges()}
									eleTestId="cancel-changes-btn"
									disabled={mandatoriesAreSame}
								>
									{i18n.twCancel || "Cancel"}
								</DLButton>
								{/*<DLButton
									eleClassName="reset-btn"
									clickHandler={() =>
										resetPreConditions()
									}
									eleTestId="policy-reset-btn"
									disabled={mandatoriesAreSame}
								>
									{i18n.twReset || "Reset"}
								</DLButton>*/}
								<DLButton
									clickHandler={() =>
										updateArchivePreCondition()
									}
									eleTestId="policy-update-btn"
									color={
										mandatoriesAreSame
											? "default"
											: "primary"
									}
									disabled={mandatoriesAreSame}
								>
									{i18n.twUpdate || "Update"}
								</DLButton>
								<DLIconButton
									eleTestId="reset-ctx-btn"
									clickHandler={handleClickPoint}
								>
									<Icon path={mdiDotsHorizontal} size={0.8} />
								</DLIconButton>
							</div>
						)}
					</div>
					{!mandatoriesAreSame && (
						<div
							className="request-save-msg"
							data-testid="request-save-msg"
						>
							Please click 'Update' to save
						</div>
					)}
					<div
						className="item-list"
						style={{ height: contentsHeight - 100 }}
					>
						<div className="mandatory-items">
							<div className="heading4">
								{i18n.twRequiredConditions} (
								{mandatories.length})
							</div>
							{mandatories.map((item: any, i: number) => {
								return (
									<div key={i}>
										<ArchPolicyCondition
											data={item}
											i18n={i18n}
											permission={permission}
											setMandatory={setMandatory}
										/>
									</div>
								)
							})}
						</div>

						<div className="optional-items">
							<div className="heading4">
								{i18n.twOptionalConditions} ({optionals.length})
							</div>
							{optionals.map((item: any, i: number) => {
								return (
									<div key={i}>
										<ArchPolicyCondition
											data={item}
											i18n={i18n}
											permission={permission}
											setMandatory={setMandatory}
										/>
									</div>
								)
							})}
						</div>
					</div>
					{clickPoint.mouseX && (
						<ResetCtxMenu
							clickPoint={clickPoint}
							handleClose={() =>
								setClickPoint({
									mouseX: null,
									mouseY: null,
								})
							}
							handleReset={() => resetPreConditions()}
						/>
					)}
				</div>
			) : (
				<div> Please select a policy</div>
			)}
		</StyledArchPolicyDetail>
	)
})

const ResetCtxMenu = observer(
	({
		clickPoint,
		handleClose,
		handleReset,
	}: {
		clickPoint: any
		handleClose: any
		handleReset: any
	}) => {
		const menuOptions = [
			{
				label: "Reset with default conditions",
				value: "ctx-reset",
				clickEvent: handleReset,
			},
		]
		return (
			<DLContextMenu
				eleId="reset-ctx-menu"
				clickPoint={clickPoint}
				handleClose={handleClose}
				menuOptions={menuOptions}
			/>
		)
	}
)

const StyledArchPolicyDetail = styled.div`
	overflow: hidden;
	padding-bottom: 3rem;
	.policy-title {
		font-weight: 700;
		margin: 1rem 0;
	}
	.request-save-msg {
		margin-bottom: 0.5rem;
		color: ${(props) => props.theme.themeOrange};
	}
	.item-list {
		overflow-y: scroll;
		.mandatory-items {
			margin-bottom: 2rem;
		}
	}

	.heading4 {
		margin-bottom: 4px;
	}
	.reset-btn {
		margin-right: 8px;
	}
`
