import { flow } from "mobx-state-tree"
import { AmArchivedApiProps } from "../am-archived-projects.apis"

const DeleteAmArchivedProject = (
	self: any,
	del: AmArchivedApiProps["apiDelete"]
) => ({
	deleteAmArchivedProject: flow(function*(payload) {
		const actionName = "deleteAmArchivedProject"
		try {
			const response = yield del(payload)
			// ConsoleLog(
			// 	"deleteAmArchivedProject",
			// 	response
			// )
			return response
		} catch (error) {
			// ConsoleLog("deleteAmArchivedProject error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteAmArchivedProject
