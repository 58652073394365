import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const GetAllFiles = (self: any) => ({
	getAllFiles(projId: string, menuId: string) {
		// 0. set actionName
		const actionName = "getAllFiles"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call

		const projNumId = idToNumber(projId, IdType.project)
		if (typeof projNumId === "string") {
			ConsoleLog("Invalid project Id..." + projId)
			return
		}
		ConsoleLog("getAllFiles, projNumId:" + projNumId)

		self.readAllFiles(projNumId, menuId)
			.then((response: any) => {
				ConsoleLog([actionName, response.data])
				if (response.status === 200 && response.data.Status === 1) {
					// if success
					// 1) reset the stored list

					// when success
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAllFiles
