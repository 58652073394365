import React from "react"
import Paper from "@material-ui/core/Paper"
import Breadcrumbs from "@material-ui/core/Breadcrumbs"
import { Link } from "react-router-dom"
import Icon from "@mdi/react"
import { mdiChevronRight } from "@mdi/js"
import styled from "styled-components"

interface DLBreadcrumbsProps {
	/** { url: string; title: string }[] */
	items: { url: string; title: string }[]
	currentLocation: string
}

/**
 * This is for the storybook documentation
 *
 * This component use `Link` of the `react-router-dom` to move
 */

export default function DLBreadCrumbsWithUrl({
	items = [{ url: "", title: "" }],
	currentLocation = "",
}: DLBreadcrumbsProps) {
	return (
		<StyledBreadcrumbs>
			<Paper elevation={0}>
				<Breadcrumbs
					separator={<Icon path={mdiChevronRight} size={1} />}
					aria-label="breadcrumb"
				>
					{items.map((item: any) => (
						<Link to={item.url} key={item.url}>
							{item.title}
						</Link>
					))}
					<div className="current-item">{currentLocation}</div>
				</Breadcrumbs>
			</Paper>
		</StyledBreadcrumbs>
	)
}

const StyledBreadcrumbs = styled.div`
	.current-item {
		color: ${(props) => props.theme.primaryMainDeep};
	}
`
