import { types } from "mobx-state-tree"
// model & view-model
import ArchiveMgmtArchivedProjectsModel from "./am-unarchived-projects.model"
import ArchiveMgmtArchivedProjectsViewModel from "./am-unarchived-projects.view-model"
import ArchiveMgmtUnarchivedProjectsUiHelper from "./am-unarchived-projects.ui-helper"
// common models
// apis
import * as api from "./am-unarchived-projects.apis"
import * as orgProjApis from "../../../../../service-modules/project-module/org-project-shared-apis"

import { AmUnarchivedApiProps } from "./am-unarchived-projects.apis"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export const initialStore = {
    storeName: DLOrgSubMenus.unarchived_mgmt,
    // ui-helpers
    selectedProj: "",
    selectedCabinetId: "",
    clickPoint: {
        mouseX: null,
        mouseY: null,
    },
    projectDetailsDialogOpen: false,
    openDeleteProjDialog: false,
    // common parts
    responses: {},
    responseSnackbar: {
        message: "",
    },
}

export const AmUnArchivedProjectsStore = ({
    apiRead,
    apiDelete,
}: AmUnarchivedApiProps) =>
    types
        .compose(
            ArchiveMgmtArchivedProjectsModel({
                apiRead,
                apiDelete,
            }),
            ArchiveMgmtArchivedProjectsViewModel,
            ArchiveMgmtUnarchivedProjectsUiHelper
        )
        .named("AmUnArchivedProjectsStore")

const AmUnArchivedProjectsStoreInstance = AmUnArchivedProjectsStore({
    apiRead: api.read,
    apiDelete: orgProjApis.deleteProject,
}).create(initialStore)

export default AmUnArchivedProjectsStoreInstance
