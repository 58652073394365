import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../components/basic-elements"
import { getRequest } from "../../../library/api-requests"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"

const ReadOrgSysSettings = (self: any) => ({
	readOrgSysSettings: flow(function* () {
		try {
			const endpoint = "/GetCustomerSysSettings"
			const response = yield getRequest({
				url: endpoint,
				params: {},
				tokenType: TOKEN_TYPE.orgToken,
			})
			ConsoleLog(["readOrgSysSettings response", response])
			return response
		} catch (error) {
			ConsoleLog(["readOrgSysSettings error", error])
			return error
		}
	}),
})

export default ReadOrgSysSettings
