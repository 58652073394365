import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLInputField,
	DLSingleSelect,
} from "../../../../../components/basic-elements"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { GetFileInfoApiMenu } from "../../../../../service-modules/file-module/apis/file-system-file-apis"

export default observer(function FileMenuSearchbar({
	partialStore,
}: {
	partialStore: any
}) {
	const projStore = useProjStore()

	const [queryText, setQueryText] = useState("")

	const handleSelect = (type: "file" | "folder") => {
		partialStore.setSearchType(type)
		partialStore.resetSearchResults()
		partialStore.setSearchQuery("")
		setQueryText("")
	}

	useEffect(() => {
		partialStore.resetSelectedItems()
	}, [])

	const handleSearchQuery = (e: any) => {
		setQueryText(e.target.value)
	}

	const searchQuery = () => {
		partialStore.setSearchQuery(queryText)

		const projectId = projStore.checkin.projId
		const financialYear = projStore.projInfo.projInfo.financialYear
		if (partialStore.searchQuery === "") {
			alert("Please enter search text")
		} else {
			partialStore.searchFileOrFolder({
				projectId,
				type: partialStore.searchType,
				searchText: partialStore.searchQuery,
				financialYear,
				fileType:
					partialStore.storeName === DLProjSubMenus.wp
						? GetFileInfoApiMenu.wp
						: GetFileInfoApiMenu.pf,
			})
		}
	}

	const handleClear = () => {
		partialStore.resetSearchResults()
		partialStore.setSearchQuery("")
		setQueryText("")
	}

	return (
		<StyledFileMenuSearchbar
			className="FR AC"
			data-testid="file-menu-searchbar"
		>
			<>
				<DLSingleSelect
					eleValue={partialStore.searchType}
					eleOnChange={(e) => handleSelect(e.target.value)}
					optionList={[
						{ name: "File", value: "file" },
						{ name: "Folder", value: "folder" },
					]}
					eleTestId="search-type"
					eleClassName="search-type"
				/>
				<DLInputField
					eleTestId="search-input"
					elePlaceholder="Search..."
					eleValue={queryText}
					eleHandleChange={handleSearchQuery}
				/>
				<DLButton
					eleTestId="search-btn"
					size="thin"
					color="primary"
					clickHandler={searchQuery}
					disabled={
						// partialStore.searchQuery === "" || queryText === ""
						queryText === ""
					}
				>
					Search
				</DLButton>
				<DLButton
					eleTestId="clear-search-btn"
					size="thin"
					// color="primary"
					clickHandler={handleClear}
				>
					Clear
				</DLButton>
			</>
		</StyledFileMenuSearchbar>
	)
})

const StyledFileMenuSearchbar = styled.div`
	width: 100%;
	height: ${(props) => props.theme.shared.baseUnit};
	z-index: 2;
	overflow: hidden;

	padding: 0 2rem;
	transition: 0.3s;
	/* background-color: ${(props) => props.theme.secondary}; */
	/* TODO: 'secondary' color is the final purpose. Do not use secondaryDeep. It just for temporary checking the area  */
	background-color: ${(props) => props.theme.secondaryDeep};

	.select-element {
		margin-top: 9px;
	}
`
