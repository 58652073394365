import React from "react"
import { Route } from "react-router-dom"
import PageContainer from "../../../components/app-frame-elements/sub-components/PageContainer"
import Budget from "./Budget"
import Schedule from "./Schedule"

export default function ScheduleAndBudget() {
	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			<Route
				exact
				path="/organization/schedule-and-budget"
				component={Schedule}
			/>
			<Route
				exact
				path="/organization/schedule-and-budget/schedule"
				component={Schedule}
			/>
			<Route
				exact
				path="/organization/schedule-and-budget/budget"
				component={Budget}
			/>
		</PageContainer>
	)
}
