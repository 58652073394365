import { ConsoleLog } from "../../components/basic-elements"
import {
	findProjRoleInfo,
	findProjRoleNewId,
	RolePropTypes,
} from "../../temporary-data/project-side/find-proj-role"

export const orgAcCtrlSet = "aul-default-org-acCtrlSet-2021-01"
export const projAcCtrlSet = "aul-default-proj-acCtrlSet-2021-01"

export const roleIdList = [
	// organization side
	{ short: "id_aa", id: "aul-org-2021-01-aa", acCtrlSetId: orgAcCtrlSet },
	{ short: "id_sa", id: "aul-org-2021-01-sa", acCtrlSetId: orgAcCtrlSet },
	{ short: "id_ga", id: "aul-org-2021-01-ga", acCtrlSetId: orgAcCtrlSet },
	{ short: "id_ua", id: "aul-org-2021-01-ua", acCtrlSetId: orgAcCtrlSet },
	{ short: "id_ex", id: "aul-org-2021-01-ex", acCtrlSetId: orgAcCtrlSet },
	// project side
	{ short: "id_paa", id: "aul-proj-2021-01-paa", acCtrlSetId: projAcCtrlSet },
	{ short: "id_pa", id: "aul-proj-2021-01-pa", acCtrlSetId: projAcCtrlSet },
	{ short: "id_pu", id: "aul-proj-2021-01-pu", acCtrlSetId: projAcCtrlSet },
	{ short: "id_pr", id: "aul-proj-2021-01-pr", acCtrlSetId: projAcCtrlSet },
	{ short: "id_oa", id: "aul-proj-2021-01-oa", acCtrlSetId: projAcCtrlSet },
	{ short: "id_pxp", id: "aul-proj-2021-01-pxp", acCtrlSetId: projAcCtrlSet },
	{ short: "id_pxr", id: "aul-proj-2021-01-pxr", acCtrlSetId: projAcCtrlSet },
]

export const convertOrgRole = (
	accessType: "Super Admin" | "Group Admin" | "User Access" | "External",
	isArchiveManager: boolean
) => {
	let orgRoleId = ""
	if (accessType === "Super Admin" && isArchiveManager) {
		orgRoleId = "aul-org-2021-01-aa"
	}
	if (accessType === "Super Admin" && !isArchiveManager) {
		orgRoleId = "aul-org-2021-01-sa"
	}
	if (accessType === "Group Admin") {
		orgRoleId = "aul-org-2021-01-ga"
	}
	if (accessType === "User Access") {
		orgRoleId = "aul-org-2021-01-ua"
	}
	if (accessType === "External") {
		orgRoleId = "aul-org-2021-01-ex"
	}
	return orgRoleId
}

// NOTE: for Project Side: temporary-data > project-side > find-proj-role.ts
export const convertProjRole = (oldRoleId: number) => {
	let roleId: undefined | string = ""
	let roleInfo: RolePropTypes = {
		oldNumId: 999,
		roleId: "",
		name: "",
		shortName: "",
		color: "",
	}
	if (oldRoleId !== 0) {
		roleId = findProjRoleNewId(oldRoleId)
		if (roleId) {
			roleInfo = findProjRoleInfo(roleId)
		} else {
			ConsoleLog("cannot find roleinfo because roleId is undefined")
		}
	} else if (oldRoleId === 0) {
		// NOTE: NEED TO CHECK THE CASE
		roleId = "id_oa" // = "aul-proj-2021-01-oa" --> because findProjRoleInfo has short ids
		roleInfo = findProjRoleInfo(roleId, "convertProjRole-2")
	}

	return roleInfo
}

export const convertProjRoleForPermission = (
	oldNumId: number,
	oldARId: number
) => {
	// NOTE: How to get proper permission role by the existing data
	// Current Role + Access Right
	/**
	 * oldNumId
	 * - 0 : OA
	 * - 1 : JS
	 * - 2 : SS
	 * - 3 : M
	 * - 4 : D
	 * - 5 : P
	 * - 6 : CP
	 * - 7 :
	 * - 8 : QC
	 * - 9 : EP
	 * - 10 : External - PBC Uploader, Readonly
	 *
	 * oldAccessRightId
	 * - 1 : Project Archive Admin
	 * - 2 : Project Admin
	 * - 3 : Project User
	 * - 4 : Readonly (External only?)
	 * - 5 : PBC Uploader
	 *
	 */
	// --> PAA, PA, PU, PR, OA, EXR, EXP
	// console.log("oldNumId, oldAccessRightId:", oldNumId, oldARId)
	if (oldNumId === 0) {
		// OA
		return "aul-proj-2021-01-oa"
	}
	if (oldARId === 1) {
		// PAA
		return "aul-proj-2021-01-paa"
	}
	if (oldARId === 2) {
		// PA
		return "aul-proj-2021-01-pa"
	}
	if (oldARId === 3) {
		// PU
		return "aul-proj-2021-01-pu"
	}
	if (oldNumId !== 10 && oldARId === 4) {
		// PR: project reaonly (internal user)
		return "aul-proj-2021-01-pr"
	}
	if (oldNumId === 10 && oldARId === 4) {
		// PXR
		return "aul-proj-2021-01-pxr"
	}
	if (oldNumId === 10 && oldARId === 5) {
		// PXP
		return "aul-proj-2021-01-pxp"
	}
}
