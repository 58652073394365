import { flow } from "mobx-state-tree"

const ReadWpFileCheckinrequest = (self: any, readWPRequest: any) => ({
	readWpFileCheckinrequest: flow(function*() {
		const actionName = "readWpFileCheckinrequest"
		try {
			const response = yield readWPRequest()
			// ConsoleLog("ReadWpFileCheckinrequest response", response)
			return response
		} catch (error) {
			// console.error("ReadWpFileCheckinrequest error", error, error.response)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadWpFileCheckinrequest
