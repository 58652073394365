import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import ResizingTrigger from "../../../../components/combined-elements/tree-nav/DLTreeNavResizingTrigger"
import ArchivePolicyDetail from "./sub-components/ArchivePolicyDetail"
import ArchivePolicyNav from "./sub-components/ArchivePolicyNavController"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import CreateArchivePolicyDialog from "./sub-components/dialog/CreateArchivePolicyDialog"
import EditArchivePolicyDialog from "./sub-components/dialog/EditArchivePolicyDialog"
import ArchConditionHelpDialog from "./sub-components/dialog/ArchConditionHelpDialog"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import MoveEngTypeDialog from "./sub-components/dialog/MoveEngTypeDialog"

export default observer(function OrgSetupArchivePolicies({
	partialStore,
	fetchingStatus,
	permission,
	contentHeight,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	contentHeight: any
	i18n: DLI18nProps
}) {
	const [dragStart, setDragStart] = useState(false)
	const [movedX, setMovedX] = useState(0)
	const handleMouseDown = (e: any) => {
		setDragStart(true)
	}
	const handleMouseUp = (e: any) => {
		setDragStart(false)
	}
	const handleMouseMove = (e: any) => {
		dragStart && setMovedX(e.movementX)
	}
	useEffect(() => {
		dragStart && partialStore.updateNavWidth(movedX)
	}, [dragStart, movedX])

	return (
		<PageContainer 
			hasToolbar={false} 
			fullWidth 
			fixedHeight
			isLoading={fetchingStatus === "LOADING"}
		>
			{fetchingStatus === "SUCCESS" && (
				<div
					className="FR archive-policy"
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove}
					style={{ height: contentHeight }}
				>
					<ArchivePolicyNav i18n={i18n} permission={permission} />
					<ResizingTrigger handleMouseDown={handleMouseDown} />
					<ArchivePolicyDetail
						i18n={i18n}
						permission={permission}
						selectedPolicyDetails={partialStore.viewSelectedPolicy()}
					/>
				</div>
			)}
			<CommonSnackbarComponent
				i18n={i18n}
				partialStore={partialStore}
			/>
			{partialStore.openCreateDialog && (
				<CreateArchivePolicyDialog i18n={i18n} />
			)}
			{partialStore.openEditDialog && (
				<EditArchivePolicyDialog i18n={i18n} />
			)}
			{partialStore.openMoveEngTypeDialog && (
				<MoveEngTypeDialog i18n={i18n} />
			)}
			{partialStore.openArchConditionHelpDialog && (
				<ArchConditionHelpDialog
					conditionId={partialStore.targetCondition}
					isOpen={partialStore.openArchConditionHelpDialog}
					setIsOpen={partialStore.setOpenArchConditionHelpDialog}
				/>
			)}
		</PageContainer>
	)
})
