import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	deleteRequest,
	postRequest,
} from "../../../../../library/api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetFinancialStatementType",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
export async function readFSGroups(params: {
	fsTypeId: number
	clientId: number
}) {
	const response = await getRequest({
		url: "/GetFinancialGroupByFsType",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readFSSubGroups(params: {
	financialGroupId: number
	clientId: number
}) {
	const response = await getRequest({
		url: "/GetFinancialSubGroupByFinancialGroup",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readLeadSheets(params: {
	clientId: number
	financialSubGroupId: number
}) {
	const response = await getRequest({
		url: "/GetLeadsheetByFinancialSunGroup",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readAvailableGroups(params: {
	fsTypeId: number
	clientId: number
}) {
	const response = await getRequest({
		url: "/GetAvailableFinancialGroup",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
export async function readAvailableSubGroups(params: {
	financialGroupId: number
	clientId: number
}) {
	const response = await getRequest({
		url: "/GetAvailableFinancialSubGroup",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function createFinGroup(payload: any) {
	const response = await postRequest({
		url: "/SaveFinancialGroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function createFinSubGroup(payload: {
	financialGroupId: number
	clientId: number
	financialSubGroup: string
}) {
	const response = await postRequest({
		url: "/SaveFinancialSubGroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function unmapGroup(payload: { MapId: number }) {
	const response = await deleteRequest({
		url: "/UnMapFsAndFgroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function mapGroup(payload: {
	fgroupId: number
	fsTypeId: number
}) {
	const response = await postRequest({
		url: "/MapFsAndFgroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function mapSubGroup(payload: {
	financialGroupId: number
	financialSubGroupId: number
}) {
	const response = await postRequest({
		url: "/MapFgroupAndFsubGroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function unmapSubGroup(payload: { MapId: number }) {
	const response = await deleteRequest({
		url: "/UnMapFgroupAndFsubGroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function delFinGroup(payload: { id: number }) {
	const response = await deleteRequest({
		url: "/DeleteFinancialGroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function delFinSubGroup(payload: { id: number }) {
	const response = await deleteRequest({
		url: "/DeleteFinancialSubGroup",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function readAvaLeadSheets(params: {
	clientId: number
	financialSubGroupId: number
}) {
	const response = await getRequest({
		url: "/GetAvailableLeadsheets",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function mapLeadSheet(payload: {
	financialSubGroupId: number
	leadsheet: string
}) {
	const response = await postRequest({
		url: "/MapFsubGroupLeadsheet",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function unmapLeadSheet(payload: { MapId: number }) {
	const response = await deleteRequest({
		url: "/UnMapFsubGroupLeadsheet",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type FSSetupApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
	apiReadFSGroups: (payload: {
		fsTypeId: number
		clientId: number
	}) => Promise<AxiosResponse<any>>
	apiReadFSSubGroups: (payload: {
		financialGroupId: number
		clientId: number
	}) => Promise<AxiosResponse<any>>
	apiReadLeadSheets: (payload: {
		clientId: number
		financialSubGroupId: number
	}) => Promise<AxiosResponse<any>>
	apiReadAvailableGroups: (payload: {
		fsTypeId: number
		clientId: number
	}) => Promise<AxiosResponse<any>>
	apiCreateFinGroup: (payload: any) => Promise<AxiosResponse<any>>
	apiUnmapGroup: (payload: { MapId: number }) => Promise<AxiosResponse<any>>
	apiMapGroup: (payload: {
		fgroupId: number
		fsTypeId: number
	}) => Promise<AxiosResponse<any>>
	apiDelFinGroup: (payload: { id: number }) => Promise<AxiosResponse<any>>
	apiReadAvailableSubGroups: (payload: {
		financialGroupId: number
		clientId: number
	}) => Promise<AxiosResponse<any>>
	apiCreateFinSubGroup: (payload: any) => Promise<AxiosResponse<any>>
	apiDelFinSubGroup: (payload: { id: number }) => Promise<AxiosResponse<any>>
	apiUnmapSubGroup: (payload: {
		MapId: number
	}) => Promise<AxiosResponse<any>>
	apiMapSubGroup: (payload: {
		financialGroupId: number
		financialSubGroupId: number
	}) => Promise<AxiosResponse<any>>
	apiReadAvaLeadSheets: (payload: {
		clientId: number
		financialSubGroupId: number
	}) => Promise<AxiosResponse<any>>
	apiUnmapLeadSheet: (payload: {
		MapId: number
	}) => Promise<AxiosResponse<any>>
	apiMapLeadSheet: (payload: {
		financialSubGroupId: number
		leadsheet: string
	}) => Promise<AxiosResponse<any>>
}
