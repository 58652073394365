import React from "react"
import {
	StyledDLBreadCrumbs,
	BreadCrumbsArrow,
	BreadCrumbsPathProps,
} from "./dl-bread-crumbs.sub-parts"
import Icon from "@mdi/react"
import { mdiCloseCircle } from "@mdi/js"
import { observer } from "mobx-react-lite"
import { ConsoleLog } from ".."

export default observer(function DLBreadCrumbs2({
	path,
	setPath,
}: {
	path: BreadCrumbsPathProps
	setPath: (idPath: string[]) => void
}) {
	let pathLength = path.length
	//
	// NOTE: These actions act little differently with currentPath actions
	// because these part recreate the path with let list
	const handleClickCrumb = (id: string) => {
		const idIndex = path.findIndex((item) => item.id === id)
		if (idIndex !== -1) {
			const newPath = path.splice(0, idIndex + 1)

			let newIdPath: string[] = []
			newPath.map((item) => newIdPath.push(item.id))
			setPath(newIdPath)
		} else {
			ConsoleLog("(DLBreadCrumbs2) Cannot find matched index", id)
		}
	}
	//
	const handleRemoveCrumb = (id: string) => {
		const idIndex = path.findIndex((item) => item.id === id)
		if (idIndex !== -1) {
			const newPath = path.splice(0, idIndex)
			let newIdPath: string[] = []

			newPath.map((item) => newIdPath.push(item.id))
			setPath(newIdPath)
		} else {
			ConsoleLog(
				"(DLBreadCrumbs2 - handleRemoveCrumb) Cannot find matched index ",
				[id, path]
			)
		}
	}

	return (
		<StyledDLBreadCrumbs className="dl-bread-crumbs-2-container FR AC">
			{pathLength === 0 ? (
				<div className="note">Please select item</div>
			) : (
				path.map((item: { id: string; name: string }, i: number) => {
					const { id, name } = item
					return (
						<div className="FR AC crumb-container" key={id + i}>
							<div
								className={`crumb FR AC ${
									i + 1 === pathLength && "last-crumb"
								}`}
							>
								<span
									className="click-able crumb-name"
									onClick={() => handleClickCrumb(id)}
								>
									{name}
								</span>
								<div
									className="close-btn FR AC"
									onClick={() => handleRemoveCrumb(id)}
								>
									<Icon path={mdiCloseCircle} size={0.6} />
								</div>
							</div>
							{i + 1 < pathLength && <BreadCrumbsArrow />}
						</div>
					)
				})
			)}
		</StyledDLBreadCrumbs>
	)
})
