import { putRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

export async function moveCabinet(payload: { FromID: number; ToID: number }) {
	const { FromID, ToID } = payload
	if (FromID === ToID) {
		alert(
			`(api - moveCabinet) target and destination are same. cabinetId: ${FromID} / moveTo: ${ToID}`
		)
	}
	const endpoint = "/CabinetMoving"
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
