// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const MoveCabinet = (self: any) => ({
	moveCabinet(cabinetId: string, moveTo: string) {
		// 0.
		const actionName = "moveCabinet"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.requestMoveCabinet(cabinetId, moveTo)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					self.updateParent(cabinetId, moveTo)
					self.setOpenMoveDialog(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default MoveCabinet
