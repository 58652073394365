import { flow } from "mobx-state-tree"
import { OrgSetupArchPolicyApiProps } from "../org-archive-policies.apis"

const RequestCopyArchivePolicy = (
	self: any,
	copy: OrgSetupArchPolicyApiProps["apiCopy"]
) => ({
	requestCopyArchivePolicy: flow(function* (policyId: number) {
		const actionName = "requestCopyArchivePolicy"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		const params = {
			ArchivePolicyId: policyId,
		}
		try {
			const response = yield copy(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestCopyArchivePolicy
