import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../../../../library/api-requests"

const version = process.env.REACT_APP_API_VERSION

export async function requestToken({
	userNumberId,
	orgNumberId,
}: {
	userNumberId: number
	orgNumberId: number
}) {
	const v2_prefix = "/security/Token"
	// const endpoint = "/CustomerToken"
	const endpoint =
		"/CustomerToken?UserId=" + userNumberId + "&CustomerId=" + orgNumberId
	// const params = {
	// 	UserId: userNumberId,
	// 	CustomerId: orgNumberId,
	// }
	const response = await postRequest({
		url: version === "v2" ? v2_prefix + endpoint : endpoint,
		params: {},
		// WARNING: Do not use params here. It will get 400 error. (Is this fine..?)
		tokenType: TOKEN_TYPE.loginToken,
		version,
	})

	return response
}

export async function readOrgInfo() {
	const endpoint = "/GetTaggedCustomersList"
	const response = await getRequest({
		// Because there are no read-org-info api, re-use the get-org-list
		// for independence of org-store. (Let's replace with different API in the future.)
		// NOTE: This API response will be operated on the org-default-store, not in the org-list-store.
		// NOTE: org-defalut store is on the org-store,  org-list store is on the lobby-store.
		url: endpoint,
		tokenType: TOKEN_TYPE.loginToken,
	})

	return response
}

export async function readSysInfo() {
	const endpoint = "/GetCustomerSysSettings"
	const response = await getRequest({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgCheckinApiProps = {
	apiRequestToken: ({
		userNumberId,
		orgNumberId,
	}: {
		userNumberId: number
		orgNumberId: number
	}) => Promise<AxiosResponse<any>>
	apiReadOrgInfo: () => Promise<AxiosResponse<any>>
	apiReadSysInfo: () => Promise<AxiosResponse<any>>
}
