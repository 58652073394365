import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { Doughnut } from "react-chartjs-2"
import { CardType } from "../../../table-view/store/data-models/dashboard-table-view.data-model"
import Icon from "@mdi/react"
import {
	mdiArrowLeft,
	mdiChartDonut,
	mdiClockOutline,
	mdiDownload,
	mdiImageOutline,
	mdiSortAlphabeticalAscending,
	mdiSortAlphabeticalDescending,
	mdiSortAscending,
	mdiSortDescending,
	mdiTable,
} from "@mdi/js"
import { DLIconButton } from "../../../../../../components/basic-elements"
import { ProgressRateDetailBarCharts } from "./ProgressRateDetailBarCharts"
import { TopicDetailCharts } from "./TopicDetailCharts"
import { handleExport } from "../../../../../../components/basic-elements/export/ExportXLSX"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

const CardDetailView = observer(
	({
		idForImgDownload,
		partialStore,
		cardType,
		data,
		summaryData,
		downloadAsImg,
		i18n,
	}: {
		idForImgDownload: string
		partialStore: any
		cardType: CardType
		data: any // filtered projects
		summaryData: any
		downloadAsImg: () => void
		i18n: DLI18nProps
	}) => {
		const [showDonut, setShowDonut] = useState(true)
		const [showTable, setShowTable] = useState(false)
		const [showDetail, setShowDetail] = useState(false)

		const [ascendByName, setAscendByName] = useState<boolean | undefined>(
			undefined
		)
		const [ascendByCount, setAscendByCount] = useState<boolean | undefined>(
			true
		)

		const widthStandard = showDonut ? 400 : 500
		const mainHeight = 20
		const subHeight = 14

		const handleDownloadTable = () => {
			let proceed = window.confirm(
				`Do you really want to download this table as xlsx?`
			)
			if (!proceed) {
				return
			} else {
				if (cardType === CardType.progressRate) {
					handleExport(
						partialStore.detailViewProgressTableData,
						partialStore.detailViewTableTitle
					)
				} else {
					handleExport(
						partialStore.detailViewTableData,
						partialStore.detailViewTableTitle
					)
				}
			}
		}

		const handleOrderByName = () => {
			if (ascendByName === undefined) {
				setAscendByName(true)
				setAscendByCount(undefined)
			} else if (ascendByName) {
				setAscendByName(false) // it means descending
				setAscendByCount(undefined)
			} else {
				setAscendByName(undefined)
				setAscendByCount(true)
			}
		}

		const handleOrderByCount = () => {
			if (ascendByCount === undefined) {
				setAscendByCount(true)
				setAscendByName(undefined) // do not use false. it means descending alphabetical order
			} else if (ascendByCount) {
				setAscendByCount(false) // this means descending
				setAscendByName(undefined) // do not use false. it means descending alphabetical order
			} else {
				setAscendByCount(undefined)
				setAscendByName(true)
			}
		}

		let topicTitle = ""
		if (cardType === CardType.engType) {
			topicTitle = "Engagement Type"
		}
		if (cardType === CardType.group) {
			topicTitle = "Group"
		}
		if (cardType === CardType.epUser) {
			topicTitle = "Engagement Partner"
		}
		if (cardType === CardType.qcUser) {
			topicTitle = "Quality Control User"
		}
		if (cardType === CardType.periodType) {
			topicTitle = "Period Type"
		}
		if (cardType === CardType.progressRate) {
			topicTitle = "Progress Rate"
		}

		return (
			<StyledCardDetailView>
				<div className="topic-detail-view-toolbar FR AC JSB">
					<DLIconButton
						eleTestId="back-to-summary"
						eleClassName="back-to-topic-summary"
						clickHandler={() =>
							partialStore.setShowCardDetailView(false)
						}
						tooltipText="Back to list"
					>
						<Icon path={mdiArrowLeft} size={1} />
					</DLIconButton>
					<div className="FR AC">
						{cardType !== CardType.progressRate && (
							<>
								<DLIconButton
									eleClassName="layout-btn"
									eleTestId="order-by-count"
									clickHandler={() => handleOrderByCount()}
									variant={
										ascendByCount === undefined
											? "outlined"
											: "contained"
									}
									tooltipText="Order by count"
								>
									{ascendByCount === undefined ||
									ascendByCount ? (
										<Icon
											path={mdiSortAscending}
											size={1}
										/>
									) : (
										<Icon
											path={mdiSortDescending}
											size={1}
										/>
									)}
								</DLIconButton>
								<DLIconButton
									eleClassName="layout-btn"
									eleTestId="order-by-name"
									clickHandler={() => handleOrderByName()}
									variant={
										ascendByName === undefined
											? "outlined"
											: "contained"
									}
									tooltipText="Order by name"
								>
									{ascendByName === undefined ||
									ascendByName === true ? (
										<Icon
											path={mdiSortAlphabeticalAscending}
											size={1}
										/>
									) : (
										<Icon
											path={mdiSortAlphabeticalDescending}
											size={1}
										/>
									)}
								</DLIconButton>
							</>
						)}

						<DLIconButton
							eleClassName="layout-btn"
							eleTestId="view-simple"
							variant={showDonut ? "contained" : "outlined"}
							tooltipText={
								showDonut
									? "Hide Left Section"
									: "Show Left Section"
							}
							clickHandler={() => setShowDonut(!showDonut)}
						>
							<Icon path={mdiChartDonut} size={0.7} />
						</DLIconButton>
						<DLIconButton
							eleClassName="layout-btn"
							eleTestId="view-as-table"
							tooltipText={
								showTable
									? "Turn to Bar charts"
									: "Turn to Table"
							}
							variant={showTable ? "contained" : "outlined"}
							clickHandler={() => setShowTable(!showTable)}
						>
							<Icon path={mdiTable} size={0.7} />
						</DLIconButton>
						{cardType !== CardType.progressRate && (
							<DLIconButton
								eleClassName="layout-btn"
								eleTestId="view-chart-detail"
								variant={showDetail ? "contained" : "outlined"}
								clickHandler={() => setShowDetail(!showDetail)}
								tooltipText={
									showDetail ? "Hide Detail" : "Show Detail"
								}
							>
								<Icon path={mdiClockOutline} size={0.7} />
							</DLIconButton>
						)}
						<DLIconButton
							eleClassName="layout-btn"
							eleTestId="download-img"
							tooltipText={
								i18n.tsDownloadAsImage || "Download as Image"
							}
							variant="outlined"
							clickHandler={() => downloadAsImg()}
						>
							<Icon path={mdiImageOutline} size={0.7} />
						</DLIconButton>
						<DLIconButton
							eleClassName="layout-btn"
							eleTestId="download-xlsx"
							tooltipText={
								i18n.tsDownloadAsTable || "Download as Table"
							}
							variant="outlined"
							clickHandler={() => handleDownloadTable()}
						>
							<Icon path={mdiDownload} size={0.7} />
						</DLIconButton>
					</div>
				</div>
				<CardDetailViewLayout
					id={idForImgDownload}
					topicName={cardType}
					summaryData={summaryData}
					showDonut={showDonut}
					showTable={showTable}
					topicTitle={topicTitle}
					detailChart={
						cardType === CardType.progressRate ? (
							<ProgressRateDetailBarCharts
								partialStore={partialStore}
								totalCount={data.length}
								data={data}
								widthStandard={widthStandard}
								mainHeight={mainHeight}
								subHeight={subHeight}
								showTable={showTable}
							/>
						) : (
							<TopicDetailCharts
								partialStore={partialStore}
								data={data}
								orderByName={ascendByName}
								orderByCount={ascendByCount}
								topic={cardType}
								showDetail={showDetail}
								widthStandard={widthStandard}
								mainHeight={mainHeight}
								subHeight={subHeight}
								showTable={showTable}
								topicTitle={topicTitle}
							/>
						)
					}
				/>
			</StyledCardDetailView>
		)
	}
)

const CardDetailViewLayout = ({
	id,
	topicName,
	summaryData,
	detailChart,
	showDonut,
	showTable,
	topicTitle,
}: {
	id: string
	topicName: CardType
	summaryData: any
	detailChart: any
	showDonut: boolean
	showTable: boolean
	topicTitle: string
}) => {
	const isPie = topicName === CardType.engType || topicName === CardType.group

	return (
		<div className="FR card-details" id={id}>
			<div
				className={"doughnut-area FC AC " + (!showDonut && "hide")}
				data-testid="topic-detail-left-side"
			>
				<div className="topic-title" data-testid="topic-title">
					{topicTitle}
				</div>
				<Doughnut
					data={summaryData}
					width={160}
					height={160}
					options={{
						cutoutPercentage: isPie ? 0 : 80,
						legend: {
							display: false,
							position: "bottom",
							align: "start",
							padding: 10,
						},
					}}
				/>
			</div>

			<div
				className="detail-chart-area"
				data-testid="topic-detail-right-side"
			>
				{detailChart}
			</div>
		</div>
	)
}

export default CardDetailView

const StyledCardDetailView = styled.div`
	/* padding-right: 1rem; */
	.topic-detail-view-toolbar {
		margin-bottom: 0.6rem;
		.back-to-topic-summary {
		}
	}
	.layout-btn {
		margin-left: 0.4rem;
	}
	.doughnut-area {
		@media (max-width: 1280px) {
			width: 20%;
			min-width: 20%;
			margin: 1rem 1rem;
		}
		@media (min-width: 1281px) {
			width: 16%;
			min-width: 16%;
			margin: 1rem 3rem;
		}

		transition: 0.4s;
		overflow: hidden;

		.topic-title {
			margin-bottom: 1rem;
			font-size: ${(props) => props.theme.shared.textXl};
			white-space: nowrap;
		}
		&.hide {
			width: 0;
			min-width: 0;
			padding: 0;
			margin: 0;
			flex: 0;
			opacity: 0;
		}
	}
	.detail-chart-area {
		width: 100%;
	}
	.title {
		display: flex;
		flex-direction: row-reverse;
		/* flex-basis: 20rem; */
	}
	.total {
		display: flex;
		flex-direction: row-reverse;
		/* flex-basis: 14rem; */
	}
	.hbar-row {
		padding: 0.4rem;
	}
	.clickable-cell {
		cursor: pointer;
		:hover {
			font-weight: 700;
		}
		/* for the detail view table cell */
	}
`
