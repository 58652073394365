// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"

/**
 * fileList example (by sortable-tree / onMoveNode / nextParentNode / children)
 * is array of
 * {
 *   id: "fileId1234", --> this file ID cannot work for API.
 * NOTE: API requires source ID instead of the file ID
 * This file ID will be converted as source ID on the next step
 *   parentId: "folderId5678",
 *   ... and others
 * }
 */

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

export type ItemListForSortProps = {
	id: string
	parentId: string | null
	[x: string]: any
}[]

const ReorderChildren = (self: any) => ({
	reorderChildren(
		parentId: string | null,
		itemList: ItemListForSortProps,
		postAction?: any
	) {
		// 0.
		const actionName = "reorderChildren"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const props = {
			parentId,
			itemList: itemList, // this list has both folder and file
		}
		// ConsoleLog(" ***** ", actionName, props, " ***** ")
		self.requestReorder(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					self.reflectReorder(parentId, itemList)
					postAction && postAction()
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ReorderChildren
