import React, { useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useLocation } from "react-router-dom"
import DLFileTree from "../../../../../components/combined-elements/file-tree/DLFileTree"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	FileScreenColumn,
	FileUploadFromType,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import { ConsoleLog } from "../../../../../components/basic-elements"

export default observer(function OrgProjTemplateFileTreeController({
	areaWidth,
}: {
	areaWidth: number
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const menuId = DLOrgSubMenus.proj_templates
	const i18nSheet = OrgI18n.projTemplates // TODO: Need to check i18n location
	// NOTE: File related common i18n is came from global sheet...

	const userId = orgStore.checkin.orgUserId
	//
	const indentWidth = 16

	const setCheckbox = templateStore.pushOrSpliceSelectedItem
	// folder related
	const getLoadingStatus = templateStore.getActionStatus

	// WARNING: UPDATE TOGETHER PROJ FILES
	const handleDragAndDrop = (data: any) => {
		ConsoleLog([" >>>>> ORG PROJ TEMPLATES handleDragAndDrop", data])
		const { node, targetIndex } = data
		if (data.updateAsRootFolder) {
			// reorder as root folder
			// in this case, data is index of the new root folder
			const newRootFolderList = templateStore.sortRootFoldersWithNewItem(
				targetIndex,
				node
			)
			let listForApi1: { id: string; parentId: null }[] = []
			newRootFolderList.map((item: string) => {
				listForApi1.push({ id: item, parentId: null })
			})
			ConsoleLog([
				" <---- reorder as root folder ",
				listForApi1,
				" ----> ",
			])
			templateStore.reorderChildren(null, listForApi1)
		} else if (data.reorderRootFolders) {
			const newOrderedRootFolders =
				templateStore.getNewOrderedRootFolders(targetIndex, node)
			let listForApi2: { id: string; parentId: null }[] = []
			newOrderedRootFolders?.map((item: string) => {
				listForApi2.push({ id: item, parentId: null })
			})
			ConsoleLog([
				" <---- reorder existing root folders: ",
				listForApi2,
				" ----> ",
			])
			templateStore.reorderChildren(null, listForApi2)
		} else {
			templateStore.reorderChildren(
				data.nextParentNode.id,
				data.nextParentNode.children
			)
		}
		// templateStore.reorderChildren(
		// 	data.id,
		// 	data.children
		// )
	}

	const handleDuplicateFolder = ({ folderId }: { folderId: string }) => {
		orgStore.orgProjTemplates.duplicateFolder(folderId)
	}
	const handleDuplicateFile = ({ fileId }: { fileId: string }) => {
		// orgStore.orgProjTemplates.
	}

	const checkDuplicatedAliasId = templateStore.isDuplicatedAliasId
	const checkDuplicatedName = templateStore.isDuplicatedName

	const handleEditFileInfo = useCallback(
		({
			fileId,
			aliasId,
			title,
		}: {
			fileId: string
			aliasId: string
			title: string
		}) => {
			templateStore.editFile({
				projId: templateStore.selectedTemplate.id,
				fileId,
				aliasId,
				title,
			})
		},
		[]
	)

	const setFileUploadFrom = (from: FileUploadFromType | undefined) => {
		templateStore.setFileUploadFrom(from)
	}
	// permission
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const dntFormat = store.global.getDntFormat

	let location = useLocation()
	const currentUrl = location.pathname
	const currentMenu = orgStore.checkin.accessibleFlatMenus.find(
		(element) => element.url === currentUrl
	)

	const i18n = store.i18n.combineI18n(i18nSheet)
	const treeData = templateStore.flatListAsTree(dntFormat)
	const canDrag = !templateStore.structureLock

	const isReadonly = false // TODO: orgStore.checkin.
	const isExternal = false // TODO: orgStore.checkin.
	//
	// const searchQuery = templateStore.searchQuery
	const searchQuery = ""
	const contentsAreaHeight = store.ui.contentsAreaHeight
	let treeHeight = contentsAreaHeight - 36 - 48
	// NOTE: DO NOT set tree height by selected item count (idiot...)

	return (
		<DLFileTree
			projId={templateStore.selectedTemplate.id}
			simpleMode
			// all things below are same with project files
			menuId={menuId}
			indentWidth={indentWidth}
			treeHeight={treeHeight}
			treeData={treeData}
			canDrag={canDrag}
			searchQuery={searchQuery}
			// folder & file shared
			isSelected={templateStore.isSelected}
			isCurrentParent={templateStore.isCurrentParent}
			isLastSelectedItem={templateStore.isLastSelectedItem}
			// nextTreeItem={nextTreeItem}
			// prevTreeItem={prevTreeItem}
			// above things are not working properly by inherit..
			setNextTreeItem={templateStore.setNextTreeItem}
			setPrevTreeItem={templateStore.setPrevTreeItem}
			showCheckbox={templateStore.showCheckbox}
			selectedItems={templateStore.selectedItems}
			setSelectedItem={templateStore.setSelectedItem}
			pushSelectedItem={templateStore.pushSelectedItem}
			setSelectedItemsWithKeyboard={
				templateStore.setSelectedItemsWithKeyboard
			}
			resetSelectedItems={templateStore.resetSelectedItems}
			setCheckbox={setCheckbox}
			permission={permission}
			// shared
			setFileTreeDialogOpen={templateStore.setFileTreeDialogOpen}
			// folder related
			getLoadingStatus={getLoadingStatus}
			getChildrenOfFolder={templateStore.getChildrenOfFolder}
			toggleExpanded={templateStore.toggleExpanded}
			handleDeleteFolder={templateStore.removeFileFolder}
			setFolderClickPoint={templateStore.setFolderClickPoint}
			//
			setFileClickPoint={templateStore.setFileClickPoint}
			openFileWithoutEditor={templateStore.openFileWithoutEditor} // TODO: is this required..??
			reflectFileCheckout={() => {}}
			// signOffArea={FileRowSignOffAreaController}
			signOffPolicy={[]}
			handleFileStatus={() => {}}
			//
			handleOpenCommentListOfFile={() => {}}
			//
			handleDragAndDrop={handleDragAndDrop}
			sortChildren={templateStore.sortChildren}
			//

			//
			handleEditFileInfo={handleEditFileInfo}
			// for sign-off area
			singleSignOff={() => {}}
			signOffByShortcut={() => {}}
			//
			isHovered={() => {}}
			getColumnSignOffs={() => {}}
			hoveredColumn={undefined}
			cellType={undefined}
			dateFormat={""}
			//
			//
			// for column width
			contentsAreaWidth={areaWidth}
			//
			assignedRolesWidth={
				templateStore.columnWidth[FileScreenColumn.assignedRoles]
			}
			aliasIdWidth={templateStore.columnWidth[FileScreenColumn.aliasId]}
			titleWidth={templateStore.columnWidth[FileScreenColumn.title]}
			formatWidth={templateStore.columnWidth[FileScreenColumn.format]}
			sizeWidth={templateStore.columnWidth[FileScreenColumn.size]}
			signOffLockWidth={
				templateStore.columnWidth[FileScreenColumn.signOffLock]
			}
			//
			fixedAreaWidth={0}
			signOffAreaWidth={0}
			historyWidth={0}
			//
			//
			// for column header
			setColumnHighlight={() => {}}
			needCollapseAll={templateStore.needCollapseAll}
			updateSignOffAreaWidth={() => {}}
			updateFileInfoAreaWidth={templateStore.updateFileInfoAreaWidth}
			//
			partialStore={templateStore} // for nextTreeItem & prevTreeItem: these two things are not working well by inherit.. don't know why
			// temp
			userInfo={{ id: userId, isReadonly, isExternal }}
			//
			// NOTE: this handle Duplicate means copy the target
			handleDuplicateFolder={handleDuplicateFolder}
			handleDuplicateFile={handleDuplicateFile}
			setFileUploadFrom={setFileUploadFrom}
			//
			checkDuplicatedAliasId={checkDuplicatedAliasId}
			checkDuplicatedName={checkDuplicatedName}
			//
			i18n={i18n}
			userRole={orgStore.checkin.assignedRole.roleId}
		/>
	)
})
