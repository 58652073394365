import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"

const DialogRelatedActions = (self: any) => ({
	// Dialog control
	setOpenAddGroupDialog(request?: boolean) {
		if (request !== undefined) {
			self.addGroupDialogOpen = request
		} else {
			self.addGroupDialogOpen = !self.addGroupDialogOpen
		}
	},
	setOpenMoveDialog(request?: boolean) {
		if (request !== undefined) {
			self.openMoveDialog = request
		} else {
			self.openMoveDialog = !self.openMoveDialog
		}
	},
	setOpenCreateProjDialog(request?: boolean) {
		if (request !== undefined) {
			self.openCreateProjDialog = request
		} else {
			self.openCreateProjDialog = !self.openCreateProjDialog
		}
	},
	setOpenEditProjDialog(request?: boolean) {
		if (request !== undefined) {
			self.openEditProjDialog = request
		} else {
			self.openEditProjDialog = !self.openEditProjDialog
		}
	},
	setOpenDeleteProjDialog(request?: boolean) {
		if (request !== undefined) {
			self.openDeleteProjDialog = request
		} else {
			self.openDeleteProjDialog = !self.openDeleteProjDialog
		}
	},
	setCreateProjectMethod(from: CreateProjectMethod) {
		self.createProjectMethod = from
	},
	setOpenCreateCabinetDialog(request?: boolean) {
		if (request !== undefined) {
			self.openCreateCabinetDialog = request
		} else {
			self.openCreateCabinetDialog = !self.openCreateCabinetDialog
		}
	},
	setOpenEditCabinetDialog(request?: boolean) {
		if (request !== undefined) {
			self.openEditCabinetDialog = request
		} else {
			self.openEditCabinetDialog = !self.openEditCabinetDialog
		}
	},
})

export default DialogRelatedActions
