import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./adjustments.provider"
import GetAdjustments from "./view-model-actions/get-adjustments"
import { AjeModel, WPRef } from "./data-models/adjustments.data-model"
import { TBDetails } from "../../financial-statements/store/data-models/financial-statement.data-model"
import GetTrialBalanceDetails from "../../financial-statements/store/view-model-actions/get-trial-balance-details"
import GetWPReferences from "./view-model-actions/get-wp-references"
import AddAdjustments from "./view-model-actions/add-adjustment"
import RemoveAdjustments from "./view-model-actions/remove-adjustment"
import GetAdjustmentUrl from "./view-model-actions/get-adjustment-url"

const AdjustmentsViewModel = types
	.model({
		adjustments: types.array(AjeModel),
		tbDetails: types.array(TBDetails),
		wpRef: types.array(WPRef),
		url: "",
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setAdjustments(ajes: any) {
			self.adjustments = ajes
		},
		setTBDetails(details: any) {
			self.tbDetails = details
		},
		setUrl(url: string) {
			self.url = url
		},
		setWPReferences(wp: any) {
			self.wpRef = wp
		},
		pushItemToAdjustment(item: any) {
			self.adjustments.push(item)
		},
		spliceItemFromAdjustment(ajeId: string) {
			const index = self.adjustments.findIndex(
				(item: any) => item.ajeId === ajeId
			)
			self.adjustments.splice(index, 1)
		},
	}))
	.actions(GetAdjustments)
	.actions(GetTrialBalanceDetails)
	.actions(GetWPReferences)
	.actions(AddAdjustments)
	.actions(RemoveAdjustments)
	.actions(GetAdjustmentUrl)
	.views((self) => ({
		viewAdjustments() {
			let ajes: any[] = []
			self.adjustments.map((item: any) => {
				ajes.push({
					ajeId: item.ajeId,
					ajeNumber: item.ajeNumber,
					ajeDesc: item.ajeDesc,
					ajeType: item.ajeType,
					wpRef: item.wpRef,
				})
			})
			return ajes
		},
		viewAdjustmentDetails(ajeId: string) {
			return self.adjustments.find((item: any) => item.ajeId === ajeId)
		},
		viewTBDetails() {
			let tbDetails: any[] = []
			self.tbDetails.map((item) => {
				tbDetails.push({
					name: item.tbName,
					value: item.tbId,
				})
			})
			return tbDetails
		},
		viewWPRef() {
			let wpRef: any[] = []
			self.wpRef.map((item) => {
				wpRef.push({
					name: item.wpRef,
					value: item.wpRef,
				})
			})
			return wpRef
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AdjustmentsViewModel
