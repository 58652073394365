import { types } from "mobx-state-tree"
import ChecklistsModel from "./checklists.model"
import ChecklistsViewModel from "./checklists.view-model"

import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { readChecklists } from "../../online-projects/store/reports-management.apis"

const ChecklistsStore = ({
	apiReadChecklists,
}: any) =>
	types
		.compose(
			ChecklistsModel({
				apiReadChecklists,
			}),
			ChecklistsViewModel
		)
		.named("ChecklistsStore")

export const initialStore = {
	checklistDetail: null,
	//
	clickPoint: initialClickPoint,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ChecklistsStoreInstance = ChecklistsStore({
	apiReadChecklists: readChecklists,
}).create(initialStore)

export default ChecklistsStoreInstance
