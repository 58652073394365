// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../../components/basic-elements"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */
 
const DuplicateOrgLibraryTemplate = (self: any) => ({
	duplicateOrgLibraryTemplate(templateId: string) {
		// 0.
		const actionName = "duplicateOrgLibraryTemplate"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let targetId =
			templateId.indexOf("wpTemplateId") !== -1
				? templateId.split("wpTemplateId")[1]
				: templateId.split("apTemplateId")[1]
		const payload = {
			SourceID: parseInt(targetId),
			Type: 2, // for template
		}
		self.copyOrgLibraryTemplate(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					// close the dialog
					self.setOpenDeleteAndDuplicateDialog(false)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DuplicateOrgLibraryTemplate
