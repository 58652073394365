// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import { RfSourceProjectProps } from "../data-models/org-project.data-model"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */

const GetRfProjectsByGroup = (self: any) => ({
	getRfProjectsByGroup(groupId: string) {
		// 0.
		const actionName = "getRfProjectsByGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readRfProjectsByGroup(groupId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const fetchedList = response.data.ClientsData
					let reOrganizedList: RfSourceProjectProps[] = []
					fetchedList.map(
						(item: {
							clientId: number
							clientName: string
							isGrayed: boolean
							linkName: string
							year: number
						}) => {
							reOrganizedList.push({
								id: idToString(item.clientId, IdType.project),
								title: item.linkName,
								available: item.isGrayed,
								combinedInfo: item.clientName,
								year: item.year,
							})
						}
					)
					self.setRfSources(reOrganizedList)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: false,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetRfProjectsByGroup
