import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLButton, DLDialog } from "../../../../../components/basic-elements"
import { mdiLock, mdiLockOpenVariantOutline } from "@mdi/js"
import Icon from "@mdi/react"
import { getTzDateAndTime2 } from "../../../../../library/converters/date-utc-converter"
import keyboardJS from "keyboardjs"

const SignOffLockDialog = observer(
	({ partialStore }: { partialStore: any }) => {
		const store = useRootStore()
		const objectId = partialStore.selectedFile
		const objInfo = partialStore.getItemInfo(objectId)
		const menuId = partialStore.storeName
		const dntFormat = store.global.getDntFormat
		const isSignOffLocked = objInfo.signOffLock

		console.log(objInfo, "objInfo")
		const dialogName = FileScreenDialog.signOffLockDialog
		const setOpen = () => {
			partialStore.setFileTreeDialogOpen(dialogName)
		}

		useEffect(() => {
			return () => {
				keyboardJS.resume()
			}
		}, [])

		const open = partialStore.fileTreeDialogOpenStatus[dialogName]

		const handleLock = () => {
			partialStore.editSignOffLock({
				signOffLock: !isSignOffLocked,
				fileId: objInfo.id,
				menuId,
			})
		}

		const signOffModifiedDate = objInfo.signOffLockModifiedDate
			? getTzDateAndTime2({
					date: objInfo.signOffLockModifiedDate,
					dntFormat,
			  })
			: ""

		return (
			<DLDialog
				eleTestId="sign-off-lock-dialog"
				isOpen={open}
				setIsOpen={setOpen}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={`Sign Off ${isSignOffLocked ? "Unlock" : "Lock"}`}
				dialogContents={
					<div>
						<div style={{ margin: "6px 0" }}>
							<b>Target File:</b> ({objInfo.aliasId}){" "}
							{objInfo.title}
						</div>
						<div>
							{isSignOffLocked
								? "Are you sure you want to unlock the sign off feature? Once unlocked, users can make sign off changes for this file."
								: "Are you sure you want to lock the sign off feature? Once locked, users cannot make sign off changes for this file. To execute the sign off, the sign-off lock should be 'UNLOCKED'"}
						</div>
						{objInfo.signOffLockModifiedDate && (
							<div
								style={{
									margin: "6px 0",
									color: "grey",
									fontSize: 12,
								}}
							>
								Sign Off was{" "}
								{isSignOffLocked ? "locked" : "unlocked"} by (
								{objInfo.signOffLockModifiedBy.roleShortName})
								{objInfo.signOffLockModifiedBy.userName} at{" "}
								{signOffModifiedDate}
							</div>
						)}
					</div>
				}
				openBtn={<div />}
				// cancelBtnText="Cancel"
				actionReady={true}
				actionBtn={
					<div className="FR JSB" style={{ width: "100%" }}>
						<DLButton
							eleTestId="sign-off-lock"
							color={isSignOffLocked ? "warning" : "primary"}
							variant="contained"
							startIcon={
								<Icon
									path={
										isSignOffLocked
											? mdiLockOpenVariantOutline
											: mdiLock
									}
									size={0.8}
								/>
							}
							clickHandler={() => handleLock()}
						>
							Sign Off {isSignOffLocked ? "Unlock" : "Lock"}
						</DLButton>
						<DLButton
							eleTestId="add-new-row"
							color="default"
							variant="contained"
							clickHandler={() => {
								setOpen()
								partialStore.setFileTreeDialogOpen(
									FileScreenDialog.signOffLockUnlockHistoryDialog
								)
							}}
						>
							Sign Off Lock History
						</DLButton>
					</div>
				}
				// handleAction={downloadPDF}
				maxWidth="sm"
				showSpinner={
					partialStore.getActionStatus("getFileInfo") === "LOADING"
				}
			/>
		)
	}
)

export default SignOffLockDialog
