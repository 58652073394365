import { flow } from "mobx-state-tree"

import { FileEditorApiProps } from "../file-editor.api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const MDiscardCheckout = (
	self: any,
	discardFileCheckout: FileEditorApiProps["apiDiscardFileCheckout"]
) => ({
	mDiscardCheckout: flow(function* (
		srcId: string,
		userId: string,
		menuId: DLProjSubMenus
	) {
		const actionName = "mDiscardCheckout"
		// 1.
		const prerequisite = [srcId, userId, menuId]
		if (prerequisite.includes("") || prerequisite.length !== 3) {
			alert(
				`(${actionName}) undefined props exist. prerequisites: ${prerequisite}`
			)
			return
		}
		//
		//
		// 2.
		const srcNumId = idToNumber(srcId, IdType.src)
		const userNumberId = idToNumber(userId, IdType.user)
		//
		//
		// 3.
		if (typeof srcNumId === "number" && typeof userNumberId === "number") {
			try {
				const payload = [
					{
						MediaID: srcNumId,
						Type: 0,
						// Maybe.. 0 means false (close) and 1 means true (open)
						// or 0 means 'force' and 1 means normal 'close'.... ????
						UserID: userNumberId,
					},
				] // NOTE: Array!
				const response = yield discardFileCheckout({
					payload,
					menuId,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(
				`(${actionName}) invalid prop exist. ${srcNumId} | ${userNumberId}`
			)
		}
	}),
})

export default MDiscardCheckout
