import { types } from "mobx-state-tree"
import EliminatingEntryModel from "./eliminating-entry.model"
import EliminatingEntryViewModel from "./eliminating-entry.view-model"
import EliminatingEntryUiHelper from "./eliminating-entry.ui-helper"
import * as api from "./eliminating-entry.apis"
import { readTrialBalance } from "../../financial-statements/store/financial-statements.api"

export const EliminatingEntryStore = ({
	apiRead,
	apiCreate,
	apiUpdate,
	apiReadTrialBalance,
	apiReadAccDetails,
	apiDeleteEE,
}: api.EliminatingEntryApiProps) =>
	types
		.compose(
			EliminatingEntryModel({
				apiRead,
				apiCreate,
				apiUpdate,
				apiReadTrialBalance,
				apiReadAccDetails,
				apiDeleteEE,
			}),
			EliminatingEntryViewModel,
			EliminatingEntryUiHelper
		)
		.named("EliminatingEntryStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const EliminatingEntryStoreInstance = EliminatingEntryStore({
	apiRead: api.read,
	apiCreate: api.create,
	apiUpdate: api.update,
	apiReadTrialBalance: readTrialBalance,
	apiReadAccDetails: api.readAccDetails,
	apiDeleteEE: api.deleteEE,
}).create(initialStore)

export default EliminatingEntryStoreInstance
