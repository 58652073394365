import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// import RoleDetailPannelHeader from "./RoleDetailPanelHeader"
import RoleDetailPanelContents from "./panel-contents/RoleDetailPanelContents"
/**
 * ---- 2-2. Pannel
 * -------- 2-2-1. PannelHeader
 * -------- 2-2-2. PannelContents
 * ------------- 2-2-1-1. ContentsControl
 * ------------- 2-2-1-2. Contents
 */
export default observer(function RoleDetailPannel() {
	const orgStore = useOrgStore()

	useEffect(() => {}, [])

	return (
		<StyledRoleDetailPannel>
			{/* ----- 2-2-1. PannelHeader ----- */}
			{/* <RoleDetailPannelHeader
				info={orgStore.orgRoles.viewRoleDetail(
					orgStore.orgRoles.selectedRole
				)}
			/> */}
			{/* ----- 2-2-1. PannelContents ----- */}
			<RoleDetailPanelContents
				data={orgStore.orgRoles.viewRoleDetail(
					orgStore.orgRoles.selectedRole
				)}
			/>
		</StyledRoleDetailPannel>
	)
})

const StyledRoleDetailPannel = styled.div`
	padding: 1rem;
	width: calc(100% - 12rem);
	/* border: 1px solid pink; */
`
