import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import { DLTooltip } from "@datalobby/components"
import { capitalizeFirstLetter } from "@datalobby/common"
import ValueWithTooltipRatio from "./ValueWithTooltipRatio"

type TableDataProps = {
	cannotCalculate: number
	late: number
	timely: number
	title: ProjectStatus
	total: number
}

export const ProgressRateTable = observer(
	({
		overallTotal,
		tableData,
	}: {
		overallTotal: number
		tableData: TableDataProps[]
	}) => {
		return (
			<StyledProgressRateTable>
				<table>
					<thead>
						<tr className="FR header">
							<th className="status">Status</th>
							<th className="total">Total</th>
							<th className="timely">Timely</th>
							<th className="late">Late</th>
							<DLTooltip
								title="Final report date is required"
								arrow
							>
								<th className="cannot-calc">?</th>
							</DLTooltip>
						</tr>
					</thead>
					<tbody>
						{tableData.map((item: any) => {
							const unarchived =
								item.title === ProjectStatus.unarchived
							const testId = item.title.toLowerCase()
							const {
								title,
								total,
								late,
								timely,
								cannotCalculate,
							} = item
							return (
								<tr
									className={
										unarchived
											? "FR body unarchived"
											: "FR body"
									}
									key={title}
								>
									<td className="status">
										{capitalizeFirstLetter(title)}
									</td>
									<td
										className="total"
										data-testid={"total-" + testId}
									>
										<ValueWithTooltipRatio
											value={total}
											total={overallTotal}
										/>
									</td>
									<td
										className="timely"
										data-testid={"timely-" + testId}
									>
										{unarchived ? (
											"-"
										) : (
											<ValueWithTooltipRatio
												title={
													title ===
													ProjectStatus.archived
														? "Archived timely"
														: title ===
														  ProjectStatus.normal
														? "Before final archive deadline date"
														: "(Not matched project status)"
												}
												value={timely}
												total={total}
											/>
										)}
									</td>
									<td
										className="late"
										data-testid={"late-" + testId}
									>
										{unarchived ? (
											"-"
										) : (
											<ValueWithTooltipRatio
												title={
													title ===
													ProjectStatus.archived
														? "Archived after the Final Deadline"
														: title ===
														  ProjectStatus.normal
														? "Expected Final Archive Date is over"
														: "(Not matched project status)"
												}
												value={late}
												total={total}
											/>
										)}
									</td>
									<td
										className="cannot-calc"
										data-testid={"cannotCalc-" + testId}
									>
										{unarchived ? (
											"-"
										) : (
											<ValueWithTooltipRatio
												title={
													title ===
													ProjectStatus.archived
														? "Archived but has no final report date"
														: title ===
														  ProjectStatus.normal
														? "Normal projects without final report date"
														: "(Not matched project status)"
												}
												value={cannotCalculate}
												total={total}
											/>
										)}
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</StyledProgressRateTable>
		)
	}
)

const StyledProgressRateTable = styled.div`
	width: 100%;
	table {
		margin-top: 1rem;
		width: 100%;
		th,
		td {
			padding: 4px 8px;
			/* border: 1px solid lightgray; */
			/* border-collapse: collapse; */
		}
		th {
			border-bottom: 1px solid lightgray;
		}
		.header {
			font-weight: bold;
		}
		/* data */
		.status {
			width: 80px;
			text-align: left;
		}
		.total {
			width: calc((100% - 80px) / 4);
			text-align: center;
		}
		.timely {
			width: calc((100% - 80px) / 4);
			text-align: center;
			color: ${(props) => props.theme.emphasis};
		}
		.late {
			width: calc((100% - 80px) / 4);
			text-align: center;
			color: ${(props) => props.theme.shared.messageRed};
		}
		.unarchived {
			color: ${(props) => props.theme.shared.messageRed};
		}
		.cannot-calc {
			width: calc((100% - 80px) / 4);
			text-align: center;
		}
	}
`
