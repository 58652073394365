import { flow } from "mobx-state-tree"

const UpdateProjInfo = (self: any, update: any) => ({
	updateProjInfo: flow(function*(payload) {
		// 0. set actionName
		const actionName = "readProjInfo"
		try {
			const response = yield update(payload)

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, open: false })

			return false
		}
	}),
})

export default UpdateProjInfo
