import { types } from "mobx-state-tree"
import ProjectDashboardModel from "./project-dashboard.model"
import ProjectDashboardViewModel from "./project-dashboard.view-model"
import * as api from "./project-dashboard.apis"

export const ProjectDashboardStore = ({ apiRead }: api.ProjDashboardApiProps) =>
	types
		.compose(ProjectDashboardModel({ apiRead }), ProjectDashboardViewModel)
		.named("ProjectDashboardStore")

export const initialStore = {
	adjustments: {
		audit: 0,
		client: 0,
		total: 0,
	},
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjectDashboardStoreInstance = ProjectDashboardStore({
	apiRead: api.read,
}).create(initialStore)

export default ProjectDashboardStoreInstance
