import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated actions
import ReadOrgToken from "./model-actions/read-org-token"
import ReadOrgInfo from "./model-actions/read-org-info"
import ReadSysInfo from "./model-actions/read-sys-info"
import ReadAssignedRoleFromGoogleSheet from "./model-actions/read-org-assigned-role-from-g-sheet"
import { OrgCheckinApiProps } from "./org-checkin.apis"
import ReadRoleSet from "./model-actions/read-role-set"
import ReadPermissionSet from "./model-actions/read-permission-set"
//
const OrgCheckinModel = ({
	apiRequestToken,
	apiReadOrgInfo,
	apiReadSysInfo,
}: OrgCheckinApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, requestToken = apiRequestToken) =>
			ReadOrgToken(self, requestToken)
		)
		.actions((self, readOrgInfo = apiReadOrgInfo) =>
			ReadOrgInfo(self, readOrgInfo)
		)
		.actions((self, readSysInfo = apiReadSysInfo) =>
			ReadSysInfo(self, readSysInfo)
		)
		.actions(ReadAssignedRoleFromGoogleSheet)
		.actions(ReadRoleSet)
		.actions(ReadPermissionSet)
		// common model actions
		.actions(CommonModelActions)

export default OrgCheckinModel
