import React, { useEffect, useState } from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLSpinnerCenterAligned } from "../../../components/basic-elements"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import EditorSnackbar from "./EditorSnackbar"
import EditorHeader from "./EditorHeader"
import EditorCheckin from "./EditorCheckin"
import { Helmet } from "react-helmet"
import styled from "styled-components"
import AuditProgramEditor from "./sub-components/AuditProgramEditor"

export enum CheckoutStatus {
	byMe = "CHECKOUT_BY_ME",
	byOthers = "CHECKOUT_BY_OTHERS",
	noOneUse = "NO_ONE_USE",
}

export default observer(function FileEditor() {
	const store = useRootStore()
	const editorStore = store.editor

	/**
	 *  NOTE: Do not use useParams of react-router-dom
	 * because it takes the first section between the '/' slash
	 * encrypted URL has slashes in it, so we need to get it by window.localtion.pathname
	 */

	const { fileTitle, fileAliasId, srcFormat } = editorStore.urlInfo
	const { isTrashed } = editorStore.fileStatus

	console.log("editorStore.urlInfo ___", editorStore.urlInfo)

	// to show spinner during the iframe be loaded
	const [loading, setLoading] = useState(true)

	const iframeUrl = editorStore.iframeUrl

	useEffect(() => {
		// this one is not working when the iframe URL does not exist
		document
			.querySelector("iframe")
			?.addEventListener("load", function (e) {
				setLoading(false)
			})
	}, [iframeUrl])

	return (
		<>
			{editorStore.editorCheckin === ActionStatus.success ? (
				<StyledFileEditor>
					<Helmet>
						<meta charSet="utf-8" />
						<title>
							{fileAliasId
								? `(${fileAliasId}) ${fileTitle}`
								: "Audit Lobby"}
						</title>
					</Helmet>
					{isTrashed ? (
						<div className="access-denied">
							<h2>Access Denied</h2>
							<br />
							<p>
								Sorry, this file has been deleted and cannot be
								accessed.
							</p>
						</div>
					) : (
						<>
							<EditorHeader />
							{srcFormat === "AUDIT_PROGRAM" ? (
								<AuditProgramEditor
									partialStore={editorStore}
									fromDialog={false}
								/>
							) : (
								<div className="iframe-container">
									{iframeUrl ? (
										<>
											{loading && (
												<DLSpinnerCenterAligned />
											)}
											<iframe
												data-testid="iframe-file-editor"
												src={iframeUrl}
												title="dl-file-editor"
												width="100%"
												height="100%"
												// below option doesn't allow scroll or changing tabs...
												// style={{ pointerEvents: "none" }}
											/>
										</>
									) : (
										<div>Waiting for iframe URL...</div>
									)}
								</div>
							)}
						</>
					)}
				</StyledFileEditor>
			) : (
				<EditorCheckin />
			)}
			<EditorSnackbar />
		</>
	)
})

const StyledFileEditor = styled.div`
	.checkin-out-note {
		position: absolute;
		background-color: ${(props) => props.theme.background};
		box-shadow: ${(props) => props.theme.shadowRegularLight};
		z-index: 999;
		width: 300px;
		height: 200px;
		top: calc(50vh - 100px);
		left: calc(50vw - 150px);
		padding: 1rem;
		.close-btn {
			margin-bottom: 0.6rem;
			cursor: pointer;
		}
		.note-text {
		}
	}
	.iframe-container {
		width: 100%;
		height: calc(100vh - 3rem);
		iframe {
			border: none;
		}
	}
	.access-denied {
		margin-top: 8rem;
		text-align: center;
	}
`
