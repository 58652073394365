import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import RoleDetailTabs from "./sub-components/RoleDetailTabs"
import RoleDetailPanel from "./sub-components/RoleDetailPanel"

export default observer(function ProjectSetupRoleDetail() {
	const projStore = useProjStore()

	/**
	 * Project Permission Detail Screen Layout
	 * - 1. PageHeader
	 * - 2. TabsAndPanel
	 * ---- 2-1. Tabs
	 * ---- 2-2. Panel
	 * -------- 2-2-1. PanelHeader
	 * -------- 2-2-2. PanelContents
	 * ------------- 2-2-1-1. ContentsControl
	 * ------------- 2-2-1-2. Contents
	 */
	return (
		<StyledProjRoleDetail>
			{/* ----- 1. PageHeader (omitted) ----- */}

			{/* ----- 2. TabsAndPanel ----- */}
			<div className="vertical-tabs-container FR">
				{/* ----- 2-1. Tabs ----- */}
				<RoleDetailTabs data={projStore.roles.roleSet.roles} />
				{/* ----- 2-2. Panel ----- */}
				<RoleDetailPanel />
			</div>
		</StyledProjRoleDetail>
	)
})

const StyledProjRoleDetail = styled.div`
	/* border: 1px solid pink; */

	.control-area {
		height: ${(props) => props.theme.shared.baseUnit};
		border-bottom: 1px solid ${(props) => props.theme.secondary};
	}
`
