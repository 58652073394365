import { types } from "mobx-state-tree"

export enum RationaleType {
	wp = "WP",
	pf = "PF",
}

export const Rationale = types.model({
	type: types.enumeration("Rationale Type", ["WP", "PF"]),
	podId: types.string, // [WorkpaperID] ex) 4505
	aliasId: types.string, // [Reference] ex) "sdf"
	description: types.string, // [Description] ex) "sfsdfa"
	modifiedAt: types.string, // [ModifiedDate] ex) "2020-01-11T12:45:20.188312+00:00"
	modifiedBy: types.string, // [ModifiedBy] ex) "cskim"
	rationaleDescription: types.optional(types.string, ""), // [RationaleDescription] ex) ""
	rationaleAt: types.union(types.null, types.string), // [RationaleDate] ex) null
	rationaleBy: types.optional(types.string, ""), // [RationaleBY] ex) ""
	rationaleId: types.string, // [RationaleID] ex) 0
	isModifiedAfterRationale: types.boolean, // [IsModifiedAfterRationale] ex) false
})
