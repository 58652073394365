import React from "react"
import DLMenusWithMultipleAction from "../../../../../components/basic-elements/menus/DLMenusWithMultipleAction"
import { DLIconButton } from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal, mdiFileExcel, mdiImageOutline } from "@mdi/js"
import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default function CardViewPageContextMenu({
	getTotalData,
	downloadAsImg,
	fileName,
	permission,
}: {
	getTotalData: any
	downloadAsImg: any
	fileName: string
	permission: PermissionAsObjectProps
}) {
	const menuOptions = [
		{
			label: "Download Filtered Project (.xlsx)",
			value: "export-xlsx-all",
			available: permission.export ? true : false,
			icon: <Icon path={mdiFileExcel} color="green" size={0.8} />,
			clickEvent: () => {
				let proceed = window.confirm(
					`Do you really want to export these ${fileName} as .xlsx?`
				)
				if (proceed) {
					handleExport(getTotalData(), fileName)
				} else {
					return
				}
			},
		},
		{
			label: "Download Screen as image",
			value: "download-dashboard-img",
			available: permission.export ? true : false,
			icon: <Icon path={mdiImageOutline} color="green" size={0.8} />,
			clickEvent: () => downloadAsImg(),
		},
	]
	return (
		<DLMenusWithMultipleAction
			remoteButton={
				<DLIconButton eleTestId="page-ctx-btn">
					<Icon path={mdiDotsHorizontal} size={0.8} />
				</DLIconButton>
			}
			menuOptions={menuOptions}
			eleTestId="page-ctx-menu"
			eleId="page-ctx-menu"
		/>
	)
}
