// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetAvailableFinSubGroups = (self: any) => ({
	getAvailableFinSubGroups(financialGroupId: number, projectId: string) {
		// 0. set actionName
		const actionName = "getAvailableFinSubGroups"
		// 1. set response cases
		// 2. set response
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Fetching available sub groups is in progress",
			open: false,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			financialGroupId,
			clientId,
		}

		// 3) request API call to Model
		self.readAvailableFinSubGroups(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200) {
						//
						const fetchedData = response.data
						let reOrganizedSubGroups: any[] = []
						fetchedData.map((item: any) => {
							reOrganizedSubGroups.push({
								fsSubGroupId: item.id,
								fsSubGroupName: item.financialSubGroup,
								fsSubGroupMapId: item.mapId,
								// message: item.Message,
								// status: item.Status.toString(),
							})
						})
						self.setAvailableSubGroups(reOrganizedSubGroups)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully fetched financial available sub groups",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAvailableFinSubGroups
