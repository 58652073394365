import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToString, IdType } from "../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 */
const GetSetupGroupList = (self: any) => ({
	getSetupGroupList() {
		// 0.
		const actionName = "getSetupGroupList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const payload = {
			// PageIndex: 1
			PageSize: 1000,
			// GroupID: 0,
			// UserID: 0,
			// ClientID: 0
		}
		self.readGroupList(payload)
			.then((response: any) => {
				// ConsoleLog(["readGroupList response", response])
				// if success
				if (response.data.Status === 1) {
					// empty existing stored list
					// re-organize response data
					const existingGroupList = response.data.Grplst
					// ConsoleLog("Grplst", existingGroupList)
					console.time()
					let groupList: any[] = []
					existingGroupList.map((group: any) => {
						const reOrganizedGroup = {
							groupId: idToString(group.GroupID, IdType.group), // [GroupID], ex) GroupID: 805
							groupName: group.GroupName, // [GroupName], ex) GroupName: "(0) Release 본부 update"
							userId: idToString(group.UserID, IdType.user), // [UserID], ex) UserID: 16 // TODO: @Prudhvi-0817: Please use idToString
							createdBy: group.CreatedBy ? group.CreatedBy : "", // [CreatedBy], ex) CreatedBy: "cskim 10/14/2019"
							userCount: group.UserCount, // [UserCount], ex) UserCount: 4
							assignedUsers: group.AssignedUsers, // [AssignedUsers], ex) AssignedUsers: "홍길동,전우치,신사임당"
							clientsCount: group.ClientsCount, // [ClientsCount], ex) ClientsCount: 3
							assignedClients: group.AssignedClients,
						}
						groupList.push(reOrganizedGroup)
					})
					self.setGroupList(groupList)
					self.setNeedRefresh(false)
					console.timeEnd()
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetSetupGroupList
