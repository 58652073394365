import React, { useEffect } from "react"
import { Redirect } from "react-router-dom"
import { observer } from "mobx-react-lite"
import ResetPassword from "./ResetPassword"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { useLobbyStore } from "../../../stores/lobby-store/lobby-store.provider"

export default observer(function ResetPasswordController(props: any) {
	const lobbyStore = useLobbyStore()
	const actionName = "resetUserPassword"

	useEffect(() => {
		// lobbyStore.resetPassword.responses.setResponse(actionName, {
		// 	actionName,
		// 	status: ActionStatus.standby,
		// })
		const token = props.location.search.split("?token=")[1] // Need To update
		localStorage.removeItem("resetPasswordToken")
		if (token) {
			localStorage.setItem("resetPasswordToken", JSON.stringify(token))
		}
	}, [])

	return (
		<>
			{lobbyStore.resetPassword.getActionStatus(actionName) ===
			"SUCCESS" ? (
				<Redirect to="/login" />
			) : (
				<ResetPassword
					partialStore={lobbyStore.resetPassword}
					actionStatus={lobbyStore.resetPassword.getActionStatus(
						actionName
					)}
				/>
			)}
		</>
	)
})
