import React from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

// NOTE: HAVE TO CHECK CONSISTENCY OF TEMP AND FINAL!!!
export const DLI18nTemp = ({
	id,
	defaultValue,
	value,
	eleClassName,
	eleTestId,
}: {
	id?: string // because some parts get translated value only without i18n ID
	value: string
	defaultValue: string
	eleClassName?: string
	eleTestId?: string
}) => {
	return (
		<div
			className={eleClassName ? eleClassName + " dli18n" : "dli18n"}
			data-i18n-id={id}
			data-testid={eleTestId}
		>
			{value ? value : defaultValue}
		</div>
	)
}

export const DLI18nFinal = ({
	id,
	defaultValue,
	value,
	eleClassName,
	eleTestId,
}: {
	id: string
	defaultValue: string
	value: string
	eleClassName?: string
	eleTestId?: string
}) => {
	const store = useRootStore()
	// How to minimize the i18n related API request?
	return (
		<div
			className={eleClassName ? eleClassName + " dli18n" : "dli18n"}
			data-i18n-id={id}
			data-testid={eleTestId}
		>
			{value ? value : defaultValue}
		</div>
	)
}
