import { flow } from "mobx-state-tree"
import { OrgGroupApiCallProps } from "../../group-module/org-group-shared-apis.calling-props"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

const DeleteProject = (
	self: any,
	deleteProject: OrgGroupApiCallProps["apiDeleteProject"]
) => ({
	deleteProject: flow(function* (projId: string, parentId: string) {
		const actionName = "deleteProject"
		// 1.
		if (projId === undefined || parentId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. projId: ${projId} / parentId: ${parentId}`
			)
			return
		}
		// 2.
		const projNumberId = idToNumber(projId, IdType.project)
		const cabinetNumberId = idToNumber(parentId, IdType.cabinet)
		if (
			typeof projNumberId === "number" &&
			typeof cabinetNumberId === "number"
		) {
			try {
				let params = {
					ClientId: projNumberId,
					CabinetId: cabinetNumberId,
					Type: "Client", // seems fixed
					// UserId: 16
				}
				const response = yield deleteProject(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${projNumberId} / ${cabinetNumberId}`)
		}
	}),
})

export default DeleteProject
