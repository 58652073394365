import { flow } from "mobx-state-tree"

const DeleteOrgTrash = (self: any, remove: any) => ({
	deleteOrgTrashItem: flow(function*(params) {
		// alert(
		//     "Do you really want to delete this file permanently? This file cannot be restored"
		// )
		const actionName = "deleteOrgTrashItem"
		try {
			const response = yield remove(params)
			// ConsoleLog("updateOrgTrashItem response", response)

			return response
		} catch (error) {
			// ConsoleLog("updateOrgTrashItem error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default DeleteOrgTrash
