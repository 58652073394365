import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import FSSetup from "./FSSetup"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import ProjNoPermissionOnMenu from "../../proj-access-control/ProjNoPermissionOnMenu"

export default observer(function FSSetupController() {
	const store = useRootStore()
	const projStore = useProjStore()
	const menuId = DLProjSubMenus.fs_setup
	const actionName = "getFinancialStatementType"

	const isLocked = projStore.projInfo.projInfo.isLocked
	const isArchived = projStore.projInfo.projInfo.isArchived
	const menuAccess = projStore.checkin.checkAccess(menuId)
	const permission = projStore.checkin.getActionsAsObject(
		menuId,
		isLocked,
		isArchived
	)
	const needRefresh = projStore.fsSetup.needRefresh

	useEffect(() => {
		menuAccess &&
			needRefresh &&
			projStore.fsSetup.getFinancialStatementType()
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<FSSetup
					partialStore={projStore.fsSetup}
					contentsHeight={store.ui.contentsAreaHeight}
					fetchingStatus={projStore.fsSetup.getActionStatus(
						actionName
					)}
					permission={permission}
				/>
			) : (
				<ProjNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
