// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import FileSaver from "file-saver"

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const ExportSignOffHistoryZip = (self: any) => ({
	exportSignOffHistoryZip() {
		// 0.
		const actionName = "exportSignOffHistoryZip"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		//
		self.downloadSignOffHistoryZip()
			.then((response: any) => {
				if (response.status === 200) {
					// console.log(actionName+ "__response " + response)
					const contentType = response.headers["content-type"]
					//
					const fetchedFile = new Blob([response.data], {
						type: contentType,
					})
					FileSaver.saveAs(fetchedFile, "Signoff History" + ".zip")

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ExportSignOffHistoryZip
