import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

import { EngType } from "../data-models/org-setup-engTypes.data-models"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetEngTypeList = (self: any) => ({
	getEngTypeList() {
		// 0.
		const actionName = "getEngTypeList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const payload = {
			PageSize: 1000,
		}
		self.readEngTypeList(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					const fetchedEngTypes = response.data.EngagementList
					let list: any[] = []
					fetchedEngTypes.map((engType: any) => {
						const reOrganizedEngType = {
							createdAt: engType.CreatedDate, // [CreatedDate], ex) "10/14/19 6:32:53 PM"
							createdBy: engType.CreatedUser
								? engType.CreatedUser
								: "(Unknown)", // [CreatedUser], ex) "cskim 10/14/2019"
							id: idToString(
								engType.EngagementID,
								IdType.engType
							), // [EngagementID], ex) 159
							name: engType.EngagementName,
						}
						list.push(reOrganizedEngType)
					})
					self.setEngTypeList(list)
					self.setNeedRefresh(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Success to get the engagement type list",
						open: true,
						autoHide: true,
					})
				} else {
					// update fail case response (200 but fail)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,

						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetEngTypeList
