import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { DLDialog, ConsoleLog } from "../../../../../components/basic-elements"
import { ProjectInfoContainer } from "./ProjectInfoContainer"
import PreProcessPDF from "./PreProcessPDF"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { saveLatestProjToken } from "../../../../../library/token-utils"

export default observer(function PreProcessDialog({ partialStore }: any) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	// const partialStore = orgStore.archiveMgmt

	const dnt = store.global.getDntFormat
	const projectId = partialStore.selectedProj
	const projectInfo = partialStore.getClientInfoById(
		projectId,
		dnt.dateFormat,
		dnt.timeZone,
		dnt.timeFormat
	)

	console.log(projectInfo, "projectInfo")

	useEffect(() => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const projNumId = idToNumber(projectId, IdType.project)

		// to hide the pdf file list
		partialStore.responses.setResponse("getPreConditionStatus", {
			actionName: "getPreConditionStatus",
			status: ActionStatus.standby,
		})

		partialStore.responses.setResponse("regenPreProcessPdfs", {
			actionName: "regenPreProcessPdfs",
			status: ActionStatus.standby,
		})

		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
					} else {
						ConsoleLog(
							"Cannot request the pre-process condition status. Failed to request project token"
						)
					}
					// NOTE: After API update, remain only the below one line
					partialStore.getPreConditionStatus()
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the pre-process condition status. Project ID or User ID is incorrect."
			)
		}
	}, [])

	const actionStatus = partialStore.getActionStatus
	const preProcessStatus = partialStore.preConditionData.isPreProcessed

	ConsoleLog("preProcessStatus " + preProcessStatus)
	return (
		<DLDialog
			eleTestId="preprocess-dialog"
			isOpen={partialStore.preProcessDialog}
			setIsOpen={partialStore.setPreProcessDialog}
			showCloseBtn={true}
			dialogTitle="Pre-Process PDF"
			dialogContents={
				<PreProcessDialogForm
					projInfo={projectInfo}
					partialStore={partialStore}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			showSpinner={
				actionStatus("regenPreProcessPdfs") === "LOADING" ||
				actionStatus("getPreConditionStatus") === "LOADING"
			}
			cannotUpdate={
				actionStatus("regenPreProcessPdfs") === "LOADING" ||
				actionStatus("getPreConditionStatus") === "LOADING"
			}
			// NOTE: Use the action button on the PreProcessPDF component
		/>
	)
})

const PreProcessDialogForm = ({
	projInfo,
	partialStore,
}: {
	projInfo: any
	partialStore: any
}) => {
	return (
		<div>
			<ProjectInfoContainer projInfo={projInfo} />
			<div style={{ height: "3rem" }} />
			<PreProcessPDF partialStore={partialStore} />
			<div style={{ height: "3rem" }} />
		</div>
	)
}
