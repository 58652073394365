import { flow } from "mobx-state-tree"
import { OrgRiskAssessmentApiProps } from "../org-risk-assessment.apis"

const UpdateRiskAssessment = (
	self: any,
	apiUpdate: OrgRiskAssessmentApiProps["apiUpdate"]
) => ({
	updateRiskAssessment: flow(function*(payload: {
		RiskAssessmentName: string
		RiskAssessmentID: number
	}) {
		const actionName = "updateRiskAssessment"
		if (typeof payload.RiskAssessmentID === "number") {
			try {
				const response = yield apiUpdate(payload)

				// ConsoleLog("requestRiskAssessmentList response", response)
				return response
			} catch (error) {
				// console.error("requestRiskAssessmentList error", error)
				self.handleModelError({ actionName, error, openSnackbar: true })

				return false
			}
		} else {
			alert(
				`(${actionName}) invalid prop exist. ${payload.RiskAssessmentID}`
			)
		}
	}),
})

export default UpdateRiskAssessment
