import { flow } from "mobx-state-tree"
import { OrgSetupArchPolicyApiProps } from "../org-archive-policies.apis"

const RequestMoveArchiveCondition = (
	self: any,
	moveArchiveCondition: OrgSetupArchPolicyApiProps["apiMoveArchiveCondition"]
) => ({
	requestMoveArchiveCondition: flow(function* (
		policyId: number,
		conditions: {
			id: number
			required: boolean
		}[]
	) {
		const actionName = "requestMoveArchiveCondition"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		let mandatoryList: any[] = []
		let optionalList: any[] = []
		conditions.map((condition) => {
			if (condition.required) {
				mandatoryList.push({
					ArchivePolicyId: policyId,
					ArchivePreConditionId: condition.id,
				})
			} else {
				optionalList.push({
					ArchivePolicyId: policyId,
					ArchivePreConditionId: condition.id,
				})
			}
		})
		const payload = {
			MandatoryList: mandatoryList,
			NonMandatoryList: optionalList,
		}
		try {
			const response = yield moveArchiveCondition(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default RequestMoveArchiveCondition
