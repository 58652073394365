import { types, IAnyModelType } from "mobx-state-tree"
import { FlatItemType } from "../../../../../../common-models/enumerations/common-enums"

export const Signoff = types.model({
	date: types.string,
	signoffBy: types.string,
})

export const File = types.model({
	fileId: types.string,
	aliasId: types.string,
	format: types.string,
	title: types.string,
	hasSignoff: types.boolean,
	prepare: types.union(types.null, Signoff),
	review: types.union(types.null, Signoff),
	epReview: types.union(types.null, Signoff),
	cpReview: types.union(types.null, Signoff),
	qcReview: types.union(types.null, Signoff),
	index: types.number,
	isActive: types.boolean,
})

export const Folder = types.model({
	folderId: types.string,
	folderTitle: types.string,
	type: types.string,
	parentFolder: types.union(types.null, types.undefined, types.string),
	files: types.array(File),
	subFolders: types.optional(
		types.maybe(types.late((): IAnyModelType => Folder)),
		[]
	),
})

type SignoffModelProps = {
	at: string
	by: string
	role: string
}

const SignoffModel = types.model({
	at: types.string,
	by: types.string,
	role: types.string,
})

export type SignoffModel2Props = {
	isNegative?: boolean
	by: string
	role: string
}

const SignoffModel2 = types.model({
	isNegative: types.optional(types.boolean, false),
	by: types.string, // user name
	role: types.string,
})

export type SignoffRowProps = {
	prepare: null | SignoffModelProps
	review: null | SignoffModelProps
	epReview: null | SignoffModelProps
	cpReview: null | SignoffModelProps
	qcReview: null | SignoffModelProps
}

const SignoffRow = types.model({
	prepare: types.union(types.null, SignoffModel),
	review: types.union(types.null, SignoffModel),
	epReview: types.union(types.null, SignoffModel),
	cpReview: types.union(types.null, SignoffModel),
	qcReview: types.union(types.null, SignoffModel),
})

export type SignoffHistoryFlatItemProps = {
	id: string
	parentId: string | null
	parentName: string | null
	title: string
	aliasId: string | undefined
	format: string | undefined
	type: FlatItemType
	expanded: boolean | undefined
	lastSignoffs: SignoffRowProps | undefined
	signoffs: SignoffRowProps[] | null
}

export const SignoffHistoryFlatItem = types.model({
	id: types.string,
	parentId: types.union(types.null, types.string),
	parentName: types.union(types.null, types.string),
	title: types.string,
	aliasId: types.union(types.string, types.undefined), // undefined for folder
	format: types.union(types.string, types.undefined), // undefined for folder
	type: types.enumeration<FlatItemType>(Object.values(FlatItemType)), // FOLDER | FILE
	expanded: types.union(types.boolean, types.undefined),
	lastSignoffs: types.union(types.undefined, SignoffRow), // undefined for folder
	signoffs: types.union(types.null, types.array(SignoffRow)), // null for folder
})

export type SignoffDetailRowProps = {
	at: string
	prepare: null | SignoffModel2Props
	review: null | SignoffModel2Props
	epReview: null | SignoffModel2Props
	cpReview: null | SignoffModel2Props
	qcReview: null | SignoffModel2Props
}

const SignoffDetailRow = types.model({
	at: types.string,
	prepare: types.union(types.null, SignoffModel2),
	review: types.union(types.null, SignoffModel2),
	epReview: types.union(types.null, SignoffModel2),
	cpReview: types.union(types.null, SignoffModel2),
	qcReview: types.union(types.null, SignoffModel2),
})

export type SignoffHistoryDetailProps = {
	fileId: string
	history: [] | SignoffDetailRowProps[]
}

export const SignoffHistoryDetail = types.model({
	fileId: types.identifier,
	history: types.optional(types.array(SignoffDetailRow), []),
})
