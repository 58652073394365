import React, { useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLDatepicker,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"

export default observer(function RelayAssignUserDialog({
	open,
	setOpen,
	title,
	repairMode,
	assignUserId,
}: {
	open: boolean
	setOpen: any
	title: any
	repairMode: boolean
	assignUserId: number | string
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const archStore = orgStore.archiveMgmt

	const [date, setDate] = useState(null)

	const handleClick = () => {
		if (typeof assignUserId === "number") {
			let targetList: any[] = []
			if (repairMode) {
				targetList = archStore.replicaTargetList
			} else {
				targetList = archStore.zipUrlList
			}
			archStore.readonlyAssignAtOnce(targetList, date, assignUserId)
			setOpen(false)
		} else {
			console.log("User ID is incorrect", assignUserId)
		}
	}

	return (
		<DLDialog
			eleTestId="relay-assign-user-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showCloseBtn={true}
			dialogTitle={title}
			dialogContents={
				<div>
					<p>User ID: {assignUserId}</p>
					<DLDatepicker
						eleTestId="exp-date-field"
						onChange={(date: any) => setDate(date)}
						disablePast
						format={store.global.dateFormat.value}
					/>
				</div>
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			// main action
			actionBtn="Proceed"
			handleAction={() => handleClick()}
			actionReady={date !== null}
		/>
	)
})
