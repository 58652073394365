import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import { DLButton } from "../../../../../../components/basic-elements"
import { CardType } from "../../../table-view/store/data-models/dashboard-table-view.data-model"
import { StackedHorizontalBarChart as Bar } from "../../../../../../components/basic-elements/chart/StackedHorizontalBarChart"
import BarWithRatio from "../../../../../../components/basic-elements/chart/StackedBarChartWithRatio"
import TopicDetailTable from "./TopicDetailTable"

// {
//     engTypeA: {
//         title: "engTypeA",
//         data: []
//     }
// }

// NOTE: update 'any' as proper type definition
const groupBy = (data: any, groupBy: string) => {
	let result: { [x: string]: { title: string; data: any } } = {}
	if (groupBy !== "epUsers" && groupBy !== "qcUsers") {
		data.map((item: any) => {
			let targetValue: string = item[groupBy]
			if (result[targetValue] === undefined) {
				result[targetValue] = {
					title: targetValue,
					data: [item],
				}
			} else {
				result[targetValue].data.push(item)
			}
		})
		return result
	} else {
		// when the required groupBy is epUsers or qcUsers
		data.map((item: any) => {
			let targetUsers: { aliasId: string; id: string; name: string }[] =
				item[groupBy]
			if (targetUsers.length === 0) {
				// nothing
			} else {
				targetUsers.map((user) => {
					const label = user.name // + " (" + user.aliasId + ")"
					if (result[label] === undefined) {
						result[label] = {
							title: label,
							data: [item],
						}
					} else {
						result[label].data.push(item)
					}
				})
			}
		})
		return result
	}
}

export const TopicDetailCharts = observer(
	({
		partialStore,
		topic,
		data,
		showDetail,
		widthStandard,
		mainHeight,
		subHeight,
		showTable,
		orderByName,
		orderByCount,
		topicTitle,
	}: {
		partialStore: any
		topic: CardType
		data: any
		showDetail: boolean
		widthStandard: number
		mainHeight: number
		subHeight: number
		showTable: boolean
		orderByName: boolean | undefined
		orderByCount: boolean | undefined
		topicTitle: string
	}) => {
		const setTargetProjects = (projects: any, dialogTitle: string) => {
			// ConsoleLog("projects of set target projects", projects)
			const deepCopy = JSON.parse(JSON.stringify(projects))
			partialStore.setHBarDialogTitle(dialogTitle)
			partialStore.setHBarTableList(deepCopy)
			partialStore.setHBarTableDetailsDialogOpen(true)
		}

		let groupingStandard = ""
		if (topic === CardType.engType) {
			groupingStandard = "engTypeName"
		} else if (topic === CardType.group) {
			groupingStandard = "groupName"
		} else if (topic === CardType.epUser) {
			groupingStandard = "epUsers"
		} else if (topic === CardType.qcUser) {
			groupingStandard = "qcUsers"
		} else if (topic === CardType.periodType) {
			groupingStandard = "periodName"
		}

		const groupedData = groupBy(data, groupingStandard)

		const [list, setList] = useState<any[]>([])

		let totalBarChartData: {
			value: number
			// color: string
			label: string
			testId: string
		}[] = []
		let topicTotal = 0
		list.map((item: any, i: number) => {
			totalBarChartData.push({
				value: item.total,
				label: item.label,
				testId: item.label.toLowerCase().replace(/ /g, "-") + "-total",
			})
			topicTotal = topicTotal + item.total
		})

		useEffect(() => {
			let defaultList: any[] = []
			for (var i in groupedData) {
				const item = groupedData[i]
				const { title, data } = item

				const normal = data.filter(
					(item: any) => item.statusName === ProjectStatus.normal
				)
				const archived = data.filter(
					(item: any) => item.statusName === ProjectStatus.archived
				)
				const unarchived = data.filter(
					(item: any) => item.statusName === ProjectStatus.unarchived
				)

				defaultList.push({
					label: title,
					total: data.length,
					totalData: data,
					//
					normalData: normal,
					archivedData: archived,
					unarchivedData: unarchived,
					//
					normal: normal.length,
					archived: archived.length,
					unarchived: unarchived.length,
					//
					// count late
					normalLate: normal.filter((item: any) => item.isLate)
						.length,
					archivedLate: archived.filter((item: any) => item.isLate)
						.length,
					unarchivedLate: unarchived.filter(
						(item: any) => item.isLate
					).length,
					//
					// count timely
					normalTimely: normal.filter(
						(item: any) => item.isLate === false
					).length,
					archivedTimely: archived.filter(
						(item: any) => item.isLate === false
					).length,
					unarchivedTimely: unarchived.filter(
						(item: any) => item.isLate === false
					).length,
					//
					// count cannot calculation
					normalCannotCalc: normal.filter(
						(item: any) => item.isLate === undefined
					).length,
					archivedCannotCalc: archived.filter(
						(item: any) => item.isLate === undefined
					).length,
					unarchivedCannotCalc: unarchived.filter(
						(item: any) => item.isLate === undefined
					).length,
				})
			}

			// ConsoleLog("orderByName:", orderByName, "/orderByCount:", orderByCount)
			if (orderByName && orderByCount === undefined) {
				defaultList.sort((a: any, b: any) =>
					b.label < a.label ? 1 : -1
				)
				totalBarChartData.sort((a: any, b: any) =>
					b.label < a.label ? 1 : -1
				)
			} else if (orderByName === false && orderByCount === undefined) {
				defaultList.sort((a: any, b: any) =>
					b.label > a.label ? 1 : -1
				)
				totalBarChartData.sort((a: any, b: any) =>
					b.label > a.label ? 1 : -1
				)
			} else if (orderByCount && orderByName === undefined) {
				defaultList.sort((a: any, b: any) => b.total - a.total)
				totalBarChartData.sort((a: any, b: any) => b.value - a.value)
			} else if (orderByCount === false && orderByName === undefined) {
				defaultList.sort((a: any, b: any) => a.total - b.total)
				totalBarChartData.sort((a: any, b: any) => a.value - b.value)
			}

			setList(defaultList)
		}, [data, orderByName, orderByCount])

		const overallTotal = data.length
		const unit = widthStandard / topicTotal // widthStandard / overallTotal

		let tableDataToExport: any = []
		list.map((item: any) => {
			tableDataToExport.push({
				label: item.label,
				total: item.total,
				//
				normal: item.normal,
				archived: item.archived,
				unarchived: item.unarchived,
				//
				normalLate: item.normalLate,
				archivedLate: item.archivedLate,
				unarchivedLate: item.unarchivedLate,
				//
				normalTimely: item.normalTimely,
				archivedTimely: item.archivedTimely,
				unarchivedTimely: item.unarchivedTimely,
				//
				normalCannotCalc: item.normalCannotCalc,
				archivedCannotCalc: item.archivedCannotCalc,
				unarchivedCannotCalc: item.unarchivedCannotCalc,
			})
		})
		partialStore.setDetailViewTableTitle(topic + " Dashboard")
		partialStore.setDetailViewTableData(tableDataToExport)

		return showTable ? (
			<TopicDetailTable
				data={list}
				showDetail={showDetail}
				setTargetProjects={setTargetProjects}
			/>
		) : (
			<StyledTopicDetailCharts>
				{/* <div className="FR AC JSB">
					<div className="overall-total-count">
						Total Projects: {overallTotal}
					</div>
					<DLButton
						eleTestId=
						color={showDetail ? "primary" : "default"}
						
					>
						View Detail
					</DLButton>
				</div> */}
				<div className="overall-total">
					<div className="group-name">
						Topic related projects: {topicTotal}
						<span className="partition">|</span>
						<span className="topic-count">{topicTitle}</span>:{" "}
						{list.length}
					</div>
					<div className="FR">
						<div className="sub-label"></div>
						<Bar
							totalWidth={widthStandard}
							unit={unit}
							height={mainHeight}
							// showCountInBar
							data={totalBarChartData}
							showTooltip
						/>
					</div>
				</div>

				{list.map((item: any, i: number) => {
					// ConsoleLog("item", item)
					const testId = item.label.toLowerCase().replace(/ /g, "-")
					return (
						<div
							className="chart-group"
							data-testid={"chart-group-" + testId}
							key={i}
						>
							<div className="group-name">{item.label}</div>
							<div className="FR">
								<div className="chart-in-group">
									<BarWithRatio
										label="Total"
										totalBarWidth={widthStandard}
										unit={unit}
										height={mainHeight}
										data={[
											{
												value: item.total,
												color: "gray",
												label: item.label + " Total",
												testId: testId + "-total",
												clickHandler: () =>
													setTargetProjects(
														item.totalData,
														`${item.label} - Total`
													),
											},
										]}
										targetCount={item.total}
										totalCount={overallTotal}
										testId={testId + "-total"}
										eleClassName="group-total"
									/>
									<BarWithRatio
										subValue
										label="Archived"
										totalBarWidth={widthStandard}
										unit={unit}
										height={subHeight}
										data={
											showDetail
												? [
														{
															value: item.archivedLate,
															color: "gray",
															label: "Late",
															testId:
																testId +
																"-archived-late",
															clickHandler: () =>
																setTargetProjects(
																	item.archivedData.filter(
																		(
																			item: any
																		) =>
																			item.isLate
																	),
																	`${item.label} - Archived Late`
																),
														},
														{
															value: item.archivedTimely,
															color: "lightgray",
															label: "Timely",
															testId:
																testId +
																"-archived-timely",
															clickHandler: () =>
																setTargetProjects(
																	item.archivedData.filter(
																		(
																			item: any
																		) =>
																			item.isLate ===
																			false
																	),
																	`${item.label} - Archived Timely`
																),
														},
														{
															value: item.archivedCannotCalc,
															color: "purple",
															label: "Cannot Calculate",
															testId:
																testId +
																"-archived-cannotCalc",
															clickHandler: () =>
																setTargetProjects(
																	item.archivedData.filter(
																		(
																			item: any
																		) =>
																			item.isLate ===
																			undefined
																	),
																	`${item.label} - Archived Cannot Calculate`
																),
														},
												  ]
												: [
														{
															value: item.archived,
															color: "lightgray",
															label:
																item.label +
																" Archived",
															testId:
																testId +
																"-archived-total",
															clickHandler: () =>
																setTargetProjects(
																	item.archivedData,
																	`${item.label} - Archived`
																),
														},
												  ]
										}
										targetCount={item.archived}
										totalCount={item.total}
										testId={testId + "-archived"}
										eleClassName="archived sub-chart-row"
									/>
									<BarWithRatio
										subValue
										label="Normal"
										totalBarWidth={widthStandard}
										unit={unit}
										height={subHeight}
										data={
											showDetail
												? [
														{
															value: item.normalLate,
															color: "gray",
															label: "Late",
															testId:
																testId +
																"-normal-late",
															clickHandler: () =>
																setTargetProjects(
																	item.normalData.filter(
																		(
																			item: any
																		) =>
																			item.isLate
																	),
																	`${item.label} - Normal Late`
																),
														},
														{
															value: item.normalTimely,
															color: "lightgray",
															label: "Timely",
															testId:
																testId +
																"-normal-timely",
															clickHandler: () =>
																setTargetProjects(
																	item.normalData.filter(
																		(
																			item: any
																		) =>
																			item.isLate ===
																			false
																	),
																	`${item.label} - Normal Timely`
																),
														},
														{
															value: item.normalCannotCalc,
															color: "purple",
															label: "Cannot Calculate",
															testId:
																testId +
																"-normal-cannotCalc",
															clickHandler: () =>
																setTargetProjects(
																	item.normalData.filter(
																		(
																			item: any
																		) =>
																			item.isLate ===
																			undefined
																	),
																	`${item.label} - Normal Cannot Calculate`
																),
														},
												  ]
												: [
														{
															value: item.normal,
															color: "lightgray",
															label:
																item.label +
																" Normal",
															testId:
																testId +
																"-normal-total",
															clickHandler: () =>
																setTargetProjects(
																	item.normalData,
																	`${item.label} - Normal`
																),
														},
												  ]
										}
										targetCount={item.normal}
										totalCount={item.total}
										testId={testId + "-normal"}
										eleClassName="normal sub-chart-row"
									/>
									<BarWithRatio
										subValue
										label="Unarchived"
										totalBarWidth={widthStandard}
										unit={unit}
										height={subHeight}
										data={
											showDetail
												? [
														{
															value: item.unarchivedLate,
															color: "gray",
															label:
																item.label +
																" Unarchived (Late archived projects)",
															testId:
																testId +
																"-unarchived-late",
															clickHandler: () =>
																setTargetProjects(
																	item.unarchivedData.filter(
																		(
																			project: any
																		) =>
																			project.isLate
																	),
																	`${item.label} - Unarchived (Late archived projects)`
																),
														},
														{
															value: item.unarchivedTimely,
															color: "lightgray",
															label:
																item.label +
																" Unarchived (Timley archived projects)",
															testId:
																testId +
																"-unarchived-timely",
															clickHandler: () =>
																setTargetProjects(
																	item.unarchivedData.filter(
																		(
																			project: any
																		) =>
																			project.isLate ===
																			false
																	),
																	`${item.label} - Unarchived (Timley archived projects)`
																),
														},
														{
															value: item.unarchivedCannotCalc,
															color: "purple",
															label:
																item.label +
																" Unarchived (Unable to confirm the timely completion)",
															testId:
																testId +
																"-unarchived-cannotCalc",
															clickHandler: () =>
																setTargetProjects(
																	item.unarchivedData.filter(
																		(
																			project: any
																		) =>
																			project.isLate ===
																			undefined
																	),
																	`${item.label} - Unarchived (Unable to confirm the timely completion)`
																),
														},
												  ]
												: [
														{
															value: item.unarchived,
															color: "lightgray",
															label:
																item.label +
																" Unarchived",
															testId:
																testId +
																"-unarchived-total",
															clickHandler: () =>
																setTargetProjects(
																	item.unarchivedData,
																	`${item.label} - Unarchived`
																),
														},
												  ]
										}
										targetCount={item.unarchived}
										totalCount={item.total}
										testId={testId + "-unarchived"}
										eleClassName="unarchived sub-chart-row"
									/>
								</div>
							</div>
						</div>
					)
				})}
			</StyledTopicDetailCharts>
		)
	}
)

const StyledTopicDetailCharts = styled.div`
	.overall-total {
		margin-bottom: 1rem;
		padding: 1rem;
	}
	.group-name {
		/* width: 15%; */
		margin-bottom: 1rem;
		.partition {
			margin: 0 1rem;
		}
		.topic-count {
		}
	}
	.sub-label {
		width: 100px;
		overflow: hidden;
	}
	.chart-group {
		margin-bottom: 0.6rem;
		border: 1px solid rgba(0, 0, 0, 0.1);
		padding: 1rem;
		border-radius: 4px;
		background-color: ${(props) => props.theme.background};
		:hover {
			box-shadow: ${(props) => props.theme.shadowRegularLight};
		}
		.chart-in-group {
			.group-total {
				margin-bottom: 0.4rem;
			}
			.archived {
				.sub-label {
					opacity: 0.6;
				}
			}
			.normal {
				.sub-label {
					opacity: 0.6;
				}
			}
			.unarchived {
				.sub-label {
					opacity: 0.6;
				}
			}
		}
	}
	.sub-chart-row {
		margin-bottom: 0.2rem;
	}
`
