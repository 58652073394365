import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function ArchivePolicyDetailDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()

	return (
		<DLDialog
			eleTestId="archive-policy-detail-dialog"
			isOpen={projStore.archive.openArchivePolicyDetailDialog}
			setIsOpen={projStore.archive.setOpenArchivePolicyDetailDialog}
			showCloseBtn={true}
			dialogTitle={i18n.twArchPolicyDetails || "Archive policy details"}
			dialogContents={<div>description</div>}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			maxWidth="sm"
			fullWidth={true}
		/>
	)
})
