import axios, { Method } from "axios"
import * as FileSaver from "file-saver"

export const downloadPostAttachment = async (
	s3ObjKey: string,
	fileName: string
) => {
	let method: Method = "get"

	const postApiEndpoint = process.env.REACT_APP_CDK_RESTAPI_ENDPOINT + "post"
	const cdkRestApiKey = process.env.REACT_APP_CDK_RESTAPI_KEY

	const endpoint = postApiEndpoint + "/getDownloadUrl"

	var config = {
		method,
		url: endpoint + `?s3ObjKey=${s3ObjKey}`,
		headers: {
			"x-api-key": cdkRestApiKey,
		},
	}
	const result = await axios(config)
	const downloadUrl = result.data.downloadUrl
	console.log("downloadPostAttachment fileName", fileName)
	FileSaver.saveAs(downloadUrl, fileName)
}
