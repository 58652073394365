import {
	ActionStatus,
	MessageColorType,
    ChecklistStatus,
} from "../../../../../../common-models/enumerations/common-enums"
import { AddChecklistProps } from "../data-models/checklist-types"
import { ChecklistRecordType } from "@datalobby/types/lib"

// NOTE: org-setup-checklist has similar case to get the checklist templates
const AddChecklist = (self: any) => ({
	addChecklist(params: AddChecklistProps) {
		const actionName = "addChecklist"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.createChecklist(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					const data = response.data
					
					const organizedData = {
						id: data.id,
						parentId: data.id,
						orgId: params.orgId,
						name: "",
						description: "",
						type: ChecklistRecordType.list,
						lastModifiedBy: "",
						lastModifiedAt: "",
						createdAt: "",
						createdBy: params.createdBy,
						assignedRoles: null,
						assignedUsers: null,
						mustInOrder:null,
						// specific for list
						status: ChecklistStatus.todo,
						templateId: params.sourceId,
						projectId: params.projectId,
						workspaceId: params.workspaceId,
						isEditingBy: null,
					}
					
					self.pushItemToChecklist(organizedData)
					self.setOpenAddChecklistDialog(false)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Successfully created checklist",
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage: "Something's Wrong... ",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddChecklist
