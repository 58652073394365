// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const GetUsersAndFilesCountOfProjects = (self: any) => ({
	getUsersAndFilesCountOfProjects(projectList: any, index: number) {
		// 0.
		const actionName = "getUsersAndFilesCountOfProjects"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 200,
			color: MessageColorType.green,
			customMessage:
				"getting users and files count..." +
				"(" +
				(index + 1) +
				"/" +
				projectList.length +
				")",
			open: true,
		})
		// 3.
		/**
		 * by project information list from the project table,
		 * make iterate by index to get the users and files count
		 * if success, add the result to the item and index + 1
		 * if fail, add file result to the item and index + 1
		 */
		const targetProject = projectList[index]
		const targetProjectId = targetProject.id

		const projectId = idToNumber(targetProjectId, IdType.project)
		const payload = {
			ClientId: projectId,
			Type: 4, // TODO: is this fixed value..?
		}

		let item = {
			succeedToGet: false,
			usersCount: 0,
			users: [], // NOTE: postpone...
			wpCount: 0,
			pfCount: 0,
			//
			financialYear: targetProject.financialYear,
			//
			projectTitle: targetProject.title,
			projectAliasId: targetProject.aliasId,
			projectId: targetProject.id,
			//
			clientName: targetProject.clientName,
			clientId: targetProject.clientId,
			clientAliasId: targetProject.clientAliasId,
			//
			engTypeName: targetProject.engTypeName,
			periodName: targetProject.periodName,
		}

		if (index === 0) {
			// initialize stored data
			self.resetUsersAndFilesCountOfProjects()
		}

		self.readProjUsersAndFileCount(payload)
			.then((response: any) => {
				// console.log(actionName, "response", response)
				const info = response.data
				if (response.status === 200 && response.data.Status === 1) {
					// when succeed
					item = {
						...item,
						succeedToGet: true,
						usersCount: info.Count, // WARNING: capital 'C' for Count
						users: [], // NOTE: postpone...
						wpCount: info.WorkPaperCount,
						pfCount: info.PermanentFileCount,
					}
					self.pushItemToUsersAndFilesCountOfProjects(item)
					if (index < projectList.length - 1) {
						self.getUsersAndFilesCountOfProjects(
							projectList,
							index + 1
						)
					}
				} else {
					// when fail
					self.pushItemToUsersAndFilesCountOfProjects(item)
					if (index < projectList.length - 1) {
						self.getUsersAndFilesCountOfProjects(
							projectList,
							index + 1
						)
					}
				}
				// when the last item
				if (index === projectList.length - 1) {
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage:
							"Complete to get the project users and files count. Please download the file",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetUsersAndFilesCountOfProjects
