import { types } from "mobx-state-tree"

export const OrgSysSettingItemModel = types.model({
	settingId: types.string,
	label: types.string,
	value: types.string,
	uiValue: types.string,
})

export interface OrgSysSettingItemProps {
	settingId: string
	label: string
	value: string
	uiValue: string
}

export const AvailableOptionsModel = types.model({
	label: types.string,
	value: types.string,
	uiValue: types.string,
})

export const ModifiedDetails = types.model({
	userName: "",
	userId: "",
	modifiedDate: "",
})
