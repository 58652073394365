import React, { useEffect, useState, useCallback } from "react"
import SortableTree from "react-sortable-tree"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import FileTreeFolderRow from "./FileTreeFolderRow"
import FileTreeFileRow from "./FileTreeFileRow"
import FileTreeColumnHeader from "./FileTreeColumnHeader"
import { DLFileTreeProps } from "./dl-file-tree-props"
import { ConsoleLog, DLSpinnerCenterAligned } from "../../basic-elements"
import {
	ActionStatus,
	DLSrcFormat,
} from "../../../common-models/enumerations/common-enums"
import CreateFileDialog from "./file-tree-dialogs/add-file-dialog/CreateFileDialog"
import {
	FileScreenDialog,
	FileUploadFromType,
} from "../../../service-modules/file-module/data-models/dl-file-control-model"
import { initialClickPoint } from "../../../common-models/types/dialog.props"
import { checkFileProps } from "../../../service-modules/file-module/check-file-props-to-open"
import { LocalFileProps } from "../../../service-modules/file-module/data-models/file.data-props"
import { formatFileSize } from "@datalobby/common"
import StyledTreeForFiles from "./StyledTreeForFileMenus"
import { v4 as uuidv4 } from "uuid"
import keyboardJS from "keyboardjs"
import { idToNumber, IdType } from "../../../library/converters/id-converter"
import { useHistory } from "react-router-dom"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function DLFileTree({
	projId,
	treeData,
	permission,
	// select
	selectedItems,
	isSelected,
	isCurrentParent,
	isLastSelectedItem,
	setPrevTreeItem,
	setNextTreeItem,
	setSelectedItem,
	pushSelectedItem,
	setSelectedItemsWithKeyboard,
	resetSelectedItems,
	setCheckbox,
	// row status
	getLoadingStatus,
	showCheckbox,
	canDrag,
	// folder
	getChildrenOfFolder,
	toggleExpanded,
	handleDeleteFolder,
	setFolderClickPoint,
	setFileTreeDialogOpen,
	// file

	setFileClickPoint,
	openFileWithoutEditor,
	reflectFileCheckout,
	handleOpenCommentListOfFile,
	handleFileStatus,
	handleEditFileInfo,
	sortChildren,
	//
	handleDuplicateFolder,
	handleDuplicateFile,
	// nextTreeItem,
	// prevTreeItem,
	// dialog
	// dnd
	handleDragAndDrop,
	// sign off
	signOffPolicy,
	getColumnSignOffs,
	isHovered,
	hoveredColumn,
	cellType,
	dateFormat,
	singleSignOff,
	// style
	treeHeight,
	searchQuery,
	menuId,
	indentWidth,
	//
	//
	// column width
	contentsAreaWidth,
	//
	assignedRolesWidth,
	aliasIdWidth,
	titleWidth,
	formatWidth,
	sizeWidth,
	signOffLockWidth,
	//
	fixedAreaWidth,
	signOffAreaWidth,
	historyWidth,
	//
	//
	// for column header
	setColumnHighlight,
	needCollapseAll,
	updateSignOffAreaWidth,
	updateFileInfoAreaWidth,
	//
	partialStore, // for keyboard next/prev item
	simpleMode, // for project template
	signOffByShortcut,
	userInfo,
	i18n,
	handleSetCurrentMenu,
	setFileUploadFrom,
	checkDuplicatedAliasId,
	checkDuplicatedName,
	//
	resetLibSelectedItem,
	userRole,
}: DLFileTreeProps) {
	ConsoleLog(" >>>>> DLFileTree ", permission)
	const store = useRootStore()
	const history = useHistory()

	const [bindingKeyToTree, setBindingKeyToTree] = useState(false)
	// WARNING: this binding is working even users moved to other menus
	useEffect(() => {
		if (!bindingKeyToTree) {
			// WARNING: RESET is mandatory to separate the binding on WP, PF, and PT
			keyboardJS.reset()
			keyboardJS.resume()
			keyboardJS.watch()

			keyboardJS.bind("up", (e) => {
				handleUpDownKeyPress(e)
			})
			keyboardJS.bind("down", (e) => {
				e?.preventDefault()
				handleUpDownKeyPress(e)
			})
			keyboardJS.bind("esc", (e) => {
				handleEscKeyPressForTree(e)
			})
			keyboardJS.bind("right", (e) => {
				handleRightKeyPress(e)
			})
			keyboardJS.bind("left", (e) => {
				handleLeftKeyPress(e)
			})
			keyboardJS.bind("1", (e) => {
				handleShortCutExpandFolders(e)
			})
			keyboardJS.bind("2", (e) => {
				handleShortCutCollapseFolders(e)
			})
			keyboardJS.bind("3", (e) => {
				handleShortCutCreateRootFolders(e)
			})
			// sign off
			permission.signOff &&
				keyboardJS.bind(["p", "r"], (e) => {
					e?.preventDefault()
					// p for prepare, r for review
					signOffByShortcut(e)
				})

			keyboardJS.bind("alt+`", (e) => {
				e?.preventDefault()
				handleShortCutCtxMenuOpen(e)
			})
			permission.delete &&
				keyboardJS.bind("x", (e) => {
					e?.preventDefault()
					handleShortCutDelete(e)
				})
			permission.create &&
				keyboardJS.bind("n", (e) => {
					e?.preventDefault()
					handleShortCutNewFile(e)
				})
			if (permission.read) {
				keyboardJS.bind("o", (e) => {
					e?.preventDefault()
					handleShortCutOpenFile(e, "o")
				})
				keyboardJS.bind("w", (e) => {
					e?.preventDefault()
					handleShortCutOpenFile(e, "w")
				})
				keyboardJS.bind("d", (e) => {
					e?.preventDefault()
					handleDownloadFile(e, "w")
				})
			}
			if (permission.update) {
				keyboardJS.bind("e", (e) => {
					e?.preventDefault()
					handleShortCutEditFile(e)
				})
				keyboardJS.bind("s", (e) => {
					e?.preventDefault()
					handleShortCutReplaceFile(e)
				})
				keyboardJS.bind("l", (e) => {
					e?.preventDefault()
					handleShortCutSignOffLock(e)
				})
			}

			setBindingKeyToTree(true)
		}
	}, [])

	const handleShortCutOpenFile = useCallback((e: any, key: string) => {
		const lastItem = selectedItems[selectedItems.length - 1]
		if (lastItem === undefined) {
			return
		}
		const item = partialStore.getItemById(lastItem.id)
		const { isParent } = item
		if (!isParent) {
			// NOTE: DO NOT PAUSE KEYBOARD BINDING because file editor is opeend with new browser window
			if (key === "o") handleDoubleClickFile(e, item)
			else handleOpenFileWithEditor(e, item)
		}
	}, [])

	const handleDownloadFile = useCallback((e: any, key: string) => {
		const lastItem = selectedItems[selectedItems.length - 1]
		if (lastItem === undefined) {
			return
		}
		const item = partialStore.getItemById(lastItem.id)
		const { isParent, id } = item
		if (!isParent) {
			partialStore.downloadFile(id)
		}
	}, [])

	const handleShortCutEditFile = useCallback((e: any) => {
		const lastItem = selectedItems[selectedItems.length - 1]
		if (lastItem === undefined) {
			return
		}
		const item = partialStore.getItemById(lastItem.id)
		const { isParent } = item
		if (isParent) {
			setFileTreeDialogOpen(FileScreenDialog.editFolder, true)
		} else {
			setFileTreeDialogOpen(FileScreenDialog.fileEdit, true)
		}
	}, [])

	const handleShortCutSignOffLock = useCallback((e: any) => {
		const lastItem = selectedItems[selectedItems.length - 1]
		if (lastItem === undefined) {
			return
		}
		const item = partialStore.getItemById(lastItem.id)
		const { isParent } = item
		if (!isParent) {
			setFileTreeDialogOpen(FileScreenDialog.signOffLockDialog, true)
		}
	}, [])

	const handleShortCutReplaceFile = useCallback((e: any) => {
		const lastItem = selectedItems[selectedItems.length - 1]
		if (lastItem === undefined) {
			return
		}
		const item = partialStore.getItemById(lastItem.id)
		const { isParent } = item
		if (!isParent) {
			setFileTreeDialogOpen(FileScreenDialog.replaceFile, true)
		}
	}, [])

	const handleShortCutDelete = useCallback((e: any) => {
		const lastItem = selectedItems[selectedItems.length - 1]
		if (lastItem === undefined) {
			return
		}
		const item = partialStore.getItemById(lastItem.id)
		const { isParent, id, title } = item
		if (isParent) {
			// Do not pause key binding. It is hard to resume
			partialStore.removeFileFolder({ id })
		} else {
			// Do not pause key binding. It is hard to resume
			partialStore.removeFile(id)
		}
	}, [])

	const handleShortCutCtxMenuOpen = useCallback((e: any) => {
		const lastItem = selectedItems[selectedItems.length - 1]

		if (lastItem === undefined) {
			return
		}
		let itemPosition = {
			preventDefault: () => {},
			clientX: 0,
			clientY: 0,
		}
		const item = partialStore.getItemById(lastItem.id)
		const { title, aliasId, isParent } = item
		let testId = ""
		if (isParent) {
			testId = "folder-" + title.toLowerCase().replace(/ /g, "-")
		} else {
			testId = "file-" + aliasId.toLowerCase().replace(/ /g, "-")
		}

		const queryItem = document.querySelector(`[data-testid=${testId}]`)
		const clientX = queryItem?.getBoundingClientRect().left
		const clientY = queryItem?.getBoundingClientRect().top

		if (clientX && clientY) {
			itemPosition.clientX = clientX + 300
			itemPosition.clientY = clientY + 16
		}

		if (lastItem.isParent) {
			handleRightClickFolder(itemPosition, lastItem.id)
		} else {
			handleRightClickFile(itemPosition, lastItem.id)
		}
	}, [])

	const handleShortCutNewFile = useCallback(
		(e: any) => {
			setFileTreeDialogOpen(FileScreenDialog.createFile, true)
		},
		[menuId]
	)

	const handleShortCutExpandFolders = useCallback(
		(e: any) => {
			partialStore.getAllFoldersAndFiles(projId, false)
		},
		[menuId]
	)

	const handleShortCutCollapseFolders = useCallback(
		(e: any) => {
			partialStore.resetSelectedItems()
			partialStore.expandAll(false)
		},
		[menuId]
	)
	const handleShortCutCreateRootFolders = useCallback(
		(e: any) => {
			partialStore.resetSelectedItems()
			partialStore.setFileTreeDialogOpen(
				FileScreenDialog.createFolder,
				true
			)
		},
		[menuId]
	)

	const [localFiles, setLocalFiles] = useState<LocalFileProps[]>([])

	const handleUpDownKeyPress = useCallback(
		(e: any) => {
			const { key, pressedKeys } = e

			// if there are no items yet, up & down keyboard input is not working
			if (treeData.length !== 0 && !partialStore.openedDialogExist) {
				// if there are no selected items. set the first folder as selected item
				if (selectedItems.length === 0) {
					ConsoleLog("no selected Items")
					setSelectedItem(treeData[0].id, true)
				} else {
					// * there are some tree-data and some selected items

					if (key === "ArrowDown") {
						// if the key is arrow down, next item is downward item
						// next item is shared for all conditions in arrow down
						const next = partialStore.nextTreeItem

						if (next !== undefined && next !== "") {
							if (pressedKeys.includes("shift")) {
								// arrow down + shift key
								if (next.includes("folder")) {
									pushSelectedItem(next, true)
								} else {
									pushSelectedItem(next, false)
								}
							} else if (pressedKeys.includes("alt")) {
								// TODO: arrow down + alt key
							} else {
								// only by Arrow Down key
								if (next.includes("folder")) {
									setSelectedItem(next, true)
								} else {
									setSelectedItem(next, false)
								}
							}
						} else {
							ConsoleLog(
								"next item is undefined. Do not work for this case"
							)
							resetSelectedItems()
							return
						}
					} else if (key === "ArrowUp") {
						// if the key is arrow up, next item is upward item
						const prev = partialStore.prevTreeItem
						if (prev !== undefined && prev !== "") {
							if (pressedKeys.includes("shift")) {
								// shift + arrow up
								if (prev.includes("folder")) {
									pushSelectedItem(prev, true)
								} else {
									pushSelectedItem(prev, false)
								}
							} else if (pressedKeys.includes("alt")) {
								// alt + arrow up
							} else {
								// only by Arrow Up Key
								if (prev.includes("folder")) {
									setSelectedItem(prev, true)
								} else {
									setSelectedItem(prev, false)
								}
							}
						} else {
							ConsoleLog(
								"prev item is undefined. Do not work for this case"
							)
							resetSelectedItems()
							return
						}
					}
				}
			}
		},
		[menuId]
	)

	const handleEscKeyPressForTree = useCallback(
		(e: any) => {
			if (bindingKeyToTree) {
				resetSelectedItems()
				setFileClickPoint(initialClickPoint)
				setFolderClickPoint(initialClickPoint)
			}
		},
		[menuId]
	)

	const handleRightKeyPress = useCallback(
		(e: any) => {
			if (
				treeData.length !== 0 &&
				selectedItems.length !== 0 &&
				!partialStore.openedDialogExist
			) {
				const lastSelectedItem = selectedItems[selectedItems.length - 1]
				if (lastSelectedItem.isParent) {
					// if the last selected item is folder, get children of the folder
					getChildrenOfFolder(lastSelectedItem.id)
					toggleExpanded(lastSelectedItem.id, true)
				}
			}
		},
		[menuId]
	)

	const handleLeftKeyPress = useCallback(
		(e: any) => {
			if (
				treeData.length !== 0 &&
				selectedItems.length !== 0 &&
				!partialStore.openedDialogExist
			) {
				const lastSelectedItem = selectedItems[selectedItems.length - 1]
				if (lastSelectedItem.isParent) {
					// if the last selected item is folder, collapse the folder
					toggleExpanded(lastSelectedItem.id, false)
				}
			}
		},
		[menuId]
	)

	const handleRightClickFolder = (event: any, folderId: string) => {
		event.preventDefault()
		const noSelectedFiles = partialStore.selectedItemsHasNoFile
		if (noSelectedFiles) {
			setSelectedItem(folderId, true)
			// NOTE: pending multiple actions
			// pushSelectedItem(folderId, true)
		} else {
			setSelectedItem(folderId, true)
		}

		setFolderClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	const handleRightClickFile = useCallback((event: any, fileId: string) => {
		event.preventDefault()
		// NOTE: Do not move this 'noSelectedFolders' to controller.
		// This one is not inherited well directly from the controller
		const noSelectedFolders = partialStore.selectedItemsHasNoFolder
		if (noSelectedFolders) {
			if (selectedItems.length > 1) {
				pushSelectedItem(fileId, false)
			} else {
				setSelectedItem(fileId, false)
			}
		} else {
			setSelectedItem(fileId, false)
		}

		setFileClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}, [])

	const handleOpenAddFolderDialog = (event: any, folderId: string) => {
		event.preventDefault()
		setSelectedItem(folderId, true) // TODO: this is not working on project template.. Is it required to add?
		setFileTreeDialogOpen(FileScreenDialog.createFolder, true) // TODO: Actually.. project template doesn't use FileScreenDialog. Is it required to integrate?
	}

	const handleOpenAddFileDialog = (event: any, folderId: string) => {
		event.preventDefault()
		setLocalFiles([])
		setSelectedItem(folderId, true)
		setFileTreeDialogOpen(FileScreenDialog.createFile, true)
	}

	const handleOpenSignOffLockDialog = (fileId: string) => {
		setSelectedItem(fileId, false)
		setFileTreeDialogOpen(FileScreenDialog.signOffLockDialog, true)
	}

	const handleFileCtxMenuButtonClick = useCallback(
		(event: any, fileId: string) => {
			event.preventDefault()
			// if (!isSelected(fileId)) {
			setSelectedItem(fileId, false)
			// }

			// NOTE: DO NOTE setSelectedItem here. It is duplicated with file row itself
			setFileClickPoint({
				mouseX: event.clientX - 2,
				mouseY: event.clientY - 4,
			})
		},
		[]
	)

	// TODO: Let match with clickFile cases
	const handleClickFolder = (
		event: any,
		expanded: boolean,
		folderId: string
	) => {
		event.preventDefault()
		!expanded && getChildrenOfFolder(folderId)
		if (selectedItems.length > 0) {
			resetSelectedItems()
			setSelectedItem(folderId, true)
		} else {
			setSelectedItem(folderId, true)
		}

		toggleExpanded(folderId)
	}

	const handleExpandSubFolder = (folderId: string) => {
		partialStore.relayGetChildrenOfFolder(0, folderId)
	}

	const handleCollapseSubFolder = (folderId: string) => {
		partialStore.collapseAllByFolder(folderId)
	}

	const handleClickFile = useCallback((event: any, fileId: string) => {
		event.preventDefault()
		setSelectedItemsWithKeyboard({
			itemId: fileId,
			isParent: false,
			shiftKey: event.shiftKey,
			altKey: event.altKey,
		})
	}, [])

	const handleCheckbox = useCallback(
		(event: any, itemId: string, isParent: boolean) => {
			event.preventDefault()
			setCheckbox(itemId, isParent)
		},
		[]
	)

	const handleFinalAnalysis = (title: string, id: any) => {
		// const projNumId = idToNumber(projId, IdType.project)
		const newMenuId = `ProjMenu_FinalAnalysis${title}`
		const newMenu = {
			id: newMenuId,
			title: `Final Analysis - ${title}`,
			url: `workpaper-files/final-analysis/${id}`,
		}
		handleSetCurrentMenu(newMenuId, newMenu)
	}

	const handleAuditProgram = (title: string, id: any) => {
		if (menuId === DLOrgSubMenus.proj_templates) {
			partialStore.setOpenAuditProgramDialog(true)
		} else {
			const projNumId = idToNumber(projId, IdType.project)
			const newMenuId = `ProjMenu_AuditProgram${title}`
			const newMenu = {
				id: newMenuId,
				title: `Audit Program - ${title}`,
				url: `workpaper-files/audit-program/${id}`,
			}
			handleSetCurrentMenu(newMenuId, newMenu)
			history.replace(
				`/project/${projNumId}/files/workpaper-files/audit-program/${id}`
			)
		}
	}

	const handleOpenEditor = (fileInfo: any) => {
		// NOTE: How to get the org Id consistently? (safety?)
		// (this part is used for both of org and proj)
		const orgId = localStorage.getItem("orgId") ?? "X"

		const fileProps = checkFileProps({
			orgId,
			projId,
			fileInfo,
			userInfo,
			asReadonly: false,
			/**
			 * WARNING:
			 * - When double click, default mode is editable
			 * - asReadonly value is for 'open as readonly' menu
			 * - but it is not implemented yet.
			 *
			 * - If the project is locked or archived or replica, project information will restrict editing.
			 * - If the file is checked out by other users, file status API will restrict the editing
			 *
			 * NOTE: When the file is not opened by editor..? @Noah ask about this to Venu
			 */
		})
		if (fileProps && fileProps.useEditor) {
			store.editor.openEditor(fileProps.props)
		} else if (fileProps && fileProps.useEditor === false) {
			openFileWithoutEditor(fileProps.props)
		} else {
			console.log("What should I do on here..?")
		}
	}

	const handleOpenFileWithEditor = (event: any, fileInfo: any) => {
		const itemsCount = selectedItems.length
		const fileSize = fileInfo.size.split("K")[0]
		console.log(fileInfo, "fileInfo1")
		if (
			[
				DLSrcFormat.excel,
				DLSrcFormat.word,
				DLSrcFormat.pdf,
				DLSrcFormat.leadSheet,
				DLSrcFormat.finalAnalysis,
				DLSrcFormat.auditProgram,
				DLSrcFormat.fluxAnalysis,
			].includes(fileInfo.srcFormat)
		) {
			if (
				parseInt(fileSize) > 15000 &&
				[
					DLSrcFormat.excel,
					DLSrcFormat.word,
					DLSrcFormat.pdf,
					DLSrcFormat.leadSheet,
				].includes(fileInfo.srcFormat)
			) {
				alert(
					"Online editor cannot open the file size over 15 MB. Please use file download and replace. To have download permission, a user must be assigned to this project."
				)
				return
			}
			if (itemsCount === 1) {
				if (fileInfo.srcFormat === DLSrcFormat.finalAnalysis) {
					handleFinalAnalysis(fileInfo.title, fileInfo.id)
				} else {
					handleOpenEditor(fileInfo)
				}
			} else if (itemsCount > 1) {
				alert("(handleFileOpen) cannot open multiple files")
			} else if (itemsCount <= 0) {
				alert("(handleFileOpen) No selected files")
			}
		} else {
			alert("Unknown Format")
		}
	}

	const handleDoubleClickFile = useCallback(
		(event: any, fileInfo: any) => {
			event.preventDefault()
			ConsoleLog("DLFileTree double click - File Info " + fileInfo)
			if (fileInfo.srcFormat === DLSrcFormat.finalAnalysis) {
				handleFinalAnalysis(fileInfo.title, fileInfo.id)
			} else if (fileInfo.srcFormat === DLSrcFormat.auditProgram) {
				handleAuditProgram(fileInfo.title, fileInfo.id)
			} else if (
				fileInfo.srcFormat === DLSrcFormat.fluxAnalysis ||
				fileInfo.srcFormat === DLSrcFormat.leadSheet ||
				fileInfo.srcFormat === DLSrcFormat.fileGroup
			) {
				handleOpenEditor(fileInfo)
			} else {
				const fileId = fileInfo.id
				partialStore.downloadFile(fileId)
			}
		},
		[menuId]
	)

	const setSelectedFile = (fileId: string) => {
		setSelectedItem(fileId, false)
	}

	const handleLocalFilesDrop = () => {
		if (permission.create) {
			setFileUploadFrom(FileUploadFromType.local)
			setFileTreeDialogOpen(FileScreenDialog.createFile, true)
		} else {
			alert("Sorry, you don't have the permission")
		}
	}

	const dropHandler = (e: any) => {
		if (e.dataTransfer.files.length === 0) {
			// NOTE: without this, root folder re-ordering also be catched as drop
			return
		}

		e.preventDefault()
		setLocalFiles([])

		if (partialStore.itemNotExist) {
			alert("Plesae create a folder first to upload local files")
			return
		} else {
			// TODO: What is different with "DataTransferItemList" and "DataTransfer"?
			// NOTE: CONSIDER TOGETHER: handleSelectFilesFromLocal
			for (var i = 0; i < e.dataTransfer.files.length; i++) {
				const file = e.dataTransfer.files[i]
				const { name, type, size, lastModified } = file
				const fileName = name.slice(0, name.lastIndexOf("."))
				const fileExtension = name.slice(name.lastIndexOf("."))
				const formattedSize = formatFileSize(size, 2)
				setLocalFiles((localFiles: any) => [
					...localFiles,
					{
						tempId: uuidv4(),
						aliasId: "",
						selected: false,
						name: fileName,
						extension: fileExtension,
						size,
						formattedSize,
						type,
						lastModified,
						file,
						prepared: false,
						reviewed: false,
					},
				])
			}

			handleLocalFilesDrop()
		}
	}
	const dragOverHandler = (e: any) => {
		// Prevent default behavior (Prevent file from being opened)
		e.preventDefault()
	}

	const sortingStatus = partialStore.getActionStatus("reorderChildren")

	const totalRowWidth =
		signOffAreaWidth +
		48 +
		48 +
		60 +
		historyWidth +
		indentWidth +
		assignedRolesWidth +
		aliasIdWidth +
		formatWidth +
		titleWidth +
		sizeWidth +
		200
	return (
		<>
			<StyledFileDropZone
				id="fileTree_dropZone"
				onDrop={(e) => dropHandler(e)}
				onDragOver={(e) => dragOverHandler(e)}
			>
				<StyledTreeForFiles style={{ width: totalRowWidth }}>
					{sortingStatus === ActionStatus.loading && (
						<DLSpinnerCenterAligned
							absolute
							backgroundOpacity={0.5}
						/>
					)}
					<FileTreeColumnHeader
						setColumnHighlight={setColumnHighlight}
						needCollapseAll={needCollapseAll}
						// left side
						indentWidth={indentWidth}
						assignedRolesWidth={assignedRolesWidth}
						aliasIdWidth={aliasIdWidth}
						formatWidth={formatWidth}
						titleWidth={titleWidth}
						sizeWidth={sizeWidth}
						signOffLockWidth={signOffLockWidth}
						// right side
						contentsAreaWidth={contentsAreaWidth}
						updateFileInfoAreaWidth={updateFileInfoAreaWidth}
						updateSignOffAreaWidth={updateSignOffAreaWidth}
						signOffAreaWidth={signOffAreaWidth}
						historyWidth={historyWidth}
						//
						simpleMode={simpleMode}
						columnDisplayStatus={partialStore.showColumns}
						signOffColumnsDisplay={partialStore.showSignOffColumns}
						signOffPolicy={signOffPolicy}
					/>
					<SortableTree
						treeData={treeData}
						className={"dl-file-tree-component"}
						//
						dndType="dlFileNode"
						shouldCopyOnOutsideDrop
						//
						// searchMethod={handleSearchIdInTree}
						// searchQuery={lastSelecteItem.id}
						searchQuery={searchQuery}
						//
						canDrag={canDrag}
						getNodeKey={({ node }: { node: any }) => node.id}
						isVirtualized={false}
						onChange={(treeData) => {}} // sortable tree set this as mandatory
						style={{
							// width: contentsAreaWidth,
							height: treeHeight,
							paddingBottom: "1rem",
							// overflowY: "auto", // Do not use scroll. It makes right side padding even when it has less item
						}}
						rowHeight={32}
						scaffoldBlockPxWidth={indentWidth}
						onMoveNode={(data: any) => {
							console.log(
								"#### onMoveNode------data:",
								JSON.parse(JSON.stringify(data))
							)
							if (data === null) {
								// when the destination is not able to get children
								alert("Cannot move that way")
								return
							} else if (data.nextParentNode === null) {
								if (data.node.parentId === null) {
									// NOTE: reorder root folders
									ConsoleLog(
										" ---- reorder root folders ---- "
									)
									handleDragAndDrop({
										reorderRootFolders: true,
										node: data.node,
										targetIndex: data.nextTreeIndex,
										data,
									})
								} else {
									// NOTE: move child folder as root folder
									let proceed = window.confirm(
										"Do you want to move this folder to root?"
									)
									if (!proceed) {
										return
									} else {
										handleDragAndDrop({
											updateAsRootFolder: true,
											node: data.node,
											targetIndex: data.nextTreeIndex,
										})
									}
								}
							} else {
								if (data.nextParentNode.isParent) {
									// NOTE: normal move
									if (
										data.nextParentNode.id !==
										data.node.parentId
									) {
										handleDragAndDrop({
											nextParentNode: data.nextParentNode,
											node: data.node,
										})
									}
								} else {
									ConsoleLog("Cannot move to under the file")
								}
							}
						}}
						onDragStateChanged={(data: any) => {
							/**
							 * (start)
							 * draggedNode:
							 * children: []
							 * expanded: false
							 * id: "fileId168784"
							 * index:2
							 * isParent: false
							 * parentId: "folderId286694"
							 * isDragging: true
							 *
							 * (after)
							 * draggedNode: null
							 * isDragging: false
							 */
						}}
						generateNodeProps={(data: any) => {
							// --- data props ---
							// isSearchFocus: boolean
							// isSearchMatch: boolan
							// lowerSiblingCounts: [0]
							// node:
							// parentNode: null or string
							// path: array of node id: parent - child
							// treeIndex: number

							return {
								title: data.node.isParent ? (
									<FileTreeFolderRow
										data={data}
										permission={permission}
										menuId={menuId}
										//
										showCheckbox={showCheckbox}
										canDrag={canDrag}
										indentWidth={indentWidth}
										// selection
										isSelected={isSelected}
										isCurrentParent={isCurrentParent}
										isLastSelectedItem={isLastSelectedItem}
										//
										setNextTreeItem={setNextTreeItem}
										setPrevTreeItem={setPrevTreeItem}
										//
										handleClick={handleClickFolder}
										setSelectedItem={setSelectedItem}
										handleCheckbox={handleCheckbox}
										handleRightClick={
											handleRightClickFolder
										}
										handleDelete={handleDeleteFolder}
										handleDuplicate={handleDuplicateFolder}
										// folder action
										getLoadingStatus={getLoadingStatus}
										setFolderClickPoint={
											setFolderClickPoint
										}
										handleOpenAddFolderDialog={
											handleOpenAddFolderDialog
										}
										handleOpenAddFileDialog={
											handleOpenAddFileDialog
										}
										handleExpandSubFolder={
											handleExpandSubFolder
										}
										handleCollapseSubFolder={
											handleCollapseSubFolder
										}
										sortChildren={sortChildren}
										partialStore={partialStore}
										i18n={i18n}
										totalRowWidth={totalRowWidth}
									/>
								) : data.node.isPlaceholder ? (
									<div>Placeholder</div>
								) : (
									<FileTreeFileRow
										data={data}
										permission={permission}
										menuId={menuId}
										//
										showCheckbox={showCheckbox}
										canDrag={canDrag}
										indentWidth={indentWidth}
										// selection
										isSelected={isSelected}
										isCurrentParent={isCurrentParent}
										isLastSelectedItem={isLastSelectedItem}
										//
										setPrevTreeItem={setPrevTreeItem}
										setNextTreeItem={setNextTreeItem}
										//
										handleRightClick={handleRightClickFile}
										handleCtxMenuButtonClick={
											handleFileCtxMenuButtonClick
										}
										//
										handleClick={handleClickFile}
										handleCheckbox={handleCheckbox}
										handleDoubleClick={
											handleDoubleClickFile
										}
										setSelectedFile={setSelectedFile}
										// for file
										handleEditFileInfo={handleEditFileInfo}
										handleOpenCommentListOfFile={
											handleOpenCommentListOfFile
										}
										handleFileStatus={handleFileStatus}
										handleDuplicate={handleDuplicateFile}
										// for sign off
										signOffPolicy={signOffPolicy}
										getColumnSignOffs={getColumnSignOffs}
										hoveredColumn={hoveredColumn}
										cellType={cellType}
										dateFormat={dateFormat}
										singleSignOff={singleSignOff}
										// column width
										contentsAreaWidth={contentsAreaWidth}
										//
										assignedRolesWidth={assignedRolesWidth}
										aliasIdWidth={aliasIdWidth}
										titleWidth={titleWidth}
										formatWidth={formatWidth}
										sizeWidth={sizeWidth}
										signOffLockWidth={signOffLockWidth}
										//
										fixedAreaWidth={fixedAreaWidth}
										signOffAreaWidth={signOffAreaWidth}
										historyWidth={historyWidth}
										//
										simpleMode={simpleMode}
										columnDisplayStatus={
											partialStore.showColumns
										}
										checkDuplicatedAliasId={
											checkDuplicatedAliasId
										}
										checkDuplicatedName={
											checkDuplicatedName
										}
										signOffColumnsDisplay={
											partialStore.showSignOffColumns
										}
										handleOpenSignOffLockDialog={
											handleOpenSignOffLockDialog
										}
									/>
								),
								// style: {
								// 	boxShadow:
								// 		data.node.isParent &&
								// 		data.node.expanded &&
								// 		"0px 1px 2px 1px rgba(192, 203, 220, 0.3)",
								// },
							}
						}}
					/>
				</StyledTreeForFiles>
			</StyledFileDropZone>
			{/* ----- File related ----- */}
			{/* Cannot move this dialog to get the drop item from local  */}
			{partialStore.fileTreeDialogOpenStatus[
				FileScreenDialog.createFile
			] && (
				<CreateFileDialog
					partialStore={partialStore}
					setLocalFiles={setLocalFiles}
					localFiles={localFiles}
					resetLibSelectedItem={resetLibSelectedItem}
					i18n={i18n}
					userRole={userRole}
					permission={permission}
				/>
			)}
		</>
	)
})

const StyledFileDropZone = styled.div`
	overflow-x: scroll;
	::-webkit-scrollbar {
		width: auto !important;
	}
`
