import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ConsoleLog } from "../../../components/basic-elements"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import { postFormData } from "../../../library/api-requests/request-post-others"
import {
	getRequest,
	putRequest,
	deleteRequest,
	postRequest,
} from "../../../library/api-requests"

/**
 * * file-folder related apis
 * - readFirstFolder
 * - readChildrenOfFolder
 * - createFodler
 * - updateFolder
 * - downloadFolder
 * - removeFolder
 *
 */

//
//
// ----- read first folder
export async function readFirstFolder(
	endpoint: string,
	params: {
		ClientId: number
	}
) {
	// ConsoleLog("on the apis", endpoint, params.ClientId)
	// wp endpoint = "/CheckWPRFldFiles"
	// pf endpoint = "/GetParentPermanentFileFolders"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken, // NOTE: This API is not working with orgToken (good)
	})

	return response
}

//
//
// ----- read children of folder
export async function readChildrenOfFolder(
	endpoint: string,
	params: { ParentFldID: number }
) {
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
//
//
// ----- create folder
export async function createFolder(props: {
	data:
		| {
				ParentMediaID: number
				WPDesc: string
		  }
		| FormData
	menuId: DLOrgSubMenus | DLProjSubMenus
}) {
	const { data, menuId } = props
	ConsoleLog(["data", data, "| menuId", menuId])
	let endpoint = ""
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/CreateWPRFOLD"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/CreatePermanentFolder"
	} else if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/CreateTemp"
	}
	//
	let response: any
	if (menuId === DLOrgSubMenus.proj_templates) {
		response = await postFormData({
			url: endpoint,
			formData: data,
			tokenType: TOKEN_TYPE.orgToken,
		})
	} else {
		response = await postRequest({
			url: endpoint,
			params: data,
			tokenType: TOKEN_TYPE.projToken,
		})
	}

	return response
}

//
//
// ----- update folder name
export type UpdateFolderApiProps = {
	payload:
		| {
				MediaID: number
				MediaName: string
		  }
		| {
				TemplateId: number
				TempName: string
		  }
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function updateFolder(props: UpdateFolderApiProps) {
	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken
	const { payload, menuId } = props
	//
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/EditWPFolder"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/EditPermanentFolder"
	} else if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/EditTemplate"
		tokenType = TOKEN_TYPE.orgToken
	}

	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType,
	})

	return response
}

//
//
// ----- remove folder
export type DeleteFolderApiProps = {
	params:
		| {
				WPFolderID: number
				// UserID: number
				// Type: string // WorkpaperFolder for both WP and PF / seems not mandatory
		  }
		| {
				TemplateId: number
				UserID: number
		  }
	menuId: DLOrgSubMenus | DLProjSubMenus
}
export async function deleteFolder({ params, menuId }: DeleteFolderApiProps) {
	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/DeleteWPFolder"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/DeletePermanentFolder"
	} else if (menuId === DLOrgSubMenus.proj_templates) {
		endpoint = "/DeleteTemplate"
		tokenType = TOKEN_TYPE.orgToken
	}
	const response = await deleteRequest({
		url: endpoint,
		params,
		tokenType,
	})

	return response
}

//
//
// ----- reorder children
export type ReorderApiProps = {
	payload:
		| {
				// * project template
				FileId: number
				TemplateFolderId: number
				Index: number
				// UserID: number
		  }[]
		| {
				// * for project template root folder
				ParentFolderID: number
				Index: number
				// UserID: number
		  }[]
		| {
				// * wp, pf
				MediaID: number
				ParentMediaID: number
				Index: number
				// UserID: number
		  }[]

	menuId: DLOrgSubMenus | DLProjSubMenus
	forRootFolders?: boolean
}

export async function reorderChildren({
	payload,
	menuId,
	forRootFolders,
}: ReorderApiProps) {
	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken

	/**
	 * - wp root folders
	 * - wp children
	 *
	 * - pf root folders
	 * - pf children
	 *
	 * - pt root folders
	 * - pt children
	 */

	// NOTE: Workpaper files
	if (menuId === DLProjSubMenus.wp && forRootFolders) {
		endpoint = "/ParentFolderDragAndDrop"
	} else if (menuId === DLProjSubMenus.wp && !forRootFolders) {
		endpoint = "/DragAndDrop"
	}

	// NOTE: Permanent files
	if (menuId === DLProjSubMenus.pf && forRootFolders) {
		endpoint = "/PermanentFileParentFolderDragAndDrop"
	} else if (menuId === DLProjSubMenus.pf && !forRootFolders) {
		endpoint = "/PermanentFileDragAndDrop"
	}

	// NOTE: Project templates
	if (menuId === DLOrgSubMenus.proj_templates && forRootFolders) {
		// project template root folder
		endpoint = "/TemplateParentFolderDragAndDrop"
		tokenType = TOKEN_TYPE.orgToken
	} else if (menuId === DLOrgSubMenus.proj_templates && !forRootFolders) {
		// project template children
		endpoint = "/TemplateFileDragAndDrop"
		tokenType = TOKEN_TYPE.orgToken
	}

	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType,
	})

	return response
}

export type FolderApiSharedProps = { menuId: DLProjSubMenus; payload: any }

//
//
//
// ----- structure lock (=folder lock / unlock)
export type StructureLockAPIProps = {
	menuId: DLProjSubMenus
	request: "lock" | "unlock"
	projNumId: number
}
export async function updateStructureLock({
	menuId,
	request,
	projNumId,
}: StructureLockAPIProps) {
	let endpoint = ""
	let tokenType = TOKEN_TYPE.projToken
	if (menuId === DLProjSubMenus.wp && request === "lock") {
		endpoint = "/CreateLock"
	} else if (menuId === DLProjSubMenus.wp && request === "unlock") {
		endpoint = "/Unlock"
	} else if (menuId === DLProjSubMenus.pf && request === "lock") {
		endpoint = "/LockFiles"
	} else if (menuId === DLProjSubMenus.pf && request === "unlock") {
		endpoint = "/UnLockFiles"
	}

	const response = await putRequest({
		url: endpoint + "?ClientID=" + projNumId, // actually, pf API takes 'clientId' instead of the 'ClientID'...
		params: {},
		tokenType,
	})

	return response
}
