import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
//
import {
	DLIconButton,
	DLTreeListByTreeData,
	DLInputField,
	DLSpinner,
	DLSpinnerCenterAligned,
} from "../../basic-elements"
import Icon from "@mdi/react"
import {
	mdiSortAlphabeticalAscending,
	mdiChevronLeft,
	mdiChevronRight,
	mdiMagnify,
} from "@mdi/js"
import { InputAdornment } from "@material-ui/core"
import { OnMoveNodeDataProps } from "../../basic-elements/tree-list/DLTreeListByTreeData"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"

export default observer(function DLTreeNav({
	treeData,
	//
	navWidth,
	navHeaderHeight,
	contentsHeight,
	navOpen,
	canDrag,
	treeHeight,
	treeIndent,
	//
	loadingStatus,
	//
	showSortBtn,
	actionButtons,
	//
	treeRowRenderer,
	handleAllowDrag,
	handleNavOpen,
	//
	showSearchbar,
	useDefaultSearchbar,
	customSearchbar,
	queryText,
	handleQueryText,
	//
	onMoveNode,
	onVisibilityToggle,
	onDragStateChanged,
}: {
	treeData: any
	//
	navWidth: number // TODO: need to check. Some parts are set also with string...
	navHeaderHeight: number
	contentsHeight: number
	navOpen: boolean
	canDrag: boolean
	treeHeight: number
	treeIndent: number
	//
	loadingStatus: ActionStatus
	//
	showSortBtn: boolean
	actionButtons?: React.ReactElement
	//
	treeRowRenderer: any
	handleAllowDrag: () => void
	handleNavOpen: () => void
	//
	showSearchbar: boolean
	useDefaultSearchbar?: boolean
	customSearchbar?: React.ReactElement
	queryText?: string // NOTE: this query text and handleQuery action is only for the searchbar. It has not any effect on the tree directly.
	handleQueryText?: (props: any) => void
	//
	onMoveNode?: (data: OnMoveNodeDataProps) => void
	onVisibilityToggle?: (props: any) => void
	onDragStateChanged?: (props: any) => void
}) {
	return (
		<StyledDLTreeNav
			navWidth={navWidth}
			// navHeaderHeight={navHeaderHeight}
			navHeight={contentsHeight}
			data-testid="dl-tree-nav"
		>
			<div className={`tree-nav-header ${!navOpen && "FR JC"}`}>
				<div className="FR AC JSB icons-area">
					{navOpen && (
						<div className="FR AC icons-when-nav-open">
							{actionButtons}
						</div>
					)}

					<DLIconButton
						eleTestId="collapse-tree-list"
						clickHandler={handleNavOpen}
					>
						{navOpen ? (
							<Icon path={mdiChevronLeft} size={0.8} />
						) : (
							<Icon path={mdiChevronRight} size={0.8} />
						)}
					</DLIconButton>
				</div>
				{navOpen && showSearchbar && useDefaultSearchbar && (
					<DLInputField
						eleType="search"
						eleClassName="search-field"
						eleTestId="tree-search-input-field"
						eleValue={queryText}
						eleHandleChange={handleQueryText}
						eleStartAdornment={
							<InputAdornment position="start">
								<Icon path={mdiMagnify} size={0.8} />
							</InputAdornment>
						}
					/>
				)}
			</div>
			{loadingStatus === "LOADING" && <DLSpinnerCenterAligned />}
			{loadingStatus === "SUCCESS" && (
				<DLTreeListByTreeData
					eleClassName={`tree-nav-body ${!navOpen && "hide"}`}
					// data related
					treeData={treeData}
					//
					eleRenderNode={treeRowRenderer}
					eleWidth={"100%"}
					eleHeight={treeHeight}
					indentWidth={treeIndent}
					rowHeight="medium"
					canDrag={canDrag}
					//
					onMoveNode={onMoveNode}
					onVisibilityToggle={onVisibilityToggle}
					onDragStateChanged={onDragStateChanged}
				/>
			)}
		</StyledDLTreeNav>
	)
})

const StyledDLTreeNav = styled.div<{
	navWidth: number
	// navHeaderHeight: number
	navHeight: number
}>`
	width: ${(props) => props.navWidth}px;
	height: ${(props) => props.navHeight}px;
	overflow: hidden;
	border-top-right-radius: 5px;
	transition: 0.2s;
	background-color: ${(props) => props.theme.secondary};

	.tree-nav-header {
		z-index: 1;
		width: ${(props) => props.navWidth}px;

		padding: 0.6rem 1rem;
		border-top-right-radius: 5px;
		transition: 0.2s;
		.icons-area {
			height: ${(props) => props.theme.shared.baseUnit};
		}
		.search-field {
			padding: 0 1rem;
			margin-bottom: 1rem;
		}
	}
	.tree-nav-body {
		overflow-y: scroll;
		// padding-left: 1rem;
		padding-bottom: 3rem;
		&.hide {
			opacity: 0;
		}
	}
`
