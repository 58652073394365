import { flow } from "mobx-state-tree"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { ConsoleLog } from "../../../../components/basic-elements"

const ReadFileInfo = (
	self: any,
	read: FileSystemApiTypes["apiReadFileInfo"]
) => ({
	readFileInfo: flow(function* ({
		wpId,
		menu,
	}: {
		wpId: string
		menu: "wp" | "pf"
	}) {
		const actionName = "readFileInfo"

		const numberId = idToNumber(wpId, IdType.file)

		if (typeof numberId === "number") {
			try {
				const response = yield read({ fileNumberId: numberId, menu })
				// console.log(actionName + "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName + "__error " + error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
			ConsoleLog(`(${actionName}) ${numberId}`)
		}
	}),
})

export default ReadFileInfo
