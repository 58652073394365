import { flow } from "mobx-state-tree"
import { NotificationSettingsApiProps } from "../notification-settings.apis"

const UpdateNotificationSettings = (self: any, update: any) => ({
	updateNotificationSettings: flow(function* (payload) {
		const actionName = "updateNotificationSettings"
		try {
			const response = yield update(payload)

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default UpdateNotificationSettings
