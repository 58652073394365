// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - success, fail
 *
 */

const RemoveGroup = (self: any) => ({
	removeGroup(groupId: string) {
		// 0.
		const actionName = "removeGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		if (groupId === undefined) {
			alert(`(${actionName}) Group ID is undefined`)
			return
		}
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			self.deleteGroupItem(numberId)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// console.log(actionName+ "__response " + response)
						/**
						 * REFLECT RESPONSE ON THE STORE
						 */
						self.resetCurrentPath()
						self.removeFlatItem(groupId)
						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
						})
					} else if (
						response.data.Status === 0 &&
						response.data.Message === "err_1"
					) {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message: response.data.Message,
							customMessage:
								"Cannot delete the group as it has projects.",
							open: true,
						})
					} else {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		} else {
			// if the numberId type is not number
			alert(`(${actionName}) ${numberId}`)
		}
	},
})

export default RemoveGroup
