import { types } from "mobx-state-tree"
import ProjRollForwardModel from "./proj-roll-forward.model"
import ProjRollForwardViewModel from "./proj-roll-forward.view-model"
import * as basicApi from "../../../organization-side/groups/store/model-actions/group-basic-actions/group-basic-apis"
import * as orgGroupApis from "../../../../service-modules/group-module/org-group-shared-apis"
import * as orgProjectApi from "../../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"
import { OrgGroupApiCallProps } from "../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import { OrgProjectApiProps } from "../../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"

const ProjRollForwardStore = ({
	apiReadGroups,
	apiReadCabinets,
	apiReadClients,
	apiCreateProject,
}: {
	apiReadGroups: OrgGroupApiCallProps["apiReadGroups"]
	apiReadCabinets: OrgGroupApiCallProps["apiReadCabinets"]
	apiReadClients: OrgProjectApiProps["apiReadClients"]
	apiCreateProject: OrgProjectApiProps["apiCreateProject"]
}) =>
	types
		.compose(
			ProjRollForwardModel({
				apiReadGroups,
				apiReadCabinets,
				apiReadClients,
				apiCreateProject,
			}),
			ProjRollForwardViewModel
		)
		.named("ProjRollForwardStore")

export const initialStore = {
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjRollForwardStoreInstance = ProjRollForwardStore({
	apiReadGroups: basicApi.readGroups,
	apiReadCabinets: basicApi.readCabinets,
	apiReadClients: orgGroupApis.readClientsByGroup,
	apiCreateProject: orgProjectApi.createProject,
}).create(initialStore)

export default ProjRollForwardStoreInstance
