import { flow } from "mobx-state-tree"
import {
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"

const ReadClientsByGroup = (self: any, readClients: any) => ({
	readClientsByGroup: flow(function* (groupId: string) {
		const actionName = "readClientsByGroup"
		// 1.
		if (groupId === "" || groupId === undefined) {
			alert(`(${actionName}) undefined props exist: groupId ${groupId}`)
		}
		//
		//
		// 2.
		const numberId = idToNumber(groupId, IdType.group)
		//
		//
		// 3.
		if (typeof numberId === "number") {
			try {
				const payload = {
					GroupID: numberId,
					Type: 2, // TODO: What is the meaning..??
				}
				const response = yield readClients(payload)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		}
	}),
})

export default ReadClientsByGroup
