import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import OrgSetupChecklistDetail from "./OrgSetupChecklistDetail"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function OrgSetupChecklistDetailController() {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_checklist
	const actionName = "getChecklistTemplates"
	const i18nSheet = OrgI18n.checklist
	const i18n = store.i18n.combineI18n(i18nSheet)

	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const needRefresh = orgStore.setupChecklist.needRefresh

	const orgId = orgStore.checkin.orgInfo.orgId

	useEffect(() => {
		// menuAccess &&
		// 	needRefresh &&
		// 	orgStore.setupChecklist.getChecklistTemplates({ orgId })
	}, [menuAccess, needRefresh])

	useEffect(() => {
		store.ui.setShowTabs(false)
	}, [])

	return (
		<>
			{menuAccess ? (
				<>
					<OrgSetupChecklistDetail
						partialStore={orgStore.setupChecklist}
						fetchingStatus={orgStore.setupChecklist.getActionStatus(
							actionName
						)}
						// permission={permission}
						i18n={i18n}
						// dateFormat={store.global.dateFormat.value}
						contentHeight={store.ui.contentsAreaHeight}
					/>
				</>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
