import { types } from "mobx-state-tree"

// model common action
import { CommonModelActions } from "../../../common-models/common-model-actions"
// separated actions
import ReadClientList from "./model-actions/read-client-list"
import CreateClient from "./model-actions/create-client"
import UpdateClient from "./model-actions/update-client"
import DeleteClient from "./model-actions/delete-client"
import ReadGroupsForClient from "./model-actions/read-client-groups"
import AssignGroupsToClient from "./model-actions/assign-groups-to-clients"
import { OrgSetupClientsApiProps } from "./org-clients.apis"

const OrgClientListModel = ({
	apiRead,
	apiUpdate,
	apiDelete,
	apiCreate,
	apiReadGroups,
	apiAssignToGroup,
}: OrgSetupClientsApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, create = apiCreate) => CreateClient(self, create))
		.actions((self, read = apiRead) => ReadClientList(self, read))
		.actions((self, update = apiUpdate) => UpdateClient(self, update))
		.actions((self, del = apiDelete) => DeleteClient(self, del))
		.actions((self, readGroups = apiReadGroups) =>
			ReadGroupsForClient(self, readGroups)
		)
		.actions((self, assignToGroup = apiAssignToGroup) =>
			AssignGroupsToClient(self, assignToGroup)
		)
		// common model actions
		.actions(CommonModelActions)

export default OrgClientListModel
