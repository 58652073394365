import React, { useEffect, useState } from "react"
import { Link } from "react-router-dom"
import PageHeader from "../../../../components/app-frame-elements/sub-components/PageHeader"
// stores
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// components
import {
	DLButton,
	DLChip,
	DLSpinnerCenterAligned,
} from "../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus, mdiDotsVertical, mdiAirHorn, mdiBullhorn } from "@mdi/js"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { PostType, Priority, Sector } from "@datalobby/types"
import styled from "styled-components"
import NotiListCtxMenu from "./NotiListCtxMenu"
import {
	DLOrgMenuGroups,
	DLOrgSubMenus,
} from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import PostTable from "../../../../service-modules/post/components/PostTable"

const NotificationList = observer(
	({
		permission,
		dataStatus,
	}: {
		permission: PermissionAsObjectProps
		dataStatus: ActionStatus
	}) => {
		const store = useRootStore()
		const orgStore = useOrgStore()
		const globalNotiStore = store.notis
		const notiStore = orgStore.noti
		const userId = orgStore.checkin.orgUserId
		const actionName = "getOrgNotiList"
		const menuId = DLOrgSubMenus.org_noti

		// useEffect(() => {
		// 	const orgId = orgStore.checkin.orgInfo.orgId
		// 	if (
		// 		globalNotiStore.getActionStatus(actionName) !== ActionStatus.success
		// 	) {
		// 		globalNotiStore.getOrgNotiList(orgId)
		// 	}
		// }, [])

		// NOTE: other things --------------------------
		const openCtxMenu = (e: any, rowInfo: any) => {
			e.preventDefault()
			notiStore.setClickPoint({
				mouseX: e.clientX - 2,
				mouseY: e.clientY - 4,
			})
			notiStore.setSelectedItem(rowInfo.original.id)
		}

		useEffect(() => {
			const userListAccess = orgStore.checkin.checkAccess(
				DLOrgSubMenus.setup_users
			)
			const userNeedRefresh = orgStore.setupUsers.needRefresh
			if (userNeedRefresh && userListAccess) {
				orgStore.setupUsers.getUserList()
			}
		}, [])

		const contentsAreaHeight = store.ui.contentsAreaHeight
		const dntFormat = store.global.getDntFormat
		const orgUserList = orgStore.setupUsers.userListForRender
		const orgNotis = globalNotiStore.viewOrgNotiList(dntFormat, orgUserList)
		const fixToTopCount = globalNotiStore.viewFixToTopCount
		// NOTE: dataStatus should be inherited from the controller for refresh

		return (
			<div className="noti-list-container" style={{ padding: "1rem" }}>
				<PageHeader noPadding>
					<div>
						Total Notifications:{" "}
						<span data-testid="total-noti-count">
							{orgNotis.length}
						</span>
					</div>
					{permission.create && (
						<Link to="/organization/notifications/add">
							<DLButton
								eleTestId="add-noti-btn"
								startIcon={<Icon path={mdiPlus} size={0.8} />}
								color="primary"
							>
								Add
							</DLButton>
						</Link>
					)}
				</PageHeader>
				{dataStatus === ActionStatus.loading && (
					<DLSpinnerCenterAligned />
				)}
				{dataStatus === ActionStatus.success && (
					<PostTable
						data={orgNotis}
						userId={userId}
						fixToTopCount={fixToTopCount}
						contentsAreaHeight={contentsAreaHeight}
						openCtxMenu={openCtxMenu}
						sector={Sector.org}
					/>
				)}
				{notiStore.clickPoint.mouseX && (
					<NotiListCtxMenu
						permission={permission}
						partialStore={notiStore}
						sector={Sector.org}
						menuId={menuId}
					/>
				)}
			</div>
		)
	}
)

export default NotificationList
