// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import FileSaver from "file-saver"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

/**
*
* @param self
* @returns
*
* * i18n records
* - loading, success, fail
*
*/

const DownloadAllAsZip = (self: any) => ({
	downloadAllAsZip({ projId, templateId } : { projId?: string, templateId?:string }) {
		// 0.
		const actionName = "downloadAllAsZip"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
			// autoHide: true,
		})
		// 3.
		self.requestDownloadAllAsZip({projId, templateId})
			.then((response: any) => {
				if (response.status === 200) {
					// console.log(actionName+ "__response " + response)
					const contentType = response.headers["content-type"]
					//
					const fileName =
						self.storeName === DLProjSubMenus.wp
							? "All Workpaper Files"
							: self.storeName === DLProjSubMenus.pf
							? "All Permanent Files"
							: self.storeName === DLOrgSubMenus.proj_templates
							? "All Template Files"
							: "Unknown Menu"
					const fetchedFile = new Blob([response.data], {
						type: contentType,
					})
					FileSaver.saveAs(fetchedFile, fileName + ".zip")
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default DownloadAllAsZip
