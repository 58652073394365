import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../common-models/common-model-actions"
// separated actions
import {
	// Group basic
	ReadOrgGroups,
	ReadOrgGroupCabinets,
	ReadOrgGroupCabinetDetail,
	UpdateGroupName,
	// Group cabinet related
	UpdateCabinetName,
	CreateCabinetInGroup,
	RequestMoveCabinet,
	// group and cabinet together
	DeleteGroupItem,
} from "./model-actions"
import { OrgGroupApiCallProps } from "../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import {
	DeleteProject,
	RequestMoveProject,
} from "../../../../service-modules/project-module/model-actions"
import ReadProjectDetails from "../../../../service-modules/project-module/model-actions/read-project-details"

const OrgGroupsModel = ({
	apiReadGroups,
	apiReadCabinets,
	apiReadCabinetDetail,
	apiUpdateGroupName,
	//
	apiCreateGroupItem,
	apiUpdateCabinetName,
	apiMoveCabinet,
	//
	apiMoveProject,
	apiDeleteProject,
	//
	apiDeleteGroupItem,
	//
	apiReadProjectDetails,
}: OrgGroupApiCallProps) =>
	types
		.model({})
		// * Group basic
		.actions((self) => ReadOrgGroups(self, apiReadGroups))
		.actions((self) => ReadOrgGroupCabinets(self, apiReadCabinets))
		.actions((self) =>
			ReadOrgGroupCabinetDetail(self, apiReadCabinetDetail)
		)
		.actions((self) => UpdateGroupName(self, apiUpdateGroupName))
		// * Group cabinet related
		.actions((self) => CreateCabinetInGroup(self, apiCreateGroupItem))
		.actions((self) => UpdateCabinetName(self, apiUpdateCabinetName))
		.actions((self) => RequestMoveCabinet(self, apiMoveCabinet))
		// * Project related
		.actions((self) => RequestMoveProject(self, apiMoveProject))
		.actions((self) => DeleteProject(self, apiDeleteProject))
		//
		.actions((self) => ReadProjectDetails(self, apiReadProjectDetails))
		// Common model actions
		.actions(CommonModelActions)
		// * Group, Cabinet shared
		.actions((self) => DeleteGroupItem(self, apiDeleteGroupItem))

export { OrgGroupsModel }
