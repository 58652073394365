import React, { useState, useEffect } from "react"
import { Link } from "react-router-dom"
import { observer } from "mobx-react-lite"
import Grid from "@material-ui/core/Grid"
import { PageHeader } from "../../../components/app-frame-elements"
import { DLI18nProps } from "../../../common-models/types/common-props"
import { ProjectDetailProps } from "../../../service-modules/project-module/data-model/project-detail.data-props"
import { DLButton } from "../../basic-elements"
import { Tabs, Tab } from "@material-ui/core"
import styled from "styled-components"
import { idToNumber, IdType } from "../../../library/converters/id-converter"
import { ProjInfoUpdateInputProps } from "../../../screens/project-side/project-information/store/data-models/proj-info-update-input-props"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import ProjectUpdateForm from "./ProjectUpdateForm"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import ProjectLocationBreadCrumb from "./ProjectLocationBreadCrumb"

enum TABS {
	INFO = 0,
	USERS = 1,
}

export default observer(function ProjectDetailsForm({
	projectDetails,
	projectUserDetails,
	i18n,
	closeDialog,
	selectedTab,
	setSelectedTab,
}: {
	projectDetails: ProjectDetailProps
	projectUserDetails: any
	i18n: DLI18nProps
	closeDialog: () => void
	selectedTab: TABS
	setSelectedTab: (tab: TABS) => void
}) {
	const orgStore = useOrgStore()
	const [isEditMode, setIsEditMode] = useState(false)
	console.log(projectDetails)
	const replicaId = projectDetails.replicaId
	let replicaNumId = replicaId ? idToNumber(replicaId, IdType.project) : ""

	const prepareOpenReplica = () => {
		if (replicaId) {
			closeDialog()
		} else {
			alert("Cannot find the replica ID. Please try again")
		}
	}

	const handleEditMode = (projInfo: any) => {
		setIsEditMode((isEditMode) => !isEditMode)
		// orgStore.projects.reflectUpdatedProjData(projInfo)
	}

	const postProcessForUpdate = (projInfo: any) => {
		setIsEditMode((isEditMode) => !isEditMode)
		orgStore.projects.reflectUpdatedProjData(projInfo)
	}

	const {
		clientId,
		clientName,
		clientAliasId,
		//
		projTitle,
		projAliasId,
		//
		statusName,
		//
		engTypeId,
		engTypeName,
		//
		periodId,
		periodName,
		//
		archPolicyPeriodId,
		//
		financialYear,
		periodEndDate,
		expectedReportDate,
		expectedArchDeadlineDate,
		finalReportDate,
		finalArchDeadlineDate,
		//
		raId,
		raName,
		//
		clientAddress,
		fee,
		isLocked,
		otherUsers,
	} = orgStore.projects.projInfo
	// setInputs()
	const [inputs, setInputs] = useState<ProjInfoUpdateInputProps>({
		clientId,
		clientName,
		clientAliasId,
		//
		projTitle,
		projAliasId,
		statusName,
		//
		engTypeId,
		engTypeName,
		periodId,
		periodName,
		archPolicyPeriodId,
		//
		financialYear,
		periodEndDate,
		expectedReportDate,
		expectedArchDeadlineDate,
		finalReportDate,
		finalArchDeadlineDate,
		//
		raId,
		raName,
		//
		clientAddress,
		fee,
		otherUsers,
	})

	useEffect(() => {
		setInputs({
			clientId,
			clientName,
			clientAliasId,
			//
			projTitle,
			projAliasId,
			statusName,
			//
			engTypeId,
			engTypeName,
			periodId,
			periodName,
			archPolicyPeriodId,
			//
			financialYear,
			periodEndDate,
			expectedReportDate,
			expectedArchDeadlineDate,
			finalReportDate,
			finalArchDeadlineDate,
			//
			raId,
			raName,
			//
			clientAddress,
			fee,
			otherUsers,
		})
	}, [orgStore.projects.getActionStatus("getProjInfo")])

	const isThereAnyChanges = () => {
		if (
			clientId === inputs.clientId &&
			clientName === inputs.clientName &&
			clientAliasId === inputs.clientAliasId &&
			//
			projTitle === inputs.projTitle &&
			projAliasId === inputs.projAliasId &&
			//
			engTypeId === inputs.engTypeId &&
			engTypeName === inputs.engTypeName &&
			//
			periodId === inputs.periodId &&
			periodName === inputs.periodName &&
			//
			archPolicyPeriodId === inputs.archPolicyPeriodId &&
			//
			financialYear === inputs.financialYear &&
			periodEndDate === inputs.periodEndDate &&
			expectedReportDate === inputs.expectedReportDate &&
			expectedArchDeadlineDate === inputs.expectedArchDeadlineDate &&
			finalReportDate === inputs.finalReportDate &&
			finalArchDeadlineDate === inputs.finalArchDeadlineDate &&
			//
			raId === inputs.raId &&
			raName === inputs.raName &&
			//
			clientAddress === inputs.clientAddress &&
			fee === inputs.fee
		) {
			return false
		} else {
			return true
		}
	}

	const inputValidation = () => {
		if (
			// are there any changes?
			isThereAnyChanges() &&
			// do mandatory data exist?
			inputs.clientName !== "" &&
			inputs.clientAliasId !== "" &&
			inputs.projTitle !== "" &&
			inputs.projAliasId !== "" &&
			inputs.periodId !== "" &&
			inputs.periodEndDate !== ""
		) {
			return true
		} else {
			return false
		}
	}

	const handleUpdate = () =>
		orgStore.projects.editProjInfo(inputs, postProcessForUpdate)

	return (
		<StyledProjectDetailsForm>
			<Tabs
				value={selectedTab}
				onChange={(e: any, value: any) =>
					setSelectedTab(
						selectedTab === TABS.INFO ? TABS.USERS : TABS.INFO
					)
				}
				className="proj-info-tabs"
				indicatorColor="primary"
				textColor="primary"
			>
				<Tab
					label="Information"
					data-testid="proj-info-tab"
					className={
						"proj-info-tab " +
						(selectedTab === TABS.INFO ? "selected" : "")
					}
				/>
				<Tab
					label="Project Users"
					data-testid="proj-users-tab"
					className={
						"proj-info-tab " +
						(selectedTab === TABS.USERS ? "selected" : "")
					}
				/>
			</Tabs>
			{selectedTab === TABS.INFO && (
				<>
					<div>
						{isEditMode ? (
							<ProjectUpdateForm
								inputs={inputs}
								setInputs={setInputs}
								partialStore={orgStore.projects}
							/>
						) : (
							<ProjectInfoContainer
								projectDetails={projectDetails}
								i18n={i18n}
								locationDetails={orgStore.projects.viewCabinetLocDetails()}
								partialStore={orgStore.projects}
							/>
						)}
					</div>
					<div className="FR JSB">
						<>
							{isEditMode ? (
								<>
									<DLButton
										eleTestId="cancel-update"
										clickHandler={handleEditMode}
									>
										Cancel
									</DLButton>
									<DLButton
										eleTestId="cancel-update"
										disabled={!inputValidation()}
										clickHandler={handleUpdate}
										color="primary"
									>
										Update
									</DLButton>
								</>
							) : (
								<>
									{statusName === ProjectStatus.normal &&
										!isLocked && (
											<DLButton
												eleTestId="edit-proj-info"
												clickHandler={handleEditMode}
												color="primary"
											>
												Edit
											</DLButton>
										)}
									{replicaId && replicaId !== "projId0" ? (
										<Link
											to={`/project/checkin/${replicaNumId}`}
											onClick={prepareOpenReplica}
										>
											<DLButton eleTestId="go-to-replica-btn">
												Go to Replica
											</DLButton>
										</Link>
									) : (
										<DLButton
											eleTestId="go-to-replica-btn"
											disabled
										>
											Go to Replica
										</DLButton>
									)}
								</>
							)}
						</>
					</div>
				</>
			)}
			{selectedTab === TABS.USERS && (
				<>
					<ProjectUsersContainer
						projectUserDetails={projectUserDetails}
						i18n={i18n}
					/>
					<div>
						<span>
							{i18n.twTotalWorkpaper} :{" "}
							{projectUserDetails.wpCount}
						</span>
						<br />
						<span>
							{i18n.twTotalPermanentFiles} :{" "}
							{projectUserDetails.pfCount}
						</span>
					</div>
				</>
			)}
		</StyledProjectDetailsForm>
	)
})

export const ProjectInfoContainer = ({
	projectDetails,
	i18n,
	locationDetails,
	partialStore,
}: {
	projectDetails: ProjectDetailProps
	i18n: DLI18nProps
	locationDetails: any
	partialStore: any
}) => {
	const intVersion = projectDetails.version
	const version = intVersion === 0 ? "-" : "v." + intVersion

	return (
		<StyledProjInfo>
			<InfoRow
				label={i18n.twProjectType || "Project Type"}
				value={projectDetails.statusName}
				testId="proj-type"
			/>
			<InfoRow
				label={i18n.twGroup || "Group"}
				value={projectDetails.groupName}
				testId="group-name"
			/>
			<InfoRow
				label={i18n.twEngagementPartner || "E.P."}
				value={projectDetails.engPartner}
				testId="ep"
			/>
			<div className="partition" />
			<InfoRow
				label={i18n.twClient || "Client"}
				value={projectDetails.clientName}
				testId="client"
			/>

			<InfoRow
				label={i18n.twClientId || "Client ID"}
				value={projectDetails.clientAliasId}
				testId="client-alias-id"
			/>
			<InfoRow
				label={i18n.twVersion || "Version"}
				value={version}
				testId="version"
			/>

			<div className="info-row">
				<div className="label-area">Cabinet</div>
				<div className="value-area" data-testid={`projDetails-cabinet`}>
					<ProjectLocationBreadCrumb
						path={locationDetails}
						setIsOpen={partialStore.setOpenProjLocationDialog}
					/>
				</div>
			</div>
			<div className="partition" />
			<InfoRow
				label={i18n.twProjectTitle || "Project Name"}
				value={projectDetails.projTitle}
				testId="proj-title"
			/>
			<InfoRow
				label={i18n.twProjectId || "Project ID"}
				value={projectDetails.projAliasId}
				testId="proj-alias-id"
			/>
			<InfoRow
				label={i18n.twEngType || "Eng. Type"}
				value={projectDetails.engTypeName}
				testId="eng-type"
			/>
			<InfoRow
				label={i18n.twClientAddress || "Client Address"}
				value={projectDetails.clientAddress || "-"}
				testId="client-address"
			/>
			<InfoRow
				label={i18n.twFee || "Fee"}
				value={projectDetails.fee || "-"}
				testId="fee"
			/>
			<div className="partition" />
			<InfoRow
				label={i18n.twCreatedBy || "Created By"}
				value={projectDetails.createdBy}
				testId="createdBy"
			/>
			<div className="partition" />
			<InfoRow
				label={i18n.twRiskAssessment || "Risk Assessment"}
				value={projectDetails.raName}
				testId="ra"
			/>
			<InfoRow
				label={i18n.twPeriodType || "Period Type"}
				value={projectDetails.periodName}
				testId="periodType"
			/>
			<div className="partition" />
			<InfoRow
				label={i18n.twArchPolicyPeriod || "Archive Policy - period"}
				value={projectDetails.archPolicyPeriodName}
				testId="archPolicyPeriod"
			/>
			<InfoRow
				label={i18n.twPeriodEndDate || "Period End Date"}
				value={projectDetails.periodEndDate}
				testId="period-end-date"
			/>
			<InfoRow
				label={i18n.twExpectedReportDate || "Expected Report Date"}
				value={projectDetails.expectedReportDate}
				testId="expected-report-date"
			/>
			<InfoRow
				label={
					i18n.twExpectedArchDeadlineDate ||
					"Expected Archive Deadline Date"
				}
				value={projectDetails.expectedArchDeadlineDate}
				testId="expected-archive-deadline-date"
			/>
			<InfoRow
				label={i18n.twFinalReportDate || "Final Report Date"}
				value={projectDetails.finalReportDate || "-"}
				testId="final-report-date"
			/>
			<InfoRow
				label={
					i18n.twFinalArchiveDeadlineDate ||
					"Final Archive Deadline Date"
				}
				value={projectDetails.finalArchDeadlineDate || "-"} // NOTE: WARNING: NEED TO UPDATE OR CLEAR!!!
				testId="final-arch-deadline-date"
			/>
		</StyledProjInfo>
	)
}

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label}</div>
			<div className="value-area" data-testid={`projDetails-${testId}`}>
				{value}
			</div>
		</div>
	)
}

export const ProjectUsersContainer = observer(
	({
		projectUserDetails,
		i18n,
	}: {
		projectUserDetails: any
		i18n: DLI18nProps
	}) => {
		return (
			<StyledProjectUsers>
				<div className="internal-users">
					<PageHeader noPadding>
						<div className="FR AC">
							<span
								className="section-header"
								style={{ fontWeight: 600 }}
							>
								{i18n.twInternalActiveUser}
							</span>{" "}
							: {projectUserDetails.activeIntUsersCount}
						</div>
						<div>
							{i18n.twInactive}:{" "}
							{projectUserDetails.inActiveIntUsersCount} /{" "}
							{i18n.twUnassigned}:{" "}
							{projectUserDetails.unassignIntUsersCount} /{" "}
							{i18n.twTotal}:{" "}
							{projectUserDetails.internalUsersCount}
						</div>
					</PageHeader>
					<div className="internal-users-grid">
						<UserDetailTable
							userData={projectUserDetails.internalUsers}
							i18n={i18n}
						/>
					</div>
				</div>

				<div className="external-users">
					<PageHeader noPadding>
						<div className="FR AC">
							<span
								className="section-header"
								style={{ fontWeight: 600 }}
							>
								{i18n.twExternalActiveUser}
							</span>{" "}
							: {projectUserDetails.activeExtUsersCount}
						</div>
						<div>
							{i18n.twInactive}:{" "}
							{projectUserDetails.inActiveExtUsersCount} /{" "}
							{i18n.twUnassigned}:{" "}
							{projectUserDetails.unassignExtUsersCount} /{" "}
							{i18n.twTotal}: {projectUserDetails.extUsersCount}
						</div>
					</PageHeader>
					<div className="external-users-grid">
						<UserDetailTable
							userData={projectUserDetails.externalUsers}
							i18n={i18n}
						/>
					</div>
				</div>
			</StyledProjectUsers>
		)
	}
)

const UserDetailTable = observer(
	({ userData, i18n }: { userData: any; i18n: DLI18nProps }) => {
		return (
			<div>
				<Grid container spacing={1} data-testid="user-grid-header">
					<Grid item xs={3} data-testid="accessRight-header">
						{i18n.twProjectAccessType}
					</Grid>
					<Grid item xs={1} data-testid="role-header">
						{i18n.twRole}
					</Grid>
					<Grid item xs={2} data-testid="aliasId-header">
						{i18n.twID}
					</Grid>
					<Grid item xs={2} data-testid="userName-header">
						{i18n.twUserName}
					</Grid>
					<Grid item xs={3} data-testid="email-header">
						{i18n.twEmail}
					</Grid>
				</Grid>
				<hr />
				{userData.map((user: any) => {
					return (
						<Grid
							container
							spacing={1}
							className={`${user.isGrayed && "disabled"} ${
								user.isStrikedOut && "striked"
							} `}
							key={user.userId}
							data-testid="user-grid-body"
						>
							<Grid item xs={3} data-testid="accessRight-cell">
								{user.accessRight}
							</Grid>
							<Grid item xs={1} data-testid="role-cell">
								{user.role === "EngagementPartner"
									? "E.P."
									: user.role === "ConcurringPartner"
									? "C.P."
									: user.role === "QualityControl"
									? "Q.C."
									: user.role}
							</Grid>
							<Grid item xs={2} data-testid="aliasId-cell">
								{user.aliasId}
							</Grid>
							<Grid item xs={2} data-testid="userName-cell">
								{user.name}
							</Grid>
							<Grid item xs={3} data-testid="email-cell">
								{user.email}
							</Grid>
						</Grid>
					)
				})}
			</div>
		)
	}
)

const StyledProjInfo = styled.div`
	padding-top: 1rem;
	height: 400px;
	overflow-y: scroll;
	.info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 1rem;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.label-area {
			min-width: 13rem;
		}
		.value-area {
		}
	}
	.partition {
		height: 1.8rem;
	}
`

const StyledProjectUsers = styled.div`
	padding: 1rem;
	overflow-y: auto;
	.internal-users,
	.external-users {
		margin-bottom: 2rem;
		height: 200px;
		.section-header {
			font-weight: 600;
			color: ${(props) => props.theme.emphasis};
		}
	}
	.internal-users-grid {
		height: 160px;
		overflow-y: scroll;
		overflow-x: hidden;
	}
	.external-users-grid {
		height: 143px;
		overflow-y: scroll;
		/* overflow-x: hidden; */
	}
	.disabled {
		opacity: 0.5;
		cursor: not-allowed;
		pointer-events: none !important;
	}
	.striked {
		text-decoration: line-through;
	}
`

const StyledProjectDetailsForm = styled.div`
	.proj-info-tabs {
		min-height: 2rem;
		.proj-info-tab {
			min-width: 50%;
			border-top: 1px solid transparent;
			border-right: 1px solid transparent;
			border-left: 1px solid transparent;
			border-bottom: 1px solid ${(props) => props.theme.emphasis};
			min-height: 2rem;
			border-top-right-radius: 3px;
			border-top-left-radius: 3px;
			background-color: ${(props) => props.theme.shade05};
			font-size: 0.75rem;
			overflow: hidden;
			color: ${(props) => props.theme.textMediumEmphasis};
			&.selected {
				color: ${(props) => props.theme.emphasis};
				font-weight: ${(props) => props.theme.shared.fontBold};
				border-top: 1px solid ${(props) => props.theme.emphasis};
				border-right: 1px solid ${(props) => props.theme.emphasis};
				border-left: 1px solid ${(props) => props.theme.emphasis};
				border-bottom: 1px solid transparent;
				background-color: ${(props) => props.theme.background};
			}
			span {
				/* for the border bottom highlighter */
				background-color: transparent;
			}
		}
	}
`
