// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"

const DuplicateFolder = (self: any) => ({
	duplicateFolder(folderId: string) {
		// 0.
		const actionName = "duplicateFolder"

		let proceed = window.confirm(
			`Do you want to duplicate this <${
				self.getItemById(folderId).title
			}> folder?`
		)
		if (!proceed) {
			return
		} else {
			// 1.
			// 2.
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.loading,
			})

			// 3.
			self.requestDuplicateFolder(folderId)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// console.log(actionName+ "__response " + response)
						/**
						 * REFLECT RESPONSE ON THE STORE
						 */
						// set success case response
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							message: response.data.Message,
							// customMessage: "Success to" + actionName,
							open: true,
							autoHide: true,
						})
					} else {
						// set fail case response
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message: response.data.Message,
							// customMessage:
							// 	"Something Wrong... (UPDATE IS REQUIRED)",
							open: true,
							autoHide: false,
						})
					}
				})
				.catch((error: Error) => {
					self.handleViewModelError({
						error,
						actionName,
						open: false,
					})
				})
		}
	},
})

export default DuplicateFolder
