import React from "react"
import Avatar from "@material-ui/core/Avatar"
import Chip from "@material-ui/core/Chip"

import styled from "styled-components"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"

type ChipProps = {
	/** "outlined" | "default" */
	variant?: "outlined" | "default"
	/** "small" | "medium" | "thin"*/
	size?: "small" | "medium" | "thin"
	label: React.ReactNode
	/** "primary" | "secondary" | "default" | "BLUE" | "ORANGE" | "CORAL" | "RED"  */
	color?: "primary" | "secondary" | "default" | MessageColorType
	colorCode?: string
	icon?: React.ReactElement
	deleteIcon?: React.ReactElement
	onClick?: React.EventHandler<any>
	onDelete?: React.EventHandler<any>
	eleTestId: string
}

/**
 * This is the placeholder for the storybook
 *
 * TODO: text color need update
 */

export default function DLChip({
	variant = "default",
	size = "small",
	label = undefined,
	color = "default",
	colorCode = undefined,
	icon = undefined,
	deleteIcon = undefined,
	onClick = undefined,
	onDelete = undefined,
	eleTestId = "testId-DLChip",
}: ChipProps) {
	const handleDelete = () => {
		alert("You clicked the delete icon.")
	}

	const handleClick = () => {
		alert("You clicked the Chip.")
	}
	return (
		<StyledContainer colorCode={colorCode}>
			<Chip
				variant={variant}
				// size={size}
				label={label}
				icon={icon}
				deleteIcon={deleteIcon}
				onClick={onClick}
				onDelete={onDelete}
				data-testid={eleTestId}
				className={`chip color-${
					colorCode ? "custom" : color
				} ${size} ${variant} ${onClick && "clickable"}`}
			/>
		</StyledContainer>
	)
}

interface StyledProps {
	readonly colorCode?: string
}

const StyledContainer = styled.div<StyledProps>`
	display: "flex";
	justify-content: "center";
	flex-wrap: "wrap";
	.chip {
		padding: 0 0.2rem;
		background-color: ${(props) => props.colorCode};
		&.color-default {
			background-color: ${(props) => props.theme.shade20};
			&.clickable:hover {
				background-color: ${(props) => props.theme.shade40};
			}
		}
		&.color-secondary {
			background-color: ${(props) => props.theme.primaryLight};
			&.clickable:hover {
				background-color: ${(props) => props.theme.emphasis};
			}
		}
		&.color-primary {
			background-color: ${(props) => props.theme.emphasis};
			&.clickable:hover {
				background-color: ${(props) => props.theme.primaryMainDeep};
			}
		}
		&.color-BLUE {
			background-color: ${(props) => props.theme.shared.messageBlue};
			&.clickable:hover {
				background-color: ${(props) =>
					props.theme.shared.messageBlueAutoHide};
			}
		}
		&.color-ORANGE {
			background-color: ${(props) => props.theme.shared.messageOrange};
			&.clickable:hover {
				background-color: ${(props) =>
					props.theme.shared.messageOrangeAutoHide};
			}
		}
		&.color-CORAL {
			background-color: ${(props) => props.theme.shared.messageCoral};
			&.clickable:hover {
				background-color: ${(props) =>
					props.theme.shared.messageCoralAutoHide};
			}
		}
		&.color-RED {
			background-color: ${(props) => props.theme.shared.messageRed};
			&.clickable:hover {
				background-color: ${(props) =>
					props.theme.shared.messageRedAutoHide};
			}
		}
		&.thin {
			height: 14px;
			font-size: 12px;
			span {
				padding-left: 5px;
				padding-right: 5px;
			}
		}
		&.small {
			height: 18px;
			padding: 0;
		}
		&.medium {
		}
		&.outlined {
			border: 1px solid ${(props) => props.colorCode};
			background-color: transparent;
			color: ${(props) => props.colorCode};
		}
	}
`
