import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import CryptoJS from "crypto-js"
import { ConsoleLog } from "../../../../../components/basic-elements"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 *  - success, fail
 */
const ResetUserPassword = (self: any) => ({
	resetUserPassword(inputs: any) {
		// 0.
		const actionName = "resetUserPassword"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const default_key = process.env.REACT_APP_encryption_key
		const default_iv = process.env.REACT_APP_encryption_iv

		const key = default_key && CryptoJS.enc.Utf8.parse(default_key)
		const iv = default_iv && CryptoJS.enc.Utf8.parse(default_iv)

		const encryptedPw = CryptoJS.AES.encrypt(
			CryptoJS.enc.Utf8.parse(inputs.password.value.trim()),
			key,
			{
				keySize: 128 / 8,
				iv: iv,
				mode: CryptoJS.mode.CBC,
				padding: CryptoJS.pad.Pkcs7,
			}
		)

		let reOrganizedUserInfoForApi = {
			AccessCode: inputs.verificationCode.value,
			Newpwd: encryptedPw.toString(),
		}

		self.requestResetPassword(reOrganizedUserInfoForApi)
			.then((response: any) => {
				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					// ConsoleLog([actionName, "__response ", response])

					// set success response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
					})
				} else {
					// set fail response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						// message: response.data.status.message,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ResetUserPassword
