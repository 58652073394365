import { flow } from "mobx-state-tree"
import { LobbyCheckinProps } from "../lobby-access-control.apis"

const ReadLobbyDefault = (
	self: any,
	readInfo: LobbyCheckinProps["apiReadInfo"]
) => ({
	readLobbyDefault: flow(function* () {
		const actionName = "readLobbyDefault"

		try {
			const response = yield readInfo()

			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)

			self.handleTokenError({ actionName, error, open: true })
			return false
		}
	}),
})

export default ReadLobbyDefault
