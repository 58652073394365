import React from "react"
// for store
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
// for input area
import InputWithLabel from "../../../../../components/basic-elements/input-with-label/InputWithLabel"
import sharedRegEx from "../../../../../library/sharedRegEx"
// validate form
import useForm from "../../../../../library/use-form"
// for UI
import styled from "styled-components"
import {
	DLButton,
	DLDialog,
	DLSystemMsg,
	DLInputField,
} from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export const AddEngTypeBtn = ({ i18n }: { i18n: DLI18nProps }) => {
	const orgStore = useOrgStore()
	return (
		<DLButton
			variant="text"
			eleTestId="add-engtype-btn"
			startIcon={<Icon path={mdiPlus} size={1} />}
			clickHandler={() => orgStore.setupEngTypes.setOpenAdd(true)}
			color="primary"
		>
			{i18n.twAddEngType || "Add"}
		</DLButton>
	)
}

const AddEngTypeDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "addEngType"
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const addEngType = () => {
		orgStore.setupEngTypes.addEngType(inputs.name.value)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addEngType,
		orgStore.setupEngTypes.openAdd // resetPoint of inputs and actionReady
	)

	return (
		<DLDialog
			eleTestId="add-engtype-dialog"
			isOpen={orgStore.setupEngTypes.openAdd}
			setIsOpen={orgStore.setupEngTypes.setOpenAdd}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPlus}
						size={1}
						// color={MessageColorType.blue}
						style={{ marginRight: 8 }}
					/>
					{i18n.twAddEngType}
				</div>
			}
			dialogContents={
				<AddClientDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
					actionName={actionName}
				/>
			}
			cancelBtnText={i18n.twCancel}
			actionReady={
				isReady &&
				!orgStore.setupEngTypes.isDuplicatedName(inputs.name.value)
			}
			actionBtn={i18n.twSubmit}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.setupEngTypes.getActionStatus(actionName) !== "SUCCESS"
					? orgStore.setupEngTypes.responses.getResponse(actionName)
							?.message
					: undefined
			}
			showSpinner={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit // This one should be deprecated if the engType get more field in it
		/>
	)
})

const AddClientDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
		actionName,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
		actionName: string
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddDialog>
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twName}>
						<DLInputField
							autoFocus
							eleTestId="engagement-type-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(orgStore.setupEngTypes.isDuplicatedName(
									inputs.name.value
								) &&
									(i18n.tsSameNameExist ||
										"The same name already exist"))
							}
							warningType={
								orgStore.setupEngTypes.getActionStatus(
									actionName
								) !== "SUCCESS" &&
								orgStore.setupEngTypes.isDuplicatedName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddDialog>
		)
	}
)

const StyledAddDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default AddEngTypeDialog
