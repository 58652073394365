import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import useForm from "../../../../../library/use-form"
import sharedRegEx from "../../../../../library/sharedRegEx"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLDialogHeader,
} from "../../../../../components/basic-elements"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import { mdiPlus, mdiPencil } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"

const EditRiskAssessmentDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "editRiskAssessment"
	const { twEdit, twCancel, twUpdate } = i18n
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const editRiskAssessment = () => {
		const raId = orgStore.setupRiskAssessment.selectedRiskAssessment
		const raName = inputs.name.value
		orgStore.setupRiskAssessment.editRiskAssessment({
			raName,
			raId,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		editRiskAssessment
	)

	const riskAssessmentInfo = orgStore.setupRiskAssessment.getRiskAssessmentById(
		orgStore.setupRiskAssessment.selectedRiskAssessment
	)

	useEffect(() => {
		inputs.name.value = riskAssessmentInfo
			? riskAssessmentInfo.riskAssessmentName
			: ""
	}, [riskAssessmentInfo])

	return (
		<DLDialog
			eleTestId="edit-ra-dialog"
			isOpen={
				orgStore.setupRiskAssessment.editRiskAssessmentDialogOpen
			}
			setIsOpen={
				orgStore.setupRiskAssessment.setEditRiskAssessmentDialogOpen
			}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<DLDialogHeader
					icon={
						<DLIcon
							name={DLIconName.edit}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
					dialogName={twEdit}
					targetName={
						riskAssessmentInfo?.riskAssessmentName || "Unknown"
					}
				/>
			}
			dialogContents={
				<EditRiskAssessmentDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					actionName={actionName}
					defaultData={riskAssessmentInfo}
					i18n={i18n}
				/>
			}
			actionReady={
				isReady &&
				riskAssessmentInfo?.riskAssessmentName !==
					inputs.name.value &&
				orgStore.setupRiskAssessment.isDuplicatedName(
					inputs.name.value
				)
					? false
					: true
			}
			handleAction={handleOnSubmit}
			actionBtn={twUpdate}
			cancelBtnText={twCancel}
			showSpinner={
				orgStore.setupRiskAssessment.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.setupRiskAssessment.getActionStatus(actionName) ===
				"LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

interface EditRiskAssessmentDialogFormProps {
	inputs: any
	handleOnChange: any
	actionName: string
	[x: string]: any
	i18n: { [x: string]: string }
}

const EditRiskAssessmentDialogForm = observer(({
	inputs,
	handleOnChange,
	actionName,
	defaultData,
	i18n,
}: EditRiskAssessmentDialogFormProps) => {
	const orgStore = useOrgStore()
	return (
		<StyledDialog>
			<div className="input-section FR">
				<InputWithLabel required label={i18n.twName}>
					<DLInputField
						autoFocus
						eleTestId="risk-assessment-name-input"
						eleFullWidth
						eleName="name"
						eleValue={inputs.name.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={inputs.name.error}
						requestInput={inputs.name.requestInput}
					/>
					{/* CHECK 1: Display fail message when the API return the fail response  */}
					{orgStore.setupRiskAssessment.getActionStatus(
						actionName
					) === "FAIL" &&
						orgStore.setupRiskAssessment.responses.getResponse(
							actionName
						)?.message}
					{/* CHECK 2: Check the duplication and same as default value  */}
					{defaultData.riskAssessmentName !== inputs.name.value &&
						orgStore.setupRiskAssessment.isDuplicatedName(
							inputs.name.value
						) && (
							<DLSystemMsg
								type={MessageColorType.red}
								msg={i18n.tsSameNameExist}
							/>
						)}
				</InputWithLabel>
			</div>
		</StyledDialog>
	)
})

export default EditRiskAssessmentDialog

const StyledDialog = styled.div`
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 4rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 16rem;
		}
	}
`
