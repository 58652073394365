import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import OrgFileLibraryTreeNav from "./sub-components/OrgFileLibraryTreeNav"
import ResizingTrigger from "../../../../components/combined-elements/tree-nav/DLTreeNavResizingTrigger"
import OrgFileLibraryDetails from "./sub-components/OrgFileLibraryDetails"
import AddFileLibFolderDialog from "./sub-components/dialogs/AddFileLibFolderDialog"
import EditFileLibFolderDialog from "./sub-components/dialogs/EditFileLibFolderDialog"
import DeleteAndDuplicateLibraryFolderDialog from "./sub-components/dialogs/DeleteAndDuplicateFolderDialog"
import AddFileLibTemplateDialog from "./sub-components/dialogs/AddFileLibTemplateDialog"
import EditFileLibTemplateDialog from "./sub-components/dialogs/EditFileLibTemplateDialog"
import { DLSpinner } from "../../../../components/basic-elements"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import styled from "styled-components"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import { DLI18nProps } from "../../../../common-models/types/common-props"

export default observer(function OrgFileLibrary({
	partialStore,
	contentsHeight,
	actionStatus,
	permission,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const [dragStart, setDragStart] = useState(false)
	const [movedX, setMovedX] = useState(0)
	const handleMouseDown = (e: any) => {
		setDragStart(true)
	}
	const handleMouseUp = (e: any) => {
		setDragStart(false)
	}
	const handleMouseMove = (e: any) => {
		dragStart && setMovedX(e.movementX)
	}
	useEffect(() => {
		dragStart && partialStore.updateListWidth(movedX)
	}, [dragStart, movedX])

	return (
		<PageContainer 
			fullWidth 
			fixedHeight 
			hasToolbar={false}
			isLoading={actionStatus === "LOADING"}
		>
			<StyledOrgFileLibraryContainer className="FC">
				<PageHeader>
					<BasicCounter
						label={
							i18n.twTotalFileLibGroups ||
							"Total File Library Groups"
						}
						value={partialStore.templateCount}
					/>
				</PageHeader>
				<div
					className="FR library-main-container"
					onMouseUp={handleMouseUp}
					onMouseMove={handleMouseMove}
					style={{ height: contentsHeight - 88 }}
				>
					<OrgFileLibraryTreeNav permission={permission} />
					<ResizingTrigger handleMouseDown={handleMouseDown} />
					<OrgFileLibraryDetails permission={permission} />
				</div>
				{/* response snackbar */}
				<CommonSnackbarComponent
					i18n={i18n}
					partialStore={partialStore}
				/>
				{/* dialogs */}
				{partialStore.openAddLibraryFolder && (
					<AddFileLibFolderDialog i18n={i18n} />
				)}
				{partialStore.openEditLibraryFolder && (
					<EditFileLibFolderDialog i18n={i18n} />
				)}
				{partialStore.openDeleteAndDuplicateDialog && (
					<DeleteAndDuplicateLibraryFolderDialog i18n={i18n} />
				)}
				{partialStore.openAddLibraryTemplate && (
					<AddFileLibTemplateDialog i18n={i18n} />
				)}
				{partialStore.openEditLibraryTemplate && (
					<EditFileLibTemplateDialog i18n={i18n} />
				)}
			</StyledOrgFileLibraryContainer>
		</PageContainer>
	)
})

const StyledOrgFileLibraryContainer = styled.div`
	/* padding-left: 1rem; */
	.page-header {
		padding: 0 0 1rem 1rem;
		border-bottom: ${(props: any) => props.theme.secondary};
	}
`
