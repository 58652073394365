import { LibHierarchy } from "../lib-in-proj.data-model"
import { getTreeFromFlatData } from "../../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"

const ViewsForTreeNav = (self: any) => ({
	treeByFileTypeAndQuery(fileType: "all" | DLObjectFormat, query: string) {
		let tree: any = []
		let list: any = []
		if (fileType === "all") {
			// list = [...self.flatList]
			if (query !== "") {
				list = self.flatList.filter((item: any) =>
					item.title.toLowerCase().includes(query.toLowerCase())
				)
			} else {
				list = self.flatList
			}
		} else {
			if (query !== "") {
				list = self.flatList.filter(
					(item: any) =>
						item.objectFormat === fileType &&
						item.title.toLowerCase().includes(query.toLowerCase())
				)
			} else {
				list = self.flatList.filter(
					(item: any) => item.objectFormat === fileType
				)
			}
		}

		// add parent folder
		list.map((item: any) => {
			if (
				list.findIndex(
					(listItem: any) => listItem.id === item.parentId
				) === -1
			) {
				// if there is no parent after filtering
				ConsoleLog(" ???? ----add parent folder ---- ????")
				const parent = self.flatList.find(
					(originItem: any) => originItem.id === item.parentId
				)
				if (parent) {
					self.toggleExpand(parent.id, true)
					list.push(parent)
				} else {
					// ConsoleLog("There is no parent even in the original list")
				}
			}
		})
		// add other children which is not matched with query tect
		list.map((item: any) => {
			if (item.hierarchy === LibHierarchy.folder) {
				const children = self.flatList.filter(
					(prev: any) => prev.parentId === item.id
				)
				children.map((child: any) => {
					if (
						list.findIndex((item: any) => item.id === child.id) ===
						-1
					) {
						list.push(child)
					}
				})
			}
		})
		tree = getTreeFromFlatData(list, "title")
		return tree
	},
})

export default ViewsForTreeNav
