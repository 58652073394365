import { types } from "mobx-state-tree"

export const OrgTrashItem = types.model({
	id: types.string, // [TrashID] ex) 2461
	type: types.string, // [Type] ex) "Project"
	previousId: types.string, // [FileID] ex) "3429"
	name: types.string, // [Name] ex) "Half year 2 2020 NameC : Remove (123) (Unarchived v2)"
	deletedBy: types.string, // [DeleteBy] ex) "Tejaswini [tkande]"
	deletedAt: types.string // [DeletedOn] ex) "2020-01-06T11:49:23.898965+00:00"
})


export enum TrashType {
	org = "ORGANIZATION",
	noti = "NOTIFICATION",
	none = "NONE"
}