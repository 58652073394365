import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLButton,
} from "../../../../../../components/basic-elements"
import { Icon } from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import { MessageColorType } from "../../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function AddFileLibFolderDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()
	const actionName = "addOrgLibraryFolder"
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters, // TODO: need to update
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}
	const addLibraryFolder = () => {
		orgStore.orgFileLib.addOrgLibraryFolder(inputs.name.value)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addLibraryFolder,
		orgStore.orgFileLib.openAddLibraryFolder // resetPoint
	)

	const actionStatus = orgStore.orgFileLib.getActionStatus(actionName)
	return (
		<DLDialog
			eleTestId="add-org-library-folder"
			isOpen={orgStore.orgFileLib.openAddLibraryFolder}
			setIsOpen={orgStore.orgFileLib.setOpenAddLibraryFolder}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twAddLibGroup || "Add Library Group"}
			dialogContents={
				<AddLibraryFolderForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				isReady &&
				!orgStore.orgFileLib.isDuplicatedLibraryFolderName(
					inputs.name.value
				)
			}
			actionBtn={i18n.twAdd || "Add"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.orgFileLib.responses.getResponse(actionName)?.message
			}
			showSpinner={actionStatus === "LOADING"}
			cannotUpdate={actionStatus === "LOADING"}
			useEnterKeyForSubmit
		/>
	)
})

const AddLibraryFolderForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddLibraryFolderForm>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twLibGroupName || "Library Group Name"}
					>
						<DLInputField
							autoFocus
							eleTestId="library-folder-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(inputs.name.value &&
									orgStore.orgFileLib.isDuplicatedLibraryFolderName(
										inputs.name.value
									) &&
									(i18n.tsSameNameExist ||
										"This name already exist."))
							}
							warningType={
								inputs.name.value &&
								orgStore.orgFileLib.isDuplicatedLibraryFolderName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddLibraryFolderForm>
		)
	}
)

const StyledAddLibraryFolderForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
