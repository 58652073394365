import { getRequest, postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export async function readFA(params: {
	clientId: number
	fsTypeId: number
	tbNameId: number
	wprId: number
}) {
	const response = await getRequest({
		url: "/GetFinalAnalytics",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function saveFA(payload: {
	Conclusion: string
	Purpose: string
	Scope: string
	lstmodel: any
	fsTypeId: number
	tbNameId: number
	wprId: number
}) {
	const response = await postRequest({
		url: "/SaveFinalAnalytics",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function getWPFileSize({ folderId }: { folderId?: number }) {
	const response = await getRequest({
		url: "/WorkspaceWorkPaperFileSize",
		params: { ...(folderId && { folderId }) },
		tokenType: TOKEN_TYPE.projToken,
	})

	return response.data
}
