import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import ReadProjUsers from "./model-actions/read-proj-users"
import ReadGroupUsers from "./model-actions/read-group-users"
import RequestAssignProjUser from "./model-actions/request-assign-proj-user"
import UpdateProjUser from "./model-actions/update-proj-user"
import RequestUnassignProjUser from "./model-actions/request-unassign-proj-user"
import RequestDeactivateProjUser from "./model-actions/request-deactivate-proj-user"
import RequestActivateProjUser from "./model-actions/request-activate-proj-user"
import { ProjSetupUserApiProps } from "./proj-setup-users.apis"
import RequestValidatePrevUserRole from "./model-actions/request-validate-prev-user-role"

const ProjUsersModel = ({
	apiActivate,
	apiDeactivate,
	apiAssign,
	apiRead,
	apiReadGroupUsers,
	apiUpdate,
	apiUnassign,
	apiValidatePrevRole,
}: ProjSetupUserApiProps) =>
	types
		.model({})
		.actions((self, read = apiRead) => ReadProjUsers(self, read))
		.actions((self, readGroupUsers = apiReadGroupUsers) =>
			ReadGroupUsers(self, readGroupUsers)
		)
		.actions((self, assign = apiAssign) =>
			RequestAssignProjUser(self, assign)
		)
		.actions((self, update = apiUpdate) => UpdateProjUser(self, update))
		.actions((self, unassign = apiUnassign) =>
			RequestUnassignProjUser(self, unassign)
		)
		.actions((self, deactivate = apiDeactivate) =>
			RequestDeactivateProjUser(self, deactivate)
		)
		.actions((self, activate = apiActivate) =>
			RequestActivateProjUser(self, activate)
		)
		.actions((self) =>
			RequestValidatePrevUserRole(self, apiValidatePrevRole)
		)
		// common parts
		.actions(CommonModelActions)

export default ProjUsersModel
