export enum OrgI18n {
	menus = "OrgMenus",
	//
	dashboard = "OrgDashboard",
	trash = "OrgTrash",
	clients = "OrgClients",
	archMgmt = "OrgArchMgmt",
	projTemplates = "OrgProjTemplates",
	fileLib = "OrgFileLib",
	groups = "Groups", // This sheet be shared with setupGroups and Groups
	projects = "Projects",
	engType = "OrgEngType",
	ra = "OrgRA",
	users = "OrgUsers",
	archPolicy = "OrgArchPolicy",
	emailNoti = "OrgSetupEmailNoti",
	sysSetting = "OrgSysSettings",
	//
	checklist = "Checklist",
	hardCopyMgmt = "HardCopyMgmt",
}
