import { types } from "mobx-state-tree"
import ProjectTrashModel from "./project-trash.model"
import ProjectTrashViewModel from "./project-trash.view-model"
import * as api from "./project-trash.apis"
import { ProjSetupTrashApiProps } from "./project-trash.apis"
import * as orgProjApis from "../../../../service-modules/project-module/org-project-shared-apis"

export const ProjectTrashStore = ({
	apiRead,
	apiRemove,
	apiRestore,
	apiRemoveAll,
	apiRemoveProj,
}: ProjSetupTrashApiProps) =>
	types
		.compose(
			ProjectTrashModel({
				apiRead,
				apiRemove,
				apiRestore,
				apiRemoveAll,
				apiRemoveProj,
			}),
			ProjectTrashViewModel
		)
		.named("ProjectTrashStore")

export const initialStore = {
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ProjectTrashStoreInstance = ProjectTrashStore({
	apiRead: api.read,
	apiRemove: api.remove,
	apiRestore: api.restore,
	apiRemoveAll: api.removeAll,
	apiRemoveProj: orgProjApis.deleteProject,
}).create(initialStore)

export default ProjectTrashStoreInstance
