import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import useForm from "../../../../../../library/use-form"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
} from "../../../../../../components/basic-elements"
import sharedRegEx from "../../../../../../library/sharedRegEx"
import { ProjTemplateDialog } from "../../store/org-proj-templates.ui-helper"
import { useRootStore } from "../../../../../../stores/root-store/root-store.provider"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function AddProjTemplateGroupDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "addProjTemplateGroup"

	const i18n = store.i18n.globalI18n("AddProjTemplateGroupDialog")
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}
	const addTemplateGroup = () => {
		orgStore.orgProjTemplates.addProjTemplateGroup(inputs.name.value)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		addTemplateGroup
	)

	return (
		<DLDialog
			eleTestId="add-proj-template-group-dialog"
			isOpen={
				orgStore.orgProjTemplates.dialogOpenStatus[
					ProjTemplateDialog.createTemplateGroup
				]
			}
			setIsOpen={() =>
				orgStore.orgProjTemplates.setDialogOpen(
					ProjTemplateDialog.createTemplateGroup
				)
			}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={i18n.twAddTemplateGroup || "Add Template Group"}
			dialogContents={
				<AddProjTemplateGroupForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				!orgStore.orgProjTemplates.isDuplicatedTemplateGroupName(
					inputs.name.value
				) && isReady
			}
			// actionStatus={orgStore.orgProjTemplates.getActionStatus(
			// 	actionName
			// )}
			actionBtn={i18n.twAdd || "Add"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.orgProjTemplates.responses.getResponse(actionName)
					?.message
			}
			showSpinner={
				orgStore.orgProjTemplates.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				orgStore.orgProjTemplates.getActionStatus(actionName) ===
				"LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const AddProjTemplateGroupForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()

		return (
			<StyledAddProjTemplateGroupForm>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={
							i18n.twTemplateGroupName || "Template Group Name"
						}
					>
						<DLInputField
							autoFocus
							eleTestId="template-group-name-input"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={
								inputs.name.error ||
								(inputs.name.value &&
									orgStore.orgProjTemplates.isDuplicatedTemplateGroupName(
										inputs.name.value
									) &&
									(i18n.tsSameNameExist ||
										"The same name already exist"))
							}
							warningType={
								inputs.name.value &&
								orgStore.orgProjTemplates.isDuplicatedTemplateGroupName(
									inputs.name.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.name.requestInput}
						/>
					</InputWithLabel>
				</div>
			</StyledAddProjTemplateGroupForm>
		)
	}
)

const StyledAddProjTemplateGroupForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
