import { observer } from "mobx-react-lite"
import React from "react"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLDialog } from "../../../components/basic-elements"
import { NewAttachmentPropsForGql } from "./PostAddOrEdit"
import styled from "styled-components"
import { S3FileStatus } from "../../../screens/organization-side/notifications/store/notifications.view-model"
import { CommonLocalFileProps } from "../../../components/basic-elements/buttons/DLLocalFileUploadButton"

/**
 * The purpose of this dialog is catch the completion of upload and delete of S3 object
 */
const PostLoadingDialog = observer(
	({
		isOpen,
		setOpen,
		partialStore,
		files,
		hasNewFile,
		addedFilesLength,
		createNewPost,
		updatePost,
		mode,
	}: {
		isOpen: boolean
		setOpen: any
		partialStore: any
		files: any
		hasNewFile: boolean
		addedFilesLength: number
		updatePost: () => void
		createNewPost: (newFiles: NewAttachmentPropsForGql[]) => void
		mode: string // "add" | "edit"
	}) => {
		let getSignedUrlsStatus = partialStore.getActionStatus("getSignedUrls")
		const s3FileStatus = partialStore.s3FilesStatus
		console.log("getSignedUrlsStatus", getSignedUrlsStatus)
		console.log("s3FileStatus", s3FileStatus)

		if (mode === "add") {
			// in this case, there is no deleted files
			if (hasNewFile && s3FileStatus.length === addedFilesLength) {
				let newFiles: NewAttachmentPropsForGql[] = []
				s3FileStatus.map(
					(item: {
						key: string
						status: ActionStatus
						fileName: string
						extension: string
						size: number
						formattedSize: string
					}) => {
						const {
							key,
							status,
							fileName,
							extension,
							size,
							formattedSize,
						} = item
						if (status === ActionStatus.success) {
							newFiles.push({
								key,
								fileName,
								extension,
								size,
								formattedSize,
							})
						}
					}
				)
				createNewPost(newFiles)
				setOpen(false)
			}
		} else if (mode === "edit") {
			console.log(
				"s3FileStatus:",
				s3FileStatus,
				s3FileStatus.length,
				"/ addedFilesLength:",
				addedFilesLength
			)

			// if there are some changes on attachments
			if (s3FileStatus.length === addedFilesLength) {
				updatePost()
				setOpen(false)
			}
			// NOTE: tempoorary.. update the post without waiting the s3 related updates
			// updatePost()
			// setOpen(false)
		}

		return (
			<DLDialog
				eleTestId="post-loading-dialog"
				isOpen={isOpen}
				setIsOpen={setOpen}
				dialogTitle="Loading..."
				dialogContents={
					<DialogContents
						addedFilesLength={addedFilesLength}
						files={files}
						s3FileStatus={s3FileStatus}
					/>
				}
			/>
		)
	}
)

const DialogContents = ({
	addedFilesLength,
	files,
	s3FileStatus,
}: {
	addedFilesLength: any
	files: CommonLocalFileProps[]
	s3FileStatus: S3FileStatus[]
}) => {
	return (
		<StyledPostLOadingDialogContents>
			<div>{addedFilesLength} File(s) upload is inprogress</div>
			<div>
				{files.map((file: CommonLocalFileProps) => {
					return <div>{file.name}</div>
				})}
				{s3FileStatus.map((file: S3FileStatus) => {
					return (
						<div>
							{file.fileName} ({file.formattedSize}) __
							{file.status}
						</div>
					)
				})}
			</div>
		</StyledPostLOadingDialogContents>
	)
}
export default PostLoadingDialog

const StyledPostLOadingDialogContents = styled.div``
