import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../components/basic-elements"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const DeleteOpenedFiles = (self: any, apiDeleteOpenedFiles: any) => ({
	deleteOpenedFiles: flow(function* (payload: any, menuId: DLProjSubMenus) {
		const actionName = "deleteOpenedFiles"
		try {
			// let endpoint = ""
			// if (menuType === DLProjSubMenus.wp) {
			// 	endpoint = "/WorkPaperOpenedFilesForceClose"
			// } else if (menuType === DLProjSubMenus.pf) {
			// 	endpoint = "/PfOpenedFilesForceClose"
			// }
			const response = yield apiDeleteOpenedFiles(payload, menuId)
			ConsoleLog([actionName, "__response ", response])
			return response
		} catch (error) {
			ConsoleLog([actionName, "__error ", error])
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default DeleteOpenedFiles
