import React from "react"
import { DLTooltip } from "@datalobby/components"
import styled from "styled-components"

export type StackedHorizontalBarChartProps = {
	value: number
	color?: string
	label: string
	testId?: string
	fontColor?: string
	clickHandler?: any
}

export function StackedHorizontalBarChart({
	totalWidth,
	unit,
	height,
	data,
	showCountInBar,
	showTooltip,
	clickable,
}: {
	totalWidth: number
	unit: number
	height: number
	data: StackedHorizontalBarChartProps[]
	showCountInBar?: boolean
	showTooltip?: boolean
	clickable?: boolean
}) {
	return (
		<div style={{ width: totalWidth }} className="FR AC">
			{data.length === 1 ? (
				<div className="FR AC">
					<PartialBar
						item={data[0]}
						index={0}
						unit={unit}
						height={height}
						showCountInBar={showCountInBar}
						clickable={clickable}
						showTooltip={showTooltip}
					/>
					{showCountInBar && data[0].value * unit < 30 && (
						<span style={{ paddingLeft: "0.3rem" }}>
							{data[0].value}
						</span>
					)}
				</div>
			) : (
				data.map((item, i: number) => (
					<div key={i}>
						<PartialBar
							item={item}
							index={i}
							unit={unit}
							height={height}
							showCountInBar={showCountInBar}
							clickable={clickable}
							showTooltip={showTooltip}
						/>
					</div>
				))
			)}
		</div>
	)
}

const PartialBar = ({
	item,
	index,
	unit,
	height,
	showCountInBar,
	clickable = true,
	showTooltip,
}: {
	item: StackedHorizontalBarChartProps
	index: number
	unit: number
	height: number
	showCountInBar?: boolean
	clickable?: boolean
	showTooltip?: boolean
}) => {
	const { clickHandler, testId, label, value } = item

	let className = "partial-bar FR AC"
	if (clickable && clickHandler) {
		className = className + " clickable"
	}
	// NOTE: even if the test id is already converted
	const newTestId = testId
		? testId.toLowerCase().replace(/ /g, "-")
		: label.toLowerCase().replace(/ /g, "-")
	return (
		<DLTooltip
			title={showTooltip ? label + ": " + value : ""}
			elePlacement="top"
			arrow
			height={height}
		>
			<StyledPartialBar
				className={className}
				style={{
					width: value * unit,
					height,
					backgroundColor: item.color
						? item.color
						: index % 2 === 1
						? "gray"
						: "lightgray",
					cursor: clickable && clickHandler && "pointer",
				}}
				onClick={clickable ? clickHandler : undefined}
				data-testid={"partial-bar-" + newTestId}
			>
				<span
					style={{
						marginLeft: "0.3rem",
						opacity: value * unit > 30 && showCountInBar ? 1 : 0,
					}}
				>
					{value}
				</span>
			</StyledPartialBar>
		</DLTooltip>
	)
}

const StyledPartialBar = styled.div`
	transition: 0.4s;
	&.clickable:hover {
		opacity: 0.6;
	}
`
