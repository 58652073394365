import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../components/basic-elements"

const AssignClientsToGroup = (self: any, apiReadClientsByGroup: any) => ({
	assignClientsToGroup: flow(function* (payload) {
		const actionName = "assignClientsToGroup"
		try {
			const response = yield apiReadClientsByGroup(payload)

			ConsoleLog(["AssignClientsToGroup response", response])
			return response
		} catch (error) {
			console.error("AssignClientsToGroup error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default AssignClientsToGroup
