import React, { ReactElement } from "react"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

interface InputWithLabelProps {
	direction?: "horizontal" | "vertical"
	required?: boolean
	label: string
	children: React.ReactNode
	eleClassName?: string
	highlight?: boolean
}

const InputWithLabel = ({
	direction = "horizontal",
	label,
	required = false,
	children,
	eleClassName,
	highlight,
}: InputWithLabelProps) => {
	const store = useRootStore()
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	let clsName =
		direction === "horizontal" ? "FR " + eleClassName : "FC" + eleClassName
	if (highlight) {
		clsName = clsName + " highlight"
	}
	return (
		<StyledInputWithLabel className={clsName}>
			<div className="label">
				{/* <div className="label" style={{ color: txtColor }}> */}
				{label}
				{required && <div className="required-mark" />}
			</div>
			<div className="input-area">{children}</div>
		</StyledInputWithLabel>
	)
}

export default InputWithLabel

const StyledInputWithLabel = styled.div`
	width: 100%;
	padding: 0.6rem;
	padding-left: 1.1rem;
	transition: border 0.3s, background-color 0.3s;
	/* to fix the size */
	border: 1px solid ${(props) => props.theme.background};

	.label {
		margin-right: 1rem;
		padding-top: 6px; /* this paddig top come from material ui default padding top of the input field */
		.MuiFormLabel-root {
			color: ${(props) => props.theme.textMediumEmphasis};
		}
		.required-mark {
			background-color: ${(props) => props.theme.warning};
			width: calc(${(props) => props.theme.shared.baseUnit} / 8);
			height: calc(${(props) => props.theme.shared.baseUnit} / 8);
			flex-shrink: 0;
			border-radius: calc(
				${(props) => props.theme.shared.baseUnit} / 8 / 2
			);
			position: relative;
			top: -1.3rem;
			left: -0.5rem;
		}
	}
	&.highlight {
		border: 1px solid ${(props) => props.theme.warning};
		border-radius: 4px;
		background-color: rgba(255, 0, 0, 0.3);
	}
`
