import React from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { getMatchedFileIcon } from "../../../../library/converters/icon-converter"
import styled from "styled-components"
import { DLIconButton, ConsoleLog } from "../../../../components/basic-elements"
import { ApgIcon } from "../../../../components/basic-elements/icons/common-icons"

import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	FileScreenDialog,
	FileUploadFromType,
} from "../../../../service-modules/file-module/data-models/dl-file-control-model"
import { apgLibPrefix } from "../store/lib-in-proj.data-model"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"

export default observer(function LibDrawerFileRow({ node }: { node: any }) {
	const projStore = useProjStore()
	const libStore = projStore.lib
	const currentMenu = libStore.openedOn

	const { id, title, srcFormat } = node
	ConsoleLog([" ------ lib node:", node])

	const dialogName = FileScreenDialog.createFile

	const handleAdd = () => {
		libStore.setSelectedItemId(id)
		if (currentMenu === DLProjSubMenus.wp) {
			projStore.wp.setFileTreeDialogOpen(dialogName)
			projStore.wp.setFileUploadFrom(FileUploadFromType.lib)
		} else if (currentMenu === DLProjSubMenus.pf) {
			projStore.pf.setFileTreeDialogOpen(dialogName)
			projStore.pf.setFileUploadFrom(FileUploadFromType.lib)
		}
	}

	const isApg = id.includes(apgLibPrefix)

	return (
		<StyledFileRow className="lib-drawer-file-row FR AC">
			<DLIconButton
				eleTestId="import-lib-file-to-project"
				eleClassName="import-to-project-btn"
				tooltipText="Import"
				clickHandler={handleAdd}
				color="primary"
			>
				<Icon path={mdiPlus} size={0.8} />
			</DLIconButton>
			<div className="format-icon">
				{isApg ? <ApgIcon /> : getMatchedFileIcon(srcFormat)}
			</div>
			<span className="file-name">{title}</span>
			{/* TODO: NOAH {node.fileType === DLObjectFormat.apg && "(APG)"} {node.title} */}
		</StyledFileRow>
	)
})

const StyledFileRow = styled.div`
	&.lib-drawer-file-row {
		.import-to-project-btn {
			opacity: 0;
			width: 0;
			transition: 0.3s;
			padding: 0;
		}
		&:hover {
			.import-to-project-btn {
				opacity: 1;
				width: ${(props) => props.theme.shared.mediumUnit};
				margin-right: 0.6rem;
			}
			.format-icon {
				display: none;
			}
		}
	}
`
