import { types } from "mobx-state-tree"

const OpenedFilesUiHelper = types
	.model({
		// dialogs
		openedFilesDialogOpen: false,
		openedFilesRequestAccessDialogOpen: false,
	})
	.actions((self) => ({
		// dialogs

		setOpenedFilesDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.openedFilesDialogOpen = request
			} else {
				self.openedFilesDialogOpen = !self.openedFilesDialogOpen
			}
		},
		setOpenedFilesRequestAccessDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.openedFilesRequestAccessDialogOpen = request
			} else {
				self.openedFilesRequestAccessDialogOpen = !self.openedFilesRequestAccessDialogOpen
			}
		},
	}))

export default OpenedFilesUiHelper
