import { observer } from "mobx-react-lite"
import React from "react"
import { DLTooltip } from "@datalobby/components"
import {
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import { DuplicatedUserItemType } from "../../../../../stores/org-sub-stores/org-users-store/view-model-actions/bulk-user-import-related"
import styled from "styled-components"

export default observer(function DuplicatedUserList({
	inputTotal,
	dupItems,
	dupItemsInSheet,
}: {
	inputTotal: number
	dupItems: DuplicatedUserItemType[]
	dupItemsInSheet: DuplicatedUserItemType[]
}) {
	const dupItemsCount = dupItems.length
	const dupItemsInSheetCount = dupItemsInSheet.length

	return (
		<StyledDuplicatedItemList className="duplicated-items-count FC">
			<span className="total-input-count">
				Total Input Count: {inputTotal}
			</span>
			<DLTooltip title={<ToolTipTable list={dupItems} />}>
				<div
					className={
						"with-existing-clients FR AC " +
						(dupItemsCount > 0 ? "warning" : "")
					}
				>
					{dupItemsCount > 0 && (
						<DLIcon name={DLIconName.warning} color="warning" />
					)}
					<span>
						Duplicated with existing clients: {dupItemsCount}
					</span>
				</div>
			</DLTooltip>
			<DLTooltip title={<ToolTipTable list={dupItemsInSheet} />}>
				<div
					className={
						"with-input FR AC " +
						(dupItemsInSheetCount > 0 ? "warning" : "")
					}
				>
					{dupItemsInSheetCount > 0 && (
						<DLIcon name={DLIconName.warning} color="warning" />
					)}
					<span>Duplicated in inputs: {dupItemsInSheetCount}</span>
				</div>
			</DLTooltip>
		</StyledDuplicatedItemList>
	)
})

const ToolTipTable = ({ list }: { list: DuplicatedUserItemType[] }) => {
	return (
		<StyledTooltipTable className="duplication-list-in-input">
			<p>*Orange colored parts are duplicated</p>
			{list.map((item: DuplicatedUserItemType) => {
				return (
					<div className="FR AC">
						<span
							className={
								"aliasId " +
								(item.duplicatedAliasId ? "duplicated" : "")
							}
						>
							{item.aliasId}
						</span>
						<span
							className={
								"email " +
								(item.duplicatedEmail ? "duplicated" : "")
							}
						>
							{item.email}
						</span>
					</div>
				)
			})}
		</StyledTooltipTable>
	)
}

const StyledDuplicatedItemList = styled.div`
	.total-input-count {
	}
	.with-existing-clients {
	}
	.with-input {
	}
`
const StyledTooltipTable = styled.div`
	.aliasId {
		width: 30%;
		margin-right: 0.6rem;
	}
	.email {
		width: 70%;
	}
	.aliasId,
	.email {
		&.duplicated {
			color: ${(props) => props.theme.themeOrange};
		}
		overflow: hidden;
		white-space: nowrap;
	}
`
