import { getRequest } from "../../../../../library/api-requests"
import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetDashboardSummaryDetails",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgDashboardApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
}
