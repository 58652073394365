import { flow } from "mobx-state-tree"

const ModelMapGroupToFSType = (self: any, mapGroup: any) => ({
	modelMapGroupToFSType: flow(function*(payload: {
		fsTypeId: number
		fgroupId: number
	}) {
		const actionName = "modelMapGroupToFSType"
		try {
			const response = yield mapGroup(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ModelMapGroupToFSType
