import React from "react"
import { observer } from "mobx-react-lite"
import { DLIconButton } from "../../../../../components/basic-elements"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { Column } from "@tanstack/react-table"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export default observer(function AdjustmentsTable({
	partialStore,
	permission,
	contentHeight,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	contentHeight: any
}) {
	const handleContextMenu = (event: any, ajeId: string) => {
		event.preventDefault()
		partialStore.setSelectedAjeId(ajeId)
		partialStore.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}

	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu, permission)}
			data={partialStore.viewAdjustments()}
			menuId={DLProjSubMenus.fs_aje}
			showPageSetting={false}
			height={contentHeight - 40}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.ajeId)
			}
			customFilters={filterComponent}
		/>
	)
})

const filterComponent = ({ column }: { column: Column<any> }) => {
	return (
		<InputFieldForTableV8
			columnId={column.id}
			onChange={column.setFilterValue}
			value={column.getFilterValue()}
		/>
	)
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns = (
	handleContextMenu: any,
	permission: PermissionAsObjectProps
) => [
	{
		header: "Ajustment Number",
		accessorKey: "ajeNumber",
		cell: renderCustomCell,
	},
	{
		header: "Adjustment Type",
		accessorKey: "ajeType",
		cell: renderCustomCell,
	},
	{
		header: "Adjustment Description",
		accessorKey: "ajeDesc",
		cell: renderCustomCell,
	},
	{
		header: "Workpaper Reference",
		accessorKey: "wpRef",
		cell: renderCustomCell,
	},
	{
		header: "Actions",
		// show: permission.delete || permission.update, // Hide column by checking permissions
		enableCustomFilter: false,
		enableSorting: false,
		cell: (props: any) => {
			const ajeId = props.row.original.ajeId

			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, ajeId)
						}
						eleTestId="aje-ctx-btn-on-row"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
