import sharedTheme from "./sharedTheme"
import {
	transparency as transp,
	shadowPosition as sp,
} from "./shared-variables"

// NOTE: NO GREEN (Its hard to distinguish with red)
const mainColor = "#609187"
const subColor = "#C3D6D2"
const emphasis = "#c17dff"

const themeBlack = "#031C0D"
const themeShadow = "#A9BCB8"

const themeRed = "#6E10AF" // unarchived project icon color
const themeYellow = "#F5BE40" // locked project icon color
const themeOrange = "#F57C40"
const themePurple = "#6236FF" // archived project icon color

const greenTheme2: { [x: string]: any } = {
	mainColor,
	subColor,
	// ---------- basic variables (sholud be matched with dark theme)
	shared: sharedTheme,
	background: "#ffffff",

	orgSidebar: mainColor,
	projSidebar: subColor,

	orgSidebarEmphasisTxt: "#ffffff",
	projSidebarEmphasisTxt: "#ffffff",
	//
	emphasis,
	emphasisDeep: "#A45AE7",
	emphasisLight: emphasis + transp.p60,
	//
	txtOnEmphasisColor: "#ffffff",

	// ---------- color standard primary
	primaryMainDeep: "#9D848E",
	primaryLight: "#609187",
	primaryLightDeep: "#87B9BB",

	// ---------- color standard secondary
	secondary: subColor + transp.p50,
	secondaryDeep: subColor + transp.p60,

	// ---------- project type color standard
	normalProject: themeBlack + transp.p60,
	normalLockedProject: themeYellow,
	replicaProject: themeBlack + transp.p20,
	archivedProject: themePurple,
	unarchivedProject: themeRed,

	// ---------- warning and dangerous
	warning: themeRed,
	warningDeep: "#B41602",

	// ---------- text standard
	textHighEmphasis: themeBlack + transp.p90,
	textMediumEmphasis: themeBlack + transp.p80,
	textDisabled: themeBlack + transp.p35,

	// ---------- shade
	shade05: themeBlack + transp.p05,
	shade10: themeBlack + transp.p10,
	shade20: themeBlack + transp.p20,
	shade30: themeBlack + transp.p30,
	shade40: themeBlack + transp.p40,
	shade60: themeBlack + transp.p60,
	shade90: themeBlack + transp.p90,

	// ---------- shadow
	shadowColorLight: themeShadow + transp.p60,
	shadowColorDeep: themeShadow + transp.p80,
	// stand
	shadowRegularLight: sp.regular + themeShadow + transp.p60,
	shadowRegularDeep: sp.regular + themeShadow + transp.p80,
	// left
	shadowRegularLeftLight: sp.left + themeShadow + transp.p60,
	shadowRegularLeftDeep: sp.left + themeShadow + transp.p80,
	// right
	shadowRegularRightLight: sp.right + themeShadow + transp.p60,
	shadowRegularRightDeep: sp.right + themeShadow + transp.p80,
	//

	themeRed,
	themeYellow,
	themeOrange,

	themePurple,
}

export default greenTheme2
