import { types } from "mobx-state-tree"
// ---------- common parts
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import ResetUserPassword from "./view-model-actions/reset-user-pw"

const ResetPasswordViewModel = types
	.model({
		// ---------- common models
		responseSnackbar: ResponseSnackbar,
		responses: Responses,
	})
	.actions(CommonViewModelActions)
	// AGER
	.actions(ResetUserPassword)
	.views(ViewResponseHelper)
export default ResetPasswordViewModel
