import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { CannotAccess } from "../../../../components/basic-elements"
import OrgSetupNotifications from "./OrgSetupNotifications"

export default observer(function OrgSetupNotificationsController() {
    const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_notifications
	// access & permission
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	
	useEffect(() => {
		if (menuAccess) {
			orgStore.setupEngTypes.getEngTypeList()
		}
	}, [menuAccess])
	
    return (
		<>
			{menuAccess ? (
				<OrgSetupNotifications
					permission={permission}
					contentHeight={store.ui.contentsAreaHeight}
				/>
			) : (
				<CannotAccess />
			)}
		</>
	)
})
