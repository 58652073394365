import React from "react"
import styled from "styled-components"

export default function CannotAccess() {
	return (
		<StyledCannotAccess className="FR AC JC">
			You don't have access on this Page. Do you want to go back?
		</StyledCannotAccess>
	)
}

const StyledCannotAccess = styled.div`
	width: 100%;
	height: 100%;
`
