import React from "react"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"

export default function DLBasicToggleButton({
	on,
	setOn,
	showText = false,
	disabled = false,
	colorWithOn = true,
	tooltipText,
	eleTestId = "toogle-button",
}: {
	on: boolean
	setOn: any
	showText?: boolean
	disabled?: boolean
	colorWithOn?: boolean
	tooltipText?: string
	eleTestId?: string
}) {
	return (
		// WARNING: TODO: What is the strength of this custom component without Switch of the Material UI? (I just don't like its looks...)
		<StyledDLToggleButton
			className={`dl-toggle-button ${disabled && "disabled"} ${
				colorWithOn && "colorWithOn"
			}`}
		>
			{showText && <span>ON</span>}
			{tooltipText ? (
				<DLTooltip title={tooltipText}>
					<div
						className={`container AC FR ${on ? "JR ON" : "JL"}`}
						onClick={() => setOn()}
						data-testid={eleTestId}
					>
						<div className="handle" />
					</div>
				</DLTooltip>
			) : (
				<div
					className={`container AC FR ${on ? "JR ON" : "JL"}`}
					onClick={() => setOn()}
					data-testid={eleTestId}
				>
					<div className="handle" />
				</div>
			)}

			{showText && <span>OFF</span>}
		</StyledDLToggleButton>
	)
}

const StyledDLToggleButton = styled.div`
	&.dl-toggle-button {
		.container {
			height: 18px;
			width: 40px;
			border-radius: 9px;
			background-color: ${(props) => props.theme.shade40};
			padding: 3px 5px;
			transition: 0.4s;
			cursor: pointer;
			:hover {
				background-color: ${(props) => props.theme.shade60};
			}
			.handle {
				width: 10px;
				height: 10px;
				border-radius: 50%;
				background-color: ${(props) => props.theme.background};
				cursor: pointer;
			}
		}
	}
	&.dl-toggle-button.disabled {
		opacity: 0.4;
		pointer-events: none;
	}
	&.dl-toggle-button.colorWithOn {
		.container.ON {
			background-color: ${(props) => props.theme.emphasis};
		}
	}
`
