import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import DLMenusWithSingleAction from "../../../../components/basic-elements/menus/DLMenusWithSingleAction"
import * as LanguageIcons from "./language-icons"
import Icon from "@mdi/react"
import { mdiTranslate } from "@mdi/js"
import { observer } from "mobx-react-lite"

/**
 *
 * Shared with LanguageSelectionDialog
 */
export const sharedChangeLanguage = ({
	value,
	store,
	postAction,
}: {
	value: any
	store: any
	postAction?: any
}) => {
	let language = value.slice(0, 2)
	if (language === "ko") {
		language = "kr"
	}
	const countryCode = value.slice(3, 5)

	if (language === "kr" || language === "en") {
		store.i18n.editUserLanguage(
			countryCode,
			language,
			store.checkin.orgUserIds[0]
		)
		postAction && postAction()
	} else {
		alert(
			`Sorry. Language code ${language} is incorrect. Please contact to system administrator.`
		)
	}
}

export default observer(function LanguageMenus() {
	const store = useRootStore()

	const changeLanguage = (value: any) => {
		sharedChangeLanguage({ value, store })
	}

	const CurrentLanguageIcon = (countryCode: string) => {
		if (countryCode === "US") {
			return LanguageIcons.US()
		} else if (countryCode === "UK") {
			return LanguageIcons.UK()
		} else if (countryCode === "IN") {
			return LanguageIcons.IN()
		} else if (countryCode === "KR") {
			return LanguageIcons.KR()
		} else {
			return <Icon path={mdiTranslate} size={1} />
		}
	}

	const languageOptions = [
		{
			label: "English",
			value: "en-UK",
			icon: LanguageIcons.UK(),
		},
		{
			label: "English",
			value: "en-US",
			icon: LanguageIcons.US(),
		},
		{
			label: "English",
			value: "en-IN",
			icon: LanguageIcons.IN(),
		},
		{
			label: "Korean",
			value: "ko-KR",
			icon: LanguageIcons.KR(),
		},
	]

	let countryCode = store.i18n.country
	return (
		<DLMenusWithSingleAction
			remoteButton={CurrentLanguageIcon(countryCode)}
			menuOptions={languageOptions}
			handleSelect={changeLanguage}
			eleTestId="language-select-menu-open"
		/>
	)
})
