// ---------- reponse cases
import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
} from "../../../../../../../common-models/enumerations/common-enums"

import {
	idToString,
	IdType,
} from "../../../../../../../library/converters/id-converter"
import { initialDLFileAsFolder } from "../../../../../../../service-modules/file-module/data-models/initial-file"
import { DLOrgSubMenus } from "../../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const GetProjTemplateFirstFolders = (self: any) => ({
	getProjTemplateFirstFolders(templateId: string) {
		// 0.
		// NOTE: Both a template and its internal folders are using same API
		// NOTE: but just make individual viewModel action because it makes some confusions...
		// WARNING: This is better, right..? wp and pf should work like this in the future maybe
		// (They should use same API without distinguish the first folder and child folders)
		// (= get file-folder details also uses same API )
		const actionName = "getProjTemplateFirstFolders"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3
		self.readProjTemplateDetails(templateId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)

					// this API retruns 2 array: (a)ChildFolds, (b)TempFiles

					const fetchedFolders = response.data.ChildFolds
					// const fetchedFiles = response.data.TempFiles

					// * API response for folders
					// FoldId: "24586"
					// FoldName: "Planning"
					// Index: 0
					// set empty list

					// NOTE: @Noah: Isn't it better to reset the list before start?
					fetchedFolders.map((folder: any, index: number) => {
						const folderId = idToString(
							folder.FoldId,
							IdType.folder
						)
						let reOrganizedFolder = {
							...initialDLFileAsFolder,
							id: folderId,
							parentId: null,
							isParent: true,
							//
							title: folder.FoldName,
							menuId: DLOrgSubMenus.proj_templates,
							objectFormat: DLObjectFormat.folder,
							expanded: false,
							index: folder.Index,
						}

						self.pushFlatItem(reOrganizedFolder)
					})

					// update success case response
					self.setNeedRefresh(false)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get template details",
						open: false,
						autoHide: true,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetProjTemplateFirstFolders
