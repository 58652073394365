import { types } from "mobx-state-tree"
import OrgTrashModel from "./org-trash.model"
import OrgTrashViewModel from "./org-trash.view-model"
import OrgTrashUiHelper from "./org-trash.ui-helper"
import * as api from "./org-trash-apis"
import { OrgTrashApiProps } from "./org-trash-apis"
import { initialClickPoint } from "../../../../common-models/types/dialog.props"
import { TrashType } from "./data-models/org-trash.data-models"

export const initialStore = {
	// ui-helper
	selectedItems: [],
	selectedTrashType: TrashType.none,
	clickPoint: initialClickPoint,
	//common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

export const OrgTrashStore = ({
	apiRead,
	apiRemove,
	apiRestore,
}: OrgTrashApiProps) =>
	types
		.compose(
			OrgTrashModel({ apiRead, apiRemove, apiRestore }),
			OrgTrashViewModel,
			OrgTrashUiHelper
		)
		.named("OrgTrashStore")

const OrgTrashStoreInstance = OrgTrashStore({
	apiRead: api.read,
	apiRemove: api.remove,
	apiRestore: api.restore,
}).create(initialStore)

export default OrgTrashStoreInstance
