import React from "react"
import {
	ConsoleLog,
	DLContextMenu,
} from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"

export default observer(function AdjustmentsTableMenus({
	partialStore,
	permission,
	menuId,
}: {
	partialStore: any
	permission: PermissionAsObjectProps
	menuId: DLProjSubMenus
}) {
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	ConsoleLog(" -=-=-=-=-$ AdjustmentsTableMenus $-=-=-=-=- ")

	const selectedAjeId = partialStore.selectedAjeId
	const link =
		menuId === DLProjSubMenus.fs_aje
			? `${defaultUrl}/financial-statements/adjustments/view/${selectedAjeId}`
			: `${defaultUrl}/financial-statements/passed-adjustments/view/${selectedAjeId}`
	const menuOptions = [
		{
			label: "View",
			value: "ctx-view-aje",
			available: permission.update,
			isLink: true,
			linkTo: link,
			clickEvent: () => {},
		},
		{
			label: "Delete",
			value: "ctx-delete-aje",
			available: permission.delete,
			clickEvent: () => {
				const selectedAjeId = partialStore.selectedAjeId
				let proceed = window.confirm(`Do you really want to delete?`)
				if (proceed) {
					if (menuId === DLProjSubMenus.fs_aje) {
						partialStore.removeAdjustments(selectedAjeId)
					} else {
						partialStore.removePassedAdjustments(selectedAjeId)
					}
				} else {
					return
				}
			},
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	const handleClose = () => {
		partialStore.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="fs-aje-ctx-menus"
			clickPoint={{
				mouseX: partialStore.clickPoint.mouseX,
				mouseY: partialStore.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0, 2]}
		/>
	)
})
