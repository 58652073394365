import { flow } from "mobx-state-tree"

const CreatePassedAdjustment = (self: any, create: any) => ({
	createPassedAdjustment: flow(function*(payload: {
		adjDescription: string
		adjustmentType: string
		// CabinetId: 1380
		clientId: number
		// FinancialYear: "2020-09-10"
		tbnameId: number
		// UserID: 861
		wprid: number
	}) {
		const actionName = "createPassedAdjustment"
		try {
			const response = yield create(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default CreatePassedAdjustment
