import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"

export default observer(function DeleteAndDuplicateLibraryFolderDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	// Note : same dialog is used for delete and duplicate in folder and template
	// type - Delete or Duollicate
	// dialogType - Folder or Template

	const orgStore = useOrgStore()
	const type = orgStore.orgFileLib.dialogActionType
	const dialogType = orgStore.orgFileLib.dialogType
	const actionName = `${type}OrgLibrary${dialogType}`

	const handleOnSubmit = () => {
		const folderId = orgStore.orgFileLib.selectedLibraryFolder
		const templateId = orgStore.orgFileLib.selectedTemplate.id
		if (type === "delete" && dialogType === "Folder") {
			orgStore.orgFileLib.removeOrgLibraryFolder(folderId)
		}
		if (type === "duplicate" && dialogType === "Folder") {
			orgStore.orgFileLib.duplicateOrgLibraryFolder(folderId)
		}
		if (type === "delete" && dialogType === "Template") {
			orgStore.orgFileLib.removeOrgLibraryTemplate(templateId)
		}
		if (type === "duplicate" && dialogType === "Template") {
			orgStore.orgFileLib.duplicateOrgLibraryTemplate(templateId)
		}
	}

	return (
		<DLDialog
			eleTestId={`${type}-org-library-${dialogType}`}
			isOpen={orgStore.orgFileLib.openDeleteAndDuplicateDialog}
			setIsOpen={orgStore.orgFileLib.setOpenDeleteAndDuplicateDialog}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={
				type === "delete"
					? i18n.twDelete || "Delete"
					: i18n.twDuplicate || "Duplicate"
			}
			dialogContents={
				type === "delete"
					? i18n.tsDoYouReallyWantToDelete ||
					  "Do you really want to delete?"
					: i18n.tsAreYouSureToDuplicate ||
					  "Are you sure you want to duplicate this item?"
			}
			actionReady={true}
			showOpenBtn={false}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionBtn={
				type === "delete"
					? i18n.twDelete || "Delete"
					: i18n.twDuplicate || "Duplicate"
			}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				orgStore.orgFileLib.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
