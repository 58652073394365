import React from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLIconButton,
	DLButton,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import Icon from "@mdi/react"
import { mdiDotsHorizontal } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { PageHeader } from "../../../../../components/app-frame-elements"
import { Table, Column } from "@tanstack/react-table"
import { AddRiskAssessmentBtn } from "../dialogs/AddRiskAccessmentDialog"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { dateRangeFilter } from "../../../../../library/react-table-utils"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"

export default observer(function OrgSetupRiskAssessmentTable({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_risk
	const contentHeight = store.ui.contentsAreaHeight

	const handleContextMenu = (event: any, riskAssessmentId: string) => {
		event.preventDefault()
		orgStore.setupRiskAssessment.setSelectedRiskAssessment(riskAssessmentId)
		orgStore.setupRiskAssessment.setClickPoint({
			mouseX: event.clientX - 2,
			mouseY: event.clientY - 4,
		})
	}
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue

	const pageHeader = (instance: Table<any>) => {
		const totalRA = instance.getPreFilteredRowModel().flatRows.length
		const filteredRA = instance.getFilteredRowModel().flatRows.length

		return (
			<PageHeader>
				<div className="FR AC counter-container">
					<BasicCounter
						label={i18n.twTotal || "Total: "}
						value={totalRA}
					/>

					{totalRA > filteredRA && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count">
								Filtered Engagement Types: {filteredRA}
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={instance.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)}
				</div>
				<div className="FR AC">
					{permission.create && <AddRiskAssessmentBtn i18n={i18n} />}
				</div>
			</PageHeader>
		)
	}

	return (
		<ReactTableV8
			tableColumns={columns(handleContextMenu, i18n, permission)}
			data={orgStore.setupRiskAssessment.formattedList(
				dateFormat,
				timeZone
			)}
			hasPagination={true}
			customFilters={(props: any) =>
				filterComponent({ ...props, dateFormat })
			}
			handleContextMenu={(e: any, row: any) =>
				handleContextMenu(e, row.riskAssessmentId)
			}
			height={contentHeight - 48}
			permission={permission}
			i18n={i18n}
			pageHeader={pageHeader}
			menuId={menuId}
		/>
	)
})

const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns = (
	handleContextMenu: any,
	i18n: DLI18nProps,
	permission: PermissionAsObjectProps
) => [
	{
		header: i18n.twName || "Name",
		accessorKey: "riskAssessmentName",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: "Actions",
		accessorKey: "xxx",
		enableColumnFilter: false,
		size: 20,
		cell: (props: any) => {
			const riskAssessmentId = props.row.original.riskAssessmentId
			return (
				<div className="FR AC JC" style={{ width: "100%" }}>
					<DLIconButton
						aria-label="more"
						clickHandler={(event: any) =>
							handleContextMenu(event, riskAssessmentId)
						}
						eleTestId="ra-ctx-btn-on-row"
					>
						<Icon path={mdiDotsHorizontal} size={0.8} />
					</DLIconButton>
				</div>
			)
		},
	},
]
