import React from "react"
import TextField from "@material-ui/core/TextField"
import Autocomplete from "@material-ui/lab/Autocomplete"
import { Interface } from "readline"

interface ComboBoxProps {
	options: Array<any>
	optionType?: Interface
	placeholder?: string
	eleOnChange?: React.EventHandler<any>
	eleName?: string
	eleClassName?: string
	onChange: (value: any) => void
	getOptionLabel?: (data: any) => string
	getOptionDisabled?: any
	loading?: any
	renderOption?: any
	helperText?: string
	groupBy?: any
	renderInput?: any
	withoutLabel?: boolean
	eleTestId?: string
	defaultValue?: any
	eleValue?: any
	eleDisabled?: boolean
	filterOptions?: any
	variant?: "standard" | "outlined" | "filled"
}
// TODO: Need to add testId as required

/**
 * ComboBox is a type of autocomplete for predefined inputs
 */
export default function DLComboBox({
	options,
	placeholder,
	eleOnChange = undefined, // TODO: Remvoe this or onChange
	onChange, // onChange return the selected item
	eleName,
	eleClassName,
	getOptionLabel = undefined,
	getOptionDisabled,
	loading = undefined,
	renderOption = undefined,
	helperText = undefined,
	groupBy,
	renderInput,
	withoutLabel,
	eleTestId,
	defaultValue,
	eleValue,
	eleDisabled,
	filterOptions,
	variant = "standard"
}: ComboBoxProps) {
	return (
		<Autocomplete
			data-testid={eleTestId}
			className={eleClassName}
			options={options}
			getOptionLabel={
				getOptionLabel ? getOptionLabel : (option) => option.name
			}
			filterOptions={filterOptions}
			getOptionSelected={(option: any, value: any) => true}
			renderOption={renderOption}
			loading={loading}
			getOptionDisabled={getOptionDisabled}
			defaultValue={defaultValue}
			value={eleValue}
			disabled={eleDisabled}
			renderInput={
				renderInput
					? renderInput
					: (params) => (
							<TextField
								{...params}
								label={withoutLabel ? "" : placeholder}
								placeholder={placeholder}
								fullWidth
								name={eleName}
								helperText={helperText}
								variant={variant as any}
							/>
							// NOTE: InputField cannot provide option list
					  )
			}
			onChange={(event: any, value: any) => onChange(value)}
			groupBy={groupBy}
		/>
	)
}
