import { flow } from "mobx-state-tree"

const ReadCheckedOutWpFiles = (self: any, readWP: any) => ({
	readCheckedOutWpFiles: flow(function* () {
		const actionName = "readCheckedOutWpFiles"
		try {
			const response = yield readWP()
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error, error.response)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadCheckedOutWpFiles
