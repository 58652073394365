import {
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import { PostType } from "@datalobby/types"

const GetProjBoardList = (self: any) => ({
	getProjBoardList(projId: string) {
		const actionName = "getProjBoardList"

		self.responses.putResponse({
			actionName,
			status: ActionStatus.loading,
		})
		// 2) request recent project list to Model
		self.readProjBoardList(projId)
			.then((response: any) => {
				const list = response
				console.log(list)
				let newList: PostType[] = []
				list.map((item: any) => {
					newList.push({
						menu: item.menu,
						id: item.id,
						attachments: item.attachments || [],
						createdAt: item.createdAt,
						createdBy: item.createdBy,
						fixToTop:
							item.fixToTop !== null ? item.fixToTop : false,

						modifiedAt: item.modifiedAt || "",
						modifiedBy: item.modifiedBy || "",
						parent: item.parent,
						priority: item.priority || "MEDIUM",
						sector: item.sector,
						title: item.title,
						content: item.content,
						// visitorCount:
						// 	item.visitorCount !== null ? item.visitorCount : 0,
						// visitors: item.visitors || [],
						// whoCanRead: item.whoCanRead || "",
						// allowedTargets: item.allowedTargets || [],
						deleted: item.deleted,
					})
				})
				self.setProjBoardList(newList)
				self.setNeedRefresh(false)
				self.handleResponse({
					actionName,
					status: ActionStatus.success,
					code: 200,
					customMessage:
						"Successfully fetched the project notifications",
					color: MessageColorType.blue,
					open: true,
					autoHide: true,
				})
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					actionName,
					error,
					open: true,
				})
			})
	},
})

export default GetProjBoardList
