import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../orgProjects.apis"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ConsoleLog } from "../../../../components/basic-elements"

const ReadOrgProjectList = (
	self: any,
	read: OrgProjectApiProps["apiRead"]
) => ({
	readOrgProjectList: flow(function* ({
		projStatus,
		year,
		searchText,
		PTMAssignedOnly,
		ForROAM,
	}: {
		projStatus: ProjectStatus
		year: "All Years" | string
		searchText?: string
		PTMAssignedOnly: boolean
		ForROAM?: boolean
	}) {
		const actionName = "readOrgProjectList"
		// 1.
		if (projStatus === undefined || year === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. projStatus: ${projStatus} / year: ${year}`
			)
			return
		}
		// 2.
		ConsoleLog(["projStatus:", projStatus, "| year:", year])
		let projTypeId: any = undefined
		if (projStatus === ProjectStatus.normal) {
			projTypeId = 1 // 1 = normal project
		} else if (projStatus === ProjectStatus.replica) {
			projTypeId = 2 // 4 = replica project
		} else if (
			projStatus === ProjectStatus.archived ||
			projStatus === ProjectStatus.roamArchived
		) {
			projTypeId = 3 // 3 = archived project
		} else if (projStatus === ProjectStatus.unarchived) {
			projTypeId = 4 // 4 = unarchived project
		} else if (projStatus === ProjectStatus.forceArchived) {
			projTypeId = 5 // 5 = Force Archived
		}
		// + to check the 'Locked', use the 'isLocked'
		const numberYear = year === "All Years" ? 0 : parseInt(year)
		if (typeof projTypeId === "number" && typeof numberYear === "number") {
			try {
				const payload = {
					FinancialYear: numberYear,
					PageIndex: 1,
					PageSize: 10000, // TODO: PageSize need to update (! Check the assigned projects model comment)
					ProjectType: projTypeId,
					PTMAssignedOnly,
					ForROAM,
					// belows are not required
					ClientMasterId: 0,
					EngagementPartner: "",
					EngagementTypeID: 0,
					GroupId: 0,
					QualityControl: "",
					SearchText: searchText || "",
					periodID: 0,
				}
				const response = yield read(payload)

				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(
				`(${actionName}) There are invalid props: ${projTypeId} / ${year}`
			)
		}
	}),
})

export default ReadOrgProjectList
