import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { findProjRoleId } from "../../../../../temporary-data/project-side/find-proj-role"

const GetAssignedUsers = (self: any) => ({
	getAssignedUsers() {
		// 0. set actionName
		const actionName = "getAssignedUsers"
		ConsoleLog(["action---", actionName, "---run"])
		// 1.
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		self.readAssignedUsers()
			.then((response: any) => {
				if (response) {
					if (response.status === 200 && response.data.Status === 1) {
						ConsoleLog(["readAssignedUsers", response])
						const users = response.data.UsersList
						let reOrganizedUsers: any[] = []

						users.map((user: any) => {
							const newUser = {
								id: user.UserID.toString(),
								type:
									user.UserType === 1
										? "internal"
										: "external",
								name: user.Name,
								aliasId: user.UserName,
								email: user.Email,
								roleId: findProjRoleId(user.RoleId),
							}
							reOrganizedUsers.push(newUser)
						})
						// set assigned users with reOrganized response
						self.setAssignedUsers(reOrganizedUsers)
						// set response as success
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 999, // temp
							customMessage:
								"Successfully get the assigned users for roles screen",
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAssignedUsers
