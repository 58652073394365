import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import GetOrgMgmtList from "./view-model-actions/get-org-mgmt-list"
import AddOrgCustomer from "./view-model-actions/add-organization"
import EditOrgCustomer from "./view-model-actions/edit-org-customer"
import { OrgMgmtListItem } from "./data-models/org-mgmt-list.data-model"
import { initialStore } from "./org-mgmt.provider"
import { sortBy } from "@datalobby/common"

const OrgMgmtViewModel = types
	.model({
		orgMgmtList: types.optional(types.array(OrgMgmtListItem), []),
		//
		// common parts
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		resetList() {
			self.orgMgmtList.length = 0
		},
		pushItemToList(item: any) {
			self.orgMgmtList.push(item)
		},
		updateWholeList(newList: typeof self.orgMgmtList) {
			self.orgMgmtList.length = 0
			self.orgMgmtList = newList
		},
		updateCustInfo(custInfo: any) {
			const target = self.orgMgmtList.find(
				(cust) => cust.id === custInfo.CustID.toString()
			)
			if (target) {
				target.custName = custInfo.CustName
				target.organizationName = custInfo.OrganisationName
				target.custStatus = custInfo.CustStatus
			} else {
				console.error("No matched engType ID")
			}
		},
	}))
	// CRUD action
	.actions(GetOrgMgmtList)
	.actions(AddOrgCustomer)
	.actions(EditOrgCustomer)
	.views((self) => ({
		get sortedOrgList() {
			const copiedArray = self.orgMgmtList.slice()
			return sortBy(copiedArray, "organizationName")
		},
	}))
	// common actions
	.actions(CommonViewModelActions)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions((self) => ({
		refresh() {
			self.initializeStore()
			self.getOrgMgmtList()
		},
	}))
	.views(ViewResponseHelper)
	.views((self) => ({
		isDuplicatedName(name: string) {
			return self.orgMgmtList.some((cust: any) => cust.custName === name)
		},
		isDuplicatedEmail(email: string) {
			return self.orgMgmtList.some((cust: any) => cust.emailId === email)
		},
		isDuplicatedOrganization(orgName: string) {
			return self.orgMgmtList.find(
				(cust: any) => cust.organizationName === orgName
			)
		},
		selectedCustDetails(id: string) {
			return self.orgMgmtList.find((cust: any) => cust.id === id)
		},
	}))

export default OrgMgmtViewModel
