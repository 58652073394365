import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../../components/basic-elements"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const RemoveRationale = (self: any) => ({
	removeRationale(rationaleId: string) {
		// 0.
		const actionName = "removeRationale"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		ConsoleLog(["rationaleId", rationaleId])

		let params = {
			rationaleID: parseInt(rationaleId),
		}

		self.deleteRationale(params)
			.then((response: any) => {
				// console.log(actionName, "__response:", response)

				if (response.status === 200 && response.data.code === 1) {
					self.resetRationale(rationaleId)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
					})
				} else if (
					response.status === 200 &&
					response.data.code === 0
				) {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999, // temp
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemoveRationale
