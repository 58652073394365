import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLSnackbar } from "../../../../components/basic-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { useHistory } from "react-router-dom"

export default observer(function ForgotPasswordSnackbar() {
	const store = useRootStore()
	let history = useHistory()

	return (
		<DLSnackbar
			type={store.forgotPw.responseSnackbar.type}
			open={store.forgotPw.responseSnackbar.open}
			setOpen={() => store.forgotPw.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={store.forgotPw.responseSnackbar.message}
			autoHideDuration={store.forgotPw.responseSnackbar.autoHideDuration}
			autoHide={store.forgotPw.responseSnackbar.autoHide}
			additionalAction={() => {
				// store.forgotPw.responses.putResponse({
				// 	actionName: "passwordReset",
				// 	status: ActionStatus.standby
				// })
				history.push("/login")
			}}
		/>
	)
})
