import React from "react"
import { PageContainer } from "../../../../components/app-frame-elements"

export default function ReportController() {
	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			Reports
		</PageContainer>
	)
}
