import axios from "axios"
import {
	APIProps,
	TOKEN_TYPE,
	setTokenForAxiosAuth,
} from "./shared-for-api-requests"

/**
 * from axiox documentation
 * -  `params` are the URL parameters to be sent with the request
 *     Must be a plain object or a URLSearchParams object
 * -  `data` is the data to be sent as the request body
 * 		Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
 * 		When no `transformRequest` is set, must be of one of the following types:
 * 		- string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
 * 		- Browser only: FormData, File, Blob
 * 		- Node only: Stream, Buffer
 *
 */
const CancelToken = axios.CancelToken
const source = CancelToken.source()

export function postWithQueryString({ url, tokenType, version }: APIProps) {
	setTokenForAxiosAuth(tokenType)

	if (version === "v2") {
		return axios.post(process.env.REACT_APP_API_ENDPOINT_V2 + url)
	} else {
		return axios.post(process.env.REACT_APP_API_ENDPOINT + url)
	}
}

export function postToDownload({ url, params = {}, tokenType }: APIProps) {
	setTokenForAxiosAuth(tokenType)
	params.cancelToken = source.token

	// for 'Access-Control-Allow-Origin' // TODO: seems not required... remove?
	if (params.headers) {
		params.headers.withCredentials = true
	} else {
		params.headers = { withCredentials: true }
	}

	return axios.post(process.env.REACT_APP_API_ENDPOINT + url, params, {
		responseType: "blob",
	})
}

export function postFormData({
	url,
	formData,
	tokenType,
}: {
	url: string
	formData: object
	tokenType: TOKEN_TYPE
}) {
	setTokenForAxiosAuth(tokenType)

	return axios({
		method: "post",
		url: process.env.REACT_APP_API_ENDPOINT + url,
		data: formData,
		// headers: { "Content-Type": "multipart/form-data" },
	})
}
