import { types } from "mobx-state-tree"
import ReadOrgRoleDetails from "./model-actions/read-org-role-details"
import ReadOrgAssignedUsers from "./model-actions/read-org-assigned-users"
import ReadRoleSet from "../../../org-access-control/org-checkin/store/model-actions/read-role-set"

const OrgRolesModel = types
	.model({})
	.actions((self) => ({}))
	.actions(ReadRoleSet)
	.actions(ReadOrgRoleDetails)
	.actions(ReadOrgAssignedUsers)

export default OrgRolesModel
