import { flow } from "mobx-state-tree"
import { AmArchivedApiProps } from "../../../am-archived-projects/store/am-archived-projects.apis"

const ReadAmArchivedProjectList2 = (
	self: any,
	read: AmArchivedApiProps["apiRead"]
) => ({
	readAmArchivedProjectList2: flow(function* (
		year: string,
		pageSize: number,
		pageIndex: number,
		engType: string,
		group: string,
		period: string,
		epUsers: string,
		qcUsers: string,
		client: string
	) {
		// 0. set actionName
		const actionName = "readAmArchivedProjectList"
		const numberYear = year === "All Years" ? 0 : parseInt(year)
		try {
			const payload = {
				// UserId: 16,
				// CustomerID: 1,
				EngagementTypeID: parseInt(engType),
				GroupID: parseInt(group),
				EpUserID: parseInt(epUsers),
				QCUserID: parseInt(qcUsers),
				ClientID: parseInt(client),
				Year: numberYear,
				SearchText: "",
				PageIndex: pageIndex || 0,
				PageSize: pageSize || 10000,
				usage: 1,
				periodID: parseInt(period),
			}
			const response = yield read(payload)

			// ConsoleLog(
			// 	"readAmArchivedProjectList response",
			// 	response
			// )
			return response
		} catch (error) {
			// ConsoleLog("readAmArchivedProjectList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadAmArchivedProjectList2
