import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import useForm from "../../../../../library/use-form"
import sharedRegEx from "../../../../../library/sharedRegEx"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
} from "../../../../../components/basic-elements"

import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"

const EditEngTypeDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	// default setting
	const orgStore = useOrgStore()
	const actionName = "editEngType"
	const { twEditEngType } = i18n
	//
	const inputsSchema = {
		name: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const editEngType = () => {
		const userId = orgStore.checkin.orgInfo.userId
		const engagementId = orgStore.setupEngTypes.engagementId
		const engTypeName = inputs.name.value
		orgStore.setupEngTypes.editEngType({
			engTypeName,
			engagementId,
			userId,
		})
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		editEngType
	)

	const engTypeInfo = orgStore.setupEngTypes.getEngTypeById(
		orgStore.setupEngTypes.engagementId
	)

	useEffect(() => {
		inputs.name.value = engTypeInfo ? engTypeInfo.name : ""
	}, [engTypeInfo])

	return (
		<DLDialog
			eleTestId="edit-engtype-dialog"
			isOpen={orgStore.setupEngTypes.openEdit}
			setIsOpen={orgStore.setupEngTypes.setOpenEdit}
			showCloseBtn={true}
			showOpenBtn={false}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPencil}
						size={1}
						color={MessageColorType.default}
						style={{ marginRight: 8 }}
					/>
					{twEditEngType}
				</div>
			}
			dialogContents={
				<EditEngTypeDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
					actionName={actionName}
					defaultData={engTypeInfo}
				/>
			}
			actionReady={isReady && engTypeInfo?.name !== inputs.name.value}
			handleAction={handleOnSubmit}
			actionBtn={i18n.twUpdate}
			cancelBtnText={i18n.twCancel}
			showSpinner={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupEngTypes.getActionStatus(actionName) === "LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const EditEngTypeDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
		actionName,
		defaultData,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
		actionName: string
		defaultData: any
	}) => {
		const orgStore = useOrgStore()
		return (
			<StyledEditDialog>
				<InputWithLabel required label={i18n.twName}>
					<DLInputField
						eleTestId="engagement-type-name-input"
						eleFullWidth
						eleName="name"
						eleValue={inputs.name.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={inputs.name.error}
						requestInput={inputs.name.requestInput}
					/>
					{/* CHECK 1: Display fail message when the API return the fail response  */}
					{orgStore.setupEngTypes.getActionStatus(actionName) ===
						"FAIL" &&
						orgStore.setupEngTypes.responses.getResponse(actionName)
							?.message}
					{/* CHECK 2: Check the duplication and same as default value  */}
					{defaultData.name !== inputs.name.value &&
						orgStore.setupEngTypes.isDuplicatedName(
							inputs.name.value
						) && (
							<DLSystemMsg
								type={MessageColorType.red}
								msg={i18n.tsSameNameExist}
							/>
						)}
				</InputWithLabel>
			</StyledEditDialog>
		)
	}
)

const StyledEditDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default EditEngTypeDialog
