import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLContextMenu,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLObjectFormat } from "../../../../../common-models/enumerations/common-enums"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import {
	PinIcon,
	MoveIcon,
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { CreateProjectMethod } from "../../../../../components/combined-elements/create-project/create-project.data-props"
import { ProjectStatus } from "../../../../../common-models/enumerations/project-related-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { requestToken } from "../../../../project-side/proj-access-control/store/proj-checkin.apis"
import { saveLatestProjToken } from "../../../../../library/token-utils"

export default observer(function OrgGroupProjectCtxMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const sheetId = OrgI18n.groups
	//
	const handleClose = () => {
		orgStore.groups.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}
	const projectInfo = orgStore.groups.rightClickTargetInfo
	ConsoleLog("projectInfo", projectInfo)
	/**
	 * (action name ) --> actionName on the permission
	 * - move project --> move
	 * - edit name --> update
	 * - delete project --> deleteProject // NOTE: share the delete permission with group, cabinet, and project..?
	 * NOTE: Is required to get project permission separately? .... TBD
	 * - duplicate --> duplicateProject
	 *
	 */
	//
	const handleOpenMoveDialog = () => {
		// ConsoleLog("clicked - handleOpenMoveDialog")
		orgStore.groups.setOpenMoveDialog(true)
	}
	//
	const handleOpenCreateProjectDialog = () => {
		// NOTE: Before this action, OrgGroupItemRow updates the pathForSelection
		const currentPath = orgStore.groups.viewCurrentPath
		orgStore.groups.setRFSourceProjInfo({
			groupName: "",
			cabinetName: "",
			clientName: projectInfo.detail.clientName,
			clientAliasId: projectInfo.detail.clientAliasId,
			title: projectInfo.title,
			projectAliasId: projectInfo.detail.aliasId,
			version: projectInfo.detail.version,
			periodName: projectInfo.detail.periodName,
			financialYear: projectInfo.detail.financialYear,
			periodEndDate: projectInfo.detail.periodEndDate,
		})
		orgStore.projects.setSelectedProj(projectInfo.id)
		orgStore.groups.setCreateProjectMethod(
			CreateProjectMethod.byRollForward
		)
		orgStore.groups.setPathForSelection(currentPath)
		orgStore.groups.setOpenCreateProjDialog(true)
	}

	const postAction = () => orgStore.projects.setUpdateDialogOpenStatus(true)

	const handleOpenEditProjectDialog = () => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const projNumId = idToNumber(
			orgStore.groups.rightClickTarget,
			IdType.project
		)

		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
						orgStore.projects.getProjInfo(
							orgStore.groups.rightClickTarget,
							postAction
						)
					} else {
						ConsoleLog(
							"Cannot request the project info. Failed to request project token"
						)
					}
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the project info. Project ID or User ID is incorrect."
			)
		}
	}

	const handleDeleteProject = () => {
		const projectId = orgStore.groups.rightClickTarget
		const params = {
			projectId,
			cabinetId: projectInfo.parentId,
			handleResponse: orgStore.groups.handleResponse,
			postWorkForSuccess: () => {
				orgStore.groups.reflectDelete(projectId)
				// orgStore.groups.resetSelectedProj
				// orgStore.groups.setOpenDeleteProjDialog(false)
				// TODO: is it required to check the email to delete the project?
				// if yes, this part should use the same DeleteProjectDialog with projects screen
			},
		}
		orgStore.groups.removeProject(params)
	}
	const count = orgStore.groups.selectedItems.length
	const orgRoleId = localStorage.getItem("orgRoleId")
	//
	const menuOptions = (i18n: DLI18nProps) => [
		{
			label: "Edit Project Info", // i18n.twEditProjTitle, // "Edit project title",
			value: "edit-proj-info",
			available:
				permission.update &&
				count === 1 &&
				projectInfo.detail.projectStatus === ProjectStatus.normal, // TODO: need to check more
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
			clickEvent: handleOpenEditProjectDialog,
		},
		// TODO: Open as a new tab
		// {
		// 	label: "Open", // "Duplicate",
		// 	value: "open-project",
		// 	available: permission...,
		// 	icon: (
		// 		<DLIcon
		// 			name={DLIconName.duplicate}
		// 			size={CommonIconSize.ctxMenuIcon}
		// 		/>
		// 	),
		// 	clickEvent: handleOpenCreateProjectDialog,
		// },
		{
			label: i18n.twRollForward || "Roll Forward", // "Duplicate",
			value: "ctx-rf",
			available:
				permission.create &&
				count === 1 &&
				projectInfo.detail.projectStatus !== ProjectStatus.archived,
			icon: (
				<DLIcon
					name={DLIconName.duplicate}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
			clickEvent: handleOpenCreateProjectDialog,
		},

		{
			label: i18n.twMoveProj || "Move Project",
			value: "ctx-move_project",
			available:
				permission.move &&
				count === 1 &&
				projectInfo.detail.projectStatus === ProjectStatus.normal, // Looks like normal project can move
			icon: <MoveIcon />,
			clickEvent: handleOpenMoveDialog,
		},
		{
			label: i18n.twDelete || "Delete",
			value: "ctx-delete_project",
			available:
				permission.delete ||
				orgRoleId === "id_aa" ||
				orgRoleId === "id_sa" ||
				orgRoleId === "id_ga" ||
				projectInfo.detail.accessRight === "Project Admin" ||
				projectInfo.detail.accessRight === "Archive Admin",
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
			clickEvent: () => {
				orgStore.groups.setSelectedProj(
					orgStore.groups.rightClickTarget
				)
				orgStore.groups.setOpenDeleteProjDialog(true)
			},
		},
	]

	let selectedItemType = orgStore.groups.rightClickTargetInfo?.type
	let projCtxMenuOpen = selectedItemType === DLObjectFormat.project

	const i18n = store.i18n.combineI18n(sheetId)
	return (
		<DLContextMenu
			eleId="proj-in-group-ctx-menus"
			clickPoint={{
				mouseX: projCtxMenuOpen
					? orgStore.groups.clickPoint.mouseX
					: null,
				mouseY: projCtxMenuOpen
					? orgStore.groups.clickPoint.mouseY
					: null,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions(i18n)}
			hasDivider={[0, 3]}
		/>
	)
})
