import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLSpinner } from "../../../../components/basic-elements"
import styled from "styled-components"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import BasicCounter from "../../../../components/app-frame-elements/sub-components/BasicCounter"
import OrgSetupChecklistTable from "./sub-components/OrgSetupChecklistTable"

export default observer(function OrgSetupChecklist({
	partialStore,
	fetchingStatus,
	contentHeight,
	i18n,
}: {
	partialStore: any
	fetchingStatus: ActionStatus
	contentHeight: any
	i18n: DLI18nProps
}) {
	// const { twTotalChecklist } = i18n
	let twTotalChecklist = undefined
	//
	console.log(fetchingStatus)
	return (
		<PageContainer fullWidth fixedHeight>
			{fetchingStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentHeight }}
				>
					<DLSpinner />
				</div>
			)}
			{fetchingStatus === ActionStatus.success && ( 
				<StyledSetupChecklist>
					{/* <PageHeader>
						<div className="FR AC counter-container">
							<BasicCounter
								label={twTotalChecklist || "Total Checklist"}
								value={partialStore.checklist.length}
							/>
						</div>
					</PageHeader> */}
					<OrgSetupChecklistTable i18n={i18n} partialStore={partialStore}/>
				</StyledSetupChecklist>
			)}
		</PageContainer>
	)
})

const StyledSetupChecklist = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
