import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLIconButton,
	DLTreeListByTreeData,
	DLInputField,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import OrgFileLibraryNavItemRenderer from "./tree-nav-parts/OrgFileLibraryNavItemRenderer"
import Icon from "@mdi/react"
import {
	mdiPlus,
	mdiSortAlphabeticalAscending,
	mdiChevronLeft,
	mdiChevronRight,
	mdiMagnify,
} from "@mdi/js"
import styled from "styled-components"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { InputAdornment } from "@material-ui/core"
import { DragOnOffIconButton } from "../../../../../components/basic-elements/buttons/toggle-icon-buttons"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function OrgFileLibraryTreeNav({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	//
	const [searchQuery, setSearchQuery] = useState("")
	const handleQuery = (e: any) => {
		ConsoleLog("query " + e.target.value)
		setSearchQuery(e.target.value)
	}
	//
	const handleAddLibraryFolder = () => {
		ConsoleLog("clicked")
		orgStore.orgFileLib.setOpenAddLibraryFolder(true)
	}
	const handleAllowDrag = () => {
		orgStore.orgFileLib.setCanDrag()
	}

	let listWidth = orgStore.orgFileLib.viewListWidth
	let contentsHeight = store.ui.contentsAreaHeight
	let navOpen = orgStore.orgFileLib.navOpen
	let canDrag = orgStore.orgFileLib.canDrag
	return (
		<StyledOrgFileLibraryTreeNav
			navWidth={listWidth}
			navHeaderHeight={112}
			navHeight={contentsHeight}
		>
			<div className={`fixed-nav-header ${!navOpen && "FR JC"}`}>
				<div className="FR AC JSB icons-area">
					{navOpen && (
						<div className="FR AC icons-when-nav-open">
							{/* {permission.update && (
									<DLIconButton
										eleTestId="sort-Library-folders-btn"
										tooltipText="Sort" // TODO: add conditional text
									>
										<Icon
											path={mdiSortAlphabeticalAscending}
											size={0.8}
										/>
									</DLIconButton>
								)} */}

							{/* {permission.update && (
									<DragOnOffIconButton
										handleOnOff={handleAllowDrag}
										draggable={orgStore.orgFileLib.canDrag}
										iconSize={0.7}
										featureName="Folder Lock"
									/>
								)} */}

							{permission.create && (
								<DLButton
									eleTestId="add-library-folder"
									clickHandler={handleAddLibraryFolder}
									startIcon={
										<Icon path={mdiPlus} size={0.8} />
									}
								>
									New Library Folder
								</DLButton>
							)}
						</div>
					)}

					<DLIconButton
						eleTestId="collapse-tree-list"
						clickHandler={() =>
							orgStore.orgFileLib.setNavOpen(!navOpen)
						}
					>
						{navOpen ? (
							<Icon path={mdiChevronLeft} size={0.8} />
						) : (
							<Icon path={mdiChevronRight} size={0.8} />
						)}
					</DLIconButton>
				</div>
				{navOpen && (
					<DLInputField
						eleType="search"
						eleClassName="search-field"
						eleTestId="group-tree-searh"
						eleValue={searchQuery}
						eleHandleChange={handleQuery}
						eleStartAdornment={
							<InputAdornment position="start">
								<Icon path={mdiMagnify} size={0.8} />
							</InputAdornment>
						}
					/>
				)}
			</div>

			<DLTreeListByTreeData
				eleClassName={`tree-nav-body ${!navOpen && "hide"}`}
				// data related
				treeData={orgStore.orgFileLib.projLibraryFoldersAsTreeWithQuery(
					searchQuery
				)}
				sortBy="title" // TODO: not working now.. (store already arrange this data before make the tree)
				//
				eleRenderNode={OrgFileLibraryNavItemRenderer}
				eleWidth={"100%"}
				eleHeight={contentsHeight - 188}
				indentWidth={24}
				rowHeight="medium"
				canDrag={canDrag}
			/>
		</StyledOrgFileLibraryTreeNav>
	)
})

const StyledOrgFileLibraryTreeNav = styled.div<{
	navWidth: number
	navHeaderHeight: number
	navHeight: number
}>`
	width: ${(props) => props.navWidth}px;
	height: ${(props) => props.navHeight}px;
	overflow: hidden;
	border-top-right-radius: 5px;
	transition: 0.2s;
	background-color: ${(props) => props.theme.secondary};
	.fixed-nav-header {
		position: fixed;
		z-index: 1;
		width: ${(props) => props.navWidth}px;
		height: ${(props) => props.navHeaderHeight}px;
		padding: 0.6rem 1rem;
		border-top-right-radius: 5px;
		transition: 0.2s;
		.icons-area {
			height: ${(props) => props.theme.shared.baseUnit};
		}
		.search-field {
			padding: 0 1rem;
			margin-bottom: 1rem;
		}
	}
	.tree-nav-body {
		overflow-y: scroll;
		margin-top: ${(props) => props.navHeaderHeight}px;
		padding-left: 1rem;
		&.hide {
			opacity: 0;
		}
	}
`
