import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../orgProjects.apis"
import { IdType, idToNumber } from "../../../../library/converters/id-converter"

const ReadClientsByGroup = (
	self: any,
	readClients: OrgProjectApiProps["apiReadClients"]
) => ({
	readClientsByGroup: flow(function* (groupId: string) {
		const actionName = "readClientsByGroup"
		// 1.
		if (groupId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. groupId: ${groupId}`
			)
			return
		}
		// 2.
		// --- previous params example ---
		// CustomerID: 1
		// GroupID: 1025
		// Type: 2
		// Lang: en
		// searchText: te
		// ----------
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			try {
				const params = {
					CustomerID: 1, // ...??? TODO: WARNING: IS THIS HAS MEANING??
					GroupID: numberId,
					Type: 2, // TODO: What is the meaning..??
				}
				const response = yield readClients(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default ReadClientsByGroup
