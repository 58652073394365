import { types } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
// separated actions
import EditPassword from "./view-model-actions/edit-password"

const MyAccountViewModel = types
	.model({
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// AGER(Add, Get, Edit, Remove) Actions
	.actions(EditPassword)
	// common parts
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default MyAccountViewModel
