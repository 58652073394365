import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { FileMenuSearchbar } from ".."
import FileMenuSearchResults from "./FileMenuSearchResults"

export default observer(function FileMenuSearchView({
	partialStore,
}: {
	partialStore: any
}) {
	return (
		<StyledFileMenuSearchView data-testid="file-menu-search-view">
			<FileMenuSearchbar partialStore={partialStore} />
			<FileMenuSearchResults partialStore={partialStore} />
		</StyledFileMenuSearchView>
	)
})

const StyledFileMenuSearchView = styled.div``
