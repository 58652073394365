import React from "react"
// stores
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
// styles
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiMapMarkerQuestionOutline, mdiAlertCircle } from "@mdi/js"
import { Link } from "react-router-dom"
// import OrgMenuFlatList from "../../../temporary-data/org-side/default-org-menu-list/org-menu-flat-list"

export default function OrgNoPermissionOnMenu({ menuId }: { menuId: string }) {
	const orgStore = useOrgStore()
	const menuTitle = "Update Me" // @Noah OrgMenuFlatList.find((element) => element.id === menuId)?.title

	return (
		<StyledNoPermissionOnMenus className="FC AC">
			<Icon path={mdiMapMarkerQuestionOutline} size={4} />
			<div className="text-contents">
				<p>
					Sorry, You don't have access on the{" "}
					<span className="blocked-menu">
						{menuTitle ? menuTitle : "menu"}.{" "}
					</span>
				</p>
				<div>
					Please use other menus below...
					<div className="other-accessible-menus">
						{orgStore.checkin.accessibleTreeMenus.length > 0 ? (
							orgStore.checkin.accessibleTreeMenus.map(
								(item: any) => {
									return (
										<div
											key={item.id}
											className="other-menu"
										>
											<Link to={item.url}>
												{item.title}
											</Link>
										</div>
									)
								}
							)
						) : (
							<div className="FR AC JC warning">
								<Icon path={mdiAlertCircle} size={1} />
								<p>
									You don't have any permission on this menu
									item
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="ps">
				If you want to use this menu, please contact your project
				administrator.
			</div>
		</StyledNoPermissionOnMenus>
	)
}

const StyledNoPermissionOnMenus = styled.div`
	width: 100%;
	height: 100%;
	text-align: center;
	height: calc(200vh - var(--standard-unit));
	overflow: scroll;
	.text-contents {
		margin-top: 2rem;
		.blocked-menu {
			/* color: ${(props) => props.theme.shade20}; */
			/* border-bottom: 1px solid ${(props) => props.theme.shade20}; */
		}
		.other-menu {
			padding: 0.8rem 1rem;
			margin-bottom: 8px;
			border-bottom: 1px solid ${(props) => props.theme.shade20};
			:hover {
				color: ${(props) => props.theme.emphasis};
			}
		}
		.warning {
			color: ${(props) => props.theme.shared.messageOrange};
			margin: 1rem 0;
			svg {
				fill: ${(props) => props.theme.shared.messageOrange};
			}
			p {
				margin-left: 0.4rem;
			}
		}
	}
	.ps {
		margin-top: 2rem;
	}
`
