import React from "react"
import { useLocalObservable } from "mobx-react-lite"
import OrgListStoreInstance from "../../screens/lobby-side/organization-list/store/org-list.provider"
import MyAccountStoreInstance from "../../screens/lobby-side/my-account/store/my-account.provider"
import orgMgmtInstance from "../../screens/lobby-side/org-mgmt/store/org-mgmt.provider"
import ResetPasswordInstance from "../../screens/lobby-side/reset-password/store/reset-password.provider"
import { ConsoleLog } from "../../components/basic-elements"

const createLobbyStore = () => {
	ConsoleLog(" -0-0- createLobbyStore -0-0- ")
	const store = {
		orgList: OrgListStoreInstance,
		my: MyAccountStoreInstance,
		orgMgmt: orgMgmtInstance,
		resetPassword: ResetPasswordInstance,
	}
	return store
}

type LobbyStoreType = ReturnType<typeof createLobbyStore>

const LobbyStoreContext = React.createContext<LobbyStoreType | null>(null)

const LobbyStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const LobbyStoreStore = useLocalObservable(createLobbyStore)

	return (
		<LobbyStoreContext.Provider value={LobbyStoreStore}>
			{children}
		</LobbyStoreContext.Provider>
	)
}

const useLobbyStore = () => {
	const store = React.useContext(LobbyStoreContext)
	if (!store) {
		throw Error("Lobby Store is null")
	}
	return store
}

export { LobbyStoreContext, LobbyStoreProvider, useLobbyStore }
