import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { OrgRoleType } from "../../../../common-models/enumerations/org-related-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { hasEmptyProps } from "@datalobby/common"
import { idToString, IdType } from "../../../../library/converters/id-converter"

const version = process.env.REACT_APP_API_VERSION

const AddOrgUser = (self: any) => ({
	addUser(userInfo: any, creatorAliasId: string) {
		const actionName = "addUser"
		// 1) set response cases
		const props = {
			name: userInfo.name.value,
			aliasId: userInfo.aliasId.value,
			type: userInfo.type.value,
			email: userInfo.email.value,
			accessRight: userInfo.accessRight.value,
			isArchiveAdmin: userInfo.isArchiveAdmin.value,
		}
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2) add response object and set its actionStatus to loading
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3)
		// re-organize the input data
		// WARNING: API update is required.
		const reOrgnizedUserInfo = {
			...checkedProps.props,
			title: userInfo.title.value,
		}

		self.createOrgUser(reOrgnizedUserInfo)
			.then((response: any) => {
				ConsoleLog(["response in viewModel", response])

				let responseStatus = 0

				// * ----- API version control
				const basicStatus = response.status
				// const prevStatus = response.data?.status?.code // ?? -- No status code is getting
				let fetchedInfo
				if (version === "v2") {
					responseStatus = response.data.Data.Status
					fetchedInfo = response.data.Data
				} else {
					responseStatus = response.data.Status
					fetchedInfo = response.data
				}
				// if success
				if (basicStatus === 200 && responseStatus === 1) {
					let status
					if (fetchedInfo.Status === 1) {
						status = "normal"
					} else if (fetchedInfo.Status === 2) {
						status = "suspended"
					} else {
						status = "TBD"
					}
					const newUser = {
						id: idToString(fetchedInfo.UserID, IdType.user),
						type: userInfo.type.value,
						name: fetchedInfo.Name,
						aliasId: fetchedInfo.UserName,
						email: fetchedInfo.EmailID,
						title: userInfo.title.value, // fetchedInfo.Title, // backend doesn't return the title. Use the input value
						status: status, // TODO: Have to update!!!
						accessRight:
							userInfo.type.value === "internal"
								? userInfo.accessRight.value
								: OrgRoleType.external,
						isArchiveAdmin: userInfo.isArchiveAdmin.value
							? "Yes"
							: "No",
						assignedGroups: [],
						createdAt: fetchedInfo.CreatedDate,
						createdBy: creatorAliasId, // fetchedInfo.CreatedBy.toString(), --> This do not return correct value
						lastAccess: null,
					}
					self.pushItemToList(newUser)
					self.setAddDialogOpen(false)
					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to add a new organization user",
						open: true,
						autoHide: true,
						autoHideDuration: 2000,
					})
				} else if (basicStatus === 200 && responseStatus === 4) {
					self.setRestoreFrom("createDialog")
					self.setRestoreEmail(checkedProps.props.email)
					self.setRestoreDialogOpen(true)
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage:
							response.data.Message || response.data.message,
						open: true,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						customMessage:
							response.data.Message || response.data.message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
	addMultipleUsers(userInfo: any[], creatorAliasId: string, index: number) {
		const actionName = "createMultipleUsers"
		if (!self.pauseMultipleUsers) {
			const target = {
				...userInfo[index],
				accessRight:
					userInfo[index].accessRight === "Archive Admin"
						? "Super Admin"
						: userInfo[index].accessRight,
				isArchiveAdmin:
					userInfo[index].accessRight === "Archive Admin"
						? "true"
						: "false",
			}
			self.updateBulkUserStatus(
				"inprogress...",
				ActionStatus.loading,
				target.userId
			)
			self.createOrgUser(target)
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						ConsoleLog([
							index,
							"Success",
							target.aliasId,
							target.name,
						])
						self.updateBulkUserStatus(
							response.data.Message,
							ActionStatus.success,
							target.userId,
							idToString(response.data.UserID, IdType.user)
						)
						if (index !== userInfo.length - 1) {
							self.addMultipleUsers(
								userInfo,
								creatorAliasId,
								index + 1
							)
						}
					} else {
						ConsoleLog([
							index,
							"Fail",
							target.aliasId,
							target.name,
							response.data.Message || response.data.message,
						])
						self.updateBulkUserStatus(
							response.data.Message,
							ActionStatus.fail,
							target.userId
						)
						if (index !== userInfo.length - 1) {
							self.addMultipleUsers(
								userInfo,
								creatorAliasId,
								index + 1
							)
						}
					}
					if (index === userInfo.length - 1) {
						const successUsers = self.bulkUserList.filter(
							(user: any) => user.status === ActionStatus.success
						)
						let newUsers: any[] = JSON.parse(
							JSON.stringify(self.orgUserList)
						)
						successUsers.map((item: any) => {
							newUsers.push({
								id: item.userId,
								type: item.type,
								name: item.name,
								aliasId: item.aliasId,
								email: item.email,
								title: item.title,
								status: "normal", // TODO: Have to update!!!
								accessRight:
									item.type === "internal"
										? item.accessRight === "Archive Admin"
											? "Super Admin"
											: item.accessRight
										: OrgRoleType.external,
								isArchiveAdmin:
									item.accessRight === "Archive Admin"
										? "Yes"
										: "No",
								assignedGroups: [],
								createdAt: response.data.CreatedDate,
								createdBy: creatorAliasId, // fetchedInfo.CreatedBy.toString(), --> This do not return correct value
								lastAccess: null,
							})
						})
						ConsoleLog([newUsers, "newUsers"])
						// self.setUserList(successUsers)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200,
							color: MessageColorType.blue,
							message: response.data.Message,
							customMessage:
								"Successfully created multiple users",
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					ConsoleLog([
						index,
						"Fail_2",
						target.aliasId,
						target.name,
						error,
					])
					if (index !== userInfo.length - 1) {
						self.addMultipleUsers(
							userInfo,
							creatorAliasId,
							index + 1
						)
					}
				})
			// WARNING: Do not place the next calling in the outside of the response status condition to take sequentiall process
		}
	},
})

export default AddOrgUser
