import { types } from "mobx-state-tree"
// Model, ViewModel, UiHelper
import DateAndTimeSettingModel from "./date-and-time-setting.model"
import DateAndTimeSettingViewModel from "./date-and-time-setting.view-model"
import DateAndTimeSettingUiHelper from "./date-and-time-setting.ui-helper"
// apis
import * as api from "../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"
import { OrgSysSettingApiTypes } from "../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"

const DateAndTimeSettingStore = ({
	apiRead,
	apiUpdate,
}: OrgSysSettingApiTypes) =>
	types
		.compose(
			DateAndTimeSettingModel({ apiRead, apiUpdate }),
			DateAndTimeSettingViewModel,
			DateAndTimeSettingUiHelper
		)
		.named("DateAndTimeSettingStore")

export const initialStore = {
	dateFormat: {
		settingId: "",
		label: "",
		value: "",
		uiValue: "",
	},
	timeFormat: {
		settingId: "",
		label: "",
		value: "",
		uiValue: "",
	},
	timeZone: {
		settingId: "",
		label: "",
		value: "",
		uiValue: "",
	},
	modifiedDetails: {
		userName: "",
		userId: "",
		modifiedDate: "",
	},
	// ui helper
	localeDate: new Date(),
	utcDate: undefined,
	// viewModel common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const DateAndTimeSettingStoreInstance = DateAndTimeSettingStore({
	apiRead: api.read,
	apiUpdate: api.update,
}).create(initialStore)

export default DateAndTimeSettingStoreInstance
