import React from "react"
import {
	ConsoleLog,
	DLContextMenu,
} from "../../../../../components/basic-elements"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../../../../components/basic-elements/icons/common-icons"

export default observer(function EliminatingEntryTableMenus({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	ConsoleLog(" -=-=-=-=-$ EliminatingEntryTableMenus $-=-=-=-=- ")

	const projStore = useProjStore()
	const menuOptions = [
		{
			label: "Edit",
			value: "ctx-edit-ee",
			available: permission.update,
			clickEvent: () =>
				projStore.eliminatingEntry.setEditEliminatingEntryDialogOpen(
					true
				),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Delete",
			value: "ctx-delete-ee",
			available: permission.delete,
			clickEvent: () => {
				const eeId = projStore.eliminatingEntry.selectedEEId
				let proceed = window.confirm("Are you sure you want to delete?")
				if (proceed) {
					projStore.eliminatingEntry.removeEliminatingEntry(eeId)
				} else {
					return
				}
			},
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]

	const handleClose = () => {
		projStore.eliminatingEntry.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="fs-ee-ctx-menus"
			clickPoint={{
				mouseX: projStore.eliminatingEntry.clickPoint.mouseX,
				mouseY: projStore.eliminatingEntry.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[0, 2]}
		/>
	)
})
