import { types } from "mobx-state-tree"
// model & view-model
import LobbyCheckinModel from "./lobby-access-control.model"
import LobbyCheckinViewModel from "./lobby-access-control.view-model"
// common models
import {
	ActionStatus,
	SupportedLanguage,
} from "../../../../common-models/enumerations/common-enums"
// apis
import * as api from "./lobby-access-control.apis"
import { LobbyCheckinProps } from "./lobby-access-control.apis"
import { initialStore } from "./lobby-access-control.view-model"

export const LobbyCheckinStore = ({
	apiLogin,
	apiLogout,
	apiReadInfo,
}: LobbyCheckinProps) =>
	types
		.compose(
			LobbyCheckinModel({ apiLogin, apiLogout, apiReadInfo }),
			LobbyCheckinViewModel
		)
		.named("LobbyCheckinStore")

// export const initialStore = {
// 	language: SupportedLanguage.us,
// 	//
// 	// loginStatus: ActionStatus.standby,
// 	lobbyCheckinStatus: ActionStatus.standby,
// 	// viewModel related parts
// 	responses: {},
// 	responseSnackbar: {
// 		message: "",
// 	},
// }

const LobbyCheckinStoreInstance = LobbyCheckinStore({
	apiLogin: api.login,
	apiLogout: api.logout,
	apiReadInfo: api.readInfo,
}).create(initialStore)

export default LobbyCheckinStoreInstance
