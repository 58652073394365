import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"

const Archive = (self: any) => ({
	archive() {
		// 0.
		const actionName = "archive"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.requestArchive()
			.then((response: any) => {
				if (response.status === 200) {
					/**
					 * TODO: UPDATE is required
					 */
					// at the last of the process, set the success case response
				} else {
					// set fail responses
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default Archive
