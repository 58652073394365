import React from "react"
import {
	DLSingleSelect,
	DLTextField,
	DLDatepicker,
	DLComboBox,
	ConsoleLog,
} from "../../../../components/basic-elements"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import {
	formattedDateWithTz,
	getTzDate,
	getFormattedEOD,
	formattedDateWithoutTzLabel,
	getFormattedEOD2,
} from "../../../../library/converters/date-utc-converter"
import { ProjInfoUpdateFormProps } from "../store/data-models/proj-info-update-input-props"

// This form does not use stored data.
export default observer(function ProjInfoUpdateForm({
	inputs,
	//
	handleChange,
	handleSelectArchPolicyPeriod,
	handleDateInput,
	handleComboBoxInput,
	//
	dntFormat,
	//
	clientList,
	engTypeList,
	periodList,
	archPolicyPeriods,
	raList,
}: ProjInfoUpdateFormProps) {
	const {
		clientId,
		clientName,
		clientAliasId,
		//
		projTitle,
		projAliasId,
		//
		engTypeId,
		engTypeName,
		periodId,
		periodName,
		archPolicyPeriodId,
		statusName,
		//
		financialYear,
		expectedReportDate,
		expectedArchDeadlineDate,
		periodEndDate,
		finalReportDate,
		finalArchDeadlineDate,
		//
		raId,
		raName,
		//
		clientAddress,
		fee,
	} = inputs

	const { timeZone, timeFormat, dateFormat } = dntFormat
	return (
		<StyledProjInfoUpdateForm>
			{/* SECTION 1: Default information  */}
			<div className="section">
				<div className="section-title">Default Information</div>
				{/* ---------- client name, client ID */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLComboBox
							eleTestId="client-select"
							eleName="clientName"
							placeholder="Select Client"
							onChange={(value: any) =>
								handleComboBoxInput("clientId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={clientList}
							defaultValue={{
								id: clientId,
								title: clientName,
								clientAliasId: clientAliasId,
							}}
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="client-aliasId-field"
							eleId="clientAliasId"
							eleLabel="Client ID"
							// eleDefaultValue={inputs.clientAliasId}
							eleFullWidth
							eleValue={inputs.clientAliasId}
							eleReadOnly
						/>
					</div>
				</div>
				{/* ---------- project title, project ID */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="project-title-field"
							eleId="projTitle"
							eleLabel="Project Title"
							eleRequired
							eleDefaultValue={projTitle}
							eleOnChange={handleChange}
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="project-aliasId-field"
							eleId="projAliasId"
							eleLabel="Project ID"
							eleDefaultValue={projAliasId}
							eleOnChange={handleChange}
							eleFullWidth
							eleRequired
						/>
					</div>
				</div>
				{/* ---------- eng type, ra */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLComboBox
							eleTestId="engType-select"
							eleName="engTypeId"
							placeholder="Engagement Type"
							onChange={(value: any) =>
								handleComboBoxInput("engTypeId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={engTypeList}
							defaultValue={{
								id: engTypeId,
								title: engTypeName,
							}}
						/>
					</div>
					<div className="input-container">
						<DLComboBox
							eleTestId="ra-select"
							eleName="raId"
							placeholder="Risk Assessment"
							onChange={(value: any) =>
								handleComboBoxInput("raId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={raList}
							defaultValue={{
								id: raId,
								title: raName,
							}}
						/>
					</div>
				</div>
				{/* ---------- period type, financial year  */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLComboBox
							eleTestId="period-select"
							eleName="periodId"
							placeholder="Period Type"
							onChange={(value: any) =>
								handleComboBoxInput("periodId", value)
							}
							getOptionLabel={(option: any) => option.title}
							options={periodList}
							defaultValue={{
								id: periodId,
								title: periodName,
							}}
						/>
					</div>
					<div className="input-container FR ">
						<div className="financial-year-field">
							<DLTextField
								eleTestId="financial-year-field"
								eleId="financialYear"
								eleLabel="Financial Year"
								eleDefaultValue={financialYear}
								eleOnChange={handleChange}
								eleFullWidth
								eleRequired
								eleReadOnly
								// TODO: @Noah auto update by period end
								//(but need to update in the future as independent select... )
								// @Noah - TODO: update to show warning about the different year with the period end date
							/>
						</div>

						<div className="period-end-field">
							<DLDatepicker
								eleTestId="period-end-field"
								eleName="periodEndDate"
								selected={getTzDate({
									date: periodEndDate,
									timeZone,
									dateFormat,
								})}
								// selected={new Date(periodEndDate)}
								eleLabel="Period End Date"
								onChange={(date: any) =>
									handleDateInput("periodEndDate", date)
								}
								format={dateFormat}
								fullWidth
							/>
							{periodEndDate !== "" &&
								getFormattedEOD2({
									date: periodEndDate,
									dntFormat,
								})}
						</div>
					</div>
				</div>
			</div>
			{/* 
			--- 
			---
			--- 
			*/}
			{/* SECTION 2: Schedule information  */}
			<div className="section">
				<div className="section-title">Schedule Information</div>
				{/* ---------- period end, archive policy period */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLSingleSelect
							eleTestId="arch-policy-period-select"
							label="Archive Policy Period"
							eleFullWidth
							eleRequired
							eleOnChange={handleSelectArchPolicyPeriod}
							placeholder="Archive Policy Period"
							eleValue={archPolicyPeriodId}
							optionList={archPolicyPeriods}
						/>
					</div>
				</div>
				{/* ---------- expected report date, final report date */}

				<div className="section-row FR JSB">
					<div className="input-container">
						<DLDatepicker
							eleTestId="expected-report-date-field"
							eleName="expectedReportDate"
							// selected={new Date(expectedReportDate)}
							selected={getTzDate({
								date: expectedReportDate,
								timeZone,
								dateFormat,
							})}
							eleLabel="Expected Report Date"
							onChange={(date: any) =>
								handleDateInput("expectedReportDate", date)
							}
							format={dateFormat}
							fullWidth
						/>
						{/* <div>{expectedReportDate.toString()}</div>
						<br /> */}
						{expectedReportDate !== "" &&
							getFormattedEOD2({
								date: expectedReportDate,
								dntFormat,
							})}
					</div>
					<div className="input-container">
						<DLDatepicker
							eleTestId="final-report-date-field"
							eleName="finalReportDate"
							selected={
								finalReportDate
									? getTzDate({
											date: finalReportDate,
											timeZone,
											dateFormat,
									  })
									: null
							}
							eleLabel="Final Report Date"
							onChange={(date: any) =>
								handleDateInput("finalReportDate", date)
							}
							format={dateFormat}
							fullWidth
						/>
						{/* <div>{finalReportDate.toString()}</div>
						<br /> */}
						{finalReportDate !== "" &&
							getFormattedEOD2({
								date: finalReportDate,
								dntFormat,
							})}
					</div>
				</div>
				{/* ---------- expected arch deadline, final archive deadline */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLDatepicker
							eleTestId="expected-arch-deadline-date-field"
							eleName="expectedArchiveDeadlineDate"
							selected={getTzDate({
								date: expectedArchDeadlineDate,
								timeZone,
								dateFormat,
							})}
							eleLabel="Expected Archive Deadline Date"
							onChange={(date: any) => {}}
							format={dateFormat}
							disabled={true}
							fullWidth
						/>
						{/* <div>{expectedArchDeadlineDate.toString()}</div>
						<br /> */}
						{expectedArchDeadlineDate !== "" &&
							getFormattedEOD2({
								date: expectedArchDeadlineDate,
								dntFormat,
							})}
					</div>
					<div className="input-container">
						<DLDatepicker
							eleTestId="final-arch-deadlind-date-field"
							eleName="finalArchDeadlineDate"
							selected={
								finalArchDeadlineDate
									? getTzDate({
											date: finalArchDeadlineDate,
											timeZone,
											dateFormat,
									  })
									: null
							}
							eleLabel="Final Archive Deadline Date"
							onChange={(date: any) => {}}
							format={dateFormat}
							disabled={true}
							fullWidth
						/>
						{/* <div>{finalArchDeadlineDate.toString()}</div>
						<br /> */}
						{finalArchDeadlineDate !== "" &&
							getFormattedEOD2({
								date: finalArchDeadlineDate,
								dntFormat,
							})}
					</div>
				</div>
			</div>
			{/* 
			--- 
			---
			--- 
			*/}
			{/* SECTION 3: Optional information  */}
			<div className="section">
				<div className="section-title">Optional Information</div>
				{/* ---------- client address, project fee */}
				<div className="section-row FR JSB">
					<div className="input-container">
						<DLTextField
							eleTestId="client-address-field"
							eleId="clientAddress"
							eleLabel="Client Address"
							eleDefaultValue={clientAddress}
							eleOnChange={handleChange}
							elePlaceholder="Input client address"
							eleFullWidth
						/>
					</div>
					<div className="input-container">
						<DLTextField
							eleTestId="project-fee-field"
							eleId="fee"
							eleLabel="Project Fee"
							eleValue={fee || ""}
							eleOnChange={handleChange}
							elePlaceholder="Input project fee"
							eleFullWidth
						/>
					</div>
				</div>
			</div>
		</StyledProjInfoUpdateForm>
	)
})

const StyledProjInfoUpdateForm = styled.div`
	width: 700px;
	.section {
		margin-bottom: 2rem;
		.section-title {
			font-weight: 700;
			margin-bottom: 1rem;
		}
		.section-row {
			width: 100%;
			.input-container {
				width: calc(50% - 1rem);
				margin-bottom: 1rem;
				.financial-year-field {
					min-width: 64px;
					width: 30%;
					margin-right: 1rem;
				}
				.period-end-field {
					width: calc(70% - 1rem);
				}
			}
		}
	}
	.MuiAutocomplete-clearIndicator {
		display: none;
	}
`
