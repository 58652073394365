import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import findOrgRoleId from "../../../../../../temporary-data/org-side/find-org-roleId"

const GetAssignedUsers = (self: any) => ({
	getAssignedUsers() {
		// 0. actionName
		const actionName = "getAssignedUsers"
		// 1.
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. call API
		const pageIndex = 1
		let payload = {
			// TODO: update as get-org-user-list
			ArchiveManager: -1,
			IsActive: 0, // read all status (suspended, normal, ...)
			PageIndex: pageIndex,
			PageSize: 1000, // request 1000 working very fast. totalUser + 10 is required?
		}

		self.readOrgAssignedUsers(payload)
			.then((response: any) => {
				if (response) {
					// ConsoleLog("readOrgAssignedUsers resopnse", response)
					const fetchedUsers = response.data.UserList
					let organizedUsers: any[] = []
					fetchedUsers.map((fetchedUser: any) => {
						const newUser = {
							id: fetchedUser.UserId.toString(),
							type:
								fetchedUser.UserType === 1
									? "internal"
									: "external",
							name: fetchedUser.Name,
							aliasId: fetchedUser.UserName,
							email: fetchedUser.Email,
							roleId: findOrgRoleId(
								fetchedUser.AccessTypeID,
								fetchedUser.ArchiveManager
							),
						}
						organizedUsers.push(newUser)
					})
					// when success
					self.setAssignedUsers(organizedUsers)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 999, // temp
						customMessage:
							"Successfully get the assigned users for the org roles",
						color: MessageColorType.blue,
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetAssignedUsers
