import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../orgProjects.apis"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const ReadUsersByGroup = (
	self: any,
	readUsers: OrgProjectApiProps["apiReadUsers"]
) => ({
	readUsersByGroup: flow(function* (groupId: string) {
		const actionName = "readUsersByGroup"
		// 1.
		if (groupId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. groupId: ${groupId}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			// ------ params example
			// CustomerID: 1
			// GroupID: 1025
			// Lang: en
			// type: 2
			// searchText: blah
			// ------

			try {
				const params = {
					// CustomerID: 1,// TODO: Need to check
					GroupID: numberId,
					type: 2, // TODO: Need to check
				}
				const response = yield readUsers(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default ReadUsersByGroup
