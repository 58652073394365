import React, { useState, useCallback, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import {
	DLDialog,
	DLButton,
	DLDialogHeader,
	ConsoleLog,
	DLSpinner,
} from "../../basic-elements"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import {
	DLObjectFormat,
	ActionStatus,
} from "../../../common-models/enumerations/common-enums"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import { addTzToDate } from "../../../library/converters/date-utc-converter"
import { addDays, getYear } from "date-fns"
import {
	CreateProjectInputProps,
	initialCreateProjectInputs,
	CreateProjectMethod,
} from "./create-project.data-props"
import { ProjectStatus } from "../../../common-models/enumerations/project-related-enums"
import { mdiAlertCircle, mdiPlusThick } from "@mdi/js"
import { Icon } from "@mdi/react"
import { BreadCrumbsOptionProps } from "../postpone-to-classify/BreadCrumbsAndOptions"
import { DLOrgMenuGroups } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

//
import StyledCreateProjectDialog from "./StyledCreateProjectDialog"
import CreateProjectSectionLeft from "./CreateProjectSectionLeft"
import CreateProjectSectionCenter from "./CreateProjectSectionCenter"
import CreateProjectSectionRight from "./CreateProjectSectionRight"
import { CreateProjectDialogProps } from "./create-project.comp-props"
import styled from "styled-components"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
} from "../../basic-elements/icons/common-icons"
import { displayedProjRoles } from "../../../temporary-data/project-side/find-proj-role"
import SourceProjectInfo from "./SourceProjectInfo"
import { useLocation } from "react-router-dom"
import { IdType, idToNumber } from "../../../library/converters/id-converter"
import { requestToken } from "../../../screens/project-side/proj-access-control/store/proj-checkin.apis"
import { saveLatestProjToken } from "../../../library/token-utils"

/**
 * Create project dialog use cases
 * - Org Groups > New Project
 * - Org Groups > Copy Project
 * - Org Projects > New Project (has no default path)
 * - ORg Projects > Copy Project;
 */
export default observer(function CreateProjectDialog({
	open,
	setOpen,
	currentMenu,
	createMethod,
	path,
	setIdPath,
	presetInputs,
}: CreateProjectDialogProps) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	// default values
	const userId = orgStore.checkin.orgUserId

	const dateFormat = store.global.dateFormat.value
	const timeFormat = store.global.timeFormat.value
	const timeZone = store.global.timeZone
	//
	const groupId = path[0]?.id
	const isRF = createMethod === CreateProjectMethod.byRollForward
	//
	const lastItem = path[path.length - 1]
	const targetInfo = orgStore.groups.getTargetDetail(lastItem?.id)
	//
	const location = useLocation()
	// const actionName = "getOrgGroupList"
	const actionName = location.state
		? "getOrgCabinetDetail"
		: "getOrgGroupList"

	const getOrgCabinetDetail = (id: string) => {
		orgStore.groups.getOrgCabinetDetail(id)
	}

	console.log(presetInputs, "presetInputs")

	const [crumbOptions, setCrumbOptions] = useState<BreadCrumbsOptionProps[]>(
		[]
	)

	useEffect(() => {
		if (lastItem) {
			// breadCrumbOptions = orgStore.groups.getChildrenInTheFlatListAsOptions(
			// 	lastItem.id
			// )
			const options = orgStore.groups.getChildrenInTheFlatListAsOptions(
				lastItem.id
			)
			console.log(options, "options-1")
			setCrumbOptions(options)

			// if (
			// 	options.length === 0 &&
			// 	targetInfo?.type !== DLObjectFormat.project
			// ) {
			// 	console.log(lastItem, "getOrgCabinetDetail - for lastItem")
			// 	getOrgCabinetDetail(lastItem.id)
			// }
		} else {
			// breadCrumbOptions = orgStore.groups.groupOptions
			console.log(orgStore.groups.groupOptions, "options-1-1")
			setCrumbOptions(orgStore.groups.groupOptions)
		}
	}, [lastItem, orgStore.groups.getActionStatus(actionName) !== "SUCCESS"])

	const getLocationDetail = (id: string) => {
		if (id.includes("group")) {
			orgStore.groups.getOrgGroupCabinetList(id)
		} else if (id.includes("cabinet")) {
			ConsoleLog("getLocationDetail - getOrgCabinetDetail")
			getOrgCabinetDetail(id)
		} else {
			// TODO: Disable the item in the cabinet
		}
	}

	/**
	 *
	 *
	 * default required data
	 *
	 *
	 *
	 * - client list
	 * - engType list
	 * - period type list
	 * - archive policy - period list
	 * - RA list
	 * + available user list
	 *
	 * NOTE) cabinet list and RF, project template list are working separately
	 */
	useEffect(() => {
		if (orgStore.groups.getGroupFlatListLength === 0) {
			orgStore.groups.getOrgGroupList(userId)
			// NOTE: do not remove this line. this component is sharing with projects
			// NOTE: above line should not remove the previous list. (Do not reset the orgGroupFlatList by this action)
		}
		/*
		/* - engType list
		/* - period type list
		/* - archive policy - period list
		/* - RA list
		*/
		orgStore.setupEngTypes.getEngTypeList()
		orgStore.projects.getProjPeriodTypes()
		orgStore.projects.getArchPolicyPeriodList()
		orgStore.setupRiskAssessment.getRiskAssessmentList()
	}, [])

	useEffect(() => {
		if (groupId !== undefined) {
			ConsoleLog(
				"(create-project-dialog) useEffect to get client list" + groupId
			)
			// orgStore.setupGroups.getClientMasterByGroup(groupId, 2) // NOTE: Are both of these 2 parts required?
			orgStore.projects.getClientsByGroup(groupId)
			orgStore.setupGroups.getAssignedUsersOfGroup(groupId) // NOTE: Need to check current group view-model (data structure)
		}
	}, [groupId])

	/**
	 *
	 *
	 * inputs
	 *
	 *
	 */
	const [inputs, setInputs] = useState<CreateProjectInputProps>(
		presetInputs === undefined
			? initialCreateProjectInputs
			: {
					// ...presetInputs,
					...initialCreateProjectInputs,
					title: presetInputs.title + " (copy)",
			  }
	)
	// NOTE: No need this part. cabinet ID will be added on handleAction
	// useEffect(() => {
	// 	if (lastItem && lastItem.id.includes("cabinet")) {
	// 		setInputs((inputs: any) => ({
	// 			...inputs,
	// 			cabinetId: lastItem.id,
	// 		}))
	// 	}
	// }, [])

	const [selectedUsers, setSelectedUsers] = useState<
		{ id: string; roleId: string }[]
	>([])
	const [highlight, setHighlight] = useState(false)

	const isOtherUsersReady =
		inputs.createMethod === CreateProjectMethod.byHardCopyOnly
			? inputs.otherUsers
					.map((item: any) =>
						Object.values(item).every(
							(i: any) => i !== "" && i !== null
						)
					)
					.every((k: boolean) => k)
			: true

	//
	let inputReady =
		// inputs.group !== "" &&
		// inputs.cabinetId !== "" &&
		inputs.clientId !== "" &&
		inputs.engTypeId !== "" &&
		inputs.periodId !== "" &&
		inputs.periodEndDate !== "" &&
		// inputs.dueDate !== "" &&
		inputs.archPolicyPeriod !== "" &&
		inputs.title !== "" &&
		inputs.aliasId !== "" &&
		inputs.riskAssessment !== "" &&
		inputs.epUserId !== "" &&
		inputs.creatorRole !== "" &&
		isOtherUsersReady
	// inputs.users !== undefined &&
	// inputs.users?.length >= 1
	//inputs.epUser !== "" &&
	//inputs.role !== "" // NOTE: template part need more configuration
	//
	let templateReady = false
	if (
		(inputs.createMethod === CreateProjectMethod.byBlank ||
			inputs.createMethod === CreateProjectMethod.byHardCopyOnly) &&
		inputs.templateId === "" &&
		inputs.copyFrom === null
	) {
		templateReady = true
	} else if (
		inputs.createMethod === CreateProjectMethod.byProjectTemplate &&
		inputs.templateId !== "" &&
		inputs.copyFrom === null
	) {
		templateReady = true
	} else if (
		inputs.createMethod === CreateProjectMethod.byRollForward &&
		inputs.templateId === "" &&
		inputs.copyFrom !== null
	) {
		templateReady = true
	}
	//
	if (inputs.expectedReportDate && inputs.archPolicyPeriod) {
		const due = orgStore.projects.archPolicyPeriodValue(
			inputs.archPolicyPeriod
		)
		let result: any = ""
		if (due !== undefined) {
			result = addDays(new Date(inputs.expectedReportDate), due)
			ConsoleLog("due" + due + " / expected archive date" + result)
		}
		//
		inputs.expectedArchiveDate = result
	} else {
		inputs.expectedArchiveDate = ""
	}

	if (inputs.finalReportDate && inputs.archPolicyPeriod) {
		const due = orgStore.projects.archPolicyPeriodValue(
			inputs.archPolicyPeriod
		)
		let result: any = ""
		if (due !== undefined) {
			result = addDays(new Date(inputs.finalReportDate), due)
			ConsoleLog("due" + due + " / final archive date" + result)
		}
		//
		inputs.finalArchDeadlineDate = result
	} else {
		inputs.finalArchDeadlineDate = ""
	}
	//
	const handleInputs = useCallback((event: any) => {
		let name = event.target.name
		let value = event.target.value
		ConsoleLog("name and value" + name + value)
		if (value === "true") {
			value = true
		} else if (value === "false") {
			value = false
		}

		if (name === "createMethod") {
			setInputs((inputs: any) => ({
				...inputs,
				[name]: value,
				// reset additional options
				templateId: "",
				copyFrom: null,
				copyContent: false,
				copyUsers: false,
				moveFsDataAsPy: false,
			}))
			// } else if (name === "expectedReportDate") {
			// 	setInputs((inputs: any) => ({
			// 		...inputs,
			// 		[name]: value,
			// 	}))
		} else {
			setInputs((inputs: any) => ({
				...inputs,
				[name]: value,
			}))
		}
	}, [])

	const handleCheckbox = useCallback((key: string) => {
		ConsoleLog("handle checkbox " + key + inputs)
		setInputs((inputs: any) => ({
			...inputs,
			[key]: !inputs[key],
		}))
	}, [])

	const handleComboBoxInput = useCallback((name: any, value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value !== null ? value.id : "",
		}))
		if (name === "engTypeId") {
			setInputs((inputs: any) => ({
				...inputs,
				engTypeName: value !== null ? value.title : "",
			}))
		}
		if (name === "clientId") {
			setInputs((inputs: any) => ({
				...inputs,
				clientAliasId: value !== null ? value.aliasId : "",
				clientName: value !== null ? value.name : "",
			}))
		}
		if (name === "periodId") {
			setInputs((inputs: any) => ({
				...inputs,
				periodName: value !== null ? value.title : "",
			}))
		}
		if (name === "archPolicyPeriod") {
			setInputs((inputs: any) => ({
				...inputs,
				archPolicyPeriod: value !== null ? value.id : "",
			}))
		}
	}, [])

	const handleEpUser = (value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			epUserId: value !== null ? value.id : "",
			creatorRole: value.id === userId ? "id_ep" : "",
		}))
	}

	const handleCreatorRole = (value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			creatorRole: value !== null ? value.roleId : "",
		}))
	}

	const handleDateInput = useCallback((name: any, date: any) => {
		const newDate = addTzToDate(date, timeZone.uiValue)
		ConsoleLog("newDate " + newDate)
		setInputs((inputs: any) => ({
			...inputs,
			[name]: newDate,
		}))
		if (name === "periodEndDate") {
			const year = getYear(new Date(newDate))
			setInputs((inputs: any) => ({
				...inputs,
				year,
			}))
		}
	}, [])

	const handleYearInput = useCallback((value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			year: value,
		}))
	}, [])

	const handleSelectTemplate = useCallback((value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			templateId: value !== null ? value.id : "",
		}))
	}, [])

	const handleSelectRfSource = useCallback((rfSourceId: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			copyFrom: rfSourceId,
		}))
	}, [])

	/**
	 *
	 * for roll-forward
	 *
	 */

	// * For Roll Forward

	let titlePostfix = ""
	if (createMethod === CreateProjectMethod.byProjectTemplate) {
		titlePostfix = " by Project template"
	} else if (createMethod === CreateProjectMethod.byRollForward) {
		titlePostfix = " by Roll-forward"
	}

	useEffect(() => {
		if (createMethod === CreateProjectMethod.byRollForward) {
			inputs.createMethod = createMethod
			inputs.copyFrom = DLOrgMenuGroups.dashboard
				? orgStore.orgDashboard.rfSourceId
				: orgStore.projects.rfSourceId
		}
		if (createMethod === CreateProjectMethod.byHardCopyOnly) {
			inputs.createMethod = createMethod
		}
	}, [createMethod])
	//

	/**
	 *
	 * Actions
	 *
	 */
	const handleSelectPath = (idPath: string[]) => {
		ConsoleLog("handleSelectPath " + idPath)
		setIdPath(idPath)
		if (idPath.length === 1) {
			setInputs((inputs: any) => ({
				...inputs,
			}))
		}
	}

	const clearRelatedParts = () => {
		setIdPath([])
		orgStore.projects.setCreateProjectPresetInputs(undefined)
	}

	const handleCreateProject = () => {
		if (inputs.createMethod === CreateProjectMethod.byHardCopyOnly) {
			orgStore.projects.addOtherProject({
				inputs: {
					stage: "NORMAL",
					isLocked: false,
					//
					groupId: path[0].id,
					cabinetId: path[path.length - 1].id,
					//
					clientId: inputs.clientId,
					engagementTypeId: inputs.engTypeId,
					//
					periodTypeId: inputs.periodId,
					//
					name: inputs.title,
					aliasId: inputs.aliasId,
					//
					year: inputs.year,
					periodEndDate: inputs.periodEndDate,
					//
					expectedReportDate: inputs.expectedReportDate,
					finalReportDate: inputs.finalReportDate,
					//
					archivePolicyPeriod: inputs.archPolicyPeriod,
					expectedArchiveDeadlineDate: inputs.expectedArchiveDate,
					finalArchiveDeadlineDate: inputs.finalArchDeadlineDate,
					//
					creationTemplate: "HARD_COPY_ONLY",
					// creationInfo: "",
					//
					createdBy: userId,
					// createdAt: new Date(),
					//
					ownerUserId: userId,
					otherUsers: inputs.otherUsers,
				},
				postAction: (newProjId: string, fetchedData: any) => {},
				handleResponse: () => {},
			})
		} else {
			orgStore.projects.addProject({
				userId,
				inputs: {
					...inputs,
					groupId: path[0].id,
					cabinetId: path[path.length - 1].id,
					users: selectedUsers,
				},
				postAction: (newProjId: string, fetchedData: any) => {
					console.log(fetchedData, "fetchedData")
					// When start from Org > Groups
					if (currentMenu === DLOrgMenuGroups.groups) {
						ConsoleLog("post action - aliasId " + inputs.aliasId)
						const newProjectForGroup = {
							id: newProjId,
							title: inputs.title,
							parentId: path[path.length - 1].id,
							createdBy: "",
							createdAt: "",
							expanded: undefined,
							type: DLObjectFormat.project,
							detail: {
								aliasId: inputs.aliasId, // input by user (business ID)
								projectStatus: ProjectStatus.normal,
								version: 0,
								accessible: true,
								//
								clientId: inputs.clientId,
								clientName: fetchedData.ClientName,
								clientAliasId: fetchedData.ClientShortName
									? fetchedData.ClientShortName
									: "(unknown)",
								//
								financialYear: fetchedData.FiscalYear,
								periodEndDate: "", // financial year
								periodName: fetchedData.periodName,
								//
								accessRight: fetchedData.AccessRights, // TODO: Should be enum
								assignedRole: fetchedData.RoleName,
								//
								isLocked: fetchedData.IsLocked,
								//
								archived: fetchedData.Archive,
								archivedBy: {
									id: "",
									name: "",
								},
								archivedAt: "",
								createdBy: {
									id: userId,
									name: "",
								},
								//

								//
								expirationDate: null,
							},
						}
						orgStore.groups.pushItemToList(newProjectForGroup)
						// setOpen(false)
						// orgStore.groups.setOpenCreateProjDialog(false)
					} else if (currentMenu === DLOrgMenuGroups.projects) {
						// When start from Org > Projects

						const newProjectForProjects = {
							type: 1, // TODO: WARNING: UPDATE!!!
							//* 1. location info: group, cabinet
							groupId: inputs.groupId,
							groupName: fetchedData.GroupName,
							cabinetId: inputs.cabinetId,
							cabinetName: "",
							//
							// * 2. client info
							clientId: inputs.clientId,
							clientName: fetchedData.ClientName,
							clientAliasId: "-",
							//
							// * 3. project basic info
							id: newProjId,
							aliasId: inputs.aliasId,
							title: inputs.title,
							//
							engTypeId: inputs.engTypeId,
							engTypeName: fetchedData.EngagementType,
							raId: "xxx",
							raName: fetchedData.RiskAssessmentName,
							//
							// * 4. project status info
							statusId: "",
							statusName: "",
							periodId: inputs.periodId,
							periodName: fetchedData.periodName,
							archiveDatePolicy: fetchedData.ArchiveDatePolicy,
							//
							// Additional
							replicaStatus: false,
							archiveZipStatus: false,
							unarchiveStatus: false,
							//
							version: 0,
							isArchived: false,
							isLocked: fetchedData.IsLocked,
							//
							// * 5. access info
							roleId: "",
							roleName: fetchedData.RoleName,
							accessible: true,
							//
							// * 6. schedule info
							financialYear: fetchedData.FiscalYear, // NEED update
							periodEndDate: JSON.stringify(inputs.periodEndDate),
							//
							expectedReportDate: "",
							finalReportDate: null, // TODO: RE-name is required
							//
							expectedArchiveDate: "",
							finalArchiveDeadlineDate: "",
							//
							// * 7. history info
							createdBy: {
								id: "", // getting 0
								aliasId: "()",
								name: fetchedData.CreatedUser ?? "?",
							},
							createdAt: "",
							//
							lastAccessBy: {
								id: "xxx", // Not getting from backend
								aliasId: "xxx",
								name: "",
							},
							lastAccessAt: null,
							//
							archivedBy: null,
							archivedAt: "",
							//
							unarchivedBy: null,
							unarchivedAt: "",
							//
							// * 8. assigned users
							epUsers: [],
							qcUsers: [],
							//
							// * 9. extras
							//
							// *
							hasExportPermission: fetchedData.hasExportAccess,
						}
						console.log(
							newProjectForProjects,
							"newProjectForProjects"
						)
						orgStore.projects.pushItemToOriginalNormalProjectList(
							newProjectForProjects
						)

						// orgStore.projects.setOpenCreateProjDialog(false)
					}
					setOpen(false)
					setIdPath([])
				},
				handleResponse:
					currentMenu === DLOrgMenuGroups.groups
						? orgStore.groups.handleResponse
						: orgStore.projects.handleResponse,
			})
		}
	}

	const [actionStatus, setActionStatus] = useState<ActionStatus>(
		ActionStatus.standby
	)

	const projectId = orgStore.projects.selectedProj

	const getProjectInfo = () => {
		const userNumId = idToNumber(orgStore.checkin.orgUserId, IdType.user)
		const projNumId = idToNumber(projectId, IdType.project)
		setActionStatus(ActionStatus.loading)
		if (typeof projNumId === "number" && typeof userNumId === "number") {
			requestToken({ UserId: userNumId, ClientId: projNumId }).then(
				(response: any) => {
					if (response.data.status.code === 200) {
						saveLatestProjToken(response.data.data.access_token)
						setActionStatus(ActionStatus.success)
						orgStore.projects.getProjInfo(projectId)
					} else {
						setActionStatus(ActionStatus.fail)
						ConsoleLog(
							"Cannot request the project info. Failed to request project token"
						)
					}
				}
			)
		} else {
			ConsoleLog(
				"Cannot request the project info. Project ID or User ID is incorrect."
			)
		}
	}

	useEffect(() => {
		if (isRF) {
			getProjectInfo()
		}
	}, [isRF])

	const orgInfo = orgStore.checkin.orgInfo
	const creatorInfo = {
		id: userId,
		aliasId: orgInfo.userAliasId,
		name: orgInfo.userName,
		email: orgInfo.email,
	}
	// NOTE: MAIN BODY --------------------------------------------------------
	const i18n = store.i18n.combineI18n(OrgI18n.projects, "CreateProjectDialog")
	const clientList = orgStore.projects.clientsByGroupOptions(groupId)
	const engTypeList = orgStore.setupEngTypes.engTypeOptions
	const periodTypeList = orgStore.projects.viewPeriodNames()
	const archPolicyPeriodOptions = orgStore.projects.archPolicyPeriodOptions
	const raList = orgStore.setupRiskAssessment.riskAssessmentOptions
	const groupUserList = orgStore.setupGroups.viewEpAvailableUsers
	// orgStore.setupGroups.assignedUsersOfGroup

	const projRoleList = displayedProjRoles.filter(
		(role: any) => role.roleId !== "id_oa" && role.roleId !== "pu_ex"
	)

	//
	let creationStatus: ActionStatus = ActionStatus.standby
	if (currentMenu === DLOrgMenuGroups.groups) {
		creationStatus = orgStore.groups.getActionStatus("addProject")
	} else if (
		currentMenu === DLOrgMenuGroups.projects ||
		currentMenu === DLOrgMenuGroups.dashboard
	) {
		creationStatus = orgStore.projects.getActionStatus("addProject")
	} else if (currentMenu === DLOrgMenuGroups.lib) {
		creationStatus = orgStore.orgProjTemplates.getActionStatus("addProject")
	}

	const partialStore =
		currentMenu === DLOrgMenuGroups.groups
			? orgStore.groups
			: orgStore.projects

	return (
		<DLDialog
			eleTestId="create-project-dialog"
			cannotUpdate={creationStatus === ActionStatus.loading}
			isOpen={open}
			draggable
			setIsOpen={setOpen}
			closeAction={clearRelatedParts}
			dialogTitle={
				<DLDialogHeader
					dialogName={i18n.tsCreateNewProject}
					targetName={titlePostfix}
					icon={
						<DLIcon
							name={DLIconName.add}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
				/>
			}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogContents={
				<StyledCreateDialog className="FR">
					<div className="left-section">
						{isRF && (
							<>
								{actionStatus === "SUCCESS" &&
								orgStore.projects.getActionStatus(
									"getProjInfo"
								) === "SUCCESS" ? (
									<>
										<SourceProjectInfo
											partialStore={partialStore}
										/>
										<hr style={{ margin: "6px" }} />
									</>
								) : (
									<div
										className="FR AC JC"
										style={{
											width: "100%",
											height: 300,
										}}
									>
										<DLSpinner />
									</div>
								)}
							</>
						)}
						{isRF && <h3>2. Roll Forward Options</h3>}
						<CreateProjectSectionCenter
							groupId={groupId}
							path={path}
							//
							inputs={inputs}
							handleInputs={handleInputs}
							handleCheckbox={handleCheckbox}
							handleSelectTemplate={handleSelectTemplate}
							handleSelectRfSource={handleSelectRfSource}
							//
							createMethod={createMethod}
							//
							highlight={highlight}
							//
							i18n={i18n}
						/>
					</div>
					<hr style={{ margin: "8px" }} />
					<div className="right-section">
						<h3>{isRF ? "3" : "2"}. New Project Users</h3>
						<CreateProjectSectionRight
							path={path}
							userList={groupUserList}
							projRoleList={projRoleList}
							handleEpUser={handleEpUser}
							handleCreatorRole={handleCreatorRole}
							highlight={highlight}
							epUserId={inputs.epUserId}
							userId={userId}
							creatorRole={inputs.creatorRole}
							createMethod={inputs.createMethod}
							creatorInfo={creatorInfo}
							otherUsers={inputs.otherUsers}
							setInputs={setInputs}
						/>
						<h3>{isRF ? "4" : "3"}. New Project Information</h3>
						<CreateProjectSectionLeft
							path={path}
							handleSelectPath={handleSelectPath}
							getLocationDetail={getLocationDetail}
							//
							inputs={inputs}
							handleInputs={handleInputs}
							handleComboBoxInput={handleComboBoxInput}
							handleDateInput={handleDateInput}
							handleYearInput={handleYearInput}
							// options
							breadCrumbOptions={crumbOptions}
							clientList={clientList}
							engTypeList={engTypeList}
							periodTypeList={periodTypeList}
							archPolicyPeriodList={archPolicyPeriodOptions}
							raList={raList}
							//
							dateFormat={dateFormat}
							timeFormat={timeFormat}
							timeZone={timeZone}
							highlight={highlight}
							//
							i18n={i18n}
						/>
					</div>
				</StyledCreateDialog>
			}
			maxWidth="lg"
			fullWidth={true}
			cancelBtnText={i18n.twCancel || "Cancel"}
			// actionBtn="Create Project"
			actionBtn={
				<div
					className="FR AC JR"
					style={{ width: "100%", paddingRight: "1rem" }}
				>
					<div
						className="create-btn"
						onMouseOver={() => {
							setHighlight(true)
						}}
						onMouseLeave={() => {
							setHighlight(false)
						}}
					>
						<DLButton
							eleTestId="submit-project"
							endIcon={<Icon path={mdiPlusThick} size={0.8} />}
							color="primary"
							disabled={
								inputReady === false ||
								templateReady === false ||
								creationStatus === ActionStatus.loading
							}
							clickHandler={handleCreateProject}
						>
							{i18n.twSubmitProject}
						</DLButton>
					</div>
				</div>
			}
			showSpinner={
				orgStore.groups.getActionStatus(actionName) !==
					ActionStatus.success ||
				creationStatus === ActionStatus.loading
			}
		/>
	)
})

const StyledCreateDialog = styled.div`
	.left-section {
		width: 30%;
		.template-type {
			.MuiFormControlLabel-root {
				margin-left: 0px !important;
			}
			.with-label {
				padding: 0 !important;
				padding-left: 1.1rem !important;
			}
			.label {
				margin-bottom: 20px;
				font-size: 1rem;
				font-weight: bold;
				padding-top: 0px !important;
			}
		}
	}
	.right-section {
		width: 70%;
		.input-section {
			width: 100%;
			margin-bottom: 0.6rem;
			border: 1px solid transparent;
			padding: 4px 1rem;
			border-radius: 4px;
			// transition: 0.3s;
			&.request {
				color: red;
				border: 1px solid red;
				background-color: rgba(255, 0, 0, 0.3);
			}
			.with-label {
				width: 100%;
				.label {
					min-width: 8rem;
				}
				.input-area {
					width: calc(100% - 8rem);
					/* min-width: 20rem; */
					.request-required-field {
						opacity: 0.4;
					}
				}
			}
		}
		.select-creator-roll {
			margin-left: 1rem;
			width: 100%;
		}
	}
	.creator-info {
		padding: 0.6rem;
		background-color: lightgray;
		border-radius: 4px;
	}
	.FCwith-label {
		padding: 0 !important;
		padding-left: 1.1rem !important;
	}
`
