import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../components/basic-elements"

const ResendVerificationCode = (self: any, resend: any) => ({
	resendVerificationCode: flow(function*(userEmail) {
		const actionName = "resendVerificationCode"
		try {
			const response = yield resend(userEmail)

			/**
			 * response cases
			 * case 1: (200 but fail) (alert) Your verification code has been expired. Please click on OK to resend code to your email.
			 */

			ConsoleLog(["resendVerificationCode response", response])
			return response
		} catch (error) {
			console.error("resendVerificationCode error", error)
			self.handleModelError({ actionName, error, open: true })
			return false
		}
	}),
})

export default ResendVerificationCode
