import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { ConsoleLog } from "../../../../components/basic-elements"

const ReadChildrenOfFolder = (
	self: any,
	apiReadChildrenOfFolder: FileSystemApiTypes["apiReadChildrenOfFolder"]
) => ({
	readChildrenOfFolder: flow(function* (
		folderId: string,
		menuId: DLProjSubMenus
	) {
		const actionName = "readChildrenOfFolder"
		// 1.
		if (folderId === undefined || folderId === "" || menuId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. folderId: ${folderId} | menuId: ${menuId}`
			)
			ConsoleLog([
				"undefined params exist - folderId:",
				folderId,
				"/ menuType:",
				menuId,
			])
			return
		}
		//
		//
		// organize params
		let endpoint: string = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/GetWPRList"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/GetPermanentFileList"
		}
		const numberId = idToNumber(folderId, IdType.folder)
		//
		//
		// 2.
		if (typeof numberId === "number" && endpoint !== "") {
			try {
				const params = {
					ParentFldID: numberId,
				}
				const response = yield apiReadChildrenOfFolder(endpoint, params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId} / endpoint: ${endpoint}`)
		}
	}),
})

export default ReadChildrenOfFolder
