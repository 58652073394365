import { types } from "mobx-state-tree"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export const SharedProjTablePageSettingModel = types.model({
	displayName: types.string,
	aliasName: types.string,
	show: types.boolean,
})

export const InitialPageSetting = (menuId: DLOrgSubMenus) => {
	let InitialPageSetting = [
		{
			displayName: "Period Name",
			aliasName: "periodName",
			show: true,
		},
		{
			displayName: "Financial Year",
			aliasName: "financialYear",
			show: true,
		},
		{
			displayName: "Group Name",
			aliasName: "groupName",
			show: true,
		},
		{
			displayName: "Client Name",
			aliasName: "clientName",
			show: true,
		},
		{
			displayName: "Project Name",
			aliasName: "title",
			show: true,
		},
		{
			displayName: "Alias Id",
			aliasName: "aliasId",
			show: true,
		},
		{
			displayName: "Version",
			aliasName: "version",
			show: true,
		},
		{
			displayName: "EP Users",
			aliasName: "epUsers",
			show: true,
		},
		{
			displayName: "QC Users",
			aliasName: "qcUsers",
			show: true,
		},
		{
			displayName: "Eng Type",
			aliasName: "engTypeName",
			show: true,
		},
		{
			displayName: "Period End Date",
			aliasName: "periodEndDate",
			show: true,
		},
		{
			displayName: "Final Report Date",
			aliasName: "finalReportDate",
			show: true,
		},
		{
			displayName: "Final Archive Deadline Date",
			aliasName: "finalArchiveDeadlineDate",
			show: true,
		},
		{
			displayName: "Expected Report Date",
			aliasName: "expectedReportDate",
			show: true,
		},
		{
			displayName: "Expected Archive Date", // Expected Archive Deadline date
			aliasName: "expectedArchiveDate",
			show: true,
		},
		{
			displayName: "Archive ZIP Status",
			aliasName: "archiveZipStatus",
			show: true,
		},
		{
			displayName: "Replica Status",
			aliasName: "replicaStatus",
			show: true,
		},
		{
			displayName: "Last Accessed At",
			aliasName: "lastAccessAt",
			show: true,
		},
		{
			displayName: "Last Accessed By",
			aliasName: "lastAccessBy",
			show: true,
		},
		{
			displayName: "Created At",
			aliasName: "createdAt",
			show: true,
		},
		{
			displayName: "Created By",
			aliasName: "createdBy",
			show: true,
		},
	]
	if (menuId === DLOrgSubMenus.assigned) {
		InitialPageSetting.push({
			displayName: "Status",
			aliasName: "statusName",
			show: true,
		})
	}
	if (menuId === DLOrgSubMenus.unarchived_mgmt) {
		InitialPageSetting = [
			...InitialPageSetting,
			{
				displayName: "Unarchived At",
				aliasName: "unarchivedAt",
				show: true,
			},
			{
				displayName: "Unarchived By",
				aliasName: "unarchivedBy",
				show: true,
			},
			{
				displayName: "Unarchive Status",
				aliasName: "unarchiveStatus",
				show: true,
			},
		]
	}
	if (
		menuId === DLOrgSubMenus.archived_mgmt ||
		menuId === DLOrgSubMenus.archived ||
		menuId === DLOrgSubMenus.replicas
	) {
		InitialPageSetting = [
			...InitialPageSetting,
			{
				displayName: "Archived At",
				aliasName: "archivedAt",
				show: true,
			},
			{
				displayName: "Archived By",
				aliasName: "archivedBy",
				show: true,
			},
		]
	}
	return InitialPageSetting
}

export const InitialDateFilters = {
	lastAccessStartDate: null,
	lastAccessEndDate: null,
	archivedStartDate: null,
	archivedEndDate: null,
	periodStartDate: null,
	periodEndDate: null,
	//
	expectedReportStartDate: null,
	expectedReportEndDate: null,
	//
	finalReportStartDate: null,
	finalReportEndDate: null,
	//
	expectedArchiveStartDate: null, // expected archive deadline date
	expectedArchiveEndDate: null, // expected archive deadline date
	//
	finalArchiveDeadlineStartDate: null,
	finalArchiveDeadlineEndDate: null,
	//
	createdAtStartDate: null,
	createdAtEndDate: null,
}
