import React from "react"
import { useLocalObservable } from "mobx-react-lite"
import LobbyCheckinStoreInstance from "../../screens/lobby-side/lobby-access-control/store/lobby-access-control.provider"
import UiStoreInstance from "./ui-store/ui-store.provider"
import I18nStoreInstance from "./i18n-store/i18n-store.provider"
import ConfirmSignupStoreInstance from "../../screens/lobby-side/confirm-signup/store/confirm-signup.provider"
import ForgotPasswordStoreInstance from "../../screens/lobby-side/forgot-password/store/forgot-pw.provider"
import GlobalStoreInstance from "./global-store/global-store.provider" // TODO: Remove!
import GlobalNotisInstance from "../../screens/lobby-side/right-drawer/store/global-notis.provider"
import FileEditorStoreInstance from "../../screens/lobby-side/file-editor/store/file-editor.provider"
import { ConsoleLog } from "../../components/basic-elements"

const createRootStore = () => {
	ConsoleLog(" $$$ createRootStore $$$ ")
	const store = {
		global: GlobalStoreInstance,
		ui: UiStoreInstance,
		//
		i18n: I18nStoreInstance,
		// lobby side stores
		checkin: LobbyCheckinStoreInstance,
		signup: ConfirmSignupStoreInstance,
		forgotPw: ForgotPasswordStoreInstance,
		notis: GlobalNotisInstance,
		// orgList: OrgListStoreInstance,
		// my: MyAccountStoreInstance,
		// orgMgmt: orgMgmtInstance,
		// resetPassword: ResetPasswordInstance,
		//
		editor: FileEditorStoreInstance,
	}
	return store
}

type RootStoreType = ReturnType<typeof createRootStore>

const RootStoreContext = React.createContext<RootStoreType | null>(null)

const RootStoreProvider = ({ children }: { children: React.ReactNode }) => {
	const RootStoreStore = useLocalObservable(createRootStore)

	return (
		<RootStoreContext.Provider value={RootStoreStore}>
			{children}
		</RootStoreContext.Provider>
	)
}

const useRootStore = () => {
	const store = React.useContext(RootStoreContext)
	if (!store) {
		throw Error("Root Store is null")
	}
	return store
}

export { RootStoreContext, RootStoreProvider, useRootStore }
