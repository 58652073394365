import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

const RequestOrgSearch = (self: any) => ({
	requestOrgSearch: flow(function* (params: {
		// UserID: number,
		Type: "group" | "cabinet" | "client"
		EnteredText: string
		// Lang: "en" | "kr"
	}) {
		const actionName = "requestOrgSearch"
		try {
			const endpoint = "/GlobalSearch"
			const response = yield getRequest({
				url: endpoint,
				params,
				tokenType: TOKEN_TYPE.orgToken,
			})
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestOrgSearch
