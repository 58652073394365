import { flow } from "mobx-state-tree"

const ReadProjInfo = (self: any, read: any) => ({
	readProjInfo: flow(function*(projId: number) {
		// 0. set actionName
		const actionName = "readProjInfo"
		try {
			const response = yield read({ ClientId: projId })
			// ConsoleLog("readProjInfo response", response)
			return response
		} catch (error) {
			// console.error("readProjInfo error", error, error.response)
			self.handleModelError({ actionName, error, open: false })

			return false
		}
	}),
})

export default ReadProjInfo
