// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const UnmapLeadSheetFromSubGroup = (self: any) => ({
	unmapLeadSheetFromSubGroup(mapId: number) {
		// 0
		const actionName = "unmapLeadSheetFromSubGroup"
		// 1
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Unmapping lead sheet is in progress",
			open: true,
		})

		const payload = {
			MapId: mapId,
		}

		// 3) request API call to Model
		self.mUnmapLeadSheetFromSubGroup(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						self.unMapLeadSheet(mapId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully unmapped lead sheet",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default UnmapLeadSheetFromSubGroup
