import React, { useState, useEffect } from "react"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
} from "../../../components/basic-elements/icons/common-icons"
import styled from "styled-components"
import {
	DLIconButton,
	DLLocalFileUploadButton,
} from "../../../components/basic-elements"
import { AttachmentProps } from "./PostAddOrEdit"
import { CommonLocalFileProps } from "../../../components/basic-elements/buttons/DLLocalFileUploadButton"
import { formatFileSize } from "@datalobby/common"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"

export const Attachments = ({
	files,
	removeFile,
	handleFiles,
	eleClassName,
}: {
	files: AttachmentProps[]
	removeFile: (id: string) => void
	handleFiles: (localFiles: CommonLocalFileProps[]) => void
	eleClassName?: string
}) => {
	const [totalSize, setTotalSize] = useState(0)

	useEffect(() => {
		// let totalFileSize = 0
		// files.map((file: AttachmentProps) => {
		// 	totalFileSize = totalFileSize + file.file.size
		// })

		setTotalSize(calcTotalSize())
	}, [files])

	const calcTotalSize = () => {
		let totalFileSize = 0
		files.map((file: AttachmentProps) => {
			totalFileSize = totalFileSize + file.file.size
		})
		console.log("calcTotalSize: ", totalFileSize)
		return totalFileSize
	}
	{
		/* TODO: NOTE: @Noah: consider the consistency with other local file upload parts */
	}

	return (
		<StyledAttachments className={eleClassName}>
			<div className="FR AC JSB">
				<div className="FR AC">
					Attachments (
					<span data-testid="attachments-count">{files?.length}</span>
					) / Total size:{" "}
					<span
						data-testid="attachments-total-size"
						style={{ marginLeft: 8 }}
						className={
							totalSize > 100000000 ? "total-size-warning" : ""
						}
					>
						{formatFileSize(totalSize)}
					</span>
					<span
						data-testid="total-size-limitation"
						style={{ marginLeft: 8, opacity: 0.5 }}
					>
						*Maximum 100MB
					</span>
				</div>
				<DLLocalFileUploadButton multiple fileHandler={handleFiles} />
			</div>
			<ReactTableV8
				tableColumns={columns(removeFile)}
				data={files}
				menuId="Attachments"
				showPageSetting={false}
				height={32 * 5 + 48}
			/>
		</StyledAttachments>
	)
}

const columns = (removeFile: (id: string) => void) => [
	{
		header: "index",
		accessorKey: "x",
		size: 30,
		cell: (props: any) => {
			return <div>{props.row.index + 1}</div>
		},
	},
	{
		header: "Name",
		accessorKey: "file.name",
		cell: (props: any) => {
			const cellId = "fileName" + "-cell"
			return (
				<div data-testid={cellId}>
					{props.getValue()}.{props.row.original.file.extension}
				</div>
			)
		},
	},
	{
		header: "Extension",
		accessorKey: "file",
		accessorFn: (props: any) => props.extension,
		cell: (props: any) => {
			const cellId = "extension" + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	// { header: "Size", accessor: "file.formattedSize", width: 100 },
	{
		headerKey: "Size",
		accessorKey: "file",
		accessorFn: (props: any) => props.formattedSize,
		cell: (props: any) => {
			const cellId = "formattedSize" + "-cell"
			return <div data-testid={cellId}>{props.getValue()}</div>
		},
	},
	{
		header: "Remove",
		accessorKey: "x",
		width: 40,
		cell: (props: any) => {
			const id = props.row.original.file.id
			console.log("file id:", id)
			return (
				<DLIconButton
					eleTestId="remove-file-btn"
					clickHandler={() => removeFile(id)}
				>
					<DLIcon
						name={DLIconName.close}
						size={CommonIconSize.small}
						noMargin
					/>
				</DLIconButton>
			)
		},
	},
]

const StyledAttachments = styled.div`
	.total-size-warning {
		color: ${(props) => props.theme.themeRed};
	}
	.attachement-list {
		height: 90px;
		overflow-y: auto;
		.attachment-item {
			width: 100%;
			border: 1px solid pink;
		}
		.no-attachments {
		}
	}
`

export default Attachments
