import { types } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import ResponseSnackbar from "../../../common-models/response-snackbar"
// ---------- common actions
import { CommonViewModelActions } from "../../../common-models/common-view-model-actions"
import { OrgSysSettingItemModel } from "../../../service-modules/sys-setting-module/sys-setting.data-model"
import { Responses } from "../../../common-models"

export type SetGlobalDnTType = {
	dateFormat: any
	timeFormat: any
	timeZone: any
}

export const GlobalStoreViewModel = types
	.model({
		sidebarViewState: types.enumeration("ViewType", ["org", "proj"]),
		currentOrg: types.model({
			id: "",
			title: "",
		}),
		currentProj: types.model({
			id: "",
			title: "",
		}),
		currentProjMenu: types.array(types.string),

		// ---------- global sharing for all
		dateFormat: OrgSysSettingItemModel,
		timeFormat: OrgSysSettingItemModel,
		timeZone: OrgSysSettingItemModel,

		// ---------- common models
		actionStatus: types.enumeration<ActionStatus>(
			Object.values(ActionStatus)
		),
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		// for DnT
		setDateFormat(dateFormat: any) {
			self.dateFormat = dateFormat
		},
		setTimeFormat(timeFormat: any) {
			self.timeFormat = timeFormat
		},
		setTimeZone(timeZone: any) {
			self.timeZone = timeZone
		},
		setGlobalDnT({ dateFormat, timeFormat, timeZone }: SetGlobalDnTType) {
			self.dateFormat = dateFormat
			self.timeFormat = timeFormat
			self.timeZone = timeZone
		},
	}))
	.views((self) => ({
		get getDntFormat() {
			return {
				dateFormat: self.dateFormat.value,
				timeZone: self.timeZone.uiValue,
				timeFormat: self.timeFormat.value,
			}
		},
	}))
	.actions((self) => ({
		setCurrentOrg(id: string, title: string) {
			self.currentOrg = {
				id,
				title,
			}
		},
		setCurrentProj(id: string, title: string) {
			self.currentProj = {
				id,
				title,
			}
		},
		setCurrentProjMenu(menuId: typeof self.currentProjMenu) {
			self.currentProjMenu = menuId
		},
	}))
	.actions(CommonViewModelActions)

export default GlobalStoreViewModel
