import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { AssignStatus } from "../data-models/org-clients.data-models"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const GetGroupsForClients = (self: any) => ({
	getGroupsForClient(type: AssignStatus, clientId: string) {
		// 0.
		const actionName = `getGroupsForClient${type}`
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		// 3. api call
		// 3-1) prepare payload
		const groupType = type === AssignStatus.assigned ? 2 : 1
		const clientMasterId = idToNumber(clientId, IdType.client)
		const payload = {
			Type: groupType,
			Lang: "en",
			ClientMasterID: clientMasterId,
		}
		// ConsoleLog(payload)
		// 3-2) reset the previous group list
		self.resetGroupList(type)
		// 3-3) call API from the Model
		self.readGroupsForClient(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					const groupList = response.data.Grplst
					groupList.map((group: any) => {
						const reOrganizedGroup = {
							id: group.GroupID.toString(), // TODO: @Prudhvi0817: Please use idToString
							name: group.GroupName, // [GroupName], ex) "A"
							selected: false,
						}
						// ConsoleLog(reOrganizedGroup)
						if (type === AssignStatus.unAssigned) {
							self.pushItemToUnAssignedGroupList(reOrganizedGroup)
						} else {
							self.pushItemToAssignedGroupList(reOrganizedGroup)
						}
					})

					// update success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: false,
					})
				} else {
					// update fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						customMessage:
							"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetGroupsForClients
