import { types } from "mobx-state-tree"
import ConfirmSignupModel from "./confirm-signup.model"
import ConfirmSignupViewModel from "./confirm-signup.view-model"
import * as api from "./confirm-signup.apis"

export const ConfirmSignupStore = (apiVerify: any, apiResend: any) =>
	types
		.compose(
			ConfirmSignupModel(apiVerify, apiResend),
			ConfirmSignupViewModel
		)
		.named("ConfirmSignupStore")

const ConfirmSignupStoreInstance = ConfirmSignupStore(
	api.verify,
	api.resend
).create({
	// shared parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
})

export default ConfirmSignupStoreInstance
