import {
	Sector,
	Priority,
	WhoCanRead,
	PostAttachmentType,
} from "@datalobby/types"

export type GetPostQuery = {
	getPostById: {
		__typename: "Post"
		menu: string
		id: string
		title: string
		content: string
		//
		sector: Sector
		parent: string
		priority?: Priority
		fixToTop?: boolean
		attachments?: PostAttachmentType[]
		//
		// whoCanRead?: WhoCanRead
		// allowedTargets?: string[]
		// visitors?: string[]
		// visitorCount?: number
		//
		createdBy?: string
		createdAt?: string
		modifiedBy?: string
		modifiedAt?: string
	} | null
}

export const getPostById = /* GraphQL */ `
	query GetPostById($menuId: String!, $postId: String!) {
		getPostById(menuId: $menuId, postId: $postId) {
			menu
			id
			title
			content
			#
			sector
			parent
			priority
			fixToTop
			attachments {
				fileName
				extension
				size
				formattedSize
				s3ObjKey
			}
			#
			# whoCanRead
			# allowedTargets
			# visitors
			# visitorCount
			#
			createdBy
			createdAt
			modifiedBy
			modifiedAt
			deleted
		}
	}
`

export type PostsByParentQuery = {
	postsByParent: {
		__typename: "ModelPostConnection"
		items: Array<{
			__typename: "Post"
			menu: string
			id: string
			title: string
			content: string
			//
			sector: Sector
			parent: string
			priority?: Priority
			fixToTop?: boolean
			attachments?: PostAttachmentType[]
			//
			// whoCanRead?: WhoCanRead
			// allowedTargets?: string[]
			// visitors?: string[]
			// visitorCount?: number
			//
			createdBy?: string
			createdAt?: string
			modifiedBy?: string
			modifiedAt?: string
		} | null> | null
		nextToken: string | null
	} | null
}

export const postsByParent = /* GraphQL */ `
	query PostsByParent($parentId: String!, $deleted: Boolean) {
		postsByParent(parentId: $parentId, deleted: $deleted) {
			menu
			id
			title
			content
			#
			sector
			parent
			priority
			fixToTop
			attachments {
				fileName
				extension
				size
				formattedSize
				s3ObjKey
			}
			#
			# whoCanRead
			# allowedTargets
			# visitors
			# visitorCount
			#
			createdBy
			createdAt
			modifiedAt
			modifiedBy
			deleted
		}
	}
`
