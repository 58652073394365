import { observer } from "mobx-react-lite"
import React, { ReactElement, useEffect, useState } from "react"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import styled from "styled-components"
import {
	InputWithLabel,
	DLInputField,
	DLSingleSelect,
	DLButton,
	DLSpinner,
	DLSpinnerCenterAligned,
	ConsoleLog,
} from "../../../../basic-elements"
import {
	apgLibPrefix,
	normalLibPrefix,
} from "../../../../../screens/project-side/lib-in-proj/store/lib-in-proj.data-model"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import AddFileSignOffForm from "./AddFileSignOffForm"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { FolderSelectField } from "."
import sharedRegEx from "../../../../../library/sharedRegEx"
import { isEmptyString } from "@datalobby/common"
import DLSystemMsg, {
	NoSpecialChar2Msg,
} from "../../../../basic-elements/system-msg/DLSystemMsg"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLCheckbox } from "@datalobby/components"
import ReactTableV8 from "../../../../basic-elements/tables/DLReactTable"

export type LocalFileProps = {
	aliasId: string
	name: string
	prepared: boolean
	reviewed: boolean
	[x: string]: any
}

export default observer(function AddTemplateForm({
	menuId,
	projId,
	parentInfo,
	folderId,
	partialStore,
	i18n,
	permission,
}: {
	menuId: DLProjSubMenus | DLOrgSubMenus
	projId: string
	parentInfo: any
	folderId: string | undefined
	partialStore: any
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// NOTE: project template has not import from library
	const projStore = useProjStore()
	const libStore = projStore.lib

	const userRole = projStore.checkin.user.roleId

	const [files, setFiles] = useState<LocalFileProps>({
		aliasId: "",
		name: "",
		prepared: false,
		reviewed: false,
		extension: "",
		size: "",
	})

	const [libGroupId, setLibGroupId] = useState("")
	const [libId, setLibId] = useState("")
	const [fileId, setFileId] = useState("")

	const [highlight, setHighlight] = useState(false)

	const fromLibDrawerId = libStore.selectedItemId

	// useEffect(() => {
	// 	if (fromLibDrawerId === "") {
	// 		const orgId = projStore.checkin.orgId
	// 		libStore.getLibrariesInProj(orgId)
	// 	}
	// }, [])

	console.log(libGroupId, libId, fromLibDrawerId, folderId, "fromLibDrawerId")

	useEffect(() => {
		const fileInfo = libStore.viewTempFileInfo(fromLibDrawerId)
		const { title, parentId, aliasId, extension, size, id } = fileInfo

		console.log(fileInfo, "fileInfo")

		setFiles((prev: any) => ({
			...prev,
			aliasId: aliasId,
			name: title,
			extension,
			size,
		}))

		setLibGroupId(libStore.selectedTempFolder)
		setLibId(libStore.selectedTempFile)
		setFileId(id)
	}, [])

	const handleAdd = () => {
		if (permission.create) {
			partialStore.addFileFromTemp({
				projectId: projId,
				userId: projStore.checkin.userId,
				parentId: folderId,
				srcFileId: fileId,
				fileName: files.name,
				fileAliasId: files.aliasId,
				prepared: files.prepared,
				reviewed: files.reviewed,
			})
		} else {
			alert("Sorry, you don't have the permission.")
		}
	}

	const checkActionReady = () => {
		let actionReady = false
		if (
			folderId !== "" &&
			folderId !== undefined &&
			libGroupId !== "" &&
			libId !== "" &&
			!isEmptyString(files.aliasId) &&
			!isEmptyString(files.name)
		) {
			const isApgLib = libId.includes(apgLibPrefix)
			if (isApgLib && fileId === "") {
				actionReady = true
			} else if (isApgLib && fileId !== "") {
				actionReady = false
			} else if (!isApgLib && fileId === "") {
				actionReady = false
			} else if (!isApgLib && fileId !== "") {
				actionReady = true
			}
		}
		return actionReady
	}

	const libGroupList = libStore.viewTempGroups({ simpleMode: true })
	const libList = libStore.viewTempListByLibGroup({
		libGroupId,
		simpleMode: true,
	})

	const libFilesFetchingStatus = libStore.getActionStatus("getLibFilesInProj")
	const isLoading =
		partialStore.getActionStatus("addFileFromLib") === ActionStatus.loading

	const checkInput = (target: "aliasId" | "name", input: string) => {
		let isDuplicatedInStore = false
		let isEmpty = false
		let isDuplicatedInDialog = false
		let isInvalidFormat =
			target === "name"
				? !sharedRegEx.noSpecialChar2.test(input)
				: !sharedRegEx.noSpecialChar3.test(input)
		let isExceed = false

		if (
			input === "" ||
			input === undefined ||
			input.replace(/ /g, "").length === 0 ||
			input.replace(/&nbsp;/g, "").replace(/ /g, "").length === 0
		) {
			isEmpty = true
		} else {
			if (target === "aliasId") {
				isDuplicatedInStore = partialStore.isDuplicatedAliasId(input)
				console.log(isDuplicatedInStore)
			} else {
				isDuplicatedInStore = partialStore.isDuplicatedName(input)
			}
		}
		const len = target === "aliasId" ? 50 : 100
		if (input.length > len) {
			isExceed = true
		}

		let result: "empty" | "duplicated" | "invalid" | "exceed" | "ready" =
			"ready"
		if (isEmpty) {
			result = "empty"
		} else if (isDuplicatedInStore || isDuplicatedInDialog) {
			result = "duplicated"
		} else if (isInvalidFormat) {
			result = "invalid"
		} else if (isExceed) {
			result = "exceed"
		}

		return result
	}

	function renderEditableRefNum(props: any) {
		const aliasIdInput = props.row.original.aliasId
		const checkResult = checkInput("aliasId", aliasIdInput)
		const clsName = "alias-id-input " + checkResult
		const testId = "alias-id"
		const columnId = props.cell.column.id

		return renderField(testId, clsName, columnId, files, checkResult)
	}

	function renderEditableFileName(props: any) {
		const nameInput = props.row.original.name
		const checkResult = checkInput("name", nameInput)
		const clsName = "file-name-input " + checkResult
		const testId = "file-name"
		const columnId = props.cell.column.id

		return renderField(testId, clsName, columnId, files, checkResult)
	}

	function renderField(
		testId: string,
		clsName: string,
		columnId: string,
		targetFile: any,
		checkResult: string
	) {
		let helpMsg = ""
		if (checkResult === "invalid") {
			helpMsg =
				i18n.warningSpecialChar2 ||
				'* | \\ / " ` : < > { } ? _ are not allowed.'
		}
		if (checkResult === "duplicated" && testId === "alias-id") {
			helpMsg = i18n.twDuplicatedAliasId || "Duplicated Ref.Num"
		}
		if (checkResult === "duplicated" && testId === "file-name") {
			helpMsg = i18n.twDuplicatedFileName || "Duplicated File Name"
		}
		if (checkResult === "exceed") {
			helpMsg = "Exceeded the limited number of characters."
		}

		// const width = testId === "file-name" ? "150px" : "100%"

		return (
			<div
				style={{ wordBreak: "break-word" }}
				className="input-container FC JC AL"
			>
				<div
					data-testid={testId}
					className={clsName}
					contentEditable
					suppressContentEditableWarning
					// style={{ height: 30 }}
					onBlur={(e: any) => {
						e.persist()
						setFiles((prev) => ({
							...prev,
							[columnId]: e.target.innerHTML
								.replace(/&nbsp;/g, " ")
								.trim(),
						}))
					}}
					dangerouslySetInnerHTML={{
						__html:
							targetFile && targetFile[columnId]
								? targetFile[columnId]
								: "",
					}}
				/>
				<span className="warning-msg">
					{checkResult !== "empty" &&
						checkResult !== "ready" &&
						helpMsg}
				</span>
			</div>
		)
	}

	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []

	let showPrepareCheckbox = false
	let showReviewCheckbox = false

	if (signOffPolicy !== null) {
		if (signOffPolicy[0].availableRoles.includes(userRole)) {
			showPrepareCheckbox = true
		}
		if (signOffPolicy[1].availableRoles.includes(userRole)) {
			showReviewCheckbox = true
		}
	}

	const alias = checkInput("aliasId", files.aliasId)
	const fileName = checkInput("name", files.name)

	const isReady = alias === "ready" && fileName === "ready"

	return (
		<>
			{isLoading && (
				<DLSpinnerCenterAligned absolute backgroundOpacity={0.5} />
			)}

			<SteyldImporFileFromLib className="FC JSB">
				<div>
					<h3>From Project Template</h3>
					<div className="input-section FR">
						<InputWithLabel
							label="Project Template Group"
							required
							highlight={highlight && libGroupId === ""}
						>
							<DLSingleSelect
								eleTestId="lib-group-select"
								eleValue={libGroupId}
								eleOnChange={(e: any) =>
									setLibGroupId(e.target.value)
								}
								eleName="lib-group"
								eleFullWidth
								withLabel={false}
								placeholder="Please select a Template group"
								optionList={libGroupList}
								eleDisabled={true}
								minHeight="auto"
							/>
						</InputWithLabel>
					</div>

					<div className="input-section FR">
						<InputWithLabel
							label="Project Template"
							required
							highlight={highlight && libId === ""}
						>
							{libGroupId !== "" && libList.length === 0 ? (
								<DLSystemMsg
									eleTestId="msg-empty-lib-group"
									type={MessageColorType.orange}
									msg="This Template Group is empty."
								/>
							) : (
								<DLSingleSelect
									eleTestId="lib-select"
									eleValue={libId}
									eleOnChange={(e: any) =>
										setLibId(e.target.value)
									}
									eleName="lib"
									eleFullWidth
									withLabel={false}
									placeholder="Please select a template"
									optionList={libList}
									eleDisabled={true}
									minHeight="auto"
								/>
							)}
						</InputWithLabel>
					</div>

					<hr style={{ margin: "2rem 0" }} />

					<h3>To</h3>
					<FolderSelectField
						i18n={i18n}
						partialStore={partialStore}
						folderTitle={parentInfo?.title}
						highlight={highlight}
						disabled={true}
					/>

					<hr style={{ margin: "2rem 0" }} />

					<h3 style={{ marginBottom: 10 }}>File Information</h3>
					<ReactTableV8
						tableColumns={AddTemplateFormTableColumns({
							renderEditableRefNum,
							renderEditableFileName,
							handlePrepare: () =>
								setFiles((prev: any) => ({
									...prev,
									prepared: !prev.prepared,
								})),
							handleReview: () =>
								setFiles((prev: any) => ({
									...prev,
									reviewed: !prev.reviewed,
								})),
							showPrepareCheckbox,
							showReviewCheckbox,
						})}
						data={[files]}
						hasPagination={false}
						showPageSetting={false}
						height={100}
						menuId="AddTemplateForm"
						// getRowClass={(row: any) => {
						// 	if (row.original.size > 100000000) {
						// 		return "oversize"
						// 	}
						// 	return ""
						// }}
					/>
					<div style={{ marginTop: 10 }}>
						<b>Note: </b>{" "}
						{`The characters / * ? " > < : | are not allowed in Ref Num and Workpaper Name`}
					</div>
				</div>
				<div className="FR JR">
					<DLButton
						eleTestId="add-file-from-lib-btn"
						color="primary"
						clickHandler={handleAdd}
						eleHeight="auto"
						disabled={!checkActionReady() || isLoading || !isReady}
						onMouseOver={() => setHighlight(true)}
						onMouseLeave={() => setHighlight(false)}
					>
						Add
					</DLButton>
				</div>
			</SteyldImporFileFromLib>
		</>
	)
})

const SteyldImporFileFromLib = styled.div`
	height: 100%;
	.input-area {
		opacity: 0.5;
		background-color: #cad6e2;
	}
	.input-container {
		.alias-id-input,
		.file-name-input {
			border-radius: 3px;
			padding: 4px 8px;
			width: 100%;
			&.empty {
				background-color: ${(props) =>
					props.theme.shared.messageRedAutoHide};
			}
			&.duplicated {
				border: 1px solid ${(props) => props.theme.shared.messageRed};
			}
			&.invalid {
				border: 1px solid purple;
				background-color: ${(props) =>
					props.theme.shared.messageOrangeAutoHide};
			}
			&.exceed {
				border: 1px solid orange;
			}
		}
		.warning-msg {
			font-size: ${(props) => props.theme.shared.textRg};
			color: ${(props) => props.theme.shared.messageRed};
			padding-left: 8px;
		}
	}
	table > thead > tr > th {
		padding: 0;
		.header {
			min-height: 2rem;
			padding-left: 8px;
			justify-content: center;
		}
	}
	table > tbody > tr > td {
		padding: 8px;
	}
`

const AddTemplateFormTableColumns = ({
	renderEditableRefNum,
	renderEditableFileName,
	handlePrepare,
	handleReview,
	showPrepareCheckbox,
	showReviewCheckbox,
}: {
	renderEditableRefNum: any
	renderEditableFileName: any
	handlePrepare: any
	handleReview: any
	showPrepareCheckbox: boolean
	showReviewCheckbox: boolean
}) => {
	const columns: any = [
		{
			header: "Ref. num",
			size: 40,
			accessorKey: "aliasId",
			cell: renderEditableRefNum,
		},
		{
			header: "Type",
			size: 40,
			accessorKey: "extension",
			cell: (props: any) => {
				const testId = props.cell.column.id + "-cell"
				return <div data-testid={testId}>{props.getValue()}</div>
			},
		},
		{
			header: "Size",
			size: 40,
			accessorKey: "size",
			cell: (props: any) => {
				const testId = props.cell.column.id + "-cell"
				return <div data-testid={testId}>{props.getValue()}</div>
			},
		},
		{
			header: "File name",
			accessorKey: "name",
			cell: renderEditableFileName,
		},
	]

	if (showPrepareCheckbox) {
		columns.push({
			header: (
				<div className="FR AC">
					{/* <DLCheckbox
						eleTestId="add-file-checkbox-prepare-all"
						eleClassName="signoff-all-toggle-checkbox prepare"
						color="primary"
						isChecked={prepare}
						clickHandler={() => toggleAllPrepare(!prepare)}
					/> */}
					Prepare
				</div>
			),
			accessorKey: "prepared",
			size: 20,
			enableSorting: false,
			cell: (props: any) => {
				const testId = "upload-file-checkbox-prepare"
				return (
					<DLCheckbox
						// eleTestId={`checkbox-prepare-${row.original.aliasId}`}
						eleTestId={testId}
						color="primary"
						isChecked={props.getValue()}
						clickHandler={handlePrepare}
					/>
				)
			},
		})
	}

	if (showReviewCheckbox) {
		columns.push({
			header: (
				<div className="FR AC">
					{/* <DLCheckbox
						eleTestId="add-file-checkbox-review-all"
						eleClassName="signoff-all-toggle-checkbox review"
						color="primary"
						isChecked={review}
						clickHandler={() => toggleAllReview(!review)}
					/> */}
					Review
				</div>
			),
			size: 20,
			enableSorting: false,
			accessorKey: "reviewed",
			cell: (props: any) => {
				const testId = "upload-file-checkbox-review"
				return (
					<DLCheckbox
						// eleTestId={`checkbox-review-${row.original.aliasId}`}
						eleTestId={testId}
						color="primary"
						isChecked={props.getValue()}
						clickHandler={handleReview}
					/>
				)
			},
		})
	}

	return columns
}
