import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../library/api-requests/shared-for-api-requests"
import { getRequest, postRequest } from "../../library/api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetSystemSettings",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

// NOTE: After discussion with backend team, need to separate or keep up
export type UpdateSysSettingApiProps = {
	// storeName: string,
	payload: {
		ParameterName: string
		SettingID: number
		ParameterDisplay: string
		ParameterValue: string
		ParameterValueUI: string
	}[]
}
export async function update(props: UpdateSysSettingApiProps) {
	const { payload } = props
	const response = await postRequest({
		url: "/UpdateSettings",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgSysSettingApiTypes = {
	apiRead: () => Promise<AxiosResponse<any>>
	apiUpdate: (props: UpdateSysSettingApiProps) => Promise<AxiosResponse<any>>
	//
}
