import {
	MenuPermissionProps,
	UserActionProps,
} from "../service-modules/role-module/roles.data-model"
import { DLProjSubMenus } from "../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export type PermissionAsObjectProps = { [x: string]: boolean }

const CheckingPermission = (self: any) => ({
	getActionsAsObject(
		menuId: string,
		isLocked?: boolean,
		isArchived?: boolean
		// orgRoleId?: string // for project side user screen
	) {
		// WARNING: be careful. this function is working for BOTH org and proj
		const permissions = self.assignedRole.menuPermissions.find(
			(menu: MenuPermissionProps) => menu.menuId === menuId
		)
		const orgRoleId = localStorage.getItem("orgRoleId")

		if (permissions) {
			let permissionObject: PermissionAsObjectProps = {}

			/**
			 * < in the PROJECT side >
			 * 1. if the project is archived
			 * ---- all actions be false except 'read' and 'export'
			 * ---- 'read' and 'export' are depend on the role
			 *
			 * 2. if the project is locked
			 * ---- all actions be false except 'read' and 'export'
			 * ---- some screens allow other actions (arch diagnosis, arch pre-process, setup users)
			 * ---- for AA, SA who assigned in the project, override the all rights as true on the users screen
			 *
			 * 3. else
			 * ---- for AA, SA who assigned in the project, override the all rights as true on the users screen
			 *
			 */
			permissions.actions.map((item: UserActionProps) => {
				// NOTE: 1. if the project is archived
				if (isArchived) {
					// all actions be false except 'read' and 'export'
					if (
						item.actionName === "read" ||
						item.actionName === "export"
					) {
						permissionObject[item.actionName] = item.available
					} else {
						permissionObject[item.actionName] = false
					}
				} else if (isLocked) {
					// NOTE: 2. if the project is locked
					// all actions be false except 'read' and 'export'
					if (
						item.actionName === "read" ||
						item.actionName === "export"
					) {
						permissionObject[item.actionName] = item.available
					} else {
						// but able to do some action on some screens
						// (archive diagnosis, archive preprocess, setup users)
						if (
							menuId === DLProjSubMenus.arch_diagnosis ||
							menuId === DLProjSubMenus.arch_pre_process
						) {
							permissionObject[item.actionName] = item.available
						} else if (menuId === DLProjSubMenus.users) {
							// check the org user role
							if (
								orgRoleId === "id_sa" ||
								orgRoleId === "id_aa"
							) {
								permissionObject[item.actionName] = true
							} else {
								permissionObject[item.actionName] =
									item.available
							}
						} else {
							permissionObject[item.actionName] = false
						}
					}
				} else {
					// NOTE: 3. else
					if (menuId === DLProjSubMenus.users) {
						// check the org user role
						if (orgRoleId === "id_sa" || orgRoleId === "id_aa") {
							permissionObject[item.actionName] = true
						} else {
							permissionObject[item.actionName] = item.available
						}
					} else {
						permissionObject[item.actionName] = item.available
					}
				}
			})

			//
			//
			//
			//
			//

			// if (
			// 	menuId === DLProjSubMenus.users &&
			// 	(orgRoleId === "id_sa" || orgRoleId === "id_aa")
			// ) {
			// 	permissions.actions.map((item: UserActionProps) => {
			// 		permissionObject[item.actionName] = true
			// 	})
			// } else {
			// 	permissions.actions.map((item: UserActionProps) => {
			// 		if (
			// 			item.actionName === "read" ||
			// 			item.actionName === "export"
			// 		) {
			// 			permissionObject[item.actionName] = item.available
			// 		} else {
			// 			// Locked or Archived status conditions
			// 			// for CUD actions
			// 			if (menuId === DLProjSubMenus.arch_diagnosis) {
			// 				// archive diagnosis allow unlock even if the project is locked
			// 				// --> Just provide default permission without change
			// 				permissionObject[item.actionName] = item.available
			// 			} else if (menuId === DLProjSubMenus.arch_pre_process) {
			// 				// archive pre-process allow some actions even if the project is locked
			// 				// --> Just provide default permisison without change
			// 				permissionObject[item.actionName] = item.available
			// 			} else if (menuId === DLProjSubMenus.users) {
			// 				// (1) OA can activate or unassign users even the project is locked
			// 				// --> Just provide default permission without change

			// 				permissionObject[item.actionName] = item.available
			// 			} else {
			// 				// in other cases, provide 'false' when the project is locked or archived
			// 				permissionObject[item.actionName] =
			// 					isLocked || isArchived ? false : item.available
			// 			}
			// 		}
			// 	})
			// }
			return permissionObject
		} else {
			console.error("Matched menu not exist")
			return {}
		}
	},
	checkAccess(menuId: string) {
		const permissions = self.assignedRole.menuPermissions
		const menuPermission = permissions.find(
			(menu: any) => menu.menuId === menuId
		)

		return menuPermission.accessible
	},
})

export default CheckingPermission
