import React from "react"
import { types } from "mobx-state-tree"
import LeadSheetViewModel from "./lead-sheets.view-model"
import LeadSheetModel from "./lead-sheet.model"

export const LeadSheetStore = () =>
	types.compose(LeadSheetModel(), LeadSheetViewModel).named("LeadSheetStore")

export const initialStore = {
	url: "",
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const LeadSheetStoreInstance = LeadSheetStore().create(initialStore)

export default LeadSheetStoreInstance
