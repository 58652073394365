import {
	DialogActionType,
	DLCommentType,
} from "../../../common-models/enumerations/common-enums"
import { ClickPointProps } from "../../../common-models/types/dialog.props"
import { ConsoleLog } from "../../../components/basic-elements"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"
import {
	FileScreenDialog,
	FileScreenColumn,
	FileUploadFromType,
	FileMenuViewType,
} from "../data-models/dl-file-control-model"

export enum ResizableFileInfoArea {
	roles = "assignedRoles",
	aliasId = "aliasId",
	title = "title",
	format = "format",
	size = "size",
	signOffLock = "signOffLock",
	attentionStatus = "attentionStatus",
}

const FileMenuUiHelperActions = (self: any) => ({
	reflectAddComment(fileId: string, commentType: DLCommentType) {
		// ConsoleLog("reflectAddComment", fileId, commentType)
		const targetFile = self.flatList.find((item: any) => item.id === fileId)
		if (targetFile) {
			if (commentType === DLCommentType.normal) {
				targetFile.reviewCommentStatus = CommentStatus.todo
			} else if (commentType === DLCommentType.qc) {
				targetFile.qcCommentStatus = CommentStatus.todo
			}
		} else {
			ConsoleLog("Cannot find the target file " + fileId)
		}
	},
	reflectRemoveCommentOnFileRow(
		fileId: string,
		commentId: string,
		commentList: any
	) {
		ConsoleLog(["reflectRemoveComment", fileId, commentId, commentList])
		const targetFile = self.flatList.find((item: any) => item.id === fileId)
		if (targetFile) {
			if (commentList.length === 1) {
				targetFile.reviewCommentStatus = CommentStatus.notExist
				targetFile.qcCommentStatus = CommentStatus.notExist
			} else {
				// if (commentType === DLCommentType.normal) {
				// 	targetFile.reviewCommentStatus = CommentStatus.todo
				// } else if (commentType === DLCommentType.qc) {
				// 	targetFile.qcCommentStatus = CommentStatus.todo
				// }
			}
		} else {
			ConsoleLog("Cannot find the target file " + fileId)
		}
	},
	setViewType(viewType?: FileMenuViewType) {
		if (viewType !== undefined) {
			self.viewType = viewType
		} else {
			self.viewType = FileMenuViewType.tree
		}
	},
	setFileTreeDialogOpen(dialogName: FileScreenDialog, request?: boolean) {
		if (self.fileTreeDialogOpenStatus[dialogName] !== undefined) {
			if (request !== undefined) {
				self.fileTreeDialogOpenStatus[dialogName] = request
			} else {
				self.fileTreeDialogOpenStatus[dialogName] =
					!self.fileTreeDialogOpenStatus[dialogName]
			}
		} else {
			ConsoleLog("This dialog does not exist on the store " + dialogName)
		}
	},
	setCommentDetailDialogMode(mode: DialogActionType) {
		self.commentDetailDialogMode = mode
	},
	setCommentType(type: DLCommentType) {
		self.commentType = type
	},
	setColumnWidth(columnName: FileScreenColumn, width: number) {
		self.setColumnWidth[columnName] = width
	},
	setColumnHighlight(columnName: string) {
		ConsoleLog(" --- set hovered column --- " + columnName)
		self.columnHighlight = columnName
	},
	setFolderClickPoint(clickPoint: ClickPointProps) {
		ConsoleLog(["setFolderClickPoint ", clickPoint])
		self.folderClickPoint = clickPoint
	},
	setFileClickPoint(clickPoint: ClickPointProps) {
		ConsoleLog(["setFileClickPoint", clickPoint])
		self.fileClickPoint = clickPoint
	},
	setSignOffCellType(type: any) {
		self.signOffCellType = type
	},
	setSignOffDisplayRange(range: any) {
		self.signOffDisplayRange = range
	},
	setSignOffSortType(sortingType: any) {
		self.signOffSortType = sortingType
	},
	setDownloadType(type: any) {
		self.downloadType = type
	},
	//
	//
	// file tree
	setShowCheckbox(request?: boolean) {
		if (request !== undefined) {
			self.showCheckbox = request
		} else {
			self.showCheckbox = !self.showCheckbox
		}
	},
	// NOTE: this one only for project template
	// because the menu has no folders lock feature
	// this one just styled thing...
	setShowDragIcon(request?: boolean) {
		ConsoleLog("setShowDragIcon for project template")
		if (request !== undefined) {
			self.structureLock = request
		} else {
			self.structureLock = !self.structureLock
		}
	},
	updateColumnDisplayStatus(columnName: string, request: boolean) {
		self.showColumns[columnName] = request
	},
	setShowColumns(columns: any) {
		self.showColumns = columns
	},
	setShowSignOffColumns(signOffCoulumns: any) {
		self.showSignOffColumns = signOffCoulumns
	},
	updateSignOffColumnDisplay(
		// this ids are same with SignOffPolicyItem ID
		signOffName:
			| "id_prepare"
			| "id_review"
			| "id_epReview"
			| "id_cpReview"
			| "id_qcReview",
		request: boolean
	) {
		self.showSignOffColumns[signOffName] = request
	},
	updateSignOffAreaWidth(value: number) {
		self.columnWidth[FileScreenColumn.signOffArea] =
			self.columnWidth[FileScreenColumn.signOffArea] - value
	},
	updateFileInfoAreaWidth(part: ResizableFileInfoArea, value: number) {
		if (part === ResizableFileInfoArea.roles) {
			self.columnWidth[FileScreenColumn.assignedRoles] =
				self.columnWidth[FileScreenColumn.assignedRoles] + value
		} else if (part === ResizableFileInfoArea.aliasId) {
			self.columnWidth[FileScreenColumn.aliasId] =
				self.columnWidth[FileScreenColumn.aliasId] + value
		} else if (part === ResizableFileInfoArea.title) {
			self.columnWidth[FileScreenColumn.title] =
				self.columnWidth[FileScreenColumn.title] + value
		} else if (part === ResizableFileInfoArea.format) {
			self.columnWidth[FileScreenColumn.format] =
				self.columnWidth[FileScreenColumn.format] + value
		} else if (part === ResizableFileInfoArea.size) {
			self.columnWidth[FileScreenColumn.size] =
				self.columnWidth[FileScreenColumn.size] + value
		} else if (part === ResizableFileInfoArea.signOffLock) {
			self.columnWidth[FileScreenColumn.signOffLock] =
				self.columnWidth[FileScreenColumn.signOffLock] + value
		}
	},

	setShowStatus(request?: boolean) {
		if (request !== undefined) {
			self.showStatus = request
		} else {
			self.showStatus = !self.showStatus
		}
	},
	setShowComments(request?: boolean) {
		if (request !== undefined) {
			self.showComments = request
		} else {
			self.showComments = !self.showComments
		}
	},
	setShowHistory(request?: boolean) {
		if (request !== undefined) {
			self.showHistory = request
		} else {
			self.showHistory = !self.showHistory
		}
	},
	setCommentClosingDialogOpenStatus(request?: boolean) {
		if (request !== undefined) {
			self.commentClosingDialogOpenStatus = request
		} else {
			self.commentClosingDialogOpenStatus =
				!self.commentClosingDialogOpenStatus
		}
	},
	setFileUploadFrom(from: FileUploadFromType | undefined) {
		self.fileUploadFrom = from
	},
})

export default FileMenuUiHelperActions
