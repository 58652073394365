import { types } from "mobx-state-tree"
import { ClickPoint } from "../../../../../common-models/types/dialog.props"

const navOpenWidth = 320
const navClosedWidth = 48

const ViewsPolicyDetail = (self: any) => ({
	viewSelectedPolicy() {
		const target = self.policyDetail.get(self.selectedPolicy)
		return target.conditions
	},
	get viewMandatoryConditions() {
		const target = self.policyDetail.get(self.selectedPolicy)
		let mandatories: any[] = []
		if (target) {
			mandatories = target.conditions.filter(
				(item: any) => item.isMandatory
			)
		}
		return mandatories
	},
	get viewOptionalConditions() {
		const target = self.policyDetail.get(self.selectedPolicy)
		let optionals: any[] = []
		if (target) {
			optionals = target.conditions.filter(
				(item: any) => !item.isMandatory
			)
		}
		return optionals
	},
	get selectedPolicyInfo() {
		const target = self.flatList.find(
			(item: any) => item.id === self.selectedPolicy
		)
		return target
	},
	isSelected(id: string) {
		if (self.selectedPolicy === id) {
			return true
		} else {
			return false
		}
	},
	viewArchivePolicies() {
		return self.flatList.filter(
			(item: any) =>
				item.type === "policy" && item.id !== self.selectedPolicy
		)
	},
})

const UiActions = (self: any) => ({
	setMandatory(id: string, request: boolean) {
		const targetPolicy = self.policyDetail.get(self.selectedPolicy)
		const targetCondition = targetPolicy.conditions.find(
			(item: any) => item.id === id
		)
		targetCondition.isMandatory = request
	},
})

const OrgArchivePoliciesUiHelper = types
	.model({
		openCreateDialog: false,
		openEditDialog: false,
		openMoveEngTypeDialog: false,
		openArchConditionHelpDialog: false,

		canDrag: false,
		navOpen: true,
		navWidth: 320,
		selectedPolicy: "",
		selectedEngType: "",
		selectedPolicyForEngType: "",

		targetCondition: "",
		clickPoint: ClickPoint,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: typeof self.clickPoint) {
			self.clickPoint = clickPoint
		},
		setTargetCondition(condition: string) {
			self.targetCondition = condition
		},
		setOpenCreateDialog(request?: boolean) {
			if (request !== undefined) {
				self.openCreateDialog = request
			} else {
				self.openCreateDialog = !self.openCreateDialog
			}
		},
		setOpenEditDialog(request?: boolean) {
			if (request !== undefined) {
				self.openEditDialog = request
			} else {
				self.openEditDialog = !self.openEditDialog
			}
		},
		setOpenMoveEngTypeDialog(request?: boolean) {
			if (request !== undefined) {
				self.openMoveEngTypeDialog = request
			} else {
				self.openMoveEngTypeDialog = !self.openMoveEngTypeDialog
			}
		},
		setOpenArchConditionHelpDialog(request?: boolean) {
			if (request !== undefined) {
				self.openArchConditionHelpDialog = request
			} else {
				self.openArchConditionHelpDialog = !self.openArchConditionHelpDialog
			}
		},

		setCanDrag(request?: boolean) {
			if (request !== undefined) {
				self.canDrag = request
			} else {
				self.canDrag = !self.canDrag
			}
		},
		setNavOpen(request?: boolean) {
			if (request !== undefined) {
				self.navOpen = request
			} else {
				self.navOpen = !self.navOpen
			}
			if (self.navOpen) {
				self.navWidth = navOpenWidth
			} else {
				self.navWidth = navClosedWidth
			}
		},
		updateNavWidth(value: number) {
			self.navWidth = self.navWidth + value
		},
		setSelectedPolicy(id: string) {
			self.selectedPolicy = id
		},
		setSelectedPolicyForEngType(id: string) {
			self.selectedPolicyForEngType = id
		},
		setSelectedEngType(id: string) {
			self.selectedEngType = id
		},
	}))
	.views((self) => ({
		get viewNavWidth() {
			return self.navWidth
		},
	}))
	.views(ViewsPolicyDetail)
	.actions(UiActions)

export default OrgArchivePoliciesUiHelper
