import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	ActionStatus,
	FlatItemType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	DLSpinner,
	DLInputField,
	DLIconButton,
	DLTreeListByTreeData,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import SomethingWrong from "../../../../../components/basic-elements/exceptions/SomethingWrong"
import FileFolderRow from "../../../../../components/combined-elements/tree-nav/DLTreeNavParentRow2"
import {
	mdiMagnify,
	mdiChevronLeft,
	mdiChevronRight,
	mdiFile,
	mdiFileOutline,
} from "@mdi/js"
import { Icon } from "@mdi/react"
import { InputAdornment } from "@material-ui/core"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import DLTreeNav from "../../../../../components/combined-elements/tree-nav/DLTreeNav"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function SignOffHistoryTreeNav({
	permission,
	handleSelectTab,
	handleCollapseList,
	actionStatus,
	listWidth,
	listCollapsed,
	currentTab,
	partialStore,
}: {
	permission: PermissionAsObjectProps
	handleSelectTab: any
	handleCollapseList: any
	actionStatus: ActionStatus
	listWidth: any
	listCollapsed: any
	currentTab: any
	partialStore: any
}) {
	const store = useRootStore()

	// for tree
	// const handleSyncExpanded = (folderId: string, expanded: boolean) => {
	// 	partialStore.toggleExpandedFolder(
	// 		folderId,
	// 		partialStore.selectedTab,
	// 		expanded
	// 	)
	// }
	//

	const listHeight = store.ui.contentsAreaHeight - 70

	return (
		<StyledSignOffHistoryTreeNav
			eleWidth={listWidth}
			eleHeight={listHeight}
		>
			<div className="file-menu-tabs FR AC">
				<div
					className={`tab FR AC JC ${
						currentTab === DLProjSubMenus.wp && "selected"
					} ${
						currentTab !== DLProjSubMenus.wp &&
						listCollapsed &&
						"hide"
					}`}
					data-testid="workpaper-tab"
					onClick={() => handleSelectTab(DLProjSubMenus.wp)}
				>
					{listCollapsed ? "WP" : "Workpapers"}
				</div>
				<div
					className={`tab FR AC JC ${
						currentTab === DLProjSubMenus.pf && "selected"
					} ${
						currentTab !== DLProjSubMenus.pf &&
						listCollapsed &&
						"hide"
					}`}
					data-testid="permanent-file-tab"
					onClick={() => handleSelectTab(DLProjSubMenus.pf)}
				>
					{listCollapsed ? "PF" : "Permanent Files"}
				</div>
			</div>
			<div className="tree-nav-area">
				{actionStatus === "LOADING" && (
					<div
						style={{ width: "100%", marginTop: "3rem" }}
						className="FR AC JC"
					>
						{" "}
						<DLSpinner />
					</div>
				)}
				{actionStatus === "FAIL" && !listCollapsed && (
					<SomethingWrong />
				)}
				{actionStatus === "SUCCESS" && (
					<div className="tree-nav">
						<div
							className={`FR AC ${listCollapsed ? "JC" : "JSB"}`}
						>
							{!listCollapsed && (
								<DLInputField
									eleClassName="search-field"
									eleTestId="search-field-on-tree-nav"
									eleType="search"
									elePlaceholder="Search"
									eleValue={partialStore.treeNavQuery}
									eleHandleChange={(e: any) =>
										partialStore.setTreeNavQuery(
											e.target.value
										)
									}
									eleStartAdornment={
										<InputAdornment position="start">
											<Icon
												path={mdiMagnify}
												size={0.8}
											/>
										</InputAdornment>
									}
								/>
							)}

							<DLIconButton
								eleTestId="onoff-tree-nav"
								clickHandler={handleCollapseList}
							>
								{listCollapsed ? (
									<Icon path={mdiChevronRight} size={0.8} />
								) : (
									<Icon path={mdiChevronLeft} size={0.8} />
								)}
							</DLIconButton>
						</div>

						<div
							className="folder-and-file-tree"
							// style={{ height: listHeight - 48 }}
						>
							<DLTreeListByTreeData
								treeData={partialStore.viewFlatListAsTreeWithQuery(
									currentTab
								)}
								eleRenderNode={FolderAndFileRowRenderer}
								canDrag={false}
								eleHeight={listHeight - 48}
								indentWidth={16}
								rowHeight="medium"
								sortBy="title"
							/>
						</div>
					</div>
				)}
			</div>
		</StyledSignOffHistoryTreeNav>
	)
})

const FolderAndFileRowRenderer = ({ node }: { node: any }) => {
	return (
		<>
			{node.type === FlatItemType.folder && (
				<FolderRowController node={node} />
			)}
			{node.type === FlatItemType.file && (
				<FileRowController node={node} />
			)}
		</>
	)
}

const FileRowController = observer(({ node }: { node: any }) => {
	const projStore = useProjStore()
	const handleFileExpand = (id: string) => {
		projStore.signOff.setSelectedFile(id)
	}

	const selectedFile = projStore.signOff.selectedFile
	return (
		<div
			className="FR AC"
			style={{ color: selectedFile === node.id ? "blue" : "black" }}
			onClick={() => handleFileExpand(node.id)}
			data-testid="file-row-for-signoff-history"
		>
			<Icon path={mdiFileOutline} size={0.8} /> {node.title} (
			{node.format})
		</div>
	)
})

const FolderRowController = observer(({ node }: { node: any }) => {
	const projStore = useProjStore()
	const handleExpand = () => {
		projStore.signOff.toggleExpandedFolder(
			node.id,
			projStore.signOff.selectedTab,
			!node.expanded
		)
		projStore.signOff.setSelectedFile("")
	}

	const listWidth = projStore.signOff.listWidth
	return (
		<div style={{ width: listWidth }}>
			<FileFolderRow
				expanded={node.expanded}
				handleExpand={handleExpand}
				canDrag={false}
				title={node.title}
				selected={false} // TODO: update is required
			/>
		</div>
	)
})

const StyledSignOffHistoryTreeNav = styled.div<{
	eleWidth: number
	eleHeight: number
}>`
	width: ${(props) => props.eleWidth}px;
	height: ${(props) => props.eleHeight}px;
	transition: 0.4s;
	.file-menu-tabs {
		width: 100%;
		.tab {
			width: 100%;
			height: ${(props) => props.theme.shared.baseUnit};
			opacity: 0.5;
			transition: font-weight 0.2s;
			background-color: ${(props) => props.theme.background};
			border-radius: 4px 4px 0 0;
			color: black;
			font-weight: 600;
			&.selected {
				font-weight: 700;
				opacity: 1;
				color: ${(props) => props.theme.emphasis};
				background-color: ${(props) => props.theme.secondaryDeep};
			}
			&.hide {
				display: none;
			}
		}
	}
	.tree-nav-area {
		background-color: ${(props) => props.theme.secondaryDeep};
		width: 100%;
		height: ${(props) => props.eleHeight}px;
		padding: 1rem;
		.tree-nav {
			.search-field {
				padding: 0 1rem;
				margin-bottom: 1rem;
			}
			.folder-and-file-tree {
				overflow: hidden;
				overflow-y: scroll;
			}
		}
	}
`
