import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { DLTooltip } from "@datalobby/components"
import ValueWithTooltipRatio from "./ValueWithTooltipRatio"

type TableDataProps = {
	title: string
	total: number
	archived: number
	normal: number
	late: number
	unarchived: number
}

export const CommonTableForCard = observer(
	({
		overallTotal,
		tableData,
	}: {
		overallTotal: number
		tableData: TableDataProps[]
	}) => {
		return (
			<StyledTable>
				<table>
					<thead>
						<tr className="FR header">
							<th className="title">Name</th>
							<th className="total">Total</th>
							<th className="archived">Archived</th>
							<th className="normal">Normal</th>
							<DLTooltip
								title="Unarchived"
								elePlacement="top"
								arrow
							>
								<th className="unarchived">!!</th>
							</DLTooltip>
							<DLTooltip
								title={
									"Total Late of Normal and Archived"
									// "Total Late for "
									// <div style={{ textAlign: "center" }}>
									// 	<div>Total Late</div>
									// 	<div>
									// 		(including both Normal and Archived)
									// 	</div>
									// </div>
								}
								elePlacement="top"
								arrow
							>
								<th className="late">!</th>
							</DLTooltip>
						</tr>
					</thead>
					<tbody>
						{tableData.slice(0, 3).map((item: any) => {
							const testId = item.title
								.toLowerCase()
								.replace(/ /g, "-")
							const {
								total,
								title,
								archived,
								normal,
								unarchived,
								late,
							} = item
							return (
								<tr className="FR body" key={title}>
									<DLTooltip
										title={title}
										elePlacement="top"
										arrow
									>
										<td
											className="title"
											data-testid={"title-" + testId}
										>
											{title}
										</td>
									</DLTooltip>
									<td
										className="total"
										data-testid={"total-" + testId}
									>
										<ValueWithTooltipRatio
											value={total}
											total={overallTotal}
										/>
									</td>
									<td
										className="archived"
										data-testid={"archived-" + testId}
									>
										<ValueWithTooltipRatio
											value={archived}
											total={total}
										/>
									</td>
									<td
										className="normal"
										data-testid={"normal-" + testId}
									>
										<ValueWithTooltipRatio
											value={normal}
											total={total}
										/>
									</td>
									<td
										className="unarchived"
										data-testid={"unarchived-" + testId}
									>
										<ValueWithTooltipRatio
											value={unarchived}
											total={total}
										/>
									</td>
									<td
										className="late"
										data-testid={"late-" + testId}
									>
										<ValueWithTooltipRatio
											value={late}
											total={total}
										/>
									</td>
								</tr>
							)
						})}
					</tbody>
				</table>
			</StyledTable>
		)
	}
)

const StyledTable = styled.div`
	width: 100%;
	table {
		margin-top: 1rem;
		width: 100%;
	}
	th,
	td {
		padding: 4px 8px;
	}
	th {
		border-bottom: 1px solid lightgray;
		text-align: center;
	}
	.header {
		font-weight: bold;
	}
	.title {
		width: 80px;
		text-align: left;
	}
	td.title {
		overflow: hidden;
		white-space: nowrap;
	}
	/* 
		total, archived, normal width = 
		calc(100% - titleWidth(80px) - lateWidth(40px) - unarchivedWidth(40px))
	*/
	.total {
		width: calc((100% - 160px) / 3);
		text-align: center;
	}
	.archived {
		width: calc((100% - 160px) / 3);
		text-align: center;
		color: ${(props) => props.theme.emphasis};
	}
	.normal {
		width: calc((100% - 160px) / 3);
		text-align: center;
	}
	.late {
		width: 40px;
		text-align: center;
		color: ${(props) => props.theme.shared.messageOrange};
	}
	.unarchived {
		width: 40px;
		text-align: center;
		color: ${(props) => props.theme.shared.messageRed};
	}
`
