import { flow } from "mobx-state-tree"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { OrgGroupApiCallProps } from "../../../../../../service-modules/group-module/org-group-shared-apis.calling-props"

const ReadOrgGroupList = (
	self: any,
	readGroups: OrgGroupApiCallProps["apiReadGroups"]
) => ({
	readOrgGroupList: flow(function* (userId: string) {
		const actionName = "readOrgGroupList"
		// 1.
		if (userId === undefined) {
			alert(`(${actionName}) param is undefined: userId: ${userId}`)
			return
		}
		// 2.
		const numberId = idToNumber(userId, IdType.user)
		if (typeof numberId === "number") {
			try {
				const params = {
					UserID: numberId,
				}
				const response = yield readGroups(params)
				// ConsoleLog("readOrgGroupList response", response)
				return response
			} catch (error) {
				// ConsoleLog("readOrgGroupList error", error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default ReadOrgGroupList
