import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
// -------- view model actions
import GetNotes from "./view-model-actions/get-proj-notes"
import SaveNotes from "./view-model-actions/save-notes"
import { initialStore } from "./notes.provider"

const NotesViewModel = types
	.model({
		notes: types.union(types.string, types.null),
		noteId: types.number,
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions(GetNotes)
	.actions(SaveNotes)
	.actions((self) => ({
		setNotes(note: string) {
			self.notes = note
		},
		updateNotes(note: string) {
			self.notes = note
		},
		setNoteId(id: number) {
			self.noteId = id
		},
	}))
	.views((self) => ({
		viewNotes() {
			return self.notes
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default NotesViewModel
