import {
	getRequest,
	putRequest,
	postRequest,
} from "../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"

export async function read() {
	const response = await getRequest({
		url: "/GetCustomerManagement",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function create(payload: any) {
	const response = await postRequest({
		url: "/CreateCustomer",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function update(payload: any) {
	const response = await putRequest({
		url: "/EditCustomerDetails",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
