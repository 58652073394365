import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./financial-statements.provider"
import GetFinancialPresentation from "./view-model-actions/get-financial-presentation"
import GetTrialBalanceDetails from "./view-model-actions/get-trial-balance-details"
import { TBDetails, FSModel } from "./data-models/financial-statement.data-model"

const FinancialStatementsViewModel = types
	.model({
		tbDetails: types.array(TBDetails),
		fsList: types.array(FSModel),
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setTBDetails(details: any) {
			self.tbDetails = details
		},
		setFSList(list: any){
			self.fsList = list
		}
	}))
	.actions(GetFinancialPresentation)
	.actions(GetTrialBalanceDetails)
	.views((self) => ({
		viewTBDetails() {
			let tbDetails: any[] = [
				{ name: "Combined", value: 1 },
				{ name: "Consolidated", value: -1 },
			]
			self.tbDetails.map((item) => {
				tbDetails.push({
					name: item.tbName,
					value: item.tbId,
				})
			})
			return tbDetails
		},
		viewFSList() {
			console.log(self.fsList.filter((item) => item.parentName === "B1"),"fsd")
			return [...self.fsList]
		}
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default FinancialStatementsViewModel
