import React, { useState, useEffect } from "react"
import "date-fns"
import DateFnsUtils from "@date-io/date-fns"
import styled from "styled-components"
// import DatePicker from "react-datepicker"
// import "react-datepicker/dist/react-datepicker.css"
import {
	MuiPickersUtilsProvider,
	KeyboardDatePicker,
} from "@material-ui/pickers"
import { TextField } from "@material-ui/core"

interface DatePickerProps {
	eleTestId: string
	selected?: any
	disabled?: boolean
	eleName?: string
	onChange(value: any): any
	format?: string
	noMargin?: boolean
	eleLabel?: string
	fullWidth?: boolean
	disablePast?: boolean
}

export default function DLDatepicker({
	eleTestId = "dl-date-picker",
	selected = null,
	disabled = false,
	eleName = "",
	onChange,
	format = "MM/dd/yyyy",
	noMargin,
	eleLabel = "",
	fullWidth,
	disablePast = false,
}: DatePickerProps) {
	// const [selectedDate, setSelectedDate] = React.useState<
	//     Date | null | undefined
	// >(selected)
	// const handleDateChange = (date: Date | null) => {
	//     setSelectedDate(date)
	// }

	const [selectedDate, setSelectedDate] = React.useState<Date | null>(null)

	useEffect(() => {
		setSelectedDate(selected)
	}, [selected])

	const handleDateChange = (
		date: Date | null,
		value: string | null | undefined
	) => {
		setSelectedDate(date)
		onChange(value)
	}

	const TextFieldComponent = (props: any) => {
		return <TextField {...props} inputProps={{ readOnly: true }} />
	}

	return (
		<DLStyledDatePicker
			className={`dl-date-picker-container ${fullWidth && "fullWidth"}`}
			data-testid={eleTestId}
		>
			<MuiPickersUtilsProvider utils={DateFnsUtils}>
				<KeyboardDatePicker
					// disableToolbar
					className={`custom-style ${noMargin && "no-margin"}`}
					variant="inline"
					format={format}
					margin="normal"
					// id={eleTestId}
					label={eleLabel}
					name={eleName}
					value={selectedDate}
					onChange={handleDateChange}
					KeyboardButtonProps={{
						"aria-label": "change date",
						id: eleTestId + "-calendar-btn",
					}}
					autoOk
					disabled={disabled}
					disablePast={disablePast}
					TextFieldComponent={TextFieldComponent}
				/>
			</MuiPickersUtilsProvider>
		</DLStyledDatePicker>
	)
}

const DLStyledDatePicker = styled.div`
	&.dl-date-picker-container {
		&.fullWidth {
			width: 100%;
			.custom-style {
				width: 100%;
			}
		}
		.custom-style {
			&.no-margin {
				margin-top: 0;
				margin-bottom: 0;
			}
		}
		.MuiFormControl-marginNormal {
			margin: 0;
		}
	}
`
