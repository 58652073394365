import React, { useEffect } from "react"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	InputWithLabel,
	DLRadioGroup,
	DLComboBox,
	DLDatepicker,
	DLSystemMsg,
} from "../../../../components/basic-elements"
import styled from "styled-components"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import {
	AssignProjUserInputProps,
	ProjRoles,
	ProjAccess,
} from "../store/data-models/proj-setup-users.data-models"
import SelectedUserCard from "./SelectedUserCard"
import { findProjRoleInfo } from "../../../../temporary-data/project-side/find-proj-role"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import { getProjIdFromUrl } from "../../../../library/api-requests/request-get-others"

export default observer(function AddProjUserForm({
	inputs,
	handleInputs,
	handleUserSelect,
	handleDateInput,
	dateFormat,
}: {
	inputs: AssignProjUserInputProps
	handleInputs: any
	handleUserSelect: any
	handleDateInput: any
	dateFormat: string
}) {
	// default settings
	const projStore = useProjStore()
	const projId = getProjIdFromUrl()
	const { groupId, projectId } = projStore.projInfo.projInfo
	//
	if (groupId === "0") {
		// TODO: what is the meaning of 0??
		projStore.projInfo.getProjInfo(projectId)
	}

	useEffect(() => {
		projStore.projUsers.getGroupUsers(inputs.type, groupId)
	}, [inputs.type, projId, groupId])

	// ConsoleLog("inputs", inputs)

	const targetUser = projStore.projUsers.viewSelectedUserForAssign(
		inputs.type
	)
	const prevRoleId = projStore.projUsers.prevUserRole

	let prevRoleName = ""
	if (prevRoleId !== null) {
		prevRoleName = findProjRoleInfo(prevRoleId).name
	}
	if (prevRoleId !== null && inputs.roleId !== prevRoleId) {
		handleInputs({
			target: {
				name: "roleId",
				value: prevRoleId,
			},
		})
	}
	return (
		<StyledAddProjUserForm>
			{/* 1. select User type (internal / external)
				2. select Email (select an organization user)
				3. Position in the project
				4. is Read Only?
				5. Project Access Right
				6. RollForward Permission */}
			{/* ----- 1. User Type: Internal or External */}
			<div className="input-section FR">
				<InputWithLabel label="User Type">
					<DLRadioGroup
						selectedValue={inputs.type}
						items={[
							{ value: "internal", label: "Internal User" },
							{ value: "external", label: "External User" },
						]}
						groupName="type"
						eleOnChange={handleInputs}
						itemDirection="row"
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel label="User" required>
					<DLComboBox
						eleName="id"
						eleTestId="select-user-input"
						options={projStore.projUsers.viewGroupUsers(
							inputs.type
						)}
						onChange={handleUserSelect}
						filterOptions={createFilterOptions({
							// matchFrom: 'start',
							stringify: (option: any) =>
								option.name + option.aliasId + option.email,
						})}
						renderOption={(
							option: any,
							{ inputValue }: { inputValue: any }
						) => (
							<GroupUserRow
								userInfo={option}
								inputValue={inputValue}
							/>
						)}
						helperText={
							inputs.id === ""
								? `Select one user in the ${projStore.projInfo.projInfo.groupName} group`
								: undefined
						}
					/>
					{prevRoleId !== null && (
						<DLSystemMsg
							msg={
								<div>
									Previously Assigned Role:{" "}
									<span style={{ fontWeight: 600 }}>
										{prevRoleName}
									</span>
								</div>
							}
							type={MessageColorType.orange}
							eleTestId="reassign-restriction"
						/>
					)}
				</InputWithLabel>
			</div>
			{targetUser && (
				<div className="input-section FR">
					<InputWithLabel label="User Information">
						<SelectedUserCard
							name={targetUser.name}
							aliasId={targetUser.aliasId}
							email={targetUser.email}
							title={
								targetUser.orgTitle ? targetUser.orgTitle : ""
							}
						/>
					</InputWithLabel>
				</div>
			)}
			{inputs.type === "internal" ? (
				<>
					<div className="input-section FR">
						<InputWithLabel label="Role" required>
							<DLSingleSelect
								eleTestId="select-project-role"
								eleValue={
									prevRoleId === null
										? inputs.roleId
										: prevRoleId
								}
								eleDisabled={prevRoleId !== null}
								eleOnChange={handleInputs}
								eleName="roleId"
								eleFullWidth
								withLabel={false}
								// eleRequired
								placeholder="Select a role in the project"
								optionList={[
									{
										name: "Quality Control",
										value: ProjRoles.qc,
									},
									{
										name: "Engagement Partner",
										value: ProjRoles.ep,
									},
									{
										name: "Concurring Partner",
										value: ProjRoles.cp,
									},
									{ name: "Partner", value: ProjRoles.p },
									{
										name: "Director",
										value: ProjRoles.d,
									},
									{ name: "Manager", value: ProjRoles.m },
									{
										name: "Senior Staff",
										value: ProjRoles.ss,
									},
									{
										name: "Junior Staff",
										value: ProjRoles.js,
									},
								]}
							/>
							{prevRoleId !== null && (
								<DLSystemMsg
									msg={
										<div>
											<div>
												Must assign "Previously Assigned
												Role" to proceed
											</div>
											<div>
												(Role change can be performed
												after this process)
											</div>
										</div>
									}
									type={MessageColorType.orange}
									eleTestId="reassign-restriction"
								/>
							)}
						</InputWithLabel>
					</div>
					{/* <div className="input-section FR">
						<InputWithLabel label="Set Readonly">
							<DLRadioGroup
								selectedValue={inputs.readonly}
								eleOnChange={handleInputs}
								items={[
									{ value: false, label: "No" },
									{ value: true, label: "Yes" },
								]}
								groupName="readonly"
								itemDirection="row"
							/>
						</InputWithLabel>
					</div> */}
					<div className="input-section FR">
						<InputWithLabel label="Access Type">
							<DLRadioGroup
								selectedValue={
									inputs.roleId === ProjRoles.ep
										? ProjAccess.archAdmin
										: inputs.accessTypeId
								}
								disabled={inputs.roleId === ProjRoles.ep}
								// disableOtherOptions={
								// 	inputs.roleId === ProjRoles.ep ||
								// 	inputs.roleId === ProjRoles.qc
								// }
								eleOnChange={handleInputs}
								items={[
									{
										value: ProjAccess.archAdmin,
										label: "Project Acrhive Admin",
									},
									{
										value: ProjAccess.projAdmin, // TODO: if user select QC role, select PA automatically
										label: "Project Admin",
									},
									// {
									// 	value: ProjAccess.projAdmin,
									// 	label: "Quality Control",
									// },
									{
										value: ProjAccess.user,
										label: "Project User",
										disabled:
											inputs.roleId === ProjRoles.qc,
									},
									{
										value: ProjAccess.readonly,
										label: "Readonly",
										disabled:
											inputs.roleId === ProjRoles.qc,
									},
								]}
								groupName="accessTypeId"
								itemDirection="column"
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Roll Forward">
							<DLRadioGroup
								selectedValue={inputs.canRollForward}
								disableOtherOptions={
									inputs.accessTypeId ===
										ProjAccess.readonly ||
									inputs.roleId === ProjRoles.ep
								}
								eleOnChange={handleInputs}
								items={[
									{ value: false, label: "Reject" },
									{ value: true, label: "Allow" },
								]}
								groupName="canRollForward"
								itemDirection="row"
								formHelpText={
									inputs.accessTypeId === ProjAccess.readonly
										? "Readonly user cannot use roll-forward"
										: undefined
								}
							/>
						</InputWithLabel>
					</div>
				</>
			) : (
				<>
					<div className="input-section FR">
						<InputWithLabel label="External User Right">
							<DLRadioGroup
								selectedValue={inputs.extUserRight}
								eleOnChange={handleInputs}
								items={[
									{
										value: ProjAccess.readonly,
										label: "Read Only",
									},
									{
										value: ProjAccess.pbcUpload,
										label: "PBC Upload",
									},
								]}
								groupName="extUserRight"
								itemDirection="row"
							/>
						</InputWithLabel>
					</div>
					<div className="input-section FR">
						<InputWithLabel label="Project Access Expired Date">
							<DLDatepicker
								eleTestId="proj-exp-date"
								eleName="expDate"
								// selected={new Date(expectedReportDate)}
								selected={
									inputs.expDate === ""
										? null
										: inputs.expDate
								}
								// eleLabel="Expected Report Date"
								onChange={(date: any) =>
									handleDateInput("expDate", date)
								}
								format={dateFormat}
							/>
						</InputWithLabel>
					</div>
				</>
			)}
		</StyledAddProjUserForm>
	)
})

// NOTE: match with AccessMgmtDialogForm
const GroupUserRow = ({
	userInfo,
	inputValue,
}: {
	userInfo: any
	inputValue: any
}) => {
	const matches_name = match(userInfo.name, inputValue)
	const parts_name = parse(userInfo.name, matches_name)
	const matches_aliasId = match(userInfo.aliasId, inputValue)
	const parts_aliasId = parse(userInfo.aliasId, matches_aliasId)
	const matches_email = match(userInfo.email, inputValue)
	const parts_email = parse(userInfo.email, matches_email)

	const ModifiedOption = ({ option }: { option: any }) => {
		return (
			<>
				{option.map((part: any, i: number) => (
					<span
						key={i}
						style={{
							fontWeight: part.highlight ? 700 : 400,
						}}
					>
						{part.text}
					</span>
				))}
			</>
		)
	}

	return (
		<div className="FR AC">
			<div
				style={{
					marginRight: "0.4rem",
					whiteSpace: "nowrap",
				}}
			>
				<ModifiedOption option={parts_name} />
			</div>
			<div
				style={{
					marginRight: "0.6rem",
					opacity: 0.8,
					whiteSpace: "nowrap",
				}}
			>
				(<ModifiedOption option={parts_aliasId} />)
			</div>
			<div
				style={{
					opacity: 0.8,
					fontSize: "0.8rem",
					whiteSpace: "nowrap",
				}}
			>
				<ModifiedOption option={parts_email} />
			</div>
		</div>
	)
}

const StyledAddProjUserForm = styled.div`
	padding-left: 0.5rem;
	height: 500px;
	min-height: 50vh;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
