import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"

const GetClientsByGroup = (self: any) => ({
	getClientsByGroup(groupId: string) {
		// 0.
		const actionName = "getClientsByGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.

		self.readClientsByGroup(groupId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const fetchedClients = response.data.ClientMasterList
					let reOrganizedClients: any[] = []
					fetchedClients.map((client: any) => {
						reOrganizedClients.push({
							clientId: idToString(
								client.ClientMasterID,
								IdType.client
							),
							clientAliasId: client.ClientId,
							clientName: client.ClientName,
						})
					})
					self.setClientList(reOrganizedClients)

					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get SOMETHING",
						open: false,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetClientsByGroup
