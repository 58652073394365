import React from "react"
import { observer } from "mobx-react-lite"
import { useLobbyStore } from "../../../../stores/lobby-store/lobby-store.provider"
import { DLSnackbar } from "../../../../components/basic-elements"

const ResetPasswordSnackbar = observer(() => {
	const lobbyStore = useLobbyStore()

	return (
		<DLSnackbar
			type={lobbyStore.resetPassword.responseSnackbar.type}
			open={lobbyStore.resetPassword.responseSnackbar.open}
			setOpen={lobbyStore.resetPassword.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={lobbyStore.resetPassword.responseSnackbar.message}
			autoHideDuration={
				lobbyStore.resetPassword.responseSnackbar.autoHideDuration
			}
			autoHide={lobbyStore.resetPassword.responseSnackbar.autoHide}
		/>
	)
})

export default ResetPasswordSnackbar
