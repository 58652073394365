import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import OrgSearch from "./view-model-actions/org-search"
import ProjectSearch from "./view-model-actions/get-project-search"
// ---------- common actions
import {
	CommonViewModelActions,
	ViewResponseHelper,
} from "../../../../common-models/common-view-model-actions"
import { initialStore } from "./org-search.provider"
import { OrgSearchProjectDetail } from "./data-models/org-search.data-models"

const OrgSearchViewModel = types
	.model({
		projectList: types.array(OrgSearchProjectDetail),
		searchType: "client",
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	// common parts
	.actions((self) => ({
		setSearchType(type: string) {
			self.searchType = type
		},
		resetList() {
			self.projectList.length = 0
		},
		pushItemToProjects(item: any) {
			self.projectList.push(item)
		},
	}))
	.actions(OrgSearch)
	.actions(ProjectSearch)
	.views((self) => ({
		viewProjectResults() {
			return self.projectList
		},
	}))
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupSearch")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)
// AGER(Add, Get, Edit, Remove) Actions
// Add
// Get
// Edit
// Remove

export default OrgSearchViewModel
