import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../common-models/common-model-actions"
// separated model actions
import ReadRecentProjList from "./model-actions/read-recent-proj-list"
import { OrgRecentProjectApiProps } from "./recent-proj-list.apis"

const RecentProjModel = ({ apiRead }: OrgRecentProjectApiProps) =>
	types
		.model({})
		// CRUD actions
		.actions((self, read = apiRead) => ReadRecentProjList(self, read))
		// Common model actions
		.actions(CommonModelActions)

export { RecentProjModel }
