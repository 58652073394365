import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { PageContainer } from "../../../components/app-frame-elements"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLSpinner, DLSingleSelect } from "../../../components/basic-elements"
import ProjectResults from "./sub-components/ProjectResults"
import { DLSearchBoxBasic } from "../../../components/basic-elements/search/DLSearchBox"
import ProjectTable from "./sub-components/ProjectTable"

export default observer(function OrgSearch({
	partialStore,
	actionStatus,
	contentsHeight,
}: {
	partialStore: any
	actionStatus: ActionStatus
	contentsHeight: any
}) {
	const [type, setType] = useState<"group" | "cabinet" | "client">(
		partialStore.searchType
	)
	const [searchText, setSearchText] = useState("")
	const handleSelect = (type: "group" | "cabinet" | "client") => {
		setType(type)
	}

	const searchElement = (e: any) => {
		setSearchText(e.target.value)
	}

	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			searchQuery()
		}
	}

	const searchQuery = () => {
		if (searchText === "") {
			alert("Please enter search text")
		} else {
			partialStore.orgSearch({ type, query: searchText })
		}
	}
	return (
		<PageContainer
			hasToolbar={false}
			pageTools={<div />}
			fullWidth
			fixedHeight
		>
			<StyledSearchResult>
				<div className="FR search-bar">
					<DLSingleSelect
						eleValue={type}
						eleOnChange={(e) => handleSelect(e.target.value)}
						optionList={[
							// { name: "Group", value: "group" },
							// { name: "Cabinet", value: "cabinet" },
							{ name: "Client", value: "client" },
						]}
						eleTestId="search-type"
						eleClassName="search-type"
					/>
					<DLSearchBoxBasic
						id="searchbox-in-org-search"
						eleTestId="search_text_box"
						placeholder="Search..."
						iconPlacement="end"
						onChange={searchElement}
						eleValue={searchText}
						onKeyDown={handleKeyDown}
						clickHandler={searchQuery}
						variant="outlined"
					/>
				</div>
				<hr />
				{actionStatus === "LOADING" && (
					<div
						className="FR AC JC"
						style={{ width: "100%", height: contentsHeight }}
					>
						<DLSpinner />
					</div>
				)}
				{actionStatus === "SUCCESS" && (
					// <ProjectResults partialStore={partialStore} />
					<>
						<div>
							{partialStore.viewProjectResults().length} Results
							Found
						</div>
						<ProjectTable
							partialStore={partialStore}
							contentsHeight={contentsHeight}
						/>
					</>
				)}
			</StyledSearchResult>
		</PageContainer>
	)
})

const StyledSearchResult = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.select-element {
		margin-top: 8px;
	}
	.search-bar {
		justify-content: center;
	}
`
