import {
	AsyncItemType,
	AsyncItemStatus,
} from "../store/data-models/notifications.data-model"

export const items = [
	// ready & download
	{
		id: "id1",
		targetId: null, // because archived project and replica project are not good
		type: AsyncItemType.archZip,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "Q2 2021 Alpha - External Audit",
		expiration: "2021-07-27T20:24:10.384Z",
		expired: false,
	},
	{
		id: "id2",
		targetId: "proj123", // parent project ID
		parentName: "Q1 2021 French Roastery - Audit",
		type: AsyncItemType.fileFolder,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "Section 2000 - 위험평가 Planning",
		expiration: "2021-07-27T15:12:34.384Z",
		expired: false,
	},
	{
		id: "id3",
		targetId: "proj2727", // parent project ID
		parentName: "FY 2021 French Roastery - Audit",
		type: AsyncItemType.exportAll,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "All Workpapers",
		expiration: "2021-07-26T03:48:32.384Z",
		expired: true,
	},
	/**
	 *
	 *
	 *
	 */
	// ready & move to the project
	{
		id: "id4",
		targetId: "proj555",
		type: AsyncItemType.replica,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "Jun 2021 RingNet - Audit",
	},
	{
		id: "id5",
		targetId: "proj333", // because archived project and replica project are not good
		type: AsyncItemType.unarchived,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "Q1 2021 GaGao - Audit",
	},
	{
		id: "id6",
		targetId: "proj444", // because archived project and replica project are not good
		type: AsyncItemType.rollForward,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "Q3 2021 CuCumber - Audit",
	},
	{
		id: "id7",
		targetId: "proj32323", // because archived project and replica project are not good
		type: AsyncItemType.newProjByTemplate,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "Q3 2021 ZigZag - Audit",
	},
	{
		id: "id8",
		targetId: "template7878", // because archived project and replica project are not good
		type: AsyncItemType.duplicateTemplate,
		status: AsyncItemStatus.ready,
		url: "/",
		title: "K-IFRS 2021 Template (copy)",
	},
	/**
	 *
	 *
	 *
	 */
	{
		id: "id9",
		targetId: null, // because archived project and replica project are not good
		type: AsyncItemType.archZip,
		status: AsyncItemStatus.inprogress,
		url: "/",
		title: "May 2021 RingNet - External Audit",
	},

	{
		id: "id10",
		targetId: null, // because archived project and replica project are not good
		type: AsyncItemType.archZip,
		status: AsyncItemStatus.fail,
		url: "/",
		title: "FY 2020 French Roastery - Audit",
	},
	{
		id: "id11",
		targetId: "proj456", // parent project ID
		parentName: "Feb 2019 CrazyZoo - Private Audit",
		type: AsyncItemType.fileFolder,
		status: AsyncItemStatus.downloaded,
		url: "/",
		title: "Section 3000 - 위험에 대한 대응 Planning",
		expiration: "2021-07-25T11:35:54.384Z",
		expired: true,
	},
	{
		id: "id12",
		targetId: "proj678", // parent project ID
		parentName: "Feb 2019 CrazyZoo - Private Audit",
		type: AsyncItemType.fileFolder,
		status: AsyncItemStatus.downloaded,
		url: "/",
		title: "Section 4000 - Blah Blah Blah",
		expiration: "2021-07-24T23:40:14.384Z",
		expired: true,
	},
	{
		id: "id13",
		targetId: null, // because archived project and replica project are not good
		type: AsyncItemType.archZip,
		status: AsyncItemStatus.downloaded,
		url: "/",
		title: "Dec 2020 CrazyZoo - Private Audit",
		expiration: "2021-07-28T20:18:07.384Z",
		expired: false,
	},
	{
		id: "id14",
		targetId: "proj987", // target project ID
		type: AsyncItemType.preprocess,
		status: AsyncItemStatus.pending,
		url: "/",
		title: "HY1 2021 Chaos Machine - External Audit",
	},
	{
		id: "id15",
		targetId: "proj989", // replica project ID
		type: AsyncItemType.replica,
		status: AsyncItemStatus.pending,
		url: "/",
		title: "Q1 2021 Ostem Lighting - External Audit",
	},
	{
		id: "id16",
		targetId: "proj1432", // unarchived project ID
		type: AsyncItemType.unarchived,
		status: AsyncItemStatus.pending,
		url: "/",
		title: "FY 2020 SeoHeung - External Audit",
	},
	{
		id: "id17",
		targetId: "proj2345", // roll-forwarded project ID
		type: AsyncItemType.rollForward,
		status: AsyncItemStatus.pending,
		url: "/",
		title: "FY 2019 CooCon - External Audit",
	},
	{
		id: "id18",
		targetId: "template13", // duplicated template ID
		type: AsyncItemType.duplicateTemplate,
		status: AsyncItemStatus.pending,
		url: "/",
		title: "K-GAAP Normal Company Accounting Standard 2020.12.09",
	},
]
