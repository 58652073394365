import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLSingleSelect } from "../../../basic-elements"
import { DLSearchBoxBasic } from "../../../basic-elements/search/DLSearchBox"
import styled from "styled-components"
import { Redirect } from "react-router-dom"
import { DLI18nProps } from "../../../../common-models/types/common-props"

export default observer(function OrgSearchbar({ i18n }: { i18n: DLI18nProps }) {
	const orgStore = useOrgStore()
	const [searchText, setSearchText] = useState("")
	const [type, setType] = useState<"group" | "cabinet" | "client">("client")
	const handleSelect = (type: "group" | "cabinet" | "client") => {
		setType(type)
	}
	const searchElement = (e: any) => {
		setSearchText(e.target.value)
	}

	const handleKeyDown = (e: any) => {
		if (e.key === "Enter") {
			searchQuery()
		}
	}

	const searchQuery = () => {
		if (searchText === "") {
			alert("Please enter search text")
		} else {
			orgStore.search.orgSearch({ type, query: searchText })
		}
	}

	return (
		<StyledSearch className="org-searchbar FR">
			<DLSingleSelect
				eleValue={type}
				eleOnChange={(e) => handleSelect(e.target.value)}
				optionList={[
					// { name: "Group", value: "group" },
					// { name: "Cabinet", value: "cabinet" },
					{ name: i18n.twClient || "Client", value: "client" },
				]}
				eleTestId="search-type"
				eleClassName="search-type"
			/>
			<DLSearchBoxBasic
				id="searchbox-in-org-header"
				eleTestId="org-searchbar-container"
				placeholder={
					i18n.tsSearchProjectByClientName ||
					"Search Project by Client name"
				}
				iconPlacement="end"
				onChange={searchElement}
				eleValue={searchText}
				onKeyDown={handleKeyDown}
				clickHandler={searchQuery}
			/>
			{orgStore.search.getActionStatus("getOrgSearch") === "LOADING" && (
				<Redirect to="/organization/search" />
			)}
		</StyledSearch>
	)
})

const StyledSearch = styled.div`
	margin-right: 1rem;

	.select-element {
		margin-top: 8px;
	}
	.form-control {
		min-width: 5rem;
	}
`
