import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { Redirect, useLocation } from "react-router-dom"
import { DLSpinner, ConsoleLog } from "../../../../components/basic-elements"
import {
	ActionStatus,
	SupportedLanguage,
} from "../../../../common-models/enumerations/common-enums"
import StyledCheckin from "../../../../components/app-frame-elements/checkin-progress-circles.style"

/**
 * NOTE: Approach cases
 * 1. from the login: Select a organization on the lobby sector
 * 2. from the lobbyRoute: prerequisites exist but checkin status is not success
 */
const LobbyCheckin = observer(() => {
	const store = useRootStore()
	/**
	 *
	 * ? Lobby Checkin Purpose
	 * --- globally required --> lobby-checkin.ts
	 * 1. language
	 * 2. country
	 * 3. lobbyUserId (not exist yet --> use email instead of the ID)
	 * 4. lobbyUserName
	 * 5. lobbyDefaultEmail
	 * 6. orgUserIds: to get user Information (temporary solution)
	 * 7. getGlobalI18n (to minimize the request)
	 *
	 * --- TODO: lobby side required --> ...? --> lobby-checkin has related information
	 * 8. lobbyPermission
	 * 9. accessibleMenus
	 */

	// ----- required items
	const lobbyUserId = localStorage.getItem("lobbyUserId")
	const lobbyToken = localStorage.getItem("loginToken")
	// ----- Prerequisite
	const prerequisite = lobbyUserId !== null && lobbyToken !== null

	// ----- set redirecting destination
	const [goTo, setGoTo] = useState("/lobby/organization-list")

	// NOTE: this location.state.from be inherited from the Route
	let location: { state: { from: any } } = useLocation()

	const actionNames = {
		step1: "login",
		step2: "getLobbyDefault",
		step3: "setI18nStore",
		step4: "getCustomer",
		step5: "getCustomerMenus",
	}
	let step1_status = store.checkin.getActionStatus(actionNames.step1)
	let step2_status = store.checkin.getActionStatus(actionNames.step2)
	let step3_status = store.checkin.getActionStatus(actionNames.step3)
	let step4_status = store.checkin.getActionStatus(actionNames.step4)
	let step5_status = store.checkin.getActionStatus(actionNames.step5)

	/**
	 * ?????
	 */

	useEffect(() => {
		ConsoleLog(" __________ LobbyCheckin __________")
		let from = { pathname: "" }
		if (location.state) {
			from = location.state.from
			setGoTo(from.pathname)
		}

		if (prerequisite) {
			console.log("LobbyCheckin prerequisites are exist")
			if (store.checkin.lobbyCheckinStatus !== "SUCCESS") {
				// token exist but lobbyCheckinStatus is not success
				store.checkin.setLobbyCheckinStatus(ActionStatus.loading)
				// set login as success (because prerequisites exist)
				// if the prerequisite invalid, next step will validate it
				store.checkin.responses.putResponse({
					actionName: actionNames.step1,
					status: ActionStatus.success,
				})
				ConsoleLog("LOBBY CHECKIN STEP2: get default lobby information")
				store.checkin.getLobbyDefault() // if fail, lobbyCheckinStatus turn to fail in the viewModelAction
			} else {
				// User be redirected to the previous screen
				// (This case will be done in LobbyRoute)
			}
		} else {
			// (This case will be done in LobbyRoute)
			ConsoleLog(
				"One of the LobbyCheckin Prerequisite(lobbyUserId, lobbyToken) is null. LobbyCheckinStatus be turned to fail"
			)
			store.checkin.setLobbyCheckinStatus(ActionStatus.fail)
		}
	}, [])
	// ? ----- STEP 1: login
	/**
	 *
	 *
	 */
	// ? ----- STEP 2: getLobbyDefault
	/**
	 *
	 *
	 */
	// ? ----- STEP 3: setI18nStore
	useEffect(() => {
		if (step2_status === "SUCCESS") {
			ConsoleLog("LOBBY CHECKIN STEP3: set i18n related information")
			const languageCode =
				store.checkin.language === "en"
					? SupportedLanguage.us
					: SupportedLanguage.kr
			store.i18n.setLanguage(languageCode)
			store.i18n.setCountry(store.checkin.country)
			// NOTE: temporary pass
			store.checkin.responses.setResponse("setI18nStore", {
				actionName: "setI18nStore",
				status: ActionStatus.success,
			})
		}
	}, [step2_status === "SUCCESS"])

	/**
	 *
	 *
	 */
	// ? ----- STEP 4: getCustomer
	useEffect(() => {
		if (step3_status === "SUCCESS") {
			ConsoleLog("LOBBY CHECKIN STEP4: Get Customer Info From Admin App")
			store.checkin.getCustomer()
		}
	}, [step3_status === "SUCCESS"])

	/**
	 *
	 *
	 */
	// ? ----- STEP 5: getCustomerMenus
	useEffect(() => {
		if (step4_status === "SUCCESS") {
			ConsoleLog("LOBBY CHECKIN STEP5: Set Customer Menus")
			const postAction = () =>
				store.checkin.setLobbyCheckinStatus(ActionStatus.success)

			store.checkin.getAllMenus(postAction)
		}
	}, [step4_status === "SUCCESS"])

	/**
	 *
	 *
	 *
	 */

	// NOTE: store.checkin.lobbyCheckinStatus is different with getActionStatus("login") or getActionStatus("getLobbyDefault")
	let checkinStatus = store.checkin.lobbyCheckinStatus
	/**
	 *
	 *
	 *
	 */
	step1_status = store.checkin.responses.getActionStatus(actionNames.step1)
	step2_status = store.checkin.responses.getActionStatus(actionNames.step2)
	// step3 response not exist (dummy varialbles to understand process)
	step3_status = store.checkin.responses.getActionStatus(actionNames.step3)
	step4_status = store.checkin.responses.getActionStatus(actionNames.step4)
	step5_status = store.checkin.responses.getActionStatus(actionNames.step5)
	// NOTE: If there are no component which use step status, console log below should be active

	return (
		<StyledCheckin>
			{checkinStatus === "STANDBY" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{checkinStatus === "LOADING" && (
				<div
					className="progress-circles"
					data-testid="lobby-chekcin-progress-circles"
				>
					<div className="text-info">
						Lobby Checkin is in progress
					</div>
					<div className="steps">
						<div className={`progress-step ${step1_status}`} />
						<div className={`progress-step ${step2_status}`} />
						<div className={`progress-step ${step3_status}`} />
						<div className={`progress-step ${step4_status}`} />
						<div className={`progress-step ${step5_status}`} />
						<div className={`progress-step ${checkinStatus}`} />
					</div>
				</div>
			)}
			{checkinStatus === ActionStatus.success && <Redirect to={goTo} />}
			{checkinStatus === ActionStatus.fail && <Redirect to="/login" />}
		</StyledCheckin>
	)
})

export default LobbyCheckin
