// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const ResetArchivePolicy = (self: any) => ({
	resetArchivePolicy(policyId: string) {
		// 0.
		const actionName = "resetArchivePolicy"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * PARAMETER or PAYLOAD READY
		 */
		const policyNumberId = idToNumber(policyId, IdType.archivePolicy)
		self.requestResetArchivePolicy(policyNumberId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// set success case response
					self.resetPolicyToDefault(policyId)
					// self.getArchivePolicies()
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message || response.data.message,
						open: true,
						autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ResetArchivePolicy
