import { flow } from "mobx-state-tree"

const ModelMapLeadSheetToSubGroup = (self: any, mapLeadSheet: any) => ({
	modelMapLeadSheetToSubGroup: flow(function*(payload: {
		financialSubGroupId: number
		leadsheet: string
	}) {
		const actionName = "modelMapLeadSheetToSubGroup"
		try {
			const response = yield mapLeadSheet(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ModelMapLeadSheetToSubGroup
