import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import {
	PageContainer,
	PageHeader,
} from "../../../../components/app-frame-elements"
import {
	DLButton,
	DLSingleSelect,
	DLSpinner,
	InputWithLabel,
} from "../../../../components/basic-elements"
import styled from "styled-components"
import { PermissionAsObjectProps } from "../../../../common-models/permission"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import AdjustmentsTable from "./sub-components/AdjustmentsTable"
import AddAdjustmentDialog from "./sub-components/dialogs/AddAdjustmentsDialog"
import AdjustmentsTableMenus from "./sub-components/AdjustmentsContextMenu"
import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import CommonSnackbarComponent from "../../../../components/combined-elements/snackbar/CommonSnackbarComponent"

export default observer(function Adjustments({
	partialStore,
	contentsHeight,
	fetchingStatus,
	permission,
	menuId,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: ActionStatus
	permission: PermissionAsObjectProps
	menuId: DLProjSubMenus
}) {
	const projStore = useProjStore()
	const selectedEntity =
		partialStore.viewTBDetails().length > 0
			? partialStore.viewTBDetails()[0].value
			: 0
	const [entity, selectEntity] = useState(selectedEntity)

	const projId = projStore.checkin.projId

	const needRefresh = partialStore.needRefresh
	useEffect(() => {
		if (entity !== 0 && needRefresh) {
			partialStore.setSelectedEntity(entity)
			if (menuId === DLProjSubMenus.fs_aje) {
				partialStore.getAdjustments(projId, entity)
			} else {
				partialStore.getPassedAdjustments(projId, entity)
			}
		}
	}, [entity, needRefresh])

	const handleSelect = (value: number) => {
		selectEntity(value)
	}

	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			<StyledAdjustments>
				<PageHeader>
					<InputWithLabel label="Select Entity">
						<DLSingleSelect
							placeholder="Select Entity"
							eleValue={entity}
							eleOnChange={(e: any) =>
								handleSelect(e.target.value)
							}
							optionList={partialStore.viewTBDetails()}
							eleTestId="select-entity"
							eleClassName="select-entity"
						/>
					</InputWithLabel>
					{permission.create && (
						<DLButton
							variant="text"
							eleTestId="create-aje-btn"
							startIcon={<Icon path={mdiPlus} size={1} />}
							clickHandler={() =>
								partialStore.setAddAdjustmentDialogOpen(true)
							}
							color="primary"
							disabled={entity === 0}
						>
							Create Adjustment
						</DLButton>
					)}
				</PageHeader>
				{fetchingStatus === ActionStatus.loading && (
					<div
						className="FR AC JC"
						style={{
							width: "100%",
							height: contentsHeight - 22,
						}}
					>
						<DLSpinner />
					</div>
				)}
				{fetchingStatus === ActionStatus.success && (
					<AdjustmentsTable
						partialStore={partialStore}
						permission={permission}
						contentHeight={contentsHeight}
					/>
				)}
			</StyledAdjustments>
			{/* ----Snackbar---- */}
			<CommonSnackbarComponent
				i18n={{ x: "x" }}
				partialStore={partialStore}
			/>
			{/* ----TableMenu---- */}
			<AdjustmentsTableMenus
				partialStore={partialStore}
				permission={permission}
				menuId={menuId}
			/>
			{/* ----Dialog---- */}
			{partialStore.addAdjustmentDialogOpen && (
				<AddAdjustmentDialog
					partialStore={partialStore}
					menuId={menuId}
				/>
			)}
		</PageContainer>
	)
})

const StyledAdjustments = styled.div`
	.MuiInput-formControl {
		margin-top: 0px;
	}
`
