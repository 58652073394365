import { flow } from "mobx-state-tree"

const RequestAssignProjUser = (self: any, assign: any) => ({
	/**
	 * NOTE:
	 * basically, 'create' is used on the Model
	 * but 'createProjUser' seems weird, so take 'assign' instead of the 'create'
	 */
	requestAssignProjUser: flow(function* (payload: {
		AccessRightsID: number
		ClientId: number // projectId
		CreatedBy: number
		ExpirationDate: null | string
		RoleId: number
		UserId: number
		isRollForward: 1 | 0
		isVerified: boolean
	}) {
		const actionName = "requestAssignProjUser"
		// before API update, re-organize the ViewModel payload for existing API.
		// This step must be removed in the future. (ViewModel should provides valid data)

		try {
			const response = yield assign(payload)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default RequestAssignProjUser
