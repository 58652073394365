import React, { useState, memo } from "react"
import { observer } from "mobx-react-lite"
import DLBreadCrumbs2 from "../../basic-elements/breadcrumbs/DLBreadCrumbs2"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiChevronUp, mdiChevronDown } from "@mdi/js"
import { DLTooltip } from "@datalobby/components"
import {
	DLObjectFormat,
	ActionStatus,
} from "../../../common-models/enumerations/common-enums"
import {
	FolderIcon,
	DLIcon,
	DLIconName,
} from "../../basic-elements/icons/common-icons"
import { ConsoleLog, DLIconButton, DLSpinner } from "../../basic-elements"
import { CreateProjectPathProps } from "../create-project/create-project.data-props"

export type BreadCrumbsOptionProps = {
	id: string
	name: string
	objFormat: DLObjectFormat
	[x: string]: any
}

/**
 * WARNING: This component is not only for create-project
 */
const BreadCrumbsAndOptions = observer(({
	path,
	setPath,
	getLocationDetail,
	postActionForSelect,
	options,
	optionLoadingStatus,
	showLocationDetail,
}: // setShowLocationDetail,
{
	path: CreateProjectPathProps
	getLocationDetail: (id: string) => void
	setPath: (idPath: string[]) => void
	postActionForSelect?: (id: string, objFormat: DLObjectFormat) => void
	options: BreadCrumbsOptionProps[]
	optionLoadingStatus?: ActionStatus
	showLocationDetail?: boolean
	// setShowLocationDetail?: () => void
}) => {
	const [showDetail, setShowDetail] = useState(
		showLocationDetail ? showLocationDetail : false
	)
	const handleShowDetail = () => {
		setShowDetail(!showDetail)
	}

	const handleSelectOption = (id: string, objFormat: DLObjectFormat) => {
		if (
			objFormat === DLObjectFormat.group ||
			objFormat === DLObjectFormat.cabinet
		) {
			// get detail of selected item
			getLocationDetail(id)
			// update the path
			ConsoleLog("pushItemToPath " + id)
			// postActionForSelect: for create project dialog or... TBD
			if (postActionForSelect !== undefined) {
				postActionForSelect(id, objFormat)
			}
			let idPath: string[] = []
			path.map((item) => idPath.push(item.id))
			idPath.push(id)
			setPath(idPath)
		}
	}
	
	console.log(options,"options")

	const lastObj = path[path.length - 1]
	return (
		<StyledBreadCrumbsAndOptions className="bread-crumbs-and-options">
			<DLBreadCrumbs2 path={path} setPath={setPath} />
			<div className={`last-item-detail ${showDetail && "opened"}`}>
				<div className="FR AC JSB final-location">
					<div className="FR AC ">
						<span className="label">Location</span>:{" "}
						{lastObj?.objFormat === DLObjectFormat.folder && (
							<FolderIcon />
						)}{" "}
						{lastObj?.name}
					</div>
					{showDetail ? (
						<DLIconButton
							eleTestId="location-detail-close-btn"
							clickHandler={handleShowDetail}
						>
							<Icon path={mdiChevronUp} size={0.8} />
						</DLIconButton>
					) : (
						<DLIconButton
							eleTestId="location-detail-open-btn"
							clickHandler={handleShowDetail}
						>
							<Icon path={mdiChevronDown} size={0.8} />
						</DLIconButton>
					)}
				</div>

				<div
					className={`final-location-items ${
						showDetail && "display"
					}`}
				>
					{options.length === 0 &&
					optionLoadingStatus !== ActionStatus.loading ? (
						<div className="no-items">(No Items)</div>
					) : options.length === 0 &&
					  optionLoadingStatus === ActionStatus.loading ? (
						<DLSpinner />
					) : (
						options.map((option) => {
							return (
								<div
									key={option.id}
									onClick={() =>
										handleSelectOption(
											option.id,
											option.objFormat
										)
									}
								>
									<OptionRowForSelection option={option} />
								</div>
							)
						})
					)}
				</div>
			</div>
		</StyledBreadCrumbsAndOptions>
	)
})

export default memo(BreadCrumbsAndOptions)

const OptionRowForSelection = memo(({
	option,
}: {
	option: BreadCrumbsOptionProps
}) => {
	return (
		<>
			{option.objFormat === DLObjectFormat.group ? (
				<div className="FR AC option-item selectable">
					<DLIcon name={DLIconName.group} /> {option.name}
				</div>
			) : option.objFormat === DLObjectFormat.cabinet ? (
				<div className="FR AC option-item selectable">
					<FolderIcon /> {option.name}
				</div>
			) : (
				<DLTooltip title="Cannot select item">
					<div className="option-item disabled">
						({option.objFormat.toLowerCase()}) {option.name}
					</div>
				</DLTooltip>
			)}
		</>
	)
})

const StyledBreadCrumbsAndOptions = styled.div`
	&.bread-crumbs-and-options {
		.last-item-detail {
			transition: height 0.4s;
			&.opened {
				min-height: 25vh;
			}
			margin-top: 1rem;
			background-color: ${(props) => props.theme.shade05};
			border-radius: 3px;
			overflow: hidden;
			margin-bottom: 1rem;
			.final-location {
				background-color: ${(props) => props.theme.shade10};
				padding: 8px;

				span.label {
					font-weight: 700;
				}
			}
			.final-location-items {
				height: 0;

				&.display {
					height: auto;
					max-height: 320px;
					overflow-y: scroll;
					padding: 0.5rem 1rem;
				}
				.option-item {
					margin-bottom: 4px;
					padding: 4px;
					border-radius: 3px;
					border: 1px solid lightgray;
					cursor: pointer;
					transition: 0.4s;
					&.disabled {
						opacity: 0.4;
						cursor: not-allowed;
						border: none;
					}
					&.selectable:hover {
						background-color: ${(props) => props.theme.background};
						border: 1px solid gray;
					}
				}
			}
		}
		.no-items {
			opacity: 0.4;
		}
	}
`
