import { ActionStatus } from "@datalobby/types"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { convertSourceFormat } from "../../../../../library/converters/source-format-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * ! share the same msgCode with others: "closeRequestedOpenedFile"
 * - success (TODO: set fail case?)
 */

const sharedWordForMsgCode = "closeOpenedFile"

const RemoveSingleWorkPaperOpenedNotification = (self: any) => ({
	removeSingleWorkPaperOpenedNotification(srcId: string) {
		// 0.
		const actionName = "removeSingleWorkPaperOpenedNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const payload = {
			MediaID: idToNumber(srcId, IdType.src),
		}
		const url = "/CloseFileNotifications"
		self.mDiscardCheckoutFile(url, payload)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						self.removeNotification(srcId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage: "Successfully closed notification",
							msgCode:
								ActionStatus.success +
								"_" +
								sharedWordForMsgCode,
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

const RemoveSinglePermanentOpenedNotification = (self: any) => ({
	removeSinglePermanentOpenedNotification(mediaId: string) {
		// 0.
		const actionName = "removeSinglePermOpenNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const payload = {
			MediaID: parseInt(mediaId),
		}
		const url = "/ClosePfFileNotifications"
		self.mDiscardCheckoutFile(url, payload)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						self.removeNotification(mediaId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage: "Successfully closed notification",
							msgCode:
								ActionStatus.success +
								"_" +
								sharedWordForMsgCode,
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

const RemoveSinglePermanentRequestedNotification = (self: any) => ({
	removeSinglePermanentRequestedNotification(mediaId: string) {
		// 0.
		const actionName = "removeSinglePermReqNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const payload = {
			MediaID: parseInt(mediaId),
		}
		const url = "/ClosePfRequestFileNotifications"
		self.mDiscardCheckoutFile(url, payload)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						self.removeReqNotification(mediaId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage: "Successfully closed notification",
							msgCode:
								ActionStatus.success +
								"_" +
								sharedWordForMsgCode,
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

const RemoveSingleWorkPaperRequestedNotification = (self: any) => ({
	removeSingleWorkPaperRequestedNotification(mediaId: string) {
		// 0.
		const actionName = "removeSingleWPReqNotification"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		const payload = {
			MediaID: parseInt(mediaId),
		}
		const url = "/CloseRequestFileNotifications"
		self.mDiscardCheckoutFile(url, payload)
			.then((res: any) => {
				if (res) {
					// if success
					if (res.status === 200 && res.data.Status === 1) {
						ConsoleLog(res)
						self.removeReqNotification(mediaId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							// customMessage: "Successfully closed notification",
							msgCode:
								ActionStatus.success +
								"_" +
								sharedWordForMsgCode,
							color: MessageColorType.blue,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export {
	RemoveSingleWorkPaperOpenedNotification,
	RemoveSinglePermanentOpenedNotification,
	RemoveSinglePermanentRequestedNotification,
	RemoveSingleWorkPaperRequestedNotification,
}
