import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import {
	DLDialog,
	DLInputField,
	InputWithLabel,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function EditOrgGroupCabinetDialog() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const actionName = "editCabinetName"

	//
	const cabinetId = orgStore.groups.rightClickTarget
	const cabinetName = orgStore.groups.getNameById(cabinetId)
	//
	const inputsSchema = {
		name: { value: cabinetName, error: "", requestInput: false },
	}
	const i18n = store.i18n.globalI18n("EditOrgGroupCabinetDialog")

	const validationSchema = {
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const handleEditCabinet = () => {
		orgStore.groups.editCabinetName({
			cabinetId: cabinetId,
			title: inputs.name.value,
		})
	}

	const handleOpen = orgStore.groups.setOpenEditCabinetDialog
	const open = orgStore.groups.openEditCabinetDialog
	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		handleEditCabinet,
		open
	)

	// useEffect(() => {
	// 	inputs.name.value = cabinetName
	// }, [])

	const isDuplicated = orgStore.groups.isDuplicatedNameInCabinet(
		inputs.name.value,
		orgStore.groups.getLastItemOfPath
	)
	const actionStatus = orgStore.groups.getActionStatus(actionName)
	return (
		<DLDialog
			eleTestId="edit-cabinet-in-org-group-dialog"
			isOpen={open}
			setIsOpen={handleOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={`${i18n.twEdit} ${i18n.twCabinetName}` || "Edit Cabinet Name"}
			dialogContents={
				<StyledAddCabinetForm>
					<div className="input-section FR">
						<InputWithLabel
							required
							label={i18n.twCabinetName || "Cabinet Name"}
						>
							<DLInputField
								eleTestId="cabinet-name-input"
								eleFullWidth
								eleName="name"
								eleValue={inputs.name.value}
								eleHandleChange={handleOnChange}
								eleRequired
								warningMsg={
									inputs.name.error ||
									(inputs.name.value !== cabinetName &&
										isDuplicated &&
										"The same name is already exist.")
								}
								// warningType={isDuplicated ? "orange" : undefined}
								requestInput={inputs.name.requestInput}
							/>
						</InputWithLabel>
					</div>
				</StyledAddCabinetForm>
			}
			//
			cancelBtnText={i18n.twCancel || "Cancel"}
			// actionReady={isReady}
			actionReady={isReady && !isDuplicated}
			actionBtn={i18n.twEdit || "Edit"}
			maxWidth="sm"
			fullWidth={true}
			// dialogError={
			// 	projStore.wp.actionStatus !== "SUCCESS" &&
			// 	projStore.wp.response.statusMessage
			// 		? projStore.wp.response.statusMessage
			// 		: undefined
			// }
			showSpinner={actionStatus === "LOADING"}
			cannotUpdate={actionStatus === "LOADING"}
			useEnterKeyForSubmit
		/>
	)
})

const StyledAddCabinetForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
