import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../library/converters/id-converter"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"
import {
	MultipleSignOffProps,
	OldSignOffType,
} from "../../data-models/sign-off.data-model"

const MultipleSignOff = (self: any) => ({
	handleSignOff({
		type,
		forCancel,
		userId,
		roleId,
	}: {
		type: OldSignOffType
		forCancel: boolean
		userId: string
		roleId: string
	}) {
		if (self.selectedItems.length > 1) {
			self.setMultipleSignOffType({ type, forCancel })
			self.setFileTreeDialogOpen(FileScreenDialog.multipleSignOff, true)
		} else {
			const target = self.selectedItems[0]
			if (forCancel) {
				self.unSignOff({
					userId,
					roleId,
					signOffType: type,
					fileId: target.id,
					forCancel: true,
				})
			} else {
				self.singleSignOff({
					userId,
					roleId,
					signOffType: type,
					fileId: target.id,
					forCancel: false,
				})
			}
		}
	},

	multipleSignOffs({
		userId,
		roleId,
		signOffType,
		fileIds,
	}: MultipleSignOffProps) {
		// 0.
		const actionName = "multipleSignOffs"
		// 1.
		// 2.

		// 3.
		// NOTE: current API doesn't working with multiple files
		// (it takes file list but just working for the only one - first item)
		// So, request multiple times temporary
		let fileInfoList: string[] = []
		fileIds.map((id) => {
			const info = self.fileShortInfo(id, "multipleSignOffs")
			fileInfoList.push(`(${info.aliasId}) ${info.title}`)
		})
		let proceed = window.confirm(
			`Do you want to ${signOffType} sign off on ${
				fileIds.length
			} files ${fileInfoList.map((info) => info)}?`
		)
		if (!proceed) {
			return
		}
		// TODO: How to check partial failure case?
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 999,
			color: MessageColorType.green,
			open: true,
		})
		//
		let numberIdList: { WPRID: number }[] = []
		fileIds.map((id) => {
			const numberId = idToNumber(id, IdType.file)
			if (typeof numberId === "number") {
				numberIdList.push({ WPRID: numberId })
			} else {
				alert(
					`(${actionName}) Fail to convert ID to number: ${id}. Sign Off will proceed except for this file.`
				)
			}
		})
		//
		//TODO: How to control the prepare of QC? (It is not allowed now)
		// WARNING: Temporary, do not check the exception case. Need to discuss
		// below is previous trying
		// let type: OldSignOffType = OldSignOffType.prepare
		// if (signOffColumnId !== "id_prepare") {
		// 	if (signOffColumnId === "id_qcReview") {
		// 		type = OldSignOffType.qcReview
		// 	} else {
		// 		type = OldSignOffType.review
		// 	}
		// }
		const userNumId = idToNumber(userId, IdType.user)
		if (typeof userNumId !== "number") {
			alert("Something wrong. User ID has invalid format")
		}

		numberIdList.map((item, index: number) => {
			self.requestSignOff({
				fileList: [item],
				roleId,
				userNumId,
				type: signOffType,
				menuId: self.storeName,
			})
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// console.log(actionName+ "__response " + response)
						const result = response.data.SignOff
						self.reflectSignOff({
							userId,
							fileId: idToString(item.WPRID, IdType.file),
							type: signOffType,
							signOff: {
								roleName: result.roleName,
								userName: result.userName,
								at: result.Date,
							},
						})
						if (index + 1 === numberIdList.length) {
							// set success
							self.handleResponse({
								actionName,
								status: ActionStatus.success,
								code: 200,
								color: MessageColorType.blue,
								open: true,
							})
						}
					} else {
						// set fail
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							open: true,
						})
					}
				})
				.catch((error: Error) => {
					ConsoleLog([actionName, "__error ", error])
					self.handleViewModelError({
						error,
						actionName,
						open: true,
					})
				})
		})
	},
	sequentialMultipleSignOffs(props: MultipleSignOffProps) {
		const { userId, roleId, signOffType, fileIds, index } = props
		// 0.
		const actionName = "sequentialMultipleSignOffs"
		// 1.
		// 2.
		if (index === 0) {
			// Do not comment under things. It is used to restrict multiple click sign off button
			self.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.loading,
			})
		}

		// 3.
		// NOTE: current API doesn't working with multiple files. it takes file list but just working for the only one - first item
		//TODO: How to control the prepare of QC? (It is not allowed now)
		// WARNING: Temporary, do not check the exception case. Need to discuss

		const targetId = fileIds[index]
		const fileNumId = idToNumber(targetId, IdType.file)
		const fileActionName = "signOff_" + targetId
		const carryOn = index < fileIds.length - 1
		const lastItem = index === fileIds.length - 1

		/**
		 * * casess
		 * 1. success
		 * 2. fail by logical issue (200 but fail)
		 * 3. fail by model or view model issue
		 * 4. fail because fail to convert file Id as number
		 */
		const userNumId = idToNumber(userId, IdType.user)
		if (typeof userNumId !== "number") {
			alert("Something wrong. User ID has invalid format")
		}

		if (typeof fileNumId === "number") {
			self.responses.setResponse(fileActionName, {
				actionName: fileActionName,
				status: ActionStatus.loading,
			})
			self.requestSignOff({
				fileList: [{ WPRID: fileNumId }], // set as a list for API
				roleId,
				userNumId,
				type: signOffType,
				menuId: self.storeName,
			})
				.then((response: any) => {
					if (response.status === 200 && response.data.Status === 1) {
						// ConsoleLog(fileActionName, "__response", response)
						// 1. when sucess
						ConsoleLog(["success", targetId, fileActionName])
						const result = response.data.SignOff
						self.reflectSignOff({
							userId,
							fileId: targetId, // idToString(item.WPRID, IdType.file),
							type: signOffType,
							signOff: {
								roleName: result.roleName,
								userName: result.userName,
								at: result.Date,
							},
						})
						self.responses.setResponse(fileActionName, {
							actionName: fileActionName,
							status: ActionStatus.success,
						})
						self.spliceSelectedItem(targetId)

						// NOTE: call next item or finish this action
						if (carryOn) {
							self.sequentialMultipleSignOffs({
								...props,
								index: index + 1,
							})
						}
						if (lastItem) {
							self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.standby,
							})
						}
					} else {
						// NOTE: call next item or finish this action
						ConsoleLog(["fail-A", targetId, fileActionName])
						if (carryOn) {
							self.sequentialMultipleSignOffs({
								...props,
								index: index + 1,
							})
						}
						if (lastItem) {
							self.responses.setResponse(actionName, {
								actionName,
								status: ActionStatus.standby,
							})
						}
						// set fail
						self.handleResponse({
							actionName: fileActionName,
							status: ActionStatus.fail,
							code: 999,
							color: MessageColorType.orange,
							message:
								response.data.Message ||
								response.data.message ||
								"Something's wrong...",
							open: false,
						})
						if (lastItem) {
						}
					}
				})
				.catch((error: Error) => {
					ConsoleLog(["fail-B", targetId, fileActionName])
					ConsoleLog([actionName, "__error ", error])
					// NOTE: call next item or finish this action
					if (carryOn) {
						self.sequentialMultipleSignOffs({
							...props,
							index: index + 1,
						})
					}
					if (lastItem) {
						self.responses.setResponse(actionName, {
							actionName,
							status: ActionStatus.standby,
						})
					}
					self.handleResponse({
						actionName: fileActionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: "Something's wrong in viewModel...",
						open: false,
					})
				})
		} else {
			ConsoleLog(["fail-C", fileNumId, targetId, fileActionName])
			// NOTE: call next item or finish this action
			if (carryOn) {
				self.sequentialMultipleSignOffs({
					...props,
					index: index + 1,
				})
			}
			if (lastItem) {
				self.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.standby,
				})
			}
			self.handleResponse({
				actionName: fileActionName,
				status: ActionStatus.fail,
				code: 999,
				color: MessageColorType.orange,
				message: "Faild to convert the file Id to number",
				open: false,
			})
		}
	},
})

export default MultipleSignOff
