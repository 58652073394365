import { types } from "mobx-state-tree"
// ---------- common models
// separated actions
import HandleDialogs from "./ui-helper-actions/handle-dialog"
import {
	HandleSelectedUser,
	ViewSelectedUser,
} from "./ui-helper-actions/handle-selected-user"
import {
	ClickPoint,
	ClickPointProps,
} from "../../../common-models/types/dialog.props"
import { ConsoleLog } from "../../../components/basic-elements"

const SelectedUser = types.model({
	id: types.string,
})

const OrgSetupUsersUiHelper = types
	.model({
		selectedUser: SelectedUser, // for multiple usage, set this as object. let's add additional property on the model
		selectedUsers: types.array(SelectedUser),
		clickPoint: ClickPoint,
		restoreFrom: "createDialog",
		restoreEmail: "",
		pauseMultipleUsers: false,
		// dialogs
		addDialogOpen: types.boolean,
		editDialogOpen: types.boolean,
		suspendDialogOpen: types.boolean,
		restoreDialogOpen: types.boolean,
		removeDialogOpen: types.boolean,
		bulkAddUsersDialogOpen: false,
		assignedProjectsDialogOpen: false,
	})
	.actions((self) => ({
		setClickPoint(clickPoint: ClickPointProps) {
			ConsoleLog("set Clickpoint")
			self.clickPoint = clickPoint
		},
		setRestoreFrom(from: string) {
			self.restoreFrom = from
		},
		setRestoreEmail(email: string) {
			self.restoreEmail = email
		},
		setPauseMultipleUsers(pause: boolean) {
			self.pauseMultipleUsers = pause
		},
	}))
	.actions(HandleSelectedUser)
	.actions(HandleDialogs)
	.views(ViewSelectedUser)

export default OrgSetupUsersUiHelper
