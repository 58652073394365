import { types } from "mobx-state-tree"
import I18nModel from "./i18n-store.model"
import I18nViewModel from "./i18n-store.view-model"
import { SupportedLanguage } from "../../../common-models/enumerations/common-enums"

const I18nStore = types.compose(I18nModel, I18nViewModel).named("I18nStore")

export const initialStore = {
	language: SupportedLanguage.us,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const I18nStoreInstance = I18nStore.create(initialStore)

export default I18nStoreInstance
