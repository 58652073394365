import { AxiosResponse } from "axios"
import { getRequest, postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export type ReadOtherProjectsProps = {
	orgId: string // NOTE: orgId56 (X) org56 (O)
	year?: number // when the year is undefined, query all years data
}
export async function readOthers({ orgId, year }: ReadOtherProjectsProps) {
	let url = `workspaces?orgId=${orgId}`

	const response = await getRequest({
		url,
		params: {
			orgId,
			...(year && { year }),
		},
		tokenType: TOKEN_TYPE.cdkBackend,
	})

	return response
}

export type OtherProjectsApiProps = {
	apiReadOthers: (
		params: ReadOtherProjectsProps
	) => Promise<AxiosResponse<any>>
}
