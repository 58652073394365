import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { DLSnackbar } from "../../../../components/basic-elements/index"

const OpenedFilesOnDrawerSnackbar = observer(() => {
	const store = useRootStore()

	return (
		<DLSnackbar
			type={store.notis.responseSnackbar.type}
			open={store.notis.responseSnackbar.open}
			setOpen={store.notis.responseSnackbar.setOpen}
			anchorLocation="RIGHT_TOP"
			message={store.notis.responseSnackbar.message}
			autoHideDuration={store.notis.responseSnackbar.autoHideDuration}
			autoHide={store.notis.responseSnackbar.autoHide}
		/>
	)
})

export default OpenedFilesOnDrawerSnackbar
