import React, { useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import { OrgLibraryType } from "../store/data-models/org-library.data-model"
import {
	DLSpinner,
	DLSpinnerCenterAligned,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { Icon } from "@mdi/react"
import { mdiBookMultiple } from "@mdi/js"
import styled from "styled-components"
import NoSelectedItem from "../../../../../components/global-shared-components/NoSelectedItem"
import Iframe from "react-iframe"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"

export default observer(function OrgFileLibraryDetails({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const template = orgStore.orgFileLib.selectedTemplate
	const actionName =
		template.type === OrgLibraryType.wpTemplate
			? "getOrgWpTemplateDetail"
			: "getOrgAuditProgramTemplateDetail"

	// to show spinner during the iframe be loaded
	const [loading, setLoading] = useState(true)
	document.querySelector("iframe")?.addEventListener("load", function (e) {
		setLoading(false)
	})

	const fetchingStatus = orgStore.orgFileLib.getActionStatus(actionName)
	const areaWidth =
		store.ui.contentsAreaWidth - orgStore.orgFileLib.viewListWidth - 48
	const contentsHeight = store.ui.contentsAreaHeight - 88
	return (
		<StyledOrgFileLibraryDetails style={{ width: areaWidth }}>
			{template.id !== "" ? (
				<>
					{fetchingStatus === "LOADING" && (
						<div
							className="FR AC JC"
							style={{ width: "100%", height: 200 }}
						>
							<DLSpinner />
						</div>
					)}
					{fetchingStatus === "SUCCESS" && (
						<div className="template-detail-container">
							<div className="template-title FR AC JSB">
								<div className="FR">
									<Icon path={mdiBookMultiple} size={1} />
									<div className="title-text heading3">
										{
											orgStore.orgFileLib.selectedTemplate
												.title
										}
									</div>
								</div>
							</div>
							<div className="template-detail-tree">
								{/* NOTE: This part is not working on this screen...
									{loading && <DLSpinnerCenterAligned />} */}
								<iframe
									data-testid="iframe-file-lib-detail"
									src={
										orgStore.orgFileLib.selectedTemplateURL
									}
									title="dl-file-library"
									width="100%"
									height={`${contentsHeight + 88}px`}
								/>
								{/* <Iframe
										url={
											orgStore.orgFileLib
												.selectedTemplateURL
										}
										width="100%"
										// frameborder="0"
										// allowfullscreen
										height={`${contentsHeight + 88}px`}
									/> */}
							</div>
						</div>
					)}
				</>
			) : (
				<NoSelectedItem />
			)}
		</StyledOrgFileLibraryDetails>
	)
})

const StyledOrgFileLibraryDetails = styled.div`
	/* min-width: 500px;
	min-height: 500px; */
	height: 100%;
	overflow: hidden;
	overflow-y: scroll;

	.template-title {
		height: ${(props) => props.theme.shared.baseUnit};
		padding-left: 1rem;
		padding-right: 1rem;
		.title-text {
			margin-left: 0.6rem;
			font-weight: 700;
		}
	}
	.template-detail-tree {
		overflow: hidden;
		overflow-y: scroll;
	}
`
