import { types } from "mobx-state-tree"
import ViewComment from "./ui-helper-views/view-comments"
import {
	ClickPoint,
	initialClickPoint,
} from "../../../../../common-models/types/dialog.props"
import CommentDialogActions from "./ui-helper-views/comment-dialog-actions.ui-actions"
import { DialogActionType } from "../../../../../common-models/enumerations/common-enums"
import {
	CommonSelectedItem,
	SelectedItemsSharedActions,
	SelectedItemsSharedViews,
} from "../../../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"

const CommentsUiHelper = types
	.model({
		clickPoint: ClickPoint,
		//
		// comment has 2 dialogs on file related menus
		// - comment list (has multiple comments in it)
		// - a comment detail
		// TODO: WARNING: Are these three lines below for project-files...??
		listDialogOpenStatus: false, // TODO: This line should be moved to project-files store
		detailDialogOpenStatus: false, // for both read, edit, add dialog (they share one dialog)
		detailDialogMode: types.enumeration<DialogActionType>(
			Object.values(DialogActionType)
		),
		deleteAllCommentsDialogOpen: false,
		//

		commentClosingDialogOpenStatus: false,

		//
		selectedItems: types.array(CommonSelectedItem),
		// drawer related
		openDrawer: false,
		firstCalled: true, // TODO: isn't it correct say drawerFirstCalled?
	})
	.actions(SelectedItemsSharedActions)
	.views(SelectedItemsSharedViews)
	.actions((self) => ({
		setClickPoint(clickPoint: typeof self.clickPoint) {
			self.clickPoint = clickPoint
		},
		resetClickPoint() {
			self.clickPoint = initialClickPoint
		},
		//
		setOpenDrawer(request?: boolean) {
			if (request !== undefined) {
				self.openDrawer = request
			} else {
				self.openDrawer = !self.openDrawer
			}
		},
		setFirstCalled(request: boolean) {
			self.firstCalled = request
		},
	}))
	// dialog related actions
	.actions((self) => ({
		setDetailDialogOpenStatus(request?: boolean) {
			if (request !== undefined) {
				self.detailDialogOpenStatus = request
			} else {
				self.detailDialogOpenStatus = !self.detailDialogOpenStatus
			}
		},
		setCommentClosingDialogOpenStatus(request?: boolean) {
			if (request !== undefined) {
				self.commentClosingDialogOpenStatus = request
			} else {
				self.commentClosingDialogOpenStatus =
					!self.commentClosingDialogOpenStatus
			}
		},
		setDeleteAllTrashDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.deleteAllCommentsDialogOpen = request
			} else {
				self.deleteAllCommentsDialogOpen =
					!self.deleteAllCommentsDialogOpen
			}
		},
	}))
	.views((self) => ({
		get drawerOpenStatus() {
			return self.openDrawer
		},
	}))
	.actions(CommentDialogActions)
	.views(ViewComment)
/**
 * ViewComment has itmes below
 * - getAllComments
 * - getReviewComments
 * - getQcComments
 * - commentCountByMenu
 * - formattedComments
 * - getCommentsByType
 * - getCommentByTypeAndMenu
 * - getCommentsByRefNumAndType
 * - findCommentsByFile
 * - commentById
 * - wpCommentsCount
 * - pfCommentsCount
 */

export default CommentsUiHelper
