import { flow } from "mobx-state-tree"

const UpdateGroupName = (self: any, apiUpdate: any) => ({
	updateGroupName: flow(function*(payload) {
		const actionName = "updateGroupName"
		try {
			const response = yield apiUpdate(payload)

			// ConsoleLog("updateGroupName response", response)
			return response
		} catch (error) {
			// console.error("updateGroupName error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateGroupName
