import { DLProjSubMenus } from "../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { postRequest, putRequest } from "../../../../library/api-requests"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export type DiscardFileCheckoutApiProps = {
	payload: {
		MediaID: number
		Type: number
		UserID: number
	}[]
	menuId: DLProjSubMenus
}

export async function discardFileCheckout(props: DiscardFileCheckoutApiProps) {
	const { payload, menuId } = props
	let endpoint = ""
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/WorkPaperOpenedFilesForceClose"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/PfOpenedFilesForceClose"
	} else {
		alert(
			`(api - discardFileCheckout) menu type is unknown. menu: ${menuId}`
		)
	}

	// TODO: WARNING: Organization side update is required
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type RequestCheckinFileApiProps = {
	payload: {
		ClientID: number // Project ID
		MediaID: number
		Notes: string
		UserID: number
	}[]
	menuId: string // DLProjSubMenus | DLOrgSubMenus
}

export async function requestCheckinFile(props: RequestCheckinFileApiProps) {
	const { payload, menuId } = props
	let endpoint = ""
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/WorkPaperRequestNotifications"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/SendPfRequestNotifications"
	} else {
		// TODO: Update for Organization side checkin / checkout
		alert(
			`(api - requestCheckinFile) menu type is unknown. menu: ${menuId}`
		)
	}

	// TODO: WARNING: Organization side update is required
	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
