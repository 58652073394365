import { flow } from "mobx-state-tree"

const ReadProjPeriodTypes = (self: any, readPeriodNames: any) => ({
	readProjPeriodTypes: flow(function*() {
		// 0. set actionName
		const actionName = "readProjPeriodTypes"
		try {
			const response = yield readPeriodNames()
			// ConsoleLog("readProjPeriodTypes response", response)
			return response
		} catch (error) {
			// console.error("readProjPeriodTypes error", error, error.response)
			self.handleModelError({ actionName, error, open: false })

			return false
		}
	}),
})

export default ReadProjPeriodTypes
