import { flow } from "mobx-state-tree"

const ReadCheckedOutPfFiles = (self: any, readPF: any) => ({
	readCheckedOutPfFiles: flow(function* () {
		const actionName = "readCheckedOutPfFiles"
		try {
			const response = yield readPF()
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error, error.response)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadCheckedOutPfFiles
