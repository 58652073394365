import React from "react"
import styled from "styled-components"

export default function SelectedUserCard({
	name,
	aliasId,
	email,
	title,
}: {
	name: string
	aliasId: string
	email: string
	title?: string
}) {
	return (
		<StyledSelectedUserCard className="">
			<div className="info-row FR AC">
				<span className="item-label">User Name</span> {name}{" "}
			</div>
			<div className="info-row FR AC">
				<span className="item-label">User ID</span> {aliasId}{" "}
			</div>
			<div className="info-row FR AC">
				<span className="item-label">User Email</span> {email}{" "}
			</div>
			{title && (
				<div className="info-row FR AC">
					<span className="item-label">Title</span> {title}{" "}
				</div>
			)}
		</StyledSelectedUserCard>
	)
}

const StyledSelectedUserCard = styled.div`
	transition: 0.4s;
	background-color: ${(props) => props.theme.secondary};
	padding: 0.8rem;
	border-radius: 3px;
	.info-row {
		margin-bottom: 0.2rem;
		span.item-label {
			margin-right: 0.8rem;
			width: 5rem;
		}
	}
`
