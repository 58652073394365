import { flow } from "mobx-state-tree"
import { OrgSetupClientsApiProps } from "../org-clients.apis"

const AssignGroupsToClient = (
	self: any,
	assignToGroup: OrgSetupClientsApiProps["apiAssignToGroup"]
) => ({
	assignGroupsToClient: flow(function*(payload) {
		const actionName = "assignGroupsToClient"
		try {
			const response = yield assignToGroup(payload)

			// ConsoleLog("assignGroupsToClient response", response)
			return response
		} catch (error) {
			// console.error("assignGroupsToClient error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default AssignGroupsToClient
