import { flow } from "mobx-state-tree"
import { OrgFileLibApiProps } from "../org-file-lib-api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const ReadOrgFileLibFolders = (
	self: any,
	readLibraryFolders: OrgFileLibApiProps["readLibraryFolders"]
) => ({
	readOrgFileLibFolders: flow(function* (orgId: string) {
		const actionName = "readOrgFileLibFolders"
		// 1.
		if (orgId === "" || orgId === undefined) {
			alert(`(${actionName}) Undeinfed props exist. orgId: ${orgId}`)
			return
		}
		//
		//
		// 2.
		const numberId = idToNumber(orgId, IdType.org)
		//
		//
		// 3.
		if (typeof numberId === "number") {
			try {
				const params = {
					CustomerID: numberId,
				}
				const response = yield readLibraryFolders(params)
				// NOTE: Do not save the original data on the Model (TBD) --> Move to the ViewModel
				// ConsoleLog(actinName, "__response", response)
				return response
			} catch (error) {
				// ConsoleLog(actinName, "__error", error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) Prop format is invalid. ${numberId}`)
		}
	}),
})

export default ReadOrgFileLibFolders
