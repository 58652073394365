import React from "react"
import { Link } from "react-router-dom"
import LobbyHeader from "../../../components/app-frame-elements/header/LobbyHeader"
import { observer } from "mobx-react-lite"
import {
	DLSpinner,
	DLButton,
	ConsoleLog,
} from "../../../components/basic-elements"
import styled from "styled-components"
//
import OrgListSnackbar from "./OrgListSnackbar"

// WARNING: Have to remote
import Icon from "@mdi/react"
import { mdiArrowRightBold } from "@mdi/js"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../common-models/types/common-props"

export default observer(function OrgList({
	partialStore,
	actionStatus,
	listType,
	handleListType,
	i18n,
}: {
	partialStore: any
	actionStatus: ActionStatus
	listType: boolean
	handleListType: () => void
	i18n: DLI18nProps
}) {
	ConsoleLog(" ----- OrgList ----- ")
	const { twOrgList, twOpen } = i18n

	const handleSelectOrg = (orgId: string, orgUserId: string) => {
		localStorage.setItem("orgId", orgId)
		localStorage.setItem("orgUserId", orgUserId)
	}

	const sortedOrgList = partialStore.sortedList

	return (
		<StyledOrgList>
			<LobbyHeader />
			<div className="org-list-container FC JC">
				{/* <div className="title-area"> */}
				{/* <div data-testid="org-list-title">{twOrgList}</div> */}
				{/* <div className="view-type FR AC">
							<div className="icon" onClick={handleListType}>
								<Icon
									path={mdiViewList}
									size={1}
									color={listType ? "#444444" : "#e5e5e5"}
								/>
							</div>
							<div className="icon" onClick={handleListType}>
								<Icon
									path={
										listType
											? mdiViewGridOutline
											: mdiViewGrid
									}
									size={0.8}
									color={listType ? "#e5e5e5" : "#444444"}
								/>
							</div>
						</div> */}
				{/* </div> */}
				<div
					className={
						listType
							? `organization-list ${listType}`
							: "organization-list card-type"
					}
				>
					{/* ----- Spinner ----- */}
					{actionStatus === "LOADING" && (
						<div
							className="FR AC JC"
							style={{ width: "100%", height: "100%" }}
						>
							<DLSpinner />
						</div>
					)}
					{actionStatus === "SUCCESS" && sortedOrgList !== undefined && (
						<>
							{sortedOrgList.map((org: any) => (
								<Link
									onClick={() =>
										handleSelectOrg(org.orgId, org.userId)
									}
									to="/organization/checkin"
									key={org.orgId}
								>
									<div
										className={
											listType
												? "organization-item FR AC"
												: "organization-item FC JSB"
										}
										data-testid="org-item"
									>
										{/* <Icon
														path={mdiDomain}
														size={1}
													/> */}
										<div className="organization-title FR AC JC">
											<span
												data-testid={
													"org-title-" + org.orgName
												}
											>
												{org.orgName}
											</span>
										</div>
										<DLButton
											eleClassName="org-open-btn"
											eleHeight="auto"
											eleTestId={`org-open-btn-${org.orgName
												.toLowerCase()
												.replace(/ /g, "-")}`}
											color="primary"
											variant="text"
											endIcon={
												<Icon
													path={mdiArrowRightBold}
													size={1}
												/>
											}
										>
											{twOpen}
										</DLButton>
									</div>
								</Link>
							))}
						</>
					)}
				</div>
			</div>
			{/* response snackbar -- can be removed or commented in the future */}
			<OrgListSnackbar />
		</StyledOrgList>
	)
})

const StyledOrgList = styled.div`
	.org-list-container {
		padding: 4rem;
		height: 94vh;
		overflow-y: auto;
		.title-area {
			font-size: var(--text-xxl);
			margin-bottom: var(--spacing-md);
			color: ${(props) => props.theme.shade60};
			.view-type {
				margin-left: var(--spacing-md);
				.icon {
					margin-top: var(--spacing-sm);
					margin-right: var(--spacing-sm);
					transition: 0.2s;
					cursor: pointer;
					&:hover {
						color: black;
					}
				}
			}
		}
		.organization-list {
			width: 100%;
			a {
				display: flex;
				width: 25%;
				min-width: 240px;
				min-height: 240px;
				overflow: hidden;
				margin-right: var(--spacing-md);
				margin-bottom: var(--spacing-md);
			}
			.organization-item {
				width: 25%;
				min-width: 240px;
				min-height: 240px;
				padding: var(--spacing-md);
				transition: 0.2s;
				transition-property: box-shadow, background-color, border, color,
					fill;
				border: 1px solid ${(props) => props.theme.shade10};
				border-radius: var(--spacing-sm);
				margin-right: var(--spacing-md);
				margin-bottom: var(--spacing-md);
				.organization-title {
					font-size: 2rem;
					text-align: center;
					flex: 8;
				}
				.org-open-btn {
					flex: 2;
				}
				&:hover {
					box-shadow: ${(props) => props.theme.shadowRegularDeep};
					/* border: 1px solid ${(props) => props.theme.shade05}; */
					/* border: 1px solid ${(props) => props.theme.shade10};
					margin-bottom: 5rem; */
					/* background-color: ${(props) =>
						props.theme.background}; */
					background-color: ${(props) => props.theme.emphasis};
					/* color: ${(props) => props.theme.emphasisDeep}; */
					color: ${(props) => props.theme.background};
					/* fill: ${(props) => props.theme.emphasisDeep}; */
					fill: ${(props) => props.theme.background};
				}
			}
			&.card-type {
				display: flex;
				flex-wrap: wrap;
				.organization-item {
					/* width: 20vw; */
					/* max-height: 200px;
					height: 16vw; */
					.organization-title {
						margin-bottom: 1rem;
					}
				}
			}
		}
	}
`
