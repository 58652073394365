import React, { useState } from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
// WARNING: dependencies
import AppBar from "@material-ui/core/AppBar"
import Toolbar from "@material-ui/core/Toolbar"
// sub components
import ProjTitle from "./sub-components/ProjTitle"
import {
	LanguageMenus,
	ProfileMenus,
	HeaderMobileMenus,
} from "./sub-components"
import { DLSnackbar, DLIconButton } from "../../../components/basic-elements"
import DLSearchBox from "../../../components/basic-elements/search/DLSearchBox"
import Icon from "@mdi/react"
import { mdiHelp } from "@mdi/js"
import CheckoutToLobby from "./sub-components/CheckoutToLobby"
import NotificationBtn from "./sub-components/NotificationBtn"
import { ProjI18n } from "../../../common-models/enumerations/translation-sheets"
import { Avatar } from "@material-ui/core"
import { StyledHeader } from "./StyledHeader"

/**
 * WARNING: Mobile menu not exist yet!
 */

export default observer(function ProjHeader() {
	const store = useRootStore()

	const i18n = store.i18n.combineI18n(ProjI18n.menus, "ProjHeader")
	return (
		<StyledHeader className="grow" data-testid="proj-header-container">
			<AppBar position="static" className="app-bar">
				<Toolbar className="toolbar">
					{/* ===== Title =====  */}

					<ProjTitle />

					<div className="grow" />

					{/* ===== Desktop Menus =====  */}
					<div className="section-desktop">
						{/* <DLSearchBox
								id="searchbox-in-proj-header"
								eleTestId="acv"
								placeholder="Search Project or Client"
							/> */}

						{/* ----- Checkout to Lobby ----- */}
						<div className="header-btn">
							<CheckoutToLobby
								tooltipText={
									i18n.twBackToOrgList ||
									"Back to the organization list"
								}
							/>
						</div>

						{/* ----- Notification Drawer Open ----- */}
						<div className="header-btn">
							<NotificationBtn
								tooltipText={
									i18n.twNotifications || "Notifications"
								}
							/>
						</div>
						{/* ----- Help info drawer ----- */}
						<DLIconButton
							eleClassName="header-btn help-info-btn"
							eleTestId="help-info-btn"
							clickHandler={() =>
								store.ui.setHelpInfoDrawerOpen(
									!store.ui.isHelpInfoDrawerOpen
								)
							}
							tooltipText="help"
						>
							<Icon path={mdiHelp} size={0.7} />
						</DLIconButton>

						{/* ----- User related actions ----- */}
						<ProfileMenus />
						{/* ----- Language select button ----- */}
						<LanguageMenus />
					</div>

					{/* ===== Mobile Menus ===== */}
					<div className="section-mobile">
						<HeaderMobileMenus />
					</div>
				</Toolbar>
			</AppBar>
			<ProjHeaderSnackbar />
		</StyledHeader>
	)
})

const ProjHeaderSnackbar = observer(() => {
	const store = useRootStore()

	const { type, open, setOpen, message, autoHideDuration } =
		store.global.responseSnackbar
	return (
		<DLSnackbar
			type={type}
			open={open}
			setOpen={setOpen}
			anchorLocation="RIGHT_TOP"
			message={message}
			autoHideDuration={autoHideDuration}
			autoHide={false}
		/>
	)
})
