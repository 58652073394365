import React, { useState, useEffect } from "react"
import {
	Accordion,
	AccordionDetails,
	AccordionSummary,
	Checkbox,
	FormControlLabel,
} from "@material-ui/core"

import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiChevronDown } from "@mdi/js"

type DLExpansionPanelSingleProps = {
	/** description for the title prop */
	title: string | React.ReactNode
	eleClassName?: string
	hasCheckbox?: boolean
	eleExpanded?: boolean
	defaultExpanded?: boolean
	children?: any
	eleTestId: string
}

DLExpansionPanelSingle.defaultProps = {}

export default function DLExpansionPanelSingle({
	title = "",
	eleClassName = undefined,
	hasCheckbox = undefined,
	eleExpanded = false,
	defaultExpanded = false,
	children = undefined,
	eleTestId = "testId-DLExpansionPanelSingle",
}: DLExpansionPanelSingleProps) {
	const [expanded, setExpanded] = useState(eleExpanded)

	useEffect(() => setExpanded(eleExpanded), [eleExpanded])

	return (
		<StyledExpansionPanel className="expansion-panels-container">
			<Accordion
				expanded={expanded}
				defaultExpanded={defaultExpanded}
				data-testid={eleTestId}
				onChange={(e, value) => setExpanded(value)}
			>
				<AccordionSummary
					expandIcon={<Icon path={mdiChevronDown} size={1} />}
					aria-label="Expand"
					aria-controls="additional-actions1-content"
					id="additional-actions1-header"
					className={`expansion-header ${eleClassName}`}
				>
					{hasCheckbox ? (
						<FormControlLabel
							aria-label="Acknowledge"
							onClick={(event: any) => event.stopPropagation()}
							onFocus={(event: any) => event.stopPropagation()}
							control={<Checkbox />}
							label={title}
						/>
					) : (
						<>{title}</>
					)}
				</AccordionSummary>
				<AccordionDetails className="expansion-body">
					{children}
				</AccordionDetails>
			</Accordion>
		</StyledExpansionPanel>
	)
}

const StyledExpansionPanel = styled.div`
	width: 100%;
	&.expansion-panels-container {
		.expansion-header {
			min-height: 3rem;
			height: 3rem;
			overflow: hidden;
		}
		.expansion-body {
			background-color: ${(props) => props.theme.secondary};
			width: 100%;
		}
	}
`
