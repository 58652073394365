import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"
import { DLSignOffModelProps } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { formattedDateWithTz2 } from "../../../../../library/converters/date-utc-converter"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { DnTFormatProps } from "../../../../../common-models/types/common-props"
import { FileMenuViewType } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"

function makeStringSignoff({
	signOffItem,
	dntFormat,
}: {
	signOffItem: DLSignOffModelProps
	dntFormat: DnTFormatProps
}) {
	const {
		userId,
		userName,
		roleShortName,
		signOffType,
		signedAt,
		signOffModified,
		srcModified,
	} = signOffItem

	let star = srcModified ? "*" : ""
	let redText = signOffModified ? "!" : ""

	const stringSignedAt = formattedDateWithTz2({
		date: signedAt,
		timeZone: dntFormat.timeZone,
		timeFormat: dntFormat.timeFormat,
		dateFormat: dntFormat.dateFormat,
	})

	const stringSignoff =
		star +
		redText +
		"(" +
		roleShortName +
		") " +
		userName +
		" " +
		stringSignedAt.date +
		" " +
		stringSignedAt.time
	return stringSignoff
}

const handleExportFileList = ({
	formattedFileOnly,
	dntFormat,
	periodName,
	financialYear,
	clientName,
	menuId,
	timeZoneAbbr,
	periodAbbrName,
	viewType,
}: {
	formattedFileOnly: any
	dntFormat: DnTFormatProps
	periodName: string
	financialYear: number
	clientName: string
	menuId: string
	timeZoneAbbr?: string
	periodAbbrName?: string
	viewType?: FileMenuViewType
}) => {
	const adminMode = localStorage.getItem("isAdminAppUser") === "true"
	const data = formattedFileOnly(dntFormat, true, adminMode)

	let signoffConvert: any[] = []
	data.map((item: any) => {
		const {
			cpReviewList,
			qcReviewList,
			epReviewList,
			prepareList,
			reviewList,
			lastModifiedBy,
		} = item

		const newCpReviews = cpReviewList
			.map((item: any) => {
				return makeStringSignoff(item)
			})
			.toString()
		const newQcReviews = qcReviewList
			.map((item: any) => {
				return makeStringSignoff(item)
			})
			.toString()
		const newEpReviews = epReviewList
			.map((item: any) => {
				return makeStringSignoff(item)
			})
			.toString()
		const newPrepares = prepareList
			.map((item: any) => {
				return makeStringSignoff(item)
			})
			.toString()
		const newReviews = reviewList
			.map((item: any) => {
				return makeStringSignoff(item)
			})
			.toString()

		const newItem = {
			...item,
			cpReviewList: newCpReviews.replaceAll(",", " \r\n"),
			qcReviewList: newQcReviews.replaceAll(",", " \r\n"),
			epReviewList: newEpReviews.replaceAll(",", " \r\n"),
			prepareList: newPrepares.replaceAll(",", " \r\n"),
			reviewList: newReviews.replaceAll(",", " \r\n"),
			// lastModifiedBy: lastModifiedByAsString,
		}
		signoffConvert.push(newItem)
	})

	const fileType = menuId === DLProjSubMenus.wp ? "WP" : "PF"
	let fileName = "Export File List"
	if (
		viewType === FileMenuViewType.fileTable ||
		viewType === FileMenuViewType.tree ||
		viewType === undefined
	) {
		fileName = `${periodAbbrName} ${financialYear} ${clientName} - ${fileType} File List (${timeZoneAbbr})`
	}
	if (viewType === FileMenuViewType.search) {
		fileName = `${periodAbbrName} ${financialYear} ${clientName} - ${fileType} Search Results (${timeZoneAbbr})`
	}

	handleExport(signoffConvert, fileName)
}

export default handleExportFileList
