import { idToNumber, IdType } from "../../library/converters/id-converter"

export enum OrgRoleType {
	archive = "Archive Admin",
	super = "Super Admin",
	group = "Group Admin",
	user = "User Access",
	external = "External User",
	undefined = "Undefined",
}

// Org level access right types
// "UA" = 3
// "GA" = 2
// "SA" = 1

export const periodOptions = [
	{
		id: "fullYear",
		label: "Full year",
		numberId: 1,
		abbr: "FY"
	},
	{
		id: "halfYear1",
		label: "Half year1",
		numberId: 2,
		abbr: "HY1"
	},
	{
		id: "halfYear2",
		label: "Half year2",
		numberId: 3,
		abbr: "HY2"
	},
	{
		id: "q1",
		label: "Quarter 1",
		numberId: 4,
		abbr: "Q1"
	},
	{
		id: "q2",
		label: "Quarter 2",
		numberId: 5,
		abbr: "Q2"
	},
	{
		id: "q3",
		label: "Quarter 3",
		numberId: 6,
		abbr: "Q3"
	},
	{
		id: "q4",
		label: "Quarter 4",
		numberId: 7,
		abbr: "Q4"
	},
	{
		id: "jan",
		label: "January",
		numberId: 8,
		abbr: "Jan"
	},
	{
		id: "feb",
		label: "February",
		numberId: 9,
		abbr: "Feb"
	},
	{
		id: "mar",
		label: "March",
		numberId: 10,
		abbr: "Mar"
	},
	{
		id: "apr",
		label: "April",
		numberId: 11,
		abbr: "Apr"
	},
	{
		id: "may",
		label: "May",
		numberId: 12,
		abbr: "May"
	},
	{
		id: "jun",
		label: "June",
		numberId: 13,
		abbr: "June"
	},
	{
		id: "jul",
		label: "July",
		numberId: 14,
		abbr: "July"
	},
	{
		id: "aug",
		label: "August",
		numberId: 15,
		abbr: "Aug"
	},
	{
		id: "sep",
		label: "September",
		numberId: 16,
		abbr: "Sep"
	},
	{
		id: "oct",
		label: "October",
		numberId: 17,
		abbr: "Oct"
	},
	{
		id: "nov",
		label: "November",
		numberId: 18,
		abbr: "Nov"
	},
	{
		id: "dec",
		label: "December",
		numberId: 19,
		abbr: "Dec"
	},
]

export function findPeriodNumberId(stringId: string) {
	const target = periodOptions.find((option) => option.id === stringId)
	if (target) {
		return target.numberId
	} else {
		console.error("(findPeriodNumberId) No mathched period")
	}
}

export function getPeriodAbbrById(periodId: string){
	const id = idToNumber( periodId, IdType.period )
	const target = periodOptions.find((option) => option.numberId === id)
	if (target) {
		return target.abbr
	} else {
		console.error("(getPeriodAbbrById) No mathched period")
	}
}
