function findOrgRoleId(
	accessTypeId: number | string,
	isArchiveAdmin: number | string
) {
	const roleList = [
		{
			id: 0,
			roleId: "id_aa",
		},
		{
			id: 1,
			roleId: "id_sa",
		},
		{
			id: 2,
			roleId: "id_ga",
		},
		{
			id: 3,
			roleId: "id_ua",
		},
		{
			id: 4,
			roleId: "x",
		},
	]

	if (isArchiveAdmin === 1) {
		accessTypeId = 0
	}
	// TODO: Why this undeinfed type access exist???
	if (accessTypeId === undefined) {
		accessTypeId = 4
	}
	const roleId = roleList.find((element) => element.id === accessTypeId)
		?.roleId

	return roleId
}

export default findOrgRoleId
