import { types } from "mobx-state-tree"
import { ChecklistRequirementType } from "@datalobby/types"

export type ChecklistTemplateProps = {
	id: string
	name: string
	description?: string
	engTypes: string[] | null
	//
	createdBy: string
	createdAt: string
	lastModifiedBy: string
	lastModifiedAt: string
}

export const ChecklistTemplateModel = types.model({
	id: types.string,
	name: types.string,
	description: types.string,
	// engTypes: types.union(types.null, types.array(types.string)),
	assignedRoles: types.union(types.null, types.array(types.string)),
	assignedUsers: types.union(types.null, types.array(types.string)),
	//
	createdBy: types.string,
	createdAt: types.string,
	lastModifiedBy: types.union(types.undefined, types.null, types.string),
	lastModifiedAt: types.union(types.undefined, types.null, types.string),
})

export const ChecklistItemModel = types.model({
	assignedRoles: types.union(types.null, types.array(
		types.model({
			id: types.string,
			name: types.string,
			shortName: types.string,
		})
	)),
	id: types.string,
	name: types.string,
	order: types.number,
	requirements: types.array(
		types.model({
			id: types.string,
			name: types.string,
			isRequired: types.boolean,
			order: types.number,
			type: types.enumeration<ChecklistRequirementType>(
				Object.values(ChecklistRequirementType)
			),
			value: types.union(types.null, types.undefined),
		})
	),
})

export const ChecklistTemplateDetailModel = types.model({
	// NOTE: checklist template has some difference with checklist
	checklistTemplate: types.model({
		assignedRoles: types.union(
			types.array(
				types.model({
					id: types.string,
					name: types.string,
					shortName: types.string,
				})
			),
			types.null
		),
		createdAt: types.string,
		createdBy: types.model({
			id: types.string,
			aliasId: types.string,
			name: types.string,
		}),
		description: "",
		id: types.string,
		lastModifiedAt: "",
		lastModifiedBy: "",
		name: types.string,
	}),
	items: types.array(ChecklistItemModel),
})
