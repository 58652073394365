import { DLCommentType } from "../../../../../../common-models/enumerations/common-enums"
import { DnTFormatProps } from "../../../../../../common-models/types/common-props"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import {
	getTzDateAndTime,
	getTzDateAndTime2,
} from "../../../../../../library/converters/date-utc-converter"
import { sortBy } from "@datalobby/common"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	CommentModelProps,
	CommentStatus,
} from "../data-models/comments.data-model"

const ViewComment = (self: any) => ({
	get getAllComments() {
		return self.commentList
	},
	get getReviewComments() {
		return self.commentList.filter(
			(element: any) => element.type === DLCommentType.normal
		)
	},
	get getQcComments() {
		return self.commentList.filter(
			(element: any) => element.type === DLCommentType.qc
		)
	},

	commentCountByMenu(menuId: DLProjSubMenus) {
		const comments = self.comments.filter(
			(item: any) => item.menuId === menuId
		)
		return comments.length
	},
	formattedComments(dntFormat: DnTFormatProps) {
		const comments = self.comments
		const formattedList = comments.map((comment: any) => {
			const closingDate = comment.closedAt
			const createdDate = comment.createdAt
			let tzClosingDate = "-"
			let tzCreatedDate = "-"
			if (closingDate) {
				tzClosingDate = getTzDateAndTime2({
					date: closingDate,
					dntFormat,
				})
			}
			if (createdDate) {
				tzCreatedDate = getTzDateAndTime2({
					date: createdDate,
					dntFormat,
				})
			}

			return {
				...comment,
				createdBy: comment.createdBy.name,
				closedAt: tzClosingDate,
				createdAt: tzCreatedDate,
			}
		})
		const sortByObjAlias = sortBy(formattedList, "objAliasId")
		// const sortByStatus = sortBy(sortByObjAlias, "status", false)
		// NOTE: it's hard to confirm the closing result with sortByStatus (because it rearrange the list)
		return sortByObjAlias
	},
	getCommentsByType(type: string) {
		if (type === "all") {
			return self.comments
		}
		if (type === DLCommentType.normal) {
			return self.comments.filter(
				(element: any) => element.type === DLCommentType.normal
			)
		}
		if (type === DLCommentType.qc) {
			return self.comments.filter(
				(element: any) => element.type === DLCommentType.qc
			)
		}
	},
	getCommentByTypeAndMenu(type: string, menuId: DLProjSubMenus) {
		const comments = self.comments.filter(
			(item: any) => item.menuId === menuId
		)
		if (type === "all") {
			return comments
		} else {
			const filtered = comments.filter((item: any) => item.type === type)
			return filtered
		}
	},
	getCommentsByRefNumAndType(objAliasId: string, type: DLCommentType) {
		const commentsByRefNum = self.comments.filter(
			(element: any) => element.objAliasId === objAliasId
		)
		if (type === DLCommentType.normal) {
			return commentsByRefNum.filter(
				(element: any) => element.type === type
			)
		}
		if (type === DLCommentType.qc) {
			return commentsByRefNum.filter(
				(element: any) => element.type === type
			)
		}
	},
	findCommentsByFile(fileId: string) {
		const comments = self.comments.filter(
			(comment: any) => comment.srcId === fileId // TODO: @Noah, is this possible?
		)
		return comments
	},
	viewCommentById(commentId: string, dntFormat: DnTFormatProps) {
		const target = self.comments.find(
			(item: CommentModelProps) => item.id === commentId
		)
		if (target) {
			const closingDate = target.closedAt
			const createdDate = target.createdAt
			let tzClosingDate = "-"
			let tzCreatedDate = "-"
			if (closingDate) {
				tzClosingDate = getTzDateAndTime2({
					date: closingDate,
					dntFormat,
				})
			}
			if (createdDate) {
				tzCreatedDate = getTzDateAndTime2({
					date: createdDate,
					dntFormat,
				})
			}

			return {
				...target,
				closedAt: tzClosingDate,
				createdAt: tzCreatedDate,
			}
		} else {
			ConsoleLog("cannot find the comment: " + commentId)
		}
	},
	commentById(id: string) {
		const comment = self.comments.find((comment: any) => comment.id === id)
		return comment
	},
	getClosedCount(target: "all" | "wp" | "pf") {
		let closed: CommentModelProps[] = []
		if (target === "all") {
			closed = self.comments.filter(
				(item: CommentModelProps) => item.status === CommentStatus.done
			)
		} else if (target === "wp") {
			closed = self.comments.filter(
				(item: CommentModelProps) =>
					item.menuId === DLProjSubMenus.wp &&
					item.status === CommentStatus.done
			)
		} else if (target === "pf") {
			closed = self.comments.filter(
				(item: CommentModelProps) =>
					item.menuId === DLProjSubMenus.pf &&
					item.status === CommentStatus.done
			)
		}

		return closed.length
	},
	get wpCommentsCount() {
		const wpComments = self.comments.filter(
			(comment: any) => comment.menuId === DLProjSubMenus.wp
		)
		return wpComments.length
	},
	get pfCommentsCount() {
		const wpComments = self.comments.filter(
			(comment: any) => comment.menuId === DLProjSubMenus.pf
		)
		return wpComments.length
	},
})

export default ViewComment
