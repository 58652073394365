/**
 * 1. Project Dashboard
 * 2. Informations
 * 3. Note and Comments
 * 4. PBC
 * 5. Task and Time
 * 6. Financial Statements
 * 7. Reconciliation
 * 8. SOX
 * 9. Workpapers
 * 10. Permanent Files
 * 11. Reports
 * 12. Archive
 * 13. Setup
 */

export enum DLProjMenuGroups {
	dashboard = "G_Dashboard",
	info = "G_Information",
	communication = "G_Communications",
	pbc = "G_PBC",
	task_mgmt = "G_TaskAndTime",
	fs = "G_FS",
	reconcil = "G_Reconciliation",
	sox = "G_SOX",
	files = "G_Files",

	reports = "G_Reports",
	archive = "G_Archive",
	setup = "G_ProjSetup",
}

export enum DLProjSubMenus {
	dashboard_default = "DashboardDefault",
	//
	proj_info = "ProjInfo",
	entity_info = "EntityInfo",
	coa_list = "CoAList",
	//
	board = "Board",
	note = "Note",
	comments = "Comments",
	// files
	wp = "WP",
	pf = "PF",
	// PBC
	pbc = "PBC",
	pbc_team_files = "PBCTeamFiles",
	pbc_client_files = "PBCClientFiles",
	// 6. fs
	fs_setup = "FSSetup",
	fs_fs = "FS",
	fs_cv = "ConsolidatingView",
	fs_ls = "FSLS",
	tb = "TB",
	fs_aje = "AJE", // adjustments
	fs_paje = "PAJE", // passed adjustments
	fs_ee = "EliminatingEntry",
	// -- extra
	closing = "Closing",
	ledgers = "Ledgers",
	fs_papers = "FSPapers",
	// 8. SOX
	planning = "Planning",
	risk = "Risks",
	process = "Process",
	controls = "Controls",
	sox_ls = "SOXLS",
	sox_report = "SOXReport",
	// 11. Reports
	report = "Report",
	checklist = "ProjChecklist",
	// 12. Archive
	arch_diagnosis = "ArchDiagnosis",
	arch_pre_process = "ArchPreProcess",
	signoff_history = "SignOffHistory",
	rationales = "Rationales",
	arch_history = "ArchHistory",
	// 13. setup // TODO: setup part naming seems better to update
	users = "ProjUsers",
	roles = "ProjRoles",
	trash = "ProjTrash",
	notification = "NotiSettings",
	danger = "Danger",
	roll_forward = "RF",
}
