import React from "react"
import InputBase from "@material-ui/core/InputBase"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiMagnify, mdiClose } from "@mdi/js"
import InputAdornment from "@material-ui/core/InputAdornment"
import TextField from "@material-ui/core/TextField"
import DLIconButton from "../buttons/DLIconButton"

interface DLSearchProps {
	placeholder?: string
	id: string
	eleTestId: string
	onChange?: any
}

/**
 * TODO: Check the usage cases and update style
 */

export default function DLSearchBox({
	placeholder = undefined,
	id = "search-box-id",
	eleTestId = "testId-DLSearchBox",
}: DLSearchProps) {
	return (
		<StyledSearchBar>
			<div className="search FR AC">
				<div
					className="search-icon FR AC"
					onClick={() =>
						document.getElementById(id ? id : "search-bar")?.focus()
					}
				>
					<Icon path={mdiMagnify} size={1} />
				</div>
				<InputBase
					id={id ? id : "search-bar"}
					data-testid={eleTestId}
					className="input-box"
					placeholder={placeholder}
					inputProps={{ "aria-label": "search" }}
				/>
			</div>
		</StyledSearchBar>
	)
}

interface DLSearchBasicProps {
	placeholder?: string
	id?: string
	label?: string
	eleTestId: string
	onChange?: any
	iconPlacement?: "start" | "end"
	eleValue?: string
	onKeyDown?: any
	clickHandler?: any
	variant?: any
	closeHandler?: any
}

export function DLSearchBoxBasic({
	placeholder = undefined,
	id = undefined,
	label = undefined,
	eleTestId = "testId-DLSearchBox",
	onChange = undefined,
	iconPlacement = "start",
	eleValue,
	onKeyDown,
	clickHandler,
	variant,
	closeHandler
}: DLSearchBasicProps) {
	const SearchIcon = () => (
		<InputAdornment position={iconPlacement}>
		{!eleValue ? (
			<DLIconButton
				variant="iconOnly"
				aria-label="search"
				eleTestId="search-icon"
				clickHandler={clickHandler}
			>
				<Icon path={mdiMagnify} size={1} />
			</DLIconButton>
		) : (
			<DLIconButton
				variant="iconOnly"
				aria-label="close"
				eleTestId="close-icon"
				clickHandler={closeHandler}
			>
				<Icon path={mdiClose} size={1} />
			</DLIconButton>
		)}
		</InputAdornment>
	)
	return (
		<StyledSearchBar>
			<TextField
				className=""
				placeholder={placeholder}
				id={id}
				label={label}
				onKeyDown={onKeyDown}
				onChange={onChange}
				autoComplete="off"
				value={eleValue}
				variant={variant}
				InputProps={
					iconPlacement === "start"
						?	{ startAdornment: <SearchIcon /> }
						:   { endAdornment: <SearchIcon /> }
				}
			/>
		</StyledSearchBar>
	)
}

const StyledSearchBar = styled.div`
	display: flex;
	align-items: center;
	.search {
		position: relative;
		margin-left: 0;
		width: 100%;
	}
	.search-icon {
		width: 2rem;
		height: "100%";
		position: "absolute";
		pointer-events: "none";
		display: "flex";
		align-items: "center";
		justify-content: "center";
		z-index: 100;
	}
	.input-box {
		input {
			width: 0;
			transition: 0.4s;
			border-bottom: 1px solid transparent;
			&:focus {
				border-bottom: 1px solid ${(props) => props.theme.shade20};
				width: calc(var(--standard-unit) * 4);
			}
		}
	}
	.MuiInputBase-root {
		color: ${(props) => props.theme.textMediumEmphasis};
	}
	/* border-bottom: 1px solid; */
	/* border-color: ${(props) => props.theme.textMeidumEmphasis}; */
	.MuiInput-underline:hover:not(.Mui-disabled):before {
		border-color: ${(props) => props.theme.textMediumEmphasis};
	}
	.MuiInput-underline:hover {
		border-color: ${(props) => props.theme.textHighEmphasis};
	}
	.MuiInput-underline:before {
		border-color: ${(props) => props.theme.textMediumEmphasis};
	}
	.MuiInput-underline:after {
		border-color: ${(props) => props.theme.emphasis};
	}
`
