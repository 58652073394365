import { flow } from "mobx-state-tree"

const UpdateArchivedProjectUnarchive = (self: any, unarchive: any) => ({
	archivedProjectUnarchive: flow(function*(formdata) {
		const actionName = "ArchivedProjectUnarchive"
		try {
			const response = yield unarchive(formdata)
			// ConsoleLog(
			// 	"deleteAmArchivedProject",
			// 	response
			// )
			// ConsoleLog(payload)
			// let response
			// if (payload.ArchiveDataObj.Step === 7) {
			// 	response = {
			// 		data: {
			// 			Status: 1,
			// 			ReplicaClientID: 1,
			// 		},
			// 	}
			// } else {
			// 	response = {
			// 		data: {
			// 			Status: 2,
			// 			ReplicaClientID: 1,
			// 		},
			// 	}
			// }
			return response
		} catch (error) {
			// ConsoleLog("deleteAmArchivedProject error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateArchivedProjectUnarchive
