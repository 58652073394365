import React, { useEffect } from "react"
import styled from "styled-components"
import { useProjStore } from "../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import RoleDetailPannelHeader from "./RoleDetailPanelHeader"
import RoleDetailPannelContents from "./panel-contents/RoleDetailPanelContents"
/**
 * ---- 2-2. Pannel
 * -------- 2-2-1. PannelHeader
 * -------- 2-2-2. PannelContents
 * ------------- 2-2-1-1. ContentsControl
 * ------------- 2-2-1-2. Contents
 */
export default observer(function RoleDetailPannel() {
	const projStore = useProjStore()

	useEffect(() => {
		// projStore.permission.setInitialOpenedMenus()
	}, [])

	return (
		<StyledRoleDetailPannel>
			{/* ----- 2-2-1. PannelHeader ----- */}
			<RoleDetailPannelHeader
				info={projStore.roles.viewRoleDetail(
					projStore.roles.selectedRole
				)}
			/>
			{/* ----- 2-2-1. PannelContents ----- */}
			<RoleDetailPannelContents
				data={projStore.roles.viewRoleDetail(
					projStore.roles.selectedRole
				)}
			/>
		</StyledRoleDetailPannel>
	)
})

const StyledRoleDetailPannel = styled.div`
	padding: 1rem;
	width: calc(100% - 12rem);
	/* border: 1px solid pink; */
`
