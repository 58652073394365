import React from "react"

import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLIconButton,
    DLButton,
} from "../../../../../components/basic-elements"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import Icon from "@mdi/react"
import { mdiDelete, mdiPencil } from "@mdi/js"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import DLDateRangepicker from "../../../../../components/basic-elements/date-picker/DLDateRangePicker"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import ReactTableV8 from "../../../../../components/basic-elements/tables/DLReactTable"
import { PageHeader } from "../../../../../components/app-frame-elements"
import { Table, Column } from "@tanstack/react-table"
import BasicCounter from "../../../../../components/app-frame-elements/sub-components/BasicCounter"
import { AddEngTypeBtn } from "../dialogs/AddEngTypeDialog"
import { InputFieldForTableV8 } from "../../../../../components/basic-elements/tables/InputFieldForTable"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { dateRangeFilter } from "../../../../../library/react-table-utils"

export default observer(function OrgSetupEngTypesTable({
	i18n,
	permission,
}: {
	i18n: DLI18nProps
	permission: PermissionAsObjectProps
}) {
	// default settings
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.setup_eng_types
	//
	const handleEdit = (engTypeId: any) => {
		orgStore.setupEngTypes.setSelectedEngId(engTypeId)
		orgStore.setupEngTypes.setOpenEdit(true)
	}

	const handleDelete = (engTypeId: any) => {
		orgStore.setupEngTypes.setSelectedEngId(engTypeId)
		orgStore.setupEngTypes.setOpenDelete(true)
	}
	const dateFormat = store.global.dateFormat.value
	const timeZone = store.global.timeZone.uiValue
	
	const pageHeader = (instance: Table<any>) => {
		const totalEngTypes =  instance.getPreFilteredRowModel().flatRows.length
		const filteredEngTypes =  instance.getFilteredRowModel().flatRows.length

		return (
			<PageHeader>
				<div className="FR AC counter-container">
					<BasicCounter
						label={i18n.twTotalEngType || "Total: "}
						value={totalEngTypes}
					/>

					{totalEngTypes > filteredEngTypes && (
						<div className="FR AC">
							<span className="partition">|</span>
							<div className="filtered-count">
								Filtered Engagement Types: {filteredEngTypes}
							</div>
							<span className="partition">|</span>
							<DLButton
								eleTestId="clear-filters"
								clickHandler={instance.resetColumnFilters}
							>
								Clear Filters
							</DLButton>
						</div>
					)}
				</div>
				<div className="FR AC">
					{permission.create && <AddEngTypeBtn i18n={i18n} />}
				</div>
			</PageHeader>
		)
	}
	return (
		<ReactTableV8 
			tableColumns={columns1({
				handleEdit,
				handleDelete,
				permission,
				i18n,
			})}
			data={orgStore.setupEngTypes.formattedList(dateFormat, timeZone)}
			hasPagination={true}
			customFilters={(props: any) => filterComponent({ ...props, dateFormat })}
			// handleContextMenu={(e: any, row: any) => handleContextMenu(e, row.clientId)}
			height={store.ui.contentsAreaHeight}
			permission={permission}
			i18n={i18n}
			pageHeader={pageHeader}
			menuId={menuId}
		/>
	)
})


const filterComponent = ({
	column,
	table,
	dateFormat,
}: {
	column: Column<any>
	table: Table<any>
	dateFormat: string
}) => {
	switch (column.id) {
		case "createdAt":
			const filterValues: any = column.getFilterValue()
			return (
				<DLDateRangepicker
					selectedStartDate={filterValues?.startDate}
					selectedEndDate={filterValues?.endDate}
					eleStartName="startDate"
					eleEndName="endDate"
					onChange={(value: any, name: string) => {
						column.setFilterValue({
							...filterValues,
							[name]: value,
						})
					}}
					format={dateFormat}
				/>
			)

		case "actions":
			return

		default:
			return (
				<InputFieldForTableV8
					columnId={column.id}
					onChange={column.setFilterValue}
					value={column.getFilterValue()}
				/>
			)
	}
}

const renderCustomCell = (props: any) => {
	const cellId = props.cell.column.id + "-cell"
	return <div data-testid={cellId}>{props.getValue()}</div>
}

const columns1 = ({
	handleEdit,
	handleDelete,
	permission,
	i18n,
}: {
	handleEdit: (projTypeId: string) => void
	handleDelete: (projTypeId: string) => void
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) => [
	// {
	// 	Header: <SortingIconHeader title="S. No" />,
	// 	cell: (e: any) => <span> {e.index + 1} </span>,
	// },
	{
		header: i18n.twEngTypeName || "Name",
		accessorKey: "name",
		cell: renderCustomCell,
	},
	{
		header: i18n.twChecklist || "Checklist",
		accessorKey: "checklist",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedBy || "Created By",
		accessorKey: "createdBy",
		cell: renderCustomCell,
	},
	{
		header: i18n.twCreatedAt || "Created At",
		accessorKey: "createdAt",
		filterFn: dateRangeFilter,
		cell: renderCustomCell,
	},
	{
		header: "Action",
		// show: permission.update || permission.delete,
		cell: (props: any) => {
			const engTypeId = props.row.original.id

			return (
				<div className="contents-area FR AC JC">
					{permission.update && (
						<DLIconButton
							eleTestId="edit-engtype-btn-of-row"
							tooltipText="Edit"
							clickHandler={() => handleEdit(engTypeId)}
						>
							<Icon path={mdiPencil} size={0.8} />
						</DLIconButton>
					)}
					{permission.delete && (
						<DLIconButton
							eleTestId="delete-engtype-btn-of-row"
							tooltipText="Delete"
							clickHandler={() => handleDelete(engTypeId)}
						>
							<Icon path={mdiDelete} size={0.75} />
						</DLIconButton>
					)}
				</div>
			)
		},
	},
]
