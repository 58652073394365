import { flow } from "mobx-state-tree"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { FileSystemApiTypes } from "../../apis/file-system-api-types"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const UpdateFileFolder = (
	self: any,
	updateFolder: FileSystemApiTypes["apiUpdateFolder"]
) => ({
	updateFileFolder: flow(function* ({
		folderId,
		folderName,
	}: {
		folderId: string
		folderName: string
	}) {
		const actionName = "updateFileFolder"
		const menuId = self.storeName
		// 1.
		if (
			folderId === undefined ||
			folderId === "" ||
			folderName === undefined ||
			folderName === ""
		) {
			alert(
				`(${actionName}) Undefined props exist. folderId: ${folderId} | folderName: ${folderName}`
			)
			return
		}
		//
		//
		// 2.

		const folderNumId = idToNumber(folderId, IdType.folder)
		//
		//
		// 3.
		if (typeof folderNumId === "number") {
			try {
				let payload: any = {}
				if (menuId === DLOrgSubMenus.proj_templates) {
					payload = {
						TemplateId: folderNumId,
						TempName: folderName,
						WPREF: "", // NOTE: This empty string is required...
					}
				} else {
					payload = {
						MediaID: folderNumId,
						MediaName: folderName,
					}
				}

				const response = yield updateFolder({ payload, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({ actionName, error, open: true })
				return false
			}
		} else {
			alert(`(${actionName}) Invalid prop format. ${folderNumId}`)
			return false
		}
	}),
})

export default UpdateFileFolder
