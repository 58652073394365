import { flow } from "mobx-state-tree"

const ReadOrgList = (self: any, read: any) => ({
	readOrgList: flow(function* () {
		const actionName = "readOrgList"
		try {
			const response = yield read()
			// console.log(actionName, response)
			return response
		} catch (error) {
			// console.error(actionName, error)
			self.handleModelError({ actionName, error, open: false })
			return false
		}
	}),
})

export default ReadOrgList
