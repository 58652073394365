import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { AssignStatus } from "../../org-clients-store/data-models/org-clients.data-models"

const GetClientsByGroup = (self: any) => ({
	getClientsByGroup(groupId: string, type: AssignStatus) {
		// 0.
		const actionName = "clientList" + type
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const groupNumberId = idToNumber(groupId, IdType.group)
		const clientStatus = type === AssignStatus.unAssigned ? 1 : 2
		const payload = {
			Lang: "en",
			GroupID: groupNumberId,
			Type: clientStatus, // NOTE: 1: overall client list / 2: assigned client list
		}

		self.readClientMasterByGroup(payload)
			.then((response: any) => {
				ConsoleLog(response)
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					// re-organize response data
					const clientList = response.data.ClientMasterList
					// ConsoleLog("Usrlst", userList)
					let clients: any[] = []
					clientList.map((client: any) => {
						const reOrganizedClients = {
							id: client.ClientMasterID.toString(), // TODO: @Prudhvi0817: Please use idToString
							name: client.ClientName, // [ClientName], ex) "bhanus"
							clientAliasId: client.ClientId, //[clientId], ex) "9024642"
							selected: false, //default false
						}
						clients.push(reOrganizedClients)
					})
					if (type === AssignStatus.unAssigned) {
						self.setClientList(clients)
					} else {
						self.setAssignedClientList(clients)
					}
				}
				self.handleResponse({
					actionName,
					status: ActionStatus.success,
					code: 200,
					color: MessageColorType.blue,
					customMessage: "Success",
					open: false,
				})
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetClientsByGroup
