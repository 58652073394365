// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - fail
 */
const GetLobbyDefault = (self: any) => ({
	getLobbyDefault() {
		// 0.
		const actionName = "getLobbyDefault"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		/**
		 * This part is using getOrgList API temporary
		 * DO NOT INTEGRATE this with the getOrgList even they use same api
		 * This part will be updated in the future with more proper api
		 */
		self.readLobbyDefault()
			.then((response: any) => {
				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					// console.log(actionName+ "__response " + response)
					/**
					 * Purpose of this part
					 * 1. language
					 * 2. country
					 * 3. lobbyUserId (not exist yet)
					 * 4. lobbyUserName
					 * 5. lobbyDefaultEmail
					 * 6. orgUserIds
					 */
					const fetchedOrgList = response.data.data
					let newOrgList: any[] = []
					let orgUserIds: string[] = []
					fetchedOrgList.map((item: any) => {
						const newOrg = {
							orgId: idToString(item.CustomerID, IdType.org),
							orgName: item.OrganizationName,
							userId: idToString(item.UserId, IdType.user),
							userAliasId: item.UserName,
							userName: item.Name,
							email: item.Email,
							country: item.CountryCode ? item.CountryCode : "US",
							language: item.Language,
							isOrgAdmin: item.CustomerView,
						}
						newOrgList.push(newOrg)
						orgUserIds.push(newOrg.userId)
					})
					const mainOrg = newOrgList[0]
					// 1. language
					self.setLanguage(mainOrg.language)
					// 2. country
					self.setCountry(mainOrg.country)
					// 3. lobbyUserId (not exist yet)
					// 4. lobbyUserName
					self.setUserName(mainOrg.userName)
					localStorage.setItem("orgUserName", mainOrg.userName)
					// 5. lobbyDefaultEmail
					self.setDefaultEmail(mainOrg.email)
					// 6. orgUserIds
					self.setOrgUserIds(orgUserIds)

					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
				} else {
					// set fail case response
					// ConsoleLog([actionName, "fail in viewModel: ", response])
					self.setLobbyCheckinStatus(ActionStatus.fail)

					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message: response.data.Message || response.data.message,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetLobbyDefault
