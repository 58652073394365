import { types } from "mobx-state-tree"

export const SimpleUserModel = types.model({
	id: types.string,
	aliasId: types.string,
	name: types.string,
})

export type SimpleUserModelType = {
	id: string
	aliasId: string
	name: string
}

export const SimpleUserInProject = types.model({
	userAliasId: "",
	userName: "",
	userId: "",
	//
	roleId: "",
	roleShortName: "",
})

export type SimpleUserInProjectProps = {
	userAliasId: string
	userName: string
	userId: string
	//
	roleId: string
	roleName: string
}
