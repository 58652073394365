import React from "react"
import styled from "styled-components"

export const StyledSharedProjectsTable = styled.div`
	padding-left: 1rem;
	.ReactTable {
		.user-select {
			.user-select-text-field > :nth-child(1) {
				input {
					padding: none;
					font-size: 14px;
					border: none;
				}
			}
		}
	}
`
