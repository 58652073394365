import React, { useState } from "react"
import { DLButton, DLRadioGroup } from "../../../../components/basic-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import styled from "styled-components"
import { PageContainer } from "../../../../components/app-frame-elements"
import { useLobbyStore } from "../../../../stores/lobby-store/lobby-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { observer } from "mobx-react-lite"

export default observer(function MyInformation() {
	const store = useRootStore()
	const lobbyStore = useLobbyStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.my_info
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	const [value, setValue] = useState(store.ui.theme)

	const changeHandler = (event: any) => {
		setValue(event.target.value)
		store.ui.setTheme(event.target.value)
		changeThemeSetting(event.target.value)
	}

	const i18n = store.i18n.globalI18n("MyInformation")
	return (
		<>
			{menuAccess ? (
				<PageContainer
					// fullWidth
					// fixedHeight
					hasToolbar={false}
					pageTools={<div />}
				>
					<StyledContainer>
						<div className="info-container">
							{/* <DLAvatar eleTestId="profile-avatar-big" size={80} /> */}

							<InfoRow
								eleTestId="user-name"
								label={i18n.twName || "User Name"}
								value={orgStore.checkin.orgInfo.userName}
							/>

							<InfoRow
								eleTestId="user-aliasId"
								label={i18n.twAliasId || "User Alias ID"}
								value={orgStore.checkin.orgInfo.userAliasId}
							/>

							<InfoRow
								eleTestId="email"
								label={i18n.twEmail || "Email"}
								value={orgStore.checkin.orgInfo.email}
							/>

							<InfoRow
								eleTestId="org-role"
								label={i18n.twOrgRole || "Organization Role"}
								value={orgStore.checkin.assignedRole.name}
							/>

							{/* <InfoRow
								eleTestId="org-user-title"
								label={i18n.twUserTitle || "User Title"}
								value={orgStore.checkin.orgInfo.userTitle}
							/> */}

							{permission.update && (
								<InfoRow
									eleTestId="theme"
									label={i18n.twTheme || "Theme"}
									value={
										<DLRadioGroup
											// eleTestId="select-theme-radio-group"
											itemDirection="row"
											eleClassName="radio-group"
											color="default"
											// formTitle="Select Theme"
											selectedValue={value}
											items={THEME_SELECTS}
											eleOnChange={changeHandler}
										/>
									}
								/>
							)}

							{permission.update && (
								<DLButton
									variant="contained"
									eleTestId="change-password"
									color="primary"
									align="left"
									clickHandler={() =>
										lobbyStore.my.setChangePwDialogOpen(
											true
										)
									}
								>
									{i18n.twChangePw || "Change Password"}
								</DLButton>
							)}
						</div>
					</StyledContainer>
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const InfoRow = ({
	label,
	value,
	eleTestId,
}: {
	label: string
	value: any
	eleTestId: string
}) => {
	return (
		<div className="info-row" data-testid={eleTestId}>
			<div className="label-area">{label}</div>
			<div className="value-area">{value}</div>
		</div>
	)
}

export const changeThemeSetting = (value: any) => {
	const prevThemeSetting = localStorage.getItem("themeSetting")
	const userId = localStorage.getItem("orgUserId")
	const orgId = localStorage.getItem("orgId")

	const themeSetting = prevThemeSetting ? JSON.parse(prevThemeSetting) : []

	if (
		themeSetting.some(
			(item: any) => item.userId === userId && item.orgId === orgId
		)
	) {
		let prevData = themeSetting.find(
			(item: any) => item.userId === userId && item.orgId === orgId
		)
		const index = themeSetting.findIndex(
			(item: any) => item.userId === userId && item.orgId === orgId
		)
		prevData = {
			...prevData,
			theme: value,
		}
		themeSetting[index] = prevData
	} else {
		themeSetting.push({
			userId,
			orgId,
			theme: value,
		})
	}
	localStorage.setItem("themeSetting", JSON.stringify(themeSetting))
}

export const THEME_SELECTS = [
	{
		label: "Default",
		value: "blue1",
	},
	// {
	// 	label: "Blue 2",
	// 	value: "blue2",
	// },
	// {
	// 	label: "Green 1",
	// 	value: "green1",
	// },
	// {
	// 	label: "Green 2",
	// 	value: "green2",
	// },
	// {
	// 	label: "Gray 1",
	// 	value: "gray1",
	// },
	// {
	// 	label: "Gray 2",
	// 	value: "gray2",
	// },
	// {
	// 	label: "Solarized",
	// 	value: "solarized",
	// },
	{
		label: "Dark",
		value: "dark",
	},
]

const StyledContainer = styled.div`
	width: 100%;
	padding-top: 2rem;
	padding-left: 1rem;
	.panel-title {
		height: ${(props) => props.theme.shared.baseUnit};
		font-weight: 900;
	}
	.info-row {
		display: flex;
		/* padding: 0.3rem 0 0.3rem 3rem; */
		margin-bottom: 2rem;
		.label-area {
			min-width: 13rem;
		}
		.value-area {
		}
		.radio-group {
			padding-left: 0.5rem;
		}
	}
`
