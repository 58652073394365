// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	idToString,
	IdType,
} from "../../../../library/converters/id-converter"

const GetAssignedUsersOfGroup = (self: any) => ({
	getAssignedUsersOfGroup(groupId: string) {
		// 0.
		const actionName = "getAssignedUsersOfGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const groupNumberId = idToNumber(groupId, IdType.group)
		const payload = {
			Lang: "en",
			GroupID: groupNumberId,
			type: 1,
			searchText: "",
		}
		self.readAssignedUsersOfGroup(payload)
			.then((response: any) => {
				// if success
				if (response.data.Status === 1) {
					// empty existing stored list
					// re-organize response data
					const userList = response.data.UsersList
					// ConsoleLog("Usrlst", userList)
					let users: any[] = []
					userList.map((user: any) => {
						const reOrganizedUsers = {
							id: idToString(user.UserID, IdType.user),
							name: user.Name, // [Name], ex) "bhanus"
							email: user.Email, //[Email], ex) "bhanus@yopmail.com"
							title: user.Title, //[Title]
							aliasId: user.UserName, //[UserName] ex) "BS"
							accessTypeId: user.AccessTypeId.toString(), //[AccessTypeId] ex) 3
							isGrayed: user.IsGrayed, // [IsGrayed] ex)false
							selected: false, //default false
						}
						users.push(reOrganizedUsers)
					})
					self.setAssignedUserList(users)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Successfully",
						open: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError2({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetAssignedUsersOfGroup
