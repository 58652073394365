import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import {
	DLButton,
	DLIconButton,
	DLSpinner,
} from "../../../../components/basic-elements"
import styled from "styled-components"

import { CardType } from "../table-view/store/data-models/dashboard-table-view.data-model"
import { PageContainer } from "../../../../components/app-frame-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import DomToImage from "dom-to-image"
import Icon from "@mdi/react"
import {
	mdiDownload,
	mdiImage,
	mdiImageOutline,
	mdiDotsHorizontal,
} from "@mdi/js"
import { saveAs } from "file-saver"

import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { handleExport } from "../../../../components/basic-elements/export/ExportXLSX"
import DashboardFilterDialogContents from "./sub-components/DashboardFilterDialogContents"
import LineGraphView from "./sub-components/LineGraphView"
import HBarTableDetailsDialog from "../card-view/sub-components/dialogs/HBarTableDetailsDialog"
import DLMenusWithMultipleAction from "../../../../components/basic-elements/menus/DLMenusWithMultipleAction"
import FiltersDialog from "./sub-components/FiltersDialog"

export default observer(function OrgDashboardGraphView({
	partialStore,
	contentsHeight,
	actionStatus,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	actionStatus: ActionStatus
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const downloadAreaId = "download-as-img-area"

	const [open, setOpen] = useState(false)

	const downloadAsImg = () => {
		const downloadArea = document.getElementById(downloadAreaId)
		const fileName = "AuditLobby-Dashboard-Graph.jpeg"
		if (downloadArea) {
			DomToImage.toJpeg(downloadArea, {
				quality: 1,
				bgcolor: "white",
			}).then((url) => {
				var link = document.createElement("a")
				link.download = fileName
				link.href = url
				link.click()
			})
		}
	}

	const contentsAreaWidth = store.ui.contentsAreaWidth

	return (
		<StyledCardView>
			<PageContainer
				fullWidth
				pageTools={
					<div className="FR JSB">
						<div>
							<DLButton
								eleTestId="set-filters"
								color="primary"
								clickHandler={() => setOpen(true)}
							>
								Set Filters
							</DLButton>
						</div>
						<DLMenusWithMultipleAction
							remoteButton={
								<DLIconButton eleTestId="page-ctx-btn">
									<Icon path={mdiDotsHorizontal} size={0.8} />
								</DLIconButton>
							}
							menuOptions={[
								{
									label: "Download Screen as image",
									value: "download-dashboard-img",
									available: true,
									icon: (
										<Icon
											path={mdiImageOutline}
											color="green"
											size={0.8}
										/>
									),
									clickEvent: () => downloadAsImg(),
								},
								{
									label: "Set Filters",
									value: "set-filters",
									available: true,
									icon: (
										<Icon
											path={mdiImageOutline}
											color="green"
											size={0.8}
										/>
									),
									clickEvent: () => setOpen(true),
								},
							]}
							eleTestId="page-ctx-menu"
							eleId="page-ctx-menu"
						/>
					</div>
				}
				defaultToolbarStatus="expanded"
				eleClassName="topic-dashboard-summary-container"
			>
				<div className="topic-dashboard-summary-body">
					{/* ----- Spinner ----- */}
					{actionStatus === ActionStatus.loading && (
						<div
							className="FR AC JC"
							style={{
								width: "100%",
								height: contentsHeight,
							}}
						>
							<DLSpinner />
						</div>
					)}
					{actionStatus === ActionStatus.success && (
						<LineGraphView
							partialStore={partialStore}
							downloadAreaId={downloadAreaId}
						/>
					)}
				</div>
				{partialStore.hBarTableDetailsDialogOpen && (
					<HBarTableDetailsDialog
						partialStore={partialStore}
						i18n={i18n}
					/>
				)}
				{open && (
					<FiltersDialog
						open={open}
						setOpen={setOpen}
						dialogContent={
							<DashboardFilterDialogContents
								partialStore={partialStore}
								setOpen={setOpen}
								i18n={i18n}
							/>
						}
					/>
				)}
			</PageContainer>
		</StyledCardView>
	)
})

const StyledCardView = styled.div``
