import { addDays } from "date-fns"
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ProjectStatus } from "../../../../../../common-models/enumerations/project-related-enums"
import { convertProjectStatus } from "../../../../../../library/get-project-status"
import {
	idToString,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetOrgDashboardList = (self: any) => ({
	getOrgDashboardList() {
		// 0.
		const actionName = "getOrgDashboardList"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.request API call
		console.time()
		self.readOrgDashboardList()
			.then((response: any) => {
				// if success
				if (
					response.status === 200 &&
					response.data.Status.code === 200
				) {
					// 1) Initialize existing list
					// 2) Re-organize data
					const fetchedData = response.data.data

					let dashboardList: any[] = []
					fetchedData.map((project: any) => {
						let epUsers: any = []
						if (project.EngagementPartner !== "") {
							project.EngagementPartner.split(",").map(
								(user: string, i: number) => {
									epUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}
						// 2-2) convert qcUserIdList by string
						let qcUsers: any = []
						if (project.QualityControl !== "") {
							project.QualityControl.split(",").map(
								(user: string, i: number) => {
									qcUsers.push({
										id: "xxx",
										aliasId: "xxx",
										name: user,
									})
								}
							)
						}

						const policyPeriod = project.ArchiveDatePolicy
							? parseInt(project.ArchiveDatePolicy.split(" "))
							: 0
						//
						const expectedReportDate = project.ExpectedReportDate
						const finalReportDate = project.FinalIssuanceDate
						const expectedArchiveDeadlineDate =
							project.ExpectedArchiveDeadlineDate
						const finalArchiveDeadlineDate = finalReportDate
							? addDays(
									new Date(finalReportDate),
									policyPeriod
							  ).toISOString()
							: null

						const projectStatus = convertProjectStatus(
							project.ProjectType
						)
						const archivedAt = project.ArchivedDate
						let isLate: undefined | boolean = undefined
						if (finalReportDate && finalArchiveDeadlineDate) {
							// ConsoleLog("calculation check", project.ProjectName, archivedDate, finalReportDate, finalArchiveDeadlineDate, )
							if (projectStatus.name === ProjectStatus.archived) {
								// NOTE: WARNING: Watchout to set the sign. It should be "<"
								if (
									new Date(
										finalArchiveDeadlineDate
									).getTime() < new Date(archivedAt).getTime()
								) {
									isLate = true
								} else {
									isLate = false
								}
							} else {
								// NOTE: WARNING: Watchout to set the sign. It should be "<"
								if (
									new Date(
										finalArchiveDeadlineDate
									).getTime() < new Date().getTime()
								) {
									isLate = true
								} else {
									isLate = false
								}
							}
						} else {
							// cannot calculate the late status because final report date does not exist
						}
						const reOrganizedItem = {
							//
							//
							// * 1. location info: group, cabinet
							groupName: project.GroupName, // ex) "(0)테스트본부"
							cabinetId: project.CabinetId
								? idToString(project.CabinetId, IdType.cabinet)
								: "-", // [CabinetId] ex) 400
							cabinetName: project.CabinetName ?? "-",
							//
							//
							// * 2. client info\
							clientName: project.ClientMasterName, // ex) "데모식품"
							//
							//
							// * 3. project basic info
							id: idToString(project.ClientID, IdType.project), // ex) 2722 // TODO: @Prudhvi-0817: Please use idToString
							aliasId: project.ProjectBusinessId, // ex) "test"
							title: project.ProjectName, // ex) "Open file list test"
							//
							raName: project.RiskAssessmentName, // ex) ""
							engTypeName: project.EngagementType, // ex) "860995"
							//
							//
							// * 4. project status info
							statusId: projectStatus.id, // system ID / project status: normal, replica, archived, unarchived
							statusName: projectStatus.name,
							periodName: project.PeriodName, // ex) "Full year"
							//
							//
							isLocked: project.IsLocked, // ex) true
							isArchived: project.Archive, // ex) true
							version: project.Version.toString(), // ex) 1 // NOTE: @Noah: not a string.. isn't it?
							versionName: project.Version_Name, // ex) "Archived v1"
							//
							//
							// * 5. access info
							accessible: !project.IsGrayOut, // ex) true
							//
							// * 6. schedule info
							financialYear: project.Year.toString(), // ex) 2019
							//
							expectedReportDate: expectedReportDate ?? null,
							finalReportDate: project.FinalIssuanceDate, // ex) "2019-03-05T19:00:00+00:00"
							expectedArchiveDeadlineDate:
								expectedArchiveDeadlineDate ?? null,
							finalArchiveDeadlineDate:
								finalArchiveDeadlineDate ?? null,
							//
							// * 7. history info
							createdBy: project.CreatedBy
								? {
										id: idToString(
											project.CreatedBy,
											IdType.user
										),
										aliasId: "",
										name: project.CreatedByUser,
								  }
								: null,
							createdAt: project.CreatedDate ?? null,
							//
							// * 8. assigned users
							epUsers: epUsers, // ex) ""
							qcUsers: qcUsers, // ex) ""
							//
							//
							archPolicyPeriod: project.ArchiveDatePolicy, // ex) "60 Days"
							archivedAt, // ex) "2019-06-20T08:29:07.043423+00:00"
							unarchivedAt: "xxx", // Need to update from backend
							startDate: project.StartDate, // ex) null
							isLate,
							//
							//
							// * 9. extras
							archiveProjectToolTipId:
								project.ArchiveProjectToolTipId.toString(), // ex) 1
							//
							cpCompletionCount:
								project.ConcPartnerCompletedCount.toString(), // ex) 0
							cpStatus: project.ConcPartnerStatus.toString(), // ex) "0"
							//
							partnerCompletedCount:
								project.PartnerCompletedCount.toString(), // ex) 6
							partnerStatus: project.PartnerStatus, // ex) "100"
							//
							preparedCompletedCount:
								project.PreparedCompletedCount.toString(), // ex) 0
							preparedStatus: project.PreparedStatus, // ex) "0"
							//
							reviewedCompletedCount:
								project.ReviewedCompletedCount.toString(), // ex) 6
							reviewedStatus: project.ReviewedStatus, // ex) "100"
							//
							summaryReportId: project.SummaryReportId.toString(), // ex) 2647
							totalCpCount:
								project.TotalConcPartnerCount.toString(), // ex) 6
							totalPartnerCount:
								project.TotalPartnerCount.toString(), // ex) 6
							totalPreparedCount:
								project.TotalPreparedCount.toString(), // ex) 6
							totalReviewedCount:
								project.TotalReviewedCount.toString(), // ex) 6
						}
						dashboardList.push(reOrganizedItem)
					})
					self.setOrgDashBoardList(dashboardList)
					// ConsoleLog(dashboardList)
					// console.timeEnd()

					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200, // temp
						color: MessageColorType.orange,
						open: true,
						// autoHide: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgDashboardList
