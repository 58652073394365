import {
	ActionStatus,
	MessageColorType,
	SupportedLanguage,
} from "../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const EditUesrLanguage = (self: any) => ({
	editUserLanguage(country: string, language: "kr" | "en", userId: string) {
		// 0.
		const actionName = "editUserLanguage"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		const userNumberId = idToNumber(userId, IdType.user)
		// const userNumberId = parseInt(userId)
		self.updateUserLanguage(country, language, userNumberId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog(actionName + "__ response" + response)
					/**
					 * Reflect
					 */
					const countryCode = response.data.CountryCode
					const language = response.data.LanguageCode
					let languageCode = ""
					// NOTE: The process below should be updated when add additional languages
					self.setCountry(countryCode)
					if (language === "en") {
						languageCode = SupportedLanguage.us
					} else if (language === "kr") {
						languageCode = SupportedLanguage.kr
					} else {
						languageCode = SupportedLanguage.us
					}
					self.setLanguage(languageCode)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Success to" + actionName,
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					ConsoleLog(actionName + "__ fail case" + response)
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						message: response.data.Message,
						// customMessage: "Something wrong...",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default EditUesrLanguage
