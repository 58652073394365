import { types } from "mobx-state-tree"
import OrgSearchModel from "./org-search.model"
import OrgSearchViewModel from "./org-search.view-model"

const OrgSearchStore = types
	.compose(OrgSearchModel, OrgSearchViewModel)
	.named("OrgSearchStore")

export const initialStore = {
	// common parts for ViewModel
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSearchStoreInstance = OrgSearchStore.create(initialStore)

export default OrgSearchStoreInstance
