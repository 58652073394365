import React from "react"
import { Link } from "react-router-dom"
import styled from "styled-components"
import { Sector } from "@datalobby/types"
import { mdiBullhorn } from "@mdi/js"

import Icon from "@mdi/react"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"

export const PostTable = ({
	data,
	userId,
	fixToTopCount,
	contentsAreaHeight,
	openCtxMenu,
	sector,
	projDefaultUrl,
}: {
	data: any
	userId: string
	fixToTopCount: number
	contentsAreaHeight: number
	openCtxMenu: (e: any, rowInfo: any) => void
	sector: Sector
	projDefaultUrl?: string
}) => {
	const detailLink =
		sector === Sector.org
			? "/organization/notifications/detail/"
			: `${projDefaultUrl}/note-and-comments/board/detail/`
	return (
		<StyledNotiList>
			<ReactTableV8
				tableColumns={columnsForNoti(userId, fixToTopCount, detailLink)}
				data={data}
				menuId="PostTable"
				showPageSetting={false}
				height={contentsAreaHeight}
				handleContextMenu={openCtxMenu}
				getRowClass={(row: any) => {
					if (row.original.fixToTop) {
						return "fixToTop"
					}
					return ""
				}}
			/>
		</StyledNotiList>
	)
}

const StyledNotiList = styled.div`
	.fixToTop {
		background-color: ${(props) => props.theme.secondaryDeep};
		color: ${(props) => props.theme.textHighEmphasis};
		a.noti-title {
			font-weight: 700;
		}
		.noti-fixToTop-icon {
			color: ${(props) => props.theme.themeRed};
		}
	}
`

const columnsForNoti = (
	userId: string,
	fixToTopCount: number,
	detailLink: string
) => [
	{
		header: "S. No",
		accessorKey: "x",
		size: 30,
		cell: (props: any) => {
			const index = props.row.index + 1
			return <div data-testid="index-cell">{index}</div>
		},
	},
	// {
	// 	header: "Priority",
	// 	accessorKey: "priority",
	// 	width: 50,
	// 	cell: (props: any) => {
	// 		const priority = props.getValue()
	// 		let chipValue: {
	// 			label: string
	// 			color: "primary" | "secondary" | "default"
	// 		} = {
	// 			label: "Low",
	// 			color: "default",
	// 		}

	// 		if (priority === Priority.high) {
	// 			chipValue = {
	// 				label: "High",
	// 				color: "primary",
	// 			}
	// 		} else if (priority === Priority.medium) {
	// 			chipValue = {
	// 				label: "Medium",
	// 				color: "secondary",
	// 			}
	// 		} else if (priority === Priority.low) {
	// 		} else {
	// 			chipValue = { label: "?", color: "default" }
	// 		}
	// 		if (priority === Priority.low) {
	// 			return <div />
	// 		} else {
	// 			return (
	// 				<DLChip
	// 					size="thin"
	// 					eleTestId="noti-priority"
	// 					label={chipValue.label}
	// 					color={chipValue.color}
	// 				/>
	// 			)
	// 		}
	// 	},
	// },
	{
		header: "Title",
		accessorKey: "title",
		cell: (props: any) => {
			const id = props.row.original.id
			let displayNewMark = false
			// const visitors = row.original.visitors
			const fixToTop = props.row.original.fixToTop

			// if (!visitors.includes(userId)) {
			// 	displayNewMark = true
			// }
			return (
				<div className="FR AC" data-testid="noti-title-cell">
					{fixToTop && (
						<Icon
							path={mdiBullhorn}
							size={0.7}
							className="noti-fixToTop-icon"
							data-testid="noti-fixToTop-icon"
							style={{ marginRight: 8 }}
						/>
					)}
					<Link to={`${detailLink}${id}`} className="noti-title">
						{props.getValue()}
					</Link>
					{displayNewMark && (
						<span
							style={{ color: "red", marginLeft: 8 }}
							data-testid="new-noti-mark"
						>
							New!
						</span>
					)}
				</div>
			)
		},
	},
	{
		header: "Created By",
		accessorKey: "createdBy",
		cell: (props: any) => {
			return <div data-testid="createdBy-cell">{props.getValue()}</div>
		},
	},
	{
		header: "At",
		accessorKey: "createdAt",
		cell: (props: any) => {
			return <div data-testid="createdAt-cell">{props.getValue()}</div>
		},
	},
	// {
	// 	header: <div className="FR AC JC">View</div>,
	// 	accessorKey: "visitorCount",
	// 	style: { justifyContent: "center" },
	// 	cell: (props: any) => {
	// 		return <div data-testid="visitorCount-cell">{props.getValue()}</div>
	// 	},
	// },
]

export default PostTable
