import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	AvailableSettingsProps,
	SubSettingsProps,
} from "../data-models/notification-settings.data-models"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const GetNotificationSettings = (self: any) => ({
	getNotificationSettings() {
		// 0. set actionName
		const actionName = "getNotificationSettings"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call
		self.readNotificationSettings()
			.then((response: any) => {
				if (
					response.status === 200 &&
					response.data.status.code === 200
				) {
					// if success
					let commentNoti: any[] = []
					const notificationSettings =
						response.data.data.notificationSettingModels
					notificationSettings.map((noti: any) => {
						let reOrganizedSubSetting: SubSettingsProps[] = []
						noti.SubSettings.map((setting: any) => {
							let reOrgAvaiableSetting: AvailableSettingsProps[] = []
							setting.AvailableSettings.map((item: any) => {
								reOrgAvaiableSetting.push({
									ordinal: item.Ordinal.toString(), //ex) 1
									label: item.ParameterDisplay, //ex) "At Creation"
									value: item.ParameterValue, //ex) "atcreation"
									parameterValueUI: item.ParameterValueUI, //ex) "At Creation"
									parameterValueUIValue: item.ParameterValueUIValue.toString(), //ex) 0
								})
							})
							reOrganizedSubSetting.push({
								availableSettings: reOrgAvaiableSetting, // ex) null
								displayValue: setting.DisplayValue, //ex) null
								isActive: setting.IsActive, //ex) false
								ordinal: setting.Ordinal.toString(), // ex) 1
								parameterName: setting.ParameterName, //ex) "At Creation"
								parameterValue: setting.ParameterValue, //ex) "atcreation"
								parameterValueUI: setting.ParameterValueUI, //ex) "At Creation"
								parentId: setting.ParentId.toString(), //ex) 55451
								settingId: setting.SettingId.toString(), //ex) 55456
								subSettings: setting.SubSettings, // ex)null
							})
						})
						const reOrganizedData = {
							availableSettings: noti.AvailableSettings, // ex) null
							displayValue: noti.DisplayValue, // ex) "Comment Notification"
							isActive: noti.IsActive, // ex) true
							ordinal: noti.Ordinal.toString(), // ex) 1
							parameterName: noti.ParameterName, // ex) "QC Comment"
							parameterValue: noti.ParameterValue, // ex) "qccomment"
							parameterValueUI: noti.ParameterValueUI, // ex) "QC Comment"
							parentId: noti.ParentId, // ex) null
							settingId: noti.SettingId.toString(), // ex) 55451
							subSettings: reOrganizedSubSetting,
						}
						//
						if (noti.DisplayValue === "Comment Notification") {
							commentNoti.push(reOrganizedData)
						}
						// Expected Report Date Notification
						if (
							noti.DisplayValue ===
							"Expected Issuance Date Notification"
						) {
							self.setExpReportDateNoti(reOrganizedData)
						}
						//Expected Archive Deadline Date
						if (
							noti.DisplayValue ===
							"Expected Archive Deadline Date Notification"
						) {
							self.setExpArchDeadlineDateNoti(reOrganizedData)
						}
						//Final Archive Deadline Date Notification
						if (
							noti.DisplayValue ===
							"Final Archive Deadline Date Notification"
						) {
							self.setFinalArchDeadlineDateNoti(reOrganizedData)
						}
					})
					self.setCommentNoti(commentNoti)
					//
					//--> Additional details
					const additionalDetails = response.data.data
					const projInfo = {
						isLocked: additionalDetails.IsLocked, //ex) false
						archPolicyPeriod: additionalDetails.archiveDatePolicy, //ex) "45 Days"
						archPolicyPeriodValue: additionalDetails.archiveDatePolicyValue.toString(), //ex) 45
						expectedArchiveDeadlineDate:
							additionalDetails.expectedArchiveDeadlineDate, //ex) "2020-12-27T00:00:00+00:00"
						// TODO: variable name need update
						expectedReportDate:
							additionalDetails.expectedIssuanceDate, //ex) "2020-11-12T00:00:00+00:00"
						finalArchiveDeadlineDate:
							additionalDetails.finalArchiveDeadlineDate === null
								? ""
								: additionalDetails.finalArchiveDeadlineDate, //ex) "2020-02-21T00:00:00+00:00"
						// TODO: variable name need update
						finalReportDate:
							additionalDetails.finalIssuanceDate === null
								? ""
								: additionalDetails.finalIssuanceDate, //ex) "2020-01-07T00:00:00+00:00"
						modifiedBy: additionalDetails.modifiedDetails.ModifiedBy
							? additionalDetails.modifiedDetails.ModifiedBy.toString()
							: additionalDetails.modifiedDetails.ModifiedBy, //ex) 16
						modifiedDate:
							additionalDetails.modifiedDetails.ModifiedDate, //ex) "2020-06-08T03:49:14.500489+00:00"
						modifiedUser:
							additionalDetails.modifiedDetails.ModifiedUser, //ex) "김철수"
						roleName: additionalDetails.modifiedDetails.RoleName, //ex) "QC"
						userName: additionalDetails.modifiedDetails.UserName, //ex) "cskim"
					}
					self.pushItemToProjInfo(projInfo)
					// when success
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
						autoHideDuration: 3000,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetNotificationSettings
