import { flow } from "mobx-state-tree"
import { OrgGroupApiCallProps } from "../../../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const RequestMoveCabinet = (
	self: any,
	moveCabinet: OrgGroupApiCallProps["apiMoveCabinet"]
) => ({
	requestMoveCabinet: flow(function* (cabinetId: string, moveTo: string) {
		const actionName = "requestMoveCabinet"
		// 1.
		if (cabinetId === undefined || moveTo === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. cabinetId: ${cabinetId} / moveTo: ${moveTo}`
			)
			return
		}
		if (cabinetId === moveTo) {
			alert(
				`(${actionName}) target and destination are same. cabinetId: ${cabinetId} / moveTo: ${moveTo}`
			)
			return
		}
		// 2.
		let cabinetNumberId: any = undefined
		let moveToNumberId: any = undefined

		if (cabinetId.includes("group")) {
			cabinetNumberId = idToNumber(cabinetId, IdType.group)
		} else if (cabinetId.includes("cabinet")) {
			cabinetNumberId = idToNumber(cabinetId, IdType.cabinet)
		} else if (cabinetId.includes("item")) {
			alert(
				`(${actionName}) This is a project. Invalid action (moveCabinet) is working`
			)
		}

		if (moveTo.includes("group")) {
			moveToNumberId = idToNumber(moveTo, IdType.group)
		} else if (moveTo.includes("cabinet")) {
			moveToNumberId = idToNumber(moveTo, IdType.cabinet)
		} else if (moveTo.includes("item")) {
			console.error("Cannot move to project")
			// TODO: How to block this exception..
		}
		// WARNING: Backend doesn't restrict to use same id to destination...
		// WARNING: Request update to backend and also be careful on front-end side

		if (
			typeof cabinetNumberId === "number" &&
			typeof moveToNumberId === "number"
		) {
			if (cabinetNumberId === moveToNumberId) {
				alert(
					`(${actionName}) target and destination are same. cabinetId: ${cabinetNumberId} / moveTo: ${moveToNumberId}`
				)
				return
			} else {
				try {
					let payload = {
						FromID: cabinetNumberId,
						ToID: moveToNumberId,
					}
					const response = yield moveCabinet(payload)
					// console.log(actionName+ "__response " + response)
					return response
				} catch (error) {
					// console.log(actionName+ "__error "+ error)
					self.handleModelError({
						actionName,
						error,
						openSnackbar: true,
					})

					return false
				}
			}
		} else {
			alert(
				`(${actionName}) cabinetId: ${cabinetNumberId} / moveTo: ${moveToNumberId}`
			)
		}
	}),
})

export default RequestMoveCabinet
