import { flow } from "mobx-state-tree"

const MForceArchive = (self: any, forceArch: any) => ({
	mForceArchive: flow(function* ({ clientId, reason }) {
		const actionName = "readProjUsers"
		try {
			const response = yield forceArch({
				ClientId: clientId,
				Reason: reason,
			})
			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default MForceArchive
