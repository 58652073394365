import React, { useEffect, useState, useCallback } from "react"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import { useParams } from "react-router-dom"
import {
	DLButton,
	DLSingleSelect,
	DLSpinner,
	DLTextArea,
	InputWithLabel,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { PageContainer } from "../../../../../components/app-frame-elements"
import styled from "styled-components"

export default observer(function FinalAnalysis({
	partialStore,
	contentsHeight,
	fetchingStatus,
}: {
	partialStore: any
	contentsHeight: any
	fetchingStatus: any
}) {
	const projStore = useProjStore()

	let { parentId } = useParams<{ parentId?: string }>()

	const selectedEntity =
		partialStore.viewTBDetails().length > 0
			? partialStore.viewTBDetails()[0].value
			: 0
	const [entity, selectEntity] = useState(selectedEntity)
	const [fsType, selectFSType] = useState(1)
	const [inputs, setInputs] = useState({
		purpose: partialStore.finalAnalysis.purpose,
		scope: partialStore.finalAnalysis.scope,
		conclusion: partialStore.finalAnalysis.conclusion,
	})

	const handleEntity = (value: any) => {
		selectEntity(value)
	}

	const handleFSType = (value: any) => {
		selectFSType(value)
	}

	const handleInputs = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value
		setInputs((inputs) => ({
			...inputs,
			[name]: value,
		}))
	}, [])

	useEffect(() => {
		getFinalAnalysis()
	}, [])

	const getFinalAnalysis = () => {
		const projId = projStore.checkin.projId

		if (entity !== 0 && parentId) {
			ConsoleLog([entity, "entity"])
			partialStore.getFinalAnalysis(fsType, projId, entity, parentId)
		}
	}

	const handleSubmit = () => {
		getFinalAnalysis()
	}
	const handleSave = () => {
		if (entity !== 0 && parentId) {
			partialStore.editFinalAnalysis(
				inputs.conclusion,
				inputs.purpose,
				inputs.scope,
				[],
				fsType,
				entity,
				parentId
			)
		}
	}

	ConsoleLog(partialStore.finalAnalysis)
	return (
		<PageContainer hasToolbar={false} pageTools={<div />}>
			{fetchingStatus === ActionStatus.loading && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentsHeight }}
				>
					<DLSpinner />
				</div>
			)}
			{fetchingStatus === ActionStatus.success && (
				<StyledFinalAnalysis>
					<div className="FR JSA">
						<InputWithLabel label="Select Statement">
							<DLSingleSelect
								eleValue={fsType}
								eleOnChange={(e: any) =>
									handleFSType(e.target.value)
								}
								placeholder="Select Statement"
								optionList={partialStore.viewFSTypes()}
								eleTestId="search-fs-type"
								eleClassName="search-fs-type"
							/>
						</InputWithLabel>
						<InputWithLabel label="Select Entity">
							<DLSingleSelect
								eleValue={entity}
								eleOnChange={(e: any) =>
									handleEntity(e.target.value)
								}
								placeholder="Select Entity"
								optionList={partialStore.viewTBDetails()}
								eleTestId="search-entity"
								eleClassName="search-entity"
							/>
						</InputWithLabel>
						<DLButton
							eleTestId="search"
							clickHandler={handleSubmit}
							color="primary"
						>
							Submit
						</DLButton>
						<DLButton
							eleTestId="search"
							clickHandler={handleSave}
							color="primary"
						>
							Save
						</DLButton>
					</div>
					<hr />
					<div className="FR JSB">
						<div>
							<b>Purpose</b>
							<DLTextArea
								eleTestId="purpose_input_area"
								eleFullWidth
								eleName="purpose"
								eleValue={inputs.purpose}
								eleHandleChange={handleInputs}
								eleFieldHeight="5"
							/>
						</div>
						<div>
							<b>Scope</b>
							<DLTextArea
								eleTestId="scope_input_area"
								eleFullWidth
								eleName="scope"
								eleValue={inputs.scope}
								eleHandleChange={handleInputs}
								eleFieldHeight="5"
							/>
						</div>
						<div>
							<b>Conclusion</b>
							<DLTextArea
								eleTestId="conclusionxdsz_input_area"
								eleFullWidth
								eleName="conclusion"
								eleValue={inputs.conclusion}
								eleHandleChange={handleInputs}
								eleFieldHeight="5"
							/>
						</div>
					</div>
				</StyledFinalAnalysis>
			)}
		</PageContainer>
	)
})

const StyledFinalAnalysis = styled.div`
	.MuiInput-formControl {
		margin-top: 0px;
	}
	textarea {
		min-width: 20rem;
	}
`
