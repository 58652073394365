import React, { useState } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLButton,
	DLDialog,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiArrowUp } from "@mdi/js"
import * as XLSX from "xlsx"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"
import { allowedFormats } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"

type ReplicaListItemProps = {
	replicaId: number
	id: number
	title: string
	aliasId: string
	pdfStatus: boolean
}

export default observer(function RepairFailedReplicaDialog({
	open,
	setOpen,
}: {
	open: boolean
	setOpen: any
}) {
	const orgStore = useOrgStore()
	const [replicaList, setReplicaList] = useState<ReplicaListItemProps[]>([])

	const userId = orgStore.checkin.orgUserId
	const orgId = orgStore.checkin.orgInfo.orgId

	const userNumId: any = idToNumber(userId, IdType.user)
	const orgNumId: any = idToNumber(orgId, IdType.org)

	const handleRepairReplica = () => {
		orgStore.archiveMgmt.repairReplicasByIdList(
			userNumId,
			orgNumId,
			replicaList
		)
	}

	return (
		<DLDialog
			eleTestId="repair-fialed-replica-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			showCloseBtn={true}
			dialogTitle="Repair Broken Replica"
			dialogContents={
				<RepairFailedReplicaContent
					replicaList={replicaList}
					setReplicaList={setReplicaList}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			cancelBtnText="Cancel"
			// main action
			actionReady={replicaList.length > 0}
			actionBtn="Repair"
			handleAction={handleRepairReplica}
		/>
	)
})

const RepairFailedReplicaContent = ({
	replicaList,
	setReplicaList,
}: {
	replicaList: ReplicaListItemProps[]
	setReplicaList: any
}) => {
	const handleSelectFilesFromLocal = (e: any) => {
		var file = e.target.files[0]
		var reader = new FileReader()
		reader.onload = function (e) {
			if (e.target) {
				if (
					e.target.result !== null &&
					typeof e.target.result !== "string"
				) {
					var data = new Uint8Array(e.target.result)
					var workbook = XLSX.read(data, { type: "array" })

					const jsonData = XLSX.utils.sheet_to_json(
						workbook.Sheets.Sheet1
					)
					let replicaList: ReplicaListItemProps[] = []

					jsonData.map((item: any, i: any) => {
						replicaList.push({
							replicaId: item.replicaId,
							id: item.id,
							title: item.title,
							aliasId: item.aliasId,
							pdfStatus: item.pdfStatus,
						})
					})

					setReplicaList(replicaList)
				}
			} else {
				ConsoleLog("event target is null")
			}
		}
		reader.readAsArrayBuffer(file)
	}

	return (
		<StyledContent>
			<DLButton
				eleTestId="choose-files-btn"
				startIcon={<Icon path={mdiArrowUp} size={0.8} />}
				color="primary"
			>
				<label className="file-upload-btn-wrapper">
					Choose A file
					<input
						type="file"
						name="file"
						onChange={handleSelectFilesFromLocal}
						data-testid="file-input"
					/>
				</label>
			</DLButton>
			{replicaList.length > 0 && (
				<div className="FC">
					<div className="FR JSB header">
						<div className="repId">Replica Id</div>
						<div className="projId">Project Id</div>
						<div className="title">Title</div>
						<div className="aliasId">Alias Id</div>
						<div className="pdf">PDF Status</div>
					</div>
					{replicaList.map((item: ReplicaListItemProps) => {
						return (
							<div className="FR JSB">
								<div className="repId">{item.replicaId}</div>
								<div className="projId">{item.id}</div>
								<div className="title">{item.title}</div>
								<div className="aliasId">{item.aliasId}</div>
								<div className="pdf">{item.pdfStatus}</div>
							</div>
						)
					})}
				</div>
			)}
		</StyledContent>
	)
}

const StyledContent = styled.div`
	input[type="file"] {
		display: none;
	}
	.repId {
		flex-basis: 10rem;
	}
	.projId {
		flex-basis: 10rem;
	}
	.title {
		flex-basis: 10rem;
	}
	.aliasId {
		flex-basis: 10rem;
	}
	.pdf {
		flex-basis: 10rem;
	}
`
