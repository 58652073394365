import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../library/api-requests/shared-for-api-requests"
import {
	postRequest,
	putRequest,
	deleteRequest,
} from "../../../library/api-requests"

export type ReadProps = {
	ArchiveManager: number
	// CustomerID: number
	IsActive: number // 0 | 1 // TODO: confirmation is required
	PageIndex: number
	PageSize: number
	// Type: 0 | 1 // TODO: confirmation is required
}

const version = process.env.REACT_APP_API_VERSION

export async function read(payload: ReadProps) {
	const endpoint = "/GetUserManagementData"
	const v2_prefix = "/setupmanagement/User"
	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
		// version: process.env.REACT_APP_API_VERSION,
	})

	return response
}

type CreateProps = {
	AccessTypeID: number
	ArchiveManager: number
	EmailID: string
	Name: string
	UserName: string
	UserType: number
	Title?: string
}

export async function create(payload: CreateProps) {
	const endpoint = "/CreateUserManagementUsers"
	const v2_prefix = "/setupmanagement/User"
	const response = await postRequest({
		url: version === "v2" ? v2_prefix + endpoint : endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
		version,
	})

	return response
}

type UpdateProps = {
	Name: string
	UserName: string
	AccessTypeID: number
	ArchiveManager: number
	Title?: string
	UserID: number
	UserType: number
}

export async function update(payload: UpdateProps) {
	const endpoint = "/EditUserManagementUsers"
	const v2_prefix = "/setupmanagement/User"
	const response = await putRequest({
		url: version === "v2" ? v2_prefix + endpoint : endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
		version,
	})

	return response
}
export async function restore(payload: { EmailID: string }) {
	const endpoint = "/RestoreUser"
	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
export async function deleteUser(payload: { UserId: number; Type: number }) {
	const endpoint = "/DeleteUserManagementUsers"
	const response = await deleteRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgSetupUserApiProps = {
	apiRead: (payload: ReadProps) => Promise<AxiosResponse<any>>
	apiCreate: (payload: CreateProps) => Promise<AxiosResponse<any>>
	apiUpdate: (payload: UpdateProps) => Promise<AxiosResponse<any>>
	apiRestore: (payload: { EmailID: string }) => Promise<AxiosResponse<any>>
	apiDelete: (payload: {
		UserId: number
		Type: number
	}) => Promise<AxiosResponse<any>>
}
