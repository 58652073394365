import { types } from "mobx-state-tree"
// model common action
import { CommonModelActions } from "../../../../../../common-models/common-model-actions"
// separated actions
import ReadDataAndTimeSetting from "./model-actions/read-date-and-time-setting"
import UpdateDateAndTimeSetting from "./model-actions/update-date-and-time-setting"
import { OrgSysSettingApiTypes } from "../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"

const DateAndTimeSettingModel = ({
	apiRead,
	apiUpdate,
}: OrgSysSettingApiTypes) =>
	types
		.model({})
		// CRUD actions
		.actions((self) => ReadDataAndTimeSetting(self, apiRead))
		.actions((self) => UpdateDateAndTimeSetting(self, apiUpdate))
		// common model actions
		.actions(CommonModelActions)

export default DateAndTimeSettingModel
