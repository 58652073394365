import { flow } from "mobx-state-tree"
import { FileEditorApiProps } from "../file-editor.api-props"
import { checkPropExistence } from "../../../../../library/check-prerequisite"
import {
	idToNumber,
	IdType,
} from "../../../../../library/converters/id-converter"

export type RequestCheckinFileProps = {
	projId: string
	srcId: string
	message: string
	userId: string
	menuId: string
}

const MRequestCheckinFile = (
	self: any,
	requestCheckinFile: FileEditorApiProps["apiRequestCheckinFile"]
) => ({
	mRequestCheckinFile: flow(function* ({
		projId,
		srcId,
		message,
		userId,
		menuId,
	}: RequestCheckinFileProps) {
		const actionName = "mRequestCheckinFile"
		// 1.
		const prerequisite = [projId, srcId, userId, menuId]
		if (checkPropExistence(actionName, prerequisite) === false) {
			return
		}
		//
		//
		// 2.
		const projNumberId = idToNumber(projId, IdType.project)
		const srcNumId = idToNumber(srcId, IdType.src)
		const userNumberId = idToNumber(userId, IdType.user)
		//
		//
		// 3.
		if (
			typeof projNumberId === "number" &&
			typeof srcNumId === "number" &&
			typeof userNumberId === "number"
		) {
			try {
				// TODO: Which way is better? (Prepare the multiple request here?)
				const payload = [
					{
						ClientID: projNumberId,
						MediaID: srcNumId,
						Notes: message,
						UserID: userNumberId,
					},
				]
				const response = yield requestCheckinFile({ payload, menuId })
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		}
	}),
})

export default MRequestCheckinFile
