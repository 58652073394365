import { types } from "mobx-state-tree"
import { SignOffModel, DLSignOffModelProps } from "./sign-off.data-model"
import {
	ProgressStatus,
	DLObjectFormat,
	DLSrcFormat,
} from "../../../common-models/enumerations/common-enums"
import { DLProjSubMenus } from "../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import {
	SimpleUserInProject,
	SimpleUserInProjectProps,
} from "../../user-module/user-module-data-models"
import { CommentStatus } from "../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"

export const RoleModel = types.model({
	id: types.string,
	name: types.string,
	shortName: types.string,
	color: types.string,
})

export type RoleModelProps = {
	id: string
	name: string
	shortName: string
	color: string
}

/**
 * objectFormat means object (data container)
 * general file format which are related with extension is srcFormat
 */
export const DLFileModel = types.model({
	id: types.string,
	parentId: types.union(types.string, types.null),
	isParent: types.boolean, // folder or file
	//
	//
	//
	aliasId: types.string, // TODO: re-name as aliasId
	title: types.string,
	menuId: types.string, // NOTE: use string for Org > Project templates
	// types.enumeration<DLProjSubMenus>(Object.values(DLProjSubMenus)),
	objectFormat: types.enumeration<DLObjectFormat>(
		Object.values(DLObjectFormat)
	),
	//
	srcId: types.string, // [MediaID] ex) 18757
	srcFormat: types.enumeration<DLSrcFormat>(Object.values(DLSrcFormat)), // [MediaType] ex) "Excel"
	size: types.string,
	extension: types.optional(types.string, ""),
	url: types.union(types.string, types.null), // s3 url
	path: types.optional(types.string, ""), // actual file path
	uploadStatus: types.optional(types.string, ""), // for project template's S3UploadStatus
	//
	isOpen: types.boolean, // [IsOpen] ex) false // TODO: Need to update as 'checked out'
	openedByName: types.union(types.string, types.null), // [OpenedBy] // TODO: Need to update as 'checked out by"
	//
	assignedRoles: types.array(RoleModel), // [RolesList] ex) [] // TODO: update is required
	//
	expanded: types.boolean,
	index: 0, // [Index] index value in API response does not work. Use index of the response list itself // TODO: TBD. is this fine and has no possibility of errors?
	//
	//
	signOffLock: types.boolean,
	signOffLockModifiedDate: types.string,
	signOffLockModifiedBy: SimpleUserInProject,
	//
	//
	status: types.enumeration<ProgressStatus>(Object.values(ProgressStatus)),

	qcCommentStatus: types.enumeration<CommentStatus>(
		Object.values(CommentStatus)
	), // [Followup] ex) 1 --> 1 means no comments
	reviewCommentStatus: types.enumeration<CommentStatus>(
		Object.values(CommentStatus)
	), // [Comments] ex) 1 --> 2 means this pod has comments
	//
	//
	//
	cpReviewList: types.array(SignOffModel), // [ConcurringPartner] ex) []
	qcReviewList: types.array(SignOffModel), // [QualityControl] ex) []
	epReviewList: types.array(SignOffModel), // [EngagementPartner] ex) []
	prepareList: types.array(SignOffModel), // [PreparerList] ex) []
	reviewList: types.array(SignOffModel), // [ReviewerList] ex) []
	//
	isPrepared: types.boolean, // [IsPrepared]
	isReviewed: types.boolean, // [IsReviewed]
	//
	//
	//
	lastModifiedBy: SimpleUserInProject, // [LastModifiedBy] ex) {roleName: null, userName: null, Date: null}
	lastModifiedAt: types.string,
})

export type DLFileProps = {
	id: string
	parentId: string | null
	isParent: boolean
	//
	aliasId: string
	title: string
	menuId: DLProjSubMenus // wp, pf, pbc... etc
	url: string | null // undefined? null? which one is better?
	objectFormat: DLObjectFormat // TODO: clear object format vs source format
	//
	//
	// ***** below are for the source of the file *****
	status: ProgressStatus
	qcCommentStatus: CommentStatus // [Followup]
	reviewCommentStatus: CommentStatus // [Comments]
	//
	signOffLock: boolean
	signOffLockModifiedDate: string
	signOffLockModifiedBy: SimpleUserInProjectProps
	// comments
	// 1: no comments
	// 2: comment
	// 3: signed off comment
	//
	cpReviewList: DLSignOffModelProps[] // [ConcurringPartner] ex) []
	qcReviewList: DLSignOffModelProps[] // [QualityControl] ex) []
	epReviewList: DLSignOffModelProps[] // [EngagementPartner] ex) []
	prepareList: DLSignOffModelProps[] // [PreparerList] ex) []
	reviewList: DLSignOffModelProps[] // [ReviewerList] ex) []
	//
	isPrepared: boolean // [IsPrepared]
	isReviewed: boolean // [IsReviewed]
	//
	lastModifiedBy: SimpleUserInProjectProps // [LastModifiedBy] ex) {roleName: null, userName: null, Date: null}
	lastModifiedAt: string
	//
	srcId: string // [MediaID] ex) 18757
	srcFormat: DLSrcFormat // [MediaType] ex) "Excel"
	size: string
	extension: string
	path: string | undefined // NOTE: undefined?
	uploadStatus: string | undefined
	//
	isOpen: boolean // [IsOpen] ex) false // TODO: (TBD) isCheckedOut
	openedByName: string | null // [OpenedBy] // TODO: (TBD) checkedOutBy
	//
	assignedRoles: string[] // [RolesList] ex) [] // TODO: update is required
	// assignedUsers
	//
	expanded: boolean
	index: number
}

const UserModel = types.model({
	id: types.string,
	aliasId: types.union(types.string, types.null),
	name: types.union(types.string, types.null),
})

const CommentModel = types.model({
	completed: types.number,
	pending: types.number,
})

export const FileInfoModel = types.model({
	id: types.string,
	alias: types.string,
	attentionStatus: types.enumeration<ProgressStatus>(
		Object.values(ProgressStatus)
	),
	checkedOutBy: UserModel,
	createdBy: UserModel,
	createdDate: types.string,
	extension: types.string,
	srcFormat: types.string,
	assignedRoles: types.array(types.number),
	hasRationale: types.boolean,
	isCheckedOut: types.boolean,
	isLocked: types.boolean,
	lastModifiedBy: UserModel,
	lastModifiedDate: types.union(types.string, types.undefined),
	srcId: types.string,
	title: types.string,
	parentId: types.union(types.string, types.null),
	parentName: types.string,
	qcComments: CommentModel,
	reviewComments: CommentModel,
	// ProjectId: 77,
	size: types.string,
})

const ModifiedByModel = types.model({
	id: types.string,
	aliasId: types.union(types.string, types.null),
	name: types.union(types.string, types.null),
	roleId: types.string,
	roleName: types.string,
	roleAliasName: types.string,
	statusInProj: types.string,
	statusInOrg: types.string,
})

export const FileHistoryModel = types.model({
	activityName: types.string,
	attentionStatus: types.enumeration<ProgressStatus>(
		Object.values(ProgressStatus)
	),
	fileId: types.string,
	fileName: types.string,
	refName: types.string,
	signOffLock: types.boolean,
	signOffLockModifiedBy: ModifiedByModel,
	signOffLockModifiedDate: types.string,
	createdDate: types.string,
	createdBy: ModifiedByModel,
})
