import { flow } from "mobx-state-tree"

const UpdateArchiveChecklist = (self: any, updateArchChecklist: any) => ({
    updateArchiveChecklist: flow(function* (payload) {
        // 0. set actionName
        const actionName = "updateArchiveChecklist"
        try {
            const response = yield updateArchChecklist(payload)

            return response
        } catch (error) {
            self.handleModelError({ actionName, error, open: false })

            return false
        }
    }),
})

export default UpdateArchiveChecklist
