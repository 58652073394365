import { types } from "mobx-state-tree"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import RequestOrgSearch from "./model-actions/request-org-search"
import RequestProjectSearch from "./model-actions/request-proj-search"

const OrgSearchModel = types
	.model({})
	.actions(RequestOrgSearch)
	.actions(RequestProjectSearch)
	// Common model actions
	.actions(CommonModelActions)

export default OrgSearchModel
