import React from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { DLDialog } from "../../../../../../components/basic-elements"
import MapUnmapDialogForm from "./MapUnmapDialogForm"
import { DialogType } from "../../store/data-models/fs-setup.data-model"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"

export default observer(function MapUnmapLeadSheetDialog({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const projStore = useProjStore()
	const actionName = "getAvailableLeadSheets"

	const unMapHandler = (id: number) => {
		const details = projStore.fsSetup
			.viewLeadSheets()
			.find((item: any) => item.id === id)
		if (details) {
			projStore.fsSetup.unmapLeadSheetFromSubGroup(details.mapId)
		}
	}

	const mapHandler = (leadSheetName: string) => {
		const subGroupId = projStore.fsSetup.selectedSubGroupId
		projStore.fsSetup.mapLeadSheetToSubGroup(subGroupId, leadSheetName)
	}

	return (
		<DLDialog
			eleTestId="map-unmap-lead-sheet-dialog"
			draggable
			isOpen={projStore.fsSetup.mapUnmapLeadSheetDialogOpen}
			setIsOpen={projStore.fsSetup.setMapUnmapLeadSheetDialogOpen}
			showCloseBtn={true}
			dialogTitle="Map/Un-map Lead Sheets to Financial Sub-group"
			dialogContents={
				<MapUnmapDialogForm
					permission={permission}
					mapHandler={mapHandler}
					unMapHandler={unMapHandler}
					availableItems={projStore.fsSetup.viewAvailableLeadSheets()}
					mappedItems={projStore.fsSetup.viewLeadSheets()}
					dialogType={DialogType.leadSheet}
				/>
			}
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				projStore.fsSetup.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				projStore.fsSetup.getActionStatus(actionName) === "LOADING"
			}
		/>
	)
})
