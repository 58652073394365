import { types } from "mobx-state-tree"
// ViewModel, Modle, UiHelper
import OrgArchivePoliciesModel from "./org-archive-policies.model"
import OrgArchivePoliciesViewModel from "./org-archive-policies.view-model"
import OrgArchivePoliciesUiHelper from "./org-archive-policies.ui-helper"
// common models
// apis
import * as api from "./org-archive-policies.apis"

export const OrgArchivePoliciesStore = ({
	apiRead,
	apiUpdate,
	apiDelete,
	apiCreate,
	apiCopy,
	apiMoveArchiveCondition,
	apiReset,
	apiMoveEngType,
}: api.OrgSetupArchPolicyApiProps) =>
	types
		.compose(
			OrgArchivePoliciesModel({
				apiRead,
				apiUpdate,
				apiDelete,
				apiCreate,
				apiCopy,
				apiMoveArchiveCondition,
				apiReset,
				apiMoveEngType,
			}),
			OrgArchivePoliciesViewModel,
			OrgArchivePoliciesUiHelper
		)
		.named("OrgArchivePoliciesStore")

export const initialStore = {
	// viewModel related parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
}

const OrgArchivePoliciesStoreInstance = OrgArchivePoliciesStore({
	apiRead: api.read,
	apiUpdate: api.update,
	apiDelete: api.del,
	apiCreate: api.create,
	apiCopy: api.copy,
	apiMoveArchiveCondition: api.moveArchiveCondition,
	apiReset: api.reset,
	apiMoveEngType: api.moveEngType,
}).create(initialStore)

export default OrgArchivePoliciesStoreInstance
