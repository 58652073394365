import { types } from "mobx-state-tree"
// ---------- common parts
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import { CommonViewModelActions } from "../../../../common-models/common-view-model-actions"
// separated viewmodel actions
import ConfirmSignup from "./view-model-actions/confirm-signup"

const ConfirmSignupViewModel = types
	.model({
		// ---------- common models
		// ----- common models
		responseSnackbar: ResponseSnackbar,
		responses: Responses,
	})
	.actions(CommonViewModelActions)
	// AGER
	.actions(ConfirmSignup)

export default ConfirmSignupViewModel
