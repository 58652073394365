import { flow } from "mobx-state-tree"

const MUnmapLeadSheetFromSubGroup = (self: any, unmapLeadSheet: any) => ({
	mUnmapLeadSheetFromSubGroup: flow(function*(payload: { MapId: number }) {
		const actionName = "MUnmapLeadSheetFromSubGroup"
		try {
			const response = yield unmapLeadSheet(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default MUnmapLeadSheetFromSubGroup
