import { types } from "mobx-state-tree"
// model & view-model
import AmArchivedProjectsModel from "./am-archived-projects.model"
import AmArchivedProjectsViewModel, {
	AdminMode,
} from "./am-archived-projects.view-model"
import AmArchivedProjectsUiHelper from "./am-archived-projects.ui-helper"
// common models
// apis
import * as api from "./am-archived-projects.apis"
import * as orgProjApis from "../../../../../service-modules/project-module/org-project-shared-apis"

import { AmArchivedApiProps } from "./am-archived-projects.apis"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { DefaultPreConditionData } from "../../../../project-side/archive/archive-pre-process/store/data-models/archive-pre-process.data-model"

export const AmArchivedProjectsStore = (props: AmArchivedApiProps) =>
	types
		.compose(
			AmArchivedProjectsModel(props),
			AmArchivedProjectsViewModel,
			AmArchivedProjectsUiHelper
		)
		.named("AmArchivedProjectsStore")

export const initialStore = {
	storeName: DLOrgSubMenus.archived_mgmt,
	// viewModel related parts
	accessMgmtUserList: [],
	unarchivedDetails: {
		unarchivedDateTime: "",
		engagementPartner: "",
		unarchivedBy: "",
	},
	adminMode: AdminMode.repairReplica,
	//
	preConditionData: DefaultPreConditionData,
	// ui-helpers
	selectedProj: "",
	selectedCabinetId: "",
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	accessMgmtDialogOpen: false,
	archivedProjectUnarchiveDialogOpen: false,
	projectDetailsDialogOpen: false,
	removeArchivedProjectDialogOpen: false,
	unarchiveProgressDialogOpen: false,
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const AmArchivedProjectsStoreInstance = AmArchivedProjectsStore({
	apiRead: api.read,
	apiUnarchiveProject: api.unarchiveProject,
	apiDelete: orgProjApis.deleteProject,
	//
	apiAddAccessMgmtUser: api.addAccessMgmtUser,
	apiReadAccessMgmtUser: api.readAccessMgmtUser,
	apiReadFilteredUsers: api.readFilteredUsers,
	apiDeleteReadOnlyUser: api.deleteReadOnlyUser,
	apiUpdateReadOnlyUser: api.updateReadOnlyUser,
	apiRepairReplica: api.repairReplica,
	//
	apiCheckPreConditionStatus: api.preConditionStatus,
	apiReadFilters: api.readFilterData,
	apiReleaseForceArchive: api.releaseForceArchive,
	// apiStartPreProcess: api.startPreProcess,
}).create(initialStore)

export default AmArchivedProjectsStoreInstance
