import { flow } from "mobx-state-tree"
import {
	IdType,
	idToNumber,
} from "../../../../../library/converters/id-converter"

const ReadCabinetLocation = (self: any, read: any) => ({
	readCabinetLocation: flow(function* (cabinetId: string) {
		const actionName = "readCabinetLocation"

		const numberId = idToNumber(cabinetId, IdType.cabinet)

		if (typeof numberId === "number") {
			try {
				const response = yield read(numberId)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		}
	}),
})

export default ReadCabinetLocation
