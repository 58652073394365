import React from "react"
import styled from "styled-components"
import { Partition } from "."
import { observer } from "mobx-react-lite"
import { DLTooltip } from "@datalobby/components"

const FileRowHistory = observer(
	({
		lastModifiedBy,
		lastModifiedAt,
		width,
	}: {
		lastModifiedBy: any
		lastModifiedAt: string
		width: number | string
	}) => {
		const text = lastModifiedBy.userName + " " + lastModifiedAt
		// @Noah: can we know that what happened at that time?

		return (
			<DLTooltip title={"Last Modified: " + text}>
				<StyledFileRowHistory
					style={{ width }}
					data-testid="last-modified"
					className="FR AC"
				>
					<span data-testid="last-modified-by">
						{lastModifiedBy.userName}
					</span>
					{text}{" "}
					<span data-testeid="last-modified-at">
						{lastModifiedAt}
					</span>
					{/* {lastModifiedBy && (
						<div className="column-section FR AC">
							<div className="file-row-prop last-editor-name">
								{lastModifiedBy.userName}
							</div>
							<Partition />
							<div className="file-row-prop last-edit-date">
								{lastModifiedAt}
							</div>
						</div>
					)} */}
				</StyledFileRowHistory>
			</DLTooltip>
		)
	}
)

const StyledFileRowHistory = styled.div`
	/* border: 1px solid orange; */
	overflow: hidden;
	font-size: 0.6rem;
	.last-editor-name {
	}
	.last-edit-date {
		margin-left: 0.4rem;
	}
`

export default FileRowHistory
