import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"

const ProjectSearch = (self: any) => ({
	getProjectSearch() {
		// 0.
		const actionName = "getProjectSearch"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		let params = {
			ClientMasterId: 0,
			EngagementPartner: "",
			EngagementTypeID: 0,
			FinancialYear: 0,
			GroupId: 0,
			PageIndex: 1,
			PageSize: 1000,
			QualityControl: "",
			SearchText: "test",
			periodID: 0,
		}
		self.requestProjectSearch(params)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT
					 */
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get the search result",
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage: "(TODO) Something Wrong.. ",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default ProjectSearch
