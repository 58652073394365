import React from "react"
import styled from "styled-components"
import { Badge } from "@material-ui/core"
import { MessageColorType } from "../../../common-models/enumerations/common-enums"

interface DLBadgeProps {
	/** number or string */
	content: number | string
	eleTestId: string
	children: React.ReactNode
	/** DEFAULT | BLUE | ORANGE | CORAL | RED */
	color?: MessageColorType
	eleClassName?: string
	eleAction?: any
}

/**
 * This will be on the docs
 *
 * You can use *markdown syntax* here
 * TODO: dark theme setting is required
 */

export default function DLBadge({
	color = MessageColorType.default,
	content = "",
	eleTestId = "testId-DLBadge",
	children = undefined,
	eleClassName = undefined,
	eleAction = undefined,
}: DLBadgeProps) {
	return (
		<Badge
			badgeContent={
				content === 0 ? (
					0
				) : (
					<DLBadgeCircle
						content={content}
						color={color}
						action={eleAction}
					/>
				)
			}
			className={eleClassName}
			data-testid={eleTestId}
		>
			{children}
		</Badge>
	)
}

interface DLBadgeCircleProps {
	content: any
	color?: MessageColorType
	action?: any
}

const DLBadgeCircle = ({ content, color, action }: DLBadgeCircleProps) => {
	let addPadding = undefined

	if (typeof content === "number") {
		if (content > 9) {
			addPadding = "addPadding"
		}
	}
	if (typeof content === "string") {
		if (content.length > 1) {
			addPadding = "addPadding"
		}
	}

	return (
		<StyledDLBadge
			className={`FR AC JC badge-circle ${color} ${addPadding} ${action &&
				"hasAction"}`}
			onClick={action}
		>
			{content}
		</StyledDLBadge>
	)
}

const StyledDLBadge = styled.div`
	min-width: 1rem;
	height: 1rem;
	background-color: orange;
	border-radius: 0.5rem;
	transition: 0.4s;
	color: ${(props) => props.theme.textHighEmphasis};
	&.addPadding {
		padding: 0 0.4rem;
	}
	&.hasAction {
		cursor: pointer;
		:hover {
			box-shadow: 0 2px 4px 1px ${(props) => props.theme.shadowColorDeep};
			margin-bottom: 4px;
		}
	}
	&.DEFAULT {
		background-color: ${(props) => props.theme.secondary};
	}
	&.BLUE {
		background-color: ${(props) => props.theme.emphasis};
	}
	&.ORANGE {
		background-color: ${(props) => props.theme.themeOrange};
	}
	&.CORAL {
		background-color: ${(props) => props.theme.themeYellow};
	}
	&.RED {
		background-color: ${(props) => props.theme.themeRed};
		color: #ffffff;
	}
`
