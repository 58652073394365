import { flow } from "mobx-state-tree"
import { OrgSysSettingApiTypes } from "../../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"

const UpdateEmailNotificationSetting = (
	self: any,
	update: OrgSysSettingApiTypes["apiUpdate"]
) => ({
	updateEmailNotificationSetting: flow(function*(payload) {
		const actionName = "updateEmailNotificationSetting"
		try {
			const response = yield update({ payload })

			return response
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateEmailNotificationSetting
