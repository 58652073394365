import React, { useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import PageContainer from "../../../components/app-frame-elements/sub-components/PageContainer"
import NotificationList from "./sub-components/NotificationList"
import NotoficationDetail from "./sub-components/NotificationDetail"
import PostAddOrEdit from "../../../service-modules/post/components/PostAddOrEdit"
import styled from "styled-components"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { DLOrgSubMenus } from "../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	ActionStatus,
	DLSector,
} from "../../../common-models/enumerations/common-enums"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import OrgNoPermissionOnMenu from "../org-access-control/OrgNoPermissionOnMenu"
import { Sector } from "@datalobby/types"

export default observer(function Notifications() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const globalNotiStore = store.notis
	const menuId = DLOrgSubMenus.org_noti
	const sector = DLSector.org

	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)

	// const needRefresh1 = orgStore.noti.needRefresh
	const needRefresh = globalNotiStore.needRefresh
	const actionName = "getOrgNotiList"

	useEffect(() => {
		const orgId = orgStore.checkin.orgInfo.orgId
		if (menuAccess && needRefresh) {
			if (
				globalNotiStore.getActionStatus(actionName) !==
				ActionStatus.success
			) {
				globalNotiStore.getOrgNotiList(orgId)
			}
		}
	}, [menuAccess, needRefresh])

	return (
		<>
			{menuAccess ? (
				<StyledNotiContainer>
					<PageContainer
						fullWidth
						fixedHeight
						hasToolbar={false}
						pageTools={<div />}
						eleClassName="noti-container"
						noPadding
					>
						<Switch>
							<Route exact path="/organization/notifications">
								<NotificationList
									permission={permission}
									dataStatus={globalNotiStore.getActionStatus(
										actionName
									)}
								/>
							</Route>
							<Route exact path="/organization/notifications/add">
								<PostAddOrEdit
									permission={permission}
									sector={Sector.org}
									partialStore={orgStore.noti}
								/>
							</Route>
							<Route
								exact
								path="/organization/notifications/edit/:postId"
							>
								<PostAddOrEdit
									permission={permission}
									sector={Sector.org}
									postById={store.notis.orgNotiById}
									partialStore={orgStore.noti}
								/>
							</Route>
							<Route
								exact
								path="/organization/notifications/detail/:postId"
							>
								<NotoficationDetail
									permission={permission}
									menuId={menuId}
								/>
							</Route>
						</Switch>
					</PageContainer>
				</StyledNotiContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledNotiContainer = styled.div`
	.noti-container {
	}
`
