import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"

// @Noah: What is different with get-arch-policy-period?
const ReadArchPolicyPeriodList = (
	self: any,
	readArchPeriodTypes: OrgProjectApiProps["apiReadArchPolicyPeriodList"]
) => ({
	readArchPolicyPeriodList: flow(function* () {
		const actionName = "readArchPolicyPeriodList"
		/**
		 * RE-ORGANIZE PARAMS (TBD)
		 */
		try {
			const response = yield readArchPeriodTypes()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadArchPolicyPeriodList
