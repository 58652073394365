import React, { useEffect, useState, useCallback } from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import BreadCrumbsAndOptions, {
	BreadCrumbsOptionProps,
} from "../../../components/combined-elements/postpone-to-classify/BreadCrumbsAndOptions"
import { WithRollForward } from "../../../components/combined-elements/create-project/center-section-sub-parts"
import {
	CreateProjectMethod,
	CreateProjectInputProps,
	initialCreateProjectInputs,
} from "../../../components/combined-elements/create-project/create-project.data-props"
import { DLCheckbox } from "@datalobby/components/lib"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { OrgI18n } from "../../../common-models/enumerations/translation-sheets"
import {
	DLObjectFormat,
	ActionStatus,
	MessageColorType,
} from "../../../common-models/enumerations/common-enums"
import {
	ConsoleLog,
	DLComboBox,
	InputWithLabel,
	DLButton,
	DLSpinner,
} from "../../../components/basic-elements"
import parse from "autosuggest-highlight/parse"
import match from "autosuggest-highlight/match"
import { createFilterOptions } from "@material-ui/lab/Autocomplete"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { format } from "date-fns"
import CommonSnackbarComponent from "../../../components/combined-elements/snackbar/CommonSnackbarComponent"
import { PageContainer } from "../../../components/app-frame-elements"
import {
	addTzToDate,
	getTzDate,
} from "../../../library/converters/date-utc-converter"

export default observer(function ProjectRollForward({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const projInfo = projStore.projInfo.projInfo

	const [inputs, setInputs] = useState<CreateProjectInputProps>(
		initialCreateProjectInputs
	)

	const [crumbOptions, setCrumbOptions] = useState<BreadCrumbsOptionProps[]>(
		[]
	)

	const dntFormat = store.global.getDntFormat
	const path = partialStore.selectionPathWithNameAndFormat
	const pathLength = path.length
	const groupId = path[0]?.id

	const lastItem = path[path.length - 1]
	const targetInfo = partialStore.getTargetDetail(lastItem?.id)
	console.log(inputs, projInfo, path, projStore.checkin, "inputs")

	useEffect(() => {
		if (lastItem) {
			// breadCrumbOptions = orgStore.groups.getChildrenInTheFlatListAsOptions(
			// 	lastItem.id
			// )
			const options = partialStore.getChildrenInTheFlatListAsOptions(
				lastItem.id
			)
			setCrumbOptions(options)

			if (
				options.length === 0 &&
				targetInfo?.type !== DLObjectFormat.project
			) {
				ConsoleLog("getOrgCabinetDetail - for lastItem")
				// getOrgCabinetDetail(lastItem.id)
			}
		} else {
			// breadCrumbOptions = orgStore.groups.groupOptions
			setCrumbOptions(partialStore.groupOptions)
		}
	}, [lastItem])

	useEffect(() => {
		if (groupId !== undefined) {
			ConsoleLog(
				"(create-project-dialog) useEffect to get client list" + groupId
			)
			partialStore.getClientsByGroup(groupId)
			// orgStore.setupGroups.getAssignedUsersOfGroup(groupId) // NOTE: Need to check current group view-model (data structure)
		} else {
			setInputs((inputs: any) => ({
				...inputs,
				clientAliasId: "",
				clientId: "",
				clientName: "",
			}))
		}
	}, [groupId])

	const getLocationDetail = (id: string) => {
		if (id.includes("group")) {
			partialStore.getOrgGroupCabinetList(id)
		} else if (id.includes("cabinet")) {
			// 			ConsoleLog("getLocationDetail - getOrgCabinetDetail")
			// 			getOrgCabinetDetail(id)
		} else {
			// TODO: Disable the item in the cabinet
		}
	}

	const handleClientInput = useCallback((value: any) => {
		setInputs((inputs: any) => ({
			...inputs,
			clientAliasId: value ? value.aliasId : "",
			clientId: value ? value.id : "",
			clientName: value ? value.name : "",
		}))
	}, [])

	const handleCheckbox = useCallback((key: string) => {
		ConsoleLog("handle checkbox " + key + inputs)
		setInputs((inputs: any) => ({
			...inputs,
			[key]: !inputs[key],
		}))
	}, [])

	function getRequiredDate(date: string) {
		const date1 = getTzDate({
			date,
			timeZone: dntFormat.timeZone,
			dateFormat: dntFormat.dateFormat,
		})
		const date2 = addTzToDate(date1, dntFormat.timeZone)
		return date2
	}

	const handleCreateRFProject = () => {
		const userId = localStorage.getItem("orgUserId") || ""
		partialStore.addProject({
			userId,
			inputs: {
				...inputs,
				groupId: path[0].id,
				cabinetId: path[path.length - 1].id,
				copyFrom: projInfo.projectId,
				archPolicyPeriod: projInfo.archPolicyPeriodId,
				engTypeId: projInfo.engTypeId,
				engTypeName: projInfo.engTypeName,
				createMethod: CreateProjectMethod.byRollForward,
				riskAssessment: projInfo.raId,
				periodEndDate: projInfo.periodEndDate,
				periodId: projInfo.periodId,
				periodName: projInfo.periodName,
				expectedReportDate: getRequiredDate(
					projInfo.expectedReportDate
				),
				expectedArchiveDate: getRequiredDate(
					projInfo.expectedArchDeadlineDate
				),
				aliasId: `${projInfo.projAliasId} (RF-${format(
					new Date(),
					"yyyyMMddHHmmss"
				)})`,
				title: `${projInfo.projTitle} (RF-${format(
					new Date(),
					"yyyyMMddHHmmss"
				)})`,
				epUserId: userId,
				users: [],
			},
			postAction: (newProjId: string, fetchedData: any) => {
				partialStore.handleResponse({
					actionName: "addProject",
					status: ActionStatus.success,
					code: 200,
					color: MessageColorType.blue,
					customMessage:
						"Roll forwarding has been started successfully. You can access to the new project in the cabinet you set.",
					open: true,
					// autoHide: true,
				})
			},
			handleResponse: partialStore.handleResponse,
		})
	}

	const i18n = store.i18n.combineI18n(OrgI18n.projects, "CreateProjectDialog")
	const clientList = partialStore.clientsByGroupOptions(groupId)

	const rfStatus = partialStore.getActionStatus("addProject")

	return (
		<PageContainer fixedHeight hasToolbar={false}>
			<StyledProjectRollForward>
				<h2>Roll Forward</h2>
				<BreadCrumbsAndOptions
					path={path}
					setPath={partialStore.setPathForSelection}
					options={crumbOptions}
					getLocationDetail={getLocationDetail}
				/>
				<div className="input-section FR">
					<InputWithLabel
						label={i18n.twClient}
						eleClassName="with-label"
						required
					>
						{pathLength <= 1 ? (
							<div className="request-required-field">
								Please set location first
							</div>
						) : (
							<div>
								<DLComboBox
									eleDisabled={
										rfStatus === ActionStatus.loading
									}
									eleTestId="client-input-field"
									withoutLabel
									placeholder={i18n.tsSelectClient}
									options={clientList}
									filterOptions={createFilterOptions({
										stringify: (option: any) =>
											option.name + option.aliasId,
									})}
									renderOption={(
										option: any,
										{ inputValue }: { inputValue: any }
									) => {
										const matches_name = match(
											option.name,
											inputValue
										)
										const parts_name = parse(
											option.name,
											matches_name
										)
										const matches_aliasId = match(
											option.aliasId,
											inputValue
										)
										const parts_aliasId = parse(
											option.aliasId,
											matches_aliasId
										)
										return (
											<div className="FR AC">
												<div
													style={{
														marginRight: "0.4rem",
													}}
												>
													{parts_name.map(
														(part, i) => (
															<span
																key={i}
																style={{
																	fontWeight:
																		part.highlight
																			? 700
																			: 400,
																}}
															>
																{part.text}
															</span>
														)
													)}
												</div>

												<div
													style={{
														marginRight: "1rem",
														opacity: 0.8,
													}}
												>
													(
													{parts_aliasId.map(
														(part, i) => (
															<span
																key={i}
																style={{
																	fontWeight:
																		part.highlight
																			? 700
																			: 400,
																}}
															>
																{part.text}
															</span>
														)
													)}
													)
												</div>
											</div>
										)
									}}
									onChange={handleClientInput}
									eleValue={
										inputs.clientAliasId &&
										inputs.clientName
											? {
													aliasId:
														inputs.clientAliasId,
													name: inputs.clientName,
											  }
											: undefined
									}
								/>
							</div>
						)}
					</InputWithLabel>
				</div>
				{/* <div className="FR"> */}
				{/* <div>RF Target information (pending)</div> */}
				<div>
					<div className="FR rf-option">
						<DLCheckbox
							eleClassName="rf-option-checkbox"
							eleTestId="copy-content"
							eleName="copyContents"
							clickHandler={(event: any) =>
								handleCheckbox(event.target.name)
							}
							isChecked={inputs.copyContents}
							eleDisabled={rfStatus === ActionStatus.loading}
						/>
						<div>{i18n.tpRfOption1}</div>
					</div>
					<div className="help-text" style={{ paddingLeft: "2rem" }}>
						<div className="FR">
							<div
								style={{
									fontWeight: 700,
									paddingTop: "0.8rem",
								}}
							>
								Note
							</div>
							<ol>
								<li>{i18n.tpRfNote1}</li>
								<li>{i18n.tpRfNote2}</li>
								<li>{i18n.tpRfNote3}</li>
							</ol>
						</div>
					</div>
					<div className="FR rf-option">
						<DLCheckbox
							eleName="copyUsers"
							eleClassName="rf-option-checkbox"
							eleTestId="copy-users"
							clickHandler={(event: any) =>
								handleCheckbox(event.target.name)
							}
							isChecked={inputs.copyUsers}
							eleDisabled={rfStatus === ActionStatus.loading}
						/>
						<div>{i18n.tpRfOption2}</div>
					</div>
					<div className="FR rf-option">
						<DLCheckbox
							eleClassName="rf-option-checkbox"
							eleTestId="copy-fs"
							eleName="moveFsDataAsPy"
							clickHandler={(event: any) =>
								handleCheckbox(event.target.name)
							}
							isChecked={inputs.moveFsDataAsPy}
							eleDisabled={rfStatus === ActionStatus.loading}
						/>
						<div>{i18n.tpRfOption3}</div>
					</div>
				</div>
				{/* </div> */}
				<div style={{ height: "3rem" }} />
				<DLButton
					eleTestId="create-rf-project"
					clickHandler={handleCreateRFProject}
					color="primary"
					disabled={
						pathLength <= 1 ||
						inputs.clientId === "" ||
						rfStatus === ActionStatus.loading
					}
				>
					Roll Forward
				</DLButton>
			</StyledProjectRollForward>
			<CommonSnackbarComponent
				i18n={{ x: "x" }}
				partialStore={partialStore}
			/>
		</PageContainer>
	)
})

const StyledProjectRollForward = styled.div`
	padding: 1rem;
	.input-section {
		margin-bottom: 1rem;
		.label {
			flex-basis: 10rem;
		}
		.input-area {
			width: 100%;
		}
	}
`
