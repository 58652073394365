import { handleExport } from "../../../../../components/basic-elements/export/ExportXLSX"

const ZipUrlListRelated = (self: any) => ({
	getZipUrlTargetList(list: any) {
		const projects = list
		self.resetZipUrlList()

		projects.map((item: any) => {
			const {
				id,
				title,
				aliasId,
				clientName,
				epUsers,
				qcUsers,
				financialYear,
				periodName,
				periodEndDate,
				archivedAt,
				version,
				engTypeName,
			} = item

			let newEPs = ""
			let newQCs = ""

			const epLength = epUsers ? epUsers.length : 0
			const qcLength = qcUsers ? qcUsers.length : 0

			if (epLength > 0) {
				epUsers.map((ep: any, i: number) => {
					if (i === 0) {
						newEPs = ep.name
					} else if (i > 0) {
						newEPs = newEPs + ", " + ep.name
					}
				})
			}

			if (qcLength > 0) {
				qcUsers.map((qc: any, i: number) => {
					if (i === 0) {
						newQCs = qc.name
					} else if (i > 0) {
						newQCs = newQCs + ", " + qc.name
					}
				})
			}

			const simpleItem = {
				id,
				title,
				aliasId,
				clientName,
				epUsers: newEPs,
				qcUsers: newQCs,
				year: financialYear,
				periodType: periodName,
				periodEndDate,
				archivedAt,
				version,
				engType: engTypeName,
				//
				zipUrl: "",
			}
			self.pushZipUrlList(simpleItem)
		})
		console.log("zipUrlList Base", self.zipUrlList)
	},
	getZipUrls() {
		const list = self.zipUrlList
		const updateFunction = self.addZipUrl
		self.relayGetArchivedZipUrl(list, 0, updateFunction)
	},
	downloadZipUrlList() {
		const contents = self.viewZipUrlList

		console.log("current zip url list", contents)

		let proceed = window.confirm("Do you want to download this list?")
		if (!proceed) {
			return
		} else {
			handleExport(contents, "Zip-URL-List")
		}
	},
})

export default ZipUrlListRelated
