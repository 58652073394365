import { flow } from "mobx-state-tree"
import { getRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

/**
 * For comment list dialog on the wp or pf
 * (get all comments of the object)
 */
const ReadCommentsByObj = (self: any) => ({
	readCommentsByObj: flow(function* ({
		objectId,
		menuId,
	}: {
		objectId: string
		menuId: DLProjSubMenus
	}) {
		//
		const actionName = "readCommentsByObj"
		//
		const numberId = idToNumber(objectId, IdType.file) // TODO: is IdType.file is okay...?
		let endpoint = ""
		if (menuId === DLProjSubMenus.wp) {
			endpoint = "/GetWprReviewComments"
		} else if (menuId === DLProjSubMenus.pf) {
			endpoint = "/GetPermanentReviewComments"
		}

		if (typeof numberId === "number" && endpoint !== "") {
			try {
				const params = {
					wprId: numberId,
				}
				const response = yield getRequest({
					url: endpoint,
					params,
					tokenType: TOKEN_TYPE.projToken,
				})
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.error(actionName, "__error", error, error.response)
				self.handleModelError({ actionName, error, open: false })

				return false
			}
		} else {
			alert(
				`(${actionName}) Invalid props exist. endpoint: ${endpoint} | ${numberId}`
			)
		}

		//

		//
	}),
})

export default ReadCommentsByObj
