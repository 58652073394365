import React, { useEffect } from "react"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import PageToolbar from "./PageToolBar"
import { observer } from "mobx-react-lite"
import HandlePageToolBarIcon from "./HandlePageToolBarIcon"
import { DLSpinner } from "../../basic-elements"

export interface Props {
	pageTools?: any
	children?: any
	fullWidth?: boolean
	fixedHeight?: boolean
	hasToolbar?: boolean
	defaultToolbarStatus?: "collapsed" | "expanded"
	eleClassName?: string
	noPadding?: boolean
	eleStyle?: { [x: string]: any }
	isLoading?: boolean
}

export default observer(function PageContainer({
	pageTools,
	children,
	fullWidth = false,
	fixedHeight = false,
	hasToolbar = true,
	defaultToolbarStatus = "collapsed",
	eleClassName,
	noPadding,
	eleStyle,
	isLoading,
}: Props) {
	// ---------- store setting
	const store = useRootStore()

	useEffect(() => {
		defaultToolbarStatus === "collapsed"
			? store.ui.setPageToolbarOpen(false)
			: store.ui.setPageToolbarOpen(true)
	}, [])
	//

	let clsName = ""
	if (noPadding) {
		clsName = "page-contents-area noPadding"
	} else {
		if (fullWidth && fixedHeight) {
			clsName = "page-contents-area stretched fixedHeight"
		} else if (fullWidth && !fixedHeight) {
			clsName = "page-contents-area stretched"
		} else if (!fullWidth && fixedHeight) {
			clsName = "page-contents-area fixedHeight"
		} else {
			clsName = "page-contents-area"
		}
	}

	useEffect(() => {
		store.ui.setShowTabs(true)
	}, [])

	let contentHeight = store.ui.contentsAreaHeight

	return (
		<StyledPageContainer
			className={`page-container ${eleClassName}`}
			contentsAreaHeight={store.ui.currentPageContentsAreaHeight}
			contentsAreaWidth={store.ui.contentsAreaWidth} // Do not remove this line. it is required to set the width of the container
		>
			{hasToolbar && (
				<>
					<HandlePageToolBarIcon />
					<PageToolbar tools={pageTools} />
				</>
			)}
			{isLoading ? (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: contentHeight }}
				>
					<DLSpinner />
				</div>
			) : (
				<div
					// style={noPadding ? { padding: 0 } : {}}
					style={eleStyle}
					className={clsName}
				>
					{children}
				</div>
			)}
		</StyledPageContainer>
	)
})

interface StyledProps {
	readonly contentsAreaHeight?: number
	readonly contentsAreaWidth?: number
}

const StyledPageContainer = styled.div<StyledProps>`
	display: flex;
	flex-direction: column;
	width: ${(props) => props.contentsAreaWidth}px;
	height: calc(100vh - var(--standard-unit));
	& .page-contents-area {
		height: calc(100vh - var(--standard-unit) * 2);
		padding: 1rem var(--spacing-xl) 0;
		overflow: scroll;
		&.stretched {
			padding: 1rem 0;
		}
		&.fixedHeight {
			height: ${(props) => props.contentsAreaHeight}px;
			overflow: hidden;
		}
		&.noPadding {
			padding: 0 0 0 0;
			overflow-y: hidden;
			// This overflow-y is related with file screen. If you need to udpate this, check the impact on file tree scren first
		}
	}
`
