import { types } from "mobx-state-tree"
import { ConsoleLog } from "../../../components/basic-elements"

export type CommonSelectedItemProps = {
	id: string
	type?: string
}

export const CommonSelectedItem = types.model({
	id: "",
	type: "",
})
// NOTE: To use this shared actions, selectedItems is required on the model
export const SelectedItemsSharedActions = (self: any) => ({
	/**
	 * * selected items require the actions below
	 * - 0. setSelectedItems (reset and push new list)
	 * - 1. setSelectedItem (reset and push new one)
	 * - 2. pushSeletedItem (with duplication check)
	 * - 3. pushOrSpliceSelectedItem (for checkbox)
	 * - 4. setSelectedItemsWithKeyboard
	 * - 5. spliceSelectedItem
	 * - 6. resetSelectedItems
	 * - 7. toggle selection of all items
	 */

	// 0. set selected items
	setSelectedItems(items: CommonSelectedItemProps[]) {
		self.selectedItems.length = 0
		self.selectedItems = items
	},
	// 1. set selected item
	// NOTE: WARNING: .... Need to check that if it is duplicated or not with file-selected-items-ui.actions.ts
	setSelectedItem(item: CommonSelectedItemProps) {
		self.selectedItems.length = 0
		self.selectedItems.push(item)
	},
	// + (TBD) for left click (above one is... for right click?)
	setSelectedItemByClick(item: CommonSelectedItemProps) {
		const existenceCheck = self.selectedItems.find(
			(prevItem: any) => prevItem.id === item.id
		)
		if (existenceCheck === -1) {
			// if there is no dupliated item
			self.selectedItems.length = 0
			self.selectedItems.push(item)
		} else {
			// if there is duplicated item
			self.selectedItems.length = 0
		}
	},
	// 2. push seleted item (with duplication check)
	pushSelectedItem(item: CommonSelectedItemProps) {
		const duplicationCheck = self.selectedItems.findIndex(
			(prevItem: any) => prevItem.id === item.id
		)
		if (duplicationCheck === -1) {
			self.selectedItems.push(item)
		} else {
			return
		}
	},
	// 3. push or splice selected item (for checkbox)
	pushOrSpliceSelectedItem(item: CommonSelectedItemProps) {
		const itemIndex = self.selectedItems.findIndex(
			(prevItem: any) => prevItem.id === item.id
		)
		ConsoleLog("pushOrSpliceSelectedItem", [item, itemIndex])
		// NOTE: watchout: if you set selection related action on the row, it can be duplicated with checkbox.
		if (itemIndex === -1) {
			// duplicated item not exist = add item to the list = turn on the checkbox
			self.selectedItems.push(item)
		} else {
			// duplicated item exist = remove item from the list = turn off the checkbox
			self.selectedItems.splice(itemIndex, 1)
		}
	},
	// 4. set selected items with keyboard
	setSelectedItemsWithKeyboard({
		item,
		shiftKey,
		altKey,
	}: {
		item: CommonSelectedItemProps
		shiftKey: boolean
		altKey: boolean
	}) {
		if (shiftKey && !altKey) {
			// when click with shift key
			// self.selectedItems.push(selectedItem)
			self.pushSelectedItem(item)
		} else if (!shiftKey && altKey) {
			// when click with alt key
			// remove item from the list
			self.spliceSelectedItem(item)
		} else if (!shiftKey && !altKey) {
			// when click the file row without any key
			/**
			 *
			 * -- cases --
			 * - a-1. if there is only one selected item and the item is matched with the clicked item.
			 * ---> reset the selected items
			 * - a-2. if there is only one selected item and the item is not matched with the clicked item.
			 * ---> set selected item with new one
			 * - b-1. if there are multiple selected items, and the item is in them
			 * ---> set selected item with new one
			 * - b-2. if there are multiple selected items, and the item is not in them
			 * ---> set selected item with new one
			 * - c. if there are no selected items
			 * ---> set selected item with new one
			 *
			 * --> except a-1, set selected item with new one
			 */

			const duplicationCheck = self.selectedItems.findIndex(
				(prevItem: any) => prevItem.id === item.id
			)
			const selectedItems = self.selectedItems.length
			if (selectedItems === 1) {
				// a-1.
				if (duplicationCheck !== -1) {
					self.resetSelectedItems()
				} else {
					self.setSelectedItem(item)
				}
			} else {
				self.setSelectedItem(item)
			}
		}
	},
	// 5. splice selected item
	spliceSelectedItem(item: CommonSelectedItemProps) {
		const targetIndex = self.selectedItems.findIndex(
			(prevItem: any) =>
				prevItem.id === item.id && prevItem.type === item.type
		)
		if (targetIndex !== -1) {
			self.selectedItems.splice(targetIndex, 1)
		} else {
			alert(
				`(spliceSelectedItem) No matched selected items. item.id: ${item.id} | item.type: ${item.type}`
			)
		}
	},
	// 6. reset selected items
	resetSelectedItems() {
		self.selectedItems.length = 0
	},
	// 7. toggle selection of all items
	// NOTE: Be carefule to use this #7, or create new one which more matched with the module
	// TODO: type setting part needs update...
	toggleSelectAll(allItems: any) {
		if (self.selectedItems.length <= 0) {
			// clear one more time the list for safety
			self.selectedItems.length = 0

			allItems.map((item: any) => {
				self.selectedItems.push({ id: item.id, type: item.type })
			})
		} else {
			self.selectedItems.length = 0
		}
	},
})

export const SelectedItemsSharedViews = (self: any) => ({
	isSelected(itemId: string) {
		const targetIndex = self.selectedItems.findIndex(
			(prevItem: any) => prevItem.id === itemId
		)

		if (targetIndex !== -1) {
			return false
		} else {
			return true
		}
	},
	get viewSelectedItems() {
		return self.selectedItems
	},
	get selectedItemIdList() {
		let idList: string[] = []
		self.selectedItems.map((item: any) => {
			idList.push(item.id)
		})
		return idList
	},
	get selectedItemLength() {
		return self.selectedItems.length
	},
	get selectedFirstItemId() {
		if (self.selectedItems.legnth > 1) {
			ConsoleLog("Warning. There are multiple selected itmes.", "")
		}
		return self.selectedItems[0].id
	},
})
