import { types, flow } from "mobx-state-tree"

const FSSetupUiHelper = types
	.model({
		selectedTypeId: 0,
		selectedGroupId: 0,
		selectedSubGroupId: 0,
		//
		mapUnmapGroupDialogOpen: false,
		mapUnmapSubGroupDialogOpen: false,
		mapUnmapLeadSheetDialogOpen: false,
	})
	.actions((self) => ({
		setSelectedTypeId(typeId: number) {
			self.selectedTypeId = typeId
		},
		setSelectedGroupId(groupId: number) {
			self.selectedGroupId = groupId
		},
		setSelectedSubGroupId(subGroupId: number) {
			self.selectedSubGroupId = subGroupId
		},
		//
		setMapUnmapSubGroupDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.mapUnmapSubGroupDialogOpen = request
			} else {
				self.mapUnmapSubGroupDialogOpen = !self.mapUnmapSubGroupDialogOpen
			}
		},
		setMapUnmapGroupDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.mapUnmapGroupDialogOpen = request
			} else {
				self.mapUnmapGroupDialogOpen = !self.mapUnmapGroupDialogOpen
			}
		},
		setMapUnmapLeadSheetDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.mapUnmapLeadSheetDialogOpen = request
			} else {
				self.mapUnmapLeadSheetDialogOpen = !self.mapUnmapLeadSheetDialogOpen
			}
		},
	}))

export default FSSetupUiHelper
