import { flow } from "mobx-state-tree"

const UpdateReadOnlyUser = (self: any, updReadOnlyUser: any) => ({
	updateReadOnlyUser: flow(function*(payload) {
		const actionName = "updateReadOnlyUser"
		try {
			// const endpoint = "/UpdateAccessMngmntUsers"
			const response = yield updReadOnlyUser(payload)

			return response
		} catch (error) {
			// ConsoleLog("UpdateReadOnlyUser error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default UpdateReadOnlyUser
