import React from "react"
import { observer } from "mobx-react-lite"
import { PostType } from "@datalobby/types"
import { mdiBullhorn } from "@mdi/js"
import Icon from "@mdi/react"
import ReactMarkdown from "react-markdown"
import styled from "styled-components"

const PostContents = observer(
	({ post, contentHeight }: { post: PostType; contentHeight: number }) => {
		return (
			<StyledPostContents contentHeight={contentHeight}>
				<div className="FR AC JSB title-area">
					<div className="FR AC">
						{post.fixToTop && (
							<Icon
								path={mdiBullhorn}
								size={1}
								className="post-fixToTop-icon"
								data-testid="post-fixToTop-icon"
							/>
						)}
						<h2 className="post-title" data-testid="post-title">
							{post.title}
						</h2>{" "}
					</div>
					<div className="FR AC">
						{/* <DLChip
						eleTestId="post-priority"
						color={
							post.priority === Priority.high
								? "primary"
								: post.priority === Priority.medium
								? "secondary"
								: "default"
						}
						label={post.priority}
					/> */}
						{/* <DLChip
				eleTestId="post-who-can-read"
				label={post.whoCanRead}
			/> */}
					</div>
				</div>
				<div className="create-and-modify-info FR AC JR">
					<div data-testid="createdBy-info">
						Created By: {post.createdBy} ({post.createdAt})
					</div>
					{post.modifiedBy && (
						<div className="FR AC">
							<div className="divider">|</div>
							<div data-testid="modifiedBy-info">
								Modified By: {post.modifiedBy} (
								{post.modifiedAt})
							</div>
						</div>
					)}
				</div>
				<div data-testid="md-viewer">
					{/* NOTE: Because ReactMarkdown has no data-testid props  */}
					<ReactMarkdown
						children={post.content}
						className="md-area"
					/>
				</div>
			</StyledPostContents>
		)
	}
)

const StyledPostContents = styled.div<{ contentHeight: number }>`
	.title-area {
		padding-bottom: 1rem;
		border-bottom: 1px solid ${(props) => props.theme.shade20};
		margin-bottom: 0.4rem;
		.post-fixToTop-icon {
			color: ${(props) => props.theme.emphasis};
			margin-right: 0.6rem;
		}
		.post-title {
			color: ${(props) => props.theme.textHighEmphasis};
			font-weight: bold;
			margin-right: 1rem;
		}
	}
	.create-and-modify-info {
		opacity: 0.6;
		.divider {
			margin: 0 1rem;
		}
		font-size: 0.8rem;
		margin-bottom: 0.6rem;
	}
	.md-area {
		height: ${(props) => props.contentHeight}px;
		overflow: hidden;
		overflow-y: auto;
	}
`

export default PostContents
