import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLButton,
} from "../../../../components/basic-elements"
import sharedRegEx from "../../../../library/sharedRegEx"
import useForm from "../../../../library/use-form"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { MessageColorType } from "../../../../common-models/enumerations/common-enums"
import Icon from "@mdi/react"
import { mdiTrashCan } from "@mdi/js"

export default observer(function ArchivedProjectDeleteDialog({
	i18n,
	//
	isOpen,
	setIsOpen,
	//
	clientId,
	cabinetId,
	deleteAction,
	projectInfo,
}: {
	i18n: DLI18nProps
	//
	isOpen: boolean
	setIsOpen: any
	//
	clientId: string
	cabinetId: string
	deleteAction: (clientId: string, cabinetId: string) => void
	projectInfo?: any
}) {
	const userEmail = localStorage.getItem("lobbyUserId")
	const inputsSchema = {
		email: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email, // TODO: update is required
				error: "Invalid format",
			},
		},
	}

	const deleteProject = () => {
		deleteAction(clientId, cabinetId)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, deleteProject)

	console.log(projectInfo, "projectInfo")

	return (
		<DLDialog
			eleTestId="delete-project-dialog"
			isOpen={isOpen}
			setIsOpen={setIsOpen}
			showCloseBtn={true}
			dialogTitle={
				i18n.tsRequestVerifyEmail ||
				"Please enter your email to confirm delete"
			}
			dialogContents={
				<DeleteProjectDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
					projectInfo={projectInfo}
				/>
			}
			// actionReady={isReady || userEmail === inputs.email.value}
			maxWidth="xs"
			actionBtn={
				<DLButton
					variant="text"
					eleTestId="delete-btn-on-dialog"
					startIcon={<Icon path={mdiTrashCan} size={0.8} />}
					clickHandler={deleteProject}
					color="warning"
					disabled={!(isReady && userEmail === inputs.email.value)}
				>
					{i18n.twDelete || "Delete"}
				</DLButton>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
		/>
	)
})

const DeleteProjectDialogForm = observer(
	({
		inputs,
		handleOnChange,
		i18n,
		projectInfo,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
		projectInfo?: any
	}) => {
		const userEmail = localStorage.getItem("lobbyUserId")

		return (
			<StyledDialog>
				{projectInfo?.title && (
					<ProjectInfoContainer
						projectDetails={projectInfo}
						i18n={i18n}
					/>
				)}
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twEmail || "Email"}>
						<DLInputField
							autoFocus
							eleTestId="delete_email_input"
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.email.error}
							requestInput={inputs.email.requestInput}
						/>
						{inputs.email.value !== "" &&
							userEmail !== inputs.email.value && (
								<DLSystemMsg
									eleTestId="msg-warning-email-match"
									type={MessageColorType.red}
									msg={
										i18n.twEmailDoesNotMatch ||
										"Email does not match"
									}
								/>
							)}
					</InputWithLabel>
				</div>
			</StyledDialog>
		)
	}
)

const StyledDialog = styled.div`
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 3rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export const ProjectInfoContainer = ({
	projectDetails,
	i18n,
}: {
	projectDetails: any
	i18n: DLI18nProps
}) => {
	return (
		<StyledProjInfo>
			<h4 style={{ marginBottom: 15 }}>Target Project Info</h4>
			<InfoRow
				label={i18n.twPeriodType || "Period Type"}
				value={projectDetails.periodName}
				testId="periodType"
			/>

			<InfoRow
				label={"Year"}
				value={projectDetails.financialYear}
				testId="financialYear"
			/>

			<InfoRow
				label={i18n.twClient || "Client"}
				value={projectDetails.clientName}
				testId="client"
			/>

			<InfoRow
				label={i18n.twProjectTitle || "Project Name"}
				value={projectDetails.title}
				testId="proj-title"
			/>
			<InfoRow
				label={i18n.twProjectId || "Project ID"}
				value={projectDetails.aliasId}
				testId="proj-alias-id"
			/>
			<InfoRow
				label={"Project Type"}
				value={projectDetails.projectType || projectDetails?.statusName}
				testId="proj-type"
			/>
			<InfoRow
				label={"Version"}
				value={`v.${projectDetails.version}`}
				testId="proj-version"
			/>
		</StyledProjInfo>
	)
}

const InfoRow = ({
	label,
	value,
	testId,
}: {
	label: string
	value: any
	testId: string
}) => {
	return (
		<div className="info-row">
			<div className="label-area">{label} :</div>
			<div className="value-area" data-testid={`projDetails-${testId}`}>
				{value}
			</div>
		</div>
	)
}

const StyledProjInfo = styled.div`
	padding-bottom: 1rem;
	.info-row {
		display: flex;
		padding: 0.3rem 0 0.3rem 1rem;
		:hover {
			background-color: ${(props) => props.theme.secondary};
		}
		.label-area {
			min-width: 8rem;
		}
		.value-area {
		}
	}
	.partition {
		height: 1.8rem;
	}
`
