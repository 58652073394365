import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"

// basic menus (Updated at 2023-12-06 by Bo's request)
import basicMenus from "../../../../../admin-static-data/app-menu-table-eligibility/basicMenus.json"

const GetCustomer = (self: any) => ({
	getCustomer() {
		const actionName = "getCustomer"

		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const tempMenus = basicMenus.map((menu: any) => {
			return {
				...menu,
				index: 0,
			}
		})

		self.setAllowedMenus(tempMenus)
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.success,
		})
	},
})

export default GetCustomer
