import { flow } from "mobx-state-tree"

const ReadWPReferences = (self: any, readWP: any) => ({
	readWPReferences: flow(function*(payload: { ClientId: string }) {
		const actionName = "readWPReferences"
		try {
			const response = yield readWP(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default ReadWPReferences
