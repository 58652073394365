import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import { convertSourceFormat } from "../../../../library/converters/source-format-converter"
import { FileScreenDialog } from "../../data-models/dl-file-control-model"
import { initialDLFile } from "../../data-models/initial-file"
import { CreateFileFromLibActionProps } from "../../model-actions/file/create-file-from-lib"

/**
 * * get library groups without children(=libraries)
 * --> NOTE: No use...  let's use next one and get the children altogether
 * "/GetWPRTemplateList"
 * return file library groups
 * {
 *     Message: null,
 *     Status: 0,
 *     WPRTempName: "lib group name",
 *     WPRTemplateId: 1234
 * }[]
 */

/**
  * * get libraries and its group 
  * "/GetWorkPaperTemplateInfo"
  * NOTE: readLibrariesInProj()
  * NOTE: in organization: readLibraryFolders() @Noah: TODO: update the function name... 
  * return library groups and APG libraries
  * 
	{
		AuditTemplateListRes: {
			TempFoldID: 1234,
			TempFoldName: "library group",
			TemplateList: {
				MediaType: "AuditProgramTemplate",
				TempId: 5678,
				TempName: "APG library"
			}[]
		}[],
		TemplateListRes: {
			TempFoldID: 1234, 
			TempFoldName: "library group",
			TemplateList: {
				MediaType: "WorkpaperTemplate",
				TempId: 9012,
				TempName: "File library"
			}[]
		}[],
		Message: "",
		OutputString: null,
		Status: 1
	}
  * 
  */

/**
 * * get normal library files (except the APG library)
 * "/GetWPRTemplateFileList"
 * NOTE: readLibFiles()
 * return normal files in the library group
 * {
 *    MediaType: "XBRL"
 *    Message: null
 *    Status: 0
 *    WPRFileID: 1041
 *    WPRFileName: "format-xml"
 * }[]
 *
 */

/**
 * * add file from library (for both normal and apg)
 * "/CreateWPRTemplateInWPR"
 * post type request to add files from library
	payload = {
		ClientID: 5743
		FinancialYear: "2021-02-26"
		ParentMediaID: 303403
		TemplateFileID: 1041 // --> NOTE: for apg: this ID is APG library ID 
		UserID: 933
		WPDesc: "format-xml"
		WPEF: "from-wp-template"
		prepare: true
		review: true
		signOffType: [1, 2]
	}
 */

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */

const AddFileFromLib = (self: any) => ({
	addFileFromLib(props: CreateFileFromLibActionProps) {
		// 0.
		const actionName = "addFileFromLib"
		// 1.
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 111,
			color: MessageColorType.green,
			open: true,
		})
		// 3.

		self.createFileFromLib(props)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const data = response.data
					const newItem = {
						...initialDLFile,
						id: idToString(data.WPRID, IdType.file),
						parentId: idToString(data.ParentMediaID, IdType.folder), // or props.parentId
						aliasId: props.fileAliasId,
						title: props.fileName,
						extension: data.Extension,
						menuId: self.storeName,
						srcFormat: convertSourceFormat(
							data.MediaType.toLowerCase(),
							"",
							"add-file-from-lib"
						),
						path: data.FilePath,
						srcId: idToString(data.MediaID, IdType.src),
						index: 999,
					}
					self.pushFlatItem(newItem)
					// close the dialog, open the parent folder
					self.setFileTreeDialogOpen(
						FileScreenDialog.createFile,
						false
					)
					self.toggleExpanded(newItem.parentId, true)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddFileFromLib
