import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import PageContainer from "../../../../components/app-frame-elements/sub-components/PageContainer"
import styled from "styled-components"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import { ProjectStatus } from "../../../../common-models/enumerations/project-related-enums"
import { ProjScreenType } from "../../../../components/combined-elements/projects-table/projects-table-props"
import SharedProjectTableV8 from "../../../../components/combined-elements/projects-table/SharedProjectTableV8"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"
import {
	getDefaultFinancialYear,
	getPageSettings,
	savePageSetting,
} from "../../../../library/get-page-settings"
import { IconPlaceholder } from "../../../../components/basic-elements/icons/common-icons"
import { DLContextMenu } from "../../../../components/basic-elements"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import ProjectDetailsDialog from "../../../../components/combined-elements/project-dialogs/ProjectDetailsDialog"

export default observer(function ForceArchivedController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.assignedForceArchivedProjects
	const projStatus = ProjectStatus.forceArchived
	const actionName = "getOrgProjectList_" + projStatus
	//
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const needRefresh = orgStore.projects.needRefreshForAssignedForceArchived
	const i18nSheet = OrgI18n.projects

	useEffect(() => {
		const defaultYear = getDefaultFinancialYear(menuId)

		if (menuAccess && needRefresh) {
			orgStore.projects.getOrgProjectList({
				projStatus,
				year: defaultYear,
				PTMAssignedOnly: true,
				menuId,
				ForROAM: false,
			})
		}
	}, [menuAccess, needRefresh])

	const loadingStatus = orgStore.projects.getActionStatus(actionName)
	const i18n = store.i18n.combineI18n(i18nSheet)
	const clickPointExist = orgStore.projects.clickPoint.mouseX !== null

	const projId = orgStore.projects.selectedProj
	const projNumId = idToNumber(projId, IdType.project)

	const projectInfo = orgStore.projects.viewSelectedProjInfo(
		projId,
		projStatus
	)

	return (
		<>
			{menuAccess ? (
				<PageContainer
					pageTools={<div />}
					hasToolbar={false}
					fullWidth
					fixedHeight
					isLoading={loadingStatus === "LOADING"}
				>
					<StyledNormalProjects>
						{loadingStatus === "SUCCESS" && (
							<SharedProjectTableV8
								partialStore={orgStore.projects}
								i18n={i18n}
								// common part 2
								projScreenType={ProjScreenType.forceArchived}
								menuId={
									DLOrgSubMenus.assignedForceArchivedProjects
								}
								projectStatus={ProjectStatus.forceArchived}
								permission={permission}
							/>
						)}
					</StyledNormalProjects>
					{clickPointExist && (
						<DLContextMenu
							eleId="force-archive-ctx-menus"
							clickPoint={{
								mouseX: orgStore.projects.clickPoint.mouseX,
								mouseY: orgStore.projects.clickPoint.mouseY,
							}}
							handleClose={() =>
								orgStore.projects.setClickPoint({
									mouseX: null,
									mouseY: null,
								})
							}
							menuOptions={[
								{
									label: "Open",
									value: "open",
									available:
										projectInfo?.accessible &&
										permission.read,
									isLink: true,
									linkTo: `/project/checkin/${projNumId}`,
									icon: <IconPlaceholder />,
								},
								{
									label: "Project Information",
									value: "ctx-proj_info",
									disabled: !permission.read,
									// available: permission.read,
									clickEvent: () =>
										orgStore.projects.setProjectDetailsDialogOpen(
											true
										),
									icon: <IconPlaceholder />,
								},
							]}
						/>
					)}
					{orgStore.projects.projectDetailsDialogOpen && (
						<ProjectDetailsDialog
							partialStore={orgStore.projects}
						/>
					)}
				</PageContainer>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})

const StyledNormalProjects = styled.div`
	padding: 0 1rem;
`
