import { flow } from "mobx-state-tree"
import { AmUnarchivedApiProps } from "../am-unarchived-projects.apis"
// TODO: Consider to merge with OrgProject (common project model)

const ReadAmUnarchivedProjectList = (
	self: any,
	read: AmUnarchivedApiProps["apiRead"]
) => ({
	readAmUnarchivedProjectList: flow(function* (year, searchText) {
		const actionName = "readAmUnarchivedProjectList"
		const numberYear = year === "All Years" ? 0 : parseInt(year)
		try {
			const payload = {
				// UserId: 16,
				// CustomerID: 1,
				// EngagementTypeID: 0,
				// GroupID: 0,
				// EpUserID: 0,
				// QCUserID: 0,
				// ClientID: 0,
				Year: numberYear,
				SearchText: searchText === undefined ? "" : searchText,
				// PageIndex: 1,
				PageSize: 1000,
				usage: 2,
				// periodID: 0
			}
			const response = yield read(payload)
			// ConsoleLog(
			// 	"readArchiveManagementArchivedProjectList response",
			// 	response
			// )
			return response
		} catch (error) {
			// ConsoleLog("readArchiveManagementArchivedProjectList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default ReadAmUnarchivedProjectList
