import axios, { Method } from "axios"
import {
	APIProps,
	TOKEN_TYPE,
	setTokenForAxiosAuth,
} from "./shared-for-api-requests"
/**
 * from axiox documentation
 * -  `params` are the URL parameters to be sent with the request
 *     Must be a plain object or a URLSearchParams object
 * -  `data` is the data to be sent as the request body
 * 		Only applicable for request methods 'PUT', 'POST', 'DELETE , and 'PATCH'
 * 		When no `transformRequest` is set, must be of one of the following types:
 * 		- string, plain object, ArrayBuffer, ArrayBufferView, URLSearchParams
 * 		- Browser only: FormData, File, Blob
 * 		- Node only: Stream, Buffer
 *
 */
const CancelToken = axios.CancelToken
// const source = CancelToken.source()

// const allowAllOrigin = process.env.REACT_APP_ALLOW_ALL_ORIGIN

export const getRequest = ({
	url,
	params = {},
	// payload, GET request cannot use payload (data)
	tokenType,
	version,
	timeout,
}: APIProps) => {
	if (tokenType === TOKEN_TYPE.hardCopy) {
		// NOTE: for Hard copy APIs
		let method: Method = "GET"
		const hardCopyApiConfig = {
			method,
			url: process.env.REACT_APP_HARD_COPY_ENDPOINT + url,
			headers: {
				"x-api-key": process.env.REACT_APP_HARD_COPY_API_KEY,
			},
			// withCredentials: allowAllOrigin !== "*",
			cancelToken: new CancelToken(function (cancel) {}),
			timeout: timeout || 5000,
			params,
		}
		return axios(hardCopyApiConfig)
		//
		//
		//
		//
		//
		//
		//
		// } else if (tokenType === TOKEN_TYPE.adminApp) {
		// 	let method: Method = "GET"
		// 	const adminAppAxiosConfig = {
		// 		method,
		// 		url: process.env.REACT_APP_ADMIN_APP_API_ENDPOINT + url,
		// 		headers: {
		// 			"x-api-key": process.env.REACT_APP_ADMIN_APP_API_KEY,
		// 		},
		// 		// withCredentials: allowAllOrigin !== "*",
		// 		cancelToken: new CancelToken(function (cancel) {}),
		// 		timeout: timeout || 5000,
		// 		params,
		// 	}
		// 	return axios(adminAppAxiosConfig)
		//
		//
		//
		//
		//
		//
		//
	} else if (tokenType === TOKEN_TYPE.cdkBackend) {
		// NOTE: for CDK Backend
		// TODO: Which token I can use on here... Cognito?

		let method: Method = "GET"
		const config = {
			method,
			url: process.env.REACT_APP_CDK_BACKEND_RESTAPI + url,
			headers: {
				"x-api-key": process.env.REACT_APP_CDK_BACKEND_RESTAPI_KEY,
			},
			// withCredentials: allowAllOrigin !== "*",
			cancelToken: new CancelToken(function (cancel) {}),
			timeout: timeout || 5000,
			params,
		}
		return axios(config)
		//
		//
		//
		//
		//
		//
		//
	} else {
		// Others (default, and.. etc)
		setTokenForAxiosAuth(tokenType)
		// params.cancelToken = source.token
		// for 'Access-Control-Allow-Origin'
		if (params.headers) {
			params.headers.withCredentials = true
		} else {
			params.headers = { withCredentials: true }
		}

		if (version === "v2") {
			return axios.get(process.env.REACT_APP_API_ENDPOINT_V2 + url, {
				params,
			})
		} else {
			return axios.get(process.env.REACT_APP_API_ENDPOINT + url, {
				params,
			})
		}
	}
}
