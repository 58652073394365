import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import styled from "styled-components"
import {
	DLSpinner,
	DLButton,
	DLSingleSelect,
	InputWithLabel,
} from "../../../../../components/basic-elements"
import { OrgSysSettingItemProps } from "../../../../../service-modules/sys-setting-module/sys-setting.data-model"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import CommonSnackbarComponent from "../../../../../components/combined-elements/snackbar/CommonSnackbarComponent"

export default observer(function OtherSettings({
	partialStore,
	actionStatus,
	permission,
	i18n,
}: {
	partialStore: any
	actionStatus: ActionStatus
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const [hasPBCUpload, setHasPBCUpload] = useState<OrgSysSettingItemProps>(
		partialStore.hasPBCUpload
	)

	const handleHasPBCUpload = (event: any) => {
		let tempData = { ...hasPBCUpload }
		tempData.uiValue = event.target.value
		tempData.value = event.target.value
		// ConsoleLog(tempData)
		setHasPBCUpload(tempData)
	}

	const handleSubmit = () => {
		partialStore.editOtherSettings({
			hasPBCUpload: hasPBCUpload,
		})
	}

	const handleCancel = () => {
		setHasPBCUpload(partialStore.hasPBCUpload)
	}

	const { twOtherSettings, twSave, twCancel, tsHasPBCUpload } = i18n
	return (
		<StyledOtherSetting>
			<div className="panel-title">{twOtherSettings}</div>
			{actionStatus === "LOADING" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{actionStatus === "SUCCESS" && (
				<div className="FR panel-contents-container">
					<div className="FC settings-container">
						<div className="setting-section FR AC">
							<InputWithLabel
								label={tsHasPBCUpload}
								eleClassName="label-and-input-container"
							>
								<DLSingleSelect
									eleTestId="has-pbc-upload"
									eleValue={hasPBCUpload?.value}
									eleFullWidth
									withLabel={false}
									optionList={[
										{ label: i18n.twYes, value: "Yes" },
										{ label: i18n.twNo, value: "No" },
									]}
									eleOnChange={handleHasPBCUpload}
								/>
							</InputWithLabel>
						</div>
						{/* --------- Save button --------- */}
						{permission.update && (
							<div className="FR JSA JC">
								<DLButton
									color="primary"
									eleTestId="noti-setting-others-cancel-btn"
									clickHandler={() => handleCancel()}
									disabled={
										partialStore.hasPBCUpload.value ===
										hasPBCUpload?.value
									}
								>
									{twCancel}
								</DLButton>{" "}
								<DLButton
									color="primary"
									eleTestId="noti-setting-others-save-btn"
									clickHandler={() => handleSubmit()}
									disabled={
										partialStore.hasPBCUpload.value ===
										hasPBCUpload?.value
									}
								>
									{twSave}
								</DLButton>
							</div>
						)}
					</div>
				</div>
			)}
			{/* Snackbar */}
			<CommonSnackbarComponent
				i18n={i18n}
				partialStore={partialStore}
			/>
		</StyledOtherSetting>
	)
})

const StyledOtherSetting = styled.div`
	width: 100%;
	.panel-title {
		height: ${(props) => props.theme.shared.baseUnit};
		font-weight: 900;
	}
	.settings-container {
		margin-right: 4rem;
		.setting-section {
			margin-bottom: 1rem;
			.label-and-input-container {
				min-width: 20rem;
				.label {
					width: 20rem;
				}
				.input-area {
					width: calc(100% - 8rem);
				}
			}

			.example {
				margin-left: 2rem;
				color: ${(props) => props.theme.shade40};
				padding-bottom: 1rem;
			}
		}
	}
`
