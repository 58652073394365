import React from "react"
import { useHistory } from "react-router-dom"
import LobbySideProfileButton from "./LobbySideProfileButton"
import OrgSideProfileButton from "./OrgSideProfileButton"
import ProjSideProfileButton from "./ProjSideProfileButton"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import Menu from "@material-ui/core/Menu"
import MenuItem from "@material-ui/core/MenuItem"
import styled from "styled-components"
import { DLSnackbar } from "../../../../components/basic-elements"
import { DLAvatar } from "@datalobby/components"
import { observer } from "mobx-react-lite"
import ChangePwController from "../../../../screens/lobby-side/my-account/ChangePwController"
import { useLobbyStore } from "../../../../stores/lobby-store/lobby-store.provider"
import { capitalizeFirstLetter } from "@datalobby/common"

export default observer(function ProfileMenus() {
	const store = useRootStore()
	const lobbyStore = useLobbyStore()
	const history = useHistory()
	const bgColor = store.ui.getThemeValue("background")
	const txtColor = store.ui.getThemeValue("textMediumEmphasis")

	const handleLogout = () => {
		history.replace("/lobby-checkout")
	}

	const currentView = history.location.pathname.slice(1, 4) // lob, org, pro

	const [anchorEl, setAnchorEl] = React.useState<null | HTMLElement>(null)
	const open = Boolean(anchorEl)

	const handleOpen = (event: React.MouseEvent<HTMLElement>) => {
		event.preventDefault()
		setAnchorEl(event.currentTarget)
	}

	const handleClose = () => {
		setAnchorEl(null)
	}

	const handleClick = (option: any) => {
		option.clickEvent()
		handleClose()
	}

	const menuOptions = (i18n: any) => [
		{
			label: i18n.twMyAccount,
			value: "myAccount",
			available: currentView !== "lob",
			disabled: false,
			icon: undefined,
			clickEvent: () =>
				history.replace("/organization/my-page/my-information"),
		},
		{
			/**
			 * This menu requires change password dialog in the lobby / org / proj : TODO: find better method --> Let's add 'my page' in the lobby.
			 */
			label: i18n.twChangePw,
			value: "changePassword",
			available: true,
			disabled: false,
			icon: undefined,
			clickEvent: () => lobbyStore.my.setChangePwDialogOpen(true),
		},
		{
			label: i18n.twLogout,
			value: "logout",
			available: true,
			disabled: false,
			icon: undefined,
			clickEvent: handleLogout,
		},
	]

	const i18n = store.i18n.globalI18n("profileMenus")
	return (
		<StyledProlfileMenu>
			<div onClick={handleOpen} data-testid="profile-menu-btn">
				{currentView === "lob" ? (
					<LobbySideProfileButton />
				) : currentView === "org" ? (
					<OrgSideProfileButton />
				) : currentView === "pro" ? (
					<ProjSideProfileButton />
				) : (
					<div />
				)}
			</div>

			<Menu
				anchorEl={anchorEl}
				keepMounted
				open={open}
				onClose={handleClose}
				data-testid="profile-menu"
				className="menu-component"
				PaperProps={{
					style: {
						// maxHeight: ITEM_HEIGHT * 4.5,
						width: "none",
						minWidth: 200,
						maxWidth: 224,
					},
				}}
				MenuListProps={{
					style: {
						backgroundColor: bgColor,
						color: txtColor,
					},
				}}
			>
				<div className="profile FR AC JC" style={{ minHeight: 160 }}>
					<div
						className="FC AC info-area"
						style={{ padding: "1rem" }}
						data-testid="signin-info-container"
					>
						<DLAvatar eleTestId="profile-avatar-big" size={80} />
						{/* {store.checkin.defaultEmail} */}
						<div
							className="user-name"
							data-testid="signin-username"
						>
							{store.checkin.userName}
						</div>
						<div className="user-aliasId">
							{/* {store.checkin.shortName} */}
						</div>
						<p
							className="user-email"
							style={{
								whiteSpace: "pre-wrap",
								wordBreak: "break-all",
								width: 160,
							}}
							data-testid="signin-email"
						>
							{store.checkin.defaultEmail}
						</p>
					</div>
				</div>
				{menuOptions(i18n).map((option, index) => {
					const { value, available, disabled, icon, label } = option
					return (
						<div key={value + index}>
							{available && (
								<div className="FC">
									<MenuItem
										onClick={() => handleClick(option)}
										disabled={disabled}
										className="menu-item FR AC"
										data-testid={option.value}
									>
										<div
											className="FR"
											style={{ marginRight: 4 }}
										>
											{icon}
										</div>
										<div>
											{capitalizeFirstLetter(label, true)}
										</div>
									</MenuItem>
								</div>
							)}
						</div>
					)
				})}
			</Menu>
			{lobbyStore.my.changePwDialogOpen && <ChangePwController />}
			<DLSnackbar
				type={lobbyStore.my.responseSnackbar.type}
				open={lobbyStore.my.responseSnackbar.open}
				setOpen={lobbyStore.my.responseSnackbar.setOpen}
				anchorLocation="RIGHT_TOP"
				message={lobbyStore.my.responseSnackbar.message}
				autoHideDuration={
					lobbyStore.my.responseSnackbar.autoHideDuration
				}
				autoHide={lobbyStore.my.responseSnackbar.autoHide}
			/>
		</StyledProlfileMenu>
	)
})

const StyledProlfileMenu = styled.div``
