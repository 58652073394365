import { DLProjSubMenus } from "../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../library/api-requests/shared-for-api-requests"
import { getRequest, putRequest, postRequest } from "../../library/api-requests"

/**
 * ----------------------------------------
 * These apis where used in workpaper,permanent files and archive diagnosis
 */

export async function apiReadOpenedFiles(params: { Type: number }) {
	const response = await getRequest({
		url: "/GetOpenedFilesList",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

type CreateRequest = {
	ClientID: number
	CreatedDate: string
	MediaID: string
	Notes: string
	UserID: number
}

export async function apiCreateRequestNotification(
	payload: CreateRequest[],
	menuId?: DLProjSubMenus
) {
	let endpoint = ""
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/WorkPaperRequestNotifications"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/SendPfRequestNotifications"
	} else {
		alert(
			`(api - requestCheckinFile) menu type is unknown. menu: ${menuId}`
		)
	}
	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

type DeleteOpenedFile = {
	MediaID: string
	Type: number
	UserID: number
}

export async function apiDeleteOpenedFiles(
	payload: DeleteOpenedFile[],
	menuId?: DLProjSubMenus
) {
	let endpoint = ""
	if (menuId === DLProjSubMenus.wp) {
		endpoint = "/WorkPaperOpenedFilesForceClose"
	} else if (menuId === DLProjSubMenus.pf) {
		endpoint = "/PfOpenedFilesForceClose"
	} else {
		alert(
			`(api - discardFileCheckout) menu type is unknown. menu: ${menuId}`
		)
	}
	const response = await putRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type OpenedFilesApiProps = {
	apiReadOpenedFiles?: (payload: {
		Type: number
	}) => Promise<AxiosResponse<any>>
	apiDeleteOpenedFiles?: (
		payload: DeleteOpenedFile[],
		type?: DLProjSubMenus
	) => Promise<AxiosResponse<any>>
	apiCreateRequestNotification?: (
		payload: CreateRequest[],
		type?: DLProjSubMenus
	) => Promise<AxiosResponse<any>>
}
