import { types, applySnapshot } from "mobx-state-tree"
import { DLProjSubMenus } from "../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

export type OpenedMenuProps = {
	id: string // DLProjSubMenus
	title: string
	url: string
}

const OpenedMenu = types.model({
	id: types.string, // types.enumeration<DLProjSubMenus>(Object.values(DLProjSubMenus)),
	title: types.string,
	url: types.string,
})

// main Model of the project default UI store
const ProjMenuTabsStore = types
	.model({
		currentMainMenu: types.string,
		// NOTE: use types.string for final anlaysis
		// currentMainMenu: types.enumeration<DLProjSubMenus>(Object.values(DLProjSubMenus)),
		openedMenuList: types.array(OpenedMenu),
	})
	.actions((self) => ({
		setCurrentMainMenu(menuId: DLProjSubMenus | string) {
			// NOTE: allow string for final anlaysis...
			self.currentMainMenu = menuId
		},
		addOpenedMenu(newItem: OpenedMenuProps) {
			self.currentMainMenu = newItem.id
			if (
				self.openedMenuList.find(
					(element) => element.id === newItem.id
				) === undefined
			) {
				self.openedMenuList.push({
					id: newItem.id,
					title: newItem.title,
					url: newItem.url,
				})
			}
		},
		removeOpenedMenu(requestedItem: any) {
			function findTarget(element: any) {
				return element.id === requestedItem
			}
			const targetIndex = self.openedMenuList.findIndex(findTarget)
			// if (self.openedMenuList[targetIndex].isSelected === true) {
			// 	self.openedMenuList[targetIndex - 1].isSelected = true
			// }
			self.openedMenuList.remove(self.openedMenuList[targetIndex])
		},
	}))
	.views((self) => ({
		getOpenedMenuIndex(menuId: string) {
			function findTarget(element: any) {
				return element.id === menuId
			}
			return self.openedMenuList.findIndex(findTarget)
		},
		get currentMenuIndex() {
			function findTarget(element: any) {
				return element.id === self.currentMainMenu
			}
			return self.openedMenuList.findIndex(findTarget)
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))

const initialStore = {
	currentMainMenu: DLProjSubMenus.proj_info,
	openedMenuList: [
		{
			id: DLProjSubMenus.proj_info,
			title: "PROJECT INFORMATION",
			url: "project-information",
		},
	],
}

const ProjMenuTabsStoreInstance = ProjMenuTabsStore.create(initialStore)

export default ProjMenuTabsStoreInstance
