import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	deleteRequest,
	postRequest,
} from "../../../../library/api-requests"

type ActivateProps = {
	UserId: number
	Type?: number
	ExpiryDate?: string
}

export async function activate(params: ActivateProps) {
	const endpoint = "/ActivateUser?UserId=" + params.UserId
	const response = await postRequest({
		url: endpoint,
		// params: params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function deactivate(userId: number) {
	const endpoint = "/DeActivateUser?UserId=" + userId
	const response = await postRequest({
		url: endpoint,
		// params: params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type AssignProps = {
	AccessRightsID: number
	ClientId: number // projectId
	CreatedBy: number
	ExpirationDate: null | string
	RoleId: number
	UserId: number
	isRollForward: 1 | 0
	isVerified: boolean
}
export async function assign(payload: AssignProps) {
	const endpoint = "/CreateAssignedUser"
	const response = await postRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type ReadGroupUsersProps = {
	Type: number
	groupId: number
}

export async function readGroupUsers(params: ReadGroupUsersProps) {
	const endpoint = "/GetGroupUsersByProject"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type UpdateParamProps = {
	UserID: number
	RoleId: number
	ClientID: number // project ID
	UserName?: string
	ModifiedBy: number
	AccessRightID: number
	ExpirationDate: null | string
	isVerified: boolean
	isRollForward: 0 | 1
}

export async function update(params: UpdateParamProps) {
	const endpoint = "/EditUsers"
	const response = await putRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type UnassignPayloadProps = {
	UserId: number
	ClientID: number
	FinancialYear: number
	UnassignedBy: number
}

export async function unassign(payload: UnassignPayloadProps) {
	const endpoint = "/UnassignUser"
	const response = await deleteRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function validatePrevRole(params: { userId: number }) {
	const endpoint = "/ValidateUserRole"

	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ProjSetupUserApiProps = {
	apiActivate?: (params: ActivateProps) => Promise<AxiosResponse<any>>
	apiDeactivate?: (userId: number) => Promise<AxiosResponse<any>>
	apiAssign?: (payload: AssignProps) => Promise<AxiosResponse<any>>
	apiRead?: () => Promise<AxiosResponse<any>>
	apiReadGroupUsers?: (
		params: ReadGroupUsersProps
	) => Promise<AxiosResponse<any>>
	apiUpdate?: (params: UpdateParamProps) => Promise<AxiosResponse<any>>
	apiUnassign?: (payload: UnassignPayloadProps) => Promise<AxiosResponse<any>>
	apiValidatePrevRole: (params: {
		userId: number
	}) => Promise<AxiosResponse<any>>
}
