import React, { useState } from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import DLTreeNavParentRow from "../../../../../components/combined-elements/tree-nav/DLTreeNavParentRow"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { mdiAlphaPCircle } from "@mdi/js"
import { Icon } from "@mdi/react"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function ArchPolicyRowController({
	node,
	handleRightClick,
	canDrag,
}: {
	node: any
	handleRightClick: (e: any, id: string, on: "policy" | "engType") => void
	canDrag: boolean
}) {
	const orgStore = useOrgStore()
	const moduleId = DLOrgSubMenus.setup_arch_policy
	//
	// const [showButtons, setShowButtons] = useState(false)
	// const handleMouseEnter = () => {
	// 	setShowButtons(true)
	// }
	// const handleMouseLeave = () => {
	// 	setShowButtons(false)
	// }
	//
	const handleClick = () => {
		orgStore.archPolicy.toggleExpand(node.id)
		orgStore.archPolicy.setSelectedPolicy(node.id)
	}
	// actions with button
	const handleAdd = () => {}
	const handleEdit = () => {
		orgStore.archPolicy.setSelectedPolicy(node.id)
		orgStore.archPolicy.setOpenEditDialog(true)
	}
	const handleRemove = () => {
		let proceed = window.confirm(
			"Upon deletion, the engagement types under this archive condition will be moved to the Default Archive Condition policy. Do you want to continue?"
		)
		if (proceed) {
			orgStore.archPolicy.removeArchivePolicy(node.id)
		} else {
			return
		}
	}
	const handleDuplicate = () => {
		let proceed = window.confirm(
			"Are you sure you want to duplicate this archive condition policy?"
		)
		if (proceed) {
			orgStore.archPolicy.copyArchivePolicy(node.id)
		} else {
			return
		}
	}
	const handleArrange = () => {}
	//
	const actions = {
		add: { action: handleAdd, tooltipText: "Add" },
		edit: { action: handleEdit, tooltipText: "Edit" },
		remove: { action: handleRemove, tooltipText: "Remove" },
		duplicate: {
			action: handleDuplicate,
			tooltipText: "Duplicate",
		},
		arrange: { action: handleArrange, tooltipText: "Arrange" },
	}
	//
	let rowWidth = orgStore.orgFileLib.viewListWidth - 60
	// let canDrag = orgStore.archPolicy.canDrag
	let isSelected = orgStore.archPolicy.isSelected(node.id)
	const permission = orgStore.checkin.getActionsAsObject(moduleId)
	return (
		<div
			onClick={handleClick}
			onContextMenu={(e: any) => handleRightClick(e, node.id, "policy")}
			// onMouseEnter={handleMouseEnter}
			// onMouseLeave={handleMouseLeave}
			data-testid="arch-policy-row"
			style={{ width: rowWidth }}
		>
			<DLTreeNavParentRow
				expanded={node.expanded}
				canDrag={canDrag}
				title={node.title}
				selected={isSelected}
				showButtons={false}
				actions={actions}
				icon={<Icon path={mdiAlphaPCircle} size={0.8} />}
				permission={permission}
				hasChildren={node.children.length > 0}
			/>
		</div>
	)
})
