import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"
import { SingleSignOffProps } from "../../data-models/sign-off.data-model"
import { ConsoleLog } from "../../../../components/basic-elements"

const SingleSignOff = (self: any) => ({
	// signOff(type: OldSignOffType, fileId: string) {
	singleSignOff({ userId, roleId, signOffType, fileId }: SingleSignOffProps) {
		// 0.
		const actionName = "signOff"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		// NOTE: current API doesn't working with multiple files but takes list
		const numberId = idToNumber(fileId, IdType.file)
		const numberIdList = [{ WPRID: numberId }]
		//
		// let type: OldSignOffType = OldSignOffType.prepare
		// if (signOffColumnId !== "id_prepare") {
		// 	if (signOffColumnId === "id_qcReview") {
		// 		type = OldSignOffType.qcReview
		// 	} else {
		// 		type = OldSignOffType.review
		// 	}
		// }
		// Above line should do not use OldSignOffType in the future
		//
		// confirm to proceed
		const fileInfo = self.fileShortInfo(fileId, "SingleSignOff")
		const fileAliasId = fileInfo.aliasId
		const fileInfoDisplay = `(${fileAliasId}) ${fileInfo.title}`
		let proceed = window.confirm(
			`Do you want to ${signOffType} sign off on this file ${fileInfoDisplay}?`
		)
		const userNumId = idToNumber(userId, IdType.user)
		if (typeof userNumId !== "number") {
			alert("Something wrong. User ID has invalid format")
		}
		if (!proceed) {
			return
		}
		self.requestSignOff({
			fileList: numberIdList, // just a item but in a array...
			roleId,
			userNumId,
			type: signOffType,
			menuId: self.storeName,
		})
			.then((response: any) => {
				// console.log(actionName+ "__response " + response)
				if (response.status === 200 && response.data.Status === 1) {
					/**
					 * REFLECT to screen
					 */
					// NOTE: Need to use below items from the response
					/**
					 * - ReviewerList ...? ("SignOffModified" in this...???)
					 * - SignOff
					 * (Other values are meaningless)
					 */
					const result = response.data.SignOff
					self.reflectSignOff({
						userId,
						fileId,
						type: signOffType,
						signOff: {
							roleName: result.roleName,
							userName: result.userName,
							at: result.Date,
						},
					})

					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: `Success to sign off on (${fileAliasId})`,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message:
							response.data.Message ||
							response.data.message ||
							`Something wrong to sign off (${fileAliasId})`,
						// customMessage: "Something wrong...",
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				ConsoleLog([actionName, "__error ", error])
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default SingleSignOff
