import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { DLIconButton, DLChip } from "../../../../../components/basic-elements"
import { CloseIcon } from "../../../../../components/basic-elements/icons/common-icons"
import styled from "styled-components"

export default observer(function FileSelectCounter({
	partialStore,
}: {
	partialStore: any
}) {
	const store = useRootStore()
	//
	const selectedItems = partialStore.selectedItems
	const selectedFolders = partialStore.countSelectedFolders
	const fileCount = partialStore.countSelectedFiles
	//
	const display = selectedItems.length > 0
	//
	return (
		<StyledFileSelectCounter
			contentsAreaWidth={store.ui.contentsAreaWidth}
			data-testid="selected-items-counter"
			className={`${display &&
				"display"} FR AC JSB selected-items-counter`}
		>
			<div className="summary-area FC JC">
				<div className="FR JSB">
					<div className="FR AC">
						<DLIconButton
							eleTestId="reset-select"
							tooltipText="Unselect all"
							clickHandler={partialStore.resetSelectedItems}
						>
							<CloseIcon noMargin />
						</DLIconButton>

						<div className="FR AC">
							Selected Items{" "}
							<div className="FR AC counting">
								<DLChip
									eleTestId="folder-counter"
									label="folder"
								/>
								<span
									className="number"
									data-testid="selected-folders-count"
								>
									{selectedFolders}
								</span>
							</div>
							<div className="FR AC counting">
								<DLChip
									eleTestId="folder-counter"
									label="file"
								/>
								<span
									className="number"
									data-testid="selected-files-count"
								>
									{fileCount}
								</span>
							</div>
						</div>
					</div>
				</div>
			</div>
		</StyledFileSelectCounter>
	)
})

const StyledFileSelectCounter = styled.div<{ contentsAreaWidth: number }>`
	z-index: 1;
	box-shadow: 0px -4px 10px -10px ${(props) => props.theme.shade60};
	background-color: ${(props) => props.theme.background};
	width: ${(props) => props.contentsAreaWidth}px;
	overflow: hidden;
	height: 0px;
	transition: 0.3s;
	&.display {
		height: ${(props) => props.theme.shared.baseUnit};
	}
	.summary-area {
		padding: 0 2rem;
		z-index: 1;
		.divider {
			margin: 0 1rem;
		}
		.counting {
			margin-left: 1rem;
			.number {
				margin-left: 0.6rem;
			}
		}
	}
`
