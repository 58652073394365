import { flow } from "mobx-state-tree"
import { OrgGroupApiCallProps } from "../../../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const ReadOrgGroupCabinetDetail = (
	self: any,
	readCabinetDetail: OrgGroupApiCallProps["apiReadCabinetDetail"]
) => ({
	readOrgCabinetDetail: flow(function* (cabinetId: string) {
		const actionName = "readOrgCabinetDetail"
		// 1. check params existence
		if (cabinetId === undefined) {
			alert(`(${actionName}) required param(s) is(are) undefined`)
			return
		}
		// 2. validate the format of params
		const numberId = idToNumber(cabinetId, IdType.cabinet)
		if (typeof numberId === "number") {
			try {
				const params = {
					CabinetID: numberId,
					Type: 1,
				}
				const response = yield readCabinetDetail(params)
				// ConsoleLog("readOrgCabinetDetail response", response)
				return response
			} catch (error) {
				// ConsoleLog("readOrgCabinetDetail error", error)
				self.handleModelError({ actionName, error, open: true })

				return false
			}
		} else {
			console.error(
				actionName,
				"cannot get proper ID",
				numberId,
				cabinetId
			)
		}
	}),
})

export default ReadOrgGroupCabinetDetail
