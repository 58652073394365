import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getRequest, deleteRequest } from "../../../../library/api-requests"

export function readWP() {
	const response = getRequest({
		url: "/GetNotificationDetails",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readPF() {
	const response = getRequest({
		url: "/GetPfNotificationDetails",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readWPRequest() {
	const response = getRequest({
		url: "/GetRequestNotificationDetails",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function readPFRequest() {
	const response = getRequest({
		url: "/GetPfRequestNotificationDetails",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function delAllNotification(url: string) {
	const response = deleteRequest({
		url: url,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function delSingleNotification(
	url: string,
	payload: { MediaID: number }
) {
	const response = deleteRequest({
		url: url,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type GlobalNotisApiProps = {
	apiReadWP: () => Promise<AxiosResponse<any>>
	apiReadPF: () => Promise<AxiosResponse<any>>
	apiReadWPRequest: () => Promise<AxiosResponse<any>>
	apiReadPFRequest: () => Promise<AxiosResponse<any>>
	apiDelAllNotification: (url: string) => Promise<AxiosResponse<any>>
	apiDelSingleNotification: (
		url: string,
		payload: { MediaID: number }
	) => Promise<AxiosResponse<any>>
}
