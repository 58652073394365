import React from "react"
import { PageContainer } from "../../../components/app-frame-elements"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { observer } from "mobx-react-lite"
import ProjUsersTable from "./sub-components/ProjUsersTable"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import { DLI18nProps } from "../../../common-models/types/common-props"
import styled from "styled-components"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { RoleSet2Props } from "../../../service-modules/role-module/roles.data-model"

export default observer(function ProjectUsers({
	fetchingStatus,
	roleSet,
	permission,
	i18n,
}: {
	fetchingStatus: ActionStatus
	roleSet: RoleSet2Props
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const dntFormat = store.global.getDntFormat
	const projUsers = projStore.projUsers.formattedUsers(
		dntFormat.timeZone,
		dntFormat.dateFormat
	)

	return (
		<PageContainer
			fullWidth
			fixedHeight
			pageTools={<div />}
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledProjUsers>
				{/* ----- Contents ----- */}
				{fetchingStatus === "SUCCESS" && (
					<ProjUsersTable
						roleSet={roleSet}
						i18n={i18n}
						projUsers={projUsers}
						permission={permission}
					/>
				)}
			</StyledProjUsers>
		</PageContainer>
	)
})

const StyledProjUsers = styled.div`
	.proj-user-table {
		padding: 0 1rem;
	}
	.counter-container {
		cursor: pointer;
		.counter {
		}
		.focused {
			color: ${(props) => props.theme.primaryMainDeep};
			font-weight: 600;
		}
	}
	.disabled {
		opacity: 0.3;
	}
`
