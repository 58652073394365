import React, { useEffect } from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSpinner,
	DLIconButton,
	ConsoleLog,
} from "../../../components/basic-elements"
import styled from "styled-components"
//
import ProjRoleCard from "./sub-components/ProjRoleCard"
import ProjectSetupRoleDetail from "./ProjSetupRoleDetail"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import { DLSector } from "../../../common-models/enumerations/common-enums"

export default observer(function ProjectSetupRoles() {
	ConsoleLog(" ====***** ProjectSetupRoles *****===== ")
	const projStore = useProjStore()
	const actionName = "getRoleSet"
	const actionName2 = "getAllProjDetails"

	useEffect(() => {
		/**
		 * In this step, get three types of data and organized to display
		 * (Can be updated with backend service in the future)
		 */
		let projMenus: any[] = [] // NOTE: UPDATE IS REQUIRED
		projStore.roles.getRoleSet(DLSector.proj)
		projStore.roles.getAllProjDetails(projMenus)
		projStore.roles.getAssignedUsers()
	}, [])

	// TODO: WARNING: Below method re-render the card. UPDATE is required
	return (
		<StyledProjectRoles>
			{projStore.roles.responses.getActionStatus(actionName) ===
				"LOADING" && <DLSpinner />}
			{projStore.roles.responses.getActionStatus(actionName) ===
				"SUCCESS" &&
				projStore.roles.selectedRole && <ProjectSetupRoleDetail />}
			{projStore.roles.responses.getActionStatus(actionName) ===
				"SUCCESS" &&
				projStore.roles.responses.getActionStatus(actionName2) ===
					"SUCCESS" &&
				projStore.roles.selectedRole === "" && (
					<div>
						<div className="role-set-title-area FR AC JSB">
							<div className="role-set-title">
								<span>Current Role Set</span>
								<div className="heading2">
									{projStore.roles.roleSet.name}
								</div>
							</div>
							<DLIconButton eleTestId="role-set-change-btn">
								<Icon path={mdiPencil} size={1} />
							</DLIconButton>
						</div>
						<div className="role-card-container">
							{projStore.roles
								.viewRoleSetWithUsers()
								.map((role) => {
									return (
										<div key={role.roleId}>
											<ProjRoleCard role={role} />
										</div>
									)
								})}
						</div>
					</div>
				)}
		</StyledProjectRoles>
	)
})

const StyledProjectRoles = styled.div`
	.role-set-title-area {
		/* border: 1px solid pink; */
		padding: 0.5rem 2rem;
		.role-set-title {
			span {
				margin-right: 1rem;
			}
			.heading2 {
				font-weight: 600;
			}
		}
	}
	.role-card-container {
		padding: 1rem 2rem;
		display: flex;
		flex-wrap: wrap;
	}
`
