import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"

/**
*
* @param self
* @returns
*
* * i18n records
* - success, fail
*
*/

const GetProjTrashList = (self: any) => ({
	getProjTrashList() {
		// 0. set actionName
		const actionName = "getProjTrashList"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call
		self.readProjTrashList()
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// if success
					// 1) reset the stored list
					self.resetList()
					// 2) Re-organize the data
					const existingTrashList = response.data.TrashDetails
					existingTrashList.map((trashItem: any) => {
						const reOrganizedTrashItem = {
							id: trashItem.TrashID.toString(), // [TrashID] ex) 2474
							type: trashItem.Type, // [Type] ex) "WorkpaperFile"
							fileId: trashItem.FileID, // [FileID] ex) "48008"
							name: trashItem.Name, // [Name] ex) "독립성준수확인서"
							deletedBy: trashItem.DeleteBy, // [DeleteBy] ex) "김철수 [cskim]"
							deletedAt: trashItem.DeletedOn, // [DeletedOn] ex) "2020-01-11T
						}
						self.pushItemToList(reOrganizedTrashItem)
					})
					// when success
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetProjTrashList
