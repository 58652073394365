import { types } from "mobx-state-tree"
import ArchiveHistoryModel from "./archive-history.model"
import ArchiveHistoryViewModel from "./archive-history.view-model"

import * as api from "./archive-history-apis"

export const ArchiveHistoryStore = (apiRead?: any) =>
	types
		.compose(ArchiveHistoryModel(apiRead), ArchiveHistoryViewModel)
		.named("ArchiveHistoryStore")

export const initialStore = {
	archiveHistoryList: [],
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const ArchiveHistoryStoreInstance = ArchiveHistoryStore(api.read).create(
	initialStore
)

export default ArchiveHistoryStoreInstance
