import React, { useState, useEffect, useCallback } from "react"
import { observer } from "mobx-react-lite"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import Icon from "@mdi/react"
import { mdiPencil } from "@mdi/js"
import {
	DLDialog,
	DLInputField,
	DLSingleSelect,
	InputWithLabel,
} from "../../../../../../components/basic-elements"
import styled from "styled-components"

const EditEliminatingEntryDialog = observer(() => {
	const projStore = useProjStore()
	const actionName = "editEliminatingEntry"
	//
	const selectedEEId = projStore.eliminatingEntry.selectedEEId
	const eeDetails = projStore.eliminatingEntry.viewEEDetailsById(selectedEEId)

	const initialInputs = {
		year: eeDetails?.year,
		entity: eeDetails?.entityId,
		accountNumber: eeDetails?.accountNumber,
		accountDesc: eeDetails?.accountDesc,
		debit: eeDetails?.debit,
		credit: eeDetails?.credit,
	}

	const [inputs, setInputs] = useState<any>(initialInputs)
	const projId = projStore.checkin.projId

	useEffect(() => {
		projStore.eliminatingEntry.getTrialBalanceDetails(projId)
		projStore.eliminatingEntry.getAccountDetails(inputs.entity)
	}, [])

	const editEliminatingEntry = () => {
		projStore.eliminatingEntry.editEliminatingEntry(inputs, selectedEEId)
	}

	const handleOnChange = useCallback((event: any) => {
		const name = event.target.name
		let value = event.target.value
		setInputs((inputs: any) => ({
			...inputs,
			[name]: value,
		}))
		if (name === "entity" && value !== "") {
			projStore.eliminatingEntry.getAccountDetails(value)
			setInputs((inputs: any) => ({
				...inputs,
				accountNumber: "",
				accountDesc: "",
			}))
		}
		if (name === "accountNumber") {
			const desc = projStore.eliminatingEntry
				.viewAccountDetails()
				.find((item: any) => item.name === value).desc
			setInputs((inputs: any) => ({
				...inputs,
				accountDesc: desc,
			}))
		}
	}, [])

	const isReady =
		inputs.year !== "" &&
		inputs.entity !== "" &&
		inputs.accountNumber !== "" &&
		inputs.accountDesc !== "" &&
		inputs.debit !== "" &&
		inputs.credit !== ""

	return (
		<DLDialog
			eleTestId="edit-eliminating-entry-dialog"
			isOpen={projStore.eliminatingEntry.editEliminatingEntryDialogOpen}
			setIsOpen={
				projStore.eliminatingEntry.setEditEliminatingEntryDialogOpen
			}
			handleAction={editEliminatingEntry}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={
				<div className="FR AC">
					<Icon
						path={mdiPencil}
						size={1}
						// color={MessageColorType.blue}
						style={{ marginRight: 8 }}
					/>
					Edit Eliminating Entry
				</div>
			}
			dialogContents={
				<EditEliminatingEntryForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					partialStore={projStore.eliminatingEntry}
				/>
			}
			cancelBtnText="Cancel"
			actionReady={isReady}
			actionBtn="Submit"
			maxWidth="sm"
			fullWidth={true}
			showSpinner={
				projStore.eliminatingEntry.getActionStatus(actionName) ===
				"LOADING"
			}
			cannotUpdate={
				projStore.eliminatingEntry.getActionStatus(actionName) ===
				"LOADING"
			}
			useEnterKeyForSubmit
		/>
	)
})

const EditEliminatingEntryForm = observer(
	({
		inputs,
		handleOnChange,
		partialStore,
	}: {
		inputs: any
		handleOnChange: any
		partialStore: any
	}) => {
		return (
			<StyledEditEliminatingEntryDialog>
				{/* 1. Year
					2. Entity
					3. Account Number 
					4. Account Description
					5. Debit
                    6. Credit
                 */}
				{/* ----- 1. User Type: Internal or External */}
				<div className="input-section FR">
					<InputWithLabel required label="Year">
						<DLSingleSelect
							eleTestId="year-selection"
							eleName="year"
							eleValue={inputs.year}
							eleFullWidth
							withLabel={false}
							optionList={[
								{
									name: "Current Year",
									value: "Current Year",
								},
								{
									name: "Previous Year",
									value: "Previous Year",
								},
								{
									name: "Previous Year 1",
									value: "Previous Year 1",
								},
							]}
							placeholder="Select Year"
							eleOnChange={(e: any) => handleOnChange(e)}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 2. Entity  */}
				<div className="input-section FR">
					<InputWithLabel required label="Entity">
						<DLSingleSelect
							eleTestId="entity-selection"
							eleName="entity"
							eleValue={inputs.entity}
							eleFullWidth
							withLabel={false}
							optionList={partialStore.viewTBDetails()}
							placeholder="Select Entity"
							eleOnChange={(e: any) => handleOnChange(e)}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 2. Account Number  */}
				<div className="input-section FR">
					<InputWithLabel required label="Account Number">
						<DLSingleSelect
							eleTestId="accountNumber-selection"
							eleName="accountNumber"
							eleValue={inputs.accountNumber}
							eleFullWidth
							withLabel={false}
							optionList={partialStore.viewAccountDetails()}
							placeholder="Select Account Number"
							eleOnChange={(e: any) => handleOnChange(e)}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 4. Account Description  */}
				<div className="input-section FR">
					<InputWithLabel required label="Account Description">
						<DLInputField
							eleTestId="account-description-input-field"
							eleFullWidth
							eleName="accountDesc"
							eleReadOnly
							eleValue={inputs.accountDesc}
							eleHandleChange={(e: any) => handleOnChange(e)}
							eleRequired
						/>
					</InputWithLabel>
				</div>
				{/* ----- 5. Debit  */}
				<div className="input-section FR">
					<InputWithLabel required label="Debit">
						<DLInputField
							eleTestId="debit-input-field"
							eleType="number"
							eleFullWidth
							eleName="debit"
							eleValue={inputs.debit}
							eleHandleChange={(e: any) => handleOnChange(e)}
							// warningType="orange"
						/>
					</InputWithLabel>
				</div>
				{/* ----- 6. Credit  */}
				<div className="input-section FR">
					<InputWithLabel required label="Credit">
						<DLInputField
							eleTestId="credit-input-field"
							eleFullWidth
							eleType="number"
							eleName="credit"
							eleValue={inputs.credit}
							eleHandleChange={(e: any) => handleOnChange(e)}
							eleRequired
						/>
					</InputWithLabel>
				</div>
			</StyledEditEliminatingEntryDialog>
		)
	}
)

export default EditEliminatingEntryDialog

const StyledEditEliminatingEntryDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
