import React, { useEffect, useState, useCallback } from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import Icon from "@mdi/react"
import { mdiPlus } from "@mdi/js"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLInputField,
	DLSingleSelect,
	InputWithLabel,
} from "../../../../../../components/basic-elements"
import styled from "styled-components"
import { DLProjSubMenus } from "../../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"

const AddAdjustmentDialog = observer(
	({
		partialStore,
		menuId,
	}: {
		partialStore: any
		menuId: DLProjSubMenus
	}) => {
		const projStore = useProjStore()
		const actionName =
			menuId === DLProjSubMenus.fs_aje
				? "addAdjustment"
				: "addPassedAdjustment"

		const InitialInputs = {
			ajeName: "",
			ajeType: "",
			wpRef: "",
		}

		const projId = projStore.checkin.projId
		useEffect(() => {
			partialStore.getWPReferences(projId)
		}, [])

		const [inputs, setInputs] = useState(InitialInputs)

		const handleOnChange = useCallback((event: any) => {
			const name = event.target.name
			const value = event.target.value
			setInputs((inputs) => ({
				...inputs,
				[name]: value,
			}))
		}, [])

		const addAdjustment = () => {
			const selectedEntity = partialStore.selectedEntity
			if (menuId === DLProjSubMenus.fs_aje) {
				partialStore.addAdjustments(
					projId,
					inputs.ajeName,
					inputs.ajeType,
					inputs.wpRef,
					selectedEntity
				)
			} else {
				partialStore.addPassedAdjustment(
					projId,
					inputs.ajeName,
					inputs.ajeType,
					inputs.wpRef,
					selectedEntity
				)
			}
		}

		const isReady =
			inputs.ajeName !== "" &&
			inputs.ajeType !== "" &&
			inputs.wpRef !== ""

		return (
			<DLDialog
				eleTestId="add-adjustments-dialog"
				isOpen={partialStore.addAdjustmentDialogOpen}
				setIsOpen={partialStore.setAddAdjustmentDialogOpen}
				handleAction={addAdjustment}
				showOpenBtn={false}
				showCloseBtn={true}
				dialogTitle={
					<div className="FR AC">
						<Icon
							path={mdiPlus}
							size={1}
							// color={MessageColorType.blue}
							style={{ marginRight: 8 }}
						/>
						Create Adjustment
					</div>
				}
				dialogContents={
					<AddAjustmentForm
						inputs={inputs}
						handleOnChange={handleOnChange}
						partialStore={partialStore}
					/>
				}
				cancelBtnText="Cancel"
				actionReady={isReady}
				actionBtn="Submit"
				maxWidth="sm"
				fullWidth={true}
				showSpinner={
					projStore.eliminatingEntry.getActionStatus(actionName) ===
					"LOADING"
				}
				cannotUpdate={
					projStore.eliminatingEntry.getActionStatus(actionName) ===
					"LOADING"
				}
				useEnterKeyForSubmit
			/>
		)
	}
)

const AddAjustmentForm = observer(
	({
		inputs,
		handleOnChange,
		partialStore,
	}: {
		inputs: any
		handleOnChange: any
		partialStore: any
	}) => {
		return (
			<StyledAddAdjustmentDialog>
				{/* 1. Adjustment Name
					2. Adjustment Type
					3. Workpaper reference 
                 */}
				{/* ----- 1.  Adjustment Name */}
				<div className="input-section FR">
					<InputWithLabel required label="Adjustment Name">
						<DLInputField
							eleTestId="adjustment-name-input-field"
							eleFullWidth
							eleName="ajeName"
							eleValue={inputs.ajeName.value}
							eleHandleChange={handleOnChange}
							eleRequired
						/>
					</InputWithLabel>
				</div>
				{/* ----- 2. Adjustment Type  */}
				<div className="input-section FR">
					<InputWithLabel required label="Adjustment Type">
						<DLSingleSelect
							eleTestId="ajeType-selection"
							eleName="ajeType"
							eleValue={inputs.ajeType}
							eleFullWidth
							withLabel={false}
							optionList={[
								{
									name: "Client",
									value: "Client",
								},
								{
									name: "Audit",
									value: "Audit",
								},
							]}
							placeholder="Select Adjustment Type"
							eleOnChange={handleOnChange}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 2. Workpaper reference  */}
				<div className="input-section FR">
					<InputWithLabel required label="Workpaper reference">
						<DLSingleSelect
							eleTestId="wpRef-selection"
							eleName="wpRef"
							eleValue={inputs.wpRef}
							eleFullWidth
							withLabel={false}
							optionList={partialStore.viewWPRef()}
							placeholder="Select Workpaper reference"
							eleOnChange={handleOnChange}
						/>
					</InputWithLabel>
				</div>
			</StyledAddAdjustmentDialog>
		)
	}
)

export default AddAdjustmentDialog

const StyledAddAdjustmentDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
