import { types } from "mobx-state-tree"

export const TBDetails = types.model({
	tbId: types.number,
	tbName: types.string,
})

const TotalModel = types.model({
	currentYear: types.number,
	previousYear: types.number,
	previousYear1: types.number
})

export const FSModel = types.model({
	id: types.union(types.null, types.string),
	grandParentId: types.string,
	grandParentName: types.string,
	parentId: types.union(types.null, types.string),
	parentName: types.string,
	name: types.string,
	type: types.string,
	consolidated: types.union(types.null, TotalModel),
	combined: types.union(types.null, TotalModel),
})
