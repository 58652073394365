// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
} from "../../../../../../library/converters/id-converter"

const GetLeadSheetBySubGroup = (self: any) => ({
	getLeadSheetBySubGroup(fsSubGroupId: number, projectId: string) {
		// 0. set actionName
		const actionName = "getLeadSheetBySubGroup"
		// 1. set response cases
		// 2. set response
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "Fetching lead sheets is in progress",
			open: true,
		})

		const clientId = idToNumber(projectId, IdType.project)
		const payload = {
			financialSubGroupId: fsSubGroupId,
			clientId,
		}

		// 3) request API call to Model
		self.readLeadSheetBySubGroup(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200) {
						//
						const fetchedData = response.data
						let reOrganizedLeadSheets: any[] = []
						fetchedData.map((item: any) => {
							reOrganizedLeadSheets.push({
								leadSheetName: item.leadSheet,
								leadSheetMapId: item.mapId,
							})
						})
						self.setLeadSheets(reOrganizedLeadSheets)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage: "Successfully fetched lead sheets",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: "Failed",
							color: MessageColorType.orange,
							open: true,
							autoHide: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetLeadSheetBySubGroup
