import React, { useState } from "react"
import styled from "styled-components"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import LibraryRow from "../../../../../../components/combined-elements/tree-nav/DLTreeNavChildRow"
import { DLOrgSubMenus } from "../../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

export default observer(function FileLibRowController({ node }: { node: any }) {
	const orgStore = useOrgStore()
	const moduleId = DLOrgSubMenus.file_lib
	//
	const handleClickTemplate = () => {
		orgStore.orgFileLib.setSelectedTemplate({
			id: node.id,
			parentId: node.parentId,
			title: node.title,
			type: node.type,
			expanded: undefined,
		})
		orgStore.orgFileLib.getOrgFileLibShelfDetails(node.id, node.type)
	}
	const [showButtons, setShowButtons] = useState(false)
	const handleMouseEnter = () => {
		setShowButtons(true)
	}
	const handleMouseLeave = () => {
		setShowButtons(false)
	}
	//
	const handleRightClick = () => {}
	// actions with button
	const handleEdit = () => {
		orgStore.orgFileLib.setSelectedTemplate({
			id: node.id,
			parentId: node.parentId,
			title: node.title,
			type: node.type,
			expanded: undefined,
		})
		orgStore.orgFileLib.setOpenEditLibraryTemplate(true)
	}
	const handleRemove = () => {
		orgStore.orgFileLib.setSelectedTemplate({
			id: node.id,
			parentId: node.parentId,
			title: node.title,
			type: node.type,
			expanded: undefined,
		})
		orgStore.orgFileLib.setDialogType("Template")
		orgStore.orgFileLib.setDialogActionType("delete")
		orgStore.orgFileLib.setOpenDeleteAndDuplicateDialog(true)
	}
	const handleDuplicate = () => {
		orgStore.orgFileLib.setSelectedTemplate({
			id: node.id,
			parentId: node.parentId,
			title: node.title,
			type: node.type,
			expanded: undefined,
		})
		orgStore.orgFileLib.setDialogType("Template")
		orgStore.orgFileLib.setDialogActionType("duplicate")
		orgStore.orgFileLib.setOpenDeleteAndDuplicateDialog(true)
	}
	//
	const actions = {
		edit: { action: handleEdit, tooltipText: "Edit template folder" },
		remove: { action: handleRemove, tooltipText: "Remove template folder" },
		duplicate: {
			action: handleDuplicate,
			tooltipText: "Duplicate template folder",
		},
	}

	const rowWidth = orgStore.orgFileLib.viewListWidth - 92
	let canDrag = orgStore.orgFileLib.canDrag
	const permission = orgStore.checkin.getActionsAsObject(moduleId)
	return (
		<StyledTemplateRow
			onClick={handleClickTemplate}
			onContextMenu={handleRightClick}
			onMouseEnter={handleMouseEnter}
			onMouseLeave={handleMouseLeave}
			style={{ width: rowWidth }}
		>
			<LibraryRow
				canDrag={canDrag}
				title={node.title}
				selected={false} // TODO: update is required
				showButtons={showButtons}
				actions={actions}
				permission={permission}
			/>
		</StyledTemplateRow>
	)
})

const StyledTemplateRow = styled.div`
	.title-text {
		margin-left: 0.6rem;
	}
	.button-container {
		opacity: 0;
		transition: 0.4s;
	}
	:hover {
		.button-container {
			opacity: 1;
		}
	}
`
