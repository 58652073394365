import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"
import { ChecklistRecordType } from "@datalobby/types"

// NOTE: org-setup-checklist has similar case to get the checklist templates
const GetChecklistCollections = (self: any) => ({
	getChecklistCollections(orgId: string) {
		const actionName = "getChecklistCollections"
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.readChecklistCollections(orgId)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					const data = response.data
					
					const organizedData = data.map((item:any) => ({projTypeId: item.projectTypeId, templates: item.templates}))
					
					self.setChecklistCollections(organizedData)

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Successfully fetched the checklist collection",
						color: MessageColorType.blue,
						open: false,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999, // temp
						customMessage: "Something's Wrong... ",
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetChecklistCollections
