import React, { useState, useEffect } from "react"
import { observer } from "mobx-react-lite"
import { formattedDateWithTz2 } from "../../../library/converters/date-utc-converter"
import styled from "styled-components"
import { OrgSysSettingItemProps } from "../../../service-modules/sys-setting-module/sys-setting.data-model"

const SidebarDateDisplay = observer(
	({
		tz,
		tf,
		df,

		on,
	}: {
		tz: OrgSysSettingItemProps
		tf: string
		df: string

		on: boolean
	}) => {
		const [timeZone, setTz] = useState("")
		const [timeFormat, setTf] = useState("")
		const [dateFormat, setDf] = useState("")

		const [minChange, setMinChange] = useState(true)
		const [date, setDate] = useState("")
		const [time, setTime] = useState("")

		const [intervalId, setIntervalId] = useState<any>(undefined)

		useEffect(() => {
			const dnt =
				timeZone &&
				formattedDateWithTz2({
					date: new Date(),
					timeZone,
					timeFormat,
					dateFormat,
				})

			dnt && setDate(dnt.date)
			dnt && setTime(dnt.time)
		}, [timeZone, timeFormat, dateFormat, minChange])

		useEffect(() => {
			if (on) {
				setIntervalId(
					setInterval(function () {
						const s = new Date().getSeconds()
						if (s === 0) {
							setMinChange(true)
						} else {
							setMinChange(false)
						}
					}, 1000)
				)
			} else {
				clearInterval(intervalId)
			}
		}, [on])

		useEffect(() => {
			if (timeZone !== tz.uiValue) {
				setTz(tz.uiValue)
			}
			if (timeFormat !== tf) {
				setTf(tf)
			}
			if (dateFormat !== df) {
				setDf(df)
			}
		}, [tz, tf, df])

		// if (timeZone !== tz.uiValue) {
		// 	setTz(tz.uiValue)
		// }
		// if (timeFormat !== tf) {
		// 	setTf(tf)
		// }
		// if (dateFormat !== df) {
		// 	setDf(df)
		// }
		if (on) {
			return (
				<StyledDate data-testid="sidebar-date-info">
					<div className="FR JSB">
						<span data-testid="formatted-date">{date}</span>
						<span
							data-testid="formatted-time"
							style={{ marginRight: "1rem" }}
						>
							{time}
						</span>
					</div>
					<span className="tz" data-testid="timeZone">
						{tz.value}
					</span>
				</StyledDate>
			)
		} else {
			return <div />
		}
	}
)

const StyledDate = styled.div`
	margin-left: 1rem;
	font-weight: var(--font-bold);
	span {
	}
	.tz {
		font-weight: var(--font-light);
		opacity: 0.6;
		transition: 0.4s;
		:hover {
			opacity: 1;
		}
	}
`

export default SidebarDateDisplay
