import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../../library/converters/id-converter"

const RemoveUsersFromGroup = (self: any) => ({
	removeUsersFromGroup(groupId: string, userList: any, isVerified: boolean) {
		// 0.
		const actionName = "removeUsersFromGroup"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. API Call
		const Userlst = userList.map((items: any) => {
			return { UserID: idToNumber(items.UserId, IdType.user) }
		})
		const groupNumberId = idToNumber(groupId, IdType.group)

		let payload = {
			GroupID: groupNumberId,
			Userlst,
			IsVerified: isVerified,
		}
		self.unAssignUsersFromGroup(payload)
			.then((response: any) => {
				if (response.data.Status === 1) {
					self.updateAssignedUserList(userList)
					self.updateAssignedUsers(
						response.data.AssignedUsers,
						response.data.UsersCount,
						groupId
					)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
					// self.setUnAssignUsersToGroupDialogOpen(false)
				} else if (response.data.Status === 2) {
					self.resetAssignedProjectList()
					const assinedProjList = response.data.AssignedProjList
					assinedProjList.map((proj: any) => {
						let projects: any[] = []
						proj.ProjectsList.map((project: any) => {
							projects.push({
								clientName: project.ClientName,
								clientAliasId: project.ClientShortName,
								//
								financialYear: project.FinancialYear,
								periodName: project.Period,
								//
								projectName: project.ProjectName,
								projectStatus: project.ProjectType,
							})
						})
						const reOrganizedProj = {
							name: proj.Name,
							projectList: projects,
							userName: proj.UserName,
						}

						self.pushItemToAssignedProjectList(reOrganizedProj)
					})
					self.setUnAssignUsersToGroupDialogOpen(true)

					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.standby,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 200,
						color: MessageColorType.orange,
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default RemoveUsersFromGroup
