import { types, IAnyModelType } from "mobx-state-tree"

export type usePeriodProps = {
	startDate?: string | null // is 'undefined' avaiable?
	endDate?: string | null // is 'undefined' avaiable?
}

const usePeriod = types.model({
	startDate: types.union(types.string, types.null, types.undefined),
	endDate: types.union(types.string, types.null, types.undefined),
})

export type UserActionProps = {
	actionName: string
	available: boolean
	// available: boolean | null
	// NOTE: At first, 'null' type is set for the acttions which are not required to permit.
	// NOTE: finaly, if there are not required permit something, do not store it on the permission.
	// NOTE: summary: No 'null' type on the permission
}

const UserAction = types.model({
	actionName: types.string,
	available: types.boolean,
	// available: types.union(types.boolean, types.null),
})

const AssignedUser = types.model({
	id: types.string,
	type: types.enumeration(["internal", "external"]),
	roleId: types.string,
	name: types.string,
	shortName: types.string,
	email: types.string,
})

export type MenuForUiProps = {
	id: string
	title: string
	url: string
	menuGroup: null | string
	subMenus: any[]
}

const MenuForUi = types.model({
	id: types.string,
	//
	title: types.string,
	url: types.string,
	isAdminMenu: types.boolean,
	menuGroup: types.union(types.null, types.string),
	subMenus: types.optional(
		types.array(types.maybe(types.late((): IAnyModelType => MenuForUi))),
		[]
	),
})

/**
 *
 * A. Menu
 * - a1. MenuPermission
 * - a2. MenuInfo
 * - a3. CombinedMenu
 *
 * B. Role
 * - b1. RolePermission (with MenuPermission)
 * - b2. RoleInfo
 * - b3. CombinedRole (with CombinedMenu)
 *
 */

// ========================================
// a1. MenuPermission
// a2. MenuInfo
// a3. CombinedMenu

export type MenuPermissionProps = {
	menuId: string
	//
	accessible: boolean
	actions: UserActionProps[]
}
const MenuPermission = types.model({
	menuId: types.string,
	//
	accessible: types.boolean,
	actions: types.array(UserAction),
})
// ----------
export type MenuInfoProps = {
	menuId: string
	//
	title: string
	url: string
	menuType?: "MENU_GROUP" | "SUB_MENU"
	menuGroup: null | string
}
const MenuInfo = types.model({
	menuId: types.string,
	//
	title: types.string,
	url: types.string,
	menuType: types.enumeration(["MENU_GROUP", "SUB_MENU"]),
	menuGroup: types.union(types.null, types.string),
})
// ----------
export type CombinedMenuProps = {
	menuId: string
	//
	title: string
	url: string
	menuType?: "MENU_GROUP" | "SUB_MENU"
	menuGroup: null | string
	//
	accessible: boolean
	actions: UserActionProps[]
	//
	collapsed: boolean
	subMenus?: CombinedMenuProps[]
}

const CombinedMenu = types.model({
	menuId: types.string,
	//
	title: types.string,
	url: types.string,
	menuType: types.enumeration(["MENU_GROUP", "SUB_MENU"]),
	menuGroup: types.union(types.null, types.string),
	//
	accessible: types.boolean,
	actions: types.array(UserAction),
	//
	collapsed: types.boolean,
	subMenus: types.optional(
		types.array(types.maybe(types.late((): IAnyModelType => CombinedMenu))),
		[]
	),
})

// ========================================
// - b1. RolePermission (with MenuPermission)
// - b2. RoleInfo
// - b3. CombinedRole (with CombinedMenu)

export type RolePermissionProps = {
	roleId: string
	//
	usePeriod?: usePeriodProps
	globalPermissions: UserActionProps[]
	menuPermissions: MenuPermissionProps[]
}
const RolePermission = types.model({
	roleId: types.string,
	//
	usePeriod: types.optional(usePeriod, {}),
	globalPermissions: types.array(UserAction),
	menuPermissions: types.array(MenuPermission),
})
// ----------
export type RoleInfoProps = {
	roleId: string
	//
	name: string
	shortName: string
	color?: string
}
const RoleInfo = types.model({
	roleId: types.string,
	//
	name: types.string,
	shortName: types.string,
	color: types.optional(types.string, ""),
})
// ----------

export type CombinedRoleProps = {
	roleId: string
	//
	name: string
	shortName: string
	color?: string
	//
	usePeriod?: usePeriodProps
	globalPermissions: UserActionProps[]
	menuPermissions: CombinedMenuProps[]
}
const CombinedRole = types.model({
	roleId: types.string,
	//
	name: types.string,
	shortName: types.string,
	color: types.optional(types.string, ""),
	//
	usePeriod: types.optional(usePeriod, {}),
	globalPermissions: types.array(UserAction),
	menuPermissions: types.array(CombinedMenu),
})

export type CombinedRoleDefaultProps = {
	roleId: string
	//
	name: string
	shortName: string
	color?: string
	//
	usePeriod?: usePeriodProps
	globalPermissions: UserActionProps[]
	menuPermissions: MenuPermissionProps[]
}

const CombinedRoleDefault = types.model({
	roleId: types.string,
	//
	name: types.string,
	shortName: types.string,
	color: types.optional(types.string, ""),
	//
	usePeriod: types.optional(usePeriod, {}),
	globalPermissions: types.array(UserAction),
	menuPermissions: types.array(MenuPermission),
})

// ========================================
export type RoleSetProps = {
	id: string
	title: string
	createdAt: string
	createdBy: string
	modifiedAt?: null | string
	modifiedBy?: null | string
	//
	roles: RoleInfoProps[]
}

const RoleSet = types.model({
	id: types.string,
	title: types.string,
	createdAt: types.string,
	createdBy: types.string,
	modifiedAt: types.union(types.null, types.string),
	modifiedBy: types.union(types.null, types.string),
	//
	roles: types.union(types.array(RoleInfo), types.undefined),
})

const initialRoleSet2 = {
	id: "",
	name: "",
	roles: [{ color: "", id: "", index: 0, name: "", shortName: "" }],
	sector: "",
	acCtrlSetId: "",
	acCtrlSetName: "",
	type: "",
}

export type RoleInfoProps2 = {
	color: string
	id: string
	index: number
	name: string
	shortName: string
}

const RoleSet2 = types.model({
	id: "",
	name: "",
	roles: types.array(
		types.model({
			color: "",
			id: "",
			index: 0,
			name: "",
			shortName: "",
		})
	),
	sector: "",
	acCtrlSetId: "",
	acCtrlSetName: "",
	type: "",
})

export type RoleSet2Props = {
	id: string
	name: string
	roles: {
		color: string
		id: string
		index: number
		name: string
		shortName: string
	}[]
	sector: string
	acCtrlSetId: string
	acCtrlSetName: string
	type: string
}

export {
	usePeriod,
	UserAction,
	AssignedUser,
	MenuForUi,
	//
	MenuPermission,
	MenuInfo,
	CombinedMenu,
	//
	RolePermission,
	RoleInfo,
	CombinedRole,
	CombinedRoleDefault,
	//
	RoleSet,
	RoleSet2,
	initialRoleSet2,
}
