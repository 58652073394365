import React from "react"
import { DLContextMenu } from "../../basic-elements"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { PermissionAsObjectProps } from "../../../common-models/permission"
import { DLI18nProps } from "../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	IconPlaceholder,
} from "../../basic-elements/icons/common-icons"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

export default observer(function ProjArchivedCtxMenus({
	permission,
	i18n,
}: {
	permission: PermissionAsObjectProps
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()

	const projId = orgStore.archiveMgmt.selectedProj
	const projectInfo = orgStore.archiveMgmt.viewSelectedProjInfo(projId)

	const projNumId = idToNumber(projId, IdType.project)

	const menuOptions = [
		{
			label: "Open",
			value: "open",
			disabled: !projectInfo?.accessible,
			available: projectInfo?.accessible,
			isLink: true,
			linkTo: `/project/checkin/${projNumId}`,
			icon: <IconPlaceholder />,
		},
		{
			label: "Project Information", // `${i18n.twProjectDetails}`,
			value: "ctx-proj_info", // "ctx-details",
			available: permission.read,
			// disabled: false,
			clickEvent: () =>
				orgStore.archiveMgmt.setProjectDetailsDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.tsReadOnlyAccessManagement}`,
			value: "ctx-read_only_access_management",
			available: permission.update,
			// disabled: false,
			clickEvent: () =>
				orgStore.archiveMgmt.setAccessMgmtDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: "Pre-Process PDFs",
			value: "ctx-pre_process_pdf",
			available: permission.create,
			disabled: !projectInfo?.hasExportPermission,
			tooltip: !projectInfo?.hasExportPermission
				? "Readonly access is required"
				: "",
			// clickEvent: handlePreProcessPdfs,
			clickEvent: () => orgStore.archiveMgmt.setPreProcessDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.tsExportArchivedZipFile}`,
			value: "ctx-export_archived_zip_file",
			available: permission.export,
			disabled: !projectInfo?.hasExportPermission,
			tooltip: !projectInfo?.hasExportPermission
				? "Readonly access is required"
				: "",
			// NOTE: Users must submit themselves to export zip for logging
			// NOTE: Watch out. PreConditionCheckStatus API will force sign out the user if you allow the dialog without this right
			// clickEvent: () =>
			// 	orgStore.archiveMgmt.exportArchivedZipFile(
			// 		orgStore.archiveMgmt.selectedProj
			// 	),
			clickEvent: () =>
				orgStore.archiveMgmt.setArchiveZipRegenerationDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.tsRepairReplica}`,
			value: "ctx-repair_broken_replica",
			available: permission.create,
			disabled: !projectInfo?.hasExportPermission,
			tooltip: !projectInfo?.hasExportPermission
				? "Readonly access is required"
				: "",
			clickEvent: () => orgStore.archiveMgmt.setRepairReplicaDialog(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twDigitalSignature}`,
			value: "ctx-digital_signature",
			available: permission.read,
			disabled: false,
			clickEvent: () =>
				orgStore.archiveMgmt.setDigitalSignatureDialogOpen(true),
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twUnarchive}`,
			value: "ctx-unarchive",
			available: permission.update,
			disabled: false,
			clickEvent: () => {
				const actionName = "unarchiveProject"
				orgStore.archiveMgmt.responses.setResponse(actionName, {
					actionName,
					status: ActionStatus.standby,
				})
				orgStore.archiveMgmt.setArchivedProjectUnarchiveDialogOpen(true)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: `${i18n.twDelete}`,
			value: "ctx-proj_delete", // "ctx-delete",
			available: permission.delete,
			disabled: false,
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
			clickEvent: () =>
				orgStore.archiveMgmt.setRemoveArchivedProjectDialogOpen(true),
		},
	]

	const handleClose = () => {
		orgStore.archiveMgmt.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}

	return (
		<DLContextMenu
			eleId="project-ctx-menus"
			clickPoint={{
				mouseX: orgStore.archiveMgmt.clickPoint.mouseX,
				mouseY: orgStore.archiveMgmt.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions}
			hasDivider={[1, 6]}
		/>
	)
})
