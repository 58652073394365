import { flow } from "mobx-state-tree"
import { OrgProjectApiProps } from "../../../stores/org-sub-stores/org-projects-store/orgProjects.apis"
import { idToNumber, IdType } from "../../../library/converters/id-converter"

const ReadRfProjectsByGroup = (
	self: any,
	readRfProjects: OrgProjectApiProps["apiReadRfProjects"]
) => ({
	readRfProjectsByGroup: flow(function* (groupId: string) {
		const actionName = "readRfProjectsByGroup"
		// 1.
		if (groupId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. groupId: ${groupId}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(groupId, IdType.group)
		if (typeof numberId === "number") {
			try {
				const params = {
					groupId: numberId,
				}
				const response = yield readRfProjects(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default ReadRfProjectsByGroup
