import React, { useEffect, useState } from "react"
import { Redirect } from "react-router-dom"
import DLSpinner from "../../../components/basic-elements/spinner/DLSpinner"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../components/basic-elements"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { initializeOrgStores } from "../../../stores/initialize-stores"
import { clearLocalStorageForOrg } from "../../../library/token-utils"

export default observer(function OrgCheckout() {
	const store = useRootStore()
	const orgStore = useOrgStore()

	const [checkout, setCheckout] = useState(false)

	useEffect(() => {
		ConsoleLog("========== ORG CHECKOUT && REMOVE RELATED ITEMS ==========")
		clearLocalStorageForOrg()
		//
		// * initialize organization store
		// <Organization store list>
		// ! - checkin (! important)
		//
		// - default
		// - recent
		// - orgProjTemplates
		// - orgFileLibrary
		// - noti
		// - archiveMgmt
		// - unarchiveMgmt
		// - groups
		// - projects
		// - trash
		// - setupUsers
		// - setupGroups
		// - setupClients
		// - setupEngTypes
		// - setupDateAndTimeSetting
		// - orgRoles
		// - archPolicy
		// - search
		// ------------
		orgStore.checkin.setOrgCheckin(ActionStatus.standby)

		initializeOrgStores(orgStore)
		// WARNING: Do this at the last step
		// WARNING: Do not deprecate the line below. It is mandatory to re-login to organization
		orgStore.checkin.initializeStore()

		// NOTE: WARNING:
		store.notis.initializeStore()
		setCheckout(true)
	}, [])

	return (
		<div>
			{checkout ? (
				<Redirect to="/lobby/organization-list" />
			) : (
				<DLSpinner />
			)}
		</div>
	)
})
