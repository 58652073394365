import { types } from "mobx-state-tree"

export const EngType = types.model({
	// NOTE:
	/**
	 * 1. ArchivePolicyId --> do not save on the individual item
	 * 2. EngagementTypeId --> id
	 * 3. Id --> ...????
	 */
	id: types.string, // [EngagementTypeId]
	title: types.string, // [EngagementType], ex) "External Audit"
	createdBy: types.string, // [CreatedBy], ex) 1
	createdAt: types.union(types.null, types.string), // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
	modifiedBy: types.string, // [ModifiedBy], ex) 0
	modifiedAt: types.union(types.null, types.string), // [ModifiedDate], ex) null
})

export const ArchiveCondition = types.model({
	// NOTE: current condition has 3 IDs on the response
	/**
	 * 1. ArchivePolicyId --> Do not save on the individual condition
	 * 2. ArchivePreConditionId --> code
	 * 3. Id --> id
	 *
	 * 1 means the parent policy ID
	 * 2 is like a code of conditions. There are 18 conditions and this ID means one of them.
	 * 3 means a ID on the item itself
	 */
	id: types.string, // just id...
	aliasId: types.string, // condition code. 1~18
	title: types.string, // [ArchivePreCondition], ex) "Re Sign Off 2"
	description: types.string, // [Description], ex) "Sign Off again on every modified contents (No red *)"
	helpText: types.string, // [HelpText], ex) "When the Workpaper or Permanent File gets... ""
	//
	isMandatory: types.boolean, // [IsMandatory], ex) 0
	// order: types.number, // [Order], ex) 1
	createdBy: types.string, // [CreatedBy], ex) 1
	createdAt: types.union(types.null, types.string), // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
	modifiedBy: types.union(types.null, types.string), // [ModifiedBy], ex) 0
	modifiedAt: types.union(types.null, types.string), // [ModifiedDate], ex) null
})

export const Conditions = types.model({
	mandatoryList: types.array(ArchiveCondition),
	optionalList: types.array(ArchiveCondition),
})

export const OrgArchivePolicy = types.model({
	id: types.string, // [Id], ex) 2
	title: types.string, // [Title], ex) "Default Archive Policy"
	description: types.string, // [Description], ex) "Default Archive Policy"
	orgId: types.string, // [CustomerId], ex) 3 --> NOTE: Why this props is required..? (micro service?)
	//
	engTypes: types.array(EngType), // [EngagementsTypesList], ex) [{Id: 2, EngagementTypeId: 5, EngagementType: "A", ArchivePolicyId: 2, CreatedBy: 1,…}]
	conditions: Conditions, // [PreConditionsList], ex) {MandatoryList: [{Id: 13, ArchivePreConditionId: 12, ArchivePreCondition: "Opened Files",…},…],…}
	//
	createdBy: types.string, // [CreatedBy], ex) 1
	createdAt: types.union(types.null, types.string), // [CreatedDate], ex) "2018-11-26T21:34:34.44527-05:00"
	modifiedBy: types.string, // [ModifiedBy], ex) 0
	modifiedAt: types.union(types.null, types.string), // [ModifiedDate], ex) null
	isActive: types.boolean, // [IsActive], ex) 1
	isDefault: types.boolean, // [IsDefault], ex) 1
	isResetAllowed: types.boolean, // [IsResetAllowed], ex) 0
	lastModifiedBy: types.union(types.null, types.string), // [LastModifiedBy], ex) "-"
	lastModifiedAt: types.union(types.null, types.string), // [LastModifiedDate], ex) null
})

export const ArchivePolicyFlatItem = types.model({
	id: types.string,
	title: types.string,
	description: types.union(types.null, types.string),
	parentId: types.union(types.null, types.string),
	expanded: types.boolean,
	type: types.enumeration(["policy", "engType"]),
})

export const ArchivePolicyDetail = types.model({
	id: types.identifier,
	conditions: types.array(ArchiveCondition),
})
