import React from "react"
import { types } from "mobx-state-tree"
import { GlobalNotisModel } from "./global-notis.model"
import GlobalNotisViewModel from "./global-notis.view-model"
//apis
import * as api from "./global-notis.apis"

export const GlobalNotisStore = ({
	apiReadWP,
	apiReadPF,
	apiReadWPRequest,
	apiReadPFRequest,
	apiDelAllNotification,
	apiDelSingleNotification,
}: api.GlobalNotisApiProps) =>
	types
		.compose(
			GlobalNotisModel({
				apiReadWP,
				apiReadPF,
				apiReadWPRequest,
				apiReadPFRequest,
				apiDelAllNotification,
				apiDelSingleNotification,
			}),
			GlobalNotisViewModel
		)
		.named("GlobalNotisStore")

export const initialStore = {
	openedNotification: [],
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const GlobalNotisInstance = GlobalNotisStore({
	apiReadWP: api.readWP,
	apiReadPF: api.readPF,
	apiReadWPRequest: api.readWPRequest,
	apiReadPFRequest: api.readPFRequest,
	apiDelAllNotification: api.delAllNotification,
	apiDelSingleNotification: api.delSingleNotification,
}).create(initialStore)

export default GlobalNotisInstance
