import { getRequest, deleteRequest } from "../../library/api-requests"
import { TOKEN_TYPE } from "../../library/api-requests/shared-for-api-requests"

/**
 * - readProjectUsers
 * - deleteProject
 */

export type DeleteProjectApiProps = {
	ClientId: number
	CabinetId: number
	Type: string
	// UserId: number
}
export async function deleteProject(payload: DeleteProjectApiProps) {
	const endpoint = "/DeleteClient"
	const response = await deleteRequest({
		url: endpoint,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

/**
 * This readProjectUsers is shared on
 * - am-archived
 * - am-unarchived
 *  - and for other projects
 * TODO: Need to check if there are more places to use this api
 * NOTE:
 * - proj-setup-users uses same API with project token
 * NOTE:
 * - type: 4 (in am-archived, am-unarchived)
 * ...?? normal and replica also working with property type 4...
 */
// WARNING: This API works if use project-token (seems get projectId and type from project-token)
// WARNING: NO... it seems working also with the org token..
export type ReadProjectUsersApiProps = {
	clientid: number
	Type: number
	// FinancialYear: string
	// Lang: string
	// UserType: number
	// RoleID: string
	// AccessRightID: string
	// SearchText: string
	// pageSize: number
	// pageNo: number
	// UserStatusType: number
}
export async function readProjectUsers(params: ReadProjectUsersApiProps) {
	const endpoint = "/GetUsersByClient"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

/**
 * ----------------------------------------
 */

export type ReadProjectDetailsApiProps = {
	clientid: number
}
export async function readProjectDetails(params: ReadProjectDetailsApiProps) {
	const endpoint = "/GetClientInformationDetail"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
