import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"

const version = process.env.REACT_APP_API_VERSION

const GetOrgToken = (self: any) => ({
	getOrgToken(orgId: string, userId: string, postWorkForSuccess?: any) {
		// 0. set actionName
		const actionName = "getOrgToken"
		// X. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// X. call API

		// self.setActionStatus(ActionStatus.loading)
		self.readOrgToken(orgId, userId)
			.then((response: any) => {
				let responseStatus = 0

				// * ----- API version control
				const basicStatus = response.status
				const prevStatus = response.data?.status?.code

				if (version === "v2") {
					responseStatus = basicStatus
				} else {
					if (basicStatus === 200 && prevStatus === 200) {
						responseStatus = 200
					}
				}

				if (responseStatus === 200) {
					// save orgId, userID in the organization to re-use
					localStorage.setItem(
						"orgToken",
						JSON.stringify(response.data.data.access_token)
					)
					localStorage.setItem("orgId", orgId)
					self.setOrgUserId(userId)
					localStorage.setItem("orgUserId", userId)
					// for gradual process, do not use common actions (shared action) here
					// WARNING:
					postWorkForSuccess && postWorkForSuccess()
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.success,
					})
				} else {
					self.responses.setResponse(actionName, {
						actionName,
						status: ActionStatus.fail,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetOrgToken
