import React from "react"
import { useProjStore } from "../../../stores/proj-store/proj-store.provider"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiMapMarkerQuestionOutline, mdiAlertCircle } from "@mdi/js"
import { Link } from "react-router-dom"
import { useRootStore } from "../../../stores/root-store/root-store.provider"

export default function ProjNoPermissionOnMenu({ menuId }: { menuId: string }) {
	const store = useRootStore()
	const projStore = useProjStore()

	const customerMenus = store.checkin.customerMenus
	const menuTitle = customerMenus.find(
		(element) => element.id === menuId
	)?.name

	return (
		<StyledNoPermissionOnMenus className="FC AC JC">
			<Icon path={mdiMapMarkerQuestionOutline} size={4} />
			<div className="text-contents">
				<p>
					Sorry, You don't have access on the{" "}
					<span className="blocked-menu">
						{menuTitle ? menuTitle : "menu"}.{" "}
					</span>
				</p>
				<div>
					Please use other menus below...
					<div className="other-accessible-menus">
						{projStore.checkin.accessibleTreeMenus.length > 0 ? (
							projStore.checkin.accessibleTreeMenus.map(
								(item: any) => {
									return (
										<div
											key={item.id}
											className="other-menu"
										>
											<Link to={item.url}>
												{item.title}
											</Link>
										</div>
									)
								}
							)
						) : (
							<div className="FR AC JC warning">
								<Icon path={mdiAlertCircle} size={1} />
								<p>
									You don't have any permission on this
									project
								</p>
							</div>
						)}
					</div>
				</div>
			</div>
			<div className="ps">Please contact your project administrator.</div>
		</StyledNoPermissionOnMenus>
	)
}

const StyledNoPermissionOnMenus = styled.div`
	width: 100%;
	height: 100%;
	text-align: center;
	.text-contents {
		margin-top: 2rem;
		.blocked-menu {
			color: ${(props) => props.theme.shade20};
			/* border-bottom: 1px solid ${(props) => props.theme.shade20}; */
		}
		.other-menu {
			padding: 0.8rem 1rem;
			margin-bottom: 8px;
			border-bottom: 1px solid ${(props) => props.theme.shade20};
			:hover {
				color: ${(props) => props.theme.emphasis};
			}
		}
		.warning {
			color: ${(props) => props.theme.shared.messageOrange};
			margin: 1rem 0;
			svg {
				fill: ${(props) => props.theme.shared.messageOrange};
			}
			p {
				margin-left: 0.4rem;
			}
		}
	}
	.ps {
		margin-top: 2rem;
	}
`
