import React from "react"
import { observer } from "mobx-react-lite"
import { useLobbyStore } from "../../../stores/lobby-store/lobby-store.provider"
import ChangePw from "./ChangePw"
import { ConsoleLog } from "../../../components/basic-elements"

export default observer(function ChangePwController() {
	ConsoleLog(" *_________ ChangePwController _________* ")
	const lobbyStore = useLobbyStore()

	const handleChangePw = (password: { curPw: any; newPw: any }) => {
		lobbyStore.my.editPassword(password)
	}

	const setOpen = (request: boolean) => {
		lobbyStore.my.setChangePwDialogOpen(request)
	}

	const open = lobbyStore.my.changePwDialogOpen

	return (
		<>
			{open ? (
				<ChangePw
					partialStore={lobbyStore.my}
					open={lobbyStore.my.changePwDialogOpen}
					setOpen={setOpen}
					handleChangePw={handleChangePw}
				/>
			) : (
				<div />
			)}
		</>
	)
})
