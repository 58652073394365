import { getRequest } from "../../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../../library/api-requests/shared-for-api-requests"

export async function readGroups(params: { UserID: number }) {
	const response = await getRequest({
		url: "/GetAssignedGroups",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readCabinets(params: { GroupID: number; Type: number }) {
	const endpoint = "/GetCabinetsByGroup"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export async function readCabinetDetail(params: {
	CabinetID: number
	Type: number
}) {
	const endpoint = "/GetCabinetsAndClients"
	const response = await getRequest({
		url: endpoint,
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
