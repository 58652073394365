import { flow } from "mobx-state-tree"
import { OrgSysSettingApiTypes } from "../../../../../../../service-modules/sys-setting-module/org-sys-setting.apis"

const ReadEmailNotificationSetting = (
	self: any,
	read: OrgSysSettingApiTypes["apiRead"]
) => ({
	readEmailNotificationSetting: flow(function* () {
		const actionName = "readEmailNotificationSetting"
		try {
			const response = yield read()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadEmailNotificationSetting
