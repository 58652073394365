import {
	ActionStatus,
	DLSector,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import { ConsoleLog } from "../../../../../components/basic-elements"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"
import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"

const GetFileStatus = (self: any) => ({
	/**
	 *
	 * @param postAction
	 *
	 * * i18n records
	 * - success, fail
	 * NOTE: Is this translation required?
	 */
	getFileStatus(postAction?: () => void) {
		// 0.
		const actionName = "getFileStatus"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		const { menuId, srcId, sector } = self.urlInfo

		// 3.
		// console.log("getFileStatus (before read) ", srcId, "/", sector)
		self.readFileStatus(srcId, sector, menuId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName, "__response", response)
					//
					// --- response.data ---
					// AccessRights: "ProjectAdmin"
					// ActualFilePath: null
					// Extension: ".xlsx"
					// FilePath: "https://636748713458824303.s3.ap-northeast-2.amazonaws.com/Documents/ref-excel_10_4106_080620201318161816.xlsx?X-Amz-Expires=28800&response-content-disposition=attachment%3Bfilename%3Dref-excel_10_4106_080620201318161816.xlsx&X-Amz-Algorithm=AWS4-HMAC-SHA256&X-Amz-Credential=AKIAJBRNLKQV6MBQWK3A/20200611/ap-northeast-2/s3/aws4_request&X-Amz-Date=20200611T055116Z&X-Amz-SignedHeaders=host&X-Amz-Signature=7a03895e7fa91c64217f04bc171ddae489d7f7675ce4afa63da2b8422a4ae241"
					// IsOpened: false
					// IsRequested: false
					// MediaID: 280549
					// Message: "Success"
					// PdfExists: true
					// Status: 1
					// SyncDownloaded: false
					// User: "" --> Current user who checked out this file
					// UserID: 0 --> 0: none / number: Current user ID who checked out this file
					// fileSize: "3KB"
					// ----------
					//
					const data = response.data
					const fileStatus = {
						id: idToString(data.MediaID, IdType.src),
						userRole: data.AccessRights, // "ProjectAdmin" includes "Project & Archive admin"
						path:
							data.ActualFilePath !== null
								? data.ActualFilePath
								: "",
						s3Path: data.FilePath !== null ? data.FilePath : "",
						extension: data.Extension,
						isOpened: data.IsOpened,
						isRequested: data.IsRequested,
						onSyncLobby: data.SyncDownloaded,
						isTrashed: data.TrashStatus,
						//
						checkedOutByName: data.User === "" ? null : data.User,
						checkedOutById:
							data.UserID === 0
								? null
								: idToString(data.UserID, IdType.user),
						//
						hasPdf: data.PdfExists,
						size: data.fileSize,
					}
					self.setFileStatus(fileStatus)
					postAction && postAction()

					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 1,
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						// message:
						// 	response.data.message ||
						// 	response.data.Message ||
						// 	"Something went wrong",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetFileStatus
