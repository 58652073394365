import React from "react"
import { dateRangeFilter } from "../../../../library/react-table-utils"
import {
	DLDatepicker,
	DLIconButton,
} from "../../../../components/basic-elements"
import {
	DLIcon,
	DLIconName,
} from "../../../../components/basic-elements/icons/common-icons"

const AuditProgramTableColumns = ({
	handleRemove,
	handleEdit,
	dateFormat,
	fromDialog,
}: {
	handleRemove: (rowId: string) => void
	handleEdit: (rowId: string) => void
	dateFormat: string
	fromDialog: boolean
}) => {
	const columns = [
		{
			header: "Legend",
			accessorKey: "legend",
			size: 60,
		},
		{
			header: "Audit Steps",
			accessorKey: "auditSteps",
			size: 200,
			cell: (props: any) => {
				return (
					<div
						data-testid="auditSteps-cell"
						style={{ width: 150, wordWrap: "break-word" }}
					>
						{props.getValue().length > 50
							? props.getValue().slice(0, 50) + "..."
							: props.getValue()}
					</div>
				)
			},
		},
		{
			header: "Assertions",
			accessorKey: "assertions",
			size: 60,
		},
		{
			header: "Ref",
			accessorKey: "ref",
			size: 60,
		},
	]

	const additionalColums = [
		{
			header: "Notes",
			accessorKey: "notes",
			size: 200,
			cell: (props: any) => {
				return (
					<div
						data-testid="auditSteps-cell"
						style={{ width: 150, wordWrap: "break-word" }}
					>
						{props.getValue().length > 50
							? props.getValue().slice(0, 50) + "..."
							: props.getValue()}
					</div>
				)
			},
		},
		{
			header: "Preparer",
			accessorKey: "preparer",
			size: 60,
		},
		{
			header: "Due Date",
			accessorKey: "preparerDueDate",
			filterFn: dateRangeFilter,
		},
		{
			header: "Reviewer",
			accessorKey: "reviewer",
			size: 60,
		},
		{
			header: "Due Date",
			accessorKey: "reviewerDueDate",
			filterFn: dateRangeFilter,
		},
		{
			header: "Prepared",
			accessorKey: "prepared",
			size: 60,
			cell: (props: any) => {
				return <div data-testid="prepared-cell">{props.getValue()}</div>
			},
		},
		{
			header: "Reviewed",
			accessorKey: "reviewed",
			size: 60,
			cell: (props: any) => {
				return <div data-testid="reviewed-cell">{props.getValue()}</div>
			},
		},
	]

	const actionColumn = [
		{
			header: "Actions",
			size: 60,
			cell: (props: any) => {
				const rowId = props.row.original.id
				return (
					<div data-testid="actions-cell" className="FR">
						<DLIconButton
							eleTestId="edit-btn"
							tooltipText="Edit"
							clickHandler={() => handleEdit(rowId)}
						>
							<DLIcon name={DLIconName.edit} />
						</DLIconButton>
						<DLIconButton
							eleTestId="delete-btn"
							tooltipText="Delete"
							clickHandler={() => handleRemove(rowId)}
						>
							<DLIcon name={DLIconName.delete} />
						</DLIconButton>
					</div>
				)
			},
		},
	]

	let allColumns

	if (fromDialog) {
		allColumns = [...columns, ...actionColumn]
	} else {
		allColumns = [...columns, ...additionalColums, ...actionColumn]
	}

	return allColumns
}

export default AuditProgramTableColumns
