import { observer } from "mobx-react-lite"
import React, { useEffect } from "react"
import {
	DLDialog,
	DLIconButton,
} from "../../../../../components/basic-elements"
import { DLTooltip } from "@datalobby/components"
import { FileScreenDialog } from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLFileProps } from "../../../../../service-modules/file-module/data-models/file.data-model"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import Icon from "@mdi/react"
import { mdiClose } from "@mdi/js"
import styled from "styled-components"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function MultipleSignOffDialog({
	partialStore,
	i18n,
}: {
	partialStore: {
		multipleSignOffType: {
			type: OldSignOffType
			forCancel: boolean
		}
		[x: string]: any
	}
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()

	const userId = projStore.checkin.userId
	const roleId = projStore.checkin.user.roleId

	const dialogName = FileScreenDialog.multipleSignOff
	const selectedItems = partialStore.selectedItems
	const signOffType = partialStore.multipleSignOffType.type
	const forCancel = partialStore.multipleSignOffType.forCancel

	const getFileInfo = partialStore.getItemInfo
	const getMessage = partialStore.getMessage
	const cancelSelect = partialStore.spliceSelectedItem

	useEffect(() => {
		selectedItems.map((item: any) => {
			const { id } = item
			const prefix = forCancel ? "unSignOff_" : "signOff_"
			const actionName = prefix + id

			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.standby,
			})
		})
	}, [])

	const handleAction = () => {
		let idList: string[] = []
		selectedItems.map((item: any) => {
			idList.push(item.id)
		})

		const props = {
			signOffType,
			forCancel,
			userId,
			roleId,
			fileIds: idList,
		}

		if (forCancel) {
			partialStore.sequentialMultipleUnSignOffs({
				...props,
				index: 0,
			})
		} else {
			partialStore.sequentialMultipleSignOffs({
				...props,
				index: 0,
			})
		}
	}

	const setOpen = () => {
		partialStore.setFileTreeDialogOpen(dialogName)
	}

	const open = partialStore.fileTreeDialogOpenStatus[dialogName]
	if (selectedItems.length === 0) {
		partialStore.setFileTreeDialogOpen(dialogName, false)
	}
	let dialogTitle = " Multiple Files"
	let buttonText = "Sign Off"
	if (signOffType === OldSignOffType.prepare) {
		dialogTitle = (forCancel ? "Un-Prepare" : "Prepare") + dialogTitle
		buttonText = forCancel ? "Un-Prepare" : "Prepare"
	} else if (signOffType === OldSignOffType.review) {
		dialogTitle = (forCancel ? "Un-Review" : "Review") + dialogTitle
		buttonText = forCancel ? "Un-Review" : "Review"
	}
	const multipleSignOffStatus = partialStore.getActionStatus(
		"sequentialMultipleSignOffs"
	)
	const multipleUnSignOffStatus = partialStore.getActionStatus(
		"sequentialMultipleUnSignOffs"
	)

	return (
		<DLDialog
			eleTestId="multiple-signoff-dialog"
			isOpen={open}
			setIsOpen={setOpen}
			handleAction={handleAction}
			dialogContents={
				<MultipleSignOffDialogContents
					forCancel={forCancel}
					fileIdList={selectedItems}
					getFileInfo={getFileInfo}
					getMessage={getMessage}
					cancelSelect={cancelSelect}
					partialStore={partialStore}
				/>
			}
			showOpenBtn={false}
			showCloseBtn
			cancelBtnText={i18n.twCancel || "Cancel"}
			dialogTitle={dialogTitle}
			actionReady={
				multipleSignOffStatus !== ActionStatus.loading &&
				multipleUnSignOffStatus !== ActionStatus.loading
			}
			actionBtn={buttonText}
			maxWidth="md"
			fullWidth
			draggable
		/>
	)
})

const MultipleSignOffDialogContents = observer(({
	forCancel,
	fileIdList,
	getFileInfo,
	getMessage,
	cancelSelect,
	partialStore,
}: {
	forCancel: boolean
	fileIdList: string[]
	getFileInfo: (id: string) => DLFileProps
	getMessage: (acitonName: string) => string
	cancelSelect: (id: string) => void
	partialStore: any
}) => {
	const getActionStatus = (actionName: string) =>
		partialStore.getActionStatus(actionName)

	return (
		<StyledDialogContents className="dialog-contents">
			<div className="total-count">
				Total counts: {fileIdList.length}{" "}
			</div>

			<table>
				<thead>
					<tr>
						<th className="aliasId">Ref. Num</th>
						<th className="title">File Name</th>
						<th className="extension">Extension</th>
						<th className="status">Status</th>
						<th className="msg">Message</th>
						<th className="cancel">Remove</th>
					</tr>
				</thead>
				<tbody>
					{fileIdList.map((file: any, i: number) => {
						const fileInfo = getFileInfo(file.id)
						const prefix = forCancel ? "unSignOff_" : "signOff_"
						const fileActionName = prefix + fileInfo.id
						const actionStatus = getActionStatus(fileActionName)
						const message = getMessage(fileActionName)
						return (
							<tr className="table-body" key={i}>
								<td
									className="aliasId"
									data-testid="aliasId"
								>
									{fileInfo.aliasId}
								</td>
								<td className="title" data-testid="title">
									{fileInfo.title}
								</td>
								<td
									className="extension"
									data-testid="extension"
								>
									{fileInfo.extension}
								</td>

								<td className="status" data-testid="status">
									{actionStatus !== ActionStatus.standby
										? actionStatus
										: "-"}
								</td>
								<td className="msg" data-testid="msg">
									{actionStatus === ActionStatus.fail ? (
										<DLTooltip title={message} arrow>
											<span>!</span>
										</DLTooltip>
									) : (
										"-"
									)}
								</td>
								<td className="cancel" data-testid="cancel">
									{actionStatus ===
										ActionStatus.standby && (
										<DLIconButton
											eleTestId="cancel-select"
											clickHandler={() =>
												cancelSelect(file.id)
											}
										>
											<Icon
												path={mdiClose}
												size={0.7}
											/>
										</DLIconButton>
									)}
								</td>
							</tr>
						)
					})}
				</tbody>
			</table>
		</StyledDialogContents>
	)
})

const StyledDialogContents = styled.div`
	min-height: 70vh;
	.total-count {
		margin-bottom: 1rem;
	}
	table {
		width: 100%;
		white-space: nowrap;
		th {
			padding: 0.4rem 0;
			border-bottom: 1px solid rgba(0, 0, 0, 0.3);
		}
		td {
			border-bottom: 1px solid rgba(0, 0, 0, 0.1);
		}
		.aliasId {
			text-align: left;
			width: 17%;
		}
		.title {
			text-align: left;
			width: 47%;
		}
		.extension {
			width: 8%;
			text-align: left;
		}
		.status {
			width: 10%;
			text-align: center;
		}
		.msg {
			width: 10%;
			text-align: center;
		}
		.cancel {
			width: 8%;
			text-align: center;
		}
	}
`
