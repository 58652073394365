import React from "react"
import { useRootStore } from "../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { Route, Redirect } from "react-router-dom"
import { ConsoleLog } from "../components/basic-elements"
import { ActionStatus } from "../common-models/enumerations/common-enums"

/**
 * WARNING: Manadatory Items of LobbyRoute
 * 1. lobbyUserId
 * 2. lobbyToken
 *
 * If any of these conditions not exist,
 * redirect the user to loggin screen
 */
const LobbyRoute = observer(
	({
		children,
		path,
		exact,
	}: {
		children: any
		path: string
		exact?: boolean
	}) => {
		/**
		 * * <Q1> Is prerequisiete true?
		 * --- if false: redirect to the login
		 * --- if true: --> Q2
		 * * <Q2> Is lobbyCheckinStatus success?
		 */

		const store = useRootStore()

		// required items
		const lobbyUserId = localStorage.getItem("lobbyUserId")
		const lobbyToken = localStorage.getItem("loginToken")
		// prerequisite
		const prerequisite = lobbyUserId !== null && lobbyToken !== null

		//
		const lobbyCheckinStatus = store.checkin.lobbyCheckinStatus

		if (path === "/" || path === "/login" || path === undefined) {
			return (
				<Route
					exact={exact}
					path={path}
					render={() =>
						prerequisite === false ? (
							children
						) : lobbyCheckinStatus !== "SUCCESS" ? (
							<Redirect
								to={{
									pathname: "/lobby-checkin",
									// state: { from: "/" },
								}}
							/>
						) : lobbyCheckinStatus === ActionStatus.success ? (
							<Redirect
								to={{
									pathname: "/lobby/organization-list",
								}}
							/>
						) : (
							children
						)
					}
				/>
			)
		} else {
			return (
				<Route
					exact={exact}
					path={path}
					render={({ location }) =>
						prerequisite === false ? (
							<Redirect
								to={{
									pathname: "/login",
								}}
							/>
						) : lobbyCheckinStatus !== "SUCCESS" ? (
							<Redirect
								to={{
									pathname: "/lobby-checkin",
									state: { from: location },
								}}
							/>
						) : (
							children
						)
					}
				/>
			)
		}
	}
)

export default LobbyRoute
