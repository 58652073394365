import React from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

import Icon from "@mdi/react"
import { mdiSendCircle } from "@mdi/js"
//
import styled from "styled-components"
import { DLSpinner } from "../../../../components/basic-elements"

export default function ForgotPwLoading({
	i18n,
}: {
	i18n: { [x: string]: string }
}) {
	const store = useRootStore()
	const { twLoading, tsLoadingMsg } = i18n
	return (
		<StyledForgotPwLoading className="card-body">
			<Icon path={mdiSendCircle} size={5} color="#ffffff" />
			<div className="title heading1">{twLoading}</div>
			<DLSpinner fullScreen lightBackground />
			<div className="registered-email">
				{tsLoadingMsg}: {store.forgotPw.email}
			</div>
		</StyledForgotPwLoading>
	)
}

const StyledForgotPwLoading = styled.div``
