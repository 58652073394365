import {
	ActionStatus,
	MessageColorType,
} from "../../../../common-models/enumerations/common-enums"
import { idToString, IdType } from "../../../../library/converters/id-converter"
import { ArchPolicyPeriodProps } from "./arch-policy-period.data-model"

const GetArchPolicyPeriodList = (self: any) => ({
	getArchPolicyPeriodList() {
		// 0. set actionName
		const actionName = "getArchPolicyPeriodList"
		// 1. set response cases
		// 2. set response
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3) request API call to Model
		self.readArchPolicyPeriodList()
			.then((response: any) => {
				// if success
				if (response.status === 200 && response.data.Status === 1) {
					//
					const fetchedData = response.data.ArchiveDatePolicyList
					let list: ArchPolicyPeriodProps[] = []
					fetchedData.map((item: any) => {
						const reOrganizedItem = {
							id: idToString(
								item.PolicyId,
								IdType.archPolicyPeriod
							),
							title: item.ArchiveDatePolicyType,
							days: parseInt(
								item.ArchiveDatePolicyType.split(" ")[0]
							),
						}
						list.push(reOrganizedItem)
					})
					self.setArchPolicyPeriodList(list)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200, // temp
						customMessage: "Successfully",
						color: MessageColorType.blue,
						open: false,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetArchPolicyPeriodList
