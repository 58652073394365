import { flow } from "mobx-state-tree"

const RequestResetPassword = (self: any, resetPassword: any) => ({
	requestResetPassword: flow(function* (payload: {
		AccessCode: string
		Newpwd: string
	}) {
		const actionName = "requestResetPassword"
		try {
			const response = yield resetPassword(payload)
			// console.log("requestResetPassword response", response)
			return response
		} catch (error) {
			// console.error("requestResetPassword error")
			self.handleModelError({ actionName, error, open: false })
			return false
		}
	}),
})

export default RequestResetPassword
