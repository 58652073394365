import { useReducer, useCallback, useRef } from "react"

interface AsyncOptions {
	initialData?: any
	initialIsLoading?: boolean
	resetData?: boolean
	extendData?: boolean
	onSuccess?: ((result: any) => void) | null
	onFail?: ((error: any) => void) | null
	onFinish?: (() => void) | null
}

interface AsyncState {
	data: any
	error: any
	isLoading: boolean
	options: {
		initialData?: any
		dataHandler: string
	}
}

interface AsyncAction {
	type: string
	payload?: any
}

const defaultOptions: AsyncOptions = {
	initialData: null,
	initialIsLoading: false,
	resetData: true,
	extendData: false,
	onSuccess: null,
	onFail: null,
	onFinish: null,
}

const DATA_HANDLERS = {
	KEEP: "keep",
	RESET: "reset",
	EXTENDS: "extends",
}

const TYPES = {
	RESOLVE_INIT: "RESOLVE_INIT",
	RESOLVE_SUCCESS: "RESOLVE_SUCCESS",
	RESOLVE_FAILURE: "RESOLVE_FAILURE",
	RESOLVE_RESET: "RESOLVE_RESET",
}

const resolveInit = (): AsyncAction => ({ type: TYPES.RESOLVE_INIT })
const resolveSuccess = (payload: any): AsyncAction => ({
	type: TYPES.RESOLVE_SUCCESS,
	payload,
})
const resolveFailure = (payload: any): AsyncAction => ({
	type: TYPES.RESOLVE_FAILURE,
	payload,
})
const resolveReset = (payload: any): AsyncAction => ({
	type: TYPES.RESOLVE_RESET,
	payload,
})

// const mergeWithCustomizer = (objValue: any, srcValue: any): any =>
//   Array.isArray(objValue) ? objValue.concat(srcValue) : undefined;

const combineData = (prevData: any, newData: any): any =>
	Array.isArray(prevData)
		? [...prevData, ...newData]
		: Object.assign({}, prevData, newData)

const promiseReducer = (state: AsyncState, action: AsyncAction): AsyncState => {
	switch (action.type) {
		case TYPES.RESOLVE_INIT:
			return {
				...state,
				isLoading: true,
				data:
					state.options.dataHandler === DATA_HANDLERS.RESET
						? state.options.initialData
						: state.data,
				error: null,
			}
		case TYPES.RESOLVE_SUCCESS:
			return {
				...state,
				isLoading: false,
				data:
					state.options.dataHandler === DATA_HANDLERS.EXTENDS
						? combineData(state.data, action.payload)
						: action.payload,
			}
		case TYPES.RESOLVE_FAILURE:
			return { ...state, isLoading: false, error: action.payload }
		case TYPES.RESOLVE_RESET:
			return {
				...state,
				data: state.options.initialData,
				error: action.payload.withError ? null : state.error,
			}
		default:
			throw new Error()
	}
}

export const useAsync = (
	fn: (params: any) => Promise<any>,
	options: AsyncOptions = {}
): [AsyncState, (params: any) => void, () => void] => {
	const {
		initialData,
		initialIsLoading,
		resetData,
		extendData,
		onSuccess,
		onFail,
		onFinish,
	} = {
		...defaultOptions,
		...options,
	}

	const handleSuccess = onSuccess ? onSuccess : undefined
	const handleFail = onFail ? onFail : undefined
	const handleFinish = onFinish ? onFinish : undefined

	let dataHandler = resetData ? DATA_HANDLERS.RESET : DATA_HANDLERS.KEEP
	if (extendData) dataHandler = DATA_HANDLERS.EXTENDS

	const [state, dispatch] = useReducer(promiseReducer, {
		data: initialData,
		error: null,
		isLoading: initialIsLoading || false,
		options: { initialData, dataHandler },
	})

	const lastFn = useRef<Promise<any> | null>(null)

	const handler = useCallback(
		async (params: any) => {
			dispatch(resolveInit())
			let promise: Promise<any> | undefined
			try {
				promise = fn(params)
				lastFn.current = promise
				const result = await promise
				if (lastFn.current === promise) {
					dispatch(resolveSuccess(result))
					handleSuccess?.(result)
				}
			} catch (error) {
				if (lastFn.current === promise) {
					dispatch(resolveFailure(error))
					handleFail?.(error)
				}
			} finally {
				handleFinish?.()
			}
		},
		[fn, handleFail, handleFinish, handleSuccess]
	)

	const resetDataHandler = useCallback(
		(withError = true) => dispatch(resolveReset({ withError })),
		[]
	)

	return [state, handler, resetDataHandler]
}
