import React from "react"
import styled from "styled-components"
import Icon from "@mdi/react"
import { mdiChevronRight } from "@mdi/js"

export type BreadCrumbsPathProps = { id: string; name: string }[]

export const BreadCrumbsArrow = () => {
	return (
		<div
			className="arrow-right FR AC"
			style={{ marginRight: 4, opacity: 0.3 }}
		>
			<Icon path={mdiChevronRight} size={0.8} />
		</div>
	)
}

export const StyledDLBreadCrumbs: any = styled.div`
	.click-able {
		cursor: pointer;
		&:hover {
			color: ${(props) => props.theme.emphasis};
			font-weight: 700;
			cursor: pointer;
		}
	}
	&.dl-bread-crumbs-2-container {
		flex-wrap: wrap;
		.note {
			color: ${(props) => props.theme.warning};
		}
		.divider {
			margin: 0 1rem;
		}
		.crumb-container {
			height: 2rem;
			transition: 0.4s;
			.crumb {
				/* margin-right: 8px; */
				transition: 0.4s;
				.close-btn {
					opacity: 0;
					width: 0;
					overflow: hidden;
					color: gray;
					margin-left: 4px;
					transition: 0.4s;
					&:hover {
						color: ${(props) => props.theme.primaryMainDeep};
						cursor: pointer;
					}
				}
				&:hover {
					.close-btn {
						opacity: 1;
						width: 16px;
					}
				}
			}
			.last-crumb {
				font-weigth: 700;
				color: ${(props) => props.theme.primaryMainDeep};
			}
		}
	}
`
