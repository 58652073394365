import { AxiosResponse } from "axios"
import { DeleteProjectApiProps } from "../../../../service-modules/project-module/org-project-shared-apis"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import {
	getRequest,
	putRequest,
	deleteRequest,
} from "../../../../library/api-requests"

type ReadProps = { ClientID: number; Lang?: String }

export async function read(params?: ReadProps) {
	const response = await getRequest({
		url: "/GetClientTrashDetails",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type RestoreProps = { TrashID: number; UserID: number }

export async function restore(params?: RestoreProps) {
	const response = await putRequest({
		url: "/ClientRestore",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

type RemoveProps = { TrashID: number; Lang?: string }

export async function remove(params?: RemoveProps) {
	const response = await deleteRequest({
		url: "/ClientPermanentDelete",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export async function removeAll() {
	const response = await deleteRequest({
		url: "/DeleteAll",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ProjSetupTrashApiProps = {
	apiRead: (params: ReadProps) => Promise<AxiosResponse<any>>
	apiRemove: (params: RemoveProps) => Promise<AxiosResponse<any>>
	apiRestore: (params: RestoreProps) => Promise<AxiosResponse<any>>
	apiRemoveAll: () => Promise<AxiosResponse<any>>
	apiRemoveProj: (
		payload: DeleteProjectApiProps
	) => Promise<AxiosResponse<any>>
}
