import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"
import { postWithQueryString } from "../../../../../library/api-requests/request-post-others"
import { getRequest, postRequest } from "../../../../../library/api-requests"

export function create(params: { Title: string; Description: string }) {
	const response = postRequest({
		url: "/CreateArchivePolicy",
		params,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function read() {
	const response = getRequest({
		url: "/GetArchivePolicy",
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

type Condition = {
	ArchivePolicyId: number
	ArchivePreConditionId: number
}

type MoveArchCond = {
	MandatoryList: Condition[]
	NonMandatoryList: Condition[]
}

export function moveArchiveCondition(payload: MoveArchCond) {
	const response = postRequest({
		url: "/MoveArchviePrecondition", // NOTE: Typo on the endpoint...
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}
export function update(payload: {
	Title: string
	Description: string
	Id: number
}) {
	const response = postRequest({
		url: "/CreateArchivePolicy",
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export function del(payload: { ArchivePolicyId: number }) {
	const response = postRequest({
		url: `/DeleteArchivePolicy?ArchivePolicyId=${payload.ArchivePolicyId}`,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export function copy(payload: { ArchivePolicyId: number }) {
	const response = postRequest({
		url: `/CopyArchivePolicy?ArchivePolicyId=${payload.ArchivePolicyId}`,
		params: payload,
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export function reset(payload: { ArchivePolicyId: number }) {
	const response = postRequest({
		url: `/ResetArchivePreconditions?ArchivePolicyId=${payload.ArchivePolicyId}`,
		params: {},
		tokenType: TOKEN_TYPE.orgToken,
	})
	return response
}

export function moveEngType(payload: {
	EngagementId: number
	SourceArchivePolicyId: number
	TargetArchivePolicyId: number
}) {
	const response = postWithQueryString({
		url: `/MoveEngagementType?EngagementId=${payload.EngagementId}&SourceArchivePolicyId=${payload.SourceArchivePolicyId}&TargetArchivePolicyId=${payload.TargetArchivePolicyId}`,
		tokenType: TOKEN_TYPE.orgToken,
	})

	return response
}

export type OrgSetupArchPolicyApiProps = {
	apiRead: () => Promise<AxiosResponse<any>>
	apiUpdate: (payload: {
		Title: string
		Description: string
		Id: number
	}) => Promise<AxiosResponse<any>>
	apiDelete: (payload: {
		ArchivePolicyId: number
	}) => Promise<AxiosResponse<any>>
	apiCreate: (params: {
		Title: string
		Description: string
	}) => Promise<AxiosResponse<any>>
	apiCopy: (payload: {
		ArchivePolicyId: number
	}) => Promise<AxiosResponse<any>>
	apiMoveArchiveCondition: (
		payload: MoveArchCond
	) => Promise<AxiosResponse<any>>
	apiReset: (payload: {
		ArchivePolicyId: number
	}) => Promise<AxiosResponse<any>>
	apiMoveEngType: (payload: {
		EngagementId: number
		SourceArchivePolicyId: number
		TargetArchivePolicyId: number
	}) => Promise<AxiosResponse<any>>
}
