import { observer } from "mobx-react-lite"
import React from "react"
import styled from "styled-components"
import { capitalizeFirstLetter } from "@datalobby/common"

export default observer(function BasicCounter({
	label,
	value,
}: {
	label: string
	value: string | number
}) {
	return (
		<StyledBasicCounter className="FR AC dl-basic-counter">
			<span className="count-label" data-testid="count-label">
				{capitalizeFirstLetter(label, true)}
			</span>
			<span className="count-value" data-testid="count-value">
				{value}
			</span>
		</StyledBasicCounter>
	)
})

const StyledBasicCounter = styled.div`
	.count-label {
		margin-right: 0.4rem;
	}
`
