import { types, applySnapshot } from "mobx-state-tree"
import Responses from "../../../../common-models/responses"
import ResponseSnackbar from "../../../../common-models/response-snackbar"
import {
	CommonViewModelActions,
	ViewResponseHelper,
	Refresh,
} from "../../../../common-models/common-view-model-actions"
import {
	GetOrgGroupList,
	GetOrgGroupCabinetList,
} from "../../../organization-side/groups/store/view-model-actions"
import { OrgFlatGroupItem } from "../../../organization-side/groups/store/data-models/groups.data-models"
import { BreadCrumbsOptionProps } from "../../../../components/combined-elements/postpone-to-classify/BreadCrumbsAndOptions"
import { DLObjectFormat } from "../../../../common-models/enumerations/common-enums"
import {
	GroupsPathForSelectionViews,
	GroupsPathForSelectionActions,
} from "../../../organization-side/groups/store/ui-helper-actions/groups-path-for-selection-actions"
import { compareValues } from "../../../../components/basic-elements/tree-list/getTreeFromFlatData"
import { GetClientsByGroup } from "../../../../stores/org-sub-stores/org-projects-store/view-model-actions"
import {
	ClientsByGroup,
	SimpleClientProps,
} from "../../../../stores/org-sub-stores/org-projects-store/data-models/org-project.data-model"
import { AddProject } from "../../../../service-modules/project-module/view-model-actions"
import { initialStore } from "./proj-roll-forward.provider"

const ProjRollForwardViewModel = types
	.model({
		orgGroupFlatList: types.array(OrgFlatGroupItem),
		pathForSelection: types.array(types.string),
		clientsByGroupMap: types.map(ClientsByGroup),
		needRefresh: true,
		// ----- common models
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions(GetOrgGroupList)
	.actions(GetOrgGroupCabinetList)
	.actions(GetClientsByGroup)
	.actions(AddProject)
	.actions((self) => ({
		setGroupList(list: any) {
			self.orgGroupFlatList.length = 0
			self.orgGroupFlatList = list
		},
		concatNewList(newList: any) {
			// NOTE: if concat old and new list here, it occurs an error... (Not sure the reason...)
			// So we need concat old and new one in the viewModel (at reflect part)
			self.orgGroupFlatList = newList
		},
		setClientsByGroupMap(clients: SimpleClientProps[], groupId: string) {
			const clientsByGroup = {
				groupId: groupId,
				clients: clients,
			}
			self.clientsByGroupMap.set(groupId, clientsByGroup)
		},
	}))
	.views((self) => ({
		getTargetDetail(id: string) {
			return self.orgGroupFlatList.find((item: any) => item.id === id)
		},
		get groupOptions() {
			const groups = self.orgGroupFlatList.filter(
				(item) => item.type === DLObjectFormat.group
			)
			let options: BreadCrumbsOptionProps[] = []
			groups.map((group) => {
				options.push({
					id: group.id,
					name: group.title,
					objFormat: DLObjectFormat.group,
				})
			})
			return options
		},
		getChildrenInTheFlatListAsOptions(parentId: string) {
			const list = self.orgGroupFlatList.filter(
				(item) => item.parentId === parentId
			)
			const sortedList = list.sort(compareValues("title"))
			let options: BreadCrumbsOptionProps[] = []
			sortedList.map((item) => {
				options.push({
					id: item.id,
					name: item.title,
					objFormat: item.type,
				})
			})
			return options
		},
		clientsByGroupOptions(groupId: string) {
			const clientsByGroup = self.clientsByGroupMap.get(groupId)?.clients
			let options: { id: string; name: string; aliasId: string }[] = []
			if (clientsByGroup) {
				clientsByGroup.map((client) => {
					options.push({
						id: client.id,
						name: client.name,
						aliasId: client.aliasId,
					})
				})
				return options
			} else {
				return []
			}
		},
	}))
	.actions(GroupsPathForSelectionActions)
	.views(GroupsPathForSelectionViews)
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default ProjRollForwardViewModel
