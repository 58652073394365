import { types } from "mobx-state-tree"
import PBCModel from "./pbc.model"
import PBCViewModel from "./pbc.view-model"
import * as api from "./pbc.apis"

export const PBCStore = (apiRead: any) =>
	types.compose(PBCModel(apiRead), PBCViewModel).named("PBCStore")

export const initialStore = {
	url: "",
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const PBCStoreInstance = PBCStore(api.read).create(initialStore)

export default PBCStoreInstance
