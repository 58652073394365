import { flow } from "mobx-state-tree"

const CreateEliminatingEntry = (self: any, create: any) => ({
	createEliminatingEntry: flow(function*(payload: {
		AccountDescription: string
		AccountNumber: string
		ClientID: number
		Credit: string
		Debit: string
		EntityID: number
		Year: string
	}) {
		const actionName = "createEliminatingEntry"
		try {
			const response = yield create(payload)
			// ConsoleLog("pdf response response", response)
			return response
		} catch (error) {
			// ConsoleLog("pdf response error", error)
			self.handleModelError({ actionName, error, open: true })

			return false
		}
	}),
})

export default CreateEliminatingEntry
