import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { DLSpinner, DLButton } from "../../../../components/basic-elements"
import styled from "styled-components"
import CardComponent from "./sub-components/summary/CardComponent"
import CardFilters from "./sub-components/CardFIlters"
import CardDetailView from "./sub-components/details/CardDetailView"
import { CardType } from "../table-view/store/data-models/dashboard-table-view.data-model"
import { PageContainer } from "../../../../components/app-frame-elements"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import DomToImage from "dom-to-image"
import Icon from "@mdi/react"
import { mdiDownload, mdiImage, mdiImageOutline } from "@mdi/js"
import html2canvas from "html2canvas"
import { saveAs } from "file-saver"
import HBarTableDetailsDialog from "./sub-components/dialogs/HBarTableDetailsDialog"
import { DLI18nProps } from "../../../../common-models/types/common-props"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import { handleExport } from "../../../../components/basic-elements/export/ExportXLSX"
import CardViewPageContextMenu from "./sub-components/CardViewPageContextMenu"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import FiltersDialog from "../graph-view/sub-components/FiltersDialog"

export default observer(function OrgDashboardCardView({
	partialStore,
	contentsHeight,
	actionStatus,
	i18n,
}: {
	partialStore: any
	contentsHeight: any
	actionStatus: ActionStatus
	i18n: DLI18nProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.dash_chart
	const permission = orgStore.checkin.getActionsAsObject(menuId)
	const downloadAreaId = "download-as-img-area"

	const userId = localStorage.getItem("orgUserId")
	const pageSettingName = `pageSetting_${menuId}`
	const pageSetting = localStorage.getItem("pageSetting")
	const pageSettingData = pageSetting ? JSON.parse(pageSetting) : []
	let currentFilters: string[] = []

	let filters: any = ""
	if (pageSettingData.some((item: any) => item.userId === userId)) {
		filters = pageSettingData.find((item: any) => item.userId === userId)[
			pageSettingName
		]
	}
	console.log("filters", filters)
	useEffect(() => {
		if (filters) {
			const dataLength = Object.keys(filters).length
			console.log("data of ", userId, filters, dataLength)

			for (let i = 0; i < dataLength; i++) {
				console.log("i---", i)
				const values = filters[i]
				console.log("values:", filters[i], values)

				values && values.map((value: any) => currentFilters.push(value))
			}

			console.log("currentFilters:", currentFilters)
		}
	}, [pageSetting])

	console.log("pageSettingData:", pageSettingData)

	const [open, setOpen] = useState(false)

	const downloadAsImg = () => {
		const downloadArea = document.getElementById(downloadAreaId)
		const fileName = "Dashboard-" + partialStore.cardType + ".jpeg"
		if (downloadArea) {
			DomToImage.toJpeg(downloadArea, {
				quality: 1,
				bgcolor: "white",
			}).then((url) => {
				var link = document.createElement("a")
				link.download = fileName
				link.href = url
				link.click()
			})
		}
	}

	const contentsAreaWidth = store.ui.contentsAreaWidth
	const widthStandard = contentsAreaWidth / 3.3
	const basicCardWidth = 360

	let cardWidth =
		widthStandard >= basicCardWidth
			? widthStandard
			: widthStandard < 227
			? contentsAreaWidth - 80
			: basicCardWidth
	if (
		contentsAreaWidth > basicCardWidth * 2 &&
		contentsAreaWidth < basicCardWidth * 3
	) {
		cardWidth = contentsAreaWidth / 2.3
	}

	// Theme setting
	const mainColor = store.ui.getThemeValue("mainColor")
	const subColor = store.ui.getThemeValue("subColor")
	const emphasis = store.ui.getThemeValue("emphasis")
	const themeRed = store.ui.getThemeValue("themeRed")

	const filteredData = partialStore.getFilteredData()

	const bgColors = store.ui.getThemeShades()

	const selectedCard = partialStore.cardType
	const progressRateCardData = partialStore.getProgressRateCardData(
		filteredData,
		mainColor,
		subColor,
		emphasis
	)
	const engTypeCardData = partialStore.getEngTypePieData(bgColors)
	const groupCardData = partialStore.getGroupsPieData(bgColors)
	const epCardData = partialStore.getEPPieData(bgColors)
	const qcCardData = partialStore.getQCPieData(bgColors)
	const periodTypeCardData = partialStore.getPeriodTypePieData(bgColors)
	//
	//
	const dntFormat = store.global.getDntFormat
	const dateFormat = store.global.dateFormat.value
	return (
		<StyledCardView>
			<PageContainer
				fullWidth
				pageTools={
					<div className="FR JSB">
						<div>
							<DLButton
								eleTestId="set-filters"
								color="primary"
								clickHandler={() => setOpen(true)}
							>
								Set Filters
							</DLButton>
						</div>
						<CardViewPageContextMenu
							permission={permission}
							getTotalData={() =>
								partialStore.forExport(dntFormat, filteredData)
							}
							downloadAsImg={downloadAsImg}
							fileName="Filtered Dashboard Projects"
						/>
					</div>
				}
				defaultToolbarStatus="expanded"
				eleClassName="topic-dashboard-summary-container"
			>
				<div className="topic-dashboard-summary-body">
					{/* ----- Spinner ----- */}
					{actionStatus === "LOADING" && (
						<div
							className="FR AC JC"
							style={{
								width: "100%",
								height: contentsHeight,
							}}
						>
							<DLSpinner />
						</div>
					)}
					{actionStatus === "SUCCESS" && (
						<>
							{partialStore.showCardDetailView ? (
								<CardDetailView
									idForImgDownload={downloadAreaId}
									partialStore={partialStore}
									cardType={selectedCard}
									data={filteredData}
									downloadAsImg={downloadAsImg}
									summaryData={
										selectedCard === CardType.progressRate
											? partialStore.getProgressRateCardData(
													filteredData,
													mainColor,
													subColor,
													emphasis
											  )
											: selectedCard === CardType.engType
											? engTypeCardData
											: selectedCard === CardType.group
											? groupCardData
											: selectedCard === CardType.epUser
											? epCardData
											: selectedCard === CardType.qcUser
											? qcCardData
											: selectedCard ===
											  CardType.periodType
											? periodTypeCardData
											: undefined
									}
									i18n={i18n}
								/>
							) : (
								<div
									className="card-container FR JSA"
									id={downloadAreaId}
								>
									<CardComponent
										cardWidth={cardWidth}
										cardType={CardType.progressRate}
										cardData={progressRateCardData}
										cardOptions={{
											cutoutPercentage: 80,
											title: {
												display: true,
												text:
													i18n.twProgressRate ||
													"Progress Rate",
											},
											legend: {
												display: false,
											},
										}}
										tableData={partialStore.getProgressRateSummary(
											filteredData
										)}
										// tableData={partialStore.getProgressRateDetails()}
										total={filteredData.length}
									/>
									<CardComponent
										cardWidth={cardWidth}
										cardType={CardType.engType}
										cardData={engTypeCardData}
										cardOptions={{
											cutoutPercentage: 0,
											title: {
												display: true,
												text:
													i18n.twEngType ||
													"Engagement Type",
											},
											legend: {
												display: false,
											},
										}}
										tableData={partialStore.getTableSummaryData(
											partialStore.viewEngTypes(),
											filteredData,
											"engTypeName"
										)}
										total={filteredData.length}
									/>
									<CardComponent
										cardWidth={cardWidth}
										cardType={CardType.group}
										cardData={groupCardData}
										cardOptions={{
											cutoutPercentage: 0,
											title: {
												display: true,
												text: i18n.twGroup || "Groups",
											},
											legend: {
												display: false,
											},
										}}
										tableData={partialStore.getTableSummaryData(
											partialStore.viewGroupNames(),
											filteredData,
											"groupName"
										)}
										total={filteredData.length}
									/>
									<CardComponent
										cardWidth={cardWidth}
										cardType={CardType.epUser}
										cardData={epCardData}
										cardOptions={{
											cutoutPercentage: 80,
											title: {
												display: true,
												text:
													i18n.twEngagementPartner ||
													"Enagagement Partner",
											},
											legend: {
												display: false,
											},
										}}
										tableData={partialStore.getTableSummaryData(
											partialStore.viewEPUsers(),
											filteredData,
											"epUsers"
										)}
										total={filteredData.length}
									/>
									<CardComponent
										cardWidth={cardWidth}
										cardType={CardType.qcUser}
										cardData={qcCardData}
										cardOptions={{
											cutoutPercentage: 80,
											title: {
												display: true,
												text:
													i18n.twQualityController ||
													"Quality Controller",
											},
											legend: {
												display: false,
											},
										}}
										tableData={partialStore.getTableSummaryData(
											partialStore.viewQCUsers(),
											filteredData,
											"qcUsers"
										)}
										total={filteredData.length}
									/>
									<CardComponent
										cardWidth={cardWidth}
										cardType={CardType.periodType}
										cardData={periodTypeCardData}
										cardOptions={{
											cutoutPercentage: 0,
											title: {
												display: true,
												text:
													i18n.twPeriodType ||
													"Period Type",
											},
											legend: {
												display: false,
											},
										}}
										tableData={partialStore.getTableSummaryData(
											partialStore.viewPeriodTypes(),
											filteredData,
											"periodName"
										)}
										total={filteredData.length}
									/>
								</div>
							)}
						</>
					)}
					{partialStore.hBarTableDetailsDialogOpen && (
						<HBarTableDetailsDialog
							partialStore={partialStore}
							i18n={i18n}
						/>
					)}
					{open && (
						<FiltersDialog
							open={open}
							setOpen={setOpen}
							dialogContent={
								<CardFilters
									partialStore={partialStore}
									setOpen={setOpen}
									i18n={i18n}
								/>
							}
						/>
					)}
				</div>
			</PageContainer>
		</StyledCardView>
	)
})

const StyledCardView = styled.div`
	.topic-dashboard-summary-container {
		.page-contents-area {
			padding-top: 0.4rem;
		}
	}
	.topic-dashboard-summary-body {
		padding-left: 1rem;
		padding-right: 1rem;
		padding-bottom: 3rem;
		.card-container {
			display: flex;
			flex-wrap: wrap;
			margin-top: 1rem;
		}
	}
`
