import React from "react"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import OrgMgmtTableColumns, {
	OrgMgmtTableColumns1,
} from "./sub-components/OrgMgmtTableColumns"
import OrgMgmtSnackbar from "./sub-components/OrgMgmtSnackbar"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"
import {
	PageContainer,
	PageHeader,
} from "../../../components/app-frame-elements"
import styled from "styled-components"
import AddOrgnizationDialog, {
	CreateCustomerBtn,
} from "./dialogs/AddOrganizationDialog"
import EditOrganizationDialog from "./dialogs/EditOrganizationDialog"
import { DLI18nProps } from "../../../common-models/types/common-props"
import BasicCounter from "../../../components/app-frame-elements/sub-components/BasicCounter"
import ReactTableV8 from "../../../components/basic-elements/tables/DLReactTable"

export default observer(function AmArchivedProjects({
	partialStore,
	fetchingStatus,
	i18n,
}: // permission,
{
	partialStore: any
	fetchingStatus: ActionStatus
	i18n: DLI18nProps
	// permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const contentHeight = store.ui.contentsAreaHeight
	const handleEdit = (id: string) => {
		partialStore.setSelectedId(id)
		partialStore.setOpenEdit(true)
	}

	return (
		<PageContainer
			pageTools={<div />}
			hasToolbar={false}
			fullWidth
			isLoading={fetchingStatus === "LOADING"}
		>
			<StyledOrgMgmt>
				{fetchingStatus === "SUCCESS" && (
					<>
						<PageHeader>
							<BasicCounter
								label={i18n.twTotalOrg}
								value={partialStore.orgMgmtList.length}
							/>

							<div className="FR AC">
								<CreateCustomerBtn i18n={i18n} />
							</div>
						</PageHeader>

						<ReactTableV8
							tableColumns={OrgMgmtTableColumns1(
								handleEdit,
								i18n
							)}
							// tableColumns={OrgMgmtTableColumns(handleEdit, i18n)}
							data={partialStore.sortedOrgList}
							hasPagination={false}
							showPageSetting={false}
							height={contentHeight}
							menuId="OrgMgmt"
							// getRowClass={(rowInfo: any) => {
							// 	const status = rowInfo.original.custStatus
							// 	if (status === "Inactive") {
							// 		return "inactive"
							// 	}
							// 	return ""
							// }}
						/>
					</>
				)}
				<OrgMgmtSnackbar />
				{/* Dialogs */}
				{partialStore.openEdit && (
					<EditOrganizationDialog i18n={i18n} />
				)}
				{partialStore.openAdd && <AddOrgnizationDialog i18n={i18n} />}
			</StyledOrgMgmt>
		</PageContainer>
	)
})

const StyledOrgMgmt = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
	.inactive {
		opacity: 0.4;
	}
`
