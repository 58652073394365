import React, { useState } from "react"
import { observer } from "mobx-react-lite"
import { DLDialog } from "../../../../../components/basic-elements"
import styled from "styled-components"
import { DLSearchBoxBasic } from "../../../../../components/basic-elements/search/DLSearchBox"

const AssignedDetailsDialog = observer(
    ({ partialStore }: { partialStore: any }) => {
        const [searchText, setSearchText] = useState("")

        const groupInfo = partialStore.getGroupInfoById(
            partialStore.selectedGroup
        )

        const assignedList =
            partialStore.selectedGroupType === "Users"
                ? groupInfo.assignedUsers
                : groupInfo.assignedClients

        const itemList = assignedList
            ? assignedList.split(",").map((item: string) => item.trim())
            : []

        const handleSearch = (e: any) => setSearchText(e.target.value)

        const filteredList = searchText
            ? itemList.filter((item: string) =>
                  item.toLowerCase().includes(searchText.toLowerCase())
              )
            : itemList

        return (
            <DLDialog
                eleTestId="assigned-details-dialog"
                isOpen={partialStore.showAssignedDetailsDialog}
                setIsOpen={partialStore.setShowAssignedDetailsDialog}
                showOpenBtn={false}
                showCloseBtn
                maxWidth="xs"
                dialogTitle={`${groupInfo.groupName} - Assigned ${partialStore.selectedGroupType} (${itemList.length})`}
                dialogContents={
                    <StyledList>
                        <DLSearchBoxBasic
                            id="project-search"
                            eleTestId="search_text_box"
                            iconPlacement="end"
                            onChange={handleSearch}
                            eleValue={searchText}
                            placeholder="Search"
                            closeHandler={() => setSearchText("")}
                        />
                        <div className="list-items">
                            <ul>
                                {filteredList.map((item: string) => (
                                    <li key={item}>{item}</li>
                                ))}
                            </ul>
                        </div>
                    </StyledList>
                }
                cancelBtnText="Close"
            />
        )
    }
)

const StyledList = styled.div`
    .list-items {
        height: 300px;
        overflow-y: scroll;
        li {
            padding: 5px;
        }
    }
`
export default AssignedDetailsDialog
