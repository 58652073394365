import React, { useEffect, useState } from "react"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import ArchiveManagementUnarchived from "./AmUnarchivedProjects"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import SharedProjTableInitialFilters from "../../../../components/combined-elements/projects-table/SharedProjectTableInitialFilters"
import {
    getDefaultFinancialYear,
    getDefaultYear,
} from "../../../../library/get-page-settings"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function ArchiveManagementUnarchivedController() {
    const store = useRootStore()
    const orgStore = useOrgStore()
    const menuId = DLOrgSubMenus.unarchived_mgmt
    const actionName = "getUnarchivedProjects"
    const i18nSheet = OrgI18n.archMgmt
    //
    const menuAccess = orgStore.checkin.checkAccess(menuId)
    const permission = orgStore.checkin.getActionsAsObject(menuId)

    const needRefresh = orgStore.unarchiveMgmt.needRefresh

    useEffect(() => {
        const defaultYear = getDefaultFinancialYear(menuId)
        if (menuAccess && needRefresh) {
            orgStore.unarchiveMgmt.getUnarchivedProjects({ year: defaultYear })
        }
    }, [menuAccess, needRefresh])

    const i18n = store.i18n.combineI18n(
        i18nSheet,
        "AmUnarchivedProjectsController"
    )
    return (
        <>
            {menuAccess ? (
                <ArchiveManagementUnarchived
                    partialStore={orgStore.unarchiveMgmt}
                    fetchingStatus={orgStore.unarchiveMgmt.getActionStatus(
                        actionName
                    )}
                    permission={permission}
                    i18n={i18n}
                />
            ) : (
                <OrgNoPermissionOnMenu menuId={menuId} />
            )}
        </>
    )
})
