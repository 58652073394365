import { types } from "mobx-state-tree"
import {
	CommonSelectedItem,
	SelectedItemsSharedActions,
	SelectedItemsSharedViews,
} from "../../../../../common-models/shared-store-code/selected-items-related/selected-items-shared-actions"

const ClickPoint = types.model({
	mouseX: types.union(types.null, types.number),
	mouseY: types.union(types.null, types.number),
})

const AmArchivedProjectsUiHelper = types
	.model({
		selectedProj: types.string,
		selectedCabinetId: types.string,
		clickPoint: ClickPoint,
		rightClickTarget: "",
		showUnarchivedDetails: types.optional(types.boolean, false),
		selectedProjType: "",
		//
		selectedItems: types.array(CommonSelectedItem),
		//
		// dialogs
		accessMgmtDialogOpen: types.boolean,
		archivedProjectUnarchiveDialogOpen: types.boolean,
		projectDetailsDialogOpen: types.boolean,
		removeArchivedProjectDialogOpen: types.boolean,
		unarchiveProgressDialogOpen: types.boolean,
		digitalSignatureDialogOpen: false,
		preProcessDialog: false,
		repairReplicaDialog: false,
		archiveZipRegenerationDialog: false,
		releaseForceArchiveDialog: false,
	})
	.actions(SelectedItemsSharedActions)
	.views(SelectedItemsSharedViews)
	.actions((self) => ({
		setSelectedProj(projectId: string) {
			self.selectedProj = projectId
		},
		setSelectedCabinetId(cabinetId: string) {
			self.selectedCabinetId = cabinetId
		},
		setClickPoint(clickPoint: typeof self.clickPoint) {
			self.clickPoint = clickPoint
		},
		setRightClickTarget(targetId: string) {
			self.rightClickTarget = targetId
		},
		setShowUnarchivedDetails(request: boolean) {
			self.showUnarchivedDetails = request
		},
		setSelectedProjType(type: string) {
			self.selectedProjType = type
		},
		// dialogs
		setAccessMgmtDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.accessMgmtDialogOpen = request
			} else {
				self.accessMgmtDialogOpen = !self.accessMgmtDialogOpen
			}
		},
		setProjectDetailsDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.projectDetailsDialogOpen = request
			} else {
				self.projectDetailsDialogOpen = !self.projectDetailsDialogOpen
			}
		},
		setRemoveArchivedProjectDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.removeArchivedProjectDialogOpen = request
			} else {
				self.removeArchivedProjectDialogOpen =
					!self.removeArchivedProjectDialogOpen
			}
		},
		setArchivedProjectUnarchiveDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.archivedProjectUnarchiveDialogOpen = request
			} else {
				self.archivedProjectUnarchiveDialogOpen =
					!self.archivedProjectUnarchiveDialogOpen
			}
		},
		setUnarchiveProgressDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.unarchiveProgressDialogOpen = request
			} else {
				self.unarchiveProgressDialogOpen =
					!self.unarchiveProgressDialogOpen
			}
		},
		setDigitalSignatureDialogOpen(request?: boolean) {
			if (request !== undefined) {
				self.digitalSignatureDialogOpen = request
			} else {
				self.digitalSignatureDialogOpen =
					!self.digitalSignatureDialogOpen
			}
		},
		setPreProcessDialog(request?: boolean) {
			if (request !== undefined) {
				self.preProcessDialog = request
			} else {
				self.preProcessDialog = !self.preProcessDialog
			}
		},
		setRepairReplicaDialog(request?: boolean) {
			if (request !== undefined) {
				self.repairReplicaDialog = request
			} else {
				self.repairReplicaDialog = !self.repairReplicaDialog
			}
		},
		setArchiveZipRegenerationDialog(request?: boolean) {
			if (request !== undefined) {
				self.archiveZipRegenerationDialog = request
			} else {
				self.archiveZipRegenerationDialog =
					!self.archiveZipRegenerationDialog
			}
		},
		setReleaseForceArchiveDialog(request?: boolean) {
			self.releaseForceArchiveDialog =
				request ?? !self.releaseForceArchiveDialog
		},
	}))

export default AmArchivedProjectsUiHelper
