import { types, applySnapshot } from "mobx-state-tree"
// ---------- common models
import Responses from "../../../../../common-models/responses"
import ResponseSnackbar from "../../../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../../../common-models/common-view-model-actions"
import { initialStore } from "./passed-adjustments.provider"
import GetPassedAdjustments from "./view-model-actions/get-passed-adjustments"
import { TBDetails } from "../../financial-statements/store/data-models/financial-statement.data-model"
import GetTrialBalanceDetails from "../../financial-statements/store/view-model-actions/get-trial-balance-details"
import AddPassedAdjustments from "./view-model-actions/add-passed-adjustment"
import RemovePassedAdjustments from "./view-model-actions/remove-passed-adjustment"
import GetPassedAdjustmentUrl from "./view-model-actions/get-passed-adjustment-url"
import {
	AjeModel,
	WPRef,
} from "../../adjustments/store/data-models/adjustments.data-model"
import GetWPReferences from "../../adjustments/store/view-model-actions/get-wp-references"

const AdjustmentsViewModel = types
	.model({
		passedAdjustments: types.array(AjeModel),
		tbDetails: types.array(TBDetails),
		wpRef: types.array(WPRef),
		url: "",
		// ---------- common models
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar, // snackbar type 2
	})
	.actions((self) => ({
		setPassedAdjustments(ajes: any) {
			self.passedAdjustments = ajes
		},
		setTBDetails(details: any) {
			self.tbDetails = details
		},
		setUrl(url: string) {
			self.url = url
		},
		setWPReferences(wp: any) {
			self.wpRef = wp
		},
		pushItemToPassedAdjustment(item: any) {
			self.passedAdjustments.push(item)
		},
		spliceItemFromPassedAdjustment(ajeId: string) {
			const index = self.passedAdjustments.findIndex(
				(item: any) => item.ajeId === ajeId
			)
			self.passedAdjustments.splice(index, 1)
		},
	}))
	.actions(GetPassedAdjustments)
	.actions(GetTrialBalanceDetails)
	.actions(GetWPReferences)
	.actions(AddPassedAdjustments)
	.actions(RemovePassedAdjustments)
	.actions(GetPassedAdjustmentUrl)
	.views((self) => ({
		viewAdjustments() {
			return self.passedAdjustments
		},
		viewPassedAdjustmentDetails(ajeId: string) {
			return self.passedAdjustments.find(
				(item: any) => item.ajeId === ajeId
			)
		},
		viewTBDetails() {
			let tbDetails: any[] = []
			self.tbDetails.map((item) => {
				tbDetails.push({
					name: item.tbName,
					value: item.tbId,
				})
			})
			return tbDetails
		},
		viewWPRef() {
			let wpRef: any[] = []
			self.wpRef.map((item) => {
				wpRef.push({
					name: item.wpRef,
					value: item.wpRefId,
				})
			})
			return wpRef
		},
	}))
	// common parts
	.actions((self) => ({
		initializeStore() {
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default AdjustmentsViewModel
