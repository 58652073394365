import { types } from "mobx-state-tree"

const LocalDictWord = types.model({
	key: types.string,
	value: "",
})

const LocaleDict = types.model({
	language: types.identifier,
	values: types.array(LocalDictWord),
})

// module == menu
export const ModuleDictionary = types.model({
	/**
	 * * ----- Expected result
	 * ModuleId: {
	 * 		enUS: {
	 * 			twLogin: "Login",
	 * 			twLogout: "Logout"
	 * 		},
	 * 		koKR: {
	 * 			twLogin: "로그인",
	 * 			twLogout: "로그아웃"
	 * 		}
	 * }
	 * * Actually...
	 * {
	 *     key: moduleId,
	 *     translations: {
	 *         {
	 * 			    lang: enUS,
	 * 				values: [
	 * 					{ key: twLogin, value: "Login"}
	 *              ]
	 *         }
	 *     }
	 * }
	 */
	moduleId: types.identifier,
	translations: types.map(LocaleDict),
})
