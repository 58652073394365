import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { postRequest } from "../../../../library/api-requests"

export function resetPassword(payload: { AccessCode: string; Newpwd: string }) {
	const response = postRequest({
		url: "/Resetpassword",
		params: payload,
		tokenType: TOKEN_TYPE.resetPasswordToken,
	})

	return response
}
