import React from "react"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import { StackedHorizontalBarChart as Bar } from "../../../../../../components/basic-elements/chart/StackedHorizontalBarChart"
import BarWithRatio from "../../../../../../components/basic-elements/chart/StackedBarChartWithRatio"
import { ProjectStatusColor } from "../../../../../../common-models/enumerations/project-related-enums"
import { getProgressRateDetails2 } from "../../../table-view/store/view-model-views/dashboard-card-views"
import ProgressRateDetailTable from "./ProgressRateDetailTable"

type DetailBarChartsProps = {
	label: string
	total: number
	late: number
	timely: number
	cannotCalculate: number
	detail?: DetailBarChartsProps[]
	projects?: any // NOTE:update is required
}

export const ProgressRateDetailBarCharts = observer(
	({
		partialStore,
		totalCount,
		data,
		widthStandard,
		mainHeight,
		subHeight,
		showTable,
	}: {
		partialStore: any
		totalCount: number
		data: any
		widthStandard: number
		mainHeight: number
		subHeight: number
		showTable: boolean
	}) => {
		const setTargetProjects = (projects: any, dialogTitle: string) => {
			const deepCopy = JSON.parse(JSON.stringify(projects))
			partialStore.setHBarDialogTitle(dialogTitle)
			partialStore.setHBarTableList(deepCopy)
			partialStore.setHBarTableDetailsDialogOpen(true)
		}

		const dataAsObject = getProgressRateDetails2(data)
		const progressData = [dataAsObject[0], dataAsObject[1], dataAsObject[2]]

		partialStore.setDetailViewTableTitle("Progress Rate Dashboard")
		partialStore.setDetailViewProgressTableData(
			progressData.map((item: any) => {
				return { ...item, status: item.label }
				// to add 'status' column instead of the label
			})
		)

		const unit = widthStandard / totalCount

		const archived = progressData[0]
		const normal = progressData[1]
		const unarchived = progressData[2]

		return showTable ? (
			<ProgressRateDetailTable progressData={progressData} />
		) : (
			<StyledBarCharts>
				<div
					className="FR AC overall-summary"
					data-testid="total-section"
				>
					<div className="label">Total</div>
					<div className="bar-chart-row FR JSB overall-bar">
						<Bar
							totalWidth={widthStandard}
							unit={unit}
							height={mainHeight}
							showTooltip
							showCountInBar
							data={[
								{
									value: archived.total,
									color: ProjectStatusColor.timely,
									label: "Archived",
									testId: "archived",
									clickHandler: () =>
										setTargetProjects(
											archived.projects,
											"Archived"
										),
								},
								{
									value: normal.total,
									color: ProjectStatusColor.normal,
									label: "Normal",
									testId: "normal",
									clickHandler: () =>
										setTargetProjects(
											normal.projects,
											"Normal"
										),
								},
								{
									value: unarchived.total,
									color: ProjectStatusColor.unarchived,
									label: "Unarchived",
									testId: "unarchived",
									clickHandler: () =>
										setTargetProjects(
											unarchived.projects,
											"Unarchived"
										),
								},
							]}
						/>
						<div
							className="overall-total total-count"
							data-testid="overall-total"
						>
							Total {totalCount}
						</div>
					</div>
				</div>
				<hr />
				{/* ----- Archived ----- */}
				<div
					className="grouped-bar-chart"
					data-testid="archived-section"
				>
					<BarWithRatio
						label="Archived"
						totalBarWidth={widthStandard}
						unit={unit}
						height={mainHeight}
						data={[
							{
								value: archived.total,
								color: ProjectStatusColor.timely,
								label: "Archived Total",
								testId: "archived-total",
								clickHandler: () =>
									setTargetProjects(
										archived.projects,
										"Archived Total"
									),
							},
						]}
						targetCount={archived.total}
						totalCount={totalCount}
						testId="archived-total"
					/>

					<br />
					<div>
						<BarWithRatio
							label="Late"
							totalBarWidth={widthStandard}
							unit={unit}
							height={subHeight}
							data={[
								{
									value: archived.late,
									color: ProjectStatusColor.late,
									label: "Late",
									testId: "archived-late",
									clickHandler: () =>
										setTargetProjects(
											archived.projects.filter(
												(item: any) => item.isLate
											),
											"Archived Late"
										),
								},
							]}
							targetCount={archived.late}
							totalCount={archived.total}
							testId="archived-late"
							subValue
							eleClassName="sub-bar-chart"
						/>
						<BarWithRatio
							label="Timely"
							totalBarWidth={widthStandard}
							unit={unit}
							height={subHeight}
							data={[
								{
									value: archived.timely,
									color: ProjectStatusColor.timely,
									label: "Timely",
									testId: "archived-timely",
									clickHandler: () =>
										setTargetProjects(
											archived.projects.filter(
												(item: any) =>
													item.isLate === false
											),
											"Archived Timely"
										),
								},
							]}
							targetCount={archived.timely}
							totalCount={archived.total}
							testId="archived-timely"
							subValue
							eleClassName="sub-bar-chart"
						/>
						<BarWithRatio
							label="?"
							totalBarWidth={widthStandard}
							unit={unit}
							height={subHeight}
							data={[
								{
									value: archived.cannotCalculate,
									color: ProjectStatusColor.undefined,
									label: "Cannot calculate",
									testId: "archived-cannotCalc",
									clickHandler: () =>
										setTargetProjects(
											archived.projects.filter(
												(item: any) =>
													item.isLate === undefined
											),
											"Cannot Calculate (Final Report Date is required)"
										),
								},
							]}
							targetCount={archived.cannotCalculate}
							totalCount={archived.total}
							testId="archived-cannotCalc"
							subValue
							eleClassName="sub-bar-chart"
						/>
					</div>
				</div>
				<hr />
				{/* ----- Normal ----- */}
				<div className="grouped-bar-chart" data-testid="normal-section">
					<BarWithRatio
						label="Normal"
						totalBarWidth={widthStandard}
						unit={unit}
						height={mainHeight}
						data={[
							{
								value: normal.total,
								color: ProjectStatusColor.normal,
								label: "Normal Total",
								testId: "normal-total",
								clickHandler: () =>
									setTargetProjects(
										normal.projects,
										"Normal Total"
									),
							},
						]}
						targetCount={normal.total}
						totalCount={totalCount}
						testId="normal-total"
					/>

					<br />
					<div>
						<BarWithRatio
							label="Late"
							totalBarWidth={widthStandard}
							unit={unit}
							height={subHeight}
							data={[
								{
									value: normal.late,
									color: ProjectStatusColor.late,
									label: "Normal late",
									testId: "normal-late",
									clickHandler: () =>
										setTargetProjects(
											normal.projects.filter(
												(item: any) => item.isLate
											),
											"Normal Late"
										),
								},
							]}
							targetCount={normal.late}
							totalCount={normal.total}
							testId="normal-late"
							subValue
							eleClassName="sub-bar-chart"
						/>
						<BarWithRatio
							label="Normal"
							totalBarWidth={widthStandard}
							unit={unit}
							height={subHeight}
							data={[
								{
									value: normal.timely,
									color: ProjectStatusColor.normal,
									label: "Normal",
									testId: "normal-timely",
									clickHandler: () =>
										setTargetProjects(
											normal.projects.filter(
												(item: any) =>
													item.isLate === false
											),
											"Normal Timely"
										),
								},
							]}
							targetCount={normal.timely}
							totalCount={normal.total}
							testId="normal-timely"
							subValue
							eleClassName="sub-bar-chart"
						/>

						<BarWithRatio
							label="?"
							totalBarWidth={widthStandard}
							unit={unit}
							height={subHeight}
							data={[
								{
									value: normal.cannotCalculate,
									color: ProjectStatusColor.undefined,
									label: "Cannot calculate",
									testId: "normal-cannotCalc",
									clickHandler: () =>
										setTargetProjects(
											normal.projects.filter(
												(item: any) =>
													item.isLate === undefined
											),
											"Normal - Cannot Calculate (Final Report Date is required)"
										),
								},
							]}
							targetCount={normal.cannotCalculate}
							totalCount={normal.total}
							testId="normal-cannotCalc"
							subValue
							eleClassName="sub-bar-chart"
						/>
					</div>
					<br />
					<div
						className=""
						style={{ opacity: 0.6, marginBottom: "0.4rem" }}
					>
						- Reviewed status
					</div>
					{normal.detail && (
						<div className="detail-area">
							{normal.detail.map(
								(x: DetailBarChartsProps, i: number) => {
									i = i + 1 // This is for test ID. Do not remove and update
									return (
										<BarWithRatio
											label={x.label}
											totalBarWidth={widthStandard}
											unit={unit}
											height={subHeight}
											data={[
												{
													value: x.late,
													color: ProjectStatusColor.late,
													label: "late",
													testId:
														"normal-late-section-" +
														i,
													clickHandler: () =>
														setTargetProjects(
															x.projects.filter(
																(item: any) =>
																	item.isLate
															),
															"Normal - " +
																x.label +
																" - Late"
														),
												},
												{
													value: x.timely,
													color: ProjectStatusColor.normal,
													label: "normal",
													testId:
														"normal-timely-section-" +
														i,
													clickHandler: () =>
														setTargetProjects(
															x.projects.filter(
																(item: any) =>
																	item.isLate ===
																	false
															),
															"Normal - " +
																x.label +
																" - Timely"
														),
												},
												{
													value: x.cannotCalculate,
													color: ProjectStatusColor.undefined,
													label: "final report date is required",
													testId:
														"normal-cannotCalc-section-" +
														i,
													clickHandler: () =>
														setTargetProjects(
															x.projects.filter(
																(item: any) =>
																	item.isLate ===
																	undefined
															),
															"Normal - " +
																x.label
														),
												},
											]}
											targetCount={x.total}
											totalCount={normal.total}
											testId={`section-${i}`}
											subValue
											eleClassName="sub-bar-chart"
										/>
									)
								}
							)}
						</div>
					)}
				</div>
				<hr />
				{/* ----- Unarchived ----- */}
				<div
					className="grouped-bar-chart"
					data-testid="unarchived-section"
				>
					<BarWithRatio
						label="Unarchived"
						totalBarWidth={widthStandard}
						unit={unit}
						height={mainHeight}
						data={[
							{
								value: unarchived.total,
								color: ProjectStatusColor.unarchived,
								label: "Unarchived Total",
								testId: "unarchived-total",
								clickHandler: () =>
									setTargetProjects(
										unarchived.projects,
										"Unarchived"
									),
							},
						]}
						targetCount={unarchived.total}
						totalCount={totalCount}
						testId="unarchived-total"
					/>
				</div>
			</StyledBarCharts>
		)
	}
)

const StyledBarCharts = styled.div`
	.overall-summary {
		margin-bottom: 3rem;
		.label {
			font-weight: bold;
		}
	}
	.grouped-bar-chart {
		margin-bottom: 3rem;
	}
	.label {
		width: 100px;
		/* white-space: nowrap; */
	}
	.total-count {
		margin-left: 0.6rem;
		white-space: nowrap;
	}
	.sub-bar-chart {
		margin-bottom: 0.3rem;
	}
`
