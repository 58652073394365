import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../../../library/converters/id-converter"
import { ProjTemplateDialog } from "../../org-proj-templates.ui-helper"
import { OrgProjTemplateItemType } from "../../data-models/org-proj-templates.data-model"
import { hasEmptyProps } from "@datalobby/common"
import { ConsoleLog } from "../../../../../../../components/basic-elements"

/**
 *
 * @param self
 * @returns
 *
 * * i18n records
 * - loading, success, fail
 *
 */
const AddProjTemplate = (self: any) => ({
	addProjTemplate(props: {
		userId: string
		templateGroupId: string
		templateTitle: string
	}) {
		// 0.
		const actionName = "addProjTemplate"
		// 1.
		const checkedProps = hasEmptyProps(props)
		if (checkedProps.hasEmptyProps) {
			alert("Empty field exist")
			return
		}
		// 2.
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 200,
			color: MessageColorType.green,
			open: true,
		})
		// 3.
		const { userId, templateGroupId, templateTitle } = checkedProps.props

		const userNumId = idToNumber(userId.toString(), IdType.user)
		const groupNumId = idToNumber(
			templateGroupId.toString(),
			IdType.projTemplateGroup
		)

		const payload = {
			userId: userNumId,
			templateGroupId: groupNumId,
			templateTitle,
		}
		self.createProjTemplate(payload)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// ConsoleLog([actionName, "__response ", response])
					/**
					 * REFLECT RESPONSE ON THE STORE
					 */
					const returnedId = response.data.TemplateId
					self.pushProjTemplate({
						id: idToString(returnedId, IdType.projTemplate),
						parentId: templateGroupId,
						title: templateTitle,
						expanded: false,
						type: OrgProjTemplateItemType.template,
					})
					self.setDialogOpen(ProjTemplateDialog.createTemplate, false)
					self.toggleExpandedTemplateGroup(templateGroupId, true)
					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						open: true,
						autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default AddProjTemplate
