import { ActionStatus } from "../../../../../../common-models/enumerations/common-enums"

const ConnectChecklistTemplate = (self: any) => ({
	connectChecklistTemplate(params: {
        orgId: string,
        projectTypeId: string, 
        projectTypeName: string, 
        createdBy: string, 
        templates: { id: string, name: string} [],
    }) {
		// 0.
		const actionName = "connectChecklistTemplate"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})

		self.mConnectChecklistTemplate(params)
			.then((response: any) => {
				console.log(actionName, "response:", response)
				if (response.status === 200) {
					
				} else {
					//
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default ConnectChecklistTemplate
