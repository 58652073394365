import React, { useState, useEffect, useCallback } from "react"
import {
	DLButton,
	DLInputField,
	DLChip,
	DLSpinnerByIcon,
} from "../../../../basic-elements"
import Icon from "@mdi/react"
import {
	mdiFilePlusOutline,
	mdiCheckBold,
	mdiArrowRight,
	mdiCheckCircle,
	mdiAlertCircle,
} from "@mdi/js"
import { formatFileSize } from "@datalobby/common"
import { v4 as uuidv4 } from "uuid"
import { LocalFileProps } from "../../../../../service-modules/file-module/data-models/file.data-props"
import UploadFileFormTableColumns from "./upload-file-form-table-columns"

import { DLOrgSubMenus } from "../../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"

import { observer } from "mobx-react-lite"
import { ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import StyledUploadFilesForm from "./StyledUploadFilesForm"
import {
	allowedFormats,
	FileScreenDialog,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import { FolderSelectField } from "."
import sharedRegEx from "../../../../../library/sharedRegEx"
import { DLProjSubMenus } from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { getProjIdFromUrl } from "../../../../../library/api-requests/request-get-others"
import ReactTableV8 from "../../../../basic-elements/tables/DLReactTable"

const UploadFilesForm = observer(
	({
		partialStore,
		localFiles,
		parentInfo,
		i18n,
		userRole,
	}: {
		partialStore: any
		localFiles: LocalFileProps[]
		parentInfo: any
		i18n: DLI18nProps
		userRole: string
	}) => {
		const [files, setFiles] = useState<LocalFileProps[]>([])
		const [totalSize, setTotalSize] = useState("")
		const [separator, setSeparator] = useState("_")
		const [hasTotalSizeErr, setHasTotalSizeErr] = useState(false)
		const actionName = "uploadMultipleFiles"
		const menuId = partialStore.storeName

		const [highlight, setHighlight] = useState(false)

		useEffect(() => {
			partialStore.responses.setResponse(actionName, {
				actionName,
				status: ActionStatus.standby,
			})
			if (localFiles.length > 0) {
				setFiles(localFiles)
			}
		}, [])

		const handleSelectFilesFromLocal = (event: any) => {
			const choosedFiles = event.target.files

			for (let i = 0, numFiles = choosedFiles.length; i < numFiles; i++) {
				const file = choosedFiles[i]
				const { name, type, size, lastModified } = file
				const fileName = name.slice(0, name.lastIndexOf("."))
				const fileExtension = name.slice(name.lastIndexOf("."))
				const formattedSize = formatFileSize(size, 2)
				setFiles((files: any) => [
					...files,
					{
						tempId: uuidv4(),
						aliasId: "",
						selected: false,
						name: fileName,
						extension: fileExtension,
						size,
						formattedSize,
						type,
						lastModified,
						file,
						prepared: false,
						reviewed: false,
					},
				])
			}
		}

		const handleToggleSelectAll = () => {
			const selectedFiles = files.filter((file) => file.selected)

			if (selectedFiles.length >= 1) {
				let newList = [...files]
				newList.map((item) => (item.selected = false))
				setFiles(newList)
			} else {
				let newList = [...files]
				newList.map((item) => (item.selected = true))
				setFiles(newList)
			}
		}

		useEffect(() => {
			let total = 0
			files.map((file) => {
				total = total + file.size
			})
			const fmtSize = formatFileSize(total).split(" ")
			setTotalSize(formatFileSize(total))
			setHasTotalSizeErr(
				fmtSize[1] === "MB"
					? Math.ceil(+fmtSize[0]) > 100
						? true
						: false
					: false
			)
		}, [files.length])

		const handleRemoveFile = useCallback((id: any) => {
			setFiles((files) => files.filter((file) => file.tempId !== id))
		}, [])

		const handleFiles = useCallback((id: any, event: any) => {
			const name = event.target.name
			let value = event.target.value

			if (value === "true") {
				value = true
			} else if (value === "false") {
				value = false
			}

			setFiles((files) =>
				files.map((file) =>
					file.tempId === id ? { ...file, [name]: value } : file
				)
			)
		}, [])

		function renderEditableRefNum(props: any) {
			const targetFile = files.find(
				(file) => file.tempId === props.row.original.tempId
			)

			const aliasIdInput = props.row.original.aliasId
			const checkResult = checkInput("aliasId", aliasIdInput, files)
			const clsName = "alias-id-input " + checkResult
			const testId = "upload-file-alias-id-input"
			const tempId = props.row.original.tempId
			const columnId = props.cell.column.id

			return renderField(
				testId,
				clsName,
				tempId,
				columnId,
				targetFile,
				checkResult
			)
		}

		function renderEditableFileName(props: any) {
			const targetFile = files.find(
				(file) => file.tempId === props.row.original.tempId
			)

			const nameInput = props.row.original.name
			const checkResult = checkInput("name", nameInput, files)
			const clsName = "file-name-input " + checkResult
			const testId = "upload-file-name-input"
			const tempId = props.row.original.tempId
			const columnId = props.cell.column.id

			return renderField(
				testId,
				clsName,
				tempId,
				columnId,
				targetFile,
				checkResult
			)
		}

		function renderField(
			testId: string,
			clsName: string,
			tempId: string,
			columnId: string,
			targetFile: any,
			checkResult: string
		) {
			console.log(checkResult)
			let helpMsg = ""
			if (checkResult === "invalid") {
				helpMsg =
					i18n.warningSpecialChar2 ||
					'* | \\ / " ` : < > { } ? _ are not allowed.'
			}
			if (
				checkResult === "duplicated" &&
				testId === "upload-file-alias-id-input"
			) {
				helpMsg = i18n.twDuplicatedAliasId || "Duplicated Ref.Num"
			}
			if (
				checkResult === "duplicated" &&
				testId === "upload-file-name-input"
			) {
				helpMsg = i18n.twDuplicatedFileName || "Duplicated File Name"
			}
			if (checkResult === "exceed") {
				helpMsg = "Exceeded the limited number of characters."
			}

			const width = testId === "upload-file-name-input" ? "150px" : "100%"
			return (
				<div style={{ width }} className="input-container FC JC AL">
					<div
						data-testid={testId}
						className={clsName}
						contentEditable
						suppressContentEditableWarning
						onBlur={(e: any) => {
							e.persist()
							setFiles((files) =>
								files.map((file) =>
									file.tempId === tempId && e.target
										? {
												...file,
												[columnId]: e.target.innerHTML
													.replace(/&nbsp;/g, " ")
													.trim(),
										  }
										: file
								)
							)
						}}
						dangerouslySetInnerHTML={{
							__html:
								targetFile && targetFile[columnId]
									? targetFile[columnId]
									: "",
						}}
					/>
					<span className="warning-msg">
						{checkResult !== "empty" &&
							checkResult !== "ready" &&
							helpMsg}
					</span>
				</div>
			)
		}

		const handleToggleCheckbox = useCallback((id: any) => {
			setFiles((files) =>
				files.map((file) =>
					file.tempId === id
						? { ...file, selected: !file.selected }
						: file
				)
			)
		}, [])

		const handlePrepare = useCallback((id: any) => {
			setFiles((files) =>
				files.map((file) =>
					file.tempId === id
						? { ...file, prepared: !file.prepared }
						: file
				)
			)
		}, [])

		const handleReview = useCallback((id: any) => {
			setFiles((files) =>
				files.map((file) =>
					file.tempId === id
						? { ...file, reviewed: !file.reviewed }
						: file
				)
			)
		}, [])

		const handleSeparator = useCallback((event: any) => {
			setSeparator(event.target.value)
		}, [])

		const handleApplySeparator = useCallback(() => {
			if (separator) {
				setFiles((files) =>
					files.map((file) =>
						file.selected && file.name.includes(separator)
							? {
									...file,
									aliasId: file.name.slice(
										0,
										file.name.indexOf(separator)
									),
									name: file.name.slice(
										file.name.indexOf(separator) + 1,
										file.name.length
									),
							  }
							: file
					)
				)
			}
		}, [separator])

		const exTarget = files.filter((file) => file.selected)[0]?.name
		let exRefNum = ""
		let exFileName = exTarget
		if (exTarget && separator && exTarget.includes(separator)) {
			exRefNum = exTarget.slice(0, exTarget.indexOf(separator))
			exFileName = exTarget.slice(
				exTarget.indexOf(separator) + 1,
				exTarget.length
			)
		}
		const checked = files.filter((file) => file.selected)
		const parentId = parentInfo.id

		const handleUploadToServer = () => {
			let projId = getProjIdFromUrl()
			if (partialStore.storeName === DLOrgSubMenus.proj_templates) {
				// Org side
				projId = partialStore.selectedTemplate.id
			}
			partialStore.uploadMultipleFiles({ files, parentId, projId })
		}

		const oversizeExist = (files: any) => {
			let oversized = 0
			files.map((file: any) => {
				if (file.extension === ".zip") {
					if (file.size > 100000000) {
						oversized = oversized + 1
					}
				} else {
					if (file.size > 30000000) {
						oversized = oversized + 1
					}
				}
			})
			if (oversized > 0) {
				return true
			} else {
				return false
			}
		}

		const handleRetry = () => {
			// Remove success file from the list
			let failedFiles: any = []
			files.map((file: any) => {
				if (
					partialStore.getActionStatus(
						"sequentialFileUpload" + file.tempId
					) === ActionStatus.fail
				) {
					failedFiles.push(file)
				}
			})
			setFiles(failedFiles)
			// change the status to show the file list again
			partialStore.responses.setResponse("uploadMultipleFiles", {
				actionName: "uploadMultipleFiles",
				status: ActionStatus.standby,
			})
		}

		const uploadIsInProgress = (files: any) => {
			let loadingCase = 0
			files.map((file: any) => {
				if (
					partialStore.getActionStatus(
						"sequentialFileUpload" + file.tempId
					) === ActionStatus.loading
				) {
					loadingCase = loadingCase + 1
				}
			})
			if (loadingCase >= 1) {
				return true
			} else {
				return false
			}
		}

		const successAll = (files: any) => {
			let successCase = 0
			files.map((file: any) => {
				if (
					partialStore.getActionStatus(
						"sequentialFileUpload" + file.tempId
					) === ActionStatus.success
				) {
					successCase = successCase + 1
				}
			})
			if (successCase === files.length) {
				return true
			} else {
				return false
			}
		}

		const toggleAllPrepare = (on: boolean) =>
			setFiles((files) =>
				files.map((file) =>
					on
						? {
								...file,
								prepared: true,
						  }
						: {
								...file,
								prepared: false,
						  }
				)
			)
		const toggleAllReview = (on: boolean) =>
			setFiles((files) =>
				files.map((file) =>
					on
						? {
								...file,
								reviewed: true,
						  }
						: {
								...file,
								reviewed: false,
						  }
				)
			)

		const checkSignOff = (signOffType: "prepare" | "review") => {
			const signOffExist =
				files.filter((file: LocalFileProps) =>
					signOffType === "prepare" ? file.prepared : file.reviewed
				).length > 0
			return signOffExist
		}

		const checkInput = (
			target: "aliasId" | "name",
			input: string,
			files: any
		) => {
			let isDuplicatedInStore = false
			let isEmpty = false
			let isDuplicatedInDialog = false
			let isInvalidFormat =
				target === "name"
					? !sharedRegEx.noSpecialChar2.test(input)
					: !sharedRegEx.noSpecialChar3.test(input)
			let isExceed = false

			if (
				input === "" ||
				input === undefined ||
				input.replace(/ /g, "").length === 0 ||
				input.replace(/&nbsp;/g, "").replace(/ /g, "").length === 0
			) {
				isEmpty = true
			} else {
				if (target === "aliasId") {
					isDuplicatedInStore =
						partialStore.isDuplicatedAliasId(input)
					const list = files.filter(
						(file: any) => file.aliasId.trim() === input.trim()
					)
					if (list.length > 1) {
						isDuplicatedInDialog = true
					}
				} else {
					isDuplicatedInStore = partialStore.isDuplicatedName(input)
					const list = files.filter(
						(file: any) => file.name.trim() === input.trim()
					)
					if (list.length > 1) {
						isDuplicatedInDialog = true
					}
				}
			}
			const len = target === "aliasId" ? 50 : 100
			if (input.length > len) {
				isExceed = true
			}

			let result:
				| "empty"
				| "duplicated"
				| "invalid"
				| "exceed"
				| "ready" = "ready"
			if (isEmpty) {
				result = "empty"
			} else if (isDuplicatedInStore || isDuplicatedInDialog) {
				result = "duplicated"
			} else if (isInvalidFormat) {
				result = "invalid"
			} else if (isExceed) {
				result = "exceed"
			}

			return result
		}

		const signOffPolicy =
			menuId === DLProjSubMenus.wp
				? DefaultSignOffPolicy
				: menuId === DLProjSubMenus.pf
				? PfSignOffPolicy
				: null
		let showPrepareCheckbox = false
		let showReviewCheckbox = false

		if (signOffPolicy !== null) {
			if (signOffPolicy[0].availableRoles.includes(userRole)) {
				showPrepareCheckbox = true
			}
			if (signOffPolicy[1].availableRoles.includes(userRole)) {
				showReviewCheckbox = true
			}
		}

		const getActionStatus = (actionName: string) =>
			partialStore.getActionStatus(actionName)

		let checkAll: string[] = []
		files.map((file: any) => {
			checkAll.push(checkInput("aliasId", file.aliasId, files))
			checkAll.push(checkInput("name", file.name, files))
		})

		const deactivateAction =
			checkAll.findIndex((item: string) => item !== "ready") !== -1
		let msg = ""
		if (checkAll.findIndex((item: string) => item === "empty") !== -1) {
			msg = "Empty input exist"
		}
		if (
			checkAll.findIndex((item: string) => item === "duplicated") !== -1
		) {
			msg = "Duplicated input exist"
		}
		return (
			<StyledUploadFilesForm>
				{getActionStatus("uploadMultipleFiles") ===
				ActionStatus.loading ? (
					<div className="result-container">
						{files.map((file: LocalFileProps, index: number) => {
							const actionName =
								"sequentialFileUpload" + file.tempId
							return (
								<div
									key={index}
									className="result-item FR AC JSB"
									data-testid={"result-row-" + file.aliasId}
								>
									<div className="file-info">
										(
										<span data-testid="result-row-aliasId">
											{file.aliasId}
										</span>
										){" "}
										<span data-testid="result-row-fileName">
											{file.name}
										</span>{" "}
										<span data-testid="result-row-extension">
											{file.extension}
										</span>
									</div>
									<div className="response-info FR AC">
										{getActionStatus(actionName) ===
										ActionStatus.loading ? (
											<DLSpinnerByIcon />
										) : getActionStatus(actionName) ===
										  ActionStatus.success ? (
											<div className="success-icon FR AC">
												<Icon
													path={mdiCheckCircle}
													size={0.8}
												/>
											</div>
										) : getActionStatus(actionName) ===
										  ActionStatus.fail ? (
											<div className="failure-icon FR AC">
												<Icon
													path={mdiAlertCircle}
													size={0.8}
												/>
											</div>
										) : (
											""
										)}
										<span className="response-message">
											{partialStore.getMessage(
												actionName
											)}
										</span>
									</div>
								</div>
							)
						})}
						<div className="FR AC JSB second-action-btn-area">
							<DLButton
								eleTestId="retry-upload-btn"
								clickHandler={handleRetry}
								eleClassName="retry-btn"
								disabled={
									successAll(files) ||
									uploadIsInProgress(files)
								}
							>
								Retry
							</DLButton>
							<DLButton
								eleTestId="close-dialog-btn"
								clickHandler={() => {
									partialStore.setFileTreeDialogOpen(
										FileScreenDialog.createFile,
										false
									)
								}}
								eleClassName="close-btn"
								disabled={uploadIsInProgress(files)}
							>
								Close
							</DLButton>
						</div>
					</div>
				) : (
					<div className="before-start-action FC JSB">
						<FolderSelectField
							i18n={i18n}
							partialStore={partialStore}
							folderTitle={parentInfo?.title}
							highlight={highlight}
						/>
						<div className="file-list">
							<p>
								{i18n.twTotalLocalFiles || "Total Local Files"}
								<span
									className="local-files-count"
									data-testid="total-local-files"
								>
									{" "}
									({files.length})
								</span>
							</p>
							<ReactTableV8
								tableColumns={UploadFileFormTableColumns({
									checkedItemExist: checked.length > 0,
									checkSignOff,
									toggleAllPrepare,
									toggleAllReview,
									handleFiles,
									renderEditableRefNum,
									renderEditableFileName,
									handleRemoveFile,
									handleToggleCheckbox,
									handlePrepare,
									handleReview,
									handleToggleSelectAll,
									showPrepareCheckbox,
									showReviewCheckbox,
								})}
								data={files}
								hasPagination={false}
								showPageSetting={false}
								height={500}
								menuId="UploadFilesForm"
								getRowClass={(row: any) => {
									if (row.original.size > 100000000) {
										return "oversize"
									}
									return ""
								}}
							/>
						</div>
						{/* separator setting  */}
						{files.length > 0 && (
							<div
								className={`separator-area ${
									checked.length <= 0 && "disabled"
								}`}
							>
								<div className="FR AC JSB">
									<div className="FR AC">
										<span>Apply separator</span>{" "}
										<DLInputField
											eleTestId="separator-char"
											variant="filled"
											eleValue={separator}
											eleHandleChange={handleSeparator}
											eleClassName="separator-input"
										/>
										<span>
											to{" "}
											<span data-testid="separator-target-count">
												{checked.length}
											</span>{" "}
											files
										</span>
									</div>
									<DLButton
										eleTestId="separator-apply-btn"
										clickHandler={handleApplySeparator}
										disabled={checked.length <= 0}
										tooltipText={
											checked.length <= 0
												? "Please select files"
												: ""
										}
									>
										Apply
									</DLButton>
								</div>
								<div className="example FR AC">
									{checked.length <= 0 ? (
										<div>
											* Please select files for separation
										</div>
									) : (
										<div className="FR AC">
											<span>ex) {exTarget} </span>
											{separator ? (
												<div className="FR AC">
													<Icon
														path={mdiArrowRight}
														size={0.6}
													/>{" "}
													<DLChip
														label="Ref. num"
														eleTestId="ex-ref-num"
													/>{" "}
													{exRefNum}{" "}
													<DLChip
														label="File name"
														eleTestId="ex-name"
													/>
													{exFileName}
												</div>
											) : (
												<div style={{ marginLeft: 8 }}>
													(please input the separator)
												</div>
											)}
										</div>
									)}
								</div>
							</div>
						)}

						{/* action buttons  */}
						<div className="FR JSB action-buttons">
							<DLButton
								eleTestId="choose-files-btn"
								startIcon={
									<Icon
										path={mdiFilePlusOutline}
										size={0.8}
									/>
								}
							>
								<label className="file-upload-btn-wrapper">
									Choose files
									<input
										type="file"
										name="file"
										onChange={handleSelectFilesFromLocal}
										multiple
										data-testid="file-input"
										accept={allowedFormats}
									/>
								</label>
							</DLButton>
							<div className="FR AC">
								<div className="total-files-and-size">
									<span
										className="file-count"
										data-testid="total-local-files-count-2"
									>
										{files.length}
									</span>{" "}
									files total size:{" "}
									<span
										className="total-size"
										data-testid="total-local-files-size"
									>
										{totalSize}
									</span>
								</div>

								<DLButton
									eleTestId="upload-btn"
									startIcon={
										<Icon path={mdiCheckBold} size={0.8} />
									}
									clickHandler={handleUploadToServer}
									color="primary"
									disabled={
										parentId === "" ||
										parentId === null ||
										deactivateAction ||
										// oversizeExist(files) ||
										files.length <= 0 ||
										hasTotalSizeErr
									}
									onMouseOver={() => setHighlight(true)}
									onMouseLeave={() => setHighlight(false)}
								>
									Upload
								</DLButton>
							</div>
						</div>
						{files.length > 0 && (
							<div className="help-text-area">
								<div
									className="help-text FR AC JR"
									data-testid="help-msg-1"
								>
									{msg}
								</div>
								{/*{oversizeExist(files) && (
									<div
										className="help-text FR AC JR"
										data-testid="help-msg-2"
									>
										* Individual file should not exceed 30MB
										and ZIP file should not exceed 100MB
									</div>
								)}*/}
								{hasTotalSizeErr && (
									<div
										className="help-text FR AC JR"
										data-testid="help-msg-3"
									>
										* Overall files size should not exceed
										100MB
									</div>
								)}
							</div>
						)}
					</div>
				)}
			</StyledUploadFilesForm>
		)
	}
)

export default UploadFilesForm
