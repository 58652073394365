import { types } from "mobx-state-tree"

export const AjeModel = types.model({
	ajeDesc: types.string,
	ajeType: types.string,
	ajeId: types.string,
	extension: types.string,
	isOpen: types.boolean,
	mediaId: types.string,
	mediaType: types.string,
	openedBy: types.union(types.string, types.null),
	ajeNumber: types.number,
	wpDesc: types.string,
	wpRef: types.string,
})

export const WPRef = types.model({
	wpRefId: types.string,
	wpRef: types.string,
	wpRefDesc: types.string,
})
