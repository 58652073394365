import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import { useParams, useHistory } from "react-router-dom"
import { GraphQLResult } from "@aws-amplify/api"
import { getPostById, GetPostQuery } from "../../../../../graphql/Post/queries"
// import {
// 	visitPost,
// 	VisitPostResponseType,
// } from "../../../../../graphql/Post/mutations"
import {
	callGraphQL,
	subscribeGraphQL,
	subscribeGraphQL2,
} from "@datalobby/common"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import {
	onDeletePost,
	OnDeletePostSubscription,
	onUpdatePost,
	OnUpdatePostSubscription,
	setTypeOnDeletePostSubs,
	onUpdatePostHandler,
} from "../../../../../graphql/Post/subscriptions"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import PostDetail from "../../../../../service-modules/post/components/PostDetail"
import * as FileSaver from "file-saver"
import axios, { Method } from "axios"
import { PostAttachmentType } from "@datalobby/types"
import { downloadPostAttachment } from "../../../../../service-modules/post/utils/download-post-attachment"

export default observer(function BoardDetail({
	permission,
	menuId,
}: {
	permission: PermissionAsObjectProps
	menuId: string
}) {
	const store = useRootStore()
	const projStore = useProjStore()

	const defaultMenuUrl =
		projStore.checkin.defaultUrl + "/note-and-comments/board"

	const userId = localStorage.getItem("orgUserId")
	let { postId } = useParams<{ postId: string }>()
	let history = useHistory()

	const [loading, setLoading] = useState(false)

	const projId = projStore.projInfo.projInfo.projectId

	useEffect(() => {
		// NOTE: Required initialization
		projStore.board.resetS3FilesStatus()
	}, [])

	function getPost(postByIdQuery: GraphQLResult<GetPostQuery>) {
		const result = postByIdQuery.data?.getPostById
		return result
	}

	// NOTE: onUpdate subscription --------------------------
	useEffect(() => {
		const subscription = subscribeGraphQL2<OnUpdatePostSubscription>(
			onUpdatePost,
			onUpdatePostHandler,
			projStore.board.updateProjBoardItem
		)
		return () => subscription.unsubscribe()
	})

	// NOTE: onDelete subscription --------------------------
	const onDeletePostHandler = (deleteSubs: OnDeletePostSubscription) => {
		const postId = setTypeOnDeletePostSubs(deleteSubs)
		projStore.board.sliceProjBoardItem(postId)
		alert("This post has been deleted.")
		history.replace(defaultMenuUrl)
	}
	useEffect(() => {
		const subscription = subscribeGraphQL<OnDeletePostSubscription>(
			onDeletePost,
			onDeletePostHandler
		)

		return () => subscription.unsubscribe()
	})

	// NOTE: other things --------------------------
	useEffect(() => {
		async function getData() {
			setLoading(true)
			try {
				const options = {
					menuId,
					postId,
				}
				const postData = await callGraphQL<GetPostQuery>(
					getPostById,
					options
				)
				const post = getPost(postData)
				if (post === null) {
					// when the post ID doesn't exist anymore (when someone deleted it)
					history.replace(defaultMenuUrl)
				}
				projStore.board.pushProjBoardItem(post)
				setLoading(false)
			} catch (error) {
				setLoading(false)
				console.error("Error fetching post", error)
			}
		}
		const postInStore = projStore.board.getProjBoardById(postId)
		if (postInStore) {
		} else {
			getData()
		}
	}, [])

	// async function runVisitPost(options: { postId: string; userId?: string }) {
	// 	await callGraphQL<VisitPostResponseType>(visitPost, options)
	// }

	const post_static = projStore.board.getProjBoardById(postId)
	// useEffect(() => {
	// 	if (post_static && userId) {
	// 		if (!post_static.visitors.includes(userId)) {
	// 			const options = {
	// 				postId: postId,
	// 				userId: userId,
	// 			}
	// 			// Do not update store visit data. It will be updated with subscription
	// 			runVisitPost(options)
	// 		} else {
	// 			const options = {
	// 				postId: postId,
	// 			}
	// 			// Do not update store visit data. It will be updated with subscription
	// 			runVisitPost(options)
	// 		}
	// 	}
	// }, [])

	// 	useEffect(() => {
	// 		const userNeedRefresh = orgStore.setupUsers.needRefresh
	// 		const userListAccess = orgStore.checkin.checkAccess(
	// 			DLOrgSubMenus.setup_users
	// 		)
	// 		if (userNeedRefresh && userListAccess) {
	// 			orgStore.setupUsers.getUserList()
	// 		}
	// 	}, [])

	const handleDelete = () => {
		let proceed = window.confirm("Do you really want to delete this post?")
		if (!proceed) {
			return
		} else {
			projStore.board.removeOrRestorePost({
				postId,
				menuId,
				toDelete: true,
				by: userId || "",
				postAction: history.replace(defaultMenuUrl),
			})
		}
	}

	const contentsAreaWidth = store.ui.contentsAreaWidth
	const contentsAreaHeight = store.ui.contentsAreaHeight
	const dntFormat = store.global.getDntFormat
	// 		const orgUserList = orgStore.setupUsers.userListForRender
	const post = projStore.board.viewProjBoardItemById(postId, dntFormat, [])
	const existingFiles = post?.attachments.map((item: PostAttachmentType) => {
		// const fileInfo = JSON.parse(item)
		console.log("fileInfo:", item)
		return item
	})

	return (
		<PostDetail
			permission={permission}
			postId={postId}
			post={post}
			parentId={projId}
			handleDelete={handleDelete}
			contentsAreaWidth={contentsAreaWidth}
			contentsAreaHeight={contentsAreaHeight}
			loading={loading}
			existingFiles={existingFiles}
			downloadHandler={downloadPostAttachment}
			mainUrl={defaultMenuUrl}
		/>
	)
})
