import { flow } from "mobx-state-tree"
import { ConsoleLog } from "../../../../../components/basic-elements"
import { MyAccountProps, ChangePasswordProps } from "../my-account.apis"

const UpdatePassword = (
	self: any,
	changePassword: MyAccountProps["apiChangePassword"]
) => ({
	updatePassword: flow(function* (payload: ChangePasswordProps) {
		const actionName = "updatePassword"
		try {
			/**
			 * This API requires
			 * EmailID: "cskim@datal..."
			 * UserID: 16
			 * Password: "Kb0D8e41a..."
			 * NewPwd: "dSI9MqDAiX..."
			 * AWSAccessToken: "eyJraWQi..."
			 */
			const response = yield changePassword(payload)

			return response
		} catch (error) {
			ConsoleLog(["updateMyAccount error", error])
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default UpdatePassword
