import { types } from "mobx-state-tree"
import CommentsModel from "./comments.model"
import CommentsViewModel from "./comments.view-model"
import CommentsUiHelper from "./comments.ui-helper"
import { initialClickPoint } from "../../../../../common-models/types/dialog.props"
import { DialogActionType } from "../../../../../common-models/enumerations/common-enums"

const CommentsStore = types
	.compose(CommentsModel, CommentsViewModel, CommentsUiHelper)
	.named("CommentsStore")

export const initialStore = {
	clickPoint: initialClickPoint,
	// ui-helper
	currentTargetObject: {
		belongsTo: "",
		objectId: "",
	},
	detailDialogMode: DialogActionType.read,
	//
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const CommentsStoreInstance = CommentsStore.create(initialStore)

export default CommentsStoreInstance
