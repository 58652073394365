import { types } from "mobx-state-tree"
import { ActionStatus } from "../../../../common-models/enumerations/common-enums"
import { OrgRoleType } from "../../../../common-models/enumerations/org-related-enums"

export const OrgUserListItem = types.model({
	id: types.string,
	type: types.string,
	name: types.string,
	aliasId: types.string,
	email: types.string,
	title: types.union(types.string, types.null),
	status: types.string,
	accessRight: types.enumeration<OrgRoleType>(Object.values(OrgRoleType)),
	isArchiveAdmin: types.union(types.boolean, types.string), // temporary! Must update to boolean
	assignedGroups: types.array(types.string),
	createdAt: types.string,
	createdBy: types.string,
	lastAccess: types.union(types.string, types.null),
})

export const AssignedProject = types.model({
	periodType: types.union(types.string, types.null),
	type: types.string,
	clientName: types.string,
	title: types.string,
})

export const BulkUserImportProps = types.model({
	userId: types.string,
	type: types.string,
	email: types.string,
	name: types.string,
	aliasId: types.string,
	accessRight: types.string,
	title: types.string,
	// hasValidEmail: types.boolean,
	isDuplicate: types.boolean,
	// duplicatedAliasId: types.boolean,
	// duplicatedEmail: types.boolean,
	status: types.enumeration<ActionStatus>(Object.values(ActionStatus)),
	statusMessage: types.string,
})

export type BulkUserImportType = {
	userId: string
	type: string
	email: string
	name: string
	aliasId: string
	accessRight: string
	title: string
	isDuplicate: boolean
	status: ActionStatus
	statusMessage: string
}

export type OrgUserListType = {
	id: string
	type: string
	name: string
	aliasId: string
	email: string
	title: string | null
	status: string
	accessRight: OrgRoleType
	isArchiveAdmin: boolean | string // temporary! Must update to boolean
	assignedGroups: string[]
	createdAt: string
	createdBy: string
	lastAccess: string | null
}
