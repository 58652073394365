export const initialProjUsers = {
	extUsersCount: 0,
	activeExtUsersCount: 0,
	inActiveExtUsersCount: 0,
	unassignExtUsersCount: 0,
	internalUsersCount: 0,
	activeIntUsersCount: 0,
	inActiveIntUsersCount: 0,
	unassignIntUsersCount: 0,
	wpCount: 0,
	pfCount: 0,
	internalUsers: [],
	externalUsers: [],
}
