import React, { useEffect } from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import OrgNoPermissionOnMenu from "../../org-access-control/OrgNoPermissionOnMenu"
import { DLOrgSubMenus } from "../../../../temporary-data/org-side/default-org-menu-list/org-menus-enum"
import OrgDashboardGraphView from "./OrgDashboardGraphView"
import { OrgI18n } from "../../../../common-models/enumerations/translation-sheets"

export default observer(function OrgDashboardGraphViewController() {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const menuId = DLOrgSubMenus.dash_graph
	const actionName = "getOrgDashboardList"
	const menuAccess = orgStore.checkin.checkAccess(menuId)
	const needRefresh = orgStore.orgDashboard.needRefresh
	const i18nSheet = OrgI18n.projects

	useEffect(() => {
		menuAccess && needRefresh && orgStore.orgDashboard.getOrgDashboardList()
	}, [menuAccess, needRefresh])

	const i18n = store.i18n.combineI18n(i18nSheet)

	return (
		<>
			{menuAccess ? (
				<OrgDashboardGraphView
					partialStore={orgStore.orgDashboard}
					contentsHeight={store.ui.contentsAreaHeight}
					actionStatus={orgStore.orgDashboard.getActionStatus(
						actionName
					)}
					i18n={i18n}
				/>
			) : (
				<OrgNoPermissionOnMenu menuId={menuId} />
			)}
		</>
	)
})
