import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"

const data = {}

export default observer(function AuditHoursCalculation() {
	const orgStore = useOrgStore()

	return (
		<StyledAuditHoursCalculation>
			Audit Hours Calculation
		</StyledAuditHoursCalculation>
	)
})

const StyledAuditHoursCalculation = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
