import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"

const CreateProjTemplateGroup = (
	self: any,
	createTemplateGroup: OrgProjTemplatesApiProps["apiCreateProjTemplateGroup"]
) => ({
	createProjTemplateGroup: flow(function* (folderName: string) {
		const actionName = "createProjTemplateGroup"
		try {
			let params = {
				TempFoldName: folderName,
			}
			const response = yield createTemplateGroup(params)
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleModelError({ actionName, error, openSnackbar: true })

			return false
		}
	}),
})

export default CreateProjTemplateGroup
