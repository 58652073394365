import { flow } from "mobx-state-tree"

const ReadProjTrash = (self: any, read: any) => ({
	readProjTrashList: flow(function* () {
		const actionName = "readProjTrashList"
		try {
			const response = yield read()

			// console.log(actionName, "__ response", response)
			return response
		} catch (error) {
			// console.log(actionName, "__ error", error)
			self.handleModelError({ actionName, error, open: true })

			// return
		}
	}),
})

export default ReadProjTrash
