import React from "react"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import {
	DLContextMenu,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../common-models/permission"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	FolderAddIcon,
	IconPlaceholder,
} from "../../../../../components/basic-elements/icons/common-icons"
import { OrgI18n } from "../../../../../common-models/enumerations/translation-sheets"
import { GroupDetailTab } from "../../store/org-groups.ui-helper"

export default observer(function OrgGroupCtxMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const store = useRootStore()
	const orgStore = useOrgStore()
	const groupStore = orgStore.groups
	const sheetId = OrgI18n.groups
	//
	const handleClose = () => {
		orgStore.groups.setClickPoint({
			mouseX: null,
			mouseY: null,
		})
	}
	//
	const handleAddCabinet = () => {
		ConsoleLog(
			"current right click target " + orgStore.groups.rightClickTarget
		)
		orgStore.groups.setOpenCreateCabinetDialog(true)
	}
	// ----------
	/**
	 * (action name ) --> actionName on the permission
	 * 1. add cabinet --> createCabinet // NOTE: is createGroup required on the group screen?
	 * 2. edit name --> update
	 * 3. remove group --> deleteGroup
	 * 4. user management --> userAssign
	 * 5. client management --> clientAssign
	 */

	const menuOptions = (i18n: DLI18nProps) => [
		{
			label: i18n.twAddCabinet || "Add Cabinet",
			value: "ctx-add_cabinet",
			available: permission.create,
			clickEvent: handleAddCabinet,
			icon: <FolderAddIcon />,
		},
		{
			label: i18n.twEditGroup || "Edit",
			value: "ctx-edit_group_name",
			available: permission.update,
			clickEvent: () => {},
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: i18n.twUserList || "User List",
			value: "ctx-assigned_users",
			available: permission.read,
			clickEvent: () => {
				groupStore.setCurrentDetailTab(GroupDetailTab.users)
				groupStore.setGroupDetailDialogOpenStatus(true)
			},
			icon: <IconPlaceholder />,
		},
		{
			label: i18n.twClientList || "Client List",
			value: "ctx-assigned_clients",
			available: permission.read,
			clickEvent: () => {
				groupStore.setCurrentDetailTab(GroupDetailTab.clients)
				groupStore.setGroupDetailDialogOpenStatus(true)
			},
			icon: <IconPlaceholder />,
		},
		// delete group is available only on the setup
	]

	const i18n = store.i18n.combineI18n(sheetId)
	return (
		<DLContextMenu
			eleId="group-ctx-menus"
			clickPoint={{
				mouseX: orgStore.groups.clickPoint.mouseX,
				mouseY: orgStore.groups.clickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={menuOptions(i18n)}
			hasDivider={[0, 1]}
		/>
	)
})
