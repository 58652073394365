import { types } from "mobx-state-tree"
// main model & view-model
import { OrgGroupsModel } from "./org-groups.model"
import OrgGroupsViewModel from "./org-groups.view-model"
import OrgGroupsUiHelper, { GroupDetailTab } from "./org-groups.ui-helper"
// apis
import * as basicApi from "./model-actions/group-basic-actions/group-basic-apis"
import * as cabinetApi from "./model-actions/cabinet-in-group/group-cabinet-apis"
import * as projApi from "./model-actions/project-in-group/group-proj-apis"
import * as orgGroupApis from "../../../../service-modules/group-module/org-group-shared-apis"
import * as orgProjApis from "../../../../service-modules/project-module/org-project-shared-apis"
//
import { initialClickPoint } from "../../../../common-models/types/dialog.props"
import { OrgGroupApiCallProps } from "../../../../service-modules/group-module/org-group-shared-apis.calling-props"
import { CreateProjectMethod } from "../../../../components/combined-elements/create-project/create-project.data-props"
import { initialProjectDetail } from "../../../../service-modules/project-module/data-model/project-detail.data-model"
import { initialRFSourceProjInfo } from "../../../../service-modules/project-module/data-model/project-module-data-models"

export const OrgGroupsStore = (props: OrgGroupApiCallProps) =>
	types
		.compose(OrgGroupsModel(props), OrgGroupsViewModel, OrgGroupsUiHelper)
		.named("OrgGroupsStore")

export const initialStore = {
	selectedItemId: "",
	// ui-helper
	clickPoint: initialClickPoint,
	createProjectMethod: CreateProjectMethod.byUndefined,
	currentDetailTab: GroupDetailTab.users,
	projectDetails: initialProjectDetail,
	rfSourceProjInfo: initialRFSourceProjInfo,
	// viewModel common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgGroupsStoreInstance = OrgGroupsStore({
	// basic parts
	apiReadGroups: basicApi.readGroups,
	apiReadCabinets: basicApi.readCabinets,
	apiReadCabinetDetail: basicApi.readCabinetDetail,
	apiUpdateGroupName: orgGroupApis.updateGroupItem,
	// group cabinet parts
	apiCreateGroupItem: orgGroupApis.createGroupItem,
	apiUpdateCabinetName: orgGroupApis.updateGroupItem,
	apiMoveCabinet: cabinetApi.moveCabinet,
	// project related
	apiMoveProject: projApi.moveProject,
	apiDeleteProject: orgProjApis.deleteProject,
	//
	apiDeleteGroupItem: orgGroupApis.deleteGroupItem,
	apiReadProjectDetails: orgProjApis.readProjectDetails,
}).create(initialStore)

export default OrgGroupsStoreInstance
