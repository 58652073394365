import { types } from "mobx-state-tree"
import {
	ClickPoint,
	initialClickPoint,
} from "../../../../common-models/types/dialog.props"
import { ConsoleLog } from "../../../../components/basic-elements"
import { TrashType } from "./data-models/org-trash.data-models"

const OrgTrashUiHelper = types
	.model({
		selectedItems: types.array(types.string),
		selectedTrashType: types.enumeration<TrashType>(
			Object.values(TrashType)
		),
		clickPoint: ClickPoint,
	})
	.actions((self) => ({
		/**
		 * * selected items require the actions below
		 * - 1. setSelectedItem (reset and push new one)
		 * - 2. pushSeletedItem (with duplication check)
		 * - 3. pushOrSpliceSelectedItem (for checkbox)
		 * - 4. setSelectedItemsWithKeyboard
		 * - 5. spliceSelectedItem
		 * - 6. resetSelectedItems
		 */
		setSelectedItem(id: string) {
			self.selectedItems.length = 0
			self.selectedItems.push(id)
		},
		pushSelectedItem(id: string) {
			const duplicationCheck = self.selectedItems.find(
				(item) => item === id
			)
			if (!duplicationCheck) {
				self.selectedItems.push(id)
			}
		},
		pushOrSpliceItem(id: string) {
			// for checkbox
			ConsoleLog("id by checkbox " + id)
			const duplicationCheck = self.selectedItems.find(
				(item) => item === id
			)
			if (duplicationCheck) {
				const targetIndex = self.selectedItems.findIndex(
					(item) => item === id
				)
				self.selectedItems.splice(targetIndex, 1)
			} else {
				self.selectedItems.push(id)
			}
		},
		spliceSelectedItem(id: string) {
			const targetIndex = self.selectedItems.findIndex(
				(item) => item === id
			)
			self.selectedItems.splice(targetIndex, 1)
		},
		resetSelectedItems() {
			self.selectedItems.length = 0
		},
		//
		setClickPoint(clickPoint: typeof self.clickPoint) {
			self.clickPoint = clickPoint
		},
		resetClickPoint() {
			self.clickPoint = initialClickPoint
			// self.selectedItems.length = 0
		},
		setSelectedTrashType(type:TrashType) {
			self.selectedTrashType = type
		}
	}))

export default OrgTrashUiHelper
