import { flow } from "mobx-state-tree"
import { OrgProjTemplatesApiProps } from "../../org-proj-templates.api-props"
import {
	idToNumber,
	IdType,
} from "../../../../../../../library/converters/id-converter"

const DeleteProjTemplate = (
	self: any,
	deleteTemplate: OrgProjTemplatesApiProps["apiDeleteTemplate"]
) => ({
	deleteProjTemplate: flow(function* (templateId: string) {
		const actionName = "deleteProjTemplate"
		// 1.
		if (templateId === undefined) {
			alert(
				`(${actionName}) One of the params is undefined. templateId: ${templateId}`
			)
			return
		}
		// 2.
		const numberId = idToNumber(templateId, IdType.projTemplate)
		if (typeof numberId === "number") {
			try {
				const params = {
					TemplateId: numberId,
					// UserID: number
				}
				const response = yield deleteTemplate(params)
				// console.log(actionName+ "__response " + response)
				return response
			} catch (error) {
				// console.log(actionName+ "__error "+ error)
				self.handleModelError({
					actionName,
					error,
					openSnackbar: true,
				})

				return false
			}
		} else {
			alert(`(${actionName}) ${numberId}`)
		}
	}),
})

export default DeleteProjTemplate
