import { flow } from "mobx-state-tree"

const ReadOrgDashboardList = (self: any, read: any) => ({
	readOrgDashboardList: flow(function* () {
		const actionName = "readOrgDashboardList"
		try {
			const response = yield read()
			// ConsoleLog("readOrgDashboardList response", response)
			return response
		} catch (error) {
			// ConsoleLog("readOrgDashboardList error", error)
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default ReadOrgDashboardList
