import { types } from "mobx-state-tree"

export const UserForAmProject = types.model({
	userId: types.string,
	name: types.string,
	aliasId: types.string,
	email: types.string,
	role: types.string,
	accessRight: types.string,
	isGrayed: types.boolean,
	isStrikedOut: types.boolean,
})

export const AMProjectUsers = types.model({
	extUsersCount: types.number,
	activeExtUsersCount: types.number,
	inActiveExtUsersCount: types.number,
	unassignExtUsersCount: types.number,
	internalUsersCount: types.number,
	activeIntUsersCount: types.number,
	inActiveIntUsersCount: types.number,
	unassignIntUsersCount: types.number,
	//
	wpCount: types.number,
	pfCount: types.number,
	//
	internalUsers: types.array(UserForAmProject),
	externalUsers: types.array(UserForAmProject),
})
