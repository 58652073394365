import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
	DLSystemMsg,
	DLDialogHeader,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import { I18nDialogFormProps } from "../../../../../common-models/types/dialog.props"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import { MessageColorType, ActionStatus } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import styled from "styled-components"
import { useRootStore } from "../../../../../stores/root-store/root-store.provider"
import {
	CommonIconSize,
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"

export default observer(function EditClientDialog({ i18n }: { i18n: DLI18nProps }) {
	ConsoleLog(" -=-=-=-=- EditClientDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const actionName = "editClient"

	const { twCancel, twUpdate } = i18n
	// default inputs
	const inputsSchema = {
		clientName: { value: "", error: "", requestInput: false },
		clientAliasId: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		clientName: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		clientAliasId: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
	}

	const editClient = () => {
		const id = orgStore.setupClients.selectedItems[0]
		orgStore.setupClients.editClient(inputs, id)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, editClient)

	const clientInfo = orgStore.setupClients.getClientInfoById(
		orgStore.setupClients.selectedItems[0]
	)

	useEffect(() => {
		inputs.clientName.value = clientInfo?.clientName
		inputs.clientAliasId.value = clientInfo?.clientAliasId
	}, [clientInfo])
	
	const getActionStatus = orgStore.setupClients.getActionStatus

	return (
		<DLDialog
			eleTestId="edit-org-client-dialog"
			isOpen={orgStore.setupClients.editClientDialogOpen}
			setIsOpen={orgStore.setupClients.setEditClientDialogOpen}
			showOpenBtn={false}
			dialogTitle={
				<DLDialogHeader
					icon={
						<DLIcon
							name={DLIconName.edit}
							size={CommonIconSize.dialogHeaderIcon}
						/>
					}
					dialogName="Edit Client Information of"
					targetName={clientInfo?.clientName || "Unknown"}
				/>
			}
			dialogContents={
				<EditClientDialogForm
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			actionReady={isReady}
			handleAction={handleOnSubmit}
			actionBtn={twUpdate}
			cancelBtnText={twCancel}
			showSpinner={getActionStatus(actionName) === ActionStatus.loading}
			cannotUpdate={getActionStatus(actionName) === ActionStatus.loading}
		/>
	)
})


const EditClientDialogForm = observer(({
	inputs,
	handleOnChange,
	i18n,
}: I18nDialogFormProps) => {
	const orgStore = useOrgStore()

	return (
		<StyledEditClientDialog>
			<div className="input-section FR">
				<InputWithLabel required label={i18n.twClientId}>
					<DLInputField
						eleTestId="edit-client-aliasId-field"
						eleFullWidth
						eleName="clientAliasId"
						eleValue={inputs.clientAliasId.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={
							inputs.clientAliasId.error ||
							(orgStore.setupClients.isDuplicatedAliasId(
								inputs.clientAliasId.value
							) &&
								orgStore.setupClients.getClientInfoById(
									orgStore.setupClients.selectedItems[0]
								)?.clientAliasId !==
									inputs.clientAliasId.value &&
								"Client ID is already exist.")
						}
						warningType={
							orgStore.setupClients.getClientInfoById(
								orgStore.setupClients.selectedItems[0]
							)?.clientAliasId !== inputs.clientAliasId.value
								? "red"
								: undefined
						}
						requestInput={inputs.clientAliasId.requestInput}
					/>
				</InputWithLabel>
			</div>
			<div className="input-section FR">
				<InputWithLabel required label={i18n.twClientName}>
					<DLInputField
						eleTestId="edit_client_name_input"
						eleFullWidth
						eleName="clientName"
						eleValue={inputs.clientName.value}
						eleHandleChange={handleOnChange}
						eleRequired
						warningMsg={
							inputs.clientName.error ||
							(orgStore.setupClients.isDuplicatedName(
								inputs.clientName.value
							) &&
								orgStore.setupClients.getClientInfoById(
									orgStore.setupClients.selectedItems[0]
								)?.clientName !== inputs.clientName.value &&
								"Client Name is already exist.")
						}
						warningType={
							orgStore.setupClients.getClientInfoById(
								orgStore.setupClients.selectedItems[0]
							)?.clientName !== inputs.clientName.value
								? "red"
								: undefined
						}
						requestInput={inputs.clientName.requestInput}
					/>
				</InputWithLabel>
			</div>
		</StyledEditClientDialog>
	)
})

const StyledEditClientDialog = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`
