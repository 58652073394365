// ---------- common models
import {
	ActionStatus,
	MessageColorType,
} from "../../../../../../common-models/enumerations/common-enums"

const MapSubGroupToGroup = (self: any) => ({
	mapSubGroupToGroup(fsGroupId: number, fsSubGroupId: number) {
		// 0
		const actionName = "mapSubGroupToGroup"
		// 1
		// 2
		self.handleResponse({
			actionName,
			status: ActionStatus.loading,
			code: 0,
			color: MessageColorType.green,
			customMessage: "mapping financial sub group is in progress",
			open: true,
		})

		const payload = {
			financialGroupId: fsGroupId,
			financialSubGroupId: fsSubGroupId,
		}

		// 3) request API call to Model
		self.modelMapSubGroupToGroup(payload)
			.then((response: any) => {
				if (response) {
					// if success
					if (response.status === 200 && response.data.Status === 1) {
						//
						self.mapSubGroup(fsSubGroupId, response.data.MapId)
						self.handleResponse({
							actionName,
							status: ActionStatus.success,
							code: 200, // temp
							customMessage:
								"Successfully unmapped financial sub group ",
							color: MessageColorType.blue,
							open: true,
							autoHide: true,
							autoHideDuration: 3000,
						})
					} else {
						self.handleResponse({
							actionName,
							status: ActionStatus.fail,
							code: 999, // temp
							customMessage: response.data.Message,
							color: MessageColorType.orange,
							open: true,
						})
					}
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default MapSubGroupToGroup
