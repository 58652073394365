import React, { useState } from "react"
// components
import {
	DLIconButton,
	DLButton,
	DLMenus,
	DLBadge,
	DLContextMenuWithButton,
} from "../../../../../components/basic-elements"

// icons
import Icon from "@mdi/react"
import {
	mdiCheck,
	mdiCheckAll,
	mdiMinusCircleOutline,
	mdiMinusCircle,
	mdiPlaylistCheck,
	mdiMenuDown,
	mdiCommentMultipleOutline,
	mdiImport,
	mdiFolderRemoveOutline,
	mdiPlaylistEdit,
	mdiMagnify,
	mdiPlusCircle,
	mdiCommentMultiple,
	mdiFormatListChecks,
} from "@mdi/js"
import styled from "styled-components"
import { OldSignOffType } from "../../../../../service-modules/file-module/data-models/sign-off.data-model"
import { DLI18nProps } from "../../../../../common-models/types/common-props"
import {
	DLIcon,
	DLIconName,
} from "../../../../../components/basic-elements/icons/common-icons"
import { Link, useHistory } from "react-router-dom"
import {
	DragOnOffIconButton,
	CheckboxOnOffIconButton,
	ExpandAllIconButton,
	CollapseAllIconButton,
	SortingIconButton,
} from "../../../../../components/basic-elements/buttons/toggle-icon-buttons"
import { observer } from "mobx-react-lite"
import FilePageSettingDialog from "../dialogs/FilesPageSettingDialog"
import { FileMenuToolbarProps } from "./file-menu-toolbar-props"
import {
	DLProjSubMenus,
	DLProjMenuGroups,
} from "../../../../../temporary-data/project-side/default-proj-menu-list/proj-menus-enum"
import { useProjStore } from "../../../../../stores/proj-store/proj-store.provider"
import {
	DefaultSignOffPolicy,
	PfSignOffPolicy,
} from "../../../../../temporary-data/project-side/signoff-policy"
import {
	FileMenuViewType,
	FileScreenDialog,
} from "../../../../../service-modules/file-module/data-models/dl-file-control-model"
import {
	DLCommentType,
	DialogActionType,
} from "../../../../../common-models/enumerations/common-enums"

export default observer(function FileMenuToolbar({
	permission,
	ctxCreateMenus,
	//
	structureLock,
	showCheckbox,
	viewType,
	//
	commentDrawerStatus,
	commentBadgeContent,
	diagnosisDrawerStatus,
	libDrawerStatus,
	//
	countSelectedFiles,
	//
	handleAllowDrag,
	handleShowCheckbox,
	//
	handleExpandAll,
	handleCollapseAll,
	needExpandAll,
	needCollapseAll,
	handleSignOffFocusedView,
	//
	handleViewType,
	//
	handleCommentDrawer,
	handleDiagnosisDrawer,
	handleOpenedFilesDialog,
	handleLibraryDrawer,
	handleSearchView,
	//
	exportMenuOptions,
	allMenuOptions,
	//
	availableSignOffs,
	handleSignOffByToolbar,
	handleUnSignOffByToolbar,
	//
	partialStore,
	i18n,
	//
	isAsc,
	handleSortRootFolders,
	projMenuTabStore,
}: FileMenuToolbarProps) {
	// ConsoleLog("availableSignOffs in comp", availableSignOffs)
	const projStore = useProjStore()
	const defaultUrl = projStore.checkin.defaultUrl
	const setFileTreeDialogOpen = partialStore.setFileTreeDialogOpen
	const projId = projStore.checkin.projId
	let history = useHistory()

	const commentPermission = projStore.checkin.getActionsAsObject(
		DLProjSubMenus.comments
	)
	const projRole = projStore.checkin.user.roleShortName

	const handleRedirectTab = () => {
		const item = {
			id: DLProjSubMenus.signoff_history,
			menuGroup: DLProjMenuGroups.archive,
			subMenus: [],
			title: "Sign Off History",
			url: `${defaultUrl}/archive/sign-off-history`,
		}
		projMenuTabStore.setCurrentMainMenu(item.id)
		projMenuTabStore.addOpenedMenu(item)
	}

	const menuId = partialStore.storeName
	const signOffPolicy =
		menuId === DLProjSubMenus.wp
			? DefaultSignOffPolicy
			: menuId === DLProjSubMenus.pf
			? PfSignOffPolicy
			: []
	const userRole = projStore.checkin.user.roleId

	const ableToPrepare = signOffPolicy[0].availableRoles.includes(userRole)
	const ableToReview = signOffPolicy[1].availableRoles.includes(userRole)

	const selectedItems = partialStore.selectedFiles
	// const objInfo = partialStore.getItemInfo(selectedItems[0])

	const fileInfo = partialStore.getItemInfo(selectedItems[0])
	const itemsCount = selectedItems.length
	console.log(fileInfo, partialStore.selectedItems, "selectedItems")

	const isSignOffLocked = selectedItems.some((itemId: any) =>
		partialStore.flatList.find(
			(obj: any) => obj.id === itemId && obj.signOffLock === true
		)
	)

	const handleSignOff = (type: OldSignOffType) => {
		if (isSignOffLocked) {
			alert(
				`The file is in "Sign Off Locked" status. This action cannot be performed. Please change the Sign Off Lock Status to perform ${type} on the file.`
			)
			return
		}
		handleSignOffByToolbar(type)
	}

	const handleUnSignOff = (type: OldSignOffType) => {
		if (isSignOffLocked) {
			alert(
				`The file is in "Sign Off Locked" status. This action cannot be performed. Please change the Sign Off Lock Status to perform ${type} on the file.`
			)
			return
		}
		handleUnSignOffByToolbar(type)
	}

	const handleFileDownload = () => {
		const fileId = selectedItems[0]
		partialStore.downloadFile(fileId)
	}

	const handleFileDelete = () => {
		partialStore.removeFile(selectedItems[0])
	}

	const handleOpenCommentAddDialog = (type: DLCommentType) => {
		let fileId = ""
		if (itemsCount > 1) {
			alert("Cannot add comment on multiple files, please select again")
			return
		} else if (itemsCount <= 0) {
			alert("Please select a file first")
			return
		} else {
			fileId = partialStore.selectedFile // selectedItems[0].id
		}
		partialStore.setCommentDetailDialogMode(DialogActionType.add)
		partialStore.setCommentType(type)
		partialStore.setFileTreeDialogOpen(FileScreenDialog.commentDetail, true)
	}

	const handleOpenSignOffHistory = () => {
		history.push(`${defaultUrl}/archive/sign-off-history`)
	}

	const isLocked = projStore.projInfo.projInfo.isLocked

	return (
		<StyledFileMenuToolbar className="toolbar-container FC JSB">
			{/* The First individual button to create  */}
			{/* <div className={`FR AC JSB file-tree-basic-btns-container`}>
				{permission.create && (
					<DLContextMenuWithButton
						eleTestId="file-toolbar-create-menu"
						menuOptions={ctxCreateMenus}
						useScroll={false}
						showButton={true}
						showIcon={false}
						btnText="Create"
						btnVariant="outlined"
						btnSize="regular"
						eleIcon={
							<Icon
								path={mdiMenuDown}
								size={1}
								className="create-menu-btn"
							/>
						}
					/>
				)}

				{!isLocked && (
					<DLButton
						variant="outlined"
						eleTestId={`opened-wp-btn`}
						color="primary"
						size="regular"
						clickHandler={handleOpenedFilesDialog}
						// endIcon={eleIcon}
					>
						Opened Files
					</DLButton>
				)}

				{permission.structureLock && (
					<DLButton
						variant="outlined"
						eleTestId={`structure-lock-btn`}
						color="primary"
						size="regular"
						clickHandler={handleAllowDrag}
						// endIcon={eleIcon}
					>
						{structureLock ? "Unlock Structure" : "Lock Structure"}
					</DLButton>
				)}

				<DLContextMenuWithButton
					eleTestId="view-option-btn"
					menuOptions={[
						{
							label: `${
								showCheckbox ? "Hide Checkbox" : "Show Checkbox"
							}`,
							value: "ctx-show-checkbox",
							testId: "ctx-show-checkbox",
							clickEvent: handleShowCheckbox,
						},
						{
							label: `${
								isAsc
									? "Ascending Rootfolder"
									: "Descending Rootfolder"
							}`,
							value: "ctx-sorting-icon",
							testId: "ctx-sorting-icon",
							available: !structureLock && permission.update,
							clickEvent: handleSortRootFolders,
						},
						{
							label: "Expand All",
							value: "ctx-expand-all",
							testId: "ctx-expand-all",
							disabled:
								!needExpandAll ||
								partialStore.viewType !== FileMenuViewType.tree,
							clickEvent: handleExpandAll,
						},
						{
							label: "Collapse All",
							value: "ctx-collapse-all",
							testId: "ctx-collapse-all",
							disabled:
								!needCollapseAll ||
								partialStore.viewType !== FileMenuViewType.tree,
							clickEvent: handleCollapseAll,
						},
						{
							label: `${
								partialStore.viewType ===
								FileMenuViewType.fileTable
									? "Table View Off"
									: "Table View On"
							}`,
							value: "ctx-table-view-onoff",
							testId: "ctx-table-view-onoff",
							clickEvent: () =>
								handleViewType(
									partialStore.viewType ===
										FileMenuViewType.fileTable
										? FileMenuViewType.tree
										: FileMenuViewType.fileTable
								),
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="View Option"
					btnVariant="outlined"
					btnSize="regular"
					eleIcon={
						<Icon
							path={mdiMenuDown}
							size={1}
							className="create-menu-btn"
						/>
					}
				/>

				<DLButton
					variant="outlined"
					eleTestId="comment-list-onoff"
					color="primary"
					size="regular"
					clickHandler={handleCommentDrawer}
					// endIcon={eleIcon}
				>
					{commentDrawerStatus
						? i18n.twHideComments || "Hide Comments Drawer"
						: i18n.twShowComments || "Show Comments Drawer"}
				</DLButton>

				{partialStore.storeName === DLProjSubMenus.wp && !isLocked ? (
					<DLButton
						variant="outlined"
						eleTestId="library-onoff"
						color="primary"
						size="regular"
						clickHandler={handleLibraryDrawer}
						// endIcon={eleIcon}
						disabled={!partialStore.selectedItems.length}
					>
						Template Library
					</DLButton>
				) : (
					<div
						className="prepare-review-buttons-partition"
						style={{ width: "10rem" }}
					></div>
				)}

				<div className="divider" />
				<div className="button-group FR AC">
					<DLIconButton
						variant={
							partialStore.viewType === FileMenuViewType.search
								? "contained"
								: "iconOnly"
						}
						eleTestId="search-view-onoff"
						clickHandler={handleSearchView}
						tooltipText={i18n.twSearch || "Search"}
						color={
							partialStore.viewType === FileMenuViewType.search
								? "primary"
								: "default"
						}
					>
						<Icon path={mdiMagnify} size={0.8} />
					</DLIconButton>
					<DLIconButton
						variant="iconOnly"
						eleTestId="open-signoff-history-screen"
						eleClassName="move-to-signoff-screen-btn"
						clickHandler={() => handleRedirectTab()}
						tooltipText={"Sign Off History"}
					>
						<Link to={`${defaultUrl}/archive/sign-off-history`}>
							<Icon path={mdiFormatListChecks} size={0.8} />
						</Link>
					</DLIconButton>
				</div>
				<div className="divider" />
				<div className="button-group FR AC">
					{permission.export && (
						<DLMenus
							eleTestId="export-btn"
							options={exportMenuOptions}
							// showIcon={true}
							btn={
								<DLIcon
									name={DLIconName.download}
									noMargin
									tooltipText={i18n.twExport || "Export All"}
								/>
							}
						/>
					)}
					<FilePageSettingDialog partialStore={partialStore} />
				</div>
			</div> */}

			<div className={`FR AC JSB file-tree-basic-btns-container`}>
				<DLContextMenuWithButton
					eleTestId="folders-btn"
					menuOptions={[
						{
							label: "[1] Expand Root Folders",
							value: "ctx-expand-all",
							testId: "ctx-expand-all",
							disabled:
								!needExpandAll ||
								partialStore.viewType !== FileMenuViewType.tree,
							clickEvent: handleExpandAll,
						},
						{
							label: "[2] Collapse All Folders",
							value: "ctx-collapse-all",
							testId: "ctx-collapse-all",
							disabled:
								!needCollapseAll ||
								partialStore.viewType !== FileMenuViewType.tree,
							clickEvent: handleCollapseAll,
						},
						{
							label: "[3] Create Root Folder",
							value: "ctx-add-root-folder",
							testId: "ctx-add-root-folder",
							clickEvent: () => {
								partialStore.resetSelectedItems()
								partialStore.setFileTreeDialogOpen(
									FileScreenDialog.createFolder,
									true
								)
							},
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Folders"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				{/* <div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div> */}
				{/* <DLContextMenuWithButton
					eleTestId="create-btn"
					menuOptions={ctxCreateMenus}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Create"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/> */}
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				<DLContextMenuWithButton
					eleTestId="actions-btn"
					menuOptions={[
						{
							label: "Opened Files",
							value: "ctx-opened-files",
							testId: "ctx-opened-files",
							available: !isLocked,
							clickEvent: handleOpenedFilesDialog,
						},
						{
							label: structureLock
								? "Unlock Structure"
								: "Lock Structure",
							value: "ctx-structure-lock-btn",
							testId: "ctx-structure-lock-btn",
							available: permission.structureLock,
							clickEvent: handleAllowDrag,
						},
						{
							label: "Export",
							value: "ctx-export-btn",
							testId: "ctx-export-btn",
							available: permission.export,
							clickEvent: () => {
								if (
									partialStore.storeName === DLProjSubMenus.wp
								) {
									partialStore.setDownloadType("WP")
									partialStore.setFileTreeDialogOpen(
										FileScreenDialog.exportFolderDialog
									)
								} else {
									partialStore.downloadAllAsZip({ projId })
								}
							},
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Actions"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				<DLContextMenuWithButton
					eleTestId="go-to-btn"
					menuOptions={[
						{
							label: "Archive Diagnosis",
							value: "ctx-archive-diagnosis",
							testId: "ctx-archive-diagnosis",
							// clickEvent: handleExpandAll,
						},
						{
							label: "Sign Off History",
							value: "ctx-open-signoff-history-screen",
							testId: "open-signoff-history-screen",
							clickEvent: handleOpenSignOffHistory,
						},
						{
							label: commentDrawerStatus
								? i18n.twHideComments || "Hide Comments Drawer"
								: i18n.twShowComments || "Show Comments Drawer",
							value: "ctx-show-comments",
							testId: "ctx-show-comments",
							clickEvent: handleCommentDrawer,
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="Go To"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div
					className="prepare-review-buttons-partition"
					style={{ width: 16 }}
				></div>
				<DLContextMenuWithButton
					eleTestId="view-options-btn"
					menuOptions={[
						{
							label: `${
								showCheckbox ? "Hide Checkbox" : "Show Checkbox"
							}`,
							value: "ctx-show-checkbox",
							testId: "ctx-show-checkbox",
							clickEvent: handleShowCheckbox,
						},
						{
							label:
								partialStore.viewType ===
								FileMenuViewType.fileTable
									? "Table View Off"
									: "Table View On",
							value: "ctx-table-view-onoff",
							testId: "ctx-table-view-onoff",
							clickEvent: () =>
								handleViewType(
									partialStore.viewType ===
										FileMenuViewType.fileTable
										? FileMenuViewType.tree
										: FileMenuViewType.fileTable
								),
						},
					]}
					useScroll={false}
					showButton={true}
					showIcon={false}
					btnText="View Options"
					btnVariant="contained"
					btnColor="primary"
					btnSize="regular"
					btnClassName="top-row-btns"
					eleIcon={<Icon path={mdiMenuDown} size={1} />}
				/>
				<div className="divider" />
				{partialStore.storeName === DLProjSubMenus.wp && !isLocked ? (
					<DLButton
						variant="outlined"
						eleTestId="library-onoff"
						color="primary"
						size="regular"
						clickHandler={handleLibraryDrawer}
						// endIcon={eleIcon}
						disabled={!partialStore.selectedItems.length}
					>
						Template Library
					</DLButton>
				) : (
					<div
						className="prepare-review-buttons-partition"
						style={{ width: "10rem" }}
					></div>
				)}
				<div className="divider" />
				<div className="button-group FR AC">
					<DLIconButton
						variant={
							partialStore.viewType === FileMenuViewType.search
								? "contained"
								: "iconOnly"
						}
						eleTestId="search-view-onoff"
						clickHandler={handleSearchView}
						tooltipText={i18n.twSearch || "Search"}
						color={
							partialStore.viewType === FileMenuViewType.search
								? "primary"
								: "default"
						}
					>
						<Icon path={mdiMagnify} size={0.8} />
					</DLIconButton>
					<FilePageSettingDialog partialStore={partialStore} />
				</div>
			</div>

			{/* ========== Main Sign Off buttons ========== */}

			{!isLocked && (
				<div className={`sign-off-main-btns-container FR`}>
					<DLButton
						color="primary"
						variant="contained"
						disabled={
							!availableSignOffs.prepare || !ableToPrepare
							// || isSignOffLocked
						}
						eleTestId="prepare-btn-in-toolbar"
						startIcon={<Icon path={mdiCheck} size={0.8} />}
						clickHandler={() =>
							handleSignOff(OldSignOffType.prepare)
						}
						eleClassName="signoff-btns"
					>
						{i18n.twPrepare || "Prepare"}
					</DLButton>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>
					<DLButton
						color="primary"
						variant="contained"
						disabled={
							!availableSignOffs.unPrepare || !ableToPrepare
							// || isSignOffLocked
						}
						eleTestId="unprepare-btn-in-toolbar"
						startIcon={
							<Icon path={mdiMinusCircleOutline} size={0.8} />
						}
						clickHandler={() =>
							handleUnSignOff(OldSignOffType.prepare)
						}
						eleClassName="signoff-btns"
					>
						{i18n.twUnPrepare || "Un Prepare"}
					</DLButton>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>

					<DLButton
						color="primary"
						variant="contained"
						disabled={
							!availableSignOffs.review || !ableToReview
							// || isSignOffLocked
						}
						eleTestId="review-btn-in-toolbar"
						startIcon={<Icon path={mdiCheckAll} size={0.8} />}
						clickHandler={() =>
							handleSignOff(OldSignOffType.review)
						}
						eleClassName="signoff-btns"
					>
						{i18n.twReview || "Review"}
					</DLButton>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>
					<DLButton
						color="primary"
						variant="contained"
						disabled={
							!availableSignOffs.unReview || !ableToReview
							//|| isSignOffLocked
						}
						eleTestId="unreview-btn-in-toolbar"
						startIcon={<Icon path={mdiMinusCircle} size={0.8} />}
						clickHandler={() =>
							handleUnSignOff(OldSignOffType.review)
						}
						eleClassName="signoff-btns"
					>
						{i18n.twUnReview || "Un Review"}
					</DLButton>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>
					<DLContextMenuWithButton
						eleTestId="file-operation-btn"
						menuOptions={[
							{
								label: "File Download (d)",
								value: "ctx-file-download",
								testId: "ctx-file-download",
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: handleFileDownload,
							},
							{
								label: "Replace (s)",
								value: "ctx-replace",
								testId: "ctx-replace",
								available: permission.update,
								disabled:
									itemsCount > 1 ||
									itemsCount === 0 ||
									fileInfo?.isOpen,
								clickEvent: () =>
									setFileTreeDialogOpen(
										FileScreenDialog.replaceFile,
										true
									),
							},
							{
								label: "Edit File Info (e)",
								value: "ctx-edit-file",
								testId: "ctx-edit-file",
								disabled:
									itemsCount > 1 ||
									itemsCount === 0 ||
									fileInfo.isOpen,
								clickEvent: () =>
									setFileTreeDialogOpen(
										FileScreenDialog.fileEdit,
										true
									),
							},
							{
								label: `Sign Off ${
									fileInfo?.signOffLock ? "Unlock" : "Lock"
								} (l)`,
								value: "ctx-table-view-onoff",
								testId: "ctx-table-view-onoff",
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () => {
									setFileTreeDialogOpen(
										FileScreenDialog.signOffLockDialog,
										true
									)
								},
							},
							{
								label: "Delete (x)",
								value: "ctx-delete-file",
								testId: "ctx-delete-file",
								disabled:
									itemsCount > 1 ||
									itemsCount === 0 ||
									fileInfo?.isOpen,
								clickEvent: handleFileDelete,
							},
						]}
						useScroll={false}
						showButton={true}
						disableBtn={!fileInfo}
						showIcon={false}
						btnText="File Operation"
						btnVariant="outlined"
						btnColor="primary"
						btnClassName="ctx-menu-styles"
						btnSize="regular"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>
					<DLContextMenuWithButton
						eleTestId="role-assign-btn"
						menuOptions={[
							{
								label: "Assign to Role",
								value: "ctx-role-assign",
								testId: "ctx-role-assign",
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () => {},
							},
							{
								label: "Unassign from Role",
								value: "ctx-role-unassign",
								testId: "ctx-role-unassign",
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () => {},
							},
						]}
						useScroll={false}
						showButton={true}
						disableBtn={!fileInfo}
						showIcon={false}
						btnText="Role Assign"
						btnVariant="outlined"
						btnColor="primary"
						btnClassName="ctx-menu-styles"
						btnSize="regular"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>
					<DLContextMenuWithButton
						eleTestId="add-comment-btn"
						menuOptions={[
							{
								label: "Add Review Comment",
								value: "ctx-add-comment",
								available:
									commentPermission.create && !isLocked,
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () =>
									handleOpenCommentAddDialog(
										DLCommentType.normal
									),
							},
							{
								label: "Add QC Comment",
								value: "ctx-add-qc-comment",
								available:
									commentPermission.create && !isLocked,
								disabled:
									itemsCount > 1 ||
									itemsCount === 0 ||
									projRole !== "QC",
								clickEvent: () =>
									handleOpenCommentAddDialog(
										DLCommentType.qc
									),
							},
						]}
						useScroll={false}
						showButton={true}
						disableBtn={!fileInfo}
						showIcon={false}
						btnText="Add Comment"
						btnVariant="outlined"
						btnColor="primary"
						btnClassName="ctx-menu-styles"
						btnSize="regular"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
					<div
						className="prepare-review-buttons-partition"
						style={{ width: 16 }}
					></div>
					<DLContextMenuWithButton
						eleTestId="file-info-btn"
						menuOptions={[
							{
								label: "Sign Off History",
								value: "ctx-file-information",
								available: permission.read,
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () =>
									setFileTreeDialogOpen(
										FileScreenDialog.fileInfo,
										true
									),
							},
							{
								label: "Sign Off Lock History",
								value: "ctx-file-signoff-lock-history",
								available: permission.read,
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () => {
									setFileTreeDialogOpen(
										FileScreenDialog.signOffLockUnlockHistoryDialog,
										true
									)
								},
							},
							{
								label: "File History",
								value: "ctx-file-history",
								available: permission.read,
								disabled: itemsCount > 1 || itemsCount === 0,
								clickEvent: () => {
									setFileTreeDialogOpen(
										FileScreenDialog.FileHistoryDialog,
										true
									)
								},
							},
						]}
						useScroll={false}
						showButton={true}
						disableBtn={!fileInfo}
						showIcon={false}
						btnText="File Info"
						btnVariant="outlined"
						btnColor="primary"
						btnClassName="ctx-menu-styles"
						btnSize="regular"
						eleIcon={<Icon path={mdiMenuDown} size={1} />}
					/>
				</div>
			)}
		</StyledFileMenuToolbar>
	)
})

const StyledFileMenuToolbar = styled.div`
	&.toolbar-container {
		transition: 0.4s;
		.sign-off-main-btns-container {
			background-color: #f2f2f4;
			margin-top: 5px;
			// opacity: 0;
			// width: 0;
			overflow: hidden;
			// transition: 0.4s;
			// &.sign-off-mode {
			// 	opacity: 1;
			// 	width: 100%;
			// }
		}
		.file-tree-basic-btns-container {
			width: 100%;
			// &.sign-off-mode {
			// 	opacity: 0;
			// 	width: 0;
			// }
			.create-menu-btn {
				.button-group {
					.comment-drawer-btn {
						span.comment-counting-badge {
							span {
								border: 1px solid black;
							}
						}
					}
				}
			}
			.divider {
				width: 1px;
				height: 1.8rem;
				background-color: gray;
				/* margin: 0 0.8rem; */
			}
		}
	}

	.ctx-menu-styles {
		border-radius: 20px;
		&:not(.disabled) {
			color: black !important;
			border-color: black;
			// font-weight: bold;
			svg {
				fill: black;
				color: black;
			}
			:hover {
				border-color: black;
				svg {
					fill: black;
					color: black;
				}
			}
		}
	}

	.signoff-btns:not(.disabled) {
		color: black !important;
		svg {
			fill: black;
			color: black;
		}
	}

	.top-row-btns {
		min-width: 150px;
		background-color: #d2cde4;
		color: black;
		font-weight: bold;
		svg {
			fill: black;
			color: black;
		}
		:hover {
			background-color: #c5bee0;
			color: black;
			svg {
				fill: black;
				color: black;
			}
		}
	}
`
