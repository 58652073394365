import { AxiosResponse } from "axios"
import { TOKEN_TYPE } from "../../../../library/api-requests/shared-for-api-requests"
import { getRequest } from "../../../../library/api-requests"

export async function read(params: {
	ClientId: string
	FinancialYear: string
	Lang: string
}) {
	const response = await getRequest({
		url: "/GetDashBoardDetails",
		params,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export type ProjDashboardApiProps = {
	apiRead: (params: {
		ClientId: string
		FinancialYear: string
		Lang: string
	}) => Promise<AxiosResponse<any>>
}
