import { getRequest, postRequest } from "../../../../../library/api-requests"
import { TOKEN_TYPE } from "../../../../../library/api-requests/shared-for-api-requests"

export function create(payload: { notes: string; noteId: number }) {
	const response = postRequest({
		url: "/SaveNotes",
		params: payload,
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}

export function read() {
	const response = getRequest({
		url: "/GetNotes",
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
