import React, { useEffect } from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLSingleSelect,
	InputWithLabel,
	DLRadioGroup,
	DLInputField,
	DLDialog,
	DLSystemMsg,
	ConsoleLog,
} from "../../../../../components/basic-elements"
import sharedRegEx from "../../../../../library/sharedRegEx"
import useForm from "../../../../../library/use-form"
import styled from "styled-components"
import { MessageColorType } from "../../../../../common-models/enumerations/common-enums"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

const EditOrgUserDialog = observer(({ i18n }: { i18n: DLI18nProps }) => {
	ConsoleLog(" -=-=-=-=- EditOrgUserDialog -=-=-=-=- ")
	const orgStore = useOrgStore()
	const actionName = "editOrgUser"

	const inputsSchema = {
		type: { value: "internal", error: "", requestInput: false },
		email: { value: "", error: "", requestInput: false },
		name: { value: "", error: "", requestInput: false },
		aliasId: { value: "", error: "", requestInput: false },
		accessRight: { value: "User Access", error: "", requestInput: false },
		isArchiveAdmin: { value: "false", error: "", requestInput: false },
		isHardCopyAdmin: { value: "false", error: "", requestInput: false },
		title: { value: "", error: "", requestInput: false },
	}

	const validationSchema = {
		email: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.email,
				error: "Invalid format",
			},
		},
		name: {
			isRequired: true,
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		aliasId: {
			isRequired: true,
			// TODO: which special characters are restricted in here?
			validator: {
				regEx: sharedRegEx.noSpecialCharacters,
				error:
					i18n.warningNoSpecialChar ||
					"Cannot use special characters",
			},
		},
		// NOTE: external user's title is mandatory but cannot add here. So added its alert on the form directly
	}

	const updateUser = () => {
		orgStore.setupUsers.editOrgUser(inputs)
	}

	const {
		inputs,
		handleOnChange,
		handleOnSubmit,
		isReady,
		// initializeInputs
	} = useForm(inputsSchema, validationSchema, updateUser)

	const userInfo = orgStore.setupUsers.viewSelectedUserInfo()

	useEffect(() => {
		if (orgStore.setupUsers.selectedUser) {
			inputs.type.value = userInfo.type
			inputs.email.value = userInfo.email
			inputs.name.value = userInfo.name
			inputs.aliasId.value = userInfo.aliasId
			inputs.accessRight.value = userInfo.accessRight
			inputs.isArchiveAdmin.value =
				userInfo.isArchiveAdmin === "Yes" ? "true" : "false"
			inputs.title.value = userInfo.title
		}
	}, [userInfo])

	let actionReady = false
	if (isReady) {
		if (inputs.type.value === "internal") {
			// when user is internal, 'title' is not mandatory
			actionReady = true
		} else {
			// when user is external, 'title' is mandatory
			if (inputs.title.value !== "" && inputs.title.value.length > 0) {
				actionReady = true
			}
		}
	}

	return (
		<DLDialog
			eleTestId="edit-org-user-dialog"
			isOpen={orgStore.setupUsers.editDialogOpen}
			setIsOpen={orgStore.setupUsers.setEditDialogOpen}
			handleAction={handleOnSubmit}
			showOpenBtn={false}
			showCloseBtn={true}
			dialogTitle={i18n.tsEditDialogTitle}
			dialogContents={
				// <div>Update Org User</div>
				<EditOrgUserDialogForm
					userInfo={userInfo}
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			cancelBtnText={i18n.twCancel || "Cancel"}
			actionReady={
				actionReady
				// isReady
				// &&
				// inputs.name.value !== userInfo.name &&
				// inputs.aliasId.value !== userInfo.aliasId &&
				// !orgStore.setupUsers.isDuplicatedAliasId(inputs.aliasId.value)
			}
			actionBtn={i18n.twSubmit || "Submit"}
			maxWidth="sm"
			fullWidth={true}
			dialogError={
				orgStore.setupUsers.getActionStatus(actionName) !== "SUCCESS"
					? orgStore.setupUsers.responses.getResponse(actionName)
							?.message
					: undefined
			}
			showSpinner={
				orgStore.setupUsers.getActionStatus(actionName) === "LOADING"
			}
			cannotUpdate={
				orgStore.setupUsers.responses.getResponse(actionName)
					?.status === "LOADING"
			}
		/>
	)
})

const EditOrgUserDialogForm = observer(
	({
		userInfo,
		inputs,
		handleOnChange,
		i18n,
	}: {
		userInfo: any
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		const orgStore = useOrgStore()
		const actionName = "editOrgUser"

		return (
			<StyledEditOrgUserDialogForm>
				{/* 1. User type
					2. Email
					3. Name 
					4. Alias ID
					5. Access Right 
					6. Title */}
				{/* ----- 1. User Type: Internal or External */}
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twUserType}>
						<DLSingleSelect
							eleTestId="user-type-selection"
							eleName="type"
							eleValue={inputs.type.value}
							eleFullWidth
							withLabel={false}
							optionList={[
								{
									value: "internal",
									name:
										i18n.twInternalUser || "Internal User",
								},
								{
									value: "external",
									name:
										i18n.twExternalUser || "External User",
								},
							]}
							placeholder={
								i18n.twSelectUserType || "Select User Type"
							}
							eleOnChange={handleOnChange}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 2. Email  */}
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twEmail}>
						<DLInputField
							eleTestId="email-input-field"
							eleReadOnly
							eleFullWidth
							eleName="email"
							eleValue={inputs.email.value}
							eleHandleChange={handleOnChange}
							eleRequired
							// warningMsg={inputs.email.error}
							warningMsg={
								userInfo.email !== inputs.email.value &&
								orgStore.setupUsers.isDuplicatedEmail(
									inputs.email.value
								)
									? i18n.tsSameEmailExist ||
									  "The same email already exist"
									: inputs.email.error
							}
							warningType={
								userInfo.email !== inputs.email.value &&
								orgStore.setupUsers.isDuplicatedEmail(
									inputs.email.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.email.requestInput}
						/>
					</InputWithLabel>
				</div>
				{/* ----- 3. Name  */}
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twName}>
						<DLInputField
							eleTestId="username-input-field"
							eleFullWidth
							eleName="name"
							eleValue={inputs.name.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.name.error}
							requestInput={inputs.name.requestInput}
						/>
						{/* {orgStore.setupUsers.getActionStatus(actionName) !==
							"SUCCESS" &&
							// inputs.name.value !== userInfo.name &&
							orgStore.setupUsers.isDuplicatedName(
								inputs.name.value
							) &&
							userInfo.name !== inputs.name.value && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg="User name is already exist."
								/>
							)} */}
					</InputWithLabel>
				</div>
				{/* ----- 4. Alias ID  */}
				<div className="input-section FR">
					<InputWithLabel required label={i18n.twAliasId}>
						<DLInputField
							eleTestId="alias-id-input-field"
							eleFullWidth
							eleName="aliasId"
							eleValue={inputs.aliasId.value}
							eleHandleChange={handleOnChange}
							eleRequired
							// warningMsg={inputs.aliasId.error}
							warningMsg={
								userInfo.aliasId !== inputs.aliasId.value &&
								orgStore.setupUsers.isDuplicatedAliasId(
									inputs.aliasId.value
								)
									? i18n.tsSameIdExist ||
									  "The same ID already exist"
									: inputs.aliasId.error
							}
							warningType={
								userInfo.aliasId !== inputs.aliasId.value &&
								orgStore.setupUsers.isDuplicatedAliasId(
									inputs.aliasId.value
								)
									? "red"
									: undefined
							}
							requestInput={inputs.aliasId.requestInput}
						/>
						{orgStore.setupUsers.getActionStatus(actionName) !==
							"SUCCESS" &&
							inputs.aliasId.value !== userInfo.aliasId &&
							orgStore.setupUsers.isDuplicatedAliasId(
								inputs.aliasId.value
							) && (
								<DLSystemMsg
									type={MessageColorType.red}
									msg="Alias ID is already exist."
								/>
							)}
					</InputWithLabel>
				</div>
				{/* ----- 5. Access Right  */}
				{inputs.type.value !== "external" && (
					<>
						<div className="input-section FR">
							<InputWithLabel required label={i18n.twOrgRole}>
								<DLSingleSelect
									eleTestId="org-role-selection"
									eleName="accessRight"
									eleValue={inputs.accessRight.value}
									eleFullWidth
									withLabel={false}
									optionList={[
										// {
										// 	value: "Archive Admin",
										// 	name: "Archive Admin",
										// },
										{
											value: "Super Admin",
											name: "Super Admin",
										},
										{
											value: "Group Admin",
											name: "Group Admin",
										},
										{
											value: "User Access",
											name: "User Access",
										},
									]}
									placeholder="Select User Access Right"
									eleOnChange={handleOnChange}
								/>
							</InputWithLabel>
						</div>
						{inputs.accessRight.value === "Super Admin" && (
							<>
								<div
									className="input-section FR"
									data-testid="is-archive-admin-section"
								>
									<InputWithLabel
										required
										label={
											i18n.twIsOrgArchiveAdmin ||
											"Is this user Org. Archive Admin?"
										}
									>
										<DLRadioGroup
											itemDirection="row"
											groupName="isArchiveAdmin"
											selectedValue={
												inputs.isArchiveAdmin.value
											}
											items={[
												{
													value: "true",
													label: "Yes",
												},
												{
													value: "false",
													label: "No",
												},
											]}
											eleOnChange={handleOnChange}
										/>
									</InputWithLabel>
								</div>
								<div
									className="input-section FR"
									data-testid="is-hard-copy-admin-section"
								>
									<InputWithLabel
										required
										label={
											i18n.twIsHardCopyAdmin ||
											"Is this user Hard Copy Admin?" // Need i18n
										}
									>
										<DLRadioGroup
											itemDirection="row"
											groupName="isHardCopyAdmin"
											selectedValue={
												inputs.isHardCopyAdmin.value
											}
											items={[
												{
													value: "true",
													label: "Yes",
												},
												{
													value: "false",
													label: "No",
												},
											]}
											eleOnChange={handleOnChange}
										/>
									</InputWithLabel>
								</div>
							</>
						)}
					</>
				)}
				{/* ----- 6. Title  */}
				<div className="input-section FR">
					<InputWithLabel
						label={
							inputs.type.value === "external"
								? i18n.twDesc || "Description"
								: i18n.twTitle || "Title"
						}
						required={
							inputs.type.value === "internal" ? false : true
						}
					>
						<DLInputField
							eleTestId="title-input-field"
							eleFullWidth
							eleName="title"
							eleValue={inputs.title.value}
							eleHandleChange={handleOnChange}
							warningMsg={
								inputs.type.value === "external" &&
								inputs.title.value.length <= 0 &&
								inputs.title.value === ""
									? "Please input the external user description"
									: undefined
							}
							// warningType="orange"
						/>
					</InputWithLabel>
				</div>
			</StyledEditOrgUserDialogForm>
		)
	}
)

const StyledEditOrgUserDialogForm = styled.div`
	padding-left: 0.5rem;
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 9rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 20rem;
		}
	}
`

export default EditOrgUserDialog
