import React from "react"
import { observer } from "mobx-react-lite"
import { DLContextMenu } from "../../../../../../components/basic-elements"
import { useOrgStore } from "../../../../../../stores/org-store/org-store.provider"
import { initialClickPoint } from "../../../../../../common-models/types/dialog.props"
import {
	DLIcon,
	DLIconName,
	CommonIconSize,
	IconPlaceholder,
} from "../../../../../../components/basic-elements/icons/common-icons"
import { ConsoleLog } from "../../../../../../components/basic-elements"
import { PermissionAsObjectProps } from "../../../../../../common-models/permission"
import { ProjTemplateDialog } from "../../store/org-proj-templates.ui-helper"

export default observer(function ProjTemplateGroupCtxMenu({
	permission,
}: {
	permission: PermissionAsObjectProps
}) {
	const orgStore = useOrgStore()
	const templateStore = orgStore.orgProjTemplates
	const templateGroupId = templateStore.currentTargetId

	const handleClose = () => {
		templateStore.setTemplateGroupClickPoint(initialClickPoint)
	}

	const handleAddTemplate = () => {
		templateStore.setSelectedTemplateGroup(templateGroupId)
		templateStore.setDialogOpen(ProjTemplateDialog.createTemplate)
	}

	const handleEditTemplateGroup = () => {
		templateStore.setTemplateGroupEditMode(true)
	}

	const handleDuplicateTemplateGroup = () => {
		templateStore.duplicateProjTemplateGroup(templateGroupId)
	}

	const handleDeleteTemplateGroup = () => {
		if (templateGroupId) {
			templateStore.removeProjTemplateGroup(templateGroupId)
		} else {
			ConsoleLog([
				"(ProjTemplateGroupCtxMenu) Cannot find the right click target:",
				templateGroupId,
			])
		}
	}

	return (
		<DLContextMenu
			eleId="proj-templates-group-ctx-menus"
			clickPoint={{
				mouseX: templateStore.templateGroupClickPoint.mouseX,
				mouseY: templateStore.templateGroupClickPoint.mouseY,
			}}
			handleClose={handleClose}
			menuOptions={ProjTemplateGroupCtxMenuOptions({
				permission,
				handleAddTemplate,
				handleEditTemplateGroup,
				handleDeleteTemplateGroup,
				handleDuplicateTemplateGroup,
			})}
		/>
	)
})

const ProjTemplateGroupCtxMenuOptions = ({
	permission,
	handleAddTemplate,
	handleEditTemplateGroup,
	handleDeleteTemplateGroup,
	handleDuplicateTemplateGroup,
}: {
	permission: PermissionAsObjectProps
	handleAddTemplate: () => void
	handleEditTemplateGroup: () => void
	handleDeleteTemplateGroup: () => void
	handleDuplicateTemplateGroup: () => void
}) => {
	return [
		{
			label: "Add Project Template",
			value: "ctx-add-proj-template",
			available: permission.create,
			clickEvent: () => handleAddTemplate(),
			icon: <IconPlaceholder />,
		},
		{
			label: "Edit Project Template",
			value: "ctx-edit-proj-template",
			available: permission.update,
			clickEvent: () => handleEditTemplateGroup(),
			icon: (
				<DLIcon
					name={DLIconName.edit}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Duplicated Project Template Group",
			value: "ctx-duplicate-proj-template-group",
			available: permission.create,
			clickEvent: () => handleDuplicateTemplateGroup(),
			icon: (
				<DLIcon
					name={DLIconName.duplicate}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
		{
			label: "Remove Project Template Group",
			value: "ctx-remove-proj-template-group",
			available: permission.delete,
			clickEvent: () => handleDeleteTemplateGroup(),
			icon: (
				<DLIcon
					name={DLIconName.delete}
					size={CommonIconSize.ctxMenuIcon}
				/>
			),
		},
	]
}
