import React from "react"
import { DLTooltip } from "@datalobby/components"
import { mdiCheckboxBlankCircleOutline, mdiCheckCircle } from "@mdi/js"
import Icon from "@mdi/react"

const Validator = ({ value }: { value: string }) => {
	return (
		<div className="FR input-validation-ui">
			<DLTooltip title="one more small letter">
				{/[a-z]{1,}/.test(value) ? (
					<div className="regex-matched match-case">
						<Icon path={mdiCheckCircle} size={0.6} />
						a~z
					</div>
				) : (
					<div className="match-case">
						<Icon path={mdiCheckboxBlankCircleOutline} size={0.6} />
						a~z
					</div>
				)}
			</DLTooltip>
			<DLTooltip title="one more capitar">
				{/[A-Z]{1,}/.test(value) ? (
					<div className="regex-matched match-case">
						<Icon path={mdiCheckCircle} size={0.6} />
						A~Z
					</div>
				) : (
					<div className="match-case">
						<Icon path={mdiCheckboxBlankCircleOutline} size={0.6} />
						A~Z
					</div>
				)}
			</DLTooltip>
			<DLTooltip title="one more number">
				{/[0-9]{1,}/.test(value) ? (
					<div className="regex-matched match-case">
						<Icon path={mdiCheckCircle} size={0.6} />
						0~9
					</div>
				) : (
					<div className="match-case">
						<Icon path={mdiCheckboxBlankCircleOutline} size={0.6} />
						0~9
					</div>
				)}
			</DLTooltip>
			<DLTooltip title="one more special character">
				{/[!@#$%^&*()]{1,}/.test(value) ? (
					<div className="regex-matched match-case">
						<Icon path={mdiCheckCircle} size={0.6} />
						!@#$%^&*()
					</div>
				) : (
					<div className="match-case">
						<Icon path={mdiCheckboxBlankCircleOutline} size={0.6} />
						!@#$%^&*()
					</div>
				)}
			</DLTooltip>
			<DLTooltip title="more than 8 characters">
				{value.length >= 8 ? (
					<div className="regex-matched match-case">
						<Icon path={mdiCheckCircle} size={0.6} />
						more than or equal 8 characters
					</div>
				) : (
					<div className="match-case">
						<Icon path={mdiCheckboxBlankCircleOutline} size={0.6} />
						more than or equal 8 characters
					</div>
				)}
			</DLTooltip>
		</div>
	)
}

export default Validator
