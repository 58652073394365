import GlobalStoreViewModel from "./global-store.view-model"
import { ActionStatus } from "../../../common-models/enumerations/common-enums"

const GlobalStoreInstance = GlobalStoreViewModel.create({
	//
	sidebarViewState: "org",
	currentOrg: { id: "", title: "" },
	currentProj: { id: "", title: "" },
	currentProjMenu: [],
	// ---------- global sharing
	// DnT
	dateFormat: {
		settingId: "",
		label: "",
		value: "",
		uiValue: "",
	},
	timeFormat: {
		settingId: "",
		label: "",
		value: "",
		uiValue: "",
	},
	timeZone: {
		settingId: "",
		label: "",
		value: "",
		uiValue: "",
	},
	// ---------- common parts
	actionStatus: ActionStatus.standby,
	responses: {},
	responseSnackbar: {
		message: "",
	},
})

export default GlobalStoreInstance
