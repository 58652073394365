import { flow } from "mobx-state-tree"
import { callGraphQL } from "@datalobby/common"
import { createPost as gqlCreatePost } from "../../../graphql/Post/mutations"
import { CreatePostInputType } from "@datalobby/types"

const createPost = (self: any) => ({
	createPost: flow(function* (post: CreatePostInputType) {
		const actionName = "createPost"
		try {
			const result = yield callGraphQL<any>(gqlCreatePost, { post })
			return result
		} catch (error) {
			self.handleModelError({ actionName, error, openSnackbar: true })
			return false
		}
	}),
})

export default createPost
