// ---------- common models

import {
	ActionStatus,
	MessageColorType,
	DLObjectFormat,
	DLSrcFormat,
} from "../../../../../common-models/enumerations/common-enums"
import {
	apgLibPrefix,
	LibFileProps,
	libGroupPrefix,
	LibHierarchy,
	normalLibPrefix,
} from "../lib-in-proj.data-model"
import {
	idToString,
	IdType,
} from "../../../../../library/converters/id-converter"

type FetchedFolderProps = {
	Message: any // null or string
	Status: number // 0 or 1
	TempFoldID: number
	TempFoldName: string
	TemplateList: {
		MediaType: string
		TempId: number
		TempName: string
	}[]
}

/**
 * * Current File Library has two type of library
 * - Normal file library
 * - AuditProgram library
 */
const GetLibrariesInProj = (self: any) => ({
	getLibrariesInProj(orgId: string) {
		// 0.
		const actionName = "getLibrariesInProj"
		// 1.
		// 2.
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3.
		self.readLibrariesInProj(orgId)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// console.log(actionName+ "__response " + response)
					const apgList = response.data.AuditTemplateListRes
					const normalList = response.data.TemplateListRes

					let flatList: LibFileProps[] = []

					//
					// NOTE: 1. Flatten APG Libraries
					// * But this library is a custom file which has mulitiple sub steps in it
					apgList.map((libGroup: FetchedFolderProps) => {
						const libGroupId =
							libGroupPrefix +
							idToString(libGroup.TempFoldID, IdType.folder)
						const newLibGroup = {
							parentId: null,
							id: libGroupId,
							title: libGroup.TempFoldName,
							hierarchy: LibHierarchy.group,
							objectFormat: DLObjectFormat.folder,
							srcFormat: DLSrcFormat.undefined,
							expanded: false,
						}
						if (
							flatList.findIndex(
								(item) => item.id === libGroupId
							) === -1
						) {
							// because this libGroup is shared with normal files
							flatList.push(newLibGroup)
						}
						libGroup.TemplateList.map((apgLib) => {
							const convertedApgLib = {
								parentId: libGroupId,
								id:
									apgLibPrefix +
									idToString(apgLib.TempId, IdType.file),
								title: apgLib.TempName,
								hierarchy: LibHierarchy.file,
								objectFormat: DLObjectFormat.customFile, // DLObjectFormat.apg,
								srcFormat: DLSrcFormat.undefined,
								expanded: false,
							}
							flatList.push(convertedApgLib)
						})
					})
					//
					// NOTE: 2. Flatten Normal Libraries
					normalList.map((libGroup: FetchedFolderProps) => {
						const libGroupId =
							libGroupPrefix +
							idToString(libGroup.TempFoldID, IdType.folder)
						const newLibGroup = {
							parentId: null,
							id: libGroupId,
							title: libGroup.TempFoldName,
							hierarchy: LibHierarchy.group,
							objectFormat: DLObjectFormat.folder,
							srcFormat: DLSrcFormat.undefined,
							expanded: false,
						}
						if (
							flatList.findIndex(
								(item) => item.id === libGroupId
							) === -1
						) {
							// because this libGroup is shared with normal files
							flatList.push(newLibGroup)
						}
						libGroup.TemplateList.map((nomalLib) => {
							const convertedNormalLib = {
								parentId: libGroupId,
								id:
									normalLibPrefix +
									idToString(nomalLib.TempId, IdType.folder),
								title: nomalLib.TempName,
								hierarchy: LibHierarchy.folder,
								objectFormat: DLObjectFormat.normalFile,
								srcFormat: DLSrcFormat.undefined,
								expanded: false,
							}
							flatList.push(convertedNormalLib)
						})
					})
					//
					// 3. set flat list
					self.setFlatList(flatList)

					// set success case response
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to" + actionName,
						open: true,
						// autoHide: true,
					})
				} else {
					// set fail case response
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						// customMessage:
						// 	"Something Wrong... (UPDATE IS REQUIRED)",
						open: true,
						autoHide: false,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: false,
				})
			})
	},
})

export default GetLibrariesInProj
