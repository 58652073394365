import { format, endOfDay } from "date-fns"

const ReplicaRestorationRelated = (self: any) => ({
	getReplicaRestorationTargetList(filteredData: any) {
		// NOTE: this 'filteredData' is 'sortedData' in the am.archivedProjects screen
		console.log("get target list (store the filtered list as targets)")

		self.resetListWithReplicaId()
		self.resetListForJsonExport()

		let simpleList: {
			id: string
			title: string
			aliasId: string
			archivedBy: string
			archivedAt: string
		}[] = []

		filteredData.map((item: any) => {
			const simpleItem = {
				id: item.id,
				title: item.title,
				aliasId: item.aliasId,
				version: item.version,
				// (below) for export JSON
				archivedBy: item.archivedBy?.name ?? "noName",
				archivedAt: item.archivedAt ?? "noDate",
			}
			simpleList.push(simpleItem)
		})
		self.setReplicaTargetList(simpleList)
		console.log("Target List", self.viewReplicaTargetList)
	},
	readonlyAssignAtOnce(targetList: any, date: any, userId: number) {
		// NOTE: targetList = replicaTargetList or zipUrlList
		console.log(
			`Assign current signed in user ${userId} to targeted projects (1)`
		)
		const eod = endOfDay(new Date(date))
		const formattedDate = format(eod, "yyyy-MM-dd'T'HH:mm:ss.SSS")
		console.log("expiration date:", formattedDate)

		self.relayAssignUser(targetList, 0, userId, formattedDate)
	},
	preprocessPdfCheck(getProjToken: any, userId: number | string) {
		self.resetPdfStatusList()
		console.log(" ---------- reset the pdf status list ---------- ")
		console.log("PDF status check start")

		if (typeof userId === "number") {
			self.relayGetPreConditionStatus(
				self.replicaTargetList,
				0,
				getProjToken,
				userId
			)
		} else {
			console.log("User ID is incorrect", userId)
		}
	},
	validateReplicas(getProjToken: any, userId: number | string, targetReplicas:number[]) {
		console.log("Validate Replicas")
		self.resetReplicaValidationResults()
		const postAction = self.pushReplicaValidationResult

		if (typeof userId === "number") {
			let proceed = window.confirm(
				`Do you want to validate ${targetReplicas.length} replicas?`
			)
			if (!proceed) {
				return
			} else {
				self.relayValidateReplicas(
					targetReplicas,
					0,
					getProjToken,
					userId,
					postAction
				)
			}
		} else {
			console.log("User ID is incorrect", userId)
		}
	},
	generatePdfs(userId: number | string) {
		console.log("Generate preprocess PDFs")
		const pdfRequiredList = self.pdfStatusList.filter(
			(item: any) => !item.pdfStatus
		)
		console.log("pdf required list:", pdfRequiredList)
		if (typeof userId === "number") {
			let proceed = window.confirm(`Do you want to start pdf generation?`)
			if (!proceed) {
				return
			}
			self.relayRegenPreProcessPdf(pdfRequiredList, 0, userId)
		} else {
			console.log("User ID is incorrect")
		}
	},
	repairReplicas(userId: number | string, orgId: number | string) {
		console.log("Repair Replicas")

		const list = self.pdfStatusList.filter((item: any) => item.pdfStatus)
		const list2 = self.listWithReplicaId

		console.log("replica required list length: ", list.length)
		console.log("list with replica ID length: ", list2.length)
		console.log("list with replica ID:", self.viewRepIdList)

		if (list.length === list2.length) {
			let proceed = window.confirm(
				"Do you want to start replica restoration?"
			)
			if (!proceed) {
				return
			}
			self.relayRepairReplica(list2, 0, userId, orgId)
		} else {
			console.log("Please check. 2 Lists are different")
		}
	},
	repairReplicasByIdList(userId: number, orgId: number, replicaList:any) {
		// let replicaList: {
		// 	replicaId: number
		// 	id: number
		// 	title: string
		// 	aliasId: string
		// 	pdfStatus: boolean
		// }[] = [] // NOTE: input the replica list here
		console.log("replica list to repair:", replicaList)
		let proceed = window.confirm(
			`Do you want to start restoration of ${replicaList.length} replicas?`
		)
		if (!proceed) {
			return
		}
		self.relayRepairReplica(replicaList, 0, userId, orgId)
	},
	getReadonlyIds(userId: number) {
		console.log("get readonly assigned ID")
		self.resetUnassignList()
		const list = self.zipUrlList

		const updateFunction = self.pushUnassignList
		self.relayGetReadonlyIdOnly(list, 0, userId, updateFunction)
	},
	unassignReadonlyUserFromProjects() {
		const unassignList = self.viewUnassignList
		console.log("unassignList", unassignList)

		let proceed = window.confirm("Do you want to proceed unassign?")
		if (!proceed) {
			return
		} else {
			self.relayUnassignReadonlyUser(unassignList, 0)
		}
	},
})

export default ReplicaRestorationRelated
