import { getRequest } from "../../library/api-requests"
import { TOKEN_TYPE } from "../../library/api-requests/shared-for-api-requests"

// NOTE: Check the 'org-project-shared-apis'
// there is same API and it use parameters
// Need to check

export async function readProjectUsers() {
	const endpoint = "/GetUsersByClient"
	const response = await getRequest({
		url: endpoint,
		params: {},
		tokenType: TOKEN_TYPE.projToken,
	})

	return response
}
