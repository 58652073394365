import React from "react"
import styled from "styled-components"

export const StyledGlobalRightSideDrawer = styled.div`
	color: ${(props) => props.theme.textMediumEmphasis};
	.content-area {
		width: 100%;
		background-color: ${(props) => props.theme.background};
		.content-header {
			height: 3rem;
			padding: 0 1rem;
		}
		.content-body {
			padding: 0 1rem;
			.section {
				margin-bottom: 2rem;
				.section-header {
					margin-bottom: 1rem;
				}
				.section-body {
					padding-left: 0.6rem;
				}
			}
		}
	}
	.userguide-link {
		a {
			cursor: pointer;
			color: ${(props) => props.theme.emphasis};
			:hover {
				font-weight: 700;
			}
		}
	}
	.user-guide-container {
		padding: 3rem;
		a {
			color: blue;
		}
	}
	.signoff-container {
	}

	.abbreviation {
		.abbr {
			min-width: 3rem;
		}
	}
	.help-menu-tabs {
		width: calc(2rem + 4px + 8px);
		/* 4px = left side border for highlight, 8px = for right side padding */
		height: 100vh;
		background-color: ${(props) => props.theme.secondary};
		.tab {
			cursor: pointer;
			padding: 1rem 8px 1rem 4px;
			writing-mode: vertical-rl;
			text-orientation: sideways-right;
			width: 100%;
			transition: font-weight 0.2s;
			color: ${(props) => props.theme.shade40};
			border-left: 4px solid transparent;
			background-color: transparent;
			&.selected {
				font-weight: 700;
				opacity: 1;
				color: ${(props) => props.theme.emphasis};
				border-left-color: ${(props) => props.theme.emphasis};
				background-color: ${(props) => props.theme.background};
			}
			&.hide {
				display: none;
			}
			.noti-new-badge {
				.badge-circle {
					transform: rotate(-90deg);
				}
			}
		}
	}

	.shortcuts {
		margin-top: 1rem;
		table {
			color: ${(props) => props.theme.textMediumEmphasis};
			tr {
				display: flex;
				align-items: center;
				justify-content: space-between;
			}
			td {
				display: flex;
				align-items: center;
			}
			.keyboard-icon {
				width: 1.3rem;
				height: 1.3rem;
				border-radius: 3px;
				border: 1px solid ${(props) => props.theme.textMediumEmphasis};
				color: ${(props) => props.theme.textMediumEmphasis};
				background-color: transparent;
				font-size: 12px;
				&.non-square {
					width: auto;
					padding: 0 0.4rem;
				}
			}
			.connector {
				margin: 0 0.3rem;
				display: flex;
				align-items: center;
				text-align: center;
			}
			.section-divider {
				height: 1rem;
			}
		}
	}
	.theme-setting {
		margin-top: 1rem;
		padding: 2rem;
	}
`
