import React, { useEffect, useState } from "react"
import { observer } from "mobx-react-lite"
import OrgList from "./OrgList"
import { useRootStore } from "../../../stores/root-store/root-store.provider"
import { LobbySheet } from "../../../common-models/enumerations/translation-sheets"
import { useLobbyStore } from "../../../stores/lobby-store/lobby-store.provider"
import { ConsoleLog } from "../../../components/basic-elements"
import { clearLocalStorageForOrg } from "../../../library/token-utils"

export default observer(function OrgListController() {
	ConsoleLog(" *_________ OrgListContoller _________* ")
	const store = useRootStore()
	const lobbyStore = useLobbyStore()
	const actionName = "getOrgList"
	const sheetId = LobbySheet.org_list

	useEffect(() => {
		// NOTE: lobbyCheckinStatus condition is mandatory to clear the localStorage for org
		if (store.checkin.lobbyCheckinStatus === "SUCCESS") {
			lobbyStore.orgList.getOrgList()
			clearLocalStorageForOrg()
		}
	}, [store.checkin.lobbyCheckinStatus === "SUCCESS"])

	const [listType, setListType] = useState(false)

	const handleListType = () => {
		setListType(!listType)
	}

	const i18n = store.i18n.combineI18n(sheetId, "OrgListController")
	return (
		<OrgList
			partialStore={lobbyStore.orgList}
			actionStatus={lobbyStore.orgList.getActionStatus(actionName)}
			listType={listType}
			handleListType={handleListType}
			i18n={i18n}
		/>
	)
})
