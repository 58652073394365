import React from "react"
import { useProjStore } from "../../../../../../stores/proj-store/proj-store.provider"
import { observer } from "mobx-react-lite"
import {
	DLDialog,
	DLSpinner,
} from "../../../../../../components/basic-elements"
import { DLI18nProps } from "../../../../../../common-models/types/common-props"
import { getProjIdFromUrl } from "../../../../../../library/api-requests/request-get-others"

export default observer(function StartArchPreProcessingDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const projStore = useProjStore()
	const actionName = "startPreProcessing"

	const startPreProcess = () => {
		const projId = getProjIdFromUrl()
		const userId = localStorage.getItem("orgUserId")
		const isProjectLocked = projStore.projInfo.projInfo.isLocked
		if (projId && userId) {
			projStore.archivePreProcess.startPreProcessing(
				projId,
				userId,
				isProjectLocked
			)
		}
	}

	return (
		<DLDialog
			eleTestId="start-pre-processing-dialog"
			isOpen={projStore.archivePreProcess.startPreProcessDialogOpen}
			setIsOpen={projStore.archivePreProcess.setStartPreProcessDialogOpen}
			handleAction={startPreProcess}
			showCloseBtn={true}
			dialogTitle={`${i18n.twStart} !`}
			dialogContents={
				projStore.archivePreProcess.getActionStatus(actionName) ===
				"LOADING" ? (
					<PreProcessingLoader i18n={i18n} />
				) : (
					<StartPreProcessDialogForm i18n={i18n} />
				)
			}
			actionReady={
				projStore.archivePreProcess.getActionStatus(actionName) !==
				"LOADING"
			}
			maxWidth="xs"
			actionBtn={i18n.twOk}
			cancelBtnText={i18n.twClose}
		/>
	)
})

const StartPreProcessDialogForm = ({ i18n }: { i18n: DLI18nProps }) => {
	return (
		<div>
			<ol>
				<li>{i18n.tsStartMsg1}</li>
				<li>{i18n.tsStartMsg2}</li>
				<li>{i18n.tsStartMsg3}</li>
			</ol>
		</div>
	)
}

const PreProcessingLoader = ({ i18n }: { i18n: DLI18nProps }) => {
	return (
		<div>
			<h3>{i18n.tsPreProcessLoading}</h3>
			<div
				className="FR AC JC"
				style={{
					width: "100%",
					height: "100%",
					marginTop: 70,
					marginBottom: 50,
				}}
			>
				<DLSpinner />
			</div>
		</div>
	)
}
