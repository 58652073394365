import React, { useEffect, useState } from "react"
import { Redirect, useLocation } from "react-router-dom"
import { observer } from "mobx-react-lite"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"
import { useOrgStore } from "../../../../stores/org-store/org-store.provider"
import {
	ActionStatus,
	DLSector,
} from "../../../../common-models/enumerations/common-enums"
import DLSpinner from "../../../../components/basic-elements/spinner/DLSpinner"
import { ConsoleLog } from "../../../../components/basic-elements"
import { initializeOrgStores } from "../../../../stores/initialize-stores"
import StyledCheckin from "../../../../components/app-frame-elements/checkin-progress-circles.style"

/**
 * NOTE: Approach cases
 * 1. from the lobby: Select a organization on the lobby sector
 * 2. from the orgRoute: prerequisites exist but checkin status is not success
 */
export default observer(function OrgCheckin() {
	const store = useRootStore()
	const orgStore = useOrgStore()

	/**
	 * organization checkin process
	 * - 1. get token
	 * - 2. get default info
	 * - 3. get system (date & time) setting
	 * - 4. get user's permission by role
	 * - 5. set accessible menus in org
	 * - 6. checkin
	 */

	// TODO: WARNING: do not use URL Params

	// ----- required items
	const orgId = localStorage.getItem("orgId")
	const orgUserId = localStorage.getItem("orgUserId")
	const orgToken = localStorage.getItem("orgToken")
	// ----- Prerequistie (This prerequisite do not check the orgToken existence)
	const prerequisite = orgId !== null && orgUserId !== null
	//

	// ----- set redirecting destination
	let from: any
	let goTo = "/organization/my-page"
	let location: { state: { from: any } } = useLocation()

	if (location?.state) {
		from = location.state.from
	}
	if (from && !from.pathname.includes("lobby-checkin")) {
		goTo = from.pathname
	}

	// WARNING: Because cannot initialize the orgStore in the Lobby,
	// must use step 1 (token status check) as useState in this component to initialize the checkin store
	const [tokenStatus, setTokenStatus] = useState<undefined | ActionStatus>(
		undefined
	)

	const actionNames = {
		step1: "getOrgToken",
		step2: "getOrgInfo",
		step3: "getSysInfo",
		step4: "getPermissionSet",
		step5: "getAccessibleMenus",
	}
	let step1_status = orgStore.checkin.getActionStatus(actionNames.step1)
	let step2_status = orgStore.checkin.getActionStatus(actionNames.step2)
	let step3_status = orgStore.checkin.getActionStatus(actionNames.step3)
	let step4_status = orgStore.checkin.getActionStatus(actionNames.step4)
	let step5_status = orgStore.checkin.getActionStatus(actionNames.step5)

	//
	//
	// ? ----- STEP 1: get organization token
	useEffect(() => {
		ConsoleLog(" __________ OrgCheckin __________")
		ConsoleLog(["from", from?.pathname, "goTo", goTo])
		ConsoleLog("orgId " + orgId)
		// WARNING: initialize checkin store to reset the status
		orgStore.checkin.initializeStore()
		initializeOrgStores(orgStore)
		/**
		 * If prerequisite is flase, set orgCheckinStatus as fail
		 * - This prerequisite do not check the orgToken existence
		 * - OrgRoute checks the similar prerequisite
		 * - (OrgRoute checks the orgToken existence together)
		 */

		if (prerequisite === false) {
			console.error("(OrgCheckin) One of the prerequisite is null")
			orgStore.checkin.setOrgCheckin(ActionStatus.fail)
		} else {
			/**
			 * If orgToken does not exist, requset orgToken
			 * If orgToken exist, set the "getOrgToken" status to success
			 * (token validation will be done in step2)
			 */
			ConsoleLog("ORG CHKECIN STEP 1: get orgToken")
			orgStore.checkin.setOrgCheckin(ActionStatus.loading)

			if (orgStore.checkin.orgUserId === "" && orgUserId !== null) {
				orgStore.checkin.setOrgUserId(orgUserId)
			} // TODO: Try to find better place to set the orgUserId on the store

			const postWorkForSuccess = () => {
				setTokenStatus(ActionStatus.success)
				orgStore.checkin.responses.setResponse(actionNames.step1, {
					actionName: actionNames.step1,
					status: ActionStatus.success,
				})
			}
			if (orgToken === null) {
				// 'prerequisite === true' means orgId, orgUserId exist
				// but typescript cannot catch it. That's why the line bloew has two conditions
				orgId &&
					orgUserId &&
					orgStore.checkin.getOrgToken(
						orgId,
						orgUserId,
						postWorkForSuccess
					)
			} else {
				postWorkForSuccess()
			}
		}
	}, [orgId, orgUserId])

	//
	//
	// ? ----- STEP 2: get organization Info & Org Role Set info
	useEffect(() => {
		if (tokenStatus === ActionStatus.success && orgId) {
			// if (step1_status === "SUCCESS" && orgId) {
			ConsoleLog("ORG CHKECIN STEP 2: get default informations")
			orgStore.checkin.getOrgInfo(orgId)
			orgStore.checkin.getRoleSet(DLSector.org)
			// NOTE: @ Noah: is it required to separate the getRoleSet process??
			// }
		}
	}, [tokenStatus]) // [step1_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 3: get system setting (date and time formats)
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step2_status === "SUCCESS") {
				ConsoleLog("ORG CHKECIN STEP 3: get system settings")
				orgStore.checkin.getSysInfo(store.global.setGlobalDnT)
				store.global.setCurrentOrg(
					orgStore.checkin.orgInfo.orgId,
					orgStore.checkin.orgInfo.orgName
				)
			}
		}
	}, [step2_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 4: get user's permission by organization role
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step3_status === "SUCCESS") {
				ConsoleLog("ORG CHKECIN STEP 4: get assigned role's premission")

				const roleId = orgStore.checkin.orgInfo.orgRoleId
				// roleId = id_aa, id_sa... etc

				orgStore.checkin.getPermissionSet(DLSector.org, roleId)
			}
		}
	}, [step3_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 5: set accessible menu to store
	// * allowed menu list for current customer was fetched in lobby checkin
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step4_status === "SUCCESS") {
				ConsoleLog("ORG CHKECIN STEP 5: get accessible menus")
				const customerMenus = store.checkin.customerMenus

				orgStore.checkin.getAccessibleMenus(customerMenus)
			}
		}
	}, [step4_status === "SUCCESS"])

	//
	//
	// ? ----- STEP 6: set checkin state to success
	useEffect(() => {
		if (tokenStatus === ActionStatus.success) {
			if (step5_status === "SUCCESS") {
				ConsoleLog(
					"ORG CHKECIN STEP 6: set orgCheckinStatus as success"
				)
				orgStore.checkin.setOrgCheckin(ActionStatus.success)
			}
		}
	}, [step5_status])
	//
	//
	// ? ---- Fetching theme setting from local storage and updating
	useEffect(() => {
		const data = localStorage.getItem("themeSetting")
		const themeSetting = data ? JSON.parse(data) : []

		if (
			themeSetting.some(
				(item: any) => item.userId === orgUserId && item.orgId === orgId
			)
		) {
			const theme = themeSetting.find(
				(item: any) => item.userId === orgUserId && item.orgId === orgId
			).theme
			store.ui.setTheme(theme)
		} else {
			store.ui.setTheme("blue1") // if not in local storage then by default it is light
		}
	}, [])

	step1_status = orgStore.checkin.getActionStatus(actionNames.step1)
	step2_status = orgStore.checkin.getActionStatus(actionNames.step2)
	step3_status = orgStore.checkin.getActionStatus(actionNames.step3)
	step4_status = orgStore.checkin.getActionStatus(actionNames.step4)
	step5_status = orgStore.checkin.getActionStatus(actionNames.step5)
	// NOTE: If there are no component which use step status, console log below should be active

	return (
		<StyledCheckin>
			{orgStore.checkin.orgCheckin === "STANDBY" && (
				<div
					className="FR AC JC"
					style={{ width: "100%", height: "100%" }}
				>
					<DLSpinner />
				</div>
			)}
			{orgStore.checkin.orgCheckin === "LOADING" && (
				<div
					className="progress-circles"
					data-testid="org-chekcin-progress-circles"
				>
					<div className="text-info">
						Organization Checkin is in progress
					</div>
					<div className="steps">
						<div className={`progress-step ${step1_status}`} />
						<div className={`progress-step ${step2_status}`} />
						<div className={`progress-step ${step3_status}`} />
						<div className={`progress-step ${step4_status}`} />
						<div className={`progress-step ${step5_status}`} />
					</div>
				</div>
			)}
			{orgStore.checkin.orgCheckin === "SUCCESS" &&
				tokenStatus === ActionStatus.success && <Redirect to={goTo} />}
			{orgStore.checkin.orgCheckin === "FAIL" && (
				<Redirect to="/lobby/organization-list" />
			)}
		</StyledCheckin>
	)
})
