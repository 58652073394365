import { types } from "mobx-state-tree"

import {
	ReadOpenedFiles,
	DeleteOpenedFiles,
	CreateRequestNotification,
} from "../../../../service-modules/opened-files-module/model-actions"
import { CommonModelActions } from "../../../../common-models/common-model-actions"
import { OpenedFilesApiProps } from "../../../../service-modules/opened-files-module/opened-files.apis"

const OpenedFilesModel = ({
	apiReadOpenedFiles,
	apiDeleteOpenedFiles,
	apiCreateRequestNotification,
}: OpenedFilesApiProps) =>
	types
		.model({})
		//opened files
		.actions((self, readOpenedFiles = apiReadOpenedFiles) =>
			ReadOpenedFiles(self, readOpenedFiles)
		)
		.actions((self, delOpenedFiles = apiDeleteOpenedFiles) =>
			DeleteOpenedFiles(self, delOpenedFiles)
		)
		.actions((self, create = apiCreateRequestNotification) =>
			CreateRequestNotification(self, create)
		)
		// common parts
		.actions(CommonModelActions)

export default OpenedFilesModel
