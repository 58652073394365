import React from "react"
import styled from "styled-components"
import { observer } from "mobx-react-lite"
import { DLAvatar } from "@datalobby/components"
import { RoleModelProps } from "../../../../service-modules/file-module/data-models/file.data-model"
import { useRootStore } from "../../../../stores/root-store/root-store.provider"

export default observer(function FileAssignedRoles({
	roleList,
	width = 32,
	aliasId,
}: {
	roleList: RoleModelProps[]
	width: string | number
	aliasId: string
}) {
	const store = useRootStore()

	let left = 0
	const sidebarOpen = store.ui.isSidebarOpen
	if (sidebarOpen) {
		left = store.ui.drawerWidth
	} else {
		left = store.ui.collapsedDrawerWidth
	}
	return (
		<StyledFileAssignedRoles
			className="assigned-roles"
			data-testid={"assigned-roles-" + aliasId}
			style={{ width: width }}
		>
			{/* <div
					className=""
					style={{
						width: width,
						overflow: "hidden",
						position: "absolute",
						// left: left + 28,
					}}
				> */}
			{roleList.map((role: any, index: number) => {
				return (
					<div key={index}>
						<DLAvatar
							text={role.shortName}
							eleTestId={`mini-role-icon-${role.shortName}`}
							size={12}
							color={role.color}
							fontSize={9}
							// variant="square"
						/>
					</div>
				)
			})}
			{/* </div> */}
		</StyledFileAssignedRoles>
	)
})

const StyledFileAssignedRoles = styled.div`
	position: relative;
	z-index: 1;
	height: 100%;
	display: flex;
	flex-wrap: wrap;
	padding-left: 4px;
`
