import { types } from "mobx-state-tree"

const SimpleUserInfo = types.model({
	id: types.string,
	name: types.string,
})

const SimpleClientInfo = types.model({
	id: types.string,
	name: types.string,
})
export const OrgSearchProjectDetail = types.model({
	id: types.string,
	title: types.string,
	projectStatus: types.string,
	isLocked: types.boolean,
	aliasId: types.string, // project ID (user's input) (alias ID, business ID)
	accessRight: types.string,
	archived: types.boolean,
	archivedBy: SimpleUserInfo,
	archivedAt: types.union(types.string, types.null),
	createdBy: SimpleUserInfo,
	client: SimpleClientInfo,
	expirationDate: types.union(types.string, types.null),
	financialYear: types.number,
	periodEndDate: types.string, // TODO: @Prudhvi-0817: please update as "periodEnd" and related parts
	period: types.string,
	assignedRole: types.string,
	version: types.number,
	accessible: types.boolean,
})
