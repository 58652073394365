import React from "react"
import { useOrgStore } from "../../../../../stores/org-store/org-store.provider"
import { observer } from "mobx-react-lite"
import styled from "styled-components"
import {
	DLDialog,
	InputWithLabel,
	DLInputField,
} from "../../../../../components/basic-elements"
import useForm from "../../../../../library/use-form"
import { DLI18nProps } from "../../../../../common-models/types/common-props"

export default observer(function DigitalSignatureDialog({
	i18n,
}: {
	i18n: DLI18nProps
}) {
	const orgStore = useOrgStore()

	const inputsSchema = {
		hashValue: { value: "", error: "", requestInput: false },
	}
	const validationSchema = {
		hashValue: {
			isRequired: true,
		},
	}

	const verifyHash = () => {
		const projectId = orgStore.archiveMgmt.selectedProj
		orgStore.archiveMgmt.verifyDigitalSignatureHashValue(
			projectId,
			inputs.hashValue.value
		)
	}

	const { inputs, handleOnChange, handleOnSubmit, isReady } = useForm(
		inputsSchema,
		validationSchema,
		verifyHash,
		orgStore.archiveMgmt.digitalSignatureDialogOpen
	)

	return (
		<DLDialog
			eleTestId="digital-signature-dialog"
			isOpen={orgStore.archiveMgmt.digitalSignatureDialogOpen}
			setIsOpen={orgStore.archiveMgmt.setDigitalSignatureDialogOpen}
			handleAction={handleOnSubmit}
			showCloseBtn={true}
			dialogTitle={
				i18n.tsDigitalSignatureVerificationForZip ||
				"Digital Signature Verification for Zip File"
			}
			dialogContents={
				<DigitalSignatureContent
					inputs={inputs}
					handleOnChange={handleOnChange}
					i18n={i18n}
				/>
			}
			actionReady={isReady}
			actionBtn={i18n.twVerify || "Verify"}
			maxWidth="xs"
			cancelBtnText={i18n.twCancel || "Cancel"}
		/>
	)
})

const DigitalSignatureContent = observer(
	({
		inputs,
		handleOnChange,
		i18n,
	}: {
		inputs: any
		handleOnChange: any
		i18n: DLI18nProps
	}) => {
		return (
			<StyledDialog>
				<div className="input-section FR">
					<InputWithLabel
						required
						label={i18n.twHashValue || "Hash Value"}
					>
						<DLInputField
							eleTestId="hash_value_input"
							eleFullWidth
							eleName="hashValue"
							eleValue={inputs.hashValue.value}
							eleHandleChange={handleOnChange}
							eleRequired
							warningMsg={inputs.hashValue.error}
							requestInput={inputs.hashValue.requestInput}
						/>
					</InputWithLabel>
				</div>
				<div className="verification-steps">
					<h4>Steps to generate Hash Value:</h4>
					<p>
						<span>1.) On Windows (using Windows PowerShell)</span>
						Get-FileHash "\path\to\the\file" -Algorithm SHA256
					</p>
					<p>
						<span>2.) On MAC</span>
						shasum -a 256 \path\to\the\file
					</p>
					<p>
						<span>3.) On Linux</span>
						sha256sum \path\to\the\file
					</p>
				</div>
			</StyledDialog>
		)
	}
)

const StyledDialog = styled.div`
	.input-section {
		margin-bottom: 1.5rem;
		.label {
			min-width: 5rem;
		}
		.input-area {
			/* width: calc(100% - 9rem); */
			min-width: 17rem;
		}
	}
	.verification-steps {
		margin-top: 2em !important;
		p span {
			display: block;
			font-weight: bold;
		}
	}
`
