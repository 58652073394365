import { flow } from "mobx-state-tree"
import { OrgCheckinApiProps } from "../org-checkin.apis"

const ReadSysInfo = (
	self: any,
	readSysInfo: OrgCheckinApiProps["apiReadSysInfo"]
) => ({
	readSysInfo: flow(function* () {
		const actionName = "readSysInfo"
		try {
			const response = yield readSysInfo()
			// console.log(actionName+ "__response " + response)
			return response
		} catch (error) {
			// console.log(actionName+ "__error "+ error)
			self.handleTokenError({
				actionName,
				error,
				open: true,
			})

			return false
		}
	}),
})

export default ReadSysInfo
