import {
	ActionStatus,
	MessageColorType,
} from "../../../../../common-models/enumerations/common-enums"
import {
	idToNumber,
	IdType,
	idToString,
} from "../../../../../library/converters/id-converter"
import { ConsoleLog } from "../../../../../components/basic-elements"

const GetDashboardDetails = (self: any) => ({
	getDashboardDetails(projId: string, financialYear: string) {
		// 0. set actionName
		const actionName = "getDashboardDetails"
		// 1.
		// 2. set responses
		self.responses.setResponse(actionName, {
			actionName,
			status: ActionStatus.loading,
		})
		// 3. request API call

		const projNumId = idToNumber(projId, IdType.project)
		if (typeof projNumId === "string") {
			ConsoleLog("Invalid project Id..." + projId)
			return
		}

		const params = {
			ClientId: projNumId,
			FinancialYear: financialYear,
			Lang: "en",
		}

		self.readDashboardDetails(params)
			.then((response: any) => {
				if (response.status === 200 && response.data.Status === 1) {
					// if success
					// 1) reset the stored list
					//Adjustments
					const adjustments = response.data.AdjustmentsBlockResults
					self.setAdjustments({
						audit: adjustments[0].Audit,
						client: adjustments[0].Client,
						total: adjustments[0].Total,
					})
					//
					// CommentList
					let commentList: any[] = []
					const comments = response.data.ReviewCommentsBlockResults
					comments.Follow_Up.map((item: any) => {
						commentList.push({
							type: "QC Comment", // Some times getting "QCComment" and "QC Comment"
							completed: item.Completed,
							open: item.Open,
							total: item.Total,
						})
					})
					comments.Review.map((item: any) => {
						commentList.push({
							type: "Review Comment", // Some times getting "ReviewComment" and "Review Comment"
							completed: item.Completed,
							open: item.Open,
							total: item.Total,
						})
					})
					comments.Total.map((item: any) => {
						commentList.push({
							type: item.Type,
							completed: item.Completed,
							open: item.Open,
							total: item.Total,
						})
					})
					self.setCommentList(commentList)
					//
					let wpDetails: any[] = []
					response.data.WorkpaperStatusBlockResults.map(
						(detail: any) => {
							wpDetails.push({
								clientName: detail.ClientName,
								concurringPartner: detail.ConcurringPartner,
								notStarted: detail.NotStarted,
								engagementPartner: detail.Partner,
								prepared: detail.Prepared,
								reviewed: detail.Reviewed,
								total: detail.Total,
							})
						}
					)
					self.setWPStatusDetails(wpDetails)
					//
					//Audit Program
					let apDetails: any[] = []
					response.data.AuditProgramDashboard.map((detail: any) => {
						apDetails.push({
							preparedCompletedLately: detail.PCompletedLately,
							preparedCompletedTimely: detail.PCompletedTimely,
							preparedNotAssigned: detail.PNotAssigned,
							preparedOpenNotDue: detail.POpenNotDue,
							preparedOpenOverDue: detail.POpenOverDue,
							reviewedCompletedLately: detail.RCompletedLately,
							reviewedCompletedTimely: detail.RCompletedTimely,
							reviewedNotAssigned: detail.RNotAssigned,
							reviewedOpenNotDue: detail.ROpenNotDue,
							reviewedOpenOverDue: detail.ROpenOverDue,
							totalSteps: detail.TotalSteps,
							wpDesc: detail.Wpdesc,
							wpId: idToString(detail.WprId, IdType.file),
							wpRef: detail.WprefNo,
						})
					})

					self.setAuditProgramDetails(apDetails)
					// when success
					self.setNeedRefresh(false)
					self.handleResponse({
						actionName,
						status: ActionStatus.success,
						code: 200,
						color: MessageColorType.blue,
						customMessage: "Success to get dashboard details",
						open: true,
						autoHide: true,
					})
				} else {
					self.handleResponse({
						actionName,
						status: ActionStatus.fail,
						code: 999,
						color: MessageColorType.orange,
						message: response.data.Message,
						open: true,
					})
				}
			})
			.catch((error: Error) => {
				self.handleViewModelError({
					error,
					actionName,
					open: true,
				})
			})
	},
})

export default GetDashboardDetails
