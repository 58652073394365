import React, { useEffect, useState } from "react"
import styled from "styled-components"
import { PageContainer } from "../../../components/app-frame-elements"
import { observer } from "mobx-react-lite"
import { Route, Switch } from "react-router-dom"
import { useOrgStore } from "../../../stores/org-store/org-store.provider"
import data from "./dummy-clients.json"
import OrgClientsColumns from "./OrgClientsColumns"
import OrgClientDetail from "./OrgClientDetail"

export default observer(function OrgClients({
	// actionStatus,

	contentsHeight,
}: {
	// actionStatus: ActionStatus

	contentsHeight: any
}) {
	const orgStore = useOrgStore()

	return (
		<PageContainer
			fullWidth
			fixedHeight
			pageTools={<div />}
			hasToolbar={false}
		>
			<Switch>
				<Route exact path="/organization/clients">
					<StyledOrgClients>
						Table Area for OrgClients - future item
					</StyledOrgClients>
				</Route>
				<Route exact path="/organization/clients/detail/:id">
					<OrgClientDetail />
				</Route>
			</Switch>
		</PageContainer>
	)
})

const StyledOrgClients = styled.div`
	padding-left: 1rem;
	padding-right: 1rem;
`
