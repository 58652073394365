import React, { useState, useEffect } from "react"
import styled from "styled-components"
import { DLIconButton } from "../../../basic-elements"
import Icon from "@mdi/react"
import {
	mdiComment,
	mdiCommentOutline,
	mdiCommentAlert,
	mdiCommentCheckOutline,
} from "@mdi/js"
import { observer } from "mobx-react-lite"
import { DLMenuId } from "../dl-file-tree-props"
import { CommentStatus } from "../../../../screens/project-side/communications/proj-comments/store/data-models/comments.data-model"

export default observer(function FileRowComments({
	fileId,
	fileAliasId,
	menuId,
	reviewCommentStatus,
	qcCommentStatus,
	setSelectedFile,
	handleOpenCommentListOfFile,
}: {
	fileId: string
	fileAliasId: string
	menuId: DLMenuId
	reviewCommentStatus: CommentStatus
	qcCommentStatus: CommentStatus
	setSelectedFile: (fileId: string) => void
	handleOpenCommentListOfFile: (objId: string) => void
}) {
	const [color, setColor] = useState<"gray" | "orange" | "red" | "green">(
		"gray"
	)

	useEffect(() => {
		if (
			reviewCommentStatus === CommentStatus.notExist &&
			qcCommentStatus === CommentStatus.notExist
		) {
			setColor("gray")
		}

		if (
			reviewCommentStatus === CommentStatus.todo &&
			qcCommentStatus === CommentStatus.notExist
		) {
			setColor("orange")
		}

		if (
			reviewCommentStatus === CommentStatus.done &&
			qcCommentStatus === CommentStatus.notExist
		) {
			setColor("green")
		}

		if (
			reviewCommentStatus === CommentStatus.done &&
			qcCommentStatus === CommentStatus.done
		) {
			setColor("green")
		}

		if (
			reviewCommentStatus === CommentStatus.notExist &&
			qcCommentStatus === CommentStatus.done
		) {
			setColor("green")
		}
		// if the QC Comment exist, icon color must be red
		if (qcCommentStatus === CommentStatus.todo) {
			setColor("red")
		}
	}, [fileId, qcCommentStatus, reviewCommentStatus])

	const handleClickCommentBtn = (e: any) => {
		setSelectedFile(fileId)
		handleOpenCommentListOfFile(fileId)
	}

	let tooltipText = "No Comments"

	if (color === "orange") {
		tooltipText = "Review comments exist"
	} else if (color === "red") {
		tooltipText = "QC Comments exist"
	} else if (color === "green") {
		tooltipText = "Closed Comments"
	}

	return (
		<StyledFileRowComments className="FR AC JC">
			<DLIconButton
				variant="iconOnly"
				eleTestId={`comment-icon-${fileAliasId}`}
				tooltipText={tooltipText}
				clickHandler={(e: any) => handleClickCommentBtn(e)}
				size="small"
				eleClassName={`comment-${color}`}
			>
				{color === "gray" ? (
					<Icon path={mdiCommentOutline} size={0.6} />
				) : color === "red" ? (
					<Icon path={mdiCommentAlert} size={0.6} />
				) : color === "green" ? (
					<Icon path={mdiCommentCheckOutline} size={0.6} />
				) : (
					<Icon path={mdiComment} size={0.6} />
				)}
			</DLIconButton>
		</StyledFileRowComments>
	)
})

const StyledFileRowComments = styled.div`
	.comment-gray {
		color: ${(props) => props.theme.shade10};
	}
	.comment-orange {
		color: ${(props) => props.theme.themeOrange};
	}
	.comment-red {
		color: ${(props) => props.theme.themeRed};
	}
	.comment-green {
		color: ${(props) => props.theme.shade60};
	}
`
