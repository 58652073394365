import { types, applySnapshot } from "mobx-state-tree"
import {
	OrgSetupGroup,
	UserList,
	AssignedProjList,
	ClientList,
} from "./data-models/org-setup-groups.data-models"

// ---------- common models
import Responses from "../../../common-models/responses"
import ResponseSnackbar from "../../../common-models/response-snackbar"
// ---------- common actions
import {
	CommonViewModelActions,
	Refresh,
	ViewResponseHelper,
} from "../../../common-models/common-view-model-actions"
// ---------- response cases

import { initialStore } from "./org-setup-groups.provider"
import {
	GetSetupGroupList,
	AddGroup,
	EditGroupName,
	GetUnassignedUsersOfGroup,
	GetAssignedUsersOfGroup,
	AddUsersToGroup,
	RemoveUsersFromGroup,
	GetClientsByGroup,
	AddClientsToGroup,
	RemoveGroup,
} from "./view-model-actions"
import { AssignStatus } from "../org-clients-store/data-models/org-clients.data-models"
import { ConsoleLog } from "../../../components/basic-elements"

// @Prudhvi 0810:
/**
 * userList --> unassignedUsersOfGroup
 * assignedUserList --> assignedUsersOfGroup
 * Please update action names refer to updated names above
 */
const OrgSetupGroupsViewModel = types
	.model({
		groupList: types.array(OrgSetupGroup),
		unassigedUsersOfGroup: types.array(UserList),
		assignedUsersOfGroup: types.array(UserList),
		assignedProjectList: types.array(AssignedProjList),
		clientList: types.array(ClientList),
		assignedClientList: types.array(ClientList),

		// ---------- common models
		// refresh: types.union(types.boolean, types.undefined), // WARNING: Where to use?
		needRefresh: true,
		responses: Responses,
		responseSnackbar: ResponseSnackbar,
	})
	.actions((self) => ({
		// resetList() {
		// 	self.groupList.length = 0
		// },
		// resetUserList() {
		// 	self.userList.length = 0
		// },
		setGroupList(groupList: any) {
			self.groupList.length = 0
			self.groupList = groupList
		},
		// resetAssignedUserList() {
		// 	self.assignedUserList.length = 0
		// },
		resetAssignedProjectList() {
			self.assignedProjectList.length = 0
		},
		// resetClientList(Type: number) {
		// 	if (Type === 1) {
		// 		self.clientList.length = 0
		// 	} else {
		// 		self.assignedClientList.length = 0
		// 	}
		// },
		deleteGroupFromStore(id: string) {
			self.groupList.splice(
				self.groupList.findIndex((item: any) => item.groupId === id),
				1
			)
		},
		pushItemToList(item: any) {
			self.groupList.unshift(item)
		},
		// pushItemToUserList(item: any) {
		// 	self.userList.push(item)
		// },
		// pushItemToAssignedUserList(item: any) {
		// 	self.assignedUserList.push(item)
		// },
		setUnassignedUsersOfGroup(clients: any) {
			self.unassigedUsersOfGroup.length = 0
			self.unassigedUsersOfGroup = clients
		},
		setAssignedUserList(clients: any) {
			self.assignedUsersOfGroup.length = 0
			self.assignedUsersOfGroup = clients
		},
		pushItemToAssignedProjectList(item: any) {
			self.assignedProjectList.push(item)
		},
		// pushItemToClientList(item: any) {
		// 	self.clientList.push(item)
		// },
		setClientList(clients: any) {
			self.clientList.length = 0
			self.clientList = clients
		},
		setAssignedClientList(clients: any) {
			self.assignedClientList.length = 0
			self.assignedClientList = clients
		},
		// pushItemToAssignedClientList(item: any) {
		// 	self.assignedClientList.push(item)
		// },
		setUserSelect(userId: string) {
			let index = self.unassigedUsersOfGroup.findIndex(
				(item: any) => item.id === userId
			)
			self.unassigedUsersOfGroup[index].selected =
				!self.unassigedUsersOfGroup[index].selected
		},
		setAssignedUserSelect(userId: string) {
			let index = self.assignedUsersOfGroup.findIndex(
				(item: any) => item.id === userId
			)
			self.assignedUsersOfGroup[index].selected =
				!self.assignedUsersOfGroup[index].selected
		},
		updateUnassignedUserOfGroup(usrList: any) {
			usrList.map((item: any) => {
				const user = self.unassigedUsersOfGroup.find(
					(item1: any) => item1.id === item.UserId
				)
				if (user) {
					const reOrganizedUser = {
						...user,
						selected: false,
					}
					// ConsoleLog(reOrganizedUser)
					self.assignedUsersOfGroup.push(reOrganizedUser) // add to assigned group

					self.unassigedUsersOfGroup.splice(
						// remove from unassigned group
						self.unassigedUsersOfGroup.findIndex(
							(item1: any) => item1.id === item.UserId
						),
						1
					)
				}
			})
		},
		updateAssignedUserList(usrList: any) {
			usrList.map((item: any) => {
				const user = self.assignedUsersOfGroup.find(
					(item1: any) => item1.id === item.UserId
				)
				if (user) {
					const reOrganizedUser = {
						...user,
						selected: false,
					}
					// ConsoleLog(reOrganizedUser)
					self.unassigedUsersOfGroup.push(reOrganizedUser) // add to assigned group

					self.assignedUsersOfGroup.splice(
						// remove from unassigned group
						self.assignedUsersOfGroup.findIndex(
							(item1: any) => item1.id === item.UserId
						),
						1
					)
				}
			})
		},

		setClientSelect(clientMasterID: string) {
			let index = self.clientList.findIndex(
				(item: any) => item.id === clientMasterID
			)
			self.clientList[index].selected = !self.clientList[index].selected
		},
		setAssignedClientSelect(clientMasterID: string) {
			let index = self.assignedClientList.findIndex(
				(item: any) => item.id === clientMasterID
			)
			self.assignedClientList[index].selected =
				!self.assignedClientList[index].selected
		},
		updateClients(clientList: any, type: AssignStatus) {
			if (type === AssignStatus.unAssigned) {
				//type - 1 is unassigned client
				clientList.map((item: any) => {
					const client = self.clientList.find(
						(item1: any) => item1.id === item.ClientMasterID
					)
					if (client) {
						const reOrganizedClient = {
							...client,
							selected: false,
						}
						self.assignedClientList.push(reOrganizedClient) // add to assigned client

						self.clientList.splice(
							// remove from unassigned client
							self.clientList.findIndex(
								(item1: any) => item1.id === item.ClientMasterID
							),
							1
						)
					}
				})
			} else {
				//type - 2 is assigned client
				clientList.map((item: any) => {
					const client = self.assignedClientList.find(
						(item1: any) => item1.id === item.ClientMasterID
					)
					if (client) {
						const reOrganizedClient = {
							...client,
							selected: false,
						}
						self.clientList.push(reOrganizedClient) // add to unassigned client

						self.assignedClientList.splice(
							// remove from assigned client
							self.assignedClientList.findIndex(
								(item1: any) => item1.id === item.ClientMasterID
							),
							1
						)
					}
				})
			}
		},
		updateGroupNameStore(groupName: string, groupId: string) {
			const index = self.groupList.findIndex(
				(item: any) => item.groupId === groupId
			)
			self.groupList[index].groupName = groupName
			ConsoleLog(self.groupList)
		},

		updateAssignedClients(
			assignedClients: string,
			clientsCount: number,
			groupId: string
		) {
			const index = self.groupList.findIndex(
				(item: any) => item.groupId === groupId
			)
			self.groupList[index].clientsCount = clientsCount
			self.groupList[index].assignedClients = assignedClients
		},

		updateAssignedUsers(
			assignedUsers: string,
			userCount: number,
			groupId: string
		) {
			const index = self.groupList.findIndex(
				(item: any) => item.groupId === groupId
			)
			self.groupList[index].userCount = userCount
			self.groupList[index].assignedUsers = assignedUsers
		},
		isDuplicatedName(groupName: string) {
			return self.groupList.some(
				(group: any) => group.groupName === groupName
			)
		},
	}))
	.views((self) => ({
		getGroupInfoById(groupId: string) {
			return self.groupList.find(
				(element: any) => element.groupId === groupId
			)
		},
		viewGroupTable() {
			return [...self.groupList]
		},
		viewUsersList(name: string) {
			return self.unassigedUsersOfGroup.filter((item: any) =>
				item.name.toLowerCase().includes(name.toLowerCase())
			)
		},
		viewAssignedUsersList(name: string) {
			return self.assignedUsersOfGroup.filter((item: any) =>
				item.name.toLowerCase().includes(name.toLowerCase())
			)
		},
		viewClientsList(name: string) {
			return self.clientList.filter((item: any) =>
				item.name.toLowerCase().includes(name.toLowerCase())
			)
		},
		viewAssignedClientsList(name: string) {
			return self.assignedClientList.filter((item: any) =>
				item.name.toLowerCase().includes(name.toLowerCase())
			)
		},
	}))
	.views((self) => ({
		get viewEpAvailableUsers() {
			const groupUsers = self.assignedUsersOfGroup
			const epAvailableUesrs = groupUsers.filter(
				(item: any) =>
					item.accessTypeId !== "3" && item.accessTypeId !== "4"
				// TODO: WARNING: @Noah: UPDATE IS REQUIRED
			)
			/**
			 * 1: super admin
			 * 2: group admin
			 * 3: user access
			 * 4: external user
			 */
			ConsoleLog(["epAvailableUesrs", epAvailableUesrs])
			return epAvailableUesrs
		},
	}))
	// ----------
	// AGER (Add, Get, Edit, Remove)
	.actions(AddGroup)
	.actions(GetSetupGroupList)
	.actions(EditGroupName)
	.actions(GetUnassignedUsersOfGroup)
	.actions(GetAssignedUsersOfGroup)
	.actions(AddUsersToGroup)
	.actions(RemoveUsersFromGroup)
	.actions(GetClientsByGroup)
	.actions(AddClientsToGroup)
	.actions(RemoveGroup)
	// ---------
	// common parts
	.actions((self) => ({
		initializeStore() {
			// ConsoleLog("initialize store - org/setupGroups")
			applySnapshot(self, initialStore)
		},
	}))
	.actions(Refresh)
	// .actions((self) => ({
	// 	refresh() {
	// 		self.initializeStore()
	// 		self.getSetupGroupList()
	// 	},
	// }))
	.actions(CommonViewModelActions)
	.views(ViewResponseHelper)

export default OrgSetupGroupsViewModel
