import React from "react"
import { observer } from "mobx-react-lite"
import { Route, Redirect, useLocation } from "react-router-dom"
import { useProjStore } from "../stores/proj-store/proj-store.provider"
import { ConsoleLog } from "../components/basic-elements"
import { findProjToken } from "../library/token-utils"
import { getProjIdFromUrl } from "../library/api-requests/request-get-others"
import { useRootStore } from "../stores/root-store/root-store.provider"
/**
 * WARNING: Manadatory Items of ProjRoute
 * // 1. lobbyUserId
 * // 2. lobbyToken
 * (lobbyUserId and lobbyToken existence was checked on the LobbyRoute already)
 *
 * 3. orgUserId
 * 4. orgId
 * 5. orgToken
 * 6. projId
 * 7. projToken
 *
 * If any of the 3~5 conditions do not exist,
 * redirect the user to lobby screen
 *
 * If nay of the 6~7 conditions do not exist,
 * redirect the user to organization screen
 */
const ProjRoute = observer(
	({
		children,
		path,
		exact,
	}: {
		children: any
		path: string
		exact?: boolean
	}) => {
		/**
		 * * <Q1> Is organization related prerequisiete true?
		 * --- if false: redirect to the lobby
		 * --- if true: --> Q2
		 * * <Q2> Is project related prerequisiete true?
		 * --- if false: redirect to the organization
		 * --- if true: --> Q3
		 * * <Q3> Is ProjectCheckin Status success?
		 * ------ if false: redirect to the project checkin
		 * ------ if true: redirect to original project screen (children of the route)
		 */
		const store = useRootStore()
		const projStore = useProjStore()

		const customerMenus = store.checkin.customerMenus
		let menuId: undefined | string = ""
		if (customerMenus) {
			menuId = customerMenus.find(
				(item: any) => item.urlEdge === path
			)?.id
		}
		// NOTE: Cannot check accesssibility here because assigned role has not set yet
		// let menuAccess: undefined | boolean = false
		// if (menuId) {
		// 	menuAccess = projStore.checkin.checkAccess(menuId)
		// }

		let location: { pathname: string } = useLocation()
		const projNumId = parseInt(location.pathname.split("/")[2])

		// required items
		const orgUserId = localStorage.getItem("orgUserId")
		const orgId = localStorage.getItem("orgId")
		const orgToken = localStorage.getItem("orgToken")
		const projId = getProjIdFromUrl()
		const projToken = projId ? findProjToken(projId) : null
		//
		const orgPrerequisite =
			orgUserId !== null && orgId !== null && orgToken !== null
		const projPrerequisite = projId !== null && projToken !== null
		//
		const projCheckinStatus = projStore.checkin.projCheckin

		console.log(
			"orgPrerequisite:",
			orgPrerequisite,
			"projPrerequisite:",
			projPrerequisite
		)
		ConsoleLog("project checkin status in projRoute", projCheckinStatus)

		return (
			<Route
				exact={exact}
				path={path}
				render={({ location }) =>
					orgPrerequisite === false ? (
						<Redirect
							to={{ pathname: "/lobby/organization-list" }}
						/>
					) : projPrerequisite === false ? (
						<Redirect
							to={{
								pathname: `/project/checkin/${projNumId}`,
								state: { from: location },
							}}
						/>
					) : // <Redirect to={{ pathname: "/organization/my-page" }} />
					projCheckinStatus !== "SUCCESS" ? (
						<Redirect
							to={{
								pathname: `/project/checkin/${projNumId}`,
								state: { from: location },
							}}
						/>
					) : (
						children
					)
				}
			/>
		)
	}
)

export default ProjRoute
