import { types } from "mobx-state-tree"
import OrgRiskAssessmentModel from "./org-risk-assessment.model"
import OrgRiskAssessmentViewModel from "./org-risk-assessment.view-model"
import OrgSetupRiskAssessmentUiHelper from "./org-risk-assessment.ui-helper"
import { OrgRiskAssessmentApiProps } from "./org-risk-assessment.apis"
import * as api from "./org-risk-assessment.apis"

export const OrgSetupRiskAssessmentStore = ({
	apiCreate,
	apiRead,
	apiUpdate,
	apiDelete,
}: OrgRiskAssessmentApiProps) =>
	types
		.compose(
			OrgRiskAssessmentModel({
				apiCreate,
				apiRead,
				apiUpdate,
				apiDelete,
			}),
			OrgRiskAssessmentViewModel,
			OrgSetupRiskAssessmentUiHelper
		)
		.named("OrgSetupRiskAssessmentStore")

export const initialStore = {
	// ui-helpers
	selectedRiskAssessment: "",
	clickPoint: {
		mouseX: null,
		mouseY: null,
	},
	// common parts
	responses: {},
	responseSnackbar: {
		message: "",
	},
}

const OrgSetupRiskAssessmentStoreInstance = OrgSetupRiskAssessmentStore({
	apiCreate: api.create,
	apiRead: api.read,
	apiUpdate: api.update,
	apiDelete: api.del,
}).create(initialStore)

export default OrgSetupRiskAssessmentStoreInstance
